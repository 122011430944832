export const formatData = (results, property) => {
  let groupedResults = results.map((r) => ({
    label: r[property],
    value: r.grouped_count,
  }));
  return groupedResults
    .sort((a, b) => a.value - b.value)
    .reverse()
    .slice(0, 5);
};

export const formatTopData = (results, property) => {
  let groupedResults = results.map((r) => ({
    label: r[property],
    value: r.grouped_count,
  }));

  // return the top 5 most recurring lockouts
  return groupedResults
    .sort((a, b) => a.value - b.value)
    .reverse()
    .slice(0, 5);
};
