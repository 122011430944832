// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { func, oneOfType, shape, string } from "prop-types";

// ** Styles **************************
import {
  Container,
  Table,
  Day,
  DayOfWeek,
  DayPlaceholder,
  Month,
  Row,
} from "./style";

// ** Constants ***********************
const DAYS = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Calendar component
//
//  ** Remarks
//  Date picker calendar
//
//  ** Props
//  @param current {obj} - current month moment object
//  @param end {obj} - end of range moment object
//  @param hovered {obj} - current day that is being hovered over moment object
//  @param onHover {func} - toggles hovered day
//  @param onSelectDay {func} - calendar day selection on click
//  @param start {obj} - start of range moment object
//
//  ** Methods
//  checkActive - check if current day is active for styling purposes
//  renderDays - renders days of current month supplied to component
//
export default class Calendar extends Component {
  checkActive(day) {
    const { end, hovered, start } = this.props;
    let active = false;

    if (start && end && day.isBetween(start, end)) {
      active = true;
    } else if (start && hovered && !end && day.isBetween(start, hovered)) {
      active = true;
    } else {
      active = false;
    }

    return active;
  }

  renderDays() {
    const { current, renderDate } = this.props;
    const startDay = renderDate(current)
      .clone()
      .startOf("month")
      .startOf("week");
    const endDay = renderDate(current).clone().endOf("month").endOf("week");

    const calendar = [];
    const date = startDay.clone().subtract(1, "day");

    while (date.isBefore(endDay, "day")) {
      calendar.push(
        Array(7)
          .fill(0)
          .map(() => date.add(1, "day").clone())
      );
    }

    return calendar;
  }

  render() {
    const { current, end, onHover, onSelectDay, renderDate, start } =
      this.props;

    return (
      <Container>
        <Month>{renderDate(current).format("MMMM YYYY")}</Month>
        <Table>
          <thead>
            <Row>
              {DAYS.map((day) => (
                <DayOfWeek key={day}>{day}</DayOfWeek>
              ))}
            </Row>
          </thead>
          <tbody>
            {this.renderDays().map((week, i) => (
              <Row key={i}>
                {week.map((day) => {
                  if (day.month() === renderDate(current).month()) {
                    return (
                      <Day
                        key={day.unix()}
                        active={this.checkActive(day)}
                        onClick={() => onSelectDay(day)}
                        onMouseEnter={() => onHover(day)}
                        selected={
                          day.isSame(start, "day") || day.isSame(end, "day")
                        }
                      >
                        {day.format("D")}
                      </Day>
                    );
                  }
                  return <DayPlaceholder key={day.unix()} />;
                })}
              </Row>
            ))}
          </tbody>
        </Table>
      </Container>
    );
  }
}

// ** Proptypes ***********************
Calendar.propTypes = {
  current: string,
  end: oneOfType([shape(), string]),
  hovered: shape({}),
  onHover: func,
  onSelectDay: func,
  renderDate: func.isRequired,
  start: oneOfType([shape(), string]),
};
