// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { string } from "prop-types";

// ** Components **********************
import { DefaultIcon } from "views/Components/Icon";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import { ColumnContainer, Container, Details, Header } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Button component
//
//  ** Remarks
//  Simple button component extended to simplify code across the app
//
//  ** Props
//  @param details {string} - message details
//  @param header {string} - message header
//  @param icon {string} - icon identifier
//  @param iconHeight {string} - height of icon
//
const EmptyState = ({ details, header, icon, iconHeight }) => (
  <Container>
    {icon && <DefaultIcon height={iconHeight} icon={icon} />}
    <ColumnContainer icon={icon}>
      {header && <Header>{header}</Header>}
      <Details>{details}</Details>
    </ColumnContainer>
  </Container>
);

// ** Proptypes ***********************
EmptyState.propTypes = {
  details: string.isRequired,
  header: string,
  icon: string,
  iconHeight: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Default list ********************
export const DefaultListEmptyState = withProps((props) => ({
  details: props.details || "No available options",
  header: props.header,
  icon: "alert",
  iconHeight: "13px",
}))(EmptyState);

// ** Select options ******************
export const SelectEmptyState = withProps((props) => ({
  details: props.details || "No available options",
  header: props.header,
  icon: props.icon,
  iconHeight: props.iconHeight,
}))(EmptyState);

export default EmptyState;
