export const ORDER_BY_ASC = 1;
export const ORDER_BY_DESC = -1;

const querySchema = {
  $id: "/query",
  type: "object",
  properties: {
    age: { type: "number", readOnly: true },
    aggregate: { type: "array" },
    config: { type: "object" },
    configuration: { type: "object" },
    created: { type: "string", readOnly: true },
    createdBy: { type: "string", readOnly: true },
    namespace: { type: "string" },
    deleted: { type: "string", readOnly: true },
    deletedBy: { type: "string", readOnly: true },
    description: { type: "string" },
    id: { type: "number", readOnly: true },
    isBasis: { type: "boolean" },
    lastOkDuration: { type: "number", readOnly: true },
    lastProcessEnd: { type: "string", readOnly: true },
    modified: { type: "string", readOnly: true },
    modifiedBy: { type: "string", readOnly: true },
    name: { type: "string" },
    orgId: { type: "string", format: "uuid", readOnly: true },
    search: { type: "object" },
    uiVisible: { type: "boolean" },

    queryParams: {
      type: "array",
      items: {
        type: "object",
        properties: {
          field: { type: "string" },
          operator: {
            type: "string",
            enum: ["contains", "eq", "gt", "lt", "regex", "in"],
          },
          negate: { type: "boolean" },
          value: {},
        },
        required: ["field", "value"],
        additionalProperties: false,
      },
    },
    resultParams: {
      type: "object",
      properties: {
        orderBy: {
          type: "array",
          items: {
            type: "object",
            properties: {
              key: { type: "string" },
              value: { type: "number", enum: [ORDER_BY_ASC, ORDER_BY_DESC] },
            },
            required: ["key", "value"],
            additionalProperties: false,
          },
        },
        transform: {
          type: "array",
          items: {
            type: "object",
            properties: {
              field: { type: "string" },
              sort: { type: "number", enum: [ORDER_BY_ASC, ORDER_BY_DESC] },
              value: { type: ["string", "number", "boolean", "null"] },
              negate: { type: "boolean" },
              operator: { type: "string" },
            },
            required: ["operator"],
            additionalProperties: false,
          },
        },
        page: {
          type: "object",
          properties: {
            number: { type: "number" },
            count: { type: "number" },
          },
          additionalProperties: false,
        },
        outputFields: {
          type: "array",
          items: {
            type: "string",
          },
        },
      },
      additionalProperties: false,
    },
  },
  required: ["queryParams", "resultParams"],
  additionalProperties: false,
};

export default querySchema;
