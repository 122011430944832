import { API } from "./API";

/*
Constants and Helpers
*/

const DEFAULT_OPTIONS = {
  baseUrl: "graphql",
};

const blockFields = `
  age,
  automated,
  blockType,
  community,
  description,
  expirationDate,
  findingId,
  id,
  negating,
  note,
  orgId,
  target,
  targetInet,
  until,
`;

const configFields = `
  automated,
  block,
  blockConfigurationFiles { label, total, url },
  community,
  defaultDuration,
  devices,
  id,
  orgId,
  threatFeeds,
`;

const BLOCK_UPDATE_FIELDS = ["description", "note", "until"];

const blockUpdateFields = (data) => {
  const requestData = {};

  BLOCK_UPDATE_FIELDS.forEach((field) => {
    if (data[field] !== null && data[field] !== undefined) {
      requestData[field] = data[field];
    }
  });

  return requestData;
};

/*
Main class
*/

export class BlockingAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  /*
  Blocks
  */

  getBlock(blockId) {
    const query = `
      query getBlockById(
        $blockId: ID!
      ) {
        block(
          blockId: $blockId
        ) {${blockFields}}
      }
    `;

    const data = {
      blockId,
    };

    const resp = super.get({ query, data });

    return resp;
  }

  listBlocks({ orgId, findingId }) {
    const query = `
      query listBlocks(
        $orgId: ID!,
        $findingId: ID,
      ) {
        blocks(
          orgId: $orgId,
          findingId: $findingId,
        ) {${blockFields}}
      }
    `;

    const data = {
      orgId,
      findingId,
    };

    const resp = super.get({ query, data });

    return resp;
  }

  createBlock(data) {
    const query = `
      mutation createNewBlock(
        $data: blockCreate!
      ) {
        createBlock(
          data: $data
        ) {${blockFields}}
      }
    `;

    const resp = super.get({ query, data: { data } });

    return resp;
  }

  updateBlock({ blockId, data }) {
    const query = `
      mutation updateExistingBlock(
        $blockId: ID!,
        $data: blockUpdate!
      ) {
        updateBlock(
          blockId: $blockId,
          data: $data
        ) {${blockFields}}
      }
    `;

    const resp = super.get({
      query,
      data: { blockId, data: blockUpdateFields(data) },
    });

    return resp;
  }

  deleteBlock(blockId) {
    const query = `
      mutation deleteBlock(
        $blockId: ID!,
      ) {
        deleteBlock(blockId: $blockId){message,}
      }
    `;

    const data = {
      blockId,
    };

    const resp = super.get({ query, data });

    return resp;
  }

  /*
  Blocking configuration
  */

  getConfig(orgId) {
    const query = `
      query getOrgBlockingConfig(
        $orgId: ID!
      ) {
        blockingConfiguration(
          orgId: $orgId
        ) {${configFields}}
      }
    `;

    const data = {
      orgId,
    };

    const resp = super.get({ query, data });

    return resp;
  }

  createConfig(data) {
    const query = `
      mutation createNewConfig(
        $data: configInputPost!
      ) {
        createConfig(
          data: $data
        ) {${configFields}}
      }
    `;

    const resp = super.get({ query, data: { data } });

    return resp;
  }

  updateConfig({ configId, data }) {
    const query = `
      mutation updateConfig(
        $configId: ID!,
        $data: configInputPut!
      ) {
          updateConfig(
            configId: $configId,
            data: $data
          ) {
            ${configFields}
          }
      }
    `;

    const resp = super.get({ query, data: { configId, data } });

    return resp;
  }

  deleteConfig(configId) {
    const query = `
      mutation deleteConfig(
        $configId: ID!,
      ) {
          deleteConfig(configId: $configId){
            message,
            success,
          }
        }
    `;

    const data = {
      configId,
    };

    const resp = super.get({ query, data });

    return resp;
  }

  /*
  BDF-Feeds Constants
  */

  getBlockDevices() {
    const query = `
      query getBlockDevices {
        blockDevices
      }
    `;

    const resp = super.get({ query });

    return resp;
  }

  getFeedProperties() {
    const query = `
      query getFeedProperties {
        feedProperties {
          description,
          includedProperties,
          name,
        }
      }
    `;

    const resp = super.get({ query });

    return resp;
  }
}
