// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { useState } from "react";
import { arrayOf, func, shape, string } from "prop-types";

// ** Components **********************
import { PrimaryButton, LightSecondaryButton } from "views/Components/Button";
import Error from "views/Components/Error";
import { Body, Header } from "views/Components/Typography";
import { DefaultInput } from "views/Components/Input";

// ** Styles **************************
import { Container, Row } from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Save search modal
//
//  ** Remarks
//  Allow user to name search for reference/future use
//

const SaveSearchModal = (props) => {
  const [newSearchName, setNewSearchName] = useState("");

  const doSaveSearch = () => {
    saveSearch(newSearchName);
    props.onToggleModal(null);
  };

  const onChange = (id, val) => {
    setNewSearchName(val);
  };

  const { data, onToggleModal, saveSearch } = props;
  const nameExists = props.savedQueries
    .map((i) => i.name)
    .includes(newSearchName);

  return (
    <Container>
      {nameExists && (
        <Error>{["Saved query with that name already exists"]}</Error>
      )}
      <Header margin="8px 0px 0px">
        {data.selectedQueryId ? "Update Search" : "Save Search"}
      </Header>
      <Body margin="0px 0px 32px">
        Give your search a name for quick reference and future use.
      </Body>
      <DefaultInput
        id="newSearchName"
        onChange={(id, val) => onChange(id, val)}
        placeholder="Search name..."
        value={newSearchName}
      />
      <Row>
        <LightSecondaryButton onClick={() => onToggleModal(null)}>
          Close
        </LightSecondaryButton>
        <PrimaryButton
          disabled={nameExists}
          margin="0px 0px 0px 8px"
          onClick={doSaveSearch}
        >
          {data.selectedQueryId ? "Update" : "Save"}
        </PrimaryButton>
      </Row>
    </Container>
  );
};

/*

class SaveSearchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newSearchName: '',
    };
  }

  doSaveSearch() {
    const { newSearchName } = this.state;
    const { saveError, saveSearch } = this.props;
    console.log("doSaveSearch newSearchName", newSearchName);
    console.log("doSaveSearch error pre", saveError)
    saveSearch(newSearchName)
    console.log("doSaveSearch error post", saveError)
  }

  onChange(id, val) {
    this.setState({
      [id]: val,
    });
  }

  render() {
    const {
      data,
      onToggleModal,
      savedQueries,
      saveSearch,
      saveError,
    } = this.props;
    const { newSearchName } = this.state;
    const nameExists = savedQueries.map(i => i.name).includes(newSearchName);
    console.log(saveError)

    return (
      <Container>
        {nameExists && (
          <Error>{['Saved query with that name already exists']}</Error>
        )}
        <Header margin='8px 0px 0px'>{data.selectedQueryId ? 'Update Search' : 'Save Search'}</Header>
        <Body margin='0px 0px 32px'>Give your search a name for quick reference and future use.</Body>
        <DefaultInput
          id='newSearchName'
          onChange={(id, val) => this.onChange(id, val)}
          placeholder='Search name...'
          value={newSearchName}
        />
        <Row>
          <LightSecondaryButton onClick={() => onToggleModal(null)}>Close</LightSecondaryButton>
          <PrimaryButton disabled={nameExists} margin='0px 0px 0px 8px' onClick={this.doSaveSearch}>{data.selectedQueryId ? 'Update' : 'Save'}</PrimaryButton>
        </Row>
      </Container>
    );
  }
}
*/
// ** PropTypes ***********************
SaveSearchModal.propTypes = {
  data: shape(),
  onChange: func.isRequired,
  onToggleModal: func.isRequired,
  savedQueries: arrayOf(shape()),
  saveSearch: func.isRequired,
  newSearchName: string,
};

export default SaveSearchModal;
