import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "signup",
};

export class SignUpAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  create(data) {
    const params = [];
    return super.post({ params }, data);
  }
}
