import xss from "xss";
import _ from "lodash";
import moment from "moment-timezone";

import config from "./config";
import Logger from "./logger";

const { i18n = {} } = config;
const defaultLanguage = i18n.default || "en";
const languages = i18n.languages || {};

const logger = Logger("lib/helpers");

const xssOpts = {
  whiteList: [],
  stripIgnoreTag: true,
  safe: false,
  stripIgnoreTagBody: ["script"],
};

export const isDevMode = () => process.env.NODE_ENV === "development";

export const sanitize = (str, opts = {}) => {
  const options = {
    ...xssOpts,
    ...opts,
  };

  if (opts.safe === true) {
    options.whiteList = {
      ...options.whiteList,
      ul: ["className", "class", "style"],
      li: ["className", "class", "style"],
      p: ["className", "class", "style"],
      span: ["className", "class", "style"],
      b: ["class", "style"],
      strong: ["class", "style"],
      i: ["class", "style"],
      em: ["class", "style"],
      h1: ["class", "style"],
      h2: ["class", "style"],
      h3: ["class", "style"],
      h4: ["class", "style"],
      br: ["class", "style"],
      code: ["class", "style"],
      div: ["class", "className", "style"],
      img: [
        "src",
        "style",
        "width",
        "height",
        "class",
        "className",
        "target",
        "title",
        "alt",
      ],
      a: ["href", "class", "className", "target", "title", "alt"],
      table: ["class", "style"],
      tbody: ["class", "style"],
      tr: ["class", "style"],
      td: ["class", "style"],
      pre: ["class", "style"],
      u: ["class", "style"],
    };
    delete options.safe;
  }

  const x = new xss.FilterXSS({
    css: false,
    onTagAttr: (tag, name, value) => {
      if (name === "className" || name === "class") {
        return `class='${value}'`;
      }
    },
    ...options,
  });
  const sanitized = x.process(str);

  if (options.html === false) {
    return sanitized;
  }

  const html = { __html: sanitized };
  html.__html = html.__html.replace(/className=/g, "class=");
  return html;
};

/**
 * @stub @TODO
 */
export const getLanguageDictionary = (language) => {
  if (language) {
    return languages[language] || {};
  }
  return languages[defaultLanguage] || {};
};

export const render = ({
  path,
  value,
  language,
  sanitizeOutput = true,
  html = true,
  safe = true,
  defaultValue,
}) => {
  // try specified language
  let dict = getLanguageDictionary(language) || {};
  let translation = _.get(dict, `${path}.${value}`);
  if (!translation) {
    // fallback to english
    dict = getLanguageDictionary("en") || {};
    translation = _.get(dict, `${path}.${value}`);
  }
  if (_.isUndefined(translation)) {
    if (_.isUndefined(defaultValue)) {
      translation = value;
    } else {
      translation = defaultValue;
    }
  }
  if (sanitizeOutput) translation = sanitize(translation, { html, safe });
  return translation;
};

export const getRelativeDateString = (date) => {
  const then = moment(date);
  const now = moment();
  const distance = moment.duration(now.diff(then));
  const seconds = parseFloat(distance.asSeconds()).toFixed(0);
  const minutes = parseFloat(distance.asMinutes()).toFixed(0);
  const hours = parseFloat(distance.asHours()).toFixed(0);
  const days = parseFloat(distance.asDays()).toFixed(0);
  const months = parseFloat(distance.asMonths()).toFixed(0);
  const years = parseFloat(distance.asYears()).toFixed(0);

  if (seconds <= 60) {
    return `${seconds} seconds ago`;
  }

  if (minutes <= 60) {
    return `${minutes} minutes ago`;
  }

  if (hours <= 24) {
    return `${hours} hours ago`;
  }

  if (days <= 31) {
    return `${days} days ago`;
  }

  if (months <= 12) {
    return `${months} months ago`;
  }

  return `${years} years ago`;
};

export const getAbsoluteDateString = (date) => {
  const then = moment(date);
  return then.toISOString();
};

// Given an error that comes from the API or from an exception, this function
// turns it into an object with a predictable structure.
// This is important because
// it lets Components accept and manipulate errors in a predicatable way without
// worry about different structures or modes (e.g. dev vs production).
//
// @TODO: I would consider making an ErrorMessage class for a more OOP approach.
//
// Output: {
//   instanceId: this will be a unique string identifier
//   type: the type of error. Currently 'client' if thrown from in the
//         browser and 'unknown' otherwise
//   message: A string message with the broad strokes of the error
//   detail: A string with more details about the error
//   stack: If thrown from within the client, this will be a string
//          representation of the stack
// }
//
// Options:
//    prependMessage: Allows you to put a string in front of the message.
//                    E.g.: 'Your operation failed: '
//                          ->
//                          'Your operation failed: the error message'
//
export const normalizeError = (error, opts = {}) => {
  logger.error("Error formatter received error for processing", error);

  const { prependMessage } = opts;
  const errorStructure = {
    instanceId: _.uniqueId(),
    type: "unknown",
    message: "Internal error",
    detail: null,
    stack: "",
  };

  if (_.isString(error)) {
    errorStructure.message = error;
  }

  if (_.has(error, "message")) {
    errorStructure.message = error.message;
  }

  if (_.has(error, "detail")) {
    errorStructure.detail = error.detail;
  }

  if (prependMessage) {
    errorStructure.message = `${prependMessage} ${errorStructure.message}`;
  }

  // This will happen with a raw exception
  if (_.has(error, "stack") && !_.isEmpty(error.stack)) {
    if (isDevMode()) {
      // In development, we want to see the message
      errorStructure.message = `Dev Error: "${error.message}"`;
    } else {
      errorStructure.message = "Internal error";
    }

    errorStructure.type = "client";
    errorStructure.stack = String(error.stack);
  }

  return errorStructure;
};

/*
Helper function to test browser local or session storage availability.
Pulled from
https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
but the check if local storage is full is omitted,
since we never clean up the storage.
*/
export const storageAvailable = (type) => {
  try {
    const storage = window[type];
    const x = "__storage_test__";

    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (err) {
    logger.error("Local Storage is not available", err);

    return false;
  }
};

/*
Comparator for sorting array of objects by the name attribute
*/
export const sortByName = (a, b) => {
  const nameA = a.name;
  const nameB = b.name;
  if (!nameA.toLowerCase && !nameB.toLowerCase) {
    return 0;
  }
  if (!nameB.toLowerCase) {
    return -1;
  }
  if (!nameA.toLowerCase) {
    return 1;
  }
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeWords = (string) =>
  string
    .split(" ")
    .map((substring) => capitalize(substring))
    .join(" ");

export const offsetHours = () =>
  moment().tz(moment.tz.guess(true)).utcOffset() / 60;

export const timeOfDayToUtc = (hour) => {
  const offset = offsetHours();

  const utcHour = (hour + 24 - offset) % 24;

  return utcHour;
};
