import React, { useEffect, useState } from "react";
import { CustomMappingList, ArrowElement, StyledDialogTitle } from "./styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomMappingDialog = ({
  open,
  onClose,
  mappings,
  syncingType,
  psaMappingOptions,
  psaMappingOptionsError,
}) => {
  const [modalMappings, setModalMappings] = useState([]);

  useEffect(() => {
    // if there is no blumiraMappingName they don't have an existing mapping
    if (open && !mappings[0][syncingType.blumiraMappingName]) {
      buildNewMapping();
    } else {
      setModalMappings(mappings);
    }
  }, [open]);

  const defaultMapping = syncingType.blumiraMappingList.map((item) => ({
    [syncingType.psaMappingName]: psaMappingOptions[0].id,
    [syncingType.blumiraMappingName]: item.label,
  }));

  const cancelModal = () => {
    onClose(
      !mappings[0][syncingType.blumiraMappingName] ? defaultMapping : mappings
    );
  };

  const handleClose = () => {
    onClose(modalMappings);
  };

  const buildNewMapping = () => {
    setModalMappings(defaultMapping);
  };

  const handleMappingChange = ({ target }, i) => {
    setModalMappings((prevState) =>
      prevState.map((item, k) => {
        if (i === k) {
          return {
            ...item,
            [syncingType.psaMappingName]: target.value,
          };
        }
        return item;
      })
    );
  };

  return (
    <Dialog open={open} onClose={cancelModal} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle>
        Custom {syncingType.name} Mapping
        <IconButton onClick={cancelModal}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <DialogContent>
        <CustomMappingList>
          {!!modalMappings &&
            modalMappings.map((mapping, i) => (
              <tr key={`mappingList${i}`}>
                <td>
                  <Typography>
                    {syncingType.name === "Priority" && "Priority "}
                    {mapping[syncingType.blumiraMappingName]}
                  </Typography>
                </td>
                <td>
                  <ArrowElement />
                </td>
                <td>
                  <FormControl sx={{ width: 200 }}>
                    <Select
                      aria-label={`Set ${syncingType.name}`}
                      error={psaMappingOptionsError.length > 0}
                      value={mapping[syncingType.psaMappingName]}
                      onChange={(e) => handleMappingChange(e, i)}
                    >
                      <MenuItem value=""></MenuItem>
                      {psaMappingOptions.map((mappingOption) => (
                        <MenuItem
                          key={`${syncingType.name}-${mappingOption.id}`}
                          value={mappingOption.id}
                        >
                          {mappingOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{psaMappingOptionsError}</FormHelperText>
                  </FormControl>
                </td>
              </tr>
            ))}
        </CustomMappingList>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button onClick={handleClose} variant={"contained"}>
          Done
        </Button>
        <Button onClick={cancelModal} variant={"outlined"}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomMappingDialog;
