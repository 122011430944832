import { createSelector } from "reselect";

const selectSettings = (state) => state.settings;
const selectUsers = (state) => state.users;

const getCurrentUser = (settings, users) => {
  const { currentUserId = "" } = settings;
  const { byId = {} } = users;
  const user = byId[currentUserId];

  return user;
};

export const loggedInUserSelector = createSelector(
  [selectSettings, selectUsers],
  getCurrentUser
);
