import { createSelector } from "reselect";

import { orgIdSelector } from "./orgIdSelector";
import { getRows } from "../views/Pages/Support/MetricsTable";

const pipelineSelector = (state) => state.pipeline;

/* Org's active job metrics */

const getActiveJobMetrics = (orgId = "", pipelineStore = {}) => {
  const { activeJobMetricsByOrg = {} } = pipelineStore;

  const activeJobMetrics = activeJobMetricsByOrg[orgId] || {};

  return activeJobMetrics;
};

export const activeJobMetricsSelector = createSelector(
  [orgIdSelector, pipelineSelector],
  getActiveJobMetrics
);

/* Org's active job metrics error message */

const getActiveJobMetricsErr = (activeJobMetrics = {}) => {
  const { errorMsg = "" } = activeJobMetrics;

  return errorMsg;
};

export const activeJobMetricsErrSelector = createSelector(
  [activeJobMetricsSelector],
  getActiveJobMetricsErr
);

/* Org's active job metrics table rows */

const getActiveJobMetricsRows = (activeJobMetrics = {}) => {
  const { data = {} } = activeJobMetrics;
  const rows = getRows(data);

  return rows;
};

export const activeJobMetricsRowsSelector = createSelector(
  [activeJobMetricsSelector],
  getActiveJobMetricsRows
);

/* Metrics last received */

const getMetricsLastReceived = (orgId = "", pipelineStore = {}) => {
  const { metricsLastReceivedByOrg = {} } = pipelineStore;

  const metricsLastReceived = metricsLastReceivedByOrg[orgId] || "";

  return metricsLastReceived;
};

export const metricsLastReceivedSelector = createSelector(
  [orgIdSelector, pipelineSelector],
  getMetricsLastReceived
);

/* Org's primary pipeline metrics */

const getPipelineMetrics = (orgId = "", pipelineStore = {}) => {
  const { pipelineMetricsByOrg = {} } = pipelineStore;

  const pipelineMetrics = pipelineMetricsByOrg[orgId] || {};

  return pipelineMetrics;
};

export const pipelineMetricsSelector = createSelector(
  [orgIdSelector, pipelineSelector],
  getPipelineMetrics
);

/* Org's primary pipeline metrics table rows */

const getPipelineMetricsRows = (pipelineMetrics) => {
  const { pipelineData = {} } = pipelineMetrics;
  const rows = getRows(pipelineData);

  return rows;
};

export const pipelineMetricsRowSelector = createSelector(
  [pipelineMetricsSelector],
  getPipelineMetricsRows
);

// Request error selector
const getRequesErrMsg = (orgId, pipeline) => {
  const { requestErrorByOrg = {} } = pipeline;
  const requestError = requestErrorByOrg[orgId] || {};
  const { message: requestErrorMessage = "" } = requestError;

  return requestErrorMessage;
};

export const requestErrorMessageSelector = createSelector(
  [orgIdSelector, pipelineSelector],
  getRequesErrMsg
);

/* Org's primary pipeline storage data */

const getStorageData = (pipelineMetrics) => {
  const { storageData = {} } = pipelineMetrics;

  return storageData;
};

export const pipelineStorageDataSelector = createSelector(
  [pipelineMetricsSelector],
  getStorageData
);

/* Org's primary pipeline */

const getPrimaryPipeline = (orgId = "", pipelineStore = {}) => {
  const { byId = {}, primaryByOrg = {} } = pipelineStore;

  const pipelineId = primaryByOrg[orgId];
  const primaryPipeline = byId[pipelineId] || {};

  return primaryPipeline;
};

export const primaryPipelineSelector = createSelector(
  [orgIdSelector, pipelineSelector],
  getPrimaryPipeline
);

/* Org's primary pipeline restart */

const getRestartingPipeline = (orgId = "", pipelineStore = {}) => {
  const { restartingByOrg } = pipelineStore;

  const restarting = restartingByOrg[orgId] || false;

  return restarting;
};

export const restartingPipelineSelector = createSelector(
  [orgIdSelector, pipelineSelector],
  getRestartingPipeline
);
