const mspBillingPlanTemplateSchema = {
  $id: "/mspbillingplantemplate",
  title: "MSPBillingPlanTemplateSchema",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    tier: { type: "number" },
    name: { type: "string" },
    minCommit: { type: "number" },
    createdBy: { type: "string", format: "uuid", readOnly: true },
  },
  required: [],
  additionalProperties: true,
};

export default mspBillingPlanTemplateSchema;
