const ID_OPERATORS = ["eq", "!eq", "in", "!in"];

const psaSimulateFindingSchema = {
  $id: "/psaintegration/:id/test",
  title: "PSASimulateFinding",
  type: "object",
  properties: {
    orgId: {
      type: "string",
      format: "uuid",
      validOperators: ID_OPERATORS,
    },
    type: { type: "string" },
    priority: { type: "string" },
  },
  required: [],
  additionalProperties: true,
};

export default psaSimulateFindingSchema;
