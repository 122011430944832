import { combineReducers } from "redux";

import {
  REQUEST_USERS,
  RECEIVE_USER,
  RECEIVE_ORG_USERS,
  RESET_USERS,
} from "./actions/Users";
import { RECEIVE_SETTINGS } from "./actions/Settings";
import { entriesFromList as usersFromList } from "./utils/helpers";

/*
Initial state:
  byId is an object of the form { userId1: user1, userId2: user2, ...}
  byOrg is an object of the form { orgId1: [userId1, userid2,...], orgId2: [...], ...}
*/
const initialState = {
  byId: {},
  byOrg: {},
};

/*
By ID
*/

const initialById = initialState.byId;

// Helper for inserting one user.
// The user object is inserted without cloning.
const insertOneUser = ({ state = initialById, payload }) => {
  const { user } = payload;
  const userId = user.id;

  const nextState = { ...state };
  nextState[userId] = user;

  return nextState;
};

// If userId is not in byId keys, inserts action.user into byId.
// If userId is in byId keys, completely replaces the existing entry for userId.
const receiveOneUser = (state = initialById, action) =>
  insertOneUser({ state, payload: action.payload });

const receiveSettingsUser = (state = initialById, action) =>
  insertOneUser({ state, payload: action.settings });

// Sets byId to consist of action.users, completely replacing existing byId data
const addUsersForOrg = (state = initialById, action) => {
  const { payload } = action;
  const { users } = payload;

  const orgUsers = usersFromList(users);

  let nextState = { ...state };
  nextState = { ...nextState, ...orgUsers };

  return nextState;
};

// Resets byID store
const resetUserEntries = () => initialById;

// byId reducer
const usersById = (state = initialById, action) => {
  switch (action.type) {
    case RECEIVE_SETTINGS: {
      return receiveSettingsUser(state, action);
    }
    case RECEIVE_USER: {
      return receiveOneUser(state, action);
    }
    case RECEIVE_ORG_USERS: {
      return addUsersForOrg(state, action);
    }
    case RESET_USERS: {
      return resetUserEntries();
    }
    default:
      return state;
  }
};

/*
By Org
*/

const initialByOrg = initialState.byOrg;

// Sets byOrg[orgId] to consist of action.users, completely replacing existing byOrg[orgId] data.
const setUserListForOrg = (state = initialByOrg, action) => {
  const { payload } = action;
  const { users, orgId } = payload;

  const orgUserIds = users.map((user) => user.id);

  const nextState = { ...state, isFetching: false };
  nextState[orgId] = orgUserIds;

  return nextState;
};

const requestUsers = (state) => {
  return {
    ...state,
    isFetching: true,
  };
};

// Resets the byOrg store
const resetUserListForOrg = () => initialByOrg;

// byOrg reducer
const usersByOrg = (state = initialByOrg, action) => {
  switch (action.type) {
    case REQUEST_USERS: {
      return requestUsers(state);
    }
    case RECEIVE_ORG_USERS: {
      return setUserListForOrg(state, action);
    }
    case RESET_USERS: {
      return resetUserListForOrg();
    }
    default:
      return state;
  }
};

/*
Users Reducer
*/

const UserReducer = combineReducers({
  byId: usersById,
  byOrg: usersByOrg,
});

export default UserReducer;
