import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "demo",
};

export class DemoAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  resetDemo({ orgId, data = {} }) {
    const params = [orgId];

    return super.post({ params }, data);
  }
}
