import { API, setAxiosOrgHeader } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "users",
};

export class UsersAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  list(orgId) {
    const params = [orgId];
    setAxiosOrgHeader(orgId);
    return super.get({ params });
  }

  update(orgId, personId, data) {
    const params = [orgId, personId];
    return super.put({ params }, data);
  }

  create(orgId, data) {
    const params = [orgId];
    return super.post({ params }, data);
  }

  delete(orgId, personId) {
    const params = [orgId, personId];
    return super.delete({ params });
  }

  addUserToOrg(orgId, email, roles) {
    const params = [orgId];
    const data = { roles, email };
    const cAPI = new API({ baseUrl: "users/add_to_org" });
    return cAPI.post({ params }, data);
  }

  resetMFA(personId) {
    const params = [personId, "reset_mfa"];
    return super.post({ params });
  }
}
