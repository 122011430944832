import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import SVGContainer from "./SVGContainer";

const colors = {
  0: ["#602b5c", "#2c3e50", "#0f6291"],
  1: ["#c0392b", "#e67e22", "#f1c40f"],
};

const color = {
  red: "#e74c3c",
  green: "#27ae60",
};

const icons = {
  up: "\uf0d8",
  down: "\uf0d7",
};

class StatColumn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data = [], colorPallete = 0 } = this.props;
    return (
      <div className="chart">
        <SVGContainer className="stat-column" viewBox="0 0 100 120">
          <g>
            {_.map(data, (d, i) => (
              <g key={i} transform={`translate(0, ${i * 40 + 18})`}>
                {d.label ? (
                  <text
                    x="2"
                    style={{
                      fontSize: "10px",
                      fill: colors[colorPallete][i],
                      fontWeight: 500,
                    }}
                  >
                    {d.label}
                  </text>
                ) : null}
                <text
                  x="40"
                  y="5"
                  textAnchor="middle"
                  style={{
                    fontSize: "24px",
                    fill: "#333",
                  }}
                >
                  {d.value}
                </text>
                {d.direction ? (
                  <text
                    x="80"
                    y="5"
                    className="fas fa icon"
                    textAnchor="end"
                    style={{
                      fontSize: "20px",
                    }}
                    fill={color[d.color]}
                  >
                    {icons[d.direction]}
                  </text>
                ) : null}
                {d.percent ? (
                  <text
                    x="80"
                    y="0"
                    textAnchor="end"
                    style={{
                      fontSize: "10px",
                      fill: "#333",
                    }}
                  >
                    {d.percent}
                  </text>
                ) : null}
              </g>
            ))}
          </g>
        </SVGContainer>
      </div>
    );
  }
}

StatColumn.propTypes = {
  data: PropTypes.instanceOf(Array),
  colorPallete: PropTypes.number,
};

StatColumn.defaultProps = {
  data: [],
  colorPallete: 0,
};

export default StatColumn;
