import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import Codex from "@blumira/blu_constants";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PendingIcon from "@mui/icons-material/Pending";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Tab, Tabs } from "@mui/material";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import API from "lib/api/API2";
import Request from "lib/api/Request";
import { LoadStatus, useLoader } from "lib/util/LoaderHook";
import {
  getAnalysis,
  getPriorityIconColor,
  getRuleDisplayInfo,
  getStatusInfo,
} from "lib/util/RulesUtils";
import ActionDialog from "views/Components/ActionDialog";
import LoadingTable from "views/Components/LoadingTable";
import SimpleTable from "views/Components/SimpleTable";
import { StyledDialog, classes } from "./DetailsDialogStyles";
import { ConditionOverride } from "lib/models/ConditionOverride";
import { actionFailure } from "redux/actions/Page";

export const STATUS_ENABLED = 1;
export const STATUS_DISABLED = 5;
export const STATUS_PENDING_DISABLE = 50;
export const STATUS_PENDING_ENABLE = 101;
export const DEPLOYED_STATUSES = [
  STATUS_ENABLED,
  STATUS_DISABLED,
  STATUS_PENDING_DISABLE,
  STATUS_PENDING_ENABLE,
];
export const ENABLED_STATUSES = [STATUS_ENABLED, STATUS_PENDING_ENABLE];

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface RuleDetailsTabProps {
//   tableRowModel: TableRowModel;
//   actionsLoadStatus: LoadStatus;
//   findingTypesLoadStatus: LoadStatus;
//   orgId: string;
//   refreshConditionOverrides: () => Promise<object>;
//   displayMessage: (props: AlertMessageProps) => void;
// }

/**
 * Details tab within the Rule Details dialog
 */
const RuleDetailsTab = (props) => {
  const typeConstantsLoadStatus = useLoader(
    async () => {
      await Codex.loading;
      return Codex.language.models.bq.type;
    },
    "DetailsDialog-typeConstants",
    []
  );
  const dispatch = useDispatch();

  const { refreshConditionOverrides, displayMessage } = props;
  const {
    rule,
    condition,
    blumiraDefaultDisabled,
    override,
    isDefaultDisabled,
  } = props.tableRowModel || {};

  // null == blumira default, false = enabled, true = disabled
  const [formCustomStatus, setFormCustomStatus] = useState(!!override);
  const [formDefaultDisabled, setFormDefaultDisabled] =
    useState(isDefaultDisabled);
  // processingStatus tracks status of saving default state
  const [processingStatus, setProcessingStatus] = useState({
    processing: false,
  });

  // Handle click on enabled or disabled radio button
  const handleCustomChange = useCallback((event) => {
    setFormCustomStatus(event.target.value === "custom");
  }, []);
  const handleDefaultChange = useCallback((event) => {
    setFormDefaultDisabled(event.target.value === "disabled");
  }, []);
  const handleRevert = useCallback(
    (event) => {
      setFormCustomStatus(!!override);
      setFormDefaultDisabled(isDefaultDisabled);
    },
    [override, isDefaultDisabled]
  );
  const condition_id = condition?.id;
  const handleSave = useCallback(
    async (event) => {
      setProcessingStatus({ processing: true });
      try {
        if (!formCustomStatus) {
          await override.delete();
        } else if (override) {
          override.set({
            defaultDeployStatus: formDefaultDisabled
              ? STATUS_PENDING_DISABLE
              : STATUS_PENDING_ENABLE,
          });
          await override.update();
        } else {
          const newOverride = new ConditionOverride({
            conditionId: condition_id,
            orgId: props.orgId,
            defaultDeployStatus: formDefaultDisabled
              ? STATUS_PENDING_DISABLE
              : STATUS_PENDING_ENABLE,
          });
          await newOverride.create();
        }
        await refreshConditionOverrides();
        setProcessingStatus({ processing: false });
        displayMessage({
          severity: "success",
          message: "Saved rule settings",
          autoHideDuration: 10000,
        });
      } catch (error) {
        // Use redux to display an error banner
        dispatch(actionFailure(error));
        // Also display an error icon in the button
        setProcessingStatus({ processing: false, error });
        displayMessage({
          severity: "error",
          message: "Failed to save rule settings",
          autoHideDuration: 10000,
        });
      }
    },
    [
      formCustomStatus,
      override,
      condition_id,
      props.orgId,
      formDefaultDisabled,
      refreshConditionOverrides,
      dispatch,
    ]
  );

  if (
    props.actionsLoadStatus.error ||
    props.findingTypesLoadStatus.error ||
    typeConstantsLoadStatus.error
  ) {
    return <div>Error loading rule details</div>;
  }
  if (
    props.actionsLoadStatus.loading ||
    props.findingTypesLoadStatus.loading ||
    typeConstantsLoadStatus.loading
  ) {
    return <LoadingTable />;
  }
  const actions = props.actionsLoadStatus.data;
  const findingTypes = props.findingTypesLoadStatus.data;
  const typeConstants = typeConstantsLoadStatus.data;
  const displayInfo = getRuleDisplayInfo({
    rule,
    conditions: condition ? [condition] : [],
    allowlistEntries: [],
    actions,
    findingTypes,
    typeConstants,
  });

  const getPriority = (column, model) => {
    const priority = displayInfo.priority;
    const color = getPriorityIconColor(displayInfo);
    return (
      <span className={classes.priorityContainer}>
        <div
          className={classes.priorityCircle}
          style={{ backgroundColor: color }}
        />
        P{priority}
      </span>
    );
  };

  const disableActionButtons =
    processingStatus.processing ||
    (formCustomStatus === !!override &&
      (!override || formDefaultDisabled === isDefaultDisabled));

  return (
    <>
      <TableContainer className={classes.detailsTable}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{rule?.name}</TableCell>
            </TableRow>
            {displayInfo.dataType !== "" && (
              <TableRow>
                <TableCell>Data type</TableCell>
                <TableCell>{displayInfo.dataType}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>{displayInfo.category}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Priority</TableCell>
              <TableCell>{getPriority("priority", rule)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Analysis summary</TableCell>
              <TableCell
                dangerouslySetInnerHTML={{
                  __html: condition && getAnalysis("summary", condition, true),
                }}
              />
            </TableRow>
            <TableRow>
              <TableCell>Automated host isolation</TableCell>
              <TableCell>
                {displayInfo.isAHISupported ? "Supported" : "Not Supported"}
                <Link
                  href="https://blumira.help/auto-isolation"
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  style={{ marginLeft: 10 }}
                >
                  Learn more <OpenInNewIcon />
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Current state</TableCell>
              <TableCell>
                {props.tableRowModel &&
                  `Supported Accounts: Enabled: ${
                    props.tableRowModel.enabled
                  }, Disabled: ${
                    props.tableRowModel.supported - props.tableRowModel.enabled
                  }`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Default state</TableCell>
              <TableCell>
                <div>
                  Modifying the default state will not impact accounts where the
                  rule has already been implemented.
                </div>
                <RadioGroup
                  value={formCustomStatus ? "custom" : "default"}
                  onChange={handleCustomChange}
                  name="custom-state"
                  className={classes.radioGroup}
                >
                  <div>
                    <FormControlLabel
                      value="default"
                      control={<Radio />}
                      label={`Blumira default (${
                        blumiraDefaultDisabled ? "Disabled" : "Enabled"
                      })`}
                    />
                    <Tooltip title={getStatusInfo(blumiraDefaultDisabled)}>
                      <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                  </div>
                  <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label="Custom"
                  />
                </RadioGroup>
                <div
                  className={`${classes.radioSubgroup} ${
                    formCustomStatus ? "" : classes.radioSubgroupInactive
                  }`}
                >
                  <RadioGroup
                    value={formDefaultDisabled ? "disabled" : "enabled"}
                    onChange={handleDefaultChange}
                    name="default-state"
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      value="enabled"
                      control={<Radio />}
                      label="Enabled"
                      disabled={!formCustomStatus}
                    />
                    <FormControlLabel
                      value="disabled"
                      control={<Radio />}
                      label="Disabled"
                      disabled={!formCustomStatus}
                    />
                  </RadioGroup>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions>
        <Button
          variant="text"
          onClick={handleRevert}
          disabled={disableActionButtons}
          color="primary"
        >
          Revert
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          disabled={disableActionButtons}
          color="primary"
          endIcon={
            processingStatus.processing ? (
              <PendingIcon />
            ) : processingStatus.error ? (
              <ErrorIcon />
            ) : undefined
          }
          loading={processingStatus.processing}
          loadingPosition={processingStatus.processing ? "end" : undefined}
        >
          Save changes
        </LoadingButton>
      </DialogActions>
    </>
  );
};

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface ConfirmationDialogProps {
//   open: boolean;
//   desiredStatus?: 50 | 101;
//   numSelected?: number;
//   onCancel?: () => void;
//   onOk?: () => void;
// }

const ConfirmationDialog = (props) => {
  return (
    <ActionDialog
      open={props.open}
      title="Bulk Action"
      description={
        <span>
          {`The rule will be ${
            props.desiredStatus === STATUS_PENDING_DISABLE
              ? "disabled"
              : "enabled"
          } in ${props.numSelected} selected ${
            props.numSelected === 1 ? "account" : "accounts"
          }.`}
        </span>
      }
      actions={[
        {
          title:
            props.desiredStatus === STATUS_PENDING_DISABLE
              ? "Disable"
              : "Enable",
          action: props.onOk,
          variant: "contained",
          loading: props.processing,
          error: props.error,
        },
        {
          title: "Cancel",
          action: props.onCancel,
        },
      ]}
    />
  );
};

const supportedAccountColumns = [
  {
    title: "Account name",
    field: "name",
    searchable: true,
  },
  {
    title: "Findings past 24 hours",
    field: "findingsPast24Hours",
    searchable: false,
  },
  {
    title: "Current state",
    field: "status",
    searchable: true,
  },
  {
    title: "Filters",
    field: "filterCount",
    searchable: false,
  },
];

const unsupportedAccountColumns = [
  {
    title: "Account name",
    field: "name",
    searchable: true,
  },
  {
    title: "Reason unsupported",
    field: "reason",
    searchable: true,
  },
];

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface RuleDetailsTabProps {
//   tableRowModel: TableRowModel;
//   orgs: Org[];
//   orgIds: string[];
//   showUnsupported?: boolean;
//   refreshRuleStatuses: () => Promise<object>;
//   setDetailsDialogProps: (newProps: DetailsDialogProps) => void;
//   displayMessage: (props: AlertMessageProps) => void;
// }

const RuleAccountsTab = (props) => {
  const { refreshRuleStatuses, setDetailsDialogProps, displayMessage } = props;
  const { rule, condition } = props.tableRowModel;

  // confirmationProps tracks properties of the "Are You Sure" dialog
  const [confirmationProps, setConfirmationProps] = useState({
    open: false,
  });

  // A place to memoize parts of the data being reloaded.
  // The idea is: if the first reloaded query (deployed rules) doesn't
  // change the list of rules, the other reloads can just use the memoized
  // data, avoiding more reloading.
  const loadingMemo = useRef({});

  const accountInfoLoadStatus = useLoader(
    async () => {
      // Load deployed rules
      const queryParams = [
        { field: "orgId", operator: "in", value: props.orgIds },
        { field: "originatingRule", value: rule.id },
        { field: "status", operator: "in", value: DEPLOYED_STATUSES },
      ];
      const resultParams = { outputFields: ["id", "orgId", "status"] };
      const request = new Request("/rule", queryParams, resultParams);
      const deployedRules = await request.get();

      const ruleIds = deployedRules.map((rule) => rule.id);
      ruleIds.sort();
      const ruleIdsString = ruleIds.join();

      let filterCounts;
      let findingCounts;
      if (ruleIdsString === loadingMemo.current.ruleIdsString) {
        // Rule list didn't change.  No need to reload the counts
        filterCounts = loadingMemo.current.filterCounts;
        findingCounts = loadingMemo.current.findingCounts;
      } else {
        const loadFilterCounts = () => {
          const queryParams = [
            { field: "orgId", operator: "in", value: props.orgIds },
            { field: "ruleId", operator: "in", value: ruleIds },
          ];
          const resultParams = {
            outputFields: ["orgId"],
            transform: [
              {
                operator: "count",
                value: "count",
                field: "",
                negate: false,
              },
            ],
          };
          const request = new Request(
            "/allowlistentry",
            queryParams,
            resultParams
          );
          return request.get();
        };

        const loadFindingCounts = () => {
          const findingsSince = moment().subtract(24, "hours").utc().format();
          const queryParams = [
            { field: "orgId", operator: "in", value: props.orgIds },
            { field: "basisRuleId", value: rule.id },
            { field: "findingId", negate: true, value: null },
            { field: "created", operator: "gt", value: findingsSince },
          ];
          const resultParams = {
            outputFields: ["orgId"],
            transform: [
              {
                operator: "count",
                value: "count",
                field: "",
                negate: false,
              },
            ],
          };
          const request = new Request("/match", queryParams, resultParams);
          return request.get();
        };

        const concurrentTasks = [loadFilterCounts(), loadFindingCounts()];
        await Promise.allSettled(concurrentTasks);
        [filterCounts, findingCounts] = await Promise.all(concurrentTasks);

        loadingMemo.current.filterCounts = filterCounts;
        loadingMemo.current.findingCounts = findingCounts;
        loadingMemo.current.ruleIdsString = ruleIdsString;
      }
      return { deployedRules, filterCounts, findingCounts };
    },
    "DetailsDialog-accountInfo",
    [props.orgIds, rule.id]
  );

  const accountsLoadStatus = useMemo(() => {
    // Compute lists of accounts
    const accountInfo = accountInfoLoadStatus.data;
    if (accountInfo === undefined) {
      // Still loading, or got error.  Set accounts to the same status.
      return accountInfoLoadStatus;
    }

    const { deployedRules, filterCounts, findingCounts } = accountInfo;
    const rulesByOrgId = {};
    deployedRules.forEach((rule) => {
      rulesByOrgId[rule.orgId] = rule;
    });
    const filterCountsByOrgId = {};
    filterCounts.forEach((filter) => {
      filterCountsByOrgId[filter.orgId] = filter.count;
    });
    const findingCountsByOrgId = {};
    findingCounts.forEach((finding) => {
      findingCountsByOrgId[finding.orgId] = finding.count;
    });
    const isWindowed = condition.method !== 500;
    const isCustom = condition.tags.some(
      (tag) =>
        tag.substring(0, 4) === "org:" || tag.substring(0, 8) === "org_msp:"
    );

    const supportedAccounts = [];
    const unsupportedAccounts = [];
    props.orgs.forEach((org) => {
      if (org.id in rulesByOrgId) {
        const isEnabled = ENABLED_STATUSES.includes(
          rulesByOrgId[org.id].status
        );
        supportedAccounts.push({
          id: org.id,
          name: org.name,
          isEnabled,
          status: isEnabled ? "Enabled" : "Disabled",
          filterCount: filterCountsByOrgId[org.id] || 0,
          findingsPast24Hours: findingCountsByOrgId[org.id] || 0,
        });
      } else {
        const isFree = org.config.license === "FREE";
        unsupportedAccounts.push({
          id: org.id,
          name: org.name,
          reason:
            isWindowed && isFree
              ? "Windowed detections are not available to free accounts."
              : isCustom && isFree
              ? "Custom detections are not available to free accounts."
              : "Account is not sending the type of logs required for this detection.",
        });
      }
    });
    return new LoadStatus({
      data: { supportedAccounts, unsupportedAccounts },
    });
  }, [accountInfoLoadStatus, props.orgs]);

  const dispatch = useDispatch();

  const showConfirmation = useCallback(
    ({ desiredStatus, numSelected, onOk, error }) => {
      setConfirmationProps({
        open: true,
        processing: false,
        desiredStatus,
        numSelected,
        error,
        onCancel: () =>
          setConfirmationProps((prevState) => ({
            // Even though we're closing the dialog, we need to preserve
            // the previous state so that the closing animation is smooth.
            ...prevState,
            open: false,
          })),
        onOk: async () => {
          setConfirmationProps((prevState) => ({
            ...prevState,
            processing: true,
          }));
          try {
            await onOk();
            setConfirmationProps((prevState) => ({
              // Even though we're closing the dialog, we need to preserve
              // the previous state so that the closing animation is smooth.
              ...prevState,
              open: false,
            }));
            displayMessage({
              severity: "success",
              message: `The rule was ${
                desiredStatus === STATUS_PENDING_DISABLE
                  ? "disabled"
                  : "enabled"
              } for ${numSelected} ${
                numSelected === 1 ? "account" : "accounts"
              }.`,
              autoHideDuration: 10000,
            });
          } catch (error2) {
            dispatch(actionFailure(error2));
            setConfirmationProps((prevState) => ({
              ...prevState,
              processing: false,
              error: error2,
            }));
            displayMessage({
              severity: "error",
              message: `Failed to ${
                desiredStatus === STATUS_PENDING_DISABLE ? "disable" : "enable"
              } rules`,
              autoHideDuration: 10000,
            });
          }
        },
      });
    },
    [dispatch, displayMessage]
  );

  // When this is called, the accountInfo.deployedRules will be reloaded
  const refreshDeployedRules = useCallback(() => {
    return accountInfoLoadStatus.reload?.({ reset: false });
  }, [accountInfoLoadStatus]);

  const handleMultiaccountChange = useCallback(
    async (orgIds, desiredStatus) => {
      showConfirmation({
        desiredStatus,
        numSelected: orgIds.length,
        onOk: async () => {
          const params = {
            orgIds,
            basisRuleIds: [rule.id],
            desiredStatus,
          };
          const api = new API();
          await api.rpc("/updateRuleStatuses", params);
          const concurrentTasks = [
            refreshRuleStatuses(),
            refreshDeployedRules(),
          ];
          await Promise.allSettled(concurrentTasks); // Wait until all are done
          await Promise.all(concurrentTasks); // Throw if there was an error
        },
      });
    },
    [showConfirmation, rule.id, refreshRuleStatuses, refreshDeployedRules]
  );

  const renderMultiselectActions = useCallback(
    (selectedIds, allData) => {
      const dataByOrgId = {};
      allData.forEach((model) => {
        dataByOrgId[model.id] = model._model;
      });
      const idsCanEnable = selectedIds.filter(
        (id) => dataByOrgId[id] && !dataByOrgId[id].isEnabled
      );
      const idsCanDisable = selectedIds.filter(
        (id) => dataByOrgId[id] && dataByOrgId[id].isEnabled
      );
      const handleEnable = () => {
        handleMultiaccountChange(idsCanEnable, STATUS_PENDING_ENABLE);
      };
      const handleDisable = () => {
        handleMultiaccountChange(idsCanDisable, STATUS_PENDING_DISABLE);
      };
      return (
        <>
          <Tooltip
            title={idsCanEnable.length === 0 && "The rule is already enabled"}
          >
            <span>
              <Button
                className="SimpleTable-toolbarActionButton"
                onClick={handleEnable}
                disabled={idsCanEnable.length === 0}
              >
                Enable
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={idsCanDisable.length === 0 && "The rule is already disabled"}
          >
            <span>
              <Button
                className="SimpleTable-toolbarActionButton"
                onClick={handleDisable}
                disabled={idsCanDisable.length === 0}
              >
                Disable
              </Button>
            </span>
          </Tooltip>
        </>
      );
    },
    [handleMultiaccountChange]
  );

  const handleSupportedChange = useCallback(
    (event, value) => {
      if (value) {
        setDetailsDialogProps((prevState) => ({
          ...prevState,
          showUnsupported: value === "unsupported",
        }));
      }
    },
    [setDetailsDialogProps]
  );

  return (
    <>
      <ConfirmationDialog {...confirmationProps} />
      <div data-testid="accounts-table">
        <SimpleTable
          isNorthStar
          data={
            props.showUnsupported
              ? accountsLoadStatus.data?.unsupportedAccounts
              : accountsLoadStatus.data?.supportedAccounts
          }
          columns={
            props.showUnsupported
              ? unsupportedAccountColumns
              : supportedAccountColumns
          }
          isFetching={accountsLoadStatus.loading}
          emptyText={
            accountsLoadStatus.error ? (
              <span>Failed to load accounts</span>
            ) : (
              <span>{`No ${
                props.showUnsupported ? "unsupported" : "supported"
              } accounts available`}</span>
            )
          }
          renderMultiselectActions={
            props.showUnsupported ? undefined : renderMultiselectActions
          }
          toolbarActions={[
            {
              isPrimary: true,
              component: (
                <ToggleButtonGroup
                  color="primary"
                  value={props.showUnsupported ? "unsupported" : "supported"}
                  exclusive
                  onChange={handleSupportedChange}
                  className={classes.toggleGroup}
                >
                  <ToggleButton value="supported">
                    {"Supported "}
                    <b>
                      {accountsLoadStatus.data
                        ? accountsLoadStatus.data.supportedAccounts.length
                        : ""}
                    </b>
                  </ToggleButton>
                  <ToggleButton value="unsupported">
                    {"Unsupported "}
                    <b>
                      {accountsLoadStatus.data
                        ? accountsLoadStatus.data.unsupportedAccounts.length
                        : ""}
                    </b>
                  </ToggleButton>
                </ToggleButtonGroup>
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface DetailsDialogProps {
//   open: boolean;
//   ruleId: string;
//   tabValue: string;
//   showUnsupported?: boolean;
//   tableRowModel: TableRowModel;
//   setDetailsDialogProps: (dialogProps: DetailsDialogProps) => void;
//   actionsLoadStatus: LoadStatus;
//   findingTypesLoadStatus: LoadStatus;
//   orgId: string;
//   orgs: Org[];
//   orgIds: string[];
//   refreshConditionOverrides: () => Promise<object>;
//   refreshRuleStatuses: () => Promise<object>;
//   displayMessage: (props: AlertMessageProps) => void;
// }

const DetailsDialog = (props) => {
  const { setDetailsDialogProps } = props;
  const handleTabChange = useCallback(
    (event, value) => {
      setDetailsDialogProps((prevState) => ({
        ...prevState,
        tabValue: value,
      }));
    },
    [setDetailsDialogProps]
  );

  const handleClose = useCallback(
    (event, value) => {
      setDetailsDialogProps((prevState) => ({
        // Even though we're closing the dialog, we need to preserve
        // the previous state so that the closing animation is smooth.
        ...prevState,
        open: false,
      }));
    },
    [setDetailsDialogProps]
  );

  return (
    <StyledDialog open={props.open} maxWidth={"md"} fullWidth={true}>
      <div className={classes.dialogHeaderContainer}>
        <DialogTitle>Detection Rule</DialogTitle>
        <IconButton
          title="Close without saving"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        {props.open && (
          <TabContext value={props.tabValue || "Details"}>
            <Tabs
              value={props.tabValue}
              textColor={"primary"}
              indicatorColor={"primary"}
              onChange={handleTabChange}
              className={classes.tabs}
            >
              <Tab label={"Details"} value={"Details"} />
              <Tab label={"Accounts"} value={"Accounts"} />
            </Tabs>
            <TabPanel value={"Details"} className={classes.tabPanel}>
              <RuleDetailsTab
                tableRowModel={props.tableRowModel}
                actionsLoadStatus={props.actionsLoadStatus}
                findingTypesLoadStatus={props.findingTypesLoadStatus}
                orgId={props.orgId}
                refreshConditionOverrides={props.refreshConditionOverrides}
                displayMessage={props.displayMessage}
              />
            </TabPanel>
            <TabPanel value={"Accounts"} className={classes.tabPanel}>
              <RuleAccountsTab
                tableRowModel={props.tableRowModel}
                orgs={props.orgs}
                orgIds={props.orgIds}
                showUnsupported={props.showUnsupported}
                refreshRuleStatuses={props.refreshRuleStatuses}
                setDetailsDialogProps={setDetailsDialogProps}
                displayMessage={props.displayMessage}
              />
            </TabPanel>
          </TabContext>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default DetailsDialog;
