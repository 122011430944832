import { createSelector } from "reselect";

import { orgIdSelector } from "./orgIdSelector";

const orgSelector = (state) => state.org;

/*
Org data selector
*/

const getOrgInfo = (orgStore, orgId) => {
  const orgInfo = orgStore.byId[orgId] || {};

  return orgInfo;
};

export const orgInfoSelector = createSelector(
  [orgSelector, orgIdSelector],
  getOrgInfo
);

/*
Org request error selector
*/

const getOrgErrorMessage = (orgStore, orgId) => {
  const { errorByOrg = {} } = orgStore;
  const orgError = errorByOrg[orgId] || {};

  const { message = "" } = orgError;

  return message;
};

export const orgErrorMessageSelector = createSelector(
  [orgSelector, orgIdSelector],
  getOrgErrorMessage
);

/*
Org loading selector
*/

const getOrgLoading = (orgStore, orgId) => {
  const { loadingByOrg = {} } = orgStore;
  const loading = loadingByOrg[orgId] || false;

  return loading;
};

export const orgLoadingSelector = createSelector(
  [orgSelector, orgIdSelector],
  getOrgLoading
);
