import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "executivesummaries",
};

export class ExecutiveSummariesAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  list() {
    return super.get();
  }
}
