export const MAPPING_METHODS = {
  companyId: {
    label: "Auto-map Blumira “Account Name” to ConnectWise Manage “Company ID”",
    value: "companyId",
  },
  companyName: {
    label:
      "Auto-map Blumira “Account Name” to ConnectWise Manage “Company name”",
    value: "companyName",
  },
  manual: {
    label: "Manually map",
    value: "maunual",
  },
};
