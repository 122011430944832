import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { matchSorter } from "match-sorter";

const maxTags = 6;

const PREFIX = "OutputFieldsEditor";
const classes = {
  openInput: `${PREFIX}-openInput`,
  closedInput: `${PREFIX}-closedInput`,
  boldLabel: `${PREFIX}-boldLabel`,
};

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`& .${classes.openInput}`]: {
    "&::placeholder": {
      fontSize: 12,
      fontStyle: "italic",
    },
  },
  [`& .${classes.closedInput}`]: {
    "&::placeholder": {
      opacity: 1,
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
  },
  [`& .${classes.boldLabel}`]: {
    fontSize: 20,
    marginTop: -5,
    fontWeight: "bold",
    color: theme.palette.text.primary,
  },
}));

const filterOptions = (options, { inputValue }) =>
  matchSorter(options, inputValue);

const OutputFieldsEditor = (props) => {
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsAutocompleteOpen(!isAutocompleteOpen);
  };

  // creating a label that is more conspicuous for users
  const BoldLabel = ({ children }) => (
    <label className={classes.boldLabel}>{children}</label>
  );

  return (
    <StyledAutocomplete
      multiple
      size="small"
      onOpen={toggleIsOpen}
      onClose={toggleIsOpen}
      id="selected-output-fields"
      value={props.control || []}
      onChange={(event, value) => {
        const outputFields =
          value.length === 0 && !props.distinctCountsApplied ? ["id"] : value;
        props.onAddExtraOutputField(outputFields);
        props.interface.update("outputFields", outputFields);
      }}
      options={props.fields || []}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option}
      defaultValue={[]}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={<BoldLabel>Selected Columns</BoldLabel>}
          placeholder={
            isAutocompleteOpen
              ? "Type to search..."
              : !isAutocompleteOpen && props.control?.length > maxTags
              ? `Show all (${props.control.length})`
              : ""
          }
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: {
              input: isAutocompleteOpen
                ? classes.openInput
                : classes.closedInput,
            },
          }}
        />
      )}
      renderTags={(value, getTagProps) => {
        const displayedValues = value.slice(0, maxTags);

        return (
          <>
            {displayedValues.map((option, index) => (
              <Chip
                key={option}
                label={option}
                {...getTagProps({ index })}
                disabled={!props.distinctCountsApplied && option === "id"}
              />
            ))}
          </>
        );
      }}
    />
  );
};

// ** Proptypes ***********************
OutputFieldsEditor.propTypes = {
  controls: PropTypes.object.isRequired,
  interface: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
};

// ** Default props *******************
OutputFieldsEditor.defaultProps = {};

export default OutputFieldsEditor;
