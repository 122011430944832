import React from "react";
import PropTypes from "prop-types";

import DataGrid from "../../Components/DataGrid";

const DevicesTable = (props) => {
  const { data = [] } = props;

  return (
    <DataGrid
      defaultPageSize={10}
      data={data}
      columns={[
        {
          Header: "Device Address",
          accessor: "addressAccessor",
          filterable: false,
          Cell: (row) => row.original.address,
        },
        {
          Header: "Device Name",
          accessor: "nameAccessor",
          filterable: false,
          Cell: (row) => row.original.name,
        },
        {
          Header: "Type",
          accessor: "type",
          filterable: false,
        },
        {
          Header: "Log Events",
          accessor: "totalAccessor",
          filterable: false,
          defaultSortDesc: true,
          Cell: (row) => row.original.total,
        },
      ]}
    />
  );
};

DevicesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

DevicesTable.defaultProps = {
  data: [],
};

export default DevicesTable;
