import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-advanced";

const LoadingView = ({ show, error }) => (
  <Loader show={show} message="" className="fullLoader">
    {error && error.message && (
      <div className="alert alert-danger" role="alert">
        {error.message}
      </div>
    )}
  </Loader>
);

LoadingView.propTypes = {
  show: PropTypes.bool,
};

LoadingView.defaultProps = {
  show: true,
};

export default LoadingView;
