import moment from "moment-timezone";
import { v1 } from "uuid";

import Logger from "../lib/logger";

import * as Actions from "./actions";
import { DATA_SOURCES } from "./actions/QueryActions";

const logger = Logger("QueryReducer");

const DEFAULT_COLUMNS = {
  finding: [
    "created",
    "short_id",
    "type",
    "priority",
    "name",
    "analysis",
    "jurisdiction",
    "status",
  ],
  bq: ["timestamp", "devname", "device_address", "src_ip", "dst_ip", "type"],
};

// Returns the default start date for the date picker's range
export const getStartDate = (dataSource) => {
  if (dataSource === "finding") {
    return moment()
      .tz(moment.tz.guess(true))
      .startOf("day")
      .subtract(30, "days")
      .toDate();
  }

  return moment().tz(moment.tz.guess(true)).startOf("day").toDate();
};

// Returns the default end date for the date picker's range
export const getEndDate = () =>
  moment().tz(moment.tz.guess(true)).endOf("day").toDate();

const initialReportForm = {
  selectedDataQuery: null,
  frequency: "last-7-days",
  dayOfWeek: 0,
  dayOfMonth: 20,
  reportName: "",
  recipients: "",
  outputType: "csv",
  hourOfDay: 9,
  AMorPM: 0,
};

const initialQueryStore = {
  dateRangeFilter: {
    startDate: getStartDate("bq"),
    endDate: getEndDate(),
    modified: false,
  },
  columnOptions: [],
  fetchQueryError: null,
  fieldFilters: [],
  fieldOptions: [],
  fields: [],
  fieldsError: null,
  hasAdvancedFilters: false,
  isAggregateData: false,
  isLoading: false,
  reports: [],
  reportsError: null,
  runQueryError: null,
  savedQueries: [],
  saveQueryError: null,
  selectedQueryId: null,
  selectedColumns: [],
};

const initialState = {
  reportForm: initialReportForm,
  isDeleting: false,
  bq: {
    ...initialQueryStore,
  },
  finding: {
    ...initialQueryStore,
    dateRangeFilter: {
      startDate: getStartDate("finding"),
      endDate: getEndDate(),
      modified: false,
    },
  },
  messages: {},
};

const QueryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.QueryContainer.ADD_FILTER: {
      const dataState = state[action.dataSource];

      if (action.filter.id) {
        const filterIdx = dataState.fieldFilters.findIndex(
          (i) => i.id === action.filter.id
        );

        // splice returns the deleted object and mutates the object :/
        const fieldFilters = dataState.fieldFilters;
        fieldFilters.splice(filterIdx, 1, action.filter);

        return {
          ...state,
          [action.dataSource]: {
            ...dataState,
            fieldFilters,
          },
        };
      }

      const newFilter = {
        ...action.filter,
        id: v1(),
      };

      return {
        ...state,
        [action.dataSource]: {
          ...dataState,
          isDirty: true,
          fieldFilters: dataState.fieldFilters.concat([newFilter]),
        },
      };
    }
    case Actions.QueryContainer.REMOVE_FILTER: {
      const dataState = state[action.dataSource];
      return {
        ...state,
        [action.dataSource]: {
          ...dataState,
          isDirty: true,
          fieldFilters: dataState.fieldFilters.filter(
            (i) => i.fieldName !== action.filter.fieldName
          ),
        },
      };
    }
    case Actions.QueryContainer.CHANGE_DATE: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          isDirty: true,
          dateRangeFilter: {
            startDate: moment(action.startDate)
              .tz(moment.tz.guess(true))
              .startOf("day")
              .toDate(),
            endDate: moment(action.endDate)
              .tz(moment.tz.guess())
              .endOf("day")
              .toDate(),
            modified: !!action.modified,
          },
        },
      };
    }
    case Actions.QueryContainer.SET_IS_AGGREGATION: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          isAggregateData: action.isAggregateData,
        },
      };
    }
    case Actions.QueryContainer.CHANGE_COLUMNS: {
      const isDateColumn = (col) =>
        col.label === DATA_SOURCES[action.dataSource].timeColumn;

      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          isDirty: true,
          selectedColumns: (action.columns || []).sort((a, b) => {
            if (isDateColumn(a)) {
              return -1;
            }
            if (isDateColumn(b)) {
              return 1;
            }
            return 0;
          }),
        },
      };
    }
    case Actions.QueryContainer.RECEIVE_FILTER_FIELDS: {
      const dataSource = action.dataSource;
      const defaults = DEFAULT_COLUMNS[dataSource];
      const options = action.fields.map(({ field, type, mode }) => ({
        type,
        value: field && field.toLowerCase ? field : field,
        label: field,
        mode,
        constant: mode.endsWith(".CONSTANT"),
      }));

      return {
        ...state,
        [dataSource]: {
          ...state[action.dataSource],
          fieldsFetched: new Date(),
          fields: action.fields.map((i) => `${i.field}`),
          columnOptions: options,
          fieldOptions: options.filter((i) => i.mode !== "NO_FILTER"),
          selectedColumns: options
            .filter((col) => defaults.includes(col.label))
            .sort(
              ({ label: a }, { label: b }) =>
                defaults.indexOf(a) - defaults.indexOf(b)
            ),
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_FIELDS_ERROR: {
      const { payload = {} } = action;
      const { dataSource, error = null } = payload;

      return {
        ...state,
        [dataSource]: {
          ...state[dataSource],
          fieldsError: error,
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_QUERY_DATA: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          queryData: action.resultFileUrl
            ? state[action.dataSource].queryData
            : action.data,
          resultFileUrl: action.resultFileUrl,
          isLoading: false,
          maxResultsExceeded: action.maxResultsExceeded,
        },
      };
    }
    case Actions.QueryContainer.LOADING_STATE: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          isLoading: action.state,
          maxResultsExceeded: false,
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_RUN_QUERY_ERROR: {
      const { payload = {} } = action;
      const { dataSource, error } = payload;
      return {
        ...state,
        [dataSource]: {
          ...state[dataSource],
          runQueryError: error,
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_DATA_QUERIES: {
      if (action.dataSource === undefined) {
        return {
          ...state,
          ["dataQueries"]: action.dataQueries,
        };
      } else {
        return {
          ...state,
          [action.dataSource]: {
            ...state[action.dataSource],
            savedQueries: action.dataQueries,
            savedQueriesFetched: new Date(),
          },
        };
      }
    }

    case Actions.QueryContainer.RECEIVE_FETCH_QUERY_ERROR: {
      const { payload = {} } = action;
      const { dataSource, error } = payload;
      return {
        ...state,
        [dataSource]: {
          ...state[dataSource],
          fetchQueryError: error,
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_SAVED_QUERY: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          savedQueries: [
            ...state[action.dataSource].savedQueries,
            action.savedQuery,
          ],
          selectedSavedQuery: {
            label: action.savedQuery.name,
            value: action.savedQuery,
          },
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_SAVE_QUERY_ERROR: {
      const { payload = {} } = action;
      const { dataSource, error } = payload;
      return {
        ...state,
        [dataSource]: {
          ...state[dataSource],
          saveQueryError: error,
        },
      };
    }

    case Actions.QueryContainer.CREATE_REPORT: {
      return {
        ...state,
        isReportSaving: true,
      };
    }

    case Actions.QueryContainer.CHANGE_REPORT_FORM: {
      return {
        ...state,
        reportForm: {
          ...state.reportForm,
          [action.fieldName]: action.fieldValue,
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_REPORT: {
      const report = action.report;
      const dataSource = report.dataSource;

      if (!Object.keys(DATA_SOURCES).includes(dataSource)) {
        logger.error(new Error("Report invalid! Not accepted in store."));

        return {
          ...state,
          isReportSaving: false,
        };
      }

      return {
        ...state,
        isReportSaving: false,
        reportForm: initialReportForm,
        [dataSource]: {
          ...state[dataSource],
          reports: state[dataSource].reports.concat([report]),
        },
      };
    }

    case Actions.QueryContainer.TOGGLE_MESSAGE: {
      return {
        ...state,
        messages: {
          ...state.messages,
          [action.message]: action.value,
        },
      };
    }

    case Actions.QueryContainer.REQUEST_REPORTS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case Actions.QueryContainer.RECEIVE_REPORTS: {
      const reports = action.reports;
      const findingReports = reports.filter(
        (i) => i.dataSource === DATA_SOURCES.finding.name
      );
      const bqReports = reports.filter(
        (i) => i.dataSource === DATA_SOURCES.bq.name
      );
      return {
        ...state,
        isFetching: false,
        finding: {
          ...state.finding,
          reports: findingReports,
        },
        bq: {
          ...state.bq,
          reports: bqReports,
        },
      };
    }

    case Actions.QueryContainer.RECEIVE_REPORTS_ERROR: {
      const { payload = {} } = action;
      const { dataSource, error = null } = payload;

      return {
        ...state,
        [dataSource]: {
          ...state[dataSource],
          reportsError: error,
        },
      };
    }

    case Actions.QueryContainer.CHANGE_NAME: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          name: action.name,
        },
      };
    }
    case Actions.QueryContainer.TOGGLE_IS_DELETING: {
      return {
        ...state,
        isDeleting: !state.isDeleting,
      };
    }

    case Actions.QueryContainer.REMOVE_REPORT: {
      return {
        ...state,
        isDeleting: false,
        [action.dataSource]: {
          ...state[action.dataSource],
          reports: state[action.dataSource].reports.filter(
            (i) => i.id !== action.reportId
          ),
        },
      };
    }
    case Actions.QueryContainer.SELECT_DATA_QUERY: {
      const relativeStart = action.savedQuery.configuration.relativeStart;
      const columnOptions = state[action.dataSource].columnOptions || [];
      const defaultColumns =
        action.savedQuery.aggregators && action.savedQuery.aggregators.length
          ? []
          : DEFAULT_COLUMNS[action.dataSource];
      const queryColumns =
        action.savedQuery.aggregate && action.savedQuery.aggregate.length > 0
          ? action.savedQuery.aggregate.map((i) => i && i)
          : defaultColumns;
      const selectedColumns = columnOptions.filter((i) =>
        queryColumns.includes(i.label)
      );

      const dateRangeFilter = {
        ...state[action.dataSource].dateRangeFilter,
        startDate: action.savedQuery.configuration.timeStart,
        endDate: action.savedQuery.configuration.timeEnd,
        modified: false,
      };

      if (relativeStart) {
        // moment.normalizeUnits takes 'd' for days, 'M': month' and 'y': year
        const unit = moment.normalizeUnits(relativeStart.slice(-1));
        const duration = relativeStart.substr(0, relativeStart.length - 1);
        dateRangeFilter.startDate = moment()
          .subtract(duration, unit)
          .startOf("day");
        dateRangeFilter.endDate = moment().endOf("day");
      }

      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          aggregate: action.savedQuery.aggregate,
          aggregators: action.savedQuery.aggregators,
          isAggregateData:
            action.savedQuery.aggregators &&
            action.savedQuery.aggregators.length > 0,
          nonCountAggregators:
            action.savedQuery.aggregators &&
            action.savedQuery.aggregators.filter((i) => i !== "count(*)"),
          selectedSavedQuery: action.savedQuery,
          fieldFilters: action.savedQuery.parseSearch().map((i) => ({
            ...i,
            id: v1(),
          })),
          hasAdvancedFilters: action.savedQuery.hasAdvancedFilters,
          selectedQueryId: action.savedQuery.id,
          selectedColumns,
          dateRangeFilter,
          name: action.savedQuery.name,
          queryData: [],
        },
      };
    }

    case Actions.QueryContainer.RESET_QUERY_ID: {
      return {
        ...state,
        [action.dataSource]: {
          ...state[action.dataSource],
          selectedQueryId: null,
        },
      };
    }

    case Actions.QueryContainer.RESET_STORE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default QueryReducer;
