import React from "react";
import { StyledAlert, classes } from "./blumiraAgentActivationBannerStyles";
import Button from "@mui/material/Button";

const blumiraMspAgreementUrl = `https://www.blumira.com/managed_service_provider_agreement`;
const blumiraMspBillingUrl = "https://blumira.help/msppricing";

export const BlumiraAgentActivationBanner = (props) => {
  return (
    <StyledAlert
      severity={"info"}
      icon={false}
      datacy={"blumiraAgentBanner"}
      className={classes.activationBannerContainer}
    >
      <p className={classes.alertTitle}>
        Blumira Agent is a paid add-on
        {/* 
                    FYI: hiding this link until GA
                    <a 
                        target={'_blank'} 
                        href={pricingUrl}
                        rel={'noopener noreferrer'} 
                        className={classes.leftMarginLink}
                    >
                        Pricing <OpenInNewIcon/>
                    </a>
                */}
      </p>
      {props.isOrgAParentOrg ? (
        <ul>
          <li>
            By activating the Blumira Agent you are agreeing to be bound by the{" "}
            <a
              href={blumiraMspAgreementUrl}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              MSP agreement terms
            </a>
          </li>
          <li>
            The Blumira Agent ships logs from supported systems directly to
            Blumira cloud
          </li>
          <li>
            The Blumira Agent can block individual devices from accessing the
            network (host isolation)
          </li>
          <li>The Blumira Agent has a 5-device limit in your NFR account</li>
          <li>
            Additional agents are available for purchase according to our{" "}
            <a
              href={blumiraMspBillingUrl}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              MSP Pricing
            </a>
            . Email{" "}
            <a
              href="mailto:MSP@blumira.com"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              msp@blumira.com
            </a>{" "}
            for more details
          </li>
        </ul>
      ) : props.isOrgAChildOrg ? (
        <ul>
          <li>
            By activating the Blumira Agent you are agreeing to be bound by the{" "}
            <a
              href={blumiraMspAgreementUrl}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              MSP agreement terms
            </a>{" "}
            and billed according to your Agent usage
          </li>
          <li>
            Please view our{" "}
            <a
              href={blumiraMspBillingUrl}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              MSP Pricing page
            </a>{" "}
            for Agent pricing and packaging information. Email{" "}
            <a
              href="mailto:MSP@blumira.com"
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              msp@blumira.com
            </a>{" "}
            for more details
          </li>
          <li>
            The Blumira Agent has a device limit equal to the paid edition
            platform user count of this account
          </li>
          <li>
            The Blumira Agent ships logs from supported systems directly to
            Blumira cloud
          </li>
          <li>
            The Blumira Agent can block individual devices from accessing the
            network (host isolation)
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            The Blumira Agent ships logs from supported systems directly to
            Blumira cloud
          </li>
          <li>
            The Blumira Agent can block individual devices from accessing the
            network (host isolation)
          </li>
        </ul>
      )}

      <div className={classes.linkContainer}>
        <Button
          onClick={props.onActivateClick}
          variant={"contained"}
          color={"primary"}
          className={classes.activateButton}
        >
          {props.isActivatingAgent ? "Activating..." : "Activate"}
        </Button>
        {/* 
                    FYI: hiding this link until GA
                    <a 
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        href={blumiraAgentSetupUrl} 
                    >
                        See setup guide and supported systems <OpenInNewIcon/>
                    </a>
                */}
      </div>
    </StyledAlert>
  );
};
