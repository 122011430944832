import { insertData } from "../utils/helpers";

export const getReducer = ({
  actionTypeReceive,
  actionTypeReset,
  initialState,
  insert = insertData,
}) => {
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypeReceive: {
        return insert(state, action);
      }
      case actionTypeReset: {
        return initialState;
      }
      default:
        return state;
    }
  };

  return reducer;
};

export const insertError = (_, action) => {
  const { payload = {} } = action;
  const { error = null } = payload;

  return error;
};
