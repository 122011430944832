import React from "react";

import { DEFAULT_EMPTY_LABEL, DEFAULT_NUMBER_OF_BARS } from "./securityConfig";
import BluAxisLabel from "./BluAxisLabel";

/*
Helpers
*/

// Modifies `labels` by appending it with `fillWith`
// up to `num` labels.
const fillUpToNum = ({
  labels,
  num = DEFAULT_NUMBER_OF_BARS,
  fillWith = DEFAULT_EMPTY_LABEL,
}) => {
  const len = Object.keys(labels).length;

  for (let i = num; i >= len; i -= 1) {
    labels[i] = fillWith;
  }
};

/*
Main functions
*/

// Takes a raw data array as argument, and
// returns the axis labels object of the form
// { '0': label0, '1': label1,}.
export const getAxisLabelsLeft = ({ rawData, labelField, labelField2 }) => {
  // Labels for the bars
  const labels = (rawData || []).reduce((accum, elt, index) => {
    accum[index] = (
      <BluAxisLabel
        elt={elt}
        labelField={labelField}
        labelField2={labelField2}
      />
    );

    return accum;
  }, {});

  // Empty labels for non-existing bars
  // to make the total number of bars 10
  fillUpToNum({ labels });

  return labels;
};
