import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "ExecutiveSummaries";
export const rootClasses = {
  loadingContainer: `${PREFIX}-loadingContainer`,
  cardContainer: `${PREFIX}-cardContainer`,
  card: `${PREFIX}-card`,
  introductionOuterContainer: `${PREFIX}-introductionOuterContainer`,
  image: `${PREFIX}-image`,
  announcementAccordion: `${PREFIX}-announcementAccordion`,
  accordion: `${PREFIX}-accordion`,
  accordionPanel: `${PREFIX}-accordionPanel`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  introductionPrimaryText: `${PREFIX}-introductionPrimaryText`,
  linkContainer: `${PREFIX}-linkContainer`,
  span: `${PREFIX}-span`,
  sectionSubtitle: `${PREFIX}-sectionSubtitle`,
  sectionButtonContainer: `${PREFIX}-sectionButtonContainer`,
  textFieldOuterContainer: `${PREFIX}-textFieldOuterContainer`,
  checkBoxOuterContainer: `${PREFIX}-checkBoxOuterContainer`,
  checkboxContainer: `${PREFIX}-checkboxContainer`,
  textFieldContainer: `${PREFIX}-textFieldContainer`,
  textField: `${PREFIX}-textField`,
  logoUrlTextField: `${PREFIX}-logoUrlTextField`,
  textFieldRowIcon: `${PREFIX}-textFieldRowIcon`,
  contentSettingsCheckboxContainer: `${PREFIX}-contentSettingsCheckboxContainer`,
  radioGroupOuterContainer: `${PREFIX}-radioGroupOuterContainer`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  radioHelperText: `${PREFIX}-radioHelperText`,
  dialogHeader: `${PREFIX}-dialogHeader`,
  close: `${PREFIX}-close`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogImage: `${PREFIX}-dialogImage`,
  previousSummarySectionWrapper: `${PREFIX}-previousSummarySectionWrapper`,
  alertWarningContainer: `${PREFIX}-alertWarningContainer`,
  alertTitle: `${PREFIX}-alertTitle`,
  boldText: `${PREFIX}-boldText`,
};

export const dialogClasses = {
  dialogHeader: `${PREFIX}-dialogHeader`,
  close: `${PREFIX}-close`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogImage: `${PREFIX}-dialogImage`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.loadingContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.shape.padding * 2,
    "& p": {
      fontWeight: "bold",
      paddingTop: theme.shape.padding,
    },
  },
  [`& .${rootClasses.cardContainer}`]: {
    marginTop: -theme.shape.padding,
  },
  [`& .${rootClasses.card}`]: {
    boxShadow: "none",
    marginTop: theme.shape.padding,
  },
  [`&.${rootClasses.introductionOuterContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${rootClasses.image}`]: {
    transform: "rotate(11deg)",
    overflow: "hidden",
    position: "absolute",
    right: "14vw",
    bottom: "-9vw",
    "@media (max-width: 1120px)": {
      display: "none",
    },
  },
  [`& .${rootClasses.announcementAccordion}`]: {
    padding: 5,
    backgroundColor: theme.palette.background.alert,
    border: `1px solid ${theme.palette.border.alertBorder}`,
  },
  [`& .${rootClasses.accordion}`]: {
    padding: 5,
    border: "1px solid lightgray",
  },
  [`& .${rootClasses.accordionPanel}`]: {
    width: "100%",
    padding: "0 45px",
    flexDirection: "column",
    "& ul": {
      width: "50%",
      paddingTop: theme.shape.padding,
      marginLeft: -25,
      "@media (max-width: 1120px)": {
        width: "100% !important",
      },
    },
    "& li": {
      fontWeight: 400,
      paddingBottom: 5,
    },
  },
  [`& .${rootClasses.accordionTitle}`]: {
    margin: 0,
    fontSize: 20,
    display: "flex",
    paddingLeft: 10,
    fontWeight: "bold",
    alignItems: "center",
    fontFamily: '"Rubik", "Roboto", sans-serif',
  },
  [`& .${rootClasses.accordionSummary}`]: {
    marginLeft: -theme.shape.padding,
    "& div.MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& div.MuiAccordionSummary-expandIcon": {
      order: -1,
    },
    "& div.MuiAccordion-root.Mui-expanded": {
      marginTop: -15,
    },
  },
  [`& .${rootClasses.introductionPrimaryText}`]: {
    fontSize: 25,
    width: "50%",
    fontWeight: "bold",
    "@media (max-width: 1120px)": {
      width: "100% !important",
    },
  },
  [`& .${rootClasses.linkContainer}`]: {
    display: "flex",
    paddingBottom: theme.shape.padding,
    "& a": {
      fontWeight: 400,
      marginRight: theme.shape.padding,
      color: theme.palette.primary.main,
    },
  },
  [`& .${rootClasses.span}`]: {
    display: "flex",
    fontWeight: 400,
    cursor: "pointer",
    marginRight: theme.shape.padding,
    color: theme.palette.primary.main,
    "& p": {
      marginRight: 5,
    },
  },
  [`& .${rootClasses.sectionSubtitle}`]: {
    fontWeight: 550,
    marginBottom: 10,
  },
  [`& .${rootClasses.sectionButtonContainer}`]: {
    display: "flex",
    paddingBottom: theme.shape.padding,
  },
  [`& .${rootClasses.textFieldOuterContainer}`]: {
    paddingBottom: theme.shape.padding,
  },
  [`& .${rootClasses.checkBoxOuterContainer}`]: {
    marginTop: -10,
    marginLeft: -10,
    paddingBottom: theme.shape.padding,
  },
  [`& .${rootClasses.checkboxContainer}`]: {
    display: "flex",
    alignItems: "center",
    "& p": {
      margin: 0,
    },
  },
  [`& .${rootClasses.textFieldContainer}`]: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.textField}`]: {
    minWidth: 350,
  },
  [`& .${rootClasses.logoUrlTextField}`]: {
    minWidth: 350,
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.textFieldRowIcon}`]: {
    marginLeft: theme.shape.padding,
  },
  [`& .${rootClasses.contentSettingsCheckboxContainer}`]: {
    marginLeft: -10,
    paddingBottom: theme.shape.padding,
  },
  [`& .${rootClasses.radioGroupOuterContainer}`]: {
    paddingBotom: theme.shape.padding,
  },
  [`& .${rootClasses.formControlLabel}`]: {
    marginTop: -10,
  },
  [`& .${rootClasses.radioHelperText}`]: {
    fontSize: 14,
    maxWidth: "50%",
    marginTop: -10,
  },
  [`& .${rootClasses.dialogHeader}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${rootClasses.close}`]: {
    cursor: "pointer",
  },
  [`& .${rootClasses.dialogContent}`]: {
    padding: "0 30px",
    overflow: "scroll",
  },
  [`& .${rootClasses.dialogImage}`]: {
    width: "100%",
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.previousSummarySectionWrapper}`]: {
    marginTop: -theme.shape.padding,
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.alertWarningContainer}`]: {
    padding: `15px 30px`,
    "& p": {
      marginBottom: theme.shape.padding,
    },
    "& svg": {
      paddingRight: 5,
    },
  },
  [`& .${rootClasses.alertTitle}`]: {
    fontSize: 18,
    fontWeight: "bold",
  },
  [`& .${rootClasses.boldText}`]: {
    fontWeight: "bold",
  },
}));

export const SnapshotModal = styled(Dialog)(({ theme }) => ({
  [`& .${rootClasses.dialogHeader}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${rootClasses.close}`]: {
    cursor: "pointer",
  },
  [`& .${rootClasses.dialogContent}`]: {
    padding: "0 30px",
    overflow: "scroll",
  },
  [`& .${rootClasses.dialogImage}`]: {
    width: "100%",
    marginBottom: theme.shape.padding,
  },
}));
