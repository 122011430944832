import { redirect } from "redux-first-router";

import { ResourcesAPI } from "../../lib/api";
import { graphParamsFromDelta } from "../../views/Pages/Resources/common/LogdevGraph";

import { getGraphActions, getQueryGraph } from "./common/Graph";
import { fetchData } from "./common/GraphAndTable";
import {
  fetchDataAndDates,
  receiveError as receiveErrDataAndDates,
} from "./common/DataAndDates";
import {
  fetchData as fetchHostnamesHelper,
  receiveError as receiveHostsTableError,
} from "./common/Data";
import { receivePaginationParam } from "./common/Pagination";
import { receiveTimeInterval } from "./common/TimeInterval";

/*
Constants and helpers
*/

// 'graph...' actions will be imported from 'common'
export const ACTION_TYPE_PREFIX = "HOST";
export const HOST_GRAPH = "HOST_GRAPH";
export const HOST_RESET = "HOST_RESET";

const TALKERS_PREFIX = `${ACTION_TYPE_PREFIX}_TALKERS`;
const THREATS_PREFIX = `${ACTION_TYPE_PREFIX}_THREATS`;
const WINDOWS_PREFIX = `${ACTION_TYPE_PREFIX}_WINDOWS_EVENTS`;

export const HOST_SELECTED_INTERVAL_RECEIVE = "HOST_SELECTED_INTERVAL_RECEIVE";

export const HOST_ITEMS_DATA_RECEIVE = "HOST_ITEMS_DATA_RECEIVE";
export const HOST_ITEMS_ERROR_RECEIVE = "HOST_ITEMS_ERROR_RECEIVE";
export const HOST_ITEMS_META_RECEIVE = "HOST_ITEMS_META_RECEIVE";

export const HOST_PAGINATION_RECEIVE = "HOST_PAGINATION_RECEIVE";

export const HOST_TABLE_FILTERS_RECEIVE = "HOST_TABLE_FILTERS_RECEIVE";

export const HOST_TABLE_SORTING_RECEIVE = "HOST_TABLE_SORTING_RECEIVE";

export const HOST_TALKERS_DATA_RECEIVE = "HOST_TALKERS_DATA_RECEIVE";
export const HOST_TALKERS_DATES_RECEIVE = "HOST_TALKERS_DATES_RECEIVE";
export const HOST_TALKERS_ERROR_RECEIVE = "HOST_TALKERS_ERROR_RECEIVE";

export const HOST_THREATS_DATA_RECEIVE = "HOST_THREATS_DATA_RECEIVE";
export const HOST_THREATS_DATES_RECEIVE = "HOST_THREATS_DATES_RECEIVE";
export const HOST_THREATS_ERROR_RECEIVE = "HOST_THREATS_ERROR_RECEIVE";

export const HOST_WINDOWS_EVENTS_DATA_RECEIVE =
  "HOST_WINDOWS_EVENTS_DATA_RECEIVE";
export const HOST_WINDOWS_EVENTS_DATES_RECEIVE =
  "HOST_WINDOWS_EVENTS_DATES_RECEIVE";
export const HOST_WINDOWS_EVENTS_ERROR_RECEIVE =
  "HOST_WINDOWS_EVENTS_ERROR_RECEIVE";

const LOGDEV_ID_PREFIX = "hostname/";

const API = {
  resources: new ResourcesAPI(),
};

/*
Actions
*/

// Create "receive graph" actions
const { receiveGraphAll, receiveError } = getGraphActions(
  HOST_GRAPH,
  graphParamsFromDelta
);

// Create the action that fetches and stores all graph data
export const fetchGraph = fetchData({
  apiCall: API.resources.fetchHostGraph.bind(API.resources),
  getQuery: getQueryGraph,
  nameStr: "hostname graph",
  receive: receiveGraphAll,
  receiveError,
});

// Fetches and stores the Hostnames index table
export const fetchHostnames = fetchHostnamesHelper({
  actionTypePrefix: `${ACTION_TYPE_PREFIX}_ITEMS`,
  apiCall: API.resources.fetchHostnames.bind(API.resources),
});

export const clearHostnamesError = receiveHostsTableError(
  `${ACTION_TYPE_PREFIX}_ITEMS`
)(null);

// Selected Time Interval
export const {
  receiveDateRange,
  receiveRelativeInterval,
  receiveRelativeStart,
  receiveSelectedInterval,
} = receiveTimeInterval(HOST_SELECTED_INTERVAL_RECEIVE);

// Talkers
export const fetchTalkers = fetchDataAndDates({
  actionTypePrefix: TALKERS_PREFIX,
  logdevIdPrefix: LOGDEV_ID_PREFIX,
  apiCall: API.resources.fetchTalkers.bind(API.resources),
});

// Threats
export const fetchThreats = fetchDataAndDates({
  actionTypePrefix: THREATS_PREFIX,
  logdevIdPrefix: LOGDEV_ID_PREFIX,
  apiCall: API.resources.fetchThreats.bind(API.resources),
});

// WindowsEvts
export const fetchWindowsEvts = fetchDataAndDates({
  actionTypePrefix: WINDOWS_PREFIX,
  logdevIdPrefix: "",
  apiCall: API.resources.fetchWindowsEvts.bind(API.resources),
});

// Clear all errors
export const clearDetailErrors = (dispatch) => {
  dispatch(receiveError(null));
  dispatch(receiveErrDataAndDates(TALKERS_PREFIX)(null));
  dispatch(receiveErrDataAndDates(THREATS_PREFIX)(null));
  dispatch(receiveErrDataAndDates(WINDOWS_PREFIX)(null));
};

export const receivePagination = receivePaginationParam(
  HOST_PAGINATION_RECEIVE
);

export const receiveTableFilters = receivePaginationParam(
  HOST_TABLE_FILTERS_RECEIVE
);

export const receiveTableSorting = receivePaginationParam(
  HOST_TABLE_SORTING_RECEIVE
);

export const redirectToIndex = (orgId) =>
  redirect({
    type: "PAGE",
    payload: {
      orgId,
      toplevel: "investigate",
      secondlevel: "hostnames",
    },
  });
