import React from "react";
import config from "../lib/config";
import { WILDCARD_ORG_ID } from "../lib/api/API";
import { childrenPageViews } from "../views/pages";
import BluLicense from "../lib/license";
import _ from "lodash";

const USER_DENIED = 0;
const USER_ALLOWED = 1;
const SA_ALLOWED = 2;

export const getUserOrgRoles = ({ user, orgId }) => {
  const userOrgRoles =
    user && user.orgRoles
      ? user.orgRoles
          .filter((role) => role.orgId === orgId)
          .map((role) => role.roleName)
      : [];
  return userOrgRoles;
};

export const checkRoleNav = (route, user, orgId, settings) => {
  const userOrgRoles = getUserOrgRoles({ user, orgId });
  const superUser =
    user && (user.superadmin === true || user.superadmin === "true");
  const mode = orgId === WILDCARD_ORG_ID ? "admin" : "normal";
  const pageConfig = childrenPageViews.find((pc) => pc.route === route);

  // get org data to check 'market' value
  let orgsToSearch = settings.userOrgs.length
    ? settings.userOrgs
    : settings.currentUserOrgs;
  let currentOrgData = orgsToSearch.find(({ id }) => id === orgId) || null;

  if (mode === "normal" && !currentOrgData) {
    return USER_DENIED;
  }

  if (!pageConfig) {
    return USER_DENIED;
  }

  if (route.includes("superadmin") && superUser) return SA_ALLOWED;

  // this is a special case where if MSP, we grant access to executive summaries
  // regardless of license or anything else - just give it to 'em, man
  if (
    route.includes("executivesummaries") &&
    !_.isNil(currentOrgData) &&
    !_.isNil(currentOrgData.market) &&
    currentOrgData.market === 20
  )
    return USER_ALLOWED;

  //give access  to mspportal pages if user is superadmin or user has access to MSP parent organization
  //bonus: added feature flag for testing purposes
  if (
    route.includes("mspportal") &&
    !_.isNil(pageConfig) &&
    Array.isArray(pageConfig.markets) &&
    !_.isNil(currentOrgData) &&
    !_.isNil(currentOrgData.market) &&
    pageConfig.markets.includes(currentOrgData.market) &&
    _.isNil(currentOrgData.parentId)
  ) {
    if (
      (superUser ||
        userOrgRoles.some((role) => pageConfig.roles.includes(role))) &&
      // Check if the license allows access to the specific nested page
      BluLicense.isAllowedPage(route)
    ) {
      return USER_ALLOWED;
    } else if (superUser) {
      return SA_ALLOWED;
    } else {
      return USER_DENIED;
    }
  } else if (route.includes("mspportal")) {
    // if the above conditions aren't met, hide MSP Portal
    return USER_DENIED;
  }

  // Check if current mode (admin/normal) matches what the menu item allows.
  // (Default is 'normal' if menu item doesn't specify modes.)
  if (!(pageConfig.modes || ["normal"]).includes(mode)) {
    return USER_DENIED;
  }

  // Check that a valid license has been built
  if (!BluLicense.isSet()) {
    return USER_DENIED;
  }

  // Check the route against the license
  if (!BluLicense.isAllowedPage(pageConfig.route)) {
    if (pageConfig.roles?.includes("superadmin") && superUser) {
      return SA_ALLOWED;
    } else {
      return USER_DENIED;
    }
  }

  if (pageConfig.featureFlag) {
    if (
      !superUser &&
      pageConfig.featureFlag &&
      (!user.config || !user.config[pageConfig.featureFlag])
    ) {
      return USER_DENIED;
    }
  }

  // This logic is specifically targeting the BILLING page
  if (pageConfig.stripeCustomersOnly && currentOrgData) {
    if (
      !currentOrgData.stripeCustomerId &&
      !currentOrgData.config?.stripe_customer_id
    )
      // TODO: I'm not sure that stripeCustomerId is ever present but leaving it to be safe
      return USER_DENIED;
  }

  if (pageConfig.markets && currentOrgData) {
    // JS treats 0 as falsey so make sure direct customers are allowed
    const currentOrgMarket = pageConfig.markets.find(
      (marketValue) => marketValue === currentOrgData.market
    );

    if (currentOrgMarket || currentOrgMarket === 0) {
      // Make sure that direct orgs are also checking user permissions before just giving permissions for a page.
      // This addition is in response to APP-1420 and is a quick fix for now until we can overhaul the menu and this function in the future.
      if (
        superUser ||
        pageConfig.roles === undefined ||
        pageConfig.roles.includes("*") ||
        pageConfig.roles.some((role) => userOrgRoles.includes(role))
      ) {
        return USER_ALLOWED;
      }
    } else return USER_DENIED; // current org's market does not match what is defined in pages.js
  }

  if (superUser || pageConfig.roles === undefined) {
    return USER_ALLOWED;
  }

  if (pageConfig.roles.includes("*")) {
    return USER_ALLOWED;
  }

  if (pageConfig.roles.some((role) => userOrgRoles.includes(role))) {
    return USER_ALLOWED;
  }

  if (
    userOrgRoles.includes("administrator") &&
    pageConfig.labs &&
    localStorage.getItem("labsEnabled")
  ) {
    return USER_ALLOWED;
  }

  return USER_DENIED;
};

/*
"Extracted" from SidebarView.
Takes `menuItem` as argument.
Returns `true` if `menuItem` has any child items in `config`
for the logged-in user; returns `false` otherwise.
*/
const getCleanMenuItem = ({ menuItem, user, orgId, settings }) => {
  const allChildren = menuItem.children || [];
  const retMenuItem = { ...menuItem };
  let numSAChildren = 0;
  let children = allChildren
    .map((c) => {
      const retVal = checkRoleNav(
        `${menuItem.type}/${c.type}`,
        user,
        orgId,
        settings
      );
      switch (retVal) {
        case USER_DENIED:
          return null;
        case SA_ALLOWED: {
          numSAChildren++;
          const newBadge = c.badge ? `${c.badge}, SA` : "SA";
          return { ...c, badge: newBadge };
        }
        case USER_ALLOWED:
          return c;
        default:
          throw new Error("Unknown role nav");
      }
    })
    .filter((c) => !!c);
  if (numSAChildren > 0 && numSAChildren === children.length) {
    // All children are SA-only, so add a badge to the parent menu item
    // and remove the SA badge from the children.
    retMenuItem.badge = "SA";
    children = allChildren;
  }
  return {
    ...retMenuItem,
    children,
  };
};

export const getMenu = (orgId, settings) => {
  const menu = (config.menuItems || [])
    .map((menuItem) => {
      return {
        ...getCleanMenuItem({
          menuItem,
          user: settings.user,
          orgId,
          settings,
        }),
      };
    })
    .filter((menuItem) => menuItem.children.length > 0);
  return menu;
};

export const isSecondLevelSelected = (childMenuItem, activeRoute) => {
  // in some cases, we have routes that align with
  // views that act like children but should not be
  // shown in the side menu as children with their
  // own menu item. simply add the route in `children`
  // inside config.json - see `results` under `reporting`
  // for an example

  return (
    activeRoute.toLowerCase() === childMenuItem.type ||
    (childMenuItem.children &&
      childMenuItem.children.find(
        (child) => activeRoute.toLowerCase() === child
      ))
  );
};

// this div adds 30px of padding above the child page
// for when the isolation control bar is present
export const renderSpacerGridItem = () => {
  return <div style={{ height: 30 }} />;
};
