// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { node, string } from "prop-types";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import { ListContainer, ListItem } from "./style";

// *****************************************************************************
// Layout
// *****************************************************************************
//
//  List layout
//
//  ** Remarks
//  Simple list layout extended to simplify code across the app
//
//  ** Props
//  @param children {node} - child container contents
//  @param direction {string} - list direction (column/row)
//  @param itemMargin {string} - list item margin
//  @param itemPadding {string} - list item padding
//
export const List = ({ children, direction, itemMargin, itemPadding }) => (
  <ListContainer direction={direction}>
    {React.Children.map(children, (child) => (
      <ListItem
        direction={direction}
        itemMargin={itemMargin}
        itemPadding={itemPadding}
      >
        {child}
      </ListItem>
    ))}
  </ListContainer>
);

// ** Proptypes ***********************
List.propTypes = {
  children: node,
  direction: string,
  itemMargin: string,
  itemPadding: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Column list *********************
export const ColumnList = withProps((props) => ({
  children: props.children,
  itemPadding: props.itemPadding,
}))(List);

// ** Row list ************************
export const RowList = withProps((props) => ({
  children: props.children,
  direction: "row",
  itemMargin: props.itemMargin,
  itemPadding: props.itemPadding,
}))(List);
