import React from "react";
import PropTypes from "prop-types";

import "./CheckBox.scss";

/*
Checkbox with style classes.
helpTargetId can be used for displaying question mark icon and
attaching tooltip to it.
*/
const CheckBox = ({ checked, disabled = false, onChange, title }) => (
  <div className="blocking-checkbox">
    <input
      type="checkbox"
      className="blocking-checkbox-input"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />

    <div className="blocking-checkbox-label">{title}</div>
  </div>
);

CheckBox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

CheckBox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
  title: "",
};

export default CheckBox;
