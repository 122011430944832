// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { func, number, oneOfType, string } from "prop-types";

// ** Styles **************************
import { OptionBoldTextStyle, OptionContainerStyle } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Select option component
//
//  @param filterValue - filter value
//  @param label - option display label
//  @param value - option value
//
export const Option = ({ filterValue, label, onClick, value }) => (
  <OptionContainerStyle
    key={label}
    onMouseDown={() => onClick({ value: value, label: label })}
    tabIndex={-1}
  >
    <OptionBoldTextStyle>
      {label && label.substring(0, filterValue && filterValue.length)}
    </OptionBoldTextStyle>
    {label && label.substring(filterValue && filterValue.length)}
  </OptionContainerStyle>
);

// ** Proptypes ***********************
Option.propTypes = {
  filterValue: string,
  label: string,
  onClick: func,
  value: oneOfType([number, string]),
};
