import React from "react";
import PropTypes from "prop-types";

import { Tooltip } from "reactstrap";

import FormFieldHelp from "./FormFieldHelp";

import "./HelpToolTip.scss";

// Form help tooltip - pass tooltip content as children
const HelpToolTip = ({
  children,
  targetId,
  isOpen,
  toggle,
  className,
  placement,
}) => (
  <React.Fragment>
    <Tooltip
      className={className}
      innerClassName="blumira-help-tooltip"
      hideArrow
      placement={placement}
      isOpen={isOpen}
      target={targetId}
      toggle={toggle}
    >
      {children}
    </Tooltip>

    <FormFieldHelp targetId={targetId} />
  </React.Fragment>
);

// PropTypes
HelpToolTip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  placement: PropTypes.string,
  targetId: PropTypes.string,
  toggle: PropTypes.func,
};

HelpToolTip.defaultProps = {
  children: null,
  className: "",
  isOpen: false,
  placement: "bottom",
  targetId: "",
  toggle: () => {},
};

export default HelpToolTip;
