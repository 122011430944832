import { graphParamsFromDelta } from "../../../views/Pages/Resources/common/LogdevGraph";
import { millisecFromRelative } from "../../utils/resourcesTimeUtils";
import { INTERVAL_TYPE, timeParamsFromInterval } from "./TimeInterval";
import {
  receiveData,
  receiveDates,
  receiveError,
  receiveParams,
} from "./GraphAndTable";

// Returns 'histogram_bucket' and other graph parameters
// based on the selectedInterval
export const getGraphParams = (
  selectedInterval,
  paramsFromDelta = () => {}
) => {
  const { dateRange, intervalType, relativeEnd, relativeStart } =
    selectedInterval;

  let delta;

  switch (intervalType) {
    case INTERVAL_TYPE.CUSTOM: {
      const { timeEnd, timeStart } = dateRange;

      const end = timeEnd.valueOf();
      const start = timeStart.valueOf();

      delta = end - start;

      break;
    }
    case INTERVAL_TYPE.RELATIVE_INTERVAL: {
      const end = millisecFromRelative(relativeEnd);
      const start = millisecFromRelative(relativeStart);

      delta = start - end;

      break;
    }

    case INTERVAL_TYPE.RELATIVE_START: {
      delta = millisecFromRelative(relativeStart);

      break;
    }

    default: {
      delta = 86400000; // 24 hours
    }
  }

  return paramsFromDelta(delta);
};

// Creates FEA API request query from the given params
export const getQueryGraph = ({
  limit,
  orgId,
  page,
  pageSize,
  paramsFromDelta = graphParamsFromDelta,
  selectedInterval,
}) => {
  const { histogramBucket } = getGraphParams(selectedInterval, paramsFromDelta);

  const { intervalType } = selectedInterval;
  const timeParams = timeParamsFromInterval(selectedInterval);

  const query = {
    histogramBucket,
    intervalType,
    limit,
    orgId,
    page,
    pageSize,
    ...timeParams,
  };

  return query;
};

// Dispatches actions to receive all - graphByIdOrOrg, graphDates, etc.
const receiveGraphAll =
  (actionTypePrefix, paramsFromDelta) =>
  ({ data, dates, insertId, selectedInterval }) =>
  (dispatch) => {
    const graphParams = getGraphParams(selectedInterval, paramsFromDelta);

    dispatch(
      receiveParams(actionTypePrefix)({
        data: graphParams,
        insertId,
        selectedInterval,
      })
    );

    dispatch(
      receiveDates(actionTypePrefix)({
        data: dates,
        insertId,
        selectedInterval,
      })
    );

    dispatch(
      receiveData(actionTypePrefix)({
        data,
        insertId,
        selectedInterval,
      })
    );
  };

export const getGraphActions = (actionTypePrefix, paramsFromDelta) => ({
  receiveGraphAll: receiveGraphAll(actionTypePrefix, paramsFromDelta),
  receiveError: receiveError(actionTypePrefix),
});
