// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** List container ******************
export const ListContainer = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: ${(p) => p.direction || "column"};
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
`;

// ** List item container *************
export const ListItem = styled.li`
  border-bottom: ${(p) =>
    p.direction === "row" ? "none" : `1px solid ${COLOR.gray100}`};
  padding: ${(p) => p.itemPadding || "16px 0px"};
  margin: ${(p) => p.itemMargin || "0px"};
  width: ${(p) => (p.direction === "row" ? "auto" : "100%")};

  &:first-child {
    padding-left: 0px;
  }

  &:last-child {
    border-bottom: none;
    padding-right: 0px;
  }
`;
