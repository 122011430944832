const ssoConnectionSchema = {
  $id: "/ssoconnection",
  title: "SSOConnection",
  type: "object",
  properties: {
    name: { type: "string" },
    domain: { type: "string" },
    signingCert: { type: "string" },
    signInEndpoint: { type: "string" },
    signOutEndpoint: { type: "string" },
    created: { type: "string", readOnly: true },
    id: { type: "string", format: "uuid", readOnly: true },
  },
  required: ["domain", "signingCert", "signInEndpoint", "signOutEndpoint"],
  additionalProperties: false,
};

export default ssoConnectionSchema;
