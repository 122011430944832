import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { startPoll, stopPoll, POLL_STATE } from "redux/actions/Polling";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import NotificationsIcon from "@mui/icons-material/Notifications";

import { INTEGRATION_STATUS } from "../../Components/CloudModules/constants";
import PropTypes from "prop-types";

import CloudModuleDialog from "views/Components/CloudModules/CloudModuleDialog";
import SAConnectorInfoDialog from "views/Components/CloudModules/SAConnectorInfoDialog";

import SimpleTable from "views/Components/SimpleTable";

import moment from "moment-timezone";
import { loadPageData } from "../../../redux/actions/Request";

import { IntegrationLogo } from "./IntegrationLogo";

import { CircularProgress, Badge, Button, Alert } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { renderModifiedCell } from "utils";

import { IntegrationAPI } from "lib/api/IntegrationAPI";

const API = {
  integration: new IntegrationAPI(),
};

const PREFIX = "CloudConnectorsPage";

const classes = {
  moduleOptionsContainer: `${PREFIX}-moduleOptionsContainer`,
  moduleButton: `${PREFIX}-moduleButton`,
  moduleOptionContainer: `${PREFIX}-moduleOptionContainer`,
  textContainer: `${PREFIX}-textContainer`,
  moduleTitleText: `${PREFIX}-moduleTitleText`,
  moduleSubtitleText: `${PREFIX}-moduleSubtitleText`,
  form: `${PREFIX}-form`,
  dialogActions: `${PREFIX}-dialogActions`,
  dialogActionsBtnContainer: `${PREFIX}-dialogActionsBtnContainer`,
  alert: `${PREFIX}-alert`,
  error: `${PREFIX}-error`,
  container: `${PREFIX}-container`,
  circle: `${PREFIX}-circle`,
  progress: `${PREFIX}-progress`,
  alertContainer: `${PREFIX}-alertContainer`,
  connectorDetailsButtonContainer: `${PREFIX}-connectorDetailsButtonContainer`,
  connectorDetailsButton: `${PREFIX}-connectorDetailsButton`,
  connectorDetailsBadge: `${PREFIX}-connectorDetailsBadge`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.moduleOptionsContainer}`]: {
    display: "flex",
    paddingBottom: 20,
  },
  [`& .${classes.moduleButton}`]: {
    border: `1px solid ${theme.palette.divider}`,
    margin: 5,
    padding: 15,
  },
  [`& .${classes.moduleOptionContainer}`]: {
    width: 250,
    height: 100,
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    textAlign: "left",
  },
  [`& .${classes.textContainer}`]: {
    display: "flex",
    flexWrap: "no-wrap",
    flexDirection: "column",
  },
  [`& .${classes.moduleTitleText}`]: {
    margin: 0,
  },
  [`& .${classes.moduleSubtitleText}`]: {
    margin: 0,
    fontWeight: "bold",
  },
  [`& .${classes.form}`]: {
    width: 600,
    paddingTop: 10,
    "& fieldset": {
      padding: `0 !important`,
    },
  },
  [`& .${classes.dialogActions}`]: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.dialogActionsBtnContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.alert}`]: {
    margin: "10px 0",
    backgroundColor: theme.palette.info.secondary,
  },
  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.container}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.circle}`]: {
    minWidth: 15,
    height: 15,
    marginRight: 10,
    borderRadius: "100%",
  },
  [`& .${classes.progress}`]: {
    marginRight: 10,
  },
  [`& .${classes.alertContainer}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.connectorDetailsButtonContainer}`]: {
    position: "absolute",
    right: 30,
  },
  [`& .${classes.connectorDetailsButton}`]: {
    position: "relative",
    height: 50,
    fontWeight: "bold",
  },
  [`& .${classes.connectorDetailsBadge}`]: {
    position: "absolute",
    top: 5,
    right: 5,
  },
}));

const renderLogoCell = (column, model) => {
  return (
    <IntegrationLogo
      integrationType={model[column.field]}
      width="65"
      height="30"
    />
  );
};

const renderDateCell = (column, model) => {
  const timeValue = model[column.field];
  if (!timeValue) {
    return "Pending";
  }
  return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
};

const CloudModules = (props) => {
  const { integrations, orgColumnStats, ready, orgId } = props;

  const renderLogCountCell = (column, model) => {
    const thisOrgStats =
      orgColumnStats.find((stats) => stats.orgId === orgId) || {};
    const runningHistory = thisOrgStats.running_history || {};
    const runningRecent = thisOrgStats.running_recent || {};
    const historyLogs = (runningHistory[model.id] || [0])[0];
    const recentLogs = (runningRecent[model.id] || [0])[0];
    var logs = historyLogs + recentLogs;
    let n = 0;
    while (logs > 1000) {
      n += 1;
      logs = Math.floor(logs / 1000);
    }

    if (n === 0) {
      return logs;
    }

    if (n === 1) {
      return `${logs}K`;
    }

    if (n === 2) {
      return `${logs}M`;
    }

    if (n === 3) {
      return `${logs}B`;
    }

    if (n === 4) {
      return `${logs}T`;
    }
  };

  const renderStatusCell = (column, model) => {
    if (model[column.field] === INTEGRATION_STATUS.INIT) {
      return (
        <span className={classes.container}>
          <CircularProgress size={20} className={classes.progress} />
          <span>Initializing…</span>
        </span>
      );
    }

    if (model[column.field] === INTEGRATION_STATUS.OK) {
      return "Online";
    }

    if (model[column.field] === INTEGRATION_STATUS.ERROR) {
      if (model["integrationStatusMessage"]) {
        const errStr = "Error: ";
        const errMsg = <tt>{`${model["integrationStatusMessage"]}`}</tt>;
        return (
          <span>
            {errStr} {errMsg}
          </span>
        );
      } else {
        return "Error";
      }
    }

    return model[column.field];
  };

  const cloudModulesColumns = [
    {
      field: "integrationType",
      renderValue: renderLogoCell,
      searchable: false,
    },
    {
      title: "Cloud Connector Name",
      field: "integrationName",
      searchable: true,
    },
    {
      title: "Current Status",
      field: "integrationStatus",
      searchable: true,
      renderValue: renderStatusCell,
      icon: (column, model) => {
        if (model[column.field] === INTEGRATION_STATUS.INIT) {
          return null;
        } else {
          return (
            <FiberManualRecordIcon
              style={{
                color:
                  model[column.field] === INTEGRATION_STATUS.OK
                    ? "#3BB273"
                    : "#E76F51",
              }}
            />
          );
        }
      },
    },
    {
      title: "Total Logs",
      field: "totalLogs",
      searchable: false,
      renderValue: renderLogCountCell,
    },
    {
      title: "Last Status Update",
      field: "lastStatusUpdate",
      searchable: false,
      renderValue: renderDateCell,
    },
    {
      title: "Created",
      field: "created",
      searchable: false,
      renderValue: renderDateCell,
    },
    {
      title: "Last Modified",
      field: "modified",
      searchable: false,
      renderValue: renderModifiedCell,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isSADialogOpen, setIsSADialogOpen] = useState(false);
  const [isModuleDialogOpen, setIsModuleDialogOpen] = useState(false);
  const [cloudConnectorDetails, setCloudConnectorDetails] = useState([]);

  useEffect(() => {
    // connectorIdFromPayload prop is taken from the 'id1' in the page's payload
    // i.e. for a clickthrough event from the summary dashboard
    if (props.connectorIdFromPayload && integrations && props.ready) {
      // find the full model to set in state for viewing
      const selectedIntegrationFromPayload = integrations.find(
        (integration) => integration.id === props.connectorIdFromPayload
      );

      // if a matching model is found open dialog for viewing
      if (selectedIntegrationFromPayload)
        handleRowClick(selectedIntegrationFromPayload);
    }
  }, [props.connectorIdFromPayload, integrations, props.ready]);

  useEffect(() => {
    // for each integration
    props.integrations.map((integration) => {
      if (
        integration.integrationStatus === INTEGRATION_STATUS.INIT ||
        integration.lastStatusUpdate === null
      ) {
        if (
          props.polls[integration.id] &&
          props.polls[integration.id].state === POLL_STATE.STOPPED
        ) {
          // if the integration status is initialize and the poll is already registered, but stopped
          // start the poll
          return props.startPoll(integration, 5);
        } else if (!props.polls[integration.id]) {
          // if the integration status is initialize and there is no poll registered
          // start the poll
          return props.startPoll(integration, 5);
        }
        return null;
      } else {
        if (
          props.polls[integration.id] &&
          props.polls[integration.id].state === POLL_STATE.RUNNING
        ) {
          // if the integration status isn't initialize but there is a running poll
          // stop the poll
          return props.stopPoll([integration.id]);
        }
        return null;
      }
    });
  }, [props.integrations, props.polls]);

  const toggleModal = () => {
    setIsModuleDialogOpen(!isModuleDialogOpen);
  };

  const toggleSAModal = async () => {
    setIsSADialogOpen(!isSADialogOpen);
    if (!cloudConnectorDetails.length) {
      setIsLoading(true);
      try {
        const { data: saConnectorData } =
          await API.integration.getCloudConnectorDetails();
        const alphabetizedCCDetails =
          saConnectorData?.sort((a, b) => a.name.localeCompare(b.name)) || [];
        setCloudConnectorDetails(alphabetizedCCDetails);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
  };

  const handleRowClick = (model) => {
    setSelectedModel(model);
    toggleModal();
  };

  const handleNotifications = () => {
    window.open(`/${props.orgId}/user/alertsettings`);
  };

  const addAConnector = () => {
    setSelectedModel(null);
    toggleModal();
  };

  const isAddAConnectorDisabled = () => {
    // free and M365 orgs can add up to three cloud connectors
    if (
      (props.license.isTagged("limited-to-free") ||
        props.license.isTagged("limited-to-microsoft")) &&
      integrations?.length === 3
    )
      return true;

    return false;
  };

  const hasErrorIntegration = (integrations) => {
    return integrations.some(
      (integration) =>
        integration.integrationStatus === INTEGRATION_STATUS.ERROR
    );
  };

  return (
    <Root>
      {hasErrorIntegration(integrations) && (
        <Alert severity={"warning"} className={classes.alertContainer}>
          For troubleshooting help, click{" "}
          <a
            target={"_blank"}
            rel="noopener noreferrer"
            href={"https://blumira.help/troubleshooting"}
          >
            here
          </a>
          .
        </Alert>
      )}
      {props.superadmin && ready && (
        <div className={classes.connectorDetailsButtonContainer}>
          <Badge
            color={"primary"}
            badgeContent={"SA"}
            className={classes.connectorDetailsBadge}
          />
          <Button
            variant={"outlined"}
            className={classes.connectorDetailsButton}
            onClick={toggleSAModal}
          >
            Open Connector Details
          </Button>
        </div>
      )}
      <SimpleTable
        isNorthStar
        data={integrations}
        isFetching={!ready}
        rowClick={handleRowClick}
        columns={cloudModulesColumns}
        emptyText={<span>No connectors</span>}
        licenseRestriction={"integration.create"}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add Cloud Connector",
            onClick: addAConnector,
            disabled: isAddAConnectorDisabled(),
          },
          {
            icon: NotificationsIcon,
            tooltip: "Notification Settings",
            onClick: handleNotifications,
            dataCy: "notificationSettingsCCBtn",
          },
        ]}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit Cloud Connector",
            onClick: (event, model) => {
              handleRowClick(model);
            },
          },
        ]}
      />
      <CloudModuleDialog
        ready={props.ready}
        license={props.license}
        open={isModuleDialogOpen}
        toggleModal={toggleModal}
        editingModel={selectedModel}
        superadmin={props.superadmin}
        integrations={props.integrations}
        reload={(bool) => props.reload(bool)}
        integrationSchemas={props.integrationSchemas}
        connectorIdFromPayload={props.connectorIdFromPayload}
      />
      <SAConnectorInfoDialog
        isLoading={isLoading}
        open={isSADialogOpen}
        onClose={toggleSAModal}
        connectors={cloudConnectorDetails}
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    polls: state.request.polls,
    license: state.license,
    orgId: state.location.payload.orgId,
    connectorIdFromPayload: state.page.payload.id1,
    superadmin: state?.session?.settings?.user?.superadmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
    startPoll: (model, interval, autoResume) =>
      dispatch(startPoll(model, interval, autoResume)),
    stopPoll: (modelIds) => dispatch(stopPoll(modelIds)),
  };
};

CloudModules.propTypes = {
  integrations: PropTypes.array,
  integrationSchemas: PropTypes.array,
  orgColumnStats: PropTypes.array,
  ready: PropTypes.bool,
  superadmin: PropTypes.bool,
};

CloudModules.defaultProps = {
  integrations: [],
  integrationSchemas: [],
  orgColumnStats: [],
  ready: false,
  superadmin: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(CloudModules);
