import React, { useState } from "react";

import { getMetaItemDisplayName } from "./metaHelpers";
import HelpToolTip from "../../Components/HelpToolTip";
import Logger from "../../../lib/logger";

import "./MetaComponent.scss";

const logger = Logger("MetaField");

/*
Main component.
Returns an input field or text area for one meta field.
*/

export const MetaField = ({
  item,
  onChange = () => {},
  required = false,
  value = "",
}) => {
  const { description = "", type = 0 } = item;

  const label = getMetaItemDisplayName(item);

  const [key] = useState(
    `meta_component_key_${Math.random().toString().slice(2)}`
  );
  const [targetId] = useState(
    `meta_component_target_id_${Math.random().toString().slice(2)}`
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);

  // Tooltip toggle
  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  let field = null;

  switch (type) {
    case 0: {
      // An "env" type field requires an input box
      field = (
        <input
          type="text"
          className="sensor-input"
          value={value}
          onChange={onChange}
          required={required}
          placeholder={
            required ? "Enter a value (required)" : "Enter a value (optional)"
          }
        />
      );
      break;
    }

    case 1: {
      // A "file" type field requires a text area
      field = (
        <textarea
          className="sensor-textarea"
          value={value}
          onChange={onChange}
          required={required}
          placeholder={
            required ? "Enter a value (required)" : "Enter a value (optional)"
          }
        />
      );
      break;
    }

    default: {
      const err = new Error(`Unknown type code for meta item: ${item}`);
      logger.error(err);
    }
  }

  const metaComponent = field ? (
    <div key={key} className="sensor-module-meta-item">
      <div className="sensor-label">
        {label}
        {required && <span className="text-danger">*</span>}
      </div>

      {description && (
        <React.Fragment>
          <HelpToolTip
            className="sensor-modal-tooltip"
            targetId={targetId}
            isOpen={tooltipOpen}
            toggle={toggleTooltip}
          >
            <div>{description}</div>
          </HelpToolTip>
        </React.Fragment>
      )}

      <div>{field}</div>
    </div>
  ) : null;

  return metaComponent;
};
