import { handleApiError } from "../../utils/helpers";
import { DATA_RECEIVE, ERROR_RECEIVE, META_RECEIVE } from "./Strings";
import { timeParamsFromInterval } from "./TimeInterval";

/*
Constants and helpers
*/

const DATE_FILTERS_KEY_MAP = {
  created: { timeEnd: "createdBefore", timeStart: "createdAfter" },
  firstSeen: { timeEnd: "firstSeenBefore", timeStart: "firstSeenAfter" },
  lastSeen: { timeEnd: "lastSeenBefore", timeStart: "lastSeenAfter" },
};

export const FILTER_NOT_SET_STRING = "notSet";

const ORDER_BY_VALUES = {
  ASCENDING: "asc",
  DESCENDING: "desc",
};

const isEmpty = (obj) => !obj || !Object.keys(obj).length;

// Returns a shallow copy of the object with null and
// undefined properties omitted
export const noNullAndUndefined = (obj) => {
  const newObject = Object.keys(obj).reduce((accum, key) => {
    const value = obj[key];

    if (value !== null && value !== undefined) {
      accum[key] = value;
    }

    return accum;
  }, {});

  return newObject;
};

// A helper that checks if a table filter is void/unset
const filterIsUnset = (filter) =>
  isEmpty(filter) || filter.key === FILTER_NOT_SET_STRING || filter.unset;

// Returns query's date filters
const dateFiltersFromFilters = (filters) => {
  const filterParams = Object.keys(DATE_FILTERS_KEY_MAP).reduce(
    (accum, key) => {
      const filter = filters[key];

      // Exclude empty and unset filters
      if (filterIsUnset(filter)) {
        return accum;
      }

      const { value } = filter;

      const { timeEnd, timeStart } = value;

      const timeEndKey = DATE_FILTERS_KEY_MAP[key].timeEnd;
      const timeStartKey = DATE_FILTERS_KEY_MAP[key].timeStart;

      accum[timeEndKey] = timeEnd.toISOString();
      accum[timeStartKey] = timeStart.toISOString();

      return accum;
    },
    {}
  );

  return filterParams;
};

// Returns query's text filters
const getFiltersByKeys = (filters = {}, keys = []) => {
  const newFilters = keys.reduce((accum, key) => {
    const filter = filters[key];

    // Exclude empty and unset filters
    if (filterIsUnset(filter)) {
      return accum;
    }

    const { value } = filter;

    accum[key] = value;

    return accum;
  }, {});

  return newFilters;
};

// Returns the stringified filter object for the request query, or ''
const getFilterBy = ({ query, filterKeys }) => {
  const { filters } = query;

  if (isEmpty(filters)) {
    return "";
  }

  const dateFilters = dateFiltersFromFilters(filters);
  const otherFilters = getFiltersByKeys(filters, filterKeys);

  const filterByObj = {
    ...dateFilters,
    ...otherFilters,
  };

  const filterBy = JSON.stringify(filterByObj);

  return filterBy;
};

// Returns the stringified sorting object for the request query, or ''
const orderByFromSorting = (sorting) => {
  const entries = Object.entries(sorting);
  const entry = entries[0];

  if (!entry) {
    return "";
  }

  const [key, rawValue] = entry;
  const value = rawValue
    ? ORDER_BY_VALUES.ASCENDING
    : ORDER_BY_VALUES.DESCENDING;

  const orderBy = JSON.stringify({
    [key]: value,
  });

  return orderBy;
};

// Returns query's orderBy parameter
const getOrderBy = (query) => {
  let orderBy;

  const { sorting } = query;

  if (!isEmpty(sorting)) {
    orderBy = orderByFromSorting(sorting);
  } else {
    orderBy = JSON.stringify({
      lastSeen: "desc",
    });
  }

  return orderBy;
};

// Returns the selected interval's time parameters and the interval type
const getInterval = (query) => {
  const { selectedInterval } = query;

  if (isEmpty(selectedInterval)) {
    return {};
  }

  const intervalType = selectedInterval.intervalType;
  const timeParams = timeParamsFromInterval(selectedInterval);

  return {
    intervalType,
    ...timeParams,
  };
};

// Creates the table request query from the given params
export const getQueryData =
  (filterKeys = ["name", "address"]) =>
  (rawQuery) => {
    let query = { ...rawQuery };

    // Get filterBy
    const filterBy = getFilterBy({ query, filterKeys });
    delete query.filters;

    // Get orderBy
    const orderBy = getOrderBy(query);
    delete query.sorting;

    // Get the selected time interval
    const interval = getInterval(query);
    delete query.selectedInterval;

    query = {
      ...query,
      ...interval,
      filterBy,
      orderBy,
    };

    // Remove null and undefined
    query = noNullAndUndefined(query);

    return query;
  };

/*
Actions
*/

export const receiveData =
  (actionTypePrefix) =>
  ({ data, insertId }) => ({
    type: `${actionTypePrefix}_${DATA_RECEIVE}`,
    payload: {
      data,
      insertId,
    },
  });

export const receiveMeta =
  (actionTypePrefix) =>
  ({ data, insertId }) => ({
    type: `${actionTypePrefix}_${META_RECEIVE}`,
    payload: {
      data,
      insertId,
    },
  });

export const receiveError = (actionTypePrefix) => (error) => ({
  type: `${actionTypePrefix}_${ERROR_RECEIVE}`,
  payload: {
    error,
  },
});

export const fetchData =
  ({ actionTypePrefix, apiCall, getQuery = getQueryData() }) =>
  ({ orgId, rawQuery }) =>
  (dispatch) => {
    const query = getQuery(rawQuery);

    // Clear the error
    dispatch(receiveError(actionTypePrefix)(null));

    // Fetch the data
    apiCall(query)
      .then((response) => {
        const { data: respData = {} } = response;
        const { data, meta } = respData;

        dispatch(
          receiveData(actionTypePrefix)({
            data,
            insertId: orgId,
          })
        );

        dispatch(
          receiveMeta(actionTypePrefix)({
            data: meta,
            insertId: orgId,
          })
        );
      })
      .catch((err) => {
        const message = "Unable to fetch data.";

        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveError(actionTypePrefix),
          })
        );
      });
  };
