// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { bool, func, node } from "prop-types";

// ** Components **********************
import { MinimalButton } from "views/Components/Button";
import { ExIcon } from "views/Components/Icon";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import { ChildContainer, CloseContainer, Container } from "./style";

// *****************************************************************************
// Layout
// *****************************************************************************
//
//  Modal layout
//
//  ** Remarks
//  Simple modal component extended to simplify code across the app
//
//  ** Props
//  @param children {node} - child container contents
//  @param onToggleModal {func} - modal flag click handler
//  @param open {bool} - modal open/close flag
//
class Modal extends Component {
  componentDidMount() {
    window.addEventListener("keydown", this.toggleModal.bind(this));
  }

  toggleModal(e) {
    const { onToggleModal, open } = this.props;

    if (e.key === "Escape" && open) {
      onToggleModal(null);
    }
  }

  render() {
    const { children, onToggleModal, open } = this.props;

    return (
      <Container open={open}>
        <CloseContainer open={open}>
          <MinimalButton onClick={() => onToggleModal(null)}>
            <ExIcon height="20px" />
          </MinimalButton>
        </CloseContainer>
        <ChildContainer open={open}>{children}</ChildContainer>
      </Container>
    );
  }
}

// ** Proptypes ***********************
Modal.propTypes = {
  children: node,
  onToggleModal: func,
  open: bool,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Primary layout ******************
export const PrimaryModal = withProps((props) => ({
  children: props.children,
  onToggleModal: props.onToggleModal,
}))(Modal);
