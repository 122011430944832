import Request, {
  compareQueryParameters,
  QueryParameter,
} from "lib/api/Request";
import { POLL_STATE, pausePoll, resumePoll, stopPoll } from "./Polling";
import { PAGE_SUCCESS, PAGE_ERROR, PAGE_LOADING } from "./Page";
import uuid from "uuid";
import { childrenPageViews } from "views/pages";
import Moment from "moment-timezone";

export const REQUEST_GET = "REQUEST_GET";

export const REQUEST_EXPIRE_SECONDS = 3600;
export const REQUEST_STALE_SECONDS = 300;

export default class RequestAction {
  /**
   * @param {string} namespace - valid model/schema namespace
   * @param {array} queryParams (optional) - list of objects with key and value attributes: [{'key':'name.contains', 'value':'foo'}]
   * @param {number} expire (optional) - number of seconds until the request action is eligible for garbage collection
   * @param {number} stale (optional) - number of seconds until the request action will auto-reload upon request
   */
  constructor(params) {
    //validate input
    if (typeof params.namespace != "string") {
      throw new Error(`Invalid or missing namespace in RequestAction`);
    }

    if (params.queryParams === undefined) {
      params.queryParams = [];
    } else if (!Array.isArray(params.queryParams)) {
      throw new Error(`Invalid queryParams in RequestAction`);
    }

    if (params.resultParams === undefined) {
      params.resultParams = {};
    } else if (typeof params.resultParams !== "object") {
      throw new Error(`Invalid resultParams in RequestAction`);
    }

    if (params.expire === undefined) {
      params.expire = REQUEST_EXPIRE_SECONDS;
    } else if (typeof params.expire !== "number") {
      throw new Error(`Invalid expire in RequestAction`);
    }

    if (params.stale === undefined) {
      params.stale = REQUEST_STALE_SECONDS;
    } else if (typeof params.stale !== "number") {
      throw new Error(`Invalid stale in RequestAction`);
    }

    this.uuid = uuid.v4();
    this.request = new Request(
      params.namespace,
      params.queryParams,
      params.resultParams
    );
    this.__response = [];
    this.expire = params.expire;
    this.stale = params.stale;
    this.times = {
      create: new Moment(),
      read: undefined,
      update: undefined,
    };
  }

  get response() {
    this.times.read = new Moment();
    return this.__response;
  }

  isExpired() {
    if (this.expire < 0) {
      return false;
    }
    if (this.expire === 0) {
      return true;
    }
    const readTime = this.times.read || this.times.create;
    const now = new Moment();
    return now.diff(readTime, "seconds") >= this.expire;
  }

  isStale() {
    if (this.stale === 0) {
      return false;
    }
    if (!this.times.update) {
      return true;
    }
    const readTime = this.times.read || new Moment();
    const updateTime = this.times.update || new Moment();
    return readTime.diff(updateTime, "seconds") > this.stale;
  }

  handleResponse(response) {
    this.times.update = new Moment();
    this.__response = response;
    return response;
  }

  get() {
    return this.request.get().then((response) => this.handleResponse(response));
  }

  exportAs(exportType) {
    const columns = this.request.resultParams.outputFields || [];

    // if no columns detected, generate a list from the cached objects
    if (columns.length === 0) {
      const colHash = {};
      this.__response.forEach((m) =>
        Object.keys(m).forEach((c) => (colHash[c] = true))
      );
      Object.keys(colHash).forEach((c) => columns.push(c));
    }
    const objList = this.__response.map((m) =>
      Object.fromEntries(columns.map((c) => [c, m[c]]))
    );

    if (exportType === "csv") {
      const csvData = objList.map((o) => columns.map((c) => o[c]));
      return csvData
        .map((e) => e.map((v) => JSON.stringify(v)).join(","))
        .join("\n");
    } else if (exportType === "jsonl") {
      return objList.map((m) => JSON.stringify(m)).join("\n");
    } else if (exportType === "json") {
      return JSON.stringify(objList);
    } else {
      throw new Error(`Invalid export type: ${exportType}`);
    }
  }
}

export const requestActionSelector = (state, alias) => {
  const pageConfig = childrenPageViews.find(
    (pg) =>
      pg.route ===
      `${state.page.payload.toplevel}/${state.page.payload.secondlevel}`
  );
  if (
    state.request.pageCache[pageConfig.route] &&
    state.request.pageCache[pageConfig.route][alias] &&
    state.request.requests[state.request.pageCache[pageConfig.route][alias]]
  ) {
    return state.request.requests[
      state.request.pageCache[pageConfig.route][alias]
    ];
  } else {
    return false;
    //throw new Error(`Invalid query alias: ${alias}.  Did you try to access it before page load?`)
  }
};

export const exportRequestAction =
  (alias, exportType) => (dispatch, getState) => {
    const requestAction = requestActionSelector(getState(), alias);
    if (requestAction) {
      return requestAction.exportAs(exportType);
    } else {
      throw new Error(
        `Invalid query alias: ${alias}.  Did you try to access it before page load?`
      );
    }
  };

const handlePageLoading = ({ requestMap, pageConfig, dispatch }) => {
  const res = Object.fromEntries(
    Object.keys(requestMap).map((a) => [a, requestMap[a].uuid])
  );
  dispatch({
    type: PAGE_LOADING,
    responseUUIDs: res,
    route: pageConfig.route,
  });
};

export const loadPageData = (force) => (dispatch, getState) => {
  /**
   * @param {string}  force (optional) - If set to an alias name, that alias will force a refetch.
   * @param {boolean} force (optional) - If set to true, will force all to refetch
   */
  const state = getState();
  const pageConfig = childrenPageViews.find(
    (pg) =>
      pg.route ===
      `${state.page.payload.toplevel}/${state.page.payload.secondlevel}`
  );

  // handle polling pause / unpause
  const pausePolls = [];
  const resumePolls = [];
  const stopPolls = [];

  Object.keys(state.request.polls).forEach((modelId) => {
    if (state.request.polls[modelId].route === pageConfig.route) {
      // This poll matches the current page
      if (
        state.request.polls[modelId].state === POLL_STATE.PAUSED &&
        state.request.polls[modelId].autoResume
      ) {
        resumePolls.push(modelId);
      }
    } else {
      // This poll does not match the current page
      if (!state.request.polls[modelId].autoResume) {
        stopPolls.push(modelId);
      } else if (state.request.polls[modelId].state === POLL_STATE.RUNNING) {
        pausePolls.push(modelId);
      }
    }
  });

  if (pausePolls.length > 0) {
    dispatch(pausePoll(pausePolls));
  }

  if (resumePolls.length > 0) {
    dispatch(resumePoll(resumePolls));
  }

  if (stopPolls.length > 0) {
    dispatch(stopPoll(stopPolls));
  }

  if (pageConfig.requirements) {
    const requests = pageConfig.requirements(state);

    const requestMap = {};
    Object.keys(requests).forEach((alias) => {
      // TODO: this "if" is a bit ugly. we should clean this up my insuring that the values are already initialized

      // Step 1: if: check if alias is already mapped to request
      //    - add to exec list
      if (
        state.request.pageCache[pageConfig.route] &&
        state.request.pageCache[pageConfig.route][alias] &&
        state.request.requests[state.request.pageCache[pageConfig.route][alias]]
      ) {
        requestMap[alias] =
          state.request.requests[
            state.request.pageCache[pageConfig.route][alias]
          ];
      } else {
        const queryParams = Object.values(requests)
          .filter((qp) => qp.params)
          .map((qp) =>
            qp.params.map((q) => new QueryParameter(q.key, q.value))
          );
        const validMatch = Object.values(state.request.requests).find((r) =>
          compareQueryParameters(r.request, {
            namespace: requests[alias].namespace,
            queryParams,
          })
        );
        if (validMatch) {
          // Step 2: else if: check to see if there is a requirement that already fits
          //    - add exec to list
          requestMap[alias] = validMatch;
        } else {
          // Step 3: else: create new RequestAction, add to exec list
          requestMap[alias] = new RequestAction(requests[alias]);
          requestMap[alias].request.setDefaults();
        }
      }
      if (requestMap[alias] && force === alias) {
        requestMap[alias].request.dirty = true;
      }
    });

    // check over existing queries to see if they need to be ran
    for (let [alias, rUUID] of Object.entries(
      state.request.pageCache[pageConfig.route] || {}
    )) {
      if (!requestMap[alias]) {
        requestMap[alias] = state.request.requests[rUUID];
      }
    }

    // Refresh Stale Data
    Object.values(requestMap).forEach((r) => {
      if (r.isStale() || force === true) {
        r.request.dirty = true;
      }
    });

    const _apiRequests = Object.values(requestMap).filter(
      (r) => r.request.dirty
    );
    if (_apiRequests.length > 0) {
      const p = Promise.all(
        _apiRequests.map((r) =>
          r.get().catch((e) => dispatch({ type: PAGE_ERROR, error: e }))
        )
      );

      handlePageLoading({ requestMap, pageConfig, dispatch });

      p.then((results) => {
        dispatch({ type: REQUEST_GET, requests: _apiRequests });
        dispatch({ type: PAGE_SUCCESS });
      });

      return p;
    } else {
      // ensure that pageCache gets updated with
      // this route's request UUIds
      handlePageLoading({ requestMap, pageConfig, dispatch });
      dispatch({ type: PAGE_SUCCESS });
      return Promise.resolve();
    }
  } else {
    dispatch({ type: PAGE_SUCCESS });
  }
};
