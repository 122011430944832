// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Conditional container ***********
export const ConditionalContainer = styled.div`
  left: 24px;
  position: absolute;
  top: ${(p) => `${p.top}px`};
`;

// ** Outer container *****************
export const FilterContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  width: 100%;
`;

// ** Icon container ******************
export const IconContainer = styled.button`
  background-color: transparent;
  border: none;
  fill: ${COLOR.blumiraBlue800};
  margin: 0px;
  outline: none !important;
  padding: 4px;
  transition: all 300ms;

  &:hover {
    fill: ${COLOR.blumirarRed};
  }
`;

// ** Relative container **************
export const RelativeContainer = styled.div`
  border: 1px solid ${COLOR.gray100};
  border-right: none;
  border-radius: 3px;
  height: 53px;
  position: relative;
  width: 24px;
`;

// ** Row *****************************
export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 8px;
  transition: width 300ms;
  width: ${(p) => (p.multiple ? "90%" : "100%")};
`;

// ** Row section *********************
export const RowSection = styled.div`
  flex: 1;
  margin-right: 8px;
`;

// ** Tag *****************************
export const Tag = styled.div`
  background-color: rgba(105, 180, 255, 0.15);
  border: 1px solid ${COLOR.blumiraBlue700};
  border-radius: 3px;
  color: ${COLOR.blumiraBlue700};
  font-size: 9px;
  font-weight: 500;
  padding: 2px 4px 0px;
  position: absolute;
  left: -15px;
  transform: translateY(-50%);
  top: 50%;
`;
