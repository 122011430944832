// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { func, oneOfType, shape, string } from "prop-types";

// ** Componets ***********************
import Dropdown from "views/Components/Dropdown";
import { PencilIcon } from "views/Components/Icon";

// ** Constants ***********************
import { HOURS, MIN_SEC } from "utils/constants/timeValues";

// ** Style ***************************
import {
  ScrollColumn,
  ScrollContainer,
  ScrollOverflow,
  SelectionIconContainer,
  Time,
  TimeContainer,
  TimeItem,
  TimeScrollContainer,
} from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Time selection component
//
//  ** Remarks
//  Datepicker time selection step
//
//  ** Props
//  @param date {obj} - date obj
//  @param onTimeChange {func} - invoked when time value is clicked to update
//  @param tag {string} - denotes if it is the start/end date obj
//
class Timepicker extends Component {
  constructor(props) {
    super(props);

    this.hourRef = React.createRef();
    this.minRef = React.createRef();
    this.secRef = React.createRef();
  }

  componentDidMount() {
    const { date } = this.props;

    if (date && date !== "") {
      const offset = this.calcOffset(date);

      this.hourRef.current.scrollTo(0, offset.hour);
      this.minRef.current.scrollTo(0, offset.min);
      this.secRef.current.scrollTo(0, offset.sec);
    }
  }

  componentDidUpdate(prevProps) {
    const { date, timeFormat } = this.props;

    if (prevProps.date !== date || prevProps.timeFormat !== timeFormat) {
      const offset = this.calcOffset(date);

      this.hourRef.current.scrollTo(0, offset.hour);
      this.minRef.current.scrollTo(0, offset.min);
      this.secRef.current.scrollTo(0, offset.sec);
    }
  }

  calcOffset(date) {
    const { renderDate } = this.props;
    const offset = {};

    // Reference values
    const height = 28;
    const currentHour = parseInt(renderDate(date).format("h"), 10);
    const currentMin = parseInt(renderDate(date).format("m"), 10);
    const currentSec = parseInt(renderDate(date).format("s"), 10);

    if (currentHour < 8) {
      offset.hour = (currentHour - 1) * height;
    } else {
      offset.hour = 8 * height;
    }

    if (currentMin < 55) {
      offset.min = currentMin * height;
    } else {
      offset.min = 55 * height;
    }

    if (currentSec < 55) {
      offset.sec = currentSec * height;
    } else {
      offset.sec = 55 * height;
    }

    return offset;
  }

  render() {
    const { date, onTimeChange, renderDate, tag } = this.props;

    return (
      <Dropdown
        btnContents={
          <TimeContainer>
            <Time>
              {date && date !== "" ? renderDate(date).format("hh:mm:ss a") : ""}
            </Time>
            {date && date !== "" && (
              <SelectionIconContainer margin="0px 0px 0px 8px">
                <PencilIcon height="10px" />
              </SelectionIconContainer>
            )}
          </TimeContainer>
        }
      >
        {() => (
          <ScrollContainer>
            <ScrollOverflow>
              <ScrollColumn ref={this.hourRef}>
                <TimeScrollContainer offset={-140}>
                  {HOURS.map((item) => (
                    <TimeItem
                      key={item}
                      active={
                        date &&
                        date !== "" &&
                        renderDate(date).format("h") === item
                      }
                      disabled={
                        date &&
                        date !== "" &&
                        renderDate(date).format("h") === item
                      }
                      onClick={() => onTimeChange(item, "hr", tag)}
                    >
                      {item}
                    </TimeItem>
                  ))}
                </TimeScrollContainer>
              </ScrollColumn>
            </ScrollOverflow>
            <ScrollOverflow>
              <ScrollColumn ref={this.minRef}>
                <TimeScrollContainer>
                  {MIN_SEC.map((item) => (
                    <TimeItem
                      key={item}
                      active={
                        date &&
                        date !== "" &&
                        renderDate(date).format("mm") === item
                      }
                      disabled={
                        date &&
                        date !== "" &&
                        renderDate(date).format("mm") === item
                      }
                      onClick={() => onTimeChange(item, "min", tag)}
                    >
                      {item}
                    </TimeItem>
                  ))}
                </TimeScrollContainer>
              </ScrollColumn>
            </ScrollOverflow>
            <ScrollOverflow>
              <ScrollColumn ref={this.secRef}>
                <TimeScrollContainer>
                  {MIN_SEC.map((item) => (
                    <TimeItem
                      key={item}
                      active={
                        date &&
                        date !== "" &&
                        renderDate(date).format("ss") === item
                      }
                      disabled={
                        date &&
                        date !== "" &&
                        renderDate(date).format("ss") === item
                      }
                      onClick={() => onTimeChange(item, "sec", tag)}
                    >
                      {item}
                    </TimeItem>
                  ))}
                </TimeScrollContainer>
              </ScrollColumn>
            </ScrollOverflow>
            <ScrollOverflow>
              <ScrollColumn>
                <TimeScrollContainer>
                  <TimeItem
                    active={
                      date &&
                      date !== "" &&
                      renderDate(date).format("a") === "am"
                    }
                    disabled={
                      date &&
                      date !== "" &&
                      renderDate(date).format("a") === "am"
                    }
                    onClick={() => onTimeChange("am", "other", tag)}
                  >
                    am
                  </TimeItem>
                  <TimeItem
                    active={
                      date &&
                      date !== "" &&
                      renderDate(date).format("a") === "pm"
                    }
                    disabled={
                      date &&
                      date !== "" &&
                      renderDate(date).format("a") === "pm"
                    }
                    onClick={() => onTimeChange("pm", "other", tag)}
                  >
                    pm
                  </TimeItem>
                </TimeScrollContainer>
              </ScrollColumn>
            </ScrollOverflow>
          </ScrollContainer>
        )}
      </Dropdown>
    );
  }
}

// ** Proptypes ***********************
Timepicker.propTypes = {
  date: oneOfType([shape(), string]),
  onTimeChange: func,
  renderDate: func.isRequired,
  tag: string,
  timeFormat: string,
};

export default Timepicker;
