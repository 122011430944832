import { get } from "lodash";

import { HubspotAPI } from "../../../lib/api/HubspotAPI";

const API = {
  hubspot: new HubspotAPI(),
};

const mspMarketType = 20;

export const getChildOrgs = (orgListToFilter, orgIdToFilter) => {
  return orgListToFilter.filter(
    (obj) => obj.parentId === orgIdToFilter && obj.market === mspMarketType
  );
};

// given a user obj, returns array of orgRoles
export const getOrgRoles = (user) => {
  const orgRolesToReturn = get(user, ["orgRoles"], []);
  return orgRolesToReturn;
};

export const getShouldDisplayDataRetentionMessage = (
  newLicenseValue,
  orgModel
) => {
  const existingLicenseValue = get(orgModel, ["config", "license"], "FREE");

  if (existingLicenseValue === "ADVANCED") {
    if (newLicenseValue === "M365") return true;
    if (newLicenseValue === "FREE") return true;
  }

  if (existingLicenseValue === "CLOUD") {
    if (newLicenseValue === "M365") return true;
    if (newLicenseValue === "FREE") return true;
  }

  if (existingLicenseValue === "M365") {
    if (newLicenseValue === "FREE") return true;
  }

  return false;
};

export const getShouldDisplayAgentSeatCountMessage = (newLicenseValue) => {
  // only show the helper text referencing agent seat count
  // for advanced, xdr, and siem endpoint licenses
  if (newLicenseValue === "ADVANCED") return "ADVANCED";
  if (newLicenseValue === "XDR") return "XDR";
  if (newLicenseValue === "SIEM_ENDPOINT") return "SIEM_ENDPOINT";

  return "";
};

export const getHubspotContactEmails = async () => {
  try {
    // get the Company's data tied to this blumira org
    const { data: company } = await API.hubspot.getCompany();
    let emailStringToReturn = "";

    // if the company has existing contacat emails use 'em
    if (company.billing_contact_email) {
      emailStringToReturn = company.billing_contact_email;
    }

    return emailStringToReturn;
  } catch (e) {
    // Swallow error.
  }
};
