// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "../../../utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Breadcrumb **********************
export const Breadcrumb = styled.span`
  color: ${COLOR.blumiraBlue900};
  font-size: 11px;
  font-weight: 600;
  line-height: 18px;
`;

// ** Outer container *****************
export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

// ** Icon container ******************
export const IconContainer = styled.div`
  fill: #124d9e;
  margin: -3px 6px 0px;
`;
