import React, { useEffect, useState } from "react";

import {
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  IconButton,
  Link,
  TextField,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "@mui/icons-material/Send";

import { StyledDialog, classes } from "./styles";
import { mspIntegrationMetadata } from "./constants";
import { MSPIntegrationLogo } from "views/Pages/MSPPortal/MSPIntegrationLogo";

import { PSAIntegrationTest } from "lib/models/PSAIntegrationTest";
import { PSASimulateFinding } from "lib/models/PSASimulateFinding";
import MSPIntegrationAccountMapping from "./AccountMapping";
import MSPTicketFlow from "./TicketFlow";
import { PSAIntegration } from "lib/models/PSAIntegration";
import { StyledSteps } from "./TicketFlow/styles";

import findingDetailsImage from "../../../../images/finding-details-screenshot.png";

const betaMSPIntegrationTooltipText =
  "Donut I love apple pie I love jelly-o I love I love lemon drops pie. Carrot cake caramels I love gummies biscuit macaroon marshmallow."; // TODO: figure out what this beta text needs to be...until then enjoy some cupcake ipsum :)

const BLUMIRA_PRIORITIES = [
  { value: "P1", label: "Priority 1" },
  { value: "P2", label: "Priority 2" },
  { value: "P3", label: "Priority 3" },
];

const MSPIntegrationDialog = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [catchallId, setCatchallId] = useState("");
  const [companyMapping, setCompanyMapping] = useState([]);
  const [isConnectionButtonDisabled, setIsConnectionButtonDisabled] =
    useState(true);
  const [isTestSuccessful, setIsTestSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedModel, setSelectedModel] = useState(null);
  const [isTestingConnection, setIsTestingConnection] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isIntegrationConnected, setIsIntegrationConnected] = useState(false);
  const [serviceBoard, setServiceBoard] = useState("");
  const [serviceBoardDisplayText, setServiceBoardDisplayText] = useState("");
  const [expandAccordionOne, setExpandAccordionOne] = useState(true);
  const [expandAccordionTwo, setExpandAccordionTwo] = useState(false);
  const [expandAccordionThree, setExpandAccordionThree] = useState(false);
  const [expandAccordionFour, setExpandAccordionFour] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [defaultEmailError, setDefaultEmailError] = useState(false);
  const [ticketSyncing, setTicketSyncing] = useState({});
  const [orgToSimulateFrom, setOrgToSimulateFrom] = useState("");
  const [
    isFindingSimulationButtonDisabled,
    setIsFindingSimulationButtonDisabled,
  ] = useState(false);
  const [priorityToSimulateFrom, setPriorityToSimulateFrom] = useState("P1");
  const [typeToSimulateFrom, setTypeToSimulateFrom] = useState("Threat");
  const [priorityDisplayText, setPriorityDisplayText] = useState("Priority 1");
  const [isSendingSample, setIsSendingSample] = useState(false);
  const [findingTypes, setFindingTypes] = useState({});

  useEffect(() => {
    if (props.editingModel) {
      setSelectedModel(props.editingModel);
      setCurrentStep(1);
    }
  }, [props.editingModel, props.open]);

  useEffect(() => {
    // If one of the fields doesn't have a value, make sure the button is disabled
    if (
      !domainName.length ||
      !companyId.length ||
      !publicKey.length ||
      !privateKey.length
    ) {
      setIsConnectionButtonDisabled(true);
    } else {
      setIsConnectionButtonDisabled(false);
    }
  }, [domainName, companyId, publicKey, privateKey]);

  useEffect(() => {
    // If this field doesn't have a value, make sure the button is disabled
    if (!orgToSimulateFrom.length) {
      setIsFindingSimulationButtonDisabled(true);
    } else {
      setIsFindingSimulationButtonDisabled(false);
    }
  }, [orgToSimulateFrom]);

  useEffect(() => {
    if (selectedModel && selectedModel.id) {
      if (selectedModel.manageUrl) {
        setDomainName(selectedModel.manageUrl);
      }

      if (selectedModel.companyId) {
        setCompanyId(selectedModel.companyId);
      }

      if (selectedModel.defaultCompany) {
        setCatchallId(selectedModel.defaultCompany);
      }

      if (selectedModel.companyMappings) {
        setCompanyMapping(selectedModel.companyMappings);
      }

      if (selectedModel.defaultBoard) {
        const serviceBoardName = props.serviceBoards.filter(
          (board) => board.id === selectedModel.defaultBoard
        ).name;

        setServiceBoard(selectedModel.defaultBoard);
        setServiceBoardDisplayText(serviceBoardName);
      }

      if (selectedModel.defaultEmail) {
        setDefaultEmail(selectedModel.defaultEmail);
      }

      if (selectedModel.statusSyncing) {
        const { statusSyncing } = selectedModel;
        setTicketSyncing((prevState) => ({ ...prevState, statusSyncing }));
      }

      if (selectedModel.typeSyncing) {
        const { typeSyncing } = selectedModel;
        setTicketSyncing((prevState) => ({ ...prevState, typeSyncing }));
      }

      if (selectedModel.prioritySyncing) {
        const { prioritySyncing } = selectedModel;
        setTicketSyncing((prevState) => ({ ...prevState, prioritySyncing }));
      }

      if (selectedModel.statusMappings) {
        const { statusMappings } = selectedModel;
        const updatedStatusMappings = !statusMappings.length
          ? [
              {
                blumiraStatus: "",
                psaStatus: "",
              },
            ]
          : statusMappings;
        setTicketSyncing((prevState) => ({
          ...prevState,
          statusMappings: updatedStatusMappings,
        }));
      }

      if (selectedModel.priorityMappings) {
        const { priorityMappings } = selectedModel;
        const updatedPriorityMappings = !priorityMappings.length
          ? [
              {
                blumiraPriority: "",
                psaPriority: "",
              },
            ]
          : priorityMappings;
        setTicketSyncing((prevState) => ({
          ...prevState,
          priorityMappings: updatedPriorityMappings,
        }));
      }

      if (selectedModel.typeMappings) {
        const { typeMappings } = selectedModel;
        const updatedTypeMappings = !typeMappings.length
          ? [
              {
                blumiraType: "",
                psaType: "",
              },
            ]
          : typeMappings;
        setTicketSyncing((prevState) => ({
          ...prevState,
          typeMappings: updatedTypeMappings,
        }));
      }
    }
  }, [selectedModel]);

  useEffect(() => {
    // Remove finding type 40: Alert from list shown to users in accordion 5
    const filteredTypes = Object.fromEntries(
      Object.entries(props.findingTypes).filter(
        ([key, value]) => value !== "Alert"
      )
    );

    setFindingTypes(filteredTypes);
  }, [props.findingTypes]);

  useEffect(() => {
    const priorityToDisplay = BLUMIRA_PRIORITIES.filter(
      (p) => p.value === priorityToSimulateFrom
    );

    setPriorityDisplayText(priorityToDisplay[0].label);
  }, [priorityToSimulateFrom, typeToSimulateFrom]);

  const getMSPIntegrationList = () => {
    // Only show integrations that are generally available OR the the org has the beta license feature enabled for
    return mspIntegrationMetadata.filter(
      (module) => !module.betaChip || props.license.hasFeature(module?.betaChip)
    );
  };

  const isModuleInBeta = (module) => {
    const { integrationType = "" } = module; // eslint-disable-line no-unused-vars

    return false;
  };

  const isModuleNew = (module) => {
    const { integrationType = "" } = module; // eslint-disable-line no-unused-vars

    return false;
  };

  const handleSelectModule = (module) => {
    setSelectedModel(new PSAIntegration());
    setIsIntegrationConnected(false);
    setCurrentStep(1);
  };

  const handleDomainNameChange = (event) => {
    setDomainName(event.target.value);
  };

  const handlePublicKeyChange = (event) => {
    setPublicKey(event.target.value);
  };

  const handlePrivateKeyChange = (event) => {
    setPrivateKey(event.target.value);
  };

  const handleCompanyIdChange = (event) => {
    setCompanyId(event.target.value);
  };

  const IntegrationButton = (props) => {
    return (
      <Grid item xs={3} className={classes.buttonsGrid}>
        <Button
          variant={"outlined"}
          disabled={props.disabled}
          className={classes.moduleButton}
          datacy={props.module.integrationType}
          onClick={() => {
            // reference paidCta prop so that user can still
            // select to click on the cta link in the card
            if (props.paidCta) return;
            handleSelectModule(props.module);
          }}
        >
          <div className={classes.moduleOptionContainer}>
            <div className={classes.logoCtaContainer}>
              <div className={classes.logoContainer}>
                <MSPIntegrationLogo
                  integrationType={props.module.integrationType}
                  width={120}
                  height={66}
                />
              </div>
            </div>
            <div className={classes.textContainer}>
              <p className={classes.moduleTitleText}>{props.module.title}</p>
              <p className={classes.moduleSubtitleText}>
                {props.module.subtitle}
              </p>
            </div>
          </div>
        </Button>
        {props.paidCta ? (
          <div className={classes.paidPlanCtaContainer}>
            <p>Available on paid plans</p>
            <div>
              <a target={"_blank"} rel="noopener noreferrer" href={"#"}>
                Contact sales
                <OpenInNewIcon />
              </a>
            </div>
          </div>
        ) : props.isBeta ? (
          <Tooltip
            title={betaMSPIntegrationTooltipText}
            placement={"bottom-start"}
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
            >
              <p>Beta</p>
              <InfoOutlinedIcon />
            </div>
          </Tooltip>
        ) : props.isNew ? (
          <div
            className={`${classes.paidPlanCtaContainer} ${classes.betaTooltipCtaContainer}`}
          >
            <p>New</p>
            <AutoAwesomeIcon />
          </div>
        ) : null}
      </Grid>
    );
  };

  const handleConnectionButton = () => {
    const testPSAModel = new PSAIntegrationTest();
    const body = {
      companyId: companyId,
      publicKey: publicKey,
      privateKey: privateKey,
      manageUrl: domainName,
    };
    setIsTestingConnection(true);
    setErrorMessage("");
    testPSAModel.set(body);

    // Hit the POST endpoint for testing an integration
    testPSAModel
      .create()
      .then(() => {
        // Show success text and change button
        setIsTestSuccessful(true);
        setIsTestingConnection(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to connect to ConnectWise API.  Please check your secret values and try again."
        );
        setIsTestingConnection(false);
      });
  };

  const handleSaveButton = () => {
    const body = {
      companyId: companyId,
      publicKey: publicKey,
      privateKey: privateKey,
      manageUrl: domainName,
    };

    setIsSaving(true);
    selectedModel.set(body);

    const crudMethod = selectedModel.id ? "update" : "create";
    selectedModel[crudMethod]()
      .then(() => {
        props.reload(true);
        setIsIntegrationConnected(true);
        setIsTestSuccessful(false);
        setExpandAccordionOne(false);
        setIsSaving(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to connect to ConnectWise API.  Please check your secret values and try again."
        ); // TODO: figure out with this use case error message should be
        setIsSaving(false);
      });
  };

  const handleAccordionOneChange = () => {
    setExpandAccordionOne(!expandAccordionOne);
  };

  const MappingSummary = () => {
    const notMappedCount = companyMapping?.reduce(
      (acc, obj) => acc + (!obj.mapped ? 1 : 0),
      0
    );
    const mappedCount = companyMapping?.length - notMappedCount;

    return (
      <div className={classes.accordionSummaryDetails}>
        <div>
          Mapped
          <div className={classes.accordionTwoMappedChip}>{mappedCount}</div>
        </div>
        <div>
          Not Mapped
          <div className={classes.accordionTwoNotMappedChip}>
            {notMappedCount}
          </div>
        </div>
      </div>
    );
  };

  const handleSaveAccountMapping = () => {
    const body = {
      defaultCompany: catchallId.toString(),
      companyMappings: companyMapping,
    };

    setIsSaving(true);
    selectedModel.set(body);

    selectedModel
      .update()
      .then(() => {
        setExpandAccordionTwo(false);
        setIsSaving(false);
        setExpandAccordionThree(true);
      })
      .catch(() => {
        setErrorMessage(
          "Failed to connect to ConnectWise API.  Please check your secret values and try again."
        ); // TODO: figure out with this use case error message should be
        setIsSaving(false);
      });
  };

  const displayConnectedStatus = () => {
    if (
      isTestSuccessful ||
      isIntegrationConnected ||
      selectedModel?.connectionStatus === "OK"
    ) {
      return (
        <div className={classes.accordionOneConnectedChip}>
          <DoNotDisturbOnOutlinedIcon />
          Connected
        </div>
      );
    } else {
      return (
        <div className={classes.accordionOneNotConnectedChip}>
          <DoNotDisturbOnOutlinedIcon />
          Not Connected
        </div>
      );
    }
  };

  const handleServiceBoardChange = (event) => {
    setServiceBoard(event.target.value);
  };

  const handleAccordionTwoChange = () => {
    setExpandAccordionTwo((prevState) => !prevState);
  };

  const handleAccordionThreeChange = () => {
    setExpandAccordionThree(!expandAccordionThree);
  };

  const handleAccordionFourChange = () => {
    setExpandAccordionFour((prevState) => !prevState);
  };

  const handleSaveServiceButton = () => {
    const body = {
      defaultBoard: String(serviceBoard),
    };

    setIsSaving(true);
    selectedModel.set(body);

    selectedModel
      .update()
      .then(() => {
        setExpandAccordionThree(false);
        setIsSaving(false);
        setExpandAccordionFour(true);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to connect to ConnectWise API.  Please check your secret values and try again."
        ); // TODO: figure out with this use case error message should be
        setIsSaving(false);
      });
  };

  const handleSaveTicketFlowButton = () => {
    const body = {
      defaultEmail,
      priorityMappings: ticketSyncing.priorityMappings,
      prioritySyncing: ticketSyncing.prioritySyncing,
      statusMappings: ticketSyncing.statusMappings,
      statusSyncing: ticketSyncing.statusSyncing,
      typeMappings: ticketSyncing.typeMappings,
      typeSyncing: ticketSyncing.typeSyncing,
    };

    setIsSaving(true);
    selectedModel.set(body);

    selectedModel
      .update()
      .then(() => {
        setExpandAccordionFour(false);
        setIsSaving(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to connect to ConnectWise API.  Please check your secret values and try again."
        ); // TODO: figure out with this use case error message should be
        setIsSaving(false);
      });
  };

  const handleSendSampleFinding = () => {
    const testFindingSimulationModel = new PSASimulateFinding();
    const body = {
      orgId: orgToSimulateFrom,
      priority: priorityToSimulateFrom,
      type: typeToSimulateFrom,
    };
    setIsSendingSample(true);
    setErrorMessage("");
    testFindingSimulationModel.set(body);

    // Hit the POST endpoint for sending a simulation finding
    testFindingSimulationModel
      .create()
      .then(() => {
        setIsSendingSample(false);
      })
      .catch((error) => {
        setErrorMessage(
          "Failed to connect to ConnectWise API.  Please check your secret values and try again." // TODO: figure out with this use case error message should be
        );
        setIsSendingSample(false);
      });
  };

  const handleChangeOrgToSimulateFrom = (event) => {
    setOrgToSimulateFrom(event.target.value);
  };

  const handleChangePriorityToSimulateFrom = (event) => {
    setPriorityToSimulateFrom(event.target.value);
  };

  const handleChangeTypeToSimulateFrom = (event) => {
    setTypeToSimulateFrom(event.target.value);
  };

  const displayDialogContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Grid
            container
            spacing={2}
            className={classes.moduleOptionsContainer}
            datacy={"mspIntegrationsListModal"}
          >
            {getMSPIntegrationList().map((module, idx) => {
              return (
                <IntegrationButton
                  module={module}
                  key={module.integrationType}
                  isBeta={isModuleInBeta(module)}
                  isNew={isModuleNew(module)}
                />
              );
            })}
          </Grid>
        );
      case 1:
        return (
          <div>
            <div className={classes.titleWrap}>
              <Typography className={classes.stepOneAccordionText}>
                ConnectWise Manage One Way Syncing
              </Typography>
            </div>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionOne}
                onChange={handleAccordionOneChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className={classes.accordionTitle}>
                    ConnectWise Manage API Connection
                    {displayConnectedStatus()}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsContainer}>
                  <Link
                    href="#"
                    className={classes.setupGuideLink}
                    underline="hover"
                  >
                    Setup guide
                    <OpenInNewIcon />
                  </Link>
                  <TextField
                    variant="outlined"
                    label="ConnectWise Host (Domain Name)"
                    value={domainName}
                    onChange={handleDomainNameChange}
                    className={classes.accordionOneTextField}
                    placeholder="https://"
                  />
                  <TextField
                    variant="outlined"
                    label="Company ID"
                    value={companyId}
                    onChange={handleCompanyIdChange}
                    className={classes.accordionOneTextField}
                  />
                  <TextField
                    variant="outlined"
                    label="Public Key"
                    value={publicKey}
                    onChange={handlePublicKeyChange}
                    className={classes.accordionOneTextField}
                  />
                  <TextField
                    variant="outlined"
                    label="Private Key"
                    value={privateKey}
                    onChange={handlePrivateKeyChange}
                    className={classes.accordionOneTextField}
                  />
                  <div className={classes.buttonContainer}>
                    {isTestSuccessful ? (
                      <Button
                        variant="contained"
                        className={classes.accordionOneButton}
                        disabled={isConnectionButtonDisabled || isSaving}
                        onClick={handleSaveButton}
                      >
                        {isSaving
                          ? "Saving..."
                          : "Save and Continue to Account Mapping"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.accordionOneButton}
                        disabled={
                          isConnectionButtonDisabled || isTestingConnection
                        }
                        onClick={handleConnectionButton}
                      >
                        {isTestingConnection
                          ? "Testing Connection..."
                          : "Test Connection"}
                      </Button>
                    )}
                    {isTestSuccessful && (
                      <div className={classes.successContainer}>
                        <CheckIcon />
                        <p className={classes.successText}>
                          Successfully Connected
                        </p>
                      </div>
                    )}
                  </div>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                onChange={handleAccordionTwoChange}
                expanded={expandAccordionTwo}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <div className={classes.accordionTitle}>
                    Account Mapping
                    {/* Need to make sure this is still working when we are auto-opening accordions */}
                    {!expandAccordionTwo && !!companyMapping.length && (
                      <MappingSummary />
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <MSPIntegrationAccountMapping
                    currentId={selectedModel?.id}
                    catchallId={catchallId}
                    companyMapping={companyMapping}
                    setCompanyMapping={setCompanyMapping}
                    setCatchallId={setCatchallId}
                    blumiraOrganizationsList={props.blumiraOrganizationsList}
                  ></MSPIntegrationAccountMapping>
                  <Button
                    variant="contained"
                    className={classes.integrationMappingButton}
                    disabled={!companyMapping && !catchallId}
                    onClick={handleSaveAccountMapping}
                  >
                    Save Account Mapping
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionThree}
                onChange={handleAccordionThreeChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <div className={classes.accordionTitle}>
                    ConnectWise Manage Service Board
                    {selectedModel && selectedModel.defaultBoard && (
                      <span className={classes.accordionSummaryDetails}>
                        {serviceBoardDisplayText}
                      </span>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetailsContainer}>
                  <FormControl className={classes.accordionThreeSelect}>
                    <InputLabel id="board-select-label">
                      Service Board
                    </InputLabel>
                    <Select
                      labelId="board-select-label"
                      id="board-select"
                      value={serviceBoard}
                      label="Service Board"
                      onChange={handleServiceBoardChange}
                    >
                      {props.serviceBoards.map((board) => (
                        <MenuItem key={board.id} value={board.id}>
                          {board.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    className={classes.accordionThreeButton}
                    onClick={handleSaveServiceButton}
                  >
                    Save Board
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion
                className={classes.accordionContainer}
                expanded={expandAccordionFour}
                onChange={handleAccordionFourChange}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4-content"
                  id="panel4-header"
                  className={classes.accordionTitle}
                >
                  Blumira-to-ConnectWise Ticket Flow
                </AccordionSummary>
                <AccordionDetails>
                  <MSPTicketFlow
                    boardId={selectedModel?.defaultBoard}
                    defaultEmail={defaultEmail}
                    setDefaultEmail={setDefaultEmail}
                    defaultEmailError={defaultEmailError}
                    setDefaultEmailError={setDefaultEmailError}
                    ticketSyncing={ticketSyncing}
                    setTicketSyncing={setTicketSyncing}
                    filteredTypes={findingTypes}
                  />

                  <Button
                    variant="contained"
                    onClick={handleSaveTicketFlowButton}
                    disabled={!defaultEmail.length || defaultEmailError}
                  >
                    Save
                  </Button>
                </AccordionDetails>
              </Accordion>
            </Card>
            <Card className={classes.accordionCard}>
              <Accordion className={classes.accordionContainer}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5-content"
                  id="panel5-header"
                  className={classes.accordionTitle}
                >
                  Verify and Enable/Disable
                </AccordionSummary>
                <AccordionDetails>
                  <StyledSteps>
                    <li>
                      <Typography className="step-label">
                        Step 1 – Send a sample Finding to verify that everything
                        is working as expected
                      </Typography>
                      <div className={classes.accordionFiveStepOne}>
                        <div className={classes.accordionFiveSection}>
                          <table>
                            <tbody>
                              <tr>
                                <td className={classes.tableCellOne}>
                                  Simulate From
                                </td>
                                <td className={classes.tableCellTwo}>
                                  <FormControl fullWidth>
                                    <Select
                                      value={orgToSimulateFrom}
                                      onChange={handleChangeOrgToSimulateFrom}
                                      inputProps={{
                                        "aria-label":
                                          "Select an organization to simulate from",
                                      }}
                                    >
                                      {props.blumiraOrganizationsList.map(
                                        (org) => (
                                          <MenuItem value={org.id} key={org.id}>
                                            {org.name}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.tableCellOne}>
                                  Detection rule
                                </td>
                                <td className={classes.tableCellTwo}>
                                  Simulation Test Rule
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.tableCellOne}>
                                  Priority
                                </td>
                                <td className={classes.tableCellTwo}>
                                  <FormControl fullWidth>
                                    <Select
                                      value={priorityToSimulateFrom}
                                      onChange={
                                        handleChangePriorityToSimulateFrom
                                      }
                                      inputProps={{
                                        "aria-label":
                                          "Select a priority level to simulate",
                                      }}
                                    >
                                      {BLUMIRA_PRIORITIES.map((priority) => (
                                        <MenuItem
                                          value={priority.value}
                                          key={priority.value}
                                        >
                                          {priority.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </td>
                              </tr>
                              <tr>
                                <td className={classes.tableCellOne}>Type</td>
                                <td className={classes.tableCellTwo}>
                                  <FormControl fullWidth>
                                    <Select
                                      value={typeToSimulateFrom}
                                      onChange={handleChangeTypeToSimulateFrom}
                                      inputProps={{
                                        "aria-label":
                                          "Select a finding type to simulate",
                                      }}
                                    >
                                      {Object.values(findingTypes).map(
                                        (type) => (
                                          <MenuItem value={type} key={type}>
                                            {type}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className={`${classes.tableCellOne} ${classes.lastTableCellOne}`}
                                >
                                  Analysis
                                </td>
                                <td className={classes.tableCellTwo}>
                                  {`Test analysis for a ${priorityDisplayText} ${typeToSimulateFrom} finding.`}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className={classes.tableCellTwo}>
                                  <Button
                                    variant="outlined"
                                    startIcon={<SendIcon />}
                                    className={classes.sendFindingsButton}
                                    onClick={handleSendSampleFinding}
                                    disabled={
                                      isSendingSample ||
                                      isFindingSimulationButtonDisabled
                                    }
                                  >
                                    {isSendingSample
                                      ? "Sending Sample Finding..."
                                      : "Send Sample Finding"}
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          className={`${classes.accordionFiveSection} ${classes.accordionFiveImageWrap}`}
                        >
                          <figure className={classes.accordionFiveFigure}>
                            <img
                              src={findingDetailsImage}
                              alt="Screenshot of Findings details page from within the Blumira app"
                              className={classes.accordionFiveImage}
                            />
                            <figcaption
                              className={classes.accordionFiveCaption}
                            >
                              What a Finding looks like in Blumira
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </li>
                  </StyledSteps>
                </AccordionDetails>
              </Accordion>
            </Card>
          </div>
        );
    }
  };

  const displayDialogTitle = () => {
    return currentStep === 0
      ? "Available MSP Integrations"
      : "MSP Integration Details";
  };

  const resetModal = () => {
    setErrorMessage("");
    setExpandAccordionOne(true);
    resetAccordionOneValues();
    setTimeout(() => setCurrentStep(0), 200);
    setCatchallId("");
    setCompanyMapping([]);
  };

  const toggleModal = () => {
    props.toggleModal();
    resetModal();
  };

  const resetAccordionOneValues = () => {
    setDomainName("");
    setCompanyId("");
    setPrivateKey("");
    setPublicKey("");
  };

  return (
    <StyledDialog
      open={props.open}
      onClose={toggleModal}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogTitle className={classes.modalTitleWrap}>
        {displayDialogTitle()}
        <IconButton onClick={toggleModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{displayDialogContent()}</DialogContent>
    </StyledDialog>
  );
};

export default MSPIntegrationDialog;
