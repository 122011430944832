import React from "react";
import PropTypes from "prop-types";

import BluBarGraph, { BAR_GRAPH_MARGIN_LARGE_LEFT } from "./common/BluBarGraph";

/*
Constants
*/

const BAR_COLOR = "#61cdbb"; // hsl(170, 52%, 59%) rgb(97, 205, 187)
const BAR_COLOR_DARKER_1_6 = "#37746a"; // hsl(170, 36%, 34%) rgb(55, 116, 106)
const TOOLTIP_TEXT = "events";

/*
Main compenent
*/

const DevicesBarGraph = ({ labelField, labelField2, rawData, valueField }) => (
  <BluBarGraph
    labelField={labelField}
    labelField2={labelField2}
    rawData={rawData}
    valueField={valueField}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    colors={[BAR_COLOR]}
    margin={BAR_GRAPH_MARGIN_LARGE_LEFT}
    tooltipText={TOOLTIP_TEXT}
  />
);

DevicesBarGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  labelField2: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

DevicesBarGraph.defaultProps = {
  rawData: [],
};

export default DevicesBarGraph;
