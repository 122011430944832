import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";
import SupportEntry from "../../Components/Finding/SupportEntry";

const LatestTimestamp = ({
  columnNumLeft,
  columnNumRight,
  latestTimestamp,
}) => (
  <div>
    <Container className="list-container dark">
      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Latest Data Timestamp"
        value={latestTimestamp}
      />
    </Container>
  </div>
);

LatestTimestamp.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  latestTimestamp: PropTypes.string,
};

LatestTimestamp.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  latestTimestamp: "",
};

export default LatestTimestamp;
