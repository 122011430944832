import { SensorsAPI, DataQueryApi } from "../../lib/api";
import Logger from "../../lib/logger";
import {
  GENERAL_ERROR_MESSAGE,
  handleApiError,
  apiErrorMessageForUi,
} from "../utils/helpers";

import { actionSuccess, clearUrlQuery as closeModal } from "./Page";

const logger = Logger("Sensors");

export const REQUEST_SENSORS = "SENSORS.REQUEST_SENSORS";
export const SENSORS_RECEIVE_ORG_SENSORS = "SENSORS.RECEIVE_ORG_SENSORS";
export const SENSORS_DELETE_ONE_SENSOR = "SENSORS.DELETE_ONE_SENSOR";
export const SENSORS_SET_SENSORS_ERROR = "SENSORS.SET_SENSORS_ERROR";
export const SENSORS_CLEAR_ERROR = "SENSORS.SENSORS_CLEAR_ERROR";
export const SENSORS_CLEAR_SENSORS = "SENSORS.CLEAR_SENSORS";

export const SENSORS_RECEIVE_ONE_SENSOR_DETAIL =
  "SENSORS.RECEIVE_ONE_SENSOR_DETAIL";
export const SENSORS_SET_DETAIL_ERROR = "SENSORS.SET_DETAIL_ERROR";
export const SENSORS_CLEAR_DETAIL_ERROR = "SENSORS.SENSORS_CLEAR_DETAIL_ERROR";
export const SENSORS_CLEAR_SENSOR_DETAIL = "SENSORS.CLEAR_SENSOR_DETAIL";

export const SENSORS_RECEIVE_DETAIL_LOADING = "SENSORS_RECEIVE_DETAIL_LOADING";

export const SENSORS_RECEIVE_DEVICES = "SENSORS.RECEIVE_DEVICES";
export const SENSORS_SET_DEVICES_ERROR = "SENSORS.SET_DEVICES_ERROR";
export const SENSORS_CLEAR_DEVICES_ERROR = "SENSORS.CLEAR_DEVICES_ERROR";
export const SENSORS_CLEAR_DEVICES_FOR_ONE_SENSOR =
  "SENSORS.SENSORS_CLEAR_DEVICES_FOR_ONE_SENSOR";
export const SENSORS_CLEAR_DEVICES = "SENSORS.CLEAR_DEVICES";

export const SENSORS_RECEIVE_LATEST_PARSED_TIME =
  "SENSORS.RECEIVE_LATEST_PARSED_TIME";
export const SENSORS_RECEIVE_LATEST_TIMESTAMP =
  "SENSORS.RECEIVE_LATEST_TIMESTAMP";
export const SENSORS_RECEIVE_PARSING_ERRORS = "SENSORS.RECEIVE_PARSING_ERRORS";

export const SENSORS_SET_SUPPORT_ERROR = "SENSORS.SET_SUPPORT_ERROR";
export const SENSORS_CLEAR_SUPPORT_ERROR = "SENSORS.CLEAR_SUPPORT_ERROR";

const API = {
  sensors: new SensorsAPI(),
  dataQuery: new DataQueryApi(),
};

/*
Helpers and constants
*/

const DEVICES_ERROR_MESSAGE =
  "Current logging devices information is not available.";

/*
Actions
*/

/*
'sensors'
*/

const requestOrgSensors = () => ({
  type: REQUEST_SENSORS,
});

const receiveOrgSensors = ({ orgId, sensors }) => ({
  type: SENSORS_RECEIVE_ORG_SENSORS,
  payload: { orgId, sensors },
});

const receiveSensorsError = (err) => ({
  type: SENSORS_SET_SENSORS_ERROR,
  payload: { error: err },
});

const clearSensorsErr = {
  type: SENSORS_CLEAR_ERROR,
};

const clearSensors = {
  type: SENSORS_CLEAR_SENSORS,
};

/*
'sensorDetail'
*/

const receiveSensorDetail = (sensor) => ({
  type: SENSORS_RECEIVE_ONE_SENSOR_DETAIL,
  payload: { sensorDetail: sensor },
});

const receiveSensorDetailError = (err) => ({
  type: SENSORS_SET_DETAIL_ERROR,
  payload: { error: err },
});

export const clearDetailErr = {
  type: SENSORS_CLEAR_DETAIL_ERROR,
};

const clearSensorDetail = {
  type: SENSORS_CLEAR_SENSOR_DETAIL,
};

/*
'detailLoading'
*/
const receiveDetailLoading = ({ sensorId, loading }) => ({
  type: SENSORS_RECEIVE_DETAIL_LOADING,
  payload: { insertId: sensorId, data: loading },
});

/*
'devices'
*/

const receiveDevices = ({ data, sensorId }) => ({
  type: SENSORS_RECEIVE_DEVICES,
  payload: { data, sensorId },
});

const receiveDevicesErr = (err) => ({
  type: SENSORS_SET_DEVICES_ERROR,
  payload: { error: err },
});

export const clearDevicesErr = {
  type: SENSORS_CLEAR_DEVICES_ERROR,
};

const clearDevices = {
  type: SENSORS_CLEAR_DEVICES,
};

const clearDevicesForSensor = (sensorId) => ({
  type: SENSORS_CLEAR_DEVICES_FOR_ONE_SENSOR,
  payload: { sensorId },
});

/*
Composite actions
*/

export const resetSensorsStore = (dispatch) => {
  dispatch(clearDevices);
  dispatch(clearSensors);
  dispatch(clearSensorDetail);
};

/*
Fetches the sensors for the current org and puts them in redux
(current orgId, personId are extracted from redux).
*/
export const fetchSensors = (orgId) => (dispatch) => {
  if (!orgId) {
    const detailedMsg = "Cannot fetch sensors: found no org ID.";
    logger.error(new Error(detailedMsg));

    const error = new Error(GENERAL_ERROR_MESSAGE);
    dispatch(receiveSensorsError(error));

    return;
  }

  dispatch(requestOrgSensors());

  return API.sensors
    .list(orgId)
    .then(({ data: { data = [] } }) => {
      dispatch(actionSuccess); // clear possible error from another page
      dispatch(clearSensorsErr);

      return dispatch(receiveOrgSensors({ orgId, sensors: data }));
    })
    .catch((err) => {
      const message = "Failed to fetch sensors.";
      dispatch(
        handleApiError({
          message,
          err,
          onError: receiveSensorsError,
        })
      );
    });
};

// Navigates to Sensors Page View and re-fetches the sensors
const navigateToSensors = (orgId) => (dispatch) => {
  // Navigate to Sensors Page View
  const payload = {
    orgId,
    toplevel: "settings",
    secondlevel: "sensors",
  };
  const query = {};

  dispatch({
    type: "PAGE",
    payload,
    query,
  });
};

/*
Fetches the detailed info for the current sensor
(current orgId, personId, or sensorId are extracted from redux).
*/
export const fetchSensor =
  ({ orgId, sensorId }) =>
  (dispatch) => {
    if (!orgId || !sensorId) {
      const detailedMsg =
        "Cannot fetch sensor: found no org ID, person ID, or sensor ID";
      logger.error(new Error(detailedMsg));

      const error = new Error(GENERAL_ERROR_MESSAGE);
      dispatch(receiveSensorDetailError(error));

      return;
    }

    dispatch(
      receiveDetailLoading({
        sensorId,
        loading: true,
      })
    );

    return API.sensors
      .get(orgId, sensorId)
      .then((resp) => {
        dispatch(actionSuccess); // clear possible error from another page
        dispatch(clearDetailErr);

        const { data: sensorData = {} } = resp;

        dispatch(
          receiveDetailLoading({
            sensorId,
            loading: false,
          })
        );

        return dispatch(receiveSensorDetail(sensorData));
      })
      .catch((err) => {
        // if we have an issue loading sensor details
        // send the user back to the list view
        dispatch({
          type: "PAGE",
          payload: {
            orgId,
            toplevel: "settings",
            secondlevel: "sensors",
          },
        });
      });
  };

// Navigates to Sensor Detail View (the view fetches the sensor)
const navigateToSensorDetail =
  ({ orgId, sensorId }) =>
  (dispatch) => {
    const payload = {
      orgId,
      toplevel: "settings",
      secondlevel: "sensors",
      id1: sensorId,
    };
    const query = {};

    dispatch({
      type: "PAGE",
      payload,
      query,
    });
  };

/*
Fetches the logging devices for the current sensor.
*/
export const fetchDevices =
  ({ orgId, sensorId, relativeStart }) =>
  (dispatch) => {
    if (!orgId || !sensorId) {
      const detailedMsg =
        "Cannot fetch devices: no org ID, person ID, or sensor ID";
      logger.error(new Error(detailedMsg));

      const error = new Error(DEVICES_ERROR_MESSAGE);

      dispatch(receiveDevicesErr(error));

      return;
    }

    // do not display not-up-to-date devices
    dispatch(clearDevicesForSensor(sensorId));

    API.dataQuery
      .listSensorDevices({
        orgId,
        sensorId,
        relativeStart,
      })
      .then((resp) => {
        dispatch(clearDevicesErr);

        const { data } = resp;

        return dispatch(receiveDevices({ data, sensorId }));
      })
      .catch((err) => {
        // do not display stale devices inoformation
        dispatch(
          handleApiError({
            message: DEVICES_ERROR_MESSAGE,
            err,
            onError: receiveDevicesErr,
          })
        );
        dispatch(clearDevicesForSensor(sensorId));
      });
  };

/*
sensors.support error
*/

const receiveSensorSupportError = (err) => ({
  type: SENSORS_SET_SUPPORT_ERROR,
  payload: { error: err },
});

export const clearSensorSupportError = {
  type: SENSORS_CLEAR_SUPPORT_ERROR,
};

/*
Fetches the parsing errors for the current sensor.
*/
const receiveParsingErrors = ({ supportInfo, relativeStart, sensorId }) => {
  const { parsingErrors } = supportInfo;

  return {
    type: SENSORS_RECEIVE_PARSING_ERRORS,
    payload: {
      data: parsingErrors,
      timeInterval: relativeStart,
      insertId: sensorId,
    },
  };
};

const receiveLatestParsedTime = ({ supportInfo, relativeStart, sensorId }) => {
  const { latestParsedTime } = supportInfo;

  return {
    type: SENSORS_RECEIVE_LATEST_PARSED_TIME,
    payload: {
      data: latestParsedTime,
      timeInterval: relativeStart,
      insertId: sensorId,
    },
  };
};

const receiveLatestTimestamp = ({ supportInfo, relativeStart, sensorId }) => {
  const { latestTimestamp } = supportInfo;

  return {
    type: SENSORS_RECEIVE_LATEST_TIMESTAMP,
    payload: {
      data: latestTimestamp,
      timeInterval: relativeStart,
      insertId: sensorId,
    },
  };
};

export const fetchSupportInfo =
  ({ orgId, sensorId, relativeStart }) =>
  (dispatch) => {
    if (!orgId || !sensorId) {
      const detailedMsg = "Cannot fetch support info: no org ID or sensor ID";
      logger.error(new Error(detailedMsg));

      return;
    }

    API.dataQuery
      .fetchSupportInfo({
        orgId,
        sensorId,
        relativeStart,
      })
      .then((resp) => {
        const { data: supportInfo = [] } = resp;

        dispatch(
          receiveLatestParsedTime({
            supportInfo,
            relativeStart,
            sensorId,
          })
        );

        dispatch(
          receiveParsingErrors({
            supportInfo,
            relativeStart,
            sensorId,
          })
        );

        dispatch(
          receiveLatestTimestamp({
            supportInfo,
            relativeStart,
            sensorId,
          })
        );
      })
      .catch((err) => {
        const message = "Unable to fetch sensor summary information.";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveSensorSupportError,
          })
        );
      });
  };

export const createSensor =
  ({ orgId, personId, data }) =>
  (dispatch) => {
    API.sensors
      .create(orgId, personId, data)
      .then((resp) => {
        const { data: respData = {} } = resp;

        const { id: sensorId } = respData;

        // Re-fetch sensors (Sensors Page View does not fetch on update)
        dispatch(fetchSensors(orgId));

        // Navigate to the Sensor Detail View
        // (removes the URL query and re-fetches the sensor)
        dispatch(navigateToSensorDetail({ orgId, sensorId }));
      })
      .catch((err) => {
        const { name: sensorName } = data || {};

        // set human-readable error message
        const message = `Failed to create sensor with name ${sensorName}.`;

        // get details on error including incident ID for reference to CX
        const uiErrorMessage = apiErrorMessageForUi({ message, err });

        // build error to populate reducer and show in UI
        const error = new Error(uiErrorMessage);

        // dispatch appropriate error action
        dispatch(receiveSensorDetailError(error));
      });
  };

export const rebuildSensor =
  ({ data, orgId, sensorId }) =>
  (dispatch) => {
    API.sensors
      .rebuild({
        data,
        orgId,
        sensorId,
      })
      .then(
        () => {
          dispatch(fetchSensor({ orgId, sensorId }));
          dispatch(closeModal);
        },
        (err) => {
          const message = "Failed to rebuild sensor.";

          dispatch(
            handleApiError({
              message,
              err,
              onError: receiveSensorDetailError,
            })
          );
        }
      );
  };

export const updateSensor =
  ({ orgId, sensorId, data }) =>
  (dispatch) => {
    API.sensors
      .update({
        orgId,
        sensorId,
        data,
      })
      .then(
        () => {
          // Re-fetch the sensor and sensors
          dispatch(fetchSensor({ orgId, sensorId }));
          dispatch(fetchSensors(orgId));
        },
        (err) => {
          const message = "Failed to update sensor.";

          dispatch(
            handleApiError({
              message,
              err,
              onError: receiveSensorDetailError,
            })
          );
        }
      );
  };

const deleteOneSensor =
  ({ orgId, sensorId }) =>
  (dispatch) => {
    const payload = { orgId, sensorId };

    dispatch({
      type: SENSORS_DELETE_ONE_SENSOR,
      payload,
    });
  };

export const deleteSensor =
  ({ orgId, personId, sensorId }) =>
  (dispatch) => {
    // Delete the sensor from Redux and navigate to the Sensors Page View
    dispatch(deleteOneSensor({ orgId, sensorId }));
    dispatch(navigateToSensors(orgId));

    API.sensors
      .delete(orgId, personId, sensorId)
      .then(() => {
        // Re-fetch the sensors
        dispatch(fetchSensors(orgId));
      })
      .catch((err) => {
        const message = `Failed to delete sensor ${sensorId}`;
        logger.error(message, err);

        // Re-fetch the sensors
        dispatch(fetchSensors(orgId));
      });
  };

export const installModule =
  ({ orgId, personId, sensorId, data }) =>
  (dispatch) => {
    API.sensors
      .createModule(orgId, personId, sensorId, data)
      .then(() => {
        dispatch(closeModal);

        dispatch(fetchSensor({ orgId, sensorId }));
      })
      .catch((err) => {
        const message = "Failed to install module or fetch updated sensor.";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveSensorDetailError,
          })
        );
      });
  };

export const updateModule =
  ({ orgId, personId, sensorId, sensorModuleUUID, data }) =>
  (dispatch) => {
    API.sensors
      .updateModule(orgId, personId, sensorId, sensorModuleUUID, data)
      .then(() => {
        dispatch(closeModal);

        dispatch(fetchSensor({ orgId, sensorId }));
      })
      .catch((err) => {
        const message =
          "Failed to update installed module or fetch updated sensor.";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveSensorDetailError,
          })
        );
      });
  };

export const migrateModule =
  ({ orgId, personId, sensorId, sensorModuleUUID }) =>
  (dispatch) => {
    API.sensors
      .migrateModule(orgId, personId, sensorId, sensorModuleUUID)
      .then(() => {
        dispatch(closeModal);
        dispatch(fetchSensor({ orgId, sensorId }));
      })
      .catch((err) => {
        const message = "Failed to migrate module or fetch updated sensor.";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveSensorDetailError,
          })
        );
      });
  };

export const uninstallModule =
  ({ orgId, personId, sensorId, sensorModuleUUID }) =>
  (dispatch) => {
    API.sensors
      .deleteModule(orgId, personId, sensorId, sensorModuleUUID)
      .then(() => {
        dispatch(closeModal);

        dispatch(fetchSensor({ orgId, sensorId }));
      })
      .catch((err) => {
        const message = "Failed to uninstall module or fetch updated sensor.";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveSensorDetailError,
          })
        );
      });
  };
