import { styled } from "@mui/material/styles";
import { Card } from "@mui/material";

const PREFIX = "SeatCountInfoCard";
export const classes = {
  card: `${PREFIX}-card`,
  header: `${PREFIX}-header`,
  description: `${PREFIX}-description`,
  countAndActionContainer: `${PREFIX}-countAndActionContainer`,
  count: `${PREFIX}-count`,
};

export const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.card}`]: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.shape.padding,
    justifyContent: "space-between",
    "& p": {
      marginBottom: 0,
    },
  },
  [`& .${classes.header}`]: {
    fontSize: 18,
    fontWeight: "bold",
  },
  [`& .${classes.description}`]: {
    fontSize: 12,
    "& a": {
      color: theme.palette.primary.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "& svg": {
      fontSize: 16,
    },
  },
  [`& .${classes.countAndActionContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.count}`]: {
    fontSize: 50,
    fontWeight: "bold",
  },
}));
