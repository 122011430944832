// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Downloading text ****************
export const DownloadText = styled.span`
  color: ${COLOR.blumiraLightBlue};
  font-size: 13px;
  font-weight: 400;
  margin-right: 16px;
`;

// ** Flex end container **************
export const FlexEnd = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;

// ** Icon container ******************
export const IconContainer = styled.div`
  margin: 0px 8px 0px 0px;
  transform: ${(p) => (p.open ? "rotate(90deg)" : "rotate(270deg)")};
  transition: all 250ms;
`;

// ** Loading container ***************
export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

// ** Option **************************
export const Option = styled.button`
  align-items: flex-start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${COLOR.gray900};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  justify-content: flex-start;
  padding: 4px 16px;
  outline: none !important;
  transition: all 150ms;
  width: 100%;

  &:hover {
    background-color: #f2f2f2;
  }
`;

// ** Option container ****************
export const OptionsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
`;

// ** Options header ******************
export const OptionsHeader = styled.div`
  border-bottom: 1px solid ${COLOR.gray100};
  color: ${COLOR.gray600};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
  padding: 16px;
  white-space: nowrap;
`;

// ** Overflow container **************
export const Overflow = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding: 32px 0px;
  width: 100%;
`;

// ** Search header *******************
export const SearchHeader = styled.span`
  color: ${COLOR.blumiraBlue};
  display: block;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 8px;
  margin-left: 24px;
`;

// ** Section *************************
export const Section = styled.div`
  margin-bottom: 32px;
  padding: 0px 32px 0px 24px;
  width: 640px;

  &:last-child {
    margin-bottom: 0px;
    margin-top: 48px;
  }
`;

// ** Section header ******************
export const SectionHeader = styled.span`
  border-bottom: 1px dashed ${COLOR.blumiraBlue};
  color: ${COLOR.blumiraBlue};
  display: block;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 16px;
  padding: 4px 0px;
  position: relative;
  text-transform: uppercase;
`;

// ** Space between *******************
export const SpaceBetween = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
