import React from "react";

import { formatDateUserTz } from "../../../utils/index";

const DATAFLOW_WORKER_MAX = "dataflow_worker_max";

const noAutoscaling = (obj) => {
  const val = obj[DATAFLOW_WORKER_MAX];
  const disabled = val === 0 || val === "0" || val === null;

  return disabled;
};

// Returns rows for OrgInfo and PipelineInfo tables.
export const getRows = (obj, dateKeys = [], linkKeys = []) => {
  const rows = Object.keys(obj)
    .map((key) => {
      let value;

      if (linkKeys.includes(key)) {
        value = (
          <a href={obj[key]} rel="noopener noreferrer" target="_blank">
            {obj[key]}
          </a>
        );
      } else if (dateKeys.includes(key)) {
        value = formatDateUserTz(obj[key]);
      } else if (key === DATAFLOW_WORKER_MAX && noAutoscaling(obj)) {
        value = `Autoscaling Disabled (${DATAFLOW_WORKER_MAX} = ${obj[key]})`;
      } else {
        value = `${obj[key] || ""}`;
      }

      return {
        key: <strong>{key}</strong>,
        keyAccessor: key,
        value,
      };
    })
    .sort((a, b) => a.keyAccessor.localeCompare(b.keyAccessor));

  return rows;
};
