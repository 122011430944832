import { get } from "lodash";

/**
 * Helper func to return an array with or without a given value dependent on its presence
 *
 * @param {selection} string - item to search for in array can also be an obj if exact match in array
 * @param {array} array - array where existence check of given selection will be computed
 */
export const getNewArray = ({ selection = null, array = [] }) => {
  if (!selection) return array;

  let arrayCopy = array;

  const isValuePresent = array.find((arrVal) => arrVal === selection);

  if (isValuePresent) return arrayCopy.filter((arrVal) => arrVal !== selection);
  else return [...arrayCopy, selection];
};

/**
 * Helper func to return if what we have in state is different than what is saved on the org
 * so that we can disabled/enable certain features i.e. the save and reset buttons
 *
 * @param {org} obj - organization to compare state values against
 * @param {executiveSummarySettings} obj - an object mapping the values stored in state for the org's executive summary settings
 */
export const getHasOrgChanged = ({
  org = {},
  executiveSummarySettings = {},
}) => {
  const existingExecutiveSummarySettings = get(org, ["executiveSummaries"], {});

  const {
    roleRecipients,
    emailRecipients,
    contentSections,
    customText,
    logoUrl,
  } = executiveSummarySettings;

  // this user has not set up their exec summary config before
  if (Object.keys(existingExecutiveSummarySettings).length === 0) {
    return true;
  }

  if (
    existingExecutiveSummarySettings.role_recipients &&
    !(
      existingExecutiveSummarySettings.role_recipients.length ===
        roleRecipients.length &&
      existingExecutiveSummarySettings.role_recipients.every((value) =>
        roleRecipients.includes(value)
      )
    )
  ) {
    return true;
  }

  if (
    existingExecutiveSummarySettings.email_recipients &&
    existingExecutiveSummarySettings.email_recipients !== emailRecipients
  ) {
    return true;
  }

  if (
    existingExecutiveSummarySettings.content_sections &&
    !(
      existingExecutiveSummarySettings.content_sections.length ===
        contentSections.length &&
      existingExecutiveSummarySettings.content_sections.every((value) =>
        contentSections.includes(value)
      )
    )
  ) {
    return true;
  }

  // if no custom text saved and user entered custom text
  // or if the new text does not match what's saved
  if (
    (!existingExecutiveSummarySettings.custom_text && customText) ||
    (existingExecutiveSummarySettings.custom_text &&
      existingExecutiveSummarySettings.custom_text !== customText)
  )
    return true;

  // if no custom logo saved and user entered custom logo
  // or if the new logo does not match what's saved
  if (
    (!existingExecutiveSummarySettings.logo_url && logoUrl) ||
    (existingExecutiveSummarySettings.logo_url &&
      existingExecutiveSummarySettings.logo_url !== logoUrl)
  )
    return true;

  return false;
};

export const recipientCheckBoxList = [
  { label: "Admins", value: 10 },
  { label: "Managers", value: 20 },
  { label: "Responders", value: 40 },
];

export const contentSectionCheckBoxList = [
  { label: 'Section #1 - "How much data was analyzed?"', value: 1 },
  { label: 'Section #2 - "How many findings were generated?"', value: 2 },
  { label: 'Section #3 - "How many findings were resolved?"', value: 3 },
];
