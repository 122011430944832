import { combineReducers } from "redux";

import { insertData, insertDataById } from "./utils/helpers";
import { getGraphReducer } from "./reducersCommon/graphReducer";
import { getInititalState } from "./reducersCommon/graphAndTableHelpers";
import {
  getReducer as getReducerHelper,
  insertError,
} from "./reducersCommon/helpers";
import { getTableReducer } from "./reducersCommon/tableReducer";
import {
  LOGDEVS_GRAPH,
  LOGDEVS_OVERVIEW_RECEIVE_DATA,
  LOGDEVS_OVERVIEW_RECEIVE_DATES,
  LOGDEVS_OVERVIEW_RECEIVE_ERROR,
  LOGDEVS_SELECTED_INTERVAL_RECEIVE,
  LOGDEVS_TABLE,
  LOGDEVS_RESET,
} from "./actions/Logdevs";

/*
Constants and Helpers
*/

// Used to create 'overview' and 'selectedInterval' reducers
// 'graph...' and 'table...' reducers will be imported from 'common'
const STORE_TO_ACTION = {
  // Overview
  overviewByOrg: LOGDEVS_OVERVIEW_RECEIVE_DATA,
  overviewDates: LOGDEVS_OVERVIEW_RECEIVE_DATES,
  overviewError: LOGDEVS_OVERVIEW_RECEIVE_ERROR,

  // Selected time interval
  selectedInterval: LOGDEVS_SELECTED_INTERVAL_RECEIVE,
};

const INITIAL_STATE = getInititalState(STORE_TO_ACTION);
INITIAL_STATE.overviewError = null;

const getReducer = ({ initialStateKey, insert = insertData }) =>
  getReducerHelper({
    actionTypeReceive: STORE_TO_ACTION[initialStateKey],
    actionTypeReset: LOGDEVS_RESET,
    initialState: INITIAL_STATE[initialStateKey],
    insert,
  });

/*
Reducers
*/

// Graph
const graphReducer = getGraphReducer(LOGDEVS_GRAPH);

// overviewByOrg
const overviewByOrgReducer = getReducer({
  initialStateKey: "overviewByOrg",
  insert: insertDataById,
});

// overviewDates
const overviewDatesReducer = getReducer({
  initialStateKey: "overviewDates",
  insert: insertDataById,
});

// overviewError
const overviewErrorReducer = getReducer({
  initialStateKey: "overviewError",
  insert: insertError,
});

// selectedInterval
const selectedIntervalReducer = getReducer({
  initialStateKey: "selectedInterval",
  insert: insertDataById,
});

// Table
const tableReducer = getTableReducer(LOGDEVS_TABLE);

// Logdevs reducer

const reducers = {
  ...graphReducer,

  overviewByOrg: overviewByOrgReducer,
  overviewDates: overviewDatesReducer,
  overviewError: overviewErrorReducer,

  selectedInterval: selectedIntervalReducer,

  ...tableReducer,
};

const LogdevsReducer = combineReducers(reducers);

export default LogdevsReducer;
