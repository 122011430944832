export const ORDER_BY_ASC = 1;
export const ORDER_BY_DESC = -1;

export const resultParamsSchema = {
  $id: "#resultParamsSchema",
  title: "Query Settings",
  type: "object",
  properties: {
    orderBy: {
      type: "array",
      items: {
        type: "object",
        properties: {
          key: { type: "string" },
          value: { type: "number", enum: [ORDER_BY_ASC, ORDER_BY_DESC] },
        },
        required: ["key", "value"],
        additionalProperties: false,
      },
    },
    transform: {
      type: "array",
      items: {
        type: "object",
        properties: {
          field: { type: "string" },
          sort: { type: "number", enum: [ORDER_BY_ASC, ORDER_BY_DESC] },
          value: { type: ["string", "number", "boolean", "null"] },
          negate: { type: "boolean" },
          operator: { type: "string" },
        },
        additionalProperties: false,
      },
    },
    page: {
      type: "object",
      properties: {
        number: { type: "number" },
        count: { type: "number" },
      },
      additionalProperties: false,
    },
    outputFields: {
      type: "array",
      items: {
        type: "string",
      },
    },
    outputType: {
      type: "string",
      enum: ["direct", "jsonl", "csv", "xlsx"],
    },
  },
  additionalProperties: false,
};
