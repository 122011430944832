import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import EndpointVictimsGraph from "./EndpointVictimsGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "endpointVictims";
export const GRAPH_TITLE = "Top 10 Endpoint Attack Targets";

/*
Main component
*/

const EndpointVictims = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={EndpointVictimsGraph}
    labelField="victim"
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

EndpointVictims.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

EndpointVictims.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default EndpointVictims;
