import React from "react";
import PropTypes from "prop-types";

import TosDialog from "../Tos/TosDialog";

const TosView = (props) => {
  return (
    <div className="tos-view">
      <TosDialog {...props} open={true} />
    </div>
  );
};

TosView.propTypes = {
  accept: PropTypes.func,
  acceptedTos: PropTypes.string,
  cancel: PropTypes.func,
  initial: PropTypes.bool,
  orgId: PropTypes.string,
};

TosView.defaultProps = {
  accept: null,
  acceptedTos: null,
  cancel: null,
  initial: false,
  orgId: null,
};

export default TosView;
