import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import { orgNameSelector } from "../../../selectors/orgNameSelector";
import {
  primaryPipelineSelector,
  requestErrorMessageSelector,
  restartingPipelineSelector,
} from "../../../selectors/pipelineSelectors";
import { locationQuerySelector } from "../../../selectors/locationSelectors";
import {
  receiveRequestError,
  restartPrimaryPipeline,
} from "../../../redux/actions/Pipeline";
import { ErrorBanner } from "../../Components/ErrorBanner";
import WorkerSelect from "./WorkerSelect";

import "../Sensor/SensorModal.scss";
import "../Sensor/SensorModalContent.scss";

/*
Constants and helpers
*/

export const optionsFromArr = (arr, defaultLabel = "Unchanged") =>
  arr.reduce(
    (accum, elt) => {
      accum.push({
        value: elt,
        label: elt,
      });
      return accum;
    },
    [{ value: null, label: defaultLabel }]
  );

const arr1 = Array.from({ length: 7 }, (value, index) => index + 2);
const arr2 = Array.from({ length: 6 }, (value, index) => 10 + index * 2);
export const WORKER_MAX_VALUES = [0].concat(arr1, arr2);

export const workerMaxOptionsFromArr = (arr, defaultLabel = "Unchanged") => {
  const options = optionsFromArr(WORKER_MAX_VALUES, defaultLabel);

  // Value "0" stands for "Disable Autoscaling"
  const noAutoscaling = options.find((elt) => elt.value === 0);
  noAutoscaling.label = "0 (Disable Autoscaling)";

  return options;
};

const WORKER_MAX_OPTIONS = workerMaxOptionsFromArr(WORKER_MAX_VALUES);

export const WORKER_COUNT_VALUES = Array.from(
  { length: 10 },
  (value, index) => index + 1
);
const WORKER_COUNT_OPTIONS = optionsFromArr(WORKER_COUNT_VALUES);

export const WORKER_TYPE_VALUES = [
  "n1-standard-1",
  "n1-standard-2",
  "n1-standard-4",
  "n1-standard-8",
  "n1-highmem-2",
  "n1-highmem-4",
  "n1-highmem-8",
  "n1-highmem-16",
  "n1-highmem-32",
];
const WORKER_TYPE_OPTIONS = optionsFromArr(WORKER_TYPE_VALUES);

// Filter out all entries with null value
export const filterData = (data) => {
  const nextData = Object.keys(data).reduce((accum, key) => {
    if (data[key] !== null) {
      accum[key] = data[key];
    }
    return accum;
  }, {});
  return nextData;
};

export const COUNT_ERROR_MESSAGE =
  "The initial worker count cannot be greater than" +
  " the maximum worker count.";

// Validate the current input counts against each other only
// and retrun the error message
export const workerCountErrorMsg = ({ workerCount, workerMax }) => {
  if (!workerCount || !workerMax || workerCount <= workerMax) {
    return "";
  }

  return COUNT_ERROR_MESSAGE;
};

// Validates the current input counts against each other or
// against the existing ones
export const onSubmitWorkerCountErrorMsg = ({
  workerCount,
  workerCountOld,
  workerMax,
  workerMaxOld,
}) => {
  if (workerCount && workerMax) {
    return workerCount <= workerMax ? "" : COUNT_ERROR_MESSAGE;
  }

  if (workerCount && workerMaxOld) {
    return workerCount <= workerMaxOld
      ? ""
      : `${COUNT_ERROR_MESSAGE} Your existing maximum worker count is ${workerMaxOld}.`;
  }

  if (workerMax && workerCountOld) {
    return workerCountOld <= workerMax
      ? ""
      : `${COUNT_ERROR_MESSAGE} Your existing initial worker count is ${workerCountOld}.`;
  }

  return "";
};

export const getDisabled = (formErrorMessage, restarting) =>
  !!(formErrorMessage || restarting);

export const getCurrentCounts = (state) => {
  const data = primaryPipelineSelector(state);
  const {
    dataflow_worker_count: workerCountOld,
    dataflow_worker_max: workerMaxOld,
  } = data;

  return {
    workerCountOld,
    workerMaxOld,
  };
};

export const getModalStatus = (state, queryKey) => {
  const { location = {} } = state;
  const { query = {} } = location;
  const { [queryKey]: modalOpen = false } = query;

  return modalOpen;
};

const getModalBody = (orgName, restarting) =>
  restarting
    ? `Restarting the primary pipeline for ${orgName}`
    : "This will drain the active pipeline" +
      " job and start a new one with the latest available parser.";

const getModalHeader = (orgName, restarting) =>
  restarting
    ? `Restarting the primary pipeline for ${orgName}`
    : `Restart primary pipeline for ${orgName}`;

/*
Main component
*/

const PipelineModal = ({
  dispatchCloseModal = () => {},
  dismissRequestError = () => {},
  dispatchRestart = () => {},
  orgId,
  orgName,
  requestErrorMessage,
  restarting,
  modalOpen = false,
  workerCountOld,
  workerMaxOld,
}) => {
  const firstRun = useRef(true);

  const [workerCount, setWorkerCount] = useState(null);
  const [workerMax, setWorkerMax] = useState(null);
  const [workerType, setWorkerType] = useState(null);

  const [formErrorMessage, setFormErrorMessage] = useState("");

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dismissRequestError(orgId);
    dispatchCloseModal();
  }, [orgId, dismissRequestError, dispatchCloseModal]);

  useEffect(() => {
    setFormErrorMessage(
      workerCountErrorMsg({
        workerCount,
        workerMax,
      })
    );
  }, [workerCount, workerMax]);

  useEffect(() => {
    dismissRequestError(orgId);
  }, [dismissRequestError, orgId, workerCount, workerMax, workerType]);

  const onCancelRestart = () => {
    dismissRequestError(orgId);
    dispatchCloseModal();
  };

  const onConfirmRestart = () => {
    const data = filterData({
      workerCount,
      workerMax,
      workerType,
    });

    const errMessage = onSubmitWorkerCountErrorMsg({
      workerCount,
      workerCountOld,
      workerMax,
      workerMaxOld,
    });

    if (errMessage) {
      setFormErrorMessage(errMessage);
      return;
    }

    dispatchRestart({
      orgId,
      ...data,
    });
  };

  // Handle worker intial count selection
  const handleWorkerCountSelect = (newValue) => {
    setWorkerCount(newValue);
  };

  // Handle worker max count selection
  const handleWorkerMaxSelect = (newValue) => {
    setWorkerMax(newValue);
  };

  // Handle worker type selection
  const handleWorkerTypeSelect = (newValue) => {
    setWorkerType(newValue);
  };

  const modalBody = getModalBody(orgName, restarting);
  const modalHeader = getModalHeader(orgName, restarting);

  const disabled = getDisabled(formErrorMessage, restarting);

  return (
    <Modal
      zIndex={1500}
      isOpen={!!modalOpen}
      className="sensor-modal"
      backdrop={null}
      size="lg"
    >
      <ModalHeader toggle={onCancelRestart} className="sensor-modal-header">
        {modalHeader}
      </ModalHeader>

      <ModalBody className="align-middle rounded sensor-modal-body">
        <div className="sensor-modal-content">
          {requestErrorMessage && (
            <ErrorBanner
              message={requestErrorMessage}
              dismiss={() => {
                dismissRequestError(orgId);
              }}
            />
          )}

          {!requestErrorMessage && (
            <div className="pipeline-modal-body">{modalBody}</div>
          )}

          {formErrorMessage && (
            <div className="alert alert-danger sensor-form-item">
              {formErrorMessage}
            </div>
          )}

          <div className="sensor-form-item">
            <div className="sensor-label">Machine type</div>
            <WorkerSelect
              options={WORKER_TYPE_OPTIONS}
              defaultValue={workerType}
              onSelect={handleWorkerTypeSelect}
            />
          </div>

          <div className="sensor-form-item">
            <div className="sensor-label">Initial worker count</div>
            <WorkerSelect
              options={WORKER_COUNT_OPTIONS}
              defaultValue={workerCount}
              onSelect={handleWorkerCountSelect}
            />
          </div>

          <div className="sensor-form-item">
            <div className="sensor-label">Maximum worker count</div>
            <WorkerSelect
              options={WORKER_MAX_OPTIONS}
              defaultValue={workerMax}
              onSelect={handleWorkerMaxSelect}
            />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        {!restarting && (
          <Button color="danger" onClick={onCancelRestart}>
            Cancel
          </Button>
        )}

        <Button
          color="primary"
          disabled={disabled}
          className="pipeline-modal-submit-btn"
          onClick={onConfirmRestart}
        >
          {!restarting && <span>Restart</span>}
          {restarting && <span>...</span>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PipelineModal.propTypes = {
  dispatchCloseModal: PropTypes.func.isRequired,
  dismissRequestError: PropTypes.func.isRequired,
  dispatchRestart: PropTypes.func.isRequired,
  modalOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  requestErrorMessage: PropTypes.string.isRequired,
  restarting: PropTypes.bool.isRequired,
  workerCountOld: PropTypes.number.isRequired,
  workerMaxOld: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  // Org ID
  const orgId = orgIdSelector(state);

  // Org name
  const orgName = orgNameSelector(state);

  // Is modal open?
  const location = locationQuerySelector(state);
  const modalOpen = location.restart || false;

  // Is the pipeline being restarted?
  const restarting = restartingPipelineSelector(state);

  // Get the 'restart pipeline' request error message
  const requestErrorMessage = requestErrorMessageSelector(state);

  // Current pipeline worker intial and max count
  const { workerCountOld = 0, workerMaxOld = 0 } = getCurrentCounts(state);

  return {
    orgId,
    orgName,
    modalOpen,
    restarting,
    requestErrorMessage,
    workerCountOld,
    workerMaxOld,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseModal: () => {
    dispatch(closeModal);
  },

  dismissRequestError: (orgId) => {
    dispatch(receiveRequestError({ orgId, error: null }));
  },

  dispatchRestart: ({ orgId, workerCount, workerMax, workerType }) => {
    dispatch(
      restartPrimaryPipeline({
        orgId,
        workerCount,
        workerMax,
        workerType,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PipelineModal);
