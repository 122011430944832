import Moment from "moment-timezone";

const timeFrames = {
  y: "year",
  q: "quarter",
  m: "month",
  w: "week",
  d: "day",
  mon: "monday",
  tue: "tuesday",
  wed: "wednesday",
  thur: "thursday",
  fri: "friday",
  sat: "saturday",
  sun: "sunday",
};

const weekDays = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];

const momentShorthandMap = {
  q: "Q",
  m: "M",
};

const abbrv = ["th", "st", "nd", "rd"];

//Legacy Maps
const legacyDayDelta = {
  day: "d",
  month: "m",
  year: "y",
};

export const parseTimeFrameOffset = (offset) => {
  if (offset === 0) {
    return "current";
  } else if (offset < 0) {
    if (offset === -1) {
      return "previous";
    } else {
      return `previous ${Math.abs(offset)}`;
    }
  } else {
    if (offset === 1) {
      return "next";
    } else {
      const a = abbrv[offset % 10] || "th";
      return `${offset}${a}`;
    }
  }
};

export const parseTimeDeltaOffset = (offset) => {
  if (offset === 0) {
    return "current";
  } else if (offset < 0) {
    if (offset === -1) {
      return "last";
    } else {
      const absOffset = Math.abs(offset) % 10;
      return `${absOffset}${abbrv[absOffset % 10] || "th"} to last`;
    }
  } else {
    const a = abbrv[offset % 10] || "th";
    return `${offset}${a}`;
  }
};

/////////////////////////////////////////////////////////

export default class Time {
  constructor(timeString) {
    this.legacy = false;
    if (timeString) {
      this.timeString = timeString.toLowerCase();
      const parsed = this.parse();
      this.startFrame = parsed["dDeltas"].shift();
      this.timeSpans = parsed["dDeltas"];
      this.clockDelta = parsed["tDeltas"];
    } else {
      // Now
      this.startFrame = [];
      this.timeSpans = [];
      this.clockDelta = [];
    }
  }

  parse() {
    // check for legacy format
    this.parseLegacy();

    const parsed = {};
    const deltas = this.timeString.split("@");
    parsed["dDeltas"] = deltas[0].split(",").map((f) => {
      const c = f.split(":");
      return [Number(c[0]), c[1]];
    });
    parsed["tDeltas"] = deltas[1]
      ? deltas[1].split(":").map((t) => Number(t))
      : [];
    return parsed;
  }

  parseLegacy() {
    if (!this.timeString.includes(":") && !this.timeString.includes("@")) {
      this.legacy = true;
      const unit = Moment.normalizeUnits(this.timeString.slice(-1));
      const duration = this.timeString.substr(0, this.timeString.length - 1);
      if (legacyDayDelta[unit]) {
        this.timeString = `-${duration}:${legacyDayDelta[unit]}`;
      } else if (unit === "hour") {
        this.timeString = `@-${duration}`;
      }
    }
  }

  /**
   * Returns human readable language for time
   * @param {string} region - name of langauge region
   */
  language(region = "en-us") {
    //[[0, "m"], [1, "M"]]
    const delta = parseTimeFrameOffset(this.startFrame[0]);
    const dType = timeFrames[this.startFrame[1]];
    const deltas = this.timeSpans.map(
      (ts) => `${parseTimeDeltaOffset(ts[0])} ${timeFrames[ts[1]]}`
    );

    var lang = dType ? [...deltas, `${delta} ${dType}`].join(" of the ") : [];

    // Now
    if (this.startFrame.length === 0) {
      return "Now";
    }

    if (this.clockDelta.length > 0) {
      const timeLang = [];
      // hours
      if (this.clockDelta[0]) {
        if (this.clockDelta[0] < 0) {
          // delta time.  add negative hour
          timeLang.push(
            `previous ${Math.abs(this.clockDelta[0])} hour${
              Math.abs(this.clockDelta[0]) > 1 ? "s" : ""
            }`
          );
        } else {
          // literal time
          timeLang.push(
            `at ${this.clockDelta[0]} hour${this.clockDelta[0] > 1 ? "s" : ""}`
          );
        }
      }
      //minutes
      if (this.clockDelta[1] !== undefined) {
        if (timeLang.length > 0) {
          timeLang.push(`and`);
        } else {
          if (this.clockDelta[1] < 0) {
            timeLang.push(`previous`);
          } else {
            timeLang.push(`at`);
          }
        }
        if (this.clockDelta[1] < 0) {
          // delta time.  add negative hour
          timeLang.push(
            `${Math.abs(this.clockDelta[1])} minute${
              Math.abs(this.clockDelta[1]) > 1 ? "s" : ""
            }`
          );
        } else {
          // literal time
          timeLang.push(
            `${this.clockDelta[1]} minute${this.clockDelta[1] > 1 ? "s" : ""}`
          );
        }
      }
      if (lang.length > 0) {
        lang = lang + " ";
      }
      lang = lang + timeLang.join(" ");
    }
    return lang;
  }

  getTime(startTime) {
    const t = new Moment(startTime).startOf("day");

    // Now
    if (this.startFrame.length === 0) {
      return new Moment(startTime).milliseconds(0);
    }

    // set start timeframe
    if (this.startFrame[0] < 0) {
      t.subtract(
        Math.abs(this.startFrame[0]),
        momentShorthandMap[this.startFrame[1]] || this.startFrame[1]
      );
    } else if (this.startFrame[0] > 0) {
      t.add(
        this.startFrame[0],
        momentShorthandMap[this.startFrame[1]] || this.startFrame[1]
      );
    }
    var currentSpan =
      momentShorthandMap[this.startFrame[1]] || this.startFrame[1];
    t.startOf(currentSpan);

    // apply day deltas
    this.timeSpans.forEach((ts) => {
      t.startOf(currentSpan);
      const weekday = weekDays.indexOf(ts[1]);

      if (ts[0] < 0) {
        t.endOf(currentSpan);
        if (weekday > -1) {
          const weeksBack = Math.abs(ts[0]) * 7;
          t.day((weeksBack - weekday) * -1);
        } else {
          t.subtract(Math.abs(ts[0]), momentShorthandMap[ts[1]] || ts[1]);
        }
      } else if (ts[0] > 0) {
        if (weekday > -1) {
          const weekOffset = t.day() >= weekday ? 7 : 0;
          const weeksForward = (ts[0] - 1) * 7 + weekOffset;
          t.day(weeksForward + weekday);
        } else {
          t.add(ts[0], momentShorthandMap[ts[1]] || ts[1]);
        }
      }
      currentSpan = momentShorthandMap[ts[1]] || ts[1];
    });

    // apply time deltas
    if (this.clockDelta.length > 0) {
      // hours
      if (this.clockDelta[0]) {
        if (this.clockDelta[0] < 0) {
          // delta time.  add negative hour
          t.hours(Moment().hours() - Math.abs(this.clockDelta[0]));
        } else {
          // literal time
          t.hours(this.clockDelta[0]);
        }
      } else {
        t.hours(Moment().hours());
      }

      //minutes
      if (this.clockDelta[1] !== undefined) {
        if (this.clockDelta[1] < 0) {
          // delta time.  add negative hour
          //t.subtract(Math.abs(this.clockDelta[1]), 'minute')
          t.minutes(Moment().minutes() - Math.abs(this.clockDelta[1]));
        } else {
          // literal time
          t.minutes(this.clockDelta[1]);
        }
      } else {
        t.minutes(Moment().minutes());
      }
    }

    t.milliseconds(0);
    return t;
  }

  getTimeRange(startTime) {
    const times = {};
    times.startTime = this.getTime(startTime);
    const endDate =
      momentShorthandMap[this.startFrame[1]] || this.startFrame[1];
    if (this.timeSpans.length === 0) {
      var offset = this.startFrame[0];
      // when looking for day span, assume people want up until the current day
      if (endDate === "d" && this.startFrame[0] < -1) {
        offset = 0;
      }
      times.endTime = Moment()
        .add(offset, endDate)
        .endOf(endDate)
        .milliseconds(0);
    } else {
      times.endTime = Moment().milliseconds(0);
    }
    return times;
  }
}
