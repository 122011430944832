// *****************************************************************************
// Format options functionality
// *****************************************************************************
//
// Formats dataset for use with the select component
//
export default (data, label, value) =>
  data.map((datum) => ({
    label:
      typeof label === "object"
        ? datum[label[0]] || datum[label[1]]
        : datum[label],
    value: datum[value],
  }));
