import Request from "../../lib/api/Request";

import { handleApiError } from "../utils/helpers";
import { actionFailure } from "./Page";

export const REQUEST_SUBNETS = "SUBNETS.REQUEST_SUBNETS";
export const RECEIVE_SUBNETS = "SUBNETS.RECEIVE_SUBNETS";
export const RECEIVE_ERROR = "SUBNETS.RECEIVE_ERROR";
export const CLOSE_DETAIL = "SUBNETS.CLOSE_DETAIL";
export const OPEN_DETAIL = "SUBNETS.OPEN_DETAIL";

export const fetchSubnets =
  (params = {}) =>
  (dispatch) => {
    dispatch({ type: REQUEST_SUBNETS });
    const queryParams = Object.keys(params).map((key) => {
      return { key, value: params[key] };
    });
    const request = new Request("/subnet", queryParams);
    request
      .get()
      .then((subnets) =>
        dispatch({
          type: RECEIVE_SUBNETS,
          subnets,
        })
      )
      .catch((err) => {
        const message = "Failed to load subnets";
        dispatch(
          handleApiError({
            message,
            err,
            onError: actionFailure,
          })
        );
      });
  };

export const onError = (error) => ({ type: RECEIVE_ERROR, error });

export const onSuccess = () => ({ type: CLOSE_DETAIL });

export const showDetail = () => (dispatch) => dispatch({ type: OPEN_DETAIL });

export const createSubnet = (subnet) => (dispatch) => {
  subnet
    .create()
    .then(() => dispatch(fetchSubnets()))
    .then(() => dispatch(onSuccess()))
    .catch((err) => {
      const message = "Failed to create subnet";
      dispatch(
        handleApiError({
          message,
          err,
          onError,
        })
      );
    });
};

export const updateSubnet = (subnet) => (dispatch) => {
  subnet
    .update()
    .then(() => dispatch(fetchSubnets()))
    .then(() => dispatch(onSuccess()))
    .catch((err) => {
      const message = "Failed to update subnet";
      dispatch(
        handleApiError({
          message,
          err,
          onError,
        })
      );
    });
};

export const deleteSubnet = (subnet) => (dispatch) => {
  subnet
    .delete()
    .then(() => dispatch(fetchSubnets()))
    .then(() => dispatch(onSuccess()))
    .catch((err) => {
      const message = "Failed to delete subnet";
      dispatch(
        handleApiError({
          message,
          err,
          onError,
        })
      );
    });
};
