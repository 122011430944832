import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LogTypeOption from "./LogTypeOption";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";

export const sortLogTypes = (logTypes) =>
  _(logTypes)
    .toPairs()
    .orderBy((arr) => _.get(arr, [1, "displayName"], ""))
    .map((arr) => _.get(arr, [1, "name"], ""))
    .value();

const DataSourcePicker = (props) => {
  const [logTypes, setLogTypes] = useState({});
  const [control, setControls] = useState({});
  const [logTypeDisplayNameList, setLogTypeDisplayNameList] = useState([]);
  useEffect(() => {
    const controls = Object.keys(props.controls).filter(
      (c) => props.controls[c].field === "type"
    );
    if (controls.length > 1) {
      props.errorMsg("Multiple 'type' controls are not supported at this time");
      //remove the additional 'type'
      delete props.controls[controls[1]];
    }
    setControls(props.controls[controls[0]]);
  }, [props.controls, props.interface]);

  useEffect(() => {
    const retVal = {};
    Object.keys(props.logTypes)
      .filter((logType) => props.language[logType])
      .forEach((logType) => {
        retVal[logType] = {
          name: logType,
          displayName: props.language[logType].display_name,
          description: props.language[logType].description,
          categoryNames: props.logTypes[logType].categories,
        };
      });
    setLogTypes(retVal);

    const sortedLogTypeNames = sortLogTypes(retVal);
    setLogTypeDisplayNameList(sortedLogTypeNames);
  }, [props.language, props.logTypes]);

  const logTypeFilterOptions = createFilterOptions({
    stringify: (option) =>
      `${logTypes[option].displayName || logTypes[option].name} ${logTypes[
        option
      ].categoryNames.join(" ")}`,
  });

  if (control?.operator === "in") {
    return (
      <Autocomplete
        multiple
        limitTags={6}
        id="selected-log-types"
        filterOptions={logTypeFilterOptions}
        value={control?.value || []}
        onChange={(event, value) =>
          props.interface.update(control.uuid, { value })
        }
        options={logTypeDisplayNameList}
        getOptionLabel={(option) =>
          logTypes[option]
            ? logTypes[option].displayName || logTypes[option].name
            : option
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <LogTypeOption
              key={option}
              {...logTypes[option]}
              selected={selected}
            />
          </li>
        )}
        defaultValue={[]}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Data Sources"
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        data-cy="reportBuilderInput"
        sx={{ marginBottom: "20px" }}
      />
    );
  } else {
    return (
      <TextField
        disabled={true}
        label={`Data Sources${
          control?.operator === "regex" ? " (Regex match)" : ""
        }`}
        value={control?.value || ""}
        onChange={(event, value) =>
          props.interface.update(control.uuid, { value: event.target.value })
        }
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ marginBottom: "20px" }}
      />
    );
  }
};

// ** Proptypes ***********************
DataSourcePicker.propTypes = {
  selected: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  logTypes: PropTypes.object,
  language: PropTypes.object,
  refName: PropTypes.string.isRequired,
};

// ** Default props *******************
DataSourcePicker.defaultProps = {
  selected: [],
  logTypes: {},
  language: {},
};

export default DataSourcePicker;
