import { defaultTheme, shadowTheme } from "./themes/blumira";

const themes = {
  default: defaultTheme,
  shadow: shadowTheme,
};

export const themeSelector = (theme) => {
  if (!themes[theme]) {
    return themes["default"];
  }
  return themes[theme];
};
