// See blu.dates for the time unit strings
export const millisecFromRelative = (relativeDate) => {
  const re = /^(\d+)([A-Za-z]+)$/;
  const result = relativeDate.match(re);
  const [, num = "", str = ""] = result;

  switch (str) {
    case "s":
    case "sec":
    case "seconds":
      return Number(num);

    case "min":
    case "minutes":
      return Number(num) * 60000; // 1 min = 60 * 1000

    case "h":
    case "hr":
    case "hours":
      return Number(num) * 3600000; // 1 hour = 3600 * 1000

    case "d":
    case "day":
    case "days":
      return Number(num) * 86400000; // 1 day = 24 * 3600 * 1000

    case "mon":
    case "months":
      return Number(num) * 2592000000; // 1 month = 30 * 24 * 3600 * 1000

    default:
      return 86400000; // 24 hours
  }
};
