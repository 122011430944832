// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { func, oneOfType, shape, string } from "prop-types";

// ** Components **********************
import { CalendarIcon } from "views/Components/Icon";
import Timepicker from "./Timepicker";

// ** Style ***************************
import {
  FlexColumnLayout,
  FlexRowLayout,
  Label,
  SelectedDate,
  SelectionIconContainer,
} from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Time selection component
//
//  ** Remarks
//  Datepicker time selection/adjusting
//
//  ** Props
//  @param onTimeChange {func} - invoked when time value is clicked to update
//  @param rangeEnd {obj} - current end range value
//  @param rangeStart {obj} - current start range value
//
const TimeSelection = ({
  onTimeChange,
  rangeEnd,
  rangeStart,
  renderDate,
  timeFormat,
}) => (
  <>
    <FlexColumnLayout margin="0px 0px 16px">
      <Label>Range start:</Label>
      <FlexRowLayout>
        <SelectionIconContainer margin="-2px 0px 0px">
          <CalendarIcon height="16px" />
        </SelectionIconContainer>
        <SelectedDate>
          {rangeStart && rangeStart !== ""
            ? `${renderDate(rangeStart).format("MMM DD, YYYY")} at `
            : ""}
        </SelectedDate>
        <Timepicker
          date={rangeStart}
          tag="rangeStart"
          onTimeChange={onTimeChange}
          renderDate={renderDate}
        />
      </FlexRowLayout>
    </FlexColumnLayout>
    <FlexColumnLayout>
      <Label>Range end:</Label>
      <FlexRowLayout>
        <SelectionIconContainer margin="-2px 0px 0px">
          <CalendarIcon height="16px" />
        </SelectionIconContainer>
        <SelectedDate>
          {rangeEnd && rangeEnd !== ""
            ? `${renderDate(rangeEnd).format("MMM DD, YYYY")} at `
            : ""}
        </SelectedDate>
        <Timepicker
          date={rangeEnd}
          tag="rangeEnd"
          onTimeChange={onTimeChange}
          renderDate={renderDate}
          timeFormat={timeFormat}
        />
      </FlexRowLayout>
    </FlexColumnLayout>
  </>
);

// ** Proptypes ***********************
TimeSelection.propTypes = {
  onTimeChange: func,
  rangeEnd: oneOfType([shape(), string]),
  rangeStart: oneOfType([shape(), string]),
  renderDate: func.isRequired,
  timeFormat: string,
};

export default TimeSelection;
