import { combineReducers } from "redux";

import { insertData, insertDataById, mergeDataById } from "./utils/helpers";
import { getGraphReducer } from "./reducersCommon/graphReducer";
import { getInititalState } from "./reducersCommon/graphAndTableHelpers";
import {
  getReducer as getReducerHelper,
  insertError,
} from "./reducersCommon/helpers";

/*
Constants and helpers
*/

import {
  HOST_GRAPH,
  HOST_RESET,
  HOST_SELECTED_INTERVAL_RECEIVE,
  HOST_ITEMS_DATA_RECEIVE,
  HOST_ITEMS_ERROR_RECEIVE,
  HOST_ITEMS_META_RECEIVE,
  HOST_PAGINATION_RECEIVE,
  HOST_TABLE_FILTERS_RECEIVE,
  HOST_TABLE_SORTING_RECEIVE,
  HOST_TALKERS_DATA_RECEIVE,
  HOST_TALKERS_DATES_RECEIVE,
  HOST_TALKERS_ERROR_RECEIVE,
  HOST_THREATS_DATA_RECEIVE,
  HOST_THREATS_DATES_RECEIVE,
  HOST_THREATS_ERROR_RECEIVE,
  HOST_WINDOWS_EVENTS_DATA_RECEIVE,
  HOST_WINDOWS_EVENTS_DATES_RECEIVE,
  HOST_WINDOWS_EVENTS_ERROR_RECEIVE,
} from "./actions/Host";

const STORE_TO_ACTION = {
  itemsByOrg: HOST_ITEMS_DATA_RECEIVE,
  itemsError: HOST_ITEMS_ERROR_RECEIVE,

  metaByOrg: HOST_ITEMS_META_RECEIVE,
  paginationByOrg: HOST_PAGINATION_RECEIVE,

  selectedInterval: HOST_SELECTED_INTERVAL_RECEIVE,

  tableFiltersByOrg: HOST_TABLE_FILTERS_RECEIVE,
  tableSorting: HOST_TABLE_SORTING_RECEIVE,

  talkersByLogdev: HOST_TALKERS_DATA_RECEIVE,
  talkersDates: HOST_TALKERS_DATES_RECEIVE,
  talkersError: HOST_TALKERS_ERROR_RECEIVE,

  threatsByLogdev: HOST_THREATS_DATA_RECEIVE,
  threatsDates: HOST_THREATS_DATES_RECEIVE,
  threatsError: HOST_THREATS_ERROR_RECEIVE,

  windowsEvtsByLogdev: HOST_WINDOWS_EVENTS_DATA_RECEIVE,
  windowsEvtsDates: HOST_WINDOWS_EVENTS_DATES_RECEIVE,
  windowsEvtsError: HOST_WINDOWS_EVENTS_ERROR_RECEIVE,
};

const INITIAL_STATE = {
  ...getInititalState(STORE_TO_ACTION),
  talkersError: null,
  threatsError: null,
  windowsEvtsError: null,
};

const getReducer = ({ initialStateKey, insert = insertData }) =>
  getReducerHelper({
    actionTypeReceive: STORE_TO_ACTION[initialStateKey],
    actionTypeReset: HOST_RESET,
    initialState: INITIAL_STATE[initialStateKey],
    insert,
  });

/*
Reducers
*/

// Graph
const graphReducer = getGraphReducer(HOST_GRAPH);

// Hostnames by org ('itemsByOrg')
const itemsDataReducer = getReducer({
  initialStateKey: "itemsByOrg",
  insert: insertDataById,
});

// itemsError
const itemsErrorReducer = getReducer({
  initialStateKey: "itemsError",
  insert: insertError,
});

// metaByOrg
const metaByOrgReducer = getReducer({
  initialStateKey: "metaByOrg",
  insert: insertDataById,
});

// paginationByOrg
const paginationByOrgReducer = getReducer({
  initialStateKey: "paginationByOrg",
  insert: mergeDataById,
});

// selectedInterval
const selectedIntervalReducer = getReducer({
  initialStateKey: "selectedInterval",
  insert: insertDataById,
});

// tableFiltersByOrg
const tableFiltersByOrgReducer = getReducer({
  initialStateKey: "tableFiltersByOrg",
  insert: mergeDataById,
});

// tableSorting
const tableSortingReducer = getReducer({
  initialStateKey: "tableSorting",
  insert: insertDataById,
});

// talkersByLogdev
const talkersDataReducer = getReducer({
  initialStateKey: "talkersByLogdev",
});

// talkersDates
const talkersDatesReducer = getReducer({
  initialStateKey: "talkersDates",
});

// talkersError
const talkersErrorReducer = getReducer({
  initialStateKey: "talkersError",
  insert: insertError,
});

// threatsByLogdev
const threatsDataReducer = getReducer({
  initialStateKey: "threatsByLogdev",
});

// threatsDates
const threatsDatesReducer = getReducer({
  initialStateKey: "threatsDates",
});

// threatsError
const threatsErrorReducer = getReducer({
  initialStateKey: "threatsError",
  insert: insertError,
});

// windowsEvtsByLogdev
const windowsEvtsDataReducer = getReducer({
  initialStateKey: "windowsEvtsByLogdev",
});

// windowsEvtsDates
const windowsEvtsDatesReducer = getReducer({
  initialStateKey: "windowsEvtsDates",
});

// windowsEvtsError
const windowsEvtsErrorReducer = getReducer({
  initialStateKey: "windowsEvtsError",
  insert: insertError,
});

// Host reducer
const reducers = {
  ...graphReducer,

  itemsByOrg: itemsDataReducer,
  itemsError: itemsErrorReducer,

  metaByOrg: metaByOrgReducer,
  paginationByOrg: paginationByOrgReducer,

  selectedInterval: selectedIntervalReducer,

  tableFiltersByOrg: tableFiltersByOrgReducer,
  tableSorting: tableSortingReducer,

  talkersByLogdev: talkersDataReducer,
  talkersDates: talkersDatesReducer,
  talkersError: talkersErrorReducer,

  threatsByLogdev: threatsDataReducer,
  threatsDates: threatsDatesReducer,
  threatsError: threatsErrorReducer,

  windowsEvtsByLogdev: windowsEvtsDataReducer,
  windowsEvtsDates: windowsEvtsDatesReducer,
  windowsEvtsError: windowsEvtsErrorReducer,
};
const HostReducer = combineReducers(reducers);

export default HostReducer;
