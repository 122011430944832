import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "reactstrap";

import { loggedInUserSelector } from "../../selectors/loggedInUserSelector";
import { showDialog } from "../../lib/sentry";

import "./ErrorBanner.scss";

export const ErrorBanner = (props) => {
  const {
    dismiss,
    eventId,
    message,
    showDismissBtn,
    showReportBtn,
    user = {},
  } = props;

  // Opens the Sentry user feedback widget
  const onClickFeedback = () => {
    const { email, first_name: firstName, last_name: lastName } = user;

    const name = `${firstName} ${lastName}`;

    showDialog({
      email,
      eventId,
      name,
    });
  };

  if (!message) {
    return null;
  }

  return (
    <div className="alert alert-danger error-banner">
      <div className="error-banner-message">{message}</div>
      <div className="error-banner-btn">
        {showReportBtn && (
          <Button color="danger" onClick={onClickFeedback}>
            Report the error
          </Button>
        )}

        {showDismissBtn && (
          <Button color="danger" onClick={dismiss}>
            Dismiss
          </Button>
        )}
      </div>
    </div>
  );
};

// PropTypes
ErrorBanner.propTypes = {
  dismiss: PropTypes.func,
  eventId: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showDismissBtn: PropTypes.bool,
  showReportBtn: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
  }),
};

ErrorBanner.defaultProps = {
  dismiss: () => {},
  eventId: "",
  message: "",
  showDismissBtn: true,
  showReportBtn: false,
  user: {
    email: "",
    first_name: "",
    last_name: "",
  },
};

const mapStateToProps = (state) => ({
  user: loggedInUserSelector(state),
});

export default connect(mapStateToProps, null)(ErrorBanner);
