const psaStatusMappingPSASchema = {
  $id: "/psastatusmapping/psa",
  title: "PSAStatusMappingPSA",
  type: "object",
  properties: {},
  params: {
    type: "object",
    properties: {
      id: { type: "string" },
    },
    required: ["id"],
  },
  required: ["params"],
  additionalProperties: true,
};

export default psaStatusMappingPSASchema;
