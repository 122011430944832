import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import { DialogContent, DialogTitle } from "@mui/material";
import SimpleTable from "views/Components/SimpleTable";
import { orgNameSelector } from "../../../../selectors/orgNameSelector";
import { classes, StyledDialog } from "./styles";

const MSPUsageReportsDialog = (props) => {
  const renderCreatedCell = (column, model) => {
    const timeValue = model[column.field];

    if (!timeValue) {
      return "Unknown date";
    }
    return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
  };

  const handleDownloadUsageReport = (usageReport) => {
    alert(
      `Fake-downloading report with ${usageReport.billable_users} accounts`
    );
  };

  return (
    <StyledDialog open={props.open}>
      <div className={classes.dialogHeader}>
        <DialogTitle>Usage reports</DialogTitle>
        <CloseIcon className={classes.close} onClick={props.toggle} />
      </div>
      <DialogContent className={classes.dialogContent}>
        <p className={classes.dialogDescriptionText}>
          Usage reports provide a detailed snapshot of your Blumira usage from
          your MSP Portal. They are taken monthly, at the end of the month and
          are useful when reconciling your client billing.
        </p>
        {props.usageReports?.length ? (
          <SimpleTable
            isSearchHidden
            data={props.usageReports}
            columns={[
              {
                title: "Generated",
                field: "created",
                renderValue: renderCreatedCell,
              },
              {
                title: "Accounts",
                field: "billable_users",
              },
            ]}
            actions={[
              {
                icon: DownloadIcon,
                tooltip: "Download usage report",
                onClick: (event, model) => handleDownloadUsageReport(model),
              },
            ]}
          />
        ) : (
          <p className={classes.dialogDescriptionText}>
            No usage reports available.
          </p>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

MSPUsageReportsDialog.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  usageReports: PropTypes.array,
};

MSPUsageReportsDialog.defaultProps = {
  open: false,
  usageReports: [],
  toggle: () => {
    console.warn("No toggle function provided for MSPUsageReportsDialog");
  },
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const { orgId: mspOrgId } = location.payload;

  const orgName = orgNameSelector(state);

  return {
    orgName,
    orgId: mspOrgId,
    user: session.settings.user,
    isSuperadmin: session?.settings?.user?.superadmin,
  };
};

export default connect(mapStateToProps, null)(MSPUsageReportsDialog);
