/* eslint no-console: 0 */

import config from "./config";
import {
  addBreadcrumb,
  captureError,
  remoteEnabled,
  MESSAGE_SEVERITY_LEVELS,
} from "./sentry";

const CONSOLE_LEVEL = {
  debug: "debug",
  error: "error",
  info: "info",
  log: "log",
  warn: "warn",
};

// Maps console levels to remote level
const REMOTE_LEVEL = {
  debug: MESSAGE_SEVERITY_LEVELS.Debug,
  error: MESSAGE_SEVERITY_LEVELS.Error,
  info: MESSAGE_SEVERITY_LEVELS.Info,
  log: MESSAGE_SEVERITY_LEVELS.Info,
  warn: MESSAGE_SEVERITY_LEVELS.Warning,
};

// Env variables used as on/off logger and console switches
const consoleOn = () => process.env.REACT_APP_LOGGER_CONSOLE_OFF !== "true";
const loggerOff = () => process.env.REACT_APP_LOGGER_OFF === "true";

const isDevMode = () => process.env.NODE_ENV === "development";

const getConfigData = () => {
  const { loggerEnabled = true } = config;

  return { loggerEnabled };
};

// Logger is enabled iff config.loggerEnabled !== false
// In addition to that, if NODE_ENV === 'development', logger can be turned off
// by setting the environment variable REACT_APP_LOGGER_OFF to 'true',
// or by setting the url search parameter logger=off
const loggerDisabled = () => {
  // Logger can be turned off by a url search parameter
  const { location = {} } = window;
  const url = new URL(location.href);
  const urlParam = url.searchParams.get("logger");

  return (
    getConfigData().loggerEnabled === false || loggerOff() || urlParam === "off"
  );
};

// Console output is enabled iff NODE_ENV is 'development', and
// in that case it can be be turned off by setting the environment variable
// REACT_APP_LOGGER_CONSOLE_OFF=true
const consoleEnabled = () => {
  const devEnvironment = isDevMode();

  if (!devEnvironment) {
    return false;
  }

  const enable = consoleOn();

  return enable;
};

// Takes an array and returns a string
const getMessage = (arr) => {
  let message;

  try {
    message = JSON.stringify(arr);
  } catch (err) {
    console.error("Cannot JSON.stringify", err);
    message = arr.join;
  }

  return message;
};

// Returns a function that sends errors to the remote error collector
// and logs the message. If no errors are found, it creates an error
// from the message and sends that.
const getErrorLogger =
  (...args) =>
  (...rest) => {
    const enableConsole = consoleEnabled();
    const enableRemote = remoteEnabled();

    let eventId;

    // Send errors to the remote
    if (enableRemote) {
      const nonErrors = rest.filter((elt) => !(elt instanceof Error));

      const message = getMessage(nonErrors);

      const errors = rest.filter((elt) => elt instanceof Error);

      if (errors.length) {
        errors.forEach((err) => {
          eventId = captureError({
            err,
            message,
          });
        });
      } else {
        const err = new Error(message);
        eventId = captureError({
          err,
        });
      }
    }

    // Log to the console
    if (enableConsole) {
      // Add the last Sentry event ID
      const sentryEvtMsg = `Sentry event ID: ${eventId}.`;

      Function.prototype.call.call(
        console[CONSOLE_LEVEL.error],
        console,
        ...args,
        ...rest,
        sentryEvtMsg
      );
    }
  };

// Adds a breadcrumb for the remote error collector,
// then logs the message.
const getLogger =
  (key, ...args) =>
  (...rest) => {
    const enableRemote = remoteEnabled();
    const enableConsole = consoleEnabled();

    if (enableRemote) {
      const message = getMessage(rest);

      addBreadcrumb({
        message,
        level: REMOTE_LEVEL[key],
      });
    }

    if (enableConsole) {
      Function.prototype.call.call(console[key], console, ...args, ...rest);
    }
  };

/*
Usage:

import Logger from './lib/logger';
const logger = Logger('Optional string tag');

The tag will be prepended to every log message.
*/
const Logger = (tag) => {
  const disabled = loggerDisabled();

  if (disabled) {
    return Object.keys(CONSOLE_LEVEL).reduce((accum, key) => {
      accum[key] = () => {};
      return accum;
    }, {});
  }

  return {
    debug: getLogger(CONSOLE_LEVEL.debug, tag),
    error: getErrorLogger(tag),
    info: getLogger(CONSOLE_LEVEL.info, tag),
    log: getLogger(CONSOLE_LEVEL.log, tag),
    warn: getLogger(CONSOLE_LEVEL.warn, tag),
  };
};

export default Logger;
