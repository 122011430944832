import {
  REQUEST_SUBNETS,
  RECEIVE_SUBNETS,
  RECEIVE_ERROR,
  CLOSE_DETAIL,
  OPEN_DETAIL,
} from "./actions/Subnet";

const initialState = {
  subnets: [],
  error: null,
  showSubnetDetail: false,
};

const SubnetReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_SUBNETS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_SUBNETS: {
      return {
        ...state,
        isFetching: false,
        subnets: action.subnets,
      };
    }

    case CLOSE_DETAIL: {
      return {
        ...state,
        showSubnetDetail: false,
        error: null,
      };
    }

    case OPEN_DETAIL: {
      return {
        ...state,
        showSubnetDetail: true,
      };
    }

    case RECEIVE_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default SubnetReducer;
