import moment from "moment-timezone";
import { getDeltaStartTime } from "../TimespanPicker";

export const isGlobal = (model) => {
  return !model?.orgId;
};

export const isMicrosoft = (model = {}) => {
  let microsoftList = [
    "office365",
    "office365_aad",
    "office365_atp",
    "office365_compliance",
    "office365_data_center",
    "office365_dynamics",
    "office365_exchange",
    "office365_flow",
    "office365_forms",
    "office365_insight",
    "office365_power_bi",
    "office365_project",
    "office365_security",
    "office365_sharepoint",
    "office365_skype",
    "office365_stream",
    "office365_teams",
    "office365_workplace",
    "office365_yammer",
  ];

  return Object.values(model.queryParams).find((qp) => {
    return (
      qp.field === "type" &&
      Array.isArray(qp.value) &&
      qp.value.find((f) => microsoftList.includes(f))
    );
  });
};

export const getSecondaryDateText = (reportModel = {}) => {
  // set a placeholder for secondary text
  let timeToDisplay = "";

  // gather query params from saved report
  const { queryParams = [] } = reportModel;

  // get 'created' query field for time values
  const timeQueryParams = queryParams.filter(
    ({ field }) => field === "created"
  );

  // if operator is 'in' we can safely assume a relative start/end
  // since specified time constraints are stored with 'lt' and 'gt'
  const relativeTimeQueryParam = timeQueryParams.find(
    ({ operator }) => operator === "in"
  );

  // relative times are stored in an array
  if (Array.isArray(relativeTimeQueryParam?.value)) {
    // getDeltaStartTime returns obj with startTimeNumber and startTimeUnit
    // for a pretty, human-readable string to be built
    const { startTimeNumber, startTimeUnit } = getDeltaStartTime(
      relativeTimeQueryParam.value[0]
    );
    timeToDisplay = `Previous ${startTimeNumber} ${startTimeUnit}`;
  } else if (timeQueryParams) {
    // start times are stored as date strting with 'gt' operator
    const startTimeQueryParam = timeQueryParams.find(
      ({ operator }) => operator === "gt"
    );

    // end times are stored as date strting with 'lt' operator
    const endTimeQueryParam = timeQueryParams.find(
      ({ operator }) => operator === "lt"
    );

    if (startTimeQueryParam && endTimeQueryParam)
      timeToDisplay = `${moment(startTimeQueryParam.value)
        .local()
        .format("l, LT")} - ${moment(endTimeQueryParam.value)
        .local()
        .format("l, LT")}`;
  }

  return timeToDisplay;
};
