import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

export const LicenseRestriction = (props) => {
  const [allow, setAllow] = useState(false);

  // NOTE:
  // watch the _orgId for changes because for some reason the react hook
  // doesnt pick up changes in the props.license object
  // TODO: figure out why this component cant see changes in props.license
  useEffect(() => {
    var isAllowed = false;
    if (props.requires) {
      isAllowed = props.license.isAllowedAPI(props.requires);
    }
    if (props.features) {
      props.features.forEach((feature) => {
        if (!props.license.hasFeature(feature)) {
          isAllowed = false;
        } else {
          isAllowed = true;
        }
      });
    }
    setAllow(isAllowed);
  }, [props.requires, props.license._orgId, props.features]);

  const renderAllow = () => {
    if (props.renderAllow) {
      return props.renderAllow(props.children);
    } else {
      return props.children;
    }
  };

  const renderDisallow = () => {
    if (props.renderDisallow) {
      return props.renderDisallow(props.children);
    } else {
      if (props.children.map) {
        return props.children.map((child) =>
          React.cloneElement(child, { disabled: true })
        );
      } else {
        return React.cloneElement(props.children, { disabled: true });
      }
    }
  };
  if (allow === true) {
    return <>{renderAllow()}</>;
  } else {
    return <>{renderDisallow()}</>;
  }
};

const mapStateToProps = (state) => {
  return {
    license: state.license,
  };
};

export default connect(mapStateToProps, null)(LicenseRestriction);
