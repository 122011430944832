import {
  REQUEST_LOCATIONS,
  RECEIVE_LOCATIONS,
  RESET_LOCATIONS,
} from "./actions/Locations";

const initialState = {
  locations: [],
};

const LocationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_LOCATIONS: {
      return {
        ...state,
        isFetching: true,
      };
    }

    case RECEIVE_LOCATIONS: {
      const locations = [...action.locations];

      return {
        ...state,
        locations,
        isFetching: false,
      };
    }

    case RESET_LOCATIONS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default LocationsReducer;
