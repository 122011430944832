// *****************************************************************************
// Color contants
// *****************************************************************************
//
// App color palette constants fr consistency across the app.
//
export default {
  gray000: "#f8f9fa",
  gray100: "#dfe0e1",
  gray200: "#c5c6c7",
  gray400: "#acadae",
  gray500: "#929394",
  gray600: "#797A7B",
  gray800: "#5f6061",
  gray900: "#464748",
  gray1000: "#2c2d2e",

  blumiraBlue100: "#E8FFFF",
  blumiraBlue200: "#CFFFFF",
  blumiraBlue300: "#B6FFFF",
  blumiraBlue400: "#9CE7FF",
  blumiraBlue500: "#82CDFF",
  blumiraBlue600: "#69B4FF",
  blumiraBlue700: "#509BEF",
  blumiraBlue800: "#3681D5",
  blumiraBlue900: "#1D68BC",

  blumiraBlue: "#034ea2",
  blumiraDarkBlue: "#001c54",
  blumiraLightBlue: "#0095da",
  blumiraRed: "#cd242b",

  lightBlue: "#F8FAFB",
  lightRed: "#FFBDC4",

  black: "#131415",
  white: "#ffffff",
};
