const allowlistEntrySchema = {
  $id: "/allowlistentry",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    ruleId: { type: "string", createOnly: true },
    orgId: { type: "string", format: "uuid", createOnly: true },
    created: { type: "string", readOnly: true },
    createdBy: { type: "string", readOnly: true },
    modified: { type: "string", readOnly: true },
    modifiedBy: { type: "string", readOnly: true },
    deleted: { type: "string", readOnly: true },
    deletedBy: { type: "string", readOnly: true },
    name: { type: "string" },
    queryParams: {
      type: "array",
      items: {
        type: "object",
        properties: {
          uuid: { type: "string", format: "uuid", readOnly: true },
          field: { type: "string" },
          operator: {
            type: "string",
            enum: ["contains", "eq", "gt", "lt", "regex", "in"],
          },
          negate: { type: "boolean" },
          value: {},
        },
        required: ["field", "value"],
        additionalProperties: false,
      },
    },
  },
  required: ["ruleId", "queryParams"],
  additionalProperties: false,
};

export default allowlistEntrySchema;
