import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "MSPBillingContactsDialog";
export const classes = {
  dialogPaper: `${PREFIX}-dialogPaper`,
  dialogHeader: `${PREFIX}-dialogHeader`,
  close: `${PREFIX}-close`,
  dialogContent: `${PREFIX}-dialogContent`,
  textFieldButtonContainer: `${PREFIX}-textFieldButtonContainer`,
  dialogDescriptionText: `${PREFIX}-dialogDescriptionText`,
  addRecipientButton: `${PREFIX}-addRecipientButton`,
  emailTextField: `${PREFIX}-emailTextField`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogHeader}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
  },
  [`& .${classes.dialogContent}`]: {
    paddingTop: "0px !important",
  },
  [`& .${classes.textFieldButtonContainer}`]: {
    display: "flex",
    flexDirection: "row",
  },
  [`& .${classes.dialogDescriptionText}`]: {
    fontSize: 12,
  },
  [`& .${classes.addRecipientButton}`]: {
    height: 55,
    marginLeft: theme.shape.padding,
  },
  [`& .${classes.emailTextField}`]: {
    width: 250,
  },
}));
