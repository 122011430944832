import React from "react";
import { styled } from "@mui/material/styles";
import Skeleton from "@mui/material/Skeleton";

import "./index.scss";

const PREFIX = "LoadingTable";
const classes = {
  loadingTableContainer: `${PREFIX}-loadingTableContainer`,
  loadingTableRowContainer: `${PREFIX}-loadingTableRowContainer`,
  skeletonLoadingPulse: `${PREFIX}-skeletonLoadingPulse`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.loadingTableContainer}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.loadingTableRowContainer}`]: {
    height: 61,
    display: "flex",
    padding: "0 15px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  [`& .${classes.skeletonLoadingPulse}`]: {
    backgroundColor: "rgba(111, 111, 111, 0.2)",
  },
}));

const numberOfItemsPerRow = [5, 2, 3, 4, 3, 4];

const getRowClassName = (index) => {
  if (index === 0)
    return "loadingTableRowContainer darkerBackground"; // header in old table is this color
  else if (index % 2 !== 0)
    return "loadingTableRowContainer darkBackground"; // every other row in old table is this color
  else return "loadingTableRowContainer whiteBackground";
};

const LoadingTable = ({ isMaterial }) => {
  return (
    // material check and secondary loading table can be removed
    // once all pages are moved to the new material UI standard
    isMaterial ? (
      <Root className={classes.loadingTableContainer} title="Loading">
        {numberOfItemsPerRow.map((num, i) => (
          <div key={i} className={classes.loadingTableRowContainer}>
            <Skeleton height={20} width={num * 200} />
          </div>
        ))}
      </Root>
    ) : (
      <Root className={"loadingTableOuterContainer"}>
        {numberOfItemsPerRow.map((num, i) => (
          <div key={i} className={getRowClassName(i)}>
            <Skeleton
              height={20}
              width={num * 200}
              className={classes.skeletonLoadingPulse}
            />
          </div>
        ))}
      </Root>
    )
  );
};

export default LoadingTable;
