/*
Given users.byOrg, users.byId, and orgId, returns the array of users for the org.
*/
export const getOrgUsers = ({ usersStore, orgId }) => {
  const { byOrg, byId } = usersStore;
  const orgUserIds = byOrg[orgId];

  const orgUsers = orgUserIds ? orgUserIds.map((userId) => byId[userId]) : [];

  return orgUsers;
};

/*
Returns the array of all users from the `users` having a role with name `role`.
*/
export const withRole = (orgUsers, role) => {
  const users = orgUsers.filter((user) =>
    user.orgRoles.some((r) => r.roleName === role)
  );

  return users;
};
