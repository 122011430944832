import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import Safe from "./Safe";

const NewsStory = ({
  created = "",
  body = "",
  url = "",
  title = "",
  user: { timezone = "UTC" },
}) => (
  <div className="news-story">
    <div className="story-date">
      {moment.tz(created, timezone).format("MMM D")}
    </div>
    <Safe type="div" className="story-title" text={title} />
    <div className="story-content">
      <Safe
        type="span"
        className="story-content"
        text={`${body.substr(0, 100)}...`}
      />
      <a href={url}>Read More</a>
    </div>
  </div>
);

NewsStory.propTypes = {
  created: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  user: PropTypes.shape({
    timezone: PropTypes.string,
  }),
  title: PropTypes.string,
  url: PropTypes.string,
  body: PropTypes.string,
};

NewsStory.defaultProps = {
  created: new Date(),
  title: "",
  url: "#read-more",
  body: "",
  user: {
    timezone: "UTC",
  },
};

export default NewsStory;
