import { combineReducers } from "redux";

import moment from "moment-timezone";

import {
  PIPELINE_RECEIVE_ACTIVE_JOB_METRICS,
  PIPELINE_RECEIVE_PIPELINE_METRICS,
  PIPELINE_RECEIVE_PRIMARY_PIPELINE,
  PIPELINE_RECEIVE_RESTARTING,
  PIPELINE_RECEIVE_REQUEST_ERROR,
  PIPELINE_RESET,
} from "./actions/Pipeline";

import { insertDataById } from "./utils/helpers";

/*
Constants and helpers
*/

const INITIAL_STATE = {
  activeJobMetricsByOrg: {},
  byId: {},
  metricsLastReceivedByOrg: {},
  primaryByOrg: {},
  pipelineMetricsByOrg: {},
  restartingByOrg: {},
  requestErrorByOrg: {},
};

const updateOrgData = (state, action) => {
  const { payload = {} } = action;
  const { data, orgId } = payload;

  const { id: pipelineId = null } = data;

  const nextState = {
    ...state,
    [orgId]: pipelineId,
  };

  return nextState;
};

// 'activeJobMetricsByOrg'
const activeJobMetricsByOrgReducer = (
  state = INITIAL_STATE.pipelineMetricsByOrg,
  action
) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_ACTIVE_JOB_METRICS: {
      return insertDataById(state, action);
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.activeJobMetricsByOrg;
    }
    default:
      return state;
  }
};

// 'metricsLastReceivedByOrg'
const metricsLastReceivedByOrgReducer = (
  state = INITIAL_STATE.metricsLastReceivedByOrg,
  action
) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_PIPELINE_METRICS: {
      const { payload: actionPayload = {} } = action;

      const lastReceived = moment.utc().toISOString();
      const payload = {
        insertId: actionPayload.insertId,
        data: lastReceived,
      };

      return insertDataById(state, { payload });
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.metricsLastReceivedByOrg;
    }
    default:
      return state;
  }
};

// 'pipelineMetricsByOrg'
const pipelineMetricsByOrgReducer = (
  state = INITIAL_STATE.pipelineMetricsByOrg,
  action
) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_PIPELINE_METRICS: {
      return insertDataById(state, action);
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.pipelineMetricsByOrg;
    }
    default:
      return state;
  }
};

// Primary pipelines by their ID
const byIdReducer = (state = INITIAL_STATE.byId, action) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_PRIMARY_PIPELINE: {
      return insertDataById(state, action);
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.byId;
    }
    default:
      return state;
  }
};

// Primary pipelines by org ID
const primaryByOrgReducer = (state = INITIAL_STATE.primaryByOrg, action) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_PRIMARY_PIPELINE: {
      return updateOrgData(state, action);
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.primaryByOrg;
    }
    default:
      return state;
  }
};

// Primary pipeline is being restarted flag by org
const restartingByOrgReducer = (
  state = INITIAL_STATE.restartingByOrg,
  action
) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_RESTARTING: {
      return insertDataById(state, action);
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.restartingByOrg;
    }
    default:
      return state;
  }
};

// Restart primary pipeline request error by org
const requestErrorByOrgReducer = (
  state = INITIAL_STATE.requestErrorByOrg,
  action
) => {
  switch (action.type) {
    case PIPELINE_RECEIVE_REQUEST_ERROR: {
      return insertDataById(state, action);
    }
    case PIPELINE_RESET: {
      return INITIAL_STATE.requestErrorByOrg;
    }
    default:
      return state;
  }
};

const PipelineReducer = combineReducers({
  activeJobMetricsByOrg: activeJobMetricsByOrgReducer,
  byId: byIdReducer,
  metricsLastReceivedByOrg: metricsLastReceivedByOrgReducer,
  pipelineMetricsByOrg: pipelineMetricsByOrgReducer,
  primaryByOrg: primaryByOrgReducer,
  restartingByOrg: restartingByOrgReducer,
  requestErrorByOrg: requestErrorByOrgReducer,
});

export default PipelineReducer;
