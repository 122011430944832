// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Outer container *****************
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 120px auto;
  max-width: 600px;
  width: 100%;
`;

// ** Row container *******************
export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  width: 100%;
`;
