// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** ChildContainer ******************
export const ChildContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

// ** Outer container *****************
export const Container = styled.div`
  background-color: ${COLOR.gray000};
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 32px;
  width: 100%;
`;

// ** Header column *******************
export const HeaderColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

// ** Header container ****************
export const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 42px;
  width: 100%;
`;

// ** Header row **********************
export const HeaderRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

// ** Icon container ******************
export const IconContainer = styled.div`
  margin-right: 8px;
  margin-top: -1px;
`;

// ** Page title **********************
export const PageTitle = styled.span`
  color: #35485c;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
`;

// ** Title badge *********************

export const TitleBadge = styled.span`
  font-variant-caps: all-small-caps;
  margin-left: 5px;
`;
