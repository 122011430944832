import { formatBytes } from "../../../../utils/index";

export const formatNum = (num) => num.toLocaleString();

export const formatBarLabel = (str) => formatBytes(str);

// Converts the `fieldName` value to number
export const dataToNum = ({ data, fieldName }) =>
  (data || []).map((elt) => ({
    ...elt,
    [fieldName]: Number(elt[fieldName]),
  }));

// Converts the `fieldName` value to the logarithm of the original, and
// adds `bluBarLabelValue` key containing the original value.
export const dataToNumLog = ({ data, fieldName, logBaseLn = 1 }) => {
  const getScaledNumber = (num) => {
    if (num <= 1) {
      return 0;
    }

    const out = Math.log(num) / logBaseLn;

    return out;
  };

  return (data || []).map((elt) => ({
    ...elt,
    [fieldName]: getScaledNumber(Number(elt[fieldName])),
    bluBarLabelValue: elt[fieldName],
  }));
};

export const dataForOrgAndTime = ({
  data,
  orgId,
  timeInterval,
  defaultValue = [],
}) => (data[orgId] || {})[timeInterval] || defaultValue;
