import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Dialog, TextField, DialogActions } from "@mui/material";

const PREFIX = "FindingsResolutionDialog";

const classes = {
  notesDialogContainer: `${PREFIX}-notesDialogContainer`,
  resolutionTextField: `${PREFIX}-resolutionTextField`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.notesDialogContainer}`]: {
    padding: 20,
    width: 400,
  },
  [`& .${classes.resolutionTextField}`]: {
    width: "100%",
  },
}));

const FindingsResolutionDialog = (props) => {
  const [resolutionNotes, setResolutionNotes] = useState("");

  const handleResolutionNotesChange = (event) => {
    setResolutionNotes(event.target.value);
  };

  const handleClose = () => {
    const { onClose } = props;
    setResolutionNotes("");
    onClose();
  };

  const handleResolveFindings = () => {
    setResolutionNotes("");
    props.handleResolveFindings(resolutionNotes);
  };

  return (
    <StyledDialog onClose={handleClose} open={props.open}>
      <div className={classes.notesDialogContainer}>
        <TextField
          value={resolutionNotes}
          label={"Resolution Notes"}
          onChange={handleResolutionNotesChange}
          className={classes.resolutionTextField}
          InputProps={{ disableUnderline: true }}
        />
      </div>
      <DialogActions>
        <Button
          onClick={handleClose}
          color={"primary"}
          variant={"outlined"}
          style={{ marginLeft: 5 }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleResolveFindings}
          color={"primary"}
          variant={"outlined"}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default FindingsResolutionDialog;
