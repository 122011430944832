import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  clearDetailErr,
  clearDevicesErr,
} from "../../../redux/actions/Sensors";
import {
  detailErrorSelector,
  devicesErrorSelector,
} from "../../../selectors/sensorsSelectors";
import { ErrorBanner } from "../../Components/ErrorBanner";

/*
Component
*/
const ErrorMessages = ({
  detailError,
  devicesError,
  dismissDetailErr,
  dismissDevicesErr,
}) => {
  if (!detailError && !devicesError) {
    return null;
  }

  const detailErrorMsg = detailError ? detailError.message : "";
  const devicesErrorMsg = devicesError ? devicesError.message : "";

  const error = detailError || devicesError;

  if (!error) {
    return null;
  }

  return (
    <React.Fragment>
      {detailError && (
        <ErrorBanner message={detailErrorMsg} dismiss={dismissDetailErr} />
      )}

      {devicesError && (
        <ErrorBanner message={devicesErrorMsg} dismiss={dismissDevicesErr} />
      )}
    </React.Fragment>
  );
};

ErrorMessages.propTypes = {
  detailError: PropTypes.shape({}),
  devicesError: PropTypes.shape({}),
  dismissDetailErr: PropTypes.func.isRequired,
  dismissDevicesErr: PropTypes.func.isRequired,
};

ErrorMessages.defaultProps = {
  detailError: null,
  devicesError: null,
};

const mapStateToProps = (state) => {
  const detailError = detailErrorSelector(state);
  const devicesError = devicesErrorSelector(state);

  return {
    detailError,
    devicesError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dismissDetailErr: () => {
    dispatch(clearDetailErr);
  },

  dismissDevicesErr: () => {
    dispatch(clearDevicesErr);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessages);
