import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import { orgNameSelector } from "../../../selectors/orgNameSelector";
import {
  requestErrorMessageSelector,
  restartingPipelineSelector,
} from "../../../selectors/pipelineSelectors";
import { locationQuerySelector } from "../../../selectors/locationSelectors";
import {
  receiveRequestError,
  startPrimaryPipeline,
} from "../../../redux/actions/Pipeline";
import { ErrorBanner } from "../../Components/ErrorBanner";
import WorkerSelect from "./WorkerSelect";

import {
  filterData,
  getDisabled,
  optionsFromArr,
  workerCountErrorMsg,
  workerMaxOptionsFromArr,
  WORKER_MAX_VALUES,
  WORKER_COUNT_VALUES,
  WORKER_TYPE_VALUES,
} from "./PipelineModal";

import "../Sensor/SensorModal.scss";
import "../Sensor/SensorModalContent.scss";

/*
Constants and helpers
*/
const DEFAULT_LABEL = "Default";

const WORKER_MAX_OPTIONS = workerMaxOptionsFromArr(
  WORKER_MAX_VALUES,
  DEFAULT_LABEL
);

const WORKER_COUNT_OPTIONS = optionsFromArr(WORKER_COUNT_VALUES, DEFAULT_LABEL);

const WORKER_TYPE_OPTIONS = optionsFromArr(WORKER_TYPE_VALUES, DEFAULT_LABEL);

const getModalBody = (orgName, restarting) =>
  restarting
    ? `Starting the primary pipeline for ${orgName}`
    : "This will create a primary pipeline" + ` for ${orgName}`;
const getModalHeader = (orgName, restarting) =>
  restarting
    ? `Starting the primary pipeline for ${orgName}`
    : `Start primary pipeline for ${orgName}`;

/*
Main component
*/

export const PipelineModal = ({
  dispatchCloseModal = () => {},
  dismissRequestError = () => {},
  dispatchStart = () => {},
  orgId,
  orgName,
  requestErrorMessage,
  restarting,
  modalOpen = false,
}) => {
  const firstRun = useRef(true);

  const [workerCount, setWorkerCount] = useState(null);
  const [workerMax, setWorkerMax] = useState(null);
  const [workerType, setWorkerType] = useState(null);

  const [formErrorMessage, setFormErrorMessage] = useState("");

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dismissRequestError(orgId);
    dispatchCloseModal();
  }, [orgId, dismissRequestError, dispatchCloseModal]);

  useEffect(() => {
    setFormErrorMessage(
      workerCountErrorMsg({
        workerCount,
        workerMax,
      })
    );
  }, [workerCount, workerMax]);

  useEffect(() => {
    dismissRequestError(orgId);
  }, [dismissRequestError, orgId, workerCount, workerMax, workerType]);

  const onCancelRestart = () => {
    dismissRequestError(orgId);
    dispatchCloseModal();
  };

  const onConfirmRestart = () => {
    const data = filterData({
      workerCount,
      workerMax,
      workerType,
    });

    const errMessage = workerCountErrorMsg({
      workerCount,
      workerMax,
    });
    if (errMessage) {
      setFormErrorMessage(errMessage);
      return;
    }

    dispatchStart({
      orgId,
      ...data,
    });
  };

  // Handle worker initial count selection
  const handleWorkerCountSelect = (newValue) => {
    setWorkerCount(newValue);
  };

  // Handle worker max count selection
  const handleWorkerMaxSelect = (newValue) => {
    setWorkerMax(newValue);
  };

  // Handle worker type selection
  const handleWorkerTypeSelect = (newValue) => {
    setWorkerType(newValue);
  };

  const modalBody = getModalBody(orgName, restarting);
  const modalHeader = getModalHeader(orgName, restarting);

  const disabled = getDisabled(formErrorMessage, restarting);

  return (
    <Modal
      zIndex={1500}
      isOpen={!!modalOpen}
      className="sensor-modal"
      backdrop={null}
      size="lg"
    >
      <ModalHeader toggle={onCancelRestart} className="sensor-modal-header">
        {modalHeader}
      </ModalHeader>

      <ModalBody className="align-middle rounded sensor-modal-body">
        <div className="sensor-modal-content">
          {requestErrorMessage && (
            <ErrorBanner
              message={requestErrorMessage}
              dismiss={() => {
                dismissRequestError(orgId);
              }}
            />
          )}

          {!requestErrorMessage && (
            <div className="pipeline-modal-body">{modalBody}</div>
          )}

          {formErrorMessage && (
            <div className="alert alert-danger sensor-form-item">
              {formErrorMessage}
            </div>
          )}

          <div className="sensor-form-item">
            <div className="sensor-label">Machine type</div>
            <WorkerSelect
              options={WORKER_TYPE_OPTIONS}
              defaultValue={workerType}
              onSelect={handleWorkerTypeSelect}
            />
          </div>

          <div className="sensor-form-item">
            <div className="sensor-label">Initial worker count</div>
            <WorkerSelect
              options={WORKER_COUNT_OPTIONS}
              defaultValue={workerCount}
              onSelect={handleWorkerCountSelect}
            />
          </div>

          <div className="sensor-form-item">
            <div className="sensor-label">Maximum worker count</div>
            <WorkerSelect
              options={WORKER_MAX_OPTIONS}
              defaultValue={workerMax}
              onSelect={handleWorkerMaxSelect}
            />
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        {!restarting && (
          <Button color="danger" onClick={onCancelRestart}>
            Cancel
          </Button>
        )}

        <Button
          color="primary"
          disabled={disabled}
          className="pipeline-modal-submit-btn"
          onClick={onConfirmRestart}
        >
          {!restarting && <span>Start</span>}
          {restarting && <span>...</span>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

PipelineModal.propTypes = {
  dispatchCloseModal: PropTypes.func.isRequired,
  dismissRequestError: PropTypes.func.isRequired,
  dispatchStart: PropTypes.func.isRequired,
  modalOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  requestErrorMessage: PropTypes.string.isRequired,
  restarting: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  // Is modal open?
  const location = locationQuerySelector(state);
  const modalOpen = location.start || false;

  // Org ID
  const orgId = orgIdSelector(state);

  // Org name
  const orgName = orgNameSelector(state);

  // Get the 'restart pipeline' request error message
  const requestErrorMessage = requestErrorMessageSelector(state);

  // Is the pipeline being restarted?
  const restarting = restartingPipelineSelector(state);

  return {
    orgId,
    orgName,
    modalOpen,
    restarting,
    requestErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseModal: () => {
    dispatch(closeModal);
  },

  dismissRequestError: (orgId) => {
    dispatch(receiveRequestError({ orgId, error: null }));
  },

  dispatchStart: ({ orgId, workerCount, workerMax, workerType }) => {
    dispatch(
      startPrimaryPipeline({
        orgId,
        workerCount,
        workerMax,
        workerType,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PipelineModal);
