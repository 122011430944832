import { combineReducers } from "redux";

import {
  RESET_ORGS,
  RECEIVE_ORGS,
  RECEIVE_ORGS_ERROR,
  ORG_RECEIVE_ORG_DETAIL,
  ORG_RESET_ORG,
  ORG_RECEIVE_ORG_ERROR,
  ORG_RECEIVE_ORG_LOADING,
} from "./actions/Org";
import { insertDataById } from "./utils/helpers";

const INITIAL_STATE = {
  byId: [],
  errorByOrg: {},
  loadingByOrg: {},
  listOrgs: {
    orgs: [],
    error: null,
    isFetching: true,
  },
};

const makeReducer = (initialState, receiveStr) => {
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case receiveStr: {
        return insertDataById(state, action);
      }
      case ORG_RESET_ORG: {
        return initialState;
      }
      default:
        return state;
    }
  };

  return reducer;
};

const byIdReducer = makeReducer(INITIAL_STATE.byId, ORG_RECEIVE_ORG_DETAIL);

const errorByOrgReducer = makeReducer(
  INITIAL_STATE.errorByOrg,
  ORG_RECEIVE_ORG_ERROR
);

const loadingByOrgReducer = makeReducer(
  INITIAL_STATE.loadingByOrg,
  ORG_RECEIVE_ORG_LOADING
);

const OrgsReducer = (state = INITIAL_STATE.listOrgs, action = {}) => {
  switch (action.type) {
    case RESET_ORGS: {
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    }

    case RECEIVE_ORGS: {
      return {
        ...state,
        isFetching: false,
        orgs: action.orgs,
      };
    }

    case RECEIVE_ORGS_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetching: false,
      };
    }

    default:
      return state;
  }
};

const OrgReducer = combineReducers({
  byId: byIdReducer,
  errorByOrg: errorByOrgReducer,
  loadingByOrg: loadingByOrgReducer,
  listOrgs: OrgsReducer,
});

export default OrgReducer;
