import { BaseModel } from "./BaseModel";

export class User extends BaseModel {
  schema = "/user";
  constructor(params = {}) {
    return super(params, "/user");
  }

  fields = {
    name: () =>
      this.firstName || this.lastName
        ? `${this.firstName} ${this.lastName}`
        : undefined,
  };
}
