import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadPageData } from "redux/actions/Request";
import moment from "moment-timezone";
import { Root, classes } from "./styles";

import {
  useQueryControls,
  useResultControls,
} from "views/Components/QueryControls";

import { TimespanPicker } from "views/Components/TimespanPicker";

import CheckIcon from "@mui/icons-material/Check";

import {
  List,
  Grid,
  Paper,
  Alert,
  Button,
  ListItem,
  Skeleton,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { setPageHashParams } from "utils";
import { abbreviateNumber } from "./helpers";
import { entityFields, entityTypes } from "./defs";

import { LegacyOverview } from "./Overview";

import createTimelineQuery from "./query/timeline";

import SSLine from "./charts/timeline";

const normalize = (val, max, min) => (val - min) / (max - min);

/**
 * field name:
 * - op: API operator to use for matching
 * - validate: if search string does not pass validate function, it will not be used in request
 */

export const getQueryFieldsByEntityName = (entityName) => {
  const retVal = [];
  if (!Array.isArray(entityName)) {
    entityName = [entityName];
  }
  const eTypeList = entityTypes.filter((eT) => entityName.includes(eT.name));
  eTypeList.forEach((eT) => {
    eT.queryFields.forEach((f) => {
      if (!retVal.includes(f)) {
        retVal.push(f);
      }
    });
  });
  return retVal;
};

export const defaultStartTime = moment().subtract(24, "hours").unix() * 1000;
export const defaultEndTime = moment().unix() * 1000;

const timespanPickerDeltas = [
  "@-24",
  "-7:d",
  "-30:d",
  "-1:w",
  "0:m",
  "-1:m",
  "0:q",
  "-1:q",
];

const SimpleSearchInputPage = (props) => {
  const [searchValue, setSearchValue] = useState("");

  const handleChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSubmit = () => {
    if (searchValue && searchValue.length >= 3) {
      window.location.hash = `#search=${searchValue}`;
    }
  };

  const handleKeypress = (event) => {
    if (event.which === 13) {
      handleSubmit();
    }
  };

  return (
    <Root>
      <TextField
        labelId="search-value-label"
        text={searchValue}
        onChange={handleChangeSearchValue}
        onSubmit={handleSubmit}
        onKeyPress={handleKeypress}
        id="search-value-input"
        autocomplete="on"
        placeholder={"Look something up here..."}
        InputProps={{
          classes: {
            input: classes.textField,
          },
        }}
      />
      <Button
        disabled={searchValue.length < 3}
        variant="contained"
        onClick={handleSubmit}
        color="primary"
        className={classes.investigateButton}
      >
        Submit
      </Button>
      <Typography className={classes.directionalText} variant="h6">
        What can I look up?
      </Typography>
      <List component="nav">
        <ListItem className={classes.listItem} dense>
          <ListItemIcon className={classes.checkIcon}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Users (usernames or email addresses)" />
        </ListItem>
        <ListItem className={classes.listItem} dense>
          <ListItemIcon className={classes.checkIcon}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="IP Addresses" />
        </ListItem>
        <ListItem className={classes.listItem} dense>
          <ListItemIcon className={classes.checkIcon}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Ports" />
        </ListItem>
        <ListItem className={classes.listItem} dense>
          <ListItemIcon className={classes.checkIcon}>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="Applications" />
        </ListItem>
      </List>
    </Root>
  );
};

const LoadingPage = ({ searchValue }) => (
  <Root>
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12} className={classes.loadingContainer}>
        <Typography variant="h4">Looking up: {searchValue}</Typography>
        <Skeleton
          animation={"wave"}
          variant={"rounded"}
          className={classes.timeSelectSkeletonLoadingPulse}
        />
        <Skeleton
          animation={"wave"}
          variant={"rounded"}
          className={classes.chartSkeletonLoadingPulse}
        />
      </Grid>
    </Grid>
  </Root>
);

/**
 * timelineQuery
 * contains daily breakdown of how many time the searchValue matched fields
 * It does not contain any info on how many entites there are per day.
 */

const SimpleSearchResultsView = (props) => {
  const { ready, timelineQuery, findings, reload, pageHashParams, orgId } =
    props;

  // Timeline at the top of the page
  const [timelineQueryControls, timelineQueryControlInterface] =
    useQueryControls("timelineQuery");
  const [timelineResultControls, timelineResultControlInterface] = // eslint-disable-line no-unused-vars
    useResultControls("timelineQuery");

  const [searchParams, setSearchParams] = useState({
    searchValue: pageHashParams.search || "",
    startTime: pageHashParams.start || defaultStartTime,
    endTime: pageHashParams.end || defaultEndTime,
  });

  const [autoSelectedFields, setAutoSelectedFields] = useState([]);

  // timeline Data
  const [lineData, setLineData] = useState([]);
  const [entityList, setEntityList] = useState({});

  // overview data
  const [overviewData, setOverviewData] = useState({
    originData: {},
    entityReports: {},
    entityEvents: {},
  });

  // const [delta, setDelta] = useState();
  const [selectedEndTime, setSelectedEndTime] = useState(
    moment(defaultEndTime)
  );
  const [selectedStartTime, setSelectedStartTime] = useState(
    moment(defaultStartTime)
  );

  // auto select filters if the searchValue explicitly matches over 50% of the total fields
  const matchCountPercent = 0.5;

  useEffect(() => {
    if (ready) {
      fetchTimeline(
        searchParams.searchValue,
        moment(Math.floor(searchParams.startTime)),
        moment(Math.ceil(searchParams.endTime))
      );
    }
  }, [searchParams]);

  useEffect(() => {
    const newParams = {
      ...searchParams,
      searchValue: pageHashParams["search"],
    };

    if (pageHashParams["start"]) {
      const newSelectedStartTime = moment.unix(pageHashParams.start / 1000);
      setSelectedStartTime(newSelectedStartTime);
      newParams["startTime"] = pageHashParams.start;
    }
    if (pageHashParams["end"]) {
      const newSelectedEndTime = moment.unix(pageHashParams.end / 1000);
      setSelectedEndTime(newSelectedEndTime);
      newParams["endTime"] = pageHashParams.end;
    }

    setSearchParams(newParams);
  }, [pageHashParams]);

  useEffect(() => {
    const entityCount = {};

    entityTypes.forEach((eT) => {
      entityCount[eT.name] = {
        count: 0,
        disabled: false,
      };
    });

    const seriesIndexed = {};
    entityTypes.forEach((eT) => {
      seriesIndexed[eT.name] = {
        name: eT.name,
        data: [],
        color: eT.color,
      };
    });

    timelineQuery.forEach((o) => {
      const month = o.month < 10 ? `0${o.month}` : o.month;
      const day = o.day < 10 ? `0${o.day}` : o.day;
      const hour = o.hour < 10 ? `0${o.hour}` : o.hour;
      const dateString = `${o.year}-${month}-${day}T${hour}:00:00`;
      const uts = new moment(dateString).unix() * 1000;

      entityTypes.forEach((eT) => {
        const total = eT.queryFields.reduce((a, field) => {
          return a + o[`sum_${field.fieldName}`] || 0;
        }, 0);

        seriesIndexed[eT.name].data.push([uts, total]);
        entityCount[eT.name].count += total;
      });
    });

    Object.values(seriesIndexed).forEach((s) => {
      s.data.sort((a, b) => a[0] - b[0]);
    });
    setEntityList(entityCount);
    setLineData(Object.values(seriesIndexed));
  }, [timelineQuery]);

  useEffect(() => {
    if (timelineQuery.length > 0) {
      const _entityEvents = Object.fromEntries(
        entityTypes.map((eT) => [eT.name, new Set([])])
      );
      const matchCount = Object.fromEntries(
        Object.keys(entityFields).map((k) => [k, 0])
      );

      timelineQuery.forEach((m) => {
        Object.keys(entityFields)
          .filter((k) => !!m[k])
          .forEach((k) => {
            if (m[k] == searchParams.searchValue) {
              matchCount[k] += 1;
            }
          });
      });

      const sDev = Object.values(matchCount).sort().reverse();
      const matchCountSum = sDev.reduce((a, b) => a + b, 0);
      if (matchCountSum > 0) {
        setAutoSelectedFields(
          Object.keys(matchCount).filter(
            (k) => matchCount[k] >= matchCountSum * matchCountPercent
          )
        );
      }

      const tableColumns = Object.keys(entityFields).map((k) => {
        return { title: k, field: k, searchable: false };
      });

      const _uniqueValues = {};
      tableColumns.forEach((column) => {
        const _uniques = {};
        timelineQuery
          .filter((o) => o[column.field])
          .forEach((o) => {
            const lK = o[column.field].toLowerCase
              ? o[column.field].toLowerCase()
              : o[column.field];
            if (_uniques[lK] === undefined) {
              _uniques[lK] = {
                value: o[column.field],
                count: 0,
                origin: [],
                sparkline: {},
              };
            }
            _uniques[lK].count += 1;
            _uniques[lK].origin.push(o);
            // XXX TODO: the sum of all sparkline values does not match the sum of all sum_totals
            if (o.sparkline) {
              o.sparkline.forEach((v) => {
                if (_uniques[lK].sparkline[v.start] === undefined) {
                  _uniques[lK].sparkline[v.start] = 0;
                }
                _uniques[lK].sparkline[v.start] += v.value;
              });
            }
            // loop over entities and build spark data based off matching rows
            entityTypes.forEach((eT) => {
              if (
                eT.matchFields.map((f) => f.fieldName).includes(column.field)
              ) {
                _entityEvents[eT.name].add(o);
              }
            });
          });

        if (Object.keys(_uniques).length > 0) {
          _uniqueValues[column.field] = _uniques;
        }
      });

      const _attributeMatchCount = {};
      const lowerSearch = searchParams.searchValue.toLowerCase();
      const matchDistanceRange = [99999, 0];
      Object.keys(_uniqueValues).forEach((k) => {
        const uMatches = Object.keys(_uniqueValues[k]).filter((v) =>
          v.includes(lowerSearch)
        );
        const lens = uMatches.map((v) => v.length - lowerSearch.length);
        if (lens.length > 0) {
          lens.sort((a, b) => a - b);
          const minMatchDistance = lens[0];
          const avgMatchDistance =
            lens.reduce((a, b) => a + b, 0) / lens.length;
          const maxMatchDistance = lens.lastItem;
          const totalValues = Object.keys(_uniqueValues[k]).length;

          if (minMatchDistance < matchDistanceRange[0]) {
            matchDistanceRange[0] = minMatchDistance;
          }
          if (maxMatchDistance > matchDistanceRange[1]) {
            matchDistanceRange[1] = maxMatchDistance;
          }
          _attributeMatchCount[k] = {
            matches: uMatches,
            totalValues,
            dSignal:
              (minMatchDistance + maxMatchDistance) / 2 / maxMatchDistance,
            mSignal: uMatches.length / totalValues,
            minMatchDistance,
            avgMatchDistance,
            maxMatchDistance,
          };
        }
      });

      const _entityReports = {};
      Object.keys(_attributeMatchCount).forEach((k) => {
        _attributeMatchCount[k].health = normalize(
          _attributeMatchCount[k].avgMatchDistance,
          ...matchDistanceRange
        );
        const entMatches = entityTypes.filter((eT) =>
          eT.matchFields.includes(entityFields[k])
        );
        entMatches.forEach((e) => {
          if (_entityReports[e.name] === undefined) {
            _entityReports[e.name] = {};
          }
          _entityReports[e.name][k] = _attributeMatchCount[k];
        });
      });

      setOverviewData({
        originData: _uniqueValues,
        entityEvents: _entityEvents,
        entityReports: _entityReports,
      });
    }
  }, [timelineQuery]);

  const fetchTimeline = (searchValue, startTime, endTime) => {
    const entityNames = Object.keys(entityList).filter(
      (eT) => !entityList[eT].disabled
    );

    // TIMELINE QUERY START //
    const timelineQuery = createTimelineQuery(
      startTime,
      endTime,
      pageHashParams.search,
      getQueryFieldsByEntityName(entityNames)
    );

    Object.keys(timelineQueryControls).map((uuid) => {
      timelineQueryControlInterface.remove(uuid);
    });

    timelineQuery.queryParams.map((qp) =>
      timelineQueryControlInterface.add(qp)
    );

    timelineResultControlInterface.update(
      "transform",
      timelineQuery.resultParams.transform
    );
    // TIMELINE QUERY END //

    reload(true);
  };

  const handleSetTimes = (startTime, endTime) => {
    let momentStart = moment(startTime);
    let momentEnd = moment(endTime);

    setSelectedStartTime(momentStart);
    setSelectedEndTime(momentEnd);

    const start = momentStart.startOf("minute").unix() * 1000;
    const end = momentEnd.endOf("minute").unix() * 1000;

    setPageHashParams({
      ...pageHashParams,
      start: start,
      end: end,
    });
  };

  const handleResetSearchValue = () => {
    // remove `search` from page hash to clear URL
    setPageHashParams({});

    // remove searchValue from state to reset UI
    setSearchParams({
      ...searchParams,
      searchValue: "",
    });
  };

  const handleLegendClick = (chartContext, seriesIndex, config) => {
    const { series } = config.config;
    const selectedSeriesItemName = series[seriesIndex].name;

    const enabled = [];
    series.forEach((seriesItem) => {
      const isClicked = seriesItem.name !== selectedSeriesItemName;
      if (isClicked) {
        if (seriesItem.data.length > 0) {
          enabled.push(seriesItem.name);
        }
      } else if (seriesItem.data.length === 0) {
        enabled.push(seriesItem.name);
      }
    });

    // copy over the items so we're not mutating anything. #sanity
    const newEntityList = {};
    Object.keys(entityList).forEach((eT) => {
      newEntityList[eT] = { ...entityList[eT] };
      if (enabled.includes(eT)) {
        newEntityList[eT].disabled = false;
      } else {
        newEntityList[eT].disabled = true;
      }
    });
    setEntityList(newEntityList);
  };

  if (!searchParams.searchValue) {
    return <SimpleSearchInputPage />;
  }

  // if the page is still loading
  if (ready === false) {
    return <LoadingPage searchValue={searchParams.searchValue} />;
  }
  return (
    <Root>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} className={classes.resultsTitleText}>
          <Typography variant="h4">
            Results for: {searchParams.searchValue}
          </Typography>
          {overviewData.length === 5000 && (
            <Alert severity="warning">
              We found a high volume of data matching your search criteria. If
              you cannot find the data you're looking for, please refine your
              search term and timespan to focus your results further.
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <TimespanPicker
            starttime={selectedStartTime}
            endtime={selectedEndTime}
            settimes={handleSetTimes}
            deltas={timespanPickerDeltas}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper>
            {lineData.every((obj) => obj?.data?.length === 0) ? (
              <div className={classes.noDataContainer}>
                <h4>No data available.</h4>
                <Button variant={"contained"} onClick={handleResetSearchValue}>
                  Try another search term
                </Button>
              </div>
            ) : (
              lineData.length > 0 && (
                <SSLine
                  data={lineData}
                  entityList={entityList}
                  findings={findings}
                  extra={Object.fromEntries(
                    Object.keys(entityList).map((entity) => [
                      entity,
                      abbreviateNumber(entityList[entity].count),
                    ])
                  )}
                  legendClick={handleLegendClick}
                  min={searchParams.startTime}
                  max={searchParams.endTime}
                />
              )
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <>
            {entityTypes
              .filter(
                (eT) => entityList[eT.name] && !entityList[eT.name].disabled
              )
              .filter((eT) => overviewData.entityEvents[eT.name])
              .map((eT) => (
                <LegacyOverview
                  key={eT.name}
                  orgId={orgId}
                  entity={eT}
                  entityEvents={overviewData.entityEvents[eT.name] || []}
                  originData={overviewData.originData}
                  searchValue={searchParams.searchValue}
                  autoSelectedFields={autoSelectedFields}
                  searchParams={searchParams}
                ></LegacyOverview>
              ))}
          </>
        </Grid>
      </Grid>
    </Root>
  );
};

const SimpleSearchPageView = (props) => {
  if (props.pageHashParams.search) {
    return <SimpleSearchResultsView {...props} />;
  } else {
    return <SimpleSearchInputPage {...props} />;
  }
};

SimpleSearchPageView.propTypes = {
  ready: PropTypes.bool,
  orgId: PropTypes.string.isRequired,
  timelineQuery: PropTypes.arrayOf(PropTypes.shape({})),
  overviewQuery: PropTypes.arrayOf(PropTypes.shape({})),
  findings: PropTypes.arrayOf(PropTypes.shape({})),
};

SimpleSearchPageView.defaultProps = {
  timelineQuery: [],
  overviewQuery: [],
  findings: [],
};

const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
  };
};

const mapDispatchToProps = (dispatch, { orgId }) => ({
  reload: (force) => dispatch(loadPageData(force)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleSearchPageView);
