import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import HelpToolTip from "../../Components/HelpToolTip";
import CheckBox from "../../Components/CheckBox";

import "./SensorModalContent.scss";

/*
Constants and Helpers
*/

const SENSOR_NAME_TOOLTIP_TEXT = `
  Sensor names must contain only alphanumeric characters and hyphens,
  start with a letter, have no spaces, and be unique within your
  organization
  (i.e. each sensor in your organization must have a distinct name).
`;

const EMAIL_RECIPIENTS_TEXT = `
  Unless you check this box, the email with sensor install instructions
  will be sent to you as well as to all members of your team with the
  "Administrator" role.
`;

const SENSOR_DESCRIPTION_MAX_LENGTH = 1024;

const getRandom = () => Math.random().toString().slice(2);

/*
Main component
*/
const NewSensor = ({
  description,
  handleChangeBroadcast,
  handleChangeDescription,
  handleChangeLocation,
  handleChangeName,
  loading,
  locations,
  locationValue,
  nameValue,
  noBroadcast,
}) => {
  const locationOptions = locations
    .map((a) => ({
      label: [a.name, a.city, a.state].filter((i) => i).join(", "),
      value: a.id,
    }))
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

  // Sensor name tooltip
  const [nameTargetId] = useState(`sensor_name_tooltip_${getRandom()}`);
  const [nameTooltipOpen, setNameTooltipOpen] = useState(false);

  const toggleNameTooltip = () => {
    setNameTooltipOpen(!nameTooltipOpen);
  };

  // 'Email only to me' tooltip
  const [noBroadcastTargetId] = useState(
    `broadcast_status_tooltip_${getRandom()}`
  );
  const [noBroadcastTooltipOpen, setBroadcastTooltipState] = useState(false);

  const toggleBroadcastTooltip = () => {
    setBroadcastTooltipState(!noBroadcastTooltipOpen);
  };

  return (
    <div className="sensor-new blocking-form sensor-modal-content">
      {!loading && <div className="sensor-subtitle">Create a New Sensor</div>}

      <div className="sensor-form-item">
        <div className="sensor-label">
          <span>Name</span>

          <span className="text-danger">*</span>

          <HelpToolTip
            className="notifications-form-tooltip"
            targetId={nameTargetId}
            isOpen={nameTooltipOpen}
            placement="top"
            toggle={toggleNameTooltip}
          >
            {SENSOR_NAME_TOOLTIP_TEXT}
          </HelpToolTip>
        </div>

        <div>
          <input
            type="text"
            className="sensor-input"
            value={nameValue}
            onChange={handleChangeName}
            required
            placeholder="Name the new sensor"
          />
        </div>
      </div>

      <div className="sensor-form-item">
        <div className="sensor-label">
          <span>Description</span>
        </div>

        <textarea
          className="blocking-form-textarea"
          cols={35}
          maxLength={SENSOR_DESCRIPTION_MAX_LENGTH}
          minLength={0}
          rows={5}
          value={description}
          onChange={handleChangeDescription}
          placeholder="Optional description for the new sensor"
        />
      </div>

      <div className="sensor-form-item">
        <div className="sensor-label">
          <CheckBox
            title="Email sensor installation link only to me"
            checked={noBroadcast}
            onChange={handleChangeBroadcast}
          />

          <HelpToolTip
            targetId={noBroadcastTargetId}
            isOpen={noBroadcastTooltipOpen}
            toggle={toggleBroadcastTooltip}
          >
            {EMAIL_RECIPIENTS_TEXT}
          </HelpToolTip>
        </div>
      </div>

      <div className="sensor-form-item">
        <div className="sensor-label">
          <span>Location</span>
          <span className="text-danger">*</span>
        </div>

        <div className="sensor-select">
          <Select
            options={locationOptions}
            value={locationValue}
            onChange={handleChangeLocation}
            placeholder="Choose the sensor location"
          />
        </div>
      </div>
    </div>
  );
};

// PropTypes
NewSensor.propTypes = {
  description: PropTypes.string,
  loading: PropTypes.bool,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
  locationValue: PropTypes.shape({}),
  nameValue: PropTypes.string,
  noBroadcast: PropTypes.bool,
  handleChangeBroadcast: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
};

NewSensor.defaultProps = {
  description: "",
  loading: false,
  nameValue: "",
  noBroadcast: false,
  locationValue: {},
  locations: [],
};

export default NewSensor;
