import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container } from "reactstrap";

import { pipelineStorageDataSelector } from "../../../selectors/pipelineSelectors";
import SupportEntry from "../../Components/Finding/SupportEntry";
import { formatBytes, formatNumber } from "../../../utils/index";

import "../Sensor/BluSupport.scss";

/*
Component
*/

const StorageData = (props) => {
  const { columnNumLeft, columnNumRight, storageData = {} } = props;
  const { numBytes = "", numRows = "" } = storageData;

  const storageSize = formatBytes(numBytes, 0);
  const storageRowNum = formatNumber(numRows, 0);

  return (
    <React.Fragment>
      <div className="blu-support-container">
        <div className="blu-support-section-title">Stored Data</div>
        <div className="blu-support-section-body">
          <Container className="list-container dark">
            <SupportEntry
              columnNumLeft={columnNumLeft}
              columnNumRight={columnNumRight}
              label="Size:"
              value={storageSize}
            />

            <SupportEntry
              columnNumLeft={columnNumLeft}
              columnNumRight={columnNumRight}
              label="Rows:"
              value={storageRowNum}
            />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const storageData = pipelineStorageDataSelector(state);

  return {
    storageData,
  };
};

StorageData.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  storageData: PropTypes.shape({}).isRequired,
};

StorageData.defaultProps = {
  columnNumLeft: 3,
  columnNumRight: 9,
};

export default connect(mapStateToProps, null)(StorageData);
