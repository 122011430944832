import {
  // Error
  TAGGING_CLEAR_ERROR,
  TAGGING_RECEIVE_ERROR,
  // Loading
  TAGGING_CLEAR_LABELS_LOADING,
  TAGGING_SET_LABELS_LOADING,
  TAGGING_CLEAR_TAGS_LOADING,
  TAGGING_SET_TAGS_LOADING,
  // Labels
  RECEIVE_LABELS,
  // Tags
  RECEIVE_TAGS,
  // Entire store
  RESET_TAGGING,
} from "./actions/Tagging";

/*
`labels` is an array of the form
{ orgId1: [Label11, Label12,...], orgId2: [Label21, Label22,...], ...}

`tags` is an object of the form { objId1: [Tag11, Tag12,...], objId2: [Tag21, Tag22,...], ...}
*/

const initialState = {
  labels: {},
  tags: {},
  error: null,
  labelsLoading: false,
  tagsLoading: false,
};

const TaggingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Error
    case TAGGING_RECEIVE_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case TAGGING_CLEAR_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    // Loading
    case TAGGING_SET_LABELS_LOADING: {
      return {
        ...state,
        labelsLoading: true,
      };
    }

    case TAGGING_CLEAR_LABELS_LOADING: {
      return {
        ...state,
        labelsLoading: false,
      };
    }

    case TAGGING_SET_TAGS_LOADING: {
      return {
        ...state,
        tagsLoading: true,
      };
    }

    case TAGGING_CLEAR_TAGS_LOADING: {
      return {
        ...state,
        tagsLoading: false,
      };
    }

    // Labels
    case RECEIVE_LABELS: {
      const labels = {
        ...state.labels,
        ...action.labels,
      };

      return {
        ...state,
        labels,
        labelsLoading: false,
      };
    }

    // Tags
    case RECEIVE_TAGS: {
      const tags = {
        ...state.tags,
        ...action.tags,
      };

      return {
        ...state,
        tags,
        tagsLoading: false,
      };
    }

    // Entire store
    case RESET_TAGGING: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
};

export default TaggingReducer;
