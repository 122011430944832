// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Date ****************************
export const Date = styled.span`
  color: ${COLOR.gray500};
  display: block;
  font-size: 11px;
  font-weight: 300;
  padding-left: 12px;
  text-align: right;
`;

// ** Description *********************
export const Description = styled.span`
  color: ${COLOR.gray400};
  display: block;
  font-size: 11px;
  font-weight: 300;
  margin-top: 4px;
  text-align: left;
  transition: all 300ms;
  width: 100%;
`;

// ** Header container ****************
export const HeaderContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 288px;
  width: 100%;
`;

// ** Tag *****************************
export const Tag = styled.span`
  background-color: rgba(44, 45, 46, 0.2);
  border-radius: 3px;
  color: ${COLOR.white};
  display: block;
  font-size: 8px;
  font-weight: 500;
  letter-spacing: 0.05em;
  padding: 6px 8px 4px;
  text-transform: uppercase;
  transition: all 300ms;
`;

// ** Query title *********************
export const Title = styled.span`
  color: inherit;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
`;

// ** Outer container *****************
export const Container = styled.button`
  align-items: flex-start;
  background-color: transparent;
  border: none;
  color: ${COLOR.gray600};
  display: flex;
  flex-direction: column;
  outline: none !important;
  padding: 0px;
  transition: all 300ms;
  width: 100%;

  &:hover {
    color: ${COLOR.gray900};
  }

  &:hover ${Description} {
    color: ${COLOR.gray900};
  }

  &:hover ${Tag} {
    background-color: ${COLOR.gray600};
  }
`;
