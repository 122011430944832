import Request from "../../lib/api/Request";

import { handleApiError } from "../utils/helpers";
import { actionFailure } from "./Page";

export const DELETE_MESSAGE = "MESSAGE.DELETE_MESSAGE";
export const RECEIVE_MESSAGES = "MESSAGE.RECEIVE_MESSAGES";
export const RECEIVE_MESSAGE = "MESSAGE.RECEIVE_MESSAGE";
export const RESET_MESSAGE = "MESSAGE.RESET_MESSAGE";
export const UPDATE_MESSAGE = "MESSAGE.UPDATE_MESSAGE";

const COMMENT_TYPE = "FINDING_COMMENT";

export const getCommentsForFinding =
  (params = {}) =>
  (dispatch) => {
    const { findingId } = params;

    const queryParams = [
      { field: "foreignId", value: findingId },
      { field: "foreignType", value: COMMENT_TYPE },
    ];
    const request = new Request("/message", queryParams, {
      orderBy: [{ key: "created", value: -1 }],
    });

    request
      .get()
      .then((messages) =>
        dispatch({
          messages,
          objectId: findingId,
          type: RECEIVE_MESSAGES,
        })
      )
      .catch((err) => {
        const message = "Failed to load messages";
        dispatch(
          handleApiError({
            err,
            message,
            onError: actionFailure,
          })
        );
      });
  };

export const saveCommentForFinding = (message) => (dispatch) => {
  const { foreignId: findingId } = message;
  message.create().then((newMessage) => {
    dispatch({
      message: newMessage,
      objectId: findingId,
      type: RECEIVE_MESSAGE,
    });
  });
};

export const resetMessage = {
  type: RESET_MESSAGE,
};
