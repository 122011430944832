import { BaseModel } from "./BaseModel";

const FORM_TITLE_NOT_RESOLVED = "Resolve the Finding";
const FORM_TITLE_RESOLVED = "Edit the Resolution";

const STATUS_RESOLVED = 40;
export class FindingExport extends BaseModel {
  schema = "/findingexport";
  constructor(params = {}) {
    return super(params, "/findingexport");
  }

  fields = {
    formTitle: () =>
      this.status === STATUS_RESOLVED
        ? FORM_TITLE_RESOLVED
        : FORM_TITLE_NOT_RESOLVED,
  };
}
