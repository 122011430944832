const actionSchema = {
  $id: "/action",
  type: "object",
  properties: {
    id: { type: "string" },
    findingType: { type: "number" },
    priority: { type: "number" },
  },
  required: [],
  additionalProperties: false,
};

export default actionSchema;
