// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { any, bool, func, string } from "prop-types";

// ** Styles **************************
import { ChildContainer, Container, FlexRow } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Dropdown component
//
//  ** Remarks
//  Simple dropdown component extended to simplify code across the app
//
//  @param btnContents {any} - top level button contents
//  @param children {any} - dropdown contents
//  @param maxWidth {string} - child container max width
//  @param minWidth {string} - child container min width
//  @param padding {string} - button padding
//  @param right {string} - child container alignment
//
//  ** Methods
//  checkToggle - checks for focus and if click is within bounds of container
//  toggleDropdown - toggles focused state value
//
export default class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };

    this.childContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.checkToggle.bind(this));
  }

  componentWillUnmount() {
    window.addEventListener("click", this.checkToggle.bind(this));
  }

  checkToggle(e) {
    const { focused } = this.state;

    if (
      this.childContainer.current &&
      !this.childContainer.current.contains(e.target) &&
      focused
    ) {
      this.toggleDropdown();
    }
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      focused: !prevState.focused,
    }));
  }

  render() {
    const { focused } = this.state;
    const { btnContents, children, disabled, maxWidth, minWidth, right } =
      this.props;

    return (
      <Container ref={this.childContainer}>
        <FlexRow
          disabled={disabled}
          onClick={
            disabled ? Function.prototype : this.toggleDropdown.bind(this)
          }
        >
          {btnContents}
        </FlexRow>
        <ChildContainer
          focused={focused}
          maxWidth={maxWidth}
          minWidth={minWidth}
          right={right}
        >
          {children({ toggleDropdown: this.toggleDropdown.bind(this) })}
        </ChildContainer>
      </Container>
    );
  }
}

// ** Proptypes ***********************
Dropdown.propTypes = {
  btnContents: any,
  children: func,
  disabled: bool,
  maxWidth: string,
  minWidth: string,
  padding: string,
  right: bool,
};
