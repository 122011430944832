import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import { drainJob, receiveRequestError } from "../../../redux/actions/Pipeline";
import { ErrorBanner } from "../../Components/ErrorBanner";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import { orgNameSelector } from "../../../selectors/orgNameSelector";
import { locationQuerySelector } from "../../../selectors/locationSelectors";
import {
  primaryPipelineSelector,
  requestErrorMessageSelector,
  restartingPipelineSelector,
} from "../../../selectors/pipelineSelectors";

import "../Sensor/SensorModal.scss";
import "../Sensor/SensorModalContent.scss";

/*
Constants and helpers
*/

const getModalBody = (orgName) =>
  `Are you sure you want to drain the job for ${orgName}` +
  " without restarting?" +
  "  This may result in loss or delay of customer data.";

const TEXT2 = ' Please type "Yes" to confirm';

const validateInput = (inputText) => (inputText || "").toLowerCase() === "yes";

/*
Component
*/

const DrainJobModal = (props) => {
  const {
    dismissRequestError,
    dispatchCloseModal,
    dispatchDrainJob,

    modalOpen,
    orgId,
    orgName,
    pipelineId,
    requestErrorMessage,
    restarting,
  } = props;

  const firstRun = useRef(true);

  const [inputText, setInputText] = useState("");

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dismissRequestError(orgId);
    dispatchCloseModal();
  }, [orgId, dismissRequestError, dispatchCloseModal]);

  const onCancelDrain = () => {
    dismissRequestError(orgId);
    dispatchCloseModal();
  };

  const onConfirmDrain = () => {
    if (!validateInput(inputText)) {
      return;
    }

    dispatchDrainJob({
      orgId,
      pipelineId,
    });
  };

  const onChangeInput = (evt) => {
    const { target = {} } = evt;
    const { value = "" } = target;

    setInputText(value);
  };

  return (
    <Modal
      zIndex={1500}
      isOpen={!!modalOpen}
      className="sensor-modal"
      backdrop={null}
      size="lg"
    >
      <ModalHeader className="sensor-modal-header" toggle={onCancelDrain}>
        Drain the Job
      </ModalHeader>

      <ModalBody className="align-middle rounded sensor-modal-body">
        <div className="sensor-modal-content">
          {requestErrorMessage && (
            <ErrorBanner
              message={requestErrorMessage}
              dismiss={() => {
                dismissRequestError(orgId);
              }}
            />
          )}

          {!requestErrorMessage && (
            <div className="pipeline-modal-body">{getModalBody(orgName)}</div>
          )}

          <div className="sensor-form-item">
            <div className="sensor-label">{TEXT2}</div>

            <div>
              <input
                className="sensor-input"
                onChange={onChangeInput}
                placeholder=""
                type="text"
                value={inputText}
              />
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          className="pipeline-modal-submit-btn"
          onClick={onCancelDrain}
        >
          <span>Cancel</span>
        </Button>

        <Button
          color="danger"
          disabled={!validateInput(inputText)}
          className="pipeline-modal-submit-btn"
          onClick={onConfirmDrain}
        >
          {restarting ? <span>...</span> : <span>Drain</span>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DrainJobModal.propTypes = {
  dismissRequestError: PropTypes.func.isRequired,
  dispatchCloseModal: PropTypes.func.isRequired,
  dispatchDrainJob: PropTypes.func.isRequired,
  modalOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  pipelineId: PropTypes.string.isRequired,
  requestErrorMessage: PropTypes.string.isRequired,
  restarting: PropTypes.bool.isRequired,
};
DrainJobModal.defaultProps = {};

const mapStateToProps = (state) => {
  // Org ID
  const orgId = orgIdSelector(state);

  // Org name
  const orgName = orgNameSelector(state);

  // Is modal open?
  const location = locationQuerySelector(state);
  const modalOpen = location.drain || false;
  // const modalOpen = getModalStatus(state, 'drain');

  // Is the pipeline being drained?
  const restarting = restartingPipelineSelector(state);

  const pipeline = primaryPipelineSelector(state);
  const { id: pipelineId = "" } = pipeline;

  const requestErrorMessage = requestErrorMessageSelector(state);

  return {
    modalOpen,
    orgId,
    orgName,
    pipelineId,
    requestErrorMessage,
    restarting,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseModal: () => {
    dispatch(closeModal);
  },
  dispatchDrainJob: ({ orgId, pipelineId }) => {
    dispatch(
      drainJob({
        orgId,
        pipelineId,
      })
    );
  },
  dismissRequestError: (orgId) => {
    dispatch(receiveRequestError({ orgId, error: null }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DrainJobModal);
