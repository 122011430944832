// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { useState } from "react";
import { arrayOf, func, shape, string } from "prop-types";
import { xor } from "lodash";
import Logger from "../../../lib/logger";

// ** Components **********************
import { DefaultIcon } from "views/Components/Icon";

// ** Style ***************************
import {
  Column,
  Description,
  EmptyState,
  FlexColumn,
  Header,
  List,
  ListItem,
  Tag,
  Wrapper,
} from "./style";

const logger = Logger("ResultsColSelection");

const ResultsColSelection = (props) => {
  const [activeColumnData, setActiveColumnData] = useState([]);
  const [availableColumnData, setAvailableColumnData] = useState([]);

  React.useEffect(() => {
    const available = xor(
      props.activeColumns,
      props.selectedColumns.map((col) => col.name)
    );
    setActiveColumnData(
      props.selectedColumns.filter(
        (colObject) => props.activeColumns.indexOf(colObject.name) >= 0
      )
    );
    setAvailableColumnData(
      props.selectedColumns.filter(
        (colObject) => available.indexOf(colObject.name) >= 0
      )
    );
  }, [props.activeColumns, props.selectedColumns]);

  const addOrRemoveColumn = (columnId) => {
    const active = activeColumnData.find((col) => col.id == columnId);
    const inactive = availableColumnData.find((col) => col.id == columnId);
    var newActive = false;

    if (active) {
      // event column is currently active, toggle to inactive
      newActive = activeColumnData.filter(
        (colObject) => colObject.id != active.id
      );
    } else if (inactive) {
      // event column is currently inactive, toggle to active
      if (props.activeColumns.indexOf(inactive.name) == -1) {
        // avoid doubles
        newActive = activeColumnData.concat([inactive]);
      } else {
        logger.warn(
          "Column name already in activeColumns",
          inactive.name,
          props.activeColumns
        );
      }
    } else {
      logger.warn(
        "Invalid column id selected in ResultsColSelection",
        columnId,
        props.selectedColumns
      );
    }

    if (newActive) {
      // avoid potential errors, only triggering logic if change data explicitly set

      // set internal state
      setActiveColumnData(newActive);

      // callback for bound parent component
      props.updateActiveCols(newActive.map((colObject) => colObject.name));
    }
  };

  return (
    <Wrapper>
      <FlexColumn>
        <Header>
          <DefaultIcon height="14px" icon="column" margin="0px 8px 0px 0px" />
          Active
        </Header>
        <List>
          {activeColumnData.length > 0 ? (
            activeColumnData.map((col, i) => (
              <ListItem key={i}>
                <Column added onClick={() => addOrRemoveColumn(col.id)}>
                  {col.display_name || col.name}
                  <Tag added>REMOVE</Tag>
                </Column>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <EmptyState>
                <Description>
                  No columns selected, select a column from below to add it to
                  the table
                </Description>
              </EmptyState>
            </ListItem>
          )}
        </List>
      </FlexColumn>
      <FlexColumn>
        <Header>
          <DefaultIcon height="14px" icon="column" margin="0px 8px 0px 0px" />
          Available
        </Header>
        <List>
          {availableColumnData.length > 0 ? (
            availableColumnData.map((col) => {
              const displayName = col.display_name || col.name;

              return (
                <ListItem key={displayName}>
                  <Column onClick={() => addOrRemoveColumn(col.id)}>
                    {displayName}
                    <Tag>ADD</Tag>
                  </Column>
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <EmptyState>
                <Description>
                  No more columns available for selection
                </Description>
              </EmptyState>
            </ListItem>
          )}
        </List>
      </FlexColumn>
    </Wrapper>
  );
};

//  ** Prop types *********************
ResultsColSelection.propTypes = {
  selectedColumns: arrayOf(shape()),
  activeColumns: arrayOf(string),
  updateActiveCols: func,
};

//  ** Default props ******************
ResultsColSelection.defaultProps = {
  selectedColumns: [],
  activeColumns: [],
  updateActiveCols: Function.prototype(),
};

export default ResultsColSelection;
