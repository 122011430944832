import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";

import SupportEntry from "../../Components/Finding/SupportEntry";

/*
Helpers
*/

// Returns the last 300 characters of health.egod.log message``
const getEgodLog = (health) => {
  const { egod = {} } = health;

  const { log = [] } = egod;

  const logStr = log.join(" ").slice(-300);

  return logStr;
};

/*
Main component
*/

const SensorHealth = ({ columnNumLeft, columnNumRight, health }) => {
  const egodLog = getEgodLog(health);

  const egodLogStr = egodLog ? egodLog : "No egod log present";

  return (
    <div>
      <Container className="list-container light">
        <SupportEntry
          columnNumLeft={columnNumLeft}
          columnNumRight={columnNumRight}
          label="Egod log (last 300 characters)"
          value={egodLogStr}
        />
      </Container>
    </div>
  );
};

SensorHealth.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  health: PropTypes.shape({}),
};

SensorHealth.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  health: {},
};

export default SensorHealth;
