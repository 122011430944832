const locationSchema = {
  $id: "/location",
  title: "Location",
  type: "object",
  properties: {
    id: { type: "string", readOnly: true },
    name: { type: "string", minLength: 2, maxLength: 255 },
    address_one: { type: "string", maxLength: 255 },
    address_two: { type: "string", maxLength: 255 },
    age: { type: "number", readOnly: true },
    city: { type: "string", maxLength: 255 },
    cloud: { type: "boolean" },
    compliances: {
      type: "array",
      readOnly: true,
      items: { type: "object", properties: {} },
    }, // fixme: define deep schema
    config: { type: "object", properties: {}, readOnly: true }, // fixme: define deep schema
    country: { type: "string" },
    created: { type: "string", readOnly: true },
    created_by: { type: "string", format: "uuid", readOnly: true },
    deleted: { type: "string", readOnly: true },
    deleted_by: { type: "string", format: "uuid", readOnly: true },
    fax: { type: "string", maxLength: 20 },
    mobile: { type: "string", maxLength: 20 },
    modified: { type: "string", readOnly: true },
    modified_by: { type: "string", readOnly: true },
    notes: { type: "string" },
    orgId: { type: "string" },
    person_count: { type: "number", readOnly: true },
    persons: {
      type: "array",
      readOnly: true,
      items: {
        type: "object",
        properties: {
          id: { type: "string", format: "uuid", readOnly: true },
          created: { type: "string", readOnly: true },
          email: { type: "string", format: "email" },
          firstName: { type: "string" },
          lastName: { type: "string" },
          lastLoginAt: { type: "string", readOnly: true, nullable: true },
        },
      },
    },
    phone: { type: "string", maxLength: 20 },
    postal: { type: "string", maxLength: 10 },
    state: { type: "string", maxLength: 255, nullable: true },
    timezone: { type: "string" },
  },
  required: ["name", "city", "country", "timezone"],
  additionalProperties: false,
};

export default locationSchema;
