import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { isDevMode } from "../lib/helpers";

const middleware = [thunkMiddleware];

if (isDevMode()) {
  middleware.push(createLogger());
}

export default middleware;
