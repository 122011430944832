import { styled } from "@mui/material/styles";

const PREFIX = "platformLogos";
export const classes = {
  platformIcon: `${PREFIX}-platformIcon`,
};

export const StyledSVG = styled("svg")(({ theme }) => {
  return {
    [`& .${classes.platformIcon}`]: {
      fill: theme.svgs.platform.color,
    },
  };
});
