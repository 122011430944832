const BQSchema = {
  $id: "/bq",
  title: "Big Query",
  type: "object",
  properties: {
    id: { type: "number", readOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
  },
  additionalProperties: true,
};

export default BQSchema;
