import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import FailedLoginsGraph from "./FailedLoginsGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "failedLogins";
export const GRAPH_TITLE = "Top 10 Failed Login Users";

/*
Main component
*/

const FailedLogins = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={FailedLoginsGraph}
    labelField="user"
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

FailedLogins.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

FailedLogins.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default FailedLogins;
