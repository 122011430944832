import compareVersions from "compare-versions";

export const ACTION_UPGRADE = "upgrade";
export const ACTION_REMOVE = "remove";
export const ACTION_UPDATE_META = "update_meta";
export const ACTION_ROTATE_SECRETS = "rotate_secrets";
export const ACTION_MIGRATE = "migrate";

const MODULE_GENERAL_AVAILABILITY = "ga";

export const isEmpty = (value) =>
  value === undefined || value === null || value === "";

// Compares module tags
export const compareSemver = (m1, m2) => {
  try {
    return compareVersions(m1.tag, m2.tag);
  } catch (err) {
    return 0;
  }
};

export const getModuleLabel = (moduleSpec = {}) => {
  const { availabilityLabel = "", name, tag } = moduleSpec;

  let label = `${name || ""} ${tag || ""}`;

  // Module is not generally available
  if (
    availabilityLabel &&
    availabilityLabel.toLowerCase() !== MODULE_GENERAL_AVAILABILITY
  ) {
    label = `${label} [${availabilityLabel.toUpperCase()}]`;
  }

  return label;
};

const getModuleNotes = (moduleSpec = {}) => {
  const { availabilityLabel = "", deleted, notes = "" } = moduleSpec || {};

  let moduleNotes = notes;
  if (deleted || availabilityLabel.toLowerCase() === "deprecated") {
    moduleNotes = "This module has been deprecated";
  }

  return moduleNotes;
};

export const getModuleTexts = (moduleSpec = {}) => {
  const moduleTitle = getModuleLabel(moduleSpec);
  const moduleNotes = getModuleNotes(moduleSpec);
  const moduleUrl = moduleSpec.documentation_url || "";

  return {
    moduleTitle,
    moduleNotes,
    moduleUrl,
  };
};

export const sameTypeModules = ({ module, availableModules }) => {
  let out = [];

  let moduleId;
  let typeId;

  if (module && module.spec && module.spec.type && module.spec.type.id) {
    moduleId = module.spec.id;
    typeId = module.spec.type.id;
  } else {
    return out;
  }

  out = availableModules
    .filter((elt) => elt.type && elt.type.id === typeId && elt.id !== moduleId)
    .sort(compareSemver);

  return out;
};
