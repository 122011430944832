import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import Sensor from "./Sensor/Sensor";
import Loading from "../Components/Loading";
import * as Actions from "../../redux/actions";
import NewSensorModal from "./Sensor/NewSensorModal";
import { sensorConnected } from "./Sensor/common/helpers";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import {
  currentOrgSensorsSelector,
  sensorIdSelector,
} from "../../selectors/sensorsSelectors";

import SimpleTable from "views/Components/SimpleTable";

import "./SensorsPageView.sass";

import { getUrlForNewTab } from "utils";

const SensorsPageView = (props) => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevOrgId = usePrevious(props.orgId);

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    // only fetch new data if user is viewing the
    // sensors page and switches organizations
    if (prevOrgId && prevOrgId !== props.orgId) {
      viewSensors();
      fetch();
    }
  }, [props.orgId]);

  const fetch = () => {
    props.dispatchFetchSensors(props.orgId);
  };

  const viewSensor = (rowDetails) => {
    const { model } = rowDetails;

    const sensorId = model.id;

    const { gotoSensor, payload } = props;

    // Navigate to the Sensor sensorId View
    gotoSensor({ id: sensorId, payload });
  };

  const viewSensors = () => {
    const { gotoSensors, payload } = props;

    // Navigate to the Sensors Page View
    gotoSensors(payload);
  };

  const renderLocationColumn = (column, model) => {
    const fieldToRender = _.get(column, ["field"], "");
    const textToRender = _.get(model, ["location", fieldToRender], "");
    return textToRender;
  };

  const handleOpenInNewTab = (contextMenu) => {
    // get sensor from the context menu
    const { model = {} } = contextMenu;

    const dataForUrl = {
      id1: model.id,
      orgId: props.orgId,
      topLevel: "settings",
      secondLevel: "sensors",
    };

    // build the sensor detail url
    const sensorDetailUrlToOpen = getUrlForNewTab(dataForUrl);

    // open the sensor details in a new tab
    window.open(sensorDetailUrlToOpen);
  };

  const getSensorStatus = (model) => {
    // default to a 'connected' status
    const statusForRendering = _.get(model, ["status"], 70);
    const isConnected = sensorConnected(statusForRendering);

    return isConnected ? "Yes" : "No";
  };

  const getStatusIconColor = (model) => {
    // default to a 'connected' status
    const statusForRendering = _.get(model, ["status"], 70);
    const isConnected = sensorConnected(statusForRendering);

    return isConnected ? "#2c9e85" : "#e34e47";
  };

  const {
    meta = {},
    isFetching,
    sensors = [],
    loading = false,
    detailViewSensorId,
  } = props;

  const sensorsMeta = meta.sensors || {};

  return detailViewSensorId ? (
    <span className="dashboard sensors">
      <Sensor />
    </span>
  ) : (
    <React.Fragment>
      <NewSensorModal />

      <span className="dashboard sensors">
        {loading && _.isEmpty(sensorsMeta) && (
          <div className="dashboard sensors">
            <div className="loading-div">
              <Loading />
            </div>
          </div>
        )}

        <SimpleTable
          isNorthStar
          data={sensors}
          isFetching={isFetching}
          columns={[
            {
              title: "Connected",
              field: "connected",
              searchable: false,
              renderValue: (column, model) => getSensorStatus(model),
              icon: (column, model) => (
                <FiberManualRecordIcon
                  style={{ color: getStatusIconColor(model) }}
                />
              ),
            },
            {
              title: "Core Version",
              field: "coreVersion",
              searchable: true,
            },
            {
              title: "Name",
              field: "name",
              searchable: true,
            },
            {
              title: "Location Name",
              field: "name",
              searchable: true,
              sortable: false,
              renderValue: (column, model) =>
                renderLocationColumn(column, model),
            },
            {
              title: "City",
              field: "city",
              searchable: true,
              renderValue: (column, model) =>
                renderLocationColumn(column, model),
            },
            {
              title: "State",
              field: "state",
              searchable: true,
              renderValue: (column, model) =>
                renderLocationColumn(column, model),
            },
            {
              title: "Country",
              field: "country",
              searchable: true,
              renderValue: (column, model) =>
                renderLocationColumn(column, model),
            },
            {
              title: "Time Zone",
              field: "timezone",
              searchable: true,
              renderValue: (column, model) =>
                renderLocationColumn(column, model),
            },
          ]}
          contextMenuItems={[
            {
              onClick: viewSensor,
              text: "View details",
              datacy: "sensorsDetailsViewMenuItem",
            },
            {
              onClick: handleOpenInNewTab,
              text: "Open details in new tab",
            },
          ]}
          toolbarActions={[
            {
              icon: AddCircleIcon,
              tooltip: "Add new sensor",
              onClick: props.addNewSensorShow,
            },
          ]}
        />
      </span>
    </React.Fragment>
  );
};

SensorsPageView.propTypes = {
  addNewSensorShow: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  meta: PropTypes.shape({}),
  user: PropTypes.shape({}),
  payload: PropTypes.shape({}),
  loading: PropTypes.bool,
  detailViewSensorId: PropTypes.string,
  gotoSensor: PropTypes.func.isRequired,
  gotoSensors: PropTypes.func.isRequired,
  sensors: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchFetchSensors: PropTypes.func.isRequired,
};

SensorsPageView.defaultProps = {
  user: {},
  payload: {},
  data: {},
  meta: {},
  loading: false,
  detailViewSensorId: null,
  sensors: [],
};

const mapStateToProps = (state) => {
  const orgSensors = currentOrgSensorsSelector(state);
  const detailViewSensorId = sensorIdSelector(state);

  // the way this reducer is set up is fun
  const {
    sensors: { sensors },
  } = state;
  const { byOrg } = sensors;

  return {
    detailViewSensorId,
    sensors: orgSensors,
    orgId: state.location.payload.orgId,
    isFetching: byOrg.isFetching,
    payload: state.location.payload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewSensorShow: () => {
    const query = {
      addNewSensor: "true",
    };
    dispatch(Actions.Page.pageWithQuery(query));
  },

  dispatchFetchSensors: (orgId) => {
    dispatch(Actions.Sensors.fetchSensors(orgId));
  },
  gotoSensor: ({ id, payload }) => {
    // Dispatch 'page' action
    dispatch({ type: "PAGE", payload: { ...payload, id1: id } });
  },
  gotoSensors: (payload) => {
    const newPayload = { ...payload };
    delete newPayload.id1;

    dispatch({ type: "PAGE", payload: newPayload });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorsPageView);
