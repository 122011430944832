// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { array, oneOfType, string } from "prop-types";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Styles **************************
import { Line, TypographyStyle } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Typography component
//
//  ** Remarks
//  Simple typography component extended to simplify code across the app
//
//  ** Props
//  @param children {string} - text
//  @param fontSize {string} - font size
//  @param fontWeight {string} - font weight
//  @param margin {string} - custom margin
//  @param type {string} - font type
//
export const Typography = ({
  children,
  color,
  fontSize,
  fontWeight,
  margin,
  type,
}) => (
  <TypographyStyle
    color={color}
    fontSize={fontSize}
    fontWeight={fontWeight}
    margin={margin}
  >
    {children}
    {type === "subheader" && <Line color={color} />}
  </TypographyStyle>
);

// ** Proptypes ***********************
Typography.propTypes = {
  children: oneOfType([array, string]),
  color: string,
  fontSize: string,
  fontWeight: string,
  margin: string,
  type: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Default text ********************
export const DefaultText = withProps((props) => ({
  children: props.children,
  color: props.color || COLOR.gray600,
  fontSize: props.fontSize || "14px",
  fontWeight: props.fontWeight || "400",
  margin: props.margin,
}))(Typography);

// ** Body ****************************
export const Body = withProps((props) => ({
  children: props.children,
  color: props.color || COLOR.gray600,
  fontSize: "14px",
  fontWeight: "400",
  margin: props.margin,
}))(Typography);

// ** Header **************************
export const Header = withProps((props) => ({
  children: props.children,
  color: props.color || COLOR.gray900,
  fontSize: "24px",
  fontWeight: "500",
  margin: props.margin,
}))(Typography);

// ** Subheader ***********************
export const Subheader = withProps((props) => ({
  children: props.children,
  color: props.color || COLOR.gray900,
  fontSize: "14px",
  fontWeight: "500",
  margin: props.margin,
  type: "subheader",
}))(Typography);
