import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { requestActionSelector } from "redux/actions/Request";
import _ from "lodash";

import { TimespanPicker } from "./TimespanPicker";

import { Report } from "lib/models/Report";

import ScheduledReportModelForm from "views/Components/Reporting/ScheduledReportModelForm";

import { Alert, Skeleton, AlertTitle } from "@mui/material";

import {
  Switch,
  Select,
  Button,
  Dialog,
  MenuItem,
  Checkbox,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

const PREFIX = "SaveRequestModal";
const classes = {
  dialogContent: `${PREFIX}-dialogContent`,
  checkBoxContainer: `${PREFIX}-checkBoxContainer`,
  checkBoxText: `${PREFIX}-checkBoxText`,
  dialogTitle: `${PREFIX}-dialogTitle`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogContent}`]: {
    paddingTop: `${theme.shape.padding}px !important`,
  },
  [`& .${classes.checkBoxContainer}`]: {
    marginLeft: -10,
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.checkBoxText}`]: {
    marginBottom: 0,
    fontWeight: "bold",
  },
  [`& .${classes.dialogTitle}`]: {
    paddingBottom: 0,
  },
}));

// import ChipInput from "material-ui-chip-input";

const __map = {
  contains: {
    string: "string", // SQL %Like%
    array: "string", // string literal match in array
  },
  eq: {
    string: "string", // case insensitive match
    number: "number", // literal match
    boolean: "boolean", // literal match
    "date-time": "date-time", // literal match
    uuid: "string", // literal match
  },
  gt: {
    number: "number", // greater than
    array: "number", // match array whos length is greater than input int
    "date-time": "date-time", // date greater than
  },
  lt: {
    number: "number", // less than
    array: "number", // match array whos length is less than input int
    "date-time": "date-time", // date less than
  },
  in: {
    string: "array", // case insensitive match to one of
    number: "array", // literal match to one of
    uuid: "array", // literal match to one of
    "date-time": "date-time", // date less than
  },
  regex: {
    string: "string", // regex
    array: "array", // regex match in array
  },
};

export const decodeKey = (key) => {
  const splitKey = key.split(".");
  if (splitKey.length === 1) {
    splitKey.push("");
  }
  const operator = splitKey.pop();
  const field = splitKey.join(".");
  const retVal = {
    field,
    operator,
    negate: false,
  };

  if (operator[0] === "!") {
    retVal.negate = true;
    retVal.operator = retVal.operator.substring(1);
  }
  return retVal;
};

export const SaveRequestModal = (props) => {
  const [name, setName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduledReport, setScheduledReport] = useState({});
  const requestAction = useSelector((state) =>
    requestActionSelector(state, props.refName)
  );

  const handleSave = () => {
    const queryModel = requestAction.request.toQuery();

    // a little validation since we don't want the query saved
    // if the scheudled report isn't configured properly
    if (isScheduled) {
      if (!scheduledReport.recipients.length)
        return setErrorMessage("Please add at least one recipient.");
      if (!scheduledReport.name)
        return setErrorMessage("Please add name to your scheduled report.");
    }

    queryModel.set({ name });

    queryModel
      .create()
      .then((m) => {
        // if the user requested, use the newly created
        // query to create a scheduled report
        if (isScheduled) {
          scheduledReport.set({
            dataQueryId: m.id,
            dataSource: m.namespace,
          });

          scheduledReport.create();
        }

        props.onClose(m.id);
        setIsScheduled(false);
      })
      .catch((e) => setErrorMessage(e));
  };

  const handleIsScheduledToggle = () => {
    // if we are toggling to creating a scheduled report
    // make a new report model here for the model form
    if (!isScheduled) {
      const newScheduledReport = new Report();
      setScheduledReport(newScheduledReport);
    }
    setIsScheduled(!isScheduled);
  };

  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
      datacy={"reportSaveModal"}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        Save
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {errorMessage && (
          <Alert severity="error" onClose={() => setErrorMessage("")}>
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <TextField
          autoFocus
          size="small"
          id="name"
          label="Name of Query"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          datacy={"reportSaveInput"}
        />
        <div className={classes.checkBoxContainer}>
          <Checkbox
            color={"primary"}
            checked={isScheduled}
            onChange={handleIsScheduledToggle}
          />
          <p className={classes.checkBoxText}>
            Create a scheduled report for saved query
          </p>
        </div>
        {isScheduled && (
          <ScheduledReportModelForm
            model={scheduledReport}
            savedQueries={props.savedQueries}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          datacy={"reportSaveModalBtn"}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

const getDataType = (fieldSchema, operator) => {
  const opMap = __map[operator];
  if (opMap) {
    return opMap[fieldSchema.format || fieldSchema.type];
  } else {
    return false;
  }
};

export const QueryParamControl = (props) => {
  const [queryParam, setQueryParam] = useQueryControl(
    props.refName,
    props.param
  );
  const [dataType, setDataType] = useState(false);
  const requestAction = useSelector((state) =>
    requestActionSelector(state, props.refName)
  );

  useEffect(() => {
    if (requestAction && queryParam) {
      setDataType(
        getDataType(
          requestAction.request.schema.schema.properties[queryParam.field],
          queryParam.operator
        )
      );
    }
  }, [props.refName, props.param, queryParam]);

  // const handleAddChip = (chip) => {
  //   queryParam.value.push(chip);
  //   setQueryParam(queryParam.value);
  // };

  // const handleDeleteChip = (chip, index) => {
  //   queryParam.value.pop(index);
  //   setQueryParam(queryParam.value);
  // };

  if (props.lookup) {
    return (
      <Select
        label={props.label}
        id={`QueryParamControl-${queryParam.uuid}`}
        value={queryParam.value || ""}
        onChange={(e) => setQueryParam(e.target.value)}
        fullWidth
      >
        {Object.keys(props.lookup).map((k) => (
          <MenuItem key={k} value={k}>
            {props.lookup[k]}
          </MenuItem>
        ))}
      </Select>
    );
  }

  switch (dataType) {
    case "string":
      return (
        <TextField
          {...props}
          label={props.label}
          value={queryParam.value || ""}
          id={`QueryParamControl-${queryParam.uuid}`}
          onChange={(e) => setQueryParam(e.target.value)}
        />
      );
    case "number":
      return (
        <TextField
          {...props}
          type="number"
          label={props.label}
          value={queryParam.value || ""}
          InputProps={{ disableUnderline: true }}
          id={`QueryParamControl-${queryParam.uuid}`}
          onChange={(e) => setQueryParam(e.target.value)}
        />
      );
    // Will remove in a separate MR to be 100% sure that this isn't needed before removing
    // case "array":
    //   return (
    //     <ChipInput
    //       onAdd={handleAddChip}
    //       onDelete={handleDeleteChip}
    //       blurBehavior="add"
    //       value={queryParam.value}
    //     />
    //   );
    case "boolean":
      return (
        <Switch
          id={`QueryParamControl-${queryParam.uuid}`}
          checked={queryParam.value || false}
          onChange={(e) => setQueryParam(e.target.checked)}
        />
      );
    case "date-time":
      return (
        <TimespanPicker
          label={props.label}
          starttime={queryParam.value || ""}
          settimes={(startTime, endTime) => setQueryParam(startTime)}
        />
      );
    default:
      return <Skeleton variant="text" />;
  }
};

export const TransformParamControl = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [resultControls, resultControlInterface] = useResultControls(
    props.refName
  );
  const [transformParams, setTransformParams] = useState();
  const requestAction = useSelector((state) =>
    requestActionSelector(state, props.refName)
  );

  useEffect(() => {
    if (requestAction && resultControls && resultControls.transform) {
      setTransformParams(
        resultControls.transform.find((t) => t.uuid === props.param)
      );
    }
  }, [props.refName, props.param, resultControls]);

  if (transformParams) {
    return (
      <p>
        {transformParams.field} {transformParams.operator}{" "}
        {transformParams.value}
      </p>
    );
  } else {
    return <Skeleton variant="text" />;
  }
};

export const useQueryControls = (propName) => {
  const [values, setValues] = useState({});
  const requestAction = useSelector((state) =>
    requestActionSelector(state, propName)
  );

  const handleUpdate = (uuid, attr) => {
    const lookupUUID = requestAction?.request?.refNameMap[uuid] || uuid;
    if (requestAction && requestAction.request.queryParams[lookupUUID]) {
      requestAction.request.updateQueryParam(lookupUUID, attr);
    }
    _updateValues();
  };

  const handleAdd = (params) => {
    if (requestAction) {
      const uuid = requestAction.request.setQueryParam(params);
      _updateValues();
      return uuid;
    }
  };

  const handleRemove = (uuid) => {
    if (requestAction) {
      requestAction.request.deleteQueryParam(uuid);
      _updateValues();
    }
  };

  const handleRestore = () => {
    if (requestAction) {
      requestAction.request.restoreDefaultQueryParams();
      _updateValues(true);
      requestAction.request.dirty = true;
    }
  };

  const _updateValues = (force) => {
    if (requestAction) {
      const newValues = {};
      var requireRender = false;

      Object.keys(requestAction.request.queryParams).forEach((uuid) => {
        // check for inequality or new incoming values
        newValues[uuid] = requestAction.request.queryParams[uuid].toObject();
        newValues[uuid].refName = Object.keys(
          requestAction.request.refNameMap
        ).find((v) => requestAction.request.refNameMap[v] === uuid);
        if (!_.isEqual(values[uuid], newValues[uuid])) {
          requireRender = true;
        }
      });

      if (
        !requireRender &&
        Object.keys(requestAction.request.queryParams).length !==
          Object.keys(values).length
      ) {
        requireRender = true;
      }

      if (requireRender || force) {
        setValues(newValues);
      }
    }
  };

  useEffect(() => {
    _updateValues();
  }, [requestAction]);

  return [
    values,
    {
      update: handleUpdate,
      add: handleAdd,
      remove: handleRemove,
      restore: handleRestore,
    },
  ];
};

export const useQueryControl = (propName, queryRefNameorUUID) => {
  const [UUID, setUUID] = useState(false);
  const [values, setValues] = useState({});
  const requestAction = useSelector((state) =>
    requestActionSelector(state, propName)
  );

  function handleUpdate(attr) {
    if (requestAction.request.queryParams[UUID]) {
      requestAction.request.updateQueryParam(UUID, attr);
      setValues(requestAction.request.queryParams[UUID].toObject());
    }
  }

  useEffect(() => {
    if (requestAction.request) {
      const lookupUUID =
        requestAction.request.refNameMap[queryRefNameorUUID] ||
        queryRefNameorUUID;
      if (requestAction.request.queryParams[lookupUUID] === undefined) {
        throw new Error(
          `Unknown UUID of refName "${lookupUUID}" for queryParams in ${requestAction.request.namespace}`
        );
      }
      const conValues =
        requestAction.request.queryParams[lookupUUID].toObject();
      if (lookupUUID !== UUID) {
        setUUID(lookupUUID);
        setValues(requestAction.request.queryParams[lookupUUID].toObject());
      } else if (!_.isEqual(conValues.value, values.value)) {
        setValues(conValues);
      }
    }
  }, [requestAction]);

  return [values, handleUpdate];
};

export const useResultControls = (propName) => {
  const [values, setValues] = useState({});
  const requestAction = useSelector((state) =>
    requestActionSelector(state, propName)
  );

  const handleUpdate = (key, value) => {
    if (requestAction) {
      requestAction.request.setResultParam(key, value);
      setValues({ ...requestAction.request.resultParams });
    }
  };

  const handleRemove = (key) => {
    if (requestAction) {
      delete requestAction.request.resultParams[key];
      setValues({ ...requestAction.request.resultParams });
    }
  };

  const handleRestore = () => {
    if (requestAction.request) {
      requestAction.request.restoreDefaultResultParams();
      setValues({ ...requestAction.request.resultParams });
      requestAction.request.dirty = true;
    }
  };

  useEffect(() => {
    if (requestAction.request) {
      if (
        Object.keys(requestAction.request.resultParams).length !==
        Object.keys(values).length
      ) {
        setValues(requestAction.request.resultParams);
      }
    }
  }, [requestAction]);

  return [
    values,
    { update: handleUpdate, remove: handleRemove, restore: handleRestore },
  ];
};
