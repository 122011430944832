import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { orgInfoSelector } from "../../../selectors/orgSelectors";
import InfoTable from "./InfoTable";
import { getRows } from "./supportHelpers";

const DATE_KEYS = ["created", "status_changed"];

const OrgInfo = ({ orgData = {} }) => {
  const data = getRows(orgData, DATE_KEYS);

  return (
    <React.Fragment>
      <InfoTable data={data} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const orgData = orgInfoSelector(state);

  return {
    orgData,
  };
};

OrgInfo.propTypes = {
  orgData: PropTypes.shape({}).isRequired,
};

export default connect(mapStateToProps, null)(OrgInfo);
