import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "psaintegration",
};

export class ConnectwiseAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  async getServiceBoards(orgId) {
    const params = ["psa", orgId];
    return super.get({ params });
  }
}
