import { OrgAPI } from "../../lib/api";
import Logger from "../../lib/logger";
import { clearUrlQuery as closeModal } from "./Page";
import { apiErrorMessageForUi } from "../utils/helpers";

const logger = Logger("Pipeline");

export const PIPELINE_RECEIVE_ACTIVE_JOB_METRICS =
  "PIPELINE_RECEIVE_ACTIVE_JOB_METRICS";
export const PIPELINE_RECEIVE_PIPELINE_METRICS =
  "PIPELINE_RECEIVE_PIPELINE_METRICS";
export const PIPELINE_RECEIVE_PRIMARY_PIPELINE =
  "PIPELINE_RECEIVE_PRIMARY_PIPELINE";
export const PIPELINE_RECEIVE_REQUEST_ERROR = "PIPELINE_RECEIVE_REQUEST_ERROR";
export const PIPELINE_RECEIVE_RESTARTING = "PIPELINE_RECEIVE_RESTARTING";
export const PIPELINE_RESET = "PIPELINE_RESET";

const API = {
  OrgAPI: new OrgAPI(),
};

export const receiveActiveJobMetrics = ({ orgId, data }) => ({
  type: PIPELINE_RECEIVE_ACTIVE_JOB_METRICS,
  payload: { insertId: orgId, data },
});

export const receivePipelineMetrics = ({ orgId, data }) => ({
  type: PIPELINE_RECEIVE_PIPELINE_METRICS,
  payload: { insertId: orgId, data },
});

export const receivePrimaryPipeline = ({ orgId, data }) => ({
  type: PIPELINE_RECEIVE_PRIMARY_PIPELINE,
  payload: { insertId: data.id, data, orgId },
});

const receiveRestarting = ({ orgId, restarting }) => ({
  type: PIPELINE_RECEIVE_RESTARTING,
  payload: { insertId: orgId, data: restarting },
});

export const receiveRequestError = ({ orgId, error }) => ({
  type: PIPELINE_RECEIVE_REQUEST_ERROR,
  payload: { insertId: orgId, data: error },
});

// Reset all
export const resetPipeline = {
  type: PIPELINE_RESET,
};

export const drainJob =
  ({ orgId, pipelineId }) =>
  (dispatch) => {
    dispatch(receiveRequestError({ orgId, error: null }));
    dispatch(receiveRestarting({ orgId, restarting: true }));

    API.OrgAPI.drainJob({
      orgId,
      pipelineId,
    })
      .then((resp) => {
        const { data = [] } = resp;
        const { primaryPipeline = {} } = data;

        dispatch(receivePrimaryPipeline({ orgId, data: primaryPipeline }));

        dispatch(receiveRequestError({ orgId, error: null }));
        dispatch(receiveRestarting({ orgId, restarting: false }));

        dispatch(closeModal);
      })
      .catch((err) => {
        const message = "Request to drain the pipeline returned an error.";
        logger.error(message, err);

        const uiErrorMessage = apiErrorMessageForUi({ message, err });
        const error = new Error(uiErrorMessage);
        dispatch(receiveRequestError({ orgId, error }));

        dispatch(receiveRestarting({ orgId, restarting: false }));
      });
  };

export const startOrRestartPipeline =
  ({
    apiMethod = "restartPrimaryPipeline",
    errorMsg = "Unable to restart the pipeline",
  }) =>
  ({ orgId, workerCount, workerMax, workerType }) =>
  (dispatch) => {
    dispatch(receiveRequestError({ orgId, error: null }));
    dispatch(receiveRestarting({ orgId, restarting: true }));

    API.OrgAPI[apiMethod]({
      orgId,
      workerCount,
      workerMax,
      workerType,
    })
      .then((resp) => {
        const { data = [] } = resp;
        const { primaryPipeline = {} } = data;

        dispatch(
          receiveActiveJobMetrics({
            orgId,
            data: {
              errorMsg: "New job started; please check back later.",
            },
          })
        );

        dispatch(receivePrimaryPipeline({ orgId, data: primaryPipeline }));

        dispatch(receiveRequestError({ orgId, error: null }));
        dispatch(receiveRestarting({ orgId, restarting: false }));

        dispatch(closeModal);
      })
      .catch((err) => {
        logger.error(errorMsg, err);

        const uiErrorMessage = apiErrorMessageForUi({ message: errorMsg, err });
        const error = new Error(uiErrorMessage);
        dispatch(receiveRequestError({ orgId, error }));

        dispatch(receiveRestarting({ orgId, restarting: false }));
      });
  };

export const restartPrimaryPipeline = startOrRestartPipeline({});
export const startPrimaryPipeline = startOrRestartPipeline({
  apiMethod: "startPrimaryPipeline",
  errorMsg: "Unable to start the pipeline",
});
