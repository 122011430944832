// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Child container *****************
export const ChildContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: ${(p) => (p.open ? 1 : 0)};
  overflow-y: scroll;
  transition: opacity 500ms cubic-bezier(0.68, -0.55, 0.265, 1);
  transition-delay: ${(p) => (p.open ? "750ms" : "100ms")};
  width: 100%;
`;

// ** Close button container **********
export const CloseContainer = styled.div`
  fill: ${COLOR.gray500};
  opacity: ${(p) => (p.open ? 1 : 0)};
  position: absolute;
  right: 40px;
  top: 80px;
  transition: opacity 500ms cubic-bezier(0.68, -0.55, 0.265, 1), fill 200ms;
  transition-delay: ${(p) => (p.open ? "750ms" : "100ms")};

  &:hover {
    fill: ${COLOR.gray900};
  }
`;

// ** Outer container *****************
export const Container = styled.div`
  background-color: ${(p) => (p.open ? "white" : "transparent")};
  height: ${(p) => (p.open ? "100%" : "0px")};
  left: 0px;
  min-height: ${(p) => (p.open ? "100vh" : "0px")};
  overflow: hidden;
  position: fixed;
  top: 0px;
  transition: background-color 1000ms cubic-bezier(0.68, -0.55, 0.265, 1);
  transition-delay: ${(p) => (p.open ? "0ms" : "1000ms")};
  width: 100%;
  z-index: 1000;
`;
