import React from "react";
import PropTypes from "prop-types";

import { sensorConnected } from "./common/helpers";

import "./SensorStatus.scss";

const SensorStatus = ({ status }) => {
  const connected = sensorConnected(status);
  const text = connected ? "online" : "offline";

  return (
    <div className="sensor-status" title={text}>
      <div className={`fas fa-circle fa-lg status-${text}`} />
    </div>
  );
};

SensorStatus.propTypes = {
  status: PropTypes.number,
};

SensorStatus.defaultProps = {
  status: -1,
};

export default SensorStatus;
