import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { ResponsiveLine } from "@nivo/line";
import moment from "moment-timezone";

import CustomTooltip from "./CustomTooltip";

import "./LogdevGraph.scss";

/*
Constants and helpers
*/

// Bottom axis legend containing the user's timezone abbreviation -
// on hold
// const BOTTOM_AXIS_LEGEND =
//   `Time, ${moment.tz(moment.tz.guess(true)).format('zz')}`
//   + ` (${moment.tz.guess()})`;

const DATE_FORMAT_STRINGS = {
  DATE_HOURS_AND_MINUTES: "MM/DD,HH:MM",
  DATE_ONLY: "MM/DD",
  HOURS_AND_MINUTES_ONLY: "LT",
};

// 'histogram_bucket' values and 'tickValues' for (time interval)<='max'
const BUCKET_AND_TICK_SIZES = [
  {
    histogramBucket: "3min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 1800000,
    tickValues: "every 5 minutes",
  }, // 30 min or less
  {
    histogramBucket: "3min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 3600000,
    tickValues: "every 10 minutes",
  }, // 30 - 60 min
  {
    histogramBucket: "5min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 10800000,
    tickValues: "every 30 minutes",
  }, // 60 - 180 min
  {
    histogramBucket: "10min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 14400000,
    tickValues: "every 1 hours",
  }, // 3 - 4 hr
  {
    histogramBucket: "15min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 21600000,
    tickValues: "every 1 hours",
  }, // 4 - 6 hr
  {
    histogramBucket: "15min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 28800000,
    tickValues: "every 2 hours",
  }, // 6 - 8 hr
  {
    histogramBucket: "30min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 43200000,
    tickValues: "every 2 hours",
  }, // 8 - 12 hr
  {
    histogramBucket: "30min",
    dateFormatStr: DATE_FORMAT_STRINGS.HOURS_AND_MINUTES_ONLY,
    max: 57600000,
    tickValues: "every 4 hours",
  }, // 12 - 16 hr
  {
    histogramBucket: "1hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_HOURS_AND_MINUTES,
    max: 86400000,
    tickValues: "every 6 hours",
  }, // 16 - 24 hr
  {
    histogramBucket: "1hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_HOURS_AND_MINUTES,
    max: 115200000,
    tickValues: "every 8 hours",
  }, // 24 - 32 hr
  {
    histogramBucket: "2hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_HOURS_AND_MINUTES,
    max: 230400000,
    tickValues: "every 1 days",
  }, // 32 - 64 hr
  {
    histogramBucket: "4hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_HOURS_AND_MINUTES,
    max: 460800000,
    tickValues: "every 1 days",
  }, // 64 - 128 hr
  {
    histogramBucket: "8hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 604800000,
    tickValues: "every 1 days",
  }, // 5 - 7 days
  {
    histogramBucket: "8hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 864000000,
    tickValues: "every 2 days",
  }, // 7 - 10 days
  {
    histogramBucket: "12hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 1728000000,
    tickValues: "every 3 days",
  }, // 10 - 20 days
  {
    histogramBucket: "24hr",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 3456000000,
    tickValues: "every 1 weeks",
  }, // 20 - 40 days
  {
    histogramBucket: "1d",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 5184000000,
    tickValues: "every 2 weeks",
  }, // under 2 months
  {
    histogramBucket: "2d",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 10368000000,
    tickValues: "every 1 months",
  }, // 2 - 4 months
  {
    histogramBucket: "8d",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 20736000000,
    tickValues: "every 1 months",
  }, // 4 - 8 months
  {
    histogramBucket: "16d",
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_ONLY,
    max: 41472000000,
    tickValues: "every 3 months",
  }, // 8 - ? months
];

// Standard Nivo colors in a custom order
const GRAPH_COLORS = [
  "hsl(27, 61%, 77%)",
  "hsl(170, 52%, 59%)",
  "hsl(38, 79%, 56%)",
  "hsl(9, 87%, 67%)",
  "hsl(54, 84%, 65%)",
];

const getColors =
  ({ hoverId, selectedId }) =>
  (elt) => {
    if (selectedId && !hoverId) {
      if (elt.id === selectedId) {
        return "MediumBlue";
      }
      return elt.color;
    }

    if (selectedId && hoverId) {
      if (elt.id === selectedId) {
        return "MediumBlue";
      }
      if (elt.id === hoverId) {
        return "MediumBlue";
      }
      return elt.color;
    }

    if (hoverId) {
      if (elt.id === hoverId) {
        return "MediumBlue";
      }
      return elt.color;
    }

    return elt.color;
  };

// Graph's 'tooltip' property.
// Returns CustomTooltip component populated with the point's properties.
const getCustomTooltip = (tooltipProps) => {
  const { point } = tooltipProps;

  const { color, data: pointData = {}, serieId: graphId } = point;

  const { xFormatted, yFormatted } = pointData;

  return (
    <CustomTooltip
      color={color}
      graphId={graphId}
      xFormatted={xFormatted}
      yFormatted={yFormatted}
    />
  );
};

// Given time `delta` in milliseconds, returns the bucket
// with the smallest 'max' >= 'delta'
export const graphParamsFromDelta = (delta) => {
  const first = BUCKET_AND_TICK_SIZES.find((elt) => delta <= elt.max);

  return first;
};

// Returns bottom axis tick label
const getXTickLabel = (dateFormatStr) => (val) =>
  // User's timezone
  // moment.utc(val).tz(moment.tz.guess(true)).format(dateFormatStr);
  moment.utc(val).format(dateFormatStr); // UTC

const handleClick =
  (idName = "id") =>
  ({ selectedId, setHoverId, setSelectedId }) =>
  (props) => {
    const { [idName]: graphId } = props;

    if (graphId === selectedId) {
      setHoverId("");
      setSelectedId("");
      return;
    }

    setSelectedId(graphId);
  };

const onClickGraph = handleClick("serieId");

const onClickLegend = handleClick();

const onMouseEnterLegend = (setHoverId) => (props) => {
  const { id: graphId } = props;

  setHoverId(graphId);
};

const onMouseLeaveLegend = (setHoverId) => () => {
  setHoverId("");
};

// Calculates the maximum y-value for the set of graphs in 'data'
// and returns 'maxY' graph parameter
// ('auto' if the maximum y-value is at least 10, and 10 otherwise)
const getMaxY = (data) => {
  const maxY = data.reduce((accum, current) => {
    const { data: oneGraph } = current;

    const high = oneGraph.reduce((accum2, current2) => {
      const { y = 0 } = current2;
      return y > accum2 ? y : accum2;
    }, 0);

    return accum > high ? accum : high;
  }, 0);

  return maxY < 10 ? 10 : "auto";
};

/*
Component
*/

const LogdevGraph = (props) => {
  const [graphData, setGraphData] = useState([]);
  const [hoverId, setHoverId] = useState("");
  const [maxY, setMaxY] = useState("auto");
  const [selectedId, setSelectedId] = useState("");

  const { data, graphParams = {} } = props;

  // Set custom colors
  useEffect(() => {
    const len = data.length;

    setGraphData(
      data.map((elt, index) => ({
        ...elt,
        color: GRAPH_COLORS[(len - index - 1) % GRAPH_COLORS.length],
      }))
    );
  }, [data]);

  // Set vertical axis max
  useEffect(() => {
    setMaxY(getMaxY(data));
  }, [data]);

  const { dateFormatStr, tickValues } = graphParams;

  return (
    <div className="resources-logdev-graph">
      <div className="resources-logdev-graph-container">
        <ResponsiveLine
          data={graphData}
          // Graph appearance ---------

          colors={getColors({ hoverId, selectedId })}
          margin={{
            top: 50,
            right: 240,
            bottom: 50,
            left: 75,
          }}
          enableArea
          pointSize={0}
          // Axis and scales --------

          xScale={{
            type: "time",
          }}
          xFormat={getXTickLabel(dateFormatStr)}
          yScale={{
            max: maxY,
            min: 0,

            stacked: false,

            type: "linear",
          }}
          axisBottom={{
            format: getXTickLabel(dateFormatStr),

            legend: "Time, UTC", // BOTTOM_AXIS_LEGEND,
            legendOffset: 45,
            legendPosition: "middle",

            orient: "bottom",

            tickPadding: 5,
            tickSize: 7,
            tickValues,
          }}
          axisLeft={{
            format: (v) => v.toLocaleString(),

            legend: "Log events",
            legendOffset: -70,
            legendPosition: "middle",

            orient: "left",

            tickSize: 5,
            tickPadding: 5,
          }}
          // Interactivity -------

          animate
          motionStiffness={90}
          motionDamping={15}
          onClick={onClickGraph({
            selectedId,
            setHoverId,
            setSelectedId,
          })}
          tooltip={getCustomTooltip}
          useMesh // must be 'true' to enable the tolltip
          // Legends  -------

          legends={[
            {
              // general
              anchor: "top-right",
              direction: "column",
              justify: false,

              translateX: 210,
              translateY: 0,
              itemsSpacing: 5,
              itemDirection: "left-to-right",
              itemWidth: 200,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",

              // interactivity
              onMouseEnter: onMouseEnterLegend(setHoverId),
              onMouseLeave: onMouseLeaveLegend(setHoverId),
              onClick: onClickLegend({
                selectedId,
                setHoverId,
                setSelectedId,
              }),
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

LogdevGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  graphParams: PropTypes.shape({}),
};
LogdevGraph.defaultProps = {
  data: [],
  graphParams: {
    dateFormatStr: DATE_FORMAT_STRINGS.DATE_HOURS_AND_MINUTES,
    tickValues: "every 6 hours",
  },
};

export default LogdevGraph;
