import { styled } from "@mui/material/styles";

const PREFIX = "AccountMappingAccordion";
export const classes = {
  catchallInput: `${PREFIX}-catchallInput`,
  catchallLabel: `${PREFIX}-catchallLabel`,
  chip: `${PREFIX}-chip`,
  mappedChip: `${PREFIX}-mappedChip`,
  unMappedChip: `${PREFIX}-unMappedChip`,
};

export const StyledAccountMappingView = styled("div")(({ theme }) => ({
  [`& .${classes.catchallInput}`]: {
    alignItems: "center",
    display: "flex",
    marginBottom: 20,
  },
  [`& .${classes.catchallLabel}`]: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginBottom: 0,
    marginRight: 10,
  },
  [`& .${classes.chip}`]: {
    alignItems: "center",
    borderRadius: 20,
    display: "inline-flex",
    fontSize: 14,
    fontWeight: "normal",
    padding: "2px 12px 2px 8px",
    "& svg": {
      fontSize: 14,
      marginRight: 5,
    },
  },
  [`& .${classes.mappedChip}`]: {
    backgroundColor: "#C6E9D6",
    color: "#213C2E",
  },
  [`& .${classes.unMappedChip}`]: {
    backgroundColor: "#FBDBB4",
    color: "#A84700",
  },
}));
