import React from "react";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import { formatDate } from "../../utils/index";

/*
Constants
*/

const DATE_FORMAT_STR = "MM/DD/YYYY HH:mm";

const DEFAULT_PREFIX = "Showing data for";

/*
Helpers
*/

const formatDateUTC = (date) =>
  formatDate({
    date,
    formatStr: DATE_FORMAT_STR,
    guessTz: false,
  });

/*
Main component

The properties dateStart and dateEnd are strings.
*/

const TimeRange = ({ dateStart, dateEnd, label, prefix, showTz }) => {
  const start = formatDateUTC(dateStart);
  const end = formatDateUTC(dateEnd);

  if (!start) {
    return null;
  }

  const timeZone = moment.utc().format("z");

  return (
    <div className="security-time-period">
      {(prefix || label) && (
        <div>
          <span className="time-period-prefix">{prefix}</span>

          <span className="time-period-dates">
            {label ? ` the ${label.toLowerCase()}:` : ""}
          </span>
        </div>
      )}

      <div>
        <strong>{` ${start}`}</strong>

        <strong>{end && end !== start ? ` - ${end}` : ""}</strong>

        {showTz && <span>{` (${timeZone})`}</span>}
      </div>
    </div>
  );
};

TimeRange.propTypes = {
  dateStart: PropTypes.string,
  dateEnd: PropTypes.string,
  label: PropTypes.string,
  prefix: PropTypes.string,
  showTz: PropTypes.bool,
};

TimeRange.defaultProps = {
  dateStart: "",
  dateEnd: "",
  label: "",
  prefix: DEFAULT_PREFIX,
  showTz: true,
};

export default TimeRange;
