import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { Tooltip } from "reactstrap";

import Confirm from "../Confirm";
import Submitted from "../Submitted";
import { DemoAPI } from "../../../lib/api";
import Logger from "../../../lib/logger";

import "./ResetDemo.scss";

const logger = Logger("ResetDemo");

const SUBMITTED_ALERT_DURATION = 2500;

const API = {
  demo: new DemoAPI(),
};

const ResetDemo = (props) => {
  const { orgId, orgName, orgs } = props;

  const submittedMessage = `Demo for ${orgName} has been reset`;

  const [errorMessage, setErrorMessage] = useState(null);
  const [isResetting, setIsResetting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isDemoOrg = () => {
    if (!orgs) {
      return false;
    }

    const org = orgs.find((elt) => elt.id === orgId) || {};
    const { config = {} } = org;

    if (!config) {
      return false;
    }

    const { demo = false } = config;

    return demo;
  };

  const resetDemo = () => {
    setErrorMessage(null);
    setIsResetting(true);
    setShowSubmitted(false);

    const demo = isDemoOrg();

    if (!orgId || !demo) {
      const msg = `Cannot reset demo - ${orgName || ""} is not a demo org.`;
      setErrorMessage(msg);
      setIsResetting(false);
      logger.error(new Error("msg"));

      return;
    }

    const data = {
      org_id: orgId,
      launch: true,
      reset: true,
    };

    API.demo
      .resetDemo({ orgId, data })
      .then(() => {
        setIsResetting(false);
        setShowSubmitted(true);

        setTimeout(() => setShowSubmitted(false), SUBMITTED_ALERT_DURATION);
      })
      .catch((err) => {
        const message = `Failed to reset demo for ${orgName || ""}`;
        logger.error(message, err);

        setErrorMessage(message);
        setIsResetting(false);
      });
  };

  const onBtnClick = () => {
    setShowConfirm(true);
  };

  const onCancelReset = () => {
    setShowConfirm(false);
  };

  const onConfirmReset = () => {
    setShowConfirm(false);
    resetDemo();
  };

  const onDismissError = () => {
    setErrorMessage(null);
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const demo = isDemoOrg();

  return !demo ? null : (
    <div className="reset-demo-container">
      {showConfirm && (
        <Confirm
          title={`Reset demo for ${orgName}`}
          body={`Are you sure you want to reset the demo for ${orgName}?`}
          confirmBtnTxt="Confirm"
          onCancel={onCancelReset}
          onConfirm={onConfirmReset}
        />
      )}

      {isResetting && (
        <div className="resetting-demo-div">{`Resetting demo for ${orgName}...`}</div>
      )}

      {errorMessage && (
        <div className="alert alert-danger reset-demo-error">
          {errorMessage}
          <div>
            <Button className="btn btn-danger" onClick={onDismissError}>
              Dismiss
            </Button>
          </div>
        </div>
      )}

      <Button
        className="reset-demo-btn"
        bsStyle="primary"
        disabled={false}
        id="reset-demo-btn-id"
        onClick={onBtnClick}
      >
        Reset demo
      </Button>

      <Tooltip
        className="reset-demo-tooltip"
        placement="top-end"
        isOpen={tooltipOpen}
        target="reset-demo-btn-id"
        toggle={toggleTooltip}
      >
        {`Reset demo for ${orgName}`}
      </Tooltip>

      {!errorMessage && showSubmitted && (
        <Submitted isOpen={showSubmitted} body={submittedMessage} size="lg" />
      )}
    </div>
  );
};

ResetDemo.propTypes = {
  orgId: PropTypes.string,
  orgName: PropTypes.string,
  orgs: PropTypes.arrayOf(PropTypes.shape({})),
};

ResetDemo.defaultProps = {
  orgId: "",
  orgName: "",
  orgs: [],
};

export default connect(null, null)(ResetDemo);
