import { UsersAPI } from "../../lib/api/UsersAPI";
import { actionStart, actionSuccess, actionFailure } from "./Page";
import {
  handleApiError,
  rethrowApiError,
  GENERAL_ERROR_MESSAGE,
} from "../utils/helpers";

import { get } from "lodash";

export const REQUEST_USERS = "USERS.REQUEST_USERS";
export const RECEIVE_USER = "USERS.RECEIVE_USER";
export const RECEIVE_ORG_USERS = "USERS.RECEIVE_ORG_USERS";
export const RESET_USERS = "USERS.RESET";

export const RECEIVE_SESSION_USER = "SESSION.RECEIVE_USER";

const API = new UsersAPI();

/*
Actions
*/

export const resetUsers = {
  type: RESET_USERS,
};

export const requestUsers = () => ({
  type: REQUEST_USERS,
});

export const receiveUser = (user) => ({
  type: RECEIVE_USER,
  payload: { user },
});

export const receiveSessionUser = (user) => ({
  type: RECEIVE_SESSION_USER,
  user: user,
});

// given an err returns appropriate
// message to display in the UI
export const getErrorMessage = (err) => {
  const errorMessageToReturn = get(err, "message", GENERAL_ERROR_MESSAGE);
  return errorMessageToReturn;
};

const fetchUsersForOrg = (orgId) => (dispatch) => {
  dispatch(requestUsers());
  return API.list(orgId).then(({ data: users }) =>
    dispatch({
      type: RECEIVE_ORG_USERS,
      payload: { orgId, users },
    })
  );
};

/*
A wrapper for `fetchUsersForOrg` that dispatches 'page' actions.
*/
export const fetchUsersForOrgWithPageActions = (orgId) => (dispatch) => {
  dispatch(actionStart);

  return dispatch(fetchUsersForOrg(orgId))
    .then(() => {
      dispatch(actionSuccess);
    })
    .catch((err) => {
      // Dispatching error to the 'page' store for use by PageView
      const message = "Unable to fetch users.";

      dispatch(handleApiError({ message, err, onError: actionFailure }));
    });
};

// Creates a user from the `data`, then fetches the users for `orgId`
export const createUser =
  ({ orgId, data }) =>
  (dispatch) =>
    API.create(orgId, data)
      .then(() => dispatch(fetchUsersForOrg(orgId)))
      .catch((err) => {
        const message = getErrorMessage(err);
        dispatch(rethrowApiError({ message, err }));
      });

// Adds user to org `orgId`, then fetches the users for `orgId`
export const addUserToOrg =
  ({ orgId, email, roles }) =>
  (dispatch) =>
    API.addUserToOrg(orgId, email, roles)
      .then(() => dispatch(fetchUsersForOrg(orgId)))
      .catch((err) => {
        const message = "Unable to add user to a new org.";
        dispatch(rethrowApiError({ message, err }));
      });

// Deletes user `personId`, then fetches the users for `orgId`
const deleteUser =
  ({ orgId, personId }) =>
  (dispatch) =>
    API.delete(orgId, personId).then(() => dispatch(fetchUsersForOrg(orgId)));

/*
Same as `deleteUser`, plus dispatches 'page' actions
*/
export const deleteUserWithPageActions =
  ({ orgId, personId }) =>
  (dispatch) => {
    dispatch(actionStart);

    return dispatch(deleteUser({ orgId, personId }))
      .then(() => {
        dispatch(actionSuccess);
      })
      .catch((err) => {
        const message = "Unable to delete user.";

        dispatch(handleApiError({ message, err, onError: actionFailure }));
      });
  };

// Resets MFA for user `personId`
export const resetMFA =
  ({ personId }) =>
  (dispatch) =>
    API.resetMFA(personId).then(() => dispatch(actionSuccess));

// Updates user `personId` with `data`, then fetches the users for `orgId`
export const updateUser =
  ({ orgId, personId, data, editingSelf }) =>
  (dispatch) =>
    API.update(orgId, personId, data)
      .then(({ data: respData }) => {
        // editing self - update session settings
        if (editingSelf) dispatch(receiveSessionUser(respData));
        else dispatch(receiveUser(respData));
      })
      .catch((err) => {
        const message = "Unable to update user information.";
        dispatch(rethrowApiError({ message, err }));
      });

/*
Same as `updateUser`, plus dispatches 'page' actions.
*/
export const updateUserWithPageActions =
  ({ orgId, personId, data }) =>
  (dispatch) => {
    dispatch(actionStart);

    return dispatch(updateUser({ orgId, personId, data }))
      .then(() => {
        dispatch(actionSuccess);
      })
      .catch((err) => {
        const message = "Unable to update user.";

        dispatch(handleApiError({ message, err, onError: actionFailure }));
      });
  };
