import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import OverviewTable from "./OverviewTable";
import {
  ItemName,
  SensorGeneral,
  SensorHost,
  SensorCpu,
  SensorMemory,
  SensorDisk,
} from "./OverviewFields";
import { currentSensorSelector } from "../../../selectors/sensorsSelectors";

import "./Overview.scss";

// The message to display when no information is available
const NO_INFO_STRING = "No information";

// Row names
const GENERAL = "General";
const HOST_DETAILS = "Host Details";
const CPU = "CPU";
const MEMORY = "Memory";
const DISK = "Disk";

// Takes as argument the 'health.system', location, created, and
// createdByUser from the Sensor Detail. Returns the Overview Table rows.
const getOverview = ({
  system,
  location,
  created,
  createdByUser,
  listeningIps,
}) => {
  const {
    uname,
    uptime,
    time,
    cpu_percent: cpuPercent,
    memory,
    disk_usage: diskUsage,
  } = system;

  const overview = [
    {
      name: <ItemName text={GENERAL} />,
      nameAccessor: GENERAL,
      content: (
        <SensorGeneral
          location={location}
          created={created}
          createdByUser={createdByUser}
          noInfo={NO_INFO_STRING}
        />
      ),
    },
    {
      name: <ItemName text={HOST_DETAILS} />,
      nameAccessor: HOST_DETAILS,
      content: (
        <SensorHost
          uname={uname}
          uptime={uptime}
          time={time}
          noInfo={NO_INFO_STRING}
          listeningIps={listeningIps}
        />
      ),
    },
    {
      name: <ItemName text={CPU} />,
      nameAccessor: CPU,
      content: <SensorCpu cpuPercent={cpuPercent} noInfo={NO_INFO_STRING} />,
    },
    {
      name: <ItemName text={MEMORY} />,
      nameAccessor: MEMORY,
      content: <SensorMemory memory={memory} noInfo={NO_INFO_STRING} />,
    },
    {
      name: <ItemName text={DISK} />,
      nameAccessor: DISK,
      content: <SensorDisk diskUsage={diskUsage} noInfo={NO_INFO_STRING} />,
    },
  ];

  return overview;
};

/*
Component
*/

const Overview = (props) => {
  const { system, location, created, createdByUser, listeningIps } = props;

  const overview = getOverview({
    system,
    location,
    created,
    createdByUser,
    listeningIps,
  });

  return (
    <div className="sensor-overview-container">
      <OverviewTable data={overview} />
    </div>
  );
};

Overview.propTypes = {
  system: PropTypes.shape({}),
  location: PropTypes.shape({}),
  created: PropTypes.string,
  listeningIps: PropTypes.array,
  createdByUser: PropTypes.shape({}),
};

Overview.defaultProps = {
  system: {},
  location: {},
  created: "",
  createdByUser: {},
  listeningIps: [],
};

const mapStateToProps = (state) => {
  const sensor = currentSensorSelector(state);

  const health = sensor.health || {};
  const system = health.system || {};

  const created = sensor.created || "";
  const sensorLocation = sensor.location || {};
  const listeningIps = sensor.listeningIps || [];
  const createdByUser = sensor.createdByUser || {};

  return {
    system,
    location: sensorLocation,
    created,
    listeningIps,
    createdByUser,
  };
};

export default connect(mapStateToProps, null)(Overview);
