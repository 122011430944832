import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { connect } from "react-redux";

import { Location as LocationModel } from "lib/models/Location";

import ActionDialog from "views/Components/ActionDialog";
import SimpleTable from "views/Components/SimpleTable";
import SimpleModelForm from "views/Components/SimpleModelForm";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { CircularProgress, Dialog, DialogTitle } from "@mui/material";

import countries from "i18n-iso-countries";

import { updateUser } from "redux/actions/Users";
import { loadPageData } from "redux/actions/Request";

import Request from "lib/api/Request";

const PREFIX = "LocationsPageView";

const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
};

const Root = styled("span")(({ theme }) => ({
  [`& .${classes.loadingContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    "& p": {
      overflowWrap: "anywhere",
      marginTop: theme.shape.padding,
    },
  },
}));

// https://stackoverflow.com/questions/58136474/how-to-use-i18n-iso-countries-in-react
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const deleteButtonColor = "#E76F51";

export const getPersonEmailsToDisplayFromLocation = (locationModel = {}) => {
  const personsToMap = locationModel.persons ? locationModel.persons : [];
  return personsToMap.map(({ email }) => email);
};

// we do not currently have an explicit 'default' location so for
// now let's send back the location that was created first
export const getDefaultLocation = (locations = []) => {
  let defaultLocationToReturn = {};

  const firstCreatedLocation = locations?.sort(function (a, b) {
    return Date.parse(a.created) - Date.parse(b.created);
  })[0];

  if (firstCreatedLocation) defaultLocationToReturn = firstCreatedLocation;

  return defaultLocationToReturn;
};

// get current locations for this user then filter out the deleted location
// and add the default location if it does not already exist on the user
export const getNewUserLocations = async ({
  user,
  orgId,
  deletedLocationId,
  defaultLocationId,
}) => {
  const existingUserLocations = user.locations ? user.locations : [];

  // filter out the selected, deleted location
  // to update locations on the user model
  // also filter out the default location to avoid dupes
  const filteredUserLocations = existingUserLocations
    .filter(({ id }) => id !== deletedLocationId && id !== defaultLocationId)
    .map(({ id, orgId }) => ({ id: id, org_id: orgId }));

  if (defaultLocationId) {
    // create new location object for default location
    const newLocationData = { org_id: orgId, id: defaultLocationId };
    // return existing, formatted locations and new default
    return [...filteredUserLocations, newLocationData];
  } else {
    return [...filteredUserLocations];
  }
};

const LocationsPageView = (props) => {
  const { ready, locations = [], timezones = [], reload, orgId } = props;

  const [openForm, setOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState({});
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [emailsAssociatedWithLocation, setEmailsAssociatedWithLocation] =
    useState([]);

  useEffect(() => {
    const newDefaultLocation = getDefaultLocation(locations);
    setDefaultLocation(newDefaultLocation);
  }, [locations]);

  const handleClose = (action) => {
    // clear model
    selectedModel.reset();

    // reset any values set for confirmation dialog
    setEmailsAssociatedWithLocation([]);

    if (action === "create") {
      reload(true);
    }

    setOpenForm(false);
  };

  const handleIssueWithDelete = () => {
    setIsLoading(false);
    setLoadingMessage(`We had an issue with your request. Try again?`);
  };

  const handleDeleteLocation = async () => {
    // set loading state and message to display in dialog
    setIsLoading(true);
    setLoadingMessage(
      <>
        <p>
          Deleting{" "}
          <strong>
            {selectedModel.name} ({selectedModel.city})
          </strong>{" "}
        </p>
        {!!emailsAssociatedWithLocation.length && (
          <p>
            Moving users from{" "}
            <strong>
              {selectedModel.name} ({selectedModel.city})
            </strong>{" "}
            to{" "}
            <strong>
              {defaultLocation.name} ({defaultLocation.city})
            </strong>
          </p>
        )}
      </>
    );

    selectedModel
      .delete()
      .then(async ({ statusCode = 500 }) => {
        // if the location model was successfully deleted, move users
        if (statusCode === 200) {
          const promises = selectedModel.persons.map(async (person) => {
            const editingSelf = props.currentUser.id === person.id;

            // get full user data since 'locations' prop is not present
            // on a person in the location model 'persons' array
            const fullUserDataArr = await new Request("/user", [
              { field: "id", value: person.id },
            ]).get();

            // get updated list of user locations
            // including new default location
            const newUserLocations = await getNewUserLocations({
              orgId: props.orgId,
              user: fullUserDataArr[0],
              deletedLocationId: selectedModel.id,
              defaultLocationId: defaultLocation.id,
            });

            // save the new locations on the user
            const userDataToUpdate = { locations: newUserLocations };
            await props.editUser(
              props.orgId,
              person.id,
              userDataToUpdate,
              editingSelf
            );
          });
          try {
            await Promise.all(promises);
            setIsLoading(false);
            handleClose();
            toggleDeleteConfirmationDialog();
            reload(true);
          } catch (e) {
            handleIssueWithDelete();
          }
        } else {
          handleIssueWithDelete();
        }
      })
      .catch((e) => {
        handleIssueWithDelete();
      });
  };

  const toggleDeleteConfirmationDialog = () => {
    setIsLoading(false);
    setLoadingMessage(false);
    setIsConfirmationDialogOpen(!isConfirmationDialogOpen);
  };

  const getUserTimezone = () => {
    let userTz = null;

    try {
      const payload = JSON.parse(localStorage.getItem("idTokenPayload"));
      userTz = payload["https://b5a.io/geoip"].time_zone;
    } catch (err) {
      userTz = moment.tz.guess();
    }

    return userTz;
  };

  const timezoneOptions = timezones
    .map((tz) => ({ value: tz.name, label: tz.name }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
    },
    {
      title: "City",
      field: "city",
      searchable: true,
    },
    {
      title: "State",
      field: "state",
      searchable: true,
    },
    {
      title: "Country",
      field: "country",
      searchable: true,
    },
    {
      title: "Timezone",
      field: "timezone",
      searchable: true,
    },
    {
      title: "# Persons",
      field: "person_count",
      searchable: true,
    },
  ];

  const fields = {
    name: {
      type: "text",
      default: "",
      label: "Name",
    },
    address_one: {
      xs: 6,
      type: "text",
      default: "",
      label: "Address 1",
    },
    address_two: {
      xs: 6,
      type: "text",
      default: "",
      label: "Address 2",
    },
    city: {
      xs: 6,
      type: "text",
      default: "",
      label: "City",
    },
    state: {
      xs: 6,
      type: "text",
      default: "",
      label: "State/Province",
    },
    postal: {
      type: "text",
      default: "",
      label: "Postal Code",
    },
    phone: {
      xs: 6,
      type: "text",
      default: "",
      label: "Phone Number",
    },
    mobile: {
      xs: 6,
      type: "text",
      default: "",
      label: "Mobile/Alternate Number",
    },
    fax: {
      type: "text",
      default: "",
      label: "Fax Number",
    },
    country: {
      type: "select",
      default: "US",
      label: "Country",
      lookup: Object.entries(countries.getNames("en"))
        .map(([key, val]) => ({ value: key, label: val }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
    timezone: {
      type: "select",
      label: "Timezone",
      lookup: timezoneOptions,
      default: getUserTimezone(),
    },
  };

  const layout = Object.keys(fields).map((field) => {
    return { field: field, xs: fields[field].xs ? fields[field].xs : 12 };
  });

  const handleEditTableRowClick = (rowDetails) => {
    //rowDetails, in this case, is really just the user info/model
    setSelectedModel(rowDetails);
    setOpenForm(true);

    // configure  values for confirmation dialog if user wants to delete this location
    const emailArray = getPersonEmailsToDisplayFromLocation(rowDetails);
    setEmailsAssociatedWithLocation(emailArray);
  };

  const getModelFormActions = () => {
    let actionsToReturn = [
      {
        title: "Cancel",
        type: "button",
        action: handleClose,
      },
      {
        title: "Save",
        type: "submit",
        variant: "contained",
      },
      {
        type: "button",
        title: "Delete this location",
        leftAlign: true,
        textColor: "white",
        variant: "contained",
        backgroundColor: deleteButtonColor,
        action: () => setIsConfirmationDialogOpen(true),
      },
    ];

    // if user is on their last location, do not allow delete
    if (locations.length < 2 || !selectedModel.id)
      return actionsToReturn.filter(
        ({ title }) => title.toLowerCase() !== "delete"
      );

    return actionsToReturn;
  };

  return (
    <Root className="dashboard locations-page">
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openForm}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">
          {selectedModel.id ? `Edit ${selectedModel.name}` : "New Location"}
        </DialogTitle>
        <SimpleModelForm
          spacing={2}
          model={selectedModel}
          onClose={handleClose}
          actions={getModelFormActions()}
          fields={fields}
          layout={layout}
        />
      </Dialog>

      <ActionDialog
        open={isConfirmationDialogOpen}
        title={
          isLoading
            ? "Handling your request..."
            : `Are you sure you want to delete ${selectedModel.name} (${selectedModel.city})?`
        }
        description={
          isLoading || loadingMessage ? (
            <>
              <div className={classes.loadingContainer}>{loadingMessage}</div>
            </>
          ) : (
            <>
              {emailsAssociatedWithLocation.length ? (
                <div>
                  <p>
                    Deleting this location will result in the following users
                    being moved to{" "}
                    <strong>
                      {defaultLocation.name} ({defaultLocation.city})
                    </strong>
                  </p>
                  <ul>
                    {emailsAssociatedWithLocation.map((email, idx) => (
                      <li key={`${idx}-${email}`}>{email}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div>
                  <p>
                    There are no users associated with this location. It is safe
                    to delete.
                  </p>
                </div>
              )}
            </>
          )
        }
        actions={[
          {
            title: loadingMessage ? "Close" : "Cancel",
            action: () => {
              toggleDeleteConfirmationDialog();
            },
          },
          {
            textColor: "white",
            variant: "contained",
            action: handleDeleteLocation,
            backgroundColor: deleteButtonColor,
            datacy: "confirmDeleteLocationButton",
            disabled: isLoading || locations.length === 1, // prohibit users from deleting their final location
            title: isLoading ? (
              <CircularProgress style={{ color: "white" }} size={15} />
            ) : (
              "Yes, delete location"
            ),
          },
        ]}
      />

      <SimpleTable
        isNorthStar
        data={locations}
        columns={columns}
        emptyText={<span>No locations</span>}
        rowClick={handleEditTableRowClick}
        isFetching={!ready}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add New Location",
            onClick: (event) => {
              setSelectedModel(new LocationModel({ orgId: orgId }));
              setOpenForm(true);
            },
          },
        ]}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit Location",
            onClick: (event, model) => {
              handleEditTableRowClick(model);
            },
          },
        ]}
      />
    </Root>
  );
};

LocationsPageView.propTypes = {
  orgId: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})),
};

LocationsPageView.defaultProps = {
  locations: [],
};

export const LocationsComponent = LocationsPageView;

const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
    currentUser: state.session?.settings?.user,
    timezones: state.session.settings.accountConstants.timezones,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
    editUser: (orgId, personId, data, editingSelf) =>
      dispatch(updateUser({ orgId, personId, data, editingSelf })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsPageView);
