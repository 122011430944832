import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import _ from "lodash";

import "react-table/react-table.css";

import { render } from "../../lib/helpers";

const getColumns = (columnHeaders = "event", columns = []) => {
  const cleanColumns = _.map(columns, (c) => ({
    ...c,
    Header: !_.isUndefined(c.Header)
      ? c.Header
      : render({
          path: columnHeaders,
          value: c.id || c.accessor,
          html: false,
          safe: false,
        }),
    headerClassName: `dg-${c.path || c.accessor}`,
    accessor: c.accessor,
    id: c.id || undefined,
    Cell: c.Cell
      ? c.Cell
      : (p) =>
          render({
            path: c.path || c.accessor,
            value: p.value,
            html: false,
            safe: false,
            defaultValue: _.isUndefined(c.defaultValue)
              ? undefined
              : c.defaultValue,
          }),
    className: `dg-${c.path || c.accessor}`,
  }));
  return cleanColumns || [];
};

const DataGrid = ({
  columns,
  columnHeaders,
  compactStyle,
  defaultPageSize,
  pageSizeOptions,
  ...restProps
}) => (
  <div
    className={`datagrid ${compactStyle ? "compact" : ""}`}
    data-cy={"datagrid"}
  >
    <ReactTable
      minRows={3}
      defaultPageSize={defaultPageSize}
      {...restProps}
      columns={getColumns(columnHeaders, columns)}
      pageSizeOptions={pageSizeOptions}
    />
  </div>
);

DataGrid.propTypes = {
  data: PropTypes.instanceOf(Array),
  defaultPageSize: PropTypes.number,
  columns: PropTypes.instanceOf(Array),
  columnHeaders: PropTypes.string,
  compactStyle: PropTypes.bool,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
};
DataGrid.defaultProps = {
  data: [],
  defaultPageSize: 25,
  columns: [],
  columnHeaders: "",
  compactStyle: false,
  pageSizeOptions: [5, 10, 20, 25, 50, 100, 500, 1000],
};

export default DataGrid;
