import moment from "moment-timezone";

import Logger from "../../../lib/logger";

const logger = Logger("TimeInterval");

/*
Selected Interval
*/

/*
Constants and Helpers
*/

export const INTERVAL_TYPE = {
  CUSTOM: "custom",
  RELATIVE_INTERVAL: "relative_interval",
  RELATIVE_START: "relative_start",
};

// Given `selectedInterval`, returns its code used as a key
// when inserting data into 'logdevs.byOrg' and 'logdevs.dates'
export const getIntervalCode = (selectedInterval) => {
  const { intervalType, relativeEnd, relativeStart } = selectedInterval;

  switch (intervalType) {
    case INTERVAL_TYPE.CUSTOM:
      return INTERVAL_TYPE.CUSTOM;

    case INTERVAL_TYPE.RELATIVE_START:
      return relativeStart;

    case INTERVAL_TYPE.RELATIVE_INTERVAL:
      return `${relativeStart}/${relativeEnd}`;

    default:
      logger.error(new Error("Unknown selected interval type"));

      return undefined;
  }
};

// Given a selectedInterval, returns FEA '/resources/logdev'
// query's time attributes
export const timeParamsFromInterval = (selectedInterval) => {
  const { dateRange, intervalType, relativeEnd, relativeStart } =
    selectedInterval;

  switch (intervalType) {
    case INTERVAL_TYPE.CUSTOM: {
      const { timeEnd, timeStart } = dateRange;

      const timeParams = {
        intervalType,
        timeEnd: timeEnd.toISOString(),
        timeStart: timeStart.toISOString(),
      };

      return timeParams;
    }
    case INTERVAL_TYPE.RELATIVE_INTERVAL: {
      return {
        intervalType,
        relativeEnd,
        relativeStart,
      };
    }
    case INTERVAL_TYPE.RELATIVE_START: {
      return {
        intervalType,
        relativeStart,
      };
    }
    default: {
      logger.error(new Error("Unknown selected interval type"));

      return {};
    }
  }
};

/*
Actions
*/

// Receive custom date range
const receiveDateRange =
  (actionType) =>
  ({ dateRange, insertId }) => {
    const { timeEnd, timeStart } = dateRange;

    const payload = {
      insertId,
      data: {
        dateRange: {
          timeEnd: moment(timeEnd),
          timeStart: moment(timeStart),
        },
        intervalType: INTERVAL_TYPE.CUSTOM,
        relativeEnd: "",
        relativeStart: "",
      },
    };

    return {
      type: actionType,
      payload,
    };
  };

// Receive relative interval (i.e. relative start with relative end)
const receiveRelativeInterval =
  (actionType) =>
  ({ orgId, relativeEnd, relativeStart }) => {
    // Do not accept an interval without a relativeStart
    if (!relativeStart) {
      return;
    }

    const payload = {
      insertId: orgId,
      data: {
        dateRange: {},
        intervalType: INTERVAL_TYPE.RELATIVE_INTERVAL,
        relativeEnd,
        relativeStart,
      },
    };

    return {
      type: actionType,
      payload,
    };
  };

// Receive relative start (i.e. relative start without relative end)
const receiveRelativeStart =
  (actionType) =>
  ({ orgId, relativeStart }) => {
    const payload = {
      insertId: orgId,
      data: {
        dateRange: {},
        intervalType: INTERVAL_TYPE.RELATIVE_START,
        relativeEnd: "",
        relativeStart,
      },
    };

    return {
      type: actionType,
      payload,
    };
  };

// Receive general selectedInterval
const receiveSelectedInterval =
  (actionType) =>
  ({ dateRange = {}, intervalType, orgId, relativeEnd, relativeStart }) => {
    const payload = {
      insertId: orgId,
      data: {
        dateRange: { ...dateRange },
        intervalType,
        relativeEnd,
        relativeStart,
      },
    };

    return {
      type: actionType,
      payload,
    };
  };

export const receiveTimeInterval = (actionType) => ({
  receiveDateRange: receiveDateRange(actionType),
  receiveRelativeInterval: receiveRelativeInterval(actionType),
  receiveRelativeStart: receiveRelativeStart(actionType),
  receiveSelectedInterval: receiveSelectedInterval(actionType),
});
