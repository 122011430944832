/* eslint no-console: 0 */

import * as Sentry from "@sentry/react";

import { Severity } from "@sentry/browser";

import config from "./config";

// Sentry severity levels
export const MESSAGE_SEVERITY_LEVELS = {
  Debug: Severity.Debug,
  Error: Severity.Error,
  Fatal: Severity.Fatal,
  Info: Severity.Info,
  Warning: Severity.Warning,
};

// Environment variables used as on/off switches
const allowRemote = () => process.env.REACT_APP_LOGGER_ALLOW_REMOTE === "true";
const isDevMode = () => process.env.NODE_ENV === "development";

// Returns config.json entries
const getConfigData = () => {
  const {
    deploymentType,
    build_tag: releaseVersion = "",
    sentry = {},
  } = config;

  const { autoPopup, dsn, enable = false } = sentry;

  return {
    autoPopup,
    deploymentType,
    dsn,
    enable,
    releaseVersion,
  };
};

// Returns the Sentry feedback widget configuration object
export const getWidgetConfig = ({ email, eventId, name }) => {
  const widgetConfig = {
    eventId,
    subtitle: "Our team has been notified.",
    subtitle2: "If you’d like to help, tell us what happened below.",
    title: "It looks like we’re having issues.",
    user: {
      name,
      email,
    },
  };

  return widgetConfig;
};

// Returns the Sentry feedback widget auto-opener
const getBeforeSend =
  (user = {}) =>
  (evt) => {
    const { email = "", name = "" } = user;

    const widgetConfig = getWidgetConfig({
      email,
      eventId: evt.event_id,
      name,
    });

    // If event is an exception, show the report dialog
    if (evt.exception) {
      Sentry.showReportDialog(widgetConfig);
    }

    return evt;
  };

// Returns the Sentry configuration object
const getSentryConfig = (user = {}) => {
  const { autoPopup, deploymentType, dsn, releaseVersion } = getConfigData();

  if (!dsn) {
    throw new Error("Found no Sentry DSN in config.json");
  }

  const integrations = [
    new Sentry.Integrations.Breadcrumbs({
      beacon: true,
      console: false,
      dom: true,
      fetch: true,
      history: false,
      sentry: true,
      xhr: true,
    }),
  ];

  const sentryConfig = {
    dsn,
    environment: deploymentType,
    integrations,
    release: releaseVersion,
  };

  if (autoPopup) {
    sentryConfig.beforeSend = getBeforeSend(user);
  }

  return sentryConfig;
};

// Returns Sentry status.
// If NODE_ENV is not 'development', Sentry is enabled iff
// config.sentry.enable===true.
// If NODE_ENV is 'development', Sentry is disabled unless
// the environment variable REACT_APP_LOGGER_ALLOW_REMOTE='true'
// in which case the rule is the same as above.
export const remoteEnabled = () => {
  const configSetting = getConfigData().enable;
  const devEnvironment = isDevMode();

  // Local development
  if (devEnvironment) {
    const allowed = allowRemote();
    return configSetting && allowed;
  }

  // Not local development
  return configSetting;
};

// Adds user data to the currently active Sentry scope
export const scopeSetUser = (user) => {
  if (!remoteEnabled()) {
    return;
  }

  // Unset the user
  if (!user) {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
    return;
  }

  // Set the user
  const { userId } = user;

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userId,
    });
  });
};

// Adds Sentry breadcrumb
export const addBreadcrumb = ({
  category = "info", // similar to a logger name
  message = "",
  level = Severity.Info,
}) => {
  if (!remoteEnabled()) {
    return;
  }

  Sentry.addBreadcrumb({
    category,
    message,
    level,
  });
};

// Sends error to Sentry. Returns Sentry event ID.
export const captureError = ({ err, errorInfo = {}, message }) => {
  if (!remoteEnabled()) {
    return;
  }

  let eventId;

  Sentry.withScope((scope) => {
    const extras = { ...errorInfo };
    if (message) {
      extras.message = message;
    }

    scope.setExtras(extras);

    eventId = Sentry.captureException(err);
  });

  return eventId;
};

// Initializes Sentry
export const initSentry = (user) => {
  const enabled = remoteEnabled();
  const { deploymentType, releaseVersion } = getConfigData();

  if (deploymentType === "local-dev" || deploymentType === "development") {
    console.log(
      enabled
        ? `Initializing Sentry. Release version: ${releaseVersion}`
        : "Sentry not enabled"
    );
  }

  if (!enabled) {
    return;
  }

  let sentryConfig;
  try {
    sentryConfig = getSentryConfig(user);
  } catch (err) {
    console.error("Unable to initialize Sentry.", err);
    return;
  }

  Sentry.init(sentryConfig);
};

// Displays Sentry's User Feedback dialog
export const showDialog = ({ email, eventId, name }) => {
  if (!remoteEnabled()) {
    return;
  }

  const widgetConfig = getWidgetConfig({
    email,
    eventId,
    name,
  });

  Sentry.showReportDialog(widgetConfig);
};
