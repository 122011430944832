import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import CloudModuleDialog from "views/Components/CloudModules/CloudModuleDialog";

import Link from "redux-first-router-link";

import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";

import CheckCircle from "@mui/icons-material/CheckCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

import RunTestDialog from "views/Components/RunTestDialog";
import SearchableListDialog from "../../Components/SearchableListDialog";
import MoreDetectionCoverageDialog from "../../Components/MoreDetectionCoverageDialog";

import { getOrgs } from "redux/actions/Org";
import { loadPageData } from "redux/actions/Request";

import { INTEGRATION_STATUS } from "../../Components/CloudModules/constants";

import { Root, classes } from "./styles";

export const accountAdministrationHref = "https://blumira.help/users";

const OnboardingStep = (props) => {
  return (
    <Root
      className={classes.onboardingStep}
      style={{
        // leverage percentage to maintain
        // styling when menu is opened/closed
        zIndex: 10 / props.step,
        marginTop: props.step > 1 ? -320 : 0,
        width: props.step === 3 ? "37%" : "35%",
        paddingLeft: props.step === 2 ? "3%" : props.step === 3 ? "6%" : 15,
        marginLeft: props.step === 1 ? 0 : props.step === 2 ? "33%" : "63%",
      }}
    >
      <div className={classes.stepNumberContainer}>
        {props.completed ? (
          <CheckCircle className={classes.completed} />
        ) : props.loading ? (
          <CircularProgress size={20} className={classes.loading} />
        ) : null}
        <div className={classes.stepNumber}>
          <p>{props.step}</p>
        </div>
      </div>
      <div className={classes.titleContentContainer}>
        <div className={classes.stepTitle}>{props.title}</div>
        {props.content}
      </div>
      {props.cta ? props.cta : <div />}
    </Root>
  );
};

const WelcomePageView = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasIntegration, setHasIntegration] = useState(false);
  const [integration, setIntegration] = useState(null);
  const [areRulesDeployed, setAreRulesDeployed] = useState(false);
  const [isOrgNameDialogOpen, setIsOrgNameDialogOpen] = useState(false);
  const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(false);
  const [isRunTestDialogOpen, setIsRunTestDialogOpen] = useState(false);
  const [isAddConnectorButtonHidden, setIsAddConnectorButtonHidden] =
    useState(false);
  const [isDetectionRulesDialogOpen, setIsDetectionRulesDialogOpen] =
    useState(false);
  const [isDetectionCoverageDialogOpen, setIsDetectionCoverageDialogOpen] =
    useState(false);

  useEffect(() => {
    // adding this here to ensure that local storage
    // is cleared if user just signed up
    localStorage.removeItem("orgName");
    localStorage.removeItem("orgWebUrl");
    localStorage.removeItem("referralSource");

    if (!props.currentOrg) {
      props.getOrgs();
    }
    if (
      props.currentOrg &&
      props.currentOrg.name.toLowerCase().includes("sandbox")
    )
      setIsOrgNameDialogOpen(true);
    props.reload(true);
  }, [props.currentOrg]);

  useEffect(() => {
    if (props.integrations) {
      const existingIntegration = props.integrations[0];
      setIntegration(existingIntegration);
      setHasIntegration(!!existingIntegration);

      if (
        props.integrations.length > 2 &&
        props.license.isTagged("limited-to-free")
      )
        setIsAddConnectorButtonHidden(true);
    }
  }, [props.integrations, props.license]);

  useEffect(() => {
    if (props.rules) {
      setAreRulesDeployed(!!props.rules.length);
    }
  }, [props.rules]);

  const toggleDetectionRulesDialog = () => {
    setIsDetectionRulesDialogOpen(!isDetectionRulesDialogOpen);
  };

  const toggleDetectionCoverageDialog = () => {
    setIsDetectionCoverageDialogOpen(!isDetectionCoverageDialogOpen);
  };

  const toggleConnectDialog = () => {
    setIsConnectDialogOpen(!isConnectDialogOpen);
  };

  const handleAdditionalInfoClick = () => {
    setIsDetectionRulesDialogOpen(false);
    setIsDetectionCoverageDialogOpen(true);
  };

  // TODO: the test dialog is laid out in Wes' design docs for a later release
  // which includes multiple tests, not just M365
  const toggleRunTestDialog = () => {
    setIsRunTestDialogOpen(!isRunTestDialogOpen);
  };

  const isOnboaordingStepTwoComplete = () => {
    // user has rules deployed and
    // integration created and
    // integration status is 'ok'
    if (
      areRulesDeployed &&
      integration &&
      integration.integrationStatus === INTEGRATION_STATUS.OK
    )
      return true;
    return false;
  };

  /* 
        note that a user will need to refresh
        this page as we are not polling for rules 
    */
  const isOnboaordingStepTwoLoading = () => {
    // user has no rules deployed or
    // integration created and
    // status is anything but 'ok'
    if (
      (hasIntegration && !areRulesDeployed) ||
      (integration && integration.integrationStatus !== INTEGRATION_STATUS.OK)
    )
      return true;
    return false;
  };

  const handleOrgNameChange = (e) => {
    if (!props.currentOrg) return;
    props.currentOrg.set({ name: e.target.value });
  };

  const handleSaveOrg = async () => {
    setIsSaving(true);
    try {
      await props.currentOrg.update();
      await props.getOrgs();
      toggleOrgNameDialog();
      setIsSaving(false);
      setErrorMessage("");
    } catch (e) {
      setErrorMessage(e);
      setIsSaving(false);
    }
  };

  const toggleOrgNameDialog = () => {
    setIsOrgNameDialogOpen(!isOrgNameDialogOpen);
  };

  return (
    <Root className={classes.pageContainer}>
      <div className={classes.pageTitleContainer}>
        <p className={classes.pageTitle}>Welcome to Blumira!</p>
        <div className={classes.linkContainer}>
          <p className={classes.link} onClick={toggleDetectionRulesDialog}>
            What will Blumira detect?
          </p>
          <p className={classes.link} onClick={toggleDetectionCoverageDialog}>
            How do I get more detection coverage?
          </p>
        </div>
      </div>
      <div
        className={classes.stepsContainer}
        datacy={"onboardingStepContainer"}
      >
        <OnboardingStep
          step={1}
          completed={hasIntegration}
          title={<span>Add a Cloud Connector</span>}
          content={
            <div>
              <p className={classes.subsequentStepText}>
                Blumira Free Edition includes up to three Cloud Connectors,
                which connect to your apps and services to send logs to Blumira.
              </p>
            </div>
          }
          cta={
            !isAddConnectorButtonHidden ? (
              <Button
                className={classes.connectButton}
                variant={"contained"}
                color={"primary"}
                onClick={toggleConnectDialog}
                datacy={"connectToM365Button"}
                disabled={!props.integrationSchemas}
              >
                {!props.integrationSchemas
                  ? "Loading..."
                  : "Add Cloud Connector"}
              </Button>
            ) : (
              <span
                className={classes.span}
                datacy={"connectorLimitReachedText"}
              >
                You have reached your free limit.
              </span>
            )
          }
        />
        <OnboardingStep
          step={2}
          title={"Logs flow in and detection rules get deployed"}
          loading={isOnboaordingStepTwoLoading()}
          completed={isOnboaordingStepTwoComplete()}
          content={
            <p className={classes.subsequentStepText}>
              It may take up to 30 minutes for logs to begin flowing in and for
              Blumira to automatically deploy your detection rules.
              {isOnboaordingStepTwoComplete() ? null : (
                <span className={classes.span}>No logs detected yet.</span>
              )}
            </p>
          }
          cta={
            <div className={classes.link}>
              <Link
                to={{
                  type: "PAGE",
                  payload: {
                    orgId: props.orgId,
                    toplevel: "dashboard",
                    secondlevel: "summary",
                  },
                }}
                dataCy={"viewSummaryDashboardBtn"}
              >
                See Summary Dashboard
                <OpenInNewIcon />
              </Link>
            </div>
          }
        />
        <OnboardingStep
          step={3}
          title={"Invite team members"}
          content={
            <p className={classes.subsequentStepText}>
              While you wait you can invite more team members or explore tests
              you can perform to ensure Blumira is working.
            </p>
          }
          cta={
            <div className={classes.thirdStepLinkContainer}>
              <div className={classes.link}>
                <Link
                  to={{
                    type: "PAGE",
                    payload: {
                      orgId: props.orgId,
                      toplevel: "settings",
                      secondlevel: "users",
                    },
                  }}
                  datacy={"inviteTeamMembersLink"}
                >
                  Invite team members
                  <OpenInNewIcon />
                </Link>
              </div>
              <div className={classes.link}>
                <Link onClick={toggleRunTestDialog}>
                  Step-by-step tests
                  <OpenInNewIcon />
                </Link>
              </div>
            </div>
          }
        />
      </div>
      <div className={classes.learnMoreVideoContainer}>
        <div className={classes.learnMoreSectionContainer}>
          <div>
            <p className={classes.sectionTitle}>How does Blumira work?</p>
            <p>
              Blumira’s cloud security monitoring platform detects threats by
              importing logs from a variety of sources and applying detection
              rules to identify security threats.
            </p>
          </div>
          <div className={classes.learnMoreLinkContainer}>
            <p className={classes.sectionTitle}>Learn more</p>
            <a
              href={"https://blumira.help/fgetstarted"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Getting started guide <OpenInNewIcon />
            </a>
            <a
              href={accountAdministrationHref}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Administering Blumira User Accounts <OpenInNewIcon />
            </a>
            <a
              href={"https://blumira.help/center"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Help Center
              <OpenInNewIcon />
            </a>
            <a
              href={"https://www.blumira.com/webinars/"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              On-demand webinars <OpenInNewIcon />
            </a>
            <a
              href={"https://www.blumira.com/whitepapers/"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Whitepapers <OpenInNewIcon />
            </a>
            <a
              href={"https://www.blumira.com/blog/"}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Blog <OpenInNewIcon />
            </a>
          </div>
        </div>
        <div className={classes.videoSectionContainer}>
          {/* TODO: when we eventually get a relvant video we can include it here */}
          {/* <p className={classes.sectionTitle}>Video demo</p>
            <iframe 
                width={'750'}
                height={'350'}
                frameBorder={'0'} 
                src={'https://www.youtube.com/embed/YIWSEa5U9_U'}
            /> 
          */}
        </div>
      </div>

      <SearchableListDialog
        items={props.rules}
        title={"Detection rules"}
        open={isDetectionRulesDialogOpen}
        onClose={toggleDetectionRulesDialog}
        additionalInfo={"Get more detection coverage"}
        handleAdditionalInfoClick={handleAdditionalInfoClick}
        emptyText={
          "Detection rules will appear as soon as Blumira processes your incoming log data. This can take up to 60 minutes after you set up your first Cloud Connector or Sensor."
        }
      />
      <MoreDetectionCoverageDialog
        open={isDetectionCoverageDialogOpen}
        onClose={toggleDetectionCoverageDialog}
      />
      <RunTestDialog open={isRunTestDialogOpen} onClose={toggleRunTestDialog} />
      <CloudModuleDialog
        showSuccess={true}
        ready={props.ready}
        license={props.license}
        open={isConnectDialogOpen}
        superadmin={props.superadmin}
        integrations={props.integrations}
        toggleModal={toggleConnectDialog}
        reload={(bool) => props.reload(bool)}
        integrationSchemas={props.integrationSchemas}
      />
      <Dialog open={isOrgNameDialogOpen}>
        <DialogTitle>Welcome to Blumira Free Edition</DialogTitle>
        <DialogContent>
          <div className={classes.dialogSectionContainer}>
            <p>What is the name of your organization?</p>
            <TextField
              error={!!errorMessage}
              className={classes.textField}
              onChange={handleOrgNameChange}
              InputProps={{ disableUnderline: true }}
              helperText={errorMessage ? errorMessage : ""}
            />
            {errorMessage && (
              <p className={classes.error}>Please fix the errors</p>
            )}
          </div>
          <div className={classes.dialogSectionContainer}>
            <p>
              For additional information on how to add users, please reference{" "}
              <a
                rel={"noopener noreferrer"}
                href={accountAdministrationHref}
                target={"_blank"}
                className={classes.link}
              >
                Administering Blumira User Accounts
              </a>
            </p>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              disabled={isSaving}
              variant={"text"}
              color={"primary"}
              onClick={toggleOrgNameDialog}
            >
              Not Now
            </Button>
            <Button
              className={classes.button}
              disabled={isSaving}
              variant={"contained"}
              color={"primary"}
              onClick={handleSaveOrg}
            >
              {isSaving ? "Saving..." : "Submit"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Root>
  );
};

WelcomePageView.propTypes = {
  goToUsersView: PropTypes.func.isRequired,
  goToSummaryDashboardView: PropTypes.func.isRequired,
};

WelcomePageView.defaultProps = {};

const mapStateToProps = (state) => {
  const orgId = state.location.payload.orgId;
  const currentOrg = state.org.listOrgs.orgs.find((o) => o["id"] === orgId);

  return {
    orgId,
    currentOrg,
    license: state.license,
    superadmin: state?.session?.settings?.user?.superadmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToSummaryDashboardView: (orgId) => {
    const payload = {
      orgId,
      toplevel: "dashboard",
      secondlevel: "summary",
    };

    dispatch({
      type: "PAGE",
      payload,
    });
  },
  goToUsersView: (orgId) => {
    const payload = {
      orgId,
      toplevel: "settings",
      secondlevel: "users",
    };

    dispatch({
      type: "PAGE",
      payload,
    });
  },
  reload: (force) => dispatch(loadPageData(force)),
  getOrgs: () => dispatch(getOrgs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePageView);
