import React, { useState, useEffect } from "react";

import { Button, DialogTitle, DialogActions, TextField } from "@mui/material";

import { Alert } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { StyledDialog, classes } from "./addAgentDialogStyles";

import { defaultKeyValue } from "../../Pages/BlumiraAgent/InstallationPageView";

import { InstallationKeySelector } from "views/Components/BlumiraAgent/InstallationKeySelector";

/* 
    @getNewTotalAgentCount: provided two numeric values, returns the sum
*/
export const getNewTotalAgentCount = (
  existingAgentCount = 0,
  agentCountToAdd = 0
) => {
  return existingAgentCount + agentCountToAdd;
};

export const AddAgentDialog = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [agentCount, setAgentCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedKeyDescription, setSelectedKeyDescription] = useState("");
  const [selectedKeyCount, setSelectedKeyCount] = useState("");
  const [hasCountChanged, setHasCountChanged] = useState(false);

  useEffect(() => {
    if (props.selectedGroupId) {
      // find full model for selected key
      const selectedKey = props.keys.find(
        ({ id }) => id === props.selectedGroupId
      );

      // grab pertinent data to display in helper text
      const selectedKeyCount = selectedKey?.agentSeatCount || 0;
      const newSelectedKeyDescription = selectedKey?.description || "";

      const newSelectedKeyCount = getNewTotalAgentCount(
        selectedKeyCount,
        agentCount
      );

      // set in state
      setSelectedKeyCount(newSelectedKeyCount);
      setSelectedKeyDescription(newSelectedKeyDescription);
    }
  }, [props.selectedGroupId, props.keys, agentCount]);

  const handleChange = (e) => {
    const numericValue = parseInt(e.target.value);

    if (numericValue) {
      setErrorMessage("");
      setHasCountChanged(true);
      setAgentCount(numericValue);
    } else {
      setErrorMessage("");
      setHasCountChanged(false);
    }
  };

  const handleChangeInstallationKey = (selectedGroupModelId) => {
    setErrorMessage("");
    props.changeInstallationKey(selectedGroupModelId);
  };

  const resetDialog = () => {
    setAgentCount(0);
    setIsSaving(false);
    setErrorMessage("");
    setHasCountChanged(false);
  };

  const handleAddAgents = async () => {
    // restrict MSP admins to only increasing agent ceiling
    if (agentCount <= 0) {
      return setErrorMessage("Please add at least 1 key");
    } else if (props.selectedGroupId === defaultKeyValue) {
      return setErrorMessage("Please select an installation key");
    }

    setIsSaving(true);

    props
      .handleAddAgents(agentCount)
      .then(() => {
        handleClose();
        props.handleReload();
      })
      .catch((e) => {
        setIsSaving(false);
        setErrorMessage("We had an error on our end.");
      });
  };

  const handleClose = () => {
    resetDialog();
    props.onClose();
  };

  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <div
        className={classes.dialog}
        style={props.keys.length > 1 ? { minWidth: 500 } : {}}
      >
        <div className={classes.dialogHeader}>
          <DialogTitle>Add agents</DialogTitle>
          <CloseIcon className={classes.close} onClick={handleClose} />
        </div>
        <div className={classes.dialogContent}>
          {errorMessage && (
            <Alert
              className={classes.errorAlert}
              severity="error"
              action={
                <a
                  className={classes.errorAction}
                  rel={"noopener noreferrer"}
                  target={"_blank"}
                  href={"https://blumira.help/partners"}
                >
                  CONTACT
                </a>
              }
            >
              {errorMessage}
            </Alert>
          )}
          <div className={classes.rowOuterContainer}>
            {props.keys.length > 1 && (
              <div className={classes.circularNumberContainer}>
                <p>1</p>
              </div>
            )}
            <TextField
              type={"number"}
              style={{ width: 250 }}
              onChange={handleChange}
              placeholder={"Enter number of agents"}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: 0 }} // FYI: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
              helperText={
                // do not show helper text when key selection is available to users
                // since the total will show below the key selector component
                props.keys.length > 1
                  ? ""
                  : hasCountChanged
                  ? `New total: ${getNewTotalAgentCount(
                      props.existingCount,
                      agentCount
                    )}`
                  : "New total:"
              }
            />
          </div>
          {props.keys.length > 1 && (
            <div
              className={classes.rowOuterContainer}
              style={{ marginTop: 15 }}
            >
              <div className={classes.circularNumberContainer}>
                <p>2</p>
              </div>
              <div className={classes.installationKeySelectorInnerContainer}>
                <p>
                  Select the installation key you would like these agents added
                  to:
                </p>
                <InstallationKeySelector
                  keys={props.keys}
                  overrideDisabling={true}
                  selectedGroupId={props.selectedGroupId}
                  changeInstallationKey={handleChangeInstallationKey}
                  helperText={
                    hasCountChanged && selectedKeyDescription
                      ? `New total agent limit for installation key "${selectedKeyDescription}": ${selectedKeyCount}`
                      : ""
                  }
                />
              </div>
            </div>
          )}
        </div>
        <DialogActions className={classes.dialogActions}>
          <Button variant={"text"} onClick={handleClose} color={"primary"}>
            Cancel
          </Button>
          <Button
            color={"primary"}
            disabled={isSaving}
            variant={"contained"}
            onClick={handleAddAgents}
          >
            {isSaving
              ? `Adding ${agentCount} Agent${agentCount === 1 ? "" : "s"}`
              : "Add agents"}
          </Button>
        </DialogActions>
      </div>
    </StyledDialog>
  );
};
