import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateSensor } from "../../../redux/actions/Sensors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  currentSensorSelector,
  sensorIdSelector,
} from "../../../selectors/sensorsSelectors";
import { ContentEditableDiv } from "./BluContentEditable";

import "./SensorDescription.scss";

const SupportNotes = (props) => {
  const { notes, orgId, sensorId, updateSupportNotes } = props;

  return (
    <div className="sensor-description">
      {!notes && <div className="sensor-description-add">Add notes:</div>}
      <ContentEditableDiv
        innerClassName="sensor-name"
        save={updateSupportNotes({ orgId, sensorId })}
        value={notes}
        regex=""
        errorFlag={false}
      />
    </div>
  );
};

SupportNotes.propTypes = {
  notes: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  sensorId: PropTypes.string.isRequired,
  updateSupportNotes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);

  const sensor = currentSensorSelector(state);

  const { notes } = sensor;

  return {
    notes: notes || "",
    orgId,
    sensorId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSupportNotes:
    ({ orgId, sensorId }) =>
    (notes) => {
      const data = { notes };

      dispatch(
        updateSensor({
          orgId,
          sensorId,
          data,
        })
      );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportNotes);
