import * as Actions from "./actions";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const initialState = {
  theme: cookies.get("theme"),
};

const UserInterfaceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.UserInterface.CHANGE_THEME: {
      // if for some reason we don't
      // have a theme, set to default
      let theme = action.theme ? action.theme : "default";
      cookies.set("theme", theme, {
        path: "/",
        //secure: true,
        sameSite: "Strict",
        maxAge: 31536000,
      });

      return {
        ...state,
        theme: theme,
      };
    }
    default:
      return state;
  }
};

export default UserInterfaceReducer;
