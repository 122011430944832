// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { AutoSizer, List } from "react-virtualized";
import { any, bool, func, number, string } from "prop-types";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Components **********************
import { SelectEmptyState } from "views/Components/EmptyState";
import { ExIcon, SelectCaretIcon } from "views/Components/Icon";
import { DefaultInput } from "views/Components/Input";
import { Option } from "./Option";

// ** Styles **************************
import {
  RelativeContainer,
  SelectAbsoluteContainer,
  SelectContainerStyle,
  SelectErrorStyle,
  SelectIconContainer,
  SelectLabelStyle,
  SelectItem,
  SelectItemIcon,
  SelectItemIconContainer,
  SelectList,
  SelectRelativeContainer,
  SelectResultsContainer,
} from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Select component
//
//  ** Remarks
//  Simple select component extended to simplify code across the app
//
//  ** Props
//  @param bottom {string} - option container orientation
//  @param disabled {boolean} - disabled flag
//  @param error {boolean} - validation flag
//  @param errorMessage {string} - message displayed if error is present
//  @param id {string} - id value
//  @param label {string} - input label
//  @param maxHeight {number} - option container max-height
//  @param multiple {boolean} - multiple selection flag
//  @param onBlur {func} - function invoked when input loses focus
//  @param onChange {func} - function invoked when a change happens
//  @param options {array} - select options
//  @param placeholder {string} - select placeholder
//  @param padding {string} - option container padding
//  @param top {string} - option container orientation
//  @param value {string} - input value
//
//  ** State
//  @param filteredOptions {array} - options reduced/filtered by filterValue
//  @param filterValue {string} - current filter value
//  @param focused {bool} - focused flag
//
class Select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredOptions: props.options,
      filterValue: "",
      focused: false,
    };

    this.childContainer = React.createRef();
    this.selectedContainer = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("click", this.checkToggle.bind(this));

    if (this.props.multiple && this.props.value.length > 0) {
      const { options, value } = this.props;

      const filteredOps = options.filter(
        (option) => value.findIndex((data) => data.uid === option.value) < 0
      );

      this.setState({
        filteredOptions: filteredOps,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { options, multiple, value } = this.props;

    if (prevProps.options !== options && !multiple) {
      this.setState({
        filteredOptions: options,
      });
    }

    if (prevProps.value !== value && multiple) {
      const filteredOps = options.filter(
        (option) => value.findIndex((data) => data.uid === option.value) < 0
      );

      this.setState({
        filteredOptions: filteredOps,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.checkToggle.bind(this));
  }

  onClick(values, val) {
    const { id, onChange } = this.props;

    this.setState({
      filterValue: null,
      focused: false,
    });

    if (this.props.multiple) {
      const addedValue = [...values, val];

      onChange(addedValue);
    } else {
      onChange(id, val);
    }
  }

  onFilter(id, value) {
    const { options } = this.props;
    const { focused } = this.state;

    const filteredOptions =
      value !== ""
        ? options.filter(
            (option) =>
              option.label.toLowerCase().indexOf(value.toLowerCase()) === 0
          )
        : options;

    if (!focused) {
      this.setState({
        focused: true,
      });
    }

    this.setState({
      filteredOptions,
      filterValue: value,
    });
  }

  onFocus(e) {
    if (
      this.props.multiple &&
      this.selectedContainer.current &&
      !this.selectedContainer.current.contains(e.target)
    ) {
      this.setState((prevState) => ({
        focused: !prevState.focused,
      }));
    } else if (!this.props.multiple) {
      this.setState((prevState) => ({
        focused: !prevState.focused,
      }));
    }
  }

  onRemove(values, uid) {
    const { onChange } = this.props;
    const updatedValues = values.filter((item) => item.uid !== uid);

    onChange(updatedValues);
  }

  dynamicHeight() {
    const { maxHeight } = this.props;
    const { filteredOptions } = this.state;

    if (filteredOptions.length * 40 < maxHeight) {
      return filteredOptions.length * 40;
    }

    return maxHeight;
  }

  noRowsRenderer() {
    return <SelectEmptyState />;
  }

  rowRenderer({ index, key, style }) {
    const { value } = this.props;
    const { filteredOptions, filterValue } = this.state;
    const datum = filteredOptions[index];

    return (
      datum && (
        <div key={key} style={style}>
          <Option
            key={key}
            filterValue={filterValue}
            label={datum.label}
            onClick={(data) => this.onClick(value, data)}
            value={datum.value}
          />
        </div>
      )
    );
  }

  checkToggle(e) {
    const { focused } = this.state;

    if (
      this.childContainer.current &&
      !this.childContainer.current.contains(e.target) &&
      focused
    ) {
      this.onFocus(e);
    }
  }

  render() {
    const { filteredOptions, filterValue, focused } = this.state;
    const {
      bottom,
      disabled,
      error,
      errorMessage,
      id,
      label,
      maxHeight,
      multiple,
      padding,
      placeholder,
      top,
      value,
    } = this.props;

    return (
      <SelectContainerStyle ref={this.childContainer}>
        {label && <SelectLabelStyle>{label}</SelectLabelStyle>}
        <SelectRelativeContainer
          disabled={disabled}
          error={error}
          focused={focused}
          multiple={multiple}
          onClick={multiple ? this.onFocus.bind(this) : Function.prototype()}
          value={value}
        >
          {!multiple ? (
            <DefaultInput
              disabled={disabled}
              id={id}
              error={error}
              onChange={this.onFilter.bind(this)}
              onFocus={this.onFocus.bind(this)}
              placeholder={placeholder}
              value={
                filterValue !== "" && filterValue !== null ? filterValue : value
              }
            />
          ) : (
            <SelectList ref={this.selectedContainer}>
              {value.map((item) => (
                <SelectItem
                  key={item.uid}
                  onClick={() => this.onRemove(this.props.value, item.uid)}
                >
                  {item.name}
                  <SelectItemIconContainer>
                    <SelectItemIcon>
                      <ExIcon height="7px" />
                    </SelectItemIcon>
                  </SelectItemIconContainer>
                </SelectItem>
              ))}
            </SelectList>
          )}
          <SelectIconContainer>
            <SelectCaretIcon />
          </SelectIconContainer>
          <SelectAbsoluteContainer bottom={bottom} top={top}>
            <RelativeContainer>
              <SelectResultsContainer
                focused={focused}
                maxHeight={`${maxHeight}px`}
                padding={padding}
              >
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      height={this.dynamicHeight()}
                      overscanRowCount={20}
                      noRowsRenderer={this.noRowsRenderer}
                      rowCount={filteredOptions.length || 10}
                      rowHeight={40}
                      rowRenderer={this.rowRenderer.bind(this)}
                      width={width}
                    />
                  )}
                </AutoSizer>
              </SelectResultsContainer>
            </RelativeContainer>
          </SelectAbsoluteContainer>
        </SelectRelativeContainer>
        <SelectErrorStyle error={error}>{errorMessage}</SelectErrorStyle>
      </SelectContainerStyle>
    );
  }
}

// ** Proptypes ***********************
Select.propTypes = {
  bottom: string,
  disabled: bool,
  error: bool,
  errorMessage: string,
  id: string.isRequired,
  label: string,
  maxHeight: number,
  multiple: bool,
  onBlur: func,
  onChange: func,
  options: any.isRequired,
  padding: string,
  placeholder: string,
  top: string,
  value: any,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Default select ******************
export const DefaultSelect = withProps((props) => ({
  bottom: props.bottom,
  error: props.error,
  errorMessage: "This field cannot be left blank",
  id: props.id,
  label: props.label,
  maxHeight: props.maxHeight || 200,
  onBlur: props.onBlur,
  onChange: props.onChange,
  options: props.options,
  placeholder: props.placeholder,
  top: props.top,
  value: props.value || "",
}))(Select);

// ** Default multiple select *********
export const DefaultMultipleSelect = withProps((props) => ({
  bottom: props.bottom,
  error: props.error,
  errorMessage: "This field cannot be left blank",
  id: props.id,
  label: props.label,
  maxHeight: props.maxHeight || 200,
  multiple: true,
  onBlur: props.onBlur,
  onChange: props.onChange,
  options: props.options,
  padding: "8px 0px",
  top: props.top,
  value: props.value || [],
}))(Select);

export default Select;
