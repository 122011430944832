import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

/*
Constants and Helpers
*/

const bytesToMb = (val) => Math.round(val / (1024 * 1024)).toLocaleString();

const bytesToMbNoInfo = (num, noInfo) =>
  num ? `${bytesToMb(num)} MiB` : noInfo;

const usedResourceDisplayStr = ({ total, available, percent, noInfo }) => {
  const usedSupplied = (total || total === 0) && (available || available === 0);
  const percentSupplied = percent || percent === 0;

  let usedDisplayStr;
  if (usedSupplied && percentSupplied) {
    usedDisplayStr = `${bytesToMb(total - available)} MiB (${Math.round(
      percent
    )}%)`;
  } else if (usedSupplied) {
    usedDisplayStr = `${bytesToMb(total - available)} MiB`;
  } else {
    usedDisplayStr = noInfo;
  }

  return usedDisplayStr;
};

const NO_INFO_USER = "Blumira Support, support@blumira.com";

/*
Components
*/

// Overview's first column
export const ItemName = ({ text }) => (
  <div className="overview-cell-bold overview-name">{text}</div>
);

ItemName.propTypes = {
  text: PropTypes.string,
};

ItemName.defaultProps = {
  text: "",
};

// General info
export const SensorGeneral = ({ location, created, createdByUser, noInfo }) => {
  let locationInfo = "";
  if (location.name) {
    locationInfo += location.name;
  }
  if (location.city) {
    locationInfo += `, ${location.city}`;
  }
  if (location.state) {
    locationInfo += `, ${location.state}`;
  }
  if (location.country) {
    locationInfo += `, ${location.country}`;
  }

  let userInfo = "";
  if (createdByUser.name) {
    userInfo += createdByUser.name;
  }
  if (createdByUser.email) {
    userInfo += `, ${createdByUser.email}`;
  }

  const general = (
    <div className="overview-cell">
      <div>
        <span className="overview-cell-bold">Location: </span>
        <span>{locationInfo || noInfo}</span>
      </div>
      <div>
        <span className="overview-cell-bold">Time Zone: </span>
        <span>{location.timezone || noInfo}</span>
      </div>
      {created && (
        <div>
          <span className="overview-cell-bold">Added: </span>
          <span>
            {created
              ? moment
                  .tz(created, "utc")
                  .tz(moment.tz.guess(true))
                  .format("lll z")
              : noInfo}
          </span>
        </div>
      )}
      <div>
        <span className="overview-cell-bold">Added by: </span>
        <span>{userInfo || NO_INFO_USER}</span>
      </div>
    </div>
  );

  return general;
};

// Host detail
export const SensorHost = ({ uname, uptime, time, noInfo, listeningIps }) => {
  let currentTimeInfo = "";
  if (time) {
    currentTimeInfo = `${time} UTC`;
  }

  let listeningIpInfo = "";
  if (listeningIps) {
    listeningIpInfo = `${listeningIps.join(", ")}`;
  }

  const host = (
    <div className="overview-cell">
      <div>
        <span className="overview-cell-bold">Host OS: </span>
        <span>{uname || noInfo}</span>
      </div>
      <div>
        <span className="overview-cell-bold">Uptime: </span>
        <span>{uptime || noInfo}</span>
      </div>
      <div>
        <span className="overview-cell-bold">Current time: </span>
        <span>{currentTimeInfo || noInfo}</span>
      </div>
      <div>
        <span className="overview-cell-bold">IPs: </span>
        <span>{listeningIpInfo || noInfo}</span>
      </div>
    </div>
  );

  return host;
};

SensorHost.propTypes = {
  uname: PropTypes.string,
  uptime: PropTypes.string,
  time: PropTypes.string,
  noInfo: PropTypes.string,
  listeningIps: PropTypes.array,
};

SensorHost.defaultProps = {
  uname: "",
  uptime: "",
  time: "",
  noInfo: "",
  listeningIps: [],
};

// CPUs
export const SensorCpu = ({ cpuPercent, noInfo = "" }) => {
  const listItems = cpuPercent.map((percent, index) => (
    <li key={index.toString()}>
      <span>{`${Math.round(percent)}%` || noInfo}</span>
    </li>
  ));

  const cpu =
    cpuPercent.length > 0 ? (
      <div>
        <div className="overview-cell-bold">
          {`${cpuPercent.length} CPUs detected. Usage:`}
        </div>
        <ul className="overview-ul">{listItems}</ul>
      </div>
    ) : (
      <div>
        <div className="overview-cell">{noInfo}</div>
      </div>
    );

  return cpu;
};

SensorCpu.propTypes = {
  cpuPercent: PropTypes.arrayOf(PropTypes.number),
  noInfo: PropTypes.string,
};

SensorCpu.defaultProps = {
  cpuPercent: [],
  noInfo: "",
};

// Memory
export const SensorMemory = ({ memory, noInfo }) => {
  const { available, total } = memory;

  const percent = Math.round((100 * (total - available)) / total);

  const usedDisplayStr = usedResourceDisplayStr({
    total,
    available,
    percent,
    noInfo,
  });

  const memoryHtml = (
    <div>
      <div>
        <span className="overview-cell-bold">{"Installed: "}</span>
        <span>{`${bytesToMbNoInfo(total, noInfo)}`}</span>
      </div>

      <div>
        <span className="overview-cell-bold">{"Available: "}</span>
        <span>{`${bytesToMbNoInfo(available, noInfo)}`}</span>
      </div>

      <div>
        <span className="overview-cell-bold">{"Used: "}</span>
        <span>{usedDisplayStr}</span>
      </div>
    </div>
  );

  return memoryHtml;
};

SensorMemory.propTypes = {
  memory: PropTypes.shape({}),
  noInfo: PropTypes.string,
};

SensorMemory.defaultProps = {
  memory: {},
  noInfo: "",
};

// Disk
export const SensorDisk = ({ diskUsage, noInfo }) => {
  const { total, free } = diskUsage;

  const percent = Math.round((100 * (total - free)) / total);

  const usedDisplayStr = usedResourceDisplayStr({
    total,
    available: free,
    percent,
    noInfo,
  });

  const disk = (
    <div>
      <div>
        <span className="overview-cell-bold">{"Installed: "}</span>
        <span>{bytesToMbNoInfo(total, noInfo)}</span>
      </div>

      <div>
        <span className="overview-cell-bold">{"Available: "}</span>
        <span>{bytesToMbNoInfo(free, noInfo)}</span>
      </div>

      <div>
        <span className="overview-cell-bold">{"Used: "}</span>
        <span>{usedDisplayStr}</span>
      </div>
    </div>
  );

  return disk;
};

SensorDisk.propTypes = {
  diskUsage: PropTypes.shape({}),
  noInfo: PropTypes.string,
};

SensorDisk.defaultProps = {
  diskUsage: {},
  noInfo: "",
};
