import querySerializer from "query-string";
import _ from "lodash";
import { NOT_FOUND } from "redux-first-router";

import { redirectLogoutFromBlumira } from "./actions/Page";
import { auth, AUTH_STATUS } from "../lib/auth";

/*
Helpers
*/

const requiresAuthentication = (route) => {
  if (route && route.requiresAuthentication) {
    return true;
  }
  return false;
};

/*
Router options
*/
export default {
  querySerializer,
  title: (state) => {
    const map = state.location.routesMap[state.location.type];
    const title = map && map.title ? map.title : "";
    if (_.isFunction(title)) {
      return title(state);
    }

    if (title) {
      return title;
    }

    if (state.title) {
      return state.title;
    }

    return "Blumira";
  },
  onBeforeChange: (dispatch, getState, action) => {
    if (action.action.type === `${NOT_FOUND}`) {
      return;
    }

    const state = getState();
    const route = state.location.routesMap[action.action.type];

    if (
      requiresAuthentication(route) &&
      auth.currentStatus() !== AUTH_STATUS.LOGGED_IN
    ) {
      redirectLogoutFromBlumira({
        dispatch,
        location: action.action,
        pageErrorMsg: "Please login to continue",
      });
      return;
    }
  },
};
