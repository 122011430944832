import API from "../api/API2";
import Schema from "../schema";
import BluLicense from "lib/license";

let handler = {
  get: (target, name) => {
    if (target[name] === undefined) {
      if (target.fields && target.fields[name]) {
        return target.fields[name]();
      }
      return target.params[name];
    }
    return target[name];
  },
  ownKeys: function (target, key) {
    // we de-dupe the keys here, so that we can use fields to override an internal param
    const keys = new Set([
      ...Object.keys(target.params),
      ...Object.keys(target.fields),
    ]);
    return [...keys];
  },
  getOwnPropertyDescriptor: function (target, name) {
    var value;
    if (!target[name]) {
      if (target.fields && target.fields[name]) {
        value = target.fields[name]();
      } else {
        value = target.params[name];
      }
    } else {
      value = target[name];
    }
    return value !== undefined
      ? {
          value: value,
          writable: true,
          enumerable: true,
          configurable: true,
        }
      : undefined;
  },
};

const API_PARAM_READONLY = "API param is readOnly";
const API_PARAM_CREATEONLY = "API param is createOnly";

class APIParamError extends Error {}

export class APIParams {
  constructor(data, schema, originalParams) {
    this.data = data;
    this.schema = schema;
    this.originalParams = originalParams;
  }

  handleObject(data, schema, originalParams, required) {
    if (!schema.properties) {
      throw "Invalid object schema";
    }

    /*
      DEV NOTE:
      Fight the urge to loop over all of the properties
      in the schema for required and additionalProperties
      checks here. That is a job for the validation logic.
      Only work within the scope of the values passed in.
    */

    const retVal = {};
    Object.keys(data).map((property) => {
      // get the schema value if it exists
      const propSchema = schema.properties[property];
      if (propSchema) {
        const ogParams = originalParams ? originalParams[property] : undefined;
        const isRequired =
          schema.required && schema.required.includes(property);
        try {
          // pass along to next handler
          retVal[property] = this.route(
            data[property],
            propSchema,
            ogParams,
            isRequired
          );
        } catch (err) {
          if (err instanceof APIParamError) {
            console.debug(`Skipping ${property}`, err.message);
          } else {
            // if there is a non APIParamError, rethrow
            throw err;
          }
        }
      } else {
        // This value is not covered by the schema, so pass ti in as is
        // if it shouldnt be there, the validation step should complain
        retVal[property] = data[property];
      }
    });
    return retVal;
  }

  handleArray(data, schema, originalParams, required) {
    if (!schema.items) {
      throw "Invalid array schema";
    }

    const retVal = [];
    data.map((item, idx) => {
      const ogParams = originalParams ? originalParams[idx] : undefined;
      retVal.push(this.route(item, schema.items, ogParams, required));
    });

    return retVal;
  }

  handleValue(data, schema, originalParams, required) {
    if (schema?.items || schema?.properties) {
      throw "Invalid property schema";
    }

    // remove readOnly params (or reset if required and originals are available )
    // remove createOnly (if this.data.id) params (or reset if required and originals are available )

    // if createOnly and required, handle it

    if (schema.readOnly && !required) {
      throw new APIParamError(API_PARAM_READONLY);
    }

    if (schema.createOnly && this.data.id) {
      if (required) {
        if (originalParams) {
          return originalParams;
        } else {
          return data;
        }
      } else {
        throw new APIParamError(API_PARAM_CREATEONLY);
      }
    }

    return data;
  }

  route(data, schema, originalParams, required) {
    if (schema["$ref"]) {
      schema = Schema.getSchema(schema["$ref"]).schema;
    }

    switch (schema.type) {
      case "object":
        return this.handleObject(data, schema, originalParams, required);
      case "array":
        return this.handleArray(data, schema, originalParams, required);
      default:
        return this.handleValue(data, schema, originalParams, required);
    }
  }

  getAPIValues() {
    return this.route(this.data, this.schema, this.originalParams);
  }
}
export class BaseModel {
  updateOnSave = true;

  constructor(params, schema) {
    this.schema = schema;
    this._sideEffects = {};
    this.setDirty(false);
    this.error = false;
    this.params = {};
    this.fields = {};

    this.set(params || {}, false);
    // reset _original_params after set, because this is init
    this._original_params = {};
    return new Proxy(this, handler);
  }

  useEffect(func, keys) {
    keys.map((attr) => {
      if (this._sideEffects[attr] === undefined) {
        this._sideEffects[attr] = [];
      }
      this._sideEffects[attr] = func;
    });
  }

  setDirty(dirty) {
    if (this.dirty !== dirty && dirty === false) {
      this._original_params = {};
    }
    this.dirty = dirty;
    this._validAPIParams = {};
  }

  set(params, validate) {
    if (typeof params !== "object") {
      // todo: make this error better
      throw "Invalid params";
    }
    this.hash = params.__hash;
    delete params.__hash;

    const schema = Schema.getSchema(this.schema);
    const sideEffects = [];

    // Loop over params being set, and backup original values
    Object.keys(params).map((key) => {
      if (
        this._sideEffects[key] &&
        !sideEffects.includes(this._sideEffects[key])
      ) {
        sideEffects.push(this._sideEffects[key]);
      }
      if (params[key] !== undefined && params[key] !== null) {
        if (this._original_params[key] === undefined) {
          this._original_params[key] = this.params[key];
        }

        if (schema.schema.properties[key]) {
          // Warning: only mixes first level, will clobber deep objects
          switch (schema.schema.properties[key].type) {
            case "number":
              params[key] = parseInt(params[key]);
          }

          // only for nullable values that are an empty string
          // and need to be coerced into null to pass validation
          if (schema.schema.properties[key].nullable && params[key] === "")
            params[key] = null;
        }
      } else {
        delete params[key];
        delete this.params[key];
      }
    });

    this.params = { ...this.params, ...params };

    // todo: this should probably check that this.params actually changed
    this.setDirty(true);

    const valid = validate ? this.validate() : true;
    sideEffects.map((func) => func());

    return valid;
  }

  unset(key, validate) {
    if (typeof key !== "string") {
      // todo: make this error better
      throw "Invalid key";
    }
    delete this.params[key];
    return validate ? this.validate() : true;
  }

  reset(key) {
    if (key === undefined) {
      Object.keys(this._original_params).map((k) => {
        this.params[k] = this._original_params[k];
      });
      this.setDirty(false);
      this.error = false;
    } else if (this._original_params[key] !== undefined) {
      this.params = this._original_params[key];
      delete this._original_params[key];
      if (Object.keys(this._original_params).length === 0) {
        this.setDirty(false);
      }
    }
  }

  getDirtyFields() {
    return Object.keys(this._original_params);
  }

  getAPIValues() {
    return Object.keys(this._validAPIParams).length > 0
      ? this._validAPIParams
      : this.params;
  }

  validate() {
    const modelValidate = Schema.getSchema(this.schema);
    const licenseValidate = BluLicense.getSchema(this.schema);

    // if i remove this line, i get an error. I HAVE NO IDEA WHY PLZ SEND HELP
    const foo = () => {}; /* eslint-disable-line no-unused-vars */

    // check both the model schema, as well as the license schema, if applicable
    [modelValidate, licenseValidate].forEach((validate) => {
      if (validate) {
        const apiParamGenerator = new APIParams(
          this.params,
          validate.schema,
          this._original_params
        );
        this._validAPIParams = apiParamGenerator.getAPIValues();
      }
    });

    if (modelValidate(this._validAPIParams) === false) {
      return modelValidate.errors;
    } else if (
      licenseValidate &&
      licenseValidate(this._validAPIParams) === false
    ) {
      return licenseValidate.errors;
    } else {
      return true;
    }
  }

  validationMessages() {
    const regex = /\.([^[]+)(\[[\d]\])*/;
    const _valid = {};

    const validate = Schema.getSchema(this.schema);

    if (validate(this.params) !== true) {
      validate.errors.map((message) => {
        switch (message.keyword) {
          case "required":
            _valid[message.params.missingProperty] = "Required";
            break;
          case "additionalProperties":
            _valid[""] = message.message;
            break;
          default: {
            const re = regex.exec(message.dataPath);
            if (re) {
              if (re[2]) {
                // we can use this to flag which attribute is the problem
              }
              _valid[re[1]] = message.message;
            } else {
              console.warn("Unhandled validation message", message);
            }
          }
        }
      });
    }
    return _valid;
  }

  getSchema() {
    return Schema.getSchema(this.schema);
  }

  create() {
    const val = this.validate();
    if (val !== true) {
      if (val[0].message) throw `Error: ${val[0].message}`;
      else throw "There was an error.";
    }
    const api = new API();
    return api
      .post(this.schema, this.params)
      .then((response) => {
        if (this.updateOnSave) {
          this.set(response.data);
          this.setDirty(false);
          this.error = false;
        }
        return this;
      })
      .catch((error) => {
        this.error = error;

        // Quick fix put in place to remove displaying org names to users within the app (APP-1510)
        // Likely can be removed after permanent solution is put in place in the account service
        if (error.message.includes("Org with name")) {
          throw "There was a problem with your request. Please contact support with code 187a3b6H";
        }
        throw error.message;
      });
  }

  execute() {
    const val = this.validate();
    if (val !== true) {
      if (val[0].message) throw `Error: ${val[0].message}`;
      else throw "There was an error.";
    }
    const api = new API();
    return api
      .rpc(this.schema, this.params)
      .then((response) => {
        this.setDirty(false);
        this.error = false;
        return response.data;
      })
      .catch((error) => {
        this.error = error;
        throw error.message;
      });
  }

  read() {
    const api = new API();
    return api
      .get(this.schema, this.params)
      .then((response) => {
        this.set(response.data);
        this.setDirty(false);
        this.error = false;
        return this;
      })
      .catch((error) => {
        this.error = error;
        throw error;
      });
  }

  update() {
    const val = this.validate();
    if (val !== true) {
      if (val[0].message) {
        // leaving this here for easy debug
        // console.log(this, val)
        throw `Error: ${val[0].message}`;
      } else {
        throw "There was an error.";
      }
    }
    const api = new API();
    return api
      .put(this.schema, this.getAPIValues(), this.params.id)
      .then((response) => {
        if (this.updateOnSave) {
          this.set(response.data);
          this.setDirty(false);
          this.error = false;
        }
        return this;
      })
      .catch((error) => {
        this.error = error;
        throw error.message;
      });
  }

  delete() {
    const api = new API();
    // todo: if not this.id, throw error
    return api.delete(this.schema, this.id);
  }
}
