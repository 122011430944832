import config from "../../../../lib/config";

export const BLOCKING_BLOCK_TYPE_IP = 1;
export const BLOCKING_BLOCK_TYPE_DOMAIN = 2;

export const BLOCKING_BLOCK_TYPES = {
  1: "IP",
  2: "Domain",
};

export const BLOCKING_FOREVER_DISPLAY_STRING = "Forever";
export const BLOCKING_INACTIVE_DISPLAY_STRING = "Not activated";

export const BLOCKING_DEFAULT_DURATION_STRING = "7";
export const BLOCKING_BLOCK_MIN_DURATION = 0;
export const BLOCKING_CONFIG_MIN_DURATION = 1;
export const BLOCKING_MAX_DURATION = 365;

const GLOBAL_CONFIG_BLOCKING_OBJECT =
  config.i18n.languages.en.pages.settings.blocklists;

export const BLOCKING_AUTOMATED_LEGEND =
  GLOBAL_CONFIG_BLOCKING_OBJECT.blockingAutomatedLegend;

export const BLOCKING_BLOCKING_LEGEND =
  GLOBAL_CONFIG_BLOCKING_OBJECT.blockingBlockingLegend;

export const BLOCKING_COMMUNITY_LEGEND =
  GLOBAL_CONFIG_BLOCKING_OBJECT.blockingCommunityLegend;

export const BLOCKING_NEGATING_LEGEND =
  GLOBAL_CONFIG_BLOCKING_OBJECT.blockingNegatingLegend;
