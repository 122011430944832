// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { bool, func, node, string } from "prop-types";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import { ButtonStyle } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Button component
//
//  ** Remarks
//  Simple button component extended to simplify code across the app
//
//  ** Props
//  @param bgColor {string} - background color
//  @param bgColorActive {string} - background color when clicked
//  @param bgColorHover {string} - background color when hovered
//  @param border {string} - border color
//  @param borderHover {string} - border color on hover
//  @param boxShadow {string} - box shadow for hover
//  @param children {string} - button contents
//  @param color {string} - content color
//  @param colorActive {string} - content color when clicked
//  @param colorHover {string} - content color when hovered
//  @param disabled {string} - disable flag
//  @param fontSize {string} - button font size
//  @param fontWeight {string} - button font weight
//  @param margin {string} - button margin
//  @param onClick {string} - function tobe invoked on click
//  @param padding {string} - button padding
//  @param width {string} - button width
//
export const Button = ({
  bgColor,
  bgColorActive,
  bgColorHover,
  border,
  borderHover,
  children,
  color,
  colorActive,
  colorHover,
  disabled,
  fill,
  fillHover,
  fontSize,
  fontWeight,
  margin,
  onClick,
  padding,
  width,
}) => (
  <ButtonStyle
    bgColor={bgColor}
    bgColorActive={bgColorActive}
    bgColorHover={bgColorHover}
    border={border}
    borderHover={borderHover}
    color={color}
    colorActive={colorActive}
    colorHover={colorHover}
    fill={fill}
    fillHover={fillHover}
    fontSize={fontSize}
    fontWeight={fontWeight}
    disabled={disabled}
    margin={margin}
    onClick={onClick}
    padding={padding}
    width={width}
  >
    {children}
  </ButtonStyle>
);

// ** Proptypes ***********************
Button.propTypes = {
  bgColor: string,
  bgColorActive: string,
  bgColorHover: string,
  border: string,
  borderHover: string,
  children: node,
  color: string,
  colorActive: string,
  colorHover: string,
  disabled: bool,
  fill: string,
  fillHover: string,
  fontSize: string,
  fontWeight: string,
  margin: string,
  onClick: func,
  padding: string,
  width: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Link button *********************
export const LinkButton = withProps((props) => ({
  bgColor: "transparent",
  bgColorHover: "transparent",
  border: "none",
  borderHover: "none",
  children: props.children,
  color: COLOR.gray600,
  colorHover: COLOR.blumiraBlue,
  disabled: props.disabled,
  fill: COLOR.gray600,
  fillHover: COLOR.blumiraBlue,
  fontSize: props.fontSize || "11px",
  fontWeight: "500",
  margin: props.margin,
  padding: props.padding || "2px 0px",
  onClick: props.onClick,
}))(Button);

// ** Minimal button ******************
export const MinimalButton = withProps((props) => ({
  bgColor: "transparent",
  bgColorHover: "transparent",
  border: "none",
  borderHover: "none",
  children: props.children,
  color: props.color,
  colorHover: props.colorHover,
  disabled: props.disabled,
  fill: props.fill,
  fillHover: props.fillHover,
  fontWeight: "500",
  margin: props.margin,
  padding: "0px",
  onClick: props.onClick,
}))(Button);

// ** Primary button ******************
export const PrimaryButton = withProps((props) => ({
  bgColor: COLOR.blumiraBlue900,
  bgColorHover: COLOR.blumiraBlue,
  border: `1px solid ${COLOR.blumiraBlue900}`,
  borderHover: `1px solid ${COLOR.blumiraBlue}`,
  children: props.children,
  color: "white",
  colorHover: "white",
  disabled: props.disabled,
  fill: "white",
  fillHover: "white",
  margin: props.margin,
  padding: props.padding,
  onClick: props.onClick,
  width: props.width,
}))(Button);

// ** Light primary button ************
export const LightPrimaryButton = withProps((props) => ({
  bgColor: "#D6E8FA",
  bgColorHover: "#BDCFE1",
  border: "1px solid #D6E8FA",
  borderHover: "1px solid #BDCFE1",
  children: props.children,
  color: COLOR.blumiraBlue700,
  colorHover: COLOR.blumiraBlue700,
  disabled: props.disabled,
  fill: COLOR.blumiraBlue700,
  fillHover: COLOR.blumiraBlue700,
  fontSize: props.fontSize,
  margin: props.margin,
  padding: props.padding,
  onClick: props.onClick,
  width: props.width,
}))(Button);

// ** Secondary button ****************
export const SecondaryButton = withProps((props) => ({
  bgColor: "transparent",
  bgColorHover: "transparent",
  border: `1px solid ${COLOR.blumiraBlue900}`,
  borderHover: `1px solid ${COLOR.blumiraBlue}`,
  children: props.children,
  color: COLOR.blumiraBlue900,
  colorHover: COLOR.blumiraBlue,
  disabled: props.disabled,
  fill: COLOR.blumiraBlue900,
  fillHover: COLOR.blumiraBlue,
  margin: props.margin,
  padding: props.padding,
  onClick: props.onClick,
  width: props.width,
}))(Button);

// ** light secondary button ****************
export const LightSecondaryButton = withProps((props) => ({
  bgColor: "transparent",
  bgColorHover: "transparent",
  border: "1px solid rgba(105, 180, 255, 0.8)",
  borderHover: "1px solid rgba(105, 180, 255, 1)",
  children: props.children,
  color: "rgba(105, 180, 255, 0.8)",
  colorHover: "rgba(105, 180, 255, 1)",
  disabled: props.disabled,
  fill: "rgba(105, 180, 255, 0.8)",
  fillHover: "rgba(105, 180, 255, 1)",
  fontSize: props.fontSize,
  margin: props.margin,
  padding: props.padding,
  onClick: props.onClick,
  width: props.width,
}))(Button);

// ** View toggle button *******************
export const ViewToggleButton = withProps((props) => ({
  bgColor: props.selected ? "rgba(18, 77, 158, 0.1)" : "transparent",
  bgColorHover: "rgba(18, 77, 158, 0.1)",
  border: props.selected
    ? `1px solid ${COLOR.blumiraBlue900}`
    : "1px solid rgba(18, 77, 158, 0.6)",
  borderHover: `1px solid ${COLOR.blumiraBlue900}`,
  children: props.children,
  color: props.selected ? COLOR.blumiraBlue900 : "rgba(18, 77, 158, 0.6)",
  colorHover: COLOR.blumiraBlue900,
  disabled: props.disabled,
  fill: props.selected ? COLOR.blumiraBlue900 : "rgba(18, 77, 158, 0.6)",
  fillHover: COLOR.blumiraBlue900,
  fontSize: "11px",
  margin: props.margin,
  padding: "3px 6px 1px",
  onClick: props.onClick,
}))(Button);
