import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "reactstrap";

import "./TagBtn.scss";

/*
The "Label" button that opens/closes the Labels select dropdown.
*/
const TagBtn = (props) => {
  const [tooltipOpen, setTooltipState] = useState(false);
  const [targetId] = useState(`id_${props.tagId}`);

  const toggle = () => {
    setTooltipState(!tooltipOpen);
  };

  const handleDelete = () => {
    const { onDelete = () => {}, tagId } = props;

    onDelete(tagId);
  };

  const { classes = "", name = "", objType = "", removable = false } = props;

  return (
    <div className={`tag-btn ${classes}`}>
      <div className="tag-name">{name}</div>
      <div className="tag-delete">
        {removable && (
          <React.Fragment>
            <Button
              color="primary"
              size="sm"
              className={`delete-btn ${classes}`}
              onClick={handleDelete}
              id={targetId}
            >
              <i className="fas fa-times fa-xs" />
            </Button>
            <Tooltip
              className={`delete-btn-tooltip ${classes}`}
              placement="bottom"
              isOpen={tooltipOpen}
              target={targetId}
              toggle={toggle}
            >
              {`Remove tag ${name} from this ${objType}`}
            </Tooltip>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

TagBtn.propTypes = {
  classes: PropTypes.string,
  name: PropTypes.string,
  tagId: PropTypes.string.isRequired,
  objType: PropTypes.string,
  onDelete: PropTypes.func,
  removable: PropTypes.bool,
};

TagBtn.defaultProps = {
  classes: "",
  name: "",
  objType: "",
  onDelete: () => {},
  removable: false,
};

export default TagBtn;
