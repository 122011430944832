import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchLabels as fetchLabelsAction } from "../../../redux/actions/Tagging";

/*
Non-rendering component that fetches the labels for the current org
extracted from Redux location.payload.
Renders its own children.
*/
const LabelsFetch = (props) => {
  const { orgId, fetchLabels } = props;

  // Watch for orgId change
  useEffect(() => {
    fetchLabels(orgId);
  }, [orgId, fetchLabels]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

// PropTypes
LabelsFetch.propTypes = {
  orgId: PropTypes.string.isRequired,
  fetchLabels: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
};

// Redux
const mapStateToProps = ({ location }) => ({
  orgId: location.payload.orgId,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLabels: (orgId) => dispatch(fetchLabelsAction(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelsFetch);
