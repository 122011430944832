import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "DetailsDialog";
export const classes = {
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  closeButton: `${PREFIX}-closeButton`,
  tabs: `${PREFIX}-tabs`,
  tabPanel: `${PREFIX}-tabPanel`,
  detailsTable: `${PREFIX}-detailsTable`,
  priorityContainer: `${PREFIX}-priorityContainer`,
  priorityCircle: `${PREFIX}-priorityCircle`,
  radioGroup: `${PREFIX}-radioGroup`,
  radioSubgroup: `${PREFIX}-radioSubgroup`,
  radioSubgroupInactive: `${PREFIX}-radioSubgroupInactive`,
  infoIcon: `${PREFIX}-infoIcon`,
  toggleGroup: `${PREFIX}-toggleGroup`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    alignItems: "start",
  },
  "& .MuiDialog-paper": {
    marginTop: 64,
    maxHeight: "calc(100% - 96px)",
  },
  [`& .${classes.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 8,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.dialogContent}`]: {
    paddingTop: "0px !important",
  },
  [`& .${classes.tabs}`]: {
    marginLeft: 0,
    marginRight: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.tabPanel}`]: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`& .${classes.detailsTable}`]: {
    paddingLeft: 30,
    paddingRight: 30,
    "& td": {
      // Style for all columns
      verticalAlign: "top",
      borderBottomWidth: 0,
      padding: 0,
      paddingBottom: 15,
      fontSize: 14,
    },
    "& td:first-of-type": {
      // Style for left column (labels)
      xfontWeight: "bold",
      fontWeight: "900",
      paddingRight: 20,
      whiteSpace: "nowrap",
    },
  },
  [`& .${classes.priorityContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.priorityCircle}`]: {
    width: 15,
    height: 15,
    marginRight: 5,
    borderRadius: "50%",
  },
  [`& .${classes.radioGroup} label`]: {
    marginBottom: -8,
    marginRight: 10,
  },
  [`& .${classes.radioSubgroup}`]: {
    marginLeft: 40,
  },
  [`& .${classes.radioSubgroupInactive}`]: {
    display: "none",
  },
  [`& .${classes.infoIcon}`]: {
    marginTop: 8,
  },
  [`& .${classes.toggleGroup}`]: {
    marginRight: 10,
    "& button": {
      fontSize: "1rem",
      lineHeight: "inherit",
      paddingTop: 15,
      paddingBottom: 15,
      textTransform: "none",
      whiteSpace: "pre-wrap",
    },
    "& .Mui-selected": {
      color: theme.palette.text.primary,
    },
  },
}));
