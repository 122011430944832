// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { shape, string } from "prop-types";

// ** Assets **************************
import ICONS from "utils/assets/svgs";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Styles **************************
import { SvgStyle } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Svg component
//
//  ** Remarks
//  Simple svg component extended to simplify code across the app
//
//  ** Props
//  @param fill {string} - icon fill color
//  @param height {string} - icon height
//  @param icon {string} - icon constant name
//  @param margin {string} - icon margin
//  @param mobileHeight {string} - mobile view icon height
//
export const Svg = ({ fill, height, icon, margin, mobileHeight }) => (
  <SvgStyle
    fill={fill}
    height={height}
    margin={margin}
    mobileHeight={mobileHeight}
    viewBox={icon.viewBox}
  >
    <path d={icon.path} />
  </SvgStyle>
);

// ** Proptypes ***********************
Svg.propTypes = {
  fill: string,
  height: string,
  icon: shape({
    path: string,
    viewBox: string,
  }),
  margin: string,
  mobileHeight: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Default icon ********************
export const DefaultIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS[props.icon],
  margin: props.margin,
}))(Svg);

// ** Arrow icon **********************
export const ArrowIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.rightArrow,
  margin: props.margin,
}))(Svg);

// ** Calendar icon *******************
export const CalendarIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.calendar,
}))(Svg);

export const CheckmarkIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.checkmark,
  margin: props.margin,
}))(Svg);

// ** Code icon ***********************
export const CodeIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.code,
  margin: props.margin,
}))(Svg);

// ** "x" icon ************************
export const ExIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.ex,
  margin: props.margin,
}))(Svg);

// ** Eye icon ************************
export const EyeIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.eye,
  margin: props.margin,
}))(Svg);

// ** Plus icon ***********************
export const PlusIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.plus,
  margin: props.margin,
}))(Svg);

// ** Pencil icon *********************
export const PencilIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.pencil,
  margin: props.margin,
}))(Svg);

// ** Search icon *********************
export const SearchIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.search,
  margin: props.margin,
}))(Svg);

// ** Caret icon **********************
export const SelectCaretIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: "5px",
  icon: ICONS.caret,
  margin: props.margin,
}))(Svg);

// ** Tooltip icon ********************
export const TooltipIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.tooltip,
  margin: props.margin,
}))(Svg);

// ** Trash icon **********************
export const TrashIcon = withProps((props) => ({
  fill: props.fill || "inherit",
  height: props.height,
  icon: ICONS.trash,
  margin: props.margin,
}))(Svg);
