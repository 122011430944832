const columnSchema = {
  $id: "/column",
  title: "Column",
  type: "object",
  properties: {
    id: { type: "number", readOnly: true },
    core: { type: "boolean" },
    data_mode: { type: "string", enum: ["NULLABLE", "REPEATED"] },
    data_type: {
      type: "string",
      enum: ["STRING", "BOOL", "INT64", "TIMESTAMP", "FLOAT64"],
    },
    display_name: { type: "string" },
    name: { type: "string" },
    valid_operator_names: { type: "array" },
  },
  additionalProperties: true,
};

export default columnSchema;
