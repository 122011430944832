import React from "react";

import SimpleTable from "views/Components/SimpleTable";

import { LCGroup } from "lib/models/LCGroup";

import Schema from "lib/schema";

import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const LCTestPageView = (props) => {
  const lcAgent = Schema.getSchema("/lcagent").schema;
  const lcGroup = Schema.getSchema("/lcgroup").schema;

  const createGroup = () => {
    const model = new LCGroup();
    model.create();
  };

  return (
    <div>
      <Button onClick={createGroup}>Create Group</Button>
      <SimpleTable
        data={props.agents}
        title={lcAgent.title}
        columns={Object.keys(lcAgent.properties).map((k) => {
          return { title: k, field: k };
        })}
        isFetching={!props.ready}
      />
      <SimpleTable
        data={props.groups}
        title={lcGroup.title}
        columns={Object.keys(lcGroup.properties).map((k) => {
          return { title: k, field: k };
        })}
        isFetching={!props.ready}
        actions={[
          {
            icon: DeleteIcon,
            tooltip: "Delete Entry",
            onClick: (event, model) => {
              model.delete();
            },
          },
        ]}
      />
    </div>
  );
};

export default LCTestPageView;
//export default connect(mapStateToProps, mapDispatchToProps)(LCTestPageView);
