import React from "react";
import PropTypes from "prop-types";

import BluBarGraph, { BAR_GRAPH_MARGIN_LARGE_LEFT } from "./common/BluBarGraph";

/*
Constants
*/

const BAR_COLOR = "#e8c1a0"; // hsl(27, 61%, 77%) rgb(232, 193, 160)
const BAR_COLOR_DARKER_1_6 = "#836d5a"; // hsl(28, 19%, 43%) rgb(131, 109, 90)
const TOOLTIP_TEXT = "events";

/*
Main compenent
*/

const AttacksGraph = ({ labelField, rawData, valueField }) => (
  <BluBarGraph
    labelField={labelField}
    rawData={rawData}
    valueField={valueField}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    colors={[BAR_COLOR]}
    margin={BAR_GRAPH_MARGIN_LARGE_LEFT}
    tooltipText={TOOLTIP_TEXT}
  />
);

AttacksGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

AttacksGraph.defaultProps = {
  rawData: [],
};

export default AttacksGraph;
