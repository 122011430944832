const orgLogTypeSchema = {
  $id: "/orglogtype",
  title: "Organization Log Type",
  type: "object",
  properties: {
    orgId: { type: "string", format: "uuid", readOnly: true },
    id: { type: "number", readOnly: true },
    logTypeId: { type: "number" },
    logTypeName: { type: "string", readOnly: true },
    firstSeen: { type: "string", format: "date-time", readOnly: true },
    lastSeen: { type: "string", format: "date-time" },
    lastSeenHourCount: { type: "number" },
  },
  required: ["orgId", "logTypeName"],
  additionalProperties: false,
};

export default orgLogTypeSchema;
