import { SENSOR_STATUS_CONNECTED, TIME_INTERVAL_OPTIONS } from "./constants";

/*
Takes the sensor status number and returns a boolean showing
if the sensor is sending health messages or not.
*/
export const sensorConnected = (status) =>
  SENSOR_STATUS_CONNECTED.includes(status);

// Returns the option label corresponding to the option value
export const getLabel = (value, options = TIME_INTERVAL_OPTIONS) => {
  const option = options.find((elt) => elt.value === value) || {};
  return option.label || "";
};
