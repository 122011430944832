import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Loading from "../../Components/Loading";
import EmptyVisualization from "../../Components/EmptyVisualization";
import WorldChoroplethMap from "../../Components/Charts/WorldChoroplethMap";

const WorldMap = ({ mapData, handleRetry }) => {
  const worldMapHasError = !!mapData.error;

  const [visualizationData, setVisualizationData] = useState(
    mapData.visualizationData
  );
  const [isLoading, setIsLoading] = useState(
    mapData.loading || !mapData.firstLoadComplete
  );
  const [isEmpty, setIsEmpty] = useState(
    !mapData.loading && mapData.dataIsEmpty
  );

  useEffect(() => {
    setVisualizationData(mapData.visualizationData);
    // we should always be checking for dataIsEmpty
    let isEmpty =
      mapData.dataIsEmpty ||
      Object.keys(mapData.visualizationData).length === 0;
    setIsEmpty(isEmpty);
  }, [mapData.visualizationData, mapData.dataIsEmpty]);

  useEffect(() => {
    setIsLoading(mapData.loading);
  }, [mapData.loading]);

  return (
    <React.Fragment>
      {isLoading && <Loading />}

      {(isEmpty || worldMapHasError) && (
        <EmptyVisualization
          isError={worldMapHasError}
          handleRetry={handleRetry}
        >
          <WorldChoroplethMap visualizationData={visualizationData} />
        </EmptyVisualization>
      )}

      {!isLoading && !isEmpty && !worldMapHasError && (
        <WorldChoroplethMap visualizationData={visualizationData} />
      )}
    </React.Fragment>
  );
};

WorldMap.propTypes = {
  mapData: PropTypes.shape({}).isRequired,
  handleRetry: PropTypes.func.isRequired,
};

export default React.memo(WorldMap);
