import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { ErrorBanner } from "../../Components/ErrorBanner";
import RebuildSensor from "./RebuildSensor";
import { clearDetailErr, rebuildSensor } from "../../../redux/actions/Sensors";
import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import {
  currentSensorSelector,
  detailErrorSelector,
  sensorIdSelector,
} from "../../../selectors/sensorsSelectors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";

import "./SensorModal.scss";

/*
Main component
*/
const RebuildSensorModal = ({
  dispatchCloseModal,
  dispatchRebuildSensor,
  dismissRequestError,
  name,
  orgId,
  rebuilding,
  requestError,
  sensorId,
}) => {
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [noBroadcast, setNoBroadcast] = useState(false);

  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  useEffect(() => {
    dismissRequestError();

    setLoading(false);
    setFormErrorMessage("");
  }, [rebuilding, dismissRequestError]);

  useEffect(() => {
    const err = requestError || {};
    const { message = "" } = err;

    setRequestErrorMessage(message);

    setLoading(false);
  }, [requestError]);

  const handleChangeBroadcast = () => {
    setNoBroadcast(!noBroadcast);
  };

  const cancel = () => {
    dismissRequestError();

    dispatchCloseModal();
  };

  const saveChanges = () => {
    const data = {
      configInstall: { onlyEmailCreator: noBroadcast },
    };

    setLoading(true);

    dispatchRebuildSensor({
      data,
      orgId,
      sensorId,
    });
  };

  return (
    <Modal
      zIndex={1500}
      isOpen={!!rebuilding}
      className="sensor-modal"
      backdrop={null}
      size="lg"
    >
      <ModalHeader className="sensor-modal-header" toggle={cancel}>
        {`Rebuild Sensor ${name}`}
      </ModalHeader>

      <ModalBody className="align-middle rounded sensor-modal-body">
        <ErrorBanner
          message={requestErrorMessage}
          dismiss={dismissRequestError}
        />
        <ErrorBanner
          message={formErrorMessage}
          dismiss={() => {
            setFormErrorMessage("");
          }}
        />

        <RebuildSensor
          handleChangeBroadcast={handleChangeBroadcast}
          noBroadcast={noBroadcast}
        />
      </ModalBody>

      <ModalFooter className="sensor-modal-footer">
        {!loading && (
          <Button color="default" onClick={cancel}>
            Cancel
          </Button>
        )}

        <Button
          className="sensor-modal-loading-btn"
          color="primary"
          disabled={loading}
          onClick={saveChanges}
        >
          {!loading && <span>Rebuild</span>}
          {loading && <span>...</span>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// PropTypes
RebuildSensorModal.propTypes = {
  dismissRequestError: PropTypes.func.isRequired,
  dispatchCloseModal: PropTypes.func.isRequired,
  dispatchRebuildSensor: PropTypes.func.isRequired,
  name: PropTypes.string,
  orgId: PropTypes.string,
  rebuilding: PropTypes.bool,
  requestError: PropTypes.shape({}),
  sensorId: PropTypes.string.isRequired,
};

RebuildSensorModal.defaultProps = {
  name: "",
  orgId: "",
  rebuilding: false,
  requestError: {},
};

// Connect to Redux
const mapStateToProps = (state) => {
  const { location } = state;

  const sensorId = sensorIdSelector(state);
  const sensor = currentSensorSelector(state);
  const { name = "" } = sensor;
  const requestError = detailErrorSelector(state);

  const { query = {} } = location;
  const { rebuilding } = query;

  const orgId = orgIdSelector(state);

  return {
    name,
    orgId,
    rebuilding,
    requestError,
    sensorId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseModal: () => {
    dispatch(closeModal);
  },

  dispatchRebuildSensor: ({ data, orgId, sensorId }) =>
    dispatch(rebuildSensor({ orgId, sensorId, data })),

  dismissRequestError: () => {
    dispatch(clearDetailErr);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RebuildSensorModal);
