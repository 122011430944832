import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "InstallationPageView";

export const rootClasses = {
  accordion: `${PREFIX}-accordion`,
  agentSetupStepsContainer: `${PREFIX}-agentSetupStepsContainer`,
  title: `${PREFIX}-title`,
  agentSetupStepContainer: `${PREFIX}-agentSetupStepContainer`,
  circularNumberContainer: `${PREFIX}-circularNumberContainer`,
  stepTextOuterContainer: `${PREFIX}-stepTextOuterContainer`,
  stepTextInnerContainer: `${PREFIX}-stepTextInnerContainer`,
  installationKeySelect: `${PREFIX}-installationKeySelect`,
  textField: `${PREFIX}-textField`,
  copyButton: `${PREFIX}-copyButton`,
  tooltip: `${PREFIX}-tooltip`,
  readOnlyField: `${PREFIX}-readOnlyField`,
  errorBanner: `${PREFIX}-errorBanner`,
  errorAction: `${PREFIX}-errorAction`,
  cardContainer: `${PREFIX}-cardContainer`,
  addAgentButton: `${PREFIX}-addAgentButton`,
  description: `${PREFIX}-description`,
  requestMoreLink: `${PREFIX}-requestMoreLink`,
  requestMoreIcon: `${PREFIX}-requestMoreIcon`,
  platformFormControlLabel: `${PREFIX}-platformFormControlLabel`,
  stepTwoLink: `${PREFIX}-stepTwoLink`,
  clickHereLink: `${PREFIX}-clickHereLink`,
};

export const dialogClasses = {
  titleContainer: `${PREFIX}-titleContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  dialogAlert: `${PREFIX}-dialogAlert`,
  dialogActions: `${PREFIX}-dialogActions`,
  closeIcon: `${PREFIX}-closeIcon`,
  inputIcons: `${PREFIX}-inputIcons`,
  errorTextButton: `${PREFIX}-errorTextButton`,
  errorContainedButton: `${PREFIX}-errorContainedButton`,
  platformFormLabel: `${PREFIX}-platformFormLabel`,
  platformGroupWrapper: `${PREFIX}-platformGroupWrapper`,
  loadingDialogContainer: `${PREFIX}-loadingDialogContainer`,
  textField: `${PREFIX}-textField`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.accordion}`]: {
    marginBottom: theme.shape.padding,
    border: `1px solid lightgray`,
    borderRadius: "5px !important",
  },
  [`& .${rootClasses.agentSetupStepsContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${rootClasses.title}`]: {
    fontSize: 22,
    fontWeight: "bold",
  },
  [`& .${rootClasses.agentSetupStepContainer}`]: {
    display: "flex",
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.circularNumberContainer}`]: {
    width: 30,
    height: 30,
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.shape.padding,
    border: `2px solid ${theme.palette.text.primary}`,
    "& p": {
      margin: 0,
      fontWeight: "bold",
    },
  },
  [`& .${rootClasses.stepTextOuterContainer}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  [`& .${rootClasses.stepTextInnerContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& p": {
      marginBottom: 0,
    },
    "& a": {
      paddingLeft: 5,
      color: theme.palette.primary.main,
    },
  },
  [`& .${rootClasses.installationKeySelect}`]: {
    width: 250,
  },
  [`& .${rootClasses.textField}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${rootClasses.copyButton}`]: {
    width: 100,
    color: theme.palette.primary.main,
    margin: `${theme.shape.padding}px 0`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.tooltip}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.readOnlyField}`]: {
    "& .MuiInput-root": {
      border: 0,
      marginBottom: 20,
    },
  },
  [`& .${rootClasses.errorBanner}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.errorAction}`]: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    marginRight: theme.shape.padding,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.error.main,
    },
  },
  [`& .${rootClasses.cardContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    paddingBottom: theme.shape.padding,
  },
  [`& .${rootClasses.addAgentButton}`]: {
    height: 40,
    marginLeft: theme.shape.padding,
  },
  [`& .${rootClasses.description}`]: {
    fontSize: 14,
    marginLeft: 5,
    marginBottom: 0,
    "& a": {
      color: theme.palette.primary.main,
    },
    "& svg": {
      fontSize: 15,
    },
  },
  [`& .${rootClasses.requestMoreLink}`]: {
    textDecoration: "none",
    marginLeft: theme.shape.padding,
  },
  [`& .${rootClasses.requestMoreIcon}`]: {
    paddingLeft: 5,
  },
  [`& .${rootClasses.platformFormControlLabel}`]: {
    marginBottom: 0,
  },
  [`& .${rootClasses.stepTwoLink}`]: {
    marginLeft: 5,
  },
  [`& .${rootClasses.clickHereLink}`]: {
    textDecoration: "none",
  },
}));

export const InstallationDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.titleContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.closeIcon}`]: {
    marginRight: 5,
  },
  [`& .${dialogClasses.dialogContent}`]: {
    display: "flex",
    flexDirection: "column",
    padding: `20px ${theme.shape.padding}px 60px`,
  },
  [`& .${dialogClasses.dialogAlert}`]: {
    marginBottom: theme.shape.padding,
  },
  [`& .${dialogClasses.dialogActions}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: "space-between",
  },
  [`& .${dialogClasses.inputIcons}`]: {
    cursor: "pointer",
    color: theme.palette.text.primary,
    "& svg": {
      color: theme.palette.text.primary,
    },
    "&.Mui-disabled": {
      "& svg": {
        color: theme.palette.action.disabled,
      },
    },
  },
  [`& .${dialogClasses.errorTextButton}`]: {
    color: theme.palette.error.main,
  },
  [`& .${dialogClasses.errorContainedButton}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  [`& .${dialogClasses.platformFormControlLabel}`]: {
    marginBottom: 0,
  },
  [`& .${dialogClasses.platformFormLabel}`]: {
    marginBottom: 0,
    fontSize: "0.75rem",
    "&.Mui-focused": {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${dialogClasses.platformGroupWrapper}`]: {
    marginTop: 15,
  },
  [`& .${dialogClasses.loadingDialogContainer}`]: {
    height: 300,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& p": {
      marginTop: theme.shape.padding,
    },
  },
  [`& .${dialogClasses.textField}`]: {
    marginBottom: 20,
  },
}));
