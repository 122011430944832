import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";

/*
Constants and helpers
*/

export const RESOURCE_SELECT_OPTIONS = [
  { label: "IP Address", value: "ip" },
  { label: "Hostname", value: "hostname" },
  { label: "Username", value: "username" },
  { label: "Subnet", value: "subnet" },
];

const RESOURCE_SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#509BEF",
    border: "none",
    borderRadius: "4px",
    boxShadow: "none",
    color: "white",
    outline: "none",
    svg: {
      fill: "white",
    },
    "::before": {
      fontFamily: '"Font Awesome 5 Free"',
      fontWeight: "bolder",
      content: '"\f002"',
      margin: "0 10px",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white",
    color: "black",
  }),
  singleValue: (provided) => ({
    ...provided,
    border: "none",
    outline: "none",
    color: "white",
  }),
};

/*
Component
*/

const ResourceTypeSelect = (props) => {
  const { selectedOption, setSelectedOption } = props;

  const selectOnChange = (newOption) => {
    setSelectedOption(newOption);
  };

  return (
    <Select
      className="resources-search-select"
      onChange={selectOnChange}
      options={RESOURCE_SELECT_OPTIONS}
      styles={RESOURCE_SELECT_STYLES}
      value={selectedOption}
    />
  );
};

ResourceTypeSelect.propTypes = {
  selectedOption: PropTypes.shape({}).isRequired,
  setSelectedOption: PropTypes.func.isRequired,
};

export default ResourceTypeSelect;
