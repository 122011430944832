import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "stripe",
};

export class StripeAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  async createCustomer(data) {
    const params = ["customer"];
    const body = data;
    return super.post({ params }, body);
  }

  async getCheckoutSession(checkoutSessionId) {
    const params = ["checkout-session", checkoutSessionId];
    return super.get({ params });
  }

  async createCheckoutSession(body) {
    const params = ["checkout-session"];
    return super.post({ params }, body);
  }

  async createCustomerSession(body) {
    const params = ["customer-session"];
    return super.post({ params }, body);
  }

  async createSubscription(body) {
    const params = ["subscriptions"];
    return super.put({ params }, body);
  }

  async updateSubscription(body) {
    const params = ["subscriptions"];
    return super.post({ params }, body);
  }

  async getCustomer(customerId) {
    const params = ["customers", customerId];
    return super.get({ params });
  }

  async getProducts() {
    const params = ["products"];
    return super.get({ params });
  }

  async getPrices() {
    const params = ["prices"];
    return super.get({ params });
  }
}
