// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { useState, useEffect } from "react";
import { find } from "lodash";
import { arrayOf, bool, func, number, shape, string } from "prop-types";

// ** Components **********************
import { DefaultIcon } from "views/Components/Icon";

// ** Style ***************************
import {
  Actions,
  Data,
  ExpandableRowCell,
  GhostButton,
  Key,
  TableRow,
  TableRowCell,
  TableRowIcon,
  TableRowIndicator,
} from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Results table row
//
//  ** Remarks
//  Displays row data results
//
//  ** Props
//  blobCols {array} - list of remaining columns returned from search
//  border {bool} - indicates if row should have bottom border
//  defaultCols {array} - default column values
//  index {number} - data index in tableData set
//  recomputeRowHeight {func} - invoked on row toggle
//  rowData {object} - row key/value pairs
//  selectedColumns {array} - user selected column values
//
//  ** State
//  expanded {bool} - indicates if row is expanded
//

const Row = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [activeCols, setActiveCols] = useState(props.activeCols);

  useEffect(() => {
    setActiveCols(props.activeCols);
  }, [props.activeCols]);

  const addColumn = (col) => {
    const { activeCols } = props;
    const { updateActiveCols } = props;
    // avoid doubles
    if (activeCols.indexOf(col) === -1) {
      activeCols.push(col);
      updateActiveCols(activeCols);
    }
  };

  const toggleExpanded = () => {
    const { index, recomputeHeight } = props;
    setExpanded(!expanded, recomputeHeight(index));
  };
  const { blobCols, border, columns, rowData, specialColumns } = props;

  return (
    <TableRow border={border} onClick={toggleExpanded.bind(this)}>
      <TableRowIndicator>
        <TableRowIcon expanded={expanded}>
          <DefaultIcon height="6px" icon="rowArrow" />
        </TableRowIcon>
      </TableRowIndicator>
      {specialColumns.map((col) => (
        <TableRowCell key={col.name}>
          {rowData[col.name.toLowerCase()]}
        </TableRowCell>
      ))}
      {activeCols.map((key) => (
        <TableRowCell key={key}>{rowData[key]}</TableRowCell>
      ))}
      {blobCols.length > 0 && (
        <ExpandableRowCell expanded={expanded}>
          {blobCols.map((key) => (
            <Data key={key} expanded={expanded}>
              {expanded && (
                <Actions>
                  <GhostButton
                    onClick={() => addColumn(find(columns, ["name", key]).name)}
                  >
                    <DefaultIcon height="10px" icon="plus" />
                  </GhostButton>
                </Actions>
              )}
              <Key>
                {find(columns, ["name", key]) &&
                find(columns, ["name", key]).display_name
                  ? find(columns, ["name", key]).display_name
                  : key}
                :
              </Key>
              {typeof rowData[key] !== undefined ? rowData[key] : "null"}
            </Data>
          ))}
        </ExpandableRowCell>
      )}
    </TableRow>
  );
};

//  ** Prop types *********************
Row.propTypes = {
  blobCols: arrayOf(string).isRequired,
  border: bool,
  activeCols: arrayOf(string).isRequired,
  index: number.isRequired,
  selectedCols: arrayOf(string).isRequired,
  recomputeHeight: func.isRequired,
  rowData: shape().isRequired,
};

//  ** Default props ******************
Row.defaultProps = {
  border: true,
};

export default Row;
