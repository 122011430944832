import { BaseModel } from "./BaseModel";

export class Report extends BaseModel {
  schema = "/report";
  constructor(params) {
    return super(params, "/report");
  }

  fields = {
    displayTime: () => (this.timeOfDay ? `${this.timeOfDay}:00:00` : undefined),
  };

  associatedFields() {
    if (this.period === "last-7-days" || this.period === "previous-week") {
      this.params["dayOfMonth"] = null;
    } else if (
      this.period === "last-30-days" ||
      this.period === "previous-month"
    ) {
      this.params["dayOfWeek"] = null;
    } else {
      this.params["dayOfMonth"] = null;
      this.params["dayOfWeek"] = null;
    }
  }

  create() {
    this.associatedFields();
    return super.create();
  }

  update() {
    this.associatedFields();
    return super.update();
  }
}
