import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import PageHeader from "../Components/PageHeader";

import { getOrg } from "../../redux/actions/Org";
import { orgIdSelector } from "../../selectors/orgIdSelector";
import { orgLoadingSelector } from "../../selectors/orgSelectors";

import Pipeline from "./Support/Pipeline";

import "./PipelinesPageView.scss";

/*
Main component
*/

const PipelinesPageView = ({ dispatchGetOrg, loading, ready, orgId }) => {
  useEffect(() => {
    dispatchGetOrg(orgId);
  }, [dispatchGetOrg, orgId]);

  return (
    <div className="dashboard pipelines-page-view">
      <PageHeader>
        <Button
          className="refresh-btn"
          onClick={() => {
            dispatchGetOrg(orgId);
          }}
        >
          {ready && (
            <React.Fragment>
              <i className="fas fa-redo" />
              <span className="refresh-text-span">Refresh</span>
            </React.Fragment>
          )}

          {!ready && <span>...</span>}
        </Button>
      </PageHeader>

      <Pipeline />
    </div>
  );
};

// PropTypes
PipelinesPageView.propTypes = {
  dispatchGetOrg: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
};

// Connect to Redux
const mapStateToProps = (state) => ({
  loading: orgLoadingSelector(state),
  orgId: orgIdSelector(state),
});
const mapDispatchToProps = (dispatch) => ({
  dispatchGetOrg: (orgId) => {
    dispatch(getOrg(orgId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PipelinesPageView);
