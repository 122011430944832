// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Form
// *****************************************************************************

// ** Dropdown container **************
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 584px;
`;

// ** Inline display ******************
export const DisplayInline = styled.div`
  display: flex;
  flex-direction: row;
`;

// ** Overflow container **************
export const OverflowContainer = styled.div`
  box-shadow: ${(p) => (p.isMax ? "inset 0 -14px 12px -14px #aaaaaa" : "none")};
  height: 100%;
  margin: ${(p) => p.margin};
  max-height: ${(p) => p.maxHeight || "320px"};
  overflow-y: scroll;
`;

// ** Row container *******************
export const Row = styled.div`
  border-bottom: 1px solid transparent;
  color: ${COLOR.gray600};
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  transition: all 250ms;
  width: 100%;
`;

// ** Outer container *****************
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// *****************************************************************************
// Dropdown header
// *****************************************************************************

// ** Edit container ******************
export const EditContainer = styled.div`
  fill: ${COLOR.blumiraBlue};
  height: 100%;
  opacity: 0;
  transition: all 250ms;
  width: auto;
`;

// ** Header flex container ***********
export const FlexContainer = styled.div`
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

// ** Dropdown header container *******
export const Header = styled.div`
  align-items: flex-start;
  display: flex;
  fill: ${COLOR.gray1000};
  flex-direction: row;
  font-size: 13px;
  padding: 0px 0px 12px 0px;
  width: 584px;

  &:hover ${EditContainer} {
    opacity: 1;
  }
`;

// ** LogType button ******************
export const LogType = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  color: ${COLOR.gray600};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: space-between;
  margin-bottom: 12px;
  outline: none !important;
  padding: 8px;
  transition: all 250ms;
  width: 100%;

  &:hover {
    border-bottom: 1px dashed ${COLOR.gray100};
    color: ${COLOR.gray1000};
  }
`;
