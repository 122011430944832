import { entityFields } from "../defs";

export default (startTime, endTime, searchValue, queryFields) => {
  return {
    namespace: "/bq_summary",
    stale: 0,
    expire: 0,
    queryParams: [
      {
        refName: "endTime",
        field: "created",
        operator: "lt",
        value: endTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS999"),
      },
      {
        refName: "startTime",
        field: "created",
        operator: "gt",
        value: startTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS999"),
      },
      {
        field: "associationType",
        operator: "eq",
        negate: true,
        value: "",
      },
      [
        ...Object.keys(entityFields)
          .filter((field) => !!entityFields[field].validate(searchValue))
          .map((field) => {
            return {
              field,
              operator: entityFields[field].op,
              value: searchValue,
            };
          }),
        ...queryFields
          .filter((field) => !!field.validate(searchValue))
          .map((field) => {
            return {
              field: field.fieldName,
              operator: field.op,
              value: searchValue,
            };
          }),
      ],
    ],
    resultParams: {
      transform: [
        ...Object.keys(entityFields)
          .filter((field) => !!entityFields[field].validate(searchValue))
          .map((field) => {
            return {
              field: "total",
              negate: false,
              operator: `sum_if.${field}.${entityFields[field].op}.${searchValue}`,
              value: `sum_${field}`,
            };
          }),
        ...Object.keys(entityFields)
          .filter((field) => !!entityFields[field].validate(searchValue))
          .map((field) => {
            return {
              field: field,
              negate: false,
              operator: `count_if.${entityFields[field].op}.${searchValue}`,
              value: `count_${field}`,
            };
          }),
        {
          field: "total",
          negate: false,
          operator: "sum",
          value: "sum_total",
        },
        {
          field: "bytes",
          negate: false,
          operator: "sum",
          value: "sum_bytes",
        },
        {
          negate: false,
          operator: "count",
          value: "grouped_count",
        },
        {
          field: "maxTimestamp",
          negate: false,
          operator: "extract.YEAR",
          value: "year",
        },
        {
          field: "maxTimestamp",
          negate: false,
          operator: "extract.MONTH",
          value: "month",
        },
        {
          field: "maxTimestamp",
          negate: false,
          operator: "extract.DAY",
          value: "day",
        },
        {
          field: "maxTimestamp",
          negate: false,
          operator: "extract.HOUR",
          value: "hour",
        },
        {
          field: "minTimestamp",
          negate: false,
          operator: "min",
          value: "fromTime",
        },
        {
          field: "minTimestamp",
          negate: false,
          operator: "max",
          value: "toTime",
        },
      ],
      outputFields: [
        "sum_total",
        "sum_bytes",
        "logger",
        "associationType",
        ...Object.values(entityFields).map((f) => f.fieldName),
      ],
    },
  };
};
