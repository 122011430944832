import { ResourcesAPI } from "../../lib/api";
import { handleApiError } from "../utils/helpers";
import { graphParamsFromDelta } from "../../views/Pages/Resources/common/LogdevGraph";

import { INTERVAL_TYPE, receiveTimeInterval } from "./common/TimeInterval";
import { getGraphActions, getQueryGraph } from "./common/Graph";
import { fetchData } from "./common/GraphAndTable";
import { getQueryTable, getTableActions } from "./common/Table";

/*
Constants and helpers
*/

// 'graph...' and 'table...' actions will be imported from 'common'
export const LOGDEVS_GRAPH = "LOGDEVS_GRAPH";

export const LOGDEVS_OVERVIEW_RECEIVE_DATA = "LOGDEVS_OVERVIEW_RECEIVE_DATA";
export const LOGDEVS_OVERVIEW_RECEIVE_DATES = "LOGDEVS_OVERVIEW_RECEIVE_DATES";
export const LOGDEVS_OVERVIEW_RECEIVE_ERROR = "LOGDEVS_OVERVIEW_RECEIVE_ERROR";

export const LOGDEVS_RESET = "LOGDEVS_RESET";

export const LOGDEVS_SELECTED_INTERVAL_RECEIVE =
  "LOGDEVS_SELECTED_INTERVAL_RECEIVE";

export const LOGDEVS_TABLE = "LOGDEVS_TABLE";

const API = {
  resources: new ResourcesAPI(),
};

export const DEFAULT_SELECTED_INTERVAL = {
  dateRange: {},
  intervalType: INTERVAL_TYPE.RELATIVE_START,
  relativeEnd: "",
  relativeStart: "24hr",
};
export const DEFAULT_INTERVAL_TYPE = DEFAULT_SELECTED_INTERVAL.intervalType;
export const DEFAULT_RELATIVE_START = DEFAULT_SELECTED_INTERVAL.relativeStart;

/*
Actions
*/

/* Graph */

// Create the "receive" actions
const { receiveGraphAll, receiveError: receiveGraphError } = getGraphActions(
  LOGDEVS_GRAPH,
  graphParamsFromDelta
);

// Action that fetches and stores all graph data
export const fetchLogdevsGraph = fetchData({
  apiCall: API.resources.fetchLogdevsGraph.bind(API.resources),
  getQuery: getQueryGraph,
  nameStr: "graph",
  receive: receiveGraphAll,
  receiveError: receiveGraphError,
});

/* Overview */

// 'overviewByOrg'
const receiveOverview = ({ data, orgId }) => ({
  type: LOGDEVS_OVERVIEW_RECEIVE_DATA,
  payload: {
    data,
    insertId: orgId,
  },
});

// 'overviewDates'
const receiveOverviewDates = ({ data, orgId }) => ({
  type: LOGDEVS_OVERVIEW_RECEIVE_DATES,
  payload: {
    data,
    insertId: orgId,
  },
});

// 'overviewError'
const receiveOverviewError = (data) => ({
  type: LOGDEVS_OVERVIEW_RECEIVE_ERROR,
  payload: {
    error: data,
  },
});

// Fetches and stores logging devices overview for the org
export const fetchLogdevsOverview = (orgId) => (dispatch) => {
  // Clear error
  dispatch(receiveOverviewError(null));

  // Fetch data
  API.resources
    .fetchLogdevsOverview(orgId)
    .then((response) => {
      const { data: respData = {} } = response;
      const { data, dates } = respData;

      dispatch(receiveOverviewError(null));
      dispatch(
        receiveOverview({
          data,
          orgId,
        })
      );
      dispatch(
        receiveOverviewDates({
          data: dates,
          orgId,
        })
      );
    })
    .catch((err) => {
      const message = "Unable to fetch logdevs overview.";

      dispatch(
        handleApiError({
          message,
          err,
          onError: receiveOverviewError,
        })
      );
    });
};

/* Table */

// Create the "receive" table actions
const { receiveTableAll, receiveError: receiveTableError } =
  getTableActions(LOGDEVS_TABLE);

// Action that fetches and stores all graph data
export const fetchLogdevsTable = fetchData({
  apiCall: API.resources.fetchLogdevsTable.bind(API.resources),
  getQuery: getQueryTable,
  nameStr: "table",
  receive: receiveTableAll,
  receiveError: receiveTableError,
});

// Clear all errors
export const clearErr = (dispatch) => {
  dispatch(receiveGraphError(null));
  dispatch(receiveOverviewError(null));
  dispatch(receiveTableError(null));
};

/*
Selected Time Interval
*/

export const {
  receiveDateRange,
  receiveRelativeInterval,
  receiveRelativeStart,
  receiveSelectedInterval,
} = receiveTimeInterval(LOGDEVS_SELECTED_INTERVAL_RECEIVE);
