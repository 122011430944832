import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { get } from "lodash";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { CircularProgress } from "@mui/material";

import { ErrorBanner } from "../../Components/ErrorBanner";
import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import {
  clearDetailErr,
  uninstallModule,
  updateModule,
  migrateModule,
} from "../../../redux/actions/Sensors";
import ModuleDetail from "./ModuleDetail";
import {
  getCleanMeta,
  getMetaItems,
  getVersionsMeta,
  validateMetaData,
} from "./metaHelpers";
import {
  ACTION_UPGRADE,
  ACTION_REMOVE,
  ACTION_UPDATE_META,
  ACTION_ROTATE_SECRETS,
  ACTION_MIGRATE,
  isEmpty,
} from "./moduleHelpers";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  sensorIdSelector,
  currentSensorSelector,
} from "../../../selectors/sensorsSelectors";
import Logger from "../../../lib/logger";

import "./SensorModal.scss";

const logger = Logger("ModuleDetailModal");

/*
Helpers
*/

const getModuleMetaFromSpec = (module) => {
  const { spec: moduleSpec = {} } = module || {};
  const meta = moduleSpec.meta || [];

  return meta;
};

const onRemove = ({
  dispatchUninstallModule,

  orgId,
  personId,
  sensorId,
  sensorModuleUUID,

  setLoading,
}) => {
  setLoading(true);

  dispatchUninstallModule({
    orgId,
    personId,
    sensorId,
    sensorModuleUUID,
  });
};

const onRotateSecrets = ({
  dispatchUpdateModule,

  orgId,
  personId,
  sensorId,
  sensorModuleUUID,

  setLoading,
}) => {
  const data = {
    rotate_secrets: true,
  };

  setLoading(true);

  dispatchUpdateModule({
    orgId,
    personId,
    sensorId,
    sensorModuleUUID,

    data,
  });
};

const onUpdate = ({
  dispatchUpdateModule,

  metaPrevious,
  metaValues,
  sensorModule,
  moduleId,

  orgId,
  personId,
  sensorId,
  sensorModuleUUID,

  setFormErrorMessage,
  setLoading,
}) => {
  const metaCurrent = Object.values(metaValues);

  const metaData = getCleanMeta({
    metaValues,
    sensorModule,
  });

  if (
    Object.keys(metaData).length < 1 &&
    (isEmpty(moduleId) || moduleId === sensorModule.module_id)
  ) {
    return;
  }

  const errMsg = validateMetaData({
    metaCurrent,
    metaData,
    metaPrevious,
    setError: setFormErrorMessage,
  });

  if (errMsg) {
    return;
  }

  const data = Object.keys(metaData).reduce((accum, key) => {
    accum[key] = metaData[key].value;
    return accum;
  }, {});

  if (moduleId) {
    data.module_id = moduleId;
  }

  setLoading(true);

  dispatchUpdateModule({
    orgId,
    personId,
    sensorId,
    sensorModuleUUID,
    data,
  });
};

const onMigrateModule = ({
  dispatchMigrateModule,

  orgId,
  personId,
  sensorId,
  sensorModuleUUID,

  setLoading,
}) => {
  setLoading(true);

  dispatchMigrateModule({
    orgId,
    personId,
    sensorId,
    sensorModuleUUID,
  });
};

/*
Main  component
*/

const ModuleDetailModal = ({
  availableModules,
  detailId,
  dismissRequestError,
  dispatchCloseModal,
  dispatchUninstallModule,
  dispatchUpdateModule,
  dispatchMigrateModule,
  module,
  orgId,
  personId,
  requestError,
  sensorId,
  isAhiBannerPresent,
}) => {
  const [installedMeta, setInstalledMeta] = useState([]);

  const [actionValue, setActionValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const [metaValues, setMetaValues] = useState(null);

  const [versionValue, setVersionValue] = useState(null);
  const [versionsMeta, setVersionsMeta] = useState(null);

  const [formErrorMessage, setFormErrorMessage] = useState("");

  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  useEffect(() => {
    setLoading(true);
    if (availableModules && module?.uuid) {
      try {
        setInstalledMeta(getModuleMetaFromSpec(module));

        setMetaValues(
          getMetaItems({
            meta: getModuleMetaFromSpec(module),
            sensorModule: module,
          })
        );

        setActionValue(null);
        setVersionValue(null);
        setVersionsMeta(getVersionsMeta({ module, availableModules }));

        dismissRequestError();
        setLoading(false);
        setFormErrorMessage("");
      } catch (e) {
        setLoading(false);
      }
    }
  }, [availableModules, dismissRequestError, module, module.uuid]);

  useEffect(() => {
    setVersionValue(null);
  }, [actionValue]);

  useEffect(() => {
    if (versionValue && versionValue.id) {
      // Set meta values for the selected version

      const newMeta = versionsMeta[versionValue.id];

      setMetaValues(
        getMetaItems({
          meta: newMeta,
          sensorModule: module,
        })
      );
    } else {
      // Set meta values for the installed version

      setMetaValues(
        getMetaItems({
          meta: installedMeta,
          sensorModule: module,
        })
      );
    }
  }, [versionValue, installedMeta, versionsMeta, module]);

  useEffect(() => {
    // Set 'requestErrorMessage' and clear 'loading'

    const err = requestError || {};
    const { message = "" } = err;

    setRequestErrorMessage(message);

    setLoading(false);
  }, [requestError]);

  const handleChangeAction = (newValue) => {
    setActionValue(newValue);
  };

  const handleChangeVersion = (newValue) => {
    setVersionValue(newValue);
  };

  const cancel = () => {
    dismissRequestError();
    setFormErrorMessage("");
    setLoading(false);

    dispatchCloseModal();
  };

  const saveChanges = () => {
    if (!actionValue) {
      return;
    }

    if (formErrorMessage) {
      return;
    }

    const { value: action = "" } = actionValue;

    const { uuid: sensorModuleUUID = "" } = module;

    switch (action) {
      case ACTION_REMOVE: {
        onRemove({
          dispatchUninstallModule,

          orgId,
          personId,
          sensorId,
          sensorModuleUUID,

          setLoading,
        });

        return;
      }

      case ACTION_ROTATE_SECRETS: {
        onRotateSecrets({
          dispatchUpdateModule,

          moduleId: module.module_id,
          orgId,
          personId,
          sensorId,
          sensorModuleUUID,

          setLoading,
        });

        return;
      }

      case ACTION_UPDATE_META: {
        onUpdate({
          dispatchUpdateModule,

          metaPrevious: installedMeta,
          metaValues,
          sensorModule: module,

          orgId,
          personId,
          sensorId,
          sensorModuleUUID,

          setFormErrorMessage,
          setLoading,
        });

        return;
      }

      case ACTION_UPGRADE: {
        onUpdate({
          dispatchUpdateModule,

          metaPrevious: installedMeta,
          metaValues,
          sensorModule: module,
          moduleId: versionValue.id,

          orgId,
          personId,
          sensorId,
          sensorModuleUUID,

          setFormErrorMessage,
          setLoading,
        });

        return;
      }

      case ACTION_MIGRATE: {
        onMigrateModule({
          dispatchMigrateModule,

          moduleId: module.module_id,
          orgId,
          personId,
          sensorId,
          sensorModuleUUID,

          setLoading,
        });

        return;
      }

      default: {
        const message =
          `Unknown module edit action: ${actionValue.value}.` +
          "Ignoring action and closing the modal.";
        const err = new Error(message);
        logger.error(err);

        cancel();
      }
    }
  };

  const disabled = loading || !!formErrorMessage;

  return (
    <Modal
      isOpen={!!detailId}
      className="sensor-modal"
      backdrop={null}
      size="lg"
      style={{ paddingTop: isAhiBannerPresent ? 80 : 50 }}
    >
      <ModalHeader className="sensor-modal-header" toggle={cancel}>
        Edit Module
      </ModalHeader>

      {loading ? (
        <ModalBody className="sensor-modal-body">
          <div className={"sensor-modal-loading-container"}>
            <p>Loading Module Details</p>
            <CircularProgress color={"primary"} />
          </div>
        </ModalBody>
      ) : (
        <ModalBody className="sensor-modal-body">
          <ErrorBanner
            message={requestErrorMessage}
            dismiss={dismissRequestError}
          />
          <ErrorBanner
            message={formErrorMessage}
            dismiss={() => {
              setFormErrorMessage("");
            }}
          />

          <ModuleDetail
            availableModules={availableModules}
            meta={installedMeta}
            module={module}
            actionValue={actionValue}
            handleChangeAction={handleChangeAction}
            metaValues={metaValues}
            setMetaValues={setMetaValues}
            versionValue={versionValue}
            handleChangeVersion={handleChangeVersion}
            setFormErrorMessage={setFormErrorMessage}
            setRequestErrorMessage={setRequestErrorMessage}
          />
        </ModalBody>
      )}

      <ModalFooter className="sensor-modal-footer">
        {!loading && (
          <Button color="danger" onClick={cancel}>
            Cancel
          </Button>
        )}

        <Button
          className="sensor-modal-loading-btn"
          color="primary"
          onClick={saveChanges}
          disabled={disabled}
        >
          {!loading && <span>Apply</span>}
          {loading && <span>...</span>}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// PropTypes
ModuleDetailModal.propTypes = {
  availableModules: PropTypes.arrayOf(PropTypes.shape({})),
  detailId: PropTypes.string,
  dispatchCloseModal: PropTypes.func.isRequired,
  dismissRequestError: PropTypes.func.isRequired,
  dispatchUninstallModule: PropTypes.func.isRequired,
  dispatchUpdateModule: PropTypes.func.isRequired,
  dispatchMigrateModule: PropTypes.func.isRequired,
  module: PropTypes.shape({}),
  orgId: PropTypes.string,
  personId: PropTypes.string,
  requestError: PropTypes.shape({}),
  sensorId: PropTypes.string,
};

ModuleDetailModal.defaultProps = {
  availableModules: [],
  detailId: "",
  module: {},
  orgId: "",
  personId: "",
  requestError: {},
  sensorId: "",
};

// Connect to Redux
const mapStateToProps = (state) => {
  const { location, sensors, settings } = state;

  const { query = {} } = location;
  const { detailId = "" } = query;

  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);
  const sensor = currentSensorSelector(state);

  const { sensorDetail = {} } = sensors;
  const { error: requestError } = sensorDetail;

  const { availableModules = [], installedModules: modules = [] } = sensor;
  const module = modules.find((elt) => elt.uuid === detailId) || {};

  const { currentUserId: personId = "" } = settings;

  const userOrgs = get(state, ["session", "settings", "userOrgs"], []);
  const currentOrg = userOrgs.find(({ id }) => id === orgId);
  const currentOrgLicenseFeatures = get(
    currentOrg,
    ["config", "license_features"],
    []
  );
  const isAhiBannerPresent = !!currentOrgLicenseFeatures.find(
    (feature) => feature === "AUTOBOT"
  );

  return {
    availableModules,
    detailId,
    module,
    orgId,
    personId,
    requestError,
    sensorId,
    isAhiBannerPresent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dismissRequestError: () => {
    dispatch(clearDetailErr);
  },

  dispatchCloseModal: () => {
    dispatch(closeModal);
  },

  dispatchUninstallModule: ({ orgId, personId, sensorId, sensorModuleUUID }) =>
    dispatch(
      uninstallModule({
        orgId,
        personId,
        sensorId,
        sensorModuleUUID,
      })
    ),

  dispatchUpdateModule: ({
    orgId,
    personId,
    sensorId,
    sensorModuleUUID,
    data,
  }) =>
    dispatch(
      updateModule({
        orgId,
        personId,
        sensorId,
        sensorModuleUUID,
        data,
      })
    ),

  dispatchMigrateModule: ({ orgId, personId, sensorId, sensorModuleUUID }) =>
    dispatch(
      migrateModule({
        orgId,
        personId,
        sensorId,
        sensorModuleUUID,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDetailModal);
