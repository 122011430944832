import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import EgressTrafficGraph from "./EgressTrafficGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "egressTraffic";

export const GRAPH_TITLE =
  "Total Allowed Egress Traffic by Destination Country (logarithmic scale)";

/*
Main component
*/

const EgressTraffic = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={EgressTrafficGraph}
    labelField="country"
    logScale
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
    valueField="totalBytes"
  />
);

EgressTraffic.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

EgressTraffic.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default EgressTraffic;
