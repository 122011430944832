import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material";

const PREFIX = "BlumiraAgentActivationBanner";
export const classes = {
  activationBannerContainer: `${PREFIX}-activationBannerContainer`,
  alertTitle: `${PREFIX}-alertTitle`,
  leftMarginLink: `${PREFIX}-leftMarginLink`,
  linkContainer: `${PREFIX}-linkContainer`,
  activateButton: `${PREFIX}-activateButton`,
};

export const StyledAlert = styled(Alert)(({ theme }) => ({
  [`&.${classes.activationBannerContainer}`]: {
    "& a": {
      fontWeight: "bold",
      color: theme.palette.primary.main,
      "& :hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  [`& .${classes.alertTitle}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.leftMarginLink}`]: {
    marginLeft: 10,
  },
  [`& .${classes.linkContainer}`]: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.shape.padding,
  },
  [`& .${classes.activateButton}`]: {
    width: 250,
    marginRight: theme.shape.padding,
  },
}));
