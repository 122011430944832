import Ajv from "ajv";
import { getState } from "../../index";
import { WILDCARD_ORG_ID } from "../api/API";

const _baseLicense = {
  tags: [],
  api: [
    { rule: "settings.read", allow: true },
    { rule: "me.*", allow: true },
    { rule: "license/active.*", allow: true },
  ],
  page: [{ rule: "getstarted/.*", allow: true }],
  schema: [],
};

const _advanced = require("@blumira/blu_constants/data/constant/license/ADVANCED.json");

const BluLicense = class {
  constructor() {
    this._activeLicense = { ..._baseLicense };
  }

  isSet() {
    return this._activeLicense;
  }

  set orgId(orgId) {
    this._orgId = orgId;
  }

  set activeLicense(activeLicense) {
    if (this._orgId === WILDCARD_ORG_ID) {
      this._activeLicense = _advanced;
    } else {
      this._activeLicense = activeLicense;
    }
  }

  getLicenseDef() {
    return this._activeLicense;
  }

  hasFeature(feature) {
    const state = getState();

    const orgId = state.location.payload.orgId;
    const currentOrg = state.settings.currentUserOrgs.find(
      (o) => o["id"] === orgId
    );

    if (currentOrg?.config?.license_features) {
      return currentOrg.config.license_features.includes(feature);
    } else {
      return false;
    }
  }

  isAllowedPage(rule) {
    const matchRule = this._activeLicense.page.find(
      (k) => !!rule.match(k.rule)
    );
    return !!matchRule && matchRule.allow;
  }

  isAllowedAPI(rule) {
    const matchRule = this._activeLicense.api.find((k) => !!rule.match(k.rule));
    return !!matchRule && matchRule.allow;
  }

  isTagged(tag) {
    return this._activeLicense.tags.includes(tag);
  }

  getSchema(rule) {
    const schema = this._activeLicense?.schema || [];
    const matchRule = schema.find((k) => !!rule.match(k.rule));
    if (matchRule) {
      const ajv = new Ajv();
      return ajv.compile({ ...matchRule, additionalProperties: true });
    } else {
      return false;
    }
  }
};

export default new BluLicense();
