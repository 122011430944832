import React from "react";
import PropTypes from "prop-types";

import DataGrid from "../../Components/DataGrid";

const COLUMN_KEYS = ["median", "min", "mean", "max"];

const KEY_TO_LABEL = {
  cpus: "CPUs",
  eps: "EPS",
  systemLag: "Lag",
  watermarkAge: "Age",

  backlogEvents: "Backlog Events",
  backlogOldest: "Backlog Oldest",
  pulledEPS: "Pulled EPS",
  numBytes: "Stored Size",
  numRows: "Stored Rows",
};

export const getRows = (obj) => {
  const rows = Object.keys(obj).map((key) => {
    const values = obj[key] || {};

    const { mean = "", median = "", min = "", max = "" } = values;

    const row = {
      key: <strong>{KEY_TO_LABEL[key] || ""}</strong>,
      keyAccessor: key,

      median: <strong>{median}</strong>,
      medianAccessor: median,

      min: <span>{min}</span>,
      minAccessor: min,

      mean: <span>{mean}</span>,
      meanAccessor: mean,

      max: <span>{max}</span>,
      maxAccessor: max,
    };

    return row;
  });

  return rows;
};

const VALUE_COLUMNS = COLUMN_KEYS.map((key) => {
  const column = {
    Header: key.slice(0, 1).toUpperCase() + key.slice(1),
    accessor: `${key}Accessor`,
    filterable: false,
    sortable: false,
    Cell: (row) => row.original[key],
    maxWidth: 70,
    minWidth: 70,
  };

  return column;
});

const COLUMNS = [
  {
    Header: "Name",
    accessor: "keyAccessor",
    filterable: false,
    Cell: (row) => row.original.key,
    maxWidth: 120,
    minWidth: 120,
  },
].concat(VALUE_COLUMNS);

const MetricsTable = (props) => {
  const { data = [] } = props;

  return (
    <DataGrid
      defaultPageSize={20}
      data={data}
      columns={COLUMNS}
      showPagination={false}
    />
  );
};

MetricsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

MetricsTable.defaultProps = {
  data: [],
};

export default MetricsTable;
