import React from "react";
import PropTypes from "prop-types";

import BluBarGraph from "./common/BluBarGraph";
import { formatBarLabel } from "./common/securityHelpers";

/*
Constants
*/

const BAR_COLOR = "#61cdbb"; // hsl(170, 52%, 59%) rgb(97, 205, 187)
const BAR_COLOR_DARKER_1_6 = "#37746a"; // hsl(170, 36%, 34%) rgb(55, 116, 106)
const TOOLTIP_TEXT = "bytes";

/*
Main compenent
*/

const IngressTrafficGraph = ({ labelField, rawData, valueField }) => (
  <BluBarGraph
    labelField={labelField}
    rawData={rawData}
    valueField={valueField}
    colors={[BAR_COLOR]}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    formatBarLabel={formatBarLabel}
    tooltipText={TOOLTIP_TEXT}
  />
);

IngressTrafficGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

IngressTrafficGraph.defaultProps = {
  rawData: [],
};

export default IngressTrafficGraph;
