import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import {
  ModuleStatus,
  ModulePorts,
  ModuleConfig,
  ModuleName,
  ModuleVersion,
} from "./ModulesFields";
import ModulesTable from "./ModulesTable";
import { sensorConnected } from "./common/helpers";

import "./Modules.scss";

/*
Helpers
*/

const getModuleStatus = ({ sensorModule, sensorStatus }) => {
  const connected = sensorConnected(sensorStatus);
  const status = connected ? sensorModule.status : undefined;

  return status;
};

// Takes as argument the 'installedModules' from the Sensor Detail and returns
// the ModulesTable rows.
const getModules = ({ modules, sensorStatus, gotoModule }) => {
  const handleEdit = (installId) => (evt) => {
    evt.stopPropagation();
    gotoModule(installId);
  };

  // 'Accessor' fields are added to enable sorting by the column
  const displayModules = modules
    .map((sensorModule) => {
      const installId = sensorModule.uuid;
      const { spec: moduleSpec = {} } = sensorModule;
      const { name: moduleName = sensorModule.name } = moduleSpec;

      return {
        moduleId: sensorModule.module_id,
        installId,

        status: (
          <ModuleStatus
            status={getModuleStatus({ sensorModule, sensorStatus })}
          />
        ),
        statusAcessor: sensorModule.status,

        name: <ModuleName name={moduleName} />,
        nameAccessor: sensorModule.name || "",

        version: <ModuleVersion tag={sensorModule.tag} />,
        versionAccessor: sensorModule.tag,

        ports: <ModulePorts module={sensorModule} />,

        config: <ModuleConfig module={sensorModule} />,

        edit: (
          <div>
            <Button color="primary" onClick={handleEdit(installId)}>
              Edit
            </Button>
          </div>
        ),
      };
    })
    .sort((a, b) => a.nameAccessor.localeCompare(b.nameAccessor));

  return displayModules;
};

/*
Component
*/

const Modules = (props) => {
  const { modules, sensorStatus, gotoModule } = props;

  const displayModules = getModules({ modules, sensorStatus, gotoModule });

  return (
    <div className="sensor-modules-container">
      <ModulesTable data={displayModules} gotoModule={gotoModule} />
    </div>
  );
};

Modules.propTypes = {
  gotoModule: PropTypes.func,
  modules: PropTypes.arrayOf(PropTypes.shape({})),
  sensorStatus: PropTypes.number,
};

Modules.defaultProps = {
  gotoModule: () => {},
  modules: [],
  sensorStatus: null,
};

const mapStateToProps = ({ location, sensors }) => {
  const { payload = {} } = location;
  const { id1: sensorId } = payload;

  const { sensorDetail = {} } = sensors;
  const { byId: sensorDetailById = {} } = sensorDetail;
  const sensor = sensorDetailById[sensorId] || {};

  const modules = sensor.installedModules || [];
  const sensorStatus = sensor.status;

  return {
    modules,
    sensorStatus,
  };
};

export default connect(mapStateToProps, null)(Modules);
