import { connect } from "react-redux";

import InitialTosView from "./InitialTosView";
import { updateUser } from "../../redux/actions/Users";
import { logoutFromBlumira } from "../../redux/actions/Page";

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateUser: ({ orgId, personId, data }) =>
    dispatch(updateUser({ orgId, personId, data, editingSelf: true })),

  dispatchRedirectToDefault: ({ orgId }) =>
    dispatch({
      type: "PAGE",
      payload: { orgId, toplevel: "getstarted", secondlevel: "welcome" },
    }),

  dispatchRedirectToLogin: () => dispatch(logoutFromBlumira),
});

const mapStateToProps = (state) => {
  const { currentUserOrgs } = state.settings;

  if (state.session.settings == null) {
    return {
      acceptedTos: null,
      orgId: null,
      personId: null,
    };
  }
  const userData = state.session.settings.user;
  const acceptedTos = userData.acceptedTos || null;

  // The default org is the first one
  const orgId =
    currentUserOrgs && currentUserOrgs.length > 0
      ? currentUserOrgs[0].id
      : false;

  return {
    acceptedTos,
    orgId,
    personId: state.session.settings.user.id,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialTosView);
