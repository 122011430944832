import moment from "moment-timezone";

import { RESET } from "../actions/common/GraphAndTable";
import { insertData } from "../utils/helpers";
import { getReducer as getReducerHelper } from "./helpers";

// The Store's initial state object
export const getInititalState = (storeToAction) =>
  Object.keys(storeToAction).reduce((accum, key) => {
    accum[key] = {};
    return accum;
  }, {});

// Takes action type prefix and action type suffix,
// joins the two (with an underscore) and returns the result.
const getActionType = (key, actionTypePrefix = "", storeToAction) => {
  if (actionTypePrefix) {
    return `${actionTypePrefix}_${storeToAction[key]}`;
  }

  return storeToAction[key];
};

// Returns a reducer that responds to action of type
// getActionType(STORE_TO_ACTION[initialStateKey], actionTypePrefix)
// by inserting the data in its store (by default, using 'insertData')
export const getReducer = ({
  actionTypePrefix,
  initialState,
  initialStateKey,
  insert = insertData,
  storeToAction,
}) => {
  const actionTypeReceive = getActionType(
    initialStateKey,
    actionTypePrefix,
    storeToAction
  );

  // ***** change this, may be use different prefix?
  const actionTypeReset = getActionType(RESET, actionTypePrefix, storeToAction);

  const reducer = getReducerHelper({
    actionTypeReceive,
    actionTypeReset,
    initialState,
    insert,
  });

  return reducer;
};

/*
Selectors
*/

export const getGraphData = (intervalCode, logdevs, orgId) => {
  const { graphByIdOrOrg = {} } = logdevs;
  const { [orgId]: orgData = {} } = graphByIdOrOrg;
  const { [intervalCode]: intervalData = [] } = orgData;

  return intervalData;
};

export const getGraphDates = (intervalCode, graphStore, orgId) => {
  const { graphDates = {} } = graphStore;
  const { [orgId]: orgData = {} } = graphDates;
  const { [intervalCode]: intervalData = {} } = orgData;

  return intervalData;
};

export const getGraphEntries1 =
  ({ labelKey1 = "name", labelKey2 }) =>
  (rawOneGraphData) => {
    const {
      groupedHistogram = [],
      [labelKey1]: label1 = "",
      [labelKey2]: label2 = "",
    } = rawOneGraphData || {};

    const label = label2 ? `${label1}/${label2}` : `${label1}`;

    const data = groupedHistogram.map((entry) => {
      const { start, value: y } = entry;

      const x = new Date(moment.utc(start));

      return {
        x,
        y,
      };
    });

    const graph = {
      id: label,
      data,
    };

    return graph;
  };

// Given a raw graph data array, returns the graph ID and entries
export const getGraphEntries =
  ({ labelKey1 = "name", labelKey2 }) =>
  (data) => {
    const graphs = data
      .slice(0, 10)
      .map(getGraphEntries1({ labelKey1, labelKey2 }))
      .reverse();

    return graphs;
  };

export const getGraphParams = (intervalCode, logdevs, orgId) => {
  const { graphParams = {} } = logdevs;
  const { [orgId]: orgGraphParams = {} } = graphParams;
  const { [intervalCode]: intervalGraphParams = {} } = orgGraphParams;

  return intervalGraphParams;
};

export const getTableData = (intervalCode, logdevs, orgId) => {
  const { tableByOrg = {} } = logdevs;
  const { [orgId]: orgLogdevs = {} } = tableByOrg;
  const { [intervalCode]: intervalLogdevs = [] } = orgLogdevs;

  return intervalLogdevs;
};

export const getTableDates = (intervalCode, tableStore, orgId) => {
  const { tableDates = {} } = tableStore;
  const { [orgId]: orgData = {} } = tableDates;
  const { [intervalCode]: intervalData = {} } = orgData;

  return intervalData;
};

export const getTitle = (key) => (graphData) => {
  const { [key]: title = "" } = graphData;

  return title;
};
