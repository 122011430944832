import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import D3Funnel from "d3-funnel";

import "./SecurityFunnel.scss";

/*
Constants and helpers
*/

const KEY_ORDER = [
  "logsTotal",
  "blockedEvents",
  "findings",
  "threatsAndSuspects",
];

const LABEL_MAP = {
  logsTotal: "Log Events",
  blockedEvents: "Blocked Events",
  findings: "Findings",
  threatsAndSuspects: "Threats and Suspects",
};

const BACKGROUND_COLORS = {
  logsTotal: "#bedef4",
  blockedEvents: "#7cbde9",
  findings: "#2592da",
  threatsAndSuspects: "#1a6699",
};

const WINDOW_WIDTH_BREAKPOINTS = [900, 1800];

const GRAPH_DIMENSIONS = [
  { width: 375, height: 250 },
  { width: 480, height: 320 },
  { width: 675, height: 450 },
];

const BLOCK_MIN_HEIGHTS = [
  { minHeight: 45 },
  { minHeight: 60 },
  { minHeight: 80 },
];

const FONT_SIZES = [
  { fontSize: ".75rem" },
  { fontSize: ".85rem" },
  { fontSize: ".95rem" },
];

const LABEL_COLORS = {
  logsTotal: "#13263a",
  blockedEvents: "#13263a",
  findings: "#13263a",
  threatsAndSuspects: "#ffffff",
};

const formatLabel = (label, value) =>
  `${LABEL_MAP[label]}: ${value.toLocaleString()}`;

const OPTIONS = {
  block: {
    dynamicHeight: true,
    fill: {
      type: "gradient",
    },
    highlight: true,
    minHeight: 80,
  },
  chart: {
    width: 600,
    height: 400,
    bottomPinch: 1,
    bottomWidth: 0.46,
  },
  label: {
    fill: "white",
    fontSize: ".7rem",
    format: formatLabel,
  },
};

export const getData = (obj) => {
  const data = KEY_ORDER.map((key) => ({
    backgroundColor: BACKGROUND_COLORS[key],
    labelColor: LABEL_COLORS[key],
    label: key,
    value: Number(obj[key]) || 0,
  }));

  return data;
};

const getOptions = (index = 1) => {
  const options = { ...OPTIONS };

  options.block = {
    ...options.block,
    ...BLOCK_MIN_HEIGHTS[index],
  };

  options.chart = {
    ...options.chart,
    ...GRAPH_DIMENSIONS[index],
  };

  options.label = {
    ...options.label,
    ...FONT_SIZES[index],
  };

  return options;
};

const drawGraph = ({ data, index }) => {
  const chart = new D3Funnel("#blu-funnel");
  const options = getOptions(index);

  chart.draw(data, options);
};

const getWindowWidthIndex = () => {
  let index;

  if (window.innerWidth < WINDOW_WIDTH_BREAKPOINTS[0]) {
    index = 0;
  } else if (window.innerWidth > WINDOW_WIDTH_BREAKPOINTS[1]) {
    index = 2;
  } else {
    index = 1;
  }

  return index;
};

const ONLOAD_WINDOW_WIDTH_INDEX = getWindowWidthIndex(window.innerWidth);

/*
Main component
*/

const SecurityFunnel = ({ data }) => {
  const [index, setIndex] = useState(ONLOAD_WINDOW_WIDTH_INDEX);

  const updateIndex = useCallback(() => {
    const nextIndex = getWindowWidthIndex(window.innerWidth);

    if (nextIndex === index) {
      return;
    }

    setIndex(nextIndex);
  }, [index]);

  useEffect(() => {
    window.addEventListener("resize", updateIndex);

    return () => {
      window.removeEventListener("resize", updateIndex);
    };
  }, [updateIndex]);

  useEffect(() => {
    drawGraph({ data, index });
  }, [data, index]);

  return <div className="security-funnel" id="blu-funnel" />;
};

SecurityFunnel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

SecurityFunnel.defaultProps = {
  data: [],
};

export default SecurityFunnel;
