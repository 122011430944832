// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Icon container ******************
export const IconContainer = styled.div`
  fill: ${COLOR.blumiraBlue600};
  left: 8px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
`;

// ** Input container *****************
export const InputContainer = styled.div`
  border-bottom: 1px solid ${COLOR.blumiraBlue600};
  margin-bottom: 16px;
  position: relative;
  width: 100%;
`;

// ** List container ******************
export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
`;

// ** List item container *************
export const ListItem = styled.li`
  border-bottom: 1px solid ${COLOR.gray200};
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: flex-start;
  margin-bottom: 16px;
  padding-bottom: 16px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 160px;
  }
`;

// ** Modal list container ************
export const ModalListContainer = styled.div`
  z-index: 1500;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 120px auto;
  max-width: 600px;
  width: 100%;
`;
