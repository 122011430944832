import { createSelector } from "reselect";

import { sensorTimeIntervalSelector as timeIntervalSelector } from "./timeIntervalSelectors";
import { orgIdSelector } from "./orgIdSelector";

const selectLocation = (state) => state.location;
const selectSensors = (state) => state.sensors;
const selectSupport = (state) => (state.sensors || {}).support;

/*
Org sensors selector
*/

const getOrgSensors = (orgId, sensorsStore = {}) => {
  const { sensors } = sensorsStore;
  const { byId = {}, byOrg = {} } = sensors;

  const orgSensorIDs = byOrg[orgId] || [];
  const orgSensors = orgSensorIDs
    .map((sensorId) => byId[sensorId])
    .sort((a, b) => a.name.localeCompare(b.name));

  return orgSensors;
};

export const currentOrgSensorsSelector = createSelector(
  [orgIdSelector, selectSensors],
  getOrgSensors
);

/*
Sensor ID selector
*/

const getSensorId = (location) => {
  const { payload = {} } = location;
  const { id1: sensorId } = payload;

  return sensorId;
};

export const sensorIdSelector = createSelector([selectLocation], getSensorId);

/*
Current sensor selector ('sensorDetail' selector)
*/

const getCurrentSensor = (sensorId, sensors) => {
  const { sensorDetail = {} } = sensors;
  const { byId: sensorDetailById = {} } = sensorDetail;

  const sensor = sensorDetailById[sensorId] || {};

  return sensor;
};

export const currentSensorSelector = createSelector(
  [sensorIdSelector, selectSensors],
  getCurrentSensor
);

/*
detailLoading selector
*/

const getDetailLoading = (sensors, sensorId) => {
  const { detailLoading: detailLoadingAll = {} } = sensors;

  const { byId: detailLoadingById = {} } = detailLoadingAll;
  const detailLoading = detailLoadingById[sensorId] || false;

  return detailLoading;
};

export const detailLoadingSelector = createSelector(
  [selectSensors, sensorIdSelector],
  getDetailLoading
);

/*
detailError selector
*/

const getDetailError = (sensors) => {
  const { sensorDetail = {} } = sensors;
  const { error = null } = sensorDetail;

  return error;
};

export const detailErrorSelector = createSelector(
  [selectSensors],
  getDetailError
);

/*
Sensor devices selector
*/

const getSensorDevices = (sensorId, sensors) => {
  const { devices: allDevices = {} } = sensors;
  const { bySensor: devicesBySensor = {} } = allDevices;

  const devices = devicesBySensor[sensorId] || [];

  return devices;
};

export const sensorDevicesSelector = createSelector(
  [sensorIdSelector, selectSensors],
  getSensorDevices
);

/*
devicesError selector
*/

const getDevicesError = (sensors) => {
  const { devices = {} } = sensors;
  const { error = null } = devices;

  return error;
};

export const devicesErrorSelector = createSelector(
  [selectSensors],
  getDevicesError
);

/*
Auxiliary selector that returns the total log count for the devices
*/

const getSensorDevicesLogsTotal = (devices) => {
  const total = (devices || []).reduce((accum, elt) => {
    accum += elt.count || 0;

    return accum;
  }, 0);

  return total;
};

export const sensorDevicesLogsTotalSelector = createSelector(
  [sensorDevicesSelector],
  getSensorDevicesLogsTotal
);

/*
Sensor detail time window selector
*/

const getSensorTimeWindow = (sensorId, sensors) => {
  const { devices: allDevices = {} } = sensors;
  const { timeWindowBySensor = {} } = allDevices;

  const timeWindow = timeWindowBySensor[sensorId] || {};

  return timeWindow;
};

export const sensorTimeWindowSelector = createSelector(
  [sensorIdSelector, selectSensors],
  getSensorTimeWindow
);

/*
Support error selector
*/

const getSupportError = (support) => {
  const { error = null } = support;

  return error;
};

export const sensorSupportErrorSelector = createSelector(
  [selectSupport],
  getSupportError
);

/*
Latest parsed time selector
*/

const getSupportItem =
  (fieldName, defaultValue = "") =>
  (sensorId, support, timeInterval) => {
    const { [fieldName]: all = {} } = support;
    const forSensor = all[sensorId] || {};
    const item = forSensor[timeInterval] || defaultValue;

    return item;
  };

const getLatestParsedTime = getSupportItem("latestParsedTimeBySensor");

export const sensorLatestParsedTimeSelector = createSelector(
  [sensorIdSelector, selectSupport, timeIntervalSelector],
  getLatestParsedTime
);

/*
Latest timestamp selector
*/

const getLatestTimestamp = getSupportItem("latestTimestampBySensor");

export const sensorLatestTimestampSelector = createSelector(
  [sensorIdSelector, selectSupport, timeIntervalSelector],
  getLatestTimestamp
);

/*
More info selector
*/

const getMoreInfo = (sensor) => {
  const {
    loggerRecentPublishedEps,
    loggerRecentPublishedTimestamp,
    loggerRecentReadInputLine,
  } = sensor;

  return {
    loggerRecentPublishedEps: loggerRecentPublishedEps || "",
    loggerRecentPublishedTimestamp: loggerRecentPublishedTimestamp || "",
    loggerRecentReadInputLine: loggerRecentReadInputLine || "",
  };
};

export const sensorMoreInfoSelector = createSelector(
  [currentSensorSelector],
  getMoreInfo
);

/*
Parsing errors selector
*/

const getParsingErrors = getSupportItem("parsingErrorsBySensor", []);

export const sensorParsingErrorsSelector = createSelector(
  [sensorIdSelector, selectSupport, timeIntervalSelector],
  getParsingErrors
);
