export const integrationSchemaSchema = {
  $id: "/integrationSchema",
  title: "IntegrationSchema",
  type: "object",
  properties: {
    integrationType: { type: "string", readOnly: true },
    configurationSchema: { type: "object", readOnly: true },
    secretSchema: { type: "object", readOnly: true },
  },
  required: ["integrationType", "configurationSchema", "secretSchema"],
  additionalProperties: false,
};
