import { Report } from "../../lib/models/Report";
import Request from "../../lib/api/Request";

import Logger from "../../lib/logger";
import { handleApiError } from "../utils/helpers";

export const CREATE_REPORT = "DATA_QUERY.CREATE_REPORT";
export const RECEIVE_REPORT = "DATA_QUERY.RECEIVE_REPORT";
export const REQUEST_REPORTS = "DATA_QUERY.REQUEST_REPORTS";
export const RECEIVE_REPORTS = "DATA_QUERY.RECEIVE_REPORTS";
export const RECEIVE_REPORTS_ERROR = "DATA_QUERY.RECEIVE_REPORTS_ERROR";
export const TOGGLE_MESSAGE = "DATA_QUERY.TOGGLE_MESSAGE";
export const TOGGLE_IS_DELETING = "DATA_QUERY.TOGGLE_IS_DELETING";
export const REMOVE_REPORT = "DATA_QUERY.REMOVE_REPORT";
export const CHANGE_REPORT_FORM = "DATA_QUERY.CHANGE_REPORT_FORM";

const logger = Logger("Reporting");

/*
Constants and helpers
*/

const reportSaving = () => ({ type: CREATE_REPORT });

/*
Actions
*/

const receiveReport = (report) => ({
  type: RECEIVE_REPORT,
  report,
});

const receiveReports = (reports) => ({
  type: RECEIVE_REPORTS,
  reports,
});

export const receiveReportsError = ({ dataSource, error }) => ({
  type: RECEIVE_REPORTS_ERROR,
  payload: {
    dataSource,
    error,
  },
});

const removeReport = ({ reportId, dataSource }) => ({
  type: REMOVE_REPORT,
  reportId,
  dataSource,
});

const flashMessage = (message, value) => (dispatch) => {
  dispatch({
    type: TOGGLE_MESSAGE,
    message,
    value,
  });

  setTimeout(
    () =>
      dispatch({
        type: TOGGLE_MESSAGE,
        message,
        value: false,
      }),
    3000
  );
};

export const deleteConfirm = (dispatch) =>
  dispatch({
    type: TOGGLE_IS_DELETING,
  });

export const changeFormValue =
  ({ fieldName, fieldValue }) =>
  (dispatch) =>
    dispatch({
      type: CHANGE_REPORT_FORM,
      fieldName,
      fieldValue,
    });

export const fetchReportsForOrg =
  ({ orgId, dataSource }) =>
  (dispatch) => {
    dispatch({ type: REQUEST_REPORTS });
    dispatch(receiveReportsError({ dataSource, error: null }));
    const queryParams = [
      { field: "orgId", value: orgId },
      { field: "dataSource", value: dataSource },
    ];
    const request = new Request("/report", queryParams);
    request
      .get()
      .then((reports) => {
        dispatch(receiveReportsError({ dataSource, error: null }));

        dispatch(receiveReports(reports));
      })
      .catch((err) => {
        const message = "Unable to fetch reports.";

        dispatch(
          handleApiError({
            message,
            err,
            onError: (error) => receiveReportsError({ dataSource, error }),
          })
        );
      });
  };

export const createReportForDataQuery = (report) => (dispatch) => {
  dispatch(receiveReportsError({ dataSource: report.dataSource, error: null }));

  dispatch(reportSaving);

  const valid = report.validate();
  if (valid !== true) {
    dispatch(
      handleApiError({
        message: "Invalid report",
        err: {},
        onError: (error) =>
          receiveReportsError({ dataSource: report.dataSource, valid }),
      })
    );
    return;
  }

  report
    .create()
    .then((report) => {
      dispatch(
        receiveReportsError({ dataSource: report.dataSource, error: null })
      );

      dispatch(receiveReport(report));

      dispatch(flashMessage("createdReport", report.name));
    })
    .catch((err) => {
      const message = "Unable to create report.";

      dispatch(
        handleApiError({
          message,
          err,
          onError: (error) =>
            receiveReportsError({ dataSource: report.dataSource, error }),
        })
      );
    });
};

export const updateReportForDataQuery = (report) => (dispatch) => {
  dispatch(receiveReportsError({ dataSource: report.dataSource, error: null }));

  dispatch(reportSaving);

  const valid = report.validate();
  if (valid !== true) {
    dispatch(
      handleApiError({
        message: "Invalid report",
        err: {},
        onError: (error) =>
          receiveReportsError({ dataSource: report.dataSource, valid }),
      })
    );
    return;
  }

  report
    .update()
    .then((report) => {
      dispatch(
        receiveReportsError({ dataSource: report.dataSource, error: null })
      );

      dispatch(receiveReport(report));

      dispatch(flashMessage("updatedReport", report.name));
    })
    .catch((err) => {
      const message = "Unable to update report.";

      dispatch(
        handleApiError({
          message,
          err,
          onError: (error) =>
            receiveReportsError({ dataSource: report.dataSource, error }),
        })
      );
    });
};

export const deleteReport = (reportId, dataSource) => (dispatch) => {
  dispatch(receiveReportsError({ dataSource, error: null }));

  const report = new Report({ id: reportId });

  report
    .delete()
    .then((response) => {
      dispatch(receiveReportsError({ dataSource, error: null }));

      if (response.success) {
        dispatch(removeReport({ reportId, dataSource }));
      } else {
        logger.warn("Delete report response is falsy.");
      }
    })
    .catch((err) => {
      const message = "Unable to delete report.";

      dispatch(
        handleApiError({
          message,
          err,
          onError: (error) => receiveReportsError({ dataSource, error }),
        })
      );
    });
};
