// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { arrayOf, func, shape, string } from "prop-types";

// ** Components **********************
import { SelectedItem } from "views/Components/SelectedItem";
import ListItem from "./ListItem";

// ** Styles **************************
import { ListContainer } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  List reorder component
//
//  ** Remarks
//  Component allows reordering of lists (drang and drop)
//
//  ** Props
//
//  ** Methods
//  onChange - passes along expected arguments to onChange prop
//
class ListReorder extends Component {
  // Invoked on checkbox change
  moveCard(dragIndex, hoverIndex) {
    const { children, onChange } = this.props;
    const dragCard = children[dragIndex];

    children.splice(dragIndex, 1);
    children.splice(hoverIndex, 0, dragCard);

    const reordered = children.map((child) => child);
    onChange(reordered);
  }

  render() {
    const { children, maxWidth, onAddOrRemove, deleteItem } = this.props;

    return (
      <ListContainer>
        {children.length > 0 &&
          children.map((item, i) => (
            <ListItem
              key={item.id}
              id={item.id}
              index={i}
              moveCard={this.moveCard.bind(this)}
            >
              <SelectedItem
                key={item.id}
                maxWidth={maxWidth}
                onClick={() => {
                  onAddOrRemove("selectedColumns", item);
                  deleteItem("dataChanged", true);
                }}
                title={
                  item.display_name
                    ? item.display_name.replace("Finding", "")
                    : item.name
                }
              />
            </ListItem>
          ))}
      </ListContainer>
    );
  }
}

// ** Proptypes ***********************
ListReorder.propTypes = {
  children: arrayOf(shape()),
  maxWidth: string,
  onAddOrRemove: func,
  onChange: func,
  deleteItem: func,
};

export default ListReorder;
