import { handleApiError } from "../../utils/helpers";
import { getIntervalCode, INTERVAL_TYPE } from "./TimeInterval";

// Action types (can be prefixed using 'actionTypePrefix')
export const RECEIVE_DATA = "RECEIVE_DATA";
export const RECEIVE_DATES = "RECEIVE_DATES";
export const RECEIVE_ERROR = "RECEIVE_ERROR";
export const RECEIVE_PARAMS = "RECEIVE_PARAMS";
export const RESET = "RESET";

// A helper used to create fetchLogdevsGraph and fetchLogdevsTable actions
export const fetchData =
  ({
    apiCall = () => {},
    clear = () => () => {},
    getQuery = () => {},
    nameStr = "",
    receive = () => () => {},
    receiveError = () => {},
  }) =>
  ({ limit, objectId, insertId, orgId, page, page_size, selectedInterval }) =>
  (dispatch) => {
    // Clear stale data
    dispatch(
      clear({
        insertId,
        selectedInterval,
      })
    );

    // Clear error
    dispatch(receiveError(null));

    const query = getQuery({
      limit,
      page,
      page_size,
      orgId,
      selectedInterval,
    });

    // Fetch data
    apiCall({
      objectId,
      orgId,
      query,
    })
      .then(({ data: respData }) => {
        const { data, dates = {} } = respData;

        dispatch(receiveError(null));
        dispatch(
          receive({
            data,
            dates,
            insertId,
            selectedInterval,
          })
        );
      })
      .catch((err) => {
        const message = `Unable to fetch ${nameStr} data.`;

        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveError,
          })
        );
      });
  };

// A helper used to create clearStaleGraph and clearStaleTable
// that clear current 'byOrg.custom' data  iff the Selected Interval is
// custom (since the new custom interval is probably different from
// the current one)
export const clearStale =
  ({ receiveData = () => {}, receiveDates = () => {} }) =>
  ({ insertId, selectedInterval }) =>
  (dispatch) => {
    const { intervalType = INTERVAL_TYPE.CUSTOM } = selectedInterval;

    if (intervalType === INTERVAL_TYPE.CUSTOM) {
      dispatch(
        receiveData({
          data: [],
          insertId,
          selectedInterval,
        })
      );
      dispatch(
        receiveDates({
          data: {},
          insertId,
          selectedInterval,
        })
      );
    }
  };

// '...ByOrg'
export const receiveData =
  (actionTypePrefix) =>
  ({ data, insertId, selectedInterval }) => {
    const timeInterval = getIntervalCode(selectedInterval);

    return {
      type: `${actionTypePrefix}_${RECEIVE_DATA}`,
      payload: {
        data,
        insertId,
        timeInterval,
      },
    };
  };

// '...Dates'
export const receiveDates =
  (actionTypePrefix) =>
  ({ data, insertId, selectedInterval }) => {
    const timeInterval = getIntervalCode(selectedInterval);

    return {
      type: `${actionTypePrefix}_${RECEIVE_DATES}`,
      payload: {
        data,
        insertId,
        timeInterval,
      },
    };
  };

// '...Error'
export const receiveError = (actionTypePrefix) => (error) => ({
  type: `${actionTypePrefix}_${RECEIVE_ERROR}`,
  payload: {
    error,
  },
});

// 'graphParams'
export const receiveParams =
  (actionTypePrefix) =>
  ({ data, insertId, selectedInterval }) => {
    const timeInterval = getIntervalCode(selectedInterval);

    return {
      type: `${actionTypePrefix}_${RECEIVE_PARAMS}`,
      payload: {
        data,
        insertId,
        timeInterval,
      },
    };
  };
