// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import Moment from "moment-timezone";
import { bool, func, oneOfType, shape, string } from "prop-types";

// ** Components **********************
import { PrimaryButton } from "views/Components/Button";
import Header from "./Header";
import DateSelection from "./DateSelection";
import SuggestedDates from "./SuggestedDates";
import TimeSelection from "./TimeSelection";

// ** Styles **************************
import {
  ContainerLayout,
  DisplayContainer,
  DisplayLayout,
  FlexColumnLayout,
  FlexRowLayout,
  HeaderLayout,
  ColumnContainer,
  SectionHeader,
  SpaceBetween,
  SubmitRow,
  TimeToggle,
  ToggleContainer,
} from "./style";

const getDateRange = ({ key, rangeEnd, rangeStart, value }) => {
  let dateRange;

  switch (key) {
    case "rangeEnd": {
      dateRange = {
        rangeEnd: value,
        rangeStart,
      };
      break;
    }

    case "rangeStart": {
      dateRange = {
        rangeEnd,
        rangeStart: value,
      };
      break;
    }

    default: {
      dateRange = {
        rangeEnd,
        rangeStart,
      };
    }
  }

  return dateRange;
};

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Datepicker component
//
//  ** Remarks
//  Simple datepicker component extended to simplify code across the app
//
//  ** Props
//  @param onChange {func} - func invoked on date change
//  @param range {object} - range data
//  @param timeFormat {string} - local / utc format flag
//  @param width {string} - width of the component
//
class Datepicker extends Component {
  constructor(props) {
    super(props);

    const { user = {} } = props;
    const { lastLogin = {} } = user;
    const { timezone = "" } = lastLogin;

    this.state = {
      currentMonth: Moment.tz(
        timezone ? timezone : Moment.tz.guess(true)
      ).toISOString(),
      hovered: null,
      open: props.open,
      prevMonth: Moment.tz(timezone ? timezone : Moment.tz.guess(true))
        .subtract(1, "M")
        .toISOString(),
      timezone: timezone ? timezone : Moment.tz.guess(true),
      lastSelected: null,
    };

    this.datepicker = React.createRef();
  }

  componentDidMount() {
    const { onChange, onChangeRange, rangeStart, rangeEnd, timeFormat } =
      this.props;
    window.addEventListener("click", this.checkToggle.bind(this));

    if (!rangeStart && !rangeEnd) {
      onChange("rangeStart", this.renderDate(Moment()).subtract(30, "d"));
      onChange("rangeEnd", this.renderDate(Moment()));
    } else if (!rangeEnd) {
      onChange("rangeEnd", this.renderDate(Moment()));
    } else if (!rangeStart) {
      onChange("rangeStart", this.renderDate(Moment()).subtract(30, "d"));

      onChangeRange({ rangeEnd: "", rangeStart: "" }); // ***** remove?
    }

    if (!timeFormat) {
      onChange("timeFormat", "local");
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.checkToggle.bind(this));
  }

  // Input focus value
  onFocus(value) {
    this.setState((prevState) => ({
      ...prevState,
      focused: value,
    }));
  }

  // Calendar day hover
  onHover(day) {
    this.setState((prevState) => ({
      ...prevState,
      hovered: day,
    }));
  }

  // Day selection
  onSelectDay(day) {
    const { focused, lastSelected } = this.state;
    const { onChange, rangeEnd, rangeStart, onChangeRange } = this.props;
    let key = lastSelected ? "rangeEnd" : "rangeStart";
    let current = day;

    if (
      focused === "start" ||
      !rangeStart ||
      day.isBefore(!rangeStart) ||
      (lastSelected === "rangeEnd" && !day.isAfter(rangeEnd))
    ) {
      key = "rangeStart";
      this.onFocus("end");
    }

    if (key === "rangeEnd") {
      if (day.isBefore(rangeStart)) {
        key = "rangeStart";
      } else {
        current = day.endOf("day");
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      lastSelected: key,
    }));

    onChange(key, this.renderDate(current));

    // set the range
    const dateRange = getDateRange({
      key,
      rangeEnd,
      rangeStart,
      value: this.renderDate(current),
    });
    onChangeRange(dateRange);
  }

  // Suggested date(s) selection
  onSuggestedSelected(data) {
    const { onChange, onChangeRange } = this.props;
    const { currentMonth } = this.state;

    if (
      this.renderDate(currentMonth).startOf("month") !==
      this.renderDate(data.start).startOf("month")
    ) {
      const prevMonth = this.renderDate(data.start).subtract(1, "M");
      const month = this.renderDate(data.start);

      this.setState((prevState) => ({
        ...prevState,
        currentMonth: month.toISOString(),
        prevMonth: prevMonth.toISOString(),
      }));
    }

    onChange("rangeEnd", this.renderDate(data.end));
    onChange("rangeStart", this.renderDate(data.start));

    onChangeRange({ rangeEnd: data.end, rangeStart: data.start });
  }

  // Time change
  onTimeChange(value, id, key) {
    const { onChange, rangeEnd, rangeStart, onChangeRange } = this.props;
    let current = key === "rangeEnd" ? rangeEnd : rangeStart;
    current = this.renderDate(current);

    if (id === "hr") {
      if (current.format("a") === "am") {
        current = current.hours(parseInt(value, 10));
      } else {
        current = current.hours(parseInt(value, 10) + 12);
      }
    } else if (id === "min") {
      current = current.minutes(parseInt(value, 10));
    } else if (id === "sec") {
      current = current.seconds(parseInt(value, 10));
    } else {
      const hrs = current.hours();
      if (value === "pm") {
        current = current.hours(hrs + 12);
      } else {
        current = current.hours(hrs - 12);
      }
    }

    onChange(key, current);

    // set the range
    let dateRange = getDateRange({
      key,
      rangeEnd,
      rangeStart,
      value: current,
    });
    onChangeRange(dateRange);

    if (key === "rangeEnd" && current.isBefore(rangeStart)) {
      onChange("rangeStart", current.subtract(5, "m"));

      // set the range
      dateRange = getDateRange({
        key: "rangeStart",
        rangeEnd,
        rangeStart,
        value: current.subtract(5, "m"),
      });
      onChangeRange(dateRange);
    } else if (key === "rangeStart" && current.isAfter(rangeEnd)) {
      onChange("rangeEnd", current.add(5, "m"));

      // set the range
      dateRange = getDateRange({
        key: "rangeEnd",
        rangeEnd,
        rangeStart,
        value: current.add(5, "m"),
      });
      onChangeRange(dateRange);
    }
  }

  // Check display state
  checkToggle(e) {
    const { open } = this.state;

    if (
      this.datepicker.current &&
      !this.datepicker.current.contains(e.target) &&
      open
    ) {
      this.toggleDisplay();
    }
  }

  // Toggle datepicker display
  toggleDisplay(open) {
    const { onClose } = this.props;
    const newState = open || !this.state.open;

    if (newState === false) {
      onClose();
    }

    this.setState((prevState) => ({
      ...prevState,
      open: newState,
    }));
  }

  // Toggle month display
  toggleMonth(value) {
    const { currentMonth, prevMonth } = this.state;
    let prev;
    let current;

    if (value === "next") {
      prev = this.renderDate(prevMonth).add(1, "M");
      current = this.renderDate(currentMonth).add(1, "M");
    } else {
      prev = this.renderDate(prevMonth).subtract(1, "M");
      current = this.renderDate(currentMonth).subtract(1, "M");
    }

    this.setState((prevState) => ({
      ...prevState,
      currentMonth: current.toISOString(),
      prevMonth: prev.toISOString(),
    }));
  }

  renderDate(date) {
    const { timezone } = this.state;
    const { timeFormat } = this.props;

    if (timeFormat === "local") {
      return Moment.tz(date, timezone);
    }

    return Moment.utc(date);
  }

  render() {
    const {
      background,
      color,
      onChange,
      rangeEnd,
      rangeStart,
      right,
      timeFormat,
      width,
    } = this.props;

    const { currentMonth, hovered, open, prevMonth, timezone } = this.state;

    return (
      <ContainerLayout ref={this.datepicker} width={width}>
        <HeaderLayout
          background={background}
          color={color}
          onFocus={this.toggleDisplay.bind(this, true)}
          open={open}
        >
          <Header
            color={color}
            onFocus={this.onFocus.bind(this)}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
            renderDate={this.renderDate.bind(this)}
            timeFormat={timeFormat}
            timezone={timezone}
          />
        </HeaderLayout>
        <DisplayContainer maxWidth="640px" open={open} right={right}>
          <DisplayLayout>
            <FlexRowLayout stretch>
              <ColumnContainer>
                <DateSelection
                  currentMonth={currentMonth}
                  hovered={hovered}
                  onHover={this.onHover.bind(this)}
                  onSelectDay={this.onSelectDay.bind(this)}
                  prevMonth={prevMonth}
                  rangeEnd={rangeEnd}
                  rangeStart={rangeStart}
                  renderDate={this.renderDate.bind(this)}
                  toggleMonth={this.toggleMonth.bind(this)}
                />
              </ColumnContainer>
              <ColumnContainer>
                <SpaceBetween>
                  <SectionHeader>Adjust Time</SectionHeader>
                  <ToggleContainer>
                    <TimeToggle
                      onClick={() => onChange("timeFormat", "local")}
                      active={timeFormat !== "utc"}
                    >
                      {Moment.tz(timezone).format("z")}
                    </TimeToggle>
                    <TimeToggle
                      onClick={() => onChange("timeFormat", "utc")}
                      active={timeFormat === "utc"}
                    >
                      UTC
                    </TimeToggle>
                  </ToggleContainer>
                </SpaceBetween>
                <TimeSelection
                  onTimeChange={this.onTimeChange.bind(this)}
                  rangeEnd={rangeEnd}
                  rangeStart={rangeStart}
                  renderDate={this.renderDate.bind(this)}
                  timeFormat={timeFormat}
                />
              </ColumnContainer>
            </FlexRowLayout>
            <FlexRowLayout padding="0px 24px 16px">
              <FlexColumnLayout>
                <SectionHeader>Suggested Dates</SectionHeader>
                <SuggestedDates
                  onClick={this.onSuggestedSelected.bind(this)}
                  renderDate={this.renderDate.bind(this)}
                />
              </FlexColumnLayout>
            </FlexRowLayout>
          </DisplayLayout>
          <SubmitRow>
            <PrimaryButton onClick={this.toggleDisplay.bind(this, false)}>
              Apply
            </PrimaryButton>
          </SubmitRow>
        </DisplayContainer>
      </ContainerLayout>
    );
  }
}

// ** Proptypes ***********************
Datepicker.propTypes = {
  background: string,
  color: string,
  onChange: func,
  onChangeRange: func,
  onClose: func,
  open: bool,
  rangeEnd: oneOfType([shape(), string]),
  rangeStart: oneOfType([shape(), string]),
  right: bool,
  timeFormat: string,
  userTimezone: string,
  width: string,
};

Datepicker.defaultProps = {
  onChangeRange: () => {},
  onClose: () => {},
  open: false,
};

export default Datepicker;
