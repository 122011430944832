import {
  RECEIVE_SETTINGS,
  RESET_SETTINGS,
  RECEIVE_USER,
} from "./actions/Session";
import { PAGE } from "./actions/Page";
import BluLicense from "lib/license";
import { LicenseAPI } from "../lib/api/LicenseAPI";
import Logger from "../lib/logger";

const logger = Logger("LicenseReducer");
const initialState = BluLicense;

const LicenseReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RECEIVE_SETTINGS:
    case RESET_SETTINGS:
    case RECEIVE_USER: {
      return BluLicense;
    }
    case PAGE: {
      const license = new LicenseAPI();

      license
        .get(action.payload.orgId)
        .then(({ data }) => {
          BluLicense.orgId = action.payload.orgId;
          BluLicense.activeLicense = data;
        })
        .catch((err) => {
          logger.error("Failed to fetch active license.", err);
          throw err;
        });
      return BluLicense;
    }
    default:
      return state;
  }
};

export default LicenseReducer;
