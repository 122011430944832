import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";

import SupportEntry from "../../Components/Finding/SupportEntry";
import { formatNumber, getEps } from "../../../utils/index";

/*
Main component
*/

const EventsPerSecond = ({
  columnNumLeft,
  columnNumRight,
  logsTotal = 0,
  timeWindow,
}) => {
  const { windowEnd, windowStart } = timeWindow;

  const eps = getEps({
    logsTotal,
    windowEnd,
    windowStart,
  });

  return (
    <div>
      <Container className="list-container light">
        <SupportEntry
          columnNumLeft={columnNumLeft}
          columnNumRight={columnNumRight}
          label="Events per Second (EPS)"
          value={formatNumber(eps, 0)}
        />
      </Container>
    </div>
  );
};

EventsPerSecond.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  logsTotal: PropTypes.number,
  timeWindow: PropTypes.shape({}),
};

EventsPerSecond.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  logsTotal: 0,
  timeWindow: {},
};

export default EventsPerSecond;
