import { childrenPageViews } from "views/pages";

export const POLL_STATE = {
  STOPPED: "POLL_STATE.STOPPED",
  STARTING: "POLL_STATE.STARTING",
  RUNNING: "POLL_STATE.RUNNING",
  STOPPING: "POLL_STATE.STOPPING",
  PAUSED: "POLL_STATE.PAUSED",
};

export const POLL_SUCCESS = "POLL_SUCCESS";

export const POLL_LIFECYCLE = {
  [POLL_STATE.STOPPED]: POLL_STATE.RUNNING,
  [POLL_STATE.STARTING]: POLL_STATE.RUNNING,
  [POLL_STATE.RUNNING]: POLL_STATE.STOPPED,
  [POLL_STATE.STOPPING]: POLL_STATE.STOPPED,
  [POLL_STATE.PAUSED]: POLL_STATE.RUNNING,
};

/**
 * pollEngine
 * singleton created at runtime
 */
export const pollEngine = new (class {
  constructor() {
    this.tick = 1000;
    this.dispatch = false;
    this._pollId = false;

    this.state = POLL_STATE.STOPPED;
    this.registry = {};
  }

  _poll() {
    this._pollId = setTimeout(() => this._tick(), this.tick);
    this.state = POLL_STATE.RUNNING;
  }

  _tick() {
    this._pollId = false;
    // loop over registry and decrement all tick counts
    const requests = [];

    // create a quick map of interval to tick to use in STARTING
    const quickTickMap = {};
    Object.values(this.registry)
      .filter((o) => o.tick)
      .forEach((o) => {
        quickTickMap[o.interval] = o.tick;
      });

    Object.keys(this.registry).forEach((modelId) => {
      switch (this.registry[modelId].state) {
        case POLL_STATE.STARTING:
          // if there is another request with the same interval, sync to its tick, so the UI updates smoothly
          this.registry[modelId].tick =
            quickTickMap[this.registry[modelId].interval] ||
            this.registry[modelId].interval;
          this.registry[modelId].state = POLL_STATE.RUNNING;
          break;
        case POLL_STATE.STOPPING:
          this.registry[modelId].tick = this.registry[modelId].interval;
          this.registry[modelId].state = POLL_STATE.STOPPED;
          break;
        case POLL_STATE.RUNNING:
          this.registry[modelId].tick -= 1;

          // loop over registry and batch all tick count 0 API requests
          if (this.registry[modelId].tick <= 0) {
            requests.push(this.registry[modelId].model.read());
            // loop over requested items and set tick to interval
            this.registry[modelId].tick = this.registry[modelId].interval;
          }
          break;
        default:
          return;
      }

      const activePolls = Object.values(this.registry).filter(
        (o) => ![POLL_STATE.STOPPED, POLL_STATE.PAUSED].includes(o.state)
      );
      if (activePolls.length === 0) {
        this.state = POLL_STATE.STOPPING;
      }
    });

    // make requests
    if (requests.length > 0) {
      Promise.all(requests).then((results) => {
        const updated = {};
        results.forEach((model) => {
          // set hashes for all models, but only if they are running
          if (
            this.registry[model.id].hash !== model.hash &&
            this.registry[model.id].state === POLL_STATE.RUNNING
          ) {
            this.registry[model.id].hash = model.hash;
            updated[model.id] = model.hash;
          }
        });

        if (Object.keys(updated).length > 0) {
          // RequestState should take it from there
          this.dispatch({
            type: POLL_SUCCESS,
            models: Object.keys(updated).map(
              (modelId) => this.registry[modelId].model
            ),
          });
        }

        // start next tick only once we've processed everything
        if (this.state === POLL_STATE.RUNNING) {
          this._poll();
        }
      });
    } else {
      // if there are no requests, immediately start the next tick
      if (this.state === POLL_STATE.RUNNING) {
        this._poll();
      }
    }

    if (this.state === POLL_STATE.STOPPING) {
      this.state = POLL_STATE.STOPPED;
    }
  }

  startPoll(model, interval) {
    this.registry[model.id] = {
      state: POLL_STATE.STARTING,
      hash: model.hash,
      interval,
      model,
    };

    // start the poller if it isnt already running
    if (this.state !== POLL_STATE.RUNNING) {
      this.state = POLL_STATE.STARTING;
      this._poll();
    }
  }

  stopPoll(modelId) {
    if (!this.registry[modelId]) {
      throw new Error(`Unknown poll model id ${modelId}`);
    }
    // this will get picked up on the next tick
    this.registry[modelId].state = POLL_STATE.STOPPING;
  }

  pausePoll(modelId) {
    if (!this.registry[modelId]) {
      throw new Error(`Unknown poll model id ${modelId}`);
    }
    this.registry[modelId].state = POLL_STATE.PAUSED;
  }

  resumePoll(modelId) {
    if (!this.registry[modelId]) {
      throw new Error(`Unknown poll model id ${modelId}`);
    }
    this.registry[modelId].state = POLL_STATE.RUNNING;

    // start the poller if it isnt already running
    if (this.state !== POLL_STATE.RUNNING) {
      this.state = POLL_STATE.STARTING;
      this._poll();
    }
  }
})();

export const startPoll =
  (model, interval = 5, autoResume = false) =>
  (dispatch, getState) => {
    const state = getState();
    const pageConfig = childrenPageViews.find(
      (pg) =>
        pg.route ===
        `${state.page.payload.toplevel}/${state.page.payload.secondlevel}`
    );

    dispatch({
      type: POLL_STATE.STARTING,
      modelId: model.id,
      route: pageConfig.route,
      autoResume,
      interval,
    });

    pollEngine.dispatch = dispatch;
    pollEngine.startPoll(model, interval);
    dispatch({ type: POLL_STATE.RUNNING, update: { [model.id]: model.hash } });
  };

export const stopPoll = (modelIds) => (dispatch, getState) => {
  dispatch({ type: POLL_STATE.STOPPING, modelIds });
  const update = {};
  modelIds.forEach((modelId) => {
    pollEngine.stopPoll(modelId);
    update[modelId] = pollEngine.registry[modelId].hash;
  });
  dispatch({ type: POLL_STATE.STOPPED, update });
};

export const pausePoll = (modelIds) => (dispatch, getState) => {
  const update = {};
  modelIds.forEach((modelId) => {
    pollEngine.pausePoll(modelId);
    update[modelId] = pollEngine.registry[modelId].hash;
  });

  dispatch({ type: POLL_STATE.PAUSED, update });
};

export const resumePoll = (modelIds) => (dispatch, getState) => {
  const update = {};
  modelIds.forEach((modelId) => {
    pollEngine.resumePoll(modelId);
    update[modelId] = pollEngine.registry[modelId].hash;
  });
  dispatch({ type: POLL_STATE.RUNNING, update });
};
