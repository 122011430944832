import { DataQueryApi } from "../../lib/api";
import Logger from "../../lib/logger";

const logger = Logger("Security");

export const RESET_SECURITY = "RESET_SECURITY";
export const SECURITY_RECEIVE_ATTACK_TYPES = "SECURITY.RECEIVE_ATTACK_TYPES";
export const SECURITY_RECEIVE_BLOCKED_ATTACKS =
  "SECURITY.RECEIVE_BLOCKED_ATTACKS";
export const SECURITY_RECEIVE_BLOCKED_EVENTS =
  "SECURITY.RECEIVE_BLOCKED_EVENTS";
export const SECURITY_RECEIVE_DATES = "SECURITY.RECEIVE_DATES";
export const SECURITY_RECEIVE_ENDPOINTS_ATTACKS =
  "SECURITY.RECEIVE_ENDPOINTS_ATTACKS";
export const SECURITY_RECEIVE_ENDPOINT_EVENTS =
  "SECURITY.RECEIVE_ENDPOINT_EVENTS";
export const SECURITY_RECEIVE_ENDPOINTS_HOSTS =
  "SECURITY.RECEIVE_ENDPOINTS_HOSTS";
export const SECURITY_RECEIVE_ENDPOINT_VICTIMS =
  "SECURITY.RECEIVE_ENDPOINT_VICTIMS";
export const SECURITY_RECEIVE_EGRESS_TRAFFIC =
  "SECURITY.RECEIVE_EGRESS_TRAFFIC";
export const SECURITY_RECEIVE_FAILED_LOGINS = "SECURITY.RECEIVE_FAILED_LOGINS";
export const SECURITY_RECEIVE_FINDINGS = "SECURITY.RECEIVE_FINDINGS";
export const SECURITY_RECEIVE_INGRESS_TRAFFIC =
  "SECURITY.RECEIVE_INGRESS_TRAFFIC";
export const SECURITY_RECEIVE_INTRUDERS = "SECURITY.RECEIVE_INTRUDERS";
export const SECURITY_RECEIVE_LOGGING_DEVICES =
  "SECURITY.RECEIVE_LOGGING_DEVICES";
export const SECURITY_RECEIVE_LOGGING_ENDPOINTS =
  "SECURITY.RECEIVE_LOGGING_ENDPOINTS";
export const SECURITY_RECEIVE_LOGS_TOTAL = "SECURITY.RECEIVE_LOGS_TOTAL";
export const SECURITY_RECEIVE_QUERY_DESCRIPTIONS =
  "SECURITY.RECEIVE_QUERY_DESCRIPTIONS";
export const SECURITY_RECEIVE_THREATS_AND_SUSPECTS =
  "SECURITY.RECEIVE_THREATS_AND_SUSPECTS";
export const SECURITY_RECEIVE_VICTIMS = "SECURITY.SECURITY_RECEIVE_VICTIMS";

const API = {
  dataQuery: new DataQueryApi(),
};

/*
Query Descriptions
*/

const receiveQueryDescriptions = (queryDescriptions) => ({
  type: SECURITY_RECEIVE_QUERY_DESCRIPTIONS,
  payload: { queryDescriptions },
});

export const fetchQueryDescriptions = (dispatch) => {
  API.dataQuery
    .fetchQueryDescriptions()
    .then(({ data }) => dispatch(receiveQueryDescriptions(data)))
    .catch((err) => {
      logger.error("Unable to fetch query descriptions.", err);
    });
};

/*
Attack types
*/

const receiveAttackTypes = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_ATTACK_TYPES,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchAttackTypes =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchAttackTypes({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveAttackTypes({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch attack types for org ${orgId}.`, err);
      });
  };

/*
Blocked attack
*/

const receiveBlockedAttacks = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_BLOCKED_ATTACKS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchBlockedAttacks =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchBlockedAttacks({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveBlockedAttacks({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch blocked attacks for org ${orgId}.`, err);
      });
  };

/*
Blocked events
*/

const receiveBlockedEvents = ({ orgId, timeInterval, count }) => ({
  type: SECURITY_RECEIVE_BLOCKED_EVENTS,
  payload: { insertId: orgId, timeInterval, data: count },
});

// Fetches the total number of logging events for the org
export const fetchBlockedEvents =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchBlockedEvents({
        orgId,
        queryParams,
      })
      .then(({ data }) => {
        const { count = 0 } = data;

        return dispatch(
          receiveBlockedEvents({
            orgId,
            timeInterval: selectedValue,
            count,
          })
        );
      })
      .catch((err) => {
        logger.error(
          `Unable to fetch the number of blocked events for org ${orgId}.`,
          err
        );
      });
  };

/*
Endpoints attack
*/

const receiveEndpointAttacks = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_ENDPOINTS_ATTACKS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchEndpointAttacks =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchEndpointAttacks({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveEndpointAttacks({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(
          `Unable to fetch endpoints attacks for org ${orgId}.`,
          err
        );
      });
  };

/*
Endpoints evetns
*/

const receiveEndpointEvents = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_ENDPOINT_EVENTS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchEndpointEvents =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchEndpointEvents({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveEndpointEvents({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch endpoints events for org ${orgId}.`, err);
      });
  };

/*
Endpoints hosts
*/

const receiveEndpointHosts = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_ENDPOINTS_HOSTS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchEndpointHosts =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchEndpointHosts({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveEndpointHosts({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch endpoints hosts for org ${orgId}.`, err);
      });
  };

/*
Endpoint victims
*/

const receiveEndpointVictims = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_ENDPOINT_VICTIMS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchEndpointVictims =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchEndpointVictims({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveEndpointVictims({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(
          `Unable to fetch endpoints victims for org ${orgId}.`,
          err
        );
      });
  };

/*
Egress traffic
*/

const receiveEgressTraffic = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_EGRESS_TRAFFIC,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchEgressTraffic =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchEgressTraffic({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveEgressTraffic({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch egress traffic for org ${orgId}.`, err);
      });
  };

/*
Failed logins
*/

const receiveFailedLogins = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_FAILED_LOGINS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchFailedLogins =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchFailedLogins({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveFailedLogins({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch failed logins for org ${orgId}.`, err);
      });
  };

/*
Findings
*/

const receiveFindings = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_FINDINGS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the findings types
export const fetchFindings =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchFindings({
        orgId,
        queryParams,
      })
      .then(({ data }) => {
        const { count = 0 } = data;

        return dispatch(
          receiveFindings({
            orgId,
            timeInterval: selectedValue,
            data: count,
          })
        );
      })
      .catch((err) => {
        logger.error(`Unable to fetch findings for org ${orgId}.`, err);
      });
  };

/*
Ingress traffic
*/

const receiveIngressTraffic = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_INGRESS_TRAFFIC,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the attack types
export const fetchIngressTraffic =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchIngressTraffic({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveIngressTraffic({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch ingress traffic for org ${orgId}.`, err);
      });
  };

/*
Intruders
*/

const receiveIntruders = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_INTRUDERS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the intruders types
export const fetchIntruders =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchIntruders({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveIntruders({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch intruders for org ${orgId}.`, err);
      });
  };

/*
Logging devices
*/

const receiveLoggingDevices = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_LOGGING_DEVICES,
  payload: { insertId: orgId, timeInterval, data },
});

/*
Fetches the logging devices
*/
export const fetchLoggingDevices =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchLoggingDevices({
        orgId,
        queryParams: {
          ...queryParams,
          limit: 10,
        },
      })
      .then(({ data }) =>
        dispatch(
          receiveLoggingDevices({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch logging devices for org ${orgId}.`, err);
      });
  };

/*
Logging endpoints
*/

const receiveLoggingEndpoints = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_LOGGING_ENDPOINTS,
  payload: { insertId: orgId, timeInterval, data },
});

/*
Fetches the logging devices
*/
export const fetchLoggingEndpoints =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchLoggingEndpoints({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveLoggingEndpoints({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(`Unable to fetch logging devices for org ${orgId}.`, err);
      });
  };

/*
Logs total
*/

const receiveLogsTotal = ({ orgId, timeInterval, count }) => ({
  type: SECURITY_RECEIVE_LOGS_TOTAL,
  payload: { insertId: orgId, timeInterval, data: count },
});

const receiveDates = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_DATES,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the the total number of logging events for the org
export const fetchLogsTotal =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchTotalLogs({
        orgId,
        queryParams,
      })
      .then(({ data }) => {
        const { count = 0, windowEnd, windowStart } = data;

        dispatch(
          receiveLogsTotal({
            orgId,
            timeInterval: selectedValue,
            count,
          })
        );

        dispatch(
          receiveDates({
            orgId,
            timeInterval: selectedValue,
            data: {
              windowEnd,
              windowStart,
            },
          })
        );
      })
      .catch((err) => {
        logger.error(
          `Unable to fetch the total number of logs for org ${orgId}.`,
          err
        );
      });
  };

/*
Threats and suspects
*/

const receiveThreatsAndSuspects = ({ orgId, timeInterval, count }) => ({
  type: SECURITY_RECEIVE_THREATS_AND_SUSPECTS,
  payload: { insertId: orgId, timeInterval, data: count },
});

// Fetches the number of threats and suspects
export const fetchThreatsAndSuspects =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchThreatsAndSuspects({
        orgId,
        queryParams,
      })
      .then(({ data }) => {
        const { count = 0 } = data;

        return dispatch(
          receiveThreatsAndSuspects({
            orgId,
            timeInterval: selectedValue,
            count,
          })
        );
      })
      .catch((err) => {
        logger.error(
          `Unable to fetch the number of Threats and Suspects for org ${orgId}.`,
          err
        );
      });
  };

/*
Threats and suspects
*/

const receiveVictims = ({ orgId, timeInterval, data }) => ({
  type: SECURITY_RECEIVE_VICTIMS,
  payload: { insertId: orgId, timeInterval, data },
});

// Fetches the number of victimss
export const fetchVictims =
  ({ orgId, queryParams, selectedValue }) =>
  (dispatch) => {
    API.dataQuery
      .fetchVictims({
        orgId,
        queryParams,
      })
      .then(({ data }) =>
        dispatch(
          receiveVictims({
            orgId,
            timeInterval: selectedValue,
            data,
          })
        )
      )
      .catch((err) => {
        logger.error(
          `Unable to fetch the number of victims for org ${orgId}.`,
          err
        );
      });
  };

// Reset all
export const resetSecurity = {
  type: RESET_SECURITY,
};
