import React from "react";
import PropTypes from "prop-types";

import { getRelativeDateString } from "../../lib/helpers";

import Safe from "./Safe";

const ActivityStory = (props) => (
  <div className="activity-story">
    <div className="story-date">{getRelativeDateString(props.date)}</div>
    <Safe type="div" className="story-title" text={props.title} />
    <div className="story-content">
      <Safe
        type="span"
        className="story-content"
        text={`${props.content.substr(0, 100)}...`}
      />
      <a href={props.url}>Read More</a>
    </div>
  </div>
);

ActivityStory.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  title: PropTypes.string,
  url: PropTypes.string,
  content: PropTypes.string,
};

ActivityStory.defaultProps = {
  date: new Date(),
  title: "",
  url: "#read-more",
  content: "",
};

export default ActivityStory;
