import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "auth",
};

export class AuthenticationAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  /* 
    optional @data arg contains createPersonIfNotExists 
    flag that if set to true will create a user prior to 
    login i.e. if a user is leveraging self-service
  */

  login(data) {
    const params = ["login"];
    return super.post({ params }, data);
  }

  logout() {
    const params = ["logout"];
    return super.post({ params });
  }
}
