import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "ActivationConfirmationDialog";
export const classes = {
  leftMarginLink: `${PREFIX}-leftMarginLink`,
  unorderedList: `${PREFIX}-unorderedList`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.leftMarginLink}`]: {
    marginLeft: 10,
  },
  [`& .${classes.unorderedList}`]: {
    paddingLeft: theme.shape.padding,
    "& li": {
      paddingBottom: theme.shape.padding,
    },
  },
}));
