// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Column container ****************
export const ColumnContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${(p) => (p.icon ? "10px" : "0px")};
`;

// ** Outer container *****************
export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  fill: ${COLOR.blumiraBlue600};
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 8px;
`;

// ** Empty state details *************
export const Details = styled.span`
  color: ${COLOR.gray600};
  font-size: 12px;
  font-weight: 400;
`;

// ** Empty state header **************
export const Header = styled.span`
  color: ${COLOR.gray800};
  font-size: 16px;
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 4px;
`;
