import React, { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import MSPBulkDetectionsTab from "views/Components/MSP/MSPBulkDetectionsTab";
import { getChildOrgs } from "./utils";

const PREFIX = "MSPBulkActionsPageView";
const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
  tabs: `${PREFIX}-tabs`,
  tabPanel: `${PREFIX}-tabPanel`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.outerContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: `0 ${theme.shape.padding}px`,
  },
  [`& .${classes.tabs}`]: {
    marginTop: -theme.shape.padding,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& span": {
      textTransform: "none",
    },
  },
  [`& .${classes.tabPanel}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    width: "100%",
  },
}));

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface MSPBulkActionsPageViewProps {
//   orgId: string;
//   userOrgs: Org[];
// }

export const MSPBulkActionsPageView = (props) => {
  const [tabValue, setTabValue] = useState("Detections");

  const handleTabChange = useCallback((event, value) => {
    setTabValue(value);
  }, []);

  const orgs = useMemo(() => {
    // The orgs that Bulk Management cares about are:
    // the MSP parent org (the currently selected org) and its child orgs.
    return [
      props.userOrgs.find((org) => org.id === props.orgId),
      ...getChildOrgs(props.userOrgs, props.orgId),
    ];
  }, [props.userOrgs, props.orgId]);

  return (
    <Root className={classes.outerContainer}>
      <TabContext value={tabValue}>
        <Tabs
          value={tabValue}
          textColor={"primary"}
          indicatorColor={"primary"}
          onChange={handleTabChange}
          className={classes.tabs}
        >
          <Tab label={"Detection Rules"} value={"Detections"} />
        </Tabs>
        <TabPanel value={"Detections"} className={classes.tabPanel}>
          <MSPBulkDetectionsTab orgId={props.orgId} orgs={orgs} />
        </TabPanel>
      </TabContext>
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
    userOrgs: _.get(state.session, `settings.userOrgs`, []),
  };
};

export default connect(mapStateToProps, null)(MSPBulkActionsPageView);
