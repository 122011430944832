import React from "react";
import PropTypes from "prop-types";

const NOT_PROVIDED = "Not provided";

export const DeviceAddress = ({ deviceAddress }) => {
  const address = (
    <div className="devices-cell-bold devices-cell">{deviceAddress}</div>
  );

  return address;
};

DeviceAddress.propTypes = {
  deviceAddress: PropTypes.string,
};

DeviceAddress.defaultProps = {
  deviceAddress: "",
};

export const DeviceName = ({ name }) => {
  const nameHtml = <div className="devices-cell">{name}</div>;

  return nameHtml;
};

DeviceName.propTypes = {
  name: PropTypes.string,
};

DeviceName.defaultProps = {
  name: "",
};

export const DeviceLogTotal = ({ count }) => {
  const total = (
    <div className="devices-cell-bold devices-cell">
      {count || NOT_PROVIDED}
    </div>
  );

  return total;
};

DeviceLogTotal.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

DeviceLogTotal.defaultProps = {
  count: null,
};
