import React from "react";
import { CircularProgress } from "@mui/material";
import { StyledCard, classes } from "./seatCountInfoCardStyles";

export const SeatCountInfoCard = (props) => {
  return (
    <StyledCard variant={"outlined"} style={{ ...props.style }}>
      <div className={classes.card}>
        <p className={classes.header}>{props.header}</p>
        <span className={classes.description}>{props.description || ""}</span>
        <div className={classes.countAndActionContainer}>
          {props.isLoading ? (
            <CircularProgress color={"primary"} />
          ) : (
            <p className={classes.count}>{props.countToDisplay || "0"}</p>
          )}
          {props.action && props.action}
        </div>
      </div>
    </StyledCard>
  );
};
