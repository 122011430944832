import { API } from "./API";

const DEFAULT_OPTIONS = {
  prefix: "STATS/",
  baseUrl: "stats",
};

export class StatsAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  get() {
    return Promise.resolve({});
  }

  // get({
  //   orgId,
  //   role,
  //   startDate,
  //   endDate,
  // }) {
  //   const params = [orgId];
  //   const query = {
  //     role,
  //     startDate: startDate ? moment(startDate).format(DATE_FORMAT) : undefined,
  //     endDate: endDate ? moment(endDate).format(DATE_FORMAT) : undefined,
  //   };
  //   return super.get({ params, query });
  // }
}
