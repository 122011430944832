// Default time interval
export const DEFAULT_TIME_INTERVAL = "24hr";

// The time interval options
export const TIME_INTERVAL_OPTIONS = [
  { value: "30d", label: "30 Days" },
  { value: "10d", label: "10 Days" },
  { value: "7d", label: "7 Days" },
  { value: "2d", label: "2 Days" },
  { value: "24hr", label: "24 Hours" },
  { value: "12hr", label: "12 Hours" },
  { value: "8hr", label: "8 Hours" },
  { value: "60min", label: "1 Hour" },
  { value: "30min", label: "30 Minutes" },
  { value: "15min", label: "15 Minutes" },
];

// Sensor is online (i.e. the Sensor Service is receiving health messages
// from the sensor) if status is one of:
export const SENSOR_STATUS_CONNECTED = [60, 70, 80];

// Types of devices to exclude
export const DEVICE_TYPES_TO_EXCLUDE = ["error_exception", "error_unparsed"];
