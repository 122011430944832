import { combineReducers } from "redux";

import { insertData, insertDataById, mergeDataById } from "./utils/helpers";
import { getGraphReducer } from "./reducersCommon/graphReducer";
import { getInititalState } from "./reducersCommon/graphAndTableHelpers";
import {
  getReducer as getReducerHelper,
  insertError,
} from "./reducersCommon/helpers";

/*
Constants and helpers
*/

import {
  IP_GRAPH,
  IP_RESET,
  IP_SELECTED_INTERVAL_RECEIVE,
  IP_ITEMS_DATA_RECEIVE,
  IP_ITEMS_ERROR_RECEIVE,
  IP_ITEMS_META_RECEIVE,
  IP_PAGINATION_RECEIVE,
  IP_TABLE_FILTERS_RECEIVE,
  IP_TABLE_SORTING_RECEIVE,
  IP_TALKERS_DATA_RECEIVE,
  IP_TALKERS_DATES_RECEIVE,
  IP_TALKERS_ERROR_RECEIVE,
  IP_THREATS_DATA_RECEIVE,
  IP_THREATS_DATES_RECEIVE,
  IP_THREATS_ERROR_RECEIVE,
} from "./actions/Ip";

const STORE_TO_ACTION = {
  itemsByOrg: IP_ITEMS_DATA_RECEIVE,
  itemsError: IP_ITEMS_ERROR_RECEIVE,

  metaByOrg: IP_ITEMS_META_RECEIVE,
  paginationByOrg: IP_PAGINATION_RECEIVE,

  selectedInterval: IP_SELECTED_INTERVAL_RECEIVE,

  tableFiltersByOrg: IP_TABLE_FILTERS_RECEIVE,
  tableSorting: IP_TABLE_SORTING_RECEIVE,

  talkersByLogdev: IP_TALKERS_DATA_RECEIVE,
  talkersDates: IP_TALKERS_DATES_RECEIVE,
  talkersError: IP_TALKERS_ERROR_RECEIVE,

  threatsByLogdev: IP_THREATS_DATA_RECEIVE,
  threatsDates: IP_THREATS_DATES_RECEIVE,
  threatsError: IP_THREATS_ERROR_RECEIVE,
};

const INITIAL_STATE = {
  ...getInititalState(STORE_TO_ACTION),
  talkersError: null,
  threatsError: null,
};

const getReducer = ({ initialStateKey, insert = insertData }) =>
  getReducerHelper({
    actionTypeReceive: STORE_TO_ACTION[initialStateKey],
    actionTypeReset: IP_RESET,
    initialState: INITIAL_STATE[initialStateKey],
    insert,
  });

/*
Reducers
*/

// Graph
const graphReducer = getGraphReducer(IP_GRAPH);

// IPs by org ('itemsByOrg')
const itemsDataReducer = getReducer({
  initialStateKey: "itemsByOrg",
  insert: insertDataById,
});

// itemsError
const itemsErrorReducer = getReducer({
  initialStateKey: "itemsError",
  insert: insertError,
});

// metaByOrg
const metaByOrgReducer = getReducer({
  initialStateKey: "metaByOrg",
  insert: insertDataById,
});

// paginationByOrg
const paginationByOrgReducer = getReducer({
  initialStateKey: "paginationByOrg",
  insert: mergeDataById,
});

// selectedInterval
const selectedIntervalReducer = getReducer({
  initialStateKey: "selectedInterval",
  insert: insertDataById,
});

// tableFiltersByOrg
const tableFiltersByOrgReducer = getReducer({
  initialStateKey: "tableFiltersByOrg",
  insert: mergeDataById,
});

// tableSorting
const tableSortingReducer = getReducer({
  initialStateKey: "tableSorting",
  insert: insertDataById,
});

// talkersByLogdev
const talkersDataReducer = getReducer({
  initialStateKey: "talkersByLogdev",
});

// talkersDates
const talkersDatesReducer = getReducer({
  initialStateKey: "talkersDates",
});

// talkersError
const talkersErrorReducer = getReducer({
  initialStateKey: "talkersError",
  insert: insertError,
});

// threatsByLogdev
const threatsDataReducer = getReducer({
  initialStateKey: "threatsByLogdev",
});

// threatsDates
const threatsDatesReducer = getReducer({
  initialStateKey: "threatsDates",
});

// threatsError
const threatsErrorReducer = getReducer({
  initialStateKey: "threatsError",
  insert: insertError,
});

// IP reducer
const reducers = {
  ...graphReducer,

  itemsByOrg: itemsDataReducer,
  itemsError: itemsErrorReducer,

  metaByOrg: metaByOrgReducer,
  paginationByOrg: paginationByOrgReducer,

  selectedInterval: selectedIntervalReducer,

  tableFiltersByOrg: tableFiltersByOrgReducer,
  tableSorting: tableSortingReducer,

  talkersByLogdev: talkersDataReducer,
  talkersDates: talkersDatesReducer,
  talkersError: talkersErrorReducer,

  threatsByLogdev: threatsDataReducer,
  threatsDates: threatsDatesReducer,
  threatsError: threatsErrorReducer,
};
const IpReducer = combineReducers(reducers);

export default IpReducer;
