import moment from "moment-timezone";
import _ from "lodash";
import { setAxiosOrgHeader } from "../lib/api/API";
import { getPageHashParams, setPageHashParams } from "utils";
import * as Actions from "./actions";

const initialState = {
  payload: {},
  pageHash: {},
  data: {},
  meta: {},
  filters: {
    selectionRange: {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      key: "selection",
    },
    criteria: [{ type: "tunnel_protocol", value: "TCP" }],
  },
  loading: true,
  ready: false,
  error: null,
};

/*
on page navigation => PAGE: loading:true, ready:false
on page render => PAGE_LOADING: loading:false, ready:false
on page success => PAGE_SUCCESS: loading:false, ready:true
*/

const PageReducer = (state = initialState, action = {}) => {
  if (action.pageHash) {
    setPageHashParams(action.pageHash);
  } else {
    action.pageHash = getPageHashParams();
  }
  const pageHash = action.pageHash;

  switch (action.type) {
    case Actions.Page.PAGE: {
      setAxiosOrgHeader(action.payload.orgId);

      const { payload } = action;
      if (!_.isEqual(payload, state.payload)) {
        return {
          ...initialState,
          pageHash,
          payload,
        };
      }
      return {
        ...state,
        pageHash,
        payload,
      };
    }
    case Actions.Page.PAGE_LOADING: {
      return {
        ...state,
        pageHash,
        loading: false,
        ready: false,
        error: null,
      };
    }
    case Actions.Page.PAGE_SUCCESS: {
      return {
        ...state,
        pageHash,
        loading: false,
        ready: true,
        error: null,
      };
    }
    case Actions.Page.PAGE_ERROR: {
      return {
        ...state,
        pageHash,
        loading: false,
        ready: true,
        error: action.error,
      };
    }
    case Actions.Page.LOGIN: {
      if (action.payload.orgId) {
        sessionStorage.setItem("payload", JSON.stringify(action.payload));
      }
      return {
        ...state,
        pageHash,
        payload: action.payload,
      };
    }

    case Actions.Page.LOGIN_ERROR: {
      return {
        ...state,
        pageHash,
        error: action.error,
      };
    }

    default: {
      return {
        ...state,
        pageHash,
      };
    }
  }
};

export default PageReducer;
