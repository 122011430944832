import React from "react";
import { connect } from "react-redux";
import Dashboard2 from "./Dashboard2";

// All jurisdictions but 30, which is Analyst findings
const ResponderPage = (props) => {
  return (
    <Dashboard2 title="Responder Dashboard" pageRole="responder" {...props} />
  );
};

const mapStateToProps = (state, props) => ({
  theme: state.userInterface.theme ? state.userInterface.theme : "default", // to catch existing users
});

export default connect(mapStateToProps)(ResponderPage);
