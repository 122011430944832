import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import moment from "moment-timezone";

import { Report } from "lib/models/Report";

import { loadPageData } from "redux/actions/Request";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import SimpleTable from "views/Components/SimpleTable";
import ActionDialog from "views/Components/ActionDialog";
import ScheduledReportModelForm from "views/Components/Reporting/ScheduledReportModelForm";

import { isNil } from "lodash";
import { filterReports } from "./SummaryDashboard";

export const getDayToSet = (selectedDayValue, defaultDayValue) => {
  const dayValueToReturn = isNil(selectedDayValue)
    ? defaultDayValue
    : selectedDayValue;
  return dayValueToReturn;
};

const renderTimeOfDayCell = (column, model) => {
  const timeValue = model[column.field];

  if (!timeValue) {
    return "Not set";
  }

  return moment
    .utc()
    .hour(Math.floor(timeValue))
    .minute(Math.round((timeValue % 1) * 60))
    .format("h:mm A");
};

const ScheduledReportsView = (props) => {
  const [savedQueries, setSavedQueries] = useState(props.savedQueries); // eslint-disable-line no-unused-vars
  const [queryTypes, setQueryTypes] = useState([]);
  const [state, setState] = useState({});
  const [open, setOpen] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [selectedModel, setSelectedModel] = useState(false);

  useEffect(() => {
    let visibleReports = filterReports(props.savedQueries, props.license);
    setSavedQueries(visibleReports);
    const _queryTypes = {};
    visibleReports
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .map((savedQuery) => {
        _queryTypes[parseInt(savedQuery.params.id)] = savedQuery.params.name;
      });

    setQueryTypes(
      visibleReports
        .sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map((savedQuery) => {
          return {
            value: parseInt(savedQuery.params.id),
            label: savedQuery.params.name,
          };
        })
    );

    setState({
      columns: [
        {
          title: "Name",
          field: "name",
          searchable: true,
        },
        {
          title: "Query",
          field: "dataQueryId",
          lookup: _queryTypes,
          searchable: true,
        },
        {
          title: "Format",
          field: "outputType",
          lookup: { csv: "CSV", jsonl: "JSON" },
          searchable: true,
        },
        {
          title: "Period",
          field: "period",
          lookup: {
            "last-1-days": "Prior Day",
            "last-7-days": "Prior 7 Days",
            "last-30-days": "Prior 30 Days",
            "previous-week": "Previous Week",
            "previous-month": "Previous Month",
          },
          searchable: true,
        },
        {
          title: "Schedule (UTC)",
          field: "timeOfDay",
          searchable: true,
          renderValue: renderTimeOfDayCell,
        },
      ],
      data: props.reports,
    });
  }, [props.savedQueries, props.reports]);

  const handleClose = (action) => {
    selectedModel.reset();
    setOpen(false);
  };

  const handleDelete = () => {
    selectedModel.delete().then(() => {
      props.reload(true);
      setOpenAction(false);
    });
  };

  const handleEditTableRowClick = (rowDetails) => {
    // rowDetails, in this case, is really just the report info/model
    setSelectedModel(rowDetails);
    setOpen(true);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="simple-dialog-title">
          {selectedModel.id
            ? `Edit ${selectedModel.name}`
            : "New Scheduled Report"}
        </DialogTitle>
        <ScheduledReportModelForm
          model={selectedModel}
          queryTypes={queryTypes}
          handleClose={handleClose}
          savedQueries={props.savedQueries}
          reload={() => props.reload(true)}
        />
      </Dialog>
      <ActionDialog
        open={openAction}
        title={`Delete ${selectedModel.name}`}
        description="Are you sure you want to delete this report?"
        actions={[
          {
            title: "Cancel",
            action: () => setOpenAction(false),
          },
          {
            title: "Delete",
            action: handleDelete,
          },
        ]}
      />
      <SimpleTable
        isNorthStar
        title="Reports"
        isFetching={!props.ready}
        rowClick={handleEditTableRowClick}
        columns={state.columns}
        data={state.data}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Schedule Report",
            onClick: (event, model) => {
              setSelectedModel(new Report());
              setOpen(true);
            },
          },
        ]}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit Report",
            onClick: (event, model) => {
              handleEditTableRowClick(model);
            },
          },
          {
            icon: DeleteIcon,
            tooltip: "Delete Report",
            onClick: (event, model) => {
              setSelectedModel(model);
              setOpenAction(true);
            },
          },
        ]}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
  };
};

const mapStateToProps = (state, ownProps) => {
  const { query } = state;

  return {
    isSaving: query.isReportsSaving,
    isDeleting: query.isDeleting,
    reportForm: query.reportForm,
    messages: query.messages,
    license: state.license,
  };
};

ScheduledReportsView.defaultProps = {
  ready: false,
  reports: [],
  savedQueries: [],
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledReportsView);
