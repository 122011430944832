import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

class CheckboxField extends Component {
  render() {
    const {
      name,
      label,
      required,
      values = {},
      disabled = false,
      errors = {},
      touched = {},
      handleBlur,
      handleChange,
      classes = "",
      validation = {},
      formatting = {},
    } = this.props;
    const uniqueId = _.uniqueId();
    return (
      <div
        className={`checkbox field ${
          errors[name] && touched[name] ? "error" : ""
        }`}
      >
        <input
          id={`${name}__${uniqueId}`}
          name={name}
          className={classes || "control-item"}
          type="checkbox"
          value={values[name]}
          onChange={handleChange}
          onBlur={handleBlur}
          validation={{ required, ...validation }}
          formatting={{ ...formatting }}
          ref={(c) => {
            this.field = c;
          }}
          checked={values[name]}
          disabled={disabled}
        />
        <label
          htmlFor={`${name}__${uniqueId}`}
          className="inline-control-label"
          title={errors[name]}
          style={{ display: "inline-block" }}
        >
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
        {errors[name] && touched[name] ? (
          <span className="error-message">Required</span>
        ) : null}
      </div>
    );
  }
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  values: PropTypes.shape({}),
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  validation: PropTypes.shape({}),
  formatting: PropTypes.shape({}),
};

CheckboxField.defaultProps = {
  label: "",
  required: false,
  values: {},
  disabled: false,
  errors: {},
  touched: {},
  classes: "",
  validation: {},
  formatting: {},
};

export default CheckboxField;
