const lcAgentSchema = {
  $id: "/lcagent",
  title: "LC Agent",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    orgId: { type: "string", format: "uuid", readOnly: true },
    group: { type: "string", format: "uuid", readOnly: true },
    hostname: { type: "string", readOnly: true },
    isIsolated: { type: "boolean" },
    isSleeping: { type: "boolean" },
    isolationRequested: { type: "boolean" },
    version: { type: "string", readOnly: true },
    lastSeen: { type: "string", format: "date-time", readOnly: true },
    alive: { type: "string", format: "date-time", readOnly: true },
    arch: { type: "string", readOnly: true },
    isOnline: { type: "boolean", readOnly: true }, // this value is requested from LC and not stored
    internalIP: { type: "string", readOnly: true }, // this value is requested from LC and not stored
    externalIP: { type: "string", readOnly: true }, // this value is requested from LC and not stored
    macAddr: { type: "string", readOnly: true }, // this value is requested from LC and not stored
    firstSeen: { type: "string", format: "date-time", readOnly: true }, // this value is requested from LC and not stored
    modified: { type: "string", format: "date-time", readOnly: true },
    isExcluded: { type: "boolean" },
    plat: { type: "string", readOnly: true },
    keyname: { type: "string", readOnly: true },
  },
};

export default lcAgentSchema;
