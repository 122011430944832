import React from "react";
import PropTypes from "prop-types";
import SimpleModelForm from "views/Components/SimpleModelForm";
import { getDayToSet } from "views/Pages/ScheduledReportsView";

const daysOfWeekName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const ScheduledReportModelForm = (props) => {
  const getScheduledReportLayout = (includeQuerySelection) => {
    let layoutToReturn = [
      { field: "name", xs: 12 },
      { field: "dataQueryId", xs: 12 },
      { field: "recipients", xs: 12 },
      { field: "period", xs: 12 },
      { field: "outputType", xs: 4 },
      { field: "timeOfDay", xs: 4 },
      { field: "dayOfWeek", xs: 4 },
      { field: "dayOfMonth", xs: 4 },
    ];

    // do not render dataQueryId field if we are scheduling a report
    // that has not yet been created from the report builder
    if (!includeQuerySelection)
      layoutToReturn = layoutToReturn.filter(
        ({ field }) => field !== "dataQueryId"
      );

    return layoutToReturn;
  };

  const getScheduledReportFields = (includeQuerySelection) => {
    const fieldsToReturn = {
      name: {
        type: "text",
        default: "",
        label: "Scheduled Report Name",
      },
      dataQueryId: {
        type: "select",
        default: props.queryTypes.length > 0 ? props.queryTypes[0].value : "",
        label: "Saved Report Name",
        lookup: props.queryTypes,
      },
      recipients: {
        type: "chip",
        default: [],
        label: "Recipients",
        helperText: "Type at least one email and press enter to add",
      },
      timeOfDay: {
        type: "select",
        label: "Time of Day (UTC)",
        default: 1,
        lookup: Array.from({ length: 24 }, (v, k) => {
          return {
            label: `${k % 12 || 12}:00 ${k > 11 && k < 24 ? "PM" : "AM"}`,
            value: k,
          };
        }),
      },
      dayOfWeek: {
        type: "select",
        label: "Day of Week",
        default: 1,
        lookup: Array.from({ length: 7 }, (v, k) => {
          return { label: daysOfWeekName[k], value: (k + 6) % 7 };
        }),
      },
      dayOfMonth: {
        type: "select",
        label: "Day of Month",
        default: 1,
        lookup: Array.from({ length: 31 }, (v, k) => {
          return { label: k + 1, value: k + 1 };
        }),
      },
      outputType: {
        type: "select",
        default: "csv",
        label: "Output Type",
        lookup: [
          { value: "csv", label: "CSV" },
          { value: "jsonl", label: "JSON" },
        ],
      },
      period: {
        type: "radio",
        default: "last-1-days",
        label: "Period",
        lookup: [
          { value: "last-1-days", label: "Prior Day" },
          { value: "last-7-days", label: "Prior 7 Days" },
          { value: "last-30-days", label: "Prior 30 Days" },
          { value: "previous-week", label: "Previous Week" },
          { value: "previous-month", label: "Previous Month" },
        ],
      },
    };

    // do not render dataQueryId field if we are scheduling a report
    // that has not yet been created from the report builder
    if (!includeQuerySelection) delete fieldsToReturn.dataQueryId;

    return fieldsToReturn;
  };

  const onValidate = (model, fields) => {
    if (
      fields.period.value === "last-7-days" ||
      fields.period.value === "previous-week"
    ) {
      fields.dayOfWeek.visible = true;
      fields.dayOfMonth.visible = false;

      // getDayToSet expects a user selected value
      // and a default value to fall back on
      const dayOfWeekToSet = getDayToSet(
        props.model.dayOfWeek,
        fields.dayOfWeek.default
      );

      props.model.set({ dayOfMonth: null });
      props.model.set({ dayOfWeek: dayOfWeekToSet });
    } else if (
      fields.period.value === "last-30-days" ||
      fields.period.value === "previous-month"
    ) {
      fields.dayOfWeek.visible = false;
      fields.dayOfMonth.visible = true;

      const dayOfMonthToSet = getDayToSet(
        props.model.dayOfMonth,
        fields.dayOfMonth.default
      );

      props.model.set({ dayOfWeek: null });
      props.model.set({ dayOfMonth: dayOfMonthToSet });
    } else {
      fields.dayOfWeek.visible = false;
      fields.dayOfMonth.visible = false;
      props.model.set({ dayOfMonth: null });
      props.model.set({ dayOfWeek: null });
    }
    if (model.dataQueryId) {
      const dataSourceIndex = props.savedQueries.findIndex(
        (query) => query.id === model.dataQueryId
      );
      const queryForNamespace = props.savedQueries[dataSourceIndex];

      // if we are creating from the report builder, the newly saved query will
      // not be present in this array but we set the 'dataSource' elsewhere
      if (queryForNamespace && !model.dataSource) {
        if (model.dataSource !== queryForNamespace.namespace) {
          model.set({ dataSource: queryForNamespace.namespace });
        }
      }
    }
    return fields;
  };

  return (
    <SimpleModelForm
      model={props.model}
      reload={props.reload}
      onValidate={onValidate}
      onClose={props.handleClose}
      actions={
        props.handleClose
          ? [
              {
                title: "Cancel",
                type: "button",
                action: props.handleClose,
              },
              {
                title: "Save",
                type: "submit",
              },
            ]
          : []
      }
      // pass in query types length to determine if this is for the reporting
      // view or scheduled report view to render proper fields
      layout={getScheduledReportLayout(props.queryTypes.length > 0)}
      fields={getScheduledReportFields(props.queryTypes.length > 0)}
    />
  );
};

ScheduledReportModelForm.propTypes = {
  queryTypes: PropTypes.array.isRequired,
};

ScheduledReportModelForm.defaultProps = {
  queryTypes: [],
};

export default ScheduledReportModelForm;
