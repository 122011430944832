// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { func, node, shape, string } from "prop-types";

// ** Components **********************
import { DefaultListEmptyState } from "views/Components/EmptyState";
import { DefaultIcon } from "views/Components/Icon";
import { MinimalInput } from "views/Components/Input";
import { Header } from "views/Components/Typography";

// ** Features ************************
import { DefaultSearch } from "views/Features/Search";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import {
  IconContainer,
  InputContainer,
  List,
  ListItem,
  ModalListContainer,
} from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Modal list feature
//
//  ** Remarks
//  Simple modal list component extended to simplify code across the app
//
//  ** Props
//  @param component {node} - component to be displayed in modal
//  @param data {array} - query data for display
//  @param emptyStateDetails {string} - text content for empty state (details)
//  @param emptyStateHeader {string} - text content for empty state (header)
//  @param limit {number} - limit number of items to display
//  @param onToggleModal {func} - modal flag toggle
//
//  ** Methods
//  onFilter - filter function on input change
//
export default class ModalList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOptions: props.data,
      filterValue: "",
    };
  }

  onFilter(id, value) {
    const { data } = this.props;

    const filteredOps =
      value !== ""
        ? data.filter(
            (option) =>
              option.name.toLowerCase().indexOf(value.toLowerCase()) === 0
          )
        : data;

    this.setState((prevState) => ({
      ...prevState,
      filterValue: value,
      filterOptions: filteredOps,
    }));
  }

  render() {
    const {
      dispatch,
      emptyStateDetails,
      emptyStateHeader,
      modalState,
      onToggleModal,
      orgId,
      title,
    } = this.props;
    const { filterValue, filterOptions } = this.state;

    return (
      <ModalListContainer>
        <Header margin="0px 0px 32px">{title}</Header>
        <InputContainer>
          <IconContainer>
            <DefaultIcon height="12px" icon="search" />
          </IconContainer>
          <MinimalInput
            id="filterValue"
            onChange={this.onFilter.bind(this)}
            padding="6px 10px 6px 32px"
            placeholder="Search..."
            value={filterValue}
          />
        </InputContainer>
        <List>
          {filterOptions.length > 0 ? (
            filterOptions.map((search) => (
              <ListItem key={search.id}>
                <DefaultSearch
                  key={search.id}
                  dispatch={dispatch}
                  modalState={modalState}
                  onToggleModal={onToggleModal}
                  orgId={orgId}
                  search={search}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <DefaultListEmptyState
                details={emptyStateDetails}
                header={emptyStateHeader}
              />
            </ListItem>
          )}
        </List>
      </ModalListContainer>
    );
  }
}

// ** Proptypes ***********************
ModalList.propTypes = {
  component: node,
  dispatch: func,
  emptyStateDetails: string,
  emptyStateHeader: string,
  location: shape({
    payload: shape({
      orgId: string,
    }),
  }),
  modalState: shape(),
  onToggleModal: func,
  orgId: string,
  title: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Global queries modal ************
export const GlobalQueryModalList = withProps((props) => ({
  data: props.globalQueries,
  emptyStateDetails: "Global reports will be displayed here for future use",
  emptyStateHeader: "No global reports available",
  location: props.location,
  title: "Global Reports",
}))(ModalList);

// ** Saved queries modal *************
export const SavedQueryModalList = withProps((props) => ({
  data: props.savedQueries,
  emptyStateDetails: "Saved reports will be displayed here for future use",
  emptyStateHeader: "No saved reports available",
  location: props.location,
  title: "Saved Reports",
}))(ModalList);
