import { FindingAPI } from "../../lib/api";
import Logger from "../../lib/logger";
import { handleApiError } from "../utils/helpers";

import Request from "../../lib/api/Request";

import { Finding } from "../../lib/models/Finding";

export const RECEIVE_FINDING = "FINDINGS.RECEIVE_FINDING";
export const RECEIVE_CONSTANTS = "FINDINGS.RECEIVE_CONSTANTS";
export const FETCH_FINDINGS = "FINDINGS.FETCH_FINDINGS";
export const RECEIVE_FINDINGS = "FINDINGS.RECEIVE_FINDINGS";
export const TOGGLE_FINDING_MESSAGE = "FINDINGS.TOGGLE_FINDING_MESSAGE";
export const UPDATE_FINDING_FIELD = "FINDINGS.UPDATE_FINDING_FIELD";
export const RESET_FINDINGS = "FINDINGS.RESET";
export const RECEIVE_CONSTANTS_ERROR = "FINDINGS.CONSTANTS_ERROR";
export const RECEIVE_FINDINGS_ERROR = "FINDINGS.FINDINGS_ERROR";

const logger = Logger("Findings");

// Non-GraphQL '/finding' API
const API = new FindingAPI();

const receiveFinding = ({ finding }) => ({
  type: RECEIVE_FINDING,
  finding,
});

const receiveFindings = ({ findings, constants }) => ({
  type: RECEIVE_FINDINGS,
  findings,
  constants,
});

export const receiveConstantsError = (error) => ({
  type: RECEIVE_CONSTANTS_ERROR,
  payload: {
    error,
  },
});

const receiveFindingsError = (error) => ({
  type: RECEIVE_FINDINGS_ERROR,
  payload: {
    error,
  },
});

export const clearFindingsError = {
  type: RECEIVE_FINDINGS_ERROR,
  payload: {
    error: null,
  },
};

export const fetchConstants = () => (dispatch) => {
  dispatch(receiveConstantsError(null));

  const request = new Request("/constants");

  request
    .get()
    .then((constants) => {
      dispatch(receiveConstantsError(null));

      dispatch({
        type: RECEIVE_CONSTANTS,
        constants: constants[0],
      });
    })
    .catch((err) => {
      const message = "Unable to fetch constants.";
      dispatch(
        handleApiError({
          message,
          err,
          onError: receiveConstantsError,
        })
      );
    });
};

export const toggleTextbox =
  ({ id }) =>
  (dispatch) =>
    dispatch({
      type: TOGGLE_FINDING_MESSAGE,
      id,
    });

export const updateField =
  ({ id, value, field }) =>
  (dispatch) =>
    dispatch({
      type: UPDATE_FINDING_FIELD,
      id,
      value,
      field,
    });

export const setOwners =
  ({ findingId, personId, owners, ownerType }) =>
  (dispatch, getState) => {
    if (!personId) {
      personId = getState().session.settings.user.id; // current user
    }
    return API.setOwners({
      findingId,
      personId,
      owners,
      ownerType,
    })
      .then((resp) => {
        const { data: finding = {} } = resp;
        dispatch(receiveFinding({ finding }));
      })
      .catch((err) => {
        const message = "Cannot update owners or fetch updated finding";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveFindingsError,
          })
        );
      });
  };

export const fetchFinding = (findingId) => (dispatch) => {
  const request = new Finding({ id: findingId });
  return request
    .read()
    .then((finding) => {
      dispatch({
        type: RECEIVE_FINDING,
        finding: finding,
      });
    })
    .catch((err) => {
      const message = "Failed to load finding";
      dispatch(
        handleApiError({
          message,
          err,
        })
      );
    });
};

export const fetchFindings =
  ({
    count,
    personId,
    orgId,
    jurisdiction,
    withoutJurisdiction,
    status,
    withoutStatus,
  }) =>
  async (dispatch, getState) => {
    const findingFetchData = [
      { field: "orgId", value: orgId },
      {
        field: "status",
        value:
          withoutStatus !== null
            ? withoutStatus === 0
              ? "-9999"
              : `-${withoutStatus}`
            : status,
      },
    ];
    const reqJurisdiction = withoutJurisdiction
      ? `-${withoutJurisdiction}`
      : jurisdiction;
    if (reqJurisdiction) {
      findingFetchData.push({ field: "jurisdiction", value: reqJurisdiction });
    }

    try {
      const findingRequest = new Request("/finding", findingFetchData);
      const findings = await findingRequest.get();

      const constantsRequest = new Request("/constants");
      const constants = await constantsRequest.get();

      dispatch(receiveFindings({ findings, constants: constants[0] }));
    } catch (err) {
      const message = "Failed to load findings";
      dispatch(
        handleApiError({
          message,
          err,
          onError: receiveFindingsError,
        })
      );
    }
  };

export const submitWorkflowQuestion =
  ({ findingId, questionId, answerText, type, personId, oldFinding }) =>
  (dispatch, getState) => {
    if (!personId) {
      personId = getState().settings.currentUserId;
    }
    return API.submitQuestion({
      findingId,
      questionId,
      answerText,
      type,
      personId,
    })
      .then((resp) => {
        const { data: finding = {} } = resp;
        if (finding) {
          const store = getState().findings;
          const resolved = store.get("constants").status.fromName("Resolved");

          // Finding is closing
          if (
            oldFinding.jurisdiction !== finding.jurisdiction ||
            finding.status === resolved
          ) {
            dispatch(
              updateField({
                id: finding.id,
                field: "closing",
                value: true,
              })
            );
            setTimeout(() => dispatch(receiveFinding({ finding })), 900);
          } else {
            dispatch(receiveFinding({ finding }));
          }
        } else {
          throw new Error("Updated finding was not returned.");
        }
      })
      .catch((err) => {
        logger.error("Failed to submit answer.", err);
      });
  };

export const resetFindings = {
  type: RESET_FINDINGS,
};

export const resolveFinding =
  ({ findingId, data }) =>
  (dispatch) => {
    API.resolveFinding({ findingId, data })
      .then((resp) => {
        const { data: finding = {} } = resp;

        dispatch(receiveFinding({ finding }));
      })
      .catch((err) => {
        const message = "Cannot resolve the finding.";
        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveFindingsError,
          })
        );
      });
  };

export const addFindingAttachments =
  ({ orgId, findingId, files }) =>
  (dispatch) => {
    const promises = files.map((file) => {
      return API.addFindingAttachment({ orgId, findingId, filename: file.name })
        .then((resp) => {
          const formData = new FormData();
          const postPolicy = resp.data.post_policy;
          Object.keys(postPolicy.fields).forEach(function (key) {
            formData.append(key, postPolicy.fields[key]);
          });
          formData.append("file", file);
          return fetch(postPolicy.url, {
            method: "post",
            body: formData,
            mode: "no-cors",
          });
        })
        .then(() => {
          dispatch(fetchFinding(findingId));
        })
        .catch((err) => {
          const message = `Failed to attach ${file.name} to the finding.`;
          dispatch(
            handleApiError({
              message,
              err,
            })
          );
        });
    });
    return Promise.all(promises);
  };

export const deleteFindingAttachment =
  ({ orgId, findingId, attachmentId }) =>
  (dispatch) => {
    return API.deleteFindingAttachment({ orgId, findingId, attachmentId })
      .then(() => {
        return dispatch(fetchFinding(findingId));
      })
      .catch((err) => {
        const message = `Failed to remove attachment from the finding.`;
        dispatch(
          handleApiError({
            message,
            err,
          })
        );
      });
  };
