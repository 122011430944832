import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import NotificationsForm from "../Components/NotificationsForm";
import "react-table/react-table.css";
import Request from "lib/api/Request";

/*
Component
*/
export const MyAlertsView = (props) => {
  const { orgId, currentUser = {}, ready, userId } = props;
  const [viewingUser, setViewingUser] = useState({});
  const [isSelf, setIsSelf] = useState(true);

  useEffect(() => {
    if (userId === currentUser.id) {
      setIsSelf(true);
      setViewingUser(currentUser);
    } else {
      setIsSelf(false);
      const request = new Request("/user", [
        { field: "id", operator: "eq", value: userId },
      ]);
      request.get().then((user) => {
        setViewingUser(user[0]);
      });
    }
  }, [userId]);

  return (
    <div className="my-alerts-view">
      <h1>Notification Settings</h1>
      <br />

      {ready && (
        <NotificationsForm
          user={viewingUser}
          orgId={orgId}
          editingSelf={isSelf}
        />
      )}
    </div>
  );
};

// PropTypes and Redux

MyAlertsView.propTypes = {
  orgId: PropTypes.string,
};

MyAlertsView.defaultProps = {
  users: [],
  user: {
    config_alerts: {},
    email: "",
    id: "",
  },
  orgId: "",
};

const mapStateToProps = (state) => {
  const currentUser = get(state, ["session", "settings", "user"], {});
  const { orgId } = state.location.payload;
  return {
    userId: state.location.payload.id1 ?? currentUser.id,
    currentUser,
    orgId,
  };
};

export default connect(mapStateToProps, null)(MyAlertsView);
