const queryResultSchema = {
  $id: "/queryresult",
  type: "object",
  properties: {
    fields: {
      type: "array",
      items: {
        $ref: "/column",
      },
    },
  },
};

export default queryResultSchema;
