export const receivePaginationParam =
  (actionType) =>
  ({ insertId, data }) => {
    const payload = {
      insertId,
      data,
    };

    return {
      type: actionType,
      payload,
    };
  };
