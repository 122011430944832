import React from "react";
import PropTypes from "prop-types";

/*
Constants
*/
// These correspond to the Nivo default label font size
export const FIRST_LABEL_ROW_DY = "-6";
export const SECOND_LABEL_ROW_DY = "12";

// DEFAULT_LABEL here should be different from DEFAULT_LABEL in AxisLabelsLeft
// to distinguish actual bars with no label from fake bars of size 0
// appended to make the graph look OK.
const DEFAULT_LABEL = "Unknown";

/*
Helpers
*/

const truncateText = (str) => {
  if (!str || str.length <= 15) {
    return str;
  }

  return str.slice(0, 15);
};

/*
Main component - two tspan elements that can be used
as Nivo Bar axis and Nivo Pie radial labels.
*/

const BluAxisLabel = ({
  elt,
  labelField,
  labelField2,
  dyRow1 = FIRST_LABEL_ROW_DY,
  dyRow2 = SECOND_LABEL_ROW_DY,
}) => {
  if (labelField2) {
    return (
      <React.Fragment>
        <tspan x="0" dy={dyRow1} style={{ cursor: "pointer" }}>
          {truncateText(elt[labelField]) || DEFAULT_LABEL}
          <title>{elt[labelField] || DEFAULT_LABEL}</title>
        </tspan>
        <tspan x="0" dy={dyRow2}>
          {truncateText(elt[labelField2]) || ""}
        </tspan>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <tspan style={{ cursor: "pointer" }}>
        {truncateText(elt[labelField]) || "Unknown"}
        <title>{elt[labelField] || "Unknown"}</title>
      </tspan>
    </React.Fragment>
  );
};

BluAxisLabel.propTypes = {
  elt: PropTypes.shape({}).isRequired,
  dyRow1: PropTypes.string,
  dyRow2: PropTypes.string,
  labelField: PropTypes.string,
  labelField2: PropTypes.string,
};

BluAxisLabel.defaultProps = {
  dyRow1: FIRST_LABEL_ROW_DY,
  dyRow2: SECOND_LABEL_ROW_DY,
  labelField: "name",
  labelField2: "",
};

export default BluAxisLabel;
