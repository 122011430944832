export const reportRequestSchema = {
  $id: "/report/execute",
  type: "object",
  properties: {
    category: { type: "number" },
    logTypes: { type: "array" },
    selectedColumns: { type: "array" },
    timeStart: { type: "string" },
    timeEnd: { type: "string" },
    columns: { type: "array" },
  },
  additionalProperties: false,
};

export const reportSchema = {
  $id: "/report",
  type: "object",
  properties: {
    created: { type: "string", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    //dataSource: {'$ref': '/dataSource'},
    dataSource: { type: "string", enum: ["finding", "bq"] },
    dataQueryId: { type: "number" },
    dayOfMonth: { type: ["number", "null"], minimum: 0, maximum: 31 },
    dayOfWeek: { type: ["number", "null"], minimum: 0, maximum: 7 },
    deleted: { type: "string", readOnly: true },
    deletedBy: { type: "string", format: "uuid", readOnly: true },
    id: { type: "string", format: "uuid", readOnly: true },
    modified: { type: "string", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    name: { type: "string", minLength: 2, maxLength: 255 },
    orgId: { type: "string", readOnly: true },
    outputType: { type: "string" },
    period: {
      type: "string",
      enum: [
        "last-1-days",
        "last-7-days",
        "last-30-days",
        "previous-week",
        "previous-month",
      ],
    },
    recipients: {
      type: "array",
      minItems: 1,
      items: {
        type: "string",
        format: "email",
      },
    },
    timeOfDay: { type: "number", minimum: 0, maximum: 23 },
  },
  required: ["dataQueryId", "name"],
  additionalProperties: false,
};
