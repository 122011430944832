import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import _ from "lodash";

import "d3-transition";
import { geoNaturalEarth1, geoPath } from "d3-geo";
import { schemeReds } from "d3-scale-chromatic";
import { scaleThreshold } from "d3-scale";
import { legendColor } from "d3-svg-legend";
import { select } from "d3-selection";

import SVGContainer from "./SVGContainer";

import { countryGeometry } from "./sampleData";

const PREFIX = "WorldChoroplethMap";
const classes = {
  toggleLegendText: `${PREFIX}-toggleLegendText`,
  legendThreshold: `${PREFIX}-legendThreshold`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.toggleLegendText}`]: {
    fontSize: "11px",
    cursor: "pointer",
    fontWeight: "bold",
    fill: theme.palette.text.primary,
  },
  [`& .${classes.legendThreshold}`]: {
    fill: theme.palette.text.secondary,
  },
}));

class WorldChoroplethMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLegend: true,
      toolTipVisible: false,
      toolTipContent: null,
    };

    this.colorScheme = schemeReds[6];

    const maxCount = Math.max(
      ...this.props.visualizationData.map((o) => o.count)
    );

    let domainThresholds = [];

    if (maxCount < 100) {
      domainThresholds = this.linearList(maxCount, 6);
    } else {
      domainThresholds = this.exponentialList(maxCount, 6);
    }

    const labels = this.generateThresholdLabels(domainThresholds);

    this.colorScale = scaleThreshold()
      .domain(domainThresholds)
      .range(this.colorScheme);
    this.labels = labels;

    this.getColor = this.getColor.bind(this);
    this.legend = legendColor()
      .labels((d) => this.labels[d.i])
      .shapePadding(4)
      .scale(this.colorScale);

    this.data = _.keyBy(this.props.visualizationData, "code");

    this.setLegend = this.setLegend.bind(this);
  }

  componentDidMount() {
    this.setLegend();
  }

  getColor(d) {
    d.count = _.get(this.data, `${d.id}.count`, 0);
    const color = this.colorScale(d.count);
    return color;
  }

  setLegend() {
    select(this.legendSvg).call(this.legend);
  }

  toggleLegend() {
    this.setState({ showLegend: !this.state.showLegend });
  }

  linearList(max, length) {
    const spacing = Math.floor((max - 1) / (length - 2));
    const list = [];
    let _max = max;

    list.unshift(_max);

    for (let i = length - 2; i > 0; i -= 1) {
      _max -= spacing;
      list.unshift(_max);
    }

    list.unshift(1);

    return list;
  }

  exponentialList(max, length) {
    const div = Math.log10(max);
    const list = [];

    let _max = max;

    list.unshift(_max);

    for (let i = length - 2; i > 0; i -= 1) {
      _max = Math.floor(_max / div);
      list.unshift(_max);
    }

    list.unshift(1);

    return list;
  }

  generateThresholdLabels(domainThresholds) {
    const labels = ["0"];
    const len = domainThresholds.length;
    for (let i = 0; i < len - 1; i += 1) {
      const first = domainThresholds[i];
      let second = domainThresholds[i + 1] - 1;
      if (i === len - 2) {
        second += 1;
      }
      labels.push(`${first}-${second}`);
    }
    return labels;
  }

  render() {
    const { showLegend } = this.state;
    const projection = geoNaturalEarth1();
    const pathGenerator = geoPath().projection(projection);

    const countries = countryGeometry.features.map((d, i) => (
      <path
        key={`path${i}`}
        d={pathGenerator(d)}
        className="countries"
        style={{ fill: this.getColor(d) }}
        onMouseMove={(e) => {
          if (this.state.toolTipVisible) {
            this.setState({
              toolTipX: e.pageX + 10,
              toolTipY: e.pageY,
            });
          }
        }}
        onMouseOver={() => {
          if (d.count !== 0) {
            let countryName = "";
            if (d.properties) {
              countryName = `${d.properties.name}: ` || "";
            }
            this.setState({
              toolTipVisible: true,
              toolTipContent: `${countryName}${d.count}`,
            });
          }
        }}
        onMouseOut={() => {
          this.setState({
            toolTipVisible: false,
          });
        }}
      />
    ));

    const { toolTipVisible, toolTipContent, toolTipX, toolTipY } = this.state;

    const toolTip = (
      <div
        className="world-map-tooltip"
        style={{
          visibility: toolTipVisible && toolTipContent ? "visible" : "hidden",
          left: toolTipX,
          top: toolTipY,
        }}
      >
        {toolTipContent}
      </div>
    );

    return (
      <Root className="chart">
        {toolTip}
        <SVGContainer className="world-map" width="80%" viewBox="100 0 850 450">
          {countries}
        </SVGContainer>
        <SVGContainer className="legend" width="15%" viewBox="0 0 80 180">
          <g>
            <text
              x="0"
              y="10"
              className={classes.toggleLegendText}
              onClick={this.toggleLegend.bind(this)}
            >
              {showLegend ? "Hide" : "Show"} legend
            </text>
          </g>
          <g
            transform="translate(0,25)"
            className={classes.legendThreshold}
            ref={(c) => {
              this.legendSvg = c;
            }}
            style={{ display: showLegend ? "initial" : "none" }}
          />
        </SVGContainer>
      </Root>
    );
  }
}

WorldChoroplethMap.propTypes = {
  visualizationData: PropTypes.arrayOf(PropTypes.shape({})),
};

WorldChoroplethMap.defaultProps = {
  visualizationData: [],
};

export default WorldChoroplethMap;
