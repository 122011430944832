import { BaseModel } from "./BaseModel";
import moment from "moment-timezone";

export class LCAgent extends BaseModel {
  schema = "/lcagent";
  platformMap = {
    268435456: {
      name: "Windows",
      reportId: 500, // "Windows & Blumira Agent Logs' global report
    },
    536870912: {
      name: "Linux",
      reportId: 501, // "Linux Blumira Agent Logs" global report
    },
    805306368: {
      name: "macOS",
      reportId: 502, // "macOS Blumira Agent Logs" global report
    },
  };
  unknownValue = "Unknown";
  constructor(params) {
    return super(params, "/lcagent");
  }

  fields = {
    platformName: () => {
      if (this.params.plat && this.platformMap[this.params.plat]) {
        return this.platformMap[this.params.plat].name;
      } else {
        return this.unknownValue;
      }
    },
    platformReport: () => {
      if (this.params.plat && this.platformMap[this.params.plat]) {
        return this.platformMap[this.params.plat].reportId;
      }
    },
    keyname: () => {
      if (this.params.keyname) {
        return this.params.keyname;
      } else {
        return this.unknownValue;
      }
    },
  };

  set(params, validate) {
    if (params.alive) {
      params.alive = moment(params.alive).toISOString();
    }
    return super.set(params, validate);
  }
}
