import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import _ from "lodash";

import { pie, arc } from "d3-shape";

import SVGContainer from "./SVGContainer";

const PREFIX = "PieChart";
const classes = {
  legendText: `${PREFIX}-legendText`,
  legendPartText: `${PREFIX}-legendPartText`,
  legendLabelText: `${PREFIX}-legendLabelText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.legendText}`]: {
    fontSize: "15px",
    cursor: "pointer",
    fontWeight: "500",
    fill: theme.palette.text.primary,
  },
  [`& .${classes.legendPartText}`]: {
    fontSize: "13px",
    fontWeight: "400",
    fill: theme.palette.text.secondary,
  },
  [`& .${classes.legendLabelText}`]: {
    fontSize: "13px",
    fontWeight: "300",
    cursor: "pointer",
    fill: theme.palette.text.primary,
  },
}));

const colorScheme = {
  "threats:p1": "#c0392b",
  "suspects:p1": "#646464",
  "threats:p2": "#e67e22",
  "suspects:p2": "#A4A4A4",
  "threats:p3": "#f1c40f",
  "suspects:p3": "#D1D1D1",
};

const PieChart = (props) => {
  const [visualizationData, setVisualizationData] = useState(
    props.visualizationData
  );

  useEffect(() => {
    setVisualizationData(props.visualizationData);
  }, [props.visualizationData]);

  const renderPieChart = (data) => {
    const w = 90;

    const outerRadius = w / 2;
    const innerRadius = outerRadius - 15;

    const pieShape = pie()
      .value((d) => d.percent)
      .sort(null)
      .padAngle(0.0);

    const arcShape = arc().outerRadius(outerRadius).innerRadius(innerRadius);

    return (
      <SVGContainer width="30%" className="donut">
        <g transform="translate(50, 50)">
          {_.map(pieShape(data), (d) => (
            <path
              key={d.data.name}
              d={arcShape(d)}
              fill={colorScheme[d.data.name]}
            />
          ))}
        </g>
      </SVGContainer>
    );
  };

  const renderLegendPartFromData = (data, category, legendLabel, yOffset) => {
    const legendKeys = data
      .filter((d) => d.category === category)
      .map((d, i) => {
        const keysXOffset = 50 * i;

        return (
          <g
            key={`pie-chart-${category}-key-${i}`}
            transform={`translate(${keysXOffset}, 15)`}
          >
            <rect
              x="0"
              y="0"
              width="20"
              height="20"
              fill={colorScheme[d.name]}
            />
            <text x="25" y="16" className={classes.legendPartText}>
              {d.value.toUpperCase()}
            </text>
          </g>
        );
      });

    return (
      <g transform={`translate(0, ${yOffset})`}>
        <text className={classes.legendLabelText}>{legendLabel}</text>
        {legendKeys}
      </g>
    );
  };

  const renderLegend = (data) => {
    const legendTitle = (
      <text x="0" y="0" className={classes.legendPartText}>
        Priority
      </text>
    );

    return (
      <SVGContainer className="legend" width="40%" viewBox="0 0 200 200">
        <g transform="translate(0, 20)">
          {legendTitle}
          {renderLegendPartFromData(data, "suspects", "Suspects", 25)}
          {renderLegendPartFromData(data, "threats", "Threats", 90)}
        </g>
      </SVGContainer>
    );
  };

  // stub pie chart data for testing
  // const data = threatStatesData['priority'];

  const data = visualizationData["priority"];

  return (
    <Root className="chart" data-cy={"donutGraph"}>
      {renderPieChart(data)}
      {renderLegend(data)}
    </Root>
  );
};

PieChart.propTypes = {
  visualizationData: PropTypes.shape({}),
};

PieChart.defaultProps = {
  visualizationData: {},
};

export default PieChart;
