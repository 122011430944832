import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { orgIdSelector } from "../../../selectors/orgIdSelector";
import { pageWithPayloadAndQuery } from "../../../redux/actions/Page";
import Logger from "../../../lib/logger";

import DashboardSearch from "./DashboardSearch";
import DashboardTable from "./DashboardTable";

import "./ResourcesDashboard.scss";

const logger = Logger("ResourcesDashboard");

const ResourcesDashboard = (props) => {
  const { gotoLogdev, orgId } = props;

  return (
    <div className="dashboard resources-dashboard">
      <div className="resources-dashboard-title">ResourcesDashboard</div>

      <div className="resources-dashboard-section">
        <DashboardSearch />
      </div>

      <div className="resources-dashboard-section">
        <button
          type="button"
          onClick={() => {
            logger.log("DEBUG_OUTPUT: Goto Logdev-1");

            gotoLogdev(orgId);
          }}
        >
          {"Goto Logdev"}
        </button>
      </div>

      <div className="resources-dashboard-section">
        <DashboardTable data={undefined} />
      </div>
    </div>
  );
};

ResourcesDashboard.propTypes = {
  gotoLogdev: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
};

ResourcesDashboard.defaultProps = {};

const mapStateToProps = (state) => {
  const orgId = orgIdSelector(state);
  return {
    orgId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  gotoLogdev: (orgId) => {
    logger.log("DEBUG_OUTPUT Goto Logdev-2 orgId", orgId);

    const payload = {
      orgId,
      toplevel: "investigate",
      secondlevel: "logdev",
    };

    dispatch(
      pageWithPayloadAndQuery({
        payload,
        query: {},
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesDashboard);
