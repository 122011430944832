import axios from "axios";
import papa from "papaparse";

import config from "../config";
import BluLicense from "../license";

export class ForbiddenError extends Error {}

export default class API {
  buildUrl(url, id, type) {
    if (url.substring(0, 1) !== "/") {
      url = `/${url}`;
    }
    if (id) {
      url = `${url}/${id}`;
    }
    if (type === "rpc") {
      url = `/rpc${url}`;
    }
    const host =
      config.api ||
      `${window.location.protocol}//${window.location.hostname}:8090`;

    return `${host}${url}`;
  }

  _addParamToUrl(urlP, name, value) {
    // if we're dealing with a list of values
    if (Array.isArray(value)) {
      value.forEach((pValue) => {
        // if the value itself is a list
        if (Array.isArray(pValue)) {
          // papaparse expects an array of arrays
          urlP.append(name, papa.unparse([pValue]));
        } else {
          // if the value is undefined or null, then set the value to an empty string
          if (pValue === undefined || pValue === null) {
            urlP.append(name, "");
          } else {
            urlP.append(name, pValue);
          }
        }
      });
    } else {
      // if the value is undefined or null, then set the value to an empty string
      if (value === undefined || value === null) {
        urlP.append(name, "");
      } else {
        urlP.append(name, value);
      }
    }
  }

  /**
   * Returns URLSearchParams
   * @param {object} params - key:value object of URL params.
   * @param {object} orParams - Array of key:value object to be ORed in URL params.
   */
  buildParams(andParams, orParams = []) {
    const retVal = new URLSearchParams();

    Object.keys(andParams).forEach((pName) => {
      this._addParamToUrl(retVal, pName, andParams[pName]);
    });

    orParams.forEach((orList) => {
      if (orList.length === 1) {
        Object.keys(orList[0]).forEach((pName) => {
          this._addParamToUrl(retVal, pName, orList[0][pName]);
        });
      } else if (orList.length > 1) {
        const leading = orList.pop();
        const orP = {
          name: "",
          value: "",
        };
        Object.keys(leading).forEach((pName) => {
          orP.name = pName;
          orP.value = leading[pName];
        });
        orList.forEach((m) => {
          Object.keys(m).forEach((pName) => {
            orP.value = `${orP.value}|${[pName]}=${m[pName]}`;
          });
        });
        this._addParamToUrl(retVal, orP.name, orP.value);
      }
    });

    return retVal;
  }

  /**
   * Returns one promise for multiple requests
   * @param {array} requests - Array of promises
   */
  request(requests) {
    return axios.all(requests);
  }

  /**
   * POST: Used to create new objects
   * @param {string} url - endpoint URL of object (matches schema $id of model)
   * @param {object} params - object parameters representing a single object
   */
  post(url, params) {
    if (!BluLicense.isAllowedAPI(`${url.substring(1)}.create`)) {
      throw new ForbiddenError("License restrictions prohibit this action");
    }
    return axios.post(this.buildUrl(url), { ...params });
  }

  /**
   * GET: Used to get existing objects
   * @param {string} url - endpoint URL of object (matches schema $id of model)
   * @param {object} params - object parameters representing one or many objects
   */
  get(url, params, orParams) {
    if (!BluLicense.isAllowedAPI(`${url.substring(1)}.read`)) {
      throw new ForbiddenError("License restrictions prohibit this action");
    }
    if (params.id) {
      return axios.get(this.buildUrl(url, params.id));
    } else {
      return axios.get(this.buildUrl(url), {
        params: this.buildParams(params, orParams),
      });
    }
  }

  /**
   * PUT: Used to update existing objects
   * @param {string} url - endpoint URL of object (matches schema $id of model)
   * @param {object} params - object parameters representing a single object
   */
  put(url, params, id) {
    if (!BluLicense.isAllowedAPI(`${url.substring(1)}.update`)) {
      throw new ForbiddenError("License restrictions prohibit this action");
    }
    return axios.put(this.buildUrl(url, id || params.id), params);
  }

  /**
   * DELETE: Used to delete existing objects
   * @param {string} url - endpoint URL of object (matches schema $id of model)
   * @param {string} id - UUID of object, which is appeneded to end of URL
   */
  delete(url, id) {
    if (!BluLicense.isAllowedAPI(`${url.substring(1)}.delete`)) {
      throw new ForbiddenError("License restrictions prohibit this action");
    }
    return axios.delete(this.buildUrl(url, id));
  }

  rpc(url, params, id) {
    return axios.post(this.buildUrl(url, id, "rpc"), { ...params });
  }
}
