import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import SimpleTable from "views/Components/SimpleTable";
import { MSPIntegrationLogo } from "./MSPIntegrationLogo";
import MSPIntegrationDialog from "../../Components/MSP/MSPIntegrationsDialog";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import EditIcon from "@mui/icons-material/Edit";

import moment from "moment-timezone";
import { loadPageData } from "../../../redux/actions/Request";
import { ConnectwiseAPI } from "lib/api/ConnectwiseAPI";

import { getChildOrgs } from "views/Pages/MSPPortal/utils";
import _ from "lodash";
import Codex from "@blumira/blu_constants";

const connectwise = new ConnectwiseAPI();

export const MSPIntegrationsPageView = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [mspIntegrations, setMSPIntegrations] = useState([]);
  const [isAddIntegrationModalOpen, setIsAddIntegrationModalOpen] =
    useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [serviceBoards, setServiceBoards] = useState([]);

  const getServiceBoardsList = async () => {
    const body = await connectwise.getServiceBoards(props.orgId);
    const boards = body.data.boards;

    setServiceBoards(boards);
  };

  useEffect(() => {
    if (props.ready) {
      setMSPIntegrations(props.mspIntegrations);
      setIsLoading(false);

      if (props.currentOrg && props.mspIntegrations.length > 0) {
        getServiceBoardsList();
      }
    }
  }, [props.ready, props.currentOrg, props.mspIntegrations]);

  const renderLogoCell = (column, model) => {
    return (
      <MSPIntegrationLogo
        integrationType={model[column.field]}
        width="98"
        height="45"
      />
    );
  };

  const renderDateCell = (column, model) => {
    const timeValue = model[column.field];
    if (!timeValue) {
      return "Pending";
    }
    return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
  };

  const columns = [
    {
      field: "psaType",
      renderValue: renderLogoCell,
      searchable: false,
    },
    {
      title: "Name",
      field: "psaType",
      searchable: true,
    },
    {
      title: "Type",
      searchable: true,
      // This is a static value instead of a field from the model because it will be the same for all PSAs no matter their type
      renderValue: () => "PSA Ticketing",
    },
    {
      title: "Send Findings",
      field: "enabled",
      searchable: false,
      renderValue: (column, model) =>
        model[column.field] === true ? "Enabled" : "Disabled",
    },
    {
      title: "Last API Connection",
      field: "lastConnection",
      searchable: true,
      renderValue: renderDateCell,
      icon: (column, model) => {
        return (
          <FiberManualRecordIcon
            style={{
              color:
                model.connectionStatus === "OK"
                  ? "#3BB273" // green
                  : "#E76F51", // red
            }}
          />
        );
      },
    },
  ];

  const toggleMSPIntegrationDialog = () => {
    setIsAddIntegrationModalOpen(!isAddIntegrationModalOpen);
  };

  const handleRowClick = (model) => {
    setSelectedModel(model);
    toggleMSPIntegrationDialog();
  };

  const addAnMSPIntegration = () => {
    setSelectedModel(null);
    toggleMSPIntegrationDialog();
  };

  return (
    <>
      <SimpleTable
        isNorthStar
        isFetching={isLoading}
        data={mspIntegrations}
        emptyText={<span>No MSP Integrations added yet</span>}
        columns={columns}
        rowClick={handleRowClick}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add MSP Integration",
            onClick: addAnMSPIntegration,
          },
        ]}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit MSP Integration",
            onClick: (event, model) => {
              handleRowClick(model);
            },
          },
        ]}
      />
      <MSPIntegrationDialog
        open={isAddIntegrationModalOpen}
        toggleModal={toggleMSPIntegrationDialog}
        editingModel={selectedModel}
        reload={(bool) => props.reload(bool)}
        serviceBoards={serviceBoards}
        blumiraOrganizationsList={props.blumiraOrganizationsList}
        findingTypes={props.findingTypes}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
  };
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const orgId = location.payload.orgId;
  const currentOrg = session.settings.userOrgs.find(({ id }) => id === orgId);

  const orgListToFilter = _.get(session, `settings.userOrgs`, []);

  // getChildOrgs expects a a list of orgs and an
  // org id to filter and returns an array of orgs
  const childOrgsList = getChildOrgs(orgListToFilter, orgId);

  const parentOrg = orgListToFilter.find((o) => o.id === orgId);
  const blumiraOrgList = [parentOrg, ...childOrgsList];

  return {
    orgId,
    currentOrg,
    blumiraOrganizationsList: blumiraOrgList,
    findingTypes: Codex.language.models.finding.type,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSPIntegrationsPageView);
