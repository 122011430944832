import React from "react";

import moment from "moment-timezone";

import "./BlockingStatus.scss";

/*
Constants
*/

const STATUS_ONLINE = "blocking-status-online";
const STATUS_OFFLINE = "blocking-status-offline";

/*
Main component
*/

export const BlockingStatus = ({ config }) => {
  const { block, blockEnabled } = config;

  const customCss = blockEnabled ? STATUS_ONLINE : STATUS_OFFLINE;

  const displayDate = moment
    .tz(block, "utc")
    .tz(moment.tz.guess(true))
    .format("LLL z");

  const dateText = `Since ${displayDate}`;

  if (blockEnabled) {
    return (
      <div className="blocking-status">
        <div className="blocking-status-center">
          <span className={`fas fa-circle fa-lg ${customCss}`} />
          <span className="blocking-status-message">Enabled</span>
          <span className="blocking-status-detail">{dateText}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="blocking-status">
      <div className="blocking-status-center">
        <span className={`fas fa-circle fa-lg ${customCss}`} />
        <span className="blocking-status-message">Disabled</span>
        <span className="blocking-status-detail">
          Click Configure to Enable
        </span>
      </div>
    </div>
  );
};
