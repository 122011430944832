import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import BlockedAttacksGraph from "./BlockedAttacksGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "blockedAttacks";
export const GRAPH_TITLE = "Top 10 Blocked Attack Types";
/*
Main component
*/

const BlockedAttacks = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={BlockedAttacksGraph}
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

BlockedAttacks.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

BlockedAttacks.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default BlockedAttacks;
