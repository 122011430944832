import _ from "lodash";
import moment from "moment-timezone";

import Logger from "../../../lib/logger";

const logger = Logger("validators");

const traverse = (flatObj, nestedObj) => {
  _.each(nestedObj, (v, k) => {
    if (!v.field && v.fields) {
      flatObj = traverse(flatObj, v.fields);
    } else {
      flatObj[k] = v;
    }
  });
  return flatObj;
};

const testRequired = async ({ errors, name, value }) => {
  // Note that this returns true if value=[] or value = {}
  if (!value) {
    return {
      ...errors,
      [name]: "Please enter a value",
    };
  }
  return errors;
};

const testNumber = async ({ errors, name, value, props = {} }) => {
  const { min, max, type = "number" } = props;

  let parsedValue = value;

  if (type === "number") {
    parsedValue = Number(value);
  } else if (type === "float") {
    parsedValue = parseFloat(value);
  } else if (type === "int") {
    parsedValue = parseInt(value, 10);
  }

  if (_.isNaN(parsedValue)) {
    return {
      ...errors,
      [name]: "Invalid number",
    };
  }

  if (!_.isUndefined(min) && _.isUndefined(max) && parsedValue < min) {
    return {
      ...errors,
      [name]: `Please enter a value greater than ${min}`,
    };
  }

  if (_.isUndefined(min) && !_.isUndefined(max) && parsedValue > max) {
    return {
      ...errors,
      [name]: `Please enter a value less than ${max}`,
    };
  }

  if (
    !_.isUndefined(min) &&
    !_.isUndefined(max) &&
    (parsedValue > max || parsedValue < min)
  ) {
    return {
      ...errors,
      [name]: `Please enter a value between ${min} and ${max}`,
    };
  }

  return errors;
};

const testEmail = async ({ errors, name, value }) => {
  const match = /^.+@.+\..+$/.test(value);
  if (!match) {
    return {
      ...errors,
      [name]: "Invalid email address",
    };
  }
  return errors;
};

const testAlphaNumericString = async ({ errors, name, value }) => {
  const match = /^[a-zA-Z0-9_.'-]+$/.test(value);
  if (!match) {
    return {
      ...errors,
      [name]: "This value must be only letters, numbers, or one of ' _ . -",
    };
  }
  return errors;
};

const testDate = async ({ errors, name, value, props }) => {
  let format = "MM/DD/YYYY";
  let min;
  let max;
  let isValid = true;
  const dateErrors = [];
  if (_.isString(props)) {
    format = props;
  } else if (_.isObject(props)) {
    if (props.format) {
      format = props.format;
    }
    if (props.min) {
      if (_.isString(props.min)) {
        min = moment(props.min);
      } else if (_.isObject(props.min) && !_.isUndefined(props.min.days)) {
        min = moment().add(props.min.days, "days");
      }
    }
    if (props.max) {
      if (_.isString(props.max)) {
        max = moment(props.max);
      } else if (_.isObject(props.max) && !_.isUndefined(props.max.days)) {
        max = moment().add(props.max.days, "days");
      }
    }
  }
  if (value) {
    const date = moment(value, format);
    if (!date.isValid()) {
      dateErrors.push("Invalid date");
      isValid = false;
    } else {
      if (min && date < min) {
        dateErrors.push("Date is too far in the past");
        isValid = false;
      }
      if (max && date > max) {
        dateErrors.push("Date is too far in the future");
        isValid = false;
      }
    }

    if (!isValid) {
      return {
        ...errors,
        [name]: dateErrors.join(", "),
      };
    }
  }

  return errors;
};

const testPhone = async ({ errors, name, value }) => {
  const match = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(value);
  if (!match) {
    return {
      ...errors,
      [name]: "Invalid phone number",
    };
  }
  return errors;
};

const testJSON = async ({ errors, name, value }) => {
  if (value && _.isString(value)) {
    let json;
    try {
      json = JSON.parse(value);
    } catch (e) {
      // pass
    }
    if (!_.isObject(json)) {
      errors[name] = "Invalid json";
    }
  }
  return errors;
};

const testLength = async ({ errors, name, value, props }) => {
  if (value && _.isString(value)) {
    const { min, max } = props;

    if (min && value.length < min) {
      errors[name] = "Not long enough";
    } else if (max && value.length > max) {
      errors[name] = "Too long";
    }
  }
  return errors;
};

const validators = {
  required: testRequired,
  number: testNumber,
  email: testEmail,
  phone: testPhone,
  date: testDate,
  json: testJSON,
  length: testLength,
  alnumString: testAlphaNumericString,
};

export const validate = async (view = {}, values) => {
  let errors = {};
  const fields = traverse({}, view.fields);
  const keys = Object.keys(fields);

  for (let i = 0; i < keys.length; i += 1) {
    const name = keys[i];
    const field = fields[name];
    const value = values[name];
    const props = {
      ..._.cloneDeep(field.props),
    };
    const { required = false, validation = {} } = props || {};

    let newValidation = validation;
    if (_.isEmpty(value) || _.isNil(value)) {
      newValidation = {};
    }

    if (required) {
      newValidation.required = required;
    }
    const validationKeys = Object.keys(newValidation);
    for (let x = 0; x < validationKeys.length; x += 1) {
      const validator = validators[validationKeys[x]];
      try {
        errors = await validator({
          errors,
          name,
          value,
          values,
          required,
          props: newValidation[validationKeys[x]] || {},
        });
      } catch (err) {
        logger.error(err);
      }
    }
  }

  if (!_.isEmpty(errors)) {
    throw errors;
  }
};

const regexValidate = (value, regex) => {
  if (value !== "" && !regex.test(value)) {
    return false;
  }
  return true;
};

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;

export const validateEmail = (value) => regexValidate(value, emailRegex);

/* ---
makes the following formats passable
'123-456-7890',
'(123) 456-7890',
'123.456.7890',
'1234567890',
'+1 123 456 7890',
'+1 (123) 456-7890',
'+11234567890',
--*/
const phoneRegex =
  /^(?:\+?\d{1,2}[-. ]?)?(?:\(\d{3}\)|\d{3})[-. ]?\d{3}[-. ]?\d{4}$|^\+\d{11}$/;

export const validatePhone = (value) => regexValidate(value, phoneRegex);
