// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { arrayOf, func, node, shape, string } from "prop-types";
import Link from "redux-first-router-link";

// ** Components **********************
import { PrimaryButton, SecondaryButton } from "views/Components/Button";
import { DefaultIcon } from "views/Components/Icon";

// ** Features ************************
import Breadcrumbs from "views/Features/Breadcrumbs";
import { SavedQueryModalList } from "views/Features/ModalList";

// ** Layouts *************************
import { PrimaryModal } from "views/Layouts/Modal";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import {
  ChildContainer,
  Container,
  HeaderColumn,
  HeaderContainer,
  HeaderRow,
  IconContainer,
  PageTitle,
  TitleBadge,
} from "./style";

// *****************************************************************************
// Helpers
// *****************************************************************************
//
//  Returns component given type
//

const generateAction = (action, onToggleModal, orgId) => {
  switch (action.type) {
    case "primary":
      return (
        <PrimaryButton
          key={action.text}
          onClick={() => onToggleModal(action.component)}
          margin="0px 0px 0px 8px"
        >
          {action.icon && (
            <IconContainer>
              <DefaultIcon height="12px" icon={action.icon} />
            </IconContainer>
          )}
          {action.text}
        </PrimaryButton>
      );
    case "link":
      return (
        <Link
          to={{
            type: "PAGE",
            payload: {
              orgId: orgId,
              toplevel: "reporting",
              secondlevel: "scheduledreports",
            },
          }}
        >
          <SecondaryButton key={action.text} margin="0px 0px 0px 8px">
            {action.icon && (
              <IconContainer>
                <DefaultIcon height="12px" icon={action.icon} />
              </IconContainer>
            )}
            {action.text}
          </SecondaryButton>
        </Link>
      );
    default:
      return "button";
  }
};

// *****************************************************************************
// Layout
// *****************************************************************************
//
//  Container layout
//
//  ** Remarks
//  Simple layout component extended to simplify code across the app
//
//  ** Props
//  @param actions {array} - list of container actions
//  @param children {node} - child container contents
//  @param location {obj} - react-router location object
//  @param modalState {obj} - modal info (open flag and component to render)
//  @param onToggleModal {func} - modal flag click handler
//  @param pageTitle {string} - page title
//
export const ContainerLayout = ({
  actions,
  children,
  onToggleModal,
  location,
  modalState,
  pageTitle,
  badge,
}) => (
  <Container>
    <HeaderContainer>
      {pageTitle ? (
        <HeaderColumn>
          <Breadcrumbs path={location.pathname} />
          <PageTitle>
            {pageTitle}
            <TitleBadge>{badge}</TitleBadge>
          </PageTitle>
        </HeaderColumn>
      ) : (
        <div />
      )}
      <HeaderRow>
        {actions.map((action) =>
          generateAction(action, onToggleModal, location.payload.orgId)
        )}
      </HeaderRow>
    </HeaderContainer>
    <ChildContainer>{children}</ChildContainer>
    <PrimaryModal onToggleModal={onToggleModal} open={modalState.open}>
      {modalState.component}
    </PrimaryModal>
  </Container>
);

// ** Proptypes ***********************
ContainerLayout.propTypes = {
  actions: arrayOf(
    shape({
      icon: string,
      text: string,
      type: string,
    })
  ),
  children: node,
  onToggleModal: func,
  location: shape({ pathname: string }),
  modalState: shape({}),
  pageTitle: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Search container ****************
export const SearchContainer = withProps((props) => ({
  actions: [
    { icon: "calendar", type: "link", text: "Scheduled Reports" },
    {
      component: <SavedQueryModalList {...props} />,
      icon: "save",
      type: "primary",
      text: "Saved Reports",
    },
  ],
  children: props.children,
  location: props.location,
  modalState: props.modalState,
  pageTitle: "Report Builder",
}))(ContainerLayout);

// ** Search results container ********
export const SearchResultsContainer = withProps((props) => ({
  actions: [
    { icon: "calendar", type: "link", text: "Scheduled Reports" },
    {
      component: <SavedQueryModalList {...props} />,
      icon: "save",
      type: "primary",
      text: "Saved Reports",
    },
  ],
  children: props.children,
  location: props.location,
  modalState: props.modalState,
}))(ContainerLayout);
