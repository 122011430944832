import React from "react";
import PropTypes from "prop-types";

import "./ModuleNotes.scss";

const ModuleNotes = ({ moduleNotes, moduleUrl }) => (
  <div className="sensor-module-explanation">
    {moduleNotes && <div className="module-detail-notes">{moduleNotes}</div>}

    {moduleUrl && (
      <div className="sensor-module-url">
        <span> See module </span>
        <a href={moduleUrl} rel="noopener noreferrer" target="_blank">
          documentation
        </a>
        <span> for details.</span>
      </div>
    )}
  </div>
);

ModuleNotes.propTypes = {
  moduleNotes: PropTypes.string,
  moduleUrl: PropTypes.string,
};
ModuleNotes.defaultProps = {
  moduleNotes: "",
  moduleUrl: "",
};

export default ModuleNotes;
