import { createSelector } from "reselect";

import { orgIdSelector } from "./orgIdSelector";
import { securityTimeIntervalSelector as timeIntervalSelector } from "./timeIntervalSelectors";
import { dataForOrgAndTime } from "../views/Pages/Security/common/securityHelpers";
import { ORDERED_GRAPHS } from "../views/Pages/Security/common/securityConfig";

const selectSecurity = (state) => state.security;

/*
"No data for at least one graph" flag
*/

const getFlag = (security, orgId, timeInterval) =>
  ORDERED_GRAPHS.some((key) => {
    const securityData = dataForOrgAndTime({
      data: security[key] || {},
      orgId,
      timeInterval,
    });

    return securityData.length < 1;
  });

export const missingDataFlagSelector = createSelector(
  [selectSecurity, orgIdSelector, timeIntervalSelector],
  getFlag
);

/*
Time window
*/

const getTimeWindow = (security, orgId, timeInterval) => {
  const dates = dataForOrgAndTime({
    data: security.dates || {},
    orgId,
    timeInterval,
  });

  const { windowEnd = "", windowStart = "" } = dates;

  return {
    windowEnd,
    windowStart,
  };
};

export const timeWindowSelector = createSelector(
  [selectSecurity, orgIdSelector, timeIntervalSelector],
  getTimeWindow
);

/*
Security data - all types
*/

const getSecurityData = (orgId, security, timeInterval) => {
  const securityData = Object.keys(security).reduce((accum, key) => {
    accum[key] = dataForOrgAndTime({
      data: security[key] || {},
      orgId,
      timeInterval,
    });

    return accum;
  }, {});

  const { queryDescriptions } = security;
  securityData.queryDescriptions = queryDescriptions;

  return securityData;
};

export const securitySelector = createSelector(
  [orgIdSelector, selectSecurity, timeIntervalSelector],
  getSecurityData
);

/*
Security data for one bar graph
*/

export const getGraphData = (key) => (orgId, security, timeInterval) => {
  const rawData = dataForOrgAndTime({
    data: security[key] || {},
    orgId,
    timeInterval,
  });

  const { queryDescriptions = {} } = security;
  const queryDescription = queryDescriptions[key] || {};
  const { description = "", title = "" } = queryDescription;

  return {
    description,
    rawData,
    title,
  };
};

// Create a selector for each graph
export const graphDataSelectors = ORDERED_GRAPHS.reduce((accum, key) => {
  accum[key] = createSelector(
    [orgIdSelector, selectSecurity, timeIntervalSelector],
    getGraphData(key)
  );

  return accum;
}, {});

export const getGraphDataSelector = (key) =>
  createSelector(
    [orgIdSelector, selectSecurity, timeIntervalSelector],
    getGraphData(key)
  );
