import { TaggingAPI } from "../../lib/api";
import Logger from "../../lib/logger";
import Request from "../../lib/api/Request";

import { handleApiError } from "../utils/helpers";

import { actionFailure } from "./Page";

const logger = Logger("Tagging");

// Error
export const TAGGING_CLEAR_ERROR = "TAGGING.CLEAR_ERROR";
export const TAGGING_RECEIVE_ERROR = "TAGGING.RECEIVE_ERROR";

// Loading
export const TAGGING_CLEAR_LABELS_LOADING = "TAGGING.CLEAR_LABELS_LOADING";
export const TAGGING_SET_LABELS_LOADING = "TAGGING.SET_LABELS_LOADING";
export const TAGGING_CLEAR_TAGS_LOADING = "TAGGING.CLEAR_TAGS_LOADING";
export const TAGGING_SET_TAGS_LOADING = "TAGGING.SET_TAGS_LOADING";

// Tags
export const RECEIVE_TAGS = " TAGGING.RECEIVE_TAGS";

// Labels
export const RECEIVE_LABELS = "TAGGING.RECEIVE_LABELS";

// Entire store
export const RESET_TAGGING = "TAGGING.RESET_TAGGING";

// Other constants
export const SYSTEM_LABELS_KEY = "system";

const API = {
  tagging: new TaggingAPI(),
};

/*
Helpers
*/

/*
Returns an object of the form
{ orgId1: [labels for orgId1], orgId2: [labels for orgId2], ...},
where orgIdn is an org ID or SYSTEM_LABELS_KEY.
If orgId is provided, the returned object will always have orgId key.
*/
const makeLabels = ({ labels, orgId }) => {
  const labelsObj = {};

  if (orgId) {
    labelsObj[orgId] = [];
  }

  labels.forEach((elt) => {
    const key = elt.orgId ? elt.orgId : SYSTEM_LABELS_KEY;
    if (labelsObj[key]) {
      labelsObj[key].push(elt);
    } else {
      labelsObj[key] = [elt];
    }
  });

  return labelsObj;
};

/*
Returns an object of the form
{ objId1: [tags for orgId1], objId2: [tags for orgId2], ...},
where objIdn is an object ID.
Arguments:
  objId - an arrays of object IDs
  tags - the complete list of tags for the objects in objId
    (and possibly for other objects)
*/
const makeTags = ({ tags, objId }) => {
  const tagsObj = {};

  // For each id in objId, set the tag array to [] - when tags are fetched
  // and the object has no tags, object's ID does not appear in tags.
  if (Array.isArray(objId)) {
    objId.forEach((elt) => {
      tagsObj[elt] = [];
    });
  }

  tags.forEach((elt) => {
    const key = elt.foreignId;
    if (tagsObj[key]) {
      tagsObj[key].push(elt);
    } else {
      tagsObj[key] = [elt];
    }
  });

  return tagsObj;
};

/*
Error actions
*/

const receiveErr = (error) => ({
  type: TAGGING_RECEIVE_ERROR,
  error,
});

const clearErr = {
  type: TAGGING_CLEAR_ERROR,
};

/*
Loading actions
*/

const setLabelsLoading = {
  type: TAGGING_SET_LABELS_LOADING,
};

/*
Dispatches both, setLabelsLoading and clearErr
*/
const setLabelsLoadingClearErr = (dispatch) => {
  dispatch(setLabelsLoading);
  dispatch(clearErr);
};

const setTagsLoading = {
  type: TAGGING_SET_TAGS_LOADING,
};

const clearTagsLoading = {
  type: TAGGING_CLEAR_TAGS_LOADING,
};

/*
Dispatches both, setLabelsLoading and clearErr
*/
const setTagsLoadingClearErr = (dispatch) => {
  dispatch(setTagsLoading);
  dispatch(clearErr);
};

/*
Labels actions
*/

// Fetches labels for orgId
export const fetchLabels = (orgId) => (dispatch) => {
  setLabelsLoadingClearErr(dispatch);
  const request = new Request("/label");
  return request
    .get()
    .then((labels) => {
      dispatch({
        type: RECEIVE_LABELS,
        labels: makeLabels({ labels }),
      });
    })
    .catch((err) => {
      const message = "Failed to load labels";
      dispatch(
        handleApiError({
          message,
          err,
          onError: actionFailure,
        })
      );
    });
};

// Fetches labels for orgId and sets page store's 'loading' and 'error' fields
export const fetchLabelsForPage = (orgId) => (dispatch) => {
  dispatch({ type: "PAGE_FETCH" });

  return dispatch(fetchLabels(orgId))
    .then(() => dispatch({ type: "PAGE_SUCCESS" }))
    .catch((err) => {
      logger.error("Failed to fetch labels", err);

      dispatch({ type: "PAGE_ERROR", error: err });
    });
};

const createLabel = (label) => (dispatch) => {
  dispatch(clearErr);
  label
    .create()
    .then(() => dispatch(fetchLabels()))
    .catch((err) => {
      const message = "Failed to create label";
      dispatch(
        handleApiError({
          message,
          err,
          onError: receiveErr,
        })
      );
    });
};

export const createLabelAndFetch =
  ({ label }) =>
  (dispatch) =>
    dispatch(createLabel(label))
      .then(() => dispatch(fetchLabelsForPage()))
      .catch((err) => {
        logger.error("Failed to fetch labels", err);
      });

const updateLabel = (label) => (dispatch) => {
  dispatch(clearErr);
  label
    .update()
    .then(() => dispatch(fetchLabels()))
    .catch((err) => {
      const message = "Failed to update label";
      dispatch(
        handleApiError({
          message,
          err,
          onError: actionFailure,
        })
      );
    });
};

export const updateLabelAndFetch =
  ({ label }) =>
  (dispatch) =>
    dispatch(updateLabel(label))
      .then(() => dispatch(fetchLabelsForPage()))
      .catch((err) => {
        logger.error("Failed to fetch labels", err);
      });

/*
Tag actions
*/
const receiveTags = (tags) => ({
  type: RECEIVE_TAGS,
  tags,
});

/*
Entire store
*/
export const resetTagging = {
  type: RESET_TAGGING,
};

/*
Fetches the tags for all objects of type in `objType` with IDs in `objId`
*/
export const fetchTags =
  ({ objType, objId }) =>
  (dispatch) => {
    setTagsLoadingClearErr(dispatch);

    return API.tagging
      .listTagsWrapper({ objType, objId })
      .then((tags) => {
        const tagsObj = makeTags({ tags, objId });

        return dispatch(receiveTags(tagsObj));
      })
      .catch((err) => {
        dispatch(clearTagsLoading);

        const message = "Unable to fetch tags.";

        dispatch(handleApiError({ message, err, onError: receiveErr }));
      });
  };

const createTag =
  ({ objId, objType, labelId, removalRestricted }) =>
  (dispatch) => {
    dispatch(clearErr);

    return API.tagging
      .createTagWrapper({
        objId,
        objType,
        labelId,
        removalRestricted,
      })
      .catch((err) => {
        const message = "Unable to create tag";

        dispatch(handleApiError({ message, err, onError: receiveErr }));
      });
  };

/*
Fetches the tags for one object of type `objType` with ID `objId`
*/
const fetchTagsForObj =
  ({ objType, objId }) =>
  (dispatch) =>
    dispatch(fetchTags({ objType: [objType], objId: [objId] }));

export const createTagAndFetch =
  ({ objId, objType, labelId, removalRestricted }) =>
  (dispatch) =>
    dispatch(
      createTag({
        objId,
        objType,
        labelId,
        removalRestricted,
      })
    ).then(() => dispatch(fetchTagsForObj({ objType, objId })));

const deleteTag = (tagId) => (dispatch) => {
  dispatch(clearErr);

  return API.tagging.deleteTagWrapper(tagId).catch((err) => {
    const message = "Unable to delete tag";

    dispatch(handleApiError({ message, err, onError: receiveErr }));
  });
};

export const deleteTagAndFetch =
  ({ tagId, objType, objId }) =>
  (dispatch) =>
    dispatch(deleteTag(tagId)).then(() =>
      dispatch(fetchTagsForObj({ objType, objId }))
    );

/*
Combined Labels and Tags actions
*/

export const fetchLabelsAndTagsForObj =
  ({ objType, objId, orgId }) =>
  (dispatch) =>
    dispatch(fetchLabels(orgId)).then(() =>
      dispatch(fetchTagsForObj({ objType, objId }))
    );

const createLabelAndTag =
  ({ objId, objType, label, removalRestricted }) =>
  (dispatch) => {
    dispatch(clearErr);

    return dispatch(createLabel(label))
      .then((lbl) => {
        const labelId = lbl.id;

        return dispatch(
          createTag({
            objId,
            objType,
            labelId,
            removalRestricted,
          })
        );
      })
      .catch((err) => {
        logger.error("Failed to create label or tag", err);
      });
  };

export const createLabelAndTagAndFetch =
  ({ objId, objType, label, removalRestricted, orgId }) =>
  (dispatch) =>
    dispatch(
      createLabelAndTag({
        objId,
        objType,
        label,
        removalRestricted,
        orgId,
      })
    )
      .then(() => dispatch(fetchLabelsAndTagsForObj({ objType, objId, orgId })))
      .catch((err) => {
        logger.error("Failed to create label or tag", err);
      });
