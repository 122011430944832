import { connect } from "react-redux";
import LoadingView from "./LoadingView";

const mapStateToProps = (state) => {
  const { error, redirect } = state.global;
  return {
    show: error || redirect ? false : true,
    error,
    redirect,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingView);
