import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const PREFIX = "FunnelVisualization";
const classes = {
  baseContainer: `${PREFIX}-baseContainer`,
  funnelContainer: `${PREFIX}-funnelContainer`,
  funnelTitle: `${PREFIX}-funnelTitle`,
  dataText: `${PREFIX}-dataText`,
  baseFunnel: `${PREFIX}-baseFunnel`,
  firstFunnel: `${PREFIX}-firstFunnel`,
  secondFunnel: `${PREFIX}-secondFunnel`,
  thirdFunnel: `${PREFIX}-thirdFunnel`,
  fourthFunnel: `${PREFIX}-fourthFunnel`,
  triangleRight: `${PREFIX}-triangleRight`,
  tallTriangle: `${PREFIX}-tallTriangle`,
  mediumTriangle: `${PREFIX}-mediumTriangle`,
  shortTriangle: `${PREFIX}-shortTriangle`,
  lightGreyBackground: `${PREFIX}-lightGreyBackground`,
  mediumGreyBackground: `${PREFIX}-mediumGreyBackground`,
  darkGreyBackground: `${PREFIX}-darkGreyBackground`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.baseContainer}`]: {
    minHeight: 120,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.funnelContainer}`]: {
    padding: 5,
    width: "100%",
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
  },
  [`& .${classes.funnelTitle}`]: {
    fontSize: 11,
    marginBottom: 0,
    paddingRight: theme.shape.padding,
  },
  [`& .${classes.dataText}`]: {
    fontSize: 35,
    fontWeight: 400,
    lineHeight: "0.9em",
    marginTop: 6,
    marginBottom: 0,
    wordBreak: "break-all",
  },
  [`& .${classes.baseFunnel}`]: {
    width: "25%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  [`& .${classes.firstFunnel}`]: {
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    padding: `10px 0px 10px ${theme.shape.padding}px`,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.common.black,
  },
  [`& .${classes.secondFunnel}`]: {
    marginLeft: -20,
    padding: `10px 0px 10px ${theme.shape.padding + 20}px`,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  [`& .${classes.thirdFunnel}`]: {
    marginLeft: -20,
    padding: `10px 0px 10px ${theme.shape.padding + 20}px`,
    backgroundColor: theme.palette.grey[400],
    color: theme.palette.common.black,
  },
  [`& .${classes.fourthFunnel}`]: {
    color: "white",
    marginLeft: -20,
    padding: `10px 0px 10px ${theme.shape.padding + 20}px`,
    backgroundColor: "#EA4336", // color from designs
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  [`& .${classes.triangleRight}`]: {
    width: 0,
    height: 0,
    zIndex: 999,
  },
  [`& .${classes.tallTriangle}`]: {
    borderTop: "113px solid transparent",
    borderBottom: "113px solid transparent",
  },
  [`& .${classes.mediumTriangle}`]: {
    borderTop: "82px solid transparent",
    borderBottom: "82px solid transparent",
  },
  [`& .${classes.shortTriangle}`]: {
    borderTop: "55px solid transparent",
    borderBottom: "55px solid transparent",
  },
  [`& .${classes.lightGreyBackground}`]: {
    borderLeft: `20px solid ${theme.palette.grey[50]}`,
  },
  [`& .${classes.mediumGreyBackground}`]: {
    borderLeft: `20px solid ${theme.palette.grey[100]}`,
  },
  [`& .${classes.darkGreyBackground}`]: {
    borderLeft: `20px solid ${theme.palette.grey[400]}`,
  },
}));

// For formatting numbers in our funnel
const formatter = Intl.NumberFormat("en", { notation: "compact" });

const FunnelVisualization = (props) => {
  const [metrics, setMetrics] = useState({
    findings: 0,
    logsTotal: 0,
    blockedEvents: 0,
    threatsAndSuspects: 0,
  });
  useEffect(() => {
    setMetrics({
      findings: formatter.format(props.findings),
      logsTotal: formatter.format(props.logsTotal),
      blockedEvents: formatter.format(props.blockedEvents),
      threatsAndSuspects: formatter.format(props.threats + props.suspects),
    });
  }, [
    props.findings,
    props.logsTotal,
    props.blockedEvents,
    props.threats,
    props.suspects,
  ]);

  const renderTriangleClassNames = () => {
    if (props.integrations.length === 3) {
      return `${classes.triangleRight} ${classes.tallTriangle}`;
    } else if (props.integrations.length === 2) {
      return `${classes.triangleRight} ${classes.mediumTriangle}`;
    } else {
      return `${classes.triangleRight} ${classes.shortTriangle}`;
    }
  };

  return (
    <Root style={{ marginRight: "15px", width: "40%" }}>
      <div
        className={`${classes.baseContainer} ${classes.funnelContainer}`}
        datacy={"funnelVisualization"}
      >
        <div className={`${classes.baseFunnel} ${classes.firstFunnel}`}>
          <p className={classes.funnelTitle}>Raw logs imported last 6 months</p>
          <p className={classes.dataText}>{metrics.logsTotal}</p>
        </div>

        <div
          className={`${renderTriangleClassNames()} ${
            classes.lightGreyBackground
          }`}
        />

        <div className={`${classes.baseFunnel} ${classes.secondFunnel}`}>
          <p className={classes.funnelTitle}>Blocked events</p>
          <p className={classes.dataText}>{metrics.blockedEvents}</p>
        </div>

        <div
          className={`${renderTriangleClassNames()} ${
            classes.mediumGreyBackground
          }`}
        />

        <div className={`${classes.baseFunnel} ${classes.thirdFunnel}`}>
          <p className={classes.funnelTitle}>Suspects identified</p>
          <p className={classes.dataText}>{metrics.threatsAndSuspects}</p>
        </div>

        <div
          className={`${renderTriangleClassNames()} ${
            classes.darkGreyBackground
          }`}
        />

        <div className={`${classes.baseFunnel} ${classes.fourthFunnel}`}>
          <p className={classes.funnelTitle}>Unresolved findings generated</p>
          <p className={classes.dataText}>{metrics.findings}</p>
        </div>
      </div>
    </Root>
  );
};

FunnelVisualization.propTypes = {
  ready: PropTypes.bool.isRequired,
  findings: PropTypes.number.isRequired,
  logsTotal: PropTypes.number.isRequired,
  blockedEvents: PropTypes.number.isRequired,
  threats: PropTypes.number.isRequired,
  suspects: PropTypes.number.isRequired,
  integrations: PropTypes.array.isRequired,
};

FunnelVisualization.defaultProps = {
  ready: false,
  findings: 0,
  logsTotal: 0,
  blockedEvents: 0,
  threats: 0,
  suspects: 0,
  integrations: [],
};

export default FunnelVisualization;
