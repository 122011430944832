import { combineReducers } from "redux";

import {
  REQUEST_BLOCKS,
  RECEIVE_BLOCK,
  RECEIVE_ORG_BLOCKS,
  RESET_BLOCKS,
  RECEIVE_ORG_CONFIG,
  RESET_CONFIG,
  RECEIVE_DEVICES,
  RESET_DEVICES,
  RECEIVE_FEED_PROPERTIES,
  RESET_FEED_PROPERTIES,
} from "./actions/Blocking";
import { entriesFromList } from "./utils/helpers";

/*
Initial state
*/
const initialState = {
  blocks: {
    byId: {},
    byOrg: {},
  },
  config: {
    byOrg: {},
  },
  devices: [],
  feedProperties: [],
};

/*
'blocks' By ID
*/

const initialBlocksById = initialState.blocks.byId;

// Inserts one block into blocks.byId
const receiveOneBlock = (state = initialBlocksById, action) => {
  const { payload } = action;
  const { block } = payload;

  const nextState = { ...state };
  nextState[block.id] = { ...block };

  return nextState;
};

// Inserts blocks into blocks.byId
const addBlocksForOrg = (state = initialBlocksById, action) => {
  const { payload } = action;
  const { blocks } = payload;

  const orgBlocks = entriesFromList(blocks);

  let nextState = { ...state };
  nextState = { ...nextState, ...orgBlocks };

  return nextState;
};

// Resets blocks.byId store
const resetBlockEntries = () => initialBlocksById;

// 'blocks.byId' reducer
const blocksById = (state = initialBlocksById, action) => {
  switch (action.type) {
    case RECEIVE_BLOCK: {
      return receiveOneBlock(state, action);
    }
    case RECEIVE_ORG_BLOCKS: {
      return addBlocksForOrg(state, action);
    }
    case RESET_BLOCKS: {
      return resetBlockEntries();
    }
    default:
      return state;
  }
};

/*
'blocks' By Org
*/

const initialBlocksByOrg = initialState.blocks.byOrg;

const insertOneBlockId = (state = initialBlocksByOrg, action) => {
  const { payload } = action;
  const { block } = payload;
  const { orgId, id: blockId } = block;

  if (!orgId) {
    return state;
  }

  if (state[orgId] && state[orgId].indexOf(blockId) !== -1) {
    return state;
  }

  const nextState = { ...state };
  const orgBlockIds = nextState[orgId] ? [...nextState[orgId]] : [];
  orgBlockIds.push(blockId);

  nextState[orgId] = orgBlockIds.sort();

  return nextState;
};

// Sets blocks.byOrg[orgId] to consist of action.users, completely replacing existing byOrg[orgId] data.
const setBlockListForOrg = (state = initialBlocksByOrg, action) => {
  const { payload } = action;
  const { blocks, orgId } = payload;

  if (!orgId) {
    return state;
  }

  const nextState = {
    ...state,
    [orgId]: blocks,
    isFetching: false,
  };

  return nextState;
};

const requestBlocks = (state) => {
  return {
    ...state,
    isFetching: true,
  };
};

// Resets the blocks.byOrg store
const resetBlockListForOrg = () => initialBlocksByOrg;

// blocks.byOrg reducer
const blocksByOrg = (state = initialBlocksByOrg, action) => {
  switch (action.type) {
    case REQUEST_BLOCKS: {
      return requestBlocks(state);
    }
    case RECEIVE_BLOCK:
      return insertOneBlockId(state, action);
    case RECEIVE_ORG_BLOCKS: {
      return setBlockListForOrg(state, action);
    }
    case RESET_BLOCKS: {
      return resetBlockListForOrg();
    }
    default:
      return state;
  }
};

const BlocksReducer = combineReducers({
  byId: blocksById,
  byOrg: blocksByOrg,
});

/*
'config' By Org
*/

const initialConfigByOrg = initialState.config.byOrg;

// Receives 'config.byOrg'
const setConfigForOrg = (state = initialConfigByOrg, action) => {
  const { payload } = action;
  const { config, orgId } = payload;

  if (!orgId) {
    return state;
  }

  const nextState = { ...state };

  nextState[orgId] = { ...config };

  return nextState;
};

// Resets the config.byOrg store
const resetConfigByOrg = () => initialConfigByOrg;

// config.byOrg reducer
const configByOrg = (state = initialConfigByOrg, action) => {
  switch (action.type) {
    case RECEIVE_ORG_CONFIG: {
      return setConfigForOrg(state, action);
    }
    case RESET_CONFIG: {
      return resetConfigByOrg();
    }
    default:
      return state;
  }
};

const ConfigReducer = combineReducers({
  byOrg: configByOrg,
});

/*
'devices'
*/

const initialDevices = initialState.devices;

// Receives 'devices'
const setDevices = (action) => {
  const { payload } = action;
  const { devices } = payload;

  const nextState = [...devices];

  return nextState;
};

// Resets 'devices' store
const resetDevices = () => initialDevices;

// 'devices' reducer
const DevicesReducer = (state = initialDevices, action) => {
  switch (action.type) {
    case RECEIVE_DEVICES: {
      return setDevices(action);
    }
    case RESET_DEVICES: {
      return resetDevices();
    }
    default:
      return state;
  }
};

/*
'feedProperties'
*/

const initialFeedProperties = initialState.feedProperties;

// Receives 'feedProperties'
const setFeedProperties = (action) => {
  const { payload } = action;
  const { feedProperties } = payload;

  const nextState = [...feedProperties];

  return nextState;
};

// Resets 'feedProperties' store
const resetFeedProperties = () => initialFeedProperties;

// 'feedProperties' reducer
const FeedPropertiesReducer = (state = initialFeedProperties, action) => {
  switch (action.type) {
    case RECEIVE_FEED_PROPERTIES: {
      return setFeedProperties(action);
    }
    case RESET_FEED_PROPERTIES: {
      return resetFeedProperties();
    }
    default:
      return state;
  }
};

/*
Combined 'blocking' reducer
*/

const BlockingReducer = combineReducers({
  blocks: BlocksReducer,
  config: ConfigReducer,
  devices: DevicesReducer,
  feedProperties: FeedPropertiesReducer,
});

export default BlockingReducer;
