import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { pipelineMetricsRowSelector } from "../../../selectors/pipelineSelectors";
import MetricsLastReceived from "./MetricsLastReceived";
import MetricsTable from "./MetricsTable";

const PipelineMetrics = ({ pipelineMetrics = {} }) => (
  <React.Fragment>
    <MetricsLastReceived />

    <MetricsTable data={pipelineMetrics} />
  </React.Fragment>
);

const mapStateToProps = (state) => {
  const pipelineMetrics = pipelineMetricsRowSelector(state);

  return {
    pipelineMetrics,
  };
};

PipelineMetrics.propTypes = {
  pipelineMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(mapStateToProps, null)(PipelineMetrics);
