import React from "react";
import PropTypes from "prop-types";

import DataGrid from "../../Components/DataGrid";

/*
Helpers
*/

const NO_DATA_FOUND_TEXT_1 = "No modules installed.";
const NO_DATA_FOUND_TEXT_2 =
  "Without modules, sensor will not collect data." +
  " Please at least add the Logger Module.";

const BluNoDataComponent = ({ text1, text2 }) => (
  <div className="sensor-modules-none-found">
    <span className="none-found-bold">{text1}</span>
    <span>{text2}</span>
  </div>
);

BluNoDataComponent.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
};

BluNoDataComponent.defaultProps = {
  text1: NO_DATA_FOUND_TEXT_1,
  text2: NO_DATA_FOUND_TEXT_2,
};

/*
Main component
*/

const ModulesTable = (props) => {
  const { data = [], gotoModule } = props;

  const getTdProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.original) {
      return {
        onClick: (evt) => {
          evt.stopPropagation();
          if (rowInfo.original.installId) {
            gotoModule(rowInfo.original.installId);
          }
        },
        style: {
          cursor: "pointer",
        },
      };
    }
    return {};
  };

  const showTable = data && data.length > 0;

  return (
    <div className="sensor-modules-table">
      {!showTable && <BluNoDataComponent />}

      {showTable && (
        <DataGrid
          data={data}
          columns={[
            {
              Header: "Status",
              accessor: "statusAccessor",
              filterable: false,
              maxWidth: 95,
              minWidth: 60,
              Cell: (row) => row.original.status,
            },
            {
              Header: "Name",
              accessor: "nameAccessor",
              filterable: false,
              minWidth: 95,
              Cell: (row) => row.original.name,
            },
            {
              Header: "Version",
              accessor: "versionAccessor",
              filterable: false,
              maxWidth: 70,
              Cell: (row) => row.original.version,
            },
            {
              Header: "Ports",
              filterable: false,
              sortable: false,
              minWidth: 130,
              Cell: (row) => row.original.ports,
            },
            {
              Header: "Config",
              filterable: false,
              sortable: false,
              minWidth: 130,
              Cell: (row) => row.original.config,
            },
            {
              Header: "",
              filterable: false,
              sortable: false,
              minWidth: 70,
              Cell: (row) => row.original.edit,
            },
          ]}
          getTdProps={getTdProps}
        />
      )}
    </div>
  );
};

ModulesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  gotoModule: PropTypes.func,
};

ModulesTable.defaultProps = {
  data: [],
  gotoModule: () => {},
};

export default ModulesTable;
