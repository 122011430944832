import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Card, Typography, CardContent } from "@mui/material";

import Link from "redux-first-router-link";

import { ORDERED_GRAPHS } from "./common/securityConfig";
import { securitySelector } from "../../../selectors/securitySelectors";

import Attacks from "./Attacks";
import BlockedAttacks from "./BlockedAttacks";
import Devices from "./Devices";
import EndpointAttacks from "./EndpointAttacks";
import EndpointEvents from "./EndpointEvents";
import EndpointHosts from "./EndpointHosts";
import EndpointVictims from "./EndpointVictims";
import EgressTraffic from "./EgressTraffic";
import FailedLogins from "./FailedLogins";
import IngressTraffic from "./IngressTraffic";
import Intruders from "./Intruders";
import LoggingEndpoints from "./LoggingEndpoints";
import Victims from "./Victims";

import "./Graphs.scss";

/*
Constants
*/
const GRAPHS = {
  attackTypes: { graph: Attacks, clickId: 402 },
  blockedAttacks: { graph: BlockedAttacks, clickId: 403 },
  egressTraffic: { graph: EgressTraffic, logScale: true, clickId: 410 },
  endpointAttacks: { graph: EndpointAttacks, clickId: 406 },
  endpointEvents: { graph: EndpointEvents, clickId: 407 },
  endpointHosts: { graph: EndpointHosts, logScale: true, clickId: 408 },
  endpointVictims: { graph: EndpointVictims, clickId: 406 },
  failedLogins: { graph: FailedLogins, clickId: 400 },
  ingressTraffic: { graph: IngressTraffic, logScale: true, clickId: 409 },
  intruders: { graph: Intruders, clickId: 405 },
  loggingEndpoints: { graph: LoggingEndpoints, clickId: 401 },
  loggingDevices: { graph: Devices, clickId: 411 },
  victims: { graph: Victims, clickId: 404 },
};

/*
Helpers
*/

// Returns one graph
const getGraph = (key, index, securityData, orgConfig, ready) => {
  if (!securityData[key].length) {
    return null;
  }

  // Get the graph component
  const graphObject = GRAPHS[key];

  const { graph: GraphComponent, logScale } = graphObject;

  // Generate the title
  const { queryDescriptions = {} } = securityData;
  const { [key]: description = {} } = queryDescriptions;
  let { title = "" } = description;
  if (logScale) {
    title += " (logarithmic scale)";
  }

  const queryOverride = {
    "created.gt": securityData.dates.windowStart,
    "created.lt": securityData.dates.windowEnd,
  };

  const clickPayload = {
    type: "PAGE",
    payload: {
      orgId: orgConfig.id,
      toplevel: "reporting",
      secondlevel: "builder",
      id1:
        orgConfig.config && orgConfig.config.demo ? false : graphObject.clickId,
      query: queryOverride,
    },
  };

  // Instantiate the graph
  const graph = (
    <Card
      sx={{ maxWidth: 420 }}
      style={{ margin: "15px" }}
      key={key}
      datacy={`securitySmallGraph-${index}`}
    >
      <CardContent>
        {clickPayload.payload.id1 ? (
          <Link to={clickPayload}>
            <Typography
              sx={{ fontSize: 14 }}
              color="textSecondary"
              gutterBottom
            >
              {title}
            </Typography>
          </Link>
        ) : (
          <Typography sx={{ fontSize: 14 }} color="textSecondary" gutterBottom>
            {title}
          </Typography>
        )}
        <GraphComponent />
      </CardContent>
    </Card>
  );

  return graph;
};

/*
Main component
*/

const SmallGraphs = ({ securityData, orgConfig, ready }) => {
  return (
    <div className="security-two-column-div">
      {!!ready &&
        ORDERED_GRAPHS.map((key, index) =>
          getGraph(key, index, securityData, orgConfig, ready)
        )}
    </div>
  );
};

SmallGraphs.propTypes = {
  securityData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => {
  const securityData = securitySelector(state);
  return {
    securityData,
    orgConfig: state.session.settings.userOrgs.find(
      (o) => o["id"] === state.location.payload.orgId
    ),
  };
};

export default connect(mapStateToProps, null)(SmallGraphs);
