import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

// ** Components **********************
import { DefaultIcon } from "views/Components/Icon";

// ** Style ***************************
import { IconContainer, ListItemContainer } from "./style";

const ListItem = ({ id, children, index, moveCard }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "card",
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [, drag] = useDrag({
    item: { type: "card", id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <ListItemContainer ref={ref}>
      <IconContainer>
        <DefaultIcon height="14px" icon="drag" />
      </IconContainer>
      {children}
    </ListItemContainer>
  );
};
export default ListItem;
