import {
  IntegrationTypeAWS,
  IntegrationTypeDuo,
  IntegrationTypeMimecast,
  IntegrationTypeO365,
  IntegrationTypeSentinelOne,
  IntegrationTypeWebroot,
  IntegrationTypeUmbrella,
  IntegrationTypeGoogle,
  IntegrationTypeGCP,
  IntegrationTypeSophos,
  IntegrationTypeCarbonBlack,
  IntegrationTypeJumpCloud,
  IntegrationTypeOneLogin,
  IntegrationTypeAzHub,
  IntegrationTypeOnePass,
  IntegrationTypeCrowdstrike,
  IntegrationTypeMscloud,
} from "../constants";

export const cloudModuleConfigFieldLabels = {
  [IntegrationTypeDuo]: {
    secret: {
      ikey: "Duo-provided Integration Key",
      skey: "Duo-provided Secret Key",
      api_hostname: "Duo-provided API Hostname",
    },
  },
  [IntegrationTypeO365]: {
    secret: {
      client_id: "Application (client) ID",
      tenant_id: "Directory (tenant) ID",
      client_secret: 'Client Secret "Value"',
      is_gcc: "My version of M365 is GCC High.",
    },
  },
  [IntegrationTypeAzHub]: {
    secret: {
      cs: "Azure Hub Connection String",
      eh: "Azure Event Hub Name",
    },
  },
  [IntegrationTypeAWS]: {
    secret: {
      aws_access_key: "Access Key ID",
      aws_secret_access_key: "Secret Access Key",
      aws_region: "AWS Region",
    },
    configuration: {
      stream_name: "Stream Name",
    },
  },
  [IntegrationTypeSentinelOne]: {
    secret: {
      management_url: "Management URL",
      api_token: "API Token",
      account_id: "Account ID",
      site_id: "Site ID",
    },
  },
  [IntegrationTypeMimecast]: {
    secret: {
      management_url: "Global Base Root URL",
      app_id: "Application ID",
      app_key: "Application Key",
      access_key: "Access Key",
      secret_key: "Secret Key",
    },
  },
  [IntegrationTypeWebroot]: {
    secret: {
      client_id: "Client ID",
      client_secret: "Client Secret",
      site_name: "Site Name",
      parent_key_code: "Site Parent Keycode",
      admin_username: "Admin Username",
      admin_password: "Admin Password",
    },
  },
  [IntegrationTypeUmbrella]: {
    secret: {
      org_id: "Organization ID",
      org_key: "API Token",
      org_secret: "API Secret",
      loc: "Region",
    },
  },
  [IntegrationTypeGoogle]: {
    secret: {
      admin_email: "Admin Email",
      json_data: "Account Credential JSON",
    },
  },
  [IntegrationTypeGCP]: {
    secret: {
      admin_email: "Admin Email",
      json_data: "Account Credential JSON",
    },
    configuration: {
      project_name: "Project Name",
    },
  },
  [IntegrationTypeSophos]: {
    secret: {
      client_id: "Client ID",
      client_secret: "Client Secret",
    },
  },
  [IntegrationTypeCarbonBlack]: {
    secret: {
      org_url: "API Host",
      org_key: "Org Key",
      secret_id: "App ID",
      secret_key: "API Secret Key",
    },
  },
  [IntegrationTypeJumpCloud]: {
    secret: {
      api_key: "API Key",
      org_id: "Organization ID",
    },
  },
  [IntegrationTypeOneLogin]: {
    secret: {
      domain: "Domain/URL",
      client_id: "Client ID",
      client_secret: "Client Secret",
    },
  },
  [IntegrationTypeOnePass]: {
    secret: {
      key: "Api Token",
      url: "Url",
    },
  },
  [IntegrationTypeCrowdstrike]: {
    secret: {
      client_id: "Client ID",
      client_secret: "Client Secret",
      client_cloud: "Base URL",
    },
  },
  [IntegrationTypeMscloud]: {
    secret: {
      api_token: "API Token",
      api_url: "API URL",
    },
  },
};
