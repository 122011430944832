import React from "react";
import { StyledSVG, classes } from "./styles";

export function PlatformLogo(props) {
  let svg;
  switch (props.platformType) {
    case "windows":
      svg = (
        <StyledSVG
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.platformIconWrap}
        >
          <path
            className={classes.platformIcon}
            d="M4 6.29095L10.543 5.3929V11.7159H4M11.331 5.2646L20 4V11.6609H11.331M4 12.383H10.543V18.7244L4 17.808M11.331 12.4674H20V20.055L11.331 18.827"
          />
        </StyledSVG>
      );
      break;
    case "mac":
      svg = (
        <StyledSVG
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.platformIconWrap}
        >
          <path
            className={classes.platformIcon}
            d="M19.762 8.818C19.646 8.908 17.598 10.062 17.598 12.628C17.598 15.596 20.204 16.646 20.282 16.672C20.27 16.736 19.868 18.11 18.908 19.51C18.052 20.742 17.158 21.972 15.798 21.972C14.438 21.972 14.088 21.182 12.518 21.182C10.988 21.182 10.444 21.998 9.2 21.998C7.956 21.998 7.088 20.858 6.09 19.458C4.934 17.814 4 15.26 4 12.836C4 8.948 6.528 6.886 9.016 6.886C10.338 6.886 11.44 7.754 12.27 7.754C13.06 7.754 14.292 6.834 15.796 6.834C16.366 6.834 18.414 6.886 19.762 8.818ZM15.082 5.188C15.704 4.45 16.144 3.426 16.144 2.402C16.144 2.26 16.132 2.116 16.106 2C15.094 2.038 13.89 2.674 13.164 3.516C12.594 4.164 12.062 5.188 12.062 6.226C12.062 6.382 12.088 6.538 12.1 6.588C12.164 6.6 12.268 6.614 12.372 6.614C13.28 6.614 14.422 6.006 15.082 5.188Z"
          />
        </StyledSVG>
      );
      break;
    case "linux":
      svg = (
        <StyledSVG
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.platformIconWrap}
        >
          <g clipPath="url(#clip0_4007_13817)">
            <path
              d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
              fill="#020204"
            />
            <path
              d="M10.1441 7.35955C10.0667 7.45666 10.0169 7.57353 9.98596 7.69385C9.95588 7.81416 9.94471 7.93877 9.93955 8.06338C9.9301 8.31174 9.94557 8.56439 9.87939 8.80416C9.80892 9.06025 9.65166 9.28283 9.49783 9.49853C9.23057 9.87322 8.96072 10.2582 8.82924 10.6991C8.74932 10.9655 8.72267 11.2482 8.75103 11.5258C8.45283 11.9641 8.19416 12.4307 7.97931 12.9163C7.65447 13.6528 7.43189 14.4365 7.35369 15.2374C7.25744 16.218 7.38291 17.2269 7.77049 18.1327C8.04978 18.7884 8.46744 19.3874 9.00627 19.8549C9.27955 20.0921 9.58377 20.294 9.90861 20.4538C11.0353 21.0081 12.4274 21.009 13.5352 20.4186C14.1144 20.1101 14.6034 19.6589 15.0743 19.2026C15.3579 18.9276 15.6406 18.6457 15.8572 18.3157C16.274 17.6789 16.4192 16.9055 16.5095 16.1501C16.6667 14.8301 16.6719 13.4345 16.0927 12.2374C15.8933 11.8249 15.6278 11.445 15.3089 11.1159C15.2247 10.5401 15.0571 9.97549 14.8139 9.44697C14.6378 9.06455 14.4238 8.70103 14.2605 8.31432C14.1935 8.15533 14.135 7.99205 14.0611 7.8365C13.9863 7.68096 13.8953 7.53143 13.7698 7.41197C13.6426 7.28994 13.4836 7.20314 13.316 7.14814C13.1485 7.09228 12.9714 7.06736 12.7953 7.05877C12.4421 7.04072 12.0888 7.08713 11.7356 7.07252C11.4546 7.06135 11.1745 7.01236 10.8935 7.02869C10.7534 7.03728 10.6133 7.06221 10.4827 7.11549C10.352 7.16791 10.2317 7.24955 10.1441 7.35955Z"
              fill="#FDFDFB"
            />
            <mask
              id="mask0_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask0_4007_13817)">
              <g opacity="0.25" filter="url(#filter0_f_4007_13817)">
                <path
                  d="M8.90145 10.8982C8.97621 11.0108 8.85418 11.4001 10.6082 11.1509C10.6082 11.1509 10.2996 11.1844 9.99629 11.2549C9.52449 11.413 9.11371 11.5892 8.79574 11.8229C8.48207 12.0549 8.25176 12.3566 7.96387 12.6153C7.96387 12.6153 8.43051 11.7628 8.54652 11.5058C8.6634 11.2488 8.52762 11.2609 8.61957 10.8828C8.71152 10.5046 8.93668 10.1411 8.93668 10.1411C8.93668 10.1411 8.75277 10.6756 8.90145 10.8982Z"
                  fill="url(#paint0_radial_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask1_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_4007_13817)">
              <g opacity="0.42" filter="url(#filter1_f_4007_13817)">
                <path
                  d="M14.54 10.7958C14.1825 11.0451 13.9728 11.0158 13.5396 11.064C13.1057 11.1129 11.9326 11.0949 11.9326 11.0949C11.9326 11.0949 12.1019 11.0923 12.4792 11.1619C12.8556 11.2324 13.623 11.2994 14.0553 11.4636C14.4884 11.6286 14.6457 11.6758 14.9086 11.8417C15.2825 12.078 15.5575 12.4484 15.915 12.7079C15.915 12.7079 15.9321 12.3642 15.7878 12.1072C15.6434 11.8503 15.255 11.4455 15.1407 11.0674C15.0272 10.6893 14.9722 9.94678 14.9722 9.94678C14.9722 9.94678 14.8966 10.5475 14.54 10.7958Z"
                  fill="url(#paint1_radial_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask2_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_4007_13817)">
              <g opacity="0.2" filter="url(#filter2_f_4007_13817)">
                <path
                  d="M11.1788 10.2651C11.165 10.3726 11.1478 10.48 11.1272 10.5865C11.1169 10.639 11.1049 10.6914 11.086 10.7412C11.0662 10.7911 11.0378 10.8392 10.9983 10.8744C10.9631 10.9062 10.921 10.9277 10.878 10.9475C10.7113 11.0231 10.5334 11.0738 10.3521 11.097C10.4242 11.1022 10.4964 11.109 10.5695 11.1168C10.615 11.1219 10.6606 11.1271 10.7044 11.1383C10.7491 11.1503 10.7929 11.1675 10.8299 11.1941C10.8703 11.2242 10.9021 11.2646 10.9261 11.3093C10.9734 11.3969 10.9888 11.4983 10.9974 11.5989C11.0086 11.7261 11.0095 11.855 11 11.9822C11.0086 11.8971 11.0267 11.812 11.0533 11.7312C11.1023 11.5834 11.1796 11.4459 11.2845 11.3316C11.3266 11.2869 11.3721 11.2448 11.422 11.2096C11.5758 11.1013 11.7717 11.054 11.9582 11.0798C11.7683 11.0876 11.5767 11.0283 11.4254 10.914C11.3481 10.8555 11.281 10.7833 11.2346 10.6991C11.1624 10.5685 11.1418 10.4095 11.1788 10.2651Z"
                  fill="url(#paint2_radial_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask3_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask3_4007_13817)">
              <g opacity="0.11" filter="url(#filter3_f_4007_13817)">
                <path
                  d="M10.7218 12.7856C10.6307 13.132 10.568 13.486 10.5353 13.8435C10.488 14.348 10.4992 14.8559 10.4717 15.3621C10.4494 15.7909 10.3987 16.2249 10.4734 16.6485C10.5087 16.8505 10.5723 17.0473 10.6625 17.2312C10.6762 17.1607 10.6874 17.0903 10.6934 17.0189C10.7252 16.6752 10.6677 16.3306 10.6479 15.9868C10.6135 15.3835 10.6969 14.7803 10.7312 14.177C10.757 13.7138 10.7544 13.2488 10.7218 12.7856Z"
                  fill="black"
                />
              </g>
            </g>
            <mask
              id="mask4_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask4_4007_13817)">
              <g opacity="0.75" filter="url(#filter4_f_4007_13817)">
                <path
                  d="M16.7572 12.2776C16.8457 12.2639 17.3879 12.7254 17.314 12.8973C17.2401 13.0683 17.1001 12.9651 17.0004 12.966C16.9007 12.9677 16.6283 13.0915 16.5827 13.0133C16.5363 12.9351 16.703 12.7529 16.7898 12.5999C16.8603 12.477 16.6695 12.2914 16.7572 12.2776Z"
                  fill="#7C7C7C"
                />
              </g>
            </g>
            <mask
              id="mask5_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask5_4007_13817)">
              <g filter="url(#filter5_f_4007_13817)">
                <path
                  d="M13.4433 1.9554C13.2534 2.08946 13.3359 2.25704 13.4175 2.42462C13.4992 2.59306 13.2379 3.07689 13.2354 3.09751C13.2328 3.11814 13.7493 2.85259 13.8902 2.679C14.0569 2.47532 14.4772 2.95571 14.454 2.88267C14.4548 2.75204 13.6333 1.8222 13.4433 1.9554Z"
                  fill="#7C7C7C"
                />
              </g>
            </g>
            <mask
              id="mask6_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask6_4007_13817)">
              <g filter="url(#filter6_f_4007_13817)">
                <path
                  d="M14.8826 7.58523C14.7227 7.73132 14.9582 7.95046 15.0691 8.20741C15.1387 8.36726 15.3303 8.59327 15.5159 8.55718C15.656 8.52968 15.742 8.32859 15.7291 8.18765C15.7033 7.91437 15.473 7.84991 15.3054 7.75624C15.173 7.68234 14.9952 7.48296 14.8826 7.58523Z"
                  fill="#838384"
                />
              </g>
            </g>
            <path
              d="M8.49831 9.67203C7.97409 10.2667 7.43526 10.8434 7.13963 11.487C6.99268 11.8136 6.92393 12.1685 6.82338 12.5123C6.70995 12.9024 6.55526 13.2805 6.38167 13.6484C6.22096 13.9878 6.04393 14.3195 5.86776 14.6504C5.73885 14.8919 5.60823 15.1377 5.56354 15.4075C5.52831 15.6206 5.54807 15.8406 5.60307 16.0495C5.65721 16.2583 5.74401 16.4577 5.84284 16.6493C6.26221 17.47 6.89213 18.1764 7.63807 18.7178C7.97667 18.9627 8.33846 19.175 8.71917 19.3495C8.92542 19.4431 9.14198 19.5273 9.36885 19.5308C9.48229 19.5334 9.59659 19.5145 9.70057 19.4689C9.8037 19.4234 9.89651 19.3503 9.95838 19.2549C10.034 19.138 10.0598 18.9928 10.0443 18.8545C10.0289 18.7161 9.97471 18.5838 9.90252 18.4652C9.72549 18.1747 9.4462 17.9641 9.17721 17.7562C8.59627 17.3067 8.0351 16.8315 7.49713 16.3313C7.34588 16.1895 7.19377 16.0443 7.08807 15.8655C6.98495 15.692 6.92909 15.4943 6.89127 15.2958C6.78729 14.7518 6.81393 14.1795 6.9987 13.657C7.07174 13.4524 7.16713 13.2573 7.25995 13.0614C7.41979 12.722 7.57104 12.3756 7.78159 12.0645C8.0437 11.677 8.39432 11.3487 8.60745 10.9327C8.78792 10.5813 8.86096 10.1868 8.93057 9.79836C8.98557 9.49414 9.08956 9.1968 9.14284 8.89258C9.03885 9.09023 8.7037 9.41422 8.49831 9.67203Z"
              fill="#020204"
            />
            <mask
              id="mask7_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="8"
              width="6"
              height="12"
            >
              <path
                d="M8.49831 9.67203C7.97409 10.2667 7.43526 10.8434 7.13963 11.487C6.99268 11.8136 6.92393 12.1685 6.82338 12.5123C6.70995 12.9024 6.55526 13.2805 6.38167 13.6484C6.22096 13.9878 6.04393 14.3195 5.86776 14.6504C5.73885 14.8919 5.60823 15.1377 5.56354 15.4075C5.52831 15.6206 5.54807 15.8406 5.60307 16.0495C5.65721 16.2583 5.74401 16.4577 5.84284 16.6493C6.26221 17.47 6.89213 18.1764 7.63807 18.7178C7.97667 18.9627 8.33846 19.175 8.71917 19.3495C8.92542 19.4431 9.14198 19.5273 9.36885 19.5308C9.48229 19.5334 9.59659 19.5145 9.70057 19.4689C9.8037 19.4234 9.89651 19.3503 9.95838 19.2549C10.034 19.138 10.0598 18.9928 10.0443 18.8545C10.0289 18.7161 9.97471 18.5838 9.90252 18.4652C9.72549 18.1747 9.4462 17.9641 9.17721 17.7562C8.59627 17.3067 8.0351 16.8315 7.49713 16.3313C7.34588 16.1895 7.19377 16.0443 7.08807 15.8655C6.98495 15.692 6.92909 15.4943 6.89127 15.2958C6.78729 14.7518 6.81393 14.1795 6.9987 13.657C7.07174 13.4524 7.16713 13.2573 7.25995 13.0614C7.41979 12.722 7.57104 12.3756 7.78159 12.0645C8.0437 11.677 8.39432 11.3487 8.60745 10.9327C8.78792 10.5813 8.86096 10.1868 8.93057 9.79836C8.98557 9.49414 9.08956 9.1968 9.14284 8.89258C9.03885 9.09023 8.7037 9.41422 8.49831 9.67203Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask7_4007_13817)">
              <g opacity="0.95" filter="url(#filter7_f_4007_13817)">
                <path
                  d="M7.8949 11.8366C7.72303 11.9948 7.57436 12.1778 7.45404 12.3789C7.25639 12.7089 7.14037 13.0801 6.996 13.4368C6.88943 13.7023 6.76482 13.9696 6.75021 14.2558C6.74248 14.4027 6.76396 14.5497 6.76912 14.6966C6.77428 14.8436 6.76053 14.9966 6.68834 15.1246C6.62818 15.232 6.52764 15.3162 6.41162 15.3575C6.56889 15.4099 6.70811 15.5113 6.80693 15.6437C6.88943 15.7554 6.94271 15.8851 7.01404 16.0029C7.07248 16.0991 7.14381 16.1885 7.23232 16.2581C7.31998 16.3286 7.42568 16.3784 7.53654 16.3922C7.69037 16.4119 7.85021 16.3612 7.96365 16.2564C7.821 14.9201 7.95162 13.5562 8.34521 12.2715C8.37014 12.1907 8.39592 12.1091 8.40279 12.0248C8.40881 11.9398 8.39248 11.8504 8.34092 11.7834C8.31428 11.7473 8.27732 11.7189 8.23521 11.7009C8.19396 11.6837 8.14756 11.6768 8.10287 11.6828C8.05818 11.688 8.01436 11.7051 7.97826 11.7318C7.94217 11.7593 7.91295 11.7954 7.8949 11.8366Z"
                  fill="#7C7C7C"
                />
              </g>
            </g>
            <path
              d="M16.9873 11.9243C17.4376 12.287 17.7238 12.8344 17.8123 13.4059C17.8811 13.851 17.8364 14.3091 17.7358 14.7482C17.6353 15.1882 17.4806 15.6128 17.3259 16.0373C17.2649 16.2057 17.203 16.3767 17.179 16.5546C17.154 16.7334 17.1695 16.9216 17.2554 17.0797C17.3543 17.261 17.5382 17.3857 17.7358 17.4441C17.9309 17.5025 18.1415 17.5008 18.3391 17.4518C18.5368 17.4028 18.8693 17.3392 19.0301 17.2138C19.4383 16.8967 19.5354 16.3458 19.6325 15.8628C19.733 15.3584 19.684 14.8299 19.5955 14.3203C19.4743 13.625 19.2707 12.9461 19.012 12.2904C18.8212 11.8075 18.5772 11.3468 18.2867 10.9189C18.0014 10.4986 17.5975 10.1712 17.2984 9.76042C17.1944 9.61776 17.0698 9.46995 16.9684 9.32558C16.7484 9.01448 16.7983 9.07292 16.6616 8.87097C16.5628 8.72487 16.4064 8.67503 16.2422 8.61143C16.0772 8.54784 15.8933 8.52894 15.7232 8.5762C15.4997 8.63894 15.3167 8.81597 15.2196 9.02651C15.1225 9.23792 15.1053 9.48026 15.1431 9.70972C15.1921 10.0053 15.327 10.2803 15.48 10.5382C15.6518 10.8295 15.8512 11.1105 16.1116 11.3271C16.3832 11.5531 16.7115 11.7026 16.9873 11.9243Z"
              fill="#020204"
            />
            <mask
              id="mask8_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="15"
              y="8"
              width="5"
              height="10"
            >
              <path
                d="M16.9873 11.9243C17.4376 12.287 17.7238 12.8344 17.8123 13.4059C17.8811 13.851 17.8364 14.3091 17.7358 14.7482C17.6353 15.1882 17.4806 15.6128 17.3259 16.0373C17.2649 16.2057 17.203 16.3767 17.179 16.5546C17.154 16.7334 17.1695 16.9216 17.2554 17.0797C17.3543 17.261 17.5382 17.3857 17.7358 17.4441C17.9309 17.5025 18.1415 17.5008 18.3391 17.4518C18.5368 17.4028 18.8693 17.3392 19.0301 17.2138C19.4383 16.8967 19.5354 16.3458 19.6325 15.8628C19.733 15.3584 19.684 14.8299 19.5955 14.3203C19.4743 13.625 19.2707 12.9461 19.012 12.2904C18.8212 11.8075 18.5772 11.3468 18.2867 10.9189C18.0014 10.4986 17.5975 10.1712 17.2984 9.76042C17.1944 9.61776 17.0698 9.46995 16.9684 9.32558C16.7484 9.01448 16.7983 9.07292 16.6616 8.87097C16.5628 8.72487 16.4064 8.67503 16.2422 8.61143C16.0772 8.54784 15.8933 8.52894 15.7232 8.5762C15.4997 8.63894 15.3167 8.81597 15.2196 9.02651C15.1225 9.23792 15.1053 9.48026 15.1431 9.70972C15.1921 10.0053 15.327 10.2803 15.48 10.5382C15.6518 10.8295 15.8512 11.1105 16.1116 11.3271C16.3832 11.5531 16.7115 11.7026 16.9873 11.9243Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask8_4007_13817)">
              <g filter="url(#filter8_f_4007_13817)">
                <path
                  d="M15.9268 11.2256C15.9629 11.26 16.0007 11.2952 16.0393 11.3279C16.3161 11.5539 16.463 11.8074 16.744 12.0291C17.2029 12.3918 17.6679 13.0174 17.7581 13.5889C17.8286 14.0349 17.7357 14.2772 17.6223 14.8023C17.5089 15.3282 17.1875 16.0725 17.013 16.5795C16.9443 16.7814 17.1539 16.6981 17.1264 16.9112C17.1127 17.0161 17.111 17.1218 17.1239 17.2266C17.1256 17.2068 17.1264 17.1854 17.129 17.1656C17.1625 16.8751 17.2511 16.5958 17.3482 16.3217C17.5346 15.7949 17.7486 15.2775 17.8964 14.7361C18.0443 14.1956 18.0279 13.8063 17.9506 13.3414C17.8535 12.7561 17.5132 12.2164 17.0319 11.8598C16.6907 11.608 16.2894 11.4464 15.9268 11.2256Z"
                  fill="#838384"
                />
              </g>
            </g>
            <path
              d="M6.00609 16.0673C6.12469 16.0183 6.25789 16.0089 6.38336 16.0321C6.50969 16.0553 6.62914 16.1103 6.73484 16.1816C6.94711 16.326 7.10438 16.5357 7.25477 16.7437C7.60109 17.2197 7.93367 17.7062 8.23617 18.2115C8.48109 18.6222 8.70539 19.0459 8.98899 19.4301C9.17375 19.6819 9.38172 19.9147 9.56734 20.1648C9.75297 20.4158 9.91711 20.6873 9.99961 20.9881C10.107 21.3791 10.0649 21.8097 9.8793 22.1697C9.74867 22.4233 9.54758 22.6407 9.30352 22.7876C9.05945 22.9346 8.8025 22.9999 8.51719 22.9999C8.0643 22.9999 7.62172 22.7567 7.20063 22.5891C6.34297 22.2471 5.41141 22.1405 4.52711 21.8741C4.25555 21.7925 3.98828 21.6954 3.715 21.6206C3.59297 21.5871 3.47008 21.5579 3.35492 21.5055C3.23977 21.4539 3.13234 21.3757 3.06875 21.2674C3.01977 21.1832 3 21.0844 3 20.9872C3 20.8893 3.02406 20.793 3.05758 20.7019C3.12375 20.5189 3.23117 20.353 3.30336 20.1717C3.42109 19.8761 3.44258 19.5504 3.42625 19.2324C3.41078 18.9153 3.35922 18.5999 3.33773 18.2828C3.32742 18.141 3.32398 17.9975 3.35148 17.8574C3.37813 17.7182 3.43828 17.5815 3.54055 17.4836C3.63508 17.3925 3.75969 17.3383 3.88859 17.3117C4.01664 17.2851 4.14898 17.2842 4.27961 17.2868C4.41023 17.2894 4.54172 17.2971 4.67234 17.2859C4.80297 17.2756 4.93445 17.2464 5.04789 17.1811C5.15617 17.1192 5.24469 17.0272 5.31773 16.9267C5.39078 16.8262 5.45008 16.7162 5.51109 16.607C5.57125 16.4987 5.63398 16.3905 5.71391 16.2942C5.79297 16.198 5.89094 16.1146 6.00609 16.0673Z"
              fill="url(#paint3_linear_4007_13817)"
            />
            <mask
              id="mask9_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="3"
              y="16"
              width="8"
              height="7"
            >
              <path
                d="M6.00609 16.0673C6.12469 16.0183 6.25789 16.0089 6.38336 16.0321C6.50969 16.0553 6.62914 16.1103 6.73484 16.1816C6.94711 16.326 7.10438 16.5357 7.25477 16.7437C7.60109 17.2197 7.93367 17.7062 8.23617 18.2115C8.48109 18.6222 8.70539 19.0459 8.98899 19.4301C9.17375 19.6819 9.38172 19.9147 9.56734 20.1648C9.75297 20.4158 9.91711 20.6873 9.99961 20.9881C10.107 21.3791 10.0649 21.8097 9.8793 22.1697C9.74867 22.4233 9.54758 22.6407 9.30352 22.7876C9.05945 22.9346 8.8025 22.9999 8.51719 22.9999C8.0643 22.9999 7.62172 22.7567 7.20063 22.5891C6.34297 22.2471 5.41141 22.1405 4.52711 21.8741C4.25555 21.7925 3.98828 21.6954 3.715 21.6206C3.59297 21.5871 3.47008 21.5579 3.35492 21.5055C3.23977 21.4539 3.13234 21.3757 3.06875 21.2674C3.01977 21.1832 3 21.0844 3 20.9872C3 20.8893 3.02406 20.793 3.05758 20.7019C3.12375 20.5189 3.23117 20.353 3.30336 20.1717C3.42109 19.8761 3.44258 19.5504 3.42625 19.2324C3.41078 18.9153 3.35922 18.5999 3.33773 18.2828C3.32742 18.141 3.32398 17.9975 3.35148 17.8574C3.37813 17.7182 3.43828 17.5815 3.54055 17.4836C3.63508 17.3925 3.75969 17.3383 3.88859 17.3117C4.01664 17.2851 4.14898 17.2842 4.27961 17.2868C4.41023 17.2894 4.54172 17.2971 4.67234 17.2859C4.80297 17.2756 4.93445 17.2464 5.04789 17.1811C5.15617 17.1192 5.24469 17.0272 5.31773 16.9267C5.39078 16.8262 5.45008 16.7162 5.51109 16.607C5.57125 16.4987 5.63398 16.3905 5.71391 16.2942C5.79297 16.198 5.89094 16.1146 6.00609 16.0673Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask9_4007_13817)">
              <g filter="url(#filter9_f_4007_13817)">
                <path
                  d="M6.19353 16.2709C6.30095 16.2279 6.42298 16.2228 6.53556 16.2486C6.649 16.2743 6.7547 16.3293 6.8458 16.4007C7.0297 16.5425 7.15689 16.7418 7.27978 16.9386C7.57369 17.4147 7.86072 17.8968 8.13228 18.3875C8.33853 18.7579 8.53447 19.1352 8.78712 19.4747C8.9547 19.6998 9.14634 19.9069 9.31736 20.1295C9.48838 20.3521 9.64048 20.5944 9.71611 20.8651C9.81408 21.2132 9.77455 21.599 9.60611 21.9187C9.48408 22.149 9.29845 22.345 9.0733 22.4756C8.849 22.6062 8.58603 22.6715 8.3265 22.6595C7.91228 22.6406 7.5333 22.4335 7.14314 22.2934C6.39463 22.0218 5.58423 21.9849 4.81595 21.7735C4.54095 21.6987 4.27283 21.599 3.99697 21.5294C3.87408 21.4985 3.74947 21.4727 3.63345 21.4237C3.51744 21.3747 3.4083 21.2991 3.3447 21.19C3.2983 21.1083 3.27939 21.0129 3.28197 20.9193C3.28541 20.8256 3.30861 20.7328 3.34213 20.6451C3.40916 20.4698 3.514 20.3117 3.58103 20.1364C3.6833 19.8717 3.69619 19.5804 3.67728 19.2976C3.65838 19.0149 3.60853 18.7339 3.59134 18.4511C3.58447 18.3239 3.58275 18.1959 3.60853 18.0722C3.63431 17.9475 3.68845 17.8272 3.77869 17.7379C3.87666 17.6407 4.01072 17.5883 4.14736 17.5668C4.28314 17.5462 4.4215 17.5557 4.55814 17.5677C4.69564 17.5797 4.83314 17.5952 4.97064 17.5875C5.10814 17.5789 5.24736 17.5454 5.3608 17.468C5.46392 17.3984 5.54127 17.2961 5.60056 17.187C5.65986 17.0779 5.70197 16.9601 5.74752 16.845C5.7922 16.7289 5.84119 16.6138 5.91166 16.5107C5.98213 16.4084 6.07752 16.3182 6.19353 16.2709Z"
                  fill="#D99A03"
                />
              </g>
            </g>
            <mask
              id="mask10_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="3"
              y="16"
              width="8"
              height="7"
            >
              <path
                d="M6.00609 16.0673C6.12469 16.0183 6.25789 16.0089 6.38336 16.0321C6.50969 16.0553 6.62914 16.1103 6.73484 16.1816C6.94711 16.326 7.10438 16.5357 7.25477 16.7437C7.60109 17.2197 7.93367 17.7062 8.23617 18.2115C8.48109 18.6222 8.70539 19.0459 8.98899 19.4301C9.17375 19.6819 9.38172 19.9147 9.56734 20.1648C9.75297 20.4158 9.91711 20.6873 9.99961 20.9881C10.107 21.3791 10.0649 21.8097 9.8793 22.1697C9.74867 22.4233 9.54758 22.6407 9.30352 22.7876C9.05945 22.9346 8.8025 22.9999 8.51719 22.9999C8.0643 22.9999 7.62172 22.7567 7.20063 22.5891C6.34297 22.2471 5.41141 22.1405 4.52711 21.8741C4.25555 21.7925 3.98828 21.6954 3.715 21.6206C3.59297 21.5871 3.47008 21.5579 3.35492 21.5055C3.23977 21.4539 3.13234 21.3757 3.06875 21.2674C3.01977 21.1832 3 21.0844 3 20.9872C3 20.8893 3.02406 20.793 3.05758 20.7019C3.12375 20.5189 3.23117 20.353 3.30336 20.1717C3.42109 19.8761 3.44258 19.5504 3.42625 19.2324C3.41078 18.9153 3.35922 18.5999 3.33773 18.2828C3.32742 18.141 3.32398 17.9975 3.35148 17.8574C3.37813 17.7182 3.43828 17.5815 3.54055 17.4836C3.63508 17.3925 3.75969 17.3383 3.88859 17.3117C4.01664 17.2851 4.14898 17.2842 4.27961 17.2868C4.41023 17.2894 4.54172 17.2971 4.67234 17.2859C4.80297 17.2756 4.93445 17.2464 5.04789 17.1811C5.15617 17.1192 5.24469 17.0272 5.31773 16.9267C5.39078 16.8262 5.45008 16.7162 5.51109 16.607C5.57125 16.4987 5.63398 16.3905 5.71391 16.2942C5.79297 16.198 5.89094 16.1146 6.00609 16.0673Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask10_4007_13817)">
              <g filter="url(#filter10_f_4007_13817)">
                <path
                  d="M6.09307 16.0021C6.19791 15.9505 6.3208 15.9402 6.4351 15.9634C6.5494 15.9866 6.65596 16.0425 6.74619 16.1155C6.9258 16.2616 7.04096 16.4704 7.14752 16.675C7.39158 17.1442 7.62018 17.6237 7.91237 18.0663C8.12549 18.3868 8.37041 18.685 8.58526 19.0047C8.87658 19.437 9.11377 19.9131 9.21174 20.4227C9.27705 20.7665 9.27533 21.1368 9.11033 21.4454C9.00119 21.649 8.82502 21.8157 8.61619 21.9197C8.40823 22.0246 8.16846 22.0667 7.93557 22.0461C7.56862 22.0143 7.23604 21.8338 6.89057 21.7075C6.27783 21.4849 5.61526 21.4307 4.98447 21.2623C4.71893 21.1918 4.45768 21.0999 4.18869 21.044C4.06924 21.0191 3.94807 21.0011 3.83463 20.9581C3.72119 20.9151 3.61377 20.8438 3.55533 20.739C3.51494 20.665 3.50119 20.5791 3.50721 20.4949C3.51322 20.4115 3.53815 20.3299 3.5708 20.2517C3.63697 20.097 3.73408 19.9543 3.78479 19.7945C3.86041 19.5582 3.82947 19.3029 3.77276 19.0615C3.7169 18.8208 3.63526 18.5845 3.61033 18.3387C3.59916 18.2296 3.59916 18.1179 3.62494 18.0104C3.64987 17.903 3.70057 17.7999 3.78049 17.7234C3.88362 17.6246 4.02799 17.5747 4.17237 17.561C4.31588 17.5472 4.46026 17.5661 4.60291 17.5868C4.74557 17.6074 4.88994 17.6297 5.03346 17.6229C5.17783 17.6168 5.32479 17.579 5.43737 17.4905C5.54908 17.4029 5.61955 17.2731 5.65994 17.139C5.70033 17.0041 5.71323 16.864 5.72955 16.7248C5.74588 16.5856 5.76651 16.4447 5.82065 16.3149C5.87479 16.1851 5.96588 16.0657 6.09307 16.0021Z"
                  fill="#F5BD0C"
                />
              </g>
            </g>
            <mask
              id="mask11_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="3"
              y="16"
              width="8"
              height="7"
            >
              <path
                d="M6.00609 16.0673C6.12469 16.0183 6.25789 16.0089 6.38336 16.0321C6.50969 16.0553 6.62914 16.1103 6.73484 16.1816C6.94711 16.326 7.10438 16.5357 7.25477 16.7437C7.60109 17.2197 7.93367 17.7062 8.23617 18.2115C8.48109 18.6222 8.70539 19.0459 8.98899 19.4301C9.17375 19.6819 9.38172 19.9147 9.56734 20.1648C9.75297 20.4158 9.91711 20.6873 9.99961 20.9881C10.107 21.3791 10.0649 21.8097 9.8793 22.1697C9.74867 22.4233 9.54758 22.6407 9.30352 22.7876C9.05945 22.9346 8.8025 22.9999 8.51719 22.9999C8.0643 22.9999 7.62172 22.7567 7.20063 22.5891C6.34297 22.2471 5.41141 22.1405 4.52711 21.8741C4.25555 21.7925 3.98828 21.6954 3.715 21.6206C3.59297 21.5871 3.47008 21.5579 3.35492 21.5055C3.23977 21.4539 3.13234 21.3757 3.06875 21.2674C3.01977 21.1832 3 21.0844 3 20.9872C3 20.8893 3.02406 20.793 3.05758 20.7019C3.12375 20.5189 3.23117 20.353 3.30336 20.1717C3.42109 19.8761 3.44258 19.5504 3.42625 19.2324C3.41078 18.9153 3.35922 18.5999 3.33773 18.2828C3.32742 18.141 3.32398 17.9975 3.35148 17.8574C3.37813 17.7182 3.43828 17.5815 3.54055 17.4836C3.63508 17.3925 3.75969 17.3383 3.88859 17.3117C4.01664 17.2851 4.14898 17.2842 4.27961 17.2868C4.41023 17.2894 4.54172 17.2971 4.67234 17.2859C4.80297 17.2756 4.93445 17.2464 5.04789 17.1811C5.15617 17.1192 5.24469 17.0272 5.31773 16.9267C5.39078 16.8262 5.45008 16.7162 5.51109 16.607C5.57125 16.4987 5.63398 16.3905 5.71391 16.2942C5.79297 16.198 5.89094 16.1146 6.00609 16.0673Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask11_4007_13817)">
              <g filter="url(#filter11_f_4007_13817)">
                <path
                  d="M7.3999 17.1743C7.59326 17.5232 7.711 17.9237 7.90264 18.276C8.07881 18.6 8.27904 18.9335 8.45779 19.2317C8.53771 19.3649 8.72334 19.5694 8.91068 19.8865C9.08084 20.1735 9.25271 20.5749 9.34896 20.7098C9.29396 20.5517 9.18053 20.128 9.04475 19.8263C8.91842 19.5445 8.77061 19.3838 8.68037 19.2317C8.50162 18.9335 8.30826 18.6645 8.12436 18.38C7.87084 17.9873 7.67748 17.5481 7.3999 17.1743Z"
                  fill="url(#paint4_linear_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask12_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask12_4007_13817)">
              <g opacity="0.2" filter="url(#filter12_f_4007_13817)">
                <path
                  d="M20.0762 19.5288C20.0418 19.6431 19.9885 19.754 19.9206 19.8554C19.7703 20.0779 19.5511 20.2464 19.3328 20.4011C18.9607 20.6649 18.5723 20.9072 18.2251 21.2072C17.9931 21.4083 17.7808 21.6343 17.5849 21.8715C17.4173 22.0743 17.2592 22.2874 17.0675 22.4679C16.8742 22.6501 16.6473 22.7979 16.4024 22.8778C16.1042 22.9749 15.7896 22.9706 15.5026 22.8967C15.3015 22.8451 15.1047 22.7558 14.9801 22.5933C14.8555 22.4309 14.8151 22.2118 14.8005 21.9969C14.7747 21.6171 14.8203 21.2295 14.8615 20.8436C14.8959 20.5231 14.9277 20.2026 14.9406 19.8829C14.9638 19.3011 14.9251 18.7253 14.8469 18.1598C14.8331 18.0644 14.8194 17.969 14.8271 17.8719C14.8349 17.774 14.8675 17.6726 14.9363 17.5969C14.9999 17.5273 15.0884 17.4861 15.176 17.4663C15.2637 17.4457 15.3531 17.4448 15.4416 17.4422C15.6504 17.4371 15.8592 17.4242 16.0646 17.4431C16.1944 17.4543 16.3216 17.4783 16.4505 17.4904C16.6662 17.511 16.8905 17.5007 17.107 17.4371C17.3399 17.3683 17.5617 17.2403 17.7988 17.2051C17.896 17.1904 17.9931 17.1922 18.0876 17.2059C18.1838 17.2188 18.2801 17.2454 18.354 17.3047C18.4107 17.3494 18.452 17.4104 18.4838 17.4758C18.5319 17.5737 18.5611 17.6811 18.5783 17.792C18.5929 17.89 18.5989 17.9905 18.6238 18.0851C18.6651 18.2406 18.7596 18.3729 18.8722 18.4804C18.9856 18.5878 19.1171 18.6729 19.2495 18.7571C19.381 18.8404 19.5133 18.9229 19.6534 18.9917C19.7195 19.0243 19.7874 19.0536 19.8502 19.0905C19.9138 19.1283 19.9722 19.1739 20.0143 19.234C20.071 19.3157 20.0934 19.4231 20.0762 19.5288Z"
                  fill="url(#paint5_linear_4007_13817)"
                />
              </g>
            </g>
            <path
              d="M21.3453 20.1573C21.1511 20.3876 20.8813 20.5397 20.6123 20.6772C20.1542 20.9101 19.679 21.1155 19.2484 21.3965C18.9597 21.5847 18.6933 21.8056 18.4441 22.0436C18.2309 22.2473 18.029 22.4647 17.7901 22.6375C17.5486 22.8119 17.2702 22.9382 16.9754 22.9821C16.8938 22.9941 16.8121 23.0001 16.7296 23.0001C16.4512 23.0001 16.1727 22.9331 15.9166 22.8214C15.6846 22.7208 15.4612 22.5756 15.3271 22.3607C15.1931 22.1442 15.1621 21.8803 15.1621 21.6251C15.1613 21.1757 15.2446 20.7305 15.3228 20.2871C15.3881 19.9184 15.45 19.5497 15.4904 19.1776C15.5634 18.5021 15.5626 17.8189 15.5145 17.14C15.5059 17.0266 15.4964 16.9123 15.5136 16.7997C15.5308 16.6871 15.5772 16.5746 15.6631 16.5007C15.7431 16.4319 15.8496 16.401 15.9553 16.3958C16.0602 16.3898 16.165 16.4061 16.269 16.4216C16.5139 16.4577 16.7614 16.4852 17.0012 16.5488C17.1524 16.5883 17.3002 16.6416 17.4506 16.6828C17.7033 16.7507 17.9671 16.7834 18.2258 16.7533C18.5051 16.7207 18.7766 16.6158 19.0577 16.6227C19.1728 16.6253 19.2871 16.6467 19.3971 16.682C19.5088 16.7172 19.6197 16.7679 19.7022 16.8521C19.7649 16.9157 19.8088 16.9957 19.8414 17.079C19.8904 17.2028 19.917 17.3351 19.9282 17.4675C19.9377 17.5852 19.9359 17.7046 19.9583 17.8207C19.9952 18.0106 20.0958 18.185 20.2195 18.3337C20.3441 18.4832 20.4928 18.6096 20.6415 18.7342C20.7902 18.8588 20.9388 18.9825 21.0987 19.0917C21.1734 19.1432 21.2508 19.1914 21.3221 19.2472C21.3934 19.3039 21.4588 19.3692 21.5034 19.4483C21.5421 19.5179 21.5627 19.5961 21.5627 19.676C21.5627 19.7207 21.5567 19.7654 21.543 19.8101C21.5043 19.933 21.433 20.0525 21.3453 20.1573Z"
              fill="url(#paint6_linear_4007_13817)"
            />
            <mask
              id="mask13_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="15"
              y="16"
              width="7"
              height="7"
            >
              <path
                d="M21.3453 20.1573C21.1511 20.3876 20.8813 20.5397 20.6123 20.6772C20.1542 20.9101 19.679 21.1155 19.2484 21.3965C18.9597 21.5847 18.6933 21.8056 18.4441 22.0436C18.2309 22.2473 18.029 22.4647 17.7901 22.6375C17.5486 22.8119 17.2702 22.9382 16.9754 22.9821C16.8938 22.9941 16.8121 23.0001 16.7296 23.0001C16.4512 23.0001 16.1727 22.9331 15.9166 22.8214C15.6846 22.7208 15.4612 22.5756 15.3271 22.3607C15.1931 22.1442 15.1621 21.8803 15.1621 21.6251C15.1613 21.1757 15.2446 20.7305 15.3228 20.2871C15.3881 19.9184 15.45 19.5497 15.4904 19.1776C15.5634 18.5021 15.5626 17.8189 15.5145 17.14C15.5059 17.0266 15.4964 16.9123 15.5136 16.7997C15.5308 16.6871 15.5772 16.5746 15.6631 16.5007C15.7431 16.4319 15.8496 16.401 15.9553 16.3958C16.0602 16.3898 16.165 16.4061 16.269 16.4216C16.5139 16.4577 16.7614 16.4852 17.0012 16.5488C17.1524 16.5883 17.3002 16.6416 17.4506 16.6828C17.7033 16.7507 17.9671 16.7834 18.2258 16.7533C18.5051 16.7207 18.7766 16.6158 19.0577 16.6227C19.1728 16.6253 19.2871 16.6467 19.3971 16.682C19.5088 16.7172 19.6197 16.7679 19.7022 16.8521C19.7649 16.9157 19.8088 16.9957 19.8414 17.079C19.8904 17.2028 19.917 17.3351 19.9282 17.4675C19.9377 17.5852 19.9359 17.7046 19.9583 17.8207C19.9952 18.0106 20.0958 18.185 20.2195 18.3337C20.3441 18.4832 20.4928 18.6096 20.6415 18.7342C20.7902 18.8588 20.9388 18.9825 21.0987 19.0917C21.1734 19.1432 21.2508 19.1914 21.3221 19.2472C21.3934 19.3039 21.4588 19.3692 21.5034 19.4483C21.5421 19.5179 21.5627 19.5961 21.5627 19.676C21.5627 19.7207 21.5567 19.7654 21.543 19.8101C21.5043 19.933 21.433 20.0525 21.3453 20.1573Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask13_4007_13817)">
              <g filter="url(#filter13_f_4007_13817)">
                <path
                  d="M21.3227 19.573C21.2772 19.6873 21.2127 19.7947 21.132 19.8884C20.9541 20.0964 20.7083 20.233 20.4634 20.3559C20.044 20.5656 19.6109 20.7495 19.2173 21.0021C18.9543 21.1714 18.7102 21.37 18.4825 21.5848C18.2883 21.7679 18.1035 21.9638 17.8852 22.1185C17.6644 22.2757 17.41 22.3883 17.1419 22.4279C16.8153 22.476 16.4784 22.4141 16.1751 22.2843C15.9637 22.1932 15.7591 22.0643 15.6363 21.8693C15.5142 21.675 15.4859 21.4379 15.4859 21.2084C15.4841 20.8036 15.5606 20.4032 15.6328 20.0053C15.693 19.6744 15.7497 19.3427 15.7858 19.0084C15.8511 18.4 15.8485 17.7864 15.8073 17.1762C15.8004 17.0739 15.7927 16.9708 15.8081 16.8694C15.8236 16.768 15.8657 16.6675 15.9431 16.6004C16.0161 16.5377 16.1141 16.5102 16.2103 16.5059C16.3057 16.5007 16.4011 16.5162 16.4965 16.53C16.7208 16.5626 16.9459 16.5875 17.1651 16.6443C17.3034 16.6804 17.4384 16.7285 17.5759 16.7646C17.8062 16.8264 18.0477 16.8557 18.284 16.8282C18.5392 16.7989 18.7867 16.7044 19.0428 16.7104C19.1485 16.713 19.2525 16.7328 19.3531 16.7637C19.4553 16.7955 19.5567 16.8411 19.6324 16.9167C19.6891 16.9743 19.7277 17.0464 19.7595 17.1212C19.8051 17.2321 19.836 17.3507 19.8377 17.471C19.8403 17.5964 19.8137 17.7219 19.83 17.8465C19.8438 17.9471 19.8867 18.0425 19.9417 18.1284C19.9959 18.2143 20.062 18.2925 20.1282 18.3699C20.2554 18.5203 20.3826 18.6732 20.5424 18.7884C20.7238 18.9199 20.9395 18.9972 21.1268 19.1193C21.1827 19.1562 21.2368 19.1975 21.2763 19.2516C21.3434 19.3427 21.3606 19.4664 21.3227 19.573Z"
                  fill="#CD8907"
                />
              </g>
            </g>
            <mask
              id="mask14_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="15"
              y="16"
              width="7"
              height="7"
            >
              <path
                d="M21.3453 20.1573C21.1511 20.3876 20.8813 20.5397 20.6123 20.6772C20.1542 20.9101 19.679 21.1155 19.2484 21.3965C18.9597 21.5847 18.6933 21.8056 18.4441 22.0436C18.2309 22.2473 18.029 22.4647 17.7901 22.6375C17.5486 22.8119 17.2702 22.9382 16.9754 22.9821C16.8938 22.9941 16.8121 23.0001 16.7296 23.0001C16.4512 23.0001 16.1727 22.9331 15.9166 22.8214C15.6846 22.7208 15.4612 22.5756 15.3271 22.3607C15.1931 22.1442 15.1621 21.8803 15.1621 21.6251C15.1613 21.1757 15.2446 20.7305 15.3228 20.2871C15.3881 19.9184 15.45 19.5497 15.4904 19.1776C15.5634 18.5021 15.5626 17.8189 15.5145 17.14C15.5059 17.0266 15.4964 16.9123 15.5136 16.7997C15.5308 16.6871 15.5772 16.5746 15.6631 16.5007C15.7431 16.4319 15.8496 16.401 15.9553 16.3958C16.0602 16.3898 16.165 16.4061 16.269 16.4216C16.5139 16.4577 16.7614 16.4852 17.0012 16.5488C17.1524 16.5883 17.3002 16.6416 17.4506 16.6828C17.7033 16.7507 17.9671 16.7834 18.2258 16.7533C18.5051 16.7207 18.7766 16.6158 19.0577 16.6227C19.1728 16.6253 19.2871 16.6467 19.3971 16.682C19.5088 16.7172 19.6197 16.7679 19.7022 16.8521C19.7649 16.9157 19.8088 16.9957 19.8414 17.079C19.8904 17.2028 19.917 17.3351 19.9282 17.4675C19.9377 17.5852 19.9359 17.7046 19.9583 17.8207C19.9952 18.0106 20.0958 18.185 20.2195 18.3337C20.3441 18.4832 20.4928 18.6096 20.6415 18.7342C20.7902 18.8588 20.9388 18.9825 21.0987 19.0917C21.1734 19.1432 21.2508 19.1914 21.3221 19.2472C21.3934 19.3039 21.4588 19.3692 21.5034 19.4483C21.5421 19.5179 21.5627 19.5961 21.5627 19.676C21.5627 19.7207 21.5567 19.7654 21.543 19.8101C21.5043 19.933 21.433 20.0525 21.3453 20.1573Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask14_4007_13817)">
              <g filter="url(#filter14_f_4007_13817)">
                <path
                  d="M21.2973 19.4433C21.2457 19.5594 21.1796 19.6668 21.1014 19.7622C20.9192 19.9839 20.6725 20.136 20.4139 20.234C19.9868 20.3955 19.521 20.417 19.1025 20.6009C18.8472 20.7126 18.6169 20.8811 18.384 21.0409C18.1967 21.169 18.0042 21.2936 17.7979 21.3829C17.5642 21.4852 17.3167 21.5419 17.0675 21.5823C16.9093 21.6072 16.7486 21.6262 16.5888 21.6176C16.4289 21.609 16.2691 21.5712 16.1273 21.4895C16.0207 21.4276 15.9245 21.3391 15.8669 21.2231C15.8042 21.0951 15.793 20.9447 15.7939 20.7986C15.7982 20.4119 15.8815 20.0286 15.8789 19.641C15.8764 19.3033 15.8093 18.9707 15.7904 18.6338C15.7535 17.9858 15.8927 17.3276 15.7698 16.6925C15.7509 16.5902 15.7243 16.4888 15.7251 16.3848C15.726 16.3333 15.7337 16.2808 15.7518 16.2327C15.7707 16.1854 15.7999 16.1416 15.8386 16.1107C15.8721 16.0858 15.9107 16.0703 15.9511 16.0626C15.9907 16.0557 16.0319 16.0574 16.0723 16.0634C16.1522 16.0763 16.2287 16.1072 16.3069 16.1304C16.5304 16.1975 16.7667 16.1958 16.9944 16.2465C17.1371 16.2774 17.2746 16.3298 17.4164 16.3676C17.6527 16.4329 17.9002 16.4604 18.1434 16.4321C18.4046 16.4012 18.6599 16.3066 18.9229 16.3135C19.0311 16.3161 19.1377 16.3358 19.2417 16.3668C19.3457 16.3977 19.4496 16.4415 19.5287 16.5215C19.5846 16.579 19.6258 16.6521 19.6585 16.7277C19.7075 16.8386 19.741 16.9589 19.7401 17.0809C19.7393 17.1445 19.7298 17.2072 19.7238 17.2708C19.7186 17.3344 19.7169 17.3989 19.7315 17.4599C19.747 17.5218 19.7788 17.5776 19.8149 17.6283C19.851 17.679 19.8922 17.7246 19.93 17.7744C20.0349 17.9128 20.1105 18.0744 20.1921 18.2299C20.2738 18.3854 20.3657 18.5393 20.4955 18.6519C20.6717 18.804 20.8994 18.865 21.0962 18.9862C21.1538 19.0214 21.2088 19.0626 21.2492 19.1194C21.2818 19.164 21.3033 19.2182 21.3119 19.2749C21.3196 19.3308 21.3145 19.3901 21.2973 19.4433Z"
                  fill="#F5C021"
                />
              </g>
            </g>
            <mask
              id="mask15_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="15"
              y="16"
              width="7"
              height="7"
            >
              <path
                d="M21.3453 20.1573C21.1511 20.3876 20.8813 20.5397 20.6123 20.6772C20.1542 20.9101 19.679 21.1155 19.2484 21.3965C18.9597 21.5847 18.6933 21.8056 18.4441 22.0436C18.2309 22.2473 18.029 22.4647 17.7901 22.6375C17.5486 22.8119 17.2702 22.9382 16.9754 22.9821C16.8938 22.9941 16.8121 23.0001 16.7296 23.0001C16.4512 23.0001 16.1727 22.9331 15.9166 22.8214C15.6846 22.7208 15.4612 22.5756 15.3271 22.3607C15.1931 22.1442 15.1621 21.8803 15.1621 21.6251C15.1613 21.1757 15.2446 20.7305 15.3228 20.2871C15.3881 19.9184 15.45 19.5497 15.4904 19.1776C15.5634 18.5021 15.5626 17.8189 15.5145 17.14C15.5059 17.0266 15.4964 16.9123 15.5136 16.7997C15.5308 16.6871 15.5772 16.5746 15.6631 16.5007C15.7431 16.4319 15.8496 16.401 15.9553 16.3958C16.0602 16.3898 16.165 16.4061 16.269 16.4216C16.5139 16.4577 16.7614 16.4852 17.0012 16.5488C17.1524 16.5883 17.3002 16.6416 17.4506 16.6828C17.7033 16.7507 17.9671 16.7834 18.2258 16.7533C18.5051 16.7207 18.7766 16.6158 19.0577 16.6227C19.1728 16.6253 19.2871 16.6467 19.3971 16.682C19.5088 16.7172 19.6197 16.7679 19.7022 16.8521C19.7649 16.9157 19.8088 16.9957 19.8414 17.079C19.8904 17.2028 19.917 17.3351 19.9282 17.4675C19.9377 17.5852 19.9359 17.7046 19.9583 17.8207C19.9952 18.0106 20.0958 18.185 20.2195 18.3337C20.3441 18.4832 20.4928 18.6096 20.6415 18.7342C20.7902 18.8588 20.9388 18.9825 21.0987 19.0917C21.1734 19.1432 21.2508 19.1914 21.3221 19.2472C21.3934 19.3039 21.4588 19.3692 21.5034 19.4483C21.5421 19.5179 21.5627 19.5961 21.5627 19.676C21.5627 19.7207 21.5567 19.7654 21.543 19.8101C21.5043 19.933 21.433 20.0525 21.3453 20.1573Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask15_4007_13817)">
              <g filter="url(#filter15_f_4007_13817)">
                <path
                  d="M15.7258 16.6044C15.9682 16.5391 16.1744 16.723 16.3506 16.8614C16.464 16.9585 16.629 16.9344 16.7683 16.9387C16.9994 16.931 17.2289 16.9774 17.4601 16.949C17.9155 16.9104 18.3615 16.7986 18.8187 16.7797C19.037 16.766 19.2828 16.7531 19.471 16.8803C19.5475 16.9336 19.6601 17.1579 19.7348 17.0736C19.7039 16.8382 19.5294 16.6104 19.2957 16.5477C19.1118 16.5185 18.9262 16.5692 18.7405 16.5529C18.1914 16.54 17.6448 16.4377 17.094 16.4669C16.7107 16.4704 16.3283 16.4523 15.945 16.4377C15.7946 16.4068 15.7413 16.54 15.6597 16.5915C15.6812 16.6079 15.6889 16.613 15.7258 16.6044Z"
                  fill="url(#paint7_linear_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask16_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="15"
              y="16"
              width="7"
              height="7"
            >
              <path
                d="M21.3453 20.1573C21.1511 20.3876 20.8813 20.5397 20.6123 20.6772C20.1542 20.9101 19.679 21.1155 19.2484 21.3965C18.9597 21.5847 18.6933 21.8056 18.4441 22.0436C18.2309 22.2473 18.029 22.4647 17.7901 22.6375C17.5486 22.8119 17.2702 22.9382 16.9754 22.9821C16.8938 22.9941 16.8121 23.0001 16.7296 23.0001C16.4512 23.0001 16.1727 22.9331 15.9166 22.8214C15.6846 22.7208 15.4612 22.5756 15.3271 22.3607C15.1931 22.1442 15.1621 21.8803 15.1621 21.6251C15.1613 21.1757 15.2446 20.7305 15.3228 20.2871C15.3881 19.9184 15.45 19.5497 15.4904 19.1776C15.5634 18.5021 15.5626 17.8189 15.5145 17.14C15.5059 17.0266 15.4964 16.9123 15.5136 16.7997C15.5308 16.6871 15.5772 16.5746 15.6631 16.5007C15.7431 16.4319 15.8496 16.401 15.9553 16.3958C16.0602 16.3898 16.165 16.4061 16.269 16.4216C16.5139 16.4577 16.7614 16.4852 17.0012 16.5488C17.1524 16.5883 17.3002 16.6416 17.4506 16.6828C17.7033 16.7507 17.9671 16.7834 18.2258 16.7533C18.5051 16.7207 18.7766 16.6158 19.0577 16.6227C19.1728 16.6253 19.2871 16.6467 19.3971 16.682C19.5088 16.7172 19.6197 16.7679 19.7022 16.8521C19.7649 16.9157 19.8088 16.9957 19.8414 17.079C19.8904 17.2028 19.917 17.3351 19.9282 17.4675C19.9377 17.5852 19.9359 17.7046 19.9583 17.8207C19.9952 18.0106 20.0958 18.185 20.2195 18.3337C20.3441 18.4832 20.4928 18.6096 20.6415 18.7342C20.7902 18.8588 20.9388 18.9825 21.0987 19.0917C21.1734 19.1432 21.2508 19.1914 21.3221 19.2472C21.3934 19.3039 21.4588 19.3692 21.5034 19.4483C21.5421 19.5179 21.5627 19.5961 21.5627 19.676C21.5627 19.7207 21.5567 19.7654 21.543 19.8101C21.5043 19.933 21.433 20.0525 21.3453 20.1573Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask16_4007_13817)">
              <g opacity="0.35" filter="url(#filter16_f_4007_13817)">
                <path
                  d="M18.9406 17.123C18.8993 17.0413 18.8237 16.9803 18.7386 16.9451C18.6544 16.9099 18.5616 16.8978 18.4696 16.8987C18.2866 16.9004 18.1044 16.9477 17.9213 16.9322C17.7675 16.9185 17.6214 16.8609 17.471 16.8239C17.3155 16.7861 17.1505 16.7715 16.9967 16.8136C16.8317 16.8583 16.6864 16.9692 16.5953 17.1135C16.5145 17.2407 16.4767 17.3911 16.4647 17.5415C16.4527 17.6919 16.4656 17.8423 16.481 17.9927C16.4913 18.101 16.5042 18.2092 16.53 18.3149C16.5567 18.4206 16.597 18.5238 16.6606 18.6123C16.7526 18.7395 16.8892 18.8306 17.0362 18.8847C17.2768 18.9732 17.5501 18.9689 17.7864 18.8718C18.2058 18.6896 18.5607 18.3631 18.7764 17.9609C18.8546 17.8165 18.9148 17.6627 18.9534 17.5046C18.9689 17.441 18.9809 17.3765 18.9809 17.3112C18.9818 17.2467 18.9706 17.1806 18.9406 17.123Z"
                  fill="url(#paint8_radial_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask17_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="15"
              y="16"
              width="7"
              height="7"
            >
              <path
                d="M21.3453 20.1573C21.1511 20.3876 20.8813 20.5397 20.6123 20.6772C20.1542 20.9101 19.679 21.1155 19.2484 21.3965C18.9597 21.5847 18.6933 21.8056 18.4441 22.0436C18.2309 22.2473 18.029 22.4647 17.7901 22.6375C17.5486 22.8119 17.2702 22.9382 16.9754 22.9821C16.8938 22.9941 16.8121 23.0001 16.7296 23.0001C16.4512 23.0001 16.1727 22.9331 15.9166 22.8214C15.6846 22.7208 15.4612 22.5756 15.3271 22.3607C15.1931 22.1442 15.1621 21.8803 15.1621 21.6251C15.1613 21.1757 15.2446 20.7305 15.3228 20.2871C15.3881 19.9184 15.45 19.5497 15.4904 19.1776C15.5634 18.5021 15.5626 17.8189 15.5145 17.14C15.5059 17.0266 15.4964 16.9123 15.5136 16.7997C15.5308 16.6871 15.5772 16.5746 15.6631 16.5007C15.7431 16.4319 15.8496 16.401 15.9553 16.3958C16.0602 16.3898 16.165 16.4061 16.269 16.4216C16.5139 16.4577 16.7614 16.4852 17.0012 16.5488C17.1524 16.5883 17.3002 16.6416 17.4506 16.6828C17.7033 16.7507 17.9671 16.7834 18.2258 16.7533C18.5051 16.7207 18.7766 16.6158 19.0577 16.6227C19.1728 16.6253 19.2871 16.6467 19.3971 16.682C19.5088 16.7172 19.6197 16.7679 19.7022 16.8521C19.7649 16.9157 19.8088 16.9957 19.8414 17.079C19.8904 17.2028 19.917 17.3351 19.9282 17.4675C19.9377 17.5852 19.9359 17.7046 19.9583 17.8207C19.9952 18.0106 20.0958 18.185 20.2195 18.3337C20.3441 18.4832 20.4928 18.6096 20.6415 18.7342C20.7902 18.8588 20.9388 18.9825 21.0987 19.0917C21.1734 19.1432 21.2508 19.1914 21.3221 19.2472C21.3934 19.3039 21.4588 19.3692 21.5034 19.4483C21.5421 19.5179 21.5627 19.5961 21.5627 19.676C21.5627 19.7207 21.5567 19.7654 21.543 19.8101C21.5043 19.933 21.433 20.0525 21.3453 20.1573Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask17_4007_13817)">
              <g opacity="0.35" filter="url(#filter17_f_4007_13817)">
                <path
                  d="M18.9406 16.889C18.8993 16.8297 18.8237 16.7859 18.7386 16.7601C18.6544 16.7343 18.5616 16.7266 18.4696 16.7266C18.2866 16.7283 18.1044 16.7627 17.9213 16.7507C17.7675 16.7412 17.6214 16.6991 17.471 16.6733C17.3155 16.6458 17.1505 16.6346 16.9967 16.6656C16.8317 16.6974 16.6864 16.7773 16.5953 16.8821C16.5145 16.9741 16.4767 17.0832 16.4647 17.1915C16.4527 17.2998 16.4656 17.4089 16.481 17.5172C16.4913 17.5954 16.5042 17.6745 16.53 17.751C16.5567 17.8275 16.597 17.9022 16.6606 17.9658C16.7526 18.0578 16.8892 18.1231 17.0362 18.1626C17.2768 18.2271 17.5501 18.2236 17.7864 18.1532C18.2058 18.0217 18.5607 17.7853 18.7764 17.4949C18.8546 17.39 18.9148 17.2792 18.9534 17.1649C18.9689 17.1185 18.9809 17.0721 18.9809 17.0257C18.9818 16.9784 18.9706 16.9303 18.9406 16.889Z"
                  fill="url(#paint9_radial_4007_13817)"
                />
              </g>
            </g>
            <path
              d="M19.2896 16.3587C19.2595 16.2771 19.2062 16.2049 19.1418 16.1464C19.0773 16.088 19.0008 16.0433 18.9209 16.0089C18.7611 15.941 18.5866 15.9153 18.4147 15.8835C18.254 15.8534 18.0933 15.8164 17.9318 15.7889C17.7633 15.7606 17.5897 15.7417 17.423 15.7795C17.2778 15.8121 17.1429 15.8878 17.0329 15.9883C16.9229 16.0889 16.8369 16.2143 16.7751 16.3501C16.6659 16.5899 16.6315 16.8589 16.6522 17.121C16.6676 17.316 16.7175 17.5171 16.8455 17.6641C16.9495 17.7835 17.0965 17.8583 17.2477 17.903C17.509 17.9803 17.7934 17.976 18.0521 17.8918C18.4792 17.7483 18.8573 17.4639 19.1134 17.0935C19.1976 16.9706 19.2698 16.8374 19.3025 16.693C19.3274 16.583 19.3283 16.4644 19.2896 16.3587Z"
              fill="#020204"
            />
            <mask
              id="mask18_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="16"
              y="15"
              width="4"
              height="3"
            >
              <path
                d="M19.2896 16.3587C19.2595 16.2771 19.2062 16.2049 19.1418 16.1464C19.0773 16.088 19.0008 16.0433 18.9209 16.0089C18.7611 15.941 18.5866 15.9153 18.4147 15.8835C18.254 15.8534 18.0933 15.8164 17.9318 15.7889C17.7633 15.7606 17.5897 15.7417 17.423 15.7795C17.2778 15.8121 17.1429 15.8878 17.0329 15.9883C16.9229 16.0889 16.8369 16.2143 16.7751 16.3501C16.6659 16.5899 16.6315 16.8589 16.6522 17.121C16.6676 17.316 16.7175 17.5171 16.8455 17.6641C16.9495 17.7835 17.0965 17.8583 17.2477 17.903C17.509 17.9803 17.7934 17.976 18.0521 17.8918C18.4792 17.7483 18.8573 17.4639 19.1134 17.0935C19.1976 16.9706 19.2698 16.8374 19.3025 16.693C19.3274 16.583 19.3283 16.4644 19.2896 16.3587Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask18_4007_13817)">
              <g filter="url(#filter18_f_4007_13817)">
                <path
                  d="M17.5143 15.7011C17.4739 15.7037 17.4344 15.708 17.394 15.7157C17.137 15.7613 16.9015 15.9237 16.7692 16.148C16.6755 16.307 16.6334 16.4943 16.6463 16.6782C16.6523 16.5493 16.6858 16.423 16.7486 16.3104C16.8543 16.1188 17.0468 15.9744 17.2625 15.9349C17.3948 15.9108 17.5315 15.9254 17.6647 15.9417C17.7936 15.9581 17.9225 15.9753 18.0488 16.0019C18.2448 16.0449 18.4381 16.1102 18.6023 16.2245C18.6401 16.2511 18.6762 16.2812 18.7062 16.3173C18.7363 16.3525 18.7595 16.3938 18.769 16.4393C18.7819 16.5063 18.763 16.5768 18.7294 16.6361C18.6959 16.6963 18.6504 16.7478 18.6023 16.796C18.5627 16.8355 18.5215 16.8724 18.4794 16.9094C18.6693 16.8724 18.8609 16.8209 19.019 16.7109C19.0852 16.6636 19.1462 16.6078 19.1858 16.5373C19.2253 16.466 19.2416 16.38 19.2227 16.301C19.209 16.2477 19.1798 16.1987 19.1437 16.1574C19.1076 16.1162 19.0637 16.0827 19.019 16.0509C18.8205 15.9117 18.5885 15.8231 18.3479 15.7939C18.1932 15.7656 18.0376 15.7415 17.8821 15.7226C17.7609 15.7071 17.6363 15.6942 17.5143 15.7011V15.7011Z"
                  fill="url(#paint10_radial_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask19_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="16"
              y="15"
              width="4"
              height="3"
            >
              <path
                d="M19.2896 16.3587C19.2595 16.2771 19.2062 16.2049 19.1418 16.1464C19.0773 16.088 19.0008 16.0433 18.9209 16.0089C18.7611 15.941 18.5866 15.9153 18.4147 15.8835C18.254 15.8534 18.0933 15.8164 17.9318 15.7889C17.7633 15.7606 17.5897 15.7417 17.423 15.7795C17.2778 15.8121 17.1429 15.8878 17.0329 15.9883C16.9229 16.0889 16.8369 16.2143 16.7751 16.3501C16.6659 16.5899 16.6315 16.8589 16.6522 17.121C16.6676 17.316 16.7175 17.5171 16.8455 17.6641C16.9495 17.7835 17.0965 17.8583 17.2477 17.903C17.509 17.9803 17.7934 17.976 18.0521 17.8918C18.4792 17.7483 18.8573 17.4639 19.1134 17.0935C19.1976 16.9706 19.2698 16.8374 19.3025 16.693C19.3274 16.583 19.3283 16.4644 19.2896 16.3587Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask19_4007_13817)">
              <g filter="url(#filter19_f_4007_13817)">
                <path
                  d="M17.5143 15.7011C17.4739 15.7037 17.4344 15.708 17.394 15.7157C17.137 15.7613 16.9015 15.9237 16.7692 16.148C16.6755 16.307 16.6334 16.4943 16.6463 16.6782C16.6523 16.5493 16.6858 16.423 16.7486 16.3104C16.8543 16.1188 17.0468 15.9744 17.2625 15.9349C17.3948 15.9108 17.5315 15.9254 17.6647 15.9417C17.7936 15.9581 17.9225 15.9753 18.0488 16.0019C18.2448 16.0449 18.4381 16.1102 18.6023 16.2245C18.6401 16.2511 18.6762 16.2812 18.7062 16.3173C18.7363 16.3525 18.7595 16.3938 18.769 16.4393C18.7819 16.5063 18.763 16.5768 18.7294 16.6361C18.6959 16.6963 18.6504 16.7478 18.6023 16.796C18.5627 16.8355 18.5215 16.8724 18.4794 16.9094C18.6693 16.8724 18.8609 16.8209 19.019 16.7109C19.0852 16.6636 19.1462 16.6078 19.1858 16.5373C19.2253 16.466 19.2416 16.38 19.2227 16.301C19.209 16.2477 19.1798 16.1987 19.1437 16.1574C19.1076 16.1162 19.0637 16.0827 19.019 16.0509C18.8205 15.9117 18.5885 15.8231 18.3479 15.7939C18.1932 15.7656 18.0376 15.7415 17.8821 15.7226C17.7609 15.7071 17.6363 15.6942 17.5143 15.7011V15.7011Z"
                  fill="url(#paint11_linear_4007_13817)"
                />
              </g>
            </g>
            <path
              d="M10.2577 4.29022C10.1263 4.2971 9.99993 4.35811 9.90368 4.44835C9.80657 4.53858 9.73868 4.65717 9.69657 4.78178C9.61321 5.03272 9.63212 5.30428 9.65102 5.56811C9.66821 5.80616 9.68626 6.04936 9.77563 6.27108C9.82032 6.3828 9.88305 6.48678 9.96641 6.57272C10.0498 6.6578 10.1555 6.72397 10.2715 6.75233C10.3798 6.77983 10.4966 6.77467 10.6032 6.74116C10.7106 6.70678 10.8077 6.64577 10.8885 6.56757C11.0054 6.45327 11.0836 6.30374 11.1291 6.14647C11.1747 5.98921 11.1876 5.82421 11.185 5.66007C11.1816 5.45553 11.1549 5.25014 11.0913 5.05592C11.0269 4.86171 10.9246 4.6778 10.782 4.53085C10.7132 4.45952 10.6341 4.39764 10.5456 4.35381C10.4563 4.30999 10.3566 4.28506 10.2577 4.29022Z"
              fill="url(#paint12_radial_4007_13817)"
            />
            <path
              d="M9.93965 5.38234C9.91215 5.54906 9.91129 5.72351 9.96801 5.88164C10.0058 5.98648 10.0686 6.08187 10.1442 6.16351C10.1932 6.2168 10.2499 6.26492 10.3152 6.29672C10.3814 6.32851 10.457 6.34141 10.5283 6.32422C10.5936 6.30789 10.6504 6.26578 10.6925 6.21336C10.7346 6.16094 10.7629 6.0982 10.7827 6.03375C10.842 5.84383 10.8325 5.63672 10.7732 5.4468C10.7311 5.30844 10.6598 5.17523 10.5498 5.08242C10.4965 5.03773 10.4346 5.0025 10.3676 4.98703C10.3006 4.9707 10.2275 4.975 10.1648 5.00336C10.0952 5.03516 10.0411 5.09531 10.0058 5.1632C9.97059 5.23109 9.95254 5.30672 9.93965 5.38234Z"
              fill="#020204"
            />
            <mask
              id="mask20_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="4"
              width="2"
              height="3"
            >
              <path
                d="M9.93965 5.38234C9.91215 5.54906 9.91129 5.72351 9.96801 5.88164C10.0058 5.98648 10.0686 6.08187 10.1442 6.16351C10.1932 6.2168 10.2499 6.26492 10.3152 6.29672C10.3814 6.32851 10.457 6.34141 10.5283 6.32422C10.5936 6.30789 10.6504 6.26578 10.6925 6.21336C10.7346 6.16094 10.7629 6.0982 10.7827 6.03375C10.842 5.84383 10.8325 5.63672 10.7732 5.4468C10.7311 5.30844 10.6598 5.17523 10.5498 5.08242C10.4965 5.03773 10.4346 5.0025 10.3676 4.98703C10.3006 4.9707 10.2275 4.975 10.1648 5.00336C10.0952 5.03516 10.0411 5.09531 10.0058 5.1632C9.97059 5.23109 9.95254 5.30672 9.93965 5.38234Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask20_4007_13817)">
              <g filter="url(#filter20_f_4007_13817)">
                <path
                  d="M10.291 5.26215C10.3091 5.30941 10.3692 5.3266 10.4027 5.36441C10.4345 5.40051 10.468 5.43918 10.4861 5.48472C10.5196 5.57152 10.4526 5.70043 10.523 5.7623C10.5445 5.78121 10.5892 5.78207 10.6107 5.7623C10.6958 5.68496 10.6769 5.52941 10.6434 5.41941C10.6124 5.32058 10.5377 5.22605 10.4448 5.17879C10.4019 5.15644 10.3374 5.1384 10.3005 5.16933C10.2773 5.18996 10.2798 5.23293 10.291 5.26215Z"
                  fill="url(#paint13_linear_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask21_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="4"
              width="3"
              height="3"
            >
              <path
                d="M10.2577 4.29022C10.1263 4.2971 9.99993 4.35811 9.90368 4.44835C9.80657 4.53858 9.73868 4.65717 9.69657 4.78178C9.61321 5.03272 9.63212 5.30428 9.65102 5.56811C9.66821 5.80616 9.68626 6.04936 9.77563 6.27108C9.82032 6.3828 9.88305 6.48678 9.96641 6.57272C10.0498 6.6578 10.1555 6.72397 10.2715 6.75233C10.3798 6.77983 10.4966 6.77467 10.6032 6.74116C10.7106 6.70678 10.8077 6.64577 10.8885 6.56757C11.0054 6.45327 11.0836 6.30374 11.1291 6.14647C11.1747 5.98921 11.1876 5.82421 11.185 5.66007C11.1816 5.45553 11.1549 5.25014 11.0913 5.05592C11.0269 4.86171 10.9246 4.6778 10.782 4.53085C10.7132 4.45952 10.6341 4.39764 10.5456 4.35381C10.4563 4.30999 10.3566 4.28506 10.2577 4.29022Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask21_4007_13817)">
              <path
                d="M9.97344 4.82126C10.1728 4.70266 10.4143 4.67516 10.6455 4.69665C10.8758 4.71899 11.0984 4.78602 11.3218 4.84704C11.4859 4.89173 11.6518 4.93298 11.8056 5.00516C11.9586 5.07735 12.1013 5.18391 12.1863 5.33173C12.2001 5.35493 12.2113 5.37985 12.2259 5.40305C12.2405 5.42626 12.2577 5.44774 12.2791 5.46407C12.3006 5.4804 12.3281 5.49157 12.3548 5.48985C12.3685 5.48899 12.3823 5.48555 12.3934 5.47868C12.4055 5.4718 12.4158 5.46149 12.4218 5.44946C12.4287 5.43571 12.4313 5.41938 12.4304 5.40391C12.4295 5.38844 12.4261 5.37298 12.4218 5.35751C12.3659 5.15383 12.2336 4.9811 12.1339 4.79462C12.0738 4.6829 12.0239 4.56602 11.9638 4.4543C11.7549 4.07188 11.412 3.77196 11.0227 3.57688C10.6334 3.3818 10.1986 3.28813 9.76375 3.26321C9.26016 3.23313 8.75571 3.29329 8.25899 3.38094C8.04328 3.41876 7.82414 3.46344 7.63422 3.57087C7.53883 3.62415 7.45117 3.6929 7.38328 3.77883C7.31453 3.86391 7.26641 3.96618 7.25008 4.07446C7.23547 4.1793 7.25094 4.28673 7.28789 4.38555C7.32399 4.48438 7.38071 4.57462 7.44774 4.65626C7.58008 4.8204 7.7511 4.94758 7.90836 5.08852C8.06563 5.2286 8.21086 5.38415 8.38703 5.50016C8.47469 5.5586 8.57008 5.60673 8.67149 5.63508C8.77289 5.66344 8.88117 5.67118 8.9843 5.64969C9.09086 5.62821 9.19055 5.57579 9.27735 5.50876C9.36414 5.44258 9.43891 5.36266 9.51024 5.28016C9.65289 5.1143 9.7861 4.93298 9.97344 4.82126Z"
                fill="url(#paint14_radial_4007_13817)"
              />
            </g>
            <g filter="url(#filter21_f_4007_13817)">
              <path
                d="M10.8007 4.14304C10.9863 4.31663 11.1238 4.53148 11.2175 4.75835C11.1762 4.50827 11.1118 4.30632 10.9485 4.14304C10.8488 4.04937 10.7345 3.96687 10.6065 3.90929C10.493 3.85859 10.3684 3.82679 10.2962 3.82593C10.2249 3.82421 10.2077 3.82593 10.1931 3.82679C10.1777 3.82765 10.1665 3.82765 10.2129 3.83367C10.2593 3.83882 10.3633 3.86718 10.475 3.91702C10.5867 3.96687 10.7002 4.04937 10.8007 4.14304Z"
                fill="url(#paint15_linear_4007_13817)"
              />
            </g>
            <path
              d="M12.592 4.28999C12.3866 4.43179 12.2139 4.62858 12.1297 4.86405C12.0231 5.16054 12.0635 5.49226 12.1666 5.7896C12.2715 6.09468 12.4476 6.38515 12.7132 6.56819C12.8455 6.66015 12.9993 6.72288 13.1592 6.74179C13.3199 6.76069 13.4858 6.73405 13.6293 6.66015C13.8029 6.56905 13.9361 6.41007 14.0186 6.23218C14.1011 6.05343 14.1363 5.85663 14.1475 5.65984C14.1612 5.41062 14.1372 5.15624 14.0547 4.91991C13.9653 4.66296 13.8037 4.42577 13.5751 4.27796C13.4617 4.20405 13.3319 4.15335 13.197 4.13444C13.0621 4.11554 12.9229 4.13015 12.7957 4.17913C12.7235 4.20749 12.6565 4.24616 12.592 4.28999Z"
              fill="url(#paint16_radial_4007_13817)"
            />
            <path
              d="M13.0674 4.91226C12.9901 4.91741 12.9145 4.94405 12.8483 4.9853C12.783 5.02655 12.7271 5.08241 12.6833 5.14687C12.5948 5.27491 12.5561 5.43132 12.5501 5.58687C12.5458 5.70288 12.5587 5.82062 12.5974 5.93062C12.636 6.04062 12.7005 6.14288 12.7907 6.21679C12.8827 6.29327 12.9987 6.33882 13.1182 6.34483C13.2376 6.34999 13.3579 6.31562 13.4559 6.24772C13.5341 6.19358 13.5968 6.11882 13.6415 6.0346C13.6862 5.95124 13.7137 5.85843 13.7257 5.7639C13.7472 5.59804 13.7232 5.42444 13.6458 5.27491C13.5693 5.12538 13.4387 5.00249 13.2814 4.94491C13.2127 4.91999 13.1396 4.9071 13.0674 4.91226Z"
              fill="#020204"
            />
            <mask
              id="mask22_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="12"
              y="4"
              width="2"
              height="3"
            >
              <path
                d="M13.0674 4.91226C12.9901 4.91741 12.9145 4.94405 12.8483 4.9853C12.783 5.02655 12.7271 5.08241 12.6833 5.14687C12.5948 5.27491 12.5561 5.43132 12.5501 5.58687C12.5458 5.70288 12.5587 5.82062 12.5974 5.93062C12.636 6.04062 12.7005 6.14288 12.7907 6.21679C12.8827 6.29327 12.9987 6.33882 13.1182 6.34483C13.2376 6.34999 13.3579 6.31562 13.4559 6.24772C13.5341 6.19358 13.5968 6.11882 13.6415 6.0346C13.6862 5.95124 13.7137 5.85843 13.7257 5.7639C13.7472 5.59804 13.7232 5.42444 13.6458 5.27491C13.5693 5.12538 13.4387 5.00249 13.2814 4.94491C13.2127 4.91999 13.1396 4.9071 13.0674 4.91226Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask22_4007_13817)">
              <g filter="url(#filter22_f_4007_13817)">
                <path
                  d="M13.5462 5.58614C13.6321 5.50021 13.4852 5.27247 13.37 5.1788C13.2867 5.11177 13.0452 5.04044 13.0555 5.11435C13.0658 5.18739 13.1749 5.28193 13.2471 5.35411C13.3374 5.44263 13.5049 5.62739 13.5462 5.58614Z"
                  fill="url(#paint17_linear_4007_13817)"
                />
              </g>
            </g>
            <mask
              id="mask23_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="12"
              y="4"
              width="3"
              height="3"
            >
              <path
                d="M12.592 4.28999C12.3866 4.43179 12.2139 4.62858 12.1297 4.86405C12.0231 5.16054 12.0635 5.49226 12.1666 5.7896C12.2715 6.09468 12.4476 6.38515 12.7132 6.56819C12.8455 6.66015 12.9993 6.72288 13.1592 6.74179C13.3199 6.76069 13.4858 6.73405 13.6293 6.66015C13.8029 6.56905 13.9361 6.41007 14.0186 6.23218C14.1011 6.05343 14.1363 5.85663 14.1475 5.65984C14.1612 5.41062 14.1372 5.15624 14.0547 4.91991C13.9653 4.66296 13.8037 4.42577 13.5751 4.27796C13.4617 4.20405 13.3319 4.15335 13.197 4.13444C13.0621 4.11554 12.9229 4.13015 12.7957 4.17913C12.7235 4.20749 12.6565 4.24616 12.592 4.28999Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask23_4007_13817)">
              <path
                d="M11.8142 5.04023C11.9912 4.89328 12.1966 4.78156 12.4157 4.71367C12.8669 4.57445 13.3782 4.62945 13.777 4.88297C13.9145 4.96976 14.0374 5.07805 14.1654 5.17945C14.2926 5.28 14.4284 5.37453 14.5822 5.42867C14.6656 5.45789 14.7541 5.47508 14.8417 5.46562C14.9208 5.45789 14.9973 5.42781 15.0626 5.38312C15.1279 5.33758 15.1829 5.27742 15.2242 5.20953C15.3067 5.07375 15.3333 4.90875 15.3187 4.75062C15.2903 4.43351 15.1116 4.15422 14.962 3.8732C14.9148 3.78555 14.8701 3.69617 14.8134 3.61453C14.6406 3.36273 14.3639 3.19687 14.0743 3.09976C13.7847 3.00265 13.4788 2.96742 13.1754 2.92961C13.0396 2.91242 12.903 2.89437 12.7681 2.9107C12.6125 2.92961 12.4664 2.99234 12.3143 3.03101C12.2421 3.04992 12.1691 3.06281 12.0977 3.08344C12.0264 3.10406 11.956 3.13242 11.8975 3.17883C11.8133 3.24672 11.76 3.34898 11.7368 3.45469C11.7136 3.56125 11.7188 3.67125 11.736 3.77867C11.7695 3.99351 11.8502 4.20234 11.8425 4.41976C11.8374 4.56844 11.7901 4.71539 11.7961 4.86406C11.7978 4.90789 11.8064 4.99726 11.8142 5.04023Z"
                fill="url(#paint18_linear_4007_13817)"
              />
            </g>
            <g filter="url(#filter23_f_4007_13817)">
              <path
                d="M13.3068 3.67969C13.2716 3.72437 13.2398 3.7725 13.2148 3.82578C13.3738 3.86016 13.5251 3.92547 13.6609 4.0157C13.9238 4.19016 14.1232 4.44453 14.2633 4.72813C14.2959 4.69203 14.3252 4.65336 14.3509 4.61039C14.21 4.31477 14.0072 4.0475 13.7356 3.86703C13.605 3.78109 13.4598 3.71492 13.3068 3.67969Z"
                fill="url(#paint19_linear_4007_13817)"
              />
            </g>
            <mask
              id="mask24_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask24_4007_13817)">
              <g filter="url(#filter24_f_4007_13817)">
                <path
                  d="M9.97134 8.67662C10.0977 9.04271 10.3512 9.35466 10.652 9.59787C10.7517 9.67951 10.8574 9.75427 10.9751 9.80669C11.092 9.85998 11.2217 9.89005 11.3498 9.87888C11.4752 9.86857 11.5947 9.81873 11.7047 9.75685C11.8147 9.69498 11.9161 9.62021 12.0227 9.55318C12.2049 9.43716 12.3999 9.34349 12.5899 9.24037C12.8176 9.11576 13.0393 8.97396 13.231 8.79865C13.3195 8.71873 13.4011 8.63107 13.4982 8.56232C13.5953 8.49271 13.7122 8.44287 13.8308 8.45146C13.9202 8.45748 14.0035 8.49529 14.0912 8.51419C14.135 8.52365 14.1797 8.52794 14.2244 8.52107C14.2682 8.51419 14.3112 8.49615 14.3421 8.46349C14.3799 8.42396 14.3971 8.36724 14.3945 8.31224C14.3928 8.2581 14.3739 8.20482 14.3481 8.15669C14.2974 8.05958 14.2201 7.97623 14.1857 7.87224C14.1548 7.77943 14.1608 7.67974 14.1634 7.58263C14.166 7.48466 14.1642 7.38326 14.1195 7.29646C14.0852 7.23115 14.0276 7.17873 13.9623 7.14435C13.897 7.10912 13.8231 7.09107 13.7492 7.08419C13.6013 7.07044 13.4535 7.09966 13.3049 7.1074C13.1089 7.11857 12.9113 7.09451 12.7145 7.10568C12.4695 7.12115 12.2289 7.19162 11.9831 7.19247C11.703 7.19333 11.4228 7.10138 11.1461 7.14521C11.0267 7.16412 10.9132 7.20708 10.7998 7.2449C10.6855 7.28357 10.5677 7.31708 10.4474 7.31537C10.3108 7.31279 10.1785 7.26552 10.0418 7.25607C9.97392 7.25091 9.90431 7.25607 9.83986 7.28013C9.77626 7.30419 9.71869 7.34802 9.68689 7.40904C9.66884 7.44341 9.66025 7.48294 9.65853 7.52162C9.65681 7.56115 9.66197 7.59982 9.67142 7.63849C9.69033 7.71412 9.72556 7.78544 9.75392 7.85763C9.85705 8.12146 9.87939 8.40935 9.97134 8.67662Z"
                  fill="black"
                  fillOpacity="0.258824"
                />
              </g>
            </g>
            <mask
              id="mask25_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="1"
              width="13"
              height="22"
            >
              <path
                d="M12.1912 1C11.6756 1 11.1583 1.10141 10.6908 1.35406C10.194 1.62133 9.78669 2.0493 9.53661 2.55461C9.28567 3.05992 9.1877 3.59961 9.17223 4.1943C9.14388 5.32352 9.19802 6.50774 9.28309 7.5811C9.30544 7.90766 9.34669 8.09844 9.30458 8.43445C9.16536 9.14774 8.54145 9.62727 8.20802 10.2538C7.84106 10.9447 7.68638 11.7259 7.40966 12.458C7.15614 13.1275 6.80036 13.7557 6.56059 14.4303C6.22458 15.373 6.12403 16.4095 6.34575 17.3857C6.51505 18.1299 6.86825 18.8277 7.34434 19.425C7.27559 19.5488 7.20856 19.6751 7.13809 19.798C6.91723 20.1787 6.64739 20.5405 6.52192 20.9624C6.45919 21.173 6.43427 21.3981 6.47466 21.6147C6.51505 21.8313 6.62505 22.0384 6.79692 22.1759C6.9095 22.2652 7.04614 22.3237 7.18622 22.3563C7.3263 22.3881 7.47153 22.3959 7.61591 22.3933C8.16333 22.3813 8.69442 22.2154 9.2238 22.0762C9.53833 21.9937 9.85458 21.9206 10.1717 21.8545C11.3009 21.6233 12.5608 21.7161 13.6083 21.8673C13.9633 21.9215 14.3156 21.9911 14.6645 22.0762C15.2111 22.2085 15.755 22.377 16.3171 22.3933C16.4649 22.3976 16.6144 22.3907 16.7588 22.3589C16.9032 22.3263 17.0433 22.2678 17.1584 22.1759C17.3311 22.0384 17.4411 21.8304 17.4815 21.6138C17.5219 21.3973 17.497 21.1713 17.4334 20.9598C17.3062 20.537 17.0338 20.177 16.8086 19.798C16.7193 19.6476 16.6368 19.4946 16.5482 19.3442C17.2272 18.582 17.7737 17.7002 18.09 16.7317C18.4346 15.6747 18.4982 14.536 18.3529 13.4334C18.2077 12.3309 17.8579 11.2618 17.3982 10.2495C16.8207 8.98102 16.3351 8.52211 15.9828 7.4075C15.6029 6.20266 15.9166 4.77695 15.6339 3.68469C15.5333 3.31344 15.3726 2.95852 15.1655 2.63367C14.9232 2.25297 14.6155 1.91266 14.2503 1.64797C13.6676 1.22516 12.9354 1 12.1912 1Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask25_4007_13817)">
              <g opacity="0.3" filter="url(#filter25_f_4007_13817)">
                <path
                  d="M9.61998 7.63459C9.8649 7.78584 10.0849 7.97233 10.2697 8.18373C10.4407 8.38053 10.5859 8.60397 10.8102 8.7492C10.9675 8.85233 11.1574 8.9099 11.3499 8.92795C11.5759 8.94858 11.8045 8.91506 12.0228 8.85576C12.2247 8.80162 12.4198 8.72428 12.5986 8.62287C12.9397 8.42951 13.2242 8.14592 13.5997 8.01873C13.6814 7.99037 13.7665 7.97061 13.8455 7.93623C13.9246 7.90272 13.9994 7.85115 14.0372 7.77897C14.0733 7.7085 14.0715 7.62858 14.0836 7.55209C14.0965 7.46959 14.1248 7.39053 14.1403 7.30889C14.1558 7.22725 14.1575 7.14045 14.1197 7.06569C14.0879 7.00381 14.0303 6.95483 13.9641 6.92303C13.8979 6.89209 13.8232 6.87834 13.7484 6.8749C13.6006 6.86803 13.4537 6.90326 13.305 6.9153C13.109 6.93162 12.9114 6.90842 12.7146 6.91444C12.4697 6.92131 12.2282 6.97201 11.9833 6.98319C11.704 6.99522 11.4255 6.95397 11.1462 6.94537C11.0251 6.94108 10.903 6.9428 10.7844 6.96256C10.665 6.98147 10.5481 7.01842 10.4476 7.0803C10.3496 7.13959 10.2697 7.22123 10.1803 7.29084C10.1356 7.32608 10.0875 7.35787 10.0359 7.38279C9.9835 7.40686 9.92592 7.42405 9.86748 7.42662C9.8374 7.42748 9.80646 7.42576 9.77725 7.43006C9.72654 7.43694 9.68014 7.46358 9.65092 7.50139C9.6217 7.54006 9.61053 7.58904 9.61998 7.63459Z"
                  fill="black"
                />
              </g>
            </g>
            <path
              d="M10.877 6.03006C11.0085 5.8831 11.0979 5.70349 11.2233 5.55224C11.2861 5.47662 11.3591 5.40701 11.4442 5.35803C11.5293 5.30904 11.629 5.28154 11.7269 5.29013C11.8361 5.29959 11.9383 5.35373 12.0183 5.42849C12.0982 5.50326 12.1575 5.59779 12.2047 5.69662C12.2503 5.79201 12.2864 5.89256 12.3517 5.97506C12.4213 6.06271 12.5184 6.12201 12.6069 6.19162C12.6508 6.22599 12.6937 6.26295 12.7281 6.30678C12.7633 6.3506 12.79 6.40131 12.802 6.45631C12.8132 6.51217 12.8072 6.5706 12.7883 6.62388C12.7685 6.67717 12.7358 6.72529 12.6946 6.76482C12.6129 6.84388 12.5012 6.88685 12.3886 6.90232C12.1635 6.93412 11.9358 6.86709 11.708 6.8731C11.4786 6.87998 11.2534 6.96076 11.0231 6.95302C10.9088 6.94873 10.7919 6.92037 10.6974 6.85506C10.6501 6.82154 10.6089 6.77943 10.5788 6.73045C10.5496 6.68146 10.5315 6.62474 10.529 6.56717C10.5272 6.51217 10.5401 6.45717 10.5625 6.40732C10.584 6.35662 10.6149 6.31107 10.6493 6.2681C10.7189 6.18303 10.804 6.11256 10.877 6.03006Z"
              fill="url(#paint20_radial_4007_13817)"
            />
            <path
              d="M9.62939 7.44942C9.63455 7.47177 9.64229 7.49239 9.65346 7.51216C9.67322 7.54481 9.70244 7.57145 9.73338 7.5938C9.76432 7.617 9.79783 7.63591 9.83049 7.65567C10.0032 7.76481 10.1442 7.91692 10.2696 8.07848C10.4372 8.29505 10.5859 8.53481 10.8102 8.69208C10.9683 8.8038 11.1574 8.86653 11.3499 8.8863C11.5759 8.90864 11.8045 8.87255 12.0228 8.80809C12.2247 8.7488 12.4198 8.66544 12.5985 8.55458C12.9397 8.34489 13.2242 8.03723 13.5997 7.89887C13.6813 7.8688 13.7664 7.84731 13.8455 7.81036C13.9246 7.77255 13.9993 7.71755 14.0371 7.63848C14.0732 7.56286 14.0715 7.4752 14.0835 7.39184C14.0964 7.30333 14.1248 7.21739 14.1403 7.12887C14.1557 7.04036 14.1574 6.94583 14.1196 6.86419C14.0878 6.79716 14.0303 6.74387 13.9641 6.71036C13.8979 6.67598 13.8231 6.66052 13.7484 6.65708C13.6006 6.6502 13.4536 6.68801 13.3049 6.70176C13.109 6.71981 12.9113 6.69403 12.7146 6.70005C12.4696 6.70778 12.2281 6.76278 11.9832 6.77481C11.7039 6.7877 11.4255 6.74473 11.1462 6.73356C11.025 6.72841 10.903 6.73012 10.7835 6.75075C10.6641 6.77051 10.5472 6.81177 10.4475 6.88052C10.3504 6.94755 10.2731 7.03864 10.1837 7.11598C10.139 7.15466 10.0909 7.18989 10.0376 7.21567C9.98518 7.24145 9.92674 7.25778 9.86744 7.25606C9.83736 7.2552 9.80557 7.25091 9.77721 7.2595C9.75916 7.26551 9.74283 7.27669 9.72908 7.28958C9.71533 7.30333 9.70416 7.3188 9.69385 7.33426C9.66893 7.37036 9.64744 7.40903 9.62939 7.44942Z"
              fill="url(#paint21_radial_4007_13817)"
            />
            <mask
              id="mask26_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="6"
              width="6"
              height="3"
            >
              <path
                d="M9.62939 7.44942C9.63455 7.47177 9.64229 7.49239 9.65346 7.51216C9.67322 7.54481 9.70244 7.57145 9.73338 7.5938C9.76432 7.617 9.79783 7.63591 9.83049 7.65567C10.0032 7.76481 10.1442 7.91692 10.2696 8.07848C10.4372 8.29505 10.5859 8.53481 10.8102 8.69208C10.9683 8.8038 11.1574 8.86653 11.3499 8.8863C11.5759 8.90864 11.8045 8.87255 12.0228 8.80809C12.2247 8.7488 12.4198 8.66544 12.5985 8.55458C12.9397 8.34489 13.2242 8.03723 13.5997 7.89887C13.6813 7.8688 13.7664 7.84731 13.8455 7.81036C13.9246 7.77255 13.9993 7.71755 14.0371 7.63848C14.0732 7.56286 14.0715 7.4752 14.0835 7.39184C14.0964 7.30333 14.1248 7.21739 14.1403 7.12887C14.1557 7.04036 14.1574 6.94583 14.1196 6.86419C14.0878 6.79716 14.0303 6.74387 13.9641 6.71036C13.8979 6.67598 13.8231 6.66052 13.7484 6.65708C13.6006 6.6502 13.4536 6.68801 13.3049 6.70176C13.109 6.71981 12.9113 6.69403 12.7146 6.70005C12.4696 6.70778 12.2281 6.76278 11.9832 6.77481C11.7039 6.7877 11.4255 6.74473 11.1462 6.73356C11.025 6.72841 10.903 6.73012 10.7835 6.75075C10.6641 6.77051 10.5472 6.81177 10.4475 6.88052C10.3504 6.94755 10.2731 7.03864 10.1837 7.11598C10.139 7.15466 10.0909 7.18989 10.0376 7.21567C9.98518 7.24145 9.92674 7.25778 9.86744 7.25606C9.83736 7.2552 9.80557 7.25091 9.77721 7.2595C9.75916 7.26551 9.74283 7.27669 9.72908 7.28958C9.71533 7.30333 9.70416 7.3188 9.69385 7.33426C9.66893 7.37036 9.64744 7.40903 9.62939 7.44942Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask26_4007_13817)">
              <g filter="url(#filter26_f_4007_13817)">
                <path
                  d="M10.7258 7.7512C10.6974 7.86893 10.7146 7.99784 10.7739 8.1044C10.8323 8.21096 10.932 8.29346 11.048 8.33214C11.1941 8.38112 11.3591 8.35706 11.4958 8.28573C11.5757 8.24448 11.6479 8.18604 11.6934 8.1087C11.7166 8.07089 11.7329 8.02792 11.739 7.98323C11.7441 7.9394 11.7407 7.89299 11.7252 7.85089C11.708 7.80534 11.6771 7.76495 11.6401 7.73315C11.6023 7.70135 11.5585 7.67815 11.5112 7.66268C11.4184 7.63174 11.2732 7.60596 11.1752 7.60424C11.0016 7.60081 10.7541 7.63346 10.7258 7.7512Z"
                  fill="#D9B30D"
                />
              </g>
            </g>
            <mask
              id="mask27_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="6"
              width="6"
              height="3"
            >
              <path
                d="M9.62939 7.44942C9.63455 7.47177 9.64229 7.49239 9.65346 7.51216C9.67322 7.54481 9.70244 7.57145 9.73338 7.5938C9.76432 7.617 9.79783 7.63591 9.83049 7.65567C10.0032 7.76481 10.1442 7.91692 10.2696 8.07848C10.4372 8.29505 10.5859 8.53481 10.8102 8.69208C10.9683 8.8038 11.1574 8.86653 11.3499 8.8863C11.5759 8.90864 11.8045 8.87255 12.0228 8.80809C12.2247 8.7488 12.4198 8.66544 12.5985 8.55458C12.9397 8.34489 13.2242 8.03723 13.5997 7.89887C13.6813 7.8688 13.7664 7.84731 13.8455 7.81036C13.9246 7.77255 13.9993 7.71755 14.0371 7.63848C14.0732 7.56286 14.0715 7.4752 14.0835 7.39184C14.0964 7.30333 14.1248 7.21739 14.1403 7.12887C14.1557 7.04036 14.1574 6.94583 14.1196 6.86419C14.0878 6.79716 14.0303 6.74387 13.9641 6.71036C13.8979 6.67598 13.8231 6.66052 13.7484 6.65708C13.6006 6.6502 13.4536 6.68801 13.3049 6.70176C13.109 6.71981 12.9113 6.69403 12.7146 6.70005C12.4696 6.70778 12.2281 6.76278 11.9832 6.77481C11.7039 6.7877 11.4255 6.74473 11.1462 6.73356C11.025 6.72841 10.903 6.73012 10.7835 6.75075C10.6641 6.77051 10.5472 6.81177 10.4475 6.88052C10.3504 6.94755 10.2731 7.03864 10.1837 7.11598C10.139 7.15466 10.0909 7.18989 10.0376 7.21567C9.98518 7.24145 9.92674 7.25778 9.86744 7.25606C9.83736 7.2552 9.80557 7.25091 9.77721 7.2595C9.75916 7.26551 9.74283 7.27669 9.72908 7.28958C9.71533 7.30333 9.70416 7.3188 9.69385 7.33426C9.66893 7.37036 9.64744 7.40903 9.62939 7.44942Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask27_4007_13817)">
              <g filter="url(#filter27_f_4007_13817)">
                <path
                  d="M10.2458 6.8316C10.1461 6.89004 10.0507 6.95449 9.95705 7.02067C9.90806 7.0559 9.85822 7.09285 9.83244 7.147C9.81439 7.18481 9.8101 7.22778 9.80924 7.26989C9.80924 7.31285 9.81181 7.35496 9.8058 7.39707C9.80236 7.42543 9.79463 7.45379 9.79377 7.48215C9.79291 7.49676 9.79377 7.51137 9.7972 7.52512C9.8015 7.53887 9.80838 7.55262 9.81783 7.56293C9.83072 7.57668 9.84791 7.58528 9.86595 7.59043C9.884 7.59559 9.90205 7.59817 9.92095 7.60246C10.0078 7.62309 10.0834 7.67637 10.1495 7.73653C10.2157 7.79582 10.2759 7.86371 10.3455 7.91957C10.5328 8.07254 10.7812 8.13614 11.0235 8.13957C11.2659 8.14387 11.5056 8.09317 11.742 8.03989C11.9276 7.99778 12.1132 7.95481 12.2911 7.88864C12.5661 7.78723 12.8205 7.63426 13.0405 7.44262C13.141 7.35582 13.2347 7.26043 13.3473 7.18996C13.4461 7.12723 13.557 7.08598 13.6584 7.02668C13.6678 7.02153 13.6764 7.01551 13.6842 7.0095C13.6928 7.00262 13.6996 6.99403 13.7048 6.98543C13.7125 6.9691 13.7125 6.94934 13.7074 6.93215C13.7022 6.91496 13.6919 6.8995 13.6807 6.88489C13.6678 6.86942 13.6541 6.85567 13.6386 6.84192C13.5329 6.75168 13.3903 6.71903 13.251 6.70785C13.1127 6.69754 12.9726 6.70528 12.836 6.68207C12.707 6.66059 12.585 6.61246 12.4604 6.57293C12.3298 6.53082 12.1966 6.49817 12.0617 6.47582C11.7428 6.4234 11.4154 6.43371 11.1 6.49903C10.8001 6.56176 10.5096 6.6752 10.2458 6.8316Z"
                  fill="#604405"
                />
              </g>
            </g>
            <path
              d="M10.2139 6.49561C10.0712 6.59186 9.94232 6.7096 9.83318 6.8428C9.7713 6.91929 9.71458 7.00265 9.68365 7.09546C9.65873 7.16936 9.65013 7.24671 9.63466 7.32319C9.62865 7.35155 9.62177 7.37991 9.62263 7.40913C9.62263 7.42288 9.62435 7.43749 9.62865 7.4521C9.63294 7.46585 9.64068 7.47874 9.65013 7.48905C9.66732 7.50624 9.69052 7.51569 9.71373 7.52085C9.73779 7.52515 9.76185 7.526 9.78591 7.52858C9.89333 7.54147 9.99216 7.59304 10.0815 7.65491C10.1709 7.71593 10.2534 7.78811 10.3454 7.84569C10.5473 7.97374 10.7888 8.03046 11.0286 8.03991C11.2675 8.04936 11.5064 8.01327 11.7419 7.966C11.9283 7.92905 12.114 7.88436 12.291 7.81476C12.5626 7.70733 12.8075 7.54319 13.0404 7.36874C13.1469 7.28968 13.2518 7.20804 13.3472 7.11608C13.379 7.08429 13.4108 7.05249 13.4451 7.02327C13.4795 6.99491 13.5182 6.96999 13.5611 6.95624C13.6265 6.93561 13.6969 6.94163 13.7648 6.9528C13.8155 6.9614 13.8662 6.97257 13.9178 6.97085C13.9436 6.96913 13.9694 6.96483 13.9934 6.95538C14.0175 6.94593 14.0398 6.93132 14.0561 6.91069C14.0776 6.88491 14.0888 6.85054 14.0888 6.81702C14.0888 6.78265 14.0785 6.74913 14.0613 6.71991C14.0269 6.66147 13.9676 6.62194 13.9058 6.59444C13.8207 6.55663 13.7287 6.53858 13.6385 6.51624C13.3609 6.44921 13.0911 6.35038 12.8358 6.22319C12.7086 6.16046 12.5849 6.09085 12.4603 6.02382C12.3314 5.95507 12.2008 5.8889 12.0615 5.84593C11.7487 5.7514 11.4084 5.78233 11.0999 5.88975C10.7579 6.00835 10.4485 6.21975 10.2139 6.49561Z"
              fill="url(#paint22_linear_4007_13817)"
            />
            <mask
              id="mask28_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="5"
              width="6"
              height="4"
            >
              <path
                d="M10.2139 6.49561C10.0712 6.59186 9.94232 6.7096 9.83318 6.8428C9.7713 6.91929 9.71458 7.00265 9.68365 7.09546C9.65873 7.16936 9.65013 7.24671 9.63466 7.32319C9.62865 7.35155 9.62177 7.37991 9.62263 7.40913C9.62263 7.42288 9.62435 7.43749 9.62865 7.4521C9.63294 7.46585 9.64068 7.47874 9.65013 7.48905C9.66732 7.50624 9.69052 7.51569 9.71373 7.52085C9.73779 7.52515 9.76185 7.526 9.78591 7.52858C9.89333 7.54147 9.99216 7.59304 10.0815 7.65491C10.1709 7.71593 10.2534 7.78811 10.3454 7.84569C10.5473 7.97374 10.7888 8.03046 11.0286 8.03991C11.2675 8.04936 11.5064 8.01327 11.7419 7.966C11.9283 7.92905 12.114 7.88436 12.291 7.81476C12.5626 7.70733 12.8075 7.54319 13.0404 7.36874C13.1469 7.28968 13.2518 7.20804 13.3472 7.11608C13.379 7.08429 13.4108 7.05249 13.4451 7.02327C13.4795 6.99491 13.5182 6.96999 13.5611 6.95624C13.6265 6.93561 13.6969 6.94163 13.7648 6.9528C13.8155 6.9614 13.8662 6.97257 13.9178 6.97085C13.9436 6.96913 13.9694 6.96483 13.9934 6.95538C14.0175 6.94593 14.0398 6.93132 14.0561 6.91069C14.0776 6.88491 14.0888 6.85054 14.0888 6.81702C14.0888 6.78265 14.0785 6.74913 14.0613 6.71991C14.0269 6.66147 13.9676 6.62194 13.9058 6.59444C13.8207 6.55663 13.7287 6.53858 13.6385 6.51624C13.3609 6.44921 13.0911 6.35038 12.8358 6.22319C12.7086 6.16046 12.5849 6.09085 12.4603 6.02382C12.3314 5.95507 12.2008 5.8889 12.0615 5.84593C11.7487 5.7514 11.4084 5.78233 11.0999 5.88975C10.7579 6.00835 10.4485 6.21975 10.2139 6.49561Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask28_4007_13817)">
              <g filter="url(#filter28_f_4007_13817)">
                <path
                  d="M12.4061 6.56431C12.3889 6.54369 12.3648 6.52822 12.339 6.52048C12.3132 6.51275 12.2857 6.51275 12.2591 6.51705C12.2058 6.5265 12.1577 6.55486 12.113 6.5858C11.9867 6.67259 11.875 6.78002 11.777 6.89861C11.6481 7.05587 11.5415 7.2372 11.5054 7.43744C11.4994 7.47095 11.496 7.50619 11.4994 7.5397C11.5037 7.57408 11.5166 7.60759 11.5415 7.63166C11.5621 7.65314 11.5914 7.66603 11.6206 7.67033C11.6507 7.67463 11.6816 7.67033 11.71 7.66088C11.7667 7.64197 11.8139 7.60158 11.8595 7.56205C12.1061 7.34033 12.3296 7.07564 12.4189 6.75595C12.4275 6.72416 12.4353 6.6915 12.4353 6.65798C12.4353 6.62447 12.4267 6.59095 12.4061 6.56431Z"
                  fill="#F6DA4A"
                />
              </g>
            </g>
            <g opacity="0.8" filter="url(#filter29_f_4007_13817)">
              <path
                d="M10.9684 6.07537C10.9022 6.05389 10.7939 6.1699 10.8291 6.2292C10.8386 6.24553 10.8687 6.26615 10.8893 6.25498C10.9194 6.23865 10.9443 6.1785 10.977 6.15529C10.9984 6.13983 10.9941 6.08311 10.9684 6.07537Z"
                fill="url(#paint23_radial_4007_13817)"
              />
            </g>
            <g opacity="0.8" filter="url(#filter30_f_4007_13817)">
              <path
                d="M11.8139 6.10644C11.8311 6.16144 11.9196 6.15199 11.9712 6.17863C12.0158 6.20183 12.0519 6.25254 12.1026 6.25426C12.1508 6.25511 12.2264 6.23707 12.2324 6.18894C12.2401 6.12621 12.1482 6.08582 12.0889 6.06261C12.0124 6.0334 11.9144 6.01793 11.8431 6.05746C11.8268 6.06691 11.8087 6.0884 11.8139 6.10644Z"
                fill="url(#paint24_radial_4007_13817)"
              />
            </g>
            <mask
              id="mask29_4007_13817"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="9"
              y="5"
              width="6"
              height="4"
            >
              <path
                d="M9.62939 7.44942C9.63455 7.47177 9.64229 7.49239 9.65346 7.51216C9.67322 7.54481 9.70244 7.57145 9.73338 7.5938C9.76432 7.617 9.79783 7.63591 9.83049 7.65567C10.0032 7.76481 10.1442 7.91692 10.2696 8.07848C10.4372 8.29505 10.5859 8.53481 10.8102 8.69208C10.9683 8.8038 11.1574 8.86653 11.3499 8.8863C11.5759 8.90864 11.8045 8.87255 12.0228 8.80809C12.2247 8.7488 12.4198 8.66544 12.5985 8.55458C12.9397 8.34489 13.2242 8.03723 13.5997 7.89887C13.6813 7.8688 13.7664 7.84731 13.8455 7.81036C13.9246 7.77255 13.9993 7.71755 14.0371 7.63848C14.0732 7.56286 14.0715 7.4752 14.0835 7.39184C14.0964 7.30333 14.1248 7.21739 14.1403 7.12887C14.1557 7.04036 14.1574 6.94583 14.1196 6.86419C14.0878 6.79716 14.0303 6.74387 13.9641 6.71036C13.8979 6.67598 13.8231 6.66052 13.7484 6.65708C13.6006 6.6502 13.4536 6.68801 13.3049 6.70176C13.109 6.71981 12.9113 6.69403 12.7146 6.70005C12.4696 6.70778 12.2281 6.76278 11.9832 6.77481C11.7039 6.7877 11.4255 6.74473 11.1462 6.73356C11.025 6.72841 10.903 6.73012 10.7835 6.75075C10.6641 6.77051 10.5472 6.81177 10.4475 6.88052C10.3504 6.94755 10.2731 7.03864 10.1837 7.11598C10.139 7.15466 10.0909 7.18989 10.0376 7.21567C9.98518 7.24145 9.92674 7.25778 9.86744 7.25606C9.83736 7.2552 9.80557 7.25091 9.77721 7.2595C9.75916 7.26551 9.74283 7.27669 9.72908 7.28958C9.71533 7.30333 9.70416 7.3188 9.69385 7.33426C9.66893 7.37036 9.64744 7.40903 9.62939 7.44942Z"
                fill="white"
              />
              <path
                d="M10.2139 6.49561C10.0712 6.59186 9.94232 6.7096 9.83318 6.8428C9.7713 6.91929 9.71458 7.00265 9.68365 7.09546C9.65873 7.16936 9.65013 7.24671 9.63466 7.32319C9.62865 7.35155 9.62177 7.37991 9.62263 7.40913C9.62263 7.42288 9.62435 7.43749 9.62865 7.4521C9.63294 7.46585 9.64068 7.47874 9.65013 7.48905C9.66732 7.50624 9.69052 7.51569 9.71373 7.52085C9.73779 7.52515 9.76185 7.526 9.78591 7.52858C9.89333 7.54147 9.99216 7.59304 10.0815 7.65491C10.1709 7.71593 10.2534 7.78811 10.3454 7.84569C10.5473 7.97374 10.7888 8.03046 11.0286 8.03991C11.2675 8.04936 11.5064 8.01327 11.7419 7.966C11.9283 7.92905 12.114 7.88436 12.291 7.81476C12.5626 7.70733 12.8075 7.54319 13.0404 7.36874C13.1469 7.28968 13.2518 7.20804 13.3472 7.11608C13.379 7.08429 13.4108 7.05249 13.4451 7.02327C13.4795 6.99491 13.5182 6.96999 13.5611 6.95624C13.6265 6.93561 13.6969 6.94163 13.7648 6.9528C13.8155 6.9614 13.8662 6.97257 13.9178 6.97085C13.9436 6.96913 13.9694 6.96483 13.9934 6.95538C14.0175 6.94593 14.0398 6.93132 14.0561 6.91069C14.0776 6.88491 14.0888 6.85054 14.0888 6.81702C14.0888 6.78265 14.0785 6.74913 14.0613 6.71991C14.0269 6.66147 13.9676 6.62194 13.9058 6.59444C13.8207 6.55663 13.7287 6.53858 13.6385 6.51624C13.3609 6.44921 13.0911 6.35038 12.8358 6.22319C12.7086 6.16046 12.5849 6.09085 12.4603 6.02382C12.3314 5.95507 12.2008 5.8889 12.0615 5.84593C11.7487 5.7514 11.4084 5.78233 11.0999 5.88975C10.7579 6.00835 10.4485 6.21975 10.2139 6.49561Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask29_4007_13817)">
              <g filter="url(#filter31_f_4007_13817)">
                <path
                  d="M14.1092 6.94229C14.1013 7.01176 14.0713 7.07831 14.0261 7.12731C13.9808 7.1763 13.9238 7.20372 13.8678 7.20354C13.8117 7.20357 13.7611 7.17608 13.7269 7.1271C13.6928 7.07813 13.6779 7.01166 13.6856 6.94229C13.6894 6.90782 13.6987 6.8737 13.713 6.84186C13.7272 6.81002 13.7461 6.7811 13.7685 6.75675C13.791 6.7324 13.8165 6.71311 13.8437 6.69997C13.8709 6.68683 13.8992 6.6801 13.927 6.68018C13.9549 6.68014 13.9816 6.6869 14.0058 6.70006C14.03 6.71322 14.0512 6.73253 14.0681 6.75687C14.0851 6.78121 14.0974 6.81012 14.1045 6.84194C14.1115 6.87375 14.1131 6.90785 14.1092 6.94229Z"
                  fill="url(#paint25_linear_4007_13817)"
                />
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_f_4007_13817"
              x="6.68387"
              y="8.86111"
              width="5.20453"
              height="5.03412"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.64"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter1_f_4007_13817"
              x="9.97262"
              y="7.98678"
              width="7.90291"
              height="6.68123"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.98"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter2_f_4007_13817"
              x="8.99205"
              y="8.90514"
              width="4.32596"
              height="4.4368"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.68"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter3_f_4007_13817"
              x="7.93408"
              y="10.2856"
              width="5.31445"
              height="9.44531"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.25"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter4_f_4007_13817"
              x="13.0147"
              y="8.71734"
              width="7.86609"
              height="7.88172"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.78"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter5_f_4007_13817"
              x="9.77535"
              y="-1.51762"
              width="8.13973"
              height="8.07625"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.73"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter6_f_4007_13817"
              x="13.2686"
              y="5.99664"
              width="4.02186"
              height="4.12439"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.78"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter7_f_4007_13817"
              x="4.45162"
              y="9.72066"
              width="5.91219"
              height="8.63582"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.98"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter8_f_4007_13817"
              x="13.5468"
              y="8.84559"
              width="6.84203"
              height="10.761"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.19"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter9_f_4007_13817"
              x="-3.47826"
              y="9.47291"
              width="20.0073"
              height="19.9482"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="3.38"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter10_f_4007_13817"
              x="-0.69414"
              y="11.7517"
              width="14.1461"
              height="14.4996"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2.1"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter11_f_4007_13817"
              x="6.7599"
              y="16.5343"
              width="3.22922"
              height="4.81564"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.32"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter12_f_4007_13817"
              x="10.893"
              y="13.2948"
              width="13.0891"
              height="13.5568"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.95"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter13_f_4007_13817"
              x="7.24584"
              y="8.26488"
              width="22.3364"
              height="22.4195"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="4.12"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter14_f_4007_13817"
              x="9.4851"
              y="9.81811"
              width="18.0703"
              height="18.0415"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="3.12"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter15_f_4007_13817"
              x="14.8397"
              y="15.6131"
              width="5.7152"
              height="2.29918"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.41"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter16_f_4007_13817"
              x="11.56"
              y="11.8881"
              width="12.321"
              height="11.9602"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2.45"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter17_f_4007_13817"
              x="14.22"
              y="14.407"
              width="7.001"
              height="6.04152"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.12"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter18_f_4007_13817"
              x="14.884"
              y="13.9392"
              width="6.10643"
              height="4.72996"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.88"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter19_f_4007_13817"
              x="14.884"
              y="13.9392"
              width="6.10643"
              height="4.72996"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.88"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter20_f_4007_13817"
              x="9.40271"
              y="4.27283"
              width="2.14916"
              height="2.38402"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.44"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter21_f_4007_13817"
              x="9.93969"
              y="3.5852"
              width="1.5176"
              height="1.41311"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.12"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter22_f_4007_13817"
              x="12.1552"
              y="4.18301"
              width="2.3166"
              height="2.30928"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.45"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter23_f_4007_13817"
              x="12.9548"
              y="3.41969"
              width="1.65623"
              height="1.56834"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.13"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter24_f_4007_13817"
              x="6.1582"
              y="3.58057"
              width="11.7368"
              height="9.80078"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="1.75"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter25_f_4007_13817"
              x="8.0167"
              y="5.27402"
              width="7.73369"
              height="5.26006"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.8"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter26_f_4007_13817"
              x="9.1724"
              y="6.064"
              width="4.10881"
              height="3.83391"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.77"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter27_f_4007_13817"
              x="8.49346"
              y="5.14238"
              width="6.51748"
              height="4.29727"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.65"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter28_f_4007_13817"
              x="10.038"
              y="5.05416"
              width="3.85701"
              height="4.07771"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.73"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter29_f_4007_13817"
              x="10.6223"
              y="5.87275"
              width="0.568457"
              height="0.585547"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.1"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter30_f_4007_13817"
              x="11.613"
              y="5.83369"
              width="0.819922"
              height="0.620703"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.1"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <filter
              id="filter31_f_4007_13817"
              x="13.2236"
              y="6.22018"
              width="1.34773"
              height="1.44344"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="0.23"
                result="effect1_foregroundBlur_4007_13817"
              />
            </filter>
            <radialGradient
              id="paint0_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(8.25777 11.4147) scale(1.63281 1.54687)"
            >
              <stop />
              <stop offset="1" stopOpacity="0.25" />
            </radialGradient>
            <radialGradient
              id="paint1_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(13.8061 12.3092) scale(2.02812 1.54688)"
            >
              <stop />
              <stop offset="1" stopOpacity="0.25" />
            </radialGradient>
            <radialGradient
              id="paint2_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(11.0963 11.9547) scale(0.803516 0.859375)"
            >
              <stop />
              <stop offset="1" stopOpacity="0.25" />
            </radialGradient>
            <linearGradient
              id="paint3_linear_4007_13817"
              x1="4.99203"
              y1="17.5867"
              x2="8.52664"
              y2="23.5172"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#B98309" />
              <stop offset="1" stopColor="#382605" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_4007_13817"
              x1="8.54029"
              y1="19.1182"
              x2="9.65232"
              y2="21.2134"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EBC40C" />
              <stop offset="1" stopColor="#EBC40C" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_4007_13817"
              x1="15.6272"
              y1="19.2151"
              x2="15.9082"
              y2="21.2579"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_4007_13817"
              x1="16.0198"
              y1="22.744"
              x2="19.581"
              y2="16.9707"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3E2A06" />
              <stop offset="1" stopColor="#AD780A" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_4007_13817"
              x1="16.9917"
              y1="16.5262"
              x2="16.8869"
              y2="17.469"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F3CD0C" />
              <stop offset="1" stopColor="#F3CD0C" stopOpacity="0" />
            </linearGradient>
            <radialGradient
              id="paint8_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(17.5845 17.7177) rotate(15) scale(1.68953 1.77375)"
            >
              <stop stopColor="#110800" />
              <stop offset="0.59" stopColor="#A65A00" stopOpacity="0.8" />
              <stop offset="1" stopColor="#FF921E" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint9_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(17.5845 17.3187) rotate(-2.42) scale(1.69641 1.27703)"
            >
              <stop stopColor="#110800" />
              <stop offset="0.59" stopColor="#A65A00" stopOpacity="0.8" />
              <stop offset="1" stopColor="#FF921E" stopOpacity="0" />
            </radialGradient>
            <radialGradient
              id="paint10_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(18.8687 16.1781) rotate(23.5) scale(0.597266 0.275859)"
            >
              <stop stopColor="#7C7C7C" />
              <stop offset="1" stopColor="#7C7C7C" stopOpacity="0.33" />
            </radialGradient>
            <linearGradient
              id="paint11_linear_4007_13817"
              x1="17.2393"
              y1="15.9168"
              x2="17.461"
              y2="15.9074"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7C7C7C" />
              <stop offset="1" stopColor="#7C7C7C" stopOpacity="0.33" />
            </linearGradient>
            <radialGradient
              id="paint12_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(10.4331 5.41858) rotate(-0.6) scale(0.88 1.3475)"
            >
              <stop stopColor="#FEFEFC" />
              <stop offset="0.75" stopColor="#FEFEFC" />
              <stop offset="1" stopColor="#D4D4D4" />
            </radialGradient>
            <linearGradient
              id="paint13_linear_4007_13817"
              x1="10.2437"
              y1="5.00863"
              x2="10.676"
              y2="5.78121"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#757574" stopOpacity="0" />
              <stop offset="0.25" stopColor="#757574" />
              <stop offset="0.5" stopColor="#757574" />
              <stop offset="1" stopColor="#757574" stopOpacity="0" />
            </linearGradient>
            <radialGradient
              id="paint14_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(10.2957 4.75938) rotate(-9.35) scale(0.537109 0.495859)"
            >
              <stop stopColor="#C8C8C8" />
              <stop offset="1" stopColor="#797978" />
            </radialGradient>
            <linearGradient
              id="paint15_linear_4007_13817"
              x1="10.1837"
              y1="3.79413"
              x2="11.1195"
              y2="4.74976"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#646464" stopOpacity="0" />
              <stop offset="0.31" stopColor="#646464" stopOpacity="0.58" />
              <stop offset="0.47" stopColor="#646464" />
              <stop offset="0.73" stopColor="#646464" stopOpacity="0.26" />
              <stop offset="1" stopColor="#646464" stopOpacity="0" />
            </linearGradient>
            <radialGradient
              id="paint16_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(13.1463 5.41835) rotate(-1.8) scale(1.17219 1.3475)"
            >
              <stop stopColor="#FEFEFC" />
              <stop offset="0.75" stopColor="#FEFEFC" />
              <stop offset="1" stopColor="#D4D4D4" />
            </radialGradient>
            <linearGradient
              id="paint17_linear_4007_13817"
              x1="13.1303"
              y1="5.06107"
              x2="13.6278"
              y2="5.6506"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#949494" stopOpacity="0.39" />
              <stop offset="0.5" stopColor="#949494" />
              <stop offset="1" stopColor="#949494" stopOpacity="0.39" />
            </linearGradient>
            <linearGradient
              id="paint18_linear_4007_13817"
              x1="12.7027"
              y1="4.11383"
              x2="14.2857"
              y2="5.04023"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#747474" />
              <stop offset="0.13" stopColor="#8C8C8C" />
              <stop offset="0.25" stopColor="#A4A4A4" />
              <stop offset="0.5" stopColor="#D4D4D4" />
              <stop offset="0.62" stopColor="#D4D4D4" />
              <stop offset="1" stopColor="#7C7C7C" />
            </linearGradient>
            <linearGradient
              id="paint19_linear_4007_13817"
              x1="13.2406"
              y1="3.71234"
              x2="14.2942"
              y2="4.7075"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#646464" stopOpacity="0" />
              <stop offset="0.31" stopColor="#646464" stopOpacity="0.58" />
              <stop offset="0.47" stopColor="#646464" />
              <stop offset="0.73" stopColor="#646464" stopOpacity="0.26" />
              <stop offset="1" stopColor="#646464" stopOpacity="0" />
            </linearGradient>
            <radialGradient
              id="paint20_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(11.3909 6.1667) rotate(-36) scale(0.983125 0.892031)"
            >
              <stop stopColor="#020204" />
              <stop offset="0.73" stopColor="#020204" />
              <stop offset="1" stopColor="#5C5C5C" />
            </radialGradient>
            <radialGradient
              id="paint21_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(12.4335 7.06786) rotate(-22.4) scale(2.3332 1.63883)"
            >
              <stop stopColor="#D2940A" />
              <stop offset="0.75" stopColor="#D89C08" />
              <stop offset="0.87" stopColor="#B67E07" />
              <stop offset="1" stopColor="#946106" />
            </radialGradient>
            <linearGradient
              id="paint22_linear_4007_13817"
              x1="9.71115"
              y1="6.95194"
              x2="13.8946"
              y2="6.91929"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#AD780A" />
              <stop offset="0.12" stopColor="#D89E08" />
              <stop offset="0.25" stopColor="#EDB80B" />
              <stop offset="0.39" stopColor="#EBC80D" />
              <stop offset="0.53" stopColor="#F5D838" />
              <stop offset="0.77" stopColor="#F6D811" />
              <stop offset="1" stopColor="#F5CD31" />
            </linearGradient>
            <radialGradient
              id="paint23_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(10.9159 6.14584) scale(0.113438 0.122031)"
            >
              <stop stopColor="#3A2903" />
              <stop offset="0.55" stopColor="#735208" />
              <stop offset="1" stopColor="#AC8C04" />
            </radialGradient>
            <radialGradient
              id="paint24_radial_4007_13817"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(11.9935 6.13051) scale(0.238906 0.139219)"
            >
              <stop stopColor="#3A2903" />
              <stop offset="0.55" stopColor="#735208" />
              <stop offset="1" stopColor="#AC8C04" />
            </radialGradient>
            <linearGradient
              id="paint25_linear_4007_13817"
              x1="13.8918"
              y1="6.79963"
              x2="13.8918"
              y2="7.109"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F5CE2D" />
              <stop offset="1" stopColor="#D79B08" />
            </linearGradient>
            <clipPath id="clip0_4007_13817">
              <rect
                width="18.5625"
                height="22"
                fill="white"
                transform="translate(3 1)"
              />
            </clipPath>
          </defs>
        </StyledSVG>
      );
      break;
    default:
      svg = <span />;
      break;
  }
  return svg;
}
