import React from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import { Dialog, DialogTitle, DialogContent } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PREFIX = "MoreDetectionCoverageDialog";
const classes = {
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  link: `${PREFIX}-link`,
  dialogFooter: `${PREFIX}-dialogFooter`,
  disclaimerText: `${PREFIX}-disclaimerText`,
  close: `${PREFIX}-close`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.dialogContent}`]: {
    padding: 30,
  },
  [`& .${classes.list}`]: {
    paddingLeft: 20,
    marginBottom: 0,
  },
  [`& .${classes.listItem}`]: {
    paddingBottom: theme.shape.padding,
  },
  [`& .${classes.link}`]: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  [`& .${classes.dialogFooter}`]: {
    padding: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.disclaimerText}`]: {
    fontSize: 10,
    paddingTop: theme.shape.padding,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
  },
}));

export const MoreDetectionCoverageDialog = (props) => {
  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <div className={classes.dialogHeaderContainer}>
        <DialogTitle>Get more detection coverage</DialogTitle>
        <CloseIcon className={classes.close} onClick={props.onClose} />
      </div>
      <DialogContent className={classes.dialogContent}>
        <p>
          Connect Blumira to more of your infrastructure for broader detection
          coverage:
        </p>
        <ul className={classes.list}>
          <li className={classes.listItem}>Popular cloud services</li>
          <li className={classes.listItem}>AWS</li>
          <li className={classes.listItem}>Endpoint products</li>
          <li className={classes.listItem}>Microsoft server</li>
          <li className={classes.listItem}>Microsoft cloud</li>
          <li className={classes.listItem}>Identity providers</li>
          <li className={classes.listItem}>Firewall products</li>
          <li className={classes.listItem}>And many more sources</li>
        </ul>
        <a
          href={"https://www.blumira.com/integrations/"}
          target={"_blank"}
          rel={"noopener noreferrer"}
          className={classes.link}
        >
          Available connectors <OpenInNewIcon />{" "}
        </a>
        <p className={classes.disclaimerText}>
          Note: Many connectors require installation of an on-prem Linux based
          sensor provided for free by Blumira.
        </p>
      </DialogContent>
      {/* <div className={classes.dialogFooter}>
            <p>Get unlimited connectors with Blumira pro</p>
            <Button variant={'contained'} color={'primary'} onClick={() => props.goToBillingView(props.orgId)}>
                Try Pro Plan free for 14 days
            </Button>
        </div> */}
    </StyledDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToBillingView: (orgId) => {
    const payload = {
      orgId,
      toplevel: "settings",
      secondlevel: "billing",
    };

    dispatch({
      type: "PAGE",
      payload,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreDetectionCoverageDialog);
