import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { dataToNum, dataToNumLog } from "./securityHelpers";
import { graphDataSelectors } from "../../../../selectors/securitySelectors";

import "./BluGraphContainer.scss";

/*
Main component
*/

const BluGraphContainer = ({
  defaultTitle,
  rawData,
  description,
  longInt = false,
  logScale = false,
  showTitle = false,
  showSubtitle = true,
  title = "",
  Graph,
  labelField = "vulnName",
  labelField2 = "",
  valueField = "count",
}) => {
  let rawDataNum;

  if (longInt) {
    rawDataNum = dataToNum({ data: rawData, fieldName: "count" });
  } else if (logScale) {
    rawDataNum = dataToNumLog({ data: rawData, fieldName: valueField });
  } else {
    rawDataNum = rawData;
  }

  return (
    <div className="security-graph-container">
      {showTitle && (
        <div className="security-graph-container-header">
          {title || defaultTitle}
        </div>
      )}

      <Graph
        labelField={labelField}
        labelField2={labelField2}
        rawData={rawDataNum}
        valueField={valueField}
      />

      {showSubtitle && (
        <div className="security-graph-container-subtitle">{description}</div>
      )}
    </div>
  );
};

BluGraphContainer.propTypes = {
  defaultTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  Graph: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  labelField: PropTypes.string,
  labelField2: PropTypes.string,
  logScale: PropTypes.bool,
  longInt: PropTypes.bool,
  rawData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
  title: PropTypes.string.isRequired,
  valueField: PropTypes.string,
};

BluGraphContainer.defaultProps = {
  defaultTitle: "",
  labelField: "vulnName",
  labelField2: "",
  logScale: false,
  longInt: false,
  showSubtitle: true,
  showTitle: false,
  valueField: "count",
};

const makeMapStateToProps = () => {
  const mapStateToProps = (state, { reduxName }) =>
    graphDataSelectors[reduxName](state);

  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(BluGraphContainer);
