import React from "react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { dialogClasses } from "./loginViewStyles";

const SignupButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      disabled={props.disabled}
      className={dialogClasses.auth0InspiredFilledButton}
      onClick={() => {
        // set local storage for user creation later
        localStorage.setItem("isSigningUp", "true");

        // the onClick func passed in here sets the user's
        // org state vars to local storage for persistence
        props.setOrgVarsInLocalStorage();

        // open universal login component
        loginWithRedirect({ authorizationParams: { screen_hint: "signup" } });
      }}
    >
      Next
    </Button>
  );
};

export default SignupButton;
