const mspBillingPlanRampSchema = {
  $id: "/mspbillingplanramp",
  title: "MSPBillingPlanRampSchema",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    monthOneMin: { type: "number" },
    monthTwoMin: { type: "number" },
    monthThreeMin: { type: "number" },
    monthFourMin: { type: "number" },
    monthFiveMin: { type: "number" },
    monthSixMin: { type: "number" },
    minCommit: { type: "number" },
    orgId: { type: "string", format: "uuid" },
    templateId: { type: "string", format: "uuid" },
    termStart: { type: "string", format: "date-time" },
    termEnd: { type: "string", format: "date-time" },
  },
  required: [],
  additionalProperties: true,
};

export default mspBillingPlanRampSchema;
