import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "hubspot",
};

export class HubspotAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  async updateBilling(body) {
    const params = ["company"];
    return super.put({ params }, body);
  }

  async getCompany() {
    const params = ["company"];
    return super.get({ params });
  }

  async createTicket() {
    const params = ["tickets"];
    return super.post({ params });
  }
}
