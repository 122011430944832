import React, { useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Logger from "../../lib/logger";

const logger = Logger("Collapse");

const Collapse = ({
  title = "",
  height = "150px",
  collapseId,
  children = [],
}) => {
  const key = `preferences.${collapseId}`;

  let currSettings = true;
  try {
    currSettings = Object.prototype.hasOwnProperty.call(localStorage, key)
      ? localStorage.getItem(key) === "true"
      : true;
  } catch (err) {
    logger.error("Local storage not available", err);
  }
  const [open, toggle] = useState(currSettings);

  const toggleCollapse = () => {
    toggle(!open);
    try {
      localStorage.setItem(key, !open);
    } catch (err) {
      logger.error("Local storage not available", err);
    }
  };

  const extras = {};
  if (!open) {
    extras.padding = "0";
  }

  return (
    <div className="collapse-container">
      <div
        className="collapse-title"
        onClick={toggleCollapse}
        tabIndex={-1}
        role="button"
      >
        {title}
        <i className={open ? "fas fa-angle-down" : "fas fa-angle-right"} />
      </div>
      {children && !_.isEmpty(children) ? (
        <div
          className="collapse-content"
          data-open={open}
          style={{
            height: height || "300px",
            ...extras,
          }}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

Collapse.propTypes = {
  collapseId: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  title: PropTypes.string,
  height: PropTypes.string,
};

Collapse.defaultProps = {
  collapseId: "",
  children: [],
  title: "",
  height: "150px",
};

export default Collapse;
