// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import Moment from "moment-timezone";
import { func, oneOfType, shape, string } from "prop-types";

// ** Components **********************
import { ArrowIcon, CalendarIcon } from "views/Components/Icon";

// ** Styles **************************
import { FlexRowLayout, Format, HeaderInput, IconContainer } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Datepicker header component
//
//  ** Remarks
//  Datepicker header functionality (inputs)
//
//  ** Props
//  @param onFocus {func} - func invoked on input focus
//  @param rangeEnd {obj} - current end range value
//  @param rangeStart {obj} - current start range value
//
const Header = ({
  color,
  onFocus,
  rangeEnd,
  rangeStart,
  renderDate,
  timeFormat,
  timezone,
}) => (
  <FlexRowLayout color={color}>
    <FlexRowLayout>
      <HeaderInput
        color={color}
        onChange={Function.prototype}
        onFocus={() => onFocus("start")}
        placeholder="Start Date"
        readonly="readonly"
        type="text"
        value={
          rangeStart && rangeStart !== ""
            ? renderDate(rangeStart).format("MM/DD/YY | hh:mm:ss a")
            : ""
        }
      />
    </FlexRowLayout>
    <IconContainer margin="-1px 0px 0px 0px">
      <ArrowIcon height="8px" />
    </IconContainer>
    <FlexRowLayout>
      <HeaderInput
        color={color}
        onChange={Function.prototype}
        onFocus={() => onFocus("end")}
        placeholder="End Date"
        readonly="readonly"
        type="text"
        value={
          rangeEnd && rangeEnd !== ""
            ? renderDate(rangeEnd).format("MM/DD/YY | hh:mm:ss a")
            : ""
        }
      />
    </FlexRowLayout>
    <IconContainer margin="-2px 0px 0px 4px">
      {timeFormat === "local" ? (
        <Format color={color}>({Moment.tz(timezone).format("z")})</Format>
      ) : (
        <Format color={color}>(UTC)</Format>
      )}
      <CalendarIcon height="16px" />
    </IconContainer>
  </FlexRowLayout>
);

// ** Proptypes ***********************
Header.propTypes = {
  color: string,
  onFocus: func.isRequired,
  rangeEnd: oneOfType([shape(), string]),
  rangeStart: oneOfType([shape(), string]),
  renderDate: func.isRequired,
  timezone: string,
};

export default Header;
