import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import EndpointHostsGraph from "./EndpointHostsGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "endpointHosts";

export const GRAPH_TITLE = "Top 10 Endpoints by Log Bytes (logarithmic scale)";

/*
Main component
*/

const EndpointHosts = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={EndpointHostsGraph}
    labelField="devname"
    labelField2="deviceAddress"
    logScale
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
    valueField="totalBytes"
  />
);

EndpointHosts.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

EndpointHosts.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default EndpointHosts;
