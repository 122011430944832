// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { func, number, oneOfType, string } from "prop-types";

// ** Components **********************
import { ExIcon } from "views/Components/Icon";

// ** Style ***************************
import {
  SelectedIconContainer,
  SelectedItemStyle,
  SelectedTitle,
} from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Selected component
//
//  ** Remarks
//  Simple selected component extended to simplify code across the app
//
//  ** Props
//  @param onClick {func} - function invoked on click
//  @param title {string} - label
//
export const SelectedItem = ({ maxWidth, onClick, title }) => (
  <SelectedItemStyle onClick={onClick}>
    <SelectedTitle maxWidth={maxWidth}>{title}</SelectedTitle>
    <SelectedIconContainer>
      <ExIcon height="8px" />
    </SelectedIconContainer>
  </SelectedItemStyle>
);

// ** Proptypes ***********************
SelectedItem.propTypes = {
  maxWidth: string,
  onClick: func,
  title: oneOfType([number, string]),
};
