import React from "react";
import { styled } from "@mui/material/styles";
import { PlatformLogo } from "../../Components/BlumiraAgent/PlatformLogos/platforms";

import { Card, Button } from "@mui/material";

const PREFIX = "TimedTrialStepCard";
export const classes = {
  title: `${PREFIX}-title`,
  iconContainer: `${PREFIX}-iconContainer`,
  containedButton: `${PREFIX}-containedButton`,
  textButton: `${PREFIX}-textButton`,
};

export const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.iconContainer}`]: {
    display: "flex",
    justifyContent: "center",
  },
  [`& .${classes.containedButton}`]: {
    width: "50%",
    fontWeight: "bold",
    fontSize: 16,
    padding: "10px 0",
  },
  [`& .${classes.textButton}`]: {
    fontWeight: "bold",
    fontSize: 16,
  },
}));

export const TimedTrialStepCard = (props) => {
  return (
    <StyledCard className={props.className} variant={"outlined"}>
      <h3 className={classes.title}>{props.title}</h3>
      <h4>{props.subTitle}</h4>
      <p>{props.text}</p>
      {props.platformIcons && (
        <div className={classes.iconContainer}>
          <PlatformLogo platformType={"windows"} />
          <PlatformLogo platformType={"mac"} />
          <PlatformLogo platformType={"linux"} />
        </div>
      )}
      {props.additionalText && <p>{props.additionalText}</p>}
      {props.buttonText && (
        <Button
          variant={props.buttonVariant ? props.buttonVariant : "text"}
          className={
            props.buttonVariant === "contained"
              ? classes.containedButton
              : classes.textButton
          }
          onClick={props.onClick}
          datacy={props.datacy ? props.datacy : ""}
        >
          {props.buttonText}
        </Button>
      )}
    </StyledCard>
  );
};
