import { isEmpty, sameTypeModules } from "./moduleHelpers";

export const META_ITEM_TYPE_ENV = 0;
export const META_ITEM_TYPE_FILE = 1;

/*
Reduce to at most 5 consecutive '*'s
*/
const reduceAsterisks = (str) => {
  const r = /\*{6,}/g;
  return str.replace(r, "*****");
};

export const getVersionsMeta = ({ module, availableModules }) => {
  const availableVersions = sameTypeModules({ module, availableModules });

  const metaList = availableVersions.map((version) => ({
    [version.id]: version.meta,
  }));

  return Object.assign({}, ...metaList);
};

export const getMetaItemDisplayName = (item) =>
  item.display_name || item.name || item.label;

const getModuleEnvAndVolumes = (module) => {
  const config = module.config || {};

  const { env = {}, volumes = [] } = config;

  return { env, volumes };
};

/*
Finds the first file in volumes with name=key
*/
const findFile = ({ volumes, key }) => {
  for (let i = volumes.length - 1; i >= 0; i -= 1) {
    const volume = volumes[i];
    const files = volume.files || [];

    for (let j = files.length - 1; j >= 0; j -= 1) {
      if (files[j].name === key) {
        return files[j];
      }
    }
  }

  return null;
};

/*
Gets the value for the meta item from the module's `envObj` or `volumes`,
extracted by key=item.label (not item.name).
type 0 = env; type 1 = file.
*/
const getMetaValue = ({ module, key, type }) => {
  const { env, volumes } = getModuleEnvAndVolumes(module);
  let val = null;

  if (type === META_ITEM_TYPE_ENV) {
    val = env[key];
  } else if (type === META_ITEM_TYPE_FILE) {
    const file = findFile({ volumes, key });
    if (file) {
      val = file.contents;
    }
  }

  if (val) {
    return reduceAsterisks(val);
  }

  return "";
};

/*
Copies the meta `item` adding `value` attribute. If `module` is
falsy, `value` is set to the empty string.
*/
export const getMetaItem = ({ item, sensorModule = null }) => {
  // If `sensorModule` is given, add item's value
  const { label: key, type } = item; // type 0 = env; type 1 = file;

  const value = sensorModule
    ? getMetaValue({ module: sensorModule, key, type })
    : "";

  return {
    ...item,
    value,
  };
};

/*
Returns an object of the form { metaItemName1: metaItem1, ...},
adding 'value' attribute to each item. If 'sensorModule' is falsy,
'value' is set to "".
*/
export const getMetaItems = ({ meta, sensorModule = null }) => {
  const metaItems = meta.reduce((accum, item) => {
    const moduleMetaItem = getMetaItem({ sensorModule, item });

    const { name } = moduleMetaItem;

    accum[name] = moduleMetaItem;

    return accum;
  }, {});

  return metaItems;
};

/*
If `metaPrevious` is provided, returns 'required=true' iff the field
is required and is absent in `metaPrevious` (`metaPrevious` can be
an older version's meta).
If `metaPrevious` is falsy, returns item.required.
*/
export const computeRequired = ({ metaPrevious = null, item }) => {
  const { required } = item;

  if (!metaPrevious) {
    return required;
  }

  let found;

  if (Array.isArray(metaPrevious)) {
    found = metaPrevious.find((elt) => elt.name === item.name);
  }

  const computed = !found && required;

  return computed;
};

/*
Meta field validation.
The `required` argument shows if the field is required or not
and is used in place of item.required
*/
const validateMetaItem = ({ item, value, required }) => {
  const noValue = isEmpty(value);

  /* There is no value */

  if (noValue) {
    return !required;
  }

  /* There is a value */

  // Match regex if there is one
  const { regex } = item;

  if (regex) {
    const re = new RegExp(regex);

    return re.test(value);
  }

  // If no regex - every value is valid
  return true;
};

/*
Meta item validation `validateMetaItem` wrapper that computes 'required',
validates the item.value, then sets form error message string.
*/
export const validateMetaItemSetError = ({
  item,
  value,
  metaPrevious = null,
  setError,
}) => {
  const required = computeRequired({ item, metaPrevious });

  const valid = validateMetaItem({ item, value, required });

  const errorMsg = valid ? "" : `Invalid ${getMetaItemDisplayName(item)}`;

  setError(errorMsg);

  return errorMsg;
};

/*
Validates 'metaData', an object of the form { metaItemName1: metaItem1, ...}.
`metaCurrent` is meta array of the module version being installed.
`metaPrevious` is the meta array of the currently installed module.
If 'metaCurrent' is truthy, validates 'metaData' against 'metaCurrent' (used
for validating on submit).
If 'metaCurrent' is falsy, validates 'metaData' against itself (used for
form onChange validation).
*/
export const validateMetaData = ({
  metaData,
  setError,
  metaCurrent = null,
  metaPrevious = null,
}) => {
  // Validate against metaCurrent if it is present
  const meta = metaCurrent || metaData;

  const errMsg = Object.keys(meta)
    // Validate each item
    .map((key) => {
      // Get the item and its name from 'meta'
      const item = meta[key];
      const { name } = item;

      // Get the value from `metaData`
      const { value } = metaData[name] || {};

      // Validate and return the error message
      return validateMetaItemSetError({
        item,
        metaPrevious,
        setError,
        value,
      });
    })
    // Combine the error messages
    .filter((x) => x)
    .join(". ");

  // Set and return the the error message
  setError(errMsg);

  return errMsg;
};

/*
Returns a copy of `metaValues`
If `sensorModule` is truthy, it deletes any unchanged items.
If `sensorModule` is falsy, it deletes any empty items.
NOTE: This function used to delete empty items for truthy as well
      however, this prevented you from being able to remove  meta
      that had previously been added and is no longer wanted/needed
      (The regular expression field for example)
*/
export const getCleanMeta = ({ metaValues, sensorModule = null }) => {
  const cleanData = Object.keys(metaValues).reduce((accum, key) => {
    // Get the current value
    const item = metaValues[key] || {};
    const { value: currentValue = "" } = item;
    let insert = false;

    // If there is sensorModule, get the initial value
    if (sensorModule) {
      const { value: initialValue = "" } = getMetaItem({ sensorModule, item });

      insert = currentValue !== initialValue;
    } else {
      insert = !isEmpty(currentValue);
    }

    // Insert is value passed the test
    if (insert) {
      accum[key] = item;
    }

    return accum;
  }, {});

  return cleanData;
};
