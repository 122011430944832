import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { auth } from "../../lib/auth";
import Logger from "../../lib/logger";
import Alert from "../Components/Alert";
import TosView from "../Pages/TosView";

import { Me } from "../../lib/models/Me";

const logger = Logger("InitialTosView");

class InitialTosView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      errorMessage: null,
    };

    this.accept = this.accept.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentWillUnmount() {
    this.dismissError();
  }

  dismissError = () => {
    this.setState({ error: false });
  };

  updateUser = async (orgId, acceptedTos) => {
    const personId = auth.person_id;

    try {
      await this.props.dispatchUpdateUser({
        orgId,
        personId,
        data: { accepted_tos: acceptedTos },
      });
    } catch (err) {
      logger.error(err);

      this.setState({ error: true });
    }
  };

  /*
  User accepted ToS
  */
  accept = (orgId) => {
    const u = new Me();
    u.read()
      .then(() => {
        u.set({ acceptedTos: moment.utc().toISOString() });
        u.update()
          .then(() => {
            try {
              this.props.dispatchRedirectToDefault({ orgId: this.props.orgId });
              window.location.reload();
            } catch (err) {
              this.setState({
                error: true,
                errorMessage: "An error occurred. Please, refresh the page.",
              });
            }
          })
          .catch(() => {
            this.setState({ error: true });
          });
      })
      .catch(() => {
        this.setState({ error: true });
      });
  };

  /*
  User declined ToS
  */
  cancel = () => {
    this.props.dispatchRedirectToLogin();
  };

  render() {
    const viewProps = {
      initial: true,
      accept: this.accept,
      cancel: this.cancel,
      orgId: this.props.orgId,
      acceptedTos: this.props.acceptedTos,
    };

    return (
      <div className="initial-tos-view">
        {this.state.error && (
          <Alert
            color="danger"
            message={
              this.state.errorMessage ||
              "Unable to update user record at this time"
            }
            handleAction={this.dismissError}
          />
        )}

        <TosView {...viewProps} />
      </div>
    );
  }
}

InitialTosView.propTypes = {
  personId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  acceptedTos: PropTypes.string,
  prevLocationPayload: PropTypes.shape({}),
  prevLocationType: PropTypes.string,
  dispatchRedirectToLogin: PropTypes.func.isRequired,
  dispatchRedirectToDefault: PropTypes.func.isRequired,
  dispatchUpdateUser: PropTypes.func.isRequired,
};

InitialTosView.defaultProps = {
  acceptedTos: null,
  prevLocationPayload: null,
  prevLocationType: null,
};

export default InitialTosView;
