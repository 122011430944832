import Logger from "./logger";

const logger = Logger("auth");

export const AUTH_STATUS = {
  LOGGED_IN: "LOGGED_IN",
  NOT_LOGGED_IN: "NOT_LOGGED_IN",
};

class Auth {
  constructor() {
    this.initFromStorage();
  }

  /*
  Attempts to load auth data from localStorage into properties on this object.
  */
  initFromStorage = () => {
    try {
      this.token = localStorage.getItem("idToken");
      const idTokenPayloadString = localStorage.getItem("idTokenPayload");
      if (!this.token || !idTokenPayloadString) {
        this.token = null;
        this.person_id = null;
        return;
      }
    } catch (err) {
      logger.error("Failed to read auth data from Local Storage.", err);
      this.token = null;
      this.person_id = null;
    }
  };

  /*
  Saves Auth0 response data to auth attributes and localStorage, if data attributes
  are present; clears local auth state if data is missing either idToken or idTokenPayload.
  */
  _saveLocalAuthData = (data = {}) => {
    const { idToken, idTokenPayload } = data;
    if (!idToken || !idTokenPayload) {
      this._clearLocalAuthData();
      return;
    }
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("idTokenPayload", JSON.stringify(idTokenPayload));
    this.initFromStorage();
  };

  _clearLocalAuthData = () => {
    this.token = null;
    this.person_id = null;
    try {
      localStorage.removeItem("idToken");
      localStorage.removeItem("idTokenPayload");
      localStorage.removeItem("loginExpiresAt"); // cleanup from legacy code
      sessionStorage.removeItem("post_auth_restore_url");
    } catch (err) {
      logger.error("Failed to clear local storage.", err);
    }
  };

  /*
  Sets present instance's attributes, and persists Auth0 data to Local Storage
  */
  login = (authResult) => {
    this._saveLocalAuthData(authResult);
    if (sessionStorage.getItem("post_auth_restore_url")) {
      const gotoUrl = sessionStorage.getItem("post_auth_restore_url");
      sessionStorage.removeItem("post_auth_restore_url");
      window.location.assign(gotoUrl);
    }
  };

  /*
  Clears auth data from local storage.
  */
  logout = () => {
    this._clearLocalAuthData();
  };

  /*
  After receiving an auth-related API error, logout/clear local auth data storage, persist
  the current URL for later restoration, and redirect to the login page.
   */
  handleAuthErrorApiResponse = (err) => {
    logger.info("Logout & redirect due to API error response:", err);
    sessionStorage.setItem("post_auth_restore_url", window.location.href);
    localStorage.clear();
    window.location.assign(
      window.location.protocol + "//" + window.location.host
    );
    window.location.reload();
  };

  currentStatus = () => {
    if (
      localStorage.getItem("idToken") &&
      localStorage.getItem("idTokenPayload")
    ) {
      return AUTH_STATUS.LOGGED_IN;
    } else {
      return AUTH_STATUS.NOT_LOGGED_IN;
    }
  };
}

export const auth = new Auth();
