import Ajv from "ajv";

import dataSourceSchema from "./DataSource";
import { reportSchema, reportRequestSchema } from "./Report";
import subnetSchema from "./Subnet";
import labelSchema from "./Label";
import blocklistEntrySchema from "./BlocklistEntry";
import blocklistSchema from "./Blocklist";
import locationSchema from "./Location";
import { searchFilterSchema } from "./DataQuery";
import querySchema from "./Query";
import columnSchema from "./Column";
import categorySchema from "./Category";
import { logtypeSchema } from "./Logtype";
import queryResultSchema from "./QueryResult";
import orgLogTypeSchema from "./OrgLogType";
import ruleSchema, { conditionSetEntrySchema } from "./Rule";
import allowlistEntrySchema from "./AllowlistEntry";
import conditionSchema from "./Condition";
import conditionOverrideSchema from "./ConditionOverride";
import findingSchema from "./Finding";
import orgSchema from "./Org";
import userSchema from "./User";
import meSchema from "./Me";
import constantsSchema from "./Constants";
import messageSchema from "./Message";
import settingsSchema from "./Settings";
import BQSchema from "./BQ";
import BQSummarySchema from "./BQSummary";
import workflowStepSchema from "./WorkflowStep";
import actionSchema from "./Action";
import findingTypeSchema from "./FindingType";
import matchSchema from "./Match";
import transmissionSchema from "./Transmission";
import ssoConnectionSchema from "./SSOConnection";
import { resultParamsSchema } from "./Request";
import { integrationSchema } from "./Integration";
import { integrationSchemaSchema } from "./IntegrationSchema";
import { orgColumnStatsSchema } from "./OrgColumnStats";
import signUpSchema from "./SignUp";
import lcAgentSchema from "./LCAgent";
import lcGroupSchema from "./LCGroup";
import findingExportSchema from "./FindingExport";
import mspBillingPlanTemplate from "./MSPBillingPlanTemplate";
import mspBillingPlanSchema from "./MSPBillingPlan";
import usageReportSchema from "./UsageReport";
import mspBillingPlanRampSchema from "./MSPBillingPlanRamp";
import subaccountBillingHistorySchema from "./SubaccountBillingHistory";
import psaIntegrationSchema from "./PSAIntegration";
import psaCompanyMappingPSASchema from "./PSACompanyMappingPSA";
import psaPriorityMappingPSASchema from "./PSAPriorityMappingPSA";
import psaStatusMappingPSASchema from "./PSAStatusMappingPSA";
import psaTypeMappingPSASchema from "./PSATypeMappingPSA";

import testPSAIntegrationSchema from "./PSAIntegrationTest";
import psaSimulateFindingSchema from "./PSASimulateFinding";

const Schema = new Ajv({
  schemas: [
    blocklistEntrySchema,
    blocklistSchema,
    categorySchema,
    columnSchema,
    dataSourceSchema,
    //dataQuerySchema,
    querySchema,
    labelSchema,
    locationSchema,
    logtypeSchema,
    reportSchema,
    reportRequestSchema,
    searchFilterSchema,
    subnetSchema,
    queryResultSchema,
    orgLogTypeSchema,
    ruleSchema,
    allowlistEntrySchema,
    conditionSetEntrySchema,
    conditionSchema,
    conditionOverrideSchema,
    findingSchema,
    orgSchema,
    userSchema,
    meSchema,
    constantsSchema,
    messageSchema,
    resultParamsSchema,
    settingsSchema,
    BQSchema,
    BQSummarySchema,
    integrationSchema,
    integrationSchemaSchema,
    orgColumnStatsSchema,
    signUpSchema,
    workflowStepSchema,
    actionSchema,
    findingTypeSchema,
    matchSchema,
    lcAgentSchema,
    lcGroupSchema,
    transmissionSchema,
    ssoConnectionSchema,
    findingExportSchema,
    mspBillingPlanTemplate,
    mspBillingPlanSchema,
    usageReportSchema,
    mspBillingPlanRampSchema,
    subaccountBillingHistorySchema,
    psaIntegrationSchema,
    testPSAIntegrationSchema,
    psaCompanyMappingPSASchema,
    psaPriorityMappingPSASchema,
    psaStatusMappingPSASchema,
    psaTypeMappingPSASchema,
    psaSimulateFindingSchema,
  ],
  nullable: true,
  allErrors: true,
});

Schema.addFormat("bytes", {
  type: "array",
  validate: (byteArray) =>
    !byteArray.find((x) => x >= 0 && x <= 255 && x % 1 === 0),
});

export default Schema;
