// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { connect } from "react-redux";
import Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { find, omit } from "lodash";
import uuid from "uuid";
import { redirect } from "redux-first-router";
import Moment from "moment-timezone";
import { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";

// ** Actions *************************
import * as QueryActions from "redux/actions/QueryActions";
import * as SearchActions from "redux/actions/Search";

// ** Components **********************
import {
  LightPrimaryButton,
  PrimaryButton,
  SecondaryButton,
} from "views/Components/Button";
import Drawer from "@mui/material/Drawer";
import Dropdown from "views/Components/Dropdown";
import Error from "views/Components/Error";
import AlertMessage from "views/Components/AlertMessage";
import { DefaultIcon } from "views/Components/Icon";
import { Body, Header } from "views/Components/Typography";

// ** Constants ***********************
import { OPERATORS } from "utils/constants/terinaryOperators";
import VERBIAGE from "utils/constants/verbiage";

// ** Features ************************
import ContainerWrapper from "views/Features/ContainerWrapper";
import SaveSearchModal from "views/Features/Modals/SaveSearch";
import SearchResultsForm from "views/Features/Forms/SearchForm";
import ResultsColSelection from "views/Features/ResultsColSelection";
import ResultsTable from "views/Features/ResultsTable";
// ** Layouts *************************
import { SearchResultsContainer } from "views/Layouts/Container";

// ** Lib *****************************
import { decodeObj, encodeObj } from "utils/lib/paramEncoding";
import { addAggregateAndConfig, addSearch } from "views/Pages/Query/QueryUtils";

// ** Style ***************************
import {
  DownloadText,
  FlexEnd,
  IconContainer,
  Option,
  OptionsContainer,
  OptionsHeader,
  Overflow,
  SearchHeader,
  Section,
  SectionHeader,
  SpaceBetween,
} from "./style";

// *****************************************************************************
// Container
// *****************************************************************************
//
//  Search results container
//
//  ** Remarks
//  Query functionality (TODO)
//
//  ** Props
//  TODO
//
class SearchResults extends Component {
  constructor(props) {
    super(props);

    // Define a hard ordered list of columns, if present
    // https://blumira.atlassian.net/browse/BFE-185
    this.magicOrder = ["Timestamp", "count", "grouped_count"];

    this.state = {
      activeColumns: props.activeColumns,
      data: decodeObj(props.location.query && props.location.query.data),
      alertMessage: {
        open: false,
        message: "",
      },
    };
  }
  async componentDidMount() {
    const {
      dispatch,
      handleFetchColumns,
      handleFetchLogTypes,
      handleFetchQueries,
      handleFetchQueryOptions,
      location,
      orgId,
      toggleFlag,
    } = this.props;

    // ** Decode url data *************
    let data = decodeObj(location.query && location.query.data);

    // ** Queries *********************
    await handleFetchQueries({ orgId, dataSource: "finding" });
    await handleFetchQueries({ orgId, dataSource: "bq" });
    toggleFlag("queriesLoaded");

    // ** Logtypes ********************
    if (data.selectedLogTypes) {
      await handleFetchLogTypes(data.category.value, orgId);
    }

    // ** Columns *********************
    await handleFetchColumns();

    if (data) {
      // ** Format data and add to local state for use in form
      await this.formatData(data);
      toggleFlag("formDataLoaded");

      // ** Determine search source
      if (data.source && data.source === "advanced") {
        toggleFlag("formOpen");
        delete data.source;
      }

      // ** Query options ***************
      handleFetchQueryOptions({
        orgId,
        dataSource: data.dataSource,
        run: false,
      });

      // ** Run query *******************
      data = omit(data, [
        "category",
        "name",
        "reportName",
        "selectedLogTypes",
        "timeFormat",
      ]);
      await dispatch(QueryActions.runQueryAction(data));
      toggleFlag("dataLoaded");
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      dispatch,
      handleFetchQueries,
      handleFetchQueryOptions,
      location,
      orgId,
      resultFileUrl,
      toggleFlag,
    } = this.props;

    if (prevProps.orgId !== orgId) {
      toggleFlag("dataLoaded");
      // ** Decode url data *************
      let data = decodeObj(location.query && location.query.data);

      // ** Queries *********************
      await handleFetchQueries({ orgId, dataSource: "finding" });
      await handleFetchQueries({ orgId, dataSource: "bq" });
      toggleFlag("queriesLoaded");

      if (data) {
        // ** Format data and add to local state for use in form
        await this.formatData(data);

        // ** Query options ***************
        handleFetchQueryOptions({
          orgId,
          dataSource: data.dataSource,
          run: false,
        });

        // ** Run query *******************
        data = omit(data, ["name", "reportName", "timeFormat"]);
        data.orgId = orgId;
        await dispatch(QueryActions.runQueryAction(data));
      }
      toggleFlag("dataLoaded");
    }

    if (resultFileUrl && prevProps.resultFileUrl !== resultFileUrl) {
      window.open(resultFileUrl, "_blank");
    }

    if (
      prevProps.location.query &&
      prevProps.location.query.data !== location.query.data
    ) {
      toggleFlag("dataLoaded");
      let data = decodeObj(location.query && location.query.data);

      // ** Format data and add to local state for use in form
      await this.formatData(data);

      // ** Run query *******************
      data = omit(data, ["name", "reportName", "timeFormat"]);
      await dispatch(QueryActions.runQueryAction(data));
      toggleFlag("dataLoaded");
    }
  }

  // ** XXX TODO ************************
  // This is a total hack for fixing user input in the text box
  // Swapping out the textbox element for a multiselect user input
  // would take a long time.  We will fix this in the not too distiance future
  // with a redesign of the form (with form validation built in).
  fixFilterInput(filter) {
    var retVal = [];
    filter.fieldValue
      // eslint-disable-next-line no-useless-escape
      .replace(/(\]|\[|\")/g, "")
      .split(",")
      .map((i) => {
        const item = i.trim();
        if (isNaN(item)) {
          retVal.push(`"${item}"`);
        } else {
          retVal.push(`${item}`);
        }
      });
    filter.fieldValue = `[${retVal.toString()}]`;
    return filter;
  }

  async onSubmit(data) {
    const { columns, dataSource, dispatch, onChange, orgId, toggleFlag } =
      this.props;
    const queryObj = {
      dataSource,
      orgId,
    };

    const filters = data.queryFilters.map((filter) => {
      if (filter.opcode.value === "in") {
        filter = this.fixFilterInput(filter);
      }
      omit(filter, ["id"]);

      return {
        children: null,
        operator: "and",
        fieldName: filter.fieldName.value
          ? filter.fieldName.value
          : filter.fieldName,
        opcode: filter.opcode.value ? filter.opcode.value : filter.opcode,
        fieldValue:
          filter.fieldValue.value !== undefined
            ? filter.fieldValue.value
            : filter.fieldValue,
        fieldType: filter.fieldName.value
          ? find(columns, ["name", filter.fieldName.value]).data_type
          : filter.fieldType,
      };
    });

    // Configure search filters
    addSearch({
      filters,
      hasAdvancedFilters: false,
      query: queryObj,
      selectedQueryId: null,
      selectedSavedQuery: false,
    });

    // Data config
    addAggregateAndConfig({
      aggregators: [],
      dataSource,
      endTime: data.rangeEnd,
      hasNonCountAggregators: false,
      isAggregateData: data.distinctCounts,
      query: queryObj,
      selectedColumns: data.selectedColumns,
      selectedQueryId: null,
      selectedSavedQuery: false,
      startTime: data.rangeStart,
    });

    // Add report name and save is present
    if (data.reportName) {
      queryObj.name = data.reportName;
      await dispatch(QueryActions.saveDataQuery(queryObj));
    }

    // Add timeformat and encode data for routing
    queryObj.timeFormat = data.timeFormat;
    const encodedData = encodeObj(queryObj);

    dispatch(
      redirect({
        type: "PAGE",
        payload: {
          orgId,
          toplevel: "reporting",
          secondlevel: "results",
        },
        query: {
          data: encodedData,
        },
      })
    );

    onChange("dataChanged", false);
    toggleFlag("formOpen");
  }

  async saveSearch(name) {
    const {
      columns,
      dataSource,
      dispatch,
      distinctCounts,
      orgId,
      queryFilters,
      rangeEnd,
      rangeStart,
      selectedColumns,
      handleFetchQueries,
    } = this.props;
    const queryObj = {
      dataSource,
      orgId,
    };

    const filters = queryFilters.map((filter) => {
      omit(filter, ["id"]);

      return {
        children: null,
        operator: "and",
        fieldName: filter.fieldName.value
          ? filter.fieldName.value
          : filter.fieldName,
        opcode: filter.opcode.value ? filter.opcode.value : filter.opcode,
        fieldValue:
          filter.fieldValue.value !== undefined
            ? filter.fieldValue.value
            : filter.fieldValue,
        fieldType: filter.fieldName.value
          ? find(columns, ["name", filter.fieldName.value]).data_type
          : filter.fieldType,
      };
    });

    // Configure search filters
    addSearch({
      filters,
      hasAdvancedFilters: false,
      query: queryObj,
      selectedQueryId: null,
      selectedSavedQuery: false,
    });

    // Data config
    addAggregateAndConfig({
      aggregators: [],
      dataSource,
      endTime: rangeEnd,
      hasNonCountAggregators: false,
      isAggregateData: distinctCounts,
      query: queryObj,
      selectedColumns,
      selectedQueryId: null,
      selectedSavedQuery: false,
      startTime: rangeStart,
    });

    // Add report name and save is present
    queryObj.name = name;
    await dispatch(QueryActions.saveDataQuery(queryObj));
    handleFetchQueries({ orgId, dataSource: dataSource });
  }

  async downloadData(type) {
    const { dispatch, location, toggleFlag } = this.props;
    let data = decodeObj(location.query && location.query.data);

    toggleFlag("downloading");

    data.outputType = type;
    data = omit(data, [
      "reportName",
      "timeFormat",
      "category",
      "selectedLogTypes",
    ]);

    await dispatch(QueryActions.runQueryAction(data));

    toggleFlag("downloading");
  }

  formatData(data) {
    const { columns, onChange, searchColumns } = this.props;
    const allCols = data.selectedLogTypes
      ? searchColumns.columns
      : columns.filter((col) => col.dataSource === data.dataSource);

    // Add distinct counts to state
    const distinct = data.aggregate.indexOf("count(*)") > -1;
    onChange("distinctCounts", distinct);

    // Add logtypes to state
    if (data.selectedLogTypes) {
      onChange(
        "selectedLogTypes",
        searchColumns.logTypes.filter((log) =>
          find(data.selectedLogTypes, ["value", log.id])
        )
      );
    }

    // Add timeformat to state
    onChange("timeFormat", data.timeFormat || "utc");

    // Add selected columns to state
    let cols = data.aggregate.map((col) => find(allCols, ["name", col]));
    cols = cols.filter((col) => col !== undefined);
    onChange("selectedColumns", cols);

    // Add query filters to state
    const filters =
      data.search && data.search.length > 0
        ? data.search.map((filter) => {
            const column = find(allCols, ["name", filter.fieldName]);

            return {
              ...filter,
              fieldName: {
                label:
                  column && column.display_name
                    ? column.display_name
                    : column
                    ? column.name
                    : "",
                value: column && column.name,
              },
              opcode: {
                label: find(OPERATORS, (op) => op.name === filter.opcode)
                  .display_name,
                value: filter.opcode,
              },
              id: uuid.v4(),
            };
          })
        : [];
    onChange("queryFilters", filters);

    // Add range start/end to state
    onChange("rangeEnd", data.configuration.timeEnd);
    onChange("rangeStart", data.configuration.timeStart);
  }

  handleError() {
    const {
      constantsError,
      dataSource,
      fetchError,
      fieldsError,
      runError,
      saveError,
    } = this.props;

    const messages = [];

    if (fetchError || fieldsError || runError || saveError) {
      if (dataSource === "finding" && constantsError) {
        messages.push(constantsError.message || "");
      }

      if (fetchError) {
        messages.push(fetchError.message || "");
      }

      if (fieldsError) {
        messages.push(fieldsError.message || "");
      }

      if (runError) {
        messages.push(runError.message || "");
      }

      if (saveError) {
        messages.push(saveError.message || "");
      }
    }

    return messages;
  }

  async refreshData() {
    const { dispatch, location, toggleFlag } = this.props;

    toggleFlag("refreshData");

    // ** Decode url data *************
    let data = decodeObj(location.query && location.query.data);

    // ** Run query *******************
    data = omit(data, ["name", "reportName", "timeFormat"]);
    await dispatch(QueryActions.runQueryAction(data));

    toggleFlag("refreshData");
    toggleFlag("formOpen");
  }

  routeToFinding(id) {
    const { dispatch, orgId } = this.props;

    dispatch({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: id,
      },
    });
  }

  updateActiveCols(cols) {
    const addingCol = cols.find(
      (col) => this.state.activeColumns.indexOf(col) == -1
    );
    const removingCol = this.state.activeColumns.find(
      (col) => cols.indexOf(col) == -1
    );

    if (addingCol) {
      const colName = this.props.selectedColumns.find(
        (c) => c.name == addingCol
      );
      this.setState({
        activeColumns: cols.sort(
          (a, b) => this.magicOrder.indexOf(b) - this.magicOrder.indexOf(a)
        ),
        alertMessage: {
          message: `Added column ${colName.display_name || addingCol}`,
          open: true,
        },
      });
    } else if (removingCol) {
      const colName = this.props.selectedColumns.find(
        (c) => c.name == removingCol
      );
      this.setState({
        activeColumns: cols.sort(
          (a, b) => this.magicOrder.indexOf(b) - this.magicOrder.indexOf(a)
        ),
        alertMessage: {
          message: `Removed column ${colName.display_name || removingCol}`,
          open: true,
        },
      });
    }
  }

  renderHeader() {
    const { location } = this.props;
    let header = "";

    // ** Decode url data *************
    const data = decodeObj(location.query && location.query.data);

    if (data && data.dataSource === "finding") {
      header += "Finding Results for ";
    } else {
      header += "Log Event Results for ";
    }

    if (data && data.reportName) {
      header += data.reportName;
    } else {
      header += `Data Search (${Moment().format("MM/DD/YYYY")})`;
    }

    return header;
  }

  render() {
    const {
      columns,
      downloading,
      formOpen,
      location,
      maxResultsExceeded,
      onToggleModal,
      refreshData,
      selectedColumns,
      tableData,
      toggleFlag,
    } = this.props;
    const { activeColumns, alertMessage } = this.state;
    const selectedColumnNames = selectedColumns.map((c) => c.name);
    const filteredActiveColumns = activeColumns.filter(
      (colName) => selectedColumnNames.indexOf(colName) >= 0
    );
    const data = decodeObj(location.query && location.query.data);

    return (
      <DndProvider backend={Backend}>
        <SearchResultsContainer {...this.props}>
          <Drawer
            closeable
            open={formOpen}
            onClose={() => toggleFlag("formOpen")}
          >
            <Overflow>
              <SearchHeader>Search Criteria</SearchHeader>
              <Section>
                <Body margin="0px 0px 40px">
                  {VERBIAGE.SearchWorkflow.Results.a}
                </Body>
              </Section>
              <Section>
                <SectionHeader>Filters</SectionHeader>
                <SearchResultsForm
                  datum={data}
                  onSubmit={this.onSubmit.bind(this)}
                  onRefresh={this.refreshData.bind(this)}
                  refreshData={refreshData}
                  {...this.props}
                />
              </Section>
              <Section>
                <SectionHeader>Data</SectionHeader>
                <ResultsColSelection
                  selectedColumns={selectedColumns}
                  activeColumns={filteredActiveColumns}
                  updateActiveCols={this.updateActiveCols.bind(this)}
                />
              </Section>
            </Overflow>
          </Drawer>
          <AlertMessage
            message={alertMessage.message}
            open={alertMessage.open}
          />
          <Error margin="0px 0px 16px 0px">{this.handleError()}</Error>
          <Header margin="0px 0px 24px">{this.renderHeader()}</Header>
          <Body margin="0px 0px 16px 0px">
            {VERBIAGE.SearchWorkflow.Results.b}
          </Body>
          <SpaceBetween>
            <LightPrimaryButton onClick={() => toggleFlag("formOpen")}>
              <IconContainer open={formOpen}>
                <DefaultIcon height="6px" icon="caret" />
              </IconContainer>
              {formOpen ? "Collapse Search Criteria" : "Edit Search Criteria"}
            </LightPrimaryButton>
            <FlexEnd>
              {downloading ? (
                <DownloadText>Preparing file for download...</DownloadText>
              ) : (
                <span />
              )}
              <Dropdown
                btnContents={
                  <SecondaryButton
                    disabled={tableData.length === 0 || downloading}
                  >
                    Download
                  </SecondaryButton>
                }
                disabled={tableData.length === 0 || downloading}
                right="0px"
              >
                {({ toggleDropdown }) => (
                  <OptionsContainer>
                    <OptionsHeader>Download results as...</OptionsHeader>
                    <Option
                      onClick={() => {
                        this.downloadData("csv");
                        toggleDropdown();
                      }}
                    >
                      CSV
                    </Option>
                    <Option
                      onClick={() => {
                        this.downloadData("jsonl");
                        toggleDropdown();
                      }}
                    >
                      JSON
                    </Option>
                  </OptionsContainer>
                )}
              </Dropdown>
              <PrimaryButton
                margin="0px 0px 0px 8px"
                onClick={() =>
                  onToggleModal(
                    <SaveSearchModal
                      {...this.props}
                      data={data}
                      saveSearch={this.saveSearch.bind(this)}
                    />
                  )
                }
              >
                {data && data.selectedQueryId ? "Update Search" : "Save Search"}
              </PrimaryButton>
            </FlexEnd>
          </SpaceBetween>
          <ResultsTable
            columns={columns}
            maxResultsExceeded={maxResultsExceeded}
            activeCols={filteredActiveColumns}
            selectedColumns={selectedColumns}
            updateActiveCols={this.updateActiveCols.bind(this)}
            {...this.props}
          />
        </SearchResultsContainer>
      </DndProvider>
    );
  }
}

// ** Container helper injection ******
const WrappedContainer = ContainerWrapper(SearchResults);

// ** Proptypes ***********************
SearchResults.propTypes = {
  columns: oneOfType([arrayOf(shape()), shape()]),
  dispatch: func.isRequired,
  downloading: bool,
  handleFetchColumns: func.isRequired,
  handleFetchQueries: func.isRequired,
  handleFetchQueryOptions: func.isRequired,
  location: shape().isRequired,
  onChange: func.isRequired,
  onToggleModal: func.isRequired,
  orgId: string.isRequired,
  maxResultsExceeded: bool,
  refreshData: bool,
  resultFileUrl: string,
  savedQueries: arrayOf(shape()),
  tableData: arrayOf(shape()),
  toggleFlag: func.isRequired,
  activeColumns: arrayOf(string),
};

// ** Default props *******************
SearchResults.defaultProps = {
  columns: [],
  downloading: false,
  refreshData: false,
  resultFileUrl: "",
  savedQueries: [],
  tableData: [],
  selectedColumns: [],
  activeColumns: ["Timestamp", "count", "grouped_count"],
};

// ** State constants *****************
const mapStateToProps = (state) => {
  const dataSrc =
    state.location.query && state.location.query.data
      ? decodeObj(state.location.query.data).dataSource
      : "finding";

  return {
    columns: state.allColumns,
    columnOptions: state.query[dataSrc].columnOptions,
    constantsError: state.findings.get("constantsError", {}),
    dataSource: dataSrc,
    fetchError: state.query[dataSrc].fetchQueryError,
    fieldsError: state.query[dataSrc].fieldsError,
    findingConstants: state.findings.get("constants", {}),
    location: state.location,
    maxResultsExceeded: state.query[dataSrc].maxResultsExceeded,
    orgId: state.location.payload.orgId,
    resultFileUrl: state.query[dataSrc].resultFileUrl,
    runError: state.query[dataSrc].runQueryError,
    saveError: state.query[dataSrc].saveQueryError,
    savedQueries: state.query[dataSrc].savedQueries,
    searchColumns: state.searchColumns,
    tableData: [...(state.query[dataSrc].queryData || [])],
    user: state.session.settings.user,
  };
};

const mapDispatchToProps = {
  handleFetchColumns: SearchActions.fetchAllColumns,
  handleFetchQueries: QueryActions.fetchDataQueries,
  handleFetchLogTypes: SearchActions.fetchSearchColumnsAndTypes,
  handleFetchQueryOptions: QueryActions.fetchQueryOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedContainer);
