// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Key button container ************
export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  opacity: 0;
  position: absolute;
  right: calc(100% + 8px);
  top: 0;
  transition: 300ms ease;
  width: auto;
`;

// ** Blob data item ******************
export const Data = styled.div`
  align-items: center;
  color: ${COLOR.gray800};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: ${(p) => (p.expanded ? "16px" : "8px")};
  margin-right: 8px;
  position: relative;

  &:last-child: {
    margin-right: 0px;
  }

  &:hover ${Actions} {
    opacity: 1;
  }
`;

// ** Empty state container ***********
export const EmptyState = styled.div`
    align-items: center;
    color: ${COLOR.gray800};
    display: flex;
    flex-direction; column;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    padding-top: 104px;
    width: 100%;
`;

// ** Expandable row cell *************
export const ExpandableRowCell = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 3;
  flex-direction: ${(p) => (p.expanded ? "column" : "row")};
  flex-wrap: wrap;
  font-size: 12px;
  padding: 0px 8px;
  transition: all 250ms;
`;

// ** Actions button wrapper **********
export const GhostButton = styled.button`
    background-color: transparent;
    border: none;
    fill: ${COLOR.gray200};
    outline: none !important;
    transition 300ms ease;

    &:hover {
      fill: ${COLOR.gray600};
    }
`;

// ** blob data key highlight *********
export const Key = styled.span`
  background-color: rgba(105, 180, 255, 0.2);
  border-radius: 2px;
  color: ${COLOR.blumiraBlue700};
  font-size: 12px;
  font-weight: 400;
  margin-right: 2px;
  padding: 1px 4px;
`;

// ** Header container ****************
export const TableHeader = styled.div`
  border-bottom: 2px solid ${COLOR.gray100};
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 4px 0px 4px 24px;
`;

// ** Sortable header cell ************
export const SortableHeaderCell = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  color: ${COLOR.gray900};
  cursor: pointer;
  display: flex;
  fill: ${COLOR.gray600};
  flex: 1;
  flex-direction: row;
  font-size: 12px;
  font-weight: 500;
  outline: none !important;
  padding: 0px;
  text-transform: uppercase;
  transition: all 200ms;

  &:hover {
    color: ${COLOR.gray1000};
    fill: ${COLOR.gray1000};
  }
`;

// ** Header cell *********************
export const TableHeaderCell = styled.div`
  color: ${COLOR.gray900};
  flex: ${(p) => (p.flex ? p.flex : 1)};
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;

  &:last-child {
    padding: 0px 8px;
  }
`;

// ** Table row ***********************
export const TableRow = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: ${(p) => (p.border ? `1px solid ${COLOR.gray100}` : "none")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  outline: none !important;
  padding: 16px 0px 8px;
  transition: all 200ms;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;

// ** Table row cell ******************
export const TableRowCell = styled(TableHeaderCell)`
  color: ${COLOR.gray800};
  flex-shrink: 0;
  font-weight: 400;
  text-transform: none;
  text-align: left;
`;

// ** Table row icon ******************
export const TableRowIcon = styled.div`
  fill: ${COLOR.gray800};
  padding: 6px 8px;
  transform: ${(p) => (p.expanded ? "rotate(0deg)" : "rotate(-90deg)")};
  transition: transform 200ms;
`;

// ** Table row indicator *************
export const TableRowIndicator = styled.div`
  height: auto;
  width: 24px;
`;

// ** Outer container *****************
export const AlertContainer = styled.div`
  padding-top: 16px;
  width: 100%;
`;
