import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import EndpointEventsGraph from "./EndpointEventsGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "endpointEvents";
export const GRAPH_TITLE = "Top 10 Endpoint Events by Severity";

/*
Main component
*/

const EndpointEvents = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={EndpointEventsGraph}
    labelField="endpoint"
    labelField2="severityName"
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

EndpointEvents.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

EndpointEvents.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default EndpointEvents;
