import { combineReducers } from "redux";

import { insertData } from "./utils/helpers";
import {
  RESET_SECURITY,
  SECURITY_RECEIVE_ATTACK_TYPES,
  SECURITY_RECEIVE_BLOCKED_ATTACKS,
  SECURITY_RECEIVE_BLOCKED_EVENTS,
  SECURITY_RECEIVE_DATES,
  SECURITY_RECEIVE_ENDPOINTS_ATTACKS,
  SECURITY_RECEIVE_ENDPOINT_EVENTS,
  SECURITY_RECEIVE_ENDPOINTS_HOSTS,
  SECURITY_RECEIVE_ENDPOINT_VICTIMS,
  SECURITY_RECEIVE_EGRESS_TRAFFIC,
  SECURITY_RECEIVE_FAILED_LOGINS,
  SECURITY_RECEIVE_FINDINGS,
  SECURITY_RECEIVE_INGRESS_TRAFFIC,
  SECURITY_RECEIVE_INTRUDERS,
  SECURITY_RECEIVE_LOGGING_DEVICES,
  SECURITY_RECEIVE_LOGGING_ENDPOINTS,
  SECURITY_RECEIVE_LOGS_TOTAL,
  SECURITY_RECEIVE_QUERY_DESCRIPTIONS,
  SECURITY_RECEIVE_THREATS_AND_SUSPECTS,
  SECURITY_RECEIVE_VICTIMS,
} from "./actions/Security";

/*
Constants
*/

// Used to create all reducers except 'queryDescriptions'
const STORE_TO_ACTION = {
  dates: SECURITY_RECEIVE_DATES,
  attackTypes: SECURITY_RECEIVE_ATTACK_TYPES,
  blockedAttacks: SECURITY_RECEIVE_BLOCKED_ATTACKS,
  blockedEvents: SECURITY_RECEIVE_BLOCKED_EVENTS,
  egressTraffic: SECURITY_RECEIVE_EGRESS_TRAFFIC,
  endpointAttacks: SECURITY_RECEIVE_ENDPOINTS_ATTACKS,
  endpointEvents: SECURITY_RECEIVE_ENDPOINT_EVENTS,
  endpointHosts: SECURITY_RECEIVE_ENDPOINTS_HOSTS,
  endpointVictims: SECURITY_RECEIVE_ENDPOINT_VICTIMS,
  failedLogins: SECURITY_RECEIVE_FAILED_LOGINS,
  findings: SECURITY_RECEIVE_FINDINGS,
  ingressTraffic: SECURITY_RECEIVE_INGRESS_TRAFFIC,
  intruders: SECURITY_RECEIVE_INTRUDERS,
  loggingDevices: SECURITY_RECEIVE_LOGGING_DEVICES,
  loggingEndpoints: SECURITY_RECEIVE_LOGGING_ENDPOINTS,
  logsTotal: SECURITY_RECEIVE_LOGS_TOTAL,
  threatsAndSuspects: SECURITY_RECEIVE_THREATS_AND_SUSPECTS,
  victims: SECURITY_RECEIVE_VICTIMS,
};

const INITIAL_STATE = Object.keys(STORE_TO_ACTION).reduce((accum, key) => {
  accum[key] = {};
  return accum;
}, {});

INITIAL_STATE.queryDescriptions = {};

/*
Helpers
*/

const receiveQueryDescriptions = (state = {}, action) => {
  const { payload = {} } = action;
  const { queryDescriptions } = payload;

  const queryDescriptionsObj = queryDescriptions.reduce((accum, elt) => {
    const { name, description, title } = elt;
    accum[name] = {
      description,
      title,
    };

    return accum;
  }, {});

  const nextState = {
    ...state,
    ...queryDescriptionsObj,
  };

  return nextState;
};

const getReducer = ({ initialStateKey }) => {
  const actionTypeStr = STORE_TO_ACTION[initialStateKey];

  const reducer = (state = INITIAL_STATE[initialStateKey], action) => {
    switch (action.type) {
      case actionTypeStr: {
        return insertData(state, action);
      }
      case RESET_SECURITY: {
        return INITIAL_STATE[initialStateKey];
      }
      default:
        return state;
    }
  };

  return reducer;
};

/*
Reducers
*/

// queryDescriptions
const queryDescriptionsReducer = (
  state = INITIAL_STATE.queryDescriptions,
  action
) => {
  switch (action.type) {
    case SECURITY_RECEIVE_QUERY_DESCRIPTIONS: {
      return receiveQueryDescriptions(state, action);
    }
    default:
      return state;
  }
};

// All the other reducers
const reducers = Object.keys(STORE_TO_ACTION).reduce((accum, key) => {
  accum[key] = getReducer({ initialStateKey: key });
  return accum;
}, {});

reducers.queryDescriptions = queryDescriptionsReducer;
// 'security' reducer
const SecurityReducer = combineReducers(reducers);

export default SecurityReducer;
