import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";

import { redirect } from "redux-first-router";
import config from "../lib/config";

const PREFIX = "TopbarView";

const classes = {
  navBar: `${PREFIX}-navBar`,
  navBarItem: `${PREFIX}-navBarItem`,
  navBarButton: `${PREFIX}-navBarButton`,
  itemText: `${PREFIX}-itemText`,
  focusVisible: `${PREFIX}-focusVisible`,
  itemActive: `${PREFIX}-itemActive`,
  menuList: `${PREFIX}-menuList`,
  navLink: `${PREFIX}-navLink`,
  paper: `${PREFIX}-paper`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.navBar}`]: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    margin: theme.spacing(0, 4),
  },

  [`& .${classes.navBarItem}`]: {
    borderColor: alpha(theme.palette.common.white, 0.25),
    borderRightWidth: "thin",
    borderRightStyle: "solid",
    "&:first-of-type": {
      borderLeftWidth: "thin",
      borderLeftStyle: "solid",
    },
  },

  [`& .${classes.navBarButton}`]: {
    minWidth: "150px",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: theme.spacing(2, 0),
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-out",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: `100%`,
      height: 3,
      transform: "scale(0, 1)",
      transition: "0.2s ease-out",
      opacity: 0,
      backgroundColor: theme.palette.primary.light,
    },
    outline: "unset",
    "&:hover, &.Mui-focusVisible": {
      outline: "unset",
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:after": {
        opacity: 1,
        transform: "scale(1, 1)",
      },
    },
  },

  [`& .${classes.itemText}`]: {
    margin: theme.spacing(0.5, 1),
  },

  [`& .${classes.focusVisible}`]: {},

  [`& .${classes.itemActive}`]: {
    "&$item": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.primary.light
          : theme.palette.primary.main,
      "&:after": {
        opacity: 1,
        transform: "scale(1, 1)",
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.primary.light
            : theme.palette.primary.main,
      },
    },
  },

  [`& .${classes.menuList}`]: {
    zIndex: theme.zIndex.modal,
  },

  [`& .${classes.navLink}`]: {},

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],
    minWidth: "150px",
  },
}));

const TopbarView = (props) => {
  const { menuItems = [] } = config;
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState();

  const handleMenuNavigate = (menuType, childMenuType) => {
    props.dispatch(
      redirect({
        type: "PAGE",
        payload: {
          orgId: props.orgId,
          toplevel: menuType,
          secondlevel: childMenuType,
        },
      })
    );
  };

  const handleMenuFocus = (event, idx) => {
    setOpen(idx);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleMenuBlur = (event) => {
    event.currentTarget.blur();
    setOpen(null);
    setAnchorEl(null);
  };

  return (
    <Root className={classes.navBar}>
      {menuItems.map((menuItem, idx) => (
        <div className={classes.navBarItem} key={menuItem.title}>
          <ButtonBase
            className={classes.navBarButton}
            focusRipple
            focusVisibleClassName={classes.focusVisible}
            onFocus={(event) => handleMenuFocus(event, idx)}
            onBlur={handleMenuBlur}
            color="inherit"
          >
            <Badge badgeContent={menuItem.badge} color="primary">
              <Typography variant="button" className={classes.itemText}>
                {menuItem.title}
              </Typography>
            </Badge>
          </ButtonBase>
          <Popper
            className={classes.menuList}
            open={open === idx}
            anchorEl={anchorEl}
            transition
            placement="bottom-start"
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <div className={classes.paper}>
                  {menuItem.children.map((childMenuItem) => (
                    <List
                      component="div"
                      disablePadding
                      key={childMenuItem.title}
                    >
                      <ListItem
                        button
                        onClick={() =>
                          handleMenuNavigate(menuItem.type, childMenuItem.type)
                        }
                        className={classes.navLink}
                      >
                        <Badge
                          badgeContent={childMenuItem.badge}
                          color="primary"
                        >
                          <ListItemText primary={childMenuItem.title} />
                        </Badge>
                      </ListItem>
                    </List>
                  ))}
                </div>
              </Fade>
            )}
          </Popper>
        </div>
      ))}
    </Root>
  );
};

export default TopbarView;
