import { Map } from "immutable";

import {
  RECEIVE_FINDING,
  RECEIVE_FINDINGS,
  RECEIVE_CONSTANTS,
  TOGGLE_FINDING_MESSAGE,
  UPDATE_FINDING_FIELD,
  RESET_FINDINGS,
  RECEIVE_CONSTANTS_ERROR,
  RECEIVE_FINDINGS_ERROR,
} from "./actions/Findings";
import { DELETE_MESSAGE } from "./actions/Message";

const fromIdMap = (c) => c.reduce((all, x) => ({ ...all, [x.id]: x.name }), {});
const fromNameMap = (c) =>
  c.reduce((all, x) => ({ ...all, [x.name]: x.id }), {});
class Constant {
  constructor(values) {
    this.selectOptions = values.map(({ name, id }) => ({
      label: name,
      value: id,
    }));
    this._id_map = fromIdMap(values);
    this._name_map = fromNameMap(values);
  }

  fromId = (id, notfound) => this._id_map[id] || notfound;

  fromName = (name, notfound) => this._name_map[name] || notfound;
}

const constantsGenerator = (constants) =>
  Object.keys(constants).reduce(
    (allConstants, key) => ({
      ...allConstants,
      [key]: new Constant(constants[key]),
    }),
    {}
  );

// Use Map instead of fromJS because I really don't want all nested children to
// have to use the .get .set api, especially for nested workflows/tags.
const initialState = Map({
  findings: [],
  constants: {
    category: new Constant([{}]),
    jurisdiction: new Constant([{}]),
    resolution: new Constant([{}]),
    status: new Constant([{}]),
    type: new Constant([{}]),
    uiAction: new Constant([{}]),
  },
  constantsError: null,
  findingsError: null,
});

const FindingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DELETE_MESSAGE: {
      // Put the message into the finding's pendingMessage.
      // Set the finding's showingTextBox and messageError to 'true'.

      const { payload = {} } = action;
      const { objectId: findingId, message } = payload;
      const { body } = message;

      const key = state.get("findings").findIndex((x) => x.id === findingId);

      return state
        .setIn(["findings", key, "pendingMessage"], body)
        .setIn(["findings", key, "showingTextBox"], true)
        .setIn(["findings", key, "messageError"], true);
    }
    case RECEIVE_FINDINGS: {
      // showingTextBox and pendingMessage are not returned from API. Instead
      // of tracking these finding-related properties elsewhere, inject into
      // finding object. GraphQL explicitness makes sure this meta-data doesn't
      // get sent to API, and this simplies object tracking.
      return Map({
        constants: action.constants
          ? constantsGenerator(action.constants)
          : state.get("constants"),
        findings: action.findings.map((i) => ({
          ...i,
          showingTextBox: false,
          pendingMessage: "",
          messageError: false,
        })),
      });
    }
    case RECEIVE_FINDING: {
      const allMatchKeys = ({ matches }) =>
        // [[{keyA, value}, {keyB, value}], [{keyA, value}], [{keyB, value}]]
        matches
          .map((i) => i.map((x) => x.key)) // [[keyA, keyB], [keyA, keyB, keyC]]
          .flat() // [keyA, keyB, keyA, keyB, keyC]
          .reduce((all, m) => ({ ...all, [m]: true }), {});
      //  return { keyA: true, keyB: true, keyC: true }

      const evidence = (action.finding.evidence || []).map((x) =>
        x.matches && x.matches.length > 0
          ? { ...x, uniqueKeys: Object.keys(allMatchKeys(x)) }
          : x
      );

      const finding = {
        ...action.finding,
        evidence,
      };

      const key = state.get("findings").findIndex((x) => x.id === finding.id);

      // finding doesnt exist currently
      if (key === -1) {
        return state.set("findings", [finding]);
      }

      return state.setIn(["findings", key], finding);
    }
    case RECEIVE_CONSTANTS: {
      return state.set(
        "constants",
        action.constants
          ? constantsGenerator(action.constants)
          : state.get("constants")
      );
    }
    case RECEIVE_CONSTANTS_ERROR: {
      return state.set("constantsError", (action.payload || {}).error);
    }
    case RECEIVE_FINDINGS_ERROR: {
      return state.set("findingsError", (action.payload || {}).error);
    }
    case TOGGLE_FINDING_MESSAGE: {
      const key = state.get("findings").findIndex((x) => x.id === action.id);
      return state.updateIn(["findings", key, "showingTextBox"], (val) => !val);
    }
    case UPDATE_FINDING_FIELD: {
      const key = state.get("findings").findIndex((x) => x.id === action.id);
      return state.setIn(["findings", key, action.field], action.value);
    }
    case RESET_FINDINGS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default FindingsReducer;
