const messageSchema = {
  $id: "/message",
  title: "Message",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    asynchronous: { type: "boolean" },
    body: { type: "string" },
    created: { type: "string", format: "date-time", readOnly: true },
    foreignId: { type: "string", format: "uuid" },
    foreignType: { type: "string" },
    type: { type: "number" },
    orgId: { type: "string", format: "uuid" },
    recipient: { type: "string" },
    sender: { type: "string", format: "uuid" },
    subject: { type: "string" },
  },
};

export default messageSchema;
