import * as PageActions from "./Page";
import * as UserActions from "./Users";
import * as SubnetActions from "./Subnet";
import * as SensorActions from "./Sensors";
import * as TaggingActions from "./Tagging";
import * as MessageActions from "./Message";
import * as FindingActions from "./Findings";
import * as BlockingActions from "./Blocking";
import * as LocationActions from "./Locations";
import * as DataQueryActions from "./DataQuery";
import * as ReportingReportActions from "./Reporting";
import * as UserInterfaceActions from "./UserInterface";
import * as ReportingDataQueryActions from "./QueryActions";
import * as SessionActions from "./Session";

export const Page = PageActions;
export const User = UserActions;
export const Message = MessageActions;
export const Tagging = TaggingActions;
export const Findings = FindingActions;
export const DataQuery = DataQueryActions;
export const QueryContainer = {
  ...ReportingReportActions,
  ...ReportingDataQueryActions,
};
export const Subnet = SubnetActions;
export const Sensors = SensorActions;
export const Location = LocationActions;
export const UserInterface = UserInterfaceActions;
export const Session = SessionActions;
export const Blocking = BlockingActions;

const actions = {
  QueryContainer,
  User: UserActions,
  Page: PageActions,
  Subnet: SubnetActions,
  Sensors: SensorActions,
  Tagging: TaggingActions,
  Finding: FindingActions,
  Message: MessageActions,
  Location: LocationActions,
  DataQuery: DataQueryActions,
  UserInterface: UserInterfaceActions,
  Session: SessionActions,
  Blocking: BlockingActions,
};

export default actions;
