import React from "react";
import PropTypes from "prop-types";

import BluBarGraph from "./common/BluBarGraph";

/*
Constants
*/

const BAR_COLOR = "#e8c1a0"; // hsl(27, 61%, 77%) rgb(232, 193, 160)
const BAR_COLOR_DARKER_1_6 = "#836d5a"; // hsl(28, 19%, 43%) rgb(131, 109, 90)
const TOOLTIP_TEXT = "events";

/*
Main compenent
*/

const LoggingEndpointsGraph = ({
  labelField,
  labelField2,
  rawData,
  valueField,
}) => (
  <BluBarGraph
    labelField={labelField}
    labelField2={labelField2}
    rawData={rawData}
    valueField={valueField}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    colors={[BAR_COLOR]}
    tooltipText={TOOLTIP_TEXT}
  />
);

LoggingEndpointsGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  labelField2: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

LoggingEndpointsGraph.defaultProps = {
  rawData: [],
};

export default LoggingEndpointsGraph;
