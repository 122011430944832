import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import moment from "moment-timezone";
import { CircularProgress } from "@mui/material";
import Collapse from "../Components/Collapse";
import { pageWithQuery } from "../../redux/actions/Page";
import { orgIdSelector } from "../../selectors/orgIdSelector";
import { securityTimeIntervalSelector as timeIntervalSelector } from "../../selectors/timeIntervalSelectors";
import {
  missingDataFlagSelector,
  timeWindowSelector,
} from "../../selectors/securitySelectors";
import SmallGraphs from "./Security/SmallGraphs";
import SecurityOverview from "./Security/SecurityOverview";
import { TimespanPicker } from "../Components/TimespanPicker";
import {
  fetchAttackTypes,
  fetchBlockedAttacks,
  fetchBlockedEvents,
  fetchEndpointAttacks,
  fetchEndpointEvents,
  fetchEndpointHosts,
  fetchEndpointVictims,
  fetchEgressTraffic,
  fetchFailedLogins,
  fetchFindings,
  fetchIngressTraffic,
  fetchIntruders,
  fetchLoggingDevices,
  fetchLoggingEndpoints,
  fetchLogsTotal,
  fetchQueryDescriptions,
  fetchThreatsAndSuspects,
  fetchVictims,
} from "../../redux/actions/Security";
import "./SecurityDashboard.scss";

/*
Constants
*/

const FOOTER_TEXT =
  "Some graphs are not displayed due" +
  " to no available data of the corresponding type" +
  " within the selected timeframe.";

/*
Main component
*/
const SecurityDashboard = (props) => {
  const {
    ready,
    fetchData,
    dataLackingFlag,
    dispatchPageWithQuery,
    orgId,
    timeInterval: selectedValue,
  } = props;
  const [selectedEndTime, setSelectedEndTime] = useState(moment());
  const [selectedStartTime, setSelectedStartTime] = useState(
    moment().add(-30, "days")
  );

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevOrgId = usePrevious(orgId);
  const prevSelectedValue = usePrevious(selectedValue);

  useEffect(() => {
    // prevOrgId will be undefined initially
    // after initial load, only fetch when new
    // data is needed i.e. time window change
    if (prevOrgId !== orgId || prevSelectedValue !== selectedValue) {
      fetchData({
        orgId,
        selectedValue,
        timeStart: selectedStartTime,
        timeEnd: selectedEndTime,
      });
    }
  }, [orgId, fetchData, selectedValue, selectedEndTime, selectedStartTime]);

  // Handle the time interval selection
  const memoizedHandleSelect = (newValue) => {
    dispatchPageWithQuery(newValue);
  };

  const getTimeDiffAndUnit = (startTime, endTime) => {
    let i = 0;
    let timeDiff = 0;
    let timeDiffUnitAbr = "d";

    const timeSpans = [
      { unit: "days", abr: "d" },
      { unit: "hours", abr: "hr" },
      { unit: "minutes", abr: "m" },
    ];

    while (timeSpans[i] && timeDiff === 0) {
      timeDiffUnitAbr = timeSpans[i].abr;
      timeDiff = endTime.diff(startTime, timeSpans[i].unit);
      i++;
    }

    return `${startTime.format("M")}-${timeDiff}${timeDiffUnitAbr}`; // startingMonth-relativeTime
  };

  const handleSetTimes = (startTime, endTime) => {
    setSelectedStartTime(startTime);
    setSelectedEndTime(endTime);

    const newValue = getTimeDiffAndUnit(startTime, endTime);

    memoizedHandleSelect(newValue);
  };

  return (
    <div className="dashboard security-dashboard-container">
      <div className="security-select-container">
        <TimespanPicker
          starttime={selectedStartTime}
          endtime={selectedEndTime}
          settimes={handleSetTimes}
        />
      </div>

      <div className="security-top-graph-container">
        <Collapse
          title="State of Security Overview"
          height="100%"
          collapseId="security-dashboard-collapse-1"
        >
          {ready ? (
            <SecurityOverview interval={selectedValue} />
          ) : (
            <div>
              <p>Loading your data...</p>
              <CircularProgress size={25} />
            </div>
          )}
        </Collapse>
      </div>

      <div>
        <SmallGraphs ready={ready} />
      </div>

      {dataLackingFlag && (
        <div className="security-dashboard-footer">{FOOTER_TEXT}</div>
      )}
    </div>
  );
};

SecurityDashboard.propTypes = {
  fetchData: PropTypes.func.isRequired,
  dataLackingFlag: PropTypes.bool.isRequired,
  dispatchPageWithQuery: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  timeInterval: PropTypes.string.isRequired,
  timeWindow: PropTypes.shape({}).isRequired,
};

const mapStateToPropsSelector = createStructuredSelector({
  orgId: orgIdSelector,
  dataLackingFlag: missingDataFlagSelector,
  timeInterval: timeIntervalSelector,
  timeWindow: timeWindowSelector,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: ({ orgId, selectedValue, timeStart, timeEnd }) => {
    let queryParams = {};

    let newTimeStart = timeStart.toISOString();
    let newTimeEnd = timeEnd.toISOString();

    queryParams = {
      timeStart: newTimeStart,
      timeEnd: newTimeEnd,
    };

    dispatch(fetchAttackTypes({ orgId, queryParams, selectedValue }));
    dispatch(fetchBlockedAttacks({ orgId, queryParams, selectedValue }));
    dispatch(fetchBlockedEvents({ orgId, queryParams, selectedValue }));
    dispatch(fetchEgressTraffic({ orgId, queryParams, selectedValue }));
    dispatch(fetchEndpointAttacks({ orgId, queryParams, selectedValue }));
    dispatch(fetchEndpointEvents({ orgId, queryParams, selectedValue }));
    dispatch(fetchEndpointHosts({ orgId, queryParams, selectedValue }));
    dispatch(fetchEndpointVictims({ orgId, queryParams, selectedValue }));
    dispatch(fetchFindings({ orgId, queryParams, selectedValue }));
    dispatch(fetchFailedLogins({ orgId, queryParams, selectedValue }));
    dispatch(fetchIngressTraffic({ orgId, queryParams, selectedValue }));
    dispatch(fetchIntruders({ orgId, queryParams, selectedValue }));
    dispatch(fetchLoggingDevices({ orgId, queryParams, selectedValue }));
    dispatch(fetchLoggingEndpoints({ orgId, queryParams, selectedValue }));
    dispatch(fetchLogsTotal({ orgId, queryParams, selectedValue }));
    dispatch(fetchQueryDescriptions);
    dispatch(fetchThreatsAndSuspects({ orgId, queryParams, selectedValue }));
    dispatch(fetchVictims({ orgId, queryParams, selectedValue }));
  },
  dispatchPageWithQuery: (timeInterval) => {
    dispatch(pageWithQuery({ timeInterval }));
  },
});

export default connect(
  mapStateToPropsSelector,
  mapDispatchToProps
)(SecurityDashboard);
