import { createSelector } from "reselect";

const locationSelector = (state) => state.location;

export const getLocationQuery = (location) => {
  const { query = {} } = location;

  return query;
};

export const locationQuerySelector = createSelector(
  [locationSelector],
  getLocationQuery
);
