import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

class RadioField extends Component {
  constructor(props) {
    super(props);
    this.field = [];
  }

  render() {
    const {
      type = "radio",
      name,
      label,
      required,
      values = {},
      errors = {},
      touched = {},
      disabled = false,
      hidden = false,
      options = [],
      handleBlur,
      handleChange,
      classes = "",
      validation = {},
      formatting = {},
    } = this.props;
    const uniqueId = _.uniqueId();
    const inputs = [];
    _.each(options, (o, i) => {
      const uniqueId2 = _.uniqueId();
      inputs.push({
        id: `${name}__${uniqueId}__${uniqueId2}`,
        name,
        className: classes || "control-item",
        type,
        value: values[name],
        onChange: handleChange,
        onBlur: handleBlur,
        ref: (c) => {
          this.field[i] = c;
        },
        validation: { required, ...validation },
        formatting: { ...formatting },
        disabled,
        optionLabel: o.label,
        optionValue: o.value,
      });
    });
    return (
      <div className={hidden ? "hidden" : ""}>
        <div
          className={`field ${errors[name] && touched[name] ? "error" : ""}`}
        >
          <label
            htmlFor={`${name}__${uniqueId}`}
            className="control-label"
            title={errors[name]}
          >
            {label}
            {required && <span className="text-danger">*</span>}
            {errors[name] && touched[name] ? (
              <span className="error-message">{errors[name]}</span>
            ) : null}
          </label>
          {_.map(inputs, (o, i) => (
            <div className={o.className} key={i}>
              <input
                type={o.type}
                key={i}
                name={o.name}
                id={o.id}
                value={o.optionValue}
                checked={o.value === o.optionValue}
                onChange={o.onChange}
                onBlur={o.onBlur}
                ref={o.ref}
                validation={o.validation}
                formatting={o.formatting}
                disabled={o.disabled}
              />
              <label
                style={{ display: "inline-block", marginLeft: "5px" }}
                htmlFor={o.id}
              >
                {o.optionLabel}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

RadioField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  values: PropTypes.shape({}),
  options: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  validation: PropTypes.shape({}),
  formatting: PropTypes.shape({}),
};

RadioField.defaultProps = {
  type: "radio",
  label: "",
  required: false,
  values: {},
  options: [],
  hidden: false,
  disabled: false,
  errors: {},
  touched: {},
  classes: "",
  validation: {},
  formatting: {},
};

export default RadioField;
