import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { primaryPipelineSelector } from "../../../selectors/pipelineSelectors";

import InfoTable from "./InfoTable";
import { getRows } from "./supportHelpers";

/*
Constatns and helpers
*/

const DATE_KEYS = ["created", "status_changed"];

const LINK_KEYS = ["dataflow_console_url"];

export const getShowPipeline = (data) => Object.keys(data).length !== 0;

/*
Main component
*/

const PipelineInfo = ({ pipelineData = {}, showPipeline }) => {
  if (!showPipeline) {
    return (
      <div className="no-pipeline text-danger">
        No known active pipeline job exists!
      </div>
    );
  }

  const data = getRows(pipelineData, DATE_KEYS, LINK_KEYS);

  return <InfoTable data={data} />;
};

PipelineInfo.propTypes = {
  pipelineData: PropTypes.shape({}).isRequired,
  showPipeline: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const pipelineData = primaryPipelineSelector(state);
  const showPipeline = getShowPipeline(pipelineData);

  return {
    pipelineData,
    showPipeline,
  };
};

export default connect(mapStateToProps, null)(PipelineInfo);
