import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "integration",
};

export class IntegrationAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  async getCloudConnectorDetails() {
    const params = ["connectorInfo"];
    return super.get({ params });
  }
}
