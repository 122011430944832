import {
  Select,
  Divider,
  Checkbox,
  MenuItem,
  TextField,
  FormGroup,
  InputLabel,
  FormControl,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";

import RemoveRedEye from "@mui/icons-material/RemoveRedEye";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React, { useEffect, useState } from "react";
import { cloudModuleConfigFieldLabels } from "./constants";
import { Root, classes } from "./styles";

const areSecretValuesEmpty = (secretValues) => {
  let areSecretValuesEmpty = true;
  for (const value of Object.values(secretValues)) {
    if (value && value !== "") {
      areSecretValuesEmpty = false;
      break;
    }
  }
  return areSecretValuesEmpty;
};
export function ConfigurationForm(props) {
  const ConfigSection = "configuration";
  const SecretSection = "secret";
  const RootSection = "";

  const { model, disabled, validateAll, onSubmit, schemas, superadmin } = props;

  const [nameValue, setNameValue] = useState(model.integrationName || "");
  const [configValues, setConfigValues] = useState(model.configuration || {});
  const [secretValues, setSecretValues] = useState({});
  const [secretIsMasked, setSecretIsMasked] = useState(true);
  const [maskedInputIndexes, setMaskedInputIndexes] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [configFields, setConfigfields] = useState([]);
  const [secretFields, setSecretFields] = useState([]);
  const integrationType = model.integrationType;

  const integrationSchema = schemas.find(
    (schema) => schema.integrationType === integrationType
  );

  const configurationSchema = integrationSchema.configurationSchema;
  const secretSchema = integrationSchema.secretSchema;
  const nameFieldInfo = {
    section: RootSection,
    label: "Cloud Connector Name",
    name: "integrationName",
    editable: true,
    required: true,
    placeholder: "",
  };

  useEffect(() => {
    const hasValidationError = Object.values(validationErrors).some(
      (value) => value === true
    );
    props.onValidation({ hasValidationError });
  }, [validationErrors]);

  useEffect(() => {
    let configFields = [];
    let secretFields = [];

    // get total number of secret fields for this connector
    const numberOfSecretFields = Object.values(
      cloudModuleConfigFieldLabels[integrationType][SecretSection]
    ).length;

    // create new array with number of indexes to match secret fields
    const newMaskedInputArray = Array.from(Array(numberOfSecretFields).keys());

    setMaskedInputIndexes(newMaskedInputArray);

    for (const [prop, propInfo] of Object.entries(
      configurationSchema["properties"] || {}
    )) {
      if (propInfo.type !== "string") {
        console.warn("unimplemented field type", propInfo.type);
        continue;
      }
      const requiredBySchema =
        configurationSchema["required"] &&
        configurationSchema["required"].includes(prop);
      if (
        cloudModuleConfigFieldLabels[integrationType][ConfigSection] &&
        cloudModuleConfigFieldLabels[integrationType][ConfigSection][prop]
      ) {
        configFields = configFields.concat({
          section: ConfigSection,
          label:
            cloudModuleConfigFieldLabels[integrationType][ConfigSection][prop],
          name: prop,
          editable: true,
          required: requiredBySchema,
          placeholder: requiredBySchema ? "(required)" : "(optional)",
        });
      }
    }

    for (const [prop, propInfo] of Object.entries(secretSchema["properties"])) {
      if (propInfo.type !== "string" && propInfo.type !== "boolean") {
        // boolean now to allow for is_gcc on M365 CC
        console.warn("unimplemented field type", propInfo.type);
        continue;
      }

      // selectedCloudModule has dropDownValues and,
      // more specifically, provides options for this property
      const isSelectField =
        !!props.selectedCloudModule.dropDownValues &&
        !!props.selectedCloudModule.dropDownValues[prop];

      // selectedCloudModule has textArea property and,
      // more specifically, provides boolean for this property
      const isTextAreaField =
        !!props.selectedCloudModule.textArea &&
        !!props.selectedCloudModule.textArea[prop];

      const isLicenseFeatureEnabledField =
        !!props.selectedCloudModule.licenseFeatureEnabledField &&
        !!props.selectedCloudModule.licenseFeatureEnabledField[prop];

      const doesFieldHaveHelperText =
        !!props.selectedCloudModule.helperText &&
        !!props.selectedCloudModule.helperText[prop];

      // the following code is strictly to maintain
      // the order of properties displayed as they
      // are layed out in the constants file
      const secretSectionKeyArray = Object.keys(
        cloudModuleConfigFieldLabels[integrationType][SecretSection]
      );
      const indexOfProp = secretSectionKeyArray.indexOf(prop);

      const requiredBySchema =
        secretSchema["required"] && secretSchema["required"].includes(prop);
      const fieldCurrentlyRequired = !model.id
        ? requiredBySchema
        : areSecretValuesEmpty(secretValues)
        ? false
        : requiredBySchema;

      secretFields[indexOfProp] = {
        section: SecretSection,
        label:
          cloudModuleConfigFieldLabels[integrationType][SecretSection][prop],
        name: prop,
        editable: true,
        required: fieldCurrentlyRequired,
        placeholder: fieldCurrentlyRequired
          ? "(required)"
          : !model.id
          ? "(optional)"
          : "(no change)",
        options: isSelectField
          ? props.selectedCloudModule.dropDownValues[prop]
          : isTextAreaField
          ? props.selectedCloudModule.textArea[prop]
          : null,
        featureFlag: isLicenseFeatureEnabledField
          ? props.selectedCloudModule.licenseFeatureEnabledField[prop]
          : null,
        helperText: doesFieldHaveHelperText
          ? props.selectedCloudModule.helperText[prop]
          : null,
        type: propInfo.type,
      };
    }

    setConfigfields(configFields);
    setSecretFields(secretFields);
  }, [configurationSchema, secretSchema, validationErrors]);

  if (!integrationType || integrationType === "") {
    console.error("integration has no type");
    return <div />;
  }

  if (!integrationSchema) {
    console.error(
      "no schema for this integration type was returned from the server",
      integrationType
    );
    return <div />;
  }

  const updateValidation = (fieldInfo, value) => {
    setValidationErrors({
      ...validationErrors,
      [fieldInfo.section + "." + fieldInfo.name]: fieldInfo.validation
        ? !fieldInfo.validation.test(value)
        : fieldInfo.required && value === "",
    });
  };

  const handleChange = (fieldInfo, value) => {
    updateValidation(fieldInfo, value);

    if (fieldInfo.section === ConfigSection) {
      setConfigValues({
        ...configValues,
        [fieldInfo.name]: value,
      });
    } else if (fieldInfo.section === SecretSection) {
      setSecretValues({
        ...secretValues,
        [fieldInfo.name]: value,
      });
    } else if (
      fieldInfo.section === RootSection &&
      fieldInfo.name === nameFieldInfo.name
    ) {
      setNameValue(value);
    } else {
      console.error("unexpected section", fieldInfo);
    }
  };

  const handleBlur = (fieldInfo, value) => {
    updateValidation(fieldInfo, value);

    model.set({ [nameFieldInfo.name]: nameValue }, false);
    model.set({ configuration: configValues }, false);

    if (areSecretValuesEmpty(secretValues)) {
      model.set({ secret: undefined }, false);
    } else {
      model.set({ secret: secretValues }, false);
    }
  };

  const toggleMask = (inputIndex) => {
    const foundInputIndex = maskedInputIndexes.find((i) => i === inputIndex);
    const newMaskedInputIndexes =
      foundInputIndex > -1
        ? [...maskedInputIndexes].filter((i) => i !== foundInputIndex)
        : [...maskedInputIndexes].concat([inputIndex]);
    setMaskedInputIndexes(newMaskedInputIndexes);
    setSecretIsMasked(!secretIsMasked);
  };

  const isInputMasked = (inputIndex) => {
    return maskedInputIndexes.find((i) => i === inputIndex) > -1;
  };

  const isStringLengthValid = (string, maxLength) => string.length <= maxLength;
  const isStringCharactersValid = (string, regex) => regex.test(string);

  return (
    <Root
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <FormControl component="fieldset" fullWidth={true}>
        <TextField
          autoFocus={true}
          value={nameValue}
          label={nameFieldInfo.label}
          datacy={nameFieldInfo.name}
          onChange={(e) => handleChange(nameFieldInfo, e.target.value)}
          onBlur={(e) => handleBlur(nameFieldInfo, e.target.value)}
          className={classes.field}
          disabled={disabled || !nameFieldInfo.editable}
          key={RootSection + nameFieldInfo.name}
          error={
            !!validationErrors[RootSection + "." + nameFieldInfo.name] ||
            !isStringLengthValid(nameValue, 255) ||
            !isStringCharactersValid(nameValue, /^[A-Za-z0-9 -]+$/)
          }
          helperText={
            !isStringLengthValid(nameValue, 255) ||
            !isStringCharactersValid(nameValue, /^[A-Za-z0-9 -]+$/)
              ? "must be less than 256 characters and only contain letters, numbers, or dashes"
              : ""
          }
          InputLabelProps={{
            shrink: true,
          }}
        />

        {configFields.map((fieldInfo, index) => (
          <TextField
            datacy={fieldInfo.name}
            value={configValues[fieldInfo.name] || ""}
            placeholder={fieldInfo.placeholder}
            label={fieldInfo.label}
            onChange={(e) => handleChange(fieldInfo, e.target.value)}
            onBlur={(e) => handleBlur(fieldInfo, e.target.value)}
            className={classes.field}
            disabled={disabled || !fieldInfo.editable}
            key={ConfigSection + index}
            error={
              !!validationErrors[ConfigSection + "." + fieldInfo.name] ||
              (validateAll &&
                fieldInfo.required &&
                (!configValues[fieldInfo.name] ||
                  configValues[fieldInfo.name] === ""))
            }
            helperText={fieldInfo.helperText}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ))}

        <Divider className={classes.divider} />

        <p className={classes.secretsText}>
          <span className={classes.secretsTitle}>Secrets: </span>
          {model.id
            ? "Leave the following fields blank to leave this connector’s secrets unchanged."
            : "The following entries will be hidden when editing this cloud connector in the future."}
        </p>

        {secretFields.map((fieldInfo, index) => {
          // display checkbox for boolean field types like is_gcc
          if (fieldInfo.type === "boolean") {
            return (
              <FormGroup key={fieldInfo.label}>
                <FormControlLabel
                  control={<Checkbox />}
                  label={fieldInfo.label}
                  checked={secretValues[fieldInfo.name]}
                  onChange={(e) => {
                    handleChange(fieldInfo, e.target.checked);
                  }}
                  onBlur={(e) => handleBlur(fieldInfo, e.target.checked)}
                />
                {fieldInfo.helperText && (
                  <p className={classes.checkboxHelperText}>
                    {fieldInfo.helperText}
                  </p>
                )}
              </FormGroup>
            );
          } else if (
            Array.isArray(fieldInfo.options) &&
            !!fieldInfo.options.length
          ) {
            // optional options array prop for Select
            // see CloudModules/constants for example
            return (
              <FormControl fullWidth key={SecretSection + index}>
                <InputLabel id={"select-field-label"}>
                  {fieldInfo.label}
                </InputLabel>
                <Select
                  label={fieldInfo.label}
                  className={classes.field}
                  labelId={"select-field-label"}
                  value={secretValues[fieldInfo.name] || ""}
                  disabled={disabled || !fieldInfo.editable}
                  onChange={(e) => handleChange(fieldInfo, e.target.value)}
                  onBlur={(e) => handleBlur(fieldInfo, e.target.value)}
                  datacy={"integrationSelectField"}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {fieldInfo.options.map((o, index) => (
                    <MenuItem
                      key={o.label}
                      value={o.value}
                      datacy={`integrationSelectOption-${index}`}
                    >
                      {o.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          } else if (fieldInfo.options === true) {
            // optional options boolean prop for string fields with multiple lines
            // see CloudModules/constants for example (ie gsuite connector)
            return (
              <TextField
                inputProps={
                  isInputMasked(index)
                    ? {
                        style: {
                          fontFamily: "text-security-disc",
                        },
                      }
                    : {}
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ cursor: "pointer" }}
                      position={"end"}
                      onClick={() => toggleMask(index)}
                    >
                      {isInputMasked(index) ? (
                        <RemoveRedEye />
                      ) : (
                        <VisibilityOff />
                      )}
                    </InputAdornment>
                  ),
                }}
                label={fieldInfo.label}
                datacy={fieldInfo.name}
                key={SecretSection + index}
                className={classes.field}
                placeholder={fieldInfo.placeholder}
                value={secretValues[fieldInfo.name] || ""}
                disabled={disabled || !fieldInfo.editable}
                onBlur={(e) => handleBlur(fieldInfo, e.target.value)}
                onChange={(e) => handleChange(fieldInfo, e.target.value)}
                error={
                  !!validationErrors[SecretSection + "." + fieldInfo.name] ||
                  (validateAll &&
                    fieldInfo.required &&
                    (!secretValues[fieldInfo.name] ||
                      secretValues[fieldInfo.name] === ""))
                }
                multiline
                minRows={8}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            );
          } else {
            return (
              <TextField
                inputProps={
                  isInputMasked(index)
                    ? {
                        style: {
                          fontFamily: "text-security-disc",
                        },
                      }
                    : {}
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ cursor: "pointer" }}
                      position={"end"}
                      onClick={() => toggleMask(index)}
                    >
                      {isInputMasked(index) ? (
                        <RemoveRedEye />
                      ) : (
                        <VisibilityOff />
                      )}
                    </InputAdornment>
                  ),
                }}
                label={fieldInfo.label}
                datacy={fieldInfo.name}
                key={SecretSection + index}
                className={classes.field}
                placeholder={fieldInfo.placeholder}
                value={secretValues[fieldInfo.name] || ""}
                disabled={disabled || !fieldInfo.editable}
                onBlur={(e) => handleBlur(fieldInfo, e.target.value)}
                onChange={(e) => handleChange(fieldInfo, e.target.value)}
                error={
                  !!validationErrors[SecretSection + "." + fieldInfo.name] ||
                  (validateAll &&
                    fieldInfo.required &&
                    (!secretValues[fieldInfo.name] ||
                      secretValues[fieldInfo.name] === ""))
                }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            );
          }
        })}

        {model.id && superadmin && (
          <div>
            <Divider className={classes.divider} />
            <p className={classes.secretsText}>
              <span className={classes.secretsTitle}>Admin Info: </span>
              This information is only visible to Blumira superadmins.
            </p>
            <p className={classes.secretsText}>
              <span className={classes.secretsTitle}>Connector ID: </span>
              {model.id}
            </p>
          </div>
        )}
      </FormControl>
    </Root>
  );
}
