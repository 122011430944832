import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

import moment from "moment-timezone";

import { TimespanPicker } from "../../Components/TimespanPicker";
import { popularReports } from "./popularReportsList";
import VisualizationList from "../../Components/VisualizationList";
import Request from "lib/api/Request";

const PREFIX = "PopularReportsView";

const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
  selectContainer: `${PREFIX}-selectContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.outerContainer}`]: {
    width: "100%",
    padding: "0 10px",
  },
  [`& .${classes.selectContainer}`]: {
    marginBottom: 20,
  },
}));

const PopularReports = ({ savedQueries, theme }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visibleReports, setVisibleReports] = useState(popularReports);
  const [selectedEndTime, setSelectedEndTime] = useState(moment());
  const [selectedStartTime, setSelectedStartTime] = useState(
    moment().add(-30, "days")
  );

  useEffect(() => {
    if (savedQueries && savedQueries.length) {
      const promises = [];
      const reportsToMap = popularReports.map((report) => {
        const query = savedQueries.find((sq) => sq.id === report.id);
        report.request = new Request(`/bq`);
        report.model = query;

        report.request.fromQuery(query);
        report.request.setResultParam("outputFields", [report.field]);
        report.request.setResultParam("transform", [
          { operator: "count", value: "grouped_count" },
        ]);
        report.request.setResultParam("orderBy", [
          { key: "grouped_count", value: -1 },
        ]);

        // clear any queryParams already dealing with date
        Object.keys(report.request.queryParams).forEach((uuid) => {
          if (report.request.queryParams[uuid].field === "created") {
            report.request.deleteQueryParam(uuid);
          }
        });

        report.request.setQueryParam({
          field: "created",
          operator: "gt",
          value: selectedStartTime.format("YYYY-MM-DDTHH:mm"),
        });

        report.request.setQueryParam({
          field: "created",
          operator: "lt",
          value: selectedEndTime.format("YYYY-MM-DDTHH:mm"),
        });

        promises.push(
          report.request.get().then((results) => {
            report.data = report.formatFunction(results);
          })
        );
        return report;
      });

      // show loading indicator on individual report cards
      setIsLoading(true);

      Promise.all(promises).then((results) => {
        setVisibleReports(reportsToMap);
        setIsLoading(false);
      });
    }
  }, [selectedStartTime, selectedEndTime, savedQueries]);

  const handleSetTimes = (startTime, endTime) => {
    setSelectedStartTime(startTime);
    setSelectedEndTime(endTime);
  };

  return (
    <Root className={classes.outerContainer}>
      <div className={classes.selectContainer}>
        <TimespanPicker
          starttime={selectedStartTime}
          endtime={selectedEndTime}
          settimes={handleSetTimes}
        />
      </div>

      <VisualizationList
        theme={theme}
        listData={visibleReports}
        isLoading={isLoading}
      />
    </Root>
  );
};

const PopularReportsView = PopularReports;
export default PopularReportsView;
