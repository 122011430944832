import React from "react";
import PropTypes from "prop-types";

export const constantType = PropTypes.shape({
  fromId: PropTypes.func.isRequired,
  fromName: PropTypes.func.isRequired,
});

export const findingType = PropTypes.shape({
  id: PropTypes.string,
  orgId: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.number,
  created: PropTypes.string,
  createdBy: PropTypes.string,
  analysis: PropTypes.string,
  priority: PropTypes.number,
  jurisdiction: PropTypes.number,
  type: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.any),
  attachments: PropTypes.arrayOf(PropTypes.any),
});

export const constantsType = PropTypes.shape({
  resolution: constantType,
  category: constantType,
  status: constantType,
  type: constantType,
});

export const MESSAGE_ERROR_TEXT =
  "Unable to save the comment." +
  " If you continue to see this message," +
  " take a screenshot and contact support via the Help button below.";

export const Flags = (props) => {
  const { priority, typeId, typeLabel, fullWidth } = props;

  let priorityTag;
  let typeTag;

  if (fullWidth) {
    priorityTag = priority ? `Priority ${priority}` : "";
    typeTag = typeLabel;
  } else {
    priorityTag = priority ? `P${priority}` : "";
    typeTag = typeLabel.slice(0, 2).toUpperCase();
  }

  return (
    <div className="flags">
      <div className={`flag-type flag-type-${typeId}`} title={typeLabel}>
        {typeTag}
      </div>
      <div
        className={`flag-priority flag-priority-${priority}`}
        title={priorityTag}
      >
        {priorityTag}
      </div>
    </div>
  );
};

Flags.propTypes = {
  fullWidth: PropTypes.bool,
  priority: PropTypes.number,
  typeId: PropTypes.number.isRequired,
  typeLabel: PropTypes.string,
};

Flags.defaultProps = {
  fullWidth: false,
  priority: null,
  typeLabel: "",
};

export const shortIdStr = (shortId) => (shortId ? `ID: ${shortId}` : "");
