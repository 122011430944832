import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

const Loading = (props) => (
  <div className="react-loader">
    <Loader
      type={props.type}
      color={props.color}
      height={props.height}
      width={props.width}
    />
  </div>
);

Loading.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Loading.defaultProps = {
  type: "TailSpin",
  color: "#1B4F9C",
  height: "100",
  width: "100",
};

export default Loading;
