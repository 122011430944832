import React from "react";
import PropTypes from "prop-types";

import DataGrid from "../../Components/DataGrid";

const OverviewTable = (props) => {
  const { data = [] } = props;

  return (
    <DataGrid
      data={data}
      columns={[
        {
          Header: null,
          accessor: "nameAccessor",
          filterable: false,
          maxWidth: 120,
          Cell: (row) => row.original.name,
        },
        {
          Header: null,
          filterable: false,
          sortable: false,
          Cell: (row) => row.original.content,
        },
      ]}
      showPagination={false}
    />
  );
};

OverviewTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

OverviewTable.defaultProps = {
  data: [],
};

export default OverviewTable;
