import React from "react";
import moment from "moment-timezone";

import { capitalize } from "../../../lib/helpers";
import { generateSearchFilter } from "../../../lib/api/DataQueryAPI";

// Extract columns and format them for ReactTable (DataGrid)
export const pullColumns = ({
  data,
  dataSource,
  columnOptions,
  findingConstants,
  selectedColumns,
}) => {
  if (!data || !data.length) {
    return [];
  }

  const columnNames = Object.keys(data[0]);

  const idColumnSelected = selectedColumns.find((elt) => elt.value === "id");

  const headers = columnNames.map((col) => {
    if (dataSource === "finding" && col === "id") {
      return {
        Header: "ID",
        id: "findingId",
        show: !!idColumnSelected,
        accessor: (row) => row.id,
      };
    }
    const columnOption = columnOptions.find(
      (x) => x.label.toLowerCase() === col.toLowerCase()
    );

    if (!columnOption) {
      return {
        Header: col,
        id: col,
        accessor: col,
        maxWidth: 120,
      };
    }

    const isDateTime = columnOption.type.toLowerCase() === "timestamp";

    const column = {
      Header: col,
      id: col,
      accessor: (row) => row[col],
      minWidth: Math.min(200, Math.max(100, col.length * 9)),
    };

    if (dataSource === "finding") {
      if (columnOption && columnOption.constant) {
        const fieldConstants = findingConstants[column.id];
        // eslint-disable-next-line react/prop-types
        column.Cell = ({ value }) => {
          let valueConstant = fieldConstants && fieldConstants.fromId(value);

          if (column.id === "jurisdiction") {
            valueConstant = capitalize(valueConstant || "");
          }

          return <span>{valueConstant || value}</span>;
        };
        column.sortMethod = (a, b) => {
          if (fieldConstants) {
            const ax = fieldConstants.fromId(a);
            const bx = fieldConstants.fromId(b);
            if (ax && bx) {
              if (ax > bx) {
                return 1;
              }
              if (bx > ax) {
                return -1;
              }
              return 0;
            }
          }
          if (a > b) {
            return 1;
          }
          if (b > a) {
            return -1;
          }
          return 0;
        };
      }

      if (["type", "priority", "status"].includes(column.id)) {
        column.maxWidth = 80;
      }

      if (column.id === "priority") {
        column.sortMethod = (a, b) => {
          const ax = parseInt(a, 10);
          const bx = parseInt(b, 10);
          if (ax > bx) {
            return 1;
          }
          if (bx > ax) {
            return -1;
          }
          return 0;
        };
      }
    }

    if (isDateTime) {
      column.maxWidth = 100;
      column.sortMethod = (a, b) => {
        const ax = new Date(a).getTime();
        const bx = new Date(b).getTime();
        if (ax > bx) {
          return 1;
        }
        if (bx > ax) {
          return -1;
        }
        return 0;
      };
      // eslint-disable-next-line react/prop-types
      column.Cell = ({ value }) => {
        const ts = moment.utc(value).tz(moment.tz.guess(true));
        return (
          <div>
            <div>{ts.format("ll")}</div>
            <div>{ts.format("LT z")}</div>
          </div>
        );
      };
    }

    return column;
  });

  return headers;
};

/*
takes an arr and lets us know if distinct
count is present in said arr
*/
const hasDistinctCount = (arr) => {
  return !!arr.some((elt) => elt.toLowerCase() === "count(*)");
};

/*
Adds search attribute ("filters") to `query`
*/
export const addSearch = ({
  filters,
  hasAdvancedFilters,
  query,
  selectedQueryId,
  selectedSavedQuery,
}) => {
  let search;

  if (selectedQueryId && hasAdvancedFilters) {
    // No filters select - use selectedSavedQuery.rawSearch,
    // since otherwise FEA gets a distorted 'search' field
    query.rawSearch = selectedSavedQuery.rawSearch;
  } else {
    // There is filters select
    search = (filters || []).map(generateSearchFilter);
    query.search = search;
  }
};

/*
Adds 'aggregate' ("select" statements) and
'configuration' (order by, group by, time filter, etc.)
to `query`
*/
export const addAggregateAndConfig = (attr) => {
  const {
    aggregators,
    datesModified,
    endTime,
    hasNonCountAggregators,
    isAggregateData,
    query,
    saving = false,
    selectedColumns,
    selectedQueryId,
    selectedSavedQuery,
    startTime,
  } = attr;
  let aggregate;
  let configuration;

  const columns =
    (selectedColumns[0] && selectedColumns[0].value) ||
    (selectedColumns[0] && selectedColumns[0].name)
      ? selectedColumns
          .map((i) => (i.value ? i.value : i.name))
          .filter((i) => i)
      : selectedColumns;

  if (saving) {
    // If saving the query, the query configuration must contain
    // exactly one date range - the relative one or the absolute one

    if (datesModified) {
      // Dates modified
      if (selectedQueryId) {
        // loaded existing query
        configuration = { ...selectedSavedQuery.configuration };

        delete configuration.relativeEnd;
        delete configuration.relativeStart;
      } else {
        // did not load a query
        configuration = {};
      }

      // Add the dates
      configuration.timeStart = moment.utc(startTime).toISOString();
      configuration.timeEnd = moment.utc(endTime).toISOString();
    } else {
      // Dates not modified
      if (selectedQueryId) {
        // loaded existing query
        configuration = { ...selectedSavedQuery.configuration };
      } else {
        // did not load a query
        configuration = {};
      }

      // If no dates - add
      if (!configuration.relativeStart && !configuration.timeStart) {
        delete configuration.relativeEnd; // just in case it is present

        configuration.timeStart = moment.utc(startTime).toISOString();
        configuration.timeEnd = moment.utc(endTime).toISOString();
      }

      // If 'relativeStart' is present, remove the absolute date range
      if (configuration.relativeStart) {
        delete configuration.timeStart;
        delete configuration.timeEnd;
      }
    }
  } else {
    // If running the query - keep the old code. When running a query,
    // we are allowed to send both, relative and absolute, date ranges.

    configuration = {
      relativeEnd: null,
      relativeStart: null,
      timeStart: moment.utc(startTime).toISOString(),
      timeEnd: moment.utc(endTime).toISOString(),
    };

    if (selectedQueryId) {
      configuration = {
        ...selectedSavedQuery.configuration,
        ...configuration,
      };
    }
  }

  /*
  A preloaded query that has aggregator functions besides 'count(*)'
  In this case there is no columns select - use the loaded query's parameters
  */

  if (selectedQueryId && hasNonCountAggregators) {
    aggregate = [...selectedSavedQuery.rawAggregate];

    query.aggregate = aggregate;
    query.configuration = configuration;

    return;
  }

  /*
  For all other querries there is columns select -
  use the user supplied parameters.
  */

  // Copy the selected columns to the 'aggregate'
  // (why would selected columns have no value???)
  aggregate = [...columns];

  // Add the non-count-star 'aggregators' functions to the 'aggregate'
  // if there are any
  if (hasNonCountAggregators) {
    // Add the aggregator functions to the select statements
    aggregate = columns.concat(aggregators);
  }

  // Add 'count(*)' to 'aggregate' if 'Distinct counts' is on.
  // TODO: Note that this will add 'count(*)' to queries that have
  // e.g. 'max(...)' but not 'count(*)', since isAggregateData
  // is true for those queries as well. We need a separate flag for 'count(*)'
  const addCountStar = isAggregateData && !hasDistinctCount(aggregate);

  if (addCountStar) {
    aggregate.push("count(*)");
  }

  // if we have a distinct count, we can order by it
  // otherwise trying to order by count is going to break
  configuration.orderBy =
    hasDistinctCount(aggregate) && configuration.orderBy
      ? configuration.orderBy
      : [];

  // ensure timestamp is removed if a user is
  // running with distinct count
  if (hasDistinctCount(aggregate))
    configuration.groupBy = columns.filter((ag) => ag !== "Timestamp");
  query.aggregate = hasDistinctCount(aggregate)
    ? aggregate.filter((ag) => ag !== "Timestamp")
    : aggregate;
  query.configuration = configuration;
};
