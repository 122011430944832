import React, { useEffect } from "react";
import { connect } from "react-redux";
import { hot } from "react-hot-loader";
import PageContainer from "views/PageContainer";
import LoadingContainer from "views/Loading/LoadingContainer";
import LoginView from "views/Login/LoginView";
import NotFoundContainer from "views/NotFound/NotFoundContainer";
import TosContainer from "views/Tos/TosContainer";
import MonitorModeView from "views/MonitorMode/MonitorModeView";
import { NOT_FOUND } from "redux-first-router";
import config from "./config.json";
import { get, isNil } from "lodash";
import { getPendoVisitorDataFromUser, getPendoAccountDataFromOrg } from "utils";

// for reference: https://github.com/kelvinmaues/react-hubspot-tracking-code-hook
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const isProductionMode = () =>
  config.deploymentType === "staging" || config.deploymentType === "production";

const BlumiraApp = (props) => {
  const { setPathPageView, setIdentity } = useTrackingCode();

  const pendoScriptId = "pendo-script";
  const hubspotScriptId = "hubspot-script";

  // Wait for DOM ready
  document.addEventListener("DOMContentLoaded", () => {
    // Dynamically load pendo.js
    if (!document.getElementById(pendoScriptId) && isProductionMode()) {
      const pendoScript = document.createElement("script");
      pendoScript.id = "pendo-script";
      pendoScript.src = `https://content.prickly-pig.blumira.com/agent/static/${config.pendo_key}/pendo.js`;

      pendoScript.onload = () => {
        // Now safe to initialize pendo
        window.pendo.initialize({ apiKey: config.pendo_key });
      };

      document.body.appendChild(pendoScript);
    }

    // HubSpot
    if (!document.getElementById(hubspotScriptId)) {
      const hubspotScript = document.createElement("script");
      hubspotScript.id = "hubspot-script";
      hubspotScript.type = "text/javascript";
      hubspotScript.async = true;
      hubspotScript.defer = true;
      hubspotScript.src = `//js.hs-scripts.com/${config.hubspot_portal_id}.js`;

      document.body.appendChild(hubspotScript);
    }
  });

  useEffect(() => {
    // plz wait until the user is signed in
    // to identify them with Pendo
    if (props.user && props.organization) {
      const visitorInfo = getPendoVisitorDataFromUser(
        props.user,
        props.organization
      );
      const accountInfo = getPendoAccountDataFromOrg(props.organization);

      try {
        window.pendo.identify({
          visitor: visitorInfo,
          account: accountInfo,
        });
      } catch (err) {
        // Pendo is commonly blocked by common adblockers,
        // just ignore the fact it's missing and move on
        console.warn("Pendo unavailable", err);
      }
      // end Pendo

      // start Hubspot
      setIdentity(props.user.email);
      // end Hubspot
    }
  }, [props.user, props.organization, window.pendo]);

  // Track page views in HubSpot any time
  // the router path changes
  useEffect(() => {
    // Get the current path
    const currentPathname = window.location.pathname;

    // Call function to handle tracking
    setPathPageView(currentPathname);
  }, [window.location.pathname]);

  switch (props.pageType) {
    case "LOGIN":
      return <LoginView />;
    case "PAGE":
      return <PageContainer />;
    case "TOS":
      return <TosContainer />;
    case `${NOT_FOUND}`:
      return <NotFoundContainer />;
    case "MONITORMODE":
      return <MonitorModeView />;
    default:
      return <LoadingContainer />;
  }
};

const mapStateToProps = (state) => {
  let currentOrg = {};

  // get currentOrgId from page payload
  const currentOrgId = get(state, ["page", "payload", "orgId"], null);

  // get list of userOrgs from session
  const currentUserOrgs = get(state, ["session", "settings", "userOrgs"], []);

  // if we have a current org id, find org data
  if (currentOrgId)
    currentOrg = currentUserOrgs.find(({ id }) => id === currentOrgId);

  // get user data from session
  const currentUser = get(state, ["session", "settings", "user"], null);

  const pageType =
    !isNil(currentUser) && !currentUser.acceptedTos
      ? "TOS"
      : state.location.type;

  return {
    user: currentUser,
    pageType: pageType,
    organization: currentOrg,
  };
};

export default hot(module)(connect(mapStateToProps)(BlumiraApp));
