import { BaseModel } from "./BaseModel";
import BQSchema from "../schema/BQ";
import Codex from "@blumira/blu_constants";
import Schema from "../schema";

export const DATA_TYPE_MAP = {
  STRING: "string",
  INT64: "number",
  FLOAT64: "number",
  TIMESTAMP: "string",
  BOOL: "boolean",
  BYTES: "string",
};

//The conditional check was added because typeof Codex is string when running Jest tests.
//It is possible Jest doesn't handle git submodules well and some tweaking in Jest config is needed.
if (typeof Codex === "object" && "onLoad" in Codex) {
  Codex.onLoad().then(() => {
    Object.keys(Codex.constant.columns.bq).forEach((columnName) => {
      const column = Codex.constant.columns.bq[columnName];
      const colSchema = {};
      if (column.data_mode === "REPEATED") {
        colSchema["type"] = "array";
        colSchema["items"] = { type: DATA_TYPE_MAP[column.data_type] };
      } else {
        colSchema["type"] = DATA_TYPE_MAP[column.data_type];
      }
      if (column.data_type === "TIMESTAMP") {
        colSchema["format"] = "date-time";
      }
      if (column.data_type === "BYTES") {
        colSchema["format"] = "bytes";
      }
      BQSchema.properties[columnName] = colSchema;
    });
    Schema.removeSchema(BQSchema["$id"]);
    Schema.addSchema(BQSchema);
  });
}

export class BQ extends BaseModel {
  schema = "/bq";
  constructor(params) {
    return super(params, "/bq");
  }
}
