// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { func, oneOfType, shape, string } from "prop-types";

// ** Components **********************
import { MinimalButton } from "../Button";
import Calendar from "./Calendar";
import { ArrowIcon } from "../Icon";

// ** Style ***************************
import { CalendarContainer, FlexRowLayout, NavigationLayout } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Date selection component
//
//  ** Remarks
//  Datepicker date selection step
//
//  ** Props
//  @param currentMonth {obj} - current month moment object
//  @param hovered {obj} - current day that is being hovered over moment object
//  @param onHover {func} - toggles hovered day
//  @param onSelectDay {func} - calendar day selection on click
//  @param rangeEnd {obj} - end of range moment object
//  @param rangeStart {obj} - start of range moment object
//  @param toggleMonth {func} - iterates over months on click
//
const DateSelection = ({
  currentMonth,
  hovered,
  onHover,
  onSelectDay,
  rangeEnd,
  rangeStart,
  renderDate,
  toggleMonth,
}) => (
  <FlexRowLayout>
    <NavigationLayout left="8px" rotate="true">
      <MinimalButton onClick={() => toggleMonth("prev")}>
        <ArrowIcon height="12px" />
      </MinimalButton>
    </NavigationLayout>
    <CalendarContainer>
      <Calendar
        current={currentMonth}
        end={rangeEnd}
        hovered={hovered}
        onHover={onHover}
        onSelectDay={onSelectDay}
        renderDate={renderDate}
        start={rangeStart}
      />
    </CalendarContainer>
    <NavigationLayout right="28px">
      <MinimalButton onClick={() => toggleMonth("next")}>
        <ArrowIcon height="12px" />
      </MinimalButton>
    </NavigationLayout>
  </FlexRowLayout>
);

// ** Proptypes ***********************
DateSelection.propTypes = {
  currentMonth: string,
  hovered: shape({}),
  onHover: func,
  onSelectDay: func,
  rangeEnd: oneOfType([shape(), string]),
  rangeStart: oneOfType([shape(), string]),
  renderDate: func.isRequired,
  toggleMonth: func,
};

export default DateSelection;
