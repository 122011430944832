import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

/*
Component
*/

const WorkerSelect = (props) => {
  const { options, defaultValue, onSelect } = props;

  const handleChange = (selectedOption) => {
    onSelect(selectedOption.value);
  };

  const defaultOption = () =>
    options.find((option) => option.value === defaultValue);

  return (
    <Select
      className="sensor-devices-select"
      onChange={handleChange}
      options={options}
      defaultValue={defaultOption()}
    />
  );
};

WorkerSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

WorkerSelect.defaultProps = {
  defaultValue: "",
  options: [],
};

export default WorkerSelect;
