import React from "react";
import PropTypes from "prop-types";

import BluBarGraph from "./common/BluBarGraph";

/*
Constants
*/

const BAR_COLOR = "#f47560"; // hsl(9, 87%, 67%) rgb(244, 117, 96)
const BAR_COLOR_DARKER_1_6 = "#8a4236"; // hsl(9, 44%, 38%) rgb(138, 66, 54)
const TOOLTIP_TEXT = "events";

/*
Main compenent
*/

const IntrudersGraph = ({ labelField, rawData, valueField }) => (
  <BluBarGraph
    labelField={labelField}
    rawData={rawData}
    valueField={valueField}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    colors={[BAR_COLOR]}
    tooltipText={TOOLTIP_TEXT}
  />
);

IntrudersGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

IntrudersGraph.defaultProps = {
  rawData: [],
};

export default IntrudersGraph;
