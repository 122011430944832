const workflowStepSchema = {
  $id: "/step",
  type: "object",
  properties: {
    id: { type: "string", readOnly: true },
    question: { type: "string" },
  },
  required: [],
  additionalProperties: false,
};

export default workflowStepSchema;
