import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const priorityColors = {
  1: "red",
  2: "#FDE03A",
  3: "gray",
};

const isDarkMode = () => {
  return cookies.get("theme") === "shadow";
};

export default ({ data, extra, legendClick, findings }) => {
  const findingAnnotations = findings.map((f) => {
    return {
      x: new moment(f.created).unix() * 1000,
      strokeDashArray: 0,
      borderColor: priorityColors[f.priority],
      label: {
        borderColor: priorityColors[f.priority],
        style: {
          color: "#000",
          background: priorityColors[f.priority],
        },
        text: `P${f.priority}`,
      },
    };
  });

  const options = {
    theme: {
      mode: isDarkMode() ? "dark" : "light",
    },
    chart: {
      background: isDarkMode() ? "#19202B" : "#FFF",
      id: "chart2",
      type: "line",
      height: 400,
      toolbar: {
        autoSelected: "pan",
      },
      events: {
        legendClick,
      },
    },
    stroke: {
      width: 3,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      datetimeUTC: true,
      labels: {
        formatter: function (value) {
          return moment(value).format("MM/DD h:mmA");
        },
      },
    },
    extra,
    legend: {
      offsetX: -30,
      offsetY: 100,
      position: "right",
      fontSize: "16px",
      fontWeight: 400,
      itemMargin: {
        horizontal: 15,
        vertical: 15,
      },
      formatter: (seriesName, opts) => {
        return `${seriesName} (${opts.w.config.extra[seriesName]})`;
      },
      labels: {
        colors: cookies.get("theme") === "shadow" ? "white" : "black",
      },
      markers: {
        width: 12,
        height: 12,
        radius: 1,
        offsetX: -10,
      },
    },
    annotations: {
      xaxis: findingAnnotations,
    },
  };

  return (
    <div id="wrapper">
      <div id="chart-line2">
        <ReactApexChart
          options={options}
          series={data}
          type="line"
          height={400}
        />
      </div>
    </div>
  );
};
