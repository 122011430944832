import React from "react";
import PropTypes from "prop-types";

// Question mark with styles
const FormFieldHelp = ({ targetId }) => (
  <span className="blumira-form-help control-label">
    <i className="fa fa-question-circle" aria-hidden="true" id={targetId} />
  </span>
);

// PropTypes
FormFieldHelp.propTypes = {
  targetId: PropTypes.string,
};

FormFieldHelp.defaultProps = {
  targetId: "",
};

export default FormFieldHelp;
