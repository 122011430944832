// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { useState, useEffect } from "react";
import { find } from "lodash";

// ** Material UI *********************

import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// ** Components **********************
import { PrimaryButton } from "views/Components/Button";
import Datepicker from "views/Components/Datepicker";
import Dropdown from "views/Components/Dropdown";
import { DefaultIcon } from "views/Components/Icon";
import ListReorder from "views/Components/ListReorder";
import { DefaultSelect } from "views/Components/Select";

// ** Features ************************
import SearchFilters from "views/Features/SearchFilters";

// ** Lib *****************************
import formatOptions from "utils/lib/formatOptions";

import DropdownHeader from "./DropdownHeader";

// ** Style ***************************
import { Container, LogType, OverflowContainer, Row, Wrapper } from "./style";

const useStyles = makeStyles((theme) => ({
  distinctButton: {},
}));

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Search results form
//
//  ** Remarks
//  Advanced form for search results container
//
//  ** Props
//
const SearchResultsForm = (props) => {
  const [distinctCounts, setDistinctCounts] = useState(props.distinctCounts);

  const classes = useStyles();

  useEffect(() => {
    setDistinctCounts(props.distinctCounts);
  }, [props.distinctCounts]);

  const onAddOrRemoveLogType = (id, value) => {
    const { onAddOrRemove, queryFilters } = props;

    queryFilters.map((filter) => {
      if (
        filter.fieldName.value === "type" &&
        filter.fieldValue.indexOf(value.name) < 0
      ) {
        const newValue = filter.fieldValue.replace("]", "").replace("[", "");
        filter.fieldValue = `${newValue},"${value.name}"`;
        filter.fieldValue = `[${filter.fieldValue}]`;
      } else if (
        filter.fieldName.value === "type" &&
        filter.fieldValue.indexOf(value.name) > -1
      ) {
        let newValue = filter.fieldValue.replace(`"${value.name}"`, "");
        if (filter.fieldValue.indexOf(`"${value.name}",`) > -1) {
          newValue = filter.fieldValue.replace(`"${value.name}",`, "");
        }
        filter.fieldValue = newValue;
      }
    });

    onAddOrRemove(id, value);
  };

  const {
    columns,
    dataChanged,
    dataSource,
    datum,
    onAddOrRemove,
    onChange,
    onRefresh,
    onSubmit,
    queryFilters,
    rangeEnd,
    rangeStart,
    refreshData,
    searchColumns,
    selectedColumns,
    selectedLogTypes,
    timeFormat,
    toggleFlag,
    user,
  } = props;
  const cols = selectedLogTypes
    ? searchColumns && searchColumns.columns
    : columns;

  return (
    <Wrapper>
      <Row>
        <Datepicker
          formatChange={onChange}
          onChange={(id, dat) => {
            onChange(id, dat);
            onChange("dataChanged", true);
          }}
          open
          rangeEnd={rangeEnd ? rangeEnd : datum && datum.configuration.timeEnd}
          rangeStart={
            rangeStart ? rangeStart : datum && datum.configuration.timeStart
          }
          timeFormat={timeFormat}
          userTimezone={user.lastLogin.timezone}
          width="586px"
        />
      </Row>
      {selectedLogTypes && (
        <Row>
          <Dropdown
            btnContents={
              <DropdownHeader
                icon="logtype"
                type="logType"
                value={
                  selectedLogTypes ? selectedLogTypes : "No logtype applied"
                }
              />
            }
          >
            {() => (
              <Container>
                <DefaultSelect
                  id="selectedLogTypes"
                  onChange={(id, val) => {
                    onAddOrRemoveLogType(
                      "selectedLogTypes",
                      find(searchColumns && searchColumns.logTypes, [
                        "id",
                        val.value,
                      ])
                    );
                    onChange("dataChanged", true);
                    toggleFlag("formDataLoaded");
                  }}
                  options={
                    selectedLogTypes
                      ? formatOptions(
                          searchColumns.logTypes.filter(
                            (option) =>
                              !find(selectedLogTypes, ["id", option.id])
                          ),
                          ["display_name", "name"],
                          "id"
                        )
                      : []
                  }
                  placeholder="Select logtypes..."
                  top="100%"
                />
                {selectedLogTypes && (
                  <OverflowContainer
                    isMax={selectedLogTypes && selectedLogTypes.length > 5}
                    margin="16px 0px 0px"
                    maxHeight="160px"
                  >
                    {selectedLogTypes.map((log) => {
                      const displayName = log.display_name
                        ? log.display_name
                        : log.name;

                      return (
                        <LogType
                          key={displayName}
                          onClick={() =>
                            onAddOrRemoveLogType("selectedLogTypes", log)
                          }
                          type="button"
                        >
                          {displayName}
                          <DefaultIcon height="10px" icon="ex" />
                        </LogType>
                      );
                    })}
                  </OverflowContainer>
                )}
              </Container>
            )}
          </Dropdown>
        </Row>
      )}
      <Row>
        <Container padding="8px 8px 32px 8px">
          <SearchFilters
            columns={cols}
            dataSource={datum ? datum.dataSource : "finding"}
            keys={
              selectedColumns &&
              selectedColumns.filter((column) =>
                distinctCounts ? column.name !== "Timestamp" : column
              )
            }
            filters={
              queryFilters &&
              queryFilters.filter((filter) =>
                distinctCounts ? filter.fieldName.label !== "Timestamp" : filter
              )
            }
            onChange={(id, value) => {
              onChange(id, value);
              onChange("dataChanged", true);
            }}
          />
        </Container>
      </Row>
      {!find(selectedColumns, ["data_mode", "REPEATED"]) && (
        <Row>
          <Button
            className={classes.distinctButton}
            size="small"
            startIcon={
              distinctCounts ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
            }
            onClick={() => {
              setDistinctCounts(!distinctCounts);
              onChange("dataChanged", true);
            }}
          >
            Distinct counts {distinctCounts ? "on" : "off"}
          </Button>
        </Row>
      )}
      <Row>
        <Container>
          <DefaultSelect
            id="selectedColumns"
            onChange={(id, val) => {
              onAddOrRemove("selectedColumns", find(cols, ["id", val.value]));
              onChange("dataChanged", true);
              toggleFlag("formDataLoaded");
            }}
            options={formatOptions(
              dataSource
                ? cols &&
                    cols.filter(
                      (option) =>
                        !find(selectedColumns, ["name", option.name]) &&
                        option.dataSource === dataSource
                    )
                : cols &&
                    (cols.filter(
                      (option) => !find(selectedColumns, ["name", option.name])
                    ) ||
                      []),
              ["display_name", "name"],
              "id"
            )}
            placeholder="Select columns..."
            top="100%"
          />
          {selectedColumns && (
            <OverflowContainer
              isMax={selectedColumns && selectedColumns.length > 5}
              margin="16px 0px 0px"
              maxHeight="160px"
            >
              <ListReorder
                onChange={(list) => onChange("selectedColumns", list)}
                onAddOrRemove={onAddOrRemove}
                deleteItem={onChange}
              >
                {selectedColumns.filter((column) =>
                  distinctCounts ? column.name !== "Timestamp" : column
                )}
              </ListReorder>
            </OverflowContainer>
          )}
        </Container>
      </Row>
      <PrimaryButton
        disabled={refreshData}
        onClick={() =>
          !dataChanged
            ? onRefresh()
            : onSubmit({
                selectedColumns,
                distinctCounts,
                queryFilters,
                rangeEnd,
                rangeStart,
                timeFormat,
              })
        }
        margin="8px 0px 0px"
        width="100%"
      >
        {refreshData ? "Refreshing data..." : "Search"}
      </PrimaryButton>
    </Wrapper>
  );
};

export default SearchResultsForm;
