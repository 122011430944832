import { createSelector } from "reselect";

import { orgIdSelector } from "./orgIdSelector";
import _ from "lodash";
const settingsSelector = (state) => state.session.settings;

const getOrgName = (settings = {}, orgId) => {
  const userOrgs = _.get(settings, ["userOrgs"], []);
  const currentOrg = userOrgs.find(_.matches({ id: orgId }));

  return _.isNil(currentOrg) ? "" : currentOrg.name;
};

export const orgNameSelector = createSelector(
  [settingsSelector, orgIdSelector],
  getOrgName
);
