import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { submitWorkflowQuestion } from "../../../../redux/actions/Findings";
import "./style.sass";
import ErrorBoundary from "../../ErrorBoundary";
import { sanitize } from "../../../../lib/helpers";
import Logger from "../../../../lib/logger";

import Button from "@mui/material/Button";
import { Alert } from "@mui/material";

const logger = Logger("Workflow");

const styles = {
  assignmentContainer: {
    color: "black",
    margin: "10px 0",
  },
};

const getActiveQuestion = (questions, question = questions[0]) => {
  // Return first unanswered question in questions array.
  if (!question.answered) {
    return question;
  }

  const selectedAnswer = question.answers.find((a) => a.selected);

  // Return the last answered question if the last question has been answered
  // but the finding has still not closed or moved jurisdiction.
  if (!selectedAnswer || !selectedAnswer.next) {
    return question;
  }

  // For an answered question, retrieve the next question and repeat.
  const nextQuestion = questions.find((q) => q.id === selectedAnswer.next);
  if (!nextQuestion) {
    const error = Error("Next question does not exist. Malformed workflow.");
    error.name = "MissingQuestionError";
    throw error;
  }

  return getActiveQuestion(questions, nextQuestion);
};

const AnsweredQuestion = (question) => {
  const answer = question.answers.find((i) => i.selected);

  return (
    <div className="question answered">
      <div className="questionRow active">
        <div className="questionKey">{question.num}</div>
        <div
          className="questionText"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={sanitize(question.text, {
            html: true,
            safe: true,
          })}
        />
      </div>
      <label htmlFor={question.id} className="answered">
        <div className="answer">
          <input
            id={question.id}
            type="radio"
            checked
            readOnly
            className="answer"
          />
          <span className="answerText">{answer.text}</span>
        </div>
        <div className="answerLabel">{answer.label}</div>
      </label>
    </div>
  );
};

const Workflow = ({
  superadmin,
  findingId,
  answerQuestion,
  workflow: { completed, questions, type },
  pageRole,
  userIsOwner,
  onChangeOwner,
  onWorkflowError,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [pendingAnswer, setPendingAnswer] = useState({});

  useEffect(() => {
    // if a question is answered ensure a user
    // can submit their next response
    setIsSubmitting(false);
  }, [questions]);

  if (!questions[0]) {
    return <span />;
  }

  let question;
  try {
    question = getActiveQuestion(questions);
  } catch (err) {
    const message =
      `Finding with id ${findingId} has a ${type}` +
      "workflow pointing to a missing question";

    logger.error(message, err);

    onWorkflowError();
    return <span />;
  }

  const answeredQuestions = questions.filter((q) => q.answered);
  const num = answeredQuestions.length + 1;

  const isChecked = (answer) => {
    return pendingAnswer === answer;
  };

  const handleAnswerQuestion = async (question, type) => {
    // disable button to prohibit dup clicks
    setIsSubmitting(true);
    await answerQuestion(question, type, pendingAnswer).then(() => {
      setIsSubmitting(false);
      if (onSubmit) {
        onSubmit();
      }
    });
  };

  const handleSelfAssign = () => {
    onChangeOwner();
  };

  const selectAnswer = ({ answer }) => {
    setPendingAnswer(answer);
  };

  if (completed) {
    return (
      <ErrorBoundary>
        <div className="workflow completed">
          {answeredQuestions.map((q, k) => (
            <AnsweredQuestion {...q} key={k} num="" />
          ))}
        </div>
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <div className="workflow">
        {!userIsOwner && !superadmin && (
          <div style={styles.assignmentContainer}>
            <Alert
              severity={"info"}
              action={
                <Button
                  color={"inherit"}
                  size={"small"}
                  onClick={() => handleSelfAssign()}
                >
                  ASSIGN TO ME
                </Button>
              }
            >
              Assign yourself as the {` ${pageRole}`} to start this workflow
            </Alert>
          </div>
        )}
        {answeredQuestions.map((q, k) => (
          <AnsweredQuestion {...q} key={k} num={k + 1} />
        ))}
        <div className="question">
          <form>
            <div className={`${userIsOwner ? "" : "disabledWorkflow"}`}>
              <div className={`questionRow ${pendingAnswer ? "active" : ""}`}>
                <div className="questionKey">{num}</div>
                <div
                  className="questionText"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={sanitize(question.text, {
                    html: true,
                    safe: true,
                  })}
                />
              </div>
            </div>
            {question.answers.map((answer, key) => {
              const customId = `${findingId}#${question.id}#${key + 1}`;
              return (
                <label key={customId} htmlFor={customId}>
                  <div className={`${userIsOwner ? "" : "disabledWorkflow"}`}>
                    <div className="answer">
                      <input
                        id={customId}
                        type="radio"
                        name={question.id}
                        className="answer"
                        disabled={!userIsOwner}
                        checked={isChecked(answer)}
                        onChange={() => selectAnswer({ answer })}
                      />
                      <span className="answerText">{answer.text}</span>
                    </div>
                    <div className="answerLabel">{answer.label}</div>
                  </div>
                </label>
              );
            })}
            {pendingAnswer.label && (
              <button
                type="button"
                disabled={isSubmitting}
                className="submitAnswer"
                onClick={() => handleAnswerQuestion(question, type)}
              >
                {pendingAnswer.label || "Submit Answer"}
              </button>
            )}
          </form>
          {!userIsOwner && <div className={`workflowOverlay`} />}
        </div>
      </div>
    </ErrorBoundary>
  );
};

Workflow.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
  findingId: PropTypes.string.isRequired,
  onWorkflowError: PropTypes.func.isRequired,
  pageRole: PropTypes.string.isRequired,
  userIsOwner: PropTypes.bool.isRequired,
  workflow: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  superadmin:
    (state.session.settings.user && state.session.settings.user.superadmin) ||
    false,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  answerQuestion: (question, type, pendingAnswer) => {
    return dispatch(
      submitWorkflowQuestion({
        answerText: pendingAnswer.text,
        findingId: ownProps.findingId,
        questionId: question.id,
        oldFinding: ownProps.finding,
        type,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
