import React from "react";
import PropTypes from "prop-types";

const SVGContainer = (props) => (
  <div
    className={props.className || ""}
    style={{
      ...props.styles,
      width: props.width || "100%",
      display: "inline-block",
      verticalAlign: props.verticalAlign || "top",
    }}
  >
    <svg
      style={{
        display: "inline-block",
        position: "relative",
        width: "100%",
      }}
      viewBox={props.viewBox || "0 0 100 100"}
      preserveAspectRatio="xMinYMin meet"
    >
      {props.children}
    </svg>
  </div>
);

SVGContainer.propTypes = {
  styles: PropTypes.shape({}),
  className: PropTypes.string,
  width: PropTypes.string,
  verticalAlign: PropTypes.string,
  viewBox: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.shape({}, PropTypes.null),
  ]),
};

SVGContainer.defaultProps = {
  styles: {},
  className: "",
  width: "100%",
  verticalAlign: "top",
  viewBox: "0 0 100 100",
  children: [],
};

export default SVGContainer;
