import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { TextField, Typography, Tooltip, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Alert from "@mui/material/Alert";
import Schema from "lib/schema";

import FilterField, {
  defaultOperators,
} from "views/Components/Reporting/FilterField";
import ActionDialog from "views/Components/ActionDialog";
import moment from "moment-timezone";
import _ from "lodash";

const PREFIX = "AllowlistingFilter";
const classes = {
  filter: `${PREFIX}-filter`,
  triangleBorder: `${PREFIX}-triangleBorder`,
  triangleCutout: `${PREFIX}-triangleCutout`,
  fieldWrap: `${PREFIX}-fieldWrap`,
  filterContainer: `${PREFIX}-filterContainer`,
  andInnerWrapper: `${PREFIX}-andInnerWrapper`,
  andBorderBox: `${PREFIX}-andBorderBox`,
  andBox: `${PREFIX}-andBox`,
  actionWrap: `${PREFIX}-actionWrap`,
  actionText: `${PREFIX}-actionText`,
  actionTextSpan: `${PREFIX}-actionTextSpan`,
  createdText: `${PREFIX}-createdText`,
  emailText: `${PREFIX}-emailText`,
  editMenuWrap: `${PREFIX}-editMenuWrap`,
  filterFieldWrap: `${PREFIX}-filterFieldWrap`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.filter}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 10,
    margin: 20,
  },
  [`& .${classes.triangleBorder}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: "relative",
    width: "100%",
  },
  [`& .${classes.triangleCutout}`]: {
    width: 15,
    height: 15,
    border: `1px solid ${theme.palette.divider}`,
    borderTop: 0,
    borderLeft: 0,
    transform: "rotate(45deg)",
    position: "absolute",
    bottom: -8,
    left: 30,
    backgroundColor: `${theme.palette.background.paper}`,
  },
  [`& .${classes.fieldWrap}`]: {
    padding: 20,
  },
  [`& .${classes.filterContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.andInnerWrapper}`]: {
    display: "flex",
    flexDirection: "column",
  },
  [`& .${classes.andBorderBox}`]: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    width: 20,
    height: 100,
    position: "relative",
    marginLeft: 10,
  },
  [`& .${classes.andBox}`]: {
    color: `${theme.palette.text.primary}`,
    backgroundColor: `${theme.palette.background.paper}`,
    padding: 5,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: -20,
  },
  [`& .${classes.actionWrap}`]: {
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  [`& .${classes.actionText}`]: {
    marginBottom: 0,
  },
  [`& .${classes.actionTextSpan}`]: {
    paddingLeft: 20,
    fontSize: 16,
  },
  [`& .${classes.createdText}`]: {
    textAlign: "center",
    fontSize: 12,
  },
  [`& .${classes.emailText}`]: {
    display: "block",
  },
  [`& .${classes.editMenuWrap}`]: {
    width: 144,
    display: "flex",
    justifyContent: "flex-end",
  },
  [`& .${classes.filterFieldWrap}`]: {
    width: "100%",
  },
}));

const operators = {};

Object.keys(defaultOperators).forEach((k) => {
  if (!k.includes("regex")) {
    operators[k] = defaultOperators[k];
  }
});

const AllowlistingFilter = (props) => {
  const [isEditing, setIsEditing] = useState(true);
  const [filterDeleteOpen, setFilterDeleteOpen] = useState(false);
  const [filterName, setFilterName] = useState(props.model.name || "");
  const [andCount, setAndCount] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.model.name !== filterName) {
      props.model.set({ name: filterName.trim() });
    }
  }, [filterName]);

  useEffect(() => {
    countAnds();
  }, []);

  useEffect(() => {
    if (props.model && props.users) {
      if (props.model.modifiedBy) {
        const user = props.users.find((u) => u.id === props.model.modifiedBy);

        if (user) {
          setUserEmail(user.email);
        } else {
          setUserEmail("Administrator");
        }
      } else {
        const user = props.users.find((u) => u.id === props.model.createdBy);

        if (user) {
          setUserEmail(user.email);
        } else {
          setUserEmail("Administrator");
        }
      }
    }
  }, [props.users, props.model, props.model.modified]);

  useEffect(() => {
    if (props.model.id) {
      setIsEditing(false);
    }
  }, []);

  const toggleEditFilter = () => {
    setIsEditing(!isEditing);
  };

  const handleDeleteFilter = () => {
    props.model.delete().then(() => {
      if (props.canEdit) {
        props.getAllowlistFilters();
        setFilterDeleteOpen(false);
        toggleEditFilter();
      } else {
        props.reload(true);
        setFilterDeleteOpen(false);
        props.closeModal();
      }
    });
  };

  const handleFilterValueChange = (editorValues) => {
    const queryParams = props.model.queryParams;
    const param = queryParams.find((p) => p.uuid === editorValues.uuid);
    if (param) {
      param.field = editorValues.field;
      param.operator = editorValues.operator;
      param.negate = editorValues.negate;

      const schema = Schema.getSchema("/bq").schema;
      const field = schema.properties[param.field];

      if (typeof editorValues.value === "string") {
        param.value = editorValues.value.trim();
      } else if (Array.isArray(editorValues.value)) {
        let values;
        if (field.type === "number") {
          values = editorValues.value.map((v) => parseInt(v));
        } else {
          values = editorValues.value.map((v) => v.trim());
        }

        param.value = values;
      } else {
        param.value = editorValues.value;
      }

      props.model.set({ queryParams });
    }
  };

  const deleteFilterCondition = (editorValues) => {
    const filteredFilterList = props.model.queryParams.filter(
      (filter) => filter.uuid !== editorValues.uuid
    );
    props.model.set({ queryParams: filteredFilterList });
    countAnds();
  };

  const countAnds = () => {
    const andArray = [];
    const countEnd = props.model.queryParams.length - 1;

    for (let i = 0; i < countEnd; i++) {
      andArray.push(i);
    }

    setAndCount(andArray);
  };

  const handleSaveFilter = (model) => {
    setErrorMessage("");

    const func = model.id ? "update" : "create";
    const modelValidation = model.validate();
    if (modelValidation !== true) {
      const { message = "" } = modelValidation[0];
      setErrorMessage(
        `Oops, check your filter credentials. Details: ${message}`
      );
      return;
    }

    model[func]()
      .then(() => {
        toggleEditFilter();
      })
      .catch((e) => {
        setErrorMessage(`Oops, check your filter credentials. Details: ${e}`);
      });
  };

  const renderUserAndDateText = () => {
    if (props.model.modified) {
      return (
        <Typography className={classes.createdText}>
          Modified{" "}
          {moment
            .utc(props.model.modified)
            .tz(moment.tz.guess(true))
            .format("lll z")}{" "}
          <span className={classes.emailText}>by {userEmail}</span>
        </Typography>
      );
    } else if (props.model.created) {
      return (
        <Typography className={classes.createdText}>
          Created{" "}
          {moment
            .utc(props.model.created)
            .tz(moment.tz.guess(true))
            .format("lll z")}{" "}
          <span className={classes.emailText}>by {userEmail}</span>
        </Typography>
      );
    } else {
      return null;
    }
  };

  const filterActions = [
    {
      icon: CancelIcon,
      tooltip: "Delete Filter Condition",
      actionType: "delete",
      onClick: (editorValues) => {
        setErrorMessage("");
        deleteFilterCondition(editorValues);
      },
    },
  ];

  const lastFilterActions = [
    {
      icon: CancelIcon,
      tooltip: "Delete Filter Condition",
      actionType: "delete",
      onClick: (editorValues) => {
        setErrorMessage("");
        deleteFilterCondition(editorValues);
      },
    },
    {
      icon: AddCircleIcon,
      tooltip: "Add Filter Condition",
      actionType: "add",
      onClick: (editorValues) => {
        setErrorMessage("");
        props.model.set({
          queryParams: [
            ...props.model.queryParams,
            { field: props.allowListFields[0].field },
          ],
        });
        countAnds();
      },
    },
  ];

  const editMenuActions = [
    {
      icon: DeleteIcon,
      tooltip: "Delete",
      actionType: "delete",
      onClick: () => {
        setErrorMessage("");
        setFilterDeleteOpen(true);
      },
    },
    {
      icon: ClearIcon,
      tooltip: "Cancel",
      actionType: "cancel",
      onClick: (model) => {
        setErrorMessage("");
        if (model.id) {
          model.reset();
          setFilterName(props.model.name);
          props.getAllowlistFilters();
          countAnds();
          toggleEditFilter();
        } else {
          props.getAllowlistFilters();
        }
      },
    },
    {
      icon: CheckCircleIcon,
      tooltip: "Save",
      actionType: "save",
      onClick: handleSaveFilter,
    },
  ];

  const isIconButtonDisabled = (action) => {
    if (action.actionType === "delete") {
      return !props.model.id ? true : false;
    }
  };

  const isFieldInFieldsList = props.fields.filter((field) =>
    props.model.queryParams.some((param) => field.field === param.field)
  );

  return (
    <Root style={{ width: "100%" }}>
      <div className={classes.filter} datacy={"detectionFilter"}>
        <div className={classes.triangleBorder}>
          <div className={classes.triangleCutout}></div>
          <div className={classes.fieldWrap}>
            <TextField
              id="outlined-required"
              label="Name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              fullWidth
              disabled={!isEditing}
              datacy={"detectionFilterNameField"}
            />
            <div className={classes.filterContainer}>
              <div>
                <div className={classes.andInnerWrapper}>
                  {andCount.length > 0 &&
                    andCount.map((id) => (
                      <div className={classes.andBorderBox} key={id}>
                        <div className={classes.andBox}>AND</div>
                      </div>
                    ))}
                </div>
              </div>
              <div className={classes.filterFieldWrap}>
                {props.model.queryParams.map((param, index) => (
                  <FilterField
                    key={`${param.uuid}-allowlist`}
                    operators={operators}
                    actions={
                      props.model.queryParams.length - 1 === index
                        ? lastFilterActions
                        : filterActions
                    }
                    control={param}
                    namespace="/bq"
                    fields={
                      // If the filter has an id and at least one of the field options from the model is missing from the evidence, then add those missing fields to the list
                      props.model?.id &&
                      isFieldInFieldsList.length !==
                        props.model.queryParams.length
                        ? _.uniqBy(
                            [
                              // Make sure no duplicate field values show in list
                              ...props.fields,
                              {
                                field: props.model.queryParams[index].field,
                                display_name:
                                  props.model.queryParams[index].field,
                              },
                            ],
                            "field"
                          )
                        : // If the filter has an id and all of the field options from the model are in the evidence OR if you are creating a new filter, then just show the list from the evidence
                        (props.model?.id &&
                            isFieldInFieldsList.length ===
                              props.model.queryParams.length) ||
                          props.model
                        ? props.fields
                        : []
                    }
                    onChange={handleFilterValueChange}
                    elevation={0}
                    disabled={!isEditing}
                    componentStyles={"allowlist"}
                    conditionsLength={props.model.queryParams.length}
                    errorMessage={errorMessage}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actionWrap}>
          <p className={classes.actionText}>
            Action{" "}
            <span className={classes.actionTextSpan}>
              Do not generate finding
            </span>
          </p>
          {renderUserAndDateText()}
          {isEditing ? (
            <div className={classes.editMenuWrap}>
              {editMenuActions &&
                editMenuActions.map((action) => (
                  <Tooltip key={action.tooltip} title={action.tooltip}>
                    <div>
                      <IconButton
                        color={"default"}
                        onClick={() => action.onClick(props.model)}
                        disabled={isIconButtonDisabled(action)}
                        datacy={`detectionFilter-${action.actionType}Btn`}
                      >
                        <action.icon />
                      </IconButton>
                    </div>
                  </Tooltip>
                ))}
            </div>
          ) : props.canEdit ? (
            <div className={classes.editMenuWrap}>
              <Tooltip key={"Edit Filter"} title={"Edit Filter"}>
                <IconButton
                  color={"default"}
                  onClick={() => toggleEditFilter(props.model)}
                  datacy={"detectionFilterEditBtn"}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <div className={classes.editMenuWrap}>
              <Tooltip key={"Delete Filter"} title={"Delete Filter"}>
                <IconButton
                  color={"default"}
                  onClick={() => setFilterDeleteOpen(true)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        {!props.canEdit && (
          <div>
            <Alert
              icon={<InfoOutlinedIcon fontSize="inherit" />}
              severity="info"
            >
              Editing the conditions of a filter is only available from a
              finding. You can delete the filter here though.
            </Alert>
          </div>
        )}
      </div>
      <ActionDialog
        open={filterDeleteOpen}
        title={"Are you sure you want to delete this filter?"}
        description={
          "Deleting this filter will permanently remove it from this rule.  A brand new filter would need to be created again should you change your mind after the fact."
        }
        actions={[
          {
            title: "Cancel",
            action: () => setFilterDeleteOpen(false),
          },
          {
            title: "Yes, delete filter",
            action: handleDeleteFilter,
            variant: "contained",
            datacy: "detectionFilterDeleteFilterModalBtn",
          },
        ]}
      />
    </Root>
  );
};

export default AllowlistingFilter;
