import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "AddAgentDialog";
export const classes = {
  dialog: `${PREFIX}-dialog`,
  dialogHeader: `${PREFIX}-dialogHeader`,
  close: `${PREFIX}-close`,
  dialogContent: `${PREFIX}-dialogContent`,
  rowOuterContainer: `${PREFIX}-rowOuterContainer`,
  installationKeySelectorInnerContainer: `${PREFIX}-installationKeySelectorInnerContainer`,
  dialogActions: `${PREFIX}-dialogActions`,
  errorAlert: `${PREFIX}-errorAlert`,
  errorAction: `${PREFIX}-errorAction`,
  circularNumberContainer: `${PREFIX}-circularNumberContainer`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialog}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  [`& .${classes.dialogHeader}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
  },
  [`& .${classes.dialogContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: `0 ${theme.shape.padding}px`,
  },
  [`& .${classes.rowOuterContainer}`]: {
    display: "flex",
    alignItems: "flex-start",
  },
  [`& .${classes.installationKeySelectorInnerContainer}`]: {
    "& p": {
      fontSize: 12,
      marginBottom: 5,
    },
  },
  [`& .${classes.dialogActions}`]: {
    padding: theme.shape.padding,
  },
  [`& .${classes.errorAlert}`]: {
    width: "100%",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.errorAction}`]: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    marginRight: theme.shape.padding,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.error.main,
    },
  },
  [`& .${classes.circularNumberContainer}`]: {
    width: 30,
    height: 30,
    borderRadius: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.shape.padding,
    border: `2px solid ${theme.palette.text.primary}`,
    "& p": {
      margin: 0,
      fontWeight: "bold",
    },
  },
}));
