import { createSelector } from "reselect";

const selectLocation = (state) => state.location;

const getOrgId = (location) => {
  const { payload = {} } = location;

  const { orgId = "" } = payload;

  return orgId;
};

export const orgIdSelector = createSelector([selectLocation], getOrgId);
