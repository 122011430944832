import React from "react";
import PropTypes from "prop-types";
import Loading from "../../Components/Loading";
import EmptyVisualization from "../../Components/EmptyVisualization";
import StatColumn from "../../Components/Charts/StatColumn";
import StackedBarChart from "../../Components/Charts/StackedBarChart";

const TrendsGraph = ({
  trendData,
  graphData,
  type,
  colorPallete,
  handleRetry,
}) => {
  const trendIsLoading = trendData.loading || !trendData.firstLoadComplete;
  const graphIsLoading = graphData.loading || !graphData.firstLoadComplete;

  const trendIsEmpty = !trendData.loading && trendData.dataIsEmpty;
  const graphIsEmpty = !graphData.loading && graphData.dataIsEmpty;

  const trendHasError = !!trendData.error;
  const graphHasError = !!graphData.error;

  const vizIsLoading = trendIsLoading || graphIsLoading;
  const vizIsEmpty = trendIsEmpty || graphIsEmpty;
  const vizHasError = trendHasError || graphHasError;

  const vizHtml = (
    <div className="chart-container suspects-opened-resolved">
      <span className="stats">
        <StatColumn
          data={trendData.visualizationData[type]}
          colorPallete={colorPallete}
        />
      </span>
      <span className="barchart">
        <StackedBarChart
          data={graphData.visualizationData[type]}
          colorPallete={colorPallete}
          showSingleBar
        />
      </span>
    </div>
  );

  return (
    <React.Fragment>
      {vizIsLoading && <Loading />}

      {(vizIsEmpty || vizHasError) && (
        <EmptyVisualization isError={vizHasError} handleRetry={handleRetry}>
          {vizHtml}
        </EmptyVisualization>
      )}

      {!vizIsLoading && !vizIsEmpty && !vizHasError && <div>{vizHtml}</div>}
    </React.Fragment>
  );
};

TrendsGraph.propTypes = {
  trendData: PropTypes.shape({}).isRequired,
  graphData: PropTypes.shape({}).isRequired,
  type: PropTypes.oneOf(["threats", "suspects"]).isRequired,
  colorPallete: PropTypes.number,
  handleRetry: PropTypes.func.isRequired,
};

TrendsGraph.defaultProps = {
  colorPallete: 0,
};

export default React.memo(TrendsGraph);
