// FORMATTING HELPERS
import { formatData, formatTopData } from "./helpers";

// IMAGES
import azure from "../../../images/azure.png";
import firewall from "../../../images/firewall.png";
import microsoft365Logo from "../../../images/microsoft365Logo.png";
import activeDirectory from "../../../images/microsoftActiveDirectory.png";

// TODO: UPDATE WITH PRODUCTION VALUES
export const popularReports = [
  {
    id: 1003,
    data: [],
    sorted: true,
    image: activeDirectory,
    field: "subject_account_name",
    formatFunction: (results) => formatData(results, "subject_account_name"),
  },
  {
    id: 1007,
    data: [],
    sorted: true,
    image: activeDirectory,
    field: "user",
    formatFunction: (results) => formatData(results, "user"),
  },
  {
    id: 1050,
    data: [],
    image: microsoft365Logo,
    field: "user",
    formatFunction: (results) => formatTopData(results, "user"),
  },
  {
    id: 2002,
    data: [],
    imageWidth: 70,
    image: firewall,
    field: "src_ip",
    formatFunction: (results) => formatData(results, "src_ip"),
  },
  {
    id: 2001,
    data: [],
    imageWidth: 70,
    image: firewall,
    field: "src_ip",
    formatFunction: (results) => formatData(results, "src_ip"),
  },
  {
    id: 1009,
    data: [],
    imageWidth: 60,
    image: azure,
    field: "client_name",
    formatFunction: (results) => formatData(results, "client_name"),
  },
];
