import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

import _ from "lodash";

class LocationField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.locations = {};
    _.each(props.options, (o) => {
      this.locations[o.value] = o.label;
    });
  }

  removeLocation(locationId) {
    this.props.handleChange(null, locationId, true);
  }

  render() {
    const {
      name,
      label,
      required,
      placeholder,
      values = {},
      disabled = false,
      multiple = false,
      errors = {},
      touched = {},
      handleBlur,
      handleChange,
      classes = "",
      validation = {},
      formatting = {},
      options,
      hidden = false,
    } = this.props;
    const uniqueId = _.uniqueId();
    return (
      <div className={hidden ? "hidden" : ""}>
        <div
          className={`field ${errors[name] && touched[name] ? "error" : ""}`}
        >
          <label
            htmlFor={`${name}__${uniqueId}`}
            className="control-label"
            title={errors[name]}
          >
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
          <div className={classes || "value"}>
            {_.map(values[name] || [], (l) => (
              <Row key={l} className="location">
                <Col xs="9" className={classes || "value"}>
                  {_.get(this.locations, l, l)}
                </Col>
                <Col xs="1">
                  <i
                    role="button"
                    title="Remove"
                    tabIndex={-1}
                    className="fas fa-times"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      this.removeLocation(l);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </div>
          <select
            id={`${name}__${uniqueId}`}
            name={name}
            className={classes || "control-item"}
            value={values[`${name}_available_locations`]}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={(c) => {
              this.field = c;
            }}
            validation={{ required, ...validation }}
            formatting={{ ...formatting }}
            disabled={disabled}
            multiple={multiple}
            placeholder={placeholder}
          >
            {_.map(options, (o, i) => (
              <option key={i} value={o.value}>
                {o.label}
              </option>
            ))}
          </select>
          {errors[name] && touched[name] ? (
            <span className="error-message">Required</span>
          ) : null}
        </div>
      </div>
    );
  }
}

LocationField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  values: PropTypes.shape({}),
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  options: PropTypes.instanceOf(Array),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  validation: PropTypes.shape({}),
  formatting: PropTypes.shape({}),
  hidden: PropTypes.bool,
};

LocationField.defaultProps = {
  label: "",
  required: false,
  placeholder: "",
  values: {},
  disabled: false,
  multiple: false,
  errors: {},
  touched: {},
  options: [],
  classes: "",
  validation: {},
  formatting: {},
  hidden: false,
};

export default LocationField;
