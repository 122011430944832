import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { stack } from "d3-shape";
import { axisBottom, axisLeft } from "d3-axis";
import { scaleBand, scaleLinear, scaleOrdinal } from "d3-scale";
import { select } from "d3-selection";

import SVGContainer from "./SVGContainer";

const colors = {
  0: ["#602b5c", "#2c3e50", "#0f6291"],
  1: ["#c0392b", "#e67e22", "#f1c40f"],
};

class StackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setAxisAndLegend = this.setAxisAndLegend.bind(this);
    this.renderFilter = this.renderFilter.bind(this);
  }

  componentDidMount() {
    this.setAxisAndLegend();
  }

  getColor(d) {
    d.count = _.get(this.data, `${d.id}.count`, 0);
    const color = this.colorScale(d.count);
    return color;
  }

  setAxisAndLegend() {
    select(this.xAxis).call(axisBottom(this.x));
    select(this.yAxis).call(axisLeft(this.y).ticks(null, "s"));
  }

  filterBy(event) {
    this.setState({ filterBy: event.target.value });
  }

  renderFilter() {
    return (
      <div
        className="filter"
        style={{
          width: "100%",
          display: "inline-block",
          verticalAlign: "top",
          textAlign: "left",
          position: "relative",
        }}
      >
        <select
          style={{
            fontSize: "11px",
            fontWeight: "300",
            padding: "10px",
            paddingRight: "20px",
            width: "100%",
            boxSizing: "border-box",
          }}
          onChange={this.filterBy.bind(this)}
        >
          <option value="priority">By Month</option>
          <option value="priority">By Week</option>
        </select>
        <i
          className="fas fa-caret-down"
          style={{
            pointerEvents: "none",
            position: "absolute",
            right: "10px",
            top: "10px",
          }}
        />
      </div>
    );
  }

  render() {
    const { data = [], colorPallete = 0, showSingleBar } = this.props;
    const width = 740;
    const height = 250;
    const x = scaleBand().rangeRound([0, width]).paddingInner(0.05).align(0.1);

    const y = scaleLinear().rangeRound([height, 0]);

    const z = scaleOrdinal().range(colors[colorPallete]);

    const keys = ["P1", "P2", "P3"];
    const xDomain = [];
    let yMax = 0;
    const bars = [[], []];
    _.each(data, (d) => {
      xDomain.push(d.label);
      _.each(d.data, (b, i) => {
        const sum = _.sum(
          _.map(_.without(Object.keys(b), "label"), (k) => b[k])
        );
        if (sum > yMax) yMax = sum;
        bars[i].push(b);
      });
    });
    const yDomain = [0, yMax];
    const zDomain = keys;
    x.domain(xDomain);
    y.domain(yDomain).nice();
    z.domain(zDomain);

    const data1 = stack().keys(keys)(bars[0]);
    const data2 = stack().keys(keys)(bars[1]);

    this.x = x;
    this.y = y;
    this.z = z;

    let firstBarWidthDivisor = 3.0;
    let firstBarXOffset = 30;
    if (showSingleBar) {
      firstBarWidthDivisor = 2.0;
      firstBarXOffset = 48;
    }

    return (
      <div className="chart">
        <span
          className="legend"
          style={{
            display: "inline-block",
            width: "4vw",
            paddingTop: "50px",
          }}
        >
          {/* this.renderFilter() */}
          <SVGContainer width="9vw" viewBox="0 0 100 100">
            <g transform="translate(0, 20)">
              {_.map(keys, (k, i) => (
                <React.Fragment key={i}>
                  <rect width="20" height="20" x="0" y={i * 25} fill={z(k)} />
                  <text
                    x="30"
                    y={i * 25 + 15}
                    fill="#333"
                    style={{ fontSize: "12px" }}
                  >
                    {k}
                  </text>
                </React.Fragment>
              ))}
            </g>
          </SVGContainer>
        </span>
        <SVGContainer
          className="stacked-bar"
          width="calc(100% - 4vw)"
          styles={{ padding: "0 20px" }}
          viewBox={`0 0 ${width + 40} ${height + 50}`}
        >
          <g transform="translate(10, 20)">
            {_.map(data1, (d, i) => (
              <g key={i} fill={z(d.key)}>
                {_.map(d, (di, j) =>
                  !di[1] ? (
                    false
                  ) : (
                    <rect
                      key={j}
                      x={x(di.data.label) + firstBarXOffset}
                      y={y(di[1])}
                      height={y(di[0]) - y(di[1])}
                      width={x.bandwidth() / firstBarWidthDivisor}
                    />
                  )
                )}
              </g>
            ))}
            {!showSingleBar &&
              _.map(data2, (d, i) => (
                <g
                  key={i}
                  fill={z(d.key)}
                  style={{
                    opacity: 0.5,
                  }}
                >
                  {_.map(d, (di, j) => (
                    <rect
                      key={j}
                      x={x(di.data.label) + 85}
                      y={y(di[1])}
                      height={y(di[0]) - y(di[1])}
                      width={x.bandwidth() / 3.0}
                    />
                  ))}
                </g>
              ))}
            <g
              className="axis"
              transform={`translate(20, ${height})`}
              fontSize="14"
              ref={(c) => {
                this.xAxis = c;
              }}
            />
            <g
              className="axis"
              transform="translate(20, 0)"
              ref={(c) => {
                this.yAxis = c;
              }}
            />
          </g>
        </SVGContainer>
      </div>
    );
  }
}

StackedBarChart.propTypes = {
  colorPallete: PropTypes.number,
  data: PropTypes.instanceOf(Array),
  showSingleBar: PropTypes.bool,
};

StackedBarChart.defaultProps = {
  colorPallete: 0,
  data: [],
  showSingleBar: false,
};

export default StackedBarChart;
