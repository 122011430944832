const constantsSchema = {
  $id: "/constants",
  title: "Constants",
  type: "object",
  properties: {
    category: { type: "array" },
    jurisdiction: { type: "array" },
    resolution: { type: "array" },
    status: { type: "array" },
    type: { type: "array" },
    uiAction: { type: "array" },
  },
};

export default constantsSchema;
