import { API } from "./API";
import { auth } from "../auth";

const DEFAULT_OPTIONS = {
  baseUrl: "settings",
};

export class SettingsAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  get() {
    const params = [auth.person_id];
    return super.get({ params });
  }

  getZendeskJWT() {
    const params = ["zendeskJwt", auth.person_id];
    return super.get({ params });
  }
}
