import React from "react";

import filesize from "filesize";
import moment from "moment-timezone";
import _ from "lodash";

import Codex from "@blumira/blu_constants";

import Link from "redux-first-router-link";

import { MARKETS } from "../views/Pages/OrganizationPageView";

import Logger from "../lib/logger";
const logger = Logger("utils/index");

/**
 * Retrieves the hash parameters from the URL and returns them as an object.
 * Specifically handles the "search" parameter and decodes it.
 *
 * @returns {Object} An object containing the parsed hash parameters.
 */
export const getPageHashParams = () => {
  const hashString = window.location.hash.replace("#", "");
  const params = {};

  if (hashString) {
    const searchIndex = hashString.indexOf("search=");
    if (searchIndex !== -1) {
      const searchValue = hashString.slice(searchIndex + 7);
      const nextParamIndex = searchValue.indexOf("&start=");
      if (nextParamIndex !== -1) {
        // Extract the search value and decode it
        params.search = decodeURIComponent(
          searchValue.slice(0, nextParamIndex)
        );
        const remainingParams = searchValue.slice(nextParamIndex + 1);
        const keyValuePairs = remainingParams.split("&");
        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split("=");
          if (key !== "search") {
            params[key] = value;
          }
        });
      } else {
        // No additional parameters, decode the entire search value
        params.search = decodeURIComponent(searchValue);
      }
    }
  }

  return params;
};

/**
 * Sets the hash parameters in the URL based on the provided params object.
 * Encodes the "search" parameter to handle special characters.
 *
 * @param {Object} params - An object containing the hash parameters to set.
 */
export const setPageHashParams = (params) => {
  const hashParts = [];

  Object.entries(params).forEach(([key, value]) => {
    if (key === "search") {
      // Encode the search value
      hashParts.push(`search=${encodeURIComponent(value)}`);
    } else {
      hashParts.push(`${key}=${value}`);
    }
  });

  const hashString = hashParts.join("&");
  window.location.hash = hashString;
};

export function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = "";
  for (let i = 0; i < values.length; i += 1) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  const lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map((line) => line.replace(/^\s+/gm, ""))
    .join(" ")
    .trim();
}

// Takes a number (of bytes), and returns it as a string in KiB, MiB, etc.
export const formatBytes = (num, precision = 2) => {
  if (num === "" || num === undefined) {
    return "";
  }

  return filesize(num, {
    locale: true,
    round: precision,
    standard: "iec",
  });
};

// A constant used in formatNumber
const JEDEC_TO_CUSTOM_SYMBOLS = {
  B: " ",
  kB: "K",
  MB: "M",
  GB: "G",
  TB: "T",
  PB: "P",
  EB: "E",
  ZB: "Z",
  YB: "Y",
};
// Takes a number (not byte number), and returns it as a string
// with JEDEC unit abbreviations mapped to custom ones
export const formatNumber = (num, precision = 2) => {
  if (!num && num !== 0) {
    return "";
  }

  return filesize(num, {
    base: 10,
    round: precision,
    symbols: JEDEC_TO_CUSTOM_SYMBOLS,
  });
};

// Takes a string of decimal digits.
// Returns the string's truncation to 'maxLength' digits, and a units suffix.
export const formatNumberCustom = (inputStr, maxLength = 9) => {
  const sizeNames = ["K", "M", "G", "T", "P"];

  const out = {
    value: inputStr || "",
    suffix: "",
  };

  for (let i = 0; i < sizeNames.length; i += 1) {
    if (out.value.length <= maxLength) {
      return out;
    }

    out.value = out.value.slice(0, -3);
    out.suffix = sizeNames[i];
  }

  return out;
};

export const composePath = (event, nodes = []) => {
  if (nodes && nodes.length > 0) {
    const element = nodes[nodes.length - 1];

    if (element.parentElement.tagName === "HTML") {
      return nodes.concat(element.parentElement);
    }

    return composePath(event, nodes.concat([element.parentElement]));
  }

  return composePath(event, [event.target]);
};

export const composedPath = (event) => {
  if (event.composedPath && typeof event.composedPath === "function") {
    return event.composedPath();
  }

  if (event.path && Array.isArray(event.path)) {
    return event.path;
  }

  return composePath(event);
};

/*
Returns true if `evt.target` is inside `domElt`.
Can be replaced with evt.composedPath() check for everything except IE.
*/
export const isInside = (evt, domElt) => {
  if (!evt) {
    return false;
  }

  let current = evt.target;

  while (current) {
    if (current === domElt) {
      return true;
    }
    current = current.parentNode;
  }

  return false;
};

// Returns a formatted date string in UTC if guessTz is falsy,
// or in the user's timezone if guessTz is truthy
export const formatDate = ({ date, formatStr, guessTz = false }) => {
  let momentObj;

  try {
    momentObj = moment.utc(date);
  } catch (err) {
    logger.error("formatDate received invalid date", err);

    return "";
  }

  if (!momentObj.isValid()) {
    return "";
  }

  if (guessTz) {
    return momentObj.tz(moment.tz.guess()).format(formatStr);
  }

  return momentObj.format(formatStr);
};

export const formatDateUserTz = (date) =>
  formatDate({
    date,
    formatStr: "lll z",
    guessTz: true,
  });

const NO_EPS_TEXT = "Not available";

// Returns events-per-second as a string
export const getEps = ({ logsTotal, windowEnd, windowStart }) => {
  let end;
  let start;

  try {
    end = moment.utc(windowEnd);
    start = moment.utc(windowStart);
  } catch (err) {
    logger.error(
      "Sensor support EventsPerSecond received invalid time window",
      err
    );
    return NO_EPS_TEXT;
  }

  const delta = end.diff(start, "seconds");

  const eps = Math.round((logsTotal || 0) / delta);

  return eps;
};

export const isNumber = (num) => /^-?[\d.]+(?:e-?\d+)?$/.test(num);

const fallbackCopyTextToClipboard = (text) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
};
export const getValue = _.curry((defaultValue, value) => {
  return value ?? defaultValue;
});

export const isOrganizationChildOfNFR = (organization = {}) => {
  return organization.market === 20 && !!organization.parentId;
};

export const hasLegacyLicense = (org) => {
  return org.config.license === "ADVANCED" || org.config.license === "CLOUD";
};

export const renderModifiedCell = (column, model) => {
  var timeValue = model[column.field];
  if (!timeValue) {
    timeValue = model["created"];
  }
  return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
};

export const getUrlForNewTab = (urlParamData) => {
  const {
    location: { origin },
  } = window;
  const { id1, orgId } = urlParamData;

  const topLevel = urlParamData.topLevel
    ? urlParamData.topLevel
    : urlParamData.toplevel;
  const secondLevel = urlParamData.secondLevel
    ? urlParamData.secondLevel
    : urlParamData.secondlevel;

  // start url array with base params
  const urlArray = [origin, orgId];

  if (topLevel) urlArray.push(topLevel);

  if (secondLevel) urlArray.push(secondLevel);

  // id1 for viewing details i.e. from sensors of findings
  if (id1) urlArray.push(id1);

  // build array with params
  return urlArray.join("/");
};

export const isValidURL = (str) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

/**
 * @isValidHostname
 *
 */
export const isValidHostname = (str) => {
  const hostnamePattern =
    /(?:[^:]+:\/\/)?(?:[^@/\n]+@)?([^/.]+[.][^/.]+(?:\.[^/.]+)?)(?:[/?#]|$)/i;
  const match = str.match(hostnamePattern);
  return match && match[1] ? true : false;
};

export const DEFAULT_FEATURES = {
  SIEM_STARTER: ["LOGSHIPPING", "BLOCKLISTS"],
  SIEM_STARTER_COMPLIANCE: ["LOGSHIPPING", "BLOCKLISTS"],
  SIEM_ENDPOINT: ["LOGSHIPPING", "BLOCKLISTS", "HONEYPOT"],
  XDR: ["LOGSHIPPING", "BLOCKLISTS", "AUTOBOT", "HONEYPOT"],
  XDR_TRIAL: ["AUTOBOT", "LOGSHIPPING"],
};

/**
 * @replaceTransmissionVariables
 * Given a transmission type finds activity string in blu_constants
 * and optionally replaces vars with keyword arguments
 *
 * @param {string} transmissionType - Describes the type of activity on the transmission model.
 * @param {array} [kwargs] - Array of keyword argument objects taken directly from transmission model.
 * @param {array} [linkArgs] - Array of link arguments for interactive (clickable) elements in activity string
 * (each entry contains an 'idToDisplay' property corresponding to the object being linked i.e. a finding id and a 'linkTo' property for routing).
 * @returns {string} Returns a corresponding activity string for display.
 *
 */
export const replaceTransmissionVariables = ({
  transmissionType,
  linkArg,
  kwargs = {},
  valMap = {},
}) => {
  // TODO: replace with actual location [if different] in blu_constants when completed
  let transmissionTypeString =
    Codex.language.transmission && Codex.language.transmission[transmissionType]
      ? Codex.language.transmission[transmissionType].template
      : "Unknown activity.";

  let replacedTransmissionTypeString = transmissionTypeString.replace(
    /\{.*?\}/g,
    (match) => {
      // Extract the variable name without curly braces
      var variableName = match.slice(1, -1);

      const kwargPropertyToReplace = Object.keys(kwargs).find(
        (kwargKey) => kwargKey === variableName
      );

      let replacementToReturn = match;

      // if match found in kwargs, return the corresponding value;
      // otherwise return the original match
      if (kwargPropertyToReplace) {
        replacementToReturn =
          (valMap[variableName] &&
            valMap[variableName][kwargs[kwargPropertyToReplace]]) ||
          kwargs[kwargPropertyToReplace];
      }

      return replacementToReturn;
    }
  );

  if (linkArg)
    return (
      <div>
        {replacedTransmissionTypeString}{" "}
        <Link to={linkArg["linkTo"]}>{linkArg["linkText"]}</Link>
      </div>
    );

  return <div>{replacedTransmissionTypeString}</div>;
};

export const getPendoVisitorDataFromUser = (user = {}, organization = {}) => {
  const currentOrgRoles = user.orgRoles.filter(
    ({ orgId }) => orgId === organization.id
  );
  const currentOrgRoleNames = currentOrgRoles.map(
    ({ roleName = "" }) => roleName
  );

  let vistorDataToReturn = {
    id: user.id,
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    environment: process.env.NODE_ENV,
  };

  // for each unique role name under the current org
  // set a truthy bool property on the visitor data
  if (currentOrgRoleNames) {
    _.uniq(currentOrgRoleNames).forEach((role) => {
      vistorDataToReturn[role] = true;
    });
  }

  return vistorDataToReturn;
};

export const getPendoAccountDataFromOrg = (organization = {}) => {
  let activeLicense;
  const now = moment().utc().toISOString();
  const currentOrgScheduledLicense = _.get(
    organization,
    ["config", "license_scheduled"],
    {}
  );
  const currentOrgLicense = _.get(organization, ["config", "license"], "");

  /*
    IF an org has an active Trial scheduled license and their current license value is Free,
    THEN send the Trial license value to Pendo.
    OTHERWISE, send their current license value to Pendo.

    Checking the scheduled license and the license value will make sure that people
    who are upgrade from their trial to XDR before their trial is done will not still
    have the Trial value sent over the upgraded license value of the full XDR license.
  */
  if (!_.isEmpty(currentOrgScheduledLicense) && currentOrgLicense === "FREE") {
    if (
      currentOrgScheduledLicense.start < now &&
      currentOrgScheduledLicense.end > now
    ) {
      activeLicense = currentOrgScheduledLicense.license_name;
    }
  } else {
    activeLicense = currentOrgLicense;
  }

  const currentOrgMarketValue = _.get(organization, ["market"], 0);
  const currentOrgMarketLabel = _.find(MARKETS, {
    value: currentOrgMarketValue,
  })?.label;

  return {
    id: organization.id,
    name: organization.name,
    license: activeLicense,
    market: currentOrgMarketLabel,
  };
};
