import { BaseModel } from "./BaseModel";

export class Query extends BaseModel {
  schema = "/query";
  constructor(params = {}) {
    return super(params, "/query");
  }

  /** Do not allow id to be saved in a query
   *  id is typically a display level value that should be added
   *  if the use case requires the user to click through to a unique item
   */
  create() {
    var hasId = false;
    if (this.params.resultParams.outputFields.includes("id")) {
      this.params.resultParams.outputFields =
        this.params.resultParams.outputFields.filter((p) => p !== "id");
      hasId = true;
    }
    // when creating a new query, there should not be an existing id
    // if a saved query/report was loaded first, and then you attempt to save your changes as a new query
    // then the original saved query id will be present so we'll want to clear that out first
    // to ensure we save a NEW query/report and don't try to overwrite the existing one
    this.params.queryParams = this.params.queryParams.filter(
      (qp) => qp.field !== "query.id"
    );
    const request = super.create();
    if (hasId) {
      this.params.resultParams.outputFields.unshift("id");
    }
    return request;
  }

  /** Do not allow id to be saved in a query
   *  id is typically a display level value that should be added
   *  if the use case requires the user to click through to a unique item
   */
  update() {
    var hasId = false;
    if (this.params.resultParams.outputFields.includes("id")) {
      this.params.resultParams.outputFields =
        this.params.resultParams.outputFields.filter((p) => p !== "id");
      hasId = true;
    }
    const request = super.update();
    if (hasId) {
      this.params.resultParams.outputFields.unshift("id");
    }
    return request;
  }
}
