import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment-timezone";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DialogContent, DialogTitle, Tooltip } from "@mui/material";
import SimpleTable from "views/Components/SimpleTable";
import { orgNameSelector } from "../../../../selectors/orgNameSelector";
import { classes, StyledDialog } from "./styles";

const MSPInvoicesDialog = (props) => {
  const renderCreatedCell = (column, model) => {
    const timeValue = model[column.field];

    if (!timeValue) {
      return "Unknown date";
    }
    return moment.utc(timeValue).format("ll");
  };

  const renderTotalCell = (column, model) => {
    const priceValue = model[column.field];

    if (!priceValue) {
      return "Unknown price";
    }

    if (String(priceValue).includes(".")) {
      return "$" + Number(priceValue).toFixed(2);
    }

    return "$" + Number(priceValue);
  };

  const renderStatusCell = (column, model) => {
    const statusValue = model[column.field];

    if (!statusValue) {
      return (
        <>
          Unpaid{" "}
          <Tooltip
            disableInteractive
            placement={"bottom-start"}
            title={
              "Updating payment status can take a few days after payment has been sent"
            }
            overlap={"rectangular"}
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlinedIcon className={classes.iconButton} />
          </Tooltip>
        </>
      );
    }

    return "Paid";
  };

  const handleDownloadInvoice = (invoice) => {
    window.open(invoice.einvoicing_url + "invoice.pdf", "_blank");
  };

  return (
    <StyledDialog open={props.open}>
      <div className={classes.dialogHeader}>
        <DialogTitle>Invoices</DialogTitle>
        <CloseIcon className={classes.close} onClick={props.toggle} />
      </div>
      <DialogContent className={classes.dialogContent}>
        <p className={classes.dialogDescriptionText}>
          Invoices are Generated monthly, in arrears. You are billed your
          minimum, or your actual usage if above your minimum. Your invoice will
          show the total amount for all your Blumira usage during that month.
        </p>
        {props.invoices?.length ? (
          <SimpleTable
            isSearchHidden
            data={props.invoices}
            columns={[
              {
                title: "Generated",
                field: "date",
                renderValue: renderCreatedCell,
              },
              {
                title: "Invoice amount",
                field: "subtotal",
                renderValue: renderTotalCell,
              },
              {
                title: "Status",
                field: "is_paid",
                renderValue: renderStatusCell,
              },
            ]}
            actions={[
              {
                icon: DownloadIcon,
                tooltip: "Download invoice",
                onClick: (event, model) => handleDownloadInvoice(model),
              },
            ]}
          />
        ) : (
          <p className={classes.dialogDescriptionText}>
            No invoices available.
          </p>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

MSPInvoicesDialog.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  invoices: PropTypes.array,
};

MSPInvoicesDialog.defaultProps = {
  open: false,
  invoices: [],
  toggle: () => {
    console.warn("No toggle function provided for MSPInvoicesDialog");
  },
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const { orgId: mspOrgId } = location.payload;

  const orgName = orgNameSelector(state);

  return {
    orgName,
    orgId: mspOrgId,
    user: session.settings.user,
    isSuperadmin: session?.settings?.user?.superadmin,
  };
};

export default connect(mapStateToProps, null)(MSPInvoicesDialog);
