import React from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";

import { Dialog, DialogTitle, DialogContent } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PREFIX = "RunTestDialog";
const classes = {
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  title: `${PREFIX}-title`,
  link: `${PREFIX}-link`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  close: `${PREFIX}-close`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.dialogContent}`]: {
    padding: 30,
  },
  [`& .${classes.title}`]: {
    fontSize: 18,
    fontWeight: "bold",
  },
  [`& .${classes.link}`]: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
  },
  [`& .${classes.list}`]: {
    paddingLeft: 20,
    marginBottom: 0,
  },
  [`& .${classes.listItem}`]: {
    paddingBottom: theme.shape.padding,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
  },
}));

export const RunTestDialog = (props) => {
  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <div className={classes.dialogHeaderContainer}>
        <DialogTitle>Run a test</DialogTitle>
        <CloseIcon
          className={classes.close}
          onClick={props.onClose}
          datacy={"closeButton"}
        />
      </div>
      <DialogContent className={classes.dialogContent}>
        <p className={classes.title}>Microsoft 365 email forwarding test</p>
        <p>
          Blumira will generate a finding anytime an email forwarding rule is
          set up for an external domain. Following the steps below should
          generate a new finding and is a quick and easy way to ensure Blumira
          is working.
        </p>
        <ol className={classes.list}>
          <li className={classes.listItem} style={{ paddingTop: 10 }}>
            Log in to{" "}
            <a
              href={"https://outlook.office365.com/mail/"}
              target={"_blank"}
              rel={"noopener noreferrer"}
              className={classes.link}
            >
              https://outlook.office365.com/mail/ <OpenInNewIcon />
            </a>
          </li>
          <li className={classes.listItem}>
            Right click any email, go to “Advanced actions” and select “Create
            rule”
          </li>
          <li className={classes.listItem}>Click “More options”</li>
          <li className={classes.listItem}>
            Click the “Add an action” drop menu and select “Forward to”
          </li>
          <li className={classes.listItem}>
            Enter any external email address and click “Save”
          </li>
        </ol>
        <p style={{ paddingTop: 12 }}>
          A new finding and email notification should be generated within a few
          minutes.
        </p>
      </DialogContent>
    </StyledDialog>
  );
};

RunTestDialog.propTypes = {};

RunTestDialog.defaultProps = {};

export default connect(null, null)(RunTestDialog);
