import React, { useState, useEffect } from "react";

import {
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { StyledDialog, classes } from "./saConnectorInfoDialogStyles";

const CloudModuleDialog = (props) => {
  const [searchText, setSearchText] = useState("");
  const [filteredConnectors, setFilteredConnectors] = useState([]);

  useEffect(() => {
    if (props.connectors.length) setFilteredConnectors(props.connectors);
  }, [props.connectors]);

  useEffect(() => {
    const newFilteredConnectors = props.connectors.filter((connector) => {
      return Object.values(connector).some((value) => {
        return (
          typeof value === "string" &&
          value.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    });

    setFilteredConnectors(newFilteredConnectors);
  }, [searchText]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <DialogTitle>
        <div className={classes.titleContainer}>
          SA-Only Connector Info
          {!props.isLoading && (
            <TextField
              label={"Search"}
              value={searchText}
              onChange={handleSearch}
            />
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        {props.isLoading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
            <p>Loading connector information...</p>
          </div>
        ) : (
          <Grid
            container
            spacing={2}
            className={classes.connectorDetailsOuterContainer}
          >
            {filteredConnectors.map((ccDetail) => {
              return (
                <Grid key={ccDetail.name} item xs={4}>
                  <div className={classes.connectorDetailContainer}>
                    <div className={classes.connectorDetailTitleContainer}>
                      <p className={classes.name}>{ccDetail.name}</p>
                      {ccDetail.doc_link && (
                        <a
                          href={ccDetail.doc_link}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Docs
                        </a>
                      )}
                    </div>
                    <div className={classes.infoContainer}>
                      <p>
                        Release Lifecycle Phase:{" "}
                        {ccDetail.release_life_cycle_phase
                          ? ccDetail.release_life_cycle_phase
                          : "GA"}
                      </p>
                      <p>
                        Integration Level:{" "}
                        {ccDetail.integration_level
                          ? ccDetail.integration_level
                          : ""}
                      </p>
                      <p>
                        Limited to License:{" "}
                        {ccDetail.license_level
                          ? ccDetail.license_level
                          : "N/A"}
                      </p>
                      <p>
                        Limited to License Feature:{" "}
                        {ccDetail.license_chip ? ccDetail.license_chip : "N/A"}
                      </p>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default CloudModuleDialog;
