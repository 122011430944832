import { styled } from "@mui/material/styles";

const PREFIX = "FilterField";
export const reportingClasses = {
  formControl: `${PREFIX}-formControl`,
  rightMargin: `${PREFIX}-rightMargin`,
  topMargin: `${PREFIX}-topMargin`,
  wordButtons: `${PREFIX}-wordButtons`,
  iconButtons: `${PREFIX}-iconButtons`,
  actionButtonWrap: `${PREFIX}-actionButtonWrap`,
  iconButtonWrap: `${PREFIX}-iconButtonWrap`,
  paper: `${PREFIX}-paper`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  operatorWidth: `${PREFIX}-operatorWidth`,
  valueWidth: `${PREFIX}-valueWidth`,
};

export const allowlistClasses = {
  formControl: `${PREFIX}-formControl`,
  rightMargin: `${PREFIX}-rightMargin`,
  topMargin: `${PREFIX}-topMargin`,
  wordButtons: `${PREFIX}-wordButtons`,
  iconButtons: `${PREFIX}-iconButtons`,
  actionButtonWrap: `${PREFIX}-actionButtonWrap`,
  iconButtonWrap: `${PREFIX}-iconButtonWrap`,
  paper: `${PREFIX}-paper`,
  selectFieldWrapper: `${PREFIX}-selectFieldWrapper`,
  valueFieldWrapper: `${PREFIX}-valueFieldWrapper`,
  fieldWidth: `${PREFIX}-fieldWidth`,
  operatorWidth: `${PREFIX}-operatorWidth`,
  valueWidth: `${PREFIX}-valueWidth`,
  errorText: `${PREFIX}-errorText`,
};

export const ReportingFilterField = styled("div")(({ theme }) => ({
  [`& .${reportingClasses.formControl}`]: {
    margin: theme.spacing(1),
  },
  [`& .${reportingClasses.rightMargin}`]: {
    marginRight: theme.shape.padding,
  },
  [`& .${reportingClasses.topMargin}`]: {
    marginTop: -5, // this accounts for the helper text
  },
  [`& .${reportingClasses.wordButtons}`]: {
    marginTop: 8,
  },
  [`& .${reportingClasses.iconButtons}`]: {
    display: "flex",
    alignItems: "center",
    height: 44,
    marginTop: 20,
  },
  [`& .${reportingClasses.actionButtonWrap}`]: {
    display: "flex",
  },
  [`& .${reportingClasses.iconButtonWrap}`]: {
    width: 88,
  },
  [`& .${reportingClasses.paper}`]: {
    display: "flex",
    alignItems: "flex-start",
    padding: `${theme.shape.padding}px`,
  },
  [`& .${reportingClasses.fieldWidth}`]: {
    minWidth: "150px",
  },
  [`& .${reportingClasses.operatorWidth}`]: {
    minWidth: "150px",
  },
  [`& .${reportingClasses.valueWidth}`]: {
    minWidth: "150px",
  },
}));

export const AllowlistFilterField = styled("div")(({ theme }) => ({
  [`& .${allowlistClasses.formControl}`]: {
    margin: theme.spacing(1),
  },
  [`& .${allowlistClasses.rightMargin}`]: {
    marginRight: theme.shape.padding,
  },
  [`& .${allowlistClasses.topMargin}`]: {
    marginTop: -5, // this accounts for the helper text
  },
  [`& .${allowlistClasses.wordButtons}`]: {
    marginTop: 25,
  },
  [`& .${allowlistClasses.iconButtons}`]: {
    display: "flex",
    alignItems: "center",
    height: 44,
    marginTop: 5,
  },
  [`& .${allowlistClasses.actionButtonWrap}`]: {
    display: "flex",
  },
  [`& .${allowlistClasses.iconButtonWrap}`]: {
    width: 88,
  },
  [`& .${allowlistClasses.paper}`]: {
    display: "flex",
    alignItems: "flex-start",
    padding: `${theme.shape.padding}px 0`,
  },
  [`& .${allowlistClasses.selectFieldWrapper}`]: {
    width: "25%",
  },
  [`& .${allowlistClasses.valueFieldWrapper}`]: {
    width: "50%",
  },
  [`& .${allowlistClasses.fieldWidth}`]: {
    width: "97%",
  },
  [`& .${allowlistClasses.operatorWidth}`]: {
    width: "97%",
  },
  [`& .${allowlistClasses.valueWidth}`]: {
    width: "100%",
  },
  [`& .${allowlistClasses.errorText}`]: {
    color: theme.palette.error.main,
  },
  [`& .${allowlistClasses.formControl}`]: {
    width: "100%",
  },
}));
