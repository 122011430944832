import TextField from "./Form/TextField";
import CheckboxField from "./Form/CheckboxField";
import SelectField from "./Form/SelectField";
import ReactSelectField from "./Form/ReactSelectField";
import TextAreaField from "./Form/TextAreaField";
import RadioField from "./Form/RadioField";
import RoleField from "./Form/RoleField";
import LocationField from "./Form/LocationField";
import FlyoutInputGroup from "./Form/FlyoutInputGroup";
import { validate, validateEmail } from "./Form/validators";
import { format } from "./Form/formatters";

const attachRefToFields = (page, c) => {
  if (c && c.props && c.props.name) {
    if (!page.fields) page.fields = {};
    page.fields[c.props.name] = c;
  }
};

export {
  // Fields
  TextField,
  CheckboxField,
  SelectField,
  ReactSelectField,
  TextAreaField,
  RadioField,
  RoleField,
  LocationField,
  FlyoutInputGroup,
  validate,
  format,
  attachRefToFields,
  validateEmail,
};
