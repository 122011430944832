// *****************************************************************************
// Dependencies
// *****************************************************************************
import { createFactory } from "react";

// *****************************************************************************
// withProps function
// *****************************************************************************

const mapProps = (propsMapper) => (BaseComponent) => {
  const factory = createFactory(BaseComponent);
  const MapProps = (props) => factory(propsMapper(props));
  return MapProps;
};

const withProps = (input) => {
  const hoc = mapProps((props) => ({
    ...props,
    ...(typeof input === "function" ? input(props) : input),
  }));
  return hoc;
};

export default withProps;
