// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Calendar styles
// *****************************************************************************

// ** Calendar day ********************
export const Day = styled.td`
  background-color: ${(p) =>
    p.selected
      ? COLOR.blumiraBlue800
      : p.active
      ? COLOR.blumiraBlue400
      : "transparent"};
  border: ${(p) =>
    p.selected
      ? COLOR.blumiraBlue800
      : p.active
      ? `1px solid ${COLOR.blumiraBlue500}`
      : `1px solid ${COLOR.gray100}`};
  color: ${(p) =>
    p.selected ? COLOR.white : p.active ? COLOR.blumiraBlue800 : "auto"};
  cursor: pointer;
  font-size: 12px;
  flex: 1;
  height: 38px;
  text-align: center;
  transition: all 0.2s;
  vertical-align: middle;
  padding: 0px 10px;

  &:hover {
    background-color: ${(p) =>
      p.selected ? COLOR.blumiraBlue800 : COLOR.blumiraBlue400};
    border: ${(p) =>
      p.selected
        ? `1px solid ${COLOR.blumiraBlue800}`
        : `1px solid ${COLOR.blumiraBlue500}`};
    color: ${(p) => (p.selected ? COLOR.white : COLOR.blumiraBlue800)};
  }
`;

// ** Day of week *********************
export const DayOfWeek = styled.th`
  color: ${COLOR.gray800};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  width: 39px;
`;

// ** Day placeholder *****************
export const DayPlaceholder = styled.td`
  height: 38px;
  width: 39px;
`;

// ** Month display *******************
export const Month = styled.span`
  color: ${COLOR.gray900};
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
`;

// ** Layout row **********************
export const Row = styled.tr`
  width: 100%;
`;

// ** Outer container *****************
export const Container = styled.div`
  padding: 0px;
`;

// ** Outer container *****************
export const Table = styled.table`
  border-collapse: collapse !important;
  border-spacing: 0px;
`;

// *****************************************************************************
// Header
// *****************************************************************************

// ** Header custom input *************
export const HeaderInput = styled.input`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  color: ${(p) => (p.color ? p.color : COLOR.blumiraBlue700)};
  font-size: 14px;
  outline: none !important;
  padding: 8px 10px 6px;
  transition: all 0.3s;
  width: 100%;

  ::placeholder {
    color: ${(p) => (p.color ? p.color : COLOR.blumiraBlue700)};
  }

  &:focus {
    border-bottom: 2px solid ${COLOR.blumiraBlue800};
  }
`;

// *****************************************************************************
// Layout
// *****************************************************************************

// ** Datepicker container ************
export const ContainerLayout = styled.div`
  position: relative;
  width: ${(p) => p.width || "100%"};
`;

// ** Timeformat display **************
export const Format = styled.span`
  color: ${(p) => (p.color ? p.color : COLOR.blumiraBlue700)};
  font-size: 10px;
  font-weight: 400;
  margin-right: 8px;
  margin-top: 4px;
  white-space: nowrap;
`;

// ** Flex column *********************
export const FlexColumnLayout = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: ${(p) => p.margin};
  width: 100%;
`;

// ** Flex row ************************
export const FlexRowLayout = styled.div`
  align-items: ${(p) => (p.stretch ? "stretch" : "center")};
  display: flex;
  fill: ${(p) => (p.color ? p.color : COLOR.blumiraBlue600)};
  flex-direction: row;
  justify-content: flex-start;
  padding: ${(p) => p.padding};
  width: 100%;
`;

// ** Datepicker header container *****
export const HeaderLayout = styled.div`
  background-color: ${(p) => {
    if (p.background) {
      return p.background;
    }

    if (!p.background && p.open) {
      return "rgba(105, 180, 255, 0.35)";
    }

    return "rgba(105, 180, 255, 0.25)";
  }};
  border-radius: 3px;
  fill: ${(p) => p.color};
  padding: 0px 10px 0px 0px;
  transition: all 300ms;
  width: ${(p) => p.width || "100%"};

  &:hover {
    background-color: ${(p) =>
      p.background ? p.background : "rgba(105, 180, 255, 0.35)"};
  }
`;

// ** Icon container ******************
export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: ${(p) => p.margin || "0px 4px"};
`;

// ** Navigation container ************
export const NavigationLayout = styled.div`
  fill: ${COLOR.gray200};
  left: ${(p) => p.left};
  position: absolute;
  right: ${(p) => p.right};
  top: 7px;
  transform: ${(p) => (p.rotate ? "rotate(180deg)" : "none")};
  transition: fill 100ms;

  &:hover {
    fill: ${COLOR.gray900};
  }
`;

// ** Submit row **********************
export const SubmitRow = styled.div`
  border-top: 1px solid ${COLOR.gray100};
  display: flex;
  justify-content: flex-end;
  margin: 0px 24px;
  padding: 8px 0px 16px;
`;

// ** Display container ***************
export const DisplayContainer = styled.div`
  background-color: ${COLOR.white};
  border: ${(p) => (p.open ? `1px solid ${COLOR.gray100}` : "none")};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  height: ${(p) => (p.open ? "auto" : "0px")};
  margin-top: 8px;
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};
  overflow: hidden;
  position: absolute;
  right: ${(p) => p.right && "0px"};
  top: 100%;
  z-index: 1000;
`;

// ** Datepicker display container ****
export const DisplayLayout = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
`;

// ** Calendar container **************
export const CalendarContainer = styled.div`
  margin-right: 16px;
`;

// ** Column container ****************
export const ColumnContainer = styled.div`
  margin: 24px 24px 24px 0px;
  position: relative;

  &:first-child {
    margin-left: 24px;
  }

  &:last-child {
    border-left: 1px solid ${COLOR.gray100};
    padding-left: 24px;
  }
`;

// ** Section container ***************
export const SectionContainer = styled.div`
  border-bottom: 1px solid ${COLOR.gray100};
  margin-bottom: 16px;
  padding-bottom: 16px;
  width: 100%;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`;

// ** Section header ******************
export const SectionHeader = styled.span`
  color: ${COLOR.gray900};
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: left;
  white-space: nowrap;
  width: 100%;
`;

// *****************************************************************************
// Suggestions
// *****************************************************************************

// ** Datepicker suggestion ***********
export const Suggestion = styled.button`
  background-color: ${COLOR.blumiraBlue600};
  border: none;
  border-radius: 20px;
  color: ${COLOR.white};
  font-size: 11px;
  margin-right: 8px;
  margin-bottom: 8px;
  outline: none !important;
  padding: 4px 10px 3px;
  white-space: nowrap;

  &:last-child: {
    margin-right: 0px;
  }
`;

// ** Sugested dates layout ***********
export const SuggestedDatesLayout = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-width: 240px;
  width: 100%;
`;

// *****************************************************************************
// Time picker
// *****************************************************************************

// ** Flex container ******************
export const SpaceBetween = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 208px;
  width: 100%;
`;

// ** Scroll container ****************
export const ScrollContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  height: 144px;
  justify-content: flex-start;
  width: 100%;
`;

// ** Scroll column *******************
export const ScrollColumn = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  position: relative;
  width: 42px;
`;

// ** Scroll overflow *****************
export const ScrollOverflow = styled.div`
  border-right: 1px solid ${COLOR.gray100};
  height: 100%;
  overflow-x: hidden;
  width: 32px;

  &:last-child: {
    border-right: none;
  }
`;

// ** Time display ********************
export const Time = styled.span`
  display: block;
  white-space: nowrap;
`;

// ** Scroll container ****************
export const TimeScrollContainer = styled.div`
  height: 100%;
  padding-right: 10px;
  position: absolute;
  width: 42px;
`;

// ** Time picker container ***********
export const TimeContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${COLOR.gray100};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  fill: ${COLOR.gray400};
  flex-wrap: nowrap;
  font-size: 13px;
  margin-top: 6px;
  padding: 0px 4px 4px;
`;

// ** Time select item ****************
export const TimeItem = styled.button`
  align-items: center;
  background-color: ${(p) => (p.active ? COLOR.blumiraBlue400 : "transparent")};
  border: none;
  color: ${(p) => (p.active ? COLOR.blumiraBlue800 : COLOR.gray1000)};
  display: flex;
  font-size: 12px;
  outline: none !important;
  height: 28px;
  justify-content: center;
  transition: background-color 250ms;
  width: 100%;

  &:hover {
    background-color: ${(p) =>
      p.active ? COLOR.blumiraBlue400 : COLOR.gray100};
  }

  &:last-child {
    margin-bottom: 4px;
  }
`;

// ** Toggle container ****************
export const ToggleContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 4px;
`;

// ** Time format toggle **************
export const TimeToggle = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: ${(p) =>
    p.active ? `1px solid ${COLOR.blumiraBlue800}` : "1px solid transparent"};
  color: ${(p) => (p.active ? COLOR.blumiraBlue800 : COLOR.gray400)};
  font-size: 11px;
  font-weight: 400;
  outline: none !important;
  margin-left: 10px;
  padding: 0px 0px 2px 0px;
  transition: color 150ms;

  &:hover {
    color: ${COLOR.blumiraBlue800};
  }
`;

// *****************************************************************************
// Time selection
// *****************************************************************************

// ** Selection label *****************
export const Label = styled.span`
  color: ${COLOR.gray600};
  display: block;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 2px;
`;

// ** Selected Date *******************
export const SelectedDate = styled.span`
  color: ${COLOR.gray900};
  font-size: 13px;
  margin-left: 16px;
  margin-right: 4px;
  white-space: nowrap;
`;

// ** Icon container ******************
export const SelectionIconContainer = styled.div`
  flex-shrink: 0;
  margin: ${(p) => p.margin};
`;
