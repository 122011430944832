import React from "react";
import PropTypes from "prop-types";

import "./CustomTooltip.scss";

const CustomTooltip = (props) => {
  const { color, graphId, xFormatted, yFormatted } = props;

  return (
    <div>
      <div className="logdevs-custom-tooltip">
        <div>
          <div className="logdevs-custom-tooltip-title">
            <i className="fas fa-circle" style={{ color }} />
          </div>
          <strong>{graphId}</strong>
        </div>

        <div>
          <div className="logdevs-custom-tooltip-title">{"x:"}</div>
          <strong>{xFormatted}</strong>
        </div>

        <div>
          <div className="logdevs-custom-tooltip-title">{"y:"}</div>
          <strong>{yFormatted}</strong>
        </div>
      </div>
    </div>
  );
};

CustomTooltip.propTypes = {
  color: PropTypes.string,
  graphId: PropTypes.string,
  xFormatted: PropTypes.string,
  yFormatted: PropTypes.number,
};
CustomTooltip.defaultProps = {
  color: "white",
  graphId: "",
  xFormatted: "",
  yFormatted: "",
};

export default CustomTooltip;
