import React from "react";
import PropTypes from "prop-types";

import ResourcesTable, { caseInsensitiveFilter } from "./common/ResourcesTable";

// ***** remove
const TEST_DATA = [
  { hostname: "h1", subnet: "111.11.11" },
  { hostname: "h2", subnet: "222.22.22" },
  { hostname: "abc2", subnet: "123.22.22" },
].map((elt) => {
  const { hostname = "", subnet = "" } = elt;
  return {
    hostname: <div className="blocking-blocks-table-bold">{hostname}</div>,
    hostnameAccessor: hostname,

    subnet: <div>{subnet}</div>,
    subnetAccessor: subnet,

    accessorValueStrings: [
      String(hostname).toLowerCase(),
      String(subnet).toLowerCase(),
    ],
  };
});
// ***** remove

/*
Component
*/
const DashboardTable = (props) => {
  const { data } = props;

  const columns = [
    {
      Header: "Hostname",
      accessor: "hostnameAccessor",
      filterable: true,
      sortable: true,
      Cell: (row) => row.original.hostname,
      filterMethod: caseInsensitiveFilter(),
      // The filter gets all the rows if `filterAll: true`:
      // filterMethod: (filter, rows) => {
      //   // matchSorter(rows, filter.value, { keys: ["lastName"] });
      // },
      // filterAll: true,
      maxWidth: 195,
      minWidth: 120,
    },
    {
      Header: "Subnet",
      accessor: "subnetAccessor",
      filterable: true,
      sortable: true,
      style: { whiteSpace: "unset", wordBreak: "break-word" },
      Cell: (row) => row.original.subnet,
      filterMethod: caseInsensitiveFilter(),
    },
  ];

  return <ResourcesTable data={data} columns={columns} />;
};

DashboardTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

DashboardTable.defaultProps = {
  data: TEST_DATA, // [],
};

export default DashboardTable;
