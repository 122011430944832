const dataSourceSchema = {
  $id: "/dataSource",
  title: "Data Source",
  type: "object",
  properties: {
    bq: { type: "string" },
    bq_summary: { type: "string" },
    finding: { type: "string" },
  },
};

export default dataSourceSchema;
