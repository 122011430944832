import { BaseModel } from "./BaseModel";
import uuid from "uuid";

export class AllowlistEntry extends BaseModel {
  schema = "/allowlistentry";
  constructor(params) {
    return super(params, "/allowlistentry");
  }

  fields = {
    uuid: () => {
      if (this.params.id) {
        return this.params.id;
      } else if (!this.__uuid) {
        this.__uuid = uuid.v4();
      }
      return this.__uuid;
    },
  };

  set(params, validate) {
    if (params.queryParams) {
      params.queryParams = params.queryParams.map((qp) => {
        if (!qp.uuid) {
          qp.uuid = uuid.v4();
        }
        return qp;
      });
    }
    return super.set(params, validate);
  }

  create() {
    const queryParams = JSON.parse(JSON.stringify(this.params.queryParams));
    this.params.queryParams.forEach((qp) => {
      qp.negate = qp.negate || false;
      delete qp.uuid;
    });

    const request = super.create().then((m) => {
      m.set({ queryParams });
    });
    return request;
  }

  update() {
    const queryParams = JSON.parse(JSON.stringify(this.params.queryParams));
    this.params.queryParams.forEach((qp) => {
      qp.negate = qp.negate || false;
      delete qp.uuid;
    });

    const request = super.update().then((m) => {
      m.set({ queryParams });
    });
    return request;
  }
}
