import {
  RECEIVE_DATA,
  RECEIVE_DATES,
  RECEIVE_ERROR,
  RESET,
} from "../actions/common/GraphAndTable";
import { getInititalState, getReducer } from "./graphAndTableHelpers";
import { getReducer as getReducerHelper, insertError } from "./helpers";

// The Store's keys and their corresponding action type suffixes
const STORE_TO_ACTION = {
  tableByOrg: RECEIVE_DATA,
  tableDates: RECEIVE_DATES,
};

// The Store's initial state
const INITIAL_STATE = getInititalState(STORE_TO_ACTION);

/*
Reducer
*/

/*
Returns an object of the form
{
  tableByOrg: tableByOrgReducer,
  tableDates: tableDatesReducer,
  tableError: tableErrorReducer,
}
*/
export const getTableReducer = (actionTypePrefix) => {
  const reducer = Object.keys(STORE_TO_ACTION).reduce((accum, key) => {
    accum[key] = getReducer({
      actionTypePrefix,
      initialStateKey: key,
      initialState: INITIAL_STATE[key],
      storeToAction: STORE_TO_ACTION,
    });

    return accum;
  }, {});

  reducer.tableError = getReducerHelper({
    actionTypeReceive: `${actionTypePrefix}_${RECEIVE_ERROR}`,
    actionTypeReset: `${actionTypePrefix}_${RESET}`,
    initialState: null,
    insert: insertError,
  });

  return reducer;
};
