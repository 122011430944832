// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { findIndex } from "lodash";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Container hoc
//
//  ** Remarks
//  State and method management for containers
//
//  ** Methods
//  onAddOrRemove - adds/removes item from list stored in state
//  onChange - adds/updates property value stored in state
//  onChangeBlob - adds/updates values stored in state
//  onToggleLoading - toggles isLoading flag stored in state
//  onToggleModal - toggles modal flag and component stored in state
//
function containerWrapper(WrappedComponent) {
  return class ContainerWrapper extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        modalState: {
          component: null,
          open: false,
        },
      };
    }

    // Updates state value on change
    onAddOrRemove(property, value) {
      const list = this.state[property] || [];

      if (findIndex(list, ["id", value.id]) > -1) {
        list.splice(findIndex(list, ["id", value.id]), 1);
      } else {
        list.push(value);
      }

      this.setState((prevState) => ({
        ...prevState,
        [property]: list,
      }));
    }

    // Updates state value on change
    onChange(property, value, callback) {
      this.setState((prevState) => ({
        ...prevState,
        [property]: value,
      }));

      if (callback) {
        callback();
      }
    }

    // Set data to state
    onChangeBlob(data) {
      this.setState((prevState) => ({
        ...prevState,
        ...data,
      }));
    }

    // Loading toggle
    onToggleLoading() {
      this.setState((prevState) => ({
        ...prevState,
        isLoading: !prevState.isLoading,
      }));
    }

    // Modal visibility toggle
    onToggleModal(component) {
      this.setState((prevState) => ({
        ...prevState,
        modalState: {
          component: component || null,
          open: !prevState.modalState.open,
        },
      }));
    }

    toggleFlag(val) {
      this.setState((prevState) => ({
        ...prevState,
        [val]: !prevState[val],
      }));
    }

    render() {
      return (
        <WrappedComponent
          onAddOrRemove={this.onAddOrRemove.bind(this)}
          onChange={this.onChange.bind(this)}
          onChangeBlob={this.onChangeBlob.bind(this)}
          onToggleLoading={this.onToggleLoading.bind(this)}
          onToggleModal={this.onToggleModal.bind(this)}
          toggleFlag={this.toggleFlag.bind(this)}
          {...this.state}
          {...this.props}
        />
      );
    }
  };
}

export default containerWrapper;
