// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Icon container ******************
export const IconContainer = styled.div`
  fill: ${COLOR.gray200};
  margin-left: 4px;
`;

// ** List container ******************
export const ListContainer = styled.div`
  width: 100%;
`;

// ** List item ***********************
export const ListItemContainer = styled.div`
  align-items: center;
  cursor: move;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 4px;
  transition: all 250ms;
  width: 100%;
`;
