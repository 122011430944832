import React, { Component } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

class FlyoutInputGroup extends Component {
  render() {
    const { name, label, required, children, classes } = this.props;
    const uniqueId = _.uniqueId();
    return (
      <React.Fragment>
        {label && (
          <span
            htmlFor={`${name}__${uniqueId}`}
            className="group-control-label"
          >
            {label}
            {required && <span className="text-danger">*</span>}
          </span>
        )}
        <div
          className={`flyout-input-group ${classes}`}
          ref={(c) => {
            this.field = c;
          }}
        >
          <div>{children}</div>
        </div>
      </React.Fragment>
    );
  }
}

FlyoutInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.instanceOf(Array),
  ]),
  classes: PropTypes.string,
};

FlyoutInputGroup.defaultProps = {
  label: "",
  required: false,
  children: [],
  classes: "",
};

export default FlyoutInputGroup;
