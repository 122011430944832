import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import AttacksGraph from "./AttacksGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "attackTypes";
export const GRAPH_TITLE = "Top 10 Attack Types";

/*
Main component
*/

const Attacks = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={AttacksGraph}
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

Attacks.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

Attacks.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default Attacks;
