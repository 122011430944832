import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

const SupportEntry = ({
  columnNumLeft,
  columnNumRight,
  hideOnNoValue,
  label,
  value,
}) => {
  const show =
    !hideOnNoValue || (value !== null && value !== undefined && value !== "");

  return (
    show && (
      <Row>
        <Col xs={columnNumLeft}>
          <span className="support-item-name">{label}</span>
        </Col>
        <Col xs={columnNumRight} className="itemContent">
          <span>{value}</span>
        </Col>
      </Row>
    )
  );
};

SupportEntry.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  hideOnNoValue: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SupportEntry.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  hideOnNoValue: false,
  label: "",
  value: "",
};

export default SupportEntry;
