import React from "react";
import PropTypes from "prop-types";

import BluBarGraph from "./common/BluBarGraph";

/*
Constants
*/

const BAR_COLOR = "#e8a838"; // hsl(38, 79%, 56%) rgb(232, 168, 56)
const BAR_COLOR_DARKER_1_6 = "#835f20"; // hsl(38, 61%, 32%) rgb(131, 95, 32)
const TOOLTIP_TEXT = "events";

/*
Main compenent
*/

const VictimsGraph = ({ labelField, rawData, valueField }) => (
  <BluBarGraph
    labelField={labelField}
    rawData={rawData}
    valueField={valueField}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    colors={[BAR_COLOR]}
    tooltipText={TOOLTIP_TEXT}
  />
);

VictimsGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

VictimsGraph.defaultProps = {
  rawData: [],
};

export default VictimsGraph;
