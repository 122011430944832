import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "ControlBar";
export const classes = {
  controlBarWrap: `${PREFIX}-controlBarWrap`,
  innerWrap: `${PREFIX}-innerWrap`,
  controlBarText: `${PREFIX}-controlBarText`,
  button: `${PREFIX}-button`,
  buttonText: `${PREFIX}-buttonText`,
  disabledButton: `${PREFIX}-disabledButton`,
  disabledButtonText: `${PREFIX}-disabledButtonText`,
  refreshButton: `${PREFIX}-refreshButton`,
  countButton: `${PREFIX}-countButton`,
  checkboxAlert: `${PREFIX}-checkboxAlert`,
  rulesLink: `${PREFIX}-rulesLink`,
  linkButton: `${PREFIX}-linkButton`,
  linkButtonText: `${PREFIX}-linkButtonText`,
};

export const dialogClasses = {
  dialogActions: `${PREFIX}-dialogActions`,
  checkboxOuterContainer: `${PREFIX}-checkboxOuterContainer`,
  checkboxInnerContainer: `${PREFIX}-checkboxInnerContainer`,
};

export const Root = styled("div")(({ theme }) => ({
  [`&.${classes.controlBarWrap}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.autoIsoControlBar,
    height: 35,
    position: "relative",
  },
  [`& .${classes.innerWrap}`]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  [`& .${classes.controlBarText}`]: {
    color: theme.palette.text.autoIsoControlBar,
    fontWeight: "bold",
    marginRight: 10,
  },
  [`& .${classes.button}`]: {
    marginRight: 10,
    color: theme.palette.text.autoIsoControlBar,
    height: 25,
    borderRadius: 3,
    border: `2px solid ${theme.palette.border.autoIsoControlBar}`,
    fontWeight: "bold",
  },
  [`& .${classes.buttonText}`]: {
    margin: 0,
    color: theme.palette.common.white,
  },
  [`& .${classes.disabledButton}`]: {
    marginRight: 10,
    height: 25,
    borderRadius: 3,
    border: "none",
    fontWeight: "bold",
  },
  [`& .${classes.disabledButtonText}`]: {
    margin: 0,
    color: theme.palette.text.autoIsoControlBar,
  },
  [`& .${classes.refreshButton}`]: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    color: theme.palette.text.autoIsoControlBar,
    border: `2px solid ${theme.palette.border.autoIsoControlBar}`,
    borderLeft: "none !important",
    height: 25,
    fontWeight: "bold",
  },
  [`& .${classes.countButton}`]: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    color: theme.palette.text.autoIsoControlBar,
    border: `2px solid ${theme.palette.border.autoIsoControlBar}`,
    height: 25,
    fontWeight: "bold",
  },
  [`& .${classes.checkboxAlert}`]: {
    marginLeft: 10,
    marginRight: 10,
  },

  [`& .${classes.rulesLink}`]: {
    marginLeft: 5,
  },
  [`& .${classes.linkButton}`]: {
    marginRight: 10,
    color: theme.palette.text.autoIsoControlBar,
    height: 25,
    borderRadius: 3,
    border: `2px solid ${theme.palette.border.autoIsoControlBar}`,
    fontWeight: "bold",
    display: "flex",
    padding: "0 15px",
    position: "absolute",
    top: "50%",
    right: 10,
    transform: "translateY(-50%)",
    "&.MuiLink-underlineHover:hover": {
      textDecoration: "none",
    },
  },
  [`& .${classes.linkButtonText}`]: {
    margin: "0 0 0 5px",
    color: theme.palette.common.white,
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.dialogActions}`]: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.shape.padding}px ${theme.shape.padding * 2}px`,
    justifyContent: "space-between",
    "& a": {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  [`& .${dialogClasses.checkboxOuterContainer}`]: {
    display: "flex",
    flexDirection: "column",
    padding: theme.shape.padding,
  },
  [`& .${dialogClasses.checkboxInnerContainer}`]: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginBottom: 0,
    },
  },
}));
