import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadPageData } from "redux/actions/Request";

import { Subnet as SubnetModel } from "lib/models/Subnet";

import SimpleTable from "views/Components/SimpleTable";
import ActionDialog from "views/Components/ActionDialog";
import SimpleModelForm from "views/Components/SimpleModelForm";

import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const SubnetsPageView = ({ ready, subnets, reload, orgId }) => {
  const [selectedModel, setSelectedModel] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const handleClose = (action) => {
    selectedModel.reset();
    if (action === "create") {
      reload(true);
    }
    setOpenForm(false);
  };

  const handleDelete = () => {
    selectedModel.delete().then(() => {
      reload(true);
      setOpenAction(false);
    });
  };

  const typeLookup = {
    10: "Private",
    20: "DMZ",
    30: "Public",
  };

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
    },
    {
      title: "Network",
      field: "network",
      searchable: true,
    },

    {
      title: "Type",
      field: "ipTypeId",
      lookup: typeLookup,
      searchable: true,
    },
    {
      title: "PCI",
      field: "pci",
      lookup: { true: <CheckIcon />, false: "" },
    },
    {
      title: "HIPAA",
      field: "hipaa",
      lookup: { true: <CheckIcon />, false: "" },
    },
    {
      title: "Servers",
      field: "servers",
      lookup: { true: <CheckIcon />, false: "" },
    },
    {
      title: "Workstations",
      field: "workstations",
      lookup: { true: <CheckIcon />, false: "" },
    },
    {
      title: "Wireless",
      field: "wireless",
      lookup: { true: <CheckIcon />, false: "" },
    },
    {
      title: "Cloud",
      field: "cloud",
      lookup: { true: <CheckIcon />, false: "" },
    },
    {
      title: "Zone Name",
      field: "zoneName",
    },
  ];

  const attributesOptions = [
    { value: "pci", label: "PCI" },
    { value: "hipaa", label: "HIPAA" },
    { value: "servers", label: "Servers" },
    { value: "workstations", label: "Workstations" },
    { value: "wireless", label: "Wireless" },
    { value: "cloud", label: "Cloud" },
  ];

  const onValidate = (model, fields) => {
    attributesOptions.map((attr) =>
      model.set(
        { [attr.value]: fields.attributes.value.includes(attr.value) },
        false
      )
    );
    model.set({ attributes: undefined });
    return fields;
  };

  const getTypeIdArray = () => {
    return Object.entries(typeLookup).map((arr) => {
      return { value: arr[0], label: arr[1] };
    });
  };

  const handleEditTableRowClick = (rowDetails) => {
    //rowDetails, in this case, is really just the user info/model
    setSelectedModel(rowDetails);
    setOpenForm(true);
  };

  return (
    <span className="dashboard subnets-page">
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openForm}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">
          {selectedModel.id ? `Edit ${selectedModel.name}` : "New Subnet"}
        </DialogTitle>
        <SimpleModelForm
          spacing={1}
          model={selectedModel}
          onValidate={onValidate}
          onClose={handleClose}
          actions={[
            {
              title: "Cancel",
              type: "button",
              action: handleClose,
            },
            {
              title: "Save",
              type: "submit",
            },
          ]}
          layout={[
            { field: "name", xs: 12 },
            { field: "ipTypeId", xs: 6 },
            { field: "network", xs: 6 },
            { field: "zoneName", xs: 12 },
            { field: "attributes", xs: 12 },
          ]}
          fields={{
            name: {
              type: "text",
              default: "",
              label: "Subnet Name",
            },
            zoneName: {
              type: "text",
              default: "",
              label: "Zone Name",
            },
            ipTypeId: {
              type: "select",
              default: 10,
              label: "Type",
              lookup: getTypeIdArray(),
            },
            network: {
              type: "text",
              default: "",
              label: "Network",
            },
            attributes: {
              type: "multiselect",
              label: "Attributes",
              lookup: attributesOptions,
              value: attributesOptions
                .map((opt) => opt.value)
                .filter((v) => selectedModel[v]),
            },
          }}
        />
      </Dialog>

      <ActionDialog
        open={openAction}
        title={`Delete ${selectedModel.name}`}
        description="Are you sure you want to delete this subnet?"
        actions={[
          {
            title: "Cancel",
            action: () => setOpenAction(false),
          },
          {
            title: "Delete",
            action: handleDelete,
          },
        ]}
      />

      <SimpleTable
        isNorthStar
        title="Subnets"
        data={subnets}
        columns={columns}
        rowClick={handleEditTableRowClick}
        isFetching={!ready}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add New Subnet",
            onClick: (event) => {
              setSelectedModel(new SubnetModel());
              setOpenForm(true);
            },
          },
        ]}
        actions={[
          {
            icon: EditIcon,
            tooltip: "Edit Subnet",
            onClick: (event, model) => {
              handleEditTableRowClick(model);
            },
          },
          {
            icon: DeleteIcon,
            tooltip: "Delete Subnet",
            onClick: (event, model) => {
              setSelectedModel(model);
              setOpenAction(true);
            },
          },
        ]}
      />
    </span>
  );
};

SubnetsPageView.propTypes = {
  ready: PropTypes.bool,
  orgId: PropTypes.string.isRequired,
  subnets: PropTypes.arrayOf(PropTypes.shape({})),
};

SubnetsPageView.defaultProps = {
  subnets: [],
};

const mapStateToProps = (state) => {
  return {
    orgId: state.location.payload.orgId,
  };
};

const mapDispatchToProps = (dispatch, { orgId }) => ({
  reload: (force) => dispatch(loadPageData(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubnetsPageView);
