import { handleApiError } from "../../utils/helpers";
import { DATA_RECEIVE, DATES_RECEIVE, ERROR_RECEIVE } from "./Strings";
import { getIntervalCode, timeParamsFromInterval } from "./TimeInterval";

// Creates the graph request query from the given params
const getQuery = ({
  limit = 10,
  logdevId,
  logdevIdPrefix,
  page,
  pageSize,
  orgId,
  selectedInterval,
}) => {
  const timeParams = timeParamsFromInterval(selectedInterval);

  const query = {
    limit,
    logdevId: `${logdevIdPrefix}${logdevId}`,
    orgId,
    page,
    pageSize,
    ...timeParams,
  };

  return query;
};

const receiveData =
  (actionTypePrefix) =>
  ({ data, insertId, selectedInterval }) => {
    const timeInterval = getIntervalCode(selectedInterval);

    return {
      type: `${actionTypePrefix}_${DATA_RECEIVE}`,
      payload: {
        data,
        insertId,
        timeInterval,
      },
    };
  };

const receiveDates =
  (actionTypePrefix) =>
  ({ data, insertId, selectedInterval }) => {
    const timeInterval = getIntervalCode(selectedInterval);

    return {
      type: `${actionTypePrefix}_${DATES_RECEIVE}`,
      payload: {
        data,
        insertId,
        timeInterval,
      },
    };
  };

export const receiveError = (actionTypePrefix) => (error) => ({
  type: `${actionTypePrefix}_${ERROR_RECEIVE}`,
  payload: {
    error,
  },
});

export const fetchDataAndDates =
  ({
    actionTypePrefix = "",
    apiCall = () => {},
    logdevIdPrefix,
    nameStr = "",
  }) =>
  ({ logdevId, orgId, selectedInterval }) =>
  (dispatch) => {
    const query = getQuery({
      logdevId,
      logdevIdPrefix,
      orgId,
      selectedInterval,
    });

    // Clear error
    dispatch(receiveError(actionTypePrefix)(null));

    apiCall({
      query,
    })
      .then((response) => {
        const { data: respData = {} } = response;
        const { data, dates = {} } = respData;

        dispatch(receiveError(actionTypePrefix)(null));

        dispatch(
          receiveData(actionTypePrefix)({
            data,
            insertId: logdevId,
            selectedInterval,
          })
        );

        dispatch(
          receiveDates(actionTypePrefix)({
            data: dates,
            insertId: logdevId,
            selectedInterval,
          })
        );
      })
      .catch((err) => {
        const message = `Unable to fetch ${nameStr} data.`;

        dispatch(
          handleApiError({
            message,
            err,
            onError: receiveError(actionTypePrefix),
          })
        );
      });
  };
