const ID_OPERATORS = ["eq", "!eq", "in", "!in"];

const testPSAIntegrationSchema = {
  $id: "/testpsaintegration",
  title: "PSAIntegrationTest",
  type: "object",
  properties: {
    clientId: { type: "string" },
    companyId: { type: "string" },
    manageUrl: { type: "string" },
    name: { type: "string" },
    privateKey: { type: "string" },
    publicKey: { type: "string" },
    orgId: {
      type: "string",
      format: "uuid",
      validOperators: ID_OPERATORS,
    },
    created: { type: "string", format: "date-time", readOnly: true },
  },
  required: [],
  additionalProperties: true,
};

export default testPSAIntegrationSchema;
