import React from "react";

import { computeRequired, getCleanMeta, validateMetaData } from "./metaHelpers";
import { MetaField } from "./MetaField";

/*
Helpers
*/

/*
Used for setting and validating meta field input.
`metaValues`is an object of the form { metaItemName1: metaItem1, ...}.
`metaPrevious` is the currently installed module's meta array
(can be an older version's meta compared to the one being
installed)
`sensorModule` is the module being updated and is used to extract
the meta field's orginal value for veryfying if it has changed.
*/
const handleChangeMeta =
  ({
    metaValues,
    setMetaValues,
    setFormErrorMessage,
    metaPrevious = null,
    sensorModule = null,
  }) =>
  (item) =>
  (evt) => {
    const value = evt.target.value;

    // Get the item's name
    const { name } = item;

    // Create the incoming meta data
    const changedMetaData = {
      [name]: {
        ...item,
        value,
      },
    };

    // Set the new meta values
    const newMetaValues = {
      ...metaValues,
      ...changedMetaData,
    };
    setMetaValues(newMetaValues);

    // Clean the incoming meta data (deletes empty and,
    // if sensorModule is present, unchanged original values)
    const metaData = getCleanMeta({
      metaValues: changedMetaData,
      sensorModule,
    });

    // Validate the incoming value if not cleaned-out
    if (Object.keys(metaData).length > 0) {
      validateMetaData({
        metaData,
        metaPrevious,
        setError: setFormErrorMessage,
      });
    }
  };

/*
Main component
*/
export const MetaComponent = ({
  metaValues = {},
  metaPrevious = null,

  sensorModule = null,
  setMetaValues = () => {},
  setFormErrorMessage = () => {},
}) => {
  const handleChange = handleChangeMeta({
    metaValues,
    setMetaValues,
    metaPrevious,
    sensorModule,
    setFormErrorMessage,
  });

  return (Object.keys(metaValues) || []).map((name, index) => {
    const item = metaValues[name] || {};

    const required = computeRequired({ metaPrevious, item });
    const value = item ? item.value || "" : "";

    return (
      <MetaField
        key={`meta_component_${index}`}
        item={item}
        onChange={handleChange(item)}
        required={required}
        value={value}
      />
    );
  });
};
