import _ from "lodash";
import moment from "moment-timezone";

import Logger from "../../../lib/logger";

const logger = Logger("formatters");

const traverse = (flatObj, nestedObj) => {
  _.each(nestedObj, (v, k) => {
    if (!v.field && v.fields) {
      flatObj = traverse(flatObj, v.fields);
    } else {
      flatObj[k] = v;
    }
  });
  return flatObj;
};

const formatters = {
  asNumber: async (value, props = {}) => {
    let type = "number";
    if (_.isObject(props)) {
      if (props.type) type = props.type;
    }
    if (type === "number") {
      return Number(value);
    }
    if (type === "float") {
      return parseFloat(value);
    }
    if (type === "int") {
      return parseInt(value, 10);
    }
    return Number(value);
  },
  asDate: async (value, props = {}) => {
    if (!value) return null;
    const { format = "MM/DD/YYYY", timezone = "UTC", toFormat } = props;
    if (toFormat) {
      return moment.tz(value, format, timezone).format(toFormat);
    }
    return moment.tz(value, format, timezone).toDate();
  },
  asBool: async (value) => {
    if (
      value === 0 ||
      value === "0" ||
      value === "false" ||
      value === "False" ||
      value === false ||
      value === null ||
      value === undefined ||
      _.isUndefined(value)
    ) {
      return false;
    }
    return true;
  },
  asJSON: async (value) => {
    if (_.isObject(value)) return value;
    if (_.isString(value)) return JSON.parse(value);
    return value;
  },
  asString: async (value) => _.trim(String(value)),
  noEmptyStr: async (value) => {
    if (!(typeof value === "string") && !(value instanceof String)) {
      return value;
    }
    const trimmed = value.trim();
    if (trimmed === "") {
      return undefined;
    }
    return trimmed;
  },
};

export const format = async (view, values) => {
  const newValues = {
    ...values,
  };
  const fields = traverse({}, view.fields);
  const keys = Object.keys(fields);
  for (let i = 0; i < keys.length; i += 1) {
    const name = keys[i];
    const field = fields[name];
    const value = values[name];
    const { formatting = {} } = field.props || {};
    const formattingKeys = Object.keys(formatting);
    let newValue = value;
    for (let x = 0; x < formattingKeys.length; x += 1) {
      const formatter = formatters[formattingKeys[x]];
      try {
        newValue = await formatter(value, formatting[formattingKeys[x]]);
      } catch (err) {
        logger.error(err);
      }
    }
    newValues[name] = newValue;
  }
  return newValues;
};
