const subaccountBillingHistorySchema = {
  $id: "/subaccountbillinghistory",
  type: "object",
  properties: {
    id: {
      type: "string",
      format: "uuid",
      readOnly: true,
    },
    licenseId: { type: "string", format: "uuid" },
    planId: { type: "integer" },
    subaccountId: { type: "string", format: "uuid" },
    billableUsers: { type: "integer" },
    createdBy: {
      type: "string",
      format: "uuid",
      readOnly: true,
    },
    deletedBy: {
      type: "string",
      format: "uuid",
      readOnly: true,
    },
    created: {
      type: "string",
      format: "date-time",
      readOnly: true,
    },
    deleted: {
      type: "string",
      format: "date-time",
      readOnly: true,
    },
  },
  required: ["licenseId", "planId", "subaccountId", "billableUsers"],
  additionalProperties: true,
};

export default subaccountBillingHistorySchema;
