// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Subheader line ******************
export const Line = styled.div`
  background-color: ${(p) => p.color};
  height: 1px;
  margin-left: 8px;
  width: 40px;
`;

// ** Typography **********************
export const TypographyStyle = styled.div`
  align-items: center;
  color: ${(p) => p.color};
  display: flex;
  flex-direction: row;
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  margin: ${(p) => p.margin};
  width: 100%;
`;
