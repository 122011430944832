// *****************************************************************************
// Dependencies
// *****************************************************************************
import { ActionTypes } from "../utils/constants/store";
import Logger from "../lib/logger";

const logger = Logger("SearchReducer");
// *****************************************************************************
// Helpers
// *****************************************************************************
const formatData = (list) => {
  const data = list.map((col) => {
    if (col.name.indexOf("finding_") > -1) {
      const name = col.name.replace("finding_", "").trim();
      return {
        ...col,
        dataSource: "finding",
        display_name: col.display_name.replace("Finding", "").trim(),
        name,
      };
    }

    return {
      ...col,
      dataSource: "bq",
    };
  });

  data.sort((a, b) => {
    if (
      a.display_name &&
      b.display_name &&
      a.display_name.toLowerCase() < b.display_name.toLowerCase()
    ) {
      return -1;
    }
    if (
      a.display_name &&
      b.name &&
      a.display_name.toLowerCase() < b.name.toLowerCase()
    ) {
      return -1;
    }
    if (
      a.name &&
      b.display_name &&
      a.name.toLowerCase() < b.display_name.toLowerCase()
    ) {
      return -1;
    }
    if (a.name && b.name && a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }

    if (
      a.display_name &&
      b.display_name &&
      a.display_name.toLowerCase() > b.display_name.toLowerCase()
    ) {
      return 1;
    }
    if (
      a.display_name &&
      b.name &&
      a.display_name.toLowerCase() > b.name.toLowerCase()
    ) {
      return 1;
    }
    if (
      a.name &&
      b.display_name &&
      a.name.toLowerCase() > b.display_name.toLowerCase()
    ) {
      return 1;
    }
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }

    return 0;
  });

  return data;
};

// *****************************************************************************
// Reducers
// *****************************************************************************

// ** All columns *********************
export const allColumns = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Search.fetchAllColumnsFail:
      return { ...state, error: action.payload };
    case ActionTypes.Search.fetchAllColumnsSuccess:
      return action.payload;
    default:
      return state;
  }
};

// ** All logTypes *********************
export const allLogTypes = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.Search.fetchLogTypesFail:
      return { ...state, error: action.payload };
    case ActionTypes.Search.fetchLogTypesSuccess:
      return action.payload;
    default:
      return state;
  }
};

// ** Org search categories ***********
export const searchCategories = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Search.fetchCategoriesFail:
      return action.payload;
    case ActionTypes.Search.fetchCategoriesSuccess:
      logger.log("CATEGORIES", action.payload);
      action.payload.sort((a, b) =>
        a.display_name.toUpperCase() > b.display_name.toUpperCase() ? 1 : -1
      );
      return action.payload;
    default:
      return state;
  }
};

// ** Org search columns **************
export const searchColumns = (state = null, action) => {
  switch (action.type) {
    case ActionTypes.Search.fetchColumnsAndTypesFail:
      return { ...state, error: action.payload };
    case ActionTypes.Search.fetchColumnsAndTypesSuccess:
      return {
        columns: formatData(action.payload.columns),
        logTypes: action.payload.logTypes.map((logType) => ({
          ...logType,
          schema_column_summaries: formatData(logType.schema_column_summaries),
        })),
      };
    default:
      return state;
  }
};

// Filter operators
export const searchOperators = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.Search.fetchFilterOperatorsFail:
      return state;
    case ActionTypes.Search.fetchFilterOperatorsSuccess:
      return {
        ...state,
        [action.payload.type]: {
          id: action.payload.id,
          operators: action.payload.operators,
        },
      };
    default:
      return state;
  }
};
