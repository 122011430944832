import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "MSPBillingReportsDialog";
export const classes = {
  dialogHeader: `${PREFIX}-dialogHeader`,
  close: `${PREFIX}-close`,
  dialogContent: `${PREFIX}-dialogContent`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.dialogHeader}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.shape.padding,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
  },
  [`& .${classes.dialogContent}`]: {
    paddingTop: "0px !important",
  },
}));
