import { combineReducers } from "redux";
import {
  REQUEST_SENSORS,
  SENSORS_RECEIVE_ORG_SENSORS,
  SENSORS_DELETE_ONE_SENSOR,
  SENSORS_SET_SENSORS_ERROR,
  SENSORS_CLEAR_ERROR,
  SENSORS_CLEAR_SENSORS,
  SENSORS_RECEIVE_ONE_SENSOR_DETAIL,
  SENSORS_SET_DETAIL_ERROR,
  SENSORS_CLEAR_SENSOR_DETAIL,
  SENSORS_CLEAR_DETAIL_ERROR,
  SENSORS_RECEIVE_DETAIL_LOADING,
  SENSORS_RECEIVE_DEVICES,
  SENSORS_SET_DEVICES_ERROR,
  SENSORS_CLEAR_DEVICES_ERROR,
  SENSORS_CLEAR_DEVICES_FOR_ONE_SENSOR,
  SENSORS_CLEAR_DEVICES,
  SENSORS_RECEIVE_LATEST_PARSED_TIME,
  SENSORS_RECEIVE_LATEST_TIMESTAMP,
  SENSORS_RECEIVE_PARSING_ERRORS,
  SENSORS_SET_SUPPORT_ERROR,
  SENSORS_CLEAR_SUPPORT_ERROR,
} from "./actions/Sensors";
import { entriesFromList, insertData, insertDataById } from "./utils/helpers";

const INITIAL_STATE = {
  detailLoading: {
    byId: {},
  },
  devices: {
    bySensor: {},
    timeWindowBySensor: {},
    error: null,
  },
  sensorDetail: {
    byId: {},
    error: null,
  },
  sensors: {
    byId: {},
    byOrg: {},
    error: null,
  },
  support: {
    latestParsedTimeBySensor: {},
    latestTimestampBySensor: {},
    parsingErrorsBySensor: {},
    error: null,
  },
};

/*
'sensors.sensors'
*/

const initialSensors = INITIAL_STATE.sensors;

/*
'sensors.sensors.byId'
*/

const initialSensorsById = initialSensors.byId;

// Inserts sensors into sensors.byId
const addSensorsForOrg = (state = initialSensorsById, action) => {
  const { payload } = action;
  const { sensors } = payload;

  const orgSensors = entriesFromList(sensors);

  const nextState = { ...state, ...orgSensors };

  return nextState;
};

const deleteOneSensor = (state = initialSensorsById, action) => {
  const { payload } = action;
  const { sensorId } = payload;

  const nextState = { ...state };
  delete nextState[sensorId];

  return nextState;
};

// Resets sensors.byId store
const resetSensorsEntries = () => initialSensorsById;

// 'sensors.byId' reducer
const sensorsById = (state = initialSensorsById, action) => {
  switch (action.type) {
    case SENSORS_RECEIVE_ORG_SENSORS: {
      return addSensorsForOrg(state, action);
    }
    case SENSORS_DELETE_ONE_SENSOR: {
      return deleteOneSensor(state, action);
    }
    case SENSORS_CLEAR_SENSORS: {
      return resetSensorsEntries();
    }
    default:
      return state;
  }
};

/*
'sensors.sensors.byOrg'
*/

const initialSensorsByOrg = initialSensors.byOrg;

const setSensorsListForOrg = (state = initialSensorsByOrg, action) => {
  const { payload } = action;
  const { sensors, orgId } = payload;

  if (!orgId) {
    return state;
  }

  const orgSensorIds = sensors.map((elt) => elt.id).sort();

  const nextState = {
    ...state,
    [orgId]: orgSensorIds,
    isFetching: false,
  };

  return nextState;
};

const removeOneSensorFromOrg = (state = initialSensorsById, action) => {
  const { payload } = action;
  const { orgId, sensorId } = payload;

  const orgSensors = (state[orgId] || []).filter((str) => str !== sensorId);

  const nextState = {
    ...state,
    [orgId]: orgSensors,
  };

  return nextState;
};

const requestSensors = (state, action) => {
  return {
    ...state,
    isFetching: true,
  };
};

// Resets the blocks.byOrg store
const resetSensorsListForOrg = () => initialSensorsByOrg;

// sensors.byOrg reducer
const sensorsByOrg = (state = initialSensorsByOrg, action) => {
  switch (action.type) {
    case REQUEST_SENSORS: {
      return requestSensors(state, action);
    }
    case SENSORS_DELETE_ONE_SENSOR: {
      return removeOneSensorFromOrg(state, action);
    }
    case SENSORS_RECEIVE_ORG_SENSORS: {
      return setSensorsListForOrg(state, action);
    }
    case SENSORS_CLEAR_SENSORS: {
      return resetSensorsListForOrg();
    }
    default:
      return state;
  }
};

/*
'sensors.sensors.error'
*/

const initialSensorsError = initialSensors.error;

// Used to set the error field
const getError = (action) => {
  const { payload } = action;
  const { error } = payload;

  return error;
};

const sensorsErrorReducer = (state = initialSensorsError, action) => {
  switch (action.type) {
    case SENSORS_SET_SENSORS_ERROR: {
      return getError(action);
    }
    case SENSORS_CLEAR_ERROR: {
      return initialSensorsError;
    }
    default:
      return state;
  }
};

/*
'sensors.sensors' reducer
*/

const SensorsReducer = combineReducers({
  byId: sensorsById,
  byOrg: sensorsByOrg,
  error: sensorsErrorReducer,
});

/*
'sensors.sensorDetail'
*/

const initialSensorDetail = INITIAL_STATE.sensorDetail;

/*
'sensors.sensorDetail.byId'
*/

const initialSensorDetailById = initialSensorDetail.byId;

// Inserts one Sensor Detail into sensorDetail.byId
const receiveOneSensorDetail = (state = initialSensorDetailById, action) => {
  const { payload } = action;
  const { sensorDetail } = payload;

  const nextState = { ...state };
  nextState[sensorDetail.id] = { ...sensorDetail };

  return nextState;
};

// Resets 'sensors.sensorDetail.byId' store
const resetSensorDetailEntries = () => initialSensorDetailById;

// 'sensors.sensorDetail.byId' reducer
const sensorDetailById = (state = initialSensorDetailById, action) => {
  switch (action.type) {
    case SENSORS_RECEIVE_ONE_SENSOR_DETAIL: {
      return receiveOneSensorDetail(state, action);
    }
    case SENSORS_DELETE_ONE_SENSOR: {
      return deleteOneSensor(state, action);
    }
    case SENSORS_CLEAR_SENSOR_DETAIL: {
      return resetSensorDetailEntries();
    }
    default:
      return state;
  }
};

/*
'sensors.sensorDetail.error'
*/

const initialDetailError = initialSensorDetail.error;

/*
'sensors.sensorDetail.detailError' reducer
*/

const detailErrorReducer = (state = initialDetailError, action) => {
  switch (action.type) {
    case SENSORS_SET_DETAIL_ERROR: {
      return getError(action);
    }
    case SENSORS_CLEAR_DETAIL_ERROR: {
      return initialDetailError;
    }
    default:
      return state;
  }
};

const SensorDetailReducer = combineReducers({
  byId: sensorDetailById,
  error: detailErrorReducer,
});

/*
'sensors.detailLoading'
*/
const detailLoadingByIdReducer = (
  state = INITIAL_STATE.detailLoading.byId,
  action
) => {
  switch (action.type) {
    case SENSORS_RECEIVE_DETAIL_LOADING: {
      return insertDataById(state, action);
    }
    default:
      return state;
  }
};

const DetailLoadingReducer = combineReducers({
  byId: detailLoadingByIdReducer,
});

/*
'sensors.devices'
*/

const initialDevices = INITIAL_STATE.devices;

/*
'sensors.devices.bySensor'
*/

const initialDevicesBySensor = initialDevices.bySensor;

const setDevicesListForSensor = (state = initialDevicesBySensor, action) => {
  const { payload } = action;

  const { data = {}, sensorId } = payload;
  const { devices = [] } = data;

  if (!sensorId) {
    return state;
  }

  const nextState = { ...state };
  nextState[sensorId] = [...devices];

  return nextState;
};

const deleteItemById = (state, action) => {
  const { payload } = action;
  const { sensorId } = payload;
  if (!sensorId) {
    return state;
  }

  const nextState = { ...state };
  delete nextState[sensorId];

  return nextState;
};

const resetDevicesLists = () => initialDevicesBySensor;

/*
sensors.devices.bySensor reducer
*/

const devicesBySensor = (state = initialDevicesBySensor, action) => {
  switch (action.type) {
    case SENSORS_RECEIVE_DEVICES: {
      return setDevicesListForSensor(state, action);
    }
    case SENSORS_CLEAR_DEVICES_FOR_ONE_SENSOR: {
      return deleteItemById(state, action);
    }
    case SENSORS_CLEAR_DEVICES: {
      return resetDevicesLists();
    }
    default:
      return state;
  }
};

/*
'sensors.devices.timeWindowBySensor'
*/

const initialDeviceTimeWindowBySensor = initialDevices.timeWindowBySensor;

const setDevicesTimeWindowForSensor = (
  state = initialDeviceTimeWindowBySensor,
  action
) => {
  const { payload } = action;
  const { data, sensorId } = payload;

  const { windowEnd, windowStart } = data;

  if (!sensorId) {
    return state;
  }

  const nextState = { ...state };

  nextState[sensorId] = {
    windowEnd,
    windowStart,
  };

  return nextState;
};

const resetDevicesTimeWindows = () => initialDeviceTimeWindowBySensor;

/*
sensors.devices.timeWindowBySensor reducer
*/

const devicesTimeWindowBySensor = (state = initialDevicesBySensor, action) => {
  switch (action.type) {
    case SENSORS_RECEIVE_DEVICES: {
      return setDevicesTimeWindowForSensor(state, action);
    }
    case SENSORS_CLEAR_DEVICES_FOR_ONE_SENSOR: {
      return deleteItemById(state, action);
    }
    case SENSORS_CLEAR_DEVICES: {
      return resetDevicesTimeWindows();
    }
    default:
      return state;
  }
};

/*
'sensors.devices.error'
*/

const initialDevicesError = initialDevices.error;

const devicesErrorReducer = (state = initialDevicesError, action) => {
  switch (action.type) {
    case SENSORS_SET_DEVICES_ERROR: {
      return getError(action);
    }
    case SENSORS_CLEAR_DEVICES_ERROR: {
      return initialDevicesError;
    }
    default:
      return state;
  }
};

/*
'sensors.devices' reducer
*/

const DevicesReducer = combineReducers({
  bySensor: devicesBySensor,
  timeWindowBySensor: devicesTimeWindowBySensor,
  error: devicesErrorReducer,
});

/*
'sensors.support'
*/

/*
parsingErrorsBySensor
*/

const initialParsingErrorsBySensor =
  INITIAL_STATE.support.parsingErrorsBySensor;

const parsingErrorsBySensor = (
  state = initialParsingErrorsBySensor,
  action
) => {
  switch (action.type) {
    case SENSORS_RECEIVE_PARSING_ERRORS: {
      return insertData(state, action);
    }
    default:
      return state;
  }
};

/*
latestParsedTimeBySensor
*/

const initialLatestParsedTime = INITIAL_STATE.support.latestParsedTimeBySensor;

const latestParsedTimeBySensor = (state = initialLatestParsedTime, action) => {
  switch (action.type) {
    case SENSORS_RECEIVE_LATEST_PARSED_TIME: {
      return insertData(state, action);
    }
    default:
      return state;
  }
};

/*
latestTimestampBySensor
*/

const initialLatestTimestamp = INITIAL_STATE.support.latestTimestampBySensor;

const latestTimestampBySensor = (state = initialLatestTimestamp, action) => {
  switch (action.type) {
    case SENSORS_RECEIVE_LATEST_TIMESTAMP: {
      return insertData(state, action);
    }
    default:
      return state;
  }
};

/*
'sensors.support.error' reducer
*/

const initialSupportError = INITIAL_STATE.support.error;

const supportErrorReducer = (state = initialSupportError, action) => {
  switch (action.type) {
    case SENSORS_SET_SUPPORT_ERROR: {
      return getError(action);
    }
    case SENSORS_CLEAR_SUPPORT_ERROR: {
      return initialSupportError;
    }
    default:
      return state;
  }
};

/*
'sensors.support' reducer
*/

const SupportReducer = combineReducers({
  error: supportErrorReducer,
  latestParsedTimeBySensor,
  latestTimestampBySensor,
  parsingErrorsBySensor,
});

/*
'sensors' Store Reducer
*/

const SensorsStoreReducer = combineReducers({
  devices: DevicesReducer,
  detailLoading: DetailLoadingReducer,
  sensors: SensorsReducer,
  sensorDetail: SensorDetailReducer,
  support: SupportReducer,
});

export default SensorsStoreReducer;
