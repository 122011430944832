// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "../../../utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Input container *****************
export const InputContainerStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  fill: ${COLOR.gray500};
  width: 100%;
`;

// ** Input error *********************
export const InputErrorStyle = styled.span`
  color: ${COLOR.blumirarRed};
  display: block;
  font-size: 12px;
  font-weight: 500;
  height: ${(p) => (p.error ? "20px" : "0px")};
  line-height: 20px;
  margin-left: 2px;
  overflow: hidden;
  transition: all 0.3s;
`;

// ** Input ***************************
export const InputStyle = styled.input`
  background-color: ${(p) =>
    p.minimal ? "transparent" : "rgba(105, 180, 255, 0.25)"};
  border: none;
  border-radius: 3px;
  box-shadow: none;
  color: ${COLOR.blumiraBlue700};
  display: block;
  font-size: 14px;
  outline: none;
  padding: ${(p) => p.padding || "8px 10px"};
  transition: all 0.3s;
  width: 100%;

  ::placeholder {
    color: ${COLOR.blumiraBlue700};
  }

  &:hover {
    background-color: ${(p) =>
      p.minimal ? "none" : "rgba(105, 180, 255, 0.35)"};
  }

  &:disabled {
    background-color: ${COLOR.gray400};
    cursor: not-allowed;
  }

  &:focus {
    background-color: ${(p) =>
      p.minimal ? "none" : "rgba(105, 180, 255, 0.35)"};
    color: ${COLOR.blumiraBlue900};
  }
`;

// ** Input label *********************
export const InputLabelStyle = styled.span`
  color: ${COLOR.black};
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
`;
