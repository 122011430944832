import React from "react";
import { Button } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { rootClasses } from "./loginViewStyles";

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      datacy={"loginBtn"}
      disabled={props.disabled}
      className={rootClasses.primaryButton}
      onClick={() => {
        localStorage.setItem("isLoggingIn", "true");
        loginWithRedirect({
          authorizationParams: {
            login_hint: props.userEmail,
          },
        });
      }}
      style={{ marginRight: 20 }}
    >
      Log in
    </Button>
  );
};

export default LoginButton;
