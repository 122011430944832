const matchSchema = {
  $id: "/match",
  title: "Match",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    deleted: { type: "boolean" },
    deletedBy: { type: "string", format: "uuid" },
    ruleId: { type: "string", format: "uuid", readOnly: true },
    findingId: { type: "string", format: "uuid", readOnly: true },
    orgId: { type: "string", format: "uuid", readOnly: true },
    conditionId: { type: "number" },
    actionId: { type: "number" },
    category: { type: "number" },
    summary: { type: "string" },
    name: { type: "string" },
  },
};

export default matchSchema;
