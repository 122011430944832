import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "FindingsResolutionNotes";
const classes = {
  tableRowLabel: `${PREFIX}-tableRowLabel`,
  resolutionTable: `${PREFIX}-resolutionTable`,
};

const Root = styled("table")(({ theme }) => ({
  [`& .${classes.tableRowLabel}`]: {
    fontWeight: "bold",
    paddingRight: 20,
    whiteSpace: "nowrap",
    width: 160,
  },
  [`&.${classes.resolutionTable}`]: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    fontSize: 16,
    height: 80,
    "& td": {
      paddingBottom: 15,
      verticalAlign: "top",
    },
  },
}));

const FindingsResolutionNotes = (props) => {
  const resolutionOptions = [
    { value: 10, label: "Valid" },
    { value: 20, label: "False Positive" },
    { value: 30, label: "No Action Needed" },
    { value: 40, label: "Risk Accepted" },
  ];

  const findingResolutionValue = resolutionOptions.find(
    (r) => r.value === props.finding.resolution
  );

  return (
    <Root className={classes.resolutionTable}>
      <tbody>
        <tr>
          <td className={classes.tableRowLabel}>Resolution</td>
          <td>{findingResolutionValue?.label}</td>
        </tr>
        <tr>
          <td className={classes.tableRowLabel}>Resolution Notes</td>
          <td>
            {props.finding.resolutionNotes
              ? props.finding.resolutionNotes
              : "This finding does not have any resolution notes at this time."}
          </td>
        </tr>
      </tbody>
    </Root>
  );
};

export default FindingsResolutionNotes;
