import { OrgAPI } from "../../lib/api";
import Request from "../../lib/api/Request";
import Logger from "../../lib/logger";
import { clearUrlQuery as closeModal } from "./Page";
import {
  receiveActiveJobMetrics,
  receivePipelineMetrics,
  receivePrimaryPipeline,
} from "./Pipeline";
import { apiErrorMessageForUi } from "../utils/helpers";

const logger = Logger("Org");

export const RESET_ORGS = "ORGS.RESET_ORGS";
export const RECEIVE_ORGS = "ORGS.RECEIVE_ORGS";
export const RECEIVE_ORGS_ERROR = "ORGS.RECEIVE_ORGS_ERROR";
export const ORG_RECEIVE_ORG_DETAIL = "ORG_RECEIVE_ORG_DETAIL";
export const ORG_RECEIVE_ORG_ERROR = "ORG_RECEIVE_ORG_ERROR";
export const ORG_RECEIVE_ORG_LOADING = "ORG_RECEIVE_ORG_LOADING";
export const ORG_RESET_ORG = "ORG_RESET_ORG";

const API = {
  OrgAPI: new OrgAPI(),
};

export const receiveOrg = ({ orgId, data }) => ({
  type: ORG_RECEIVE_ORG_DETAIL,
  payload: { insertId: orgId, data },
});

export const receiveOrgError = ({ orgId, error }) => ({
  type: ORG_RECEIVE_ORG_ERROR,
  payload: { insertId: orgId, data: error },
});

export const receiveOrgLoading = ({ orgId, loading }) => ({
  type: ORG_RECEIVE_ORG_LOADING,
  payload: { insertId: orgId, data: loading },
});

/**
 * Get an org from the config service.
 */
export const getOrg = (orgId) => (dispatch) => {
  dispatch(receiveOrgError({ orgId, error: null }));
  dispatch(receiveOrgLoading({ orgId, loading: true }));

  return API.OrgAPI.getOrg(orgId)
    .then((resp) => {
      const { data = [] } = resp;
      const {
        activeJobMetrics = {},
        pipelineMetrics = {},
        primaryPipeline = {},
        summary = {},
      } = data;

      dispatch(receiveOrg({ orgId, data: summary }));

      dispatch(
        receiveActiveJobMetrics({
          orgId,
          data: activeJobMetrics,
        })
      );
      dispatch(
        receivePipelineMetrics({
          orgId,
          data: pipelineMetrics,
        })
      );

      dispatch(receivePrimaryPipeline({ orgId, data: primaryPipeline }));

      dispatch(receiveOrgError({ orgId, error: null }));
      dispatch(receiveOrgLoading({ orgId, loading: false }));
    })
    .catch((err) => {
      const message = "Unable to fetch org detail.";
      logger.error(message, err);

      const uiErrorMessage = apiErrorMessageForUi({ message, err });
      const error = new Error(uiErrorMessage);
      dispatch(receiveOrgError({ orgId, error }));

      dispatch(receiveOrgLoading({ orgId, loading: false }));
    });
};

export const updateSchema = (orgId) => (dispatch) => {
  dispatch(receiveOrgError({ orgId, error: null }));
  dispatch(receiveOrgLoading({ orgId, loading: true }));

  API.OrgAPI.updateSchema(orgId)
    .then(() => dispatch(getOrg(orgId)))
    .then(() => {
      dispatch(closeModal);
    })
    .catch((err) => {
      const message = "Request to update the schema returned an error.";
      logger.error(message, err);

      const uiErrorMessage = apiErrorMessageForUi({ message, err });
      const error = new Error(uiErrorMessage);
      dispatch(receiveOrgError({ orgId, error }));

      dispatch(receiveOrgLoading({ orgId, restarting: false }));
      dispatch(closeModal);
    });
};

/**
 * Get a list of orgs from the account service (not the config service).
 * If x-blu-orgid header is set in axios, the list will only contain one org.
 */
export const getOrgs = () => (dispatch) => {
  dispatch({ type: RESET_ORGS });
  const request = new Request("/org");
  request
    .get()
    .then((orgs) => {
      dispatch({
        type: RECEIVE_ORGS,
        orgs,
      });
    })
    .catch((err) => {
      const message = "Failed to load org detail.";
      dispatch({
        type: RECEIVE_ORGS_ERROR,
        error: message,
      });
    });
};

export const resetOrgs = () => (dispatch) => {
  dispatch({ type: RESET_ORGS });
};
