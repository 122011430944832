// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Add/remove tag ******************
export const Tag = styled.div`
  align-items: center;
  background-color: ${(p) => (p.added ? COLOR.blumiraRed : COLOR.blumiraBlue)};
  border-radius: 2px;
  color: #fff;
  display: flex;
  fill: #fff;
  flex-direction: row;
  font-size: 10px;
  justify-content: flex-end;
  opacity: 0;
  padding: 1px 4px 0px;
  transition: opacity 250ms;
`;

// ** Column **************************
export const Column = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  color: ${COLOR.gray600};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  font-weight: 400;
  justify-content: space-between;
  outline: none !important;
  padding: 6px 0px;
  transition: all 250ms;
  width: 100%;

  &:hover ${Tag} {
    opacity: 1;
  }

  &:hover {
    border-bottom: 1px dashed ${COLOR.gray100};
    color: ${COLOR.gray1000};
  }
`;

// ** Empty state description *********
export const Description = styled.span`
  color: ${COLOR.gray600};
  display: block;
  flex-grow: 0;
  font-size: 12px;
`;

// ** Empty state container ***********
export const EmptyState = styled.div`
  align-items: flex-start;
  display: flex;
  fill: ${COLOR.gray600};
  flex-direction: row;
  padding: 8px 0px;
  width: 100%;
`;

// ** Header **************************
export const Header = styled.div`
  align-items: center;
  color: ${COLOR.gray1000};
  display: flex;
  fill: ${COLOR.gray1000};
  flex-direction: row;
  font-size: 14px;
  justify-content: flex-start;
  margin-bottom: 8px;
`;

// ** List item ***********************
export const ListItem = styled.li`
  padding: 0px 0px 0px 20px;
  width: 100%;
`;

// ** List ****************************
export const List = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
`;

// ** Column container ****************
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`;

// ** Outer container *****************
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
