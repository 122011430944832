import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { fetchTags } from "../../../redux/actions/Tagging";

/*
Helpers
*/

/*
Shallow comparison of two arrays of string elements
(or string conversion of each element);
*/
const customCompare = (prev, curr) => {
  if (!prev) {
    return curr ? false : true;
  }

  if (!curr) {
    return prev ? false : true;
  }

  if (prev.length !== curr.length) {
    return false;
  }

  const prevSorted = [...prev].sort();
  const currSorted = [...curr].sort();

  for (let i = prev.length - 1; i >= 0; i -= 1) {
    if (prevSorted[i] !== currSorted[i]) {
      return false;
    }
  }

  return true;
};

/*
Non-rendering component that fetches the tags for object in `objId`.
`objType` and `objId` are string arrays.
Renders its own children.
*/
const TagsFetch = (props) => {
  const { objType, objId = "" } = props;

  // Used to save the array to watch for change.
  const objIdRef = useRef([]);

  // Watch for objId change
  useEffect(() => {
    if (!customCompare(objIdRef.current, props.objId)) {
      objIdRef.current = props.objId;
      props.fetchTags({ objType, objId });
    }
  });

  return <React.Fragment>{props.children}</React.Fragment>;
};

// PropTypes
TagsFetch.propTypes = {
  objId: PropTypes.arrayOf(PropTypes.string).isRequired,
  objType: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchTags: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
};

// Redux
const mapDispatchToProps = (dispatch) => ({
  fetchTags: ({ objType, objId }) => dispatch(fetchTags({ objType, objId })),
});

export default connect(null, mapDispatchToProps)(TagsFetch);
