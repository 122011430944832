import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";

/*
Component
*/

const customStyles = {
  container: (provided) => ({
    ...provided,
    display: "inline-block",
    width: "150px",
    minHeight: "30px",
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "30px",
    height: "30px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    height: "12px",
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: "28px",
    paddingTop: "0",
    paddingBottom: "0",
  }),
};

const DevicesSelect = (props) => {
  const { options, defaultValue, onSelect } = props;

  const handleChange = (selectedOption) => {
    onSelect(selectedOption.value);
  };

  const defaultOption = () =>
    options.find((option) => option.value === defaultValue);

  return (
    <Select
      className="sensor-devices-select"
      onChange={handleChange}
      options={options}
      defaultValue={defaultOption()}
      styles={customStyles}
    />
  );
};

DevicesSelect.propTypes = {
  defaultValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
};

DevicesSelect.defaultProps = {
  defaultValue: "",
  options: [],
};

export default DevicesSelect;
