import { getPageHashParams } from "utils";
//import createSearchtermQuery from "./query/uniqueValues";
import { entityTypes } from "./defs";

export const abbreviateNumber = (value) => {
  const suffixes = ["", "k", "m", "b", "t"];
  const roundValues = [0, 100, 10, 1, 1];
  var itr = 0;
  while (value >= 1000) {
    itr += 1;
    value /= 1000;
  }
  const roundVal = roundValues[`${Math.floor(value)}`.length];
  value = Math.round(value * roundVal) / roundVal;
  return `${value}${suffixes[itr]}`;
};

export const navToInvestigation = (orgId, pageHashParams) => {
  const php = getPageHashParams();
  var hashString = `#search=${pageHashParams.search}`;
  if (pageHashParams.start || php.start) {
    hashString += `&start=${pageHashParams.start || php.start}`;
  }
  if (pageHashParams.end || php.end) {
    hashString += `&end=${pageHashParams.end || php.end}`;
  }
  window.open(`/${orgId}/investigate/search${hashString}`, "_blank");
};

/*
export const getUniquesFromBQ = (searchValue) => {
  const query = createSearchtermQuery(
    new moment(),
    "year",
    searchValue,
    entityFields
  );
  const req = new Request(query.namespace, query.queryParams, query.resultParams)
  req.get()
}
*/

export const getEntityByFieldName = (search) =>
  entityTypes.find((eT) => eT.matchFields.find((f) => f.fieldName === search));
