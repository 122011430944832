import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";

import { formatDateUserTz } from "../../../utils/index";
import SupportEntry from "../../Components/Finding/SupportEntry";

const SensorStatusDetail = ({
  columnNumLeft,
  columnNumRight,
  statusCode = "",
  statusDetail = "",
  statusModified = "",
  statusName = "",
}) => {
  const showStatus = statusCode || statusDetail || statusModified || statusName;

  if (!showStatus) {
    return null;
  }

  const statusModifiedUserTz = formatDateUserTz(statusModified);

  return (
    <Container className="list-container dark">
      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Status Name"
        value={statusName}
      />

      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Status Changed"
        value={statusModifiedUserTz}
      />

      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Status Detail"
        value={statusDetail}
      />

      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Status Code"
        value={statusCode}
      />
    </Container>
  );
};

SensorStatusDetail.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  statusCode: PropTypes.string,
  statusDetail: PropTypes.string,
  statusModified: PropTypes.string,
  statusName: PropTypes.string,
};

SensorStatusDetail.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  statusCode: "",
  statusDetail: "",
  statusModified: "",
  statusName: "",
};

export default SensorStatusDetail;
