// *****************************************************************************
// Dependencies
// *****************************************************************************
import { Column } from "../../lib/models/Column";
import Request from "../../lib/api/Request";

import { concat, uniqBy } from "lodash";
import { ActionTypes } from "../../utils/constants/store";
import Logger from "../../lib/logger";

const logger = Logger("Search");

// *****************************************************************************
// Thunks
// *****************************************************************************

// Fetch all columns
//
// Fetches alll columns available for search
//
// *** Example ***
//  fetchAllColumns()
//
export const fetchAllColumns = () => async (dispatch) => {
  const request = new Request("/column");
  return request
    .get()
    .then((columns) => {
      dispatch({
        type: ActionTypes.Search.fetchAllColumnsSuccess,
        payload: columns,
      });
    })
    .catch((err) => {
      logger.error(err);
      dispatch({
        type: ActionTypes.Search.fetchAllColumnsFail,
        payload: err,
      });
    });
};

// Fetch search categories
//
// Fetches categories available to that org
//
// *** Example ***
//  handleFetchSearchCategories()
//
export const fetchSearchCategories = () => async (dispatch) => {
  const request = new Request("/category");
  return request
    .get()
    .then((categories) => {
      dispatch({
        type: ActionTypes.Search.fetchCategoriesSuccess,
        payload: categories,
      });
    })
    .catch((err) => {
      logger.error(err);
      dispatch({
        type: ActionTypes.Search.fetchCategoriesFail,
        payload: err,
      });
    });
};

// Fetch all log types
//
// Fetches all log types available for search
//
// *** Example ***
//  handleFetchAllLogTypes()
//
export const fetchAllLogTypes = () => async (dispatch) => {
  const request = new Request("/logtype");
  return request
    .get()
    .then((logTypes) => {
      dispatch({
        type: ActionTypes.Search.fetchLogTypesSuccess,
        payload: logTypes,
      });
    })
    .catch((err) => {
      logger.error(err);
      dispatch({
        type: ActionTypes.Search.fetchLogTypesFail,
        payload: err,
      });
    });
};

// Fetch columns and types
//
// Fetches all columns and types associated with selected category
//
// *** Example ***
//  handleFetchColumnsAndTypes(categoryID)
//
export const fetchSearchColumnsAndTypes = (categoryID) => async (dispatch) => {
  try {
    const request = new Request("/logtype", [
      { field: "categoryID", value: categoryID },
    ]);
    await request.get().then((logTypes) => {
      const data = {
        columns: [],
        logTypes: [],
      };

      logTypes.map((logType) => {
        data.logTypes.push(logType);
        data.columns = concat(data.columns, logType.schema_column_summaries);
        data.columns = uniqBy(data.columns, "id").sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
      });
      data.columns = data.columns.map((c) => new Column(c));
      dispatch({
        type: ActionTypes.Search.fetchColumnsAndTypesSuccess,
        payload: data,
      });
    });
  } catch (err) {
    logger.error(err);
    await dispatch({
      type: ActionTypes.Search.fetchColumnsAndTypesFail,
      payload: err.message,
    });
  }
};
