import React from "react";
import PropTypes from "prop-types";

import Labels from "./Labels";
import ObjTags from "./ObjTags";

import "./LabelsAndTags.scss";

/*
This component combines Labels and ObjTags components. It displays both,
the Labels select dropdwon and the object's tags.

Gets the data from Redux 'tagging' but does not fetch it on mount.
Use it with LabelsAndTagsFetch, or use LabelsAndTagsWithFetch instead.
*/
const LabelsAndTags = (props) => {
  const { objId, objType, orgId, superadmin } = props;

  return (
    <div className="labels-and-tags-container">
      <Labels
        objId={objId}
        objType={objType}
        orgId={orgId}
        superadmin={superadmin}
      />
      <ObjTags objId={objId} objType={objType} superadmin={superadmin} />
    </div>
  );
};

LabelsAndTags.propTypes = {
  objId: PropTypes.string.isRequired,
  objType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  superadmin: PropTypes.bool,
};

LabelsAndTags.defaultProps = {
  superadmin: false,
};

export default LabelsAndTags;
