import React from "react";
import PropTypes from "prop-types";

import LabelsFetch from "./LabelsFetch";
import TagsFetch from "./TagsFetch";

/*
Non-rendering component that fetches the labels for `orgId` that is gets
from redux location.payload, the system labels, and the tags for `objId`.
`objType`, `objId` are string arrays. `orgId` is a string.
Renders its own children.
*/
const LabelsAndTagsFetch = (props) => {
  const { objId, objType } = props;

  return (
    <LabelsFetch>
      <TagsFetch objId={objId} objType={objType}>
        {props.children}
      </TagsFetch>
    </LabelsFetch>
  );
};

// PropTypes
LabelsAndTagsFetch.propTypes = {
  objId: PropTypes.arrayOf(PropTypes.string).isRequired,
  objType: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
};

export default LabelsAndTagsFetch;
