const BQSummarySchema = {
  $id: "/bq_summary",
  title: "Big Query Summary",
  type: "object",
  properties: {
    created: { type: "string", format: "date-time", readOnly: true },
    logger: { type: "string", readOnly: true },
    deviceAddress: { type: "string", readOnly: true },
    deviceAddressHex: { type: "string", readOnly: true },
    devname: { type: "string", readOnly: true },
    type: { type: "string", readOnly: true },
    associationType: { type: "string", readOnly: true },
    srcAddress: { type: "string", readOnly: true },
    srcAddressHex: { type: "string", readOnly: true },
    dstAddress: { type: "string", readOnly: true },
    dstAddressHex: { type: "string", readOnly: true },
    dstPort: { type: "number", readOnly: true },
    vulnName: { type: "string", readOnly: true },
    user: { type: "string", readOnly: true },
    domain: { type: "string", readOnly: true },
    action: { type: "string", readOnly: true },
    activity: { type: "string", readOnly: true },
    application: { type: "string", readOnly: true },
    browser: { type: "string", readOnly: true },
    deviceUUID: { type: "string", readOnly: true },
    dstAddressXlated: { type: "string", readOnly: true },
    dstAddressXlatedHex: { type: "string", readOnly: true },
    dstName: { type: "string", readOnly: true },
    dstZone: { type: "string", readOnly: true },
    email: { type: "string", readOnly: true },
    //hash: { type: "string", readOnly: true },
    mac: { type: "string", readOnly: true },
    parentProcess: { type: "string", readOnly: true },
    process: { type: "string", readOnly: true },
    protocol: { type: "string", readOnly: true },
    result: { type: "string", readOnly: true },
    srcAddressXlated: { type: "string", readOnly: true },
    srcAddressXlatedHex: { type: "string", readOnly: true },
    srcName: { type: "string", readOnly: true },
    srcZone: { type: "string", readOnly: true },
    targetUser: { type: "string", readOnly: true },
    userUUID: { type: "string", readOnly: true },
    url: { type: "string", readOnly: true },
    urlPath: { type: "string", readOnly: true },
    maxTimestamp: { type: "string", format: "date-time", readOnly: true },
    minTimestamp: { type: "string", format: "date-time", readOnly: true },
    maxTimestampId: { type: "string", format: "uuid", readOnly: true },
    maxTimestampParsed: { type: "string", format: "date-time", readOnly: true },
    minTimestampParsed: { type: "string", format: "date-time", readOnly: true },
    maxTimestampParsedId: { type: "string", format: "uuid", readOnly: true },
    bytes: { type: "number", readOnly: true },
    total: { type: "number", readOnly: true },
  },
  required: [
    "max_timestamp",
    "min_timestamp",
    "max_timestamp_id",
    "max_timestamp_parsed",
    "max_timestamp_parsed_id",
    "total",
  ],
  additionalProperties: false,
};

export default BQSummarySchema;
