export const logtypeSchema = {
  $id: "/logtype",
  title: "Log Type",
  type: "object",
  properties: {
    id: { type: "number" },
    age: { type: "number" },
    category_names: { type: "array" },
    created: { type: "string", format: "date-time" },
    deleted: { type: "boolean" },
    deleted_by: { type: "string", format: "uuid" },
    description: { type: "string" },
    display_name: { type: "string" },
    first_seen_in_org: { type: "string", format: "date-time" },
    last_seen_in_org: { type: "string", format: "date-time" },
    modified: { type: "string", format: "date-time" },
    modified_by: { type: "string", format: "uuid" },
    name: { type: "string" },
    notes: { type: "string" },
    num_core_schema_columns: { type: "number" },
    num_schema_columns: { type: "number" },
    schema_column_summaries: { type: "array", items: { $ref: "/column" } },
  },
};
