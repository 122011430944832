import _ from "lodash";
import moment from "moment-timezone";

import Logger from "../logger";
import { COUNTRY_ISO_DATA } from "../../views/Components/Charts/sampleData";
import {
  TYPE_TO_NAME_MAPPING,
  STATUS_TO_NAME_MAPPING,
  DATA_QUERIES,
} from "./DataQueryConstants";

const logger = Logger("DataQueryUtil");

export const DataQueryUtil = {
  rawDataToVisualizationDataTransform(data, dataQueryKey) {
    const SUSPECT = TYPE_TO_NAME_MAPPING.SUSPECT.id;
    const THREAT = TYPE_TO_NAME_MAPPING.THREAT.id;

    const DQ = DATA_QUERIES; // shorter alias for readability

    switch (dataQueryKey) {
      case DQ.DASHBOARD_SCROLLER_STATS.key: {
        const dataQueryId1 = _.find(data, (d) => d.dataQueryId === "100");
        const dataQueryId2 = _.find(data, (d) => d.dataQueryId === "2");

        let eventsDetectedAndAnalyized = "0";
        let suspectsIdentifiedAndFiltered = 0;
        let threatsIdentified = 0;

        if (dataQueryId1 && !_.isEmpty(dataQueryId1.data)) {
          eventsDetectedAndAnalyized = _.first(dataQueryId1.data).bigCount;
        }

        if (dataQueryId2 && !_.isEmpty(dataQueryId2.data)) {
          const suspects = dataQueryId2.data.find((d) => d.type === SUSPECT);
          const threats = dataQueryId2.data.find((d) => d.type === THREAT);

          suspectsIdentifiedAndFiltered = (suspects && suspects.count) || 0;
          threatsIdentified = (threats && threats.count) || 0;
        }

        return {
          eventsDetectedAndAnalyized,
          suspectsIdentifiedAndFiltered,
          threatsIdentified,
        };
      }
      case DQ.DASHBOARD_PIE_CHART.key: {
        const dataQueryId3 = _.find(data, (d) => d.dataQueryId === "3");

        const TYPES = [SUSPECT, THREAT];
        const PRIORITIES = [1, 2, 3];

        let totalPriorityCount = 0;

        const filteredData = dataQueryId3.data.filter((d) => {
          if (TYPES.includes(d.type)) {
            totalPriorityCount += d.count;
            return true;
          }
          return false;
        });

        const dataMap = _.keyBy(filteredData, (d) => `${d.type}-${d.priority}`);

        const priorityData = [];

        PRIORITIES.forEach((priority) => {
          TYPES.forEach((type) => {
            let category = "";
            if (type === SUSPECT) {
              category = "suspects";
            } else if (type === THREAT) {
              category = "threats";
            }

            const key = `${type}-${priority}`;
            const count = dataMap[key] ? dataMap[key].count : 0;

            // @TODO: round will chop off all decimals... is this fine?
            const percent = Math.round((count / totalPriorityCount) * 100);
            priorityData.push({
              name: `${category}:p${priority}`,
              value: `p${priority}`,
              category,
              percent,
              count,
            });
          });
        });

        return {
          priority: priorityData,
          severity: [],
        };
      }
      case DQ.DASHBOARD_WORLD_MAP.key: {
        const dataQueryId4 = _.find(data, (d) => d.dataQueryId === "4");
        const dataQueryId5 = _.find(data, (d) => d.dataQueryId === "5");

        let suspectsData = [];
        let threatsData = [];

        if (dataQueryId4 && !_.isEmpty(dataQueryId4.data)) {
          suspectsData = dataQueryId4.data;
        }

        if (dataQueryId5 && !_.isEmpty(dataQueryId5.data)) {
          threatsData = dataQueryId5.data;
        }

        const allCountriesData = [...suspectsData, ...threatsData];

        const allCounts = {};

        allCountriesData.forEach((d) => {
          const countryCode = d.jsonb_array_elements_src_country;

          if (!(countryCode in allCounts)) {
            const country = COUNTRY_ISO_DATA[countryCode];
            if (!country) return;
            const newCountryCount = {
              name: country.name,
              code: country.alpha3,
              count: d.count,
            };
            allCounts[countryCode] = newCountryCount;
          } else {
            allCounts[countryCode].count += d.count;
          }
        });

        const allCountsArray = Object.values(allCounts);

        return allCountsArray;
      }
      case DQ.DASHBOARD_LOCATIONS_TABLE.key: {
        const dataQueryId4 = _.find(data, (d) => d.dataQueryId === "4");
        const dataQueryId5 = _.find(data, (d) => d.dataQueryId === "5");

        let suspectsData = [];
        let threatsData = [];

        if (dataQueryId4 && !_.isEmpty(dataQueryId4.data)) {
          suspectsData = dataQueryId4.data;
        }

        if (dataQueryId5 && !_.isEmpty(dataQueryId5.data)) {
          threatsData = dataQueryId5.data;
        }

        const allCountriesData = {};

        [...suspectsData, ...threatsData].forEach((d) => {
          const countryCode = d.jsonb_array_elements_src_country;
          const country = COUNTRY_ISO_DATA[countryCode];
          if (!country) return;
          const newCountryRow = {
            source: country.name,
            suspects: {
              count: 0,
              p1: 0,
              p2: 0,
              p3: 0,
            },
            threats: {
              count: 0,
              p1: 0,
              p2: 0,
              p3: 0,
            },
          };

          allCountriesData[countryCode] = newCountryRow;
        });

        suspectsData.forEach((d) => {
          const countryCode = d.jsonb_array_elements_src_country;
          const { count, priority } = d;
          if (!allCountriesData[countryCode]) return;
          allCountriesData[countryCode].suspects.count += count;
          allCountriesData[countryCode].suspects[`p${priority}`] += count;
        });

        threatsData.forEach((d) => {
          const countryCode = d.jsonb_array_elements_src_country;
          const { count, priority } = d;
          if (!allCountriesData[countryCode]) return;
          allCountriesData[countryCode].threats.count += count;
          allCountriesData[countryCode].threats[`p${priority}`] += count;
        });

        const countryTableData = Object.values(allCountriesData);

        return countryTableData;
      }
      case DQ.DASHBOARD_RECENT_THREATS_TABLE.key: {
        const dataQueryId12 = _.find(data, (d) => d.dataQueryId === "12");

        let recentThreatsData = [];

        if (dataQueryId12 && !_.isEmpty(dataQueryId12.data)) {
          recentThreatsData = dataQueryId12.data;
        }

        const statusIdMap = _.keyBy(
          Object.values(STATUS_TO_NAME_MAPPING),
          "id"
        );

        const recentThreats = recentThreatsData
          .map((d) => {
            let status = "Unknown";
            if (statusIdMap[d.status]) {
              status = statusIdMap[d.status].name || "Unknown";
            }

            return {
              name: d.name,
              created: d.created,
              priority: d.priority,
              id: d.id,
              status,
            };
          })
          .sort((a, b) => {
            const aUnix = moment(a.created).unix();
            const bUnix = moment(b.created).unix();
            return bUnix - aUnix;
          })
          .slice(0, 5);

        return recentThreats;
      }
      case DQ.DASHBOARD_FINDINGS_DETECTED_GRAPH.key: {
        // @TODO: these deeply nested fetches can become dangerous

        const threatId = TYPE_TO_NAME_MAPPING.THREAT.id;
        const suspectId = TYPE_TO_NAME_MAPPING.SUSPECT.id;

        const stackedBarChart = {
          threats: [],
          suspects: [],
        };

        const format = "YYYY-MM-DDTHH:mm:ssZZ";
        data.sort((a, b) => {
          const aMoment = moment(a.options.timeStart).format(format);
          const bMoment = moment(b.options.timeStart).format(format);

          if (aMoment < bMoment) {
            return -1;
          }

          if (aMoment > bMoment) {
            return 1;
          }

          return 0;
        });

        data.forEach((d) => {
          const findings = {};
          findings[threatId] = {
            1: 0,
            2: 0,
            3: 0,
          };
          findings[suspectId] = {
            1: 0,
            2: 0,
            3: 0,
          };

          d.data.forEach((p) => {
            if (p.type === threatId || p.type === suspectId) {
              findings[p.type][p.priority] = p.count;
            }
          });

          const monthString = moment(d.options.timeStart).format("MMMM");

          const threatsMonthData = {
            label: monthString,
            data: [
              {
                label: monthString,
                P1: findings[threatId][1],
                P2: findings[threatId][2],
                P3: findings[threatId][3],
              },
            ],
          };

          const suspectsMonthData = {
            label: monthString,
            data: [
              {
                label: monthString,
                P1: findings[suspectId][1],
                P2: findings[suspectId][2],
                P3: findings[suspectId][3],
              },
            ],
          };

          stackedBarChart.threats.push(threatsMonthData);
          stackedBarChart.suspects.push(suspectsMonthData);
        });

        return stackedBarChart;
      }
      case DQ.DASHBOARD_FINDINGS_TREND.key: {
        const threatId = TYPE_TO_NAME_MAPPING.THREAT.id;
        const suspectId = TYPE_TO_NAME_MAPPING.SUSPECT.id;

        const lastMonth = _.find(data, (d) => d.options.relativeEnd === null);
        const secondToLastMonth = _.find(
          data,
          (d) => d.options.relativeEnd !== null
        );

        const getPriorityCount = (list, type, priority) => {
          let count = 0;
          const found = _.find(
            list,
            (d) => d.type === type && d.priority === priority
          );
          if (found && found.count) {
            count = found.count;
          }
          return count;
        };

        const generateTrends = (type, lastMonthData, secondToLastMonthData) => {
          const lmP1 = getPriorityCount(lastMonthData, type, 1);
          const slmP1 = getPriorityCount(secondToLastMonthData, type, 1);

          const lmP2 = getPriorityCount(lastMonthData, type, 2);
          const slmP2 = getPriorityCount(secondToLastMonthData, type, 2);

          const lmP3 = getPriorityCount(lastMonthData, type, 3);
          const slmP3 = getPriorityCount(secondToLastMonthData, type, 3);

          const getPriorityTrend = (lmP, slmP) => {
            let trend = { direction: "", color: "" }; // neutral trend
            if (lmP < slmP) {
              trend = { direction: "down", color: "green" }; // positive trend
            } else if (lmP > slmP) {
              trend = { direction: "up", color: "red" }; // negative trend
            }
            return trend;
          };

          const p1Trend = getPriorityTrend(lmP1, slmP1);
          const p2Trend = getPriorityTrend(lmP1, slmP2);
          const p3Trend = getPriorityTrend(lmP3, slmP3);

          const trends = [
            {
              label: "P1",
              value: lmP1,
              ...p1Trend,
            },
            {
              label: "P2",
              value: lmP2,
              ...p2Trend,
            },
            {
              label: "P3",
              value: lmP3,
              ...p3Trend,
            },
          ];

          return trends;
        };

        const threats = generateTrends(
          threatId,
          lastMonth.data,
          secondToLastMonth.data
        );
        const suspects = generateTrends(
          suspectId,
          lastMonth.data,
          secondToLastMonth.data
        );

        return {
          threats,
          suspects,
        };
      }
      case DQ.DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE.key: {
        const dataQueryId11 = _.find(data, (d) => d.dataQueryId === "11");

        const dataMap = _.keyBy(
          dataQueryId11.data,
          (d) => `${d.type}-${d.status}`
        );
        Object.keys(dataMap).forEach((key) => {
          const count = dataMap[key].count;
          dataMap[key] = count;
        });

        const s = STATUS_TO_NAME_MAPPING;

        const S = TYPE_TO_NAME_MAPPING.SUSPECT.id;
        const T = TYPE_TO_NAME_MAPPING.THREAT.id;

        const suspectsOpened = dataMap[`${S}-${s.OPEN.id}`];
        const threatsOpened = dataMap[`${T}-${s.OPEN.id}`];

        const suspectsAnalysisStarted =
          dataMap[`${S}-${s.ANALYSIS_IN_PROGRESS.id}`] || 0;
        const threatsAnalysisStarted =
          dataMap[`${T}-${s.ANALYSIS_IN_PROGRESS.id}`] || 0;

        const suspectsAnalysisCompleted =
          dataMap[`${S}-${s.ANALYSIS_IN_PROGRESS.id}`] || 0;
        const threatsAnalysisCompleted =
          dataMap[`${T}-${s.ANALYSIS_IN_PROGRESS.id}`] || 0;

        const suspectsResponseStarted =
          dataMap[`${S}-${s.RESPONSE_IN_PROGRESS.id}`] || 0;
        const threatsResponseStarted =
          dataMap[`${T}-${s.RESPONSE_IN_PROGRESS.id}`] || 0;

        const suspectsTotalActive =
          suspectsOpened +
          suspectsAnalysisStarted +
          suspectsAnalysisCompleted +
          suspectsResponseStarted;
        const threatsTotalActive =
          threatsOpened +
          threatsAnalysisStarted +
          threatsAnalysisCompleted +
          threatsResponseStarted;

        const suspectsResolved = dataMap[`${S}-${s.CLOSED.id}`] || 0;
        const threatsResolved = dataMap[`${T}-${s.CLOSED.id}`] || 0;

        // @TODO: annoyingly, ReactTable doesn't print out 0s and instead shows
        //  a blank, is there a quick way around this?
        const formattedTableData = [
          {
            status: "Opened",
            suspects: suspectsOpened || "0",
            threats: threatsOpened || "0",
          },
          {
            status: "Analysis Started",
            suspects: suspectsAnalysisStarted || "0",
            threats: threatsAnalysisStarted || "0",
          },
          {
            status: "Analysis Completed",
            suspects: suspectsAnalysisCompleted || "0",
            threats: threatsAnalysisCompleted || "0",
          },
          {
            status: "Response Started",
            suspects: suspectsResponseStarted || "0",
            threats: threatsResponseStarted || "0",
          },
          {
            status: "Total Active",
            suspects: suspectsTotalActive || "0",
            threats: threatsTotalActive || "0",
          },
          {
            status: "Resolved",
            suspects: suspectsResolved || "0",
            threats: threatsResolved || "0",
          },
        ];
        return formattedTableData;
      }
      default: {
        logger.warn(
          "Unhandled data -> viz transformation for data query:",
          dataQueryKey
        );
        return {};
      }
    }
  },
};
