import { styled } from "@mui/styles";
import { Dialog } from "@mui/material";

const PREFIX = "FindingsListViewStyles";
export const rootClasses = {
  flexContainer: `${PREFIX}-flexContainer`,
  scheduledReportButton: `${PREFIX}-scheduledReportButton`,
  presetButtonContainer: `${PREFIX}-presetButtonContainer`,
  labelText: `${PREFIX}-labelText`,
  filterContainer: `${PREFIX}-filterContainer`,
  filterSectionOuterContainer: `${PREFIX}-filterSectionOuterContainer`,
  advancedSectionContainer: `${PREFIX}-advancedSectionContainer`,
  distinctCountContainer: `${PREFIX}-distinctCountContainer`,
  noColumnText: `${PREFIX}-noColumnText`,
  filterSelect: `${PREFIX}-filterSelect`,
  emptyFilterContainer: `${PREFIX}-emptyFilterContainer`,
  titleText: `${PREFIX}-titleText`,
  sectionText: `${PREFIX}-sectionText`,
  chipContainer: `${PREFIX}-chipContainer`,
  chip: `${PREFIX}-chip`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
  select: `${PREFIX}-select`,
  row: `${PREFIX}-row`,
  inputContainer: `${PREFIX}-inputContainer`,
  textContainer: `${PREFIX}-textContainer`,
  sectionTitleText: `${PREFIX}-sectionTitleText`,
  noAssignees: `${PREFIX}-noAssignees`,
  notesDialogContainer: `${PREFIX}-notesDialogContainer`,
  resolutionTextField: `${PREFIX}-resolutionTextField`,
  quickFilterContainer: `${PREFIX}-quickFilterContainer`,
  quickFilterLabel: `${PREFIX}-quickFilterLabel`,
  quickFilterButton: `${PREFIX}-quickFilterButton`,
  exportAlert: `${PREFIX}-exportAlert`,
};

export const dialogClasses = {
  presetDialog: `${PREFIX}-presetDialog`,
  titleOuterContainer: `${PREFIX}-titleOuterContainer`,
  titleContainer: `${PREFIX}-titleContainer`,
  queryContainer: `${PREFIX}-queryContainer`,
  queryNameContainer: `${PREFIX}-queryNameContainer`,
  confirmContainer: `${PREFIX}-confirmContainer`,
  confirmDelete: `${PREFIX}-confirmDelete`,
};

export const priorityClasses = {
  container: `${PREFIX}-container`,
  circle: `${PREFIX}-circle`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.presetDialog}`]: {
    width: 800,
    height: 600,
    paddingHorizontal: 20,
  },
  [`& .${dialogClasses.titleOuterContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${dialogClasses.titleContainer}`]: {
    padding: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  [`& .${dialogClasses.queryContainer}`]: {
    width: "100%",
    display: "flex",
    padding: "0 20px",
    overflowY: "scroll",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [`& .${dialogClasses.queryNameContainer}`]: {
    padding: 10,
    width: "100%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
    "& p": {
      margin: 0,
    },
  },
  [`& .${dialogClasses.confirmContainer}`]: {
    display: "flex",
    alignItems: "center",
    "& p": {
      color: theme.palette.text.secondary,
    },
  },
  [`& .${dialogClasses.confirmDelete}`]: {
    color: theme.palette.error.main,
  },
}));

export const Root = styled("div")(({ theme }) => ({
  [`&.${rootClasses.flexContainer}`]: {
    display: "flex",
  },
  [`& .${rootClasses.scheduledReportsButton}`]: {
    height: 43,
    borderRadius: 10,
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    "& a": {
      margin: 0,
      flexWrap: "no-wrap",
      textTransform: "none",
      textDecoration: "none",
    },
  },
  [`& .${rootClasses.presetButtonContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [`& .${rootClasses.labelText}`]: {
    fontSize: 14,
    marginBottom: 5,
  },
  [`& .${rootClasses.filterContainer}`]: {
    padding: 20,
    borderRadius: 5,
    display: "flex",
    marginBottom: 20,
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.loadingText}`]: {
    fontSize: 20,
  },
  [`& .${rootClasses.filterSectionOuterContainer}`]: {
    width: "100%",
    justifyContent: "flex-start",
  },
  [`& .${rootClasses.advancedSectionContainer}`]: {
    paddingBottom: 20,
  },
  [`& .${rootClasses.distinctCountContainer}`]: {
    display: "flex",
    padding: "20px 0",
    alignItems: "center",
  },
  [`& .${rootClasses.noColumnText}`]: {
    margin: 0,
    padding: 10,
    fontSize: 14,
  },
  [`& .${rootClasses.filterSelect}`]: {
    height: 40,
    padding: 5,
    width: "100%",
    display: "flex",
    borderRadius: 5,
    cursor: "pointer",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    border: `1px solid ${theme.palette.divider}`,
    "& p": {
      margin: "0 0 0 5px",
      color: theme.palette.text.secondary,
    },
  },
  [`& .${rootClasses.emptyFilterContainer}`]: {
    padding: 15,
    fontSize: 15,
    color: "white",
    borderRadius: 10,
    margin: "15px 0",
    backgroundColor: theme.palette.success.main,
    "& p": {
      margin: 0,
    },
  },
  [`& .${rootClasses.titleText}`]: {
    margin: 0,
    fontWeight: "bold",
  },
  [`& .${rootClasses.sectionText}`]: {
    fontSize: 15,
    marginBottom: 5,
    marginRight: 10,
    color: theme.palette.text.primary,
  },
  [`& .${rootClasses.chipContainer}`]: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  [`& .${rootClasses.chip}`]: {
    margin: 5,
  },
  [`& .${rootClasses.buttonContainer}`]: {
    paddingTop: 20,
    display: "flex",
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`& .${rootClasses.button}`]: {
    width: 100,
    height: 50,
    margin: "10px 0",
    padding: "0 50px",
    boxShadow: "none",
    textTransform: "none",
  },
  [`& .${rootClasses.select}`]: {
    width: 200,
    borderRadius: 20,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.row}`]: {
    width: "100%",
    display: "flex",
    margin: "10px 0",
    alignItems: "center",
  },
  [`& .${rootClasses.inputContainer}`]: {
    display: "flex",
    marginRight: 10,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  [`& .${rootClasses.textContainer}`]: {
    minWidth: 250,
    display: "flex",
    borderRadius: 10,
    cursor: "pointer",
    alignItems: "center",
    padding: "10px 15px",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    minHeight: "56px",
    marginBottom: "22px",
    "& p": {
      fontSize: 15,
      marginBottom: 0,
    },
  },
  [`& .${rootClasses.sectionTitleText}`]: {
    margin: 0,
    fontSize: 13,
    padding: "10px 0",
    fontWeight: "bold",
  },
  [`& .${rootClasses.noAssignees}`]: {
    fontStyle: "italic",
  },
  [`& .${rootClasses.notesDialogContainer}`]: {
    padding: 20,
    width: 400,
  },
  [`& .${rootClasses.resolutionTextField}`]: {
    width: "100%",
  },
  [`& .${rootClasses.quickFilterContainer}`]: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  [`& .${rootClasses.quickFilterLabel}`]: {
    marginRight: 20,
  },
  [`& .${rootClasses.quickFilterButton}`]: {
    textTransform: "none",
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.action.toggleButtonHover,
    },
  },
  [`& .${rootClasses.exportAlert}`]: {
    marginBottom: theme.shape.padding,
  },
}));

export const PrioritySpan = styled("span")((theme) => ({
  [`& .${priorityClasses.container}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${priorityClasses.circle}`]: {
    width: 15,
    height: 15,
    marginRight: 5,
    borderRadius: "50%",
  },
}));
