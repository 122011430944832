// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { useState, useEffect } from "react";
import { arrayOf, bool, func, shape, string } from "prop-types";

// ** Components **********************
import { DefaultIcon } from "views/Components/Icon";

// ** Style ***************************
import { SortableHeaderCell, TableHeader, TableHeaderCell } from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Results table header
//
//  ** Remarks
//  Displays column headers
//
//  ** Props
//  activeCols {array} - list of active cols
//  onSort {func} - invoked on sortable header cell click
//  selectedColumns {array} - user selected column values
//  sorting {shape} - current sorting value and direction
//

const Header = (props) => {
  const [availableColumnData, setAvailableColumnData] = useState([]);
  const [specialColumns, setSpecialColumns] = useState(props.specialColumns); // eslint-disable-line no-unused-vars
  const [sorting, setSorting] = useState(props.sorting); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (props.selectedColumns) {
      const selected = props.selectedColumns.filter(
        (colObject) => props.activeCols.indexOf(colObject.name) >= 0
      );
      // reorder by activeCols so we maintain a common order everywhere
      const newSelected = selected.sort(
        (a, b) =>
          props.activeCols.indexOf(a.name) - props.activeCols.indexOf(b.name)
      );
      setAvailableColumnData(newSelected);
    }
  }, [props.activeCols, props.selectedColumns]);

  useEffect(() => {
    setSorting(props.sorting);
  }, [props.sorting]);

  return (
    <TableHeader>
      {specialColumns.map((col) => (
        <SortableHeaderCell
          key={col.name}
          onClick={() => props.onSort(col.name)}
        >
          {col.display_name}
          {props.sorting.key === col.name && (
            <DefaultIcon
              height="8px"
              icon={props.sorting.dir ? "upArrow" : "downArrow"}
              margin="0px 0px 0px 8px"
            />
          )}
        </SortableHeaderCell>
      ))}
      {availableColumnData.map((col) => (
        <SortableHeaderCell
          key={col.name}
          onClick={() => props.onSort(col.name)}
        >
          {col.display_name || col.name}
          {props.sorting.key === col.name && (
            <DefaultIcon
              height="8px"
              icon={props.sorting.dir ? "upArrow" : "downArrow"}
              margin="0px 0px 0px 8px"
            />
          )}
        </SortableHeaderCell>
      ))}
      {props.blobCols.length > 0 && (
        <TableHeaderCell flex={3}>Data</TableHeaderCell>
      )}
    </TableHeader>
  );
};

//  ** Prop types *********************
Header.propTypes = {
  blobCols: arrayOf(string).isRequired,
  activeCols: arrayOf(string).isRequired,
  onSort: func.isRequired,
  selectedColumns: arrayOf(shape()).isRequired,
  sorting: shape({
    dir: bool,
    key: string,
  }).isRequired,
};

// default props
Header.defaultProps = {
  blobCols: [],
  activeCols: [],
  selectedColumns: [],
};

export default Header;
