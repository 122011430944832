// TODO: account service is only returning Tier 1 as it stands

export const tiers = [
  // This is being removed until Phase 2 as per discussion during Kickoff
  // {
  //   tier: 0,
  //   name: "Pay as you go",
  //   prices: ["$12", "$10.50", "$7", "$1.50", "Free"],
  // },
  {
    tier: 1,
    prices: ["$10", "$8.50", "$5", "$1.50", "Free"],
  },
  {
    tier: 2,
    name: "Tier 2",
    prices: ["$9", "$7.50", "$4.50", "$1.50", "Free"],
  },
  {
    tier: 3,
    name: "Tier 3",
    prices: ["$8", "$6.50", "$4", "$1.50", "Free"],
  },
  {
    tier: 4,
    name: "Tier 4",
    prices: ["$7.50", "$6", "$3.50", "$1.50", "Free"],
  },
  {
    tier: 5,
    name: "Tier 5",
    prices: ["$6.50", "$5.50", "$3", "$1.20", "Free"],
  },
];
