// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { any, bool, func, string } from "prop-types";

// ** Styles **************************
import {
  InputContainerStyle,
  InputErrorStyle,
  InputLabelStyle,
  InputStyle,
} from "./style";

// ** Utils ***************************
import withProps from "../../../utils/lib/withProps";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Input component
//
//  ** Remarks
//  Simple input component extended to simplify code across the app
//
//  ** Props
//  @param error {boolean} - input validation flag
//  @param errorMessage {string} - message displayed if error is present
//  @param id {string} - input id value
//  @param label {string} - input label
//  @param minimal {boolean} - minimal style flag
//  @param onBlur {func} - function invoked when input loses focus
//  @param onChange {func} - function invoked when a change happens
//  @param onFocus {func} - function invoked when input is focused
//  @param pattern {string} - regex for input value validation
//  @param placeholder {string} - input placeholder value
//  @param type {string} - input type
//  @param value {string} - input value
//
class Input extends Component {
  // Invoked on input blur
  onBlur(e) {
    const { onBlur, pattern } = this.props;

    if (onBlur && pattern) {
      onBlur(e.target.id, pattern.test(e.target.value));
    }
  }

  // Invoked on input change
  onChange(e) {
    const { onBlur, onChange, pattern } = this.props;

    onChange(e.target.id, e.target.value);

    if (onBlur && pattern) {
      onBlur(e.target.id, pattern.test(e.target.value));
    }
  }

  render() {
    const {
      disabled,
      error,
      errorMessage,
      id,
      label,
      minimal,
      onFocus,
      padding,
      pattern,
      placeholder,
      type,
      value,
    } = this.props;

    return (
      <InputContainerStyle minimal={minimal}>
        {label && <InputLabelStyle>{label}</InputLabelStyle>}
        <InputStyle
          autoComplete="off"
          disabled={disabled}
          id={id}
          error={error}
          minimal={minimal}
          onBlur={(e) => this.onBlur(e)}
          onChange={(e) => this.onChange(e)}
          onFocus={onFocus}
          padding={padding}
          pattern={pattern}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        <InputErrorStyle error={error}>{errorMessage}</InputErrorStyle>
      </InputContainerStyle>
    );
  }
}

// ** Proptypes ***********************
Input.propTypes = {
  disabled: bool,
  error: bool,
  errorMessage: string,
  id: string.isRequired,
  label: string,
  minimal: bool,
  padding: string,
  onChange: func.isRequired,
  onBlur: func,
  onFocus: func,
  pattern: any,
  placeholder: string,
  type: string,
  value: string,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Default input *******************
export const DefaultInput = withProps((props) => ({
  disabled: props.disabled,
  error: props.error,
  errorMessage: props.errorMessage || "This field cannot be left blank",
  id: props.id,
  label: props.label,
  onBlur: props.onBlur,
  onChange: props.onChange,
  pattern: /\S/,
  placeholder: props.placeholder,
  type: props.type || "text",
  value: props.value || "",
}))(Input);

// ** Minimal input *******************
export const MinimalInput = withProps((props) => ({
  disabled: props.disabled,
  error: props.error,
  errorMessage: props.errorMessage || "This field cannot be left blank",
  id: props.id,
  label: props.label,
  minimal: true,
  onBlur: props.onBlur,
  onChange: props.onChange,
  padding: props.padding,
  pattern: /\S/,
  placeholder: props.placeholder,
  type: props.type || "text",
  value: props.value || "",
}))(Input);

export default Input;
