// 1 will be at the head of the list, then 2, 3, etc.
const typeOrder = {
  20: 1, // Suspects
  30: 2, // Threats
  15: 3, // Risk
  40: 4, // Alerts
  10: 5, // Operational
};

const any = (obj) => Object.keys(obj).length > 0;

// earliest created first
export const dateSorter = (findingA, findingB) =>
  findingA.created - findingB.created <= 0 ? 1 : -1;

// lowest priority number meaning highest priority first - P1
export const prioritySorter = (findingA, findingB) => {
  const d = findingA.priority - findingB.priority;

  if (d === 0) {
    return dateSorter(findingA, findingB);
  }

  return d;
};

export const ownerSorter = (findingA, findingB, userId) => {
  const ownersA = {};
  findingA.owners.responders.forEach((ownerId) => {
    ownersA[ownerId] = "responder";
  });
  const ownersB = {};
  findingB.owners.responders.forEach((ownerId) => {
    ownersB[ownerId] = "responder";
  });

  if (ownersA[userId] && ownersB[userId]) {
    return prioritySorter(findingA, findingB);
  }

  if (ownersA[userId] && !ownersB[userId]) {
    return -1;
  }
  if (ownersB[userId] && !ownersA[userId]) {
    return 1;
  }

  // Other owners for both
  if (any(ownersA) && any(ownersB)) {
    return prioritySorter(findingA, findingB);
  }

  // No owners for A and other owners for B, A comes first
  if (!any(ownersA) && any(ownersB)) {
    return -1;
  }

  // No owners for B and other owners for A, B comes first
  if (any(ownersA) && !any(ownersB)) {
    return 1;
  }

  // Nobody owns either Finding, sort by priority
  if (!any(ownersA) && !any(ownersB)) {
    return prioritySorter(findingA, findingB);
  }
};

export const typeSorter = (findingA, findingB, userId) => {
  const d = typeOrder[findingA.type] - typeOrder[findingB.type];

  if (d === 0) {
    return ownerSorter(findingA, findingB, userId);
  }

  return d;
};

// TODO: eventually this information should be
// provided through the column service
export const findingColumnOptions = [
  {
    field: "status",
    display_name: "Status",
    options: [
      { value: 0, label: "Open" },
      { value: 10, label: "Analysis in Progress" },
      { value: 20, label: "Analysis Complete" },
      { value: 30, label: "Response in Progress" },
      { value: 40, label: "Resolved" },
    ],
  },
  {
    field: "shortId",
    display_name: "Short Id",
  },
  {
    field: "type",
    display_name: "Type",
    options: [
      { value: 10, label: "Operational" },
      { value: 15, label: "Risk" },
      { value: 20, label: "Suspect" },
      { value: 30, label: "Threat" },
      { value: 40, label: "System" },
    ],
  },
  {
    field: "resolution",
    display_name: "Resolution",
    options: [
      { value: 10, label: "Valid" },
      { value: 20, label: "False Positive" },
      { value: 30, label: "No Action Needed" },
      { value: 40, label: "Risk Accepted" },
    ],
  },
  {
    field: "category",
    display_name: "Category",
    options: [
      { value: 0, label: "Unknown" },
      { value: 10, label: "Unauthorized Access Attempt" },
      { value: 20, label: "Malicious Code" },
      { value: 30, label: "Vulnerability Exploit Attempt" },
      { value: 40, label: "Reconnaissance" },
      { value: 50, label: "Denial of Service" },
      { value: 60, label: "Policy Violation" },
      { value: 70, label: "Assets" },
      { value: 80, label: "Authentication" },
      { value: 90, label: "Code Injection" },
      { value: 100, label: "Collection" },
      { value: 110, label: "Command and Control" },
      { value: 120, label: "Command Line Logging" },
      { value: 125, label: "Credential Access" },
      { value: 130, label: "Defense Evasion" },
      { value: 140, label: "Discovery/Reconnaissance" },
      { value: 150, label: "EventIDs" },
      { value: 160, label: "Execution" },
      { value: 170, label: "Exfiltration" },
      { value: 175, label: "Impact" },
      { value: 180, label: "Initial Access" },
      { value: 190, label: "Internal" },
      { value: 200, label: "Lateral Movement" },
      { value: 210, label: "Persistance" },
      { value: 220, label: "Privilege Escalation" },
      { value: 225, label: "Resource Development" },
      { value: 230, label: "Threats" },
      { value: 240, label: "Users and Groups" },
    ],
  },
  {
    field: "priority",
    display_name: "Priority",
    options: [
      { value: 1, label: "P1" },
      { value: 2, label: "P2" },
      { value: 3, label: "P3" },
    ],
  },
  {
    field: "id",
    display_name: "Id",
  },
  {
    // TO DO: eventually this should get operators from column service
    field: "analysis",
    display_name: "Analysis",
  },
  {
    // TO DO: eventually this should get operators from column service
    field: "name",
    display_name: "Name",
  },
  {
    // TO DO: eventually this should get operators from column service
    field: "summary",
    display_name: "Summary",
  },
  {
    // TO DO: eventually this should get operators from column service
    field: "created",
    display_name: "Created",
  },
  {
    field: "jurisdiction",
    display_name: "Jurisdiction",
    options: [
      { value: 10, label: "administrator" },
      { value: 20, label: "manager" },
      { value: 30, label: "analyst" },
      { value: 40, label: "responder" },
    ],
  },
  {
    field: "blocked",
    display_name: "Blocked",
    options: [
      { value: true, label: "Blocked" },
      { value: false, label: "Not Blocked" },
    ],
  },
  {
    field: "owners",
    display_name: "Assignees",
  },
];
