import React from "react";
import PropTypes from "prop-types";

import DataGrid from "../../Components/DataGrid";

const InfoTable = (props) => {
  const { data = [] } = props;

  return (
    <DataGrid
      defaultPageSize={20}
      data={data}
      columns={[
        {
          Header: "Key",
          accessor: "keyAccessor",
          filterable: false,
          Cell: (row) => row.original.key,
          maxWidth: 195,
          minWidth: 120,
        },
        {
          Header: "Value",
          accessor: "valueAccessor",
          filterable: false,
          sortable: false,
          style: { whiteSpace: "unset", wordBreak: "break-word" },
          Cell: (row) => row.original.value,
        },
      ]}
      showPagination={false}
    />
  );
};

InfoTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

InfoTable.defaultProps = {
  data: [],
};

export default InfoTable;
