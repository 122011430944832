import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

import { formatNumberCustom } from "../../../utils/index";

const Scroller = ({
  scrollerOpen,
  toggleScroller,
  scrollerStats: {
    eventsDetectedAndAnalyized,
    suspectsIdentifiedAndFiltered,
    threatsIdentified,
  },
}) => {
  // eventsDetectedAndAnalyized is a string
  const {
    value: eventsValue = eventsDetectedAndAnalyized,
    suffix: eventsSuffix = "",
  } = formatNumberCustom(eventsDetectedAndAnalyized || "");

  return (
    <div className="scroller flex" data-open={scrollerOpen}>
      <i
        style={{
          position: "absolute",
          bottom: "2px",
          right: "4px",
          color: "#FFFFFF",
          cursor: "pointer",
        }}
        tabIndex={-1}
        role="button"
        className={`fas fa-${scrollerOpen ? "angle-up" : "angle-down"}`}
        onClick={toggleScroller}
      />
      <div className="row">
        <div className="col-4 left-column justify-content-center d-flex">
          <div className="align-self-top" style={{ textAlign: "center" }}>
            <h2>
              <NumberFormat
                isNumericString
                suffix={eventsSuffix}
                value={eventsValue}
                displayType="text"
                thousandSeparator
                data-cy="eventsCounter"
              />
            </h2>
            <h3>Events Detected and Analyzed</h3>
          </div>
        </div>
        <div className="col-4 middle-column justify-content-center d-flex">
          <div className="align-self-top" style={{ textAlign: "center" }}>
            <h2>
              <NumberFormat
                value={suspectsIdentifiedAndFiltered}
                displayType="text"
                thousandSeparator
                data-cy="suspectCounter"
              />
            </h2>
            <h3>Suspects Identified and Filtered</h3>
          </div>
        </div>
        <div className="col-4 right-column justify-content-center d-flex">
          <div className="align-self-top" style={{ textAlign: "center" }}>
            <h2>
              <NumberFormat
                value={threatsIdentified}
                displayType="text"
                thousandSeparator
                data-cy="threatsCounter"
              />
            </h2>
            <h3>Threats Identified</h3>
          </div>
        </div>
      </div>
      <div className="scroller-bg" />
    </div>
  );
};

Scroller.propTypes = {
  scrollerOpen: PropTypes.bool.isRequired,
  toggleScroller: PropTypes.func.isRequired,
  scrollerStats: PropTypes.shape({
    eventsDetectedAndAnalyized: PropTypes.string,
    suspectsIdentifiedAndFiltered: PropTypes.number,
    threatsIdentified: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  scrollerOpen: state.settings.scrollerOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleScroller: () => dispatch({ type: "SETTINGS.TOGGLE_SCROLLER" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Scroller);
