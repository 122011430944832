import _ from "lodash";

import { DataQueryApi } from "../../lib/api";
import { FORCE_EMPTY_DATA_STUBS } from "../../lib/api/DataQueryAPI";
import {
  DATA_QUERY_EMPTY_STUB,
  DATA_QUERIES,
} from "../../lib/util/DataQueryConstants";
import { auth } from "../../lib/auth";
import { normalizeError } from "../../lib/helpers";
import Logger from "../../lib/logger";
import { apiErrorMessageForUi } from "../utils/helpers";

export const DATA_QUERY_PROCESS_START = "DATA_QUERY_PROCESS_START";
export const DATA_QUERY_PROCESS_SUCCESS = "DATA_QUERY_PROCESS_SUCCESS";
export const DATA_QUERY_PROCESS_FAILURE = "DATA_QUERY_PROCESS_FAILURE";
export const RESET_DATA_QUERY = "DATA_QUERY.RESET";

const logger = Logger("DataQuery");

const API = {
  dataQuery: new DataQueryApi(),
};

const PREPEND_MESSAGE = "There was an issue processing your data query:";

function dataQueryProcessStart(dataQueryKey) {
  return {
    type: DATA_QUERY_PROCESS_START,
    dataQueryKey,
  };
}

function dataQueryProcessSuccess(dataQueryKey, data) {
  return {
    type: DATA_QUERY_PROCESS_SUCCESS,
    dataQueryKey,
    data,
  };
}

function dataQueryProcessFailure(dataQueryKey, error) {
  return {
    type: DATA_QUERY_PROCESS_FAILURE,
    error,
    dataQueryKey,
  };
}

export const resetDataQuery = {
  type: RESET_DATA_QUERY,
};

//---------------------------------------------------------------------------
// Re-usable thunks / mixins
//---------------------------------------------------------------------------

const processDataQueriesMixin =
  (orgId, personId, dataQueries) => (dispatch) => {
    dataQueries.forEach((dataQueryKey) => {
      dispatch(dataQueryProcessStart(dataQueryKey));

      const compositeData = [];
      const errors = [];

      const DQ = DATA_QUERIES[dataQueryKey];

      const promises = DQ.queries.map((query) => {
        const { dataQueryId, options = {} } = query;

        return API.dataQuery
          .processDataQuery({
            orgId,
            personId,
            dataQueryId,
            options,
          })
          .then(({ data = null }) => ({ dataQueryId, options, data }))
          .then((apiData) => {
            if (FORCE_EMPTY_DATA_STUBS) {
              logger.warn(`Using empty data stub for ${dataQueryKey}`);
              compositeData.push({
                ...apiData,
                data: DATA_QUERY_EMPTY_STUB.data,
              });
            } else {
              compositeData.push(apiData);
            }
          })
          .catch((err) => {
            logger.error(err);

            const message = apiErrorMessageForUi({
              message: "Error processing data query.",
              err,
            });

            errors.push(new Error(message));
          });
      });

      Promise.all(promises).then(() => {
        // Just the first error
        const error = _.first(errors);
        if (error) {
          dispatch(
            dataQueryProcessFailure(
              dataQueryKey,
              normalizeError(error, { prependMessage: PREPEND_MESSAGE })
            )
          );
        } else {
          dispatch(dataQueryProcessSuccess(dataQueryKey, compositeData));
        }
      });
    });
  };

//---------------------------------------------------------------------------
// Exported action creators.
//---------------------------------------------------------------------------

export const processDataQueries = (orgId, dataQueryKey = null) => {
  const personId = auth.person_id;

  const dataQueries = dataQueryKey ? [dataQueryKey] : Object.keys(DATA_QUERIES);

  return (dispatch) => {
    dispatch(processDataQueriesMixin(orgId, personId, dataQueries));
  };
};
