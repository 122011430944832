import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Container, Row, Col } from "reactstrap";

import "./BlockDevices.scss";

/*
Helpers
*/
const handleClick = (evt) => {
  evt.target.select();
};

/*
Returns a single block configuration file row
*/
const BlockDeviceRow = ({ label, url, index }) => (
  <Row key={index} className="blocking-devices-row">
    <Col xs="3" className="blocking-devices-label">
      <div>{label}</div>
    </Col>

    <Col xs="9" className="blocking-devices-url">
      <input type="text" value={url} readOnly onClick={handleClick} />
    </Col>
  </Row>
);

BlockDeviceRow.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  index: PropTypes.number,
};

BlockDeviceRow.defaultProps = {
  label: "",
  url: "",
  index: 0,
};

/*
Returns the list of block configuration files rows
*/
const getRows = (blockConfigurationFiles) => {
  const displayRows = Object.keys(blockConfigurationFiles || {}).map(
    (key, index) => {
      const { url = "" } = blockConfigurationFiles[key];

      return BlockDeviceRow({ label: key, url, index });
    }
  );

  return displayRows;
};

/*
Main component
*/
const BlockDevices = ({ orgConfig = {} }) => {
  const { blockConfigurationFiles = {} } = orgConfig;

  const displayRows = getRows(blockConfigurationFiles);

  if (displayRows.length < 1) {
    return null;
  }

  return (
    <div className="blocking-devices">
      <Container>{displayRows}</Container>
    </div>
  );
};

BlockDevices.propTypes = {
  orgConfig: PropTypes.shape({}).isRequired,
};

BlockDevices.defaultProps = {};

const mapStateToProps = (state) => {
  const { blocking = {}, location = {} } = state;

  const { config = {} } = blocking;
  const { byOrg = {} } = config;

  const { payload = {} } = location;
  const { orgId } = payload;

  const orgConfig = byOrg[orgId] || {};

  return {
    orgConfig,
    orgId,
  };
};

export default connect(mapStateToProps, null)(BlockDevices);
