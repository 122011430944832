export const orgColumnStatsSchema = {
  $id: "/orgColumnStats",
  title: "OrgColumnStats",
  type: "object",
  properties: {
    running_recent: { type: "object" },
    running_history: { type: "object" },
  },
  required: [],
};
