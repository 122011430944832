import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Tooltip } from "reactstrap";
import Select from "react-select";
import Link from "redux-first-router-link";

import { sortByName } from "../../../lib/helpers";
import {
  SYSTEM_LABELS_KEY,
  createTagAndFetch,
  createLabelAndTagAndFetch,
} from "../../../redux/actions/Tagging";
import { isInside } from "../../../utils";

import "./Labels.scss";

/*
This component displays the Labels select dropdown. More precisely,
it displays a 'label' icon. When the icon is clicked, a dropdown appears.
The dropdown contains the following.
  (1) An open select that allows the user to select a label for tagging
    the object.
  (2) A 'Manage' button that links to the 'settings/labels' page
  (3) If the user is a superadmin, a 'Restrict' checkbox. If checked,
    only global labels are available. If an  object is tagged in the
    restricted mode, only a superadmin can remove the tag.

The Labels can be used with or without the ObjTags component (that displays
the object's tags).

For a component that displays the labels select dropdown together with
the object's tags, see LabelsAndTags.

Gets the data from Redux 'tagging' but does not fetch it on mount.
Use it with LabelsFetch, or use LabelsAndTagsWithFetch instead.
*/
const Labels = (props) => {
  const [error, setError] = useState(null);
  const [removalRestricted, setRemovalRestricted] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [targetId] = useState(`id_${props.objId}`);
  const [tooltipOpen, setTooltipState] = useState(false);

  /*
  Resets the state on 'objId' change
  */
  useEffect(() => {
    setError(null);
    setRemovalRestricted(false);
    setShowSelect(false);
  }, [props.objId]);

  /*
  Closes the dropdown if data has been reloaded
  */
  useEffect(() => {
    if (props.loading === false) {
      setShowSelect(false);
    }
  }, [props.loading]);

  /*
  Closes the dropdown on Escape 'keyup'
  */
  useEffect(() => {
    const handleKeyUp = (evt) => {
      if (evt.code === "Escape" && showSelect) {
        setShowSelect(false);
      }
    };

    window.addEventListener("keyup", handleKeyUp, true);

    return () => window.removeEventListener("keyup", handleKeyUp, true);
  });

  const labelsContainer = useRef();

  /*
  Closes the dropdown on mouse 'click' outside Labels component.
  */
  useEffect(() => {
    const handleClick = (evt) => {
      const inside = isInside(evt, labelsContainer.current);

      if (!inside && showSelect) {
        setShowSelect(false);
      }
    };

    window.addEventListener("click", handleClick, false);

    return () => window.removeEventListener("click", handleClick, false);
  });

  /*
  Copies error from props to state.
  */
  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  /*
  Clears error on state changes
  */
  useEffect(() => {
    setError(null);
  }, [removalRestricted, showSelect]);

  /*
  Toggles the Labels tooltip
  */
  const toggleTooltip = () => {
    setTooltipState(!tooltipOpen);
  };

  /*
  Opens/closes the 'Select label' dropdown
  */
  const toggle = () => {
    setShowSelect(!showSelect);
  };

  /*
  Toggles removalRestricted
  */
  const toggleResrictRemoval = () => {
    setRemovalRestricted(!removalRestricted);
  };

  /*
  Called when an existing label (to tag the object with) is selected from the drop-down.
  */
  const handleChange = (newValue) => {
    const { objId = null, objType = null } = props;

    const labelId = newValue.value;

    props.createTagAndFetch({
      objId,
      objType,
      labelId,
      removalRestricted,
    });
  };

  const {
    labels: allLabels = {},
    loading = false,
    objId,
    orgId = "default",
    superadmin = false,
    tags: allTags = {},
  } = props;

  const labels = allLabels[SYSTEM_LABELS_KEY]
    ? (allLabels[orgId] || []).concat(allLabels[SYSTEM_LABELS_KEY])
    : allLabels[orgId] || [];
  const tags = allTags[objId] || [];

  let labelOptions = [...labels];

  // If adding a removal-restricted tag, filter out all custom labels
  if (superadmin && removalRestricted) {
    labelOptions = labelOptions.filter((elt) => !elt.orgId);
  }

  labelOptions = labelOptions
    .filter((elt) => !tags.some((e) => e.labelId === elt.id))
    .sort(sortByName)
    .map((elt) => ({ value: elt.id, label: elt.name }));

  return (
    <div className="labels-container" ref={labelsContainer}>
      <Button
        className="label-button"
        color="white"
        onClick={toggle}
        id={targetId}
        disabled={loading}
      >
        <i className="fas fa-tag" /> &bull;&bull;&bull;
      </Button>

      <Tooltip
        className="labels-btn-tooltip"
        placement="bottom"
        isOpen={tooltipOpen}
        target={targetId}
        toggle={toggleTooltip}
      >
        Tags
      </Tooltip>

      {!loading && showSelect && (
        <div className="labels-select-backdrop">
          <div className="labels-select-header">
            {superadmin && (
              <div className="restrict-checkbox">
                <input
                  type="checkbox"
                  className="restrict-checkbox-checkbox"
                  onChange={toggleResrictRemoval}
                  checked={removalRestricted}
                />
                <div className="restrict-checkbox-label">Restricted</div>
              </div>
            )}

            <Link
              to={{
                type: "PAGE",
                payload: { orgId, toplevel: "settings", secondlevel: "tags" },
              }}
            >
              <Button className="manage-link">Manage</Button>
            </Link>
          </div>

          {error && <div className="alert alert-danger">{error.message}</div>}

          <div className="labels-select">
            <Select menuIsOpen options={labelOptions} onChange={handleChange} />
          </div>
        </div>
      )}
    </div>
  );
};

Labels.propTypes = {
  error: PropTypes.shape({ message: PropTypes.string }),
  createLabelAndTagAndFetch: PropTypes.func.isRequired,
  createTagAndFetch: PropTypes.func.isRequired,
  labels: PropTypes.shape({}),
  loading: PropTypes.bool,
  objId: PropTypes.string.isRequired,
  objType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  superadmin: PropTypes.bool,
  tags: PropTypes.shape({}),
};

Labels.defaultProps = {
  error: null,
  labels: {},
  loading: false,
  superadmin: false,
  tags: {},
};

const mapStateToProps = (state) => {
  const {
    error = null,
    labels = {},
    labelsLoading = false,
    tagsLoading = false,
    tags = {},
  } = state.tagging;

  return {
    error,
    labels,
    loading: labelsLoading || tagsLoading,
    tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createLabelAndTagAndFetch: ({
    objId,
    objType,
    label,
    removalRestricted,
    orgId,
  }) => {
    dispatch(
      createLabelAndTagAndFetch({
        objId,
        objType,
        label,
        removalRestricted,
        orgId,
      })
    );
  },
  createTagAndFetch: ({ objId, objType, labelId, removalRestricted }) =>
    dispatch(
      createTagAndFetch({
        objId,
        objType,
        labelId,
        removalRestricted,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
