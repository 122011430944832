import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import clsx from "clsx";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import LicenseRestriction from "views/Components/License";
import Zendesk from "./Components/Zendesk";

import { get } from "lodash";
import { getUserOrgRoles } from "utils/sidebarUtils";
import { WILDCARD_ORG_ID } from "lib/api/API";
import moment from "moment";

import {
  isSecondLevelSelected,
  getMenu,
  renderSpacerGridItem,
} from "../utils/sidebarUtils";

const PREFIX = "SidebarView2";

const classes = {
  title: `${PREFIX}-title`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  sideNav: `${PREFIX}-sideNav`,
  selectedMenuItem: `${PREFIX}-selectedMenuItem`,
  menuItem: `${PREFIX}-menuItem`,
  xdrUpsellContainer: `${PREFIX}-xdrUpsellContainer`,
  tryXdrButton: `${PREFIX}-tryXdrButton`,
  progressBar: `${PREFIX}-progressBar`,
  redProgressBar: `${PREFIX}-redProgressBar`,
  childLevelBadge: `${PREFIX}-childLevelBadge`,
};

const Root = styled(Drawer)(({ theme }) => ({
  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.drawerPaper}`]: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  [`& .${classes.drawerPaperClose}`]: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
  },
  [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,
  [`& .${classes.sideNav}`]: {
    paddingBottom: 300,
  },
  [`& .${classes.selectedMenuItem}`]: {
    backgroundColor: theme.palette.action.hover,
  },
  [`& .${classes.menuItem}`]: {
    textTransform: "uppercase",
  },
  [`& .${classes.xdrUpsellContainer}`]: {
    fontSize: 12,
    display: "flex",
    borderRadius: 10,
    flexDirection: "column",
    backgroundColor: "#d8eada",
    border: "1px solid #a3cda8",
    padding: theme.shape.padding,
    margin: theme.shape.padding,
    color: "#3B3B3B",
    "& p": {
      textWrap: "wrap",
      textAlign: "center",
      fontSize: "14px",
    },
  },
  [`& .${classes.tryXdrButton}`]: {
    color: "white",
    backgroundColor: "#3B3B3B",
    boxShadow: "3px 3px 5px rgb(0, 0, 0, 0.25)",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#3B3B3B",
    },
  },
  [`& .${classes.progressBar}`]: {
    height: 10,
  },
  [`& .${classes.redProgressBar}`]: {
    height: 10,
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: "red",
    },
  },
  [`& .${classes.childLevelBadge}`]: {
    "& .MuiBadge-badge": {
      right: "unset",
    },
  },
}));

const drawerWidth = 220;

const SidebarView = (props) => {
  const [open, setOpen] = useState(true);
  const [menuItems, setMenuItems] = useState([]);

  const [selectedMenuItem, setSelectedMenuItem] = useState({
    selected: {
      toplevel: props.payload.toplevel,
      secondlevel: props.payload.secondlevel,
    },
    previous: null,
  });

  const [exit, setExit] = useState(false); // eslint-disable-line no-unused-vars

  useEffect(() => {
    let newMenuItems = getMenu(props.orgId, props.settings);

    setMenuItems(newMenuItems);
  }, [props.orgId]);

  useEffect(() => {
    setSelectedMenuItem({
      selected: {
        toplevel: props.payload.toplevel,
        secondlevel: props.payload.secondlevel,
      },
      previous: selectedMenuItem.previous,
    });
  }, [props.payload]);

  const handleDrawerOpen = () => {
    setSelectedMenuItem({
      selected: selectedMenuItem.previous,
      previous: null,
    });
    setOpen(true);
  };

  const handleDrawerClose = () => {
    handleMenuSelect("");
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const handleMenuSelect = (menuType) => {
    if (open === false) {
      setOpen(true);
    }
    if (menuType === selectedMenuItem.selected.toplevel) {
      menuType = "";
    }
    setSelectedMenuItem({
      selected: {
        toplevel: menuType,
        secondlevel: selectedMenuItem.selected.secondlevel,
      },
      previous: selectedMenuItem.selected,
    });
  };

  const handleMenuNavigate = (menuType, childMenuType) => {
    props.dispatch({
      type: "PAGE",
      pageHash: {},
      payload: {
        orgId: props.orgId,
        toplevel: menuType,
        secondlevel: childMenuType,
      },
    });
  };

  const handleViewBilling = () => {
    props.goToBillingView(props.currentOrgId);
  };

  const gracePeriodDisplayText = () => {
    if (graceDaysRemaining() === 0) {
      return 'Last day of trial "overtime"';
    } else if (graceDaysRemaining() === 1) {
      return '1 day left in trial "overtime"';
    } else {
      return `${graceDaysRemaining()} days left in trial "overtime"`;
    }
  };

  const whatTrialExperienceToShow = () => {
    // Check if trial trial is expired
    const now = moment.utc().toISOString();
    const orgHasScheduleLicense = props.currentOrg?.config?.license_scheduled;
    const isScheduledLicenseActive = orgHasScheduleLicense
      ? props.currentOrg?.config?.license_scheduled.end > now &&
        props.currentOrg?.config?.license_scheduled.start < now
      : false;

    // check if org and user have proper requirements to initiate a trial
    const isFreeOrg = props.currentOrg?.config?.license === "FREE";
    const isProperMarketType =
      props.currentOrg?.market === 30 || props.currentOrg?.market === 0; // Self-Service and Direct only

    // Check if org's trial is within 7 day grace period
    const isInGracePeriod = orgHasScheduleLicense
      ? moment(props.currentOrg?.config?.license_scheduled?.end).diff(
          now,
          "days"
        ) < 8 &&
        moment(props.currentOrg?.config?.license_scheduled?.end).diff(
          now,
          "days"
        ) >= 0
      : false;

    // If a scheduled license exists and the 37 day timeframe has expired, do not show green trial banner anymore
    if (
      isFreeOrg &&
      isProperMarketType &&
      orgHasScheduleLicense !== undefined &&
      !isScheduledLicenseActive
    ) {
      return null;
      // If org has never had a trial and meets requirements for a trial, show the try a trial green trial banner
    } else if (
      isFreeOrg &&
      isProperMarketType &&
      orgHasScheduleLicense === undefined &&
      !isScheduledLicenseActive &&
      (props.superadmin || props.shouldDisplayBasedOnRole)
    ) {
      return (
        <div
          className={classes.xdrUpsellContainer}
          datacy={"xdrTrialLearnMoreBanner"}
        >
          <p>Try XDR Platform free for 30 days!</p>
          <Button
            onClick={handleViewBilling}
            className={classes.tryXdrButton}
            datacy={"xdrTrialLearnMoreButton"}
          >
            Learn More
          </Button>
        </div>
      );
      // If org is currently in an active trial, show the progress green trial banner
    } else if (
      isFreeOrg &&
      isProperMarketType &&
      orgHasScheduleLicense !== undefined &&
      isScheduledLicenseActive &&
      !isInGracePeriod
    ) {
      return (
        <div
          className={classes.xdrUpsellContainer}
          style={{ justifyContent: "center" }}
          datacy={"xdrTrialActiveBanner"}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: 10,
            }}
          >
            <p style={{ marginBottom: 0, fontWeight: "bold" }}>XDR Trial</p>
            <p style={{ marginBottom: 0 }}>
              {trialDaysRemaining() === 1
                ? "1 day left"
                : `${trialDaysRemaining()} days left`}
            </p>{" "}
          </div>
          <LinearProgress
            variant="determinate"
            value={trialProgress()}
            className={
              trialDaysRemaining() < 8
                ? classes.redProgressBar
                : classes.progressBar
            }
            datacy={"xdrTrialProgressBar"}
          />{" "}
          <p style={{ paddingTop: 10 }}>1 year data retention</p>
          <Button
            onClick={handleViewBilling}
            className={classes.tryXdrButton}
            datacy={"xdrUpgradeButton"}
          >
            Billing Details
          </Button>
        </div>
      );
      // If org is currently in an active trial within its 7 day grace period, then display the expired trial green banner
    } else if (
      isFreeOrg &&
      isProperMarketType &&
      orgHasScheduleLicense !== undefined &&
      isScheduledLicenseActive &&
      isInGracePeriod
    ) {
      return (
        <div
          className={classes.xdrUpsellContainer}
          style={{ justifyContent: "center" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: 10,
            }}
          >
            <p style={{ marginBottom: 0, fontWeight: "bold" }}>XDR Trial</p>
            <p style={{ marginBottom: 0 }}>Ended</p>{" "}
          </div>
          <LinearProgress
            variant="determinate"
            value={100}
            className={classes.redProgressBar}
          />{" "}
          <p style={{ paddingTop: 10 }}>{gracePeriodDisplayText()}</p>
          <Button
            onClick={handleViewBilling}
            className={classes.tryXdrButton}
            datacy={"xdrUpgradeButton"}
          >
            Billing Details
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  // Calculate the number of days left in the 7 day trial grace period
  const graceDaysRemaining = () => {
    if (
      props.currentOrg &&
      props.currentOrg?.config?.license_scheduled !== undefined
    ) {
      const licenseEndDate = moment(
        props.currentOrg.config?.license_scheduled.end
      ); // Full 37 day trial period
      const now = moment().utc().toISOString();

      return licenseEndDate.diff(now, "days");
    }
  };

  // Calculate the number of days left in the active trial
  const trialDaysRemaining = () => {
    if (
      props.currentOrg &&
      props.currentOrg?.config?.license_scheduled !== undefined
    ) {
      const licenseEndDate = props.currentOrg.config.license_scheduled.end; // Full 37 day trial period
      const endOfThirtyDays = moment(licenseEndDate)
        .utc()
        .subtract(7, "d")
        .toISOString(); // 30 Day Trial period for progress bar and displayed countdown for days left
      const trialEndDate = moment(endOfThirtyDays);
      const now = moment().utc().toISOString();

      return trialEndDate.diff(now, "days");
    }
  };

  // Calculate the percentage of time (days) left over in an active trial to show on progress bar
  const trialProgress = () => {
    if (
      props.currentOrg &&
      props.currentOrg?.config?.license_scheduled !== undefined
    ) {
      // Calculate trial period minus the 7 day grace period for progress bar math to be correct.
      // This number will usually be 30, but in the case that an SA gives an org a timeframe longer or shorter
      // than the self-initiated 30 days, then we want this math to be done correctly for all scenarios.
      const trialStartTime = props.currentOrg?.config?.license_scheduled?.start;
      const trialEndTime = props.currentOrg?.config?.license_scheduled?.end;
      const endOfTrialPeriod = moment(trialEndTime)
        .utc()
        .subtract(7, "d")
        .toISOString();
      const trialEndBeforeGracePeriod = moment(endOfTrialPeriod);
      const trialTimeFrame = trialEndBeforeGracePeriod.diff(
        trialStartTime,
        "days"
      );
      const percentCompleted =
        100 - (trialDaysRemaining() / trialTimeFrame) * 100; // divide the number of days remaining in trial period by the total trial time frame (not including 7 day grace period)

      if (percentCompleted < 5) {
        return 5;
      } else {
        return percentCompleted;
      }
    }
  };

  const displayAhiControlBar = () => {
    const now = moment().utc().toISOString();

    // If org has a scheduled license value and a Free license
    if (
      props.currentOrg?.config?.license_scheduled !== undefined &&
      props.currentOrg?.config?.license === "FREE"
    ) {
      // If the scheduled license is currently active, then allow the control bar to show if they have the license feature enabled
      if (
        props.currentOrg?.config?.license_scheduled.end > now &&
        props.currentOrg?.config?.license_scheduled.start < now
      ) {
        return (
          <LicenseRestriction
            features={["AUTOBOT"]}
            renderAllow={renderSpacerGridItem}
            renderDisallow={() => null}
          />
        );
        // If the scheduled license is expired or outside the start and end dates, then don't show the control bar
      } else {
        return null;
      }
      // If the org doesn't meet the original conditional (having a scheduled license value and/or a Free license), then only show the control bar if the license feature is enabled on the org
    } else {
      return (
        <LicenseRestriction
          features={["AUTOBOT"]}
          renderAllow={renderSpacerGridItem}
          renderDisallow={() => null}
        />
      );
    }
  };

  return (
    <Root
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div>
        <div className={classes.appBarSpacer} />
        {displayAhiControlBar()}
        <List className={classes.sideNav}>
          <ListItem
            key="menu-item-drawer"
            button
            onMouseOver={() => setExit(true)}
            onClick={handleDrawerToggle}
          >
            <ListItemIcon>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </ListItemIcon>
          </ListItem>

          {open && whatTrialExperienceToShow()}

          {menuItems.map((menuItem) => (
            <div key={`menu-item-${menuItem.type}`}>
              <ListItem
                button
                data-cy={`${menuItem.type}`} // for selecting with cypress
                onClick={() => handleMenuSelect(menuItem.type)}
              >
                <ListItemIcon>
                  <Icon
                    className={menuItem.icon}
                    style={{ width: "100%", textAlign: "left" }}
                  />
                </ListItemIcon>
                <ListItemText
                  className={classes.menuItem}
                  primary={menuItem.title}
                  secondary={menuItem.subtitle}
                />
                {menuItem.badge && (
                  <Badge
                    style={{ marginRight: 10 }}
                    badgeContent={menuItem.badge}
                    color="primary"
                  />
                )}
              </ListItem>
              <Collapse
                in={selectedMenuItem.selected.toplevel === menuItem.type}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {menuItem.children.map((childMenuItem) => (
                    <ListItem
                      button
                      data-cy={`${childMenuItem.type}`} // for selecting with cypress
                      data-license={`${menuItem.type}`} // for selecting in license cypress test
                      key={`menu-item-${childMenuItem.type}`}
                      className={
                        isSecondLevelSelected(
                          childMenuItem,
                          selectedMenuItem.selected.secondlevel
                        )
                          ? classes.selectedMenuItem
                          : ""
                      }
                      onClick={() =>
                        handleMenuNavigate(menuItem.type, childMenuItem.type)
                      }
                    >
                      <ListItemIcon />
                      <Badge
                        badgeContent={childMenuItem.badge}
                        color="primary"
                        overlap={"rectangular"}
                        className={classes.childLevelBadge}
                      >
                        <ListItemText
                          primaryTypographyProps={{ variant: "overline" }}
                          primary={childMenuItem.title}
                        />
                      </Badge>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </div>
      {
        <Zendesk
          open={open}
          licenseLabel={props.licenseLabel}
          license={props.license}
        />
      }
    </Root>
  );
};

const mapDispatchToProps = (dispatch) => ({
  goToBillingView: (orgId) => {
    const payload = {
      orgId,
      toplevel: "settings",
      secondlevel: "billing",
    };

    dispatch({
      type: "PAGE",
      payload,
    });
  },
});

const mapStateToProps = (state) => {
  let currentOrg = {};

  const isAdministeringOrg =
    !!state.page?.payload?.orgId &&
    WILDCARD_ORG_ID === state.page.payload.orgId;

  const currentOrgId = get(state, ["page", "payload", "orgId"], null);
  const currentUserOrgs = get(state, ["session", "settings", "userOrgs"], []);

  // if we have a current org id, find org data
  if (currentOrgId)
    currentOrg = currentUserOrgs.find(({ id }) => id === currentOrgId);

  const userOrgRoles = getUserOrgRoles({
    user: state.session.settings.user,
    orgId: currentOrgId,
  });

  // only admins and managers have access to billing
  const isUserAdminOrManager = ["administrator", "manager"].some((role) =>
    userOrgRoles.includes(role)
  );

  const shouldDisplayBasedOnRole =
    state.session.settings.user.superadmin || isUserAdminOrManager;

  return {
    orgId: state.location.payload.orgId,
    payload: state.location.payload,
    settings: state.session.settings,
    currentOrgId: currentOrgId,
    isAdministeringOrg: isAdministeringOrg,
    license: state.license,
    currentOrg,
    superadmin: state?.session?.settings?.user?.superadmin,
    shouldDisplayBasedOnRole,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SidebarView);
