import { redirect } from "redux-first-router";
import { getMenu, getUserOrgRoles } from "utils/sidebarUtils";
import { auth } from "../../lib/auth";
import {
  setAxiosHeader,
  setAxiosOrgHeader,
  WILDCARD_ORG_ID,
} from "../../lib/api/API";
import { AuthenticationAPI } from "../../lib/api";
import Logger from "../../lib/logger";
import BluLicense from "../../lib/license";
import { LicenseAPI } from "../../lib/api/LicenseAPI";

import { resetDataQuery } from "./DataQuery";
import { resetFindings } from "./Findings";
import { resetMessage } from "./Message";
import { resetTagging } from "./Tagging";
import { resetUsers } from "./Users";
import { resetOrgs } from "./Org";
import { resetLocations } from "./Locations";
import { resetPipeline } from "./Pipeline";
import { resetSensorsStore } from "./Sensors";
import { resetSettings } from "./Settings";
import { resetSecurity } from "./Security";
import { resetBlocking } from "./Blocking";
import { resetSession } from "./Session";
import _ from "lodash";
const logger = Logger("Page Actions");

export const PAGE = "PAGE";
export const PAGE_LOADING = "PAGE_LOADING";
export const PAGE_SUCCESS = "PAGE_SUCCESS";
export const PAGE_ERROR = "PAGE_ERROR";

export const LOGIN = "LOGIN";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

// Deprecated
export const PAGE_FETCH = "PAGE_FETCH";

/*
Helper action. Resets selected Redux stores to their initial state.
*/
const resetReduxStores = (dispatch) => {
  dispatch(resetBlocking);
  dispatch(resetDataQuery);
  dispatch(resetFindings);
  dispatch(resetLocations);
  dispatch(resetMessage);
  dispatch(resetPipeline);
  dispatch(resetSecurity);
  dispatch(resetSensorsStore);
  dispatch(resetSettings);
  dispatch(resetTagging);
  dispatch(resetUsers);
  dispatch(resetOrgs);
  dispatch(resetSession);
};

const authenticationAPI = new AuthenticationAPI();

/*
Exported actions
on page navigation => PAGE_FETCH
on page render => PAGE_LOADING
on page success => PAGE_SUCCESS
*/

export const actionStart = {
  type: PAGE_FETCH,
};

export const actionSuccess = {
  type: PAGE_SUCCESS,
};

export const actionFailure = (error) => ({
  type: PAGE_ERROR,
  error,
});

export const pageWithQuery = (query) => (dispatch, getState) => {
  const state = getState();
  const { location = {} } = state;
  const { payload = {} } = location;

  const nextPayload = {
    ...payload,
    query: {},
  };

  return dispatch({
    type: "PAGE",
    payload: nextPayload,
    query: { ...query },
  });
};

export const clearUrlQuery = (dispatch) => dispatch(pageWithQuery({}));

/*
Login/Logout actions
*/

/*
Logs into Blumira, persists Auth0 data to storage, and navigates to PAGE.
*/
export const loginToBlumira =
  ({ authResult, redirectPayload, createPersonIfNotExists }) =>
  async (dispatch) => {
    const { idToken = "" } = authResult;

    // Set axios auth header
    setAxiosHeader(idToken);

    logger.log("Sending login request to Blumira");

    try {
      await authenticationAPI.login({ createPersonIfNotExists });
    } catch (err) {
      logger.error("Blumira login failed. Clearing data.", err);

      // Try to clear auth data from storage
      auth.logout();

      // Clear axios headers
      setAxiosHeader();
      setAxiosOrgHeader();

      // Set page error
      dispatch({
        type: LOGIN_ERROR,
        error: new Error("Login error"),
      });

      return;
    }

    // ensure local storage is cleared from sign up
    localStorage.removeItem("isSigningUp");
    localStorage.removeItem("displayMessage");

    // simple table has access to local storage for persisting
    // row count selected by a user while browsing data
    localStorage.removeItem("rowsPerPage");

    // Try to persist auth data to storage
    auth.login(authResult);

    // Route to the user's default page
    if (redirectPayload) {
      dispatch({ type: PAGE, payload: redirectPayload });
    } else {
      dispatch({ type: LOGIN });
    }
  };

/*
Sends Blumira logout request to FEA, and clears axios headers.
*/
const sendLogoutRequest = () => {
  logger.log("Sending logout request to Blumira");

  authenticationAPI.logout().catch((err) => {
    logger.error("Unable to log out from account service", err);
  });

  // Clear axios headers
  setAxiosHeader();
  setAxiosOrgHeader();
};

/**
 * Reset the redux data that varies per org.
 * This should be dispatched when the user switches orgs.
 */
export const resetOrgData = (dispatch) => {
  dispatch(resetLocations);
  dispatch(resetOrgs());
};

/*
A combined action that sends blumira logout request, navigates to LOGIN,
and clears auth data and redux stores.
*/
export const logoutFromBlumira = (dispatch, getState) => {
  // Logout from Blumira
  authenticationAPI.logout().catch((err) => {
    logger.error("Unable to log out from account service", err);
  });

  sessionStorage.removeItem("payload");

  // Clear auth data from storage (may fail)
  auth.logout();

  // Clear select Redux stores
  dispatch(resetReduxStores);

  // Clear axios headers
  setAxiosHeader();
  setAxiosOrgHeader();

  dispatch(
    redirect({
      type: LOGIN,
      query: { logout: true },
    })
  );
};

export const redirectLogoutFromBlumira = ({
  dispatch,
  location,
  pageErrorMsg,
}) => {
  // Logout from Blumira
  sendLogoutRequest();
  dispatch(resetReduxStores);
  dispatch(
    redirect({
      type: LOGIN,
      payload: { ...location.payload },
      query: { ...location.query },
    })
  );
  dispatch({
    type: LOGIN_ERROR,
    error: new Error(pageErrorMsg),
  });
};

const getOrgId = (settings, payload) => {
  const orgList = settings.userOrgs.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  );
  const isWildcard = payload.orgId === WILDCARD_ORG_ID;
  const isValidUserOrg = orgList.find((o) => o.id === payload.orgId);
  if (orgList.length > 0) {
    return isValidUserOrg || isWildcard ? payload.orgId : orgList[0].id;
  } else {
    return false;
  }
};

/*
Redirects to the default path for the user
*/

export const navigateToDefault = async (dispatch, getState) => {
  const state = getState();
  const orgId = getOrgId(state.session.settings, state.page.payload);

  const licenseAPI = new LicenseAPI();
  var license = await licenseAPI.get(orgId);
  BluLicense.orgId = orgId;
  BluLicense.activeLicense = license.data;

  const menu = getMenu(orgId, state.session.settings, state.license);

  const mspOrganization = state.session.settings.userOrgs.find(
    (org) => org.market === 20 && _.isNil(org.parentId)
  );
  const currentUser = state.session.settings.user;
  const isSuperAdmin = currentUser.superadmin === true;
  const userOrgRoles = getUserOrgRoles({ user: currentUser, orgId });

  if (state.session.settings.user.acceptedTos == null) {
    dispatch({
      type: "TOS",
      payload: {
        orgId,
      },
    });
  }
  //redirect to mspportal/accounts if user is not superadmin and has access to MSP parent organization
  //bonus: added feature flag for testing purposes
  else if (
    !isSuperAdmin &&
    !_.isNil(mspOrganization) &&
    mspOrganization.market === 20 &&
    _.isNil(mspOrganization.parentId) &&
    userOrgRoles.includes("administrator")
  ) {
    license = await licenseAPI.get(mspOrganization.id);
    BluLicense.orgId = mspOrganization.id;
    BluLicense.activeLicense = license.data;

    dispatch({
      type: PAGE,
      payload: {
        orgId: mspOrganization.id,
        toplevel: "mspportal",
        secondlevel: "accounts",
      },
    });
  } else if (state.session.settings.user.monitor) {
    dispatch({
      type: "MONITORMODE",
      payload: {
        orgId,
      },
    });
  } else {
    dispatch({
      type: PAGE,
      payload: {
        orgId,
        toplevel: menu[0].type,
        secondlevel: menu[0].children[0].type,
      },
    });
  }
};

/*
Navigates to LOGIN clearing query.logout
*/
export const clearLogoutQuery = () => (dispatch, getState) => {
  return dispatch({
    type: LOGIN,
  });
};

export const pageWithPayloadAndQuery =
  ({ payload, query }) =>
  (dispatch) => {
    // Clear location.payload.query - we will add location.query
    const nextPayload = {
      ...payload,
      query: {},
    };

    return dispatch({
      type: PAGE,
      payload: nextPayload,
      query: { ...query },
    });
  };

/*
Navigates to /user/alertsettings page
*/
export const navigateToAlertSettings = () => (dispatch, getState) => {
  const state = getState();
  const orgId = getOrgId(state.session.settings, state.page.payload);
  return dispatch({
    type: PAGE,
    payload: {
      orgId: orgId,
      toplevel: "user",
      secondlevel: "alertsettings",
    },
  });
};
