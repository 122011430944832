const labelSchema = {
  $id: "/label",
  title: "Label",
  type: "object",
  properties: {
    orgId: { type: "string" },
    id: { type: "string", format: "uuid", readOnly: true },
    name: {
      type: "string",
      minLength: 1,
      maxLength: 255,
      pattern: "^[a-zA-Z][\\w_.+:()-/ ]+$",
    },
  },
  required: ["name"],
  additionalProperties: false,
};

export default labelSchema;
