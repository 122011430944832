import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import LoggingEndpointsGraph from "./LoggingEndpointsGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "loggingEndpoints";
export const GRAPH_TITLE = "Top 10 Endpoints by Log Count";

/*
Main component
*/

const LoggingEndpoints = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    longInt
    defaultTitle={GRAPH_TITLE}
    Graph={LoggingEndpointsGraph}
    labelField="devname"
    labelField2="deviceAddress"
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

LoggingEndpoints.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

LoggingEndpoints.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default LoggingEndpoints;
