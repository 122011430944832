import { Map } from "immutable";
import {
  DELETE_MESSAGE,
  RECEIVE_MESSAGE,
  RECEIVE_MESSAGES,
  RESET_MESSAGE,
  UPDATE_MESSAGE,
} from "./actions/Message";

const initialState = Map({
  messages: {},
});

const MessageReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DELETE_MESSAGE: {
      const { payload = {} } = action;
      const { message, objectId } = payload;
      const { id: messageId } = message;

      const nextState = state.updateIn(["messages", objectId], (messages) => {
        const index = messages.findIndex((elt) => elt.id === messageId);

        const newMessages = [...messages];
        newMessages.splice(index, 1);

        return newMessages;
      });

      return nextState;
    }
    case RECEIVE_MESSAGES: {
      return state.setIn(["messages", action.objectId], action.messages);
    }
    case RECEIVE_MESSAGE: {
      return state.updateIn(["messages", action.objectId], (messages) =>
        messages ? [action.message].concat(messages) : [action.message]
      );
    }
    case RESET_MESSAGE: {
      return { ...initialState };
    }
    case UPDATE_MESSAGE: {
      const { payload = {} } = action;
      const { message = {}, objectId } = payload;
      const { id: messageId } = message;

      const nextState = state.updateIn(["messages", objectId], (messages) => {
        const index = messages.findIndex((elt) => elt.id === messageId);

        const newMessages = [...messages];
        newMessages[index] = { ...message };

        return newMessages;
      });

      return nextState;
    }
    default:
      return state;
  }
};

export default MessageReducer;
