import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";

import SupportEntry from "../../Components/Finding/SupportEntry";

const MoreInfo = ({
  columnNumLeft,
  columnNumRight,
  loggerRecentPublishedEps,
  loggerRecentPublishedTimestamp,
  loggerRecentReadInputLine,
}) => {
  const publishedTime = loggerRecentPublishedTimestamp
    ? `${loggerRecentPublishedTimestamp} (UTC)`
    : "";

  return (
    <Container className="list-container dark">
      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Sensor Published EPS"
        value={loggerRecentPublishedEps}
      />

      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Sensor Recent Published Time"
        value={publishedTime}
      />

      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Sensor Recent Input Log"
        value={loggerRecentReadInputLine}
      />
    </Container>
  );
};

MoreInfo.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  loggerRecentPublishedEps: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  loggerRecentPublishedTimestamp: PropTypes.string,
  loggerRecentReadInputLine: PropTypes.string,
};

MoreInfo.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  loggerRecentPublishedEps: "",
  loggerRecentPublishedTimestamp: "",
  loggerRecentReadInputLine: "",
};

export default MoreInfo;
