// This is known as a "person" on the back end.

const sAlert = {
  type: "object",
  properties: {
    "*": {
      type: "object",
      properties: {
        email: { type: "boolean" },
        voice: { type: "boolean" },
        text: { type: "boolean" },
      },
    },
  },
};

const pAlert = {
  type: "object",
  properties: {
    p1: {
      type: "object",
      properties: {
        email: { type: "boolean" },
        voice: { type: "boolean" },
        text: { type: "boolean" },
      },
    },
    p2: {
      type: "object",
      properties: {
        email: { type: "boolean" },
        voice: { type: "boolean" },
        text: { type: "boolean" },
      },
    },
    p3: {
      type: "object",
      properties: {
        email: { type: "boolean" },
        voice: { type: "boolean" },
        text: { type: "boolean" },
      },
    },
  },
};

const config_alerts = {
  contact_details: {
    type: "object",
    properties: {
      email_address: { type: "string", format: "email" },
      voice_number: { type: "string" },
      text_number: { type: "string" },
    },
  },
  notify_all_org_comments_email: {
    type: "array",
  },
  email_on_initial_responder_ownership: {
    type: "array",
  },
  f_10: sAlert,
  f_15: pAlert,
  f_20: pAlert,
  f_30: pAlert,
  f_40: sAlert,
};

const userSchema = {
  $id: "/user",
  title: "User",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    acceptedTos: { type: "string" },
    activeUser: { type: "boolean", readOnly: true },
    authId: { type: "string", readOnly: true },
    authSettings: {
      type: "object",
      readOnly: true,
      properties: {
        force_password_reset_after: {
          type: "boolean",
          readOnly: true,
          nullable: true,
        },
        invited_by: { type: "string", nullable: true, readOnly: true },
        login_duration: { type: "number", nullable: true, readOnly: true },
        max_password_age: { type: "number", nullable: true, readOnly: true },
        mfa_allow_remember: { type: "boolean", readOnly: true },
        mfa_disabled: { type: "boolean", readOnly: true },
      },
    },
    config: { type: "object", properties: {}, readOnly: true },
    configAlerts: config_alerts,
    configUser: { type: "object", readOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    email: { type: "string", minLength: 1, maxLength: 132, format: "email" }, //shouldn't this accept 255 or maybe even 320 based on the official spec??
    firstLogin: { type: "string", format: "date-time", readOnly: true },
    firstName: { type: "string", maxLength: 149 }, //backend is limiting full name to 300 characters including the [space] between first and last name, so making first name max 149 and last name max 150
    lastLogin: { type: "object", properties: {}, readOnly: true },
    lastName: { type: "string", maxLength: 150 }, //backend is limiting full name to 300 characters including the [space] between first and last name, so making first name max 149 and last name max 150
    locations: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string", format: "uuid" },
          orgId: { type: "string", format: "uuid" },
        },
        required: ["id", "orgId"],
      },
    },
    monitor: { type: "boolean", readOnly: true },
    modified: { type: "string", format: "date-time", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    nickname: { type: "string" },
    notes: { type: "string" },
    orgs: {
      type: "array",
      items: { type: "object", properties: {} },
      readOnly: true,
    },
    orgRoles: {
      type: "array",
      minItems: 1,
      items: {
        type: "object",
        properties: {
          orgId: { type: "string", format: "uuid" },
          roleId: { type: "number" },
        },
        required: ["orgId", "roleId"],
        additionalProperties: false,
      },
    },
    picture: { type: "string", readOnly: true },
    serviceAccount: { type: "boolean", readOnly: true },
    status: { type: "number", readOnly: true },
    superadmin: { type: "boolean", readOnly: true },
    title: { type: "string", maxLength: 255 },
  },
  required: ["email", "orgRoles"],
  additionalProperties: false,
};

export default userSchema;
