import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "msp_stripe",
};

export class MSPStripeAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  async createCustomerSession(body) {
    const params = ["customer-session"];
    return super.post({ params }, body);
  }

  async createCustomer(data) {
    const params = ["customer"];
    const body = data;
    return super.post({ params }, body);
  }

  async getCustomer(customerId) {
    const params = ["customers", customerId];
    return super.get({ params });
  }

  async getInvoice(invoiceId) {
    const params = ["invoices", invoiceId];
    return super.get({ params });
  }
}
