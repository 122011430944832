import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Blocklist as BlocklistModel } from "lib/models/Blocklist";
import { BlocklistEntry as BlocklistEntryModel } from "lib/models/BlocklistEntry";

// COMPONENTS
import { Button } from "reactstrap";
import PageHeader from "../Components/PageHeader";
import SimpleTable from "views/Components/SimpleTable";
import ActionDialog from "views/Components/ActionDialog";
import SimpleModelForm from "views/Components/SimpleModelForm";

// ACTIONS
import {
  fetchConfigForOrg,
  fetchBlocksForOrg,
} from "../../redux/actions/Blocking";
import { pageWithQuery } from "../../redux/actions/Page";

// BLOCK HELPERS
import {
  getColumns,
  tableDataArray,
  configLayout,
  configFields,
  entryLayout,
  entryFields,
} from "./Blocking/common/blockingHelpers";

import BlockDevices from "./Blocking/BlockDevices";
import { BlockingStatus } from "./Blocking/BlockingStatus";

// MATERIAL FUN
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

const PREFIX = "BlockingPageView";

const classes = {
  infoBannerLink: `${PREFIX}-infoBannerLink`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.infoBannerLink}`]: {
    color: "#315290", // CHAMBRAY from theme file to match text
  },
}));

const BlockingPageView = ({
  blocks,
  clearQuery,
  clearFilter,
  dispatchFetchBlocksForOrg,
  dispatchFetchConfigForOrg,
  orgConfig,
  orgId,
  query,
  isFetching,
  gotoFinding,
  license,
  superadmin,
}) => {
  const { findingId } = query;

  const [openForm, setOpenForm] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [selectedModel, setSelectedModel] = useState(false);
  const [isEditingConfig, setIsEditingConfig] = useState(false);

  /* Refs */

  // Initialize "component is mounting" flag
  const mounting = useRef(true);

  // Initialize previous orgId
  const previousOrgId = useRef("");

  /* Fetch Data */

  // Fetch config
  useEffect(() => {
    dispatchFetchConfigForOrg(orgId);
  }, [orgId]);

  // Fetch blocks
  useEffect(() => {
    /* Fetch on mount */

    if (mounting.current) {
      dispatchFetchBlocksForOrg({ orgId, findingId });
      return;
    }

    /* Otherwise fetch on certain props updates */

    // If no findingId, fetch
    if (findingId === undefined) {
      dispatchFetchBlocksForOrg({ orgId, findingId });
      return;
    }

    // If there is findingId, fetch only if orgId has not changed
    if (previousOrgId.current === orgId) {
      dispatchFetchBlocksForOrg({ orgId, findingId });
    }
  }, [orgId, findingId]);

  /* Save orgId */

  useEffect(() => {
    previousOrgId.current = orgId;
  }, [orgId]);

  /* Navigate */

  // Clear the URL query component on orgId change, but not when mounting.
  useEffect(() => {
    if (mounting.current) {
      mounting.current = false;
      return;
    }

    clearQuery();
  }, [orgId]);

  const handleOpenForm = (isConfig) => {
    setOpenForm(true);
    setIsEditingConfig(!!isConfig);
  };

  const handleClose = () => {
    if (isEditingConfig) dispatchFetchConfigForOrg(orgId);
    else dispatchFetchBlocksForOrg({ orgId, findingId });
    selectedModel.reset();
    setOpenForm(false);
  };

  const handleDelete = () => {
    selectedModel.delete().then(() => {
      dispatchFetchBlocksForOrg({ orgId, findingId });
      setOpenAction(false);
    });
  };

  const handleOpenConfigForm = () => {
    handleOpenForm(true);
    setSelectedModel(new BlocklistModel(orgConfig));
  };

  const handleTableRowClick = (rowDetails) => {
    //rowDetails, in this case, is really just the user info/model
    if (rowDetails?.findingId) {
      gotoFinding({ id: rowDetails.findingId, orgId });
    } else {
      setSelectedModel(rowDetails);
      handleOpenForm();
    }
  };

  const resolveModalTitle = () => {
    if (selectedModel.id)
      return `Edit Block ${isEditingConfig ? "Configuration" : "Entry"}`;
    else return `New Block ${isEditingConfig ? "Configuration" : "Entry"}`;
  };

  return (
    <Root>
      {!isFetching && (
        <>
          <PageHeader>
            <div>
              {findingId && (
                <Button onClick={clearFilter({ orgId, query })}>
                  Clear Filter
                </Button>
              )}

              <Button
                onClick={handleOpenConfigForm}
                datacy={"blocklistsConfigureButton"}
              >
                Configure
              </Button>
            </div>
          </PageHeader>

          <div>
            <BlockingStatus config={orgConfig} />
          </div>

          <div>
            <BlockDevices />
          </div>
        </>
      )}

      {tableDataArray.map((tableData, i) => (
        <div key={i} style={{ paddingTop: 20 }}>
          <SimpleTable
            isNorthStar
            searchData={findingId}
            isFetching={isFetching}
            rowClick={handleTableRowClick}
            title={`${tableData.title} Entries`}
            columns={getColumns(tableData.type)}
            data={blocks.filter(
              ({ blockType }) => blockType === tableData.type
            )}
            toolbarActions={[
              {
                icon: AddCircleIcon,
                tooltip: `Add ${tableData.title} Entry`,
                onClick: (event) => {
                  handleOpenForm();
                  setSelectedModel(
                    new BlocklistEntryModel({
                      orgId: orgId,
                      blockType: tableData.type,
                    })
                  );
                },
              },
            ]}
            actions={[
              {
                icon: EditIcon,
                tooltip: "Edit Entry",
                onClick: (event, model) => {
                  setSelectedModel(model);
                  handleOpenForm();
                },
              },
              {
                icon: DeleteIcon,
                tooltip: "Delete Entry",
                onClick: (event, model) => {
                  setSelectedModel(model);
                  setOpenAction(true);
                },
              },
            ]}
          />
        </div>
      ))}

      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openForm}
        fullWidth={true}
        maxWidth="md"
        datacy={"blocklistsConfigureDialog"}
      >
        <DialogTitle id="simple-dialog-title">
          {resolveModalTitle()}
        </DialogTitle>
        {superadmin ||
        license.hasFeature("BLOCKLISTS") ||
        license.isTagged("limited-to-xdr") ||
        license.isTagged("limited-to-advanced") ? null : (
          <Alert
            severity={"info"}
            style={{ margin: "0 20px" }}
            datacy={"blocklistsConfigureInfoAlert"}
          >
            Blocklists help you reduce your overall attack surface by providing
            your firewall with a dynamic source of threat intelligence.
            Blumira's dynamic blocklists, which join community and threat feeds
            with the block entries you provide, shield your network by stopping
            threats at the firewall. You can also stop access attempts with
            manual blocking in Blumira's finding workflows when a threat is
            detected in your network.
            <br></br>
            <br></br>
            For a faster threat response with automated blocking, upgrade to{" "}
            <a
              href="https://www.blumira.com/pricing/"
              className={classes.infoBannerLink}
            >
              XDR Platform Edition
            </a>
            .
          </Alert>
        )}
        <SimpleModelForm
          spacing={2}
          model={selectedModel}
          onClose={handleClose}
          actions={[
            {
              title: "Cancel",
              type: "button",
              action: handleClose,
            },
            {
              title: "Save",
              type: "submit",
            },
          ]}
          layout={isEditingConfig ? configLayout : entryLayout}
          fields={
            isEditingConfig
              ? configFields(selectedModel, license, superadmin)
              : entryFields(selectedModel)
          }
        />
      </Dialog>

      <ActionDialog
        title={`Delete`}
        open={openAction}
        description="Are you sure you want to delete this block entry?"
        actions={[
          {
            title: "Cancel",
            action: () => setOpenAction(false),
          },
          {
            title: "Delete",
            action: handleDelete,
          },
        ]}
      />
    </Root>
  );
};

BlockingPageView.propTypes = {
  dispatchFetchConfigForOrg: PropTypes.func.isRequired,
  dispatchFetchBlocksForOrg: PropTypes.func.isRequired,
  orgConfig: PropTypes.shape({}).isRequired,
  orgId: PropTypes.string.isRequired,
  query: PropTypes.shape({}).isRequired,
  clearFilter: PropTypes.func.isRequired,
  clearQuery: PropTypes.func.isRequired,
};

BlockingPageView.defaultProps = {};

const defaultBlockConfig = {
  blockEnabled: false,
  blockConfiguration: {
    automated: false,
    community: true,
    default_number: 7,
    devices: [],
    threat_feeds: 2,
  },
};

const mapStateToProps = (state) => {
  const { blocking = {}, location = {} } = state;

  const { config = {}, blocks = {} } = blocking;
  const { byOrg = {} } = config;

  const { payload = {}, query = {} } = location;
  const { orgId = "" } = payload;

  defaultBlockConfig.orgId = orgId;

  const orgConfig =
    byOrg[orgId] && byOrg[orgId].blockConfiguration
      ? byOrg[orgId]
      : defaultBlockConfig;

  const blocksToReturn = blocks.byOrg[orgId] || [];

  return {
    orgId,
    query,
    orgConfig,
    blocks: blocksToReturn,
    isFetching: blocks.byOrg.isFetching,
    license: state.license,
    superadmin: state?.session?.settings?.user?.superadmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  /* Data fetching */

  dispatchFetchConfigForOrg: (orgId) => dispatch(fetchConfigForOrg(orgId)),

  dispatchFetchBlocksForOrg: ({ orgId, findingId }) =>
    dispatch(fetchBlocksForOrg({ orgId, findingId })),

  // Deletes 'findingId' key from the URL query component
  clearFilter:
    ({ query = {} }) =>
    () => {
      const nextQuery = { ...query };

      delete nextQuery.findingId;

      dispatch(pageWithQuery(nextQuery));
    },

  clearQuery: () => {
    dispatch(pageWithQuery({}));
  },

  gotoFinding: ({ id, orgId }) => {
    dispatch({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "reporting",
        secondlevel: "findings",
        id1: id,
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BlockingPageView);
