import React, { Component } from "react";
import PropTypes from "prop-types";

import _ from "lodash";

class TextField extends Component {
  render() {
    const {
      type = "text",
      name,
      label,
      dataCy,
      required,
      placeholder,
      values = {},
      disabled = false,
      errors = {},
      touched = {},
      handleBlur,
      handleChange,
      customHandleChange,
      classes = "",
      validation = {},
      formatting = {},
      labelClasses = "",
    } = this.props;
    const uniqueId = _.uniqueId();
    const isError = errors[name] && touched[name];
    return (
      <div className={`field ${isError ? "error" : ""}`}>
        {label && (
          <label
            htmlFor={`${name}__${uniqueId}`}
            className={labelClasses || "control-label"}
            title={errors[name]}
          >
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <input
          id={`${name}__${uniqueId}`}
          name={name}
          datacy={dataCy}
          className={classes || "control-item"}
          type={type}
          value={values[name] || ""}
          onChange={async (e) => {
            await handleChange(e);
            if (customHandleChange) {
              customHandleChange(e);
            }
          }}
          onBlur={handleBlur}
          ref={(c) => {
            this.field = c;
          }}
          validation={{ required, ...validation }}
          formatting={{ ...formatting }}
          placeholder={placeholder}
          disabled={disabled}
        />
        {errors[name] && touched[name] ? (
          <span className="error-message">{errors[name]}</span>
        ) : null}
      </div>
    );
  }
}

TextField.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  values: PropTypes.shape({}),
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  customHandleChange: PropTypes.func,
  classes: PropTypes.string,
  validation: PropTypes.shape({}),
  formatting: PropTypes.shape({}),
};

TextField.defaultProps = {
  type: "text",
  label: "",
  required: false,
  placeholder: "",
  values: {},
  disabled: false,
  errors: {},
  touched: {},
  customHandleChange: () => {},
  classes: "",
  validation: {},
  formatting: {},
  handleBlur: () => {},
};

export default TextField;
