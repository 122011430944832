import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";
import { Button } from "react-bootstrap";

import MuiButton from "@mui/material/Button";

import Alert from "../Components/Alert";
import Submitted from "../Components/Submitted";
import ResetDemo from "../Components/ResetDemo/ResetDemo";
import { CHANGE_THEME } from "../../redux/actions/UserInterface";
import { updateUser, resetMFA } from "../../redux/actions/Users";
import Avatar from "../Components/Avatar";
import Logger from "../../lib/logger";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import TosDialog from "../Tos/TosDialog";

import ActionDialog from "views/Components/ActionDialog";

import { orgIdSelector } from "../../selectors/orgIdSelector";
import { orgNameSelector } from "../../selectors/orgNameSelector";
import { getUserOrgRoles } from "utils/sidebarUtils";

import {
  validate,
  format,
  TextField,
  attachRefToFields,
} from "../Components/Form";

const logger = Logger("MyAccountView");

const SUBMITTED_ALERT_DURATION = 2500;

class MyAccountView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errors: false,
      isTosOpen: false,
      submitted: false,
      submittedMsg: "",
      showMFAResetModal: false,
      mfaSuccessMessage: null,
      mfaFailureMessage: null,
    };

    this.dismissError = this.dismissError.bind(this);
    this.dismissMfaFeedback = this.dismissMfaFeedback.bind(this);
    this.handleError = this.handleError.bind(this);
    this.submit = this.submit.bind(this);
    this.validate = this.validate.bind(this);
    this.resetMFA = this.resetMFA.bind(this);
  }

  componentWillUnmount() {
    this.dismissError();
  }

  /*
  'Form' methods
  */

  async validate(values) {
    let errors = {};

    await Promise.all(
      [validate(this, values)].map((p) =>
        p.catch((err) => {
          logger.warn(err);
          throw err;
        })
      )
    ).then((results) => {
      errors = Object.assign({}, ...results);
    });

    if (!_.isEmpty(errors)) {
      this.setState({
        errors: true,
      });

      throw errors;
    } else {
      this.setState({
        errors: false,
      });
    }
  }

  // Clear error
  dismissError() {
    this.setState({ error: null });
  }

  // Set error
  handleError(error) {
    logger.error(error);

    this.setState({ error });
  }

  submit(userData) {
    const { orgId } = this.props;

    const data = { ...userData };
    delete data.email;

    this.props.onFormSubmit(orgId, this.props.personId, data).catch((err) => {
      logger.error(err);

      this.setState({ error: err });
    });
  }

  handleThemeChange(event) {
    this.props.changeTheme(event.target.value);
  }

  async resetMFA() {
    let error;
    try {
      await this.props.resetMFA({ personId: this.props.personId });
      this.setState({
        mfaSuccessMessage: "Your two-factor authenticators have been reset.",
        mfaFailureMessage: null,
        showMFAResetModal: false,
      });
    } catch (e) {
      error = e;
    }
    if (error) {
      this.setState({
        mfaFailureMessage:
          "Unable to reset your two-factor authenticators. If this error persists, please contact support@blumira.com.",
        mfaSuccessMessage: null,
        showMFAResetModal: false,
      });
    }
  }

  dismissMfaFeedback() {
    this.setState({
      mfaSuccessMessage: null,
      mfaFailureMessage: null,
      showMFAResetModal: false,
    });
  }

  toggleTos() {
    const { isTosOpen } = this.state;
    this.setState({ isTosOpen: !isTosOpen });
  }

  toggleMFAResetModal() {
    const { showMFAResetModal } = this.state;
    this.setState({ showMFAResetModal: !showMFAResetModal });
  }

  render() {
    const {
      orgId,
      orgName,
      userOrgs,
      values: fields,
      currentOrg,
      currentUser,
    } = this.props;

    const { email = "", first_name = "", last_name = "" } = fields;

    const {
      error,
      errors,
      isTosOpen,
      submitted,
      submittedMsg,
      showMFAResetModal,
    } = this.state;

    const errorMessage = error ? error.message : null;
    const userOrgRoles = getUserOrgRoles({ user: currentUser, orgId });

    const shouldShowCustomerAdvocateProgram = () => {
      // Only show this section to Superadmins or Admin users in direct orgs
      if (currentUser.superadmin) {
        return true;
      } else if (
        currentOrg.market === 0 &&
        userOrgRoles.includes("administrator")
      ) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <div className="my-account-view">
        <div className="my-account-header">
          <div className="avatar-container">
            <Avatar
              email={email}
              firstname={first_name}
              lastname={last_name}
              showTooltip
            />
          </div>

          <h1>Account</h1>

          <ResetDemo orgId={orgId} orgName={orgName} orgs={userOrgs} />
        </div>

        {error && (
          <Alert
            color="danger"
            message={errorMessage}
            handleAction={this.dismissError}
          />
        )}

        {!error && submitted && (
          <Submitted isOpen={submitted} body={submittedMsg} size="lg" />
        )}

        <Formik
          initialValues={{
            ...fields,
          }}
          ref={(c) => {
            this.form = c;
          }}
          enableReinitialize
          validate={(values) => this.validate(values)}
          onSubmit={async (values, { setSubmitting }) => {
            let newValues = values;

            try {
              newValues = await format(this, values);
            } catch (err) {
              this.handleError(err);
              return;
            }

            setSubmitting(true);
            let resp;
            try {
              resp = await this.submit(newValues);
            } catch (err) {
              this.handleError(err);
              return;
            }

            setSubmitting(false);

            this.setState({
              submitted: true,
              submittedMsg: "Account settings have been updated",
            });
            setTimeout(
              () => this.setState({ submitted: false }),
              SUBMITTED_ALERT_DURATION
            );

            return resp;
          }}
          render={(props) => {
            const page = this;
            return (
              <form>
                <div className="form-group">
                  <div className="row">
                    <TextField
                      dataCy={"firstNameInput"}
                      classes="form-control control-item"
                      type="text"
                      name="first_name"
                      label="First Name"
                      placeholder="first name"
                      required
                      validation={{
                        length: { min: 1, max: 40 },
                      }}
                      ref={attachRefToFields.bind(this, page)}
                      {...props}
                    />
                    <TextField
                      classes="form-control control-item"
                      type="text"
                      name="last_name"
                      label="Last Name"
                      placeholder="last name"
                      required
                      validation={{
                        length: { min: 1, max: 40 },
                      }}
                      ref={attachRefToFields.bind(this, page)}
                      {...props}
                    />
                  </div>
                  <div className="row">
                    <TextField
                      classes="form-control control-item"
                      type="text"
                      name="title"
                      label="Title"
                      placeholder="job title"
                      validation={{
                        length: { min: 1, max: 40 },
                      }}
                      ref={attachRefToFields.bind(this, page)}
                      {...props}
                    />
                  </div>
                  <div className="row">
                    <TextField
                      classes="form-control control-item"
                      type="text"
                      name="email"
                      label="Email"
                      disabled
                      placeholder="email"
                      ref={attachRefToFields.bind(this, page)}
                      {...props}
                    />
                  </div>
                </div>

                <Button
                  style={{ marginBottom: "20px" }}
                  type="submit"
                  bsStyle="primary"
                  disabled={errors}
                  onClick={props.handleSubmit}
                >
                  Save
                </Button>
              </form>
            );
          }}
        />
        <Divider />
        <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
          Manage Two-Factor Authentication
        </Typography>
        <p style={{ fontSize: ".9em" }}>
          If you have lost your two-factor authentication device or wish to
          reconfigure your Blumira two-factor authenticator, reset your
          authenticators here. You will receive an email with instructions on
          configuring a new authenticator.
        </p>

        {this.state.mfaFailureMessage && (
          <Alert
            color="danger"
            message={this.state.mfaFailureMessage}
            handleAction={this.dismissMfaFeedback}
          />
        )}

        {this.state.mfaSuccessMessage && !this.state.mfaFailureMessage && (
          <Alert
            color="success"
            message={this.state.mfaSuccessMessage}
            handleAction={this.dismissMfaFeedback}
          />
        )}

        <Button
          bsStyle="warning"
          style={{ marginBottom: "20px" }}
          onClick={() => this.toggleMFAResetModal()}
        >
          Reset Two-Factor Authenticators
        </Button>

        <TosDialog open={isTosOpen} handleBack={() => this.toggleTos()} />

        <ActionDialog
          title={"Are you sure?"}
          open={showMFAResetModal}
          description={
            <span>
              Are you sure you want to reset your two-factor authenticators?
              <br />
              <br />
              The next time you log into Blumira, a second factor will not be
              required, and you will need to set up a new authenticator at that
              time.
              <br />
              <br />
              You will also receive an email with instructions on how to
              proceed.
            </span>
          }
          actions={[
            {
              title: "Reset",
              variant: "outlined",
              action: () => {
                this.toggleMFAResetModal();
                this.resetMFA(this.props.user);
              },
            },
            {
              title: "Cancel",
              variant: "contained",
              action: () => {
                this.toggleMFAResetModal();
              },
            },
          ]}
        />

        <Divider />
        <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
          Appearance
        </Typography>
        <FormControl variant="outlined">
          <InputLabel id="theme-selector-label">Theme</InputLabel>
          <Select
            label="Theme"
            labelId="theme-selector-label"
            id="theme-selector"
            style={{ minWidth: "150px", marginBottom: "20px" }}
            defaultValue={"default"}
            value={this.props.theme}
            onChange={this.handleThemeChange.bind(this)}
          >
            <MenuItem value={"default"}>Light</MenuItem>
            <MenuItem value={"shadow"}>Dark</MenuItem>
            <MenuItem value={"auto"}>Auto</MenuItem>
          </Select>
        </FormControl>

        {shouldShowCustomerAdvocateProgram() ? (
          <>
            <Divider />
            <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
              Customer Advocate Program
            </Typography>
            <p style={{ fontSize: ".9em" }}>
              Get rewarded for referrals with Blumira's new Customer Advocate
              program! Receive a $250 gift card or donate to charity if the
              person you refer completes a demo with Blumira. Help us spread the
              word about how Blumira provides simplified security for IT teams.
            </p>

            <Button
              bsStyle="warning"
              style={{ marginBottom: "20px" }}
              href="https://info.blumira.com/customer-advocate"
            >
              Learn more
            </Button>
          </>
        ) : null}

        <div className="tosLink">
          <MuiButton
            onClick={() => this.toggleTos()}
            variant={"contained"}
            color={"primary"}
          >
            Blumira Terms of Use
          </MuiButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { session } = state;
  const orgId2 = state.location.payload.orgId;
  const currentOrg = state.session.settings.userOrgs.find(
    ({ id }) => id === orgId2
  );

  return {
    values: {
      first_name: session.settings.user.first_name,
      email: session.settings.user.email,
      last_name: session.settings.user.last_name,
      title: session.settings.user.title,
    },
    personId: session.settings.user.id,
    error: session.settings.error,
    userOrgs: session.settings.userOrgs,
    orgId: orgIdSelector(state),
    orgName: orgNameSelector(state),
    currentOrg,
    currentUser: state?.session?.settings?.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFormSubmit: (orgId, personId, userData) =>
    dispatch(
      updateUser({ orgId, personId, data: userData, editingSelf: true })
    ),
  changeTheme: (theme) => dispatch({ type: CHANGE_THEME, theme: theme }),
  resetMFA: ({ personId }) => dispatch(resetMFA({ personId })),
});

MyAccountView.propTypes = {
  orgId: PropTypes.string,
  personId: PropTypes.string,
  onFormSubmit: PropTypes.func.isRequired,
  changeTheme: PropTypes.func.isRequired,
  resetMFA: PropTypes.func.isRequired,
  orgName: PropTypes.string,
  userOrgs: PropTypes.instanceOf(Array),
  values: PropTypes.shape({
    first_name: PropTypes.string,
    email: PropTypes.string,
    last_name: PropTypes.string,
    title: PropTypes.string,
  }),
};

MyAccountView.defaultProps = {
  orgId: "",
  personId: null,
  orgName: "",
  userOrgs: [],
  values: {
    first_name: "",
    email: "",
    last_name: "",
    title: "",
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountView);
