/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import moment from "moment";

import { get } from "lodash";
import Cookies from "universal-cookie";

import {
  Grid,
  Card,
  Link,
  Alert,
  Button,
  Tooltip,
  Divider,
  Checkbox,
  TextField,
  Typography,
  AlertTitle,
  CardContent,
  CardActions,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { getOrgs } from "redux/actions/Org";

import { getCurrentPlanAndInvoiceData, formatProducts } from "./helpers";

import { StripeAPI } from "lib/api/StripeAPI";
import { HubspotAPI } from "lib/api/HubspotAPI";
import LaunchIcon from "@mui/icons-material/Launch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { availablePlans } from "./constants";

import { PlanOption } from "./components/PlanOption";

import { Root, BillingDialog, rootClasses, dialogClasses } from "./styles.js";

import ahiControlBarLight from "../../../images/AHI_control_bar.png";
import animatedGIF from "../../../images/confetti-2.gif";
import cloudConnectorModalLight from "../../../images/cloud_connector_modal.png";
import cloudConnectorModalDark from "../../../images/connectors_dark_mode.png";
import ahiControlBarDark from "../../../images/ahi_dark_mode.png";
import timedTrialInfo from "../../../images/timedTrialInfo.png";

import { TimedTrialStepCard } from "views/Components/Billing/TimedTrialStepCard";
import { DEFAULT_FEATURES } from "utils";

import Request from "lib/api/Request";

const API = {
  stripe: new StripeAPI(),
  hubspot: new HubspotAPI(),
};

const FREE_LICENSE = "FREE";

export const baseContactSalesUrl = "https://info.blumira.com/pql-pricing";

const cookies = new Cookies();

const shouldShowFeature = (options) => {
  const {
    isFreeOrg,
    isProperMarketType,
    superadmin,
    isCurrentUserAdmin,
    orgHasScheduledLicense,
    isScheduledLicenseActive,
  } = options;

  const isAdminUser =
    superadmin ||
    isCurrentUserAdmin.includes(10) ||
    isCurrentUserAdmin.includes(20);

  const hasValidScheduledLicense =
    (orgHasScheduledLicense === undefined && !isScheduledLicenseActive) ||
    (orgHasScheduledLicense !== undefined && isScheduledLicenseActive);

  return (
    isFreeOrg && isProperMarketType && isAdminUser && hasValidScheduledLicense
  );
};

const BillingPageView = (props) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [productQuantity, setProductQuantity] = useState(10);
  const [isUserOnLegacyPlan, setIsUserOnLegacyPlan] = useState(false);
  // const [promotionCode, setPromotionCode] = useState('') // exclude promo code for now
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPlanData, setCurrentPlanData] = useState(null);
  const [numberOfTrialDays, setNumberOfTrialDays] = useState(0);
  const [currentInvoiceData, setCurrentInvoiceData] = useState(null);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const [productQuantityMinimum, setProductQuantityMinimum] = useState(1);
  const [upgradeSubscriptionStep, setUpgradeSubscriptionStep] = useState(0);
  const [contactSalesUrl, setContactSalesUrl] = useState(baseContactSalesUrl);
  const [currentSelectedPricePlanId, setCurrentSelectedPricePlanId] =
    useState(null);
  const [isStripeQuantityDialogOpen, setIsStripeQuantityDialogOpen] =
    useState(false);
  const [isTrialDialogOpen, setIsTrialDialogOpen] = useState(false);
  const [xdrTrialInfoCurrentStep, setXDRTrialInfoCurrentStep] = useState(1);
  const [isStartingTrial, setIsStartingTrial] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [numberOfDeployedDevices, setNumberOfDeployedDevices] = useState(null);
  const [numberOfInstallKeys, setNumberOfInstallKeys] = useState(null);
  const [upgradeErrorMessage, setUpgradeErrorMessage] = useState(false);
  const [isLaunchingStripePortal, setIsLaunchingStripePortal] = useState(false);

  const connectorImageByTheme =
    cookies.get("theme") === "shadow"
      ? cloudConnectorModalDark
      : cloudConnectorModalLight;
  const ahiImageByTheme =
    cookies.get("theme") === "shadow" ? ahiControlBarDark : ahiControlBarLight;

  // check if org and user have proper requirements to initiate an XDR timed trial
  const isFreeOrg = props.currentOrg?.license === FREE_LICENSE;
  const isProperMarketType =
    props.currentOrg?.market === 30 || props.currentOrg?.market === 0; // Self-Service and Direct only
  const isCurrentUserAdmin =
    props.currentUser && props.currentUser.orgRoles
      ? props.currentUser.orgRoles
          .filter((role) => role.orgId === props.orgId)
          .map((role) => role.roleId)
      : [];

  const getAndSetCustomerSubscription = async () => {
    setIsLoading(true);
    let activeCustomer = null;

    if (!props.currentOrg) return;

    const {
      data: { prices },
    } = await API.stripe.getPrices();

    const formattedProducts = formatProducts(prices, availablePlans);

    setProducts(formattedProducts);

    if (props.currentOrg.stripeCustomerId) {
      const {
        data: { customer },
      } = await API.stripe.getCustomer(props.currentOrg.stripeCustomerId);
      activeCustomer = customer;
    }

    // gets current data and formats for UI
    // planData = { productId, renewalDate, invoiceEmail, subscriptionId }
    // invoiceData = { nextIssueDate, invoiceTotal }
    const { planData, invoiceData } =
      getCurrentPlanAndInvoiceData(activeCustomer);

    setCurrentPlanData(planData);
    setCurrentInvoiceData(invoiceData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (props.currentOrg) {
      if (props.isScheduledLicenseActive) {
        setXDRTrialInfoCurrentStep(2);
      }
    } else {
      setXDRTrialInfoCurrentStep(1);
    }
  }, [props.isScheduledLicenseActive, props.currentOrg]);

  useEffect(() => {
    // get org's license
    const currentOrgLicense = get(
      props.currentOrg,
      ["config", "license"],
      "ADVANCED"
    );

    // check if current org's license is present in new product list
    const isCurrentLicenseLegacy =
      !products.find(
        ({ licenseValue }) => licenseValue === currentOrgLicense
      ) && currentOrgLicense !== "SIEM_STARTER_COMPLIANCE"; // TODO: clean this up

    // if not present, user should see warning of legacy plan
    setIsUserOnLegacyPlan(isCurrentLicenseLegacy);
  }, [props.currentOrg, products]);

  useEffect(() => {
    if (props.currentUser) {
      const { first_name = "", last_name = "", email = "" } = props.currentUser;
      const contactUrlToSet =
        baseContactSalesUrl +
        `?firstname=${first_name}&lastname=${last_name}&email=${email}`;
      setContactSalesUrl(contactUrlToSet);
    }
  }, [props.currentUser]);

  useEffect(() => {
    if (!props.currentOrg) {
      setIsLoading(true);
      props.getOrgs();
    } else if (props.currentOrg) {
      getAndSetCustomerSubscription();
    }
  }, [props.currentOrg]);

  useEffect(() => {
    // only fetch devices if not on an org with a license that allows LC API calls
    // or the org has the BA license feature added
    if (props.currentOrg) {
      if (
        props.license.isTagged("limited-to-xdr") ||
        props.license.isTagged("limited-to-siem-endpoint") ||
        props.license.isTagged("limited-to-xdr-trial") ||
        props.license.isTagged("limited-to-siem-starter") ||
        props.license.isTagged("limited-to-siem-starter-compliance") ||
        props.license.hasFeature("LOGSHIPPING")
      ) {
        getDevicesAndInstallKeyTotals();
      }
    }
  }, [props.currentOrg, props.license]);

  const getDevicesAndInstallKeyTotals = async () => {
    // get number of devices to compare against upgrade modal quantity value
    const agentRequest = new Request("/lcagent");
    const groupRequest = new Request("/lcgroup");
    const devices = await agentRequest.get();
    const installKeys = await groupRequest.get();

    const totalGroupSeatArray = installKeys.map((key) => key.agentSeatCount);

    const totalKeyCount = totalGroupSeatArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );

    setNumberOfInstallKeys(totalKeyCount);
    setNumberOfDeployedDevices(devices.length);
  };

  const handleChangePlan = async (selectedStripePriceId, isTrial = false) => {
    // this looks funky because I set up the 'Compliance Upgrade'
    // as an 'optionalUpgradeLicense' prop to the JSON for 'SIEM Starter'
    const selectedProduct = products.find(
      (product) => product?.stripeConfig?.priceId === selectedStripePriceId
    );

    const newSelectedProduct = selectedProduct
      ? selectedProduct
      : products.find(
          (product) =>
            product?.optionalUpgradeLicense?.stripeConfig?.priceId ===
            selectedStripePriceId
        )?.optionalUpgradeLicense;

    setSelectedProduct(newSelectedProduct);

    if (isTrial) {
      // if this is a superadmin starting a trial for
      // a user, open dialog for trial day entry
      toggleStripeQuantityDialog();
    } else if (currentPlanData?.subscriptionId) {
      // if we have a subscription id already, open modal
      // to handle the subscription as stripe's checkout
      // session does not handle subscription updates
      handleSetProductQuantityMinimum(newSelectedProduct);
      toggleUpgradeDialog();
    } else {
      // otherwise we can open the stripe-hosted
      // checkout session to establish intent
      toggleStripeQuantityDialog();
    }
  };

  const handleStripeCheckoutSession = async (priceId) => {
    let currentCustomerId;

    if (!priceId) {
      return setErrorMessage(
        "Oops, something doesn't look right with that request."
      );
    }

    setIsLaunchingStripePortal(true);

    try {
      if (props.currentOrg.stripeCustomerId) {
        currentCustomerId = props.currentOrg.stripeCustomerId;
      }

      const body = {
        customer: currentCustomerId || null,
        priceId: priceId,
        redirectUrl: window.location.href,
        userCount: productQuantity,
        trialDays: numberOfTrialDays,
      };

      const {
        data: { session },
      } = await API.stripe.createCheckoutSession(body);

      // open stripe hosted checkout in same window
      window.location.href = session.url;
      setIsLaunchingStripePortal(false);
    } catch (e) {
      setIsLaunchingStripePortal(false);
      setErrorMessage(
        `Oops, we had trouble launching the Stripe portal. Details: ${
          e.message || e
        }`
      );
    }
  };

  const handleCreateTrialSubscription = async () => {
    let currentCustomerId;

    if (props.currentOrg.stripeCustomerId)
      currentCustomerId = props.currentOrg.stripeCustomerId;

    const body = {
      trialDays: numberOfTrialDays,
      priceId: selectedProduct.priceId,
      customer: currentCustomerId || null,
    };

    const {
      data: { customerId },
    } = await API.stripe.createSubscription(body);

    props.currentOrg.set({
      stripeCustomerId: customerId,
      license: selectedProduct.license,
    });

    setIsLoading(true);
    setIsStripeQuantityDialogOpen(false);
    await props.currentOrg.update();
    setUpgradeSubscriptionStep(1);
    setIsUpgradeDialogOpen(true);
    setIsLoading(false);
  };

  const handleEditCustomer = async () => {
    // disable customer view if no customer to view
    if (!props.currentOrg.stripeCustomerId) return;

    setIsLaunchingStripePortal(true);

    try {
      const body = {
        redirectUrl: window.location.href,
        customer: props.currentOrg.stripeCustomerId,
      };

      const { data } = await API.stripe.createCustomerSession(body);

      // open stripe hosted customer portal
      window.location.href = data.session.url;

      setIsLaunchingStripePortal(false);
    } catch (e) {
      setIsLaunchingStripePortal(false);
      setErrorMessage(
        `Oops, we had trouble launching the Stripe portal. Details: ${
          e.message || e
        }`
      );
    }
  };

  const handleUpgradeSubscription = async () => {
    // non-customers should never make it here
    // but better safe than sorry
    if (!props.currentOrg.stripeCustomerId) return;

    try {
      // customers cannot downgrade their seat count/# of agents
      // if they have more deployed agents then the new adjusted number
      if (
        numberOfDeployedDevices > productQuantity ||
        numberOfInstallKeys > productQuantity
      ) {
        setUpgradeErrorMessage(true);
        return;
      }

      setIsUpgrading(true);

      const body = {
        quantity: productQuantity,
        plan: selectedProduct.stripeConfig.priceId,
        subscriptionId: currentPlanData.subscriptionId,
        // promotionCode: promotionCode, // exclude promo code for now
      };

      await API.stripe.updateSubscription(body);

      const { licenseValue } = selectedProduct;

      const existingFeaturesWithDefaultFeatures = [
        ...new Set(
          (props.currentOrg?.licenseFeatures ?? []).concat(
            DEFAULT_FEATURES[licenseValue]
          )
        ),
      ];

      props.currentOrg.set({
        license: licenseValue,
        licenseFeatures: existingFeaturesWithDefaultFeatures,
        agentSeatCount: productQuantity,
        userCount: productQuantity,
      });

      await props.currentOrg.update();

      getAndSetCustomerSubscription();
      setUpgradeSubscriptionStep(1);
    } catch (e) {
      toggleUpgradeDialog();
      setErrorMessage(
        `Oops, we had trouble updating your subscription. Details: ${
          e.message || e
        }`
      );
    }
  };

  const handleUpgradeSubscriptionStepTwo = () => {
    // TODO: repurpose this to use the reload func from redux
    window.location = window.location.href.split("?")[0];
    toggleUpgradeDialog();
    setProductQuantity(productQuantityMinimum);
  };

  const toggleUpgradeDialog = () => {
    setIsChecked(false);
    setIsLoading(false);
    setIsUpgrading(false);
    setIsUpgradeDialogOpen(!isUpgradeDialogOpen);
    setUpgradeSubscriptionStep(0);
  };

  const toggleStripeLaunchDialog = () => {
    setIsLaunchingStripePortal(!isLaunchingStripePortal);
  };

  const toggleStripeQuantityDialog = (selectedStripePriceId) => {
    const stripePriceId = selectedStripePriceId ? selectedStripePriceId : null;
    setCurrentSelectedPricePlanId(stripePriceId);
    setIsStripeQuantityDialogOpen(!isStripeQuantityDialogOpen);
  };

  const toggleTrialDialog = () => {
    setIsTrialDialogOpen(!isTrialDialogOpen);
  };

  const toggleCheck = () => {
    setIsChecked(!isChecked);
  };

  const handleSetProductQuantityMinimum = (plan) => {
    // advanced licensing requires a
    // minimum of 50 purchased
    let minimum = plan.name.toLowerCase().includes("advanced") ? 50 : 10;

    setProductQuantity(minimum);
    setProductQuantityMinimum(minimum);
  };

  const handleProductQuantityChange = (e) => {
    setProductQuantity(e.target.value);
  };

  // exclude promo code for now
  // const handlePromotionCodeChange = e => {
  //   setPromotionCode(e.target.value)
  // }

  const handleNumberOfTrialDaysChange = (e) => {
    setNumberOfTrialDays(e.target.value);
  };

  const startXDRTrial = () => {
    const startTime = moment.utc().toISOString();
    const endTime = moment.utc().add(37, "days").toISOString();
    const trialLicenseFeatures = DEFAULT_FEATURES["XDR_TRIAL"];

    props.currentOrg.set({
      licenseScheduled: {
        license_name: "XDR_TRIAL",
        start: startTime,
        end: endTime,
      },
      agentSeatCount: 50,
      // Trial compliant orgs most likely won't already have license features
      // But, I'm going to keep this conditional here for any rare edge cases
      licenseFeatures: props.currentOrg?.licenseFeatures
        ? [...props.currentOrg.licenseFeatures, ...trialLicenseFeatures]
        : [...trialLicenseFeatures],
      dataTableExpireDays: 366,
    });

    setIsStartingTrial(true);
    props.currentOrg
      .update()
      .then(() => {
        window.location.reload();
        setXDRTrialInfoCurrentStep(2);
        setIsStartingTrial(false);
      })
      .catch((e) => {
        setIsStartingTrial(false);
        setErrorMessage(
          "Oops, we had a problem initiating a trial for your org. Please contact support for assistance."
        );
      });
  };

  const letsGoOnClick = () => {
    props.routeToInstallationPage(props.orgId);
  };

  const navigateToCloudConnectorPage = () => {
    props.routeToCloudConnectorPage(props.orgId);
  };

  const disableStartTrialByRole = () => {
    // Only allow a user to self-initate a trial if they are an SA, admin, or manager
    if (
      props.superadmin ||
      isCurrentUserAdmin.includes(10) ||
      isCurrentUserAdmin.includes(20)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const renderXDRTrialBanners = () => {
    if (xdrTrialInfoCurrentStep === 1) {
      return (
        <>
          <Card
            className={rootClasses.timedTrialInfoCard}
            datacy={"xdrTrialStep1Banner"}
          >
            <div className={rootClasses.xdrTrialChip}>XDR Platform Trial</div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className={rootClasses.xdrTrialFlexContainer}>
                <div className={rootClasses.xdrTrialStepOneImageContainer}>
                  <div className={rootClasses.xdrTrialImage}>
                    <img
                      src={timedTrialInfo}
                      alt="Blumira XDR includes a SIEM, Enpoint visibility, and SOAR"
                      className={rootClasses.xdrTrialImage}
                    />
                  </div>
                </div>
                <div className={rootClasses.xdrTrialStepOneTextContainer}>
                  <h2 className={rootClasses.xdrTrialStepTitle}>
                    Gain greater visibility & better protection with XDR
                    Platform
                  </h2>
                  <p>
                    Spend less than 15 minutes a day managing security with
                    Blumira's SIEM + XDR
                  </p>
                  <ul>
                    <li>
                      Detect early signs of attacker activity your other tools
                      may miss
                    </li>
                    <li>
                      Contain or block threats immediately to prevent widespread
                      damage
                    </li>
                    <li>
                      Easily meet compliance with a year of data retention &
                      compliance reports
                    </li>
                    <li>
                      Extend your team's capabilities with our 24/7 SecOps
                      support
                    </li>
                  </ul>
                  <p>
                    Our platform comes ready out-of-the-box, requiring no
                    security expertise
                  </p>
                  {disableStartTrialByRole() ? (
                    <Tooltip
                      title={
                        "Please contact your administrator to start a trial"
                      }
                      placement={"top"}
                    >
                      <div>
                        <Button
                          variant={"contained"}
                          onClick={startXDRTrial}
                          className={rootClasses.startTrialButton}
                          disabled={true}
                          datacy={"xdrTrialStartTrialButton"}
                        >
                          Start free trial
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Button
                      variant={"contained"}
                      onClick={startXDRTrial}
                      className={rootClasses.startTrialButton}
                      disabled={isStartingTrial}
                      datacy={"xdrTrialStartTrialButton"}
                    >
                      {isStartingTrial
                        ? "Starting free trial..."
                        : "Start free trial"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </>
      );
    } else if (xdrTrialInfoCurrentStep === 2) {
      return (
        <Card
          className={rootClasses.timedTrialInfoCard}
          datacy={"xdrTrialStep2Banner"}
        >
          <div className={rootClasses.xdrTrialCardHeaderWrap}>
            <div className={rootClasses.xdrTrialChip}>XDR Platform Trial</div>
            <Link
              href="https://info.blumira.com/xdr-trial"
              style={{ margin: 10 }}
              underline={"none"}
            >
              Connect with a coach <LaunchIcon />
            </Link>
          </div>
          <CardContent>
            <div className={rootClasses.stepTwoContentWrap}>
              <div style={{ height: 80, width: 80 }}>
                <img
                  src={animatedGIF}
                  alt="Animated celebratory horn with confetti"
                  className={rootClasses.xdrTrialImage}
                />
              </div>
              <h2 className={rootClasses.xdrTrialStepTitle}>
                Congratulations! You can now try out XDR Platform free for 30
                days!
              </h2>
              <p style={{ fontSize: 18 }}>
                Start protecting your organization right away with Blumira
                Agent.
              </p>
            </div>
            <div className={rootClasses.stepTwoContentWrap}>
              <h3 style={{ fontSize: 18, fontWeight: "bold", marginTop: 20 }}>
                Blumira Get Started Guide
              </h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  maxWidth: 1800,
                }}
              >
                <TimedTrialStepCard
                  title={"Step 1"}
                  subTitle={"Install agent"}
                  text={
                    "Install our agent on a Windows, Mac or Linux device to start sending us logs."
                  }
                  platformIcons={true}
                  additionalText={"Windows, Mac or Linux"}
                  buttonText={"Let's go!"}
                  buttonVariant={"contained"}
                  className={rootClasses.xdrTrialStepCard}
                  onClick={letsGoOnClick}
                  datacy={"xdrTrialLetsGoButton"}
                />
                <TimedTrialStepCard
                  title={"Step 2"}
                  subTitle={"Run a test"}
                  text={
                    "After the agent starts sending us logs, we'll guide you through how to easily simulate a security event."
                  }
                  className={rootClasses.xdrTrialStepCard}
                />
                <TimedTrialStepCard
                  title={"Step 3"}
                  subTitle={"Investigate a finding"}
                  text={
                    "Blumira will send you a Finding (alert) and we'll walk you through how to respond."
                  }
                  className={rootClasses.xdrTrialStepCard}
                />
              </div>
            </div>
          </CardContent>
          <CardActions
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 100,
            }}
          >
            <Button
              variant={"text"}
              onClick={() => setXDRTrialInfoCurrentStep(3)}
              className={rootClasses.textButton}
            >
              See what else you can do with XDR Platform
            </Button>
          </CardActions>
        </Card>
      );
    } else if (xdrTrialInfoCurrentStep === 3) {
      return (
        <Card className={rootClasses.timedTrialInfoCard}>
          <div className={rootClasses.xdrTrialChip}>XDR Platform Trial</div>
          <div style={{ display: "block", margin: "auto", maxWidth: 1800 }}>
            <div style={{ padding: "10px 20px 0" }}>
              <Button
                variant={"outlined"}
                style={{
                  width: "fit-content",
                  fontSize: 14,
                  padding: "2px 12px",
                  marginBottom: 15,
                }}
                onClick={() => setXDRTrialInfoCurrentStep(2)}
              >
                <ArrowBackIcon
                  fontSize={"inherit"}
                  style={{ marginRight: 5 }}
                />
                Back
              </Button>
              <h2 className={rootClasses.xdrTrialStepTitle}>
                Get more value out of your trial
              </h2>
            </div>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div className={rootClasses.stepThreeContentWrap}>
                <Card className={rootClasses.xdrTrialStepThreeCard}>
                  <h3 className={rootClasses.xdrTrialStepTitle}>
                    Protect an application by adding a Cloud Connector
                  </h3>
                  <p>
                    Blumira's Cloud Connectors make it quick and easy to get
                    critical log data...so that security events can be detected.
                  </p>
                  <div className={rootClasses.xdrTrialImage}>
                    <img
                      src={connectorImageByTheme}
                      alt="Selection of available cloud connectors within Blumira"
                      className={rootClasses.xdrTrialImage}
                      style={{
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <Button
                    variant={"contained"}
                    className={rootClasses.containedButton}
                    onClick={navigateToCloudConnectorPage}
                  >
                    Add Cloud Connector
                  </Button>
                </Card>
              </div>
              <div className={rootClasses.stepThreeContentWrap}>
                <Card
                  className={rootClasses.xdrTrialStepThreeCard}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <h3 className={rootClasses.xdrTrialStepTitle}>
                      Leverage the power of Automated Host Isolation
                    </h3>
                    <p>
                      Blumira Agent's automated host isolation will immediately
                      isolate an endpoint when a critical threat is identified.
                      Automatically block traffic from known malicious sources
                      (dynamic blocklists).
                    </p>
                    <div className={rootClasses.xdrTrialImage}>
                      <img
                        src={ahiImageByTheme}
                        alt="Control bar for automated host isolation within Blumira"
                        className={rootClasses.xdrTrialImage}
                        style={{
                          marginBottom: 10,
                        }}
                      />
                    </div>
                    <ul>
                      <li>
                        Select which Detection Rules should trigger automated
                        host isolation
                      </li>
                      <li>
                        Exclude specific devices from automated host isolation
                      </li>
                      <li>You can also manually isolate hosts</li>
                      <li>
                        Global start/pause control bar at the top of Blumira
                      </li>
                    </ul>
                  </div>
                  <div>
                    <Link
                      href="https://blumira.help/auto-isolation"
                      underline={"none"}
                    >
                      Automated Host Isolation <LaunchIcon />
                    </Link>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </Card>
      );
    }
  };

  const whatTrialExperienceToShow = () => {
    const shouldShowFeatureOptions = {
      isFreeOrg: isFreeOrg,
      isProperMarketType: isProperMarketType,
      superadmin: props.superadmin,
      isCurrentUserAdmin: isCurrentUserAdmin,
      orgHasScheduledLicense: props.orgHasScheduledLicense,
      isScheduledLicenseActive: props.isScheduledLicenseActive,
    };

    // If a scheduled license exists and the trial timeframe has expired, do not show trial banners
    if (
      isFreeOrg &&
      isProperMarketType &&
      props.orgHasScheduledLicense !== undefined &&
      !props.isScheduledLicenseActive
    ) {
      return null;
    } else if (
      // If org has currently active trial or meets requirements for a trial, show the trial banners
      shouldShowFeature(shouldShowFeatureOptions)
    ) {
      return renderXDRTrialBanners();
    } else {
      return null;
    }
  };

  const displayUpgradeHelperText = () => {
    if (upgradeErrorMessage) {
      return "Oops! You still have agents running and/or installation keys created that exceed the quantity associated with this change. Remove the devices and/or installation keys you no longer need then try again, or cancel this change to keep the agents and installation keys you currently have.";
    } else if (productQuantity < productQuantityMinimum) {
      return `You must purchase at least ${productQuantityMinimum} license${
        productQuantityMinimum > 1 ? "s" : ""
      }`;
    } else {
      return "";
    }
  };

  const getProductNameForBillingDetails = (currentPlanData, products) => {
    if (!currentPlanData?.priceId) return "";

    const product = products.find((product) => {
      if (!product.stripeConfig?.priceId) return;
      return product.stripeConfig.priceId === currentPlanData.priceId;
    });

    if (product) {
      return `(${product.name})`;
    }

    const optionalUpgradeProduct = products.find(
      ({ optionalUpgradeLicense }) =>
        optionalUpgradeLicense?.stripeConfig?.priceId ===
        currentPlanData.priceId
    );

    if (optionalUpgradeProduct) {
      return `(${optionalUpgradeProduct.optionalUpgradeLicense.name})`;
    }

    return "";
  };

  const isUpgradeButtonDisabled = () => {
    return (
      !isChecked ||
      isUpgrading ||
      upgradeErrorMessage ||
      productQuantity < productQuantityMinimum
    );
  };

  return (
    <Root>
      <div className={rootClasses.page}>
        {currentPlanData && currentPlanData.isInTrial && (
          <Alert
            severity={"info"}
            action={
              <Button
                color={"inherit"}
                size={"small"}
                onClick={handleEditCustomer}
              >
                MANAGE SUBSCRIPTION
              </Button>
            }
          >
            <p className={rootClasses.alertText}>
              Your trial will end and you will be charged on{" "}
              {moment
                .unix(currentPlanData?.trialEndDate)
                .format("MMMM DD YYYY")}
            </p>
          </Alert>
        )}
        <div className={rootClasses.section}>
          {isLoading && !isUpgrading ? (
            <div className={rootClasses.loadingContainer}>
              <Typography variant={"h6"}>
                Loading your billing information...
              </Typography>
              <CircularProgress />
            </div>
          ) : (
            !!products.length && (
              <div>
                {errorMessage && (
                  <Alert
                    className={rootClasses.errorBanner}
                    severity="error"
                    action={
                      <a
                        className={rootClasses.errorAction}
                        rel={"noopener noreferrer"}
                        target={"_blank"}
                        href={"https://blumira.help/help"}
                      >
                        CONTACT
                      </a>
                    }
                  >
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
                {isUserOnLegacyPlan && (
                  <Alert
                    severity={"info"}
                    className={rootClasses.legacyPlanAlert}
                  >
                    You are currently on a Legacy plan.{" "}
                    <a
                      href={contactSalesUrl}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      Contact Sales
                    </a>{" "}
                    to discuss your options.
                  </Alert>
                )}
                {props.currentOrg && whatTrialExperienceToShow()}
                <Grid container spacing={2}>
                  {products.map((plan, index) => (
                    <Grid
                      item
                      key={index}
                      xs={plan.optionalUpgradeLicense ? 5 : 2.33}
                    >
                      <PlanOption
                        plan={plan}
                        license={props.license}
                        isStripeCustomer={false}
                        startXDRTrial={startXDRTrial}
                        contactSalesUrl={contactSalesUrl}
                        isUserOnLegacyPlan={isUserOnLegacyPlan}
                        isScheduledLicenseActive={
                          props.isScheduledLicenseActive
                        }
                        openCheckoutSession={(priceId) =>
                          handleStripeCheckoutSession(priceId)
                        }
                        changePlan={(priceId) => {
                          handleChangePlan(priceId);
                        }}
                        activePlan={
                          props.orgHasScheduledLicense !== undefined &&
                          props.isScheduledLicenseActive
                            ? "XDR"
                            : get(
                                props.currentOrg,
                                ["config", "license"],
                                "ADVANCED"
                              )
                        }
                        shouldShowTrialCta={shouldShowFeature({
                          isFreeOrg: isFreeOrg,
                          isProperMarketType: isProperMarketType,
                          superadmin: props.superadmin,
                          isCurrentUserAdmin: isCurrentUserAdmin,
                          orgHasScheduledLicense: props.orgHasScheduledLicense,
                          isScheduledLicenseActive:
                            props.isScheduledLicenseActive,
                        })}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )
          )}
        </div>

        {!isLoading && (
          <>
            <div className={rootClasses.section}>
              <p className={rootClasses.sectionTitle}>
                Billing details{" "}
                {getProductNameForBillingDetails(currentPlanData, products)}
              </p>
              <div className={rootClasses.sectionInfoContainer}>
                <div
                  className={`${rootClasses.sectionInfoCard} ${rootClasses.rightBorder}`}
                >
                  <p className={rootClasses.sectionInfoCardTitle}>Renewal:</p>
                  <p className={rootClasses.sectionInfoCardText}>
                    {currentPlanData?.renewalDate
                      ? "Monthly"
                      : "Not applicable"}
                  </p>
                  {/* <p className={classes.link}>Try the Professional Plan risk-free for 30 days</p> TODO: tie to...something */}
                  <div />
                </div>
                <div
                  className={`${rootClasses.sectionInfoCard} ${rootClasses.rightBorder}`}
                >
                  <p className={rootClasses.sectionInfoCardTitle}>
                    Payment method:
                  </p>
                  <p className={rootClasses.sectionInfoCardText}>
                    {currentPlanData?.paymentMethod
                      ? currentPlanData?.paymentMethod
                      : "Not applicable"}
                  </p>
                  <p className={rootClasses.link} onClick={handleEditCustomer}>
                    Change
                  </p>
                  <div />
                </div>
                <div className={rootClasses.sectionInfoCard}>
                  <p className={rootClasses.sectionInfoCardTitle}>
                    Send invoices to:
                  </p>
                  <p className={rootClasses.sectionInfoCardText}>
                    {currentPlanData?.invoiceEmail
                      ? currentPlanData.invoiceEmail
                      : "Not applicable"}
                  </p>
                  <p className={rootClasses.link} onClick={handleEditCustomer}>
                    Change
                  </p>
                </div>
              </div>
            </div>

            <div className={rootClasses.section}>
              <div className={rootClasses.manageInvoicesContainer}>
                <p className={rootClasses.sectionTitle}>Invoices</p>
                <Button
                  variant={"text"}
                  color={"primary"}
                  onClick={handleEditCustomer}
                  className={rootClasses.manageButton}
                  disabled={!currentInvoiceData?.nextIssueDate}
                >
                  View all invoices
                </Button>
              </div>
              <div className={rootClasses.sectionInfoContainer}>
                <div
                  className={`${rootClasses.sectionInfoCard} ${rootClasses.rightBorder}`}
                >
                  <p className={rootClasses.sectionInfoCardTitle}>
                    {" "}
                    Next invoice will be issued on:
                  </p>
                  <p className={rootClasses.sectionInfoCardText}>
                    {currentInvoiceData?.nextIssueDate
                      ? moment
                          .unix(currentInvoiceData.nextIssueDate)
                          .format("MMMM DD YYYY")
                      : "Not applicable"}
                  </p>
                </div>
                <div
                  className={`${rootClasses.sectionInfoCard} ${rootClasses.rightBorder}`}
                >
                  <p className={rootClasses.sectionInfoCardTitle}>
                    Billable users detected this invoice period:
                  </p>
                  <p className={rootClasses.sectionInfoCardText}>
                    {currentInvoiceData?.knowledgeWorkers
                      ? currentInvoiceData?.knowledgeWorkers
                      : "Not applicable"}
                  </p>
                </div>
                <div className={rootClasses.sectionInfoCard}>
                  <p className={rootClasses.sectionInfoCardTitle}>
                    Invoice total:
                  </p>
                  <p className={rootClasses.sectionInfoCardText}>
                    {currentInvoiceData?.invoiceTotal
                      ? currentInvoiceData.invoiceTotal
                      : "$0"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}

        <BillingDialog
          open={isLaunchingStripePortal}
          onClose={toggleStripeLaunchDialog}
        >
          <DialogContent>
            <div className={dialogClasses.stripeLaunchDialogContainer}>
              <p>Redirecting you to your Stripe portal...</p>
              <CircularProgress />
            </div>
          </DialogContent>
        </BillingDialog>

        <BillingDialog open={isUpgradeDialogOpen} onClose={toggleUpgradeDialog}>
          <DialogTitle>
            Change{upgradeSubscriptionStep === 1 && "d"}{" "}
            {selectedProduct ? "to" : null} {selectedProduct?.name || ""}
          </DialogTitle>
          {upgradeSubscriptionStep === 0 ? (
            <DialogContent>
              {isUpgrading ? (
                <div className={dialogClasses.stripeLaunchDialogContainer}>
                  <p>Updating your subscription with Stripe...</p>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <p>
                    By checking the box and selecting to 'Update Now' you are
                    giving Blumira permission to update your plan and incur any
                    related charges to the credit card stored in Stripe.
                  </p>
                  <p>
                    To view your saved credit card details, select 'Manage
                    Credit Card' to open your Stripe customer portal.
                  </p>
                  <p>
                    After updating you may need to{" "}
                    <strong>
                      refresh your browser to experience relevant changes to
                      your account
                    </strong>
                    .
                  </p>
                  <TextField
                    fullWidth
                    type={"number"}
                    value={productQuantity}
                    className={dialogClasses.productQuantity}
                    label={"# of seats"}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 10,
                      max: 50,
                    }}
                    onChange={handleProductQuantityChange}
                    error={
                      upgradeErrorMessage ||
                      productQuantity < productQuantityMinimum
                    }
                    helperText={displayUpgradeHelperText()}
                  />
                  {/*
              FYI: limiting promo code scope for now, leaving
              code here for when we want to test upgrades
            */}
                  {/* <TextField
              type={'text'}
              value={promotionCode}
              label={'Promotion code'}
              className={classes.productQuantity}
              onChange={handlePromotionCodeChange}
            /> */}
                  <div className={dialogClasses.certify}>
                    <Checkbox
                      color={"primary"}
                      checked={isChecked}
                      onChange={toggleCheck}
                    />
                    <p>
                      I agree to changing my plan and to recieve a prorated
                      invoice immediately, charged to the credit card on file
                    </p>
                  </div>
                </>
              )}
            </DialogContent>
          ) : (
            <DialogContent>
              <p>Your plan was successfully upgraded.</p>
              <p>Please refresh your browser for your new experience.</p>
            </DialogContent>
          )}
          {upgradeSubscriptionStep === 0 ? (
            <DialogActions className={dialogClasses.dialogActions}>
              <Button
                disabled={isUpgradeButtonDisabled()}
                onClick={handleUpgradeSubscription}
                color={"primary"}
                variant={"contained"}
              >
                {isUpgrading ? "Updating..." : "Update now"}
              </Button>
              <Button
                disabled={isUpgrading}
                onClick={handleEditCustomer}
                color={"primary"}
                variant={"contained"}
              >
                Manage credit card
              </Button>
              <Button
                onClick={toggleUpgradeDialog}
                color={"primary"}
                variant={"outlined"}
              >
                {isUpgrading ? "Close" : "Cancel"}
              </Button>
            </DialogActions>
          ) : (
            <DialogActions className={dialogClasses.dialogActions}>
              <Button
                onClick={handleUpgradeSubscriptionStepTwo}
                color={"primary"}
                variant={"contained"}
              >
                Refresh Browser
              </Button>
              <Button
                onClick={toggleUpgradeDialog}
                color={"primary"}
                variant={"outlined"}
              >
                Cancel
              </Button>
            </DialogActions>
          )}
        </BillingDialog>

        <BillingDialog
          open={isStripeQuantityDialogOpen}
          onClose={toggleStripeQuantityDialog}
          datacy={"billingChangePlanDialog"}
        >
          <DialogTitle>Change to {selectedProduct?.name || ""}</DialogTitle>
          <DialogContent>
            {props.superadmin && (
              <div>
                <p>Blumira super admins may begin trials for customers.</p>
                <div className={dialogClasses.stepContainer}>
                  <div className={dialogClasses.numberContainer}>
                    <p>1</p>
                  </div>
                  <p>Enter number of trial days below</p>
                </div>
                <div className={dialogClasses.stepContainer}>
                  <div className={dialogClasses.numberContainer}>
                    <p>2</p>
                  </div>
                  <p>Click 'Create Trial Subscription'</p>
                </div>
                <div className={dialogClasses.stepContainer}>
                  <div className={dialogClasses.numberContainer}>
                    <p>3</p>
                  </div>
                  <p>Refresh the browser to see your updates</p>
                </div>
                <div className={dialogClasses.stepContainer}>
                  <div className={dialogClasses.numberContainer}>
                    <p>4</p>
                  </div>
                  <p>
                    To cancel, select 'Manage Subscription' in the alert then in
                    the Stripe portal 'Cancel Plan'
                  </p>
                </div>
                <TextField
                  type={"number"}
                  value={numberOfTrialDays}
                  label={"# of trial days"}
                  onChange={handleNumberOfTrialDaysChange}
                  helperText={"free days added to beginning of subscription"}
                />
                <Divider className={dialogClasses.divider} />
              </div>
            )}
            <p>
              Blumira uses the secure Stripe Portal to manage subscriptions.
            </p>
            <div className={dialogClasses.stepContainer}>
              <div className={dialogClasses.numberContainer}>
                <p>1</p>
              </div>
              <p>Click Launch Stripe Portal below</p>
            </div>
            <div className={dialogClasses.stepContainer}>
              <div className={dialogClasses.numberContainer}>
                <p>2</p>
              </div>
              <p>
                Enter your total user count when prompted to 'Update Quantity'
              </p>
            </div>
            <div className={dialogClasses.stepContainer}>
              <div className={dialogClasses.numberContainer}>
                <p>3</p>
              </div>
              <p>Enter payment information, then click 'Subscribe'</p>
            </div>
          </DialogContent>
          <DialogActions className={dialogClasses.dialogActions}>
            {props.superadmin && (
              <Button
                onClick={handleCreateTrialSubscription}
                color={"primary"}
                variant={"contained"}
              >
                Create Trial Subscription
              </Button>
            )}
            <Button
              onClick={handleStripeCheckoutSession}
              color={"primary"}
              variant={"contained"}
            >
              Launch Stripe Portal
            </Button>
            <Button
              onClick={toggleStripeQuantityDialog}
              color={"primary"}
              variant={"outlined"}
            >
              Cancel
            </Button>
          </DialogActions>
        </BillingDialog>
        <BillingDialog open={isTrialDialogOpen} onClose={toggleTrialDialog}>
          <div className={dialogClasses.trialDialogContainer}>
            <p className={dialogClasses.trialDialogHeader}>
              Your Free XDR Platform Trial is Underway
            </p>
            <p className={dialogClasses.trialDialogText}>
              Our team will follow up with you shortly to activate a free 14 day
              trial of the full featured XDR Platform and will provide any
              support needed along the way.{" "}
            </p>
            <Button
              color={"primary"}
              variant={"outlined"}
              onClick={toggleTrialDialog}
              className={dialogClasses.trialDialogCloseButton}
            >
              Close
            </Button>
          </div>
        </BillingDialog>
      </div>
    </Root>
  );
};

const mapStateToProps = (state) => {
  const orgId = state.location.payload.orgId;

  const currentOrg = state.org.listOrgs.orgs.find((o) => o["id"] === orgId);

  const now = moment.utc().toISOString();
  const orgHasScheduledLicense = currentOrg?.licenseScheduled;
  const isScheduledLicenseActive = orgHasScheduledLicense
    ? currentOrg?.licenseScheduled?.end > now &&
      currentOrg?.licenseScheduled?.start < now
    : false;

  return {
    orgId,
    currentOrg,
    license: state.license,
    location: state.location,
    currentUser: state?.session?.settings?.user,
    superadmin: state?.session?.settings?.user?.superadmin,
    isScheduledLicenseActive,
    orgHasScheduledLicense,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrgs: () => dispatch(getOrgs()),
  routeToInstallationPage: (orgId) => {
    dispatch({
      type: "PAGE",
      payload: {
        orgId: orgId,
        toplevel: "blumiraagent",
        secondlevel: "installation",
      },
    });
  },
  routeToCloudConnectorPage: (orgId) => {
    dispatch({
      type: "PAGE",
      payload: {
        orgId: orgId,
        toplevel: "settings",
        secondlevel: "cloudmodules",
      },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingPageView);
/* eslint-enable no-unused-vars */
