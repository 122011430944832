import React from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";

const LogTypeOption = (props) => {
  return (
    <div data-cy="dataSourceOption">
      <Typography variant="h6">{props.displayName || props.name}</Typography>
      <Typography variant="body2" color="textSecondary">
        {props.description}
      </Typography>
      <Typography variant="overline">
        Categories: {props.categoryNames.join(", ")}
      </Typography>
    </div>
  );
};

// ** Proptypes ***********************
LogTypeOption.propTypes = {
  displayName: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  categoryNames: PropTypes.array.isRequired,
};

// ** Default props *******************
LogTypeOption.defaultProps = {
  name: "",
  description: "",
};

export default LogTypeOption;
