import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

const PREFIX = "TosDialog";

const classes = {
  paper: `${PREFIX}-paper`,
  titleContainer: `${PREFIX}-titleContainer`,
  textContainer: `${PREFIX}-textContainer`,
  backButton: `${PREFIX}-backButton`,
  agreeButton: `${PREFIX}-agreeButton`,
  disagreeButton: `${PREFIX}-disagreeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    top: "50%",
    left: "50%",
    maxHeight: "65vh",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    "& h4": {
      fontSize: 14,
      fontWeight: "bold",
      color: theme.palette.text.primary,
    },
    "& p": {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.titleContainer}`]: {
    height: 100,
    display: "flex",
    marginBottom: -50,
    flexDirection: "column",
    "& p": {
      fontSize: 10,
      marginTop: -5,
    },
  },
  [`& .${classes.textContainer}`]: {
    padding: "0 !important",
    fontSize: 14,
  },
  [`& .${classes.backButton}`]: {
    float: "right",
    marginTop: -40,
  },
  [`& .${classes.agreeButton}`]: {
    color: "white",
    backgroundColor: blumiraBlue,
  },
  [`& .${classes.disagreeButton}`]: {
    color: blumiraBlue,
    borderColor: blumiraBlue,
  },
}));

const blumiraBlue = "#007ffc";

const TosDialog = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = useState("paper");
  const { open, initial = false, handleBack } = props;

  return (
    <div className={classes.dialogContainer}>
      <StyledDialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
        aria-labelledby="tos-dialog-title"
        aria-describedby="tos-dialog-description"
      >
        <DialogTitle id="tos-dialog-title">
          <div className={classes.titleContainer}>
            <h2>Blumira Terms of Use</h2>
            <p>Last Modified: May 9, 2023</p>
          </div>
          {!initial && ( // user is viewing via profile
            <React.Fragment>
              <Button
                color={"primary"}
                variant={"outlined"}
                onClick={handleBack}
                className={classes.backButton}
              >
                Exit
              </Button>
            </React.Fragment>
          )}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContent
            id="tos-dialog-description"
            className={classes.textContainer}
          >
            <p>
              Welcome to Blumira! These “Terms of Use” are a legal contract
              between Blumira, Inc., a Delaware corporation (“the Company”,
              “us”, “we” or “our”) and you (referred to as “you, “your” or
              “user”), as a user of Blumira's proprietary threat detection
              engine (the “Service”). Please let us know if you have any
              questions about this policy. Your use of the Service, and these
              Terms of Use, are subject to either: (i) Terms and Conditions,
              (ii) Pass Through Terms and Conditions, (iii) a Reseller
              Agreement, or (iv) the Blumira, Inc. Managed Service Provider
              Agreement (each referred to herein as the “Master Agreement”)
              between the entity that provides you with access to the Service
              (the “Authorized Entity”) and the Company
            </p>

            <h4>1. PRELIMINARY LEGAL TERMS</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">
                  Entire Agreement; Modification.{" "}
                </span>
                <span className="listItemContent">
                  The entire agreement between you and the Company is made up of
                  these Terms of Use, the Company's Privacy Policy{" "}
                  <a href="https://www.blumira.com/privacy-policy">
                    https://www.blumira.com/privacy-policy
                  </a>
                  , the Master Agreement and any other terms or policies
                  accompanying our Service (collectively, the “Agreement”).
                  These Terms of Use may be updated and modified by the Company
                  from time to time. Those modifications are incorporated as
                  part of these Terms of Use. You will be notified of
                  significant modifications when you use the Service. If you do
                  not accept the modifications to the Terms of Use, your sole
                  remedy is not to use the Company's Service.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Age. </span>
                <span className="listItemContent">
                  You must be at least eighteen (18) years of age to enter into
                  a contract with the Company and use the Service. In no case
                  are persons under the age of thirteen (13) years old permitted
                  to use the Service.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  “Signing“ of Terms of Use.{" "}
                </span>
                <span className="listItemContent">
                  You “sign” and accept these Terms of Use by affirmatively
                  consenting to the acceptance of these Terms of Use before you
                  use the Service the first time after you are provided with
                  access by the Authorized Entity.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  Authorized Entity Contact.{" "}
                </span>
                <span className="listItemContent">
                  The Authorized Entity has made the Service available for your
                  use. Any questions on these Terms of Use should be directed to
                  Blumira using the information in Section 22, but any questions
                  or comments about intended use of the Service should be
                  directed to the Authorized Entity's administrator for the
                  Service.
                </span>
              </li>
            </ol>

            <h4>2. OUR SERVICE; YOUR ACCOUNT</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">
                  Our Service; Service Functionality.
                </span>

                <ol className="listItemContent subSection" type="i">
                  <li>
                    You must conduct yourself appropriately and within the
                    bounds of applicable law when using the Service. Use of the
                    Service is a privilege and not a right. The Company or the
                    Authorized Entity may suspend or deactivate your account or
                    disable access to the Service at any time as set forth in
                    these Terms of Use.
                  </li>
                </ol>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Your Account.</span>

                <ol className="listItemContent subSection" type="i">
                  <li>
                    In order to use the Service, the Authorized Entity will
                    assign an administrator to create an account for you. You
                    promise that: (i) you will be the only person accessing the
                    Service through the account created for you, (ii) you will
                    keep your account password secure, and (iii) you will notify
                    the Company of any suspected breach of security or
                    unauthorized use of your account. You are responsible for
                    all actions that are performed with, by, or under your
                    account credentials whether done by you or by others. We are
                    not liable for any damages that result from unauthorized
                    account access or use.
                  </li>

                  <li>
                    If you wish to delete your account, please contact the
                    administrator assigned by the Authorized Entity.
                  </li>
                </ol>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Your Use.</span>

                <ol className="listItemContent subSection" type="i">
                  <li>
                    Your account is not transferable, assignable, or
                    sublicensable to any other party. You obtain no ownership
                    rights in the Service by creating an account. Instead, you
                    are given a license to use the Service solely according to
                    the terms of these Terms of Use and for the purpose intended
                    when the Authorized Entity gave you access to the Service.
                    The license to the Service, and any information regarding
                    your use of the Service, will be valid so long as you and
                    the Authorized Entity remain in compliance with the terms of
                    these Terms of Use or until your access to the Service is
                    deactivated by the Authorized Entity, in their sole
                    discretion. However, we or the Authorized Entity may retain
                    and use the information about your use of the Service as set
                    forth in our Privacy Policy.
                  </li>

                  <li>
                    You are solely responsible for the use of your account, how
                    you use the Service and your interactions with third parties
                    through your use of the Service. In the event you use the
                    Service to access any third party services, you remain
                    subject to the terms of such service. You are solely
                    responsible for the configuration of the Service. In the
                    event you need assistance configuring the Service, you may
                    contact Company support. As the Company does not control the
                    policies or practices of any third party applications,
                    services or websites, we encourage you to read (and you must
                    comply with) such application, service or website's terms
                    and policies. The Company is not responsible for your use or
                    misuse of any third party applications, services or
                    websites.
                  </li>

                  <li>
                    <span>
                      With respect to your use of the Service, you represent,
                      warrant and covenant that:
                    </span>

                    <ol type="1">
                      <li>
                        You are only using the Service in the manner approved by
                        the Authorized Entity and for purposes intended by the
                        Authorized Entity. Your use of the Service is solely for
                        the purposes of providing services to the Authorized
                        Entity.
                      </li>

                      <li>
                        Your use of the Service is not intended to, and may not
                        result in, any unlawful activities. Your use of the
                        Service is itself not an unlawful activity or a
                        solicitation to engage in any unlawful activity under
                        any Applicable Laws. For purposes of these Terms of Use,
                        “Applicable Laws” means those laws, ordinances, rules or
                        regulations related to you, your use of the Service and
                        the functionality of the Service.
                      </li>

                      <li>
                        In connection with your use of the Service, you will not
                        take any action that will or may result in the
                        transmission of any malicious or unsolicited software or
                        any malicious or inappropriate content.
                      </li>

                      <li>
                        You agree not to use any third party data in connection
                        with the Service without appropriate consent or
                        permission. By using the Service in connection with
                        analysis or processing of any data, you agree that such
                        consent or permission has been obtained and can be
                        provided to Company upon request.
                      </li>

                      <li>
                        You agree to comply with all Applicable Laws when
                        engaging in any activity in connection with your use of
                        the Service.
                      </li>
                    </ol>
                  </li>
                </ol>

                <span className="listItemContent">
                  If you violate any representation, warranty or covenant you
                  made above, we or the Authorized Entity can immediately
                  terminate or suspend your use of the Service.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">License to Use Service.</span>

                <ol className="listItemContent subSection" type="i">
                  <li>
                    Subject to compliance with Applicable Laws, your use of the
                    Service grants the Company, Authorized Entity, and their
                    affiliates a license to display, distribute, store,
                    transmit, reproduce, modify and otherwise use and reuse all
                    or part of the data gathered in connection with your use of
                    the Service. The license is non-exclusive, fully-paid,
                    royalty-free, worldwide, transferable and for all formats,
                    platforms, devices, modes of transmission, methods of
                    delivery, and media now known or later developed.
                  </li>

                  <li>
                    You may terminate the license you have granted to the
                    Company by removing or deleting (or writing to the Company
                    to remove or delete) information provided in connection with
                    your use of the Service. We, or our third party service
                    providers that have agreed to comply with Applicable Laws,
                    may retain such information until such time as the
                    information is overwritten or deleted or you request that
                    such information should be deleted or corrected.
                  </li>

                  <li>
                    Unless expressly authorized by us in writing, you are not
                    granted permission to copy, frame, embed, or otherwise
                    disseminate aggregated or specific content that the Company
                    provides to you, including any data or reports generated
                    using the Service, unless such dissemination is to Customer.
                    Your use of the Service must be in conformity with the
                    restrictions in Sections 7 and 8 above.
                  </li>
                </ol>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  Company Intellectual Property.{" "}
                </span>
                <span className="listItemContent">
                  The Company and/or its licensors retain ownership of all
                  intellectual property rights relating to the Company, the
                  Service, the Company's trademarks and copyrights, and any
                  Service-related software, data, text, images, graphics, logos,
                  user interface, and videos displayed through the Service. You
                  are authorized to use such materials only as expressly
                  authorized within these Terms of Use. Nothing in these Terms
                  of Use transfers ownership of such rights.
                </span>
              </li>
            </ol>

            <h4>3. OPERATION OF THE SERVICE</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">
                  Integrity of the Service.{" "}
                </span>
                <span className="listItemContent">
                  You agree not to take any action to harm the Company or the
                  Service or interfere with its operation, or access or copy its
                  underlying technology. You agree not to: (i) use any automated
                  system (crawlers, robots, bots, spiders, extractors, etc.) to
                  harm the Service; (ii) circumvent, disable or otherwise
                  interfere with security-related features or digital rights
                  management functions of the Service; or (iii) hack, reverse
                  engineer, or disable any technology at or functionality of the
                  Service.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Network Integrity. </span>
                <span className="listItemContent">
                  Our systems are designed to meet the anticipated needs of our
                  customers, users, and our needs. If we or the Authorized
                  Entity determine that your use of the Service impairs the
                  stability of the Service, we or the Authorized Entity
                  administrator may suspend or terminate your use of the
                  Service.
                </span>
              </li>
            </ol>

            <h4>4. TERMINATION</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">Termination. </span>
                <span className="listItemContent">
                  These Terms of Use, and your rights and the license granted
                  herein, will automatically terminate without notice upon
                  termination of the Master Agreement.
                </span>
              </li>
            </ol>

            <h4>5. NO WARRANTY; LIMITATION OF LIABILITY</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">NO WARRANTY.</span>

                <ol className="listItemContent subSection" type="i">
                  <li>
                    THE SERVICE IS PROVIDED FOR “AS IS,” WITH NO WARRANTIES
                    WHATSOEVER, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                    LIMITATION, ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, OR NON-INFRINGEMENT.
                  </li>

                  <li>
                    You alone are responsible for the decisions, consequences
                    and other actions resulting from your use of the Service,
                    including the decisions made or conclusions resulting from
                    or generated by the Service.
                  </li>
                </ol>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  Limitation of Liability for Damages.
                </span>
                <ol className="listItemContent subSection" type="i">
                  <li>
                    The Service is not intended to be a replacement for robust
                    internal security protocols and is not intended to be a
                    complete defense against or notification system for threats,
                    whether internal or from a third party. The Service is meant
                    to supplement Customer's internal systems, processes and
                    procedures and you agree TO THE MAXIMUM EXTENT PERMITTED BY
                    LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE TO YOU FOR ANY
                    DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR
                    CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM THESE TERMS
                    OF USE, PERFORMANCE OF THE SERVICE OR YOUR USE OF THE
                    SERVICE, REGARDLESS OF THE THEORY OF LIABILITY, INCLUDING
                    EXPRESS CONTRACT, IMPLIED CONTRACT, NEGLIGENCE, WARRANTY, OR
                    MISREPRESENTATION, AND WHETHER OR NOT THE YOU ARE ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGES.
                  </li>

                  <li>
                    As used in this section, “Company” includes the Company, its
                    officers, directors, employees, contractors, agents,
                    members, parent, subsidiaries, related business entities,
                    successors, assigns, and clients.
                  </li>

                  <li>
                    BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
                    LIMITATION OF CERTAIN TYPES OF DAMAGES, THE LIABILITY OF THE
                    COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES,
                    AGENTS, LICENSORS, AND CLIENTS IN SUCH JURISDICTIONS SHALL
                    BE LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
                  </li>

                  <li>
                    IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL
                    CODE §1542, WHICH SAYS: “A GENERAL RELEASE DOES NOT EXTEND
                    TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
                    EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                    WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS
                    SETTLEMENT WITH THE DEBTOR.”
                  </li>
                </ol>
              </li>
            </ol>

            <h4>6. INDEMNIFICATION</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">
                  Indemnification; Cooperation.
                </span>
                <ol className="listItemContent subSection" type="i">
                  <li>
                    You acknowledge that your account with the Company and your
                    use of the Service is based upon the truth of the
                    statements, representations, warranties and covenants made
                    by you in these Terms of Use. By agreeing to be bound these
                    Terms of Use, you indemnify, hold harmless, and agree to
                    defend the Company against all damages, losses, judgments,
                    penalties, expenses, costs, and fees (including reasonable
                    attorneys' fees) incurred by, or awarded or assessed against
                    the Company in connection with any third party (including
                    domestic or foreign governments or agencies) assertion
                    inconsistent with the statements, representations,
                    warranties or covenants. This obligation extends to the
                    Company, its officers, directors, employees, contractors,
                    agents, members, parent, subsidiaries, related business
                    entities, successors and assigns. These obligations survive
                    termination of these Terms of Use.
                  </li>

                  <li>
                    In addition to the obligation of indemnification, above, you
                    agree to use your best efforts to assist the Company in the
                    investigation and resolution of any third party claim or
                    assertion inconsistent with your representations and
                    warranties. You agree to provide such assistance promptly
                    upon receipt of notice from the Company of such claim or
                    assertion and at no charge.
                  </li>

                  <li>
                    The terms of Sections 2e and 5 to 6 shall survive
                    termination of these Terms of Use.
                  </li>
                </ol>
              </li>
            </ol>

            <h4>7. MISCELLANEOUS</h4>

            <ol className="mainSection" type="a">
              <li className="listItem">
                <span className="listItemTitle">
                  The Service is Delivered from Ann Arbor, Michigan.{" "}
                </span>
                <span className="listItemContent">
                  These Terms of Use are executed (signed) and performed in Ann
                  Arbor, Michigan. You agree that the Service is based in and
                  delivered from Ann Arbor, Michigan, USA. The Service shall be
                  deemed a passive service that does not give rise to personal
                  jurisdiction over the Company, either specific or general, in
                  jurisdictions other than Ann Arbor, Michigan. These Terms of
                  Use shall be governed by the internal substantive laws of the
                  State of Michigan, without respect to its conflict of laws
                  principles. Any claim or dispute between you and the Company
                  that arises in whole or in part from use of the Service shall
                  be decided exclusively by a court of competent jurisdiction
                  located in Washtenaw County, Michigan.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Force Majeure. </span>
                <span className="listItemContent">
                  Except for the obligation to pay monies due and owing, neither
                  party shall be liable for any delay or failure in performance
                  due to events outside the party's reasonable control,
                  including third party service failures, distributed denial of
                  service (DDoS) attacks, acts of God, bandwidth interruptions,
                  general network outages, earthquake, labor disputes,
                  pandemics, shortages of supplies, riots, war, fire, epidemics,
                  or delays of common carriers. The obligations and rights of
                  the excused party shall be extended on a day to day basis for
                  the time period equal to the period of the excusable delay.
                  The party affected by an excusable delay shall notify the
                  other party as soon as possible, but in no event less than ten
                  (10) days from the beginning of the event.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">No Implied Waiver. </span>
                <span className="listItemContent">
                  No waiver by the Company shall be implied. Any waiver of any
                  term of these Terms of Use must be in writing and signed by an
                  officer of the Company.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Severability. </span>
                <span className="listItemContent">
                  If any portion of these Terms of Use is found to be
                  unenforceable, such portion will be modified to reflect the
                  parties' intention and only to the extent necessary to make it
                  enforceable. The remaining provisions of these Terms of Use
                  will remain in full force and effect.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  Time for Bringing Claims.{" "}
                </span>
                <span className="listItemContent">
                  Any litigation or other dispute resolution arising out of or
                  related to these Terms of Use must be commenced within one (1)
                  year after the date of the event giving rise to the claim.
                  Otherwise, such causes of actions are permanently barred.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  International Considerations.{" "}
                </span>
                <span className="listItemContent">
                  The Company makes no representations that the Service, or any
                  content available within the Service, is appropriate or
                  available for use in locations other than the United States.
                  Those who access or use the Service from outside the U.S. do
                  so at their own volition and are responsible for compliance
                  with applicable laws.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Notices. </span>
                <span className="listItemContent">
                  The Service is offered by Blumira, Inc., with offices at 206 E
                  Huron St, Ste 106 Ann Arbor, MI 48104 and email:
                  info@Blumira.com. The Company may provide you with notices,
                  including those regarding changes to these Terms of Use, by
                  email, first class mail, or postings on our website. Notice is
                  deemed given upon the earlier of (i) actual receipt, (ii)
                  twenty-four (24) hours after an email is sent, (iii) three (3)
                  days after first class mail is deposited with the U.S. Postal
                  Service, or (iv) thirty (30) days after a notice is posted to
                  our website.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Entire Agreement. </span>
                <span className="listItemContent">
                  The Agreement is the entire agreement between you and the
                  Company relating to the stated subject matter. It can be
                  modified by the parties in a writing signed by both parties,
                  or by a change to these terms as expressly set forth in these
                  Terms of Use.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">Nature of Agreement. </span>
                <span className="listItemContent">
                  No agency, partnership, joint venture, employee-employer or
                  franchiser-franchisee relationship is intended or created by
                  these Terms of Use. These Terms of Use do not create any right
                  that may be enforced by a third party.
                </span>
              </li>

              <li className="listItem">
                <span className="listItemTitle">
                  California Users and Residents.{" "}
                </span>
                <span className="listItemContent">
                  By the terms of California Civil Code Section 1789.3, any and
                  all comments, questions or concerns can be addressed and sent
                  to us via certified mail to: Compliance Officer, Blumira Inc.
                  206 E Huron St, Ste 106 Ann Arbor, MI 48104. California users
                  are also entitled to the following specific consumer rights
                  notice: The Complaint Assistance Unit of the Division of
                  Consumer Services of the California Department of Consumer
                  Affairs may be contacted in writing at 1625 North Market
                  Blvd., Sacramento, CA.
                </span>
              </li>
            </ol>
          </DialogContent>
        </DialogContent>
        {initial && ( // user is viewing during signup
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={props.cancel}
              className={classes.disagreeButton}
            >
              Disagree
            </Button>
            <Button
              variant={"contained"}
              onClick={props.accept}
              className={classes.agreeButton}
            >
              Agree
            </Button>
          </DialogActions>
        )}
      </StyledDialog>
    </div>
  );
};

export default TosDialog;
