import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "reactstrap";

import { ErrorBanner } from "../../Components/ErrorBanner";
import ActiveJobMetrics from "./ActiveJobMetrics";
import NewPipelineModal from "./NewPipelineModal";
import OrgInfo from "./OrgInfo";
import PipelineMetrics from "./PipelineMetrics";
import PipelineInfo, { getShowPipeline } from "./PipelineInfo";
import PipelineModal from "./PipelineModal";
import StorageData from "./StorageData";

import { orgErrorMessageSelector } from "../../../selectors/orgSelectors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import { pageWithQuery } from "../../../redux/actions/Page";
import { primaryPipelineSelector } from "../../../selectors/pipelineSelectors";
import { receiveOrgError } from "../../../redux/actions/Org";
import { receiveRequestError } from "../../../redux/actions/Pipeline";
import DrainJobModal from "./DrainJobModal";
import UpdateSchemaModal from "./UpdateSchemaModal";

import "./Pipeline.scss";

const Pipeline = ({
  clearOrgError,
  openDrainJobModal,
  openPipelineModal,
  openNewPipelineModal,
  openUpdateSchemaModal,
  requestErrorMessage,
  orgId,
  showPipeline,
}) => {
  const onDrainPipeline = (evt) => {
    evt.stopPropagation();
    openDrainJobModal(orgId);
  };

  const onRestartPipeline = (evt) => {
    evt.stopPropagation();
    openPipelineModal(orgId);
  };

  const onStartPipeline = (evt) => {
    evt.stopPropagation();
    openNewPipelineModal(orgId);
  };

  return (
    <React.Fragment>
      <UpdateSchemaModal />
      <DrainJobModal />
      <PipelineModal />
      <NewPipelineModal />

      <div className="sensor-view" style={{ marginTop: "20px" }}>
        {requestErrorMessage && (
          <ErrorBanner
            message={requestErrorMessage}
            dismiss={() => {
              clearOrgError(orgId);
            }}
          />
        )}

        <div className="sensor-section">
          <div className="sensor-section-header">
            <div className="overview-title">Org Configuration</div>

            <div>
              <Button color="primary" onClick={openUpdateSchemaModal}>
                Update Schema
              </Button>
            </div>
          </div>

          <div className="sensor-section-body">
            <OrgInfo />
          </div>
        </div>

        <div className="sensor-section">
          <div className="sensor-section-header">
            <div className="overview-title">
              Pipeline Metrics
              <span className="overview-subtitle">
                &nbsp; over the last 15 minutes of available data
              </span>
            </div>
          </div>

          <div className="sensor-section-body">
            <PipelineMetrics />
          </div>

          <div className="sensor-section-body">
            <StorageData />
          </div>
        </div>

        <div className="sensor-section">
          <div className="sensor-section-header">
            <div className="overview-title">
              Active Job Metrics
              <span className="overview-subtitle">
                &nbsp; over the last 15 minutes of available data
              </span>
            </div>
          </div>

          <div className="sensor-section-body">
            <ActiveJobMetrics />
          </div>
        </div>

        <div className="sensor-section">
          <div className="sensor-section-header">
            <div className="overview-title">Active Job Information</div>

            {showPipeline && (
              <div className="sensor-button-group">
                <Button color="danger" onClick={onDrainPipeline}>
                  Drain Job
                </Button>

                <Button color="primary" onClick={onRestartPipeline}>
                  Restart Pipeline
                </Button>
              </div>
            )}

            {!showPipeline && (
              <Button color="primary" onClick={onStartPipeline}>
                Start Pipeline
              </Button>
            )}
          </div>

          <div className="sensor-section-body">
            <PipelineInfo />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Pipeline.propTypes = {
  clearOrgError: PropTypes.func.isRequired,
  openDrainJobModal: PropTypes.func.isRequired,
  openNewPipelineModal: PropTypes.func.isRequired,
  openPipelineModal: PropTypes.func.isRequired,
  openUpdateSchemaModal: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  requestErrorMessage: PropTypes.string.isRequired,
  showPipeline: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const orgId = orgIdSelector(state);

  const data = primaryPipelineSelector(state);
  const showPipeline = getShowPipeline(data);

  const requestErrorMessage = orgErrorMessageSelector(state);

  return {
    requestErrorMessage,
    orgId,
    showPipeline,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearOrgError: (orgId) => {
    dispatch(receiveOrgError({ orgId, error: null }));
  },
  openDrainJobModal: (orgId) => {
    const query = {
      drain: "true",
    };
    dispatch(pageWithQuery(query));
    dispatch(receiveRequestError({ orgId, error: null }));
  },
  openNewPipelineModal: (orgId) => {
    const query = {
      start: "true",
    };
    dispatch(pageWithQuery(query));
    dispatch(receiveRequestError({ orgId, error: null }));
  },
  openPipelineModal: (orgId) => {
    const query = {
      restart: "true",
    };
    dispatch(pageWithQuery(query));
    dispatch(receiveRequestError({ orgId, error: null }));
  },
  openUpdateSchemaModal: (orgId) => {
    const query = {
      update: "true",
    };
    dispatch(pageWithQuery(query));
    dispatch(receiveRequestError({ orgId, error: null }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Pipeline);
