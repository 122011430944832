import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import TimeRange from "../../../Components/TimeRange";
import DataGrid from "../../../Components/DataGrid";
import TableSearch from "./TableSearch";

import "./ResourcesTable.scss";

// react-table case-insensitive column filter for String-valued columns
// Filters by 'key' if 'key' is truthy, and by the accessor if 'key' is falsy
export const caseInsensitiveFilter = (key) => (filter, row) => {
  const { id: accessor, value: filterValue = "" } = filter;

  const rowValue = key
    ? (row._original[key] || "").toLowerCase()
    : (row[accessor] || "").toLowerCase();

  return rowValue.includes(filterValue.toLowerCase());
};

const matchOne = ({ obj, filter = "" }) => {
  const accessorStrings = (obj.accessorValueStrings || []).map((elt) =>
    elt.toLowerCase()
  );

  const flag = accessorStrings.some((val) =>
    val.includes(filter.toLowerCase())
  );

  return flag;
};

// `data` array filtering
const filterData = ({ data, filter }) => {
  const filtered = data.filter((elt) => matchOne({ obj: elt, filter }));

  return filtered;
};

const ResourcesTable = (props) => {
  const { columns, data, showSearch, windowEnd, windowStart } = props;

  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setFilteredData(filterData({ data, filter }));
  }, [data, filter]);

  return (
    <div className="resources-table">
      <div className="resources-table-title">
        <div className="title-left">
          <div className="title-main">Devices</div>

          <TimeRange dateEnd={windowEnd} dateStart={windowStart} prefix="" />
        </div>

        {showSearch && (
          <div className="title-right">
            <TableSearch
              submit={(inputValue) => {
                setFilter(inputValue);
              }}
            />
          </div>
        )}
      </div>

      <div className="resources-table-table">
        <DataGrid
          defaultPageSize={10}
          data={filteredData}
          columns={columns}
          showPagination
        />
      </div>
    </div>
  );
};

ResourcesTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  showSearch: PropTypes.bool,
  windowEnd: PropTypes.string,
  windowStart: PropTypes.string,
};

ResourcesTable.defaultProps = {
  columns: [],
  data: [],
  showSearch: true,
  windowEnd: "",
  windowStart: "",
};

export default ResourcesTable;
