import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { deleteTagAndFetch } from "../../../redux/actions/Tagging";
import TagBtn from "./TagBtn";
import { sortByName } from "../../../lib/helpers";

import "./ObjTags.scss";

/*
This component displays the object's tags. Can be used with or without
the Labels component.

For a component that displays the object's tags together with
the labels select dropdown, see LabelsAndTags.

Gets the data from Redux 'tagging' but does not fetch it on mount.
Use it with TagsFetch, or use LabelsAndTagsWithFetch instead.
*/
const ObjTags = (props) => {
  const handleDelete = (tagId) => {
    const { objId, objType } = props;

    props.deleteTagAndFetch({ tagId, objType, objId });
  };

  const { objType, objId, superadmin = false, tags: allTags = {} } = props;

  const tags = allTags[objId] || [];
  const sortedTags = [...tags].sort(sortByName);

  return (
    <div className="tags-container">
      {sortedTags.map((elt) => (
        <TagBtn
          key={elt.id}
          name={elt.name}
          onDelete={handleDelete}
          objType={objType}
          removable={superadmin || !elt.removalRestricted}
          tagId={elt.id}
        />
      ))}
    </div>
  );
};

ObjTags.propTypes = {
  deleteTagAndFetch: PropTypes.func.isRequired,
  objId: PropTypes.string.isRequired,
  objType: PropTypes.string.isRequired,
  superadmin: PropTypes.bool,
  tags: PropTypes.shape({}),
};

ObjTags.defaultProps = {
  tags: {},
  superadmin: false,
};

// Redux
const mapStateToProps = (state) => {
  const { tags = {} } = state.tagging;

  return {
    tags,
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteTagAndFetch: ({ tagId, objType, objId }) =>
    dispatch(deleteTagAndFetch({ tagId, objType, objId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjTags);
