// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { useState, useEffect } from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller,
} from "react-virtualized";
import { arrayOf, bool, shape, string } from "prop-types";
import { Alert } from "reactstrap";
import Moment from "moment-timezone";

// ** Components **********************
import LoadingSpinner from "views/Components/LoadingSpinner";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { SelectEmptyState } from "views/Components/EmptyState";

// ** Style ***************************
import { AlertContainer, EmptyState } from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Results table
//
//  ** Remarks
//  Results display in expandable row format
//
//  ** Props
//  dataLoaded {bool} - indicates if data has loaded
//  maxResultsExceeded {bool} - indicates if max limit for reults has been hit
//  activeCols {array} - list of active cols
//  tableData {array} - data returned from the search
//
//  ** State
//  activeCols {array} - list of active cols
//  blobCols {array} - list of remaining columns returned from search
//  sorting {obj} - selected sorting key and direction
//

const SearchResultsTable = (props) => {
  const [blobCols, setBlobCols] = useState([]);
  const [sorting, setSorting] = useState({ dir: false, key: null });
  const [tableData, setTableData] = useState(props.tableData);
  const [sortedData, setSortedData] = useState(props.tableData);
  const [specialColumns, setSpecialColumns] = useState([]);

  const list = React.createRef();
  const cache = new CellMeasurerCache({ defaultHeight: 64, fixedWidth: true });

  const noRowsRenderer = () => {
    return <SelectEmptyState />;
  };

  const sortCol = (key) => {
    const dir = key === sorting.key ? !sorting.dir : false;
    setSorting({ dir, key });
    setSortedData(sorting.key ? sortData() : tableData);
  };

  const sortData = () => {
    if (sorting.key === "timestamp") {
      if (sorting.dir) {
        return tableData.sort(
          (a, b) => Moment(b.timestamp).unix() - Moment(a.timestamp).unix()
        );
      }

      return tableData.sort(
        (a, b) => Moment(a.timestamp).unix() - Moment(b.timestamp).unix()
      );
    }

    return tableData.sort((a, b) =>
      b[sorting.key] > a[sorting.key] === sorting.dir ? 1 : -1
    );
  };

  const checkDistinctType = () => {
    const rowData = props.tableData[0] || [];
    const distinctCol = Object.keys(rowData).find(
      (col) => col === "grouped_count" || col === "count"
    );
    if (props.distinctCounts && distinctCol) {
      if (!specialColumns.find((col) => col.name === distinctCol)) {
        setSpecialColumns((prevState) => {
          return prevState.concat({
            name: distinctCol,
            display_name: "Distinct Counts",
          });
        });
      }
    } else {
      setSpecialColumns((prevState) => {
        return prevState.filter(
          (col) => col.name !== "grouped_count" && col.name !== "count"
        );
      });
    }
  };

  useEffect(() => {
    setTableData(props.tableData);
    checkDistinctType();
    const rowData = props.tableData[0];
    setBlobCols(
      rowData &&
        Object.keys(rowData).filter(
          (col) => col !== "grouped_count" && col !== "count"
        )
    );
    if (list.current) {
      cache.clearAll();
      list.current.recomputeRowHeights();
    }
  }, [props.activeCols, props.tableData, props.distinctCounts]);

  useEffect(() => {
    setSortedData(sorting.key ? sortData() : tableData);
  }, [tableData]);

  const recomputeRowHeight = (index) => {
    cache.clear(index, 0);
    list.current.recomputeRowHeights(index);
  };

  const rowRenderer = (args) => {
    const { index, key, parent, style } = args;
    const { columns, selectedColumns, updateActiveCols, activeCols } = props;
    const datum = sortedData[index];

    return (
      datum && (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <div style={style}>
            <TableRow
              blobCols={blobCols}
              border={index !== sortedData.length - 1}
              columns={columns}
              activeCols={activeCols}
              index={index}
              recomputeHeight={recomputeRowHeight}
              rowData={datum}
              specialColumns={specialColumns}
              selectedColumns={selectedColumns}
              updateActiveCols={updateActiveCols}
            />
          </div>
        </CellMeasurer>
      )
    );
  };

  const {
    columns,
    dataLoaded,
    maxResultsExceeded,
    selectedColumns,
    activeCols,
  } = props;

  return dataLoaded ? (
    <div className="query-container">
      <AlertContainer>
        <Alert color="warning" isOpen={maxResultsExceeded}>
          Caution
          <p>
            Query exceeds 5000 results. Only the first 5000 rows will appear in
            the following table.
          </p>
          <p>
            If you would like to obtain all the results, click the Download
            button above to fetch and export the data to a file, or try
            narrowing down your search.
          </p>
        </Alert>
      </AlertContainer>
      <div className="results">
        {sortedData.length > 0 ? (
          <>
            <TableHeader
              blobCols={blobCols}
              columns={columns}
              activeCols={activeCols}
              onSort={sortCol}
              selectedColumns={selectedColumns}
              sorting={sorting}
              specialColumns={specialColumns}
            />
            <WindowScroller>
              {({ height, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      deferredMeasurementCache={cache}
                      autoHeight
                      height={height}
                      overscanRowCount={10}
                      noRowsRenderer={noRowsRenderer}
                      ref={list}
                      rowCount={sortedData.length}
                      rowHeight={cache.rowHeight}
                      rowRenderer={rowRenderer}
                      scrollTop={scrollTop}
                      width={width}
                    />
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          </>
        ) : (
          <EmptyState>Search returned no results</EmptyState>
        )}
      </div>
    </div>
  ) : (
    <LoadingSpinner height="240px" />
  );
};

//  ** Prop types *********************
SearchResultsTable.propTypes = {
  dataLoaded: bool,
  activeCols: arrayOf(string),
  maxResultsExceeded: bool,
  selectedColumns: arrayOf(shape()),
  tableData: arrayOf(shape()),
};

//  ** Default props ******************
SearchResultsTable.defaultProps = {
  dataLoaded: false,
  activeCols: [],
  maxResultsExceeded: false,
  selectedColumns: [],
  tableData: [],
};

export default SearchResultsTable;
