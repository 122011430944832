import { styled } from "@mui/material/styles";

const PREFIX = "WelcomePageView";
export const classes = {
  pageContainer: `${PREFIX}-pageContainer`,
  pageTitleContainer: `${PREFIX}-pageTitleContainer`,
  pageTitle: `${PREFIX}-pageTitle`,
  stepsContainer: `${PREFIX}-stepsContainer`,
  image: `${PREFIX}-image`,
  connectButton: `${PREFIX}-connectButton`,
  onboardingStep: `${PREFIX}-onboardingStep`,
  connectStepContainer: `${PREFIX}-connectStepContainer`,
  stepNumberContainer: `${PREFIX}-stepNumberContainer`,
  completed: `${PREFIX}-completed`,
  loading: `${PREFIX}-loading`,
  stepNumber: `${PREFIX}-stepNumber`,
  titleContentContainer: `${PREFIX}-titleContentContainer`,
  stepTitle: `${PREFIX}-stepTitle`,
  subsequentStepText: `${PREFIX}-subsequentStepText`,
  span: `${PREFIX}-span`,
  microsoftBlumiraImgContainer: `${PREFIX}-microsoftBlumiraImgContainer`,
  microsoftBlumiraImg: `${PREFIX}-microsoftBlumiraImg`,
  learnMoreVideoContainer: `${PREFIX}-learnMoreVideoContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  learnMoreSectionContainer: `${PREFIX}-learnMoreSectionContainer`,
  learnMoreLinkContainer: `${PREFIX}-learnMoreLinkContainer`,
  linkContainer: `${PREFIX}-linkContainer`,
  thirdStepLinkContainer: `${PREFIX}-thirdStepLinkContainer`,
  linkButton: `${PREFIX}-linkButton`,
  link: `${PREFIX}-link`,
  videoSectionContainer: `${PREFIX}-videoSectionContainer`,
  disclaimerText: `${PREFIX}-disclaimerText`,
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  headerCtaContainer: `${PREFIX}-headerCtaContainer`,
  ruleContainer: `${PREFIX}-ruleContainer`,
  dialogFooter: `${PREFIX}-dialogFooter`,
  close: `${PREFIX}-close`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  textFieldContainer: `${PREFIX}-textFieldContainer`,
  textField: `${PREFIX}-textField`,
  successContainer: `${PREFIX}-successContainer`,
  checkCircle: `${PREFIX}-checkCircle`,
  connectionDialogContainer: `${PREFIX}-connectionDialogContainer`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
  absoluteDotProgressContainer: `${PREFIX}-absoluteDotProgressContainer`,
  flexDotProgressContainer: `${PREFIX}-flexDotProgressContainer`,
  alert: `${PREFIX}-alert`,
  dialogSectionContainer: `${PREFIX}-dialogSectionContainer`,
  error: `${PREFIX}-error`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.pageContainer}`]: {
    padding: "0px 30px",
  },

  [`& .${classes.pageTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `${theme.shape.padding}px 0`,
  },

  [`& .${classes.pageTitle}`]: {
    fontSize: 40,
    marginBottom: theme.shape.padding,
    fontWeight: "bold",
  },

  [`& .${classes.stepsContainer}`]: {
    width: "100%",
    marginBottom: theme.shape.padding,
  },

  [`& .${classes.image}`]: {
    width: 120,
  },

  [`& .${classes.connectButton}`]: {
    width: 170,
    padding: theme.shape.padding,
    marginBottom: 10,
  },

  [`&.${classes.onboardingStep}`]: {
    width: "33%",
    height: 320,
    padding: "15px 30px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.connectStepContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  [`& .${classes.stepNumberContainer}`]: {
    position: "relative",
    paddingBottom: theme.shape.padding,
  },

  [`& .${classes.completed}`]: {
    top: 10,
    right: -5,
    zIndex: 999,
    fontSize: 25,
    position: "absolute",
    color: theme.palette.success.main,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.loading}`]: {
    top: 10,
    right: -5,
    zIndex: 999,
    borderRadius: 15,
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.stepNumber}`]: {
    width: 70,
    height: 70,
    padding: 0,
    display: "flex",
    borderRadius: 35,
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "center",
    border: `3px solid ${theme.palette.divider}`,
    "& p": {
      padding: 10,
      fontSize: 35,
      marginBottom: 0,
    },
  },

  [`& .${classes.titleContentContainer}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },

  [`& .${classes.stepTitle}`]: {
    fontWeight: "bold",
  },

  [`& .${classes.subsequentStepText}`]: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    padding: `${theme.shape.padding}px ${theme.shape.padding}px 0 ${theme.shape.padding}px`,
  },

  [`& .${classes.span}`]: {
    fontSize: 15,
    fontStyle: "italic",
    marginTop: theme.shape.padding,
    color: theme.palette.text.secondary,
  },

  [`& .${classes.microsoftBlumiraImgContainer}`]: {
    padding: "15px 0",
    wiidth: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  [`& .${classes.microsoftBlumiraImg}`]: {
    width: "80%",
  },

  [`& .${classes.learnMoreVideoContainer}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.sectionTitle}`]: {
    fontSize: 18,
    fontWeight: "bold",
  },

  [`& .${classes.learnMoreSectionContainer}`]: {
    padding: 30,
    flexDirection: "column",
  },

  [`& .${classes.learnMoreLinkContainer}`]: {
    display: "flex",
    flexDirection: "column",
    "& a": {
      paddingBottom: theme.shape.padding,
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.linkContainer}`]: {
    display: "flex",
    "& p": {
      marginLeft: theme.shape.padding * 2,
    },
  },

  [`& .${classes.thirdStepLinkContainer}`]: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
  },

  [`& .${classes.linkButton}`]: {
    padding: 10,
    cursor: "pointer",
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
  },

  [`& .${classes.link}`]: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
    },
    display: "flex",
    "& svg": {
      paddingLeft: 5,
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },

  [`& .${classes.videoSectionContainer}`]: {
    padding: 30,
    marginLeft: theme.shape.padding,
  },

  [`& .${classes.disclaimerText}`]: {
    fontSize: 10,
    paddingTop: theme.shape.padding,
  },

  [`& .${classes.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.dialogContent}`]: {
    padding: 30,
  },

  [`& .${classes.headerCtaContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "& p": {
      marginBottom: 0,
      paddingRight: theme.shape.padding,
    },
    "& a": {
      paddingRight: theme.shape.padding,
    },
  },

  [`& .${classes.ruleContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dialogFooter}`]: {
    padding: 30,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  [`& .${classes.close}`]: {
    cursor: "pointer",
  },

  [`& .${classes.list}`]: {
    paddingLeft: 20,
    marginBottom: 0,
  },

  [`& .${classes.listItem}`]: {
    paddingBottom: theme.shape.padding,
  },

  [`& .${classes.textFieldContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.textField}`]: {
    width: "100%",
    marginBottom: theme.shape.padding,
  },

  [`& .${classes.successContainer}`]: {
    width: 500,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& p": {
      marginBottom: theme.shape.padding,
    },
  },

  [`& .${classes.checkCircle}`]: {
    fontSize: 50,
    marginBottom: theme.shape.padding,
    color: theme.palette.success.main,
  },

  [`& .${classes.connectionDialogContainer}`]: {
    minHeight: 500,
  },

  [`& .${classes.buttonContainer}`]: {
    zIndex: 999,
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: theme.shape.padding * 2,
  },

  [`& .${classes.button}`]: {
    marginLeft: theme.shape.padding,
  },

  [`& .${classes.absoluteDotProgressContainer}`]: {
    bottom: 30,
    width: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.flexDotProgressContainer}`]: {
    width: "100%",
    marginTop: -85, // align with buttons
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.shape.padding * 2,
  },

  [`& .${classes.alert}`]: {
    marginTop: -theme.shape.padding,
    marginBottom: theme.shape.padding,
  },

  [`& .${classes.dialogSectionContainer}`]: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.shape.padding,
  },

  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },
}));
