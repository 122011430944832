import React from "react";
import PropTypes from "prop-types";
import Loading from "../../Components/Loading";
import EmptyVisualization from "../../Components/EmptyVisualization";
import DataGrid from "../../Components/DataGrid";

const TableWrapper = ({ data, defaultPageSize }) => (
  <DataGrid
    data={data || []}
    columns={[
      {
        Header: "Source",
        accessor: "source",
        minWidth: 140,
      },
      {
        Header: "Threats",
        columns: [
          {
            Header: "Total",
            accessor: "threats.count",
            minWidth: 50,
            defaultSortDesc: true,
          },
          {
            Header: "P1",
            accessor: "threats.p1",
            minWidth: 50,
          },
          {
            Header: "P2",
            accessor: "threats.p2",
            minWidth: 50,
          },
          {
            Header: "P3",
            accessor: "threats.p3",
            minWidth: 50,
          },
        ],
      },
      {
        Header: "Suspects",
        columns: [
          {
            Header: "Total",
            accessor: "suspects.count",
            minWidth: 50,
          },
          {
            Header: "P1",
            accessor: "suspects.p1",
            minWidth: 50,
          },
          {
            Header: "P2",
            accessor: "suspects.p2",
            minWidth: 50,
          },
          {
            Header: "P3",
            accessor: "suspects.p3",
            minWidth: 50,
          },
        ],
      },
    ]}
    showPagination={false}
    showPageSizeOptions={false}
    showPageJump={false}
    resizeable={false}
    defaultPageSize={defaultPageSize}
    compactStyle
    defaultSorted={[
      {
        id: "threats.count",
        desc: true,
      },
    ]}
  />
);

TableWrapper.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  defaultPageSize: PropTypes.number.isRequired,
};

const LocationsData = ({ tableData, defaultPageSize, handleRetry }) => {
  const tableIsLoading = tableData.loading || !tableData.firstLoadComplete;
  const tableIsEmpty = !tableData.loading && tableData.dataIsEmpty;
  const tableHasError = !!tableData.error;

  return (
    <React.Fragment>
      {tableIsLoading && <Loading />}

      {(tableIsEmpty || tableHasError) && (
        <div>
          <EmptyVisualization isError={tableHasError} handleRetry={handleRetry}>
            <TableWrapper
              data={tableData.visualizationData}
              defaultPageSize={defaultPageSize}
            />
          </EmptyVisualization>
        </div>
      )}

      {!tableIsLoading && !tableIsEmpty && !tableHasError && (
        <TableWrapper
          data={tableData.visualizationData}
          defaultPageSize={defaultPageSize}
        />
      )}
    </React.Fragment>
  );
};

LocationsData.propTypes = {
  tableData: PropTypes.shape({}).isRequired,
  defaultPageSize: PropTypes.number,
  handleRetry: PropTypes.func.isRequired,
};
LocationsData.defaultProps = {
  defaultPageSize: 10,
};

export default React.memo(LocationsData);
