const ID_OPERATORS = ["eq", "!eq", "in", "!in"];

const conditionOverrideSchema = {
  $id: "/conditionoverride",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", validOperators: ID_OPERATORS },
    conditionId: { type: "number", validOperators: ID_OPERATORS },
    orgId: { type: "string", format: "uuid", validOperators: ID_OPERATORS },
    defaultDeployStatus: { type: "number", validOperators: ID_OPERATORS },
  },
  required: ["conditionId", "orgId", "defaultDeployStatus"],
  additionalProperties: false,
};

export default conditionOverrideSchema;
