import { timeParamsFromInterval } from "./TimeInterval";
import { receiveData, receiveDates, receiveError } from "./GraphAndTable";

// Creates the table request query from the given params
export const getQueryTable = ({
  limit = 10,
  page,
  pageSize,
  selectedInterval,
}) => {
  const { intervalType } = selectedInterval;
  const timeParams = timeParamsFromInterval(selectedInterval);

  const query = {
    intervalType,
    limit,
    page,
    pageSize,
    ...timeParams,
  };

  return query;
};

// Dispatches actions to receive all table data - tableByOrg, tableDates, etc.
const receiveTableAll =
  (actionTypePrefix) =>
  ({ data, dates, insertId, selectedInterval }) =>
  (dispatch) => {
    dispatch(
      receiveDates(actionTypePrefix)({
        data: dates,
        insertId,
        selectedInterval,
      })
    );

    dispatch(
      receiveData(actionTypePrefix)({
        data,
        insertId,
        selectedInterval,
      })
    );
  };

export const getTableActions = (actionTypePrefix) => ({
  receiveTableAll: receiveTableAll(actionTypePrefix),
  receiveError: receiveError(actionTypePrefix),
});
