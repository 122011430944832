import { styled } from "@mui/material/styles";

const PREFIX = "SummaryDashboard";
export const classes = {
  page: `${PREFIX}-page`,
  row: `${PREFIX}-row`,
  topRowContainer: `${PREFIX}-topRowContainer`,
  sortedDataContainer: `${PREFIX}-sortedDataContainer`,
  sortedDataText: `${PREFIX}-sortedDataText`,
  baseContainer: `${PREFIX}-baseContainer`,
  findingNumberText: `${PREFIX}-findingNumberText`,
  dataTitle: `${PREFIX}-dataTitle`,
  dataText: `${PREFIX}-dataText`,
  rulesContainer: `${PREFIX}-rulesContainer`,
  connectorStatusContainer: `${PREFIX}-connectorStatusContainer`,
  status: `${PREFIX}-status`,
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  testRunContainer: `${PREFIX}-testRunContainer`,
  reportNumberContainer: `${PREFIX}-reportNumberContainer`,
  popReportPlaceholder: `${PREFIX}-popReportPlaceholder`,
  emptyStateContainer: `${PREFIX}-emptyStateContainer`,
  dialogHeaderContainer: `${PREFIX}-dialogHeaderContainer`,
  dialogContent: `${PREFIX}-dialogContent`,
  close: `${PREFIX}-close`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  list: `${PREFIX}-list`,
  listItem: `${PREFIX}-listItem`,
  dialogActions: `${PREFIX}-dialogActions`,
  red: `${PREFIX}-red`,
  connectorName: `${PREFIX}-connectorName`,
  statusText: `${PREFIX}-statusText`,
  restoreText: `${PREFIX}-restoreText`,
  dashIcon: `${PREFIX}-dashIcon`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.page}`]: {
    padding: `0 ${theme.shape.padding}px`,
  },
  [`& .${classes.topRowContainer}`]: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.sortedDataContainer}`]: {
    flexDirection: "column",
  },
  [`& .${classes.sortedDataText}`]: {
    margin: 0,
    color: theme.palette.grey[400],
  },
  [`& .${classes.baseContainer}`]: {
    minHeight: 120,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.findingNumberText}`]: {
    fontSize: 22,
    marginBottom: 0,
    fontWeight: "bold",
  },
  [`& .${classes.dataTitle}`]: {
    fontSize: 12,
    fontWeight: 700,
    marginBottom: 0,
    textTransform: "uppercase",
    color: theme.palette.text.primary,
  },
  [`& .${classes.dataText}`]: {
    fontSize: 35,
    fontWeight: 400,
    marginBottom: 0,
  },
  [`& .${classes.rulesContainer}`]: {
    width: "10%",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    padding: theme.shape.padding,
    justifyContent: "space-between",
    marginRight: theme.shape.padding,
  },
  [`& .${classes.connectorStatusContainer}`]: {
    width: "30%",
    cursor: "pointer",
    padding: theme.shape.padding,
    marginRight: theme.shape.padding,
  },
  [`& .${classes.status}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 5,
  },
  [`& .${classes.testRunContainer}`]: {
    width: "20%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& p": {
      marginBottom: 0,
    },
  },
  [`& .${classes.reportNumberContainer}`]: {
    height: 100,
    width: "10%",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    padding: theme.shape.padding,
    justifyContent: "space-between",
    marginRight: theme.shape.padding,
  },
  [`& .${classes.popReportPlaceholder}`]: {
    width: "90%",
    height: 100,
    padding: theme.shape.padding,
  },
  [`& .${classes.emptyStateContainer}`]: {
    height: 200,
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.dialogHeaderContainer}`]: {
    display: "flex",
    paddingRight: 30,
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.dialogContent}`]: {
    padding: theme.shape.padding * 2,
  },
  [`& .${classes.close}`]: {
    cursor: "pointer",
  },
  [`& .${classes.sectionTitle}`]: {
    marginBottom: 0,
  },
  [`& .${classes.list}`]: {
    paddingLeft: theme.shape.padding,
  },
  [`& .${classes.listItem}`]: {
    marginLeft: theme.shape.padding,
  },
  [`& .${classes.dialogActions}`]: {
    justifyContent: "flex-start",
    padding: theme.shape.padding * 2,
    paddingTop: 0,
  },
  [`& .${classes.red}`]: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
  },
  [`& .${classes.connectorName}`]: {
    fontSize: 14,
    fontWeight: 700,
    marginBottom: 0,
    marginTop: theme.shape.padding,
  },
  [`& .${classes.statusText}`]: {
    fontSize: 14,
    marginLeft: 5,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
  [`& .${classes.restoreText}`]: {
    fontSize: 14,
    marginBottom: 0,
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  [`& .${classes.dashIcon}`]: {
    fill: theme.palette.text.secondary,
  },
}));

export const IntegrationStatusDiv = styled("div")(({ theme }) => ({
  [`&.${classes.row}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.success}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.error}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.statusText}`]: {
    fontSize: 14,
    marginBottom: 0,
    color: theme.palette.text.secondary,
  },
}));
