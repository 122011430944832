export const resolutionOptions = [
  { value: 10, label: "Valid" },
  { value: 20, label: "False Positive" },
  { value: 30, label: "No Action Needed" },
  { value: 40, label: "Risk Accepted" },
];

export const getFindingTableQuickFilters = ({
  findings = [],
  isTableFilteredByType = false,
}) => {
  // we are only concerned with showing "Open" findings in the quick filters
  const openFindings = findings.filter(({ status }) => status === 0);
  const totalCount = openFindings.length;
  const totalOperationalCount = openFindings.filter(
    ({ type }) => type === 10
  ).length;
  const totalRiskCount = openFindings.filter(({ type }) => type === 15).length;
  const totalSuspectCount = openFindings.filter(
    ({ type }) => type === 20
  ).length;
  const totalThreatCount = openFindings.filter(
    ({ type }) => type === 30
  ).length;

  return [
    {
      label: isTableFilteredByType ? "All" : `All (${totalCount})`,
      queryParameter: null,
    },
    {
      label: isTableFilteredByType
        ? "Operational"
        : `Operational (${totalOperationalCount})`,
      queryParameter: { field: "type", value: 10, operator: "eq" },
    },
    {
      label: isTableFilteredByType ? "Risk" : `Risk (${totalRiskCount})`,
      queryParameter: { field: "type", value: 15, operator: "eq" },
    },
    {
      label: isTableFilteredByType
        ? "Suspect"
        : `Suspect (${totalSuspectCount})`,
      queryParameter: { field: "type", value: 20, operator: "eq" },
    },
    {
      label: isTableFilteredByType ? "Threat" : `Threat (${totalThreatCount})`,
      queryParameter: { field: "type", value: 30, operator: "eq" },
    },
  ];
};

export const getQueryParameterFromFilter = ({ filterLabel, findings }) => {
  let queryParameterToReturn = null;

  if (!!findings && !!filterLabel) {
    const tableFilterOptions = getFindingTableQuickFilters({ findings });

    const activeFilter = tableFilterOptions.find(
      // this looks this way since the labels are dynamic and display a count at certain
      // times, so we are only concerned with the first word i.e. 'Suspect' or 'Threat'
      ({ label }) => label.includes(filterLabel.split("")[0])
    );

    if (activeFilter) queryParameterToReturn = activeFilter.queryParameter;
  }

  return queryParameterToReturn;
};

// given the table quick filter options from above find if one of the quick filters
// is present as a query param in the UI's current queryControls and if so return
// that label so we can display it as active to the user
export const getSelectedFilterFromQueryControls = (queryControls = {}) => {
  // default to all
  let selectedFilterToReturn = "All";

  const tableFilterOptions = getFindingTableQuickFilters({ findings: [] });

  // find if one of the quick filters (outside of 'All') is selected
  const activeTableFilter = tableFilterOptions.find((filterOption) => {
    return !!Object.keys(queryControls).find((qcUUID) => {
      return (
        queryControls[qcUUID] &&
        filterOption.queryParameter &&
        queryControls[qcUUID].field === "type" &&
        queryControls[qcUUID].value === filterOption.queryParameter.value
      );
    });
  });

  if (activeTableFilter) selectedFilterToReturn = activeTableFilter.label;

  return selectedFilterToReturn;
};
