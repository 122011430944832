import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { updateSensor } from "../../../redux/actions/Sensors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  currentSensorSelector,
  sensorIdSelector,
} from "../../../selectors/sensorsSelectors";

import "./SensorAlerts.scss";

const SensorAlerts = ({
  alert,
  dispatchUpdateAlerts,
  orgId,
  resourcesAlert,
  sensorId,
}) => {
  const [checked, setChecked] = useState(false);
  const [resourcesChecked, setResourcesChecked] = useState(false);

  useEffect(() => {
    setChecked(!alert);
  }, [alert]);

  useEffect(() => {
    setResourcesChecked(!resourcesAlert);
  }, [resourcesAlert]);

  const toggleAlert = () => {
    const nextChecked = !checked;

    setChecked(nextChecked);

    dispatchUpdateAlerts({
      alert: !nextChecked,
      orgId,
      sensorId,
      resourcesAlert: !resourcesChecked,
    });
  };

  const toggleResourcesAlert = () => {
    const nextChecked = !resourcesChecked;

    setResourcesChecked(nextChecked);

    dispatchUpdateAlerts({
      alert: !checked,
      orgId,
      sensorId,
      resourcesAlert: !nextChecked,
    });
  };

  return (
    <React.Fragment>
      <div className="sensor-alerts-container">
        <div className="sensor-alerts">
          Alert Blumira when the sensor goes offline
        </div>

        <input
          type="checkbox"
          className="sensor-checkbox"
          checked={checked}
          onClick={toggleAlert}
        />
      </div>

      <div className="sensor-alerts-container">
        <div className="sensor-alerts">
          Alert Blumira when the sensor resources are low
        </div>

        <input
          type="checkbox"
          className="sensor-checkbox"
          checked={resourcesChecked}
          onClick={toggleResourcesAlert}
        />
      </div>
    </React.Fragment>
  );
};

SensorAlerts.propTypes = {
  alert: PropTypes.bool.isRequired,
  dispatchUpdateAlerts: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  resourcesAlert: PropTypes.bool.isRequired,
  sensorId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const orgId = orgIdSelector(state);
  const sensor = currentSensorSelector(state);
  const sensorId = sensorIdSelector(state);

  const { configAlerts = {} } = sensor;

  const {
    offlineAlertSupportDisabled: alert = false,
    resourcesLowAlertSupportDisabled: resourcesAlert = false,
  } = configAlerts;

  return {
    alert,
    orgId,
    resourcesAlert,
    sensorId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateAlerts: ({ alert, orgId, resourcesAlert, sensorId }) => {
    const data = {
      configAlerts: {
        offlineAlertSupportDisabled: alert,
        resourcesLowAlertSupportDisabled: resourcesAlert,
      },
    };

    dispatch(
      updateSensor({
        orgId,
        sensorId,
        data,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorAlerts);
