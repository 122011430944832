import { styled } from "@mui/material/styles";

const PREFIX = "SimpleSearchPageView";
export const classes = {
  loadingContainer: `${PREFIX}-loadingContainer`,
  noDataContainer: `${PREFIX}-noDataContainer`,
  textField: `${PREFIX}-textField`,
  investigateButton: `${PREFIX}-investigateButton`,
  directionalText: `${PREFIX}-directionalText`,
  checkIcon: `${PREFIX}-checkIcon`,
  listItem: `${PREFIX}-listItem`,
  resultsTitleText: `${PREFIX}-resultsTitleText`,
  chartSkeletonLoadingPulse: `${PREFIX}-chartSkeletonLoadingPulse`,
  timeSelectSkeletonLoadingPulse: `${PREFIX}-timeSelectSkeletonLoadingPulse`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.loadingContainer}`]: {
    width: "100%",
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    paddingTop: "0px !important",
  },
  [`& .${classes.noDataContainer}`]: {
    width: "100%",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: "0px !important",
    "& h4": {
      marginBottom: theme.shape.padding,
    },
  },
  [`& .${classes.resultsTitleText}`]: {
    paddingTop: "0px !important",
  },
  [`& .${classes.investigateButton}`]: {
    height: 45,
    width: 100,
    marginLeft: theme.shape.padding,
  },
  [`& .${classes.textField}`]: {
    height: 10,
    width: 250,
  },
  [`& .${classes.directionalText}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${classes.checkIcon}`]: {
    minWidth: 40,
  },
  [`& .${classes.listItem}`]: {
    paddingLeft: 0,
  },
  [`& .${classes.chartSkeletonLoadingPulse}`]: {
    width: "100%",
    height: "100%",
  },
  [`& .${classes.timeSelectSkeletonLoadingPulse}`]: {
    width: 260,
    height: 60,
    margin: "15px 0",
  },
}));
