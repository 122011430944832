export const searchFilterSchema = {
  $id: "/searchfilter",
  type: "object",
  properties: {
    operator: { type: "string", enum: ["and", "or", "not"] },
    opcode: { type: "string" }, // todo: enum of opcodes
    fieldName: { type: "string" },
    fieldValue: { type: ["number", "string", "array", "boolean"] },
    fieldType: { type: "string" },
    children: {
      type: "array",
      items: {
        $ref: "/searchfilter",
      },
    },
  },
  additionalProperties: false,
};

export const dataQuerySchema = {
  $id: "/dataquery",
  type: "object",
  properties: {
    age: { type: "number" },
    aggregate: {
      type: "array",
      items: {
        type: "string",
      },
    },
    aggregators: {
      type: "array",
      items: {
        type: "string",
      },
    },
    configuration: {
      type: "object",
      properties: {
        groupBy: {
          type: "array",
          items: {
            type: "string",
          },
        },
        orderBy: {
          type: "array",
          items: {
            type: "object",
            properties: {
              fieldName: { type: "string" },
              direction: { type: "string" },
            },
          },
        },
        relativeStart: { type: "string" },
        relativeEnd: { type: "string" },
        timeStart: { type: "string" },
        timeEnd: { type: "string" },
        limit: { type: "number" },
      },
    },
    created: { type: "string" },
    createdBy: { type: "string", format: "uuid" },
    count: { type: "boolean" },
    dataSource: { type: "string", enum: ["finding", "bq"] },
    deleted: { type: "string" },
    deletedBy: { type: "string" },
    description: { type: "string" },
    id: { type: "number" },
    isBasis: { type: "boolean" },
    lastOkDuration: { type: "number" },
    lastProcessEnd: { type: "string" },
    modified: { type: "string" },
    modifiedBy: { type: "string" },
    name: { type: "string" },
    isAggregate: { type: "boolean" },
    orgId: { type: "string", format: "uuid" },
    outputType: { type: "string", enum: ["csv", "jsonl"] },
    rawAggregate: {
      type: "array",
      items: {
        type: "string",
      },
    },
    rawSearch: { type: "string" },
    search: {
      type: "array",
      items: { $ref: "/searchfilter" },
    },
    hasAdvancedFilters: { type: "boolean" },
  },
  required: ["aggregators", "name"],
  additionalProperties: false,
};
