import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";
import SupportEntry from "../../Components/Finding/SupportEntry";

const LatestParsedTime = ({
  columnNumLeft,
  columnNumRight,
  latestParsedTime,
}) => (
  <div>
    <Container className="list-container light">
      <SupportEntry
        columnNumLeft={columnNumLeft}
        columnNumRight={columnNumRight}
        label="Latest Parsed Time"
        value={latestParsedTime}
      />
    </Container>
  </div>
);

LatestParsedTime.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  latestParsedTime: PropTypes.string,
};

LatestParsedTime.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  latestParsedTime: "",
};

export default LatestParsedTime;
