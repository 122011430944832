import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

const PREFIX = "DotProgress";
const classes = {
  container: `${PREFIX}-container`,
  dot: `${PREFIX}-dot`,
  complete: `${PREFIX}-complete`,
  incomplete: `${PREFIX}-incomplete`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.container}`]: {
    maxWidth: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.dot}`]: {
    width: 8,
    height: 8,
    margin: 2,
    borderRadius: 20,
  },
  [`& .${classes.complete}`]: {
    backgroundColor: theme.palette.grey[500],
  },
  [`& .${classes.incomplete}`]: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const DotProgress = (props) => {
  return (
    <Root className={classes.container}>
      {Array.from(Array(props.stepsLength).keys()).map((step, index) => (
        <div
          key={index}
          className={
            index < props.stepsCompleted
              ? `${classes.dot} ${classes.complete}`
              : `${classes.dot} ${classes.incomplete}`
          }
        />
      ))}
    </Root>
  );
};

DotProgress.propTypes = {
  stepsLength: PropTypes.number,
  stepsCompleted: PropTypes.number,
};

DotProgress.defaultProps = {
  stepsLength: 1,
  stepsCompleted: 0,
};

export default DotProgress;
