// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Svg *****************************
export const SvgStyle = styled.svg`
  display: block;
  fill: ${(p) => p.fill};
  height: ${(p) => p.height};
  margin: ${(p) => p.margin};
  pointer-events: none;

  @media (max-width: 768px) {
    height: ${(p) => p.mobileHeight};
  }
`;
