import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ReactQuill from "react-quill";

import { Message as MessageActions } from "../../../../redux/actions";

import { orderBy } from "lodash";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

import MessageItem from "./Message";

import { Message } from "lib/models/Message";

const PREFIX = "MessageList";
const classes = {
  paper: `${PREFIX}-paper`,
  loadingThrobber: `${PREFIX}-loadingThrobber`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
  [`&.${classes.loadingThrobber}`]: {
    display: "flex",
    "& > *": {
      margin: "auto",
    },
  },
}));

const editorFormats = [
  "font",
  "bold",
  "italic",
  "underline",
  "blockquote",
  "code-block",
  "list",
  "indent",
];

const editorModules = {
  toolbar: [
    [{ font: [] }, "bold", "italic", "underline", "blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: true,
  },
};

const MessageList = (props) => {
  const [messageDisplay, setMessageDisplay] = useState(null);
  const [pendingMessage, setPendingMessage] = useState("");

  useEffect(() => {
    if (props.messages === false) {
      setMessageDisplay(
        <div className={classes.loadingThrobber}>
          <CircularProgress />
        </div>
      );
    } else if (props.messages.length === 0) {
      setMessageDisplay(
        <div className={classes.loadingThrobber}>
          <Typography>No messages</Typography>
        </div>
      );
    } else {
      const initialMessageDisplay = [];
      props.messages.map((message) => {
        initialMessageDisplay.push(
          <MessageItem key={message.id} {...message} />
        );
      });

      // order messages based on their created prop
      // so that oldest messages appear on top
      const orderedMessageDisplay = orderBy(
        initialMessageDisplay,
        (message) => message.props.created,
        ["asc"]
      );

      setMessageDisplay(orderedMessageDisplay);
    }
  }, [props.messages]);

  const updatePendingMessage = (value) => {
    setPendingMessage(value);
  };

  const sendMessage = () => {
    const newMessage = new Message({
      type: 20,
      body: pendingMessage,
      foreignId: props.findingId,
      orgId: props.orgId,
      foreignType: "FINDING_COMMENT",
      asynchronous: true,
      sender: props.currentUser,
    });
    props.dispatch(MessageActions.saveCommentForFinding(newMessage));
    setPendingMessage();
  };

  return (
    <Root>
      <div>{messageDisplay}</div>
      <Paper elevation={0} variant="outlined" className={classes.paper}>
        <ReactQuill
          formats={editorFormats}
          modules={editorModules}
          placeholder=""
          value={pendingMessage}
          onChange={updatePendingMessage}
          style={{
            width: "100%",
            backgroundColor: "#FFF",
          }}
        />
      </Paper>
      <Button
        onClick={sendMessage}
        variant="contained"
        color="primary"
        datacy={"submitButton"}
      >
        Submit
      </Button>
    </Root>
  );
};

export default MessageList;
