export const FLAG_TYPE = {
  10: "#034ea2",
  15: "#35027b",
  20: "#683967",
  30: "#9b2f49",
  40: "#c0392b",
};

export const PRIORITY = {
  1: "#c0392b",
  2: "#e67e22",
  3: "#f1c40f",
};
