import React from "react";
import ReactApexChart from "react-apexcharts";

export default ({ data, color, height }) => {
  const chartType = "area";
  const options = {
    chart: {
      id: `${data[0].name}-spark`,
      type: chartType,
      sparkline: {
        enabled: true,
      },
    },
    colors: [color || "#008FFB"],
    stroke: {
      width: 1,
    },
    fill: {
      opacity: 0.3,
    },
    xaxis: {
      type: "datetime",
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      tickAmount: 2,
      labels: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={data}
      type={chartType}
      height={height || 100}
    />
  );
};
