// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { arrayOf, string } from "prop-types";

// ** Styles **************************
import { Container, Row } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Error component
//
//  ** Props
//  @param children {node} - error container contents
//  @param margin {string} - error margin
//
const Error = ({ children, margin }) =>
  children.length > 0 ? (
    <Container margin={margin}>
      {children.map((child, index) => (
        <Row key={index}>{`(${index + 1}) ${child}`}</Row>
      ))}
    </Container>
  ) : null;

// ** Proptypes ***********************
Error.propTypes = {
  children: arrayOf(string).isRequired,
  margin: string,
};

export default Error;
