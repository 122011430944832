import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { MetaComponent } from "./MetaComponent";
import {
  ACTION_UPGRADE,
  ACTION_REMOVE,
  ACTION_UPDATE_META,
  ACTION_ROTATE_SECRETS,
  ACTION_MIGRATE,
  compareSemver,
  getModuleLabel,
  getModuleTexts,
  sameTypeModules,
} from "./moduleHelpers";
import ModuleNotes from "./ModuleNotes";

import "./ModuleDetail.scss";

/*
Helpers
*/

const actionsForInstalledModule = ({ module, availableModules }) => {
  const actionList = [];

  const { spec: moduleSpec = {} } = module || {};
  const { deleted, meta = [], permissions = [] } = moduleSpec;

  if (permissions.length) {
    actionList.push({
      label: "Issue New Credentials",
      value: ACTION_ROTATE_SECRETS,
      explain: `This module will be issued new credentials and be restarted.
          It is a good practice to "rotate" your credentials like this on
          a periodic basis.`,
      module,
    });
  }

  // The Sensor Service does not support parameter update for expired modules
  if (meta.length && !deleted) {
    actionList.push({
      label: "Update Parameters",
      value: ACTION_UPDATE_META,
      explain: `You may specify new configuration parameters for your module
        here. For security reasons, we cannot show you your existing
        configuration values.
        If left blank or unchanged, configuration
        parameters will be carried over from the existing configuration.`,
      module,
    });
  }

  const availableVersions = sameTypeModules({ module, availableModules });

  if (availableVersions.length) {
    actionList.push({
      label: "Change Module Version",
      value: ACTION_UPGRADE,
      explain: `Other versions of this Module are available.
        Selecting a different version will replace the existing Module with
        the selected one with the configuration you provide.
        If left blank or unchanged, configuration
        parameters will be carried over from the existing configuration.`,
      module,
      availableVersions,
    });
  }

  if (module.is_migratable) {
    actionList.push({
      label: "Migrate to Cloud Connector",
      value: ACTION_MIGRATE,
      explain: `This will create a new Cloud Connector with the credentials
        you used to create the Sensor Module. The Sensor Module will then be
        deleted from the Sensor completely.`,
      module,
    });
  }

  actionList.push({
    label: "Remove",
    value: ACTION_REMOVE,
    explain: `This will remove the module from the Sensor completely.
      Use this when you no longer require this functionality.`,
    module,
  });

  return actionList;
};

const getVersionsList = ({ module = {}, availableModules = [] }) => {
  const { spec: moduleSpec = {} } = module;

  const availableVersions = sameTypeModules({ module, availableModules });

  const versionList = availableVersions.map((version) => {
    const label = `${getModuleLabel(version)}${
      compareSemver(version, moduleSpec) > 0 ? " (newer)" : ""
    }`;

    return {
      id: version.id,
      label,
      value: `update_version_${version.id}`,
    };
  });

  return versionList;
};

const actionRequiresMeta = (actionValue) =>
  actionValue && actionValue.value === "update_meta";

/*
Main component
*/
const ModuleDetail = ({
  availableModules = [],
  meta = [],
  module = {},
  actionValue,
  handleChangeAction,
  metaValues,
  setMetaValues,
  versionValue,
  handleChangeVersion,
  setFormErrorMessage,
}) => {
  // Select options
  const actions = actionsForInstalledModule({ module, availableModules });
  const versions = getVersionsList({ module, availableModules });

  // Show 'versions' Select
  const showVersions = actionValue && actionValue.value === ACTION_UPGRADE;

  // Show 'meta' select
  const requiresMeta = actionRequiresMeta(actionValue);
  const showMeta = requiresMeta || (showVersions && versionValue);

  const { moduleTitle, moduleNotes, moduleUrl } = getModuleTexts(module.spec);

  return (
    <div className="sensor-module-detail sensor-modal-content">
      <div className="sensor-subtitle">{moduleTitle}</div>

      <ModuleNotes moduleNotes={moduleNotes} moduleUrl={moduleUrl} />

      <div className="sensor-form-item">
        <Select
          options={actions}
          value={actionValue}
          onChange={handleChangeAction}
          placeholder="Choose an Action"
        />

        <div className="module-detail-action-explanation">
          {actionValue ? actionValue.explain : ""}
        </div>
      </div>

      {showVersions && (
        <div className="sensor-select">
          <Select
            options={versions || []}
            value={versionValue}
            onChange={handleChangeVersion}
            placeholder="Choose a Version"
          />
        </div>
      )}

      {showMeta && (
        <MetaComponent
          metaValues={metaValues}
          setMetaValues={setMetaValues}
          setFormErrorMessage={setFormErrorMessage}
          metaPrevious={meta}
          sensorModule={module}
        />
      )}
    </div>
  );
};

// PropTypes
ModuleDetail.propTypes = {
  availableModules: PropTypes.arrayOf(PropTypes.shape({})),
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  module: PropTypes.shape({}),
  actionValue: PropTypes.shape({}),
  handleChangeAction: PropTypes.func.isRequired,
  metaValues: PropTypes.shape({}),
  setMetaValues: PropTypes.func.isRequired,
  setFormErrorMessage: PropTypes.func,
  versionValue: PropTypes.shape({}),
  handleChangeVersion: PropTypes.func.isRequired,
};

ModuleDetail.defaultProps = {
  availableModules: [],
  meta: [],
  module: {},
  actionValue: {},
  metaValues: {},
  setFormErrorMessage: () => {},
  versionValue: null,
};

export default ModuleDetail;
