import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "maxio",
};

export class MaxioAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  async lookupCustomer(body) {
    const params = ["lookupcustomer"];
    return super.post({ params }, body);
  }

  async createCustomer(body) {
    const params = ["createcustomer"];
    return super.post({ params }, body);
  }

  async updateCustomer(customerId, body) {
    const params = ["updatecustomer", customerId];
    return super.put({ params }, body);
  }

  async getCustomer(customerId) {
    const params = ["getcustomer", customerId];
    return super.get({ params });
  }

  async getInvoices(customerId) {
    const params = ["getinvoices", customerId];
    return super.get({ params });
  }
}
