import { DataQueryApi } from "../../lib/api";
import Logger from "../../lib/logger";
import { fetchConstants } from "./Findings";
import { handleApiError } from "../utils/helpers";

import Request from "lib/api/Request";

const logger = Logger("QueryActions");

export const RECEIVE_FILTER_FIELDS = "DATA_QUERY.RECEIVE_FILTER_FIELDS";
export const ADD_FILTER = "DATA_QUERY.ADD_FILTER";
export const REMOVE_FILTER = "DATA_QUERY.REMOVE_FILTER";
export const CHANGE_DATE = "DATA_QUERY.CHANGE_DATE";
export const CHANGE_COLUMNS = "DATA_QUERY.CHANGE_COLUMNS";
export const CHANGE_NAME = "DATA_QUERY.CHANGE_NAME";
export const RECEIVE_QUERY_DATA = "DATA_QUERY.RECEIVE_QUERY_DATA";
export const RECEIVE_FETCH_QUERY_ERROR = "DATA_QUERY.RECEIVE_FETCH_QUERY_ERROR";
export const RECEIVE_RUN_QUERY_ERROR = "DATA_QUERY.RECEIVE_RUN_QUERY_ERROR";
export const RECEIVE_SAVE_QUERY_ERROR = "DATA_QUERY.RECEIVE_SAVE_QUERY_ERROR";
export const RECEIVE_FIELDS_ERROR = "DATA_QUERY.RECEIVE_FIELDS_ERROR";

export const RECEIVE_DATA_QUERIES = "DATA_QUERY.RECEIVE_DATA_QUERIES";
export const RECEIVE_SAVED_QUERY = "DATA_QUERY.RECEIVE_SAVED_QUERY";
export const SELECT_DATA_QUERY = "DATA_QUERY.SELECT_DATA_QUERY";
export const RESET_STORE = "DATA_QUERY.RESET_STORE";
export const LOADING_STATE = "DATA_QUERY.LOADING_STATE";
export const SET_IS_AGGREGATION = "DATA_QUERY.SET_IS_AGGREGATION";
export const RESET_QUERY_ID = "DATA_QUERY.RESET_QUERY_ID";

/* ========================================================================
 *   Private Constants
/* ======================================================================== */

const API = {
  dataQuery: new DataQueryApi(),
};

const receiveFilterFields = ({ dataSource, fields }) => ({
  type: RECEIVE_FILTER_FIELDS,
  dataSource,
  fields,
});

export const setIsAggregation = ({ dataSource, isAggregateData }) => ({
  type: SET_IS_AGGREGATION,
  dataSource,
  isAggregateData,
});

export const selectSavedQuery = ({ dataSource, savedQuery }) => ({
  type: SELECT_DATA_QUERY,
  dataSource,
  savedQuery,
});

export const resetQueryId = ({ dataSource }) => ({
  type: RESET_QUERY_ID,
  dataSource,
});

const receiveQueryData = ({
  data,
  resultFileUrl,
  dataSource,
  maxResultsExceeded,
}) => ({
  type: RECEIVE_QUERY_DATA,
  data,
  resultFileUrl,
  dataSource,
  maxResultsExceeded,
});

const parseSearch = (dataQuery) =>
  dataQuery.search.map((filter) => ({
    ...filter,
    fieldValue: ["string", "timestamp", "boolean"].includes(filter.fieldType)
      ? JSON.parse(filter.fieldValue)
      : filter.fieldValue,
  }));

const receiveDataQueries = ({ dataSource, dataQueries }) => ({
  type: RECEIVE_DATA_QUERIES,
  dataSource,
  dataQueries: dataQueries,
});

const receiveSavedQuery = ({ dataSource, savedQuery }) => ({
  type: RECEIVE_SAVED_QUERY,
  dataSource,
  savedQuery: {
    ...savedQuery,
    search: parseSearch(savedQuery),
  },
});

export const changeQueryName = ({ dataSource, name }) => ({
  type: CHANGE_NAME,
  dataSource,
  name,
});

/* ========================================================================
 *   Exported Constants
/* ======================================================================== */

export const DATA_SOURCES = {
  finding: {
    name: "finding",
    timeColumn: "created",
    nameColumn: "name",
  },
  bq: {
    name: "bq",
    timeColumn: "timestamp",
    nameColumn: "devname",
  },
};

export const changeDateRange = ({
  startDate,
  endDate,
  dataSource,
  modified = false,
}) => ({
  type: CHANGE_DATE,
  startDate,
  endDate,
  dataSource,
  modified,
});

export const addFilter = ({ filter, dataSource }) => ({
  type: ADD_FILTER,
  dataSource,
  filter,
});

export const removeFilter = ({ filter, dataSource }) => ({
  type: REMOVE_FILTER,
  dataSource,
  filter,
});

export const changeColumns = ({ dataSource, columns }) => ({
  type: CHANGE_COLUMNS,
  dataSource,
  columns,
});

export const receiveFetchQueryError = ({ dataSource, error }) => ({
  payload: {
    dataSource,
    error,
  },
  type: RECEIVE_FETCH_QUERY_ERROR,
});

export const receiveRunQueryError = ({ dataSource, error }) => ({
  payload: {
    dataSource,
    error,
  },
  type: RECEIVE_RUN_QUERY_ERROR,
});

export const receiveSaveQueryError = ({ dataSource, error }) => ({
  payload: {
    dataSource,
    error,
  },
  type: RECEIVE_SAVE_QUERY_ERROR,
});

export const receiveFieldsError = ({ dataSource, error }) => ({
  type: RECEIVE_FIELDS_ERROR,
  payload: {
    dataSource,
    error,
  },
});

export const setQueryLoadingState = ({ dataSource, state }) => ({
  dataSource,
  state,
  type: LOADING_STATE,
});

export const resetStore = () => ({ type: RESET_STORE });

/* ========================================================================
 *   Export Asynchronous Constants
/* ======================================================================== */

export const getFilterOptions =
  ({ orgId, dataSource }) =>
  (dispatch) => {
    dispatch(receiveFieldsError({ dataSource, error: null }));

    return API.dataQuery
      .getDataFilterOptions({ orgId, dataSource })
      .then(({ fields }) => {
        dispatch(receiveFieldsError({ dataSource, error: null }));

        dispatch(receiveFilterFields({ dataSource, fields }));
      })
      .catch((err) => {
        const message = "Cannot get query filter options.";

        dispatch(
          handleApiError({
            message,
            err,
            onError: (error) => receiveFieldsError({ dataSource, error }),
          })
        );
      });
  };

export const fetchDataQueries =
  ({ orgId, dataSource }) =>
  async (dispatch) => {
    const queryParams = [
      { field: "orgId", value: orgId },
      { field: "dataSource", value: dataSource },
    ];
    const request = new Request("/dataquery", queryParams);

    return request
      .get()
      .then((dataQueries) => {
        dispatch(receiveDataQueries({ dataQueries, dataSource }));
      })
      .catch((err) => {
        const message = "Cannot fetch saved queries.";

        dispatch(
          handleApiError({
            message,
            err,
            onError: (error) => receiveFetchQueryError({ dataSource, error }),
          })
        );
      });
  };

export const runQueryAction = (query) => async (dispatch) => {
  const { dataSource } = query;

  dispatch(setQueryLoadingState({ dataSource, state: true }));
  dispatch(receiveRunQueryError({ dataSource, error: null }));

  let response;

  try {
    response = await API.dataQuery.runDataQuery(query);
  } catch (err) {
    const message = "Unable to run the query.";
    logger.error(message, err);

    dispatch(setQueryLoadingState({ dataSource, state: false }));
    dispatch(
      handleApiError({
        message,
        err,
        onError: (error) => receiveRunQueryError({ dataSource, error }),
      })
    );

    return;
  }

  dispatch(receiveRunQueryError({ dataSource, error: null }));

  const { maxResultsExceeded, results, resultFileUrl } = response;

  const rowReducer = (rows) =>
    rows.reduce(
      (row, r) => ({
        ...row,
        [r.fieldName]: JSON.parse(r.fieldValue),
      }),
      {}
    );

  const data =
    results && results.length > 0
      ? results.reduce((all, result) => all.concat(rowReducer(result)), [])
      : [];

  dispatch(
    receiveQueryData({
      data,
      dataSource,
      maxResultsExceeded,
      resultFileUrl,
    })
  );
};

export const saveDataQuery = (query) => async (dispatch) => {
  const { dataSource } = query;

  dispatch(receiveSaveQueryError({ dataSource, error: null }));

  let response;

  try {
    response = await API.dataQuery.createDataQuery(query);
  } catch (err) {
    const message = "An error occurred saving the query.";

    logger.error(message, err);

    dispatch(
      handleApiError({
        message,
        err,
        onError: (error) => receiveSaveQueryError({ dataSource, error }),
      })
    );

    return;
  }

  dispatch(receiveSaveQueryError({ dataSource, error: null }));

  const { savedQuery } = response;

  dispatch(receiveSavedQuery({ dataSource, savedQuery }));
};

export const fetchQueryOptions =
  ({ orgId, dataSource, run }) =>
  async (dispatch, getState) => {
    const prefetchFns = [dispatch(getFilterOptions({ orgId, dataSource }))];

    if (dataSource === DATA_SOURCES.finding.name) {
      prefetchFns.push(dispatch(fetchConstants()));
    }

    const done = await Promise.all(prefetchFns);

    if (done && run) {
      const { dateRangeFilter, selectedColumns, fieldFilters } =
        getState().query[dataSource];

      // Add the 'id' to 'aggregate' for displaying each finding's detail
      const aggregate = selectedColumns.map((i) => i.value).filter((i) => i);
      aggregate.push("id");

      const query = {
        aggregate,
        configuration: {
          timeStart: dateRangeFilter.startDate,
          timeEnd: dateRangeFilter.endDate,
          orderBy: [
            {
              direction: "desc",
              fieldName: DATA_SOURCES[dataSource].timeColumn,
            },
          ],
        },
        search: fieldFilters.map(
          ({ fieldName, fieldType, fieldValue, opcode }) => ({
            fieldName,
            fieldType,
            fieldValue,
            opcode,
          })
        ),
      };

      dispatch(
        runQueryAction({
          ...query,
          orgId,
          dataSource,
        })
      );
    }
  };
