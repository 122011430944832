import React, { useState } from "react";
import PropTypes from "prop-types";

import Logger from "../../../lib/logger";
import ResourceTypeSelect, {
  RESOURCE_SELECT_OPTIONS,
} from "./ResourceTypeSelect";
import SearchInput from "./SearchInput";

import "./common/ResourcesSearch.scss";

const logger = Logger("DashboardSearch");

/*
Constants and Helpers
*/

const INPUT_PLACEHOLDER_OPTIONS = {
  ip: "an IP address",
  hostname: "a hostname",
  username: "a username",
  subnet: "a subnet",
};

const INPUT_PLACEHOLDER_TAIL =
  "or select a different type of resource to investigate";

const getPlaceholder = (value) => {
  const resource = INPUT_PLACEHOLDER_OPTIONS[value];

  return `Enter ${resource} ${INPUT_PLACEHOLDER_TAIL}`;
};

/*
Component
*/

const DashboardSearch = (props) => {
  const { submit } = props;

  const [selectedOption, setSelectedOption] = useState(
    RESOURCE_SELECT_OPTIONS[0]
  );

  const getPlaceholderWrapper = () => getPlaceholder(selectedOption.value);

  const submitWrapper = (newValue) => {
    submit({
      value: newValue,
      resourceType: selectedOption.value,
    });
  };

  return (
    <div className="resources-search">
      <div className="resources-search-left">
        <ResourceTypeSelect
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>

      <div className="resources-search-right">
        <SearchInput
          getPlaceholder={getPlaceholderWrapper}
          submit={submitWrapper}
        />
      </div>
    </div>
  );
};

DashboardSearch.propTypes = {
  submit: PropTypes.func,
};

DashboardSearch.defaultProps = {
  submit: ({ value, resourceType }) => {
    logger.log(
      "DEBUG_OUTPUT: DashboardSearch submit value, resourceType",
      value,
      resourceType
    );
  },
};

export default DashboardSearch;
