import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { get } from "lodash";

import {
  Button,
  Checkbox,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from "@mui/material";

import Alert from "@mui/material/Alert";

import PauseIcon from "@mui/icons-material/Pause";
import CachedIcon from "@mui/icons-material/Cached";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import Link from "redux-first-router-link";

import { Org } from "lib/models/Org";

import Request from "lib/api/Request";

import ActionDialog from "views/Components/ActionDialog";

import { classes, dialogClasses, Root, StyledDialog } from "./controlBarStyles";
import { getUserOrgRoles } from "utils/sidebarUtils";

const threats = [
  { label: "P1 Threat", value: "threats:p1" },
  { label: "P2 Threat", value: "threats:p2" },
  { label: "P3 Threat", value: "threats:p3" },
];

const suspects = [
  { label: "P1 Suspect", value: "suspects:p1" },
  { label: "P2 Suspect", value: "suspects:p2" },
  { label: "P3 Suspect", value: "suspects:p3" },
];

const AutoIsolationControlBar = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfDevices, setNumberOfDevices] = useState(0);
  const [currentOrgModel, setCurrentOrgModel] = useState({});
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [selectedAutoIsolationTypes, setSelectedAutoIsolationTypes] = useState(
    []
  );
  const [isAHIInfoModalOpen, setIsAHIInfoModalOpen] = useState(false);
  const [totalRules, setTotalRules] = useState([]);
  const [totalAHISupportedRules, setTotalAHISupportedRules] = useState([]);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevOrgId = usePrevious(props.orgId);

  // refresh device data if org is switched
  useEffect(() => {
    if (prevOrgId !== props.orgId && props.license.hasFeature("AUTOBOT")) {
      try {
        getDevices();
      } catch {
        setNumberOfDevices(0);
        setIsLoading(false);
      }
    }
  }, [props.orgId, prevOrgId]);

  useEffect(() => {
    if (props.currentOrg) {
      // orgs in session are stored as JSON, not models
      const newOrgModel = props.currentOrg.hash
        ? props.currentOrg
        : new Org(props.currentOrg);
      const autoIsolationTypes = newOrgModel.autoIsolationTypes
        ? newOrgModel.autoIsolationTypes
        : [];
      setSelectedAutoIsolationTypes(autoIsolationTypes);
      setCurrentOrgModel(newOrgModel);
    }
  }, [props.currentOrg]);

  const getDevices = async () => {
    setIsLoading(true);

    // get number of devices to display
    const request = new Request("/lcagent");
    const devices = await request.get();
    if (devices.length) {
      // only count isolated devices
      const numberOfIsolatedDevices = devices.filter(
        ({ isIsolated }) => !!isIsolated
      ).length;
      setNumberOfDevices(numberOfIsolatedDevices);
    }

    setIsLoading(false);
  };

  const handleSetIsolationClick = async () => {
    if (currentOrgModel.autoIsolationEnabled) {
      setIsConfirmationDialogOpen(true);
    } else {
      setIsLoading(true);
      setIsAHIInfoModalOpen(true);
      await getTotalNumberOfRules();
      setIsLoading(false);
    }
  };

  const handleToggleHostIsolation = async () => {
    handleSaveHostIsolation();
  };

  const handleSettingsClick = () => {
    setIsSettingsDialogOpen(true);
  };

  const handleDevicesClick = () => {
    props.routeToDevicesPageView(props.orgId);
  };

  const handleRefreshClick = () => {
    try {
      getDevices();
    } catch {
      setNumberOfDevices(0);
      setIsLoading(false);
    }
  };

  const handleSelectType = (event) => {
    const selectedType = event.target.value;

    const isTypeSelected = selectedAutoIsolationTypes.find(
      (type) => type === selectedType
    );

    let newAutoIsolationTypes = isTypeSelected
      ? selectedAutoIsolationTypes.filter((type) => type !== selectedType)
      : selectedAutoIsolationTypes.concat([selectedType]);

    setSelectedAutoIsolationTypes(newAutoIsolationTypes);
  };

  const isTypeChecked = (selectedType) => {
    return !!selectedAutoIsolationTypes.find((type) => type === selectedType);
  };

  const saveOrg = async (orgModel) => {
    try {
      setIsLoading(true);
      await orgModel.update().then((org) => {
        setCurrentOrgModel(org);
      });
      setIsLoading(false);
      setIsSettingsDialogOpen(false);
      setIsConfirmationDialogOpen(false);
    } catch (e) {
      setIsLoading(false);
      setCurrentOrgModel(currentOrgModel);
      setIsSettingsDialogOpen(false);
      setIsConfirmationDialogOpen(false);
    }
  };

  const handleSaveSettings = () => {
    let orgModelToUpdate = currentOrgModel;
    orgModelToUpdate.set({ autoIsolationTypes: selectedAutoIsolationTypes });
    saveOrg(orgModelToUpdate);
  };

  const handleSaveHostIsolation = () => {
    let orgModelToUpdate = currentOrgModel;
    orgModelToUpdate.set({
      autoIsolationEnabled: !currentOrgModel.autoIsolationEnabled,
    });
    saveOrg(orgModelToUpdate);

    // close dialogs
    setIsAHIInfoModalOpen(false);
    setIsConfirmationDialogOpen(false);
  };

  const userOrgRoles = getUserOrgRoles({
    user: props.currentUser,
    orgId: props.orgId,
  });

  const checkUserPermissions = () => {
    return props.currentUser.superadmin ||
      userOrgRoles.includes("administrator")
      ? false
      : true;
  };

  const getTotalNumberOfRules = async () => {
    // These are the specific desired targetAction #s coming from the Condition
    const ahiTargetActionIds = [4, 5, 6, 10, 11, 12];
    // GET total number of rules for the current org that are enabled
    const rulesRequest = new Request("/rule", [
      { field: "orgId", value: props.orgId },
    ]);
    // GET total number of conditions
    const conditionsRequest = new Request("/condition");
    const allRules = await rulesRequest.get();
    const conditions = await conditionsRequest.get();

    // Find conditions that match the condition set from the list of rules and then find the conditions that match the indicated targetAction numbers for suspects and threats
    const matchedConditions = allRules
      ?.map(
        (r) =>
          conditions?.filter((c) => c.id === r.conditionSet[0].condition)[0]
      )
      .filter((c) => ahiTargetActionIds.includes(c.targetAction));
    // Find the matched conditions that meet the requirements for supporting AHI
    const totalAHISupportedRules = matchedConditions?.filter(
      (c) =>
        (c?.tags?.includes("type:windows") ||
          c?.tags?.includes("type:agent_os") ||
          c?.tags?.includes("type:agent_os_mac") ||
          c?.tags?.includes("type:agent_os_linux")) &&
        !c?.tags?.includes("AHI:disabled") &&
        c?.method === 500
    );

    setTotalRules(allRules);
    setTotalAHISupportedRules(totalAHISupportedRules);
  };

  const areAutoIsolationTypesArraysEqual = () => {
    return (
      Array.isArray(currentOrgModel.autoIsolationTypes) &&
      Array.isArray(selectedAutoIsolationTypes) &&
      currentOrgModel.autoIsolationTypes.length ===
        selectedAutoIsolationTypes.length &&
      currentOrgModel.autoIsolationTypes.every(
        (val, index) => val === selectedAutoIsolationTypes[index]
      )
    );
  };

  const handleCancelSaveSettings = () => {
    setIsSettingsDialogOpen(false);
    setSelectedAutoIsolationTypes(currentOrgModel.autoIsolationTypes);
  };

  return (
    <Root className={classes.controlBarWrap}>
      <div className={classes.innerWrap} datacy={"ahiControlBar"}>
        <Typography className={classes.controlBarText}>
          {currentOrgModel.autoIsolationEnabled
            ? "Automated Host Isolation is running"
            : "Automated Host Isolation is disabled"}
        </Typography>
        {checkUserPermissions() ? (
          <Tooltip
            title={
              "Only Blumira admins can enable or disable auto host isolation"
            }
            placement={"bottom"}
          >
            <div>
              <Button
                variant={"outlined"}
                className={classes.disabledButton}
                onClick={handleSetIsolationClick}
                disabled={true}
                datacy={"ahiStartPauseButton"}
                startIcon={
                  isLoading ? null : currentOrgModel.autoIsolationEnabled ? (
                    <PauseIcon className={classes.disabledButtonText} />
                  ) : (
                    <PlayArrowIcon className={classes.disabledButtonText} />
                  )
                }
              >
                {isLoading ? (
                  <CircularProgress
                    size={12}
                    className={classes.disabledButtonText}
                  />
                ) : (
                  <p className={classes.disabledButtonText}>
                    {currentOrgModel.autoIsolationEnabled ? "Pause" : "Start"}
                  </p>
                )}
              </Button>
            </div>
          </Tooltip>
        ) : selectedAutoIsolationTypes.length === 0 ? (
          <Tooltip
            title={
              "AHI settings are missing. Go to Settings and indicate which priority level(s) of Threat and Suspect detections are allowed to auto-isolate devices."
            }
            placement={"bottom"}
          >
            <div>
              <Button
                variant={"outlined"}
                className={classes.button}
                datacy={"ahiStartPauseButton"}
                onClick={handleSetIsolationClick}
                disabled={true}
                startIcon={
                  isLoading ? null : currentOrgModel.autoIsolationEnabled ? (
                    <PauseIcon className={classes.buttonText} />
                  ) : (
                    <PlayArrowIcon className={classes.buttonText} />
                  )
                }
              >
                {isLoading ? (
                  <CircularProgress size={12} className={classes.buttonText} />
                ) : (
                  <p className={classes.buttonText}>
                    {currentOrgModel.autoIsolationEnabled ? "Pause" : "Start"}
                  </p>
                )}
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            variant={"outlined"}
            className={classes.button}
            datacy={"ahiStartPauseButton"}
            onClick={handleSetIsolationClick}
            startIcon={
              isLoading ? null : currentOrgModel.autoIsolationEnabled ? (
                <PauseIcon className={classes.buttonText} />
              ) : (
                <PlayArrowIcon className={classes.buttonText} />
              )
            }
          >
            {isLoading ? (
              <CircularProgress size={12} className={classes.buttonText} />
            ) : (
              <p className={classes.buttonText}>
                {currentOrgModel.autoIsolationEnabled ? "Pause" : "Start"}
              </p>
            )}
          </Button>
        )}
        <Button
          variant={"outlined"}
          className={classes.button}
          onClick={handleSettingsClick}
        >
          <p className={classes.buttonText}>Settings</p>
        </Button>
        <Button
          variant={"outlined"}
          className={classes.countButton}
          onClick={handleDevicesClick}
        >
          {isLoading ? (
            <CircularProgress size={12} className={classes.buttonText} />
          ) : (
            <p className={classes.buttonText}>
              {numberOfDevices} device
              {numberOfDevices === 0 || numberOfDevices === 0 > 1
                ? "s"
                : ""}{" "}
              currently isolated
            </p>
          )}
        </Button>
        <Button
          variant={"outlined"}
          onClick={handleRefreshClick}
          className={classes.refreshButton}
          startIcon={<CachedIcon className={classes.buttonText} />}
        >
          <p className={classes.buttonText}>Refresh</p>
        </Button>
      </div>
      <ActionDialog
        title={`Are you sure?`}
        open={isConfirmationDialogOpen}
        description={
          "Automated Host Isolation will remain disabled until you restart it."
        }
        actions={[
          {
            title: "Cancel",
            action: () => setIsConfirmationDialogOpen(false),
          },
          {
            title: "Disable Automated Host Isolation",
            action: handleToggleHostIsolation,
            variant: "contained",
          },
        ]}
      />
      <ActionDialog
        title={`Are you sure?`}
        open={isAHIInfoModalOpen}
        description={
          <>
            {isAHIInfoModalOpen && isLoading ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={40} style={{}} />
              </div>
            ) : (
              <>
                <p>Please note the following about automated host isolation:</p>
                <ul>
                  <li>
                    {`${totalAHISupportedRules.length} of your ${totalRules.length} enabled detection rules support automated host isolation.`}
                    <Link
                      target={"_blank"}
                      to={{
                        type: "PAGE",
                        payload: {
                          orgId: props.orgId,
                          toplevel: "settings",
                          secondlevel: "rules",
                          query: { isAHISupported: true },
                        },
                      }}
                      className={classes.rulesLink}
                    >
                      See rules <OpenInNewIcon />
                    </Link>
                  </li>
                  <li>
                    To use automated host isolation on a device, the device must
                    have Blumira Agent.
                  </li>
                </ul>
              </>
            )}
          </>
        }
        actions={[
          {
            title: "Cancel",
            action: () => setIsAHIInfoModalOpen(false),
          },
          {
            title: "Yes, I'm sure",
            action: handleToggleHostIsolation,
            variant: "contained",
            datacy: "resumeAHIConfirmButton",
          },
        ]}
      />
      <StyledDialog
        datacy={"ahiSettingsDialog"}
        open={isSettingsDialogOpen}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogTitle>Automated Host Isolation Settings</DialogTitle>
        {checkUserPermissions() && (
          <Alert
            icon={<InfoOutlinedIcon fontSize="inherit" />}
            severity="info"
            className={classes.checkboxAlert}
          >
            Only Blumira admins can modify these settings
          </Alert>
        )}
        <DialogContent>
          <div className={dialogClasses.checkboxOuterContainer}>
            {threats.map((threat) => (
              <div
                key={threat.value}
                className={dialogClasses.checkboxInnerContainer}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color={"primary"}
                      value={threat.value}
                      onChange={handleSelectType}
                      checked={isTypeChecked(threat.value)}
                      disabled={checkUserPermissions()}
                    />
                  }
                  label={threat.label}
                  datacy={"ahiControlBarSettingsOptions"}
                />
              </div>
            ))}
          </div>
          <div className={dialogClasses.checkboxOuterContainer}>
            {suspects.map((suspect) => (
              <div
                key={suspect.value}
                className={dialogClasses.checkboxInnerContainer}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color={"primary"}
                      value={suspect.value}
                      onChange={handleSelectType}
                      checked={isTypeChecked(suspect.value)}
                      disabled={checkUserPermissions()}
                    />
                  }
                  label={suspect.label}
                  datacy={"ahiControlBarSettingsOptions"}
                />
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions className={dialogClasses.dialogActions}>
          <a
            href={"https://blumira.help/auto-isolation"}
            rel={"noopener noreferrer"}
            target={"_blank"}
          >
            Documentation <OpenInNewIcon />
          </a>
          <div style={{ display: "flex" }}>
            <Button
              color={"primary"}
              variant={"text"}
              datacy={"ahiSettingsDialogCancelButton"}
              onClick={handleCancelSaveSettings}
            >
              Cancel
            </Button>
            {selectedAutoIsolationTypes.length === 0 ? (
              <Tooltip
                title={
                  "Automated host isolation requires at least one option to be selected while it is running."
                }
                placement={"bottom"}
              >
                <div>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={handleSaveSettings}
                    disabled={true}
                  >
                    {"Save changes"}
                  </Button>
                </div>
              </Tooltip>
            ) : areAutoIsolationTypesArraysEqual() && !isLoading ? (
              <Tooltip
                title={
                  "Your current selections match your currently saved settings options.  Please make a different selection in order to save changes."
                }
                placement={"bottom"}
              >
                <div>
                  <Button
                    color={"primary"}
                    variant={"contained"}
                    onClick={handleSaveSettings}
                    disabled={true}
                  >
                    {"Save changes"}
                  </Button>
                </div>
              </Tooltip>
            ) : (
              <Button
                color={"primary"}
                variant={"contained"}
                onClick={handleSaveSettings}
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save changes"}
              </Button>
            )}
          </div>
        </DialogActions>
      </StyledDialog>
    </Root>
  );
};

const mapStateToProps = (state) => {
  const currentOrgId = state.location.payload.orgId;
  const userOrgs = get(state, ["session", "settings", "userOrgs"], []);
  const currentOrg = userOrgs.find(({ id }) => id === currentOrgId);
  const currentUser = state.session.settings.user;

  return {
    currentOrg,
    orgId: currentOrgId,
    currentUser,
    license: state.license,
  };
};

const mapDispatchToProps = (dispatch) => ({
  routeToDevicesPageView: (orgId) => {
    dispatch({
      type: "PAGE",
      payload: {
        orgId,
        toplevel: "blumiraagent",
        secondlevel: "devices",
        query: { isIsolated: true },
      },
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoIsolationControlBar);
