import React from "react";
import PropTypes from "prop-types";

const EmptyVisualization = ({
  children,
  message,
  isError,
  errorMessage,
  handleRetry,
}) => (
  <div className="empty-visualization">
    <div className="empty-visualization-inner">
      {!isError && <div className="message">{message}</div>}
      {isError && (
        <div className="error-message">
          {errorMessage}
          <button className="error-retry" type="button" onClick={handleRetry}>
            <span className="icon">
              <i className="fas fa-redo" />
            </span>
            &nbsp;Retry
          </button>
        </div>
      )}
    </div>
    <div className="blurred-container">
      <div className="white-tinting-container" />
      {children}
    </div>
  </div>
);

EmptyVisualization.propTypes = {
  message: PropTypes.string,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  handleRetry: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.shape({}, PropTypes.null),
  ]),
};

EmptyVisualization.defaultProps = {
  message: "No Data Found",
  errorMessage: "Error Fetching Data",
  isError: false,
  handleRetry: () => {},
  children: [],
};

export default EmptyVisualization;
