const signUpSchema = {
  $id: "/signup",
  title: "SignUp",
  type: "object",
  properties: {
    email: { type: "string" },
    firstName: { type: "string" },
    lastName: { type: "string" },
    locations: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string", format: "uuid" },
          orgId: { type: "string", format: "uuid" },
        },
        required: ["id", "orgId"],
      },
    },
    orgRoles: {
      type: "array",
      items: {
        type: "object",
        properties: {
          orgId: { type: "string", format: "uuid" },
          roleId: { type: "number" },
        },
        required: ["orgId", "roleId"],
        additionalProperties: false,
      },
    },
  },
  required: ["firstName", "lastName"],
  additionalProperties: false,
};

export default signUpSchema;
