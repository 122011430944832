import React from "react";
import PropTypes from "prop-types";
import { classes, Root } from "./styles";
import {
  Typography,
  TextField,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

const MSPCustomRampFields = (props) => {
  // Need a prop that can be passed in to determine is it a SA or not
  // This will help determine what experience is being shown (are the fields editable, is the dollar symbol showing, is there added text above fields for regular admins, etc)

  // Also need to determine what properties from the original implementation need to be brought in here and which can just be passed in

  return (
    <Root>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <div className={classes.overrideInput}>
          <InputLabel
            for="outlined-input-override"
            className={classes.overrideLabel}
          >
            Override monthly minimum:
          </InputLabel>
          <OutlinedInput
            id="outlined-input-override"
            className={classes.overrideField}
            type="number"
            value={props.overrideMin}
            onChange={props.handleOverrideMinChange}
            disabled={!props.isSuperadminView}
          />
        </div>
        <div
          style={{
            width: "87%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body1" className={classes.rampTitle}>
            Optional ramp
          </Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              id="month-one"
              label="Month 1"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.monthOne} // Needs to be brought in as a prop or moved into this one
              onChange={props.handleMonthOneChange} // Needs to be brought in as a prop or moved into this one
              disabled={!props.isSuperadminView}
            />
            <TextField
              id="month-two"
              label="Month 2"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.monthTwo} // Needs to be brought in as a prop or moved into this one
              onChange={props.handleMonthTwoChange} // Needs to be brought in as a prop or moved into this one
              disabled={!props.isSuperadminView}
            />
            <TextField
              id="month-three"
              label="Month 3"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.monthThree} // Needs to be brought in as a prop or moved into this one
              onChange={props.handleMonthThreeChange} // Needs to be brought in as a prop or moved into this one
              disabled={!props.isSuperadminView}
            />
            {props.isSuperadminView ? (
              <div
                className={classes.needsApprovalContainer}
                style={{
                  width: "24%",
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <TextField
                  id="month-four"
                  label="Month 4"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ margin: "15px 0 0 0", width: "32%" }}
                  className={classes.customMonthlyInput}
                  type="number"
                  value={props.monthFour} // Needs to be brought in as a prop or moved into this one
                  onChange={props.handleMonthFourChange} // Needs to be brought in as a prop or moved into this one
                  disabled={!props.isSuperadminView}
                />
                <TextField
                  id="month-five"
                  label="Month 5"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ margin: "15px 0 0 0", width: "32%" }}
                  className={classes.customMonthlyInput}
                  type="number"
                  value={props.monthFive} // Needs to be brought in as a prop or moved into this one
                  onChange={props.handleMonthFiveChange} // Needs to be brought in as a prop or moved into this one
                  disabled={!props.isSuperadminView}
                />
                <TextField
                  id="month-six"
                  label="Month 6"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ margin: "15px 0 0 0", width: "32%" }}
                  className={classes.customMonthlyInput}
                  type="number"
                  value={props.monthSix} // Needs to be brought in as a prop or moved into this one
                  onChange={props.handleMonthSixChange} // Needs to be brought in as a prop or moved into this one
                  disabled={!props.isSuperadminView}
                />
              </div>
            ) : (
              <>
                <TextField
                  id="month-four"
                  label="Month 4"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ margin: "15px 0 0 0", width: "8%" }}
                  className={classes.customMonthlyInput}
                  type="number"
                  value={props.monthFour} // Needs to be brought in as a prop or moved into this one
                  onChange={props.handleMonthFourChange} // Needs to be brought in as a prop or moved into this one
                  disabled={!props.isSuperadminView}
                />
                <TextField
                  id="month-five"
                  label="Month 5"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ margin: "15px 0 0 0", width: "8%" }}
                  className={classes.customMonthlyInput}
                  type="number"
                  value={props.monthFive} // Needs to be brought in as a prop or moved into this one
                  onChange={props.handleMonthFiveChange} // Needs to be brought in as a prop or moved into this one
                  disabled={!props.isSuperadminView}
                />
                <TextField
                  id="month-six"
                  label="Month 6"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  sx={{ margin: "15px 0 0 0", width: "8%" }}
                  className={classes.customMonthlyInput}
                  type="number"
                  value={props.monthSix} // Needs to be brought in as a prop or moved into this one
                  onChange={props.handleMonthSixChange} // Needs to be brought in as a prop or moved into this one
                  disabled={!props.isSuperadminView}
                />
              </>
            )}
            <TextField
              id="month-seven"
              label="Month 7"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.overrideMin} // Needs to be brought in as a prop or moved into this one
              disabled
            />
            <TextField
              id="month-eight"
              label="Month 8"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.overrideMin} // Needs to be brought in as a prop or moved into this one
              disabled
            />
            <TextField
              id="month-nine"
              label="Month 9"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.overrideMin} // Needs to be brought in as a prop or moved into this one
              disabled
            />
            <TextField
              id="month-ten"
              label="Month 10"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.overrideMin} // Needs to be brought in as a prop or moved into this one
              disabled
            />
            <TextField
              id="month-eleven"
              label="Month 11"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.overrideMin} // Needs to be brought in as a prop or moved into this one
              disabled
            />
            <TextField
              id="month-twelve"
              label="Month 12"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              sx={{ margin: "15px 0 0 0", width: "8%" }}
              className={classes.customMonthlyInput}
              type="number"
              value={props.overrideMin} // Needs to be brought in as a prop or moved into this one
              disabled
            />
          </div>
        </div>
      </div>
    </Root>
  );
};

MSPCustomRampFields.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  usageReports: PropTypes.array,
};

MSPCustomRampFields.defaultProps = {
  open: false,
  usageReports: [],
  toggle: () => {
    console.warn("No toggle function provided for MSPUsageReportsDialog");
  },
};

export default MSPCustomRampFields;
