import { API } from "./API";

/*
No GraphQL FEA '/finding' endpoints calls
*/

const DEFAULT_OPTIONS = {
  baseUrl: "finding",
};

export class FindingAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  resolveFinding({ data, findingId }) {
    const params = [findingId, "resolve"];
    return super.put({ params }, data);
  }

  addFindingAttachment({ orgId, findingId, filename }) {
    const params = [orgId, findingId, "attachment"];
    return super.post({ params }, { filename });
  }

  deleteFindingAttachment({ orgId, findingId, attachmentId }) {
    const params = [orgId, findingId, "attachment", attachmentId];
    return super.delete({ params });
  }

  submitQuestion(data) {
    const { findingId } = data;
    const params = [findingId, "answers"];
    return super.put({ params }, data);
  }

  setOwners(data) {
    const { findingId } = data;
    const params = [findingId, "owners"];
    return super.put({ params }, data);
  }
}
