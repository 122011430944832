export default {
  SearchErrors: {
    default:
      "We are having trouble fetching data. If a feature is not working, try refreshing the page.",
  },
  SearchWorkflow: {
    Advanced: {
      a: "Select the time range within which you would like to run your report. The default is to see reports for the last two hours.",
      b: "Here you can select the specific log types and columns you would like to see",
      c: "If you would like the report to return a list of all unique rows in your criteria, with a count for each unique row combination, select 'On' here. Note that timestamps will not be included in the aggregation.",
      d: "For every available column within the data types you've selected, you can apply a filter, so that the results only include rows with values matching your selections here.",
      e: "If you want to be able to retrieve the same report configuration to use again in the future, without having to share the URL, give it a friendly name.  This will be visible to other members of your organization's team, who can also run your query.  You'll need to do this if you want to be able to schedule your query to automatically be run periodically.",
    },
    Step1: {
      a: "First, tell us about the category of data you are interested in.  You can start typing to find suggestions, or you can select from the drop-down.  Only categories for which your organization has sent logs to Blumira will appear in the list.",
      header: "What kind of data are you interested in?",
      subHeader: "Or load a saved report",
    },
    Step2: {
      a: "Next, within the category of data you selected, let's pick the specific log types and columns you would like to see. We have pre-selected a handful of columns that are most-often useful, but you may select additional columns to include in the results by marking the check mark next to each. Some columns display a description with additional information when you hover over them. Note: if you change categories in the drop-down at this stage, any column selections you made will be lost.",
      header: "Let's get specific",
    },
    Step3: {
      a: "Within the log types you've selected, you can optionally narrow down your report using the settings below.  At the bottom, if you specify a name for this report, the types, columns, and any filters you selected here will be saved for future re-use in your team's account or for scheduling.",
      b: "Select the time range within which you would like to run your report. The default is to see reports for the last two hours.",
      c: "If you would like the report to return a list of all unique rows in your criteria, with a count for each unique row combination, select 'On' here. Note that timestamps will not be included in the aggregation.",
      d: "For every available column within the data types you've selected, you can apply a filter, so that the results only include rows with values matching your selections here.",
      e: "If you want to be able to retrieve the same report configuration to use again in the future, without having to share the URL, give it a friendly name.  This will be visible to other members of your organization's team, who can also run your query.  You'll need to do this if you want to be able to schedule your query to automatically be run periodically.",
    },
    Results: {
      a: "Here are the criteria used to run your report. Advanced users or users returning to a saved query (or by a query URL), can immediately start here.  Click “Search” again once you are ready to apply any modified criteria.",
      b: "Below are the results of your query. If you'd like to save your results locally or if there is a warning about the size of results being excessive, you can click the download button to re-run the query and save its full results to a file for download. If you would like to save or update this query, click the Save (or Update) button and give it a name for future reference",
    },
  },
};
