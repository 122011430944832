import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { DropzoneDialog } from "react-mui-dropzone";
import {
  addFindingAttachments,
  deleteFindingAttachment,
} from "../../../redux/actions/Findings";
import Request from "lib/api/Request";
import moment from "moment-timezone";
import { get } from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  Button as MuiButton,
} from "@mui/material";

const getAttachmentsFromFindingId = async (findingId) => {
  let attachmentsToReturn = [];
  try {
    const request = new Request("/finding", [
      { field: "id", value: findingId },
    ]);
    const findingArr = await request.get();
    const findingData = findingArr[0] || {};
    attachmentsToReturn = get(findingData, ["attachments"], []);
  } catch (e) {
    console.error("Error fetching attachments: ", e);
  }
  return attachmentsToReturn;
};

const FindingAttachmentsView = (props) => {
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [showConfirmAttachmentDelete, setShowConfirmAttachmentDelete] =
    useState(null);
  const [attachmentDeletesInProgress, setAttachmentDeletesInProgress] =
    useState([]);
  const [visibleAttachments, setVisibleAttachments] = useState([]);
  const [isLoadingAttachment, setIsLoadingAttachment] = useState(false);

  useEffect(() => {
    const handleAttachmentsLoadAndSet = async () => {
      setIsLoadingAttachment(true);
      const newAttachments = await getAttachmentsFromFindingId(props.findingId);
      setVisibleAttachments(newAttachments);
      setIsLoadingAttachment(false);
    };

    if (props.findingId) {
      handleAttachmentsLoadAndSet();
    }
  }, [props.findingId]);

  const closeUploader = () => {
    setUploaderOpen(false);
  };

  const uploadFiles = async (files) => {
    setUploaderOpen(false);
    setIsLoadingAttachment(true);

    // wait for upload to resolve prior to
    // displaying new attachments list
    props
      .dispatchAddFindingAttachments({
        orgId: props.orgId,
        findingId: props.findingId,
        files: files,
      })
      .then(async () => {
        const newAttachments = await getAttachmentsFromFindingId(
          props.findingId
        );
        setVisibleAttachments(newAttachments);
        setIsLoadingAttachment(false);
      })
      .catch((e) => {
        console.error("Failed to upload attachment: ", e);
        setIsLoadingAttachment(false);
      });
  };

  const toggleConfirmationDialog = () => {
    setIsConfirmationDialogOpen(!isConfirmationDialogOpen);
  };

  const handleConfirm = () => {
    setUploaderOpen(true);
    setIsConfirmationDialogOpen(false);
  };

  const startAttachmentDelete = (id) => {
    setShowConfirmAttachmentDelete(id);
  };

  const confirmPendingAttachmentDelete = async () => {
    setIsLoadingAttachment(true);
    const attachmentId = showConfirmAttachmentDelete;
    const newDeletesInProgress = Array.from(attachmentDeletesInProgress);

    newDeletesInProgress.push(attachmentId);

    setAttachmentDeletesInProgress(newDeletesInProgress);

    setShowConfirmAttachmentDelete(null);

    // wait for delete to resolve prior to
    // displaying new attachments list
    props
      .dispatchDeleteFindingAttachment({
        orgId: props.orgId,
        findingId: props.findingId,
        attachmentId: attachmentId,
      })
      .then(async () => {
        const newAttachments = await getAttachmentsFromFindingId(
          props.findingId
        );
        setVisibleAttachments(newAttachments);
        setAttachmentDeletesInProgress([]);
        setIsLoadingAttachment(false);
      })
      .catch((e) => {
        console.error("Error deleting attachment: ", e);
        setIsLoadingAttachment(false);
      });
  };

  const cancelPendingAttachmentDelete = () => {
    setShowConfirmAttachmentDelete(null);
  };

  const canCurrentUserDeleteAttachment = (attachment) => {
    if (props.currentUserId === attachment.uploaded_by) {
      // if this user uploaded the attachment, they can delete it:
      return true;
    }
    const currentUser = props.users[props.currentUserId];
    const currentUserRolesInOrg =
      currentUser && currentUser.orgRoles
        ? currentUser.orgRoles
            .filter((role) => role.orgId === props.orgId)
            .map((role) => role.roleId)
        : [];
    // if this user is an admin or manager for this org, they can delete the attachment:
    return (
      currentUserRolesInOrg.includes(10) || currentUserRolesInOrg.includes(20)
    );
    // ofc, this is enforced by FEA
  };

  // TODO(cdzombak): error showing

  const renderAttachment = (a) => {
    const date_str = moment
      .utc(a.uploaded_at)
      .tz(moment.tz.guess(true))
      .format("lll z");
    const uploader = props.users[a.uploaded_by];
    const uploader_el = uploader ? (
      <span>
        by{" "}
        <strong>
          {uploader.first_name} {uploader.last_name}
        </strong>
      </span>
    ) : null;
    return (
      <div className="attachment" key={a.id}>
        <a href={a.signed_get_url} style={{ wordBreak: "break-all" }}>
          <span className="icon" style={{ marginRight: 4 }}>
            <i className="fas fa-paperclip" />
          </span>
          {a.filename}
        </a>
        <br />
        {a.id === showConfirmAttachmentDelete ? (
          <div>
            <div
              style={{
                float: "right",
                marginLeft: 12,
                marginTop: 4,
                marginBottom: -4,
              }}
            >
              <Button
                className="btn-sm"
                bsStyle="danger"
                onClick={() => confirmPendingAttachmentDelete()}
              >
                Yes
              </Button>
              <Button
                className="btn-sm"
                bsStyle="primary"
                onClick={() => cancelPendingAttachmentDelete()}
                style={{ marginLeft: 6 }}
              >
                No
              </Button>
            </div>
            <strong>Permanently delete this attachment?</strong>
          </div>
        ) : (
          <div>
            {canCurrentUserDeleteAttachment(a) ? (
              <div
                style={{
                  float: "right",
                  marginLeft: 12,
                  marginTop: 4,
                  marginBottom: -4,
                }}
              >
                {attachmentDeletesInProgress.includes(a.id) ? (
                  <CircularProgress size={26} color="secondary" />
                ) : (
                  <Button
                    className="btn-sm"
                    bsStyle="danger"
                    onClick={() => startAttachmentDelete(a.id)}
                  >
                    <i className="fas fa-trash" />
                  </Button>
                )}
              </div>
            ) : null}
            Added {date_str} {uploader_el}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="infoTitle">
        Attachments
        <MuiButton
          style={{ float: "right" }}
          onClick={toggleConfirmationDialog}
          color={"primary"}
          variant={"contained"}
        >
          Upload
        </MuiButton>
      </div>
      {isLoadingAttachment ? (
        <p>Loading your attachments...</p>
      ) : visibleAttachments.length ? (
        visibleAttachments.map((a) => renderAttachment(a))
      ) : (
        <p>No attachments.</p>
      )}
      <Dialog open={isConfirmationDialogOpen}>
        <DialogTitle>Acknowledgement of Sensitive Material</DialogTitle>
        <DialogContent>
          <p>
            Please refrain from uploading any sensitive, confidential or
            privileged assets to your finding.
          </p>
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={toggleConfirmationDialog}
            color={"primary"}
            variant={"text"}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={handleConfirm}
            color={"primary"}
            variant={"contained"}
          >
            Acknowledge
          </MuiButton>
        </DialogActions>
      </Dialog>
      <DropzoneDialog
        open={uploaderOpen}
        onSave={uploadFiles}
        onClose={closeUploader}
        dropzoneText="Drag files here, or click to open a file browser."
        dialogTitle="Attach Files"
        filesLimit={5}
        maxFileSize={104857600}
        submitButtonText="Upload"
        showPreviews={true}
        showFileNames={true}
        showFileNamesInPreview={true}
        showAlerts={["error"]}
      />
    </div>
  );
};

FindingAttachmentsView.propTypes = {
  dispatchAddFindingAttachments: PropTypes.func.isRequired,
  dispatchDeleteFindingAttachment: PropTypes.func.isRequired,
  attachments: PropTypes.array.isRequired,
  currentUserId: PropTypes.string.isRequired,
  findingId: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
};

FindingAttachmentsView.defaultProps = {
  attachments: [],
  currentUserId: "",
  findingId: "",
  orgId: "",
  users: {},
};

const mapStateToProps = (state) => {
  return {
    currentUserId: state.session.settings.user.id,
    users: state.users.byId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAddFindingAttachments: ({ orgId, findingId, files }) => {
    return dispatch(addFindingAttachments({ orgId, findingId, files }));
  },
  dispatchDeleteFindingAttachment: ({ orgId, findingId, attachmentId }) => {
    return dispatch(
      deleteFindingAttachment({ orgId, findingId, attachmentId })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindingAttachmentsView);
