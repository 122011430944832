import React from "react";
import PropTypes from "prop-types";

import BluGraphContainer from "./common/BluGraphContainer";

import IngressTrafficGraph from "./IngressTrafficGraph";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "ingressTraffic";

export const GRAPH_TITLE =
  "Total Accepted Ingress Traffic by Source Country (logarithmic scale)";

/*
Main component
*/

const IngressTraffic = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={IngressTrafficGraph}
    labelField="country"
    logScale
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
    valueField="totalBytes"
  />
);

IngressTraffic.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

IngressTraffic.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default IngressTraffic;
