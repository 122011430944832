import React from "react";
import PropTypes from "prop-types";

import DevicesBarGraph from "./DevicesBarGraph";
import BluGraphContainer from "./common/BluGraphContainer";

import "./common/BluGraphContainer.scss";

/*
Constants
*/

const REDUX_NAME = "loggingDevices";
export const GRAPH_TITLE = "Top 10 Devices by Log Count";

/*
Main component
*/

const Devices = ({ showTitle = false, showSubtitle = true }) => (
  <BluGraphContainer
    defaultTitle={GRAPH_TITLE}
    Graph={DevicesBarGraph}
    labelField="name"
    labelField2="deviceAddress"
    reduxName={REDUX_NAME}
    showSubtitle={showSubtitle}
    showTitle={showTitle}
  />
);

Devices.propTypes = {
  showSubtitle: PropTypes.bool,
  showTitle: PropTypes.bool,
};

Devices.defaultProps = {
  showSubtitle: true,
  showTitle: false,
};

export default Devices;
