import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { DEFAULT_TIME_INTERVAL } from "./common/securityConfig";
import { dataForOrgAndTime } from "./common/securityHelpers";

import SecurityFunnel, { getData } from "./SecurityFunnel";

/*
Main component
*/
const SecurityOverview = ({
  logsTotal,
  blockedEvents,
  findings,
  threatsAndSuspects,
  showTitle = false,
}) => {
  const data = getData({
    logsTotal,
    blockedEvents,
    findings,
    threatsAndSuspects,
  });

  return (
    <div className="security-graph-container" datacy={"securityOverviewGraph"}>
      {showTitle && (
        <div className="security-graph-container-header">
          State of Security Overview
        </div>
      )}

      <SecurityFunnel data={data} />
    </div>
  );
};

SecurityOverview.propTypes = {
  logsTotal: PropTypes.string.isRequired,
  blockedEvents: PropTypes.string.isRequired,
  findings: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
  threatsAndSuspects: PropTypes.string.isRequired,
};

SecurityOverview.defaultProps = {
  showTitle: false,
};

const mapStateToProps = (state) => {
  const { location = {}, security = {} } = state;
  const { payload = {}, query = {} } = location;
  const { orgId = "" } = payload;

  const { timeInterval = DEFAULT_TIME_INTERVAL } = query;

  const {
    blockedEvents: blockedData = {},
    logsTotal: logsData = {},
    findings: findingsData = {},
    threatsAndSuspects: threatsData = {},
  } = security;
  const blockedEvents = dataForOrgAndTime({
    data: blockedData,
    orgId,
    timeInterval,
    defaultValue: "",
  });
  const logsTotal = dataForOrgAndTime({
    data: logsData,
    orgId,
    timeInterval,
    defaultValue: "",
  });
  const findings = dataForOrgAndTime({
    data: findingsData,
    orgId,
    timeInterval,
    defaultValue: "",
  });

  const threatsAndSuspects = dataForOrgAndTime({
    data: threatsData,
    orgId,
    timeInterval,
    defaultValue: "",
  });

  return {
    blockedEvents,
    logsTotal,
    findings,
    threatsAndSuspects,
  };
};

export default connect(mapStateToProps, null)(SecurityOverview);
