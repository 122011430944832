// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Bold text ***********************
export const OptionBoldTextStyle = styled.span`
  color: ${COLOR.blue1000};
  font-weight: 600;
`;

// ** Option container ****************
export const OptionContainerStyle = styled.button`
  background-color: transparent;
  border: none;
  color: ${COLOR.gray600};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  overflow: hidden;
  padding: 8px 16px;
  text-align: left;
  text-overflow: ellipsis;
  transition: all 0.3s;
  white-space: nowrap;
  width: 100%;

  &:hover {
    background-color: ${COLOR.gray000};
    color: ${COLOR.blue800};
  }

  &:hover ${OptionBoldTextStyle} {
    color: ${COLOR.blue800};
  }
`;

// ** Select results container ********
export const SelectAbsoluteContainer = styled.div`
  bottom: ${(p) => p.bottom};
  left: 0px;
  position: absolute;
  top: ${(p) => p.top};
  width: 100%;
  z-index: 10;
`;

// ** Empty state text ****************
export const SelectEmptyStateText = styled.span`
  color: ${COLOR.gray400};
  cursor: not-allowed;
  display: block;
  font-size: 15px;
  font-weight: 600;
  padding: 20px 15px;
  text-align: center;
`;

// ** Select results container ********
export const SelectResultsContainer = styled.div`
  background-color: ${COLOR.white};
  border: ${(p) =>
    p.focused ? `1px solid rgba(105, 180, 255, 0.45)` : "none"};
  box-shadow: ${(p) =>
    p.focused ? "0 7px 22px 0 rgba(5, 18, 33, 0.07)" : "none"};
  height: ${(p) => (p.focused ? "auto" : "0px")};
  max-height: ${(p) => p.maxHeight || "200px"};
  overflow: hidden;
  padding: ${(p) => (p.focused ? `${p.padding}` : "0px")};
  position: relative;
  top: 100%;
  width: 100%;
`;

// ** Select container ****************
export const SelectContainerStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

// ** Input error *********************
export const SelectErrorStyle = styled.span`
  color: ${COLOR.red1000};
  display: block;
  font-size: 12px;
  font-weight: 500;
  height: ${(p) => (p.error ? "20px" : "0px")};
  line-height: 20px;
  margin-left: 2px;
  overflow: hidden;
  transition: all 0.3s;
`;

// ** Icon absolute container *********
export const SelectIconContainer = styled.div`
  fill: ${COLOR.blumiraBlue800};
  transform: translateY(-55%);
  opacity: 0.65;
  pointer-events: none;
  position: absolute;
  right: 10px;
  top: 50%;
`;

// ** Selected options item ***********
export const SelectItem = styled.ul`
  align-items: center;
  background-color: ${COLOR.blue100};
  border-radius: 3px;
  color: ${COLOR.blue1000};
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 400;
  justify-content: flex-start;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 2px 6px;
  transition: all 300ms;

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
`;

// ** Select item icon container ******
export const SelectItemIconContainer = styled.div`
  border-left: 1px solid ${COLOR.blue200};
  margin-left: 6px;
  padding-left: 6px;
`;

// ** Select icon icon ****************
export const SelectItemIcon = styled.div`
  fill: ${COLOR.blue1000};
  margin-top: -2px;
`;

// ** Selected options list ***********
export const SelectList = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 16px;
  list-style-type: none;
  width: 95%;
`;

// ** Option container ****************
export const SelectOptionContainer = styled.div`
  border-bottom: 1px solid ${COLOR.gray800};
  margin-bottom: 10px;
  padding: 10px 0px;

  &:last-child {
    border-bottom: none;
  }
`;

// ** Relative container **************
export const SelectRelativeContainer = styled.div`
  border-radius: 3px;
  cursor: pointer;
  padding: ${(p) =>
    p.multiple && p.value.length === 0
      ? "8px 12px"
      : p.multiple && p.value.length > 0
      ? "8px 12px 0px"
      : "0px"};
  position: relative;
  transition: all 0.3s;
  width: 100%;

  @media (min-width: 768px) {
    &:hover {
      border: ${(p) =>
        p.disabled
          ? `1px solid ${COLOR.gray100}`
          : `1px solid ${COLOR.blue200}`};
    }
  }
`;

// ** Relative container **************
export const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
`;

// ** Input label *********************
export const SelectLabelStyle = styled.span`
  color: ${COLOR.black};
  display: block;
  font-size: 16px;
  margin-bottom: 10px;
  position: relative;
`;
