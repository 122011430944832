const blocklistEntrySchema = {
  $id: "/blocklistentry",
  title: "Block List Entry",
  type: "object",
  properties: {
    age: { type: "number", readOnly: true },
    automated: { type: "boolean", readOnly: true },
    blockType: { type: "number", createOnly: true },
    community: { type: "number", readOnly: true },
    created: { type: "string", readOnly: true },
    createdBy: { type: "string", readOnly: true },
    description: { type: "string", maxLength: 255 },
    expirationDate: { type: "string", readOnly: true },
    findingId: { type: "string", readOnly: true },
    id: { type: "number", readOnly: true },
    note: { type: "string", maxLength: 255 },
    negating: { type: "boolean" },
    orgId: { type: "string", createOnly: true },
    target: { type: "string", createOnly: true, maxLength: 255 },
    targetInet: { type: "string", createOnly: true },
    until: { type: "number" },
  },
};

export default blocklistEntrySchema;
