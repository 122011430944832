import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import DevicesSelect from "./DevicesSelect";
import DevicesTable from "./DevicesTable";
import { DeviceAddress, DeviceName, DeviceLogTotal } from "./DevicesFields";
import {
  DEFAULT_TIME_INTERVAL,
  DEVICE_TYPES_TO_EXCLUDE,
  TIME_INTERVAL_OPTIONS,
} from "./common/constants";
import { getLabel } from "./common/helpers";
import TimeRange from "../../Components/TimeRange";
import { fetchDevices } from "../../../redux/actions/Sensors";
import { pageWithQuery } from "../../../redux/actions/Page";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  sensorDevicesSelector,
  sensorIdSelector,
  sensorTimeWindowSelector,
} from "../../../selectors/sensorsSelectors";
import { sensorTimeIntervalSelector as timeIntervalSelector } from "../../../selectors/timeIntervalSelectors";

import "./Devices.scss";

/*
Helpers
*/

const filterDevices = (devices) => {
  const filtered = devices.filter(
    (elt) => DEVICE_TYPES_TO_EXCLUDE.find((t) => t === elt.type) === undefined
  );

  return filtered;
};

// Takes as argument the 'devices' from the Sensor Store, and returns
// the Devices Table rows.
const getDevices = (filtered) => {
  const displayDevices = filtered.map((device) => ({
    address: <DeviceAddress deviceAddress={device.deviceAddress} />,
    addressAccessor: device.deviceAddress,
    name: <DeviceName name={device.name} />,
    nameAccessor: device.name,
    type: device.type,
    total: <DeviceLogTotal count={(device.count || 0).toLocaleString()} />,
    totalAccessor: device.count,
  }));

  return displayDevices;
};

/*
Component
*/

const Devices = ({
  devices,
  dispatchFetchDevices,
  dispatchPageWithQuery,
  orgId,
  sensorId,
  timeInterval,
  windowEnd,
  windowStart,
}) => {
  const filteredDevices = filterDevices(devices);
  const displayDevices = getDevices(filteredDevices);

  // Compute the number of devices in the table
  const deviceCount = (devices || []).length;

  // Compute the number of log events in the table
  const logsTotal = (devices || []).reduce((accum, elt) => {
    accum += elt.count || 0;

    return accum;
  }, 0);

  // Currently selected time interval
  const [selectedValue, setSelectedValue] = useState(
    timeInterval || DEFAULT_TIME_INTERVAL
  );

  useEffect(() => {
    setSelectedValue(timeInterval || DEFAULT_TIME_INTERVAL);
  }, [timeInterval]);

  // Fetch the data
  useEffect(() => {
    dispatchFetchDevices({
      orgId,
      relativeStart: selectedValue,
      sensorId,
    });
  }, [selectedValue, dispatchFetchDevices, orgId, sensorId]);

  // Handle the time interval selection
  const handleSelect = (newValue) => {
    setSelectedValue(newValue);
    dispatchPageWithQuery(newValue);
  };

  return (
    <div className="sensor-devices-container">
      <div className="devices-summary-container">
        <div className="devices-summary-item">
          <TimeRange
            dateStart={windowStart}
            dateEnd={windowEnd}
            label={`last ${getLabel(selectedValue)}`}
          />
        </div>

        <div className="devices-summary-item">
          Total devices logging:
          <span className="bold">{` ${deviceCount.toLocaleString()}`}</span>
        </div>

        <div className="devices-summary-item">
          Total log events:
          <span className="bold">{` ${logsTotal.toLocaleString()}`}</span>
        </div>

        <div className="devices-summary-item">
          <DevicesSelect
            options={TIME_INTERVAL_OPTIONS}
            defaultValue={selectedValue}
            onSelect={handleSelect}
          />
        </div>
      </div>

      <div className="table-container" style={{ zIndex: 0 }}>
        <DevicesTable data={displayDevices} />
      </div>
    </div>
  );
};

Devices.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  dispatchFetchDevices: PropTypes.func.isRequired,
  dispatchPageWithQuery: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  sensorId: PropTypes.string,
  timeInterval: PropTypes.string,
  windowEnd: PropTypes.string,
  windowStart: PropTypes.string,
};

Devices.defaultProps = {
  devices: [],
  orgId: "",
  sensorId: "",
  timeInterval: DEFAULT_TIME_INTERVAL,
  windowEnd: "",
  windowStart: "",
};

const mapStateToProps = (state) => {
  const sensorId = sensorIdSelector(state);
  const orgId = orgIdSelector(state);
  const devices = sensorDevicesSelector(state);
  const timeInterval = timeIntervalSelector(state);
  const timeWindow = sensorTimeWindowSelector(state);

  const { windowEnd = "", windowStart = "" } = timeWindow;

  return {
    devices,
    orgId,
    sensorId,
    timeInterval,
    windowEnd,
    windowStart,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchFetchDevices: ({ orgId, relativeStart, sensorId }) => {
    dispatch(
      fetchDevices({
        orgId,
        relativeStart,
        sensorId,
      })
    );
  },
  dispatchPageWithQuery: (timeInterval) => {
    dispatch(pageWithQuery({ timeInterval }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
