import React from "react";
import PropTypes from "prop-types";

import DataGrid from "../../Components/DataGrid";
import Loading from "../../Components/Loading";
import EmptyVisualization from "../../Components/EmptyVisualization";

const TableWrapper = ({ data, compactStyle }) => (
  <DataGrid
    data={data || []}
    columnHeaders="activeFindingsHeaders"
    columns={[
      {
        accessor: "status",
      },
      {
        accessor: "threats",
      },
      {
        accessor: "suspects",
      },
    ]}
    defaultPageSize={6}
    showPagination={false}
    showPageSizeOptions={false}
    showPageJump={false}
    resizeable={false}
    compactStyle={compactStyle}
  />
);

TableWrapper.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  compactStyle: PropTypes.bool.isRequired,
};

const ActiveFindingsTable = ({ tableData, compactStyle, handleRetry }) => {
  const tableIsLoading = tableData.loading || !tableData.firstLoadComplete;
  const tableIsEmpty = !tableData.loading && tableData.dataIsEmpty;
  const tableHasError = !!tableData.error;

  return (
    <React.Fragment>
      {tableIsLoading && <Loading />}

      {tableIsEmpty && (
        <EmptyVisualization>
          <TableWrapper
            data={tableData.visualizationData}
            compactStyle={compactStyle}
          />
        </EmptyVisualization>
      )}

      {(tableIsEmpty || tableHasError) && (
        <div>
          <EmptyVisualization isError={tableHasError} handleRetry={handleRetry}>
            <TableWrapper
              data={tableData.visualizationData}
              compactStyle={compactStyle}
            />
          </EmptyVisualization>
        </div>
      )}

      {!tableIsLoading && !tableIsEmpty && !tableHasError && (
        <TableWrapper
          data={tableData.visualizationData}
          compactStyle={compactStyle}
        />
      )}
    </React.Fragment>
  );
};

ActiveFindingsTable.propTypes = {
  compactStyle: PropTypes.bool,
  tableData: PropTypes.shape({}).isRequired,
  handleRetry: PropTypes.func.isRequired,
};

ActiveFindingsTable.defaultProps = {
  compactStyle: false,
};

export default ActiveFindingsTable;
