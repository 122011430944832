import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";

const CHECKBOX_COLUMN_NAMES = ["voice", "text", "email"];

/*
Helpers
*/

// Capitalizes the first chracter
const formatHeader = (text) => text.charAt(0).toUpperCase() + text.slice(1);

// The checkbox component for the table cells
export const CheckBox = ({ disabled, checked, handleClick }) => (
  <span
    className="notification-option"
    role="button"
    tabIndex={-1}
    onClick={disabled ? () => {} : handleClick}
    disabled={disabled}
  >
    <input
      type="checkbox"
      className="checkbox"
      checked={checked}
      disabled={disabled}
      readOnly
    />
  </span>
);

CheckBox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

CheckBox.defaultProps = {
  disabled: false,
  checked: false,
};

/*
Component
*/
//
const MyAlertsTable = (props) => {
  const { selected, updateSelections, tableRows, numberOfRows } = props;

  // Cell's onClick handler
  const toggleCell = (cellRef) => {
    const newSelected = Object.assign({}, selected);
    newSelected[cellRef] = !selected[cellRef];

    updateSelections(newSelected);
  };

  // Creates column definitions from CHECKBOX_COLUMN_NAMES
  const makeCheckboxColumns = () => {
    const columns = CHECKBOX_COLUMN_NAMES.map((columnName) => {
      const disabled = !props[columnName];

      const checked = (cellRef) => selected[cellRef] === true;
      const handleClick = (cellRef) => () => toggleCell(cellRef);

      return {
        Header: formatHeader(columnName),
        sortable: false,
        minWidth: 40,
        Cell: ({ original }) => (
          <CheckBox
            disabled={
              original["priority"] ===
                "Maximum Deployable Agents exceeded (MSP)" &&
              columnName !== "email"
                ? true
                : disabled
            }
            checked={checked(original[columnName])}
            handleClick={handleClick(original[columnName])}
          />
        ),
      };
    });

    return columns;
  };

  const checkboxColumns = makeCheckboxColumns();
  const tableColumns = [
    {
      Header: "",
      accessor: "priority",
      minWidth: 50,
    },
    ...checkboxColumns,
  ];

  return (
    <ReactTable
      data={tableRows}
      columns={tableColumns}
      showPagination={false}
      defaultPageSize={numberOfRows}
      className="-striped -highlight my-alerts-table"
      textAlign="center"
    />
  );
};

MyAlertsTable.propTypes = {
  // The table rows.
  // The column names must match CHECKBOX_COLUMN_NAMES + 'priority'
  tableRows: PropTypes.arrayOf(
    PropTypes.shape({
      priority: PropTypes.string,
      voice: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })
  ),
  // The number of rows
  numberOfRows: PropTypes.number.isRequired,
  // The checked/not-checked flags
  selected: PropTypes.shape({}).isRequired,
  // The function to update checked/not-checked flags on the parent
  updateSelections: PropTypes.func.isRequired,
  // Booleans showing if phone numbers/email are set.
  // Used to enable/disable columns.
  text: PropTypes.bool,
  voice: PropTypes.bool,
  email: PropTypes.bool,
};

MyAlertsTable.defaultProps = {
  tableRows: [],
  text: false,
  voice: false,
  email: false,
};

export default MyAlertsTable;
