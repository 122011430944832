import BlockingReducer from "./BlockingReducer";
import DataQueryReducer from "./DataQueryReducer";
import FindingsReducer from "./FindingsReducer";
import GlobalReducer from "./GlobalReducer";
import HostReducer from "./HostReducer";
import IpReducer from "./IpReducer";
import LocationsReducer from "./LocationsReducer";
import LogdevsReducer from "./LogdevsReducer";
import MessageReducer from "./MessageReducer";
import OrgReducer from "./OrgReducer";
import PageReducer from "./PageReducer";
import PipelineReducer from "./PipelineReducer";
import QueryReducer from "./QueryReducer";
import SecurityReducer from "./SecurityReducer";
import * as Search from "./SearchReducer";
import SensorsStoreReducer from "./SensorsStoreReducer";
import SettingsReducer from "./SettingsReducer";
import SubnetReducer from "./SubnetReducer";
import TaggingReducer from "./TaggingReducer";
import UserReducer from "./UsersReducer";
import UserInterfaceReducer from "./UserInterfaceReducer";
import SessionReducer from "./SessionReducer";
import RequestReducer from "./RequestReducer";
import LicenseReducer from "./LicenseReducer";

const reducers = {
  allColumns: Search.allColumns,
  allLogTypes: Search.allLogTypes,
  blocking: BlockingReducer,
  dataQuery: DataQueryReducer,
  findings: FindingsReducer,
  global: GlobalReducer,
  host: HostReducer,
  ip: IpReducer,
  locations: LocationsReducer,
  logdevs: LogdevsReducer,
  message: MessageReducer,
  org: OrgReducer,
  page: PageReducer,
  pipeline: PipelineReducer,
  query: QueryReducer,
  security: SecurityReducer,
  searchCategories: Search.searchCategories,
  searchColumns: Search.searchColumns,
  searchOperators: Search.searchOperators,
  sensors: SensorsStoreReducer,
  settings: SettingsReducer,
  subnets: SubnetReducer,
  tagging: TaggingReducer,
  users: UserReducer,
  userInterface: UserInterfaceReducer,
  session: SessionReducer,
  request: RequestReducer,
  license: LicenseReducer,
};

export default reducers;
