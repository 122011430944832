import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import _ from "lodash";

class ReactSelectField extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFormatOptionLabel = this.handleFormatOptionLabel.bind(this);
    this.handleFormatGroupLabel = this.handleFormatGroupLabel.bind(this);
  }

  handleChange(name, value) {
    this.props.setFieldValue(name, value);
    if (_.isFunction(this.props.onChange)) {
      this.props.onChange(value);
    }
  }

  handleBlur(name) {
    this.props.setFieldTouched(name);
    if (_.isFunction(this.props.onBlur)) {
      this.props.onBlur(true);
    }
  }

  handleFormatOptionLabel(value) {
    let innards = null;
    const { label = null } = value;

    if (_.isFunction(this.props.handleFormatOptionLabel)) {
      innards = this.props.handleFormatOptionLabel(value);
    } else {
      innards = <span>{label}</span>;
    }
    return <div className="react-select-option">{innards}</div>;
  }

  handleFormatGroupLabel(value) {
    let innards = null;
    const { label = null } = value;

    if (_.isFunction(this.props.handleFormatGroupLabel)) {
      innards = this.props.handleFormatGroupLabel(value);
    } else {
      innards = <span>{label}</span>;
    }
    return <div className="react-select-group">{innards}</div>;
  }

  render() {
    const {
      name,
      label,
      required,
      placeholder,
      disabled,
      defaultValue,
      classes,
      options = [],
      values = {},
      errors = {},
      touched = {},
    } = this.props;

    const value = values[name];
    const errorMessage = errors[name];
    const hasError = !!errorMessage;
    const isTouched = touched[name];
    const uniqueId = _.uniqueId();

    return (
      <div
        className={`field select-field ${
          hasError && isTouched ? "error" : ""
        } ${classes}`}
      >
        {label && (
          <label
            htmlFor={`${name}__${uniqueId}`}
            className="control-label"
            title={errorMessage}
          >
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
        )}
        <Select
          className={classes || "control-item react-select"}
          options={options}
          onChange={(newValue) => this.handleChange(name, newValue)}
          onBlur={() => this.handleBlur(name)}
          value={value}
          defaultValue={defaultValue}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          formatOptionLabel={this.handleFormatOptionLabel}
          formatGroupLabel={this.handleFormatGroupLabel}
        />
        {hasError && isTouched && (
          <span className="error-message">{errorMessage}</span>
        )}
      </div>
    );
  }
}

ReactSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.shape({}),
  values: PropTypes.shape({}),
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  options: PropTypes.instanceOf(Array),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleFormatOptionLabel: PropTypes.func,
  handleFormatGroupLabel: PropTypes.func,
  classes: PropTypes.string,
};

ReactSelectField.defaultProps = {
  label: "",
  required: false,
  placeholder: "",
  defaultValue: null,
  values: {},
  disabled: false,
  errors: {},
  touched: {},
  options: [],
  classes: "",
  onBlur: () => {},
  onChange: () => {},
  handleFormatOptionLabel: null,
  handleFormatGroupLabel: null,
};

export default ReactSelectField;
