// *****************************************************************************
// Redux store contants
// *****************************************************************************

export const ActionTypes = {
  Search: {
    fetchAllColumnsFail: "FETCH_ALL_COLUMNS_FAILURE",
    fetchAllColumnsSuccess: "FETCH_ALL_COLUMNS_SUCCESS",

    fetchCategoriesFail: "FETCH_CATEGORIES_FAILURE",
    fetchCategoriesSuccess: "FETCH_CATEGORIES_SUCCESS",

    fetchColumnsAndTypesFail: "FETCH_COLUMNS_AND_TYPES_FAILURE",
    fetchColumnsAndTypesSuccess: "FETCH_COLUMNS_AND_TYPES_SUCCESS",

    fetchFilterOperatorsFail: "FETCH_FILTER_OPERATORS_FAILURE",
    fetchFilterOperatorsSuccess: "FETCH_FILTER_OPERATORS_SUCCESS",

    fetchLogTypesFail: "FETCH_LOG_TYPE_FAILURE",
    fetchLogTypesSuccess: "FETCH_LOG_TYPE_SUCCESS",
  },
};
