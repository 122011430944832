import React from "react";
import PropTypes from "prop-types";

import { getMetaItemDisplayName, getMetaItem } from "./metaHelpers";

/*
Constants
*/

// Meta item not set message
const META_NOT_SET_STR = "No displayable value";

/*
Components
*/

/*
Module status
*/
export const ModuleStatus = ({ status }) => {
  let dotClasses = "";
  let text = "";

  switch (status) {
    // Green
    case 10: {
      dotClasses = "fas fa-circle status-dot-green";
      text = "OK";
      break;
    }
    // Yellow
    case 20: {
      dotClasses = "fas fa-circle status-dot-yellow";
      text = "Warning";
      break;
    }
    // Red
    case 30: {
      dotClasses = "fas fa-circle status-dot-red";
      text = "Errors";
      break;
    }
    // Gray
    default:
      dotClasses = "fas fa-circle status-dot-gray";
      text = "Unknown";
  }

  return (
    <div className="modules-status">
      <div className={dotClasses} />
      <div className="status-text">{text}</div>
    </div>
  );
};

ModuleStatus.propTypes = {
  status: PropTypes.number,
};

ModuleStatus.defaultProps = {
  status: null,
};

/*
Ports
*/
export const ModulePorts = ({ module }) => {
  const config = module.config || {};
  const ports = config.ports || [];

  const listItems = (ports || []).map((port, index) => {
    const { containerPort, protocol, description } = port;

    return (
      <li key={index.toString()}>
        {`${containerPort}/ ${(protocol || "").toUpperCase()} ${description}`}
      </li>
    );
  });

  const portsList = <ul className="modules-ul modules-cell">{listItems}</ul>;

  return portsList;
};

ModulePorts.propTypes = {
  module: PropTypes.shape({}),
};

ModulePorts.defaultProps = {
  module: {},
};

/*
Module Config
*/
export const ModuleConfig = ({ module }) => {
  const spec = module.spec || {};
  const meta = spec.meta || [];

  const listItems = meta.map((item, index) => {
    const { value: metaValue } = getMetaItem({ sensorModule: module, item });

    const label = getMetaItemDisplayName(item);

    return (
      <li key={index.toString()}>
        <div className="modules-cell-bold">{`${label}:`}</div>

        <div>{metaValue || META_NOT_SET_STR}</div>
      </li>
    );
  });

  return <ul className="modules-ul modules-cell">{listItems}</ul>;
};

ModuleConfig.propTypes = {
  module: PropTypes.shape({}),
};

ModuleConfig.defaultProps = {
  module: {},
};

/*
Module name
*/
export const ModuleName = ({ name }) => {
  const nameHtml = <div className="modules-cell-bold modules-cell">{name}</div>;

  return nameHtml;
};

ModuleName.propTypes = {
  name: PropTypes.string,
};

ModuleName.defaultProps = {
  name: "",
};

/*
Module version number
*/
export const ModuleVersion = ({ tag }) => {
  const version = <div className="modules-version modules-cell">{tag}</div>;

  return version;
};

ModuleVersion.propTypes = {
  tag: PropTypes.string,
};

ModuleVersion.defaultProps = {
  tag: "",
};
