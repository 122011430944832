import React from "react";
import PropTypes from "prop-types";

import SearchInput from "../SearchInput";

import "./TableSearch.scss";

/*
Constants and helpers
*/

const DEFAULT_PLACEHOLDER = "Search all columns";
const DEFAULT_PREFIX = "";

/*
Component
*/

const TableSearch = (props) => {
  const { placeholder, prefix, submit } = props;

  return (
    <div className="resources-search">
      <div className="resources-search-left">
        <div className="resources-search-prefix">{prefix}</div>
      </div>

      <div className="resources-search-right">
        <SearchInput placeholder={placeholder} submit={submit} submitOnTheFly />
      </div>
    </div>
  );
};

TableSearch.propTypes = {
  placeholder: PropTypes.string,
  prefix: PropTypes.string,
  submit: PropTypes.func,
};

TableSearch.defaultProps = {
  placeholder: DEFAULT_PLACEHOLDER,
  prefix: DEFAULT_PREFIX,
  submit: () => {},
};

export default TableSearch;
