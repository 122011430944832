import { styled } from "@mui/material/styles";

const PREFIX = "ConfigurationForm";
export const classes = {
  button: `${PREFIX}-button`,
  divider: `${PREFIX}-divider`,
  radio: `${PREFIX}-radio`,
  field: `${PREFIX}-field`,
  secretsTitle: `${PREFIX}-secretsTitle`,
  secretsText: `${PREFIX}-secretsText`,
  checkboxHelperText: `${PREFIX}-checkboxHelperText`,
};

export const Root = styled("form")(({ theme }) => ({
  [`& .${classes.button}`]: {
    margin: theme.spacing(0, 0, 0, 1),
  },
  [`& .${classes.divider}`]: {
    margin: theme.spacing(1, 0),
  },
  [`& .${classes.radio}`]: {
    marginBottom: theme.spacing(0),
  },
  [`& .${classes.field}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.secretsTitle}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.secretsText}`]: {
    fontSize: ".85rem",
    marginBottom: theme.spacing(1),
  },
  [`& .${classes.checkboxHelperText}`]: {
    marginTop: -15,
    fontSize: ".85rem",
    fontWeight: "bold",
    color: theme.palette.error.main,
  },
}));
