import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import DataGrid from "../../Components/DataGrid";
import Loading from "../../Components/Loading";
import EmptyVisualization from "../../Components/EmptyVisualization";

const TableWrapper = ({ data, onClickRow }) => (
  <DataGrid
    data={data || []}
    columnHeaders="eventHeaders"
    getTdProps={(state, rowInfo) => ({
      onClick: (e, handleOriginal) => {
        onClickRow(rowInfo.original);
        if (handleOriginal) {
          handleOriginal();
        }
      },
      style: {
        cursor: "pointer",
      },
    })}
    columns={[
      {
        accessor: "created",
        Cell: (p) => moment(p.value).format("MM/DD/YYYY"),
        maxWidth: 110,
      },
      {
        accessor: "name",
      },
      {
        accessor: "priority",
        maxWidth: 90,
      },
      {
        accessor: "status",
        maxWidth: 100,
      },
    ]}
    showPagination={false}
  />
);

TableWrapper.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
  onClickRow: PropTypes.func.isRequired,
};

const StatesTable = ({ tableData, onClickRow, handleRetry }) => {
  const tableIsLoading = tableData.loading || !tableData.firstLoadComplete;
  const tableIsEmpty = !tableData.loading && tableData.dataIsEmpty;
  const tableHasError = !!tableData.error;

  return (
    <React.Fragment>
      {tableIsLoading && <Loading />}

      {(tableIsEmpty || tableHasError) && (
        <div>
          <EmptyVisualization isError={tableHasError} handleRetry={handleRetry}>
            <TableWrapper
              data={tableData.visualizationData}
              onClickRow={onClickRow}
            />
          </EmptyVisualization>
        </div>
      )}

      {!tableIsLoading && !tableIsEmpty && !tableHasError && (
        <TableWrapper
          data={tableData.visualizationData}
          onClickRow={onClickRow}
        />
      )}
    </React.Fragment>
  );
};

StatesTable.propTypes = {
  tableData: PropTypes.shape({}).isRequired,
  onClickRow: PropTypes.func.isRequired,
  handleRetry: PropTypes.func.isRequired,
};

export default StatesTable;
