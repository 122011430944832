import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import "./BluContentEditable.scss";

/*
Helpers
*/

const makeId = () => Math.random().toString().slice(2);

const validate =
  ({ regex, setValid }) =>
  (newValue) => {
    let isValid = true;

    if (regex) {
      const re = new RegExp(regex);
      isValid = re.test(newValue);
    }

    setValid(isValid);

    return isValid;
  };

/*
Main component
*/

export const makeContentEditable = (InnerComponent) => {
  const Editable = ({
    // inner components styles
    innerClassName,
    // button colors
    btnColorCancel = "clear",
    btnColorEdit = "clear",
    btnColorSave = "clear",
    // behavior
    cancelOnEscape = true,
    disableOnSave = true,
    editOnClick = true,
    saveOnBlur = true,
    saveOnEnter = true,
    // value and validation
    regex,
    value,
    // saving
    save = () => {},
    errorFlag = false,
    dataCy,
  }) => {
    const innerElt = useRef();

    const [btnIdCancel] = useState(makeId());
    const [btnIdSave] = useState(makeId());

    const [currentInput, setCurrentInput] = useState(value);
    const [editing, setEditing] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [valid, setValid] = useState(true);

    // Reset on value and errorFlag changes
    useEffect(() => {
      innerElt.current.textContent = value;
      setCurrentInput(value);
      setDisabled(false);
    }, [value, errorFlag]);

    // Focus on edit
    useEffect(() => {
      if (editing) {
        innerElt.current.focus();
      }
    }, [editing]);

    // Reset invalid state on edit
    useEffect(() => {
      setValid(true);
    }, [editing]);

    // Validate on currentInput change
    useEffect(() => {
      validate({ regex, setValid })(currentInput);
    }, [currentInput, regex]);

    const closeAndSave = () => {
      const isValid = validate({ regex, setValid })(currentInput);

      if (!isValid) {
        return;
      }

      setEditing(false);
      innerElt.current.blur();

      if (currentInput === value) {
        return;
      }

      if (disableOnSave) {
        setDisabled(true);
      }
      save(currentInput);
    };

    const closeAndReset = () => {
      setEditing(false);

      if (currentInput === value) {
        return;
      }

      innerElt.current.textContent = value;
      setCurrentInput(value);
    };

    const onClickCancel = (evt) => {
      evt.stopPropagation();

      closeAndReset();
    };

    const onClickEdit = (evt) => {
      evt.stopPropagation();

      setEditing(true);
    };

    const onClickInner = editOnClick
      ? (evt) => {
          evt.stopPropagation();

          setEditing(true);
        }
      : undefined;

    const onClickSave = (evt) => {
      evt.stopPropagation();

      closeAndSave();
    };

    /*
    Closes the dropdown on mouse 'click' outside Labels component.
    */
    useEffect(() => {
      const onOutsideClick = () => {
        const unsavedChanges = editing && currentInput !== value;

        if (!unsavedChanges) {
          setEditing(false);
          return;
        }

        if (saveOnBlur) {
          closeAndSave();
        } else {
          closeAndReset();
        }
      };

      window.addEventListener("click", onOutsideClick, false);

      return () => window.removeEventListener("click", onOutsideClick, false);
    });

    const onKeyDown = (evt) => {
      const { key } = evt;

      if (cancelOnEscape && key === "Escape") {
        evt.preventDefault();
        innerElt.current.blur();
        closeAndReset();
        return;
      }

      if (saveOnEnter && key === "Enter") {
        evt.preventDefault();
        closeAndSave();
      }
    };

    const onKeyUp = () => {
      if (innerElt.current.textContent !== currentInput) {
        setCurrentInput(innerElt.current.textContent);
      }
    };

    const classNameStr = disabled
      ? "blumira-editable disabled"
      : "blumira-editable";

    return (
      <div className={classNameStr}>
        <div className="blumira-editable-left">
          <InnerComponent
            dataCy={dataCy}
            contentEditable={editing}
            onClick={onClickInner}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            ref={innerElt}
            className={innerClassName}
            value={value}
          >
            {value}
          </InnerComponent>

          {!valid && <div className="blumira-editable-invalid">Invalid</div>}
        </div>

        <div className="blumira-editable-right">
          {!editing && (
            <Button
              color={btnColorEdit}
              onClick={onClickEdit}
              dataCy={"editablePencil"}
            >
              <i className="fas fa-pencil-alt" />
            </Button>
          )}

          {editing && (
            <React.Fragment>
              <Button
                color={btnColorSave}
                onClick={onClickSave}
                id={btnIdSave}
                dataCy={"editableCheck"}
              >
                <i className="fas fa-check" />
              </Button>

              <Button
                color={btnColorCancel}
                onClick={onClickCancel}
                id={btnIdCancel}
              >
                <i className="fas fa-times" />
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  };

  // PropTypes
  Editable.propTypes = {
    innerClassName: PropTypes.string,
    btnColorCancel: PropTypes.string,
    btnColorEdit: PropTypes.string,
    btnColorSave: PropTypes.string,
    cancelOnEscape: PropTypes.bool,
    disableOnSave: PropTypes.bool,
    editOnClick: PropTypes.bool,
    errorFlag: PropTypes.bool,
    saveOnBlur: PropTypes.bool,
    saveOnEnter: PropTypes.bool,
    regex: PropTypes.string,
    save: PropTypes.func,
    value: PropTypes.string,
  };

  Editable.defaultProps = {
    innerClassName: "",
    btnColorCancel: "",
    btnColorEdit: "",
    btnColorSave: "",
    cancelOnEscape: true,
    disableOnSave: true,
    editOnClick: true,
    errorFlag: false,
    saveOnBlur: true,
    saveOnEnter: true,
    regex: "",
    save: () => {},
    value: "",
  };

  return Editable;
};

/*
Editable div
*/
export const ContentEditableDiv = makeContentEditable("div");
