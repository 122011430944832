import { API, setAxiosOrgHeader } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "sensors",
};

export class SensorsAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  get(orgId, sensorId) {
    const params = [orgId, sensorId];
    return super.get({ params });
  }

  list(orgId, meta = {}) {
    const { page, page_size } = meta;
    const params = [orgId];
    const query = { page, limit: page_size };
    setAxiosOrgHeader(orgId);
    return super.get({ params, query });
  }

  rebuild({ data, orgId, sensorId }) {
    const params = [orgId, sensorId, "rebuild"];
    return super.post({ params }, data);
  }

  update({ orgId, sensorId, data = {} }) {
    const params = [orgId, sensorId];
    return super.put({ params }, data);
  }

  create(orgId, personId, data = {}) {
    const params = [orgId];
    const query = { personId };
    return super.post({ params, query }, data);
  }

  delete(orgId, personId, sensorId) {
    const params = [orgId, sensorId];
    const query = { personId };
    return super.delete({ params, query });
  }

  createModule(orgId, personId, sensorId, data = {}) {
    const params = [orgId, sensorId, "module"];
    const query = { personId };
    return super.post({ params, query }, data);
  }

  updateModule(orgId, personId, sensorId, sensorModuleUUID, data = {}) {
    const params = [orgId, sensorId, "module", sensorModuleUUID];
    const query = { personId };
    return super.put({ params, query }, data);
  }

  deleteModule(orgId, personId, sensorId, sensorModuleUUID) {
    const params = [orgId, sensorId, "module", sensorModuleUUID];
    const query = { personId };
    return super.delete({ params, query });
  }

  migrateModule(orgId, personId, sensorId, sensorModuleUUID) {
    const params = [orgId, sensorId, "module", sensorModuleUUID, "migrate"];
    const query = { personId };
    return super.post({ params, query });
  }
}
