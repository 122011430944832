// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled, { keyframes } from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Keyframes
// *****************************************************************************

// ** Circle keyframe *****************
const loadpath = keyframes`
  100% {
    stroke-dashoffset: -138;
  }
`;

// ** Svg keyframe ********************
const rotator = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Base svg styles *****************
export const LoadingAnimation = styled.svg`
  display: block;
  height: 100%;
  width: 100%;
`;

// ** Base circle styles **************
export const Path = styled.circle`
  stroke: ${COLOR.blumiraLightBlue};
  stroke-dasharray: 138;
  stroke-dashoffset: 138;
  animation: ${loadpath} 1.6s linear infinite forwards;
`;

// ** Animation container *************
export const Container = styled.div`
  animation: ${rotator} 1.6s linear infinite forwards;
  height: ${(props) => props.size || "8.8rem"};
  width: ${(props) => props.size || "8.8rem"};
`;

// ** Wrapper *************************
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${(p) => p.height};
  justify-content: center;
  width: 100%;
`;
