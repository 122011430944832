import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateSensor } from "../../../redux/actions/Sensors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  currentSensorSelector,
  detailErrorSelector,
  sensorIdSelector,
} from "../../../selectors/sensorsSelectors";
import { ContentEditableDiv } from "./BluContentEditable";
import SensorActions from "./SensorActions";
import SensorStatus from "./SensorStatus";

import "./SensorHeader.scss";

/*
Constants
*/

const SENSOR_NAME_REGEX = "^[a-zA-Z0-9_.'-]+$";

/*
Component
*/

const SensorHeader = (props) => {
  const {
    detailError,
    dispatchUpdateSensorName,
    orgId,
    sensorId,
    sensorName,
    status,
  } = props;

  const [currentName, setCurrentName] = useState(sensorName || "");

  useEffect(() => {
    setCurrentName(sensorName || "");
  }, [sensorId, detailError, sensorName]);

  return (
    <div className="sensor-header">
      <React.Fragment>
        <div className="sensor-header-left">
          <SensorStatus status={status} />

          <div>
            <ContentEditableDiv
              dataCy={"sensorNameInput"}
              innerClassName="sensor-name"
              save={dispatchUpdateSensorName({ orgId, sensorId })}
              value={currentName}
              regex={SENSOR_NAME_REGEX}
              errorFlag={!!detailError}
            />
          </div>
        </div>

        <div className="sensor-header-right">
          <SensorActions />
        </div>
      </React.Fragment>
    </div>
  );
};

SensorHeader.propTypes = {
  detailError: PropTypes.shape({}),
  dispatchUpdateSensorName: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  sensorId: PropTypes.string.isRequired,
  sensorName: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

SensorHeader.defaultProps = {
  detailError: null,
};

const mapStateToProps = (state) => {
  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);

  const detailError = detailErrorSelector(state);

  const sensor = currentSensorSelector(state);
  const { name: sensorName = "", status = -1 } = sensor;

  return {
    detailError,
    orgId,
    sensorId,
    sensorName,
    status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchUpdateSensorName:
    ({ orgId, sensorId }) =>
    (name) => {
      const data = { name };
      dispatch(
        updateSensor({
          orgId,
          sensorId,
          data,
        })
      );
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(SensorHeader);
