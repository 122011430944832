import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { Button, TextField } from "@mui/material";
import { Alert } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { HubspotAPI } from "../../../lib/api/HubspotAPI";
import { Message } from "lib/models/Message";
import { orgNameSelector } from "../../../selectors/orgNameSelector";
import { validateEmail } from "../../Components/Form/validators";
import { getHubspotContactEmails } from "./utils";

const PREFIX = "MSPSettingsPageView";
const classes = {
  outerContainer: `${PREFIX}-outerContainer`,
  largeText: `${PREFIX}-largeText`,
  button: `${PREFIX}-button`,
  textField: `${PREFIX}-textField`,
  alert: `${PREFIX}-alert`,
  pricingContainer: `${PREFIX}-pricingContainer`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.outerContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: `0 ${theme.shape.padding}px`,
  },
  [`& .${classes.largeText}`]: {
    fontWeight: "bold",
  },
  [`& .${classes.button}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${classes.textField}`]: {
    minWidth: 450,
  },
  [`& .${classes.alert}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${classes.pricingContainer}`]: {
    marginTop: theme.shape.padding,
    "& h3": {
      marginBottom: theme.shape.padding,
    },
    "& p": {
      marginBottom: theme.shape.padding,
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));

const API = {
  hubspot: new HubspotAPI(),
};

const blumiraMspBillingUrl = "https://blumira.help/msppricing";

const validateEmails = (emailList) => {
  for (const str of emailList) {
    if (validateEmail(str) === false) {
      return false;
    }
  }
  return true;
};

export const MSPSettingsPageView = (props) => {
  const { orgName } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [alertToShow, setAlertToShow] = useState(false);

  useEffect(() => {
    if (!emailString && !isSaving) {
      // to handle async calls in useEffect hook
      // build func here and call immediately
      const fetchEmailString = async () => {
        // get existing contant email string here
        // and set it to state var for displaying
        const existingString = await getHubspotContactEmails();
        setEmailString(existingString);
      };
      fetchEmailString();
    }
  });

  const handleBlur = (e) => {
    if (validateEmails(e.target.value.split(", ")) === false) {
      toggleAlert("error");
    } else {
      toggleAlert();
    }
  };

  const handleChange = (e) => setEmailString(e.target.value);

  const handleSaveChanges = async () => {
    if (validateEmails(emailString.split(", ")) === false) {
      toggleAlert("error");
      return;
    }

    // disable button and update UI
    setIsSaving(true);

    const messageMSP = new Message({
      body: `MSP Organization ${orgName} billing report recipients are: ${emailString}`,
      recipient: "msp@blumira.com",
      subject: "MSP Organization Billing Report Recipients have changed",
      type: 30,
    });

    const messageFinance = new Message({
      body: `MSP Organization ${orgName} billing report recipients are: ${emailString}`,
      recipient: "ar@blumira.com",
      subject: "MSP Organization Billing Report Recipients have changed",
      type: 30,
    });

    try {
      const messageResponseMSP = await messageMSP.create();
      const messageResponseFinance = await messageFinance.create();

      if (messageResponseMSP.error === true) {
        toggleAlert("error");
        return;
      }
      if (messageResponseFinance.error === true) {
        toggleAlert("error");
        return;
      }
    } catch {
      toggleAlert("error");
      return;
    }

    try {
      // API expects emails as an array
      const emailArray = emailString.split(", ");

      const { statusCode } = await API.hubspot.updateBilling({
        billingContactEmails: emailArray,
      });

      // enablue button and update UI
      setIsSaving(false);

      // show alert based on response status
      if (statusCode === 200) {
        setEmailString("");
        toggleAlert("success");
      } else toggleAlert("error");
    } catch (e) {
      // Swallow error.
    }
  };

  const toggleAlert = (type) => {
    if (type) setAlertToShow(type);
    else setAlertToShow(false);
  };

  return (
    <Root className={classes.outerContainer}>
      <h3 className={classes.largeText}>
        Email invoices and end-of-month billing report to:
      </h3>
      <TextField
        inputProps={{ "aria-label": "emailList" }}
        type="text"
        value={emailString}
        onChange={handleChange}
        className={classes.textField}
        helperText={"Separate multiple email addresses with a comma and space"}
        onBlur={handleBlur}
      />
      <Button
        color={"primary"}
        variant={"contained"}
        className={classes.button}
        onClick={handleSaveChanges}
        disabled={
          !emailString || isSaving || !validateEmails(emailString.split(", "))
        }
      >
        {isSaving ? "Saving..." : "Save changes"}
      </Button>
      {!!alertToShow && (
        <Alert
          severity={alertToShow}
          onClose={() => toggleAlert(false)}
          className={classes.alert}
        >
          {alertToShow === "success"
            ? "Successfully updated your billing contact emails."
            : "Oops, looks like something went wrong. Please double check your formatting and try again."}
        </Alert>
      )}
      <div className={classes.pricingContainer}>
        <h3 className={classes.largeText}>Pricing</h3>
        <p>
          An order form is required to start MSP billing. Please read the MSP
          pricing article below, then email{" "}
          <a
            href="mailto:MSP@blumira.com"
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            msp@blumira.com
          </a>{" "}
          with your desired pricing tier.
        </p>
        <a
          href={blumiraMspBillingUrl}
          target={"_blank"}
          rel={"noopener noreferrer"}
        >
          MSP Pricing Article <OpenInNewIcon />
        </a>
      </div>
    </Root>
  );
};

const mapStateToProps = (state) => {
  const orgName = orgNameSelector(state);

  return {
    orgName,
  };
};

export default connect(mapStateToProps, null)(MSPSettingsPageView);
