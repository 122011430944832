import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { updateSensor } from "../../../redux/actions/Sensors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  currentSensorSelector,
  sensorIdSelector,
} from "../../../selectors/sensorsSelectors";
import { ContentEditableDiv } from "./BluContentEditable";

import "./SensorDescription.scss";

const SensorDescription = (props) => {
  const { description, orgId, sensorId, updateSensorDescription } = props;

  return (
    <div className="sensor-description">
      {!description && (
        <div className="sensor-description-add">Add description:</div>
      )}
      <ContentEditableDiv
        innerClassName="sensor-name"
        save={updateSensorDescription({ orgId, sensorId })}
        value={description}
        regex=""
        errorFlag={false}
      />
    </div>
  );
};

SensorDescription.propTypes = {
  description: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  sensorId: PropTypes.string.isRequired,
  updateSensorDescription: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);

  const sensor = currentSensorSelector(state);

  const { description } = sensor;

  return {
    description: description || "",
    orgId,
    sensorId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSensorDescription:
    ({ orgId, sensorId }) =>
    (description) => {
      const data = { description };

      dispatch(
        updateSensor({
          orgId,
          sensorId,
          data,
        })
      );
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SensorDescription);
