import React from "react";
import PropTypes from "prop-types";

import BroadcastCheckBox from "./BroadcastCheckBox";

import "./SensorModalContent.scss";

/*
Constants and Helpers
*/

const HELP_DESK_URL =
  "https://blumira.zendesk.com/hc/en-us/articles/360044396313";

/*
Main component
*/
const RebuildSensor = ({ handleChangeBroadcast, noBroadcast }) => (
  <div className="sensor-modal-content">
    <div>
      <p>
        Blumira will generate a fresh build of your sensor, retaining all sensor
        configuration. You will receive an email with the installation command
        to run on your host.
      </p>

      <strong>
        <a href={HELP_DESK_URL} rel="noopener noreferrer" target="_blank">
          Read more about rebuilding sensors
        </a>
      </strong>
    </div>

    <div className="sensor-form-item">
      <div className="sensor-label">
        <BroadcastCheckBox
          noBroadcast={noBroadcast}
          handleChange={handleChangeBroadcast}
        />
      </div>
    </div>
  </div>
);

// PropTypes
RebuildSensor.propTypes = {
  noBroadcast: PropTypes.bool,
  handleChangeBroadcast: PropTypes.func.isRequired,
};

RebuildSensor.defaultProps = {
  noBroadcast: false,
};

export default RebuildSensor;
