import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Logger from "lib/logger";
import { actionFailure } from "redux/actions/Page";

const logger = Logger("LoaderHook");

/**
 * Class containing the status of a data load operation.
 *
 * Conceptually similar to a Promise, except it's immutable.
 * A new LoadStatus object must be created whenever the status changes.
 *
 * Only one of {loading, data, error} should be filled out in any one
 * LoadStatus instance.
 * reload is an async function that triggers a reload of the data.
 */
export class LoadStatus {
  constructor({ loading, data, error, reload }) {
    this.loading = loading;
    this.data = data;
    this.error = error;
    this.reload = reload;
  }
}

const LOADING = new LoadStatus({ loading: true });

/**
 * React hook for loading things.
 *
 * Arguments:
 *   asyncFunc: function that starts the loading process and returns a promise.
 *   name: short string that identifies the code that initiated the load
 *         (used for logging).
 *   dependencies: constant-length array of values.
 * Returns:
 *   Immutable LoadStatus object containing the status of the load
 *
 * Similar to other react hooks, if the dependencies change, the asyncFunc will
 * be called again.
 *
 * The returned LoadStatus will initially be LOADING, but when the loading
 * promise resolves, the returned LoadStatus will contain the loaded data.
 *
 * If an error occurs, it uses redux to update both the global and page-level
 * errors, so that a banner appears automatically.  The caller can also check
 * if LoadingStatus.error is set, for further customized error handling.
 *
 * Usage:
 *   const rulesLoadStatus = useLoader(() => request.get(), "rules", [orgId])
 *   if (rulesLoadStatus.loading) {...Display loading indicator}
 *   else if (rulesLoadStatus.error) {...Handle error}
 *   else {...Display data}
 *
 *   const handleReload = async () => {
 *     await rulesLoadStatus.reload({reset: false});
 *   };
 *
 * Note: there are two ways to reload data after the initial load:
 *   1. Change the dependencies. (This will always reset the status back
 *      to LOADING while it reloads.)
 *   2. Call LoadStatus.reload({reset: boolean}). (This will reset the
 *      status back to LOADING only if `reset` is true.  reload() is an
 *      async function that calls the given asyncFunc and also triggers
 *      a re-render when the data is loaded.)
 */
export function useLoader(asyncFunc, name, dependencies) {
  const [state, setState] = useState(LOADING);
  const dispatch = useDispatch();

  /**
   * Load the data by calling the given asyncFunc.  After the load completes,
   * the LoadStatus returned by useLoader will contain the loaded data.
   *
   * @param {boolean} reset - If true, the LoadStatus will be reset to LOADING
   *        before the load begins.  If false, useLoader continues to return
   *        the previously loaded data until the new data is reloaded.
   */
  const loadData = async ({ reset }) => {
    if (reset) {
      setState(LOADING);
    }
    try {
      const data = await asyncFunc();
      setState(new LoadStatus({ data, reload: loadData }));
      return data;
    } catch (error) {
      logger.error(`Failed to load ${name}`, error);
      // Dispatch actionFailure to make an error banner appear.
      dispatch(actionFailure(error));
      setState(new LoadStatus({ error, reload: loadData }));
      throw error;
    }
  };
  useEffect(() => {
    // When dependencies change, reset status to LOADING if it's not already.
    // Catch and ignore errors since they are returned in the LoadStatus.
    loadData({ reset: !state.loading }).catch(() => {});
  }, dependencies);
  return state;
}
