/*
This file contains CustomLabelsLayer component for creating Nivo
custom layer containing bar labels.
Can be used in place of the Nivo Bar Graph bar labels.
*/

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { DEFAULT_EMPTY_LABEL } from "./securityConfig";
import { formatNum } from "./securityHelpers";
/*
Constants
*/

// Graph style defaults
const LABEL_COLOR = "black";
const LABEL_BACKGROUND_COLOR = "white";
const LABEL_BACKGROUND_WIDTH_INCREMENT = 10;
const LABEL_FONT_SIZE = "10px";
const LABEL_X_DISTANCE_FROM_BAR = 5;

/*
Helpers
*/

// The bar label component
// `format` argument is a function Strign->String if it is given
// `bluBarLabelValue` as input, and Number -> String if it is given
// `value` as input
const BluBarLabel = ({
  bluBarLabelValue,
  fillColor,
  fontSize,
  format = formatNum,
  height,
  value,
  x,
  y,
}) => {
  const [rectWidth, setRectWidth] = useState("70");

  // Need to get the size of text's box after it renders
  // (used to put a rectangle under the label's text)
  const textElt = useRef();

  useEffect(() => {
    const textBox = textElt.current.getBBox();

    // make rectangle wider than label
    const w = textBox.width + LABEL_BACKGROUND_WIDTH_INCREMENT;

    setRectWidth(w);
  }, []);

  return (
    <g transform={`translate(${x}, ${y})`}>
      <rect
        x={0}
        y={0}
        width={rectWidth}
        height={height}
        fill={LABEL_BACKGROUND_COLOR}
      />
      <text
        ref={textElt}
        x={LABEL_X_DISTANCE_FROM_BAR}
        y={height / 2}
        fill={fillColor}
        textAnchor="start"
        dominantBaseline="central"
        style={{ fontSize }}
      >
        {format(bluBarLabelValue || value)}
      </text>
    </g>
  );
};

BluBarLabel.propTypes = {
  bluBarLabelValue: PropTypes.string,
  format: PropTypes.func,
  height: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  fontSize: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
};

BluBarLabel.defaultProps = {
  bluBarLabelValue: "",
  format: formatNum,
};

// Takes Nivo custom layer props and label styling parameters.
// Returns the bar labels.
// Creates bar labels for all graph lines that correspond to real data
// (including the short bars not created by Nivo - Nivo does not create
// bars whose values are much smaller than the maximum value).
// `axisLeft` argument is used to determine if the data is real or
// was inserted to fill up the graph.
const createLabelsDescending = ({
  // Nivo custom layer props
  axisLeft,
  barHeight,
  bars,
  data,
  maxBarCount,
  step,
  // label styles
  fillColor,
  fontSize,
  format = formatNum,
}) => {
  if (!bars || !bars.length) {
    return;
  }

  const labels = [];

  const stop = Math.min(data.length, maxBarCount);

  // bars[bars.length - 1] is the top bar and has the lowest y-coordinate
  const lastBarIndex = bars.length - 1;
  const lastBar = bars[lastBarIndex];
  let currentY = lastBar.y;

  for (let i = 0; i < stop; i += 1) {
    // Do not add bar label if left axis label is DEFAULT_EMPTY_LABEL
    if (axisLeft.format(i.toString()) === DEFAULT_EMPTY_LABEL) {
      continue;
    }

    // Calculate the label's x-coordinate from the bar width
    let x = 0;
    if (lastBarIndex - i >= 0) {
      const { width = 0 } = bars[lastBarIndex - i];

      x = width;
    }

    labels[i] = (
      <BluBarLabel
        bluBarLabelValue={data[data.length - 1 - i].bluBarLabelValue}
        value={data[data.length - 1 - i].value}
        height={barHeight}
        x={x}
        y={currentY}
        fillColor={fillColor}
        fontSize={fontSize}
        format={format}
        key={i}
      />
    );

    currentY += step;
  }

  return labels;
};

const createLabelsAscending = ({
  // Nivo custom layer props
  axisLeft,
  barHeight,
  bars,
  data,
  maxBarCount,
  step,
  // label styles
  fillColor,
  fontSize,
  format = formatNum,
}) => {
  if (!bars || !bars.length) {
    return;
  }

  const labels = [];

  const stop = Math.min(data.length, maxBarCount);
  const lastBarIndex = bars.length - 1;

  // bars[0] is the bottom bar and has the highest y-coordinate
  const firstBar = bars[0];
  let currentY = firstBar.y;

  for (let i = 0; i < stop; i += 1) {
    // Do not add bar label if left axis label is DEFAULT_EMPTY_LABEL
    if (axisLeft.format(i.toString()) === DEFAULT_EMPTY_LABEL) {
      continue;
    }

    // Calculate the label's x-coordinate from the bar width
    let x = 0;
    if (i <= lastBarIndex) {
      const { width = 0 } = bars[i];

      x = width;
    }

    labels[i] = (
      <BluBarLabel
        bluBarLabelValue={data[i].bluBarLabelValue}
        value={data[i].value}
        height={barHeight}
        x={x}
        y={currentY}
        fillColor={fillColor}
        fontSize={fontSize}
        format={format}
        key={i}
      />
    );

    currentY -= step;
  }

  return labels;
};

/*
Main component
(Nivo graph custom layer containing the bar labels)
*/

// This wrap takes styling parameters and returns a custom layer
const CustomLabelsLayerWrap = ({
  fillColor = LABEL_COLOR,
  fontSize = LABEL_FONT_SIZE,
  putLargestAtTheTop = true,
  format,
}) => {
  // Custom layer - receives Nivo custom layer props
  const CustomLabelsLayer = (props) => {
    const { axisLeft, bars, data, yScale } = props;

    const maxBarCount = yScale.domain() ? yScale.domain().length || 0 : 0;
    const barHeight = yScale.bandwidth();
    const step = yScale.step();

    // Add labels
    let labels;
    if (putLargestAtTheTop) {
      labels = createLabelsDescending({
        axisLeft,
        barHeight,
        bars,
        data,
        maxBarCount,
        step,
        fillColor,
        fontSize,
        format,
      });
    } else {
      labels = createLabelsAscending({
        axisLeft,
        barHeight,
        bars,
        data,
        maxBarCount,
        step,
        fillColor,
        fontSize,
        format,
      });
    }

    return <g>{labels}</g>;
  };

  CustomLabelsLayer.propTypes = {
    axisLeft: PropTypes.shape({}).isRequired,
    bars: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    yScale: PropTypes.shape({}).isRequired,
  };

  return CustomLabelsLayer;
};

export default CustomLabelsLayerWrap;
