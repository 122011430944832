import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Commenting this out for now, until form is fixed and ready to use.
// import SimpleModelForm from 'views/Components/SimpleModelForm';
import Codex from "@blumira/blu_constants";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import FindingsResolutionNotes from "./FindingsResolutionNotes";

const FINDING_SUPPORT_KEYS = [
  "jurisdiction",
  "matchId",
  "promotedToId",
  "modified",
  "modifiedByName",
  "statusModifiedByName",
  "resolution",
  "resolutionNotes",
];

const FindingSupport = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={4} style={{ paddingLeft: "24px" }}>
        <Typography>Finding Details</Typography>
        <TableContainer>
          <Table>
            <TableBody>
              {FINDING_SUPPORT_KEYS.map((k) => (
                <TableRow key={k}>
                  <TableCell align="right">{k}</TableCell>
                  <TableCell align="right">{props.finding[k]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {/* NOTE: The below form is commented out because it currently doesn't work.
      The old code that was switched out when the page speed changes were made,
      just dispatched actions to make the updates instead of checking against the schema
      to update the model.
      I have changed it in the meantime from a form to just simply display the information, 
      until we can make the necessary changes to get this form to work as intended.
      */}
      {/* <Grid item xs={8}>
        <Typography>{props.finding.formTitle}</Typography>
        <SimpleModelForm
          spacing={2}
          model={props.finding}
          actions={[
            {
              'title': 'Save',
              'type': 'submit',
            }
          ]}     
          layout={[
            {field: 'status', xs: 6},
            {field: 'resolution', xs: 6},
            {field: 'resolutionNotes', xs: 12},
          ]}
          fields = {{
            'resolution': {
              type: 'select',
              default: '',
              label: 'Resolve as',
              lookup: props.lookup.resolution
            },
            'resolutionNotes': {
              type: 'text',
              default: '',
              label: 'Resolution Notes',
              props: {
                multiline: true,
                minRows: 5,
              }
            }
          }}
        />
      </Grid> */}
      {/* NOTE: The component below holds the temporary solution to displaying
      the resolution notes information for SAs and for customers, while we try to
      fix the above form.  Once the form is fixed, we'll want to replace the table
      in this component with the form that's above.
      */}
      {props.finding.resolution ? (
        <FindingsResolutionNotes finding={props.finding} />
      ) : (
        <Typography style={{ marginLeft: 20 }}>
          This finding has not been resolved and does not currently have any
          resolution notes.
        </Typography>
      )}
    </Grid>
  );
};

FindingSupport.propTypes = {
  finding: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const lookup = {};
  Object.keys(Codex.language.models.finding).forEach((k) => {
    lookup[k] = Object.keys(Codex.language.models.finding[k]).map((value) => {
      return { value, label: Codex.language.models.finding[k][value] };
    });
  });
  return {
    lookup,
  };
};

export default connect(mapStateToProps)(FindingSupport);
