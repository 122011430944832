import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { sortByName } from "../../lib/helpers";
import {
  SYSTEM_LABELS_KEY,
  fetchLabelsForPage as fetchLabelsForPageAction,
} from "../../redux/actions/Tagging";

import { Label as LabelModel } from "lib/models/Label";

import SimpleTable from "views/Components/SimpleTable";
import SimpleModelForm from "views/Components/SimpleModelForm";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

const LabelsPageView = (props) => {
  const [openForm, setOpenForm] = useState(false);
  const [tagSections, setTagSections] = useState([]);
  const [selectedModel, setSelectedModel] = useState(false);

  const { user, orgId, labelsLoading, fetchLabelsForPage } = props;

  const { superadmin } = user;

  const columns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
    },
  ];

  // fetches all the system and given org's labels
  useEffect(() => {
    fetchLabelsForPage(orgId);
  }, [orgId]);

  useEffect(() => {
    const customLabels = (props.labels[orgId] || []).sort(sortByName);
    const systemLabels = (props.labels[SYSTEM_LABELS_KEY] || []).sort(
      sortByName
    );

    // we have multiple tables represented,
    // let's be as dry as we can here
    setTagSections([
      {
        index: 0,
        data: systemLabels,
        title: "System Tags",
        addNewText: "Add New System Tag",
        addNewTagFunc: () => setSelectedModel(new LabelModel()),
      },
      {
        index: 1,
        title: "Custom Tags",
        data: customLabels,
        addNewText: "Add New Custom Tag",
        addNewTagFunc: () =>
          setSelectedModel(new LabelModel({ orgId: props.orgId })), // tie label to this org
      },
    ]);
  }, [props.labels]);

  const handleClose = (action) => {
    if (action === "create") {
      fetchLabelsForPage();
    }
    setOpenForm(false);
    setSelectedModel(false);
  };

  const handleEditTableRowClick = (rowDetails) => {
    //rowDetails, in this case, is really just the user info/model
    setSelectedModel(rowDetails);
    setOpenForm(true);
  };

  return (
    <div className="dashboard labels-page">
      <div className="labels-page-container">
        {tagSections.map(
          ({ index, title, data, addNewText, addNewTagFunc }) => {
            return (
              <div key={index} className={"section"}>
                <SimpleTable
                  isNorthStar
                  data={data}
                  title={title}
                  columns={columns}
                  rowClick={(rowDetails) => {
                    if (index === 0) {
                      if (superadmin) handleEditTableRowClick(rowDetails);
                      else return;
                    }
                    handleEditTableRowClick(rowDetails);
                  }}
                  isFetching={labelsLoading}
                  // for actions only superadmins can
                  // add or edit system level labels
                  toolbarActions={
                    superadmin || index > 0
                      ? [
                          {
                            icon: AddCircleIcon,
                            tooltip: addNewText,
                            onClick: (event) => {
                              addNewTagFunc();
                              setOpenForm(true);
                            },
                          },
                        ]
                      : []
                  }
                  actions={
                    superadmin || index > 0
                      ? [
                          {
                            icon: EditIcon,
                            tooltip: "Edit Tag",
                            onClick: (event, model) => {
                              handleEditTableRowClick(model);
                            },
                          },
                        ]
                      : []
                  }
                />
              </div>
            );
          }
        )}
      </div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openForm}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">
          {selectedModel.id ? `Edit ${selectedModel.name}` : "New Tag"}
        </DialogTitle>
        <SimpleModelForm
          spacing={1}
          model={selectedModel}
          onClose={handleClose}
          actions={[
            {
              title: "Cancel",
              type: "button",
              action: handleClose,
            },
            {
              title: "Save",
              type: "submit",
            },
          ]}
          layout={[{ field: "name", xs: 12 }]}
          fields={{
            name: {
              type: "text",
              default: "",
              label: "Name",
            },
          }}
        />
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { labels = {}, tagsLoading, labelsLoading } = state.tagging;
  return {
    labels,
    tagsLoading,
    labelsLoading,
    orgId: state.location.payload.orgId,
    user: state.session.settings.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchLabelsForPage: (orgId) => dispatch(fetchLabelsForPageAction(orgId)),
});

LabelsPageView.propTypes = {
  loading: PropTypes.bool,
  tagsLoading: PropTypes.bool,
  labelsLoading: PropTypes.bool,
  data: PropTypes.shape({}),
  user: PropTypes.shape({}),
  labels: PropTypes.shape({}),
  payload: PropTypes.shape({}),
  orgId: PropTypes.string.isRequired,
  fetchLabelsForPage: PropTypes.func.isRequired,
};

LabelsPageView.defaultProps = {
  user: {},
  data: {},
  labels: {},
  loading: false,
  tagsLoading: false,
  labelsLoading: false,
  payload: { toplevel: "settings", secondlevel: "tags" },
};

export default connect(mapStateToProps, mapDispatchToProps)(LabelsPageView);
