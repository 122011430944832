import { BaseModel } from "./BaseModel";

export class Column extends BaseModel {
  schema = "/column";
  constructor(params) {
    return super(params, "/column");
  }

  fields = {
    dataSource: () => (this.name.indexOf("finding_") > -1 ? "finding" : "bq"),
  };
}
