// *****************************************************************************
// Filter contants
// *****************************************************************************
//
// Filter operator constants for consistency across the app.
//

// ** Operators ***********************
export const OPERATORS = {
  ilike: {
    allow_negation: false,
    description:
      "column value matches the pattern provided, irrespective of case. The % is a wildcard that matches any string. Works exactly like the operator 'like', but is not case sensitive; e.g. 'bob%' will match any strings that start with 'bob', 'BOB', 'Bob', etc.",
    display_name: "SQL Case Insensitive Like",
    doc_url: null,
    id: 10,
    input_format: null,
    name: "ilike",
  },
  "=": {
    allow_negation: false,
    description:
      "column value is exactly the provided value, ignoring upper/lower case",
    display_name: "Equal",
    doc_url: null,
    id: 1,
    input_format: null,
    name: "equal",
  },
  ">": {
    allow_negation: false,
    description: "column value is greater than the provided value",
    display_name: "Greater Than",
    doc_url: null,
    id: 5,
    input_format: null,
    name: "greaterThan",
  },
  ">=": {
    allow_negation: false,
    description: "column value is greater than or equal to the provided value",
    display_name: "Greater Than or Equal",
    doc_url: null,
    id: 6,
    input_format: null,
    name: "greaterThanOrEqual",
  },
  is: {
    allow_negation: false,
    description:
      "column value is the specified value (usually only used for 'is null')",
    display_name: "Is",
    doc_url: "https://www.w3schools.com/sql/sql_null_values.asp",
    id: 8,
    input_format: null,
    name: "is",
  },
  "<": {
    allow_negation: false,
    description: "column value is less than the provided value",
    display_name: "Less Than",
    doc_url: null,
    id: 3,
    input_format: null,
    name: "lessThan",
  },
  "<=": {
    allow_negation: false,
    description: "column value is less than or equal to the provided value",
    display_name: "Less Than or Equal",
    doc_url: null,
    id: 4,
    input_format: null,
    name: "lessThanOrEqual",
  },
  like: {
    allow_negation: false,
    description:
      "column value matches the pattern provided. The % is a wildcard that matches any string; e.g. 'bob%' will match any strings that start with 'bob'. Like is case-sensitive.",
    display_name: "SQL Like",
    doc_url: "https://www.w3schools.com/sql/sql_like.asp",
    id: 9,
    input_format: null,
    name: "like",
  },
  array_contains_equals: {
    allow_negation: false,
    description:
      "the list (array) -type column includes the exact provided value one or more times in its list, ignoring upper/lower case",
    display_name: "List Includes Value",
    doc_url: null,
    id: 12,
    input_format: null,
    name: "arrayContainsEquals",
  },
  array_contains_regexp: {
    allow_negation: false,
    description:
      "the list (array) -type column includes a value containing a match for the provided regular expression one or more times in its list. Supports the RE2 library regex syntax.",
    display_name: "List Includes RegEx",
    doc_url: "https://github.com/google/re2/wiki/Syntax",
    id: 13,
    input_format: null,
    name: "arrayContainsRegexp",
  },
  array_length_greater_than: {
    allow_negation: false,
    description:
      "the length of the list (array) -type column is greater than the provided integer",
    display_name: "List Length Greater Than",
    doc_url: null,
    id: 21,
    input_format: "int",
    name: "arrayLengthGreaterThan",
  },
  array_only_includes: {
    allow_negation: false,
    description:
      "the list (array) -type column has values matching all of the values in the provided list and no other values. In other words, the column and the provided list must be equal except for reordering.",
    display_name: "List Only Includes",
    doc_url: null,
    id: 22,
    input_format: "list",
    name: "arrayOnlyIncludes",
  },
  "!=": {
    allow_negation: false,
    description:
      "column value is not the provided value, ignoring upper/lower case",
    display_name: "Not Equal",
    doc_url: null,
    id: 2,
    input_format: null,
    name: "doesNotEqual",
  },
  in: {
    allow_negation: false,
    description:
      "column value is one of the values in the list specified, ignoring upper/lower case",
    display_name: "One Of",
    doc_url: null,
    id: 7,
    input_format: "list",
    name: "in",
  },
  regexp: {
    allow_negation: false,
    description:
      "column value contains a match for the regular expression pattern provided. Supports RE2 library regex syntax; e.g. '[Bb]ob$' will match any string that ends with 'bob' or 'Bob'. Case-sensitive, by default.",
    display_name: "Regular Expression",
    doc_url: "https://github.com/google/re2/wiki/Syntax",
    id: 11,
    input_format: null,
    name: "regexp",
  },
  "!in": {
    allow_negation: false,
    description:
      "column value is not one of the values in the list specified, ignoring upper/lower case",
    display_name: "Not Any Of",
    doc_url: null,
    id: 14,
    input_format: "list",
    name: "notIn",
  },
  "!is": {
    allow_negation: false,
    description:
      "column value is not the specified value (usually only used for 'is not null')",
    display_name: "Is Not",
    doc_url: "https://www.w3schools.com/sql/sql_null_values.asp",
    id: 15,
    input_format: null,
    name: "isNot",
  },
  "!like": {
    allow_negation: false,
    description:
      "column value doesn't match the pattern provided. The % is a wildcard that matches any string; e.g. 'bob%' will match any strings that don't start with 'bob'. Like is case-sensitive.",
    display_name: "SQL Not Like",
    doc_url: "https://www.w3schools.com/sql/sql_like.asp",
    id: 16,
    input_format: null,
    name: "notLike",
  },
  "!ilike": {
    allow_negation: false,
    description:
      "column value doesn't match the pattern provided, irrespective of case. The % is a wildcard that matches any string. Works exactly like the operator 'like', but is not case sensitive; e.g. 'bob%' will match any strings that don't start with 'bob', 'BOB', 'Bob', etc.",
    display_name: "SQL Case Insensitive Not Like",
    doc_url: null,
    id: 17,
    input_format: null,
    name: "notIlike",
  },
  "!regexp": {
    allow_negation: false,
    description:
      "column value doesn't contain a match for the regular expression pattern provided. Supports RE2 library regex syntax; e.g. '[Bb]ob$' will match any string that ends with 'bob' or 'Bob'. Case-sensitive, by default.",
    display_name: "Negated Regular Expression",
    doc_url: "https://github.com/google/re2/wiki/Syntax",
    id: 18,
    input_format: null,
    name: "negatedRegexp",
  },
  "!array_contains_equals": {
    allow_negation: false,
    description:
      "the list (array) -type column doesn't include the exact provided value anywhere in its list, ignoring upper/lower case",
    display_name: "List Doesn't Include Value",
    doc_url: null,
    id: 19,
    input_format: null,
    name: "notArrayContainsEquals",
  },
  "!array_contains_regexp": {
    allow_negation: false,
    description:
      "the list (array) -type column doesn't include a value containing a match for the provided regular expression anywhere in its list. Supports the RE2 library regex syntax.",
    display_name: "List Doesn't Include RegEx",
    doc_url: "https://github.com/google/re2/wiki/Syntax",
    id: 20,
    input_format: null,
    name: "notArrayContainsRegexp",
  },
  "!array_length_greater_than": {
    allow_negation: false,
    description:
      "the length of the list (array) -type column is less than or equal to the provided integer",
    display_name: "List Length Less Than or Equal",
    doc_url: null,
    id: 23,
    input_format: "int",
    name: "arrayLengthLessThanOrEqual",
  },
  "!array_only_includes": {
    allow_negation: false,
    description:
      "the list (array) -type column doesn't have values matching all of the values in the provided list or has other values. In other words, the column and the provided list are not equal aside from reordering.",
    display_name: "List Doesn't Only Include",
    doc_url: null,
    id: 24,
    input_format: "list",
    name: "notArrayOnlyIncludes",
  },
  array_length_less_than: {
    allow_negation: false,
    description:
      "the length of the list (array) -type column is less than the provided integer",
    display_name: "List Length Less Than",
    doc_url: null,
    id: 25,
    input_format: "int",
    name: "arrayLengthLessThan",
  },
  "!array_length_less_than": {
    allow_negation: false,
    description:
      "the length of the list (array) -type column is greater than or equal to the provided integer",
    display_name: "List Length Greater Than or Equal",
    doc_url: null,
    id: 26,
    input_format: "int",
    name: "arrayLengthGreaterThanOrEqual",
  },
  contains: {
    allow_negation: false,
    description:
      "column value contains the provided substring, ignoring upper/lower case",
    display_name: "Contains Substring",
    doc_url: null,
    id: 27,
    input_format: null,
    name: "contains",
  },
  "!contains": {
    allow_negation: false,
    description:
      "column value doesn't contain the provided substring, ignoring upper/lower case",
    display_name: "Does Not Contain Substring",
    doc_url: null,
    id: 28,
    input_format: null,
    name: "doesNotContain",
  },
};

// ** Operators mapping ***************
export const OPERATORS_MAP = {
  Contains: "contains",
  "SQL Case Insensitive Like": "ilike",
  Equal: "=",
  "Greater Than": ">",
  "Greater Than or Equal": ">=",
  Is: "is",
  "Less Than": "<",
  "Less Than or Equal": "<=",
  "SQL Like": "like",
  "List Includes Value": "array_contains_equals",
  "List Includes RegEx": "array_contains_regexp",
  "List Length Greater Than": "array_length_greater_than",
  "List Only Includes": "array_only_includes",
  "Not Equal": "!=",
  "One Of": "in",
  "Regular Expression": "regexp",
  "Not Any Of": "!in",
  "Is Not": "!is",
  "SQL Not Like": "!like",
  "SQL Case Insensitive Not Like": "!ilike",
  "Negated Regular Expression": "!regexp",
  "List Doesn't Include Value": "!array_contains_equals",
  "List Doesn't Include RegEx": "!array_contains_regexp",
  "List Length Less Than or Equal": "!array_length_greater_than",
  "List Doesn't Only Include": "!array_only_includes",
};

// ** Filter operator obj *************
export const FILTER_OPERATORS = {
  FINDING: [OPERATORS["="]],
  REPEATED: {
    STRING: [
      OPERATORS["="],
      OPERATORS.NotEqual,
      OPERATORS.ListIncludesValue,
      OPERATORS.ListIncludesRegex,
    ],
    DEFAULT: [OPERATORS["="], OPERATORS.NotEqual, OPERATORS.ListIncludesValue],
  },
  DEFAULT: {
    STRING: [
      OPERATORS["="],
      OPERATORS.NotEqual,
      OPERATORS.OneOf,
      OPERATORS.Is,
      OPERATORS.CaseInsensitiveLike,
      OPERATORS.like,
      OPERATORS.RegularExpression,
    ],
    INT64: [
      OPERATORS["="],
      OPERATORS.NotEqual,
      OPERATORS.LessThan,
      OPERATORS.LessThanOrEqual,
      OPERATORS.GreaterThan,
      OPERATORS.GreaterThanOrEqual,
      OPERATORS.OneOf,
      OPERATORS.Is,
    ],
    FLOAT64: [
      OPERATORS["="],
      OPERATORS.NotEqual,
      OPERATORS.LessThan,
      OPERATORS.LessThanOrEqual,
      OPERATORS.GreaterThan,
      OPERATORS.GreaterThanOrEqual,
      OPERATORS.OneOf,
      OPERATORS.Is,
    ],
    TIMESTAMP: [
      OPERATORS["="],
      OPERATORS.NotEqual,
      OPERATORS.LessThan,
      OPERATORS.LessThanOrEqual,
      OPERATORS.GreaterThan,
      OPERATORS.GreaterThanOrEqual,
      OPERATORS.OneOf,
      OPERATORS.Is,
    ],
    BOOL: [OPERATORS["="], OPERATORS.NotEqual, OPERATORS.Is],
    BYTES: [OPERATORS["="], OPERATORS.NotEqual, OPERATORS.OneOf, OPERATORS.Is],
  },
};

// ** Finding operators ***************
export const FINDING_OPERATORS = [
  {
    label: "Equals",
    value: "equal",
  },
];

// ** Event operators *****************
export const EVENT_OPERATORS = [
  {
    label: "Less Than",
    value: "lessThan",
  },
  {
    label: "Greater Than",
    value: "greaterThan",
  },
  {
    label: "Greater Than or Equal",
    value: "greaterThanOrEqual",
  },
  {
    label: "Less Than or Equal",
    value: "lessThanOrEqual",
  },
  {
    label: "Equals",
    value: "equal",
  },
  {
    label: "Does Not Equal",
    value: "doesNotEqual",
  },
  {
    label: "Like",
    value: "like",
  },
  {
    label: "Is Like",
    value: "ilike",
  },
  {
    label: "Is",
    value: "is",
  },
  {
    label: "In",
    value: "in",
  },
  {
    label: "Regex Expression",
    value: "regexp",
  },
  {
    label: "Array Contains Regex Expression",
    value: "arrayContainsRegexp",
  },
  {
    label: "Array Contains/Equals Regex Expression",
    value: "arrayContainsEquals",
  },
];
