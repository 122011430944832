//import ports from "./ports";
const matchStr = (searchVal) => {
  return searchVal ? !!searchVal.match(/.*/) : false;
};

/*
const drawPort = (column, model) => {
  return model[column.field];
};
*/

const entityFields = {
  type: {
    op: "eq",
    validate: (v) => {
      return false;
    },
  },
  user: {
    op: "contains",
    validate: matchStr,
  },
  deviceAddress: {
    op: "contains",
    validate: matchStr,
  },
  dstAddress: {
    op: "contains",
    validate: matchStr,
  },
  srcAddress: {
    op: "contains",
    validate: matchStr,
  },
  devname: {
    op: "contains",
    validate: matchStr,
  },
  vulnName: {
    op: "contains",
    validate: matchStr,
  },
  domain: {
    op: "contains",
    validate: matchStr,
  },
  action: {
    op: "contains",
    validate: matchStr,
  },
  activity: {
    op: "contains",
    validate: matchStr,
  },
  application: {
    op: "contains",
    validate: matchStr,
  },
  browser: {
    op: "contains",
    validate: matchStr,
  },
  deviceUUID: {
    op: "contains",
    validate: matchStr,
  },
  dstAddressXlated: {
    // not yet mapped
    op: "contains",
    validate: matchStr,
  },
  dstName: {
    op: "contains",
    validate: matchStr,
  },
  dstZone: {
    op: "contains",
    validate: matchStr,
  },
  email: {
    op: "contains",
    validate: matchStr,
  },
  /*
  hash: {
    op: "contains",
    validate: matchStr,
  },
  */
  mac: {
    op: "contains",
    validate: matchStr,
  },
  parentProcess: {
    op: "contains",
    validate: matchStr,
    bqMap: ["parent_process_name"],
  },
  process: {
    op: "contains",
    validate: matchStr,
    bqMap: ["process_name"],
  },
  protocol: {
    op: "contains",
    validate: matchStr,
  },
  result: {
    // not yet mapped
    op: "contains",
    validate: matchStr,
  },
  srcAddressXlated: {
    // not yet mapped
    op: "contains",
    validate: matchStr,
  },
  srcName: {
    op: "contains",
    validate: matchStr,
  },
  srcZone: {
    op: "contains",
    validate: matchStr,
  },
  targetUser: {
    op: "contains",
    validate: matchStr,
  },
  userUUID: {
    op: "contains",
    validate: matchStr,
  },
  url: {
    op: "contains",
    validate: matchStr,
  },
  urlPath: {
    op: "contains",
    validate: matchStr,
  },
  dstPort: {
    op: "eq",
    validate: (v) => String(Number(v)) === v && Number.isInteger(Number(v)),
  },
};

/* Self mutate for easy access */
Object.keys(entityFields).forEach((eF) => (entityFields[eF].fieldName = eF));

/** Entities
 *
 */

/*
const ENDPOINT_ENTITY = {
  name: "Endpoint",
  color: "#9EBEFB",
  queryFields: [
    entityFields.srcAddress,
    entityFields.dstAddress,
    entityFields.domain,
  ],
  matchFields: [
    entityFields.deviceAddress,
    entityFields.srcAddress,
    entityFields.dstAddress,
    entityFields.domain,
  ],
  overviewColumns: [
    { title: "Device Address", field: "deviceAddress", searchable: true },
    { title: "Source IP", field: "srcAddress", searchable: true },
    {
      title: "Port",
      field: "dstPort",
      renderValue: drawPort,
      searchable: true,
    },
    { title: "Destination IP", field: "dstAddress", searchable: true },
  ],
}
*/
const USER_ENTITY = {
  name: "User events",
  color: "#B0E6DD",
  queryFields: [
    entityFields.user,
    entityFields.email,
    entityFields.targetUser,
    entityFields.userUUID,
  ],
  matchFields: [
    entityFields.user,
    entityFields.email,
    entityFields.action,
    entityFields.activity,
    entityFields.application,
    entityFields.browser,
    entityFields.targetUser,
    entityFields.userUUID,
  ],
  overviewColumns: [
    { title: "User", field: "user", searchable: true },
    { title: "Email", field: "email", searchable: true },
  ],
};

const NETWORK_ENTITY = {
  name: "Traffic events",
  color: "#C5A3CC",
  queryFields: [
    entityFields.srcAddress,
    entityFields.dstAddress,
    entityFields.domain,
    entityFields.dstPort,
    entityFields.protocol,
    entityFields.dstZone,
    entityFields.url,
    entityFields.urlPath,
    entityFields.dstName,
    entityFields.srcName,
    entityFields.srcZone,
  ],
  matchFields: [
    entityFields.srcAddress,
    entityFields.dstAddress,
    entityFields.domain,
    entityFields.dstPort,
    entityFields.protocol,
    entityFields.dstZone,
    entityFields.url,
    entityFields.urlPath,
    entityFields.dstName,
    entityFields.srcName,
    entityFields.srcZone,
  ],
  overviewColumns: [],
};

const SYSTEM_ENTITY = {
  name: "System events",
  color: "#FAD3B5",
  queryFields: [
    entityFields.deviceAddress,
    entityFields.vulnName,
    entityFields.process,
    entityFields.parentProcess,
    entityFields.domain,
    entityFields.devname,
    entityFields.deviceUUID,
    entityFields.mac,
  ],
  matchFields: [
    entityFields.deviceAddress,
    entityFields.vulnName,
    entityFields.process,
    entityFields.parentProcess,
    entityFields.domain,
    entityFields.devname,
    entityFields.deviceUUID,
    entityFields.mac,
  ],
  overviewColumns: [
    { title: "Vuln Name", field: "vulnName", searchable: false },
    { title: "Process", field: "process", searchable: true },
    { title: "Parent Process", field: "parentProcess", searchable: true },
  ],
};

const entityTypes = [USER_ENTITY, SYSTEM_ENTITY, NETWORK_ENTITY];

// Association Types
const assoicationTypes = {
  logon: {
    entityType: USER_ENTITY,
  },
  logon_failure: {
    entityType: USER_ENTITY,
  },
  logoff: {
    entityType: USER_ENTITY,
  },
  process_create: {
    entityType: SYSTEM_ENTITY,
  },
  security: {
    entityType: SYSTEM_ENTITY,
  },
  traffic_allowed: {
    entityType: NETWORK_ENTITY,
  },
  traffic_blocked: {
    entityType: NETWORK_ENTITY,
  },
};

export { entityFields, entityTypes, assoicationTypes };
