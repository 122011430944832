import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

class Submitted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: props.isOpen,
    };
  }

  render() {
    const { backdrop, body, size } = this.props;

    return (
      <Modal
        zIndex={1500}
        isOpen={this.state.modal}
        className="submitted-modal bg-success border-success rounded text-white "
        backdrop={backdrop}
        size={size}
      >
        <ModalBody className="submitted-body align-middle bg-success border-success rounded text-center">
          {body}
        </ModalBody>
      </Modal>
    );
  }
}

Submitted.propTypes = {
  backdrop: PropTypes.bool,
  body: PropTypes.string,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
};

Submitted.defaultProps = {
  backdrop: false,
  body: null,
  isOpen: false,
  size: null,
};

export default Submitted;
