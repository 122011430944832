import React, { useCallback, useMemo, useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/**
 * useAlertMessage - a React hook that manages the props for AlertMessage
 *
 * Usage:
 *   const [alertMessageProps, displayMessage] = useAlertMessage();
 *   const handleSuccess = () => {
 *     displayMessage({
 *       severity: "success",  // See MUI Alert for possible values
 *       message: "Operation succeeded",
 *       autoHideDuration: 6000,  // default 3000
 *     });
 *   }
 *   return <AlertMessage {...alertMessageProps} />;
 *
 * @returns a 2-element array:
 *   * alertMessageProps object
 *   * displayMessage function
 */
export function useAlertMessage() {
  // This snackPack mechanism is documented here:
  // https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
  const [snackPack, setSnackPack] = useState([]);
  const [open, setOpen] = useState(false);
  const [messageInfo, setMessageInfo] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const displayMessage = useCallback((messageInfo) => {
    setSnackPack((prev) => [
      ...prev,
      { ...messageInfo, messageKey: new Date().getTime() },
    ]);
  }, []);

  const handleClose = useCallback((event, reason) => {
    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    setMessageInfo(undefined);
  }, []);

  const alertMessageProps = useMemo(
    () => ({
      open,
      ...messageInfo,
      handleClose,
      handleExited,
    }),
    [open, messageInfo]
  );
  return [alertMessageProps, displayMessage];
}

/**
 * AlertMessage - a React Snackbar component that can be used repeatedly.
 *
 * Usage: see usage of useAlertMessage above.
 *
 * @returns a React component
 */
export default function AlertMessage(props) {
  const placement = useMemo(
    () => ({
      vertical: props.vertical || "top",
      horizontal: props.horizontal || "center",
    }),
    [props.vertical, props.horizontal]
  );

  return (
    <div>
      <Snackbar
        anchorOrigin={placement}
        open={props.open}
        key={props.messageKey}
        autoHideDuration={props.autoHideDuration || 3000}
        onClose={props.handleClose}
        TransitionProps={{ onExited: props.handleExited }}
      >
        <Alert
          onClose={props.handleClose}
          severity={props.severity || "info"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
