export const integrationSchema = {
  $id: "/integration",
  title: "Integration",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    orgId: { type: "string", format: "uuid", readOnly: true },
    integrationType: { type: "string", createOnly: true },
    integrationName: {
      type: "string",
      maxLength: 255,
      pattern: "^[A-Za-z0-9 -]+$",
    },
    integrationStatus: {
      type: "string",
      readOnly: true,
      enum: ["error", "initializing", "ok", "deleted"],
    },
    integrationStatusMessage: { type: "string", readOnly: true },
    configuration: { type: "object", properties: {} }, // https://react-jsonschema-form.readthedocs.io/en/latest/usage/oneof/#anyof
    secret: { type: "object", properties: {} }, // see above
    lastStatusUpdate: { type: "string", format: "date-time", readOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
    createdBy: { type: "string", format: "uuid", readOnly: true },
    modified: { type: "string", format: "date-time", readOnly: true },
    modifiedBy: { type: "string", format: "uuid", readOnly: true },
    deleted: { type: "string", format: "date-time", readOnly: true },
    deletedBy: { type: "string", format: "uuid", readOnly: true },
  },
  required: ["integrationName"],
  additionalProperties: false,
};
