import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./PageHeader.sass";

const PageHeader = ({ children }) => (
  <div className="new-page-header">
    <div className="content">
      <div className="page-header-right">{children}</div>
    </div>
    <hr />
  </div>
);

PageHeader.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
  titleLink: PropTypes.shape({}).isRequired,
  theme: PropTypes.string,
};

PageHeader.defaultProps = {
  children: null,
};

const mapStateToProps = ({ page: { payload }, userInterface }) => ({
  path: `pages.${payload.toplevel}.${payload.secondlevel}`,
  titleLink: {
    type: "PAGE",
    // remove id1, id2, id3...
    payload: Object.entries(payload)
      .filter((i) => !i[0].match("^id[0-9]*$"))
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {}),
  },
  theme: userInterface.theme,
});

export default connect(mapStateToProps)(PageHeader);
