const initialState = {
  page: "",
  global: {},
};

const GlobalReducer = (state = initialState, action = {}) => {
  const page =
    action.type && action.type.startsWith("__PAGE__")
      ? action.type.substr(8)
      : "";
  let error = action.error;
  if (action.errorMessage) {
    error = { message: action.errorMessage };
  }
  if (page) {
    return {
      ...state,
      page,
    };
  }
  if (action.type === "GLOBAL_REDIRECT") {
    return {
      ...state,
      redirect: action.redirect,
    };
  }
  if (action.type === "GLOBAL_ERROR") {
    return {
      ...state,
      error,
    };
  }
  if (action.type === "GLOBAL_ERROR_CLEAR") {
    return {
      ...state,
      error: "",
    };
  }
  return {
    ...state,
    error,
  };
};

export default GlobalReducer;
