import { createSelector } from "reselect";

const selectSettings = (state) => state.session.settings;

const getRoleConstants = (settings) => {
  const { accountConstants = {} } = settings;
  const { roles = [] } = accountConstants;

  return roles.filter((x) => x.id !== 0);
};

// Returns all roles except the 'super'
export const roleConstantsSelector = createSelector(
  [selectSettings],
  getRoleConstants
);
