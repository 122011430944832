import React from "react";
import { Alert as BsAlert, Button } from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };

    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { reshowToken } = this.props;
    const { visible } = this.state;

    if (reshowToken !== prevProps.reshowToken) {
      if (!visible) {
        this.show();
      } else {
        // Make it leave and show up again, which can help de-mystify
        // what happened when an operation fails in different ways but as
        // a result of different actions
        this.hide();
        _.delay(() => {
          this.show();
        }, 200);
      }
    }
  }

  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  handleDismiss() {
    this.hide();
  }

  handleAction() {
    const { handleAction } = this.props;

    if (_.isFunction(handleAction)) {
      handleAction();
    } else {
      this.handleDismiss();
    }
  }

  render() {
    const { subtitle, message, details, color, actionName } = this.props;
    const { visible } = this.state;

    return (
      <BsAlert color={color} isOpen={visible} toggle={this.handleDismiss}>
        {message}
        {subtitle && <h2 className="alert-subtitle">{subtitle}</h2>}
        {details && <div className="alert-details">{details}</div>}
        <div>
          <Button color={color} onClick={() => this.handleAction()}>
            {actionName}
          </Button>
        </div>
      </BsAlert>
    );
  }
}

Alert.propTypes = {
  subtitle: PropTypes.node,
  message: PropTypes.node,
  details: PropTypes.node,
  color: PropTypes.string,
  actionName: PropTypes.string,
  handleAction: PropTypes.func,
  reshowToken: PropTypes.string,
};

Alert.defaultProps = {
  message: null,
  subtitle: null,
  details: null,
  color: "info",
  actionName: "Dismiss",
  handleAction: null,
  reshowToken: null,
};

export default Alert;
