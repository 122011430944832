const subnetSchema = {
  $id: "/subnet",
  title: "Subnet",
  type: "object",
  properties: {
    id: { type: "number" },
    network: { type: "string", maxLength: 255 },
    orgId: { type: "string" },
    ipTypeId: { type: "number", enum: [10, 20, 30] },
    name: { type: "string", maxLength: 255 },
    note: { type: "string" },
    pci: { type: "boolean" },
    hipaa: { type: "boolean" },
    critical: { type: "boolean" },
    servers: { type: "boolean" },
    workstations: { type: "boolean" },
    guest: { type: "boolean" },
    vpn: { type: "boolean" },
    wireless: { type: "boolean" },
    dhcp: { type: "boolean" },
    cloud: { type: "boolean" },
    zoneName: { type: "string", maxLength: 255 },
    location: { type: "string" },
  },
  required: ["name", "ipTypeId", "network"],
  additionalProperties: false,
};

export default subnetSchema;
