import { redirect } from "redux-first-router";

import { ResourcesAPI } from "../../lib/api";
import { graphParamsFromDelta } from "../../views/Pages/Resources/common/LogdevGraph";

import {
  fetchData as fetchIPsTable,
  receiveError as receiveIPsTableError,
} from "./common/Data";
import { fetchDataAndDates } from "./common/DataAndDates";
import { getGraphActions, getQueryGraph } from "./common/Graph";
import { fetchData as fetchIpGraph } from "./common/GraphAndTable";
import { receivePaginationParam } from "./common/Pagination";
import { receiveTimeInterval } from "./common/TimeInterval";

/*
Constants and helpers
*/

// 'graph...' actions will be imported from 'common'
export const ACTION_TYPE_PREFIX = "IP";
export const IP_GRAPH = "IP_GRAPH";
export const IP_RESET = "IP_RESET";

export const IP_SELECTED_INTERVAL_RECEIVE = "IP_SELECTED_INTERVAL_RECEIVE";

export const IP_ITEMS_DATA_RECEIVE = "IP_ITEMS_DATA_RECEIVE";
export const IP_ITEMS_ERROR_RECEIVE = "IP_ITEMS_ERROR_RECEIVE";
export const IP_ITEMS_META_RECEIVE = "IP_ITEMS_META_RECEIVE";

export const IP_PAGINATION_RECEIVE = "IP_PAGINATION_RECEIVE";

export const IP_TABLE_FILTERS_RECEIVE = "IP_TABLE_FILTERS_RECEIVE";

export const IP_TABLE_SORTING_RECEIVE = "IP_TABLE_SORTING_RECEIVE";

export const IP_TALKERS_DATA_RECEIVE = "IP_TALKERS_DATA_RECEIVE";
export const IP_TALKERS_DATES_RECEIVE = "IP_TALKERS_DATES_RECEIVE";
export const IP_TALKERS_ERROR_RECEIVE = "IP_TALKERS_ERROR_RECEIVE";

export const IP_THREATS_DATA_RECEIVE = "IP_THREATS_DATA_RECEIVE";
export const IP_THREATS_DATES_RECEIVE = "IP_THREATS_DATES_RECEIVE";
export const IP_THREATS_ERROR_RECEIVE = "IP_THREATS_ERROR_RECEIVE";

const LOGDEV_ID_PREFIX = "ip/";

const API = {
  resources: new ResourcesAPI(),
};

/*
Graph actions
*/

// Create the "receive" actions
const { receiveGraphAll, receiveError } = getGraphActions(
  IP_GRAPH,
  graphParamsFromDelta
);

// Action that fetches and stores all graph data
export const fetchGraph = fetchIpGraph({
  apiCall: API.resources.fetchIpGraph.bind(API.resources),
  getQuery: getQueryGraph,
  nameStr: "graph",
  receive: receiveGraphAll,
  receiveError,
});

export const fetchIPs = fetchIPsTable({
  actionTypePrefix: `${ACTION_TYPE_PREFIX}_ITEMS`,
  apiCall: API.resources.fetchIPs.bind(API.resources),
});

export const clearIPsError = receiveIPsTableError(
  `${ACTION_TYPE_PREFIX}_ITEMS`
)(null);

// Selected Time Interval
export const {
  receiveDateRange,
  receiveRelativeInterval,
  receiveRelativeStart,
  receiveSelectedInterval,
} = receiveTimeInterval(IP_SELECTED_INTERVAL_RECEIVE);

// Talkers
export const fetchTalkers = fetchDataAndDates({
  actionTypePrefix: `${ACTION_TYPE_PREFIX}_TALKERS`,
  logdevIdPrefix: LOGDEV_ID_PREFIX,
  apiCall: API.resources.fetchTalkers.bind(API.resources),
});

// Threats
export const fetchThreats = fetchDataAndDates({
  actionTypePrefix: `${ACTION_TYPE_PREFIX}_THREATS`,
  logdevIdPrefix: LOGDEV_ID_PREFIX,
  apiCall: API.resources.fetchThreats.bind(API.resources),
});

export const receivePagination = receivePaginationParam(IP_PAGINATION_RECEIVE);

export const receiveTableFilters = receivePaginationParam(
  IP_TABLE_FILTERS_RECEIVE
);

export const receiveTableSorting = receivePaginationParam(
  IP_TABLE_SORTING_RECEIVE
);

export const redirectToIndex = (orgId) =>
  redirect({
    type: "PAGE",
    payload: {
      orgId,
      toplevel: "investigate",
      secondlevel: "ips",
    },
  });
