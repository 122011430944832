import API from "./API2";
import ModelMap from "../models";
import { Query } from "../models/Query";
import uuid from "uuid";
import Schema from "../schema";
import { ORDER_BY_DESC } from "../schema/Request";

const RESULT_PARAMS = ["orderBy", "transform", "page", "outputFields"];

export const compareQueryParameters = (a, b) => {
  const aQ = Object.values(a.queryParams || {}).map((qp) => qp.toString());
  const bQ = Object.values(b.queryParams || {}).map((qp) => qp.toString());
  return (
    !aQ.find((aK) => !bQ.includes(aK)) &&
    aQ.length === bQ.length &&
    a.namespace === b.namespace
  );
};

const MUTABLE_QP_ATTR = ["field", "operator", "negate", "value"];

const downloadExportLink = (signedUrl) => {
  window.open(signedUrl, "_blank");
};

export class QueryParameter {
  /**
   * @param {object} querySearch - field, negate, operator and value
   */

  constructor(querySearch) {
    const { field, operator, negate, value } = querySearch || {};
    this.uuid = uuid.v4();

    this.boolParam = false;
    if (RESULT_PARAMS.includes(field)) {
      throw new Error(
        `Use of reserved key ${field} in a QueryParameter is prohibited`
      );
    }
    this.field = field;
    this.operator = operator || "eq";
    this.negate = negate;
    this.value = value;
  }

  set(fieldName, fieldValue) {
    if (MUTABLE_QP_ATTR.includes(fieldName)) {
      if (fieldName === "field" && RESULT_PARAMS.includes(fieldName)) {
        throw new Error(
          `Use of reserved key ${fieldName} in a QueryParameter is prohibited`
        );
      }

      this[fieldName] = fieldValue;
    }
  }

  toString() {
    if (!this.negate && this.operator === "") {
      return this.field;
    } else {
      return `${this.field}.${this.negate ? "!" : ""}${this.operator}`;
    }
  }

  toObject() {
    const obj = {
      uuid: this.uuid,
      isBoolParam: !!this.boolParam,
      field: this.field,
      operator: this.operator,
      negate: this.negate,
      value: this.value,
    };

    // fast clone to break reference
    return JSON.parse(JSON.stringify(obj));
  }

  static fromObject(obj) {
    const cls = new QueryParameter(obj);
    if (obj.uuid) {
      cls.uuid = obj.uuid;
    }
    return cls;
  }
}

/*
create bool cluster joins multiple qps, returns uuid
add too cluster requires uuid, takes in multiple qp
*/

export class BooleanParameter {
  /**
   * @param {string} operator - valid API operator
   */

  constructor(operator) {
    this.uuid = uuid.v4();
    this.operator = operator || "OR";
  }
}

export default class Request {
  /**
   * @param {string} namespace - valid model/schema namespace
   * @param {array} queryParams - list of objects with key and value attributes: {'key':'name.contains', 'value':'foo'}
   * @param {object} resultParams - object of resultParams (see resultParamsSchema)
   *
   */
  constructor(namespace, queryParams, resultParams) {
    this.dirty = true;
    this.model = ModelMap[namespace];
    this.schema = new this.model().getSchema();
    this.namespace = this.schema.schema["$id"];
    this.refNameMap = {};
    this.queryParams = {};
    this.boolMap = {};

    if (Array.isArray(queryParams)) {
      queryParams.forEach((qp) => this.setQueryParam(qp));
    } else {
      if (queryParams !== undefined) {
        throw new Error(
          `Invalid type ${typeof queryParams} for queryParams in ${
            this.namespace
          }`
        );
      }
    }

    this.resultParams = {};
    if (resultParams) {
      this.resultParamsSortedKeys(resultParams).forEach((key) => {
        this.setResultParam(key, resultParams[key]);
      });
    }

    this._defaults = {
      queryParams: {},
      resultParams: {},
    };
  }

  resultParamsSortedKeys(resultParams) {
    // Special keys must be set first, in the specified order
    const specialKeys = ["transform", "outputFields"];
    const allKeys = Object.keys(resultParams).sort();
    const ret = [];
    specialKeys.forEach((key) => {
      if (key in resultParams) {
        ret.push(key);
      }
    });
    allKeys.forEach((key) => {
      if (!specialKeys.includes(key)) {
        ret.push(key);
      }
    });
    return ret;
  }

  toQuery() {
    return new Query({
      namespace: this.namespace.replace("/", ""),
      queryParams: Object.values(this.queryParams).map((qp) => {
        const o = qp.toObject();
        delete o.uuid;
        delete o.isBoolParam;
        return o;
      }),
      resultParams: {
        ...this.resultParams,
        transform:
          this.resultParams.transform?.map((qp) => {
            const o = qp.toObject();
            delete o.uuid;
            delete o.isBoolParam;
            return o;
          }) || [],
      },
    });
  }

  fromQuery(query) {
    this.namespace = `/${query.namespace}`;
    query.queryParams.forEach((qp) => this.setQueryParam(qp));
    Object.entries(query.resultParams).forEach(([k, v]) =>
      this.setResultParam(k, v)
    );
  }

  isField(fieldName, allowAlias) {
    const validFields = Object.keys(this.schema.schema.properties);
    if (allowAlias && this.resultParams.transform) {
      this.resultParams.transform.forEach((t) => validFields.push(t.value));
    }
    if (!validFields.includes(fieldName)) {
      if (
        this.schema.schema.additionalProperties === false &&
        (!this.resultParams.outputFields ||
          !this.resultParams.outputFields.includes(fieldName))
      ) {
        throw new Error(
          `Field ${fieldName} is not a valid field for ${this.namespace}.`
        );
      }
      return false;
    } else {
      return true;
    }
  }

  handleModel(model, query) {
    console.warn(
      "DEPRECATION WARNING: handleModel is being called, which means a model instance is being passed into Request"
    );
    return {
      namespace: model.getSchema().schema["$id"],
      queryParams: Object.keys(model.params).map((f) => {
        return { key: `${f}.eq`, value: model.params[f] };
      }),
    };
  }

  /**
   * Saves current resultParam and queryParam as default values
   * available for future restoration.
   */
  setDefaults() {
    this.setDefaultQueryParams();
    this.setDefaultResultParams();
  }

  /**
   * Restores resultParam and queryParam from saved values
   */
  restoreDefaults() {
    this.restoreDefaultQueryParams();
    this.restoreDefaultResultParams();
  }

  // Set Defaults
  setDefaultQueryParams() {
    this._defaults.queryParams = {};
    Object.keys(this.queryParams).forEach((uuid) => {
      this._defaults.queryParams[uuid] = this.queryParams[uuid].toObject();
    });
  }

  setDefaultResultParams() {
    this._defaults.resultParams = {};
    Object.keys(this.resultParams).forEach((key) => {
      this._defaults.resultParams[key] = this.resultParams[key];
    });
  }

  // Restore Defaults
  restoreDefaultQueryParams() {
    this.queryParams = {};
    // This should support lists of QueryParameter for OR support
    Object.values(this._defaults.queryParams).forEach((obj) => {
      const qp = QueryParameter.fromObject(obj);
      this.queryParams[qp.uuid] = qp;
    });
  }

  restoreDefaultResultParams() {
    this.resultParams = {};
    Object.keys(this._defaults.resultParams).forEach((key) => {
      this.setResultParam(key, this._defaults.resultParams[key]);
    });
  }

  setResultParam(key, resultParam) {
    const validate = Schema.getSchema("#resultParamsSchema");
    if (validate({ [key]: resultParam }) === false) {
      throw new Error(validate.errors.map((ve) => ve.message));
    } else {
      if (key === "orderBy") {
        resultParam.forEach((field) => {
          if (!this.isField(field.key, true)) {
            throw new Error(`Field ${field.key} is not in the model.`);
          }
        });
      } else if (key === "outputFields") {
        resultParam.forEach((field) => {
          if (
            !this.isField(field, true) &&
            this.schema.schema.additionalProperties === false
          ) {
            throw new Error(`Field ${field} is not in the model.`);
          }
        });
      }

      if (key === "transform") {
        this.resultParams[key] = resultParam.map(
          (params) => new QueryParameter(params)
        );
      } else {
        // fast clone to break reference
        this.resultParams[key] = JSON.parse(JSON.stringify(resultParam));
      }
    }
  }

  setQueryParam(queryParam) {
    if (Array.isArray(queryParam)) {
      const orMap = queryParam.map((_qp) => this.setQueryParam(_qp));
      this.createBooleanSet(orMap);
    } else {
      const qp = new QueryParameter(queryParam);
      this.isField(qp.field);
      this.queryParams[qp.uuid] = qp;
      if (queryParam.refName) {
        this.refNameMap[queryParam.refName] = qp.uuid;
      }
      this.dirty = true;
      return qp.uuid;
    }
  }

  updateQueryParam(uuid, queryParam) {
    const qp = this.queryParams[uuid];
    if (!qp) {
      throw new Error(`Unknown QueryParameter ${uuid}.`);
    }
    Object.keys(queryParam).forEach((k) => qp.set(k, queryParam[k]));
    this.dirty = true;
    return uuid;
  }

  deleteQueryParam(uuid) {
    if (this.queryParams[uuid]) {
      delete this.queryParams[uuid];
    } else {
      throw new Error(`Invalid QueryParameter ID: ${uuid}`);
    }
    this.dirty = true;
  }

  // create bool cluster joins multiple qps, returns uuid
  createBooleanSet(uuidList) {
    const bp = new BooleanParameter();
    this.boolMap[bp.uuid] = bp;
    uuidList.forEach((uuid) => {
      if (!this.queryParams[uuid]) {
        throw new Error(`Invalid QueryParameter ID: ${uuid}`);
      }
      this.queryParams[uuid].boolParam = bp.uuid;
    });
    return bp;
  }

  // add too cluster requires uuid, takes in multiple qp
  addQueryParamToBooleanSet(uuidList, uuid) {
    const bp = this.boolMap[uuid];
    if (!bp) {
      throw new Error(`Unknown BooleanParameter ${uuid}.`);
    }
    uuidList.forEach((uuid) => {
      if (!this.queryParams[uuid]) {
        throw new Error(`Invalid QueryParameter ID: ${uuid}`);
      }
      this.queryParams[uuid].boolParam = uuid;
    });
  }

  compile() {
    const params = {};
    const orParams = [];
    const compiledUUIDs = [];

    Object.values(this.queryParams).forEach((qp) => {
      if (!compiledUUIDs.includes(qp.uuid)) {
        // check for BooleanParameter
        if (qp.boolParam) {
          const mapped = Object.keys(this.queryParams).filter(
            (qpUUID) => this.queryParams[qpUUID].boolParam === qp.boolParam
          );
          mapped.forEach((uuid) => compiledUUIDs.push(uuid));
          orParams.push(
            mapped.map((uuid) => {
              return {
                [this.queryParams[uuid].toString()]:
                  this.queryParams[uuid].value,
              };
            })
          );
        } else {
          const key = qp.toString();
          if (!params[key]) {
            params[key] = [];
          }
          params[key].push(qp.value);
          compiledUUIDs.push(qp.uuid);
        }
      }
    });

    // compile orderBy
    const stringParams = [];
    if (this.resultParams.orderBy) {
      this.resultParams.orderBy.forEach((p) => {
        if (p.value === ORDER_BY_DESC) {
          stringParams.push(`-${p.key}`);
        } else {
          stringParams.push(p.key);
        }
      });
    }

    if (stringParams.length > 0) {
      params["orderBy"] = stringParams.join(",");
    }

    // compile page
    if (this.resultParams.page) {
      const pageNumber = this.resultParams.page.number || 0;
      const pageCount = this.resultParams.page.count || 100;
      params["page"] = `${pageNumber}:${pageCount}`;
    }

    // compile outputFields
    if (this.resultParams.outputFields) {
      params["outputFields"] = this.resultParams.outputFields.join(",");
    }

    // compile transform
    if (this.resultParams.transform && this.resultParams.transform.length > 0) {
      this.resultParams.transform.forEach((transform) => {
        const fieldKey = transform.field
          ? `${transform.field}.${transform.operator}`
          : transform.operator;
        params[fieldKey] = transform.value;
      });
      if (params["outputFields"]) {
        params["outputFields"] = params["outputFields"]
          .split(",")
          .filter((f) => f !== "id")
          .join(",");
      }
    }

    if (this.resultParams.outputType) {
      params["outputType"] = this.resultParams.outputType;
    }

    return { andParams: params, orParams };
  }

  get() {
    const api = new API();
    const { andParams, orParams } = this.compile();
    const response = api
      .get(this.namespace, andParams, orParams)
      .then((data) => {
        // handle exports
        if (data.statusCode === 201 && data.message) {
          downloadExportLink(data.message);
          return [];
        } else {
          if (!Array.isArray(data.data)) {
            return new this.model(data.data);
          } else {
            return data.data.map((obj) => {
              const m = new this.model(obj);
              m.setDirty(false);
              return m;
            });
          }
        }
      });
    this.dirty = false;
    return response;
  }

  delete() {
    const api = new API();
    const response = api.delete(this.model.schema, this.model.params.id);
    this.dirty = false;
    return response;
  }
}
