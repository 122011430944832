import { createSelector } from "reselect";

import { DEFAULT_TIME_INTERVAL as SECURITY_DEFAULT_TIME_INTERVAL } from "../views/Pages/Security/common/securityConfig";

import { DEFAULT_TIME_INTERVAL as SENSOR_DEFAULT_TIME_INTERVAL } from "../views/Pages/Sensor/common/constants";

const selectLocation = (state) => state.location;

const getTimeInterval = (defaultTimeInterval, location) => {
  const { query = {} } = location;

  const { timeInterval = defaultTimeInterval } = query;

  return timeInterval;
};

export const makeTimeIntervalSelector = (defaultTimeInterval) =>
  createSelector([() => defaultTimeInterval, selectLocation], getTimeInterval);

export const securityTimeIntervalSelector = makeTimeIntervalSelector(
  SECURITY_DEFAULT_TIME_INTERVAL
);

export const sensorTimeIntervalSelector = makeTimeIntervalSelector(
  SENSOR_DEFAULT_TIME_INTERVAL
);
