import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "graphql",
};

export class PostingAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  list(...args) {
    return this.get(...args);
  }

  get() {
    const query = `
      query Posting {
        listPostings {
          id
          created
          created_by
          attachments {
            id
            name
          }
          body
          published
          tags {
            id
            name
          }
          title
        }
      }
    `;
    const data = {};
    return super
      .get({ query, data })
      .then(({ listPostings }) => ({ data: listPostings }));
  }
}
