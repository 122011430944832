import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";

import FilterField from "./FilterField";
import Chip from "@mui/material/Chip";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

const PREFIX = "FilterEditor";
const classes = {
  chip: `${PREFIX}-chip`,
  filterFieldPaper: `${PREFIX}-filterFieldPaper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.chip}`]: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 180,
    margin: 3,
  },
  [`& .${classes.filterFieldPaper}`]: {
    display: "flex",
    alignItems: "flex-start",
    padding: `${theme.shape.padding}px`,
  },
}));

const FilterChip = (props) => {
  const fieldConfig = props.fields.find((fc) => fc.field === props.field) || {};
  const mappedValue = fieldConfig.options?.find((f) => f.value === props.value);
  const label = `${fieldConfig.display_name || props.field} ${
    props.negate ? `is not ${props.operator}` : props.operator
  } ${mappedValue ? mappedValue.label : props.value}`;
  return (
    <Chip
      size="small"
      key={`searchFieldChip_${props.uuid}`}
      label={label}
      disabled={props.disabled}
      title={label}
      color={props.negate ? "secondary" : "primary"}
      onDelete={() => props.onDelete(props.uuid)}
      onClick={(event) => props.onClick(event, props.uuid)}
      className={props.classes.chip}
    />
  );
};
// ** Proptypes ***********************
FilterChip.propTypes = {
  uuid: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  negate: PropTypes.bool.isRequired,
  fields: PropTypes.array,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
};

// ** Default props *******************
FilterChip.defaultProps = {
  negate: false,
  operator: "eq",
  fields: [],
  disabled: false,
};

const FilterEditor = (props) => {
  const [controls, setControls] = useState([]);
  const [selectedControl, setSelectedControl] = useState(props.selected);
  const [filterPickerAnchorEl, setFilterPickerAnchorEl] = useState(null);

  useEffect(() => {
    if (props.controls) {
      // If filtering on /bq endpoint (report builder), then remove type field filter chip from appearing in the advanced menu.
      if (props.namespace === "/bq") {
        setControls(
          Object.keys(props.controls).filter(
            (uuid) =>
              props.controls[uuid].field !== "created" &&
              props.controls[uuid].field !== "type" &&
              props.controls[uuid].field !== "query.id"
          )
        );
        // When filtering on another endpoint (just findings right now), then allow the type field filter chip to display in the advanced menu.
      } else {
        setControls(
          Object.keys(props.controls).filter(
            (uuid) =>
              props.controls[uuid].field !== "created" &&
              props.controls[uuid].field !== "query.id"
          )
        );
      }
    }
  }, [props.controls, props.interface]);

  const onClose = () => {
    setFilterPickerAnchorEl(null);
  };

  const onClick = (event, uuid) => {
    setSelectedControl(uuid);
    setFilterPickerAnchorEl(event.currentTarget);
  };

  const onAddFilter = (event) => {
    setSelectedControl(null);
    setFilterPickerAnchorEl(event.currentTarget);
  };

  return (
    <Root
      style={{
        display: "flex",
      }}
    >
      <Button variant={"outlined"} onClick={onAddFilter}>
        Add Filter
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {controls
          .filter((uuid) => props.controls[uuid])
          .map((uuid) => (
            <FilterChip
              key={uuid}
              disabled={props.controls[uuid].isBoolParam}
              classes={classes}
              {...props.controls[uuid]}
              fields={props.fields}
              onClick={onClick}
              onDelete={props.interface.remove}
            />
          ))}
      </div>
      <Popover
        id="filterPickerAnchorEl"
        anchorEl={filterPickerAnchorEl}
        open={!!filterPickerAnchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <FilterField
          namespace={props.namespace}
          control={props.controls[selectedControl]}
          interface={props.interface}
          fields={props.fields}
          onComplete={onClose}
          actions={props.actions}
          elevation={8}
          componentStyles={"reporting"}
        />
      </Popover>
    </Root>
  );
};

// ** Proptypes ***********************
FilterEditor.propTypes = {
  selected: PropTypes.string,
  controls: PropTypes.object.isRequired,
  interface: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  namespace: PropTypes.string.isRequired,
};

// ** Default props *******************
FilterEditor.defaultProps = {
  selected: null,
  namespace: "/bq",
};

export default FilterEditor;
