const categorySchema = {
  $id: "/category",
  title: "Category",
  type: "object",
  properties: {
    id: { type: "number" },
    description: { type: "string" },
    display_name: { type: "string" },
    earliest_available: { type: "string", format: "date-time" },
    log_type_names_for_org: { type: "array" },
    name: { type: "string" },
    num_log_types: { type: "number" },
    num_log_types_for_org: { type: "number" },
  },
};

export default categorySchema;
