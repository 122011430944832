import React, { useState } from "react";
import PropTypes from "prop-types";

import HelpToolTip from "../../Components/HelpToolTip";
import CheckBox from "../../Components/CheckBox";

/*
Constants and Helpers
*/

const TOOLTIP_TEXT = `
  Unless you check this box, the email with sensor install instructions
  will be sent to you as well as to all members of your team with the
  "Administrator" role.
`;

const getRandom = () => Math.random().toString().slice(2);

/*
Main component
*/
const BroadcastCheckBox = (props) => {
  const { noBroadcast, handleChange } = props;

  const [targetId] = useState(`broadcast_status_tooltip_${getRandom()}`);
  const [tooltipOpen, setTooltipState] = useState(false);

  const toggleTooltip = () => {
    setTooltipState(!tooltipOpen);
  };

  return (
    <React.Fragment>
      <CheckBox
        title="Email sensor installation link only to me"
        checked={noBroadcast}
        onChange={handleChange}
      />

      <HelpToolTip
        targetId={targetId}
        isOpen={tooltipOpen}
        toggle={toggleTooltip}
      >
        {TOOLTIP_TEXT}
      </HelpToolTip>
    </React.Fragment>
  );
};

// PropTypes
BroadcastCheckBox.propTypes = {
  noBroadcast: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
};

BroadcastCheckBox.defaultProps = {
  noBroadcast: false,
};

export default BroadcastCheckBox;
