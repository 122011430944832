import { API, setAxiosOrgHeader } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "license/active",
};

export class LicenseAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  get(orgId) {
    setAxiosOrgHeader(orgId);
    return super.get();
  }
}
