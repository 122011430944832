export const getCurrentPlanAndInvoiceData = (stripeCustomerObj) => {
  const currentSubscription = stripeCustomerObj?.subscriptions?.data[0] || null;

  let planData = { isPaid: false };
  let invoiceData = {};

  // consider both 'active' *and* 'trialing' subscriptions
  if (
    currentSubscription?.status === "active" ||
    currentSubscription?.status === "trialing"
  ) {
    planData = {
      priceId: currentSubscription.plan.id,
      invoiceEmail: stripeCustomerObj.email,
      subscriptionId: currentSubscription.id,
      productId: currentSubscription.plan.product,
      renewalDate: currentSubscription.current_period_end,
      isInTrial: currentSubscription.status === "trialing",
      trialEndDate: currentSubscription.trial_end,
      paymentMethod: stripeCustomerObj.paymentMethod || "",
    };

    invoiceData = {
      isPaid: currentSubscription.latest_invoice,
      nextIssueDate: currentSubscription.current_period_end,
      invoiceTotal: `$${currentSubscription.latest_invoice.amount_due / 100}`,
      knowledgeWorkers: currentSubscription?.latest_invoice?.lines
        ? currentSubscription.latest_invoice.lines.data.slice(-1)[0].quantity
        : undefined,
    };
  } else if (stripeCustomerObj) {
    // if we have a customer and no active
    // subscription users can still edit email
    planData = { invoiceEmail: stripeCustomerObj.email };
  }

  return {
    planData,
    invoiceData,
  };
};

export const formatProducts = (prices, plans) => {
  // Filter prices/products based on segment metadata
  let activePrices = prices.data.filter(
    (price) => price.product.active === true
  );

  // Create a map of license values to prices
  const priceMap = new Map();
  activePrices.forEach((price) => {
    const licenseValue = price.product.metadata.license;
    priceMap.set(licenseValue, price);
  });

  // Extend plans with matching price IDs
  const extendedPlans = plans.map((plan) => {
    const matchedPrice = priceMap.get(plan.licenseValue);
    const updatedPlan = { ...plan };

    if (matchedPrice) {
      updatedPlan.stripeConfig = {
        ...plan.stripeConfig,
        priceId: matchedPrice.id,
      };
    }

    if (plan.optionalUpgradeLicense) {
      const matchedUpgradePrice = priceMap.get(
        plan.optionalUpgradeLicense.licenseValue
      );

      if (matchedUpgradePrice) {
        updatedPlan.optionalUpgradeLicense = {
          ...plan.optionalUpgradeLicense,
          stripeConfig: {
            ...plan.optionalUpgradeLicense.stripeConfig,
            priceId: matchedUpgradePrice.id,
          },
        };
      }
    }

    return updatedPlan;
  });

  return extendedPlans;
};
