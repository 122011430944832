import React, { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as Actions from "../../../redux/actions";
import StatesPieChart from "./StatesPieChart";
import WorldMap from "./Worldmap";
import { getOrgUsers, withRole } from "../../../redux/utils/users";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Finding
import FindingCard from "../../Components/Finding/Card";
import { CircularProgress } from "@mui/material";

const PREFIX = "Dashboard2";

const classes = {
  root: `${PREFIX}-root`,
  moreFindingsButton: `${PREFIX}-moreFindingsButton`,
  loading: `${PREFIX}-loading`,
  sortedDataContainer: `${PREFIX}-sortedDataContainer`,
  sortedDataText: `${PREFIX}-sortedDataText`,
  findingNumberText: `${PREFIX}-findingNumberText`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    width: "100%",
    flexGrow: 1,
  },
  [`& .${classes.moreFindingsButton}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.loading}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  [`& .${classes.sortedDataContainer}`]: {
    flexDirection: "column",
    marginRight: theme.shape.padding,
  },
  [`& .${classes.sortedDataText}`]: {
    margin: 0,
    color: theme.palette.grey[400],
  },
  [`& .${classes.findingNumberText}`]: {
    fontSize: 22,
    marginBottom: 0,
    fontWeight: "bold",
  },
}));

const Dashboard = (props) => {
  const {
    orgId,
    findingsStatesPieData: { firstLoadComplete },
  } = props;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevOrgId = usePrevious(orgId);

  useEffect(() => {
    // if we have not processed the data queries
    // or a user changes orgs, let's get fresh data
    if (
      props.ready &&
      (!firstLoadComplete || (prevOrgId && prevOrgId !== orgId))
    ) {
      refreshData();
    }
  }, [orgId, firstLoadComplete, props.ready]);

  const refreshData = () => {
    props.processAllDataQueries(props.orgId);
    props.fetchUsersForOrg(props.orgId);
  };

  const ownerUsers = props.orgUsers.reduce((all, x) => {
    return { ...all, [x.id]: x };
  }, {});

  const retryDataQuery = (dataQueryKey) => {
    props.processDataQuery(props.orgId, dataQueryKey);
  };

  const gotoFindings = () =>
    props.dispatch({
      type: "PAGE",
      payload: {
        orgId: props.orgId,
        toplevel: "reporting",
        secondlevel: "findings",
      },
    });

  const { sourceLocationMapData, findingsStatesPieData } = props;

  return (
    <Root className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {props.ready && (
            <div className={classes.moreFindingsButton}>
              <div className={classes.sortedDataContainer}>
                <p className={classes.findingNumberText}>
                  {props.findings
                    ? `${props.findings.length} Unresolved Findings`
                    : "No Unresolved Findings"}
                </p>
                {props.findings && (
                  <p className={classes.sortedDataText}>
                    {" "}
                    Past month, sorted by newest first
                  </p>
                )}
              </div>
              <Button
                variant="contained"
                color="primary"
                onClick={gotoFindings}
                datacy={"viewMoreFindingsButton"}
              >
                View more findings
              </Button>
            </div>
          )}
          {!props.ready ? (
            <div className={classes.loading}>
              <p>Fetching unresolved findings from the past month...</p>
              <CircularProgress />
            </div>
          ) : (
            props.findings &&
            props.findings.map((finding) => (
              <FindingCard
                key={finding.id}
                {...finding}
                constants={props.constants}
                ownerUsers={ownerUsers}
                dispatch={props.dispatch}
              />
            ))
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="h6">Finding States</Typography>
          <StatesPieChart
            chartData={findingsStatesPieData}
            handleRetry={() => {
              retryDataQuery(findingsStatesPieData.key);
            }}
          />
          <Typography variant="h6">Finding Source Locations</Typography>
          <WorldMap
            mapData={sourceLocationMapData}
            handleRetry={() => {
              retryDataQuery(sourceLocationMapData.key);
            }}
          />
        </Grid>
      </Grid>
    </Root>
  );
};

Dashboard.propTypes = {
  fetchUsersForOrg: PropTypes.func.isRequired,
  findingsStatesPieData: PropTypes.shape({}).isRequired,
  orgId: PropTypes.string.isRequired,
  processAllDataQueries: PropTypes.func.isRequired,
  processDataQuery: PropTypes.func.isRequired,
  pageRole: PropTypes.string.isRequired,
  scrollerStats: PropTypes.shape({}).isRequired,
  sourceLocationMapData: PropTypes.shape({}).isRequired,
  orgUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (
  { dataQuery: { dataQueries }, users, settings, location },
  { pageRole }
) => {
  const orgId = location.payload.orgId;
  const orgUsers = getOrgUsers({ usersStore: users, orgId });

  return {
    findingsStatesPieData: dataQueries.DASHBOARD_PIE_CHART,
    scrollerStats: dataQueries.DASHBOARD_SCROLLER_STATS.visualizationData,
    sourceLocationMapData: dataQueries.DASHBOARD_WORLD_MAP,
    orgUsers: withRole(orgUsers, pageRole),
    orgId,
  };
};

const mapDispatchToProps = (dispatch, { orgId, user }) => ({
  processDataQuery: (orgId, dataQueryKey) =>
    dispatch(Actions.DataQuery.processDataQueries(orgId, dataQueryKey)),
  processAllDataQueries: (orgId) =>
    dispatch(Actions.DataQuery.processDataQueries(orgId)),
  fetchUsersForOrg: (orgId) =>
    dispatch(Actions.User.fetchUsersForOrgWithPageActions(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
