import React from "react";

import CloseIcon from "@mui/icons-material/Close";

import { DialogContent, DialogTitle } from "@mui/material";

import { SnapshotModal, dialogClasses } from "./styles.js";

import compressedSummary from "../../../images/compressedSummary.png";

const SnapshotDialog = (props) => {
  return (
    <SnapshotModal open={props.open} onClose={props.onClose}>
      <div className={dialogClasses.dialogHeader}>
        <DialogTitle>Executive Summaries Sample (not live data)</DialogTitle>
        <CloseIcon className={dialogClasses.close} onClick={props.onClose} />
      </div>
      <DialogContent className={dialogClasses.dialogContent}>
        <img
          src={compressedSummary}
          className={dialogClasses.dialogImage}
          alt={"Executive Summaries"}
        />
      </DialogContent>
    </SnapshotModal>
  );
};

export default SnapshotDialog;
