import React from "react";
import PropTypes from "prop-types";

import { Container } from "reactstrap";
import SupportEntry from "../../Components/Finding/SupportEntry";

const ParsingErrors = ({ columnNumLeft, columnNumRight, parsingErrors }) => {
  const noErrors = !parsingErrors || !parsingErrors.length;

  return (
    <div>
      <Container className="list-container dark">
        {noErrors && (
          <SupportEntry
            columnNumLeft={columnNumLeft}
            columnNumRight={columnNumRight}
            label="Parsing errors"
            value="0"
          />
        )}

        {(parsingErrors || []).map((elt, index) => (
          <SupportEntry
            key={index}
            columnNumLeft={columnNumLeft}
            columnNumRight={columnNumRight}
            label={`Parsing errors: ${elt.type}`}
            value={(elt.count || 0).toLocaleString()}
          />
        ))}
      </Container>
    </div>
  );
};

ParsingErrors.propTypes = {
  columnNumLeft: PropTypes.number,
  columnNumRight: PropTypes.number,
  parsingErrors: PropTypes.arrayOf(PropTypes.shape({})),
};

ParsingErrors.defaultProps = {
  columnNumLeft: 4,
  columnNumRight: 8,
  parsingErrors: [],
};

export default ParsingErrors;
