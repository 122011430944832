import { BaseModel } from "./BaseModel";
import BQSummarySchema from "../schema/BQSummary";
import Codex from "@blumira/blu_constants";
import Schema from "../schema";

export const DATA_TYPE_MAP = {
  STRING: "string",
  INT64: "number",
  FLOAT64: "number",
  TIMESTAMP: "string",
  BOOL: "boolean",
  BYTES: "string",
};

export const fieldMapping = {
  // backend: frontend
  created: { key: "created" },
  logger: { key: "logger" },
  device_address: { key: "deviceAddress" },
  device_address_hex: { key: "deviceAddressHex" },
  devname: { key: "devname" },
  type: { key: "type" },
  association_type: { key: "associationType" },
  src_address: { key: "srcAddress" },
  src_address_hex: { key: "srcAddressHex" },
  dst_address: { key: "dstAddress" },
  dst_address_hex: { key: "dstAddressHex" },
  dst_port: { key: "dstPort" },
  vuln_name: { key: "vulnName" },
  user: { key: "user" },
  domain: { key: "domain" },
  action: { key: "action" },
  activity: { key: "activity" },
  application: { key: "application" },
  browser: { key: "browser" },
  device_uuid: { key: "deviceUUID" },
  dst_address_xlated: { key: "dstAddressXlated" },
  dst_address_xlated_hex: { key: "dstAddressXlatedHex" },
  dst_name: { key: "dstName" },
  dst_zone: { key: "dstZone" },
  email: { key: "email" },
  //hash: { key: "hash" },
  mac: { key: "mac" },
  parent_process: { key: "parentProcess" },
  process: { key: "process" },
  protocol: { key: "protocol" },
  result: { key: "result" },
  src_address_xlated: { key: "srcAddressXlated" },
  src_address_xlated_hex: { key: "srcAddressXlatedHex" },
  src_name: { key: "srcName" },
  src_zone: { key: "srcZone" },
  target_user: { key: "targetUser" },
  user_uuid: { key: "userUUID" },
  url: { key: "url" },
  url_path: { key: "urlPath" },
  max_timestamp: { key: "maxTimestamp" },
  min_timestamp: { key: "minTimestamp" },
  max_timestamp_id: { key: "maxTimestampId" },
  max_timestamp_parsed: { key: "maxTimestampParsed" },
  min_timestamp_parsed: { key: "minTimestampParsed" },
  max_timestamp_parsed_id: { key: "maxTimestampParsedId" },
  bytes: { key: "bytes" },
  total: { key: "total" },
};

export const SUMMARY_TO_BQ_FIELD_MAP = {};
export const BQ_TO_SUMMARY_FIELD_MAP = {};

//The conditional check was added because typeof Codex is string when running Jest tests.
//It is possible Jest doesn't handle git submodules well and some tweaking in Jest config is needed.
if (typeof Codex === "object" && "onLoad" in Codex) {
  Codex.onLoad().then(() => {
    Object.keys(Codex.constant.columns.bq_summary).forEach((columnName) => {
      const column = Codex.constant.columns.bq_summary[columnName];
      const colSchema = { readOnly: true };
      if (column.data_mode === "REPEATED") {
        colSchema["type"] = "array";
        colSchema["items"] = { type: DATA_TYPE_MAP[column.data_type] };
      } else {
        colSchema["type"] = DATA_TYPE_MAP[column.data_type];
      }
      if (column.data_type === "TIMESTAMP") {
        colSchema["format"] = "date-time";
      }
      if (column.data_type === "BYTES") {
        colSchema["format"] = "bytes";
      }
      BQSummarySchema.properties[columnName] = colSchema;
    });

    Codex.constant.summaryRules.forEach((rule) => {
      rule.fields
        .filter((f) => f.op === "alias" && fieldMapping[f.alias] !== undefined)
        .forEach((f) => {
          if (
            SUMMARY_TO_BQ_FIELD_MAP[fieldMapping[f.alias].key] === undefined
          ) {
            SUMMARY_TO_BQ_FIELD_MAP[fieldMapping[f.alias].key] = {};
          }
          if (
            SUMMARY_TO_BQ_FIELD_MAP[fieldMapping[f.alias].key][
              rule.association_type
            ] === undefined
          ) {
            SUMMARY_TO_BQ_FIELD_MAP[fieldMapping[f.alias].key][
              rule.association_type
            ] = new Set([]);
          }
          if (
            !SUMMARY_TO_BQ_FIELD_MAP[fieldMapping[f.alias].key][
              rule.association_type
            ].has(f.field)
          ) {
            SUMMARY_TO_BQ_FIELD_MAP[fieldMapping[f.alias].key][
              rule.association_type
            ].add(f.field);
          }

          if (BQ_TO_SUMMARY_FIELD_MAP[f.field] === undefined) {
            BQ_TO_SUMMARY_FIELD_MAP[f.field] = {};
          }
          if (
            BQ_TO_SUMMARY_FIELD_MAP[f.field][rule.association_type] ===
            undefined
          ) {
            BQ_TO_SUMMARY_FIELD_MAP[f.field][rule.association_type] = new Set(
              []
            );
          }
          if (
            !BQ_TO_SUMMARY_FIELD_MAP[f.field][rule.association_type].has(
              fieldMapping[f.alias].key
            )
          ) {
            BQ_TO_SUMMARY_FIELD_MAP[f.field][rule.association_type].add(
              fieldMapping[f.alias].key
            );
          }
        });
    });

    Schema.removeSchema(BQSummarySchema["$id"]);
    Schema.addSchema(BQSummarySchema);
  });
}

export const toReportFields = (data, associationType) => {
  const retVal = [];
  const collection = [];
  Object.keys(data)
    .filter((f) => SUMMARY_TO_BQ_FIELD_MAP[f])
    .forEach((f) => {
      if (SUMMARY_TO_BQ_FIELD_MAP[f][associationType] !== undefined) {
        SUMMARY_TO_BQ_FIELD_MAP[f][associationType].forEach((o) =>
          collection.push({ field: o, value: data[f] })
        );
      } else {
        Object.keys(SUMMARY_TO_BQ_FIELD_MAP)
          .map((aT) => SUMMARY_TO_BQ_FIELD_MAP[f][aT])
          .forEach((o) => collection.push(o));
      }
    });
  retVal.push(collection);
  return retVal;
};

export class BQSummary extends BaseModel {
  schema = "/bq_summary";
  fields = {
    id: () => this.hash,
    associationType: () => {
      // there are legacy assocaition types in the DB, lets scrub them
      if (this.__fixedAT === undefined) {
        this.__fixedAT = this.params.associationType?.replace("windows_", "");
      }
      return this.__fixedAT;
    },
  };

  toReportFields(fields) {
    const searchParams = fields || Object.keys(this.params);
    const ent = searchParams
      .filter((p) => this.params[p] !== undefined)
      .map((p) => [p, this.params[p]]);
    return toReportFields(Object.fromEntries(ent), this.associationType);
  }

  constructor(params) {
    return super(params, "/bq_summary");
  }
}
