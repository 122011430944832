import React from "react";
import PropTypes from "prop-types";

import { sanitize, render } from "../../lib/helpers";

const Safe = (props) => {
  const htmlProps = {
    ...props,
  };

  delete htmlProps.render;
  delete htmlProps.path;
  delete htmlProps.value;
  delete htmlProps.safe;

  if (props.render) {
    if (props.type === "div") {
      return (
        // eslint-disable-next-line react/no-danger
        <div {...htmlProps} dangerouslySetInnerHTML={render({ ...props })} />
      );
    }
    if (props.type === "span") {
      return (
        // eslint-disable-next-line react/no-danger
        <span {...htmlProps} dangerouslySetInnerHTML={render({ ...props })} />
      );
    }
  } else {
    if (props.type === "div") {
      return (
        <div
          {...htmlProps}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={sanitize(props.text, {
            html: true,
            safe: props.safe,
          })}
        />
      );
    }
    if (props.type === "span") {
      return (
        <span
          {...htmlProps}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={sanitize(props.text, {
            html: true,
            safe: props.safe,
          })}
        />
      );
    }
  }
};

Safe.propTypes = {
  render: PropTypes.bool,
  safe: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.string,
};

Safe.defaultProps = {
  render: false,
  safe: false,
  type: "span",
  text: "",
};

export default Safe;
