import { API } from "./API";

/*
No GraphQL FEA '/resources' endpoints calls for the "assets"
*/

const DEFAULT_OPTIONS = {
  baseUrl: "resources",
};

const HOST_URL = "host";
const IP_URL = "ip";
const LOGDEV_URL = "logdev";
const TALKERS_URL = "talkers";
const THREATS_URL = "threats";
const WINDOWS_EVTS_URL = "windows_evts";

export class ResourcesAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  fetchHostGraph({ objectId, query }) {
    const params = [HOST_URL, objectId, "graph"];

    return super.get({ params, query });
  }

  fetchHostnames(query) {
    const params = [HOST_URL];

    return super.get({ params, query });
  }

  fetchIpGraph({ objectId, query }) {
    const params = [IP_URL, objectId, "graph"];

    return super.get({ params, query });
  }

  fetchIPs(query) {
    const params = [IP_URL];

    return super.get({ params, query });
  }

  fetchLogdevsGraph({ orgId, query }) {
    const params = [LOGDEV_URL, orgId, "graph"];

    return super.get({ params, query });
  }

  fetchLogdevsOverview(orgId) {
    const params = [LOGDEV_URL, orgId, "overview"];

    return super.get({ params });
  }

  fetchLogdevsTable({ orgId, query }) {
    const params = [LOGDEV_URL, orgId, "table"];

    return super.get({ params, query });
  }

  fetchTalkers({ query }) {
    const params = [TALKERS_URL];

    return super.get({ params, query });
  }

  fetchThreats({ query }) {
    const params = [THREATS_URL];

    return super.get({ params, query });
  }

  fetchWindowsEvts({ query }) {
    const params = [WINDOWS_EVTS_URL];

    return super.get({ params, query });
  }
}
