import { API, setAxiosOrgHeader } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "locations",
};

export class LocationsAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  list(orgId) {
    const params = [orgId];
    setAxiosOrgHeader(orgId);
    return super.get({ params });
  }

  update(orgId, locationId, data = {}) {
    const params = [orgId, locationId];
    return super.put({ params }, data);
  }

  create(orgId, data = {}) {
    const params = [orgId];
    return super.post({ params }, data);
  }
}
