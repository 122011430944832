import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { clearUrlQuery as closeModal } from "../../../redux/actions/Page";
import { updateSchema } from "../../../redux/actions/Org";
import Confirm from "../../Components/Confirm";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import { orgNameSelector } from "../../../selectors/orgNameSelector";
import { orgLoadingSelector } from "../../../selectors/orgSelectors";
import { locationQuerySelector } from "../../../selectors/locationSelectors";

import "../Sensor/SensorModal.scss";

/*
Component
*/

const UpdateSchemaModal = (props) => {
  const {
    dispatchCloseModal = () => {},
    dispatchUpdateSchema = () => {},
    loading,
    modalOpen,
    orgId,
    orgName,
  } = props;

  const firstRun = useRef(true);

  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dispatchCloseModal();
  }, [orgId, dispatchCloseModal]);

  const onCancelUpdate = () => {
    dispatchCloseModal();
  };

  const onConfirmUpdate = () => {
    dispatchUpdateSchema(orgId);
  };

  if (!modalOpen) {
    return null;
  }

  return (
    <div className="sensor-modal">
      <Confirm
        title={`Update the schema for ${orgName}`}
        body={`Are you sure you want to update the schema for ${orgName}?`}
        confirmBtnTxt={loading ? "..." : "Confirm"}
        onCancel={onCancelUpdate}
        onConfirm={onConfirmUpdate}
      />
    </div>
  );
};

UpdateSchemaModal.propTypes = {
  dispatchCloseModal: PropTypes.func.isRequired,
  dispatchUpdateSchema: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  modalOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  orgId: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

UpdateSchemaModal.defaultProps = {};

const mapStateToProps = (state) => {
  const loading = orgLoadingSelector(state);

  const location = locationQuerySelector(state);
  const modalOpen = location.update || false;

  const orgId = orgIdSelector(state);
  const orgName = orgNameSelector(state);

  return {
    loading,
    modalOpen,
    orgId,
    orgName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchCloseModal: () => {
    dispatch(closeModal);
  },
  dispatchUpdateSchema: (orgId) => {
    dispatch(updateSchema(orgId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSchemaModal);
