import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "BillingPageView";
export const rootClasses = {
  page: `${PREFIX}-page`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  legacyPlanAlert: `${PREFIX}-legacyPlanAlert`,
  section: `${PREFIX}-section`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  sectionInfoContainer: `${PREFIX}-sectionInfoContainer`,
  sectionInfoCard: `${PREFIX}-sectionInfoCard`,
  alertText: `${PREFIX}-alertText`,
  rightBorder: `${PREFIX}-rightBorder`,
  sectionInfoCardTitle: `${PREFIX}-sectionInfoCardTitle`,
  sectionInfoCardText: `${PREFIX}-sectionInfoCardText`,
  manageInvoicesContainer: `${PREFIX}-manageInvoicesContainer`,
  manageButton: `${PREFIX}-manageButton`,
  link: `${PREFIX}-link`,
  editCardText: `${PREFIX}-editCardText`,
  productsContainer: `${PREFIX}-productsContainer`,
  productSectionTitle: `${PREFIX}-productSectionTitle`,
  planOption: `${PREFIX}-planOption`,
  namePriceContainer: `${PREFIX}-namePriceContainer`,
  billingPeriod: `${PREFIX}-billingPeriod`,
  price: `${PREFIX}-price`,
  userHelperText: `${PREFIX}-userHelperText`,
  checkContainer: `${PREFIX}-checkContainer`,
  checkContainerShiftedRight: `${PREFIX}-checkContainerShiftedRight`,
  currentCheck: `${PREFIX}-currentCheck`,
  currentText: `${PREFIX}-currentText`,
  listItems: `${PREFIX}-listItems`,
  listItem: `${PREFIX}-listItem`,
  newPlanContainer: `${PREFIX}-newPlanContainer`,
  newPlanPrimaryContent: `${PREFIX}-newPlanPrimaryContent`,
  upgradeContent: `${PREFIX}-upgradeContent`,
  planNameText: `${PREFIX}-planNameText`,
  wrapPlanNameText: `${PREFIX}-wrapPlanNameText`,
  priceContainer: `${PREFIX}-priceContainer`,
  priceText: `${PREFIX}-priceText`,
  bannerTextContainer: `${PREFIX}-bannerTextContainer`,
  bannerText: `${PREFIX}-bannerText`,
  descriptionTextContainer: `${PREFIX}-descriptionTextContainer`,
  descriptionText: `${PREFIX}-descriptionText`,
  editionSectionTitle: `${PREFIX}-editionSectionTitle`,
  detailTextContainer: `${PREFIX}-detailTextContainer`,
  upgradeDetailTextContainer: `${PREFIX}-upgradeDetailTextContainer`,
  detailText: `${PREFIX}-detailText`,
  checkIcon: `${PREFIX}-checkIcon`,
  ctaButton: `${PREFIX}-ctaButton`,
  cardCtaButton: `${PREFIX}-cardCtaButton`,
  secondCardCtaButton: `${PREFIX}-secondCardCtaButton`,
  cardCtaButtonContainer: `${PREFIX}-cardCtaButtonContainer`,
  bonusMaterialContainer: `${PREFIX}-bonusMaterialContainer`,
  bonusMaterialTitle: `${PREFIX}-bonusMaterialTitle`,
  footerContainer: `${PREFIX}-footerContainer`,
  infoContainer: `${PREFIX}-infoContainer`,
  ctaContainer: `${PREFIX}-ctaContainer`,
  freeLicenceCtaContainer: `${PREFIX}-freeLicenceCtaContainer`,
  requestXdrTrialButton: `${PREFIX}-requestXdrTrialButton`,
  xdrTrialStepCard: `${PREFIX}-xdrTrialStepCard`,
  startTrialButton: `${PREFIX}-startTrialButton`,
  textButton: `${PREFIX}-textButton`,
  containedButton: `${PREFIX}-containedButton`,
  timedTrialInfoCard: `${PREFIX}-timedTrialInfoCard`,
  xdrTrialChip: `${PREFIX}-xdrTrialChip`,
  xdrTrialFlexContainer: `${PREFIX}-xdrTrialFlexContainer`,
  xdrTrialStepOneImageContainer: `${PREFIX}-xdrTrialStepOneImageContainer`,
  xdrTrialStepOneTextContainer: `${PREFIX}-xdrTrialStepOneTextContainer`,
  xdrTrialStepTitle: `${PREFIX}-xdrTrialStepTitle`,
  xdrTrialImage: `${PREFIX}-xdrTrialImage`,
  xdrTrialCardHeaderWrap: `${PREFIX}-xdrTrialCardHeaderWrap`,
  stepTwoContentWrap: `${PREFIX}-stepTwoContentWrap`,
  stepThreeContentWrap: `${PREFIX}-stepThreeContentWrap`,
  xdrTrialStepThreeCard: `${PREFIX}-xdrTrialStepThreeCard`,
  errorBanner: `${PREFIX}-errorBanner`,
  errorAction: `${PREFIX}-errorAction`,
  tooltip: `${PREFIX}-tooltip`,
};

export const dialogClasses = {
  stripeLaunchDialogContainer: `${PREFIX}-stripeLaunchDialogContainer`,
  certify: `${PREFIX}-certify`,
  dialogActions: `${PREFIX}-dialogActions`,
  stepContainer: `${PREFIX}-stepContainer`,
  numberContainer: `${PREFIX}-numberContainer`,
  divider: `${PREFIX}-divider`,
  productQuantity: `${PREFIX}-productQuantity`,
  trialDialogContainer: `${PREFIX}-trialDialogContainer`,
  trialDialogHeader: `${PREFIX}-trialDialogHeader`,
  trialDialogText: `${PREFIX}-trialDialogText`,
  trialDialogCloseButton: `${PREFIX}-trialDialogCloseButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.page}`]: {
    padding: `0 ${theme.shape.padding}px`,
  },
  [`& .${rootClasses.loadingContainer}`]: {
    width: "100%",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& h6": {
      marginBottom: theme.shape.padding * 2,
    },
  },
  [`& .${rootClasses.legacyPlanAlert}`]: {
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  [`& .${rootClasses.section}`]: {
    marginBottom: theme.shape.padding * 2,
  },
  [`& .${rootClasses.sectionTitle}`]: {
    fontSize: 25,
    fontWeight: "bold",
  },
  [`& .${rootClasses.sectionInfoContainer}`]: {
    width: "100%",
    minHeight: 100,
    display: "flex",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.sectionInfoCard}`]: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.shape.padding,
    justifyContent: "space-between",
    "& p": {
      margin: 0,
    },
  },
  [`& .${rootClasses.alertText}`]: {
    margin: 0,
  },
  [`& .${rootClasses.rightBorder}`]: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  [`& .${rootClasses.sectionInfoCardTitle}`]: {
    fontSize: 12,
  },
  [`& .${rootClasses.sectionInfoCardText}`]: {
    fontWeight: "bold",
  },
  [`& .${rootClasses.manageInvoicesContainer}`]: {
    display: "flex",
    marginBottom: 16,
    alignItems: "center",
    "& p": {
      margin: 0,
    },
  },
  [`& .${rootClasses.manageButton}`]: {
    marginLeft: theme.shape.padding,
  },
  [`& .${rootClasses.link}`]: {
    fontSize: 14,
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  [`& .${rootClasses.editCardText}`]: {
    maxWidth: 150,
    cursor: "pointer",
    color: theme.palette.primary.main,
    marginLeft: theme.shape.padding * 2,
  },
  [`& .${rootClasses.productsContainer}`]: {
    display: "flex",
  },
  [`& .${rootClasses.productSectionTitle}`]: {
    fontSize: 20,
    marginBottom: 0,
    fontWeight: "bold",
    marginLeft: theme.shape.padding,
  },
  [`&.${rootClasses.planOption}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.shape.padding,
    justifyContent: "space-between",
    borderRadius: theme.shape.borderRadius,
    border: `2px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.namePriceContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.billingPeriod}`]: {
    fontWeight: "bold",
  },
  [`& .${rootClasses.price}`]: {
    fontSize: 12,
    marginBottom: 0,
  },
  [`& .${rootClasses.userHelperText}`]: {
    fontSize: 12,
    paddingTop: theme.shape.padding,
  },
  [`& .${rootClasses.checkContainer}`]: {
    top: 0,
    right: 0,
    fontSize: 12,
    display: "flex",
    padding: `5px 10px `,
    position: "absolute",
    alignItems: "center",
    backgroundColor: theme.palette.success.main,
    borderRadius: `0 ${theme.shape.borderRadius}px 0 ${theme.shape.borderRadius}px`,
  },
  [`& .${rootClasses.checkContainerShiftedRight}`]: {
    right: "50% !important",
    borderRadius: `0 0 0 ${theme.shape.borderRadius}px !important`,
  },
  [`& .${rootClasses.currentCheck}`]: {
    marginRight: 5,
    color: theme.palette.common.white,
  },
  [`& .${rootClasses.currentText}`]: {
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 0,
    color: theme.palette.common.white,
  },
  [`& .${rootClasses.listItems}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  [`& .${rootClasses.listItem}`]: {
    display: "flex",
    fontSize: 14,
    "& p": {
      marginLeft: 10,
    },
  },
  [`& .${rootClasses.newPlanContainer}`]: {
    height: "100%",
    minHeight: 630,
    display: "flex",
    boxShadow: "none",
    position: "relative",
    flexDirection: "row",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
  },
  [`& .${rootClasses.newPlanPrimaryContent}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    justifyContent: "space-between",
  },
  [`& .${rootClasses.upgradeContent}`]: {
    width: "50%",
    backgroundColor: "#ECF2FE",
    color: theme.palette.common.black,
  },
  [`& .${rootClasses.planNameText}`]: {
    fontSize: 18,
    fontWeight: "bold",
    fontFamily: "Prompt",
    padding: theme.shape.padding,
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.wrapPlanNameText}`]: {
    width: "150px !important",
  },
  [`& .${rootClasses.priceContainer}`]: {
    marginTop: -30,
    display: "flex",
    "& svg": {
      paddingLeft: 5,
    },
  },
  [`& .${rootClasses.priceText}`]: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: theme.shape.padding,
  },
  [`& .${rootClasses.descriptionTextContainer}`]: {
    marginTop: -theme.shape.padding,
  },
  [`& .${rootClasses.descriptionText}`]: {
    fontSize: 14,
    paddingLeft: theme.shape.padding,
  },
  [`& .${rootClasses.bannerTextContainer}`]: {
    width: "100%",
    padding: "7px 15px",
    color: theme.palette.common.black,
    backgroundColor: "#E8E8E8",
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.bannerText}`]: {
    margin: 0,
    fontSize: 14,
  },
  [`& .${rootClasses.editionSectionTitle}`]: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: "bold",
    textTransform: "uppercase",
    paddingLeft: theme.shape.padding,
  },
  [`& .${rootClasses.detailTextContainer}`]: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingRight: theme.shape.padding,
  },
  [`& .${rootClasses.detailText}`]: {
    fontSize: 13,
    marginBottom: 7,
    "& svg": {
      paddingLeft: 5,
    },
  },
  [`& .${rootClasses.checkIcon}`]: {
    fontSize: 13,
    color: theme.palette.primary.main,
  },
  [`& .${rootClasses.ctaButton}`]: {
    height: 40,
    fontSize: 15,
    fontWeight: "bold",
  },
  [`& .${rootClasses.cardCtaButtonContainer}`]: {
    bottom: 15,
    width: "100%",
    padding: "0px 15px",
    position: "relative",
    marginTop: theme.shape.padding,
  },
  [`& .${rootClasses.cardCtaButton}`]: {
    minHeight: 40,
    fontSize: 16,
    width: "100%",
    borderColor: theme.palette.border.xdrTrialCard,
    backgroundColor: theme.palette.background.paper,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  [`& .${rootClasses.secondCardCtaButton}`]: {
    marginTop: 10,
  },
  [`& .${rootClasses.bonusMaterialContainer}`]: {
    color: "black",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.shape.padding,
    marginTop: theme.shape.padding,
    borderTop: "1px solid #cddaf2",
    background: "radial-gradient(white, #dae4f8)",
    "& p": {
      fontSize: 14,
    },
  },
  [`& .${rootClasses.bonusMaterialTitle}`]: {
    fontWeight: "bold",
  },
  [`& .${rootClasses.footerContainer}`]: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      margin: 0,
      fontSize: 14,
    },
  },
  [`& .${rootClasses.infoContainer}`]: {
    display: "flex",
    alignItems: "center",
    margin: `${theme.shape.padding}px 0`,
    "& p": {
      marginLeft: 5,
      fontWeight: 500,
    },
  },
  [`& .${rootClasses.ctaContainer}`]: {
    display: "flex",
    alignItems: "center",
    "& p": {
      marginRight: theme.shape.padding * 2,
    },
  },
  [`& .${rootClasses.freeLicenceCtaContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& h1": {
      fontSize: 26,
      fontWeight: "bold",
      marginBottom: theme.shape.padding,
    },
    "& h2": {
      fontSize: 20,
      marginBottom: theme.shape.padding,
    },
  },
  [`& .${rootClasses.requestXdrTrialButton}`]: {
    fontSize: 20,
    fontWeight: "bold",
    color: "white !important",
    backgroundColor: "#132A5E",
    marginBottom: theme.shape.padding * 2,
    padding: `5px ${theme.shape.padding * 2}px`,
    "&:hover": {
      backgroundColor: "#132A5E",
    },
  },
  [`& .${rootClasses.xdrTrialStepCard}`]: {
    width: "31%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 20,
    position: "relative",
    overflow: "visible",
    border: `1px solid ${theme.palette.border.xdrTrialCard}`,
    "&:not(:last-of-type)": {
      "&:before": {
        position: "absolute",
        right: -16,
        top: "50%",
        width: 0,
        height: 0,
        content: '""',
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent",
        borderLeftWidth: "15px",
        borderLeftStyle: "solid",
        borderLeftColor: theme.palette.border.xdrTrialCard,
        transform: "translateY(-50%)",
      },
      "&:after": {
        position: "absolute",
        right: -15,
        top: "50%",
        width: 0,
        height: 0,
        content: '""',
        borderTop: "15px solid transparent",
        borderBottom: "15px solid transparent",
        borderLeftWidth: "15px",
        borderLeftStyle: "solid",
        borderLeftColor: theme.palette.background.paper,
        transform: "translateY(-50%)",
      },
    },
  },
  [`& .${rootClasses.startTrialButton}`]: {
    width: "40%",
    fontWeight: "bold",
    fontSize: 16,
    padding: "10px 0",
  },
  [`& .${rootClasses.textButton}`]: {
    fontWeight: "bold",
    fontSize: 16,
  },
  [`& .${rootClasses.containedButton}`]: {
    width: "50%",
    fontWeight: "bold",
    fontSize: 16,
    padding: "10px 0",
  },
  [`& .${rootClasses.timedTrialInfoCard}`]: {
    marginBottom: 25,
    paddingBottom: 15,
  },
  [`& .${rootClasses.xdrTrialChip}`]: {
    backgroundColor: "#d8eada",
    borderRadius: 10,
    padding: "0 10px",
    fontSize: "12px",
    margin: 10,
    width: "fit-content",
    fontWeight: "bold",
    color: "black",
  },
  [`& .${rootClasses.xdrTrialFlexContainer}`]: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    maxWidth: 1800,
  },
  [`& .${rootClasses.xdrTrialStepOneImageContainer}`]: {
    width: "48%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    maxWidth: 700,
  },
  [`& .${rootClasses.xdrTrialStepOneTextContainer}`]: {
    width: "48%",
    padding: "10px 20px",
  },
  [`& .${rootClasses.xdrTrialStepTitle}`]: {
    fontWeight: "bold",
  },
  [`& .${rootClasses.xdrTrialImage}`]: {
    width: "100%",
    height: "auto",
  },
  [`& .${rootClasses.xdrTrialCardHeaderWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${rootClasses.stepTwoContentWrap}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  [`& .${rootClasses.stepThreeContentWrap}`]: {
    padding: 15,
    width: "50%",
  },
  [`& .${rootClasses.xdrTrialStepThreeCard}`]: {
    padding: 15,
    height: "100%",
  },
  [`& .${rootClasses.errorBanner}`]: {
    alignItems: "center",
    marginBottom: theme.shape.padding,
  },
  [`& .${rootClasses.errorAction}`]: {
    fontWeight: "bold",
    color: theme.palette.error.main,
    marginRight: theme.shape.padding,
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.error.main,
    },
  },
}));

export const BillingDialog = styled(Dialog)(({ theme }) => ({
  [`& .${dialogClasses.stripeLaunchDialogContainer}`]: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  [`& .${dialogClasses.certify}`]: {
    display: "flex",
    alignItems: "center",
    "& p": {
      margin: 0,
      fontSize: 10,
      color: theme.palette.text.secondary,
    },
  },
  [`& .${dialogClasses.dialogActions}`]: {
    justifyContent: "flex-start",
    padding: theme.shape.padding * 2,
    paddingTop: 0,
  },
  [`& .${dialogClasses.stepContainer}`]: {
    display: "flex",
  },
  [`& .${dialogClasses.numberContainer}`]: {
    width: 20,
    height: 20,
    borderRadius: 10,
    marginRight: theme.shape.padding,
    backgroundColor: theme.palette.grey[600],
    textAlign: "center",
    "& p": {
      margin: 0,
      color: theme.palette.common.white,
    },
  },
  [`& .${dialogClasses.divider}`]: {
    margin: `${theme.shape.padding}px 0`,
  },
  [`& .${dialogClasses.productQuantity}`]: {
    marginBottom: theme.shape.padding,
    marginRight: theme.shape.padding,
  },
  [`& .${dialogClasses.trialDialogContainer}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "30px 45px",
  },
  [`& .${dialogClasses.trialDialogHeader}`]: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
  },
  [`& .${dialogClasses.trialDialogText}`]: {
    fontSize: 18,
  },
  [`& .${dialogClasses.trialDialogCloseButton}`]: {
    padding: `10px ${theme.shape.padding}px`,
  },
}));
