import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import moment from "moment-timezone";

import _ from "lodash";

import { loadPageData } from "redux/actions/Request";
import { pageWithPayloadAndQuery } from "redux/actions/Page";
import { updateUser, addUserToOrg } from "redux/actions/Users";

import Request from "lib/api/Request";
import { WILDCARD_ORG_ID } from "lib/api/API";

import { Org } from "lib/models/Org";
import { User } from "lib/models/User";
import { Location } from "lib/models/Location";

import UsersPageView2 from "./UsersPageView2";
import { LocationsComponent } from "./LocationsPageView";
import { OrganizationComponent, EditOrgDialog } from "./OrganizationPageView";

import SimpleTable from "views/Components/SimpleTable";
import { renderConfigCell } from "views/Components/MSP/helpers";

import { roleConstantsSelector } from "../../selectors/roleConstantsSelector";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import DetailsIcon from "@mui/icons-material/ZoomIn";

const OrganizationDetail = (props) => {
  const { timezones, orgs, orgsError, handleDeleteDone, orgId, routeToPage } =
    props;

  return (
    <div className="organizations-detail-page">
      <div className="dashboard-table">
        <OrganizationComponent
          org={[orgs.find((o) => o.id === orgId)]}
          ready={props.ready}
          orgsError={orgsError}
          orgId={orgId}
          loadPageData={loadPageData}
          timezones={timezones}
          handleDeleteDone={handleDeleteDone}
          sessionOrgId={orgId}
          superadmin={!!props.superadmin}
          isAdministeringOrg={!!props.isAdministeringOrg}
        />
      </div>

      <div className="dashboard-table">
        <LocationsComponent
          locations={props.locations}
          ready={props.ready}
          orgId={orgId}
          timezones={timezones}
        />
      </div>

      <div className="dashboard-table">
        <UsersPageView2 locations={props.locations} routeToPage={routeToPage} />
      </div>
    </div>
  );
};

export const getRolesForAddingUserToOrg = ({ user = {}, orgId = "" }) => {
  const orgRoles = [
    ...user.orgRoles,
    { orgId: orgId, roleId: 10 },
    { orgId: orgId, roleId: 40 },
  ];

  return orgRoles;
};

export const getSnakecaseFormattedUserData = ({
  user = {},
  location = {},
  orgId = "",
}) => {
  // redux action for editing user expects data to be
  // formatted in snake case as opposed to camel
  let formattedExistingLocations = user.locations.map(({ orgId, id }) => ({
    org_id: orgId,
    id: id,
  }));

  const locations = [
    ...formattedExistingLocations,
    { org_Id: orgId, id: location.id },
  ];

  const userDataToReturn = {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    title: user.title,
    locations: locations,
  };

  return userDataToReturn;
};

const OrganizationsPageView = (props) => {
  const [openForm, setOpenForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedOrgModel, setSelectedOrgModel] = useState(false);
  const [locationModel, setLocationModel] = useState(false);
  const [userModel, setUserModel] = useState(false);
  const [totalAgents, setTotalAgents] = useState({});

  const {
    currentUserId,
    timezones,
    user,
    orgs,
    orgsError,
    gotoOrg,
    payload,
    roleConstants,
    locations,
    routeToPage,
  } = props;

  useEffect(() => {
    const totalAgentsRequest = new Request("/lcagent");

    if (props.isAdministeringOrg) {
      totalAgentsRequest.get().then((allAgents) => {
        const totalAgentsByOrg = _(allAgents)
          .countBy((agent) => agent.orgId)
          .value();
        setTotalAgents(totalAgentsByOrg);
      });
    }
  }, [orgs]);

  const renderDateCell = (column, model) => {
    const timeValue = model[column.field];
    if (!timeValue) {
      return "Pending";
    }
    return moment.utc(timeValue).tz(moment.tz.guess(true)).format("lll z");
  };

  const tableColumns = [
    {
      title: "Name",
      field: "name",
      searchable: true,
    },
    {
      title: "User Count",
      field: "config.userCount",
      searchable: true,
      renderValue: renderConfigCell(null, 0),
    },
    {
      title: "Agent Count",
      field: "totalAgents",
      searchable: true,
      renderValue: renderConfigCell(totalAgents, 0),
    },
    {
      title: "Web Domain",
      field: "webUrl",
      searchable: true,
    },
    {
      title: "Edition",
      field: "config.license",
      searchable: true,
      renderValue: renderConfigCell(null, ""),
    },
    {
      title: "Account created",
      field: "created",
      searchable: true,
      renderValue: renderDateCell,
    },
    {
      title: "Parent ID",
      field: "parentId",
      searchable: true,
    },
    {
      title: "Blumira ID",
      field: "id",
      searchable: true,
    },
  ];

  const handleDeleteDone = () => {
    gotoOrg({ payload, orgId: undefined });
  };

  if (props.orgId) {
    return (
      <OrganizationDetail
        props={props}
        orgId={props.orgId}
        orgs={orgs}
        ready={props.ready}
        orgsError={orgsError}
        timezones={timezones}
        currentUserId={currentUserId}
        user={user}
        handleDeleteDone={handleDeleteDone}
        roleConstants={roleConstants}
        locations={locations}
        routeToPage={routeToPage}
        superadmin={props.superadmin}
        isAdministeringOrg={props.isAdministeringOrg}
      />
    );
  }

  const handleClose = () => {
    setOpenForm(false);
    setIsSaving(false);
  };

  const handleSaveClick = async () => {
    setIsSaving(true);

    let existingUserJSON;

    // query for users with entered email
    await new User({ email: userModel.email }).read().then((user) => {
      if (user && user[0]) existingUserJSON = user[0];
    });

    // if we can, validate other models in the form
    // to avoid creating an org with malformed location
    const locationValidation = locationModel.validate();

    // if valid, validate returns true, else returns arr
    // of one obj containing details on failure
    if (locationValidation !== true) {
      const { message = "" } = locationValidation[0];
      setErrorMessage(
        `Oops, check your location credentials. Details: ${message}`
      );
      setIsSaving(false);
      return;
    }

    try {
      selectedOrgModel.create().then((createdOrg = {}) => {
        const orgObjectForUser = {
          created: createdOrg.created,
          id: createdOrg.id,
          name: createdOrg.name,
        };

        // after org creation we have necessary
        // data to successfully create first location
        locationModel.set({ orgId: createdOrg.id });
        locationModel
          .create()
          .then((createdLocation = {}) => {
            // after location creation we have necessary
            // data to successfully create first user

            // if this user exists, update instead of create
            if (existingUserJSON) {
              const newRoles = getRolesForAddingUserToOrg({
                user: existingUserJSON,
                orgId: createdOrg.id,
              });

              props
                .addExistingUser({
                  orgId: createdOrg.id,
                  email: existingUserJSON.email,
                  roles: newRoles,
                })
                .then(() => {
                  // leverage existing redux action for editing a user
                  // that requires snake case
                  const formatteduserData = getSnakecaseFormattedUserData({
                    user: existingUserJSON,
                    location: createdLocation,
                    orgId: createdOrg.id,
                  });

                  props.editUser({
                    orgId: createdOrg.id,
                    personId: existingUserJSON.id,
                    data: formatteduserData,
                  });
                })
                .then(() => {
                  props.reload();
                  handleClose();
                })
                .catch((e) => {
                  setErrorMessage(
                    `Oops, we had trouble with adding that user. The organization ${createdOrg.name} was still created. Details: ${e}`
                  );
                  setIsSaving(false);
                });
            } else {
              // else if this user does not exist, create a new user
              // with the provided information
              userModel.set({
                orgRoles: [
                  { orgId: orgObjectForUser.id, roleId: 10 },
                  { orgId: orgObjectForUser.id, roleId: 40 },
                ], // default to administrator, responder
                locations: [{ orgId: createdOrg.id, id: createdLocation.id }],
              });
              userModel
                .create()
                .then(() => {
                  props.reload();
                  handleClose();
                })
                .catch((e) => {
                  setErrorMessage(
                    `Oops, we had trouble with creating that user. The organization ${createdOrg.name} was still created. Details: ${e}`
                  );
                  setIsSaving(false);
                });
            }
          })
          .catch((e) => {
            setErrorMessage(
              `Oops, we had trouble creating your location. The organization ${createdOrg.name} was still created. Details: ${e}`
            );
            setIsSaving(false);
          });
      });
    } catch (e) {
      setErrorMessage(`Oops, we had trouble with that request. Details: ${e}`);
      setIsSaving(false);
    }
  };

  const handleTableRowClick = (model) => {
    gotoOrg({ payload, orgId: model.id });
  };

  return (
    <div className="dashboard organizations-page">
      <div className="dashboard-table">
        <SimpleTable
          isNorthStar
          title="Organizations"
          data={props.orgs}
          isFetching={!props.ready}
          rowClick={handleTableRowClick}
          error={orgsError}
          columns={tableColumns}
          toolbarActions={[
            {
              icon: AddCircleIcon,
              tooltip: "Add New Organization",
              onClick: (event) => {
                setSelectedOrgModel(new Org({ config: { userCount: 1 } })); // org creation also creates a user
                setLocationModel(new Location());
                setUserModel(new User({ orgRoles: [] })); // set empty list to clear log warnings for required prop
                setOpenForm(true);
              },
            },
          ]}
          actions={[
            {
              icon: DetailsIcon,
              tooltip: "Show/Edit Details",
              onClick: (event, model) => {
                gotoOrg({ payload, orgId: model.id });
              },
            },
          ]}
        />
      </div>
      <EditOrgDialog
        error={errorMessage}
        openForm={openForm}
        isSaving={isSaving}
        timezones={timezones}
        userModel={userModel}
        handleClose={handleClose}
        locationModel={locationModel}
        isSuperadmin={props.superadmin}
        selectedOrgModel={selectedOrgModel}
        handleSaveClick={() => handleSaveClick()}
        isAdministeringOrg={props.isAdministeringOrg}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.org.listOrgs;

  const roleConstants = roleConstantsSelector(state);

  const isAdministeringOrg =
    !!state.page?.payload?.orgId &&
    WILDCARD_ORG_ID === state.page.payload.orgId;

  return {
    orgsError: error,
    roleConstants,
    user: state.session.settings.user,
    isAdministeringOrg: isAdministeringOrg,
    sessionOrgId: state.page.payload.orgId,
    currentUser: state.session.settings.user,
    currentUserId: state.session.settings.user.id,
    superadmin: state.session.settings.user.superadmin,
    timezones: state.session.settings.accountConstants.timezones,
    orgId: state.location.payload.id1,
    payload: state.location.payload,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reload: (force) => dispatch(loadPageData(force)),
  gotoOrg: ({ payload, orgId }) => {
    dispatch(
      pageWithPayloadAndQuery({
        payload: { ...payload, id1: orgId },
        query: {},
      })
    );
  },
  editUser: ({ orgId, personId, data }) =>
    dispatch(updateUser({ orgId, personId, data, editingSelf: false })),
  addExistingUser: ({ orgId, email, roles }) =>
    dispatch(addUserToOrg({ orgId, email, roles })),
});

OrganizationsPageView.propTypes = {
  orgs: PropTypes.array,
  payload: PropTypes.shape({}),
};

OrganizationsPageView.defaultProps = {
  orgs: [],
  orgsError: null,
  payload: { toplevel: "settings", secondlevel: "organizations" },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationsPageView);
