import { styled } from "@mui/material/styles";

const PREFIX = "MSPCustomRampFields";

export const classes = {
  customMonthlyInput: `${PREFIX}-customMonthlyInput`,
  overrideInput: `${PREFIX}-overrideInput`,
  needsApprovalContainer: `${PREFIX}-needsApprovalContainer`,
  rampTitle: `${PREFIX}-rampTitle`,
  overrideField: `${PREFIX}-overrideField`,
  overrideLabel: `${PREFIX}-overrideLabel`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.customMonthlyInput}`]: {
    "& .MuiFormLabel-root": {
      color: theme.palette.superadmin.main,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      "& .Mui-disabled": {
        backgroundColor: "rgba(204, 206, 207, 0.25)", // LIGHT_GRAY from theme
        cursor: "not-allowed",
      },
    },
  },
  [`& .${classes.overrideInput}`]: {
    width: "12%",
    "& .MuiInputLabel-root": {
      whiteSpace: "normal",
      color: "black",
    },
    "& .Mui-disabled": {
      backgroundColor: "rgba(204, 206, 207, 0.25)", // LIGHT_GRAY from theme
      cursor: "not-allowed",
    },
  },
  [`& .${classes.needsApprovalContainer}`]: {
    width: "24%",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "100%",
      border: "1px solid",
      height: 20,
      bottom: -20,
      left: 0,
      borderColor:
        "transparent rgba(77, 65, 131, 0.35) rgba(77, 65, 131, 0.35) rgba(77, 65, 131, 0.35)", // Superadmin main/dark purple color from theme
    },
    "&::before": {
      position: "absolute",
      content: '"Requires manager approval"',
      width: "63%",
      height: 20,
      bottom: -30,
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      color: theme.palette.superadmin.main,
      fontSize: 13,
      backgroundColor: theme.palette.superadmin.light,
      zIndex: 1,
    },
  },
  [`& .${classes.rampTitle}`]: {
    fontSize: "14px !important",
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
  [`& .${classes.overrideField}`]: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`& .${classes.overrideLabel}`]: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: "15px",
  },
}));
