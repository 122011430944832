import axios from "axios";
import _ from "lodash";
import { GraphQLClient } from "graphql-request";

import { auth } from "../auth";
import config from "../config";
import Logger from "../logger";

const logger = Logger("API");

/*
Constants
*/

export const WILDCARD_ORG_ID = "00000000-0000-0000-0000-000000000000";

export const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";

export const X_BLU_REQUEST_ID_HEADER = "x-blu-request-id";

/*
Helpers
*/

export const apiUrl = (url) => {
  return `${config.api}/${url}`;
};

const createClient = () =>
  new GraphQLClient(apiUrl("graphql"), {
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  });

export const getApiErrorId = (err) => {
  const { response = {} } = err;
  const { headers = {} } = response;

  if (headers[X_BLU_REQUEST_ID_HEADER]) {
    return headers[X_BLU_REQUEST_ID_HEADER];
  }

  if (headers.get) {
    return headers.get(X_BLU_REQUEST_ID_HEADER) || "";
  }

  return "";
};

const apiErrorMessage = ({ message = "API request error.", err }) => {
  const errorId = getApiErrorId(err);

  return `${message} Error ID: ${errorId}`;
};

const parseResponse = (response) => {
  const { data: resp = {} } = response;

  const ret = {
    message: "OK",
    statusCode: response.status,
    success: true,
    data: {},
    ...resp,
  };

  return ret;
};

const parseError = (err) => {
  logger.error(apiErrorMessage({ err }), err);

  const { response = {} } = err;
  const { data = {}, headers = {} } = response;

  if (response.status === 401) {
    auth.handleAuthErrorApiResponse(err);
  } else {
    const resp =
      typeof data === "string" || data instanceof String
        ? { message: data }
        : data;

    const errorProps = {
      message: "Internal error",
      statusCode: 0,
      errors: [],
      success: false,
      ...resp,
      response: { headers },
    };
    const error = new Error();
    Object.assign(error, errorProps);

    return error;
  }
};

/*
Axios configuration
*/

axios.defaults.timeout = 120 * 1000; // wait 2 min

axios.interceptors.response.use(
  (response) => parseResponse(response),
  (error) => Promise.reject(parseError(error))
);

axios.interceptors.request.use(
  (request) => {
    if (!(request.url.indexOf(config.api) === 0)) {
      delete request.headers;
    }
    return request;
  },
  (error) => Promise.reject(parseError(error))
);

export const setAxiosHeader = (token = null) => {
  if (token !== null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setAxiosOrgHeader = (orgId = null) => {
  if (orgId !== null && orgId !== WILDCARD_ORG_ID) {
    axios.defaults.headers.common["x-blu-orgid"] = orgId;
  } else {
    delete axios.defaults.headers.common["x-blu-orgid"];
  }
};

/*
Main class
*/

export class API {
  constructor(options) {
    const { id = _.uniqueId(), baseUrl } = options;
    this.options = options;
    this.id = id;
    this.baseUrl = baseUrl;
    this.graphql = false;
    this.cancel = () => logger.warn("Cancel not implemented");
    if (this.baseUrl.includes("graphql")) {
      this.graphql = true;
    }
    setAxiosHeader(auth.token);
  }

  buildUrl(options = {}) {
    const { params = [], query = {}, baseUrl = this.baseUrl } = options;
    let url = options.url || `${baseUrl}`;
    _.each(params, (p) => {
      url += `/${p}`;
    });
    if (!_.isEmpty(query)) {
      url += "?";
      _.each(query, (v, k) => {
        if (!_.isUndefined(v)) {
          url += `${k}=${v}&`;
        }
      });
      url = url.substr(0, url.length - 1);
    }
    return apiUrl(url);
  }

  get(options = {}) {
    const { query = "", data = {} } = options;
    if (this.graphql) {
      // using rawRequest since, if there is an error, we will need headers
      return createClient()
        .rawRequest(query, data)
        .then(({ data: respData }) => respData)
        .catch((e) => {
          if (e.response && e.response.status === 401) {
            auth.handleAuthErrorApiResponse(e);
          }
        });
    }
    const url = this.buildUrl(options);
    return axios.get(url);
  }

  put(options = {}, data = {}) {
    const url = this.buildUrl(options);
    return axios.put(url, data);
  }

  post(options = {}, data = {}) {
    const url = this.buildUrl(options);
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    this.cancel = source.cancel;
    return axios.post(url, data, { cancelToken: source.token });
  }

  delete(options = {}) {
    const url = this.buildUrl(options);
    return axios.delete(url);
  }
}

export { axios };
