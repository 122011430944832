const psaCompanyMappingPSASchema = {
  $id: "/psacompanymapping/psa",
  title: "PSACompanyMappingPSA",
  type: "object",
  properties: {
    id: { type: "string" },
    identifier: { type: "string" },
    name: { type: "string" },
  },
  params: {
    type: "object",
    properties: {
      id: { type: "string" },
    },
    required: ["id"],
  },
  required: ["params"],
  additionalProperties: true,
};

export default psaCompanyMappingPSASchema;
