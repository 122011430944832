import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Button, TextField, DialogContent, DialogTitle } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { Message } from "lib/models/Message";
import SimpleTable from "views/Components/SimpleTable";
import { validateEmail } from "views/Components/Form/validators";
import { orgNameSelector } from "../../../../selectors/orgNameSelector";
import { classes, StyledDialog } from "./styles";
import { HubspotAPI } from "lib/api/HubspotAPI";
import { MaxioAPI } from "lib/api/MaxioAPI";

const API = {
  hubspot: new HubspotAPI(),
  maxio: new MaxioAPI(),
};

const MSPBillingContactsDialog = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [emailString, setEmailString] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => setEmailString(e.target.value);

  const validateEmails = (emailList) => {
    return emailList.every((str) => validateEmail(str));
  };

  const sendNotificationEmail = async () => {
    const message = new Message({
      body: `MSP Organization ${props.orgName} billing report recipients are: ${emailString}`,
      recipient: "msp@blumira.com",
      subject: "MSP Organization Billing Report Recipients have changed",
      type: 30,
    });

    try {
      const messageResponse = await message.create();
      if (messageResponse.error === true) {
        throw new Error("Failed to send notification email");
      }
    } catch (e) {
      throw new Error("Failed to send notification email");
    }
  };

  const updateBillingContacts = async (emailArray) => {
    const { statusCode } = await API.maxio.updateCustomer(
      props.currentOrg.maxioCustomerId,
      {
        billingContactEmails: emailArray,
      }
    );

    if (statusCode !== 200) {
      throw new Error("Failed to update billing contacts");
    }

    const { statusCode: statusCodeHubSpot } = await API.hubspot.updateBilling({
      billingContactEmails: emailArray,
    });

    if (statusCodeHubSpot !== 200) {
      throw new Error("Failed to update billing contacts");
    }
  };

  const handleAddRecipient = async () => {
    const genericErrMessage = "Oops, we had trouble with your request.";

    if (!validateEmails(emailString.split(", "))) {
      setErrorMessage("Oops, we detected an invalid email.");
      return;
    }

    setIsSaving(true);

    try {
      await sendNotificationEmail();

      const combinedEmailString = props.emailString
        ? `${props.emailString}, ${emailString}`
        : emailString;
      const emailArray = combinedEmailString.split(", ");

      await updateBillingContacts(emailArray);

      setEmailString("");
      props.reload();
    } catch (e) {
      setErrorMessage(genericErrMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveEmail = async ({ email }) => {
    const genericErrMessage = "Oops, we had trouble removing that email.";

    setIsSaving(true);

    try {
      await sendNotificationEmail();

      const emailArray = props.emailString.split(", ");
      const indexToRemove = emailArray.indexOf(email);

      if (indexToRemove !== -1) {
        emailArray.splice(indexToRemove, 1);
        await updateBillingContacts(emailArray);
        props.reload();
      } else {
        setErrorMessage("Email not found in the list.");
      }
    } catch (e) {
      setErrorMessage(genericErrMessage);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <StyledDialog open={props.open}>
      <div className={classes.dialogHeader}>
        <DialogTitle>Edit recipients</DialogTitle>
        <CloseIcon className={classes.close} onClick={props.toggle} />
      </div>
      <DialogContent className={classes.dialogContent}>
        <p className={classes.dialogDescriptionText}>
          Manage the email recipients who will receive notifications for new
          invoices, monthly billing reports, and any issues with payment
          methods. Keep your team informed by adding or updating email addresses
          here.
        </p>
        <div className={classes.textFieldButtonContainer}>
          <TextField
            type={"text"}
            value={emailString}
            label={"Email address"}
            onChange={handleChange}
            placeholder={"Enter email address here..."}
            validation={{ email: true }}
            className={classes.emailTextField}
            error={!!errorMessage}
            helperText={errorMessage ? errorMessage : ""}
          />
          <Button
            disabled={isSaving}
            variant={"contained"}
            className={classes.addRecipientButton}
            onClick={handleAddRecipient}
          >
            Add recipient
          </Button>
        </div>
        {!!props.emailContacts.length && (
          <SimpleTable
            isSearchHidden
            data={props.emailContacts}
            columns={[
              {
                title: "Email",
                field: "email",
              },
            ]}
            actions={[
              {
                icon: DeleteIcon,
                tooltip: "Remove email",
                onClick: (event, model) => handleRemoveEmail(model),
              },
            ]}
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

MSPBillingContactsDialog.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  isSaving: PropTypes.bool,
  emailString: PropTypes.string,
  emailContacts: PropTypes.array,
  customerId: PropTypes.number,
};

MSPBillingContactsDialog.defaultProps = {
  open: false,
  emailString: "",
  isSaving: false,
  emailContacts: [],
  customerId: 0,
  toggle: () => {
    console.warn("No toggle function provided for MSPBillingContactsDialog");
  },
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const { orgId: mspOrgId } = location.payload;

  const orgName = orgNameSelector(state);

  const currentOrg = state.org.listOrgs.orgs.find((o) => o["id"] === mspOrgId);

  return {
    currentOrg,
    orgName,
    orgId: mspOrgId,
    user: session.settings.user,
    isSuperadmin: session?.settings?.user?.superadmin,
  };
};

export default connect(mapStateToProps, null)(MSPBillingContactsDialog);
