import config from "lib/config";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

import { Menu, Paper, MenuItem } from "@mui/material";

import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

import "./DataGrid.scss";

import { LicenseInfo } from "@mui/x-data-grid-pro";

const PREFIX = "DataTable";
const classes = {
  actionsContainer: `${PREFIX}-actionsContainer`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  textField: `${PREFIX}-textField`,
  noRowsText: `${PREFIX}-noRowsText`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.actionsContainer}`]: {
    display: "flex",
  },
  [`& .${classes.paper}`]: {
    boxShadow: "none",
  },
  [`& .${classes.table}`]: {
    height: 600,
    width: "100%",
    border: "none",
    minHeight: 250,
    backgroundColor: theme.palette.background.default,
    "& .MuiDataGrid-toolbar": {
      justifyContent: "space-between",
    },
    "& .MuiDataGrid-main": {
      borderRadius: 5,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.divider}`,
    },
  },
  [`& .${classes.textField}`]: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.noRowsText}`]: {
    alignItems: "center",
    justifyContent: "center",
  },
}));

LicenseInfo.setLicenseKey(config["x_grid_license_key"]);
export default function DataTable(props) {
  const [page, setPage] = useState(0);
  const [contextMenu, setContextMenu] = useState({});
  const [pageSize, setPageSize] = useState(20);

  const apiRef = useGridApiRef();

  useEffect(() => {
    setPage(0);
  }, [props.data, setPage]);

  useEffect(() => {
    const handleRowClick = (params, event, details) => {
      props.onColumnOrderChange(
        details.api.getVisibleColumns().map((obj) => obj.field)
      );
    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent("columnHeaderDragEnd", handleRowClick);
  }, [apiRef]);

  const handleClick = (gridCellParams, event) => {
    if (props.contextMenuItems) {
      setContextMenu({
        anchorEl: event.currentTarget,
        model: gridCellParams.row,
        fieldName: gridCellParams.field,
      });
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const menuClick = (menuItem) => {
    menuItem.onClick(contextMenu);
    setContextMenu({});
  };

  const getRowId = (row) => {
    return row.id || props.data.indexOf(row);
  };

  return (
    <Root>
      <Paper
        className={classes.paper}
        onKeyDownCapture={(e) => {
          if (e.key === "Backspace" || e.key === "Delete") {
            e.stopPropagation();
          }
        }}
        datacy={"reportDataTable"}
      >
        <DataGridPro
          apiRef={apiRef}
          disableScrollLock={false}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          pagination
          pageSize={pageSize}
          rowsPerPageOptions={[20, 50, 100, 250]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          page={page}
          autoHeight={true}
          className={classes.table}
          onPageChange={handlePageChange}
          getRowId={getRowId}
          onCellClick={handleClick}
          loading={props.isFetching}
          rowHeight={38}
          icons={{
            OpenFilterButtonIcon: TuneOutlinedIcon,
          }}
          rows={props.data}
          columns={props.isFetching ? [] : props.columns}
        />
        <Menu
          id="context-menu"
          anchorEl={contextMenu.anchorEl}
          keepMounted
          open={Boolean(contextMenu.anchorEl)}
          onClose={() => setContextMenu({})}
          disableScrollLock={true}
        >
          {props.contextMenuItems &&
            props.contextMenuItems.map((menuItem) => (
              <MenuItem
                key={menuItem.text}
                disabled={
                  menuItem.isDisabled && menuItem.isDisabled(contextMenu)
                }
                onClick={() => menuClick(menuItem)}
              >
                {menuItem.text}
              </MenuItem>
            ))}
        </Menu>
      </Paper>
    </Root>
  );
}

DataTable.propTypes = {
  isFetching: PropTypes.bool,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
  isFetching: false,
};
