// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";

// ** Components **********************
import { DefaultIcon } from "views/Components/Icon";

// ** Style ***************************
import { EditContainer, FlexContainer, Header } from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Dropdown header
//
//  ** Remarks
//  Search form dropdown header for displaying current values
//
//  ** Props
//
const DropdownHeader = ({ icon, type, value }) => (
  <Header>
    <DefaultIcon height="16px" icon={icon} margin="0px 8px 0px 0px" />
    <FlexContainer>
      {type === "logType" &&
        typeof value !== "string" &&
        value.map((logType) => (
          <>
            <span>
              {logType.display_name ? logType.display_name : logType.name}
            </span>
            <br />
          </>
        ))}
      {type === "filter" &&
        typeof value !== "string" &&
        value.map((filter) => (
          <>
            <span>
              {filter.fieldName.value
                ? filter.fieldName.value
                : filter.fieldName}{" "}
              {filter.opcode.value ? filter.opcode.value : filter.opcode}{" "}
              {filter.fieldValue.value
                ? filter.fieldValue.value
                : filter.fieldValue}
            </span>
            <br />
          </>
        ))}
      {type !== "filter" && type !== "logType" && value}
    </FlexContainer>
    <EditContainer>
      <DefaultIcon height="14px" icon="edit" margin="0px 0px 0px 8px" />
    </EditContainer>
  </Header>
);

export default DropdownHeader;
