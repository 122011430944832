import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FullWidthFinding from "views/Components/Finding/FullWidth";
import {
  LabelsAndTagsFetch,
  TAG_OBJECT_TYPES,
} from "views/Components/Label/Label";

const DetailView = ({ finding, matches, users, ready, rules, conditions }) => {
  return (
    <div className="finding-detail-view">
      <div className="content">
        <LabelsAndTagsFetch
          objId={[finding?.id || ""]}
          objType={[TAG_OBJECT_TYPES.FINDING]}
        >
          <FullWidthFinding
            matches={matches}
            finding={finding}
            users={users}
            ready={ready}
            rules={rules}
            conditions={conditions}
          />
        </LabelsAndTagsFetch>
      </div>
    </div>
  );
};

DetailView.propTypes = {
  finding: PropTypes.shape({}),
};

DetailView.defaultProps = {
  finding: {},
};

const mapStateToProps = (state, ownProps) => {
  return {
    orgId: state.page.payload.orgId,
  };
};

const mapDispatchToProps = (dispatch, { finding }) => {
  finding = finding ? finding[0] : null;
  return { finding };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailView);
