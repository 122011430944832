import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";

import {
  sensorIdSelector,
  currentSensorSelector,
  detailLoadingSelector,
} from "../../../selectors/sensorsSelectors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  LabelsAndTagsWithFetch,
  TAG_OBJECT_TYPES,
} from "../../Components/Label/Label";
import { fetchSensor } from "../../../redux/actions/Sensors";
import { pageWithQuery } from "../../../redux/actions/Page";
import SensorDescription from "./SensorDescription";
import Devices from "./Devices";
import ErrorMessages from "./ErrorMessages";
import Installation from "./Installation";
import Overview from "./Overview";
import Modules from "./Modules";
import ModuleDetailModal from "./ModuleDetailModal";
import NewModuleModal from "./NewModuleModal";
import SensorHeader from "./SensorHeader";
import Support from "./Support";

import "./Sensor.scss";

/*
Component
*/

const Sensor = ({
  addNewModule,
  addNewModuleShow,
  detailLoading,
  detailId,
  detailShow,
  dispatchFetchSensor,
  orgId,
  sensorId,
  sensorName,
  superadmin,
}) => {
  useEffect(() => {
    dispatchFetchSensor({ orgId, sensorId });
    // Add fetching sensor devices here, if you do not expect
    // the present Sensor component to be reloaded on sensor ID change.
  }, [sensorId, dispatchFetchSensor, orgId]);

  // Show Redux errors only if no modals are open
  const noModals = !addNewModule && !detailId;

  return (
    <div className="sensor-view">
      {noModals && <ErrorMessages />}

      {!!detailId && <ModuleDetailModal />}

      {!!addNewModule && <NewModuleModal />}

      <div className="sensor-header-section">
        {(!detailLoading || sensorName) && (
          <React.Fragment>
            <SensorHeader />

            <SensorDescription />
          </React.Fragment>
        )}
      </div>

      <div className="sensor-section">
        <div className="sensor-section-header overview">
          <div className="overview-title">Overview</div>

          <div className="sensor-labels">
            <LabelsAndTagsWithFetch
              objId={sensorId}
              objType={TAG_OBJECT_TYPES.SENSOR}
              orgId={orgId}
              superadmin={superadmin}
            />
          </div>
        </div>

        <div className="sensor-section-body">
          <Overview className="sensor-overview" />
        </div>
      </div>

      <div className="sensor-section">
        <div className="sensor-section-header">Installation Instructions</div>

        <div className="sensor-section-body">
          <Installation />
        </div>
      </div>

      <div className="sensor-section">
        <div className="sensor-section-header">Logging devices</div>

        <div className="sensor-section-body">
          <Devices className="sensor-devices" />
        </div>
      </div>

      <div className="sensor-section">
        <div className="sensor-section-header">
          Modules
          <Button
            color="primary"
            onClick={() => {
              addNewModuleShow();
            }}
            datacy={"sensorsAddModuleButton"}
          >
            Add Module
          </Button>
        </div>

        <div className="sensor-section-body">
          <Modules className="sensor-modules" gotoModule={detailShow} />
        </div>
      </div>

      {superadmin && (
        <div className="sensor-section">
          <div className="sensor-section-header">Support</div>

          <div className="sensor-section-body">
            <Support className="sensor-support" />
          </div>
        </div>
      )}
    </div>
  );
};

Sensor.propTypes = {
  addNewModule: PropTypes.string,
  addNewModuleShow: PropTypes.func.isRequired,
  detailLoading: PropTypes.bool.isRequired,
  detailId: PropTypes.string,
  detailShow: PropTypes.func.isRequired,
  dispatchFetchSensor: PropTypes.func.isRequired,
  orgId: PropTypes.string,
  sensorId: PropTypes.string.isRequired,
  sensorName: PropTypes.string.isRequired,
  superadmin: PropTypes.bool.isRequired,
};

Sensor.defaultProps = {
  addNewModule: "",
  detailId: "",
  orgId: "",
};

const mapStateToProps = (state) => {
  const { session, location } = state;

  const loggedInUser = session.settings.user;

  const orgId = orgIdSelector(state);
  const sensorId = sensorIdSelector(state);
  const sensor = currentSensorSelector(state);
  const { name: sensorName = "" } = sensor;

  const { query = {} } = location;
  const { detailId = "", addNewModule = "" } = query;

  const { superadmin = false } = loggedInUser;

  const detailLoading = detailLoadingSelector(state);

  return {
    addNewModule,
    detailId,
    detailLoading,
    orgId,
    query,
    sensorId,
    sensorName,
    superadmin,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewModuleShow: () => {
    const query = {
      addNewModule: "true",
    };
    dispatch(pageWithQuery(query));
  },

  detailShow: (installId) => {
    const query = {
      detailId: installId,
    };
    dispatch(pageWithQuery(query));
  },

  dispatchFetchSensor: ({ orgId, sensorId }) => {
    dispatch(fetchSensor({ orgId, sensorId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sensor);
