import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  StyledCard,
  StyledCardContainer,
  StyledErrorContainer,
  classes,
} from "./SimpleCountInfoCardStyles";

export const SimpleCardContainer = StyledCardContainer;

// Uncomment this code block if/when we move to typescript.
// Meanwhile, this block serves to document the prop types.
//
// interface SimpleCountInfoCardProps {
//   widthPercent: number;
//   header: string;
//   description?: string;
//   tooltip?: string;
//   countToDisplay?: number;
//   isLoading: boolean;
//   error?: string;
//   action?: React.ElementType;
// }

export const SimpleCountInfoCard = (props) => {
  return (
    <Tooltip title={props.tooltip}>
      <StyledCard
        variant={"outlined"}
        className={classes.card}
        style={{ width: `${props.widthPercent}%` }}
      >
        <p className={classes.header}>{props.header}</p>
        <span className={classes.description}>{props.description || ""}</span>
        <div className={classes.countAndActionContainer}>
          {props.isLoading ? (
            <CircularProgress color={"primary"} title="Loading" />
          ) : props.error ? (
            <StyledErrorContainer title="Error">
              <ErrorIcon color="error" fontSize="large" />
            </StyledErrorContainer>
          ) : (
            <p className={classes.count}>{props.countToDisplay || "0"}</p>
          )}
          {props.action && props.action}
        </div>
      </StyledCard>
    </Tooltip>
  );
};
