// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { connect } from "react-redux";
import { filter, findIndex } from "lodash";
import uuid from "uuid";
import { arrayOf, func, shape, string } from "prop-types";

// ** Components **********************
import { LinkButton } from "views/Components/Button";
import { PlusIcon, TrashIcon } from "views/Components/Icon";
import { DefaultInput } from "views/Components/Input";
import { DefaultSelect } from "views/Components/Select";

// ** Constants ***********************
import { OPERATORS } from "utils/constants/terinaryOperators";

// ** Lib *****************************
import formatOptions from "utils/lib/formatOptions";

// ** Styles **************************
import {
  ConditionalContainer,
  FilterContainer,
  IconContainer,
  RelativeContainer,
  Row,
  RowSection,
  Tag,
} from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Search filters
//
//  ** Remarks
//  Allows user to apply filters to search
//
//  ** Props
//  @param datasource {string} - datasource utilizing filtering functionality
//  @param filters {array} - list of current filters applied to query
//  @param keys {array} - list of possible keys
//  @param onChange {func} - invoked on field change
//  @param terinaryOperators {array} - list of possible terinary operators
//  @param values {array} - list of possible values (if applicable)
//
class SearchFilters extends Component {
  constructor(props) {
    super(props);

    this.filter = {
      id: uuid.v4(),
      fieldName: "",
      opcode: "",
      fieldValue: "",
    };
    this.newFilter = this.filter;
    this.state = {
      filters: props.filters || [],
    };
  }

  onAdd() {
    const { filters, onChange } = this.props;
    const currentFilters =
      filters && filters.length > 0 ? filters : this.state.filters;

    const update = [...currentFilters, { ...this.newFilter, id: uuid.v4() }];

    onChange("queryFilters", update);
  }

  onChange(id, key, value) {
    const { filters, onChange } = this.props;
    const currentFilters =
      filters && filters.length > 0 ? filters : this.state.filters;

    const update = currentFilters.map((fil) => {
      if (fil.id === id) {
        // we append the index to the key for a
        // unique id to avoid rendering errors
        // remove it here to set the proper key/value
        var selectedKey = key.split("-")[0];
        return { ...fil, [selectedKey]: value };
      }
      return { ...fil };
    });

    onChange("queryFilters", update);
  }

  onDelete(id) {
    const { filters, onChange } = this.props;
    const currentFilters =
      filters && filters.length > 0 ? filters : this.state.filters;

    const update = filter(currentFilters, (fil) => fil.id !== id);

    onChange("queryFilters", update);
  }

  renderOperatorOptions(fil, keys) {
    if (fil.fieldName.value) {
      if (
        keys &&
        keys[findIndex(keys, ["name", fil.fieldName.value])]
          .valid_operator_names.length === 1 &&
        fil.opcode === ""
      ) {
        const operator =
          keys[findIndex(keys, ["name", fil.fieldName.value])]
            .valid_operator_names;

        this.onChange(fil.id, "opcode", {
          label: OPERATORS[operator].display_name,
          value: OPERATORS[operator].name,
        });
        return [];
      }

      return (
        keys &&
        keys[
          findIndex(keys, ["name", fil.fieldName.value])
        ].valid_operator_names.map((operator) => ({
          label: OPERATORS[operator].display_name,
          value: OPERATORS[operator].name,
        }))
      );
    }

    return [];
  }

  render() {
    const { columns, filters, keys, values } = this.props;
    const currentFilters =
      filters && filters.length > 0 ? filters : this.state.filters;
    const filteredKeys = keys && keys.filter((key) => key !== undefined);

    return (
      <FilterContainer>
        {currentFilters.map((fil, i) => (
          <Row multiple={currentFilters.length > 1} key={i}>
            {i < currentFilters.length && i > 0 && (
              <ConditionalContainer top={i > 1 ? 53 * (i - 1) + 17 : 17}>
                <RelativeContainer>
                  <Tag>AND</Tag>
                </RelativeContainer>
              </ConditionalContainer>
            )}
            <Row>
              <RowSection>
                <DefaultSelect
                  id={`fieldName-${i}`} // use index to avoid errors with non-unique identifiers
                  onChange={this.onChange.bind(this, fil.id)}
                  options={
                    filteredKeys
                      ? formatOptions(
                          filteredKeys.filter(
                            (option) =>
                              findIndex(currentFilters, [
                                "fieldName.value",
                                option.name,
                              ]) < 0
                          ),
                          ["display_name", "name"],
                          "name"
                        )
                      : []
                  }
                  placeholder="Select column..."
                  value={
                    fil.fieldName && fil.fieldName.label
                      ? fil.fieldName.label
                      : fil.fieldName
                  }
                />
              </RowSection>
              <RowSection>
                <DefaultSelect
                  id={`opcode-${i}`} // use index to avoid errors with non-unique identifiers
                  onChange={this.onChange.bind(this, fil.id)}
                  options={
                    filteredKeys ? this.renderOperatorOptions(fil, columns) : []
                  }
                  placeholder="Operator..."
                  value={
                    fil.opcode && fil.opcode.label
                      ? fil.opcode.label
                      : fil.opcode
                  }
                />
              </RowSection>
              <RowSection>
                {values[
                  fil.fieldName.label
                    ? fil.fieldName.label.toLowerCase()
                    : fil.fieldValue
                ] ? (
                  <DefaultSelect
                    id={`fieldValue-${i}`} // use index to avoid errors with non-unique identifiers
                    onChange={this.onChange.bind(this, fil.id)}
                    options={
                      values[
                        fil.fieldName.label
                          ? fil.fieldName.label.toLowerCase()
                          : fil.fieldName
                      ].selectOptions
                    }
                    placeholder="Select Value..."
                    value={
                      fil.fieldValue && fil.fieldValue.label
                        ? fil.fieldValue.label
                        : fil.fieldValue
                    }
                  />
                ) : (
                  <DefaultInput
                    id={`fieldValue-${i}`} // use index to avoid errors with non-unique identifiers
                    onChange={this.onChange.bind(this, fil.id)}
                    placeholder="Value..."
                    value={fil.fieldValue}
                  />
                )}
              </RowSection>
              <IconContainer onClick={this.onDelete.bind(this, fil.id)}>
                <TrashIcon height="14px" />
              </IconContainer>
            </Row>
          </Row>
        ))}
        <Row multiple={currentFilters.length > 1}>
          <LinkButton
            disabled={
              filteredKeys && filteredKeys.length === currentFilters.length
            }
            onClick={this.onAdd.bind(this)}
            padding="4px 2px"
          >
            <PlusIcon height="10px" margin="-3px 6px 0px 0px" />
            Add Filter
          </LinkButton>
        </Row>
      </FilterContainer>
    );
  }
}

// ** Prop types **********************
SearchFilters.propTypes = {
  dataSource: string,
  filters: arrayOf(shape({})),
  keys: arrayOf(shape({})),
  onChange: func.isRequired,
  values: shape({}).isRequired,
};

// ** Map state to props **************
const mapStateToProps = (state, ownProps) => ({
  values:
    ownProps.dataSource === "finding"
      ? state.findings.get("constants", {})
      : {},
});

export default connect(mapStateToProps, null)(SearchFilters);
