import React from "react";
import PropTypes from "prop-types";

import BluBarGraph from "./common/BluBarGraph";
import { formatBarLabel } from "./common/securityHelpers";

/*
Constants
*/

const BAR_COLOR = "#e8a838"; // hsl(38, 79%, 56%) rgb(232, 168, 56)
const BAR_COLOR_DARKER_1_6 = "#835f20"; // hsl(38, 61%, 32%) rgb(131, 95, 32)
const TOOLTIP_TEXT = "bytes";

/*
Main compenent
*/

const EndpointHostsGraph = ({
  labelField,
  labelField2,
  rawData,
  valueField,
}) => (
  <BluBarGraph
    labelField={labelField}
    labelField2={labelField2}
    rawData={rawData}
    valueField={valueField}
    barLabelColor={BAR_COLOR_DARKER_1_6}
    colors={[BAR_COLOR]}
    formatBarLabel={formatBarLabel}
    tooltipText={TOOLTIP_TEXT}
  />
);

EndpointHostsGraph.propTypes = {
  labelField: PropTypes.string.isRequired,
  labelField2: PropTypes.string.isRequired,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  valueField: PropTypes.string.isRequired,
};

EndpointHostsGraph.defaultProps = {
  rawData: [],
};

export default EndpointHostsGraph;
