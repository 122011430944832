export const conditionSetEntrySchema = {
  $id: "/conditionsetentry",
  type: "object",
  properties: {
    step: { type: "number" },
    condition: { type: "number" },
  },
  required: ["step", "condition"],
  additionalProperties: false,
};

const ruleSchema = {
  $id: "/rule",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    name: { type: "string" },
    isBasis: { type: "boolean", readOnly: true },
    orgId: { type: "string", format: "uuid" },
    originatingRule: { type: ["string", "null"], format: "uuid" },
    status: { type: "number" },
    conditionSet: { type: "array", items: { $ref: "/conditionsetentry" } },
    tags: { type: "array", items: { type: "string" } },
    created: { type: "string", format: "date-time", readOnly: true },
  },
  required: ["status", "conditionSet", "name"],
  additionalProperties: false,
};

export default ruleSchema;
