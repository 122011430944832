const mspBillingPlanSchema = {
  $id: "/mspbillingplan",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    templateId: { type: "string", createOnly: true },
    orgId: { type: "string", format: "uuid", readOnly: true },
    rampId: { type: "string", format: "uuid", readOnly: true },
    createdBy: { type: "string", readOnly: true },
    created: { type: "string", format: "date-time", readOnly: true },
    deleted: { type: "string", format: "date-time", readOnly: true },
    deletedBy: { type: "string", readOnly: true },
    termDate: { type: "string", format: "date-time" },
    isAutoRenew: { type: "boolean" },
    template: {
      type: "object",
      properties: {
        id: { type: "string", format: "uuid", readOnly: true },
        tier: { type: "number" },
        name: { type: "string" },
        minCommit: { type: "number" },
      },
    },
    ramp: {
      type: "object",
      properties: {
        id: { type: "string", format: "uuid", readOnly: true },
        monthOneMin: { type: "number" },
        monthTwoMin: { type: "number" },
        monthThreeMin: { type: "number" },
        monthFourMin: { type: "number" },
        monthFiveMin: { type: "number" },
        monthSixMin: { type: "number" },
        minCommit: { type: "number" },
        orgId: { type: "string", format: "uuid" },
        templateId: { type: "string", format: "uuid" },
        termStart: { type: "string", format: "date-time" },
        termEnd: { type: "string", format: "date-time" },
      },
    },
    licenses: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string", format: "uuid", readOnly: true },
          license: { type: "string" },
          name: { type: "string" },
          pricePerUser: { type: "number" },
          msrpPricePerUser: { type: "number" },
        },
      },
    },
  },
  required: [],
  additionalProperties: true,
};

export default mspBillingPlanSchema;
