import { WILDCARD_ORG_ID } from "lib/api/API";
import { sortByName } from "../lib/helpers";
import { auth } from "../lib/auth";
import {
  ADD_ERROR,
  CLEAR_ERROR,
  RECEIVE_OUTAGE_STATUS,
  RECEIVE_SETTINGS,
  RECEIVE_USER,
  RECEIVE_ZENDESK_STATUS,
  RESET_SETTINGS,
} from "./actions/Settings";

export const TOGGLE_SCROLLER = "SETTINGS.TOGGLE_SCROLLER";

const initialState = {
  accountConstants: {},
  scrollerOpen: true,
  currentUserId: null,
  zendeskLoaded: false,
  currentUserOrgs: [],
  outageStatus: {},
};

const SettingsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_SCROLLER: {
      return {
        ...state,
        scrollerOpen: !state.scrollerOpen,
      };
    }

    case ADD_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case CLEAR_ERROR: {
      const newState = { ...state };
      delete newState.error;

      return newState;
    }

    case RECEIVE_OUTAGE_STATUS: {
      const { payload = {} } = action;

      const newState = {
        ...state,
        outageStatus: {
          ...payload,
        },
      };

      return newState;
    }

    case RECEIVE_SETTINGS: {
      auth.person_id = action.settings.user.id;
      const nextState = {
        ...state,
        ...action.settings,
        currentUserId: action.settings.user.id,
        userOrgs: action.settings.userOrgs.sort(sortByName),
        currentUserOrgs: action.settings.userOrgs.sort(sortByName),
        zendeskJWT: action.settings.user.zendeskJWT || "",
      };

      // eslint-disable-next-line no-unused-vars
      let activeOrg = { id: undefined, name: "" };

      // TODO: Currently used but we should be storing settings
      // in the session reducer exclusively, if I'm not mistaken
      if (state.location?.payload?.orgId)
        activeOrg = action.settings.user.orgs.find(
          (o) => o["id"] === state.location.payload.orgId
        ) || { id: state.location.payload.orgId, name: "Acting Super Admin" };
      else activeOrg = { id: WILDCARD_ORG_ID, name: "Acting Super Admin" };
      delete nextState.user;
      delete nextState.userOrgs;

      return nextState;
    }

    // only overwrite fields that are being changed for user
    case RECEIVE_USER: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
      };
    }

    case RECEIVE_ZENDESK_STATUS: {
      return {
        ...state,
        zendeskLoaded: action.zendeskLoaded,
      };
    }

    case RESET_SETTINGS: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default SettingsReducer;
