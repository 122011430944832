import { createSelector } from "reselect";

const requestSelector = (state) => state.request.requests;
const uuidSelector = (state) =>
  state.request.pageCache[
    `${state.page.payload.toplevel}/${state.page.payload.secondlevel}`
  ];

const mapRequests = (requests, uuids) => {
  if (requests && uuids) {
    const entries = Object.keys(uuids).map((a) => [
      a,
      requests[uuids[a]] ? requests[uuids[a]].response : undefined,
    ]);
    return Object.fromEntries(entries);
  } else {
    return {};
  }
};

export const requestMapper = createSelector(
  [requestSelector, uuidSelector],
  mapRequests
);
