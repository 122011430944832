import React from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { sanitize } from "../../../../lib/helpers";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const PREFIX = "Message";
const classes = {
  paper: `${PREFIX}-paper`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.paper}`]: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
  },
}));

const MessageItem = (props) => {
  return (
    <StyledPaper elevation={0} variant="outlined" className={classes.paper}>
      {props.user && (
        <Typography>
          {props.user.first_name} {props.user.last_name}
        </Typography>
      )}
      <Typography variant="overline">
        {moment.utc(props.created).tz(moment.tz.guess(true)).format("lll z")}
      </Typography>
      <Divider />
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={sanitize(props.body, {
          html: true,
          safe: true,
        })}
      />
    </StyledPaper>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.users.byId[props.sender],
  };
};

export default connect(mapStateToProps)(MessageItem);
