import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { requestMapper } from "selectors/RequestSelector";
import { connect } from "react-redux";
import HeaderView from "./HeaderView2";
import SidebarView from "./SidebarView2";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MuiErrorBanner from "./Components/MuiErrorBanner";
import LicenseRestriction from "views/Components/License";
import AutoIsolationControlBar from "./Components/AutoIsolation/ControlBar";
import { getLanguageDictionary } from "../lib/helpers";
import Request from "lib/api/Request";
import { renderSpacerGridItem } from "../utils/sidebarUtils";
import { getPageHashParams } from "utils";
import {
  ORG_TYPES,
  MARKETS,
  LICENSE_TYPES,
  SCHEDULED_LICENSE_TYPES,
} from "./Pages/OrganizationPageView";
import Cookies from "universal-cookie";
import _ from "lodash";
import clsx from "clsx";
import moment from "moment";

const language = getLanguageDictionary("en");
const now = moment.utc().toISOString();

const setCustomerInfoCookie = (orgDetails = {}) => {
  const { type, market: channel, license, scheduledLicense } = orgDetails;
  const cookies = new Cookies();

  // If org has an active scheduled license, then use that as the license value.  Otherwise use the regular license value
  if (scheduledLicense === "XDR_TRIAL" && license === "FREE") {
    cookies.set(
      "orgDetails",
      JSON.stringify({
        type: type,
        channel: channel,
        license: scheduledLicense,
      }),
      {
        path: "/",
        domain: ".blumira.com",
      }
    );
  } else {
    cookies.set(
      "orgDetails",
      JSON.stringify({
        type: type,
        channel: channel,
        license: license,
      }),
      {
        path: "/",
        domain: ".blumira.com",
      }
    );
  }
};

const isObject = (value) =>
  _.isObject(value) &&
  !(
    value instanceof String ||
    value instanceof Number ||
    value instanceof Array ||
    value instanceof Function ||
    value instanceof RegExp
  );

export const getOrgDetails = (
  org = {},
  orgTypes = [],
  markets = [],
  licenseTypes = [],
  scheduledLicenseTypes = []
) => {
  let orgObj = {};
  if (isObject(org) && !_.isEmpty(org)) {
    const type =
      orgTypes.find((ot) => ot.value === org.orgType)?.label || org.orgType;
    const market =
      markets.find((m) => m.value === org.market)?.label || org.market;
    const license =
      licenseTypes.find((lt) => lt.value === org.license)?.value ||
      org.license ||
      licenseTypes.find((lt) => lt.value === "ADVANCED")?.value;
    const scheduledLicense =
      org?.licenseScheduled &&
      now > org?.licenseScheduled.start &&
      now < org?.licenseScheduled.end
        ? scheduledLicenseTypes.find(
            (slt) => slt.value === org?.licenseScheduled?.license_name
          )?.value || org?.licenseScheduled?.license_name
        : "";

    orgObj = {
      type: type,
      market: market,
      license: license,
      scheduledLicense: scheduledLicense,
    };
  }
  return orgObj;
};

const PREFIX = "PageView2Styles";
const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  grid: `${PREFIX}-grid`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  pageTitle: `${PREFIX}-pageTitle`,
  pageSubTitle: `${PREFIX}-pageSubTitle`,
  pageTitleDivider: `${PREFIX}-pageTitleDivider`,
  gridItem: `${PREFIX}-gridItem`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    width: "100%",
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${classes.grid}`]: {
    margin: "-12px -12px 12px -12px",
    width: "calc(100% - 220px)",
  },
  [`& .${classes.appBarSpacer}`]: {
    minHeight: 64,
  },
  [`& .${classes.pageTitle}`]: {
    margin: theme.spacing(0, 1, 0, 2),
    verticalAlign: "center",
    display: "inline-block",
  },
  [`& .${classes.pageSubTitle}`]: {
    margin: theme.spacing(0, 1, 0, 2),
    verticalAlign: "center",
    display: "inline-block",
    borderLeft: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 3, 0),
  },
  [`& .${classes.pageTitleDivider}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.gridItem}`]: {
    paddingTop: `${theme.shape.padding}px !important`,
    width: "100%",
  },
}));

const PageView = (props) => {
  const { ChildView, payload = {} } = props;
  const [navType, setNavType] = useState("left"); // eslint-disable-line no-unused-vars
  const [orgLicenseType, setOrgLicenseType] = useState("");
  const [pageHashParams, setPageHashParams] = useState(props.pageHash);

  useEffect(() => {
    const handleHashChange = () => {
      const params = getPageHashParams();
      setPageHashParams(params);
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    // quick compare, so we're not forcing unnecessary renders
    if (
      Object.entries(props.pageHash).sort().toString() !==
      Object.entries(pageHashParams).sort().toString()
    ) {
      setPageHashParams(props.pageHash);
    }
  }, [props.pageHash]);

  useEffect(() => {
    new Request("/org", [{ field: "id", value: props.payload.orgId }])
      .get()
      .then((orgArr) => {
        const orgDetails = getOrgDetails(
          orgArr[0],
          ORG_TYPES,
          MARKETS,
          LICENSE_TYPES,
          SCHEDULED_LICENSE_TYPES
        );
        // This instance of license is for displaying the org's license name above the help button
        let license;
        // If org has an active trial in progress, send scheduled license value.  Otherwise use regular license value
        if (
          orgArr[0]?.licenseScheduled !== undefined &&
          orgArr[0].license === "FREE" &&
          now > orgArr[0]?.licenseScheduled?.start &&
          now < orgArr[0]?.licenseScheduled?.end
        ) {
          license =
            SCHEDULED_LICENSE_TYPES.find(
              (lt) => lt.value === orgArr[0]?.licenseScheduled?.license_name
            )?.label || orgArr[0]?.licenseScheduled?.license_name;
        } else {
          license =
            LICENSE_TYPES.find((lt) => lt.value === orgArr[0].license)?.label ||
            orgArr[0].license ||
            LICENSE_TYPES.find((lt) => lt.value === "ADVANCED")?.label;
        }

        setOrgLicenseType(license);
        if (!_.isEmpty(orgDetails)) {
          setCustomerInfoCookie(orgDetails);
        } // else don't set a cookie
      })
      .catch((err) => {
        // intentionally letting this fail silently
        // if something goes wrong with the api call
        // by not doing anything with the err
        // since all we're doing here is setting a cookie in the background
        // the end user doesn't need to know it failed
      });
  }, [props.payload.orgId]);

  let title,
    description = false;
  if (
    language.pages[payload.toplevel] &&
    language.pages[payload.toplevel][payload.secondlevel]
  ) {
    ({ title, description } =
      language.pages[payload.toplevel][payload.secondlevel]);
  }

  useEffect(() => {
    // Removes the viewingQuery local storage value from the Findings page when you navigate away from the Findings page after selecting a preset value
    if (
      localStorage.getItem("viewingQuery") &&
      props?.payload?.secondlevel !== "findings"
    ) {
      localStorage.removeItem("viewingQuery");
    }
  }, [props.payload]);

  return (
    <Root sx={{ display: "flex", width: "100%", flexGrow: 1 }}>
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <HeaderView {...props} withNav={navType === "top"} />
          {/* 
            If an org with an orgLicenseType of Free SIEM, then don't allow the AHI bar to be shown
            even if they have the license feature chip on their org.

            If an org has any other value for an orgLicenseType, 
            only allow them to see the AHI control bar if they have the license feature chip on their org.

            This conditional is being put here temporarily for the Timed Trial project until we no longer need to 
            manually deprovision license features and other properties after a trial is expired.  It will be removed
            once we get that figured out we can take this conditional away.

            This conditional is also everywhere that we are using the license restriction component to determine
            whether we should show the AHI control bar or not.  If you search the code for "features={["AUTOBOT"]}",
            then you'll find all instances of it when it comes to removing it.
          */}
          {orgLicenseType !== "Free SIEM" ? (
            <LicenseRestriction
              features={["AUTOBOT"]}
              renderAllow={(children) => children}
              renderDisallow={() => null}
            >
              <AutoIsolationControlBar />
            </LicenseRestriction>
          ) : null}
        </AppBar>
        <SidebarView
          dispatch={props.dispatch}
          licenseLabel={orgLicenseType}
          license={props.license}
        />
        <Grid container direction="column" spacing={3} className={classes.grid}>
          <Grid item className={classes.appBarSpacer} />
          {orgLicenseType !== "Free SIEM" ? (
            <LicenseRestriction
              features={["AUTOBOT"]}
              renderAllow={renderSpacerGridItem}
              renderDisallow={() => null}
            />
          ) : null}
          {title && (
            <Grid item>
              <Typography
                component="h1"
                variant="h5"
                className={classes.pageTitle}
              >
                {title}
              </Typography>
              {description && (
                <Typography
                  variant="caption"
                  className={clsx(classes.pageTitle, classes.pageSubTitle)}
                >
                  {description}
                </Typography>
              )}
              <Divider />
            </Grid>
          )}
          <Grid item className={classes.gridItem}>
            <MuiErrorBanner />
            {/* 
              leaving this announcement component for
              future announcements of new releases
              <MuiAnnouncementDialog /> 
              */}
            <ChildView
              ready={props.ready}
              dispatch={props.dispatch}
              pageHashParams={pageHashParams}
              {...props.requests}
              theme={props.theme}
            />
          </Grid>
        </Grid>
      </div>
    </Root>
  );
};

PageView.propTypes = {
  ChildView: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  payload: PropTypes.shape({}),
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

PageView.defaultProps = {
  ChildView: <div />,
  payload: {},
  error: {
    message: "",
  },
};

const mapStateToProps = (state) => {
  return {
    requests: requestMapper(state),
    pageHash: state.page.pageHash,
    ready: state.page.ready,
    payload: state.location.payload,
    license: state.license,
  };
};

export default connect(mapStateToProps)(PageView);
