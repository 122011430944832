import React from "react";
import PropTypes from "prop-types";

import ErrorBannerConnected from "./ErrorBanner";
import { captureError, remoteEnabled } from "../../lib/sentry";

import "./ErrorBoundary.sass";

const ERROR_MESSAGE =
  "An error occurred." + " Refreshing the page may resolve the issue.";

const ERROR_MESSAGE_NO_FEEDBACK =
  `${ERROR_MESSAGE}` +
  ' If you’d like to help, click "Help" and tell us what happened.';
const ERROR_MESSAGE_FEEDBACK =
  `${ERROR_MESSAGE}` +
  " If you’d like to help," +
  ' click "Report the error" and tell us what happened.';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorType: null,
      eventId: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return {
      hasError: true,
      errorType: error.name,
    };
  }

  componentDidCatch(error, errorInfo) {
    const eventId = captureError({
      err: error,
      errorInfo,
    });

    this.setState({ eventId });
  }

  render() {
    const showReportBtn = remoteEnabled();
    const message = showReportBtn
      ? ERROR_MESSAGE_FEEDBACK
      : ERROR_MESSAGE_NO_FEEDBACK;

    // If error - show the fallback UI
    if (this.state.hasError) {
      switch (this.state.errorType) {
        // Original Findings code - remove?
        case "MissingQuestionError":
          return <span />;

        default:
          return (
            <React.Fragment>
              <ErrorBannerConnected
                eventId={this.state.eventId}
                message={message}
                showDismissBtn={false}
                showReportBtn={showReportBtn}
              />
            </React.Fragment>
          );
      }
    }

    // If no error - show the children
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    PropTypes.shape({}).isRequired,
  ]).isRequired,
  user: PropTypes.shape({}),
};

ErrorBoundary.defaultProps = {
  user: {
    email: "",
    name: "",
  },
};

export default ErrorBoundary;
