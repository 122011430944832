import { API, setAxiosOrgHeader } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "tagging",
};

// Object types sent to the API when specific objects are tagged.
// Should be used whenever objType argument is required.
export const TAG_OBJECT_TYPES = {
  FINDING: "finding",
  LOCATION: "location",
  SENSOR: "sensor",
  SUBNET: "subnet",
};

export class TaggingAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  listLabels(orgId) {
    const params = ["label", "search", orgId];
    setAxiosOrgHeader(orgId);
    return super.get({ params });
  }

  updateLabel({ labelId, data }) {
    const params = ["label", labelId];
    return super.put({ params }, data);
  }

  createLabel(data) {
    const params = ["label"];
    return super.post({ params }, data);
  }

  // objType and objId are arrays of strings
  listTags({ objType, objId }) {
    const params = ["tag", "search"];
    const data = { objType, objId };
    return super.post({ params }, data);
  }

  createTag(data) {
    const params = ["tag"];
    return super.post({ params }, data);
  }

  deleteTag(tagId) {
    const params = ["tag", tagId];
    return super.delete({ params });
  }

  /*
  Helpers.
  Errors are caught in the actions/Tagging.
  */

  /*
  Creates a new label
  */
  createLabelWrapper = async (labelData) => {
    let resp = null;

    resp = await this.createLabel(labelData);

    const { data: label = {} } = resp;

    return label;
  };

  /*
  Fetches the labels for orgId and system labels
  */
  listLabelsWrapper = async (orgId) => {
    let resp = null;

    resp = await this.listLabels(orgId);

    const { data: labels = [] } = resp;

    return labels;
  };

  /*
  Updates a label
  */
  updateLabelWrapper = async ({ labelId, data }) => {
    let resp = null;

    resp = await this.updateLabel({ labelId, data });

    return resp;
  };

  /*
  Tags the object (i.e. creates a tag)
  */
  createTagWrapper = async (tagData) => {
    const { objId, objType, labelId, removalRestricted } = tagData;
    const reqData = {
      foreign_id: objId,
      foreign_type: objType,
      label_id: labelId,
      removal_restricted: removalRestricted,
    };

    let resp = null;

    resp = await this.createTag(reqData);

    const { data: tag = {} } = resp;

    return tag;
  };

  /*
  Untags the object (i.e. deletes a tag)
  */
  deleteTagWrapper = async (tagId) => {
    let resp = null;

    resp = await this.deleteTag(tagId);

    return resp;
  };

  /*
  Fetches the tags for the objects of types objType with IDs objId,
  where objType and objId are arrays for strings.
  */
  listTagsWrapper = async ({ objType, objId }) => {
    let resp = null;

    resp = await this.listTags({ objType, objId });

    const { data: tags = [] } = resp;

    return tags;
  };
}
