const transmissionSchema = {
  $id: "/transmission",
  title: "Transmission",
  type: "object",
  properties: {
    id: { type: "string", format: "uuid", readOnly: true },
    created: { type: "string", readOnly: true },
    orgId: { type: "string", format: "uuid" },
    modelId: { type: "string", format: "uuid" },
    modelType: { type: "string" },
    transmissionType: { type: "string" },
    kwargs: { type: "object" },
  },
  required: ["modelId", "modelType", "transmissionType"],
  additionalProperties: false,
};

export default transmissionSchema;
