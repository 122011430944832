import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  Switch,
  Tooltip,
  Button,
  TextField,
  CircularProgress,
  Snackbar,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";

import ActionDialog from "views/Components/ActionDialog";

import { SSOConnection as SSOConnectionModel } from "lib/models/SSOConnection";

import { isValidURL } from "utils";

import { Root, classes } from "./styles.js";
import { isDevMode } from "../../../lib/helpers";

const identityProviderSectionFields = [
  {
    label: "Domain",
    property: "domain",
    tooltip: "",
  },
  {
    label: "Signing Certificate",
    property: "signingCert",
    tooltip: "Please paste the contents of your cert file here.",
    multiline: true,
  },
  {
    label: "Sign-in Endpoint",
    property: "signInEndpoint",
    tooltip: "",
  },
  {
    label: "Sign-out Endpoint",
    property: "signOutEndpoint",
    tooltip: "",
  },
];

const SingleSignOnPageView = (props) => {
  const auth0OrgName = props.currentOrg.name
    .toLowerCase()
    .split(/[^a-zA-Z0-9]/)
    .join("");

  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [viewingModel, setViewingModel] = useState({});
  const [isSSOEnabled, setIsSSOEnabled] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const buildAndSetNewConnectionModel = () => {
    const newViewingConnectionModel = new SSOConnectionModel();
    setViewingModel(newViewingConnectionModel);
  };

  useEffect(() => {
    if (props.ssoconnection?.length) {
      setIsSSOEnabled(true);
      const decodedCert = Buffer.from(
        props.ssoconnection[0].signingCert,
        "base64"
      ).toString("ascii");
      setViewingModel(props.ssoconnection[0]);
      props.ssoconnection[0].set({ signingCert: decodedCert });
    } else {
      buildAndSetNewConnectionModel();
    }
  }, [props.ssoconnection]);

  const handleResetSnackBar = () => {
    setSnackBarMessage("");
    setIsSnackBarOpen(false);
  };

  const handleDisableSSOConfiguration = async () => {
    try {
      setIsSaving(true);

      // delete sso configuration
      await viewingModel.delete();

      // reset model in view to clear inputs
      buildAndSetNewConnectionModel();

      // toggle enablement button at top of page
      setIsSSOEnabled(!isSSOEnabled);

      // close dialog and enable buttons
      setIsConfirmationDialogOpen(false);
      setIsSaving(false);

      // show success to customer
      setSnackBarMessage("Success! Your SSO configuration was disabled.");
    } catch (e) {
      setIsSnackBarOpen(true);
      setIsSaving(false);
      setSnackBarMessage(
        "Oops, we had trouble disabled your SSO configuration."
      );
    }
  };

  const handleToggleSSO = () => {
    // if the organziation has an
    // active SSO config, delete it
    if (viewingModel.name) {
      setIsConfirmationDialogOpen(true);
    } else {
      setIsSSOEnabled(!isSSOEnabled);
    }
  };

  const handleValueChange = ({ value, property }) => {
    const updatedModel = new SSOConnectionModel({
      ...viewingModel,
      [property]: value,
    });

    setViewingModel(updatedModel);

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [property]: "",
    }));
  };

  const handleSave = async () => {
    const modelAction = viewingModel.name ? "update" : "create";

    setIsSaving(true);

    try {
      const encodedCert = Buffer.from(viewingModel.signingCert).toString(
        "base64"
      );
      viewingModel.set({ signingCert: encodedCert });
    } catch (e) {
      setIsSaving(false);
      setIsSnackBarOpen(true);
      setSnackBarMessage(
        "Oops, we had trouble encoding your Signing Certificate."
      );
      return;
    }

    try {
      const newSSOConnection = await viewingModel[modelAction]();
      setViewingModel(newSSOConnection);
      setIsSnackBarOpen(true);
      setSnackBarMessage("Success! Your SSO configuration has been saved.");
      setIsSaving(false);
    } catch (e) {
      setIsSnackBarOpen(true);
      setSnackBarMessage(
        "Oops, we had trouble updating your SSO configuration."
      );
      setIsSaving(false);
    }
  };

  const handleBlur = (event, property) => {
    const { value } = event.target;
    const urlFields = ["signInEndpoint", "signOutEndpoint"];

    const isValueValidUrl = isValidURL(value);

    if (urlFields.includes(property)) {
      if (!isValueValidUrl) {
        setFormErrors((prevFormErrors) => ({
          ...prevFormErrors,
          [property]: `Please enter a valid ${property}.`,
        }));
      }
    }
  };

  const linkUrlEnv = isDevMode() ? "auth.d.b5a.io" : "auth.blumira.com";

  return (
    <Root>
      {props.ready ? (
        <>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={isSnackBarOpen}
            autoHideDuration={6000}
            message={snackBarMessage}
            onClose={handleResetSnackBar}
          />
          <div className={classes.switchButtonContainer}>
            <Switch
              color={"primary"}
              onChange={handleToggleSSO}
              checked={isSSOEnabled ? true : false}
            />
            <p className={classes.switchButtonLabel}>
              {isSSOEnabled ? "Enabled" : "Disabled"}
            </p>
          </div>
          <p className={classes.pageDescription}>
            Blumira supports SAML SSO, which allows users to access multiple
            applications with one set of login credentials authenticated through
            a central identity provider.
          </p>
          <div className={classes.sectionContainer}>
            <p className={classes.sectionTitle}>Identity Provider</p>
            {identityProviderSectionFields.map((field) => (
              <div key={field.property} className={classes.textFieldContainer}>
                <p className={classes.textFieldLabel}>{field.label}</p>
                {field.tooltip ? (
                  <Tooltip
                    disableInteractive
                    placement={"bottom-start"}
                    title={field.tooltip}
                    overlap={"rectangular"}
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <InfoOutlinedIcon className={classes.iconButton} />
                  </Tooltip>
                ) : (
                  <span />
                )}
                <TextField
                  disabled={!isSSOEnabled}
                  className={classes.textField}
                  multiline={field.multiline}
                  maxRows={field.multiline ? 8 : 1}
                  value={viewingModel[field.property]}
                  onChange={(e) =>
                    handleValueChange({
                      value: e.target.value,
                      property: field.property,
                    })
                  }
                  onBlur={(e) => handleBlur(e, field.property)}
                  error={!!formErrors[field.property]}
                  helperText={formErrors[field.property]}
                />
              </div>
            ))}
            <div className={classes.textFieldContainer}>
              <span />
              <span />
              <Button
                className={classes.submitButton}
                disabled={!isSSOEnabled}
                variant={"contained"}
                color={"primary"}
                onClick={handleSave}
              >
                {isSaving ? "Saving" : "Save"}
              </Button>
            </div>
            <p className={classes.sectionTitle}>Blumira</p>
            <div className={classes.textFieldContainer}>
              <p className={classes.textFieldLabel}>Blumira ACS URL</p>
              <span />
              <TextField
                disabled={!isSSOEnabled}
                className={classes.textField}
                value={
                  isSSOEnabled
                    ? `https://${linkUrlEnv}/login/callback?connection=${auth0OrgName}-sso`
                    : ""
                }
              />
            </div>
            <div className={classes.textFieldContainer}>
              <p className={classes.textFieldLabel}>Blumira Logout URL</p>
              <span />
              <TextField
                disabled={!isSSOEnabled}
                className={classes.textField}
                value={isSSOEnabled ? `https://${linkUrlEnv}/logout` : ""}
              />
            </div>
            <div className={classes.textFieldContainer}>
              <p className={classes.textFieldLabel}>Blumira Entity ID</p>
              <span />
              <TextField
                disabled={!isSSOEnabled}
                className={classes.textField}
                value={
                  isSSOEnabled ? `urn:auth0:blumira:${auth0OrgName}-sso` : ""
                }
              />
            </div>
            <div className={classes.textFieldContainer}>
              <p className={classes.textFieldLabel}>Download</p>
              <span />
              <Button
                target={"_blank"}
                variant={"contained"}
                rel={"noopener noreferrer"}
                disabled={!viewingModel.name}
                className={classes.button}
                href={`https://${linkUrlEnv}/samlp/metadata?connection=${viewingModel.name}`}
              >
                <DownloadIcon />
                Metadata XML
                <span />
              </Button>
            </div>
            <div className={classes.textFieldContainer}>
              <span />
              <span />
              <Button
                target={"_blank"}
                variant={"contained"}
                rel={"noopener noreferrer"}
                disabled={!viewingModel.name}
                className={classes.button}
                href={`https://${linkUrlEnv}/cer?cert=${viewingModel.name}`}
              >
                <DownloadIcon />
                Signing Certificate
                <span />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <CircularProgress color={"primary"} />
      )}
      <ActionDialog
        title={`Are you sure?`}
        open={isConfirmationDialogOpen}
        description={
          "Your single sign-on configuration will be disabled and removed."
        }
        actions={[
          {
            title: isSaving ? "Close" : "Cancel",
            action: () => setIsConfirmationDialogOpen(false),
          },
          {
            title: isSaving ? "Disabling..." : "Disable Single Sign-On",
            action: handleDisableSSOConfiguration,
            variant: "contained",
            diasbled: isSaving,
          },
        ]}
      />
    </Root>
  );
};

const mapStateToProps = (state) => {
  const orgId = state.location.payload.orgId;
  const currentOrg = state.session.settings.userOrgs.find(
    ({ id }) => id === orgId
  );

  return { orgId, currentOrg };
};

export default connect(mapStateToProps, null)(SingleSignOnPageView);
