import { API } from "./API";

const DEFAULT_OPTIONS = {
  baseUrl: "org",
};

export class OrgAPI extends API {
  constructor(options = {}) {
    super({ ...DEFAULT_OPTIONS, ...options });
  }

  getOrg(orgId) {
    const params = [orgId];

    return super.get({ params });
  }

  drainJob({ orgId, pipelineId }) {
    const params = ["drain-job", orgId];

    const data = {
      pipelineId,
    };

    return super.post({ params }, data);
  }

  restartPrimaryPipeline({ orgId, workerCount, workerMax, workerType }) {
    const params = ["restart-primary-pipeline", orgId];

    const data = {
      workerCount,
      workerMax,
      workerType,
    };

    return super.post({ params }, data);
  }

  startPrimaryPipeline({ orgId, workerCount, workerMax, workerType }) {
    const params = ["start-primary-pipeline", orgId];

    const data = {
      workerCount,
      workerMax,
      workerType,
    };

    return super.post({ params }, data);
  }

  updateSchema(orgId) {
    const params = ["update-schema", orgId];

    return super.post({ params });
  }
}
