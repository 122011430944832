import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import "./Installation.scss";
import { currentSensorSelector } from "../../../selectors/sensorsSelectors";
import { Button } from "react-bootstrap";
import { copyTextToClipboard } from "utils";

const Installation = (props) => {
  const { sensor } = props;
  const configInstall = sensor.configInstall || {};
  const script = configInstall.script || {};
  const signedUrl = script.signed_url;
  const scriptText = "curl -sS '" + signedUrl + "' | sudo bash";
  const expires = script.expires || null;
  const expiresMoment = moment.utc(expires);

  // Still loading the sensor
  if (Object.keys(sensor).length === 0) {
    return null;
  }

  // Sensor script has expired
  if (
    Object.keys(configInstall).length === 0 ||
    !expiresMoment.isValid() ||
    expiresMoment.isBefore()
  ) {
    return (
      <p>
        To generate a new script, click the "Rebuild Sensor" button at the top
        of the page.
      </p>
    );
  }

  // Sensor script is available
  return (
    <div className="sensor-installation-container">
      <p>Paste the following script into a Linux terminal:</p>
      <p>
        <code className="installation-script">{scriptText}</code>
      </p>

      <p>
        <Button
          bsStyle="primary"
          onClick={() => copyTextToClipboard(scriptText)}
        >
          Copy Installation Script
        </Button>
      </p>
    </div>
  );
};

Installation.propTypes = {
  script: PropTypes.shape({}),
};

Installation.defaultProps = {
  script: {},
};

const mapStateToProps = (state) => {
  const sensor = currentSensorSelector(state);

  return {
    sensor,
  };
};

export default connect(mapStateToProps, null)(Installation);
