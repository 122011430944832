import _ from "lodash";

import * as Actions from "./actions";
import { DATA_QUERIES } from "../lib/util/DataQueryConstants";
import { DataQueryUtil } from "../lib/util/DataQueryUtil";

const initialState = {
  dataQueries: DATA_QUERIES,
};

const DataQueryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Actions.DataQuery.DATA_QUERY_PROCESS_START: {
      const newState = _.cloneDeep(state);

      const { dataQueryKey } = action;

      newState.dataQueries[dataQueryKey].updating = false;
      newState.dataQueries[dataQueryKey].loading = true;
      newState.dataQueries[dataQueryKey].error = null;

      return newState;
    }

    case Actions.DataQuery.DATA_QUERY_PROCESS_SUCCESS: {
      const newState = _.cloneDeep(state);

      const { data, dataQueryKey } = action;

      let dataIsEmpty = true;

      let visualizationData;

      data.forEach((d) => {
        if (!_.isEmpty(d.data)) {
          dataIsEmpty = false;
        }
      });

      if (dataIsEmpty) {
        visualizationData =
          DATA_QUERIES[dataQueryKey].emptyStateVisualizationData;
      } else {
        visualizationData = DataQueryUtil.rawDataToVisualizationDataTransform(
          data,
          dataQueryKey
        );
      }

      newState.dataQueries[dataQueryKey].data = data;
      newState.dataQueries[dataQueryKey].visualizationData = visualizationData;

      newState.dataQueries[dataQueryKey].dataIsEmpty = dataIsEmpty;
      newState.dataQueries[dataQueryKey].firstLoadComplete = true;
      newState.dataQueries[dataQueryKey].loading = false;
      newState.dataQueries[dataQueryKey].error = null;

      return newState;
    }

    case Actions.DataQuery.DATA_QUERY_PROCESS_FAILURE: {
      const newState = _.cloneDeep(state);

      const { dataQueryKey, error } = action;

      const visualizationData =
        DATA_QUERIES[dataQueryKey].emptyStateVisualizationData;

      newState.dataQueries[dataQueryKey].visualizationData = visualizationData;
      newState.dataQueries[dataQueryKey].firstLoadComplete = true;
      newState.dataQueries[dataQueryKey].loading = false;
      newState.dataQueries[dataQueryKey].error = error;

      return newState;
    }

    case Actions.DataQuery.DATA_QUERY_RESET: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default DataQueryReducer;
