// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import { string } from "prop-types";

// ** Style ***************************
import { Container, LoadingAnimation, Path, Wrapper } from "./style";

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Loading spinner component
//
//  ** Props
//  @param height {string} - outer wrapper height
//  @param size {string} - height/width of spinner
//
const LoadingSpinner = ({ height, size }) => (
  <Wrapper height={height}>
    <Container size={size}>
      <LoadingAnimation viewBox="0 0 50 50">
        <Path cx="25" cy="25" r="20" fill="none" />
      </LoadingAnimation>
    </Container>
  </Wrapper>
);

// ** Proptypes ***********************
LoadingSpinner.propTypes = {
  height: string,
  size: string,
};

// ** Default props *******************
LoadingSpinner.defaultProps = {
  height: "100px",
  size: "48px",
};

export default LoadingSpinner;
