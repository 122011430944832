// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Error container *****************
export const Container = styled.div`
  background-color: ${COLOR.lightRed};
  border: 1px solid ${COLOR.blumiraRed};
  border-radius: 3px;
  color: ${COLOR.blumiraRed};
  font-size: 12px;
  font-weight: 400;
  margin: ${(p) => p.margin};
  padding: 8px;
  width: 100%;
`;

// ** Error message row ***************
export const Row = styled.div`
  margin-bottom: 8px;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
`;
