// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Child container *****************
export const ChildContainer = styled.div`
  background-color: ${COLOR.white};
  border: ${(p) => (p.focused ? `1px solid ${COLOR.gray100}` : "none")};
  border-radius: 0.25rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  height: ${(p) => (p.focused ? "auto" : "0px")};
  margin-top: 8px;
  min-width: ${(p) => p.minWidth};
  max-width: ${(p) => p.maxWidth};
  overflow: ${(p) => (p.focused ? "visible" : "hidden")};
  position: absolute;
  right: ${(p) => p.right};
  top: 100%;
  z-index: 1000;
`;

// ** Outer container *****************
export const Container = styled.div`
  position: relative;
`;

// ** Flex container ******************
export const FlexRow = styled.div`
  align-items: center;
  cursor: ${(p) => (p.disabled ? "not-allowed" : "pointer")};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
