import { normalizeError } from "../../lib/helpers";
import { SettingsAPI } from "../../lib/api/SettingsAPI";
import { getOutageStatus } from "../../lib/api/OutageAPI";
import Logger from "../../lib/logger";

const logger = Logger("Settings");

export const ADD_ERROR = "SETTINGS.ADD_ERROR";
export const CLEAR_ERROR = "SETTINGS.CLEAR_ERROR";
export const RECEIVE_OUTAGE_STATUS = "SETTINGS.RECEIVE_OUTAGE_STATUS";
export const RECEIVE_USER = "SETTINGS.RECEIVE_USER";
export const RECEIVE_ZENDESK_STATUS = "SETTINGS.RECEIVE_ZENDESK_STATUS";
export const RESET_SETTINGS = "SETTINGS.RESET";

export const RECEIVE_SETTINGS = "SESSION.RECEIVE_SETTINGS";

const API = {
  settings: new SettingsAPI(),
};

export const receiveSettings = (settings) => ({
  type: RECEIVE_SETTINGS,
  settings,
});

export const fetchSettings = () => (dispatch) =>
  API.settings
    .get()
    .then(({ data }) =>
      // Persist settings to Redux
      dispatch(receiveSettings(data))
    )
    .catch((err) => {
      logger.error("Failed to fetch settings.", err);
      throw err;
    });

export const addError = (error) => ({
  type: ADD_ERROR,
  error: normalizeError(error),
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

// The error is caught in the Zendesk component
export const fetchZendeskJwt = () =>
  API.settings.getZendeskJWT().then(({ data }) => data);

export const receiveZendeskStatus = (zendeskLoaded) => ({
  type: RECEIVE_ZENDESK_STATUS,
  zendeskLoaded,
});

const receiveOutageStatus = (outageStatus) => ({
  type: RECEIVE_OUTAGE_STATUS,
  payload: outageStatus,
});

export const fetchOutageStatus = () => (dispatch) => {
  getOutageStatus()
    .then((response) => {
      const {
        enabled = false,
        info_url = "",
        info_url_text = "",
        message = "",
      } = response;

      dispatch(
        receiveOutageStatus({
          enabled,
          info_url,
          info_url_text,
          message,
        })
      );
    })
    .catch((err) => {
      logger.error("Failed to fetch the service status message.", err);
    });
};

export const resetSettings = {
  type: RESET_SETTINGS,
};
