import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import { Tooltip } from "reactstrap";
import md5 from "md5";

import { isInside } from "../../utils";

const GRAVATAR_URL_PREFIX = "https://www.gravatar.com/avatar/";
const GRAVATAR_URL_PARAMS = "?d=404";

const getInitials = ({ firstname, lastname }) => {
  const first = firstname ? (firstname || "")[0] : "";
  const last = lastname ? (lastname || "")[0] : "";
  return (first + last).toUpperCase();
};

const urlFromEmail = (email) => {
  if (!email) {
    return "";
  }

  const hash = md5(email.trim().toLowerCase());
  const url = GRAVATAR_URL_PREFIX + hash + GRAVATAR_URL_PARAMS;

  return url;
};

const GRAVATAR_TOOLTIP_TEXT_1 = " We display your gravatar";
const GRAVATAR_TOOLTIP_TEXT_2 = " associated with the email address ";
const GRAVATAR_TOOLTIP_TEXT_3 =
  " To add or change this image, please go to the ";
const GRAVATAR_TOOLTIP_URL = "https://en.gravatar.com/";

const getTooltipContent = (email, ref, handleMouseEnter, handleMouseLeave) => (
  <div
    ref={ref}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <span>{GRAVATAR_TOOLTIP_TEXT_1}</span>

    <span>{GRAVATAR_TOOLTIP_TEXT_2}</span>

    <span>{email}</span>
    <span>{GRAVATAR_TOOLTIP_TEXT_3}</span>

    <strong>
      <a
        className="gravatar-link"
        href={GRAVATAR_TOOLTIP_URL}
        rel="noopener noreferrer"
        target="_blank"
      >
        Gravatar
      </a>
    </strong>

    <span> website.</span>
  </div>
);

class Avatar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      tooltipOpen: false,
    };

    this.tooltipRef = React.createRef();

    this.targetId = `blu-avatar-${String(Math.random()).slice(2)}`;
    this.handleClick = this.handleClick.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick, false);
    window.addEventListener("keyup", this.handleKeyUp, true);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick, false);
    window.removeEventListener("keyup", this.handleKeyUp, true);
  }

  handleError() {
    this.setState({ error: true });
  }

  toggleTooltip() {
    if (this.state.tooltipOpen) {
      return;
    }

    this.setState({ tooltipOpen: true });

    this.timeoutID = setTimeout(() => {
      this.setState({ tooltipOpen: false });
    }, 3500);
  }

  handleKeyUp(evt) {
    if (evt.code === "Escape" && this.state.tooltipOpen) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.setState({ tooltipOpen: false });
    }
  }

  handleClick(evt) {
    const inside = isInside(evt, this.tooltipRef.current);

    if (!inside && this.state.tooltipOpen) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.setState({ tooltipOpen: false });
    }
  }

  handleMouseEnter() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
    this.setState({ tooltipOpen: true });
  }

  handleMouseLeave() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
    this.setState({ tooltipOpen: false });
  }

  render() {
    const {
      email = "",
      firstname = "",
      lastname = "",
      showTooltip = false,
    } = this.props;

    const gravatarUrl = urlFromEmail(email);
    const initials = getInitials({ firstname, lastname });
    const tooltipContent = getTooltipContent(
      email,
      this.tooltipRef,
      this.handleMouseEnter,
      this.handleMouseLeave
    );

    const { error, tooltipOpen } = this.state;

    return (
      <React.Fragment>
        {showTooltip && (
          <Tooltip
            className=""
            innerClassName="blumira-help-tooltip"
            hideArrow
            placement="bottom"
            isOpen={tooltipOpen}
            target={this.targetId}
            toggle={this.toggleTooltip}
          >
            {tooltipContent}
          </Tooltip>
        )}

        <div className="avatar" id={this.targetId}>
          {error && <div className="avatar-content">{initials}</div>}

          {!error && (
            <img
              className="avatar-content"
              alt={initials}
              src={gravatarUrl}
              onError={this.handleError}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

Avatar.propTypes = {
  email: PropTypes.string,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  showTooltip: PropTypes.bool,
};

Avatar.defaultProps = {
  email: "",
  firstname: "",
  lastname: "",
  showTooltip: false,
};

export default Avatar;
