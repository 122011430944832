import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

import _ from "lodash";
import { capitalizeWords } from "../../../lib/helpers";

class RoleField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  removeRole(role) {
    this.props.handleChange(null, role, true);
  }

  render() {
    const {
      name,
      label,
      required,
      placeholder,
      values = {},
      disabled = false,
      multiple = false,
      errors = {},
      touched = {},
      handleBlur,
      handleChange,
      classes = "",
      validation = {},
      formatting = {},
      hidden = false,
      roleConstants,
      newUser = false,
    } = this.props;
    const uniqueId = _.uniqueId();

    // Remove 'monitor' role from roleConstants for all existing users,
    // since non-monitor users cannot change their role to 'monitor',
    // and monitor users are not displayed the RolesField at all.
    const adjustedRoleConstants = newUser
      ? roleConstants
      : roleConstants.filter((role) => role.id !== 50);

    // Options for the roles select
    let selectOptions = [];
    if (!values.roles.some((elt) => elt.roleId === 50)) {
      selectOptions = [{ id: -1, name: "Add Role" }]
        .concat(adjustedRoleConstants)
        .map(
          (o, i) =>
            !values.roles.find((elt) => elt.roleId === o.id) &&
            (o.id !== 50 || values.roles.length === 0) && (
              <option className="role" key={i} value={o.id}>
                {capitalizeWords(o.name)}
              </option>
            )
        )
        .filter((elt) => elt);
    }

    return (
      <div className={hidden ? "hidden" : ""}>
        <div
          className={`field ${errors[name] && touched[name] ? "error" : ""}`}
        >
          <label
            htmlFor={`${name}__${uniqueId}`}
            className="control-label"
            title={errors[name]}
          >
            {label}
            {required && <span className="text-danger">*</span>}
          </label>
          <div className={classes || "value"}>
            {(values[name] || []).map((r, k) => (
              <Row key={k} className="itemContent">
                <Col xs="7" className={classes || "value"}>
                  <div className="role">
                    {r.roleId
                      ? r.roleId === 30
                        ? capitalizeWords(r.roleName)
                        : capitalizeWords(
                            adjustedRoleConstants.find((x) => x.id === r.roleId)
                              .name
                          )
                      : ""}
                  </div>
                </Col>
                <Col xs="1">
                  <i
                    role="button"
                    title="Remove"
                    tabIndex={-1}
                    className="fas fa-times"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      this.removeRole(r);
                    }}
                  />
                </Col>
              </Row>
            ))}
          </div>
          {selectOptions.length > 1 && (
            <select
              id={`${name}__${uniqueId}`}
              name={name}
              className={classes || "control-item role"}
              value={values[`${name}_available_roles`]}
              onChange={handleChange}
              onBlur={handleBlur}
              ref={(c) => {
                this.field = c;
              }}
              validation={{ required, ...validation }}
              formatting={{ ...formatting }}
              disabled={disabled}
              multiple={multiple}
              placeholder={placeholder}
            >
              {selectOptions}
            </select>
          )}
          {errors[name] && touched[name] ? (
            <span className="error-message">Required</span>
          ) : null}
        </div>
      </div>
    );
  }
}

RoleField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  values: PropTypes.shape({}),
  disabled: PropTypes.bool,
  errors: PropTypes.shape({}),
  touched: PropTypes.shape({}),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  classes: PropTypes.string,
  validation: PropTypes.shape({}),
  formatting: PropTypes.shape({}),
  hidden: PropTypes.bool,
  roleConstants: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  newUser: PropTypes.bool,
};

RoleField.defaultProps = {
  label: "",
  required: false,
  placeholder: "",
  values: {},
  disabled: false,
  multiple: false,
  errors: {},
  touched: {},
  classes: "",
  validation: {},
  formatting: {},
  hidden: false,
  newUser: false,
};

export default RoleField;
