import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Divider, Tooltip, MenuItem, TextField } from "@mui/material";
import { defaultKeyValue } from "../../Pages/BlumiraAgent/InstallationPageView";

const PREFIX = "InstallationKeySelector";
const classes = {
  tooltip: `${PREFIX}-tooltip`,
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${classes.tooltip}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const InstallationKeySelector = (props) => {
  const [helperText, setHelperText] = useState("");

  useEffect(() => {
    if (props.helperText) setHelperText(props.helperText);
    else setHelperText("");
  }, [props.helperText]);

  const handleChangeInstallationKey = (event) => {
    // key ID is the group model ID
    const selectedKeyId = event.target.value;
    props.changeInstallationKey(selectedKeyId);
  };

  const handleOpenKeyDialog = () => {
    props.openKeyDialog();
  };

  return (
    <StyledTextField
      select
      helperText={helperText}
      value={props.selectedGroupId}
      labelid={"selectInstallationKey"}
      style={{ width: props.width || 250 }}
      datacy={"installationKeySelectField"}
      onChange={handleChangeInstallationKey}
    >
      <MenuItem value={defaultKeyValue}>Select installation key</MenuItem>
      {props.allowCreate && (
        <MenuItem onClick={handleOpenKeyDialog}>
          Create new installation key
        </MenuItem>
      )}
      {props.keys?.length && <Divider />}
      {props.keys.map((groupObj, idx) =>
        groupObj.agentCount === groupObj.agentSeatCount &&
        !props.overrideDisabling ? (
          <Tooltip
            key={idx}
            title={"This group has reached its agent seat count limit."}
            placement={"right"}
            className={classes.tooltip}
          >
            <div>
              <MenuItem
                key={groupObj.id}
                value={groupObj.id}
                disabled
                datacy={`groupInstallationKey-${idx}`}
              >
                {groupObj.description}
              </MenuItem>
            </div>
          </Tooltip>
        ) : (
          <MenuItem
            key={groupObj.id}
            value={groupObj.id}
            datacy={`groupInstallationKey-${idx}`}
          >
            {groupObj.description}
          </MenuItem>
        )
      )}
    </StyledTextField>
  );
};
