const FINDING_TYPE_SUSPECT = 20;
const FINDING_TYPE_THREAT = 30;

const replacePre = (html) => {
  return html.replaceAll("<pre>", "<code>").replaceAll("</pre>", "</code>");
};

const stripHTML = (html) => {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

export function isRuleEnabled(rule) {
  return rule.status === 1 || rule.status === 101; //1=enabled, 101=pending_enabled
}

export function getAnalysis(column, model, getFullSummary) {
  const maxChars = 256;
  if (getFullSummary) {
    return replacePre(model.analysis);
  } else {
    const stripped = stripHTML(model.analysis);
    return stripped.length > maxChars
      ? stripped.substring(0, maxChars).trim() + "..."
      : stripped;
  }
}

export function getStatusInfo(isDisabled) {
  return isDisabled
    ? "Blumira's incident detection team has determined this rule generates noisy findings that are not likely to present threats, so it is disabled by default."
    : "Blumira's incident detection team has determined this rule generates findings that are likely to present a threat, so it is enabled by default.";
}

export function getPriorityIconColor(model) {
  const priority = model?.priority;

  switch (priority) {
    case 1:
      return "red";
    case 2:
      return "#FDE03A";
    case 3:
      return "gray";
    default:
      return "gray";
  }
}

export function getRuleDisplayInfo({
  rule,
  conditions,
  allowlistEntries,
  actions,
  findingTypes,
  typeConstants,
}) {
  const thisCondition = conditions?.filter(
    (c) => c.id === rule.conditionSet[0].condition
  )[0];
  const thisAction = actions?.filter(
    (a) => a.id === thisCondition?.targetAction
  )[0];
  const thisCategory = findingTypes?.filter(
    (t) => t.id === thisAction?.findingType
  )[0];
  const dataTypes = thisCondition?.tags?.filter(
    (t) => t.substring(0, 5) === "type:"
  );
  // These are the specific desired targetAction #s coming from the Condition
  const ahiFindingTypes = [FINDING_TYPE_SUSPECT, FINDING_TYPE_THREAT];
  return {
    fullAnalysisSummary: thisCondition?.analysis,
    priority: thisAction?.priority,
    category: thisCategory?.name,
    dataType:
      // If a dataType has only one type tag, then display that type.
      // Otherwise, if it has more than one type tag or no type tags, then show Multi-Source.
      dataTypes?.length && dataTypes?.length === 1
        ? typeConstants[dataTypes[0].replace("type:", "")]?.display_name
        : "Multi-Source",
    defaultState: thisCondition?.tags?.some(
      (t) => t === "status:default_disabled"
    )
      ? "Disabled"
      : "Enabled",
    detectionType: thisCondition?.method === 500 ? "Real-Time" : "Windowed",
    allowlist: allowlistEntries?.filter((f) => f.ruleId === rule.id),
    isAHISupported:
      (dataTypes?.includes("type:windows") ||
        dataTypes?.includes("type:agent_os") ||
        dataTypes?.includes("type:agent_os_mac") ||
        dataTypes?.includes("type:agent_os_linux")) &&
      thisCondition?.method === 500 &&
      !thisCondition?.tags?.includes("AHI:disabled") &&
      ahiFindingTypes.includes(thisAction?.findingType)
        ? true
        : false,
  };
}
