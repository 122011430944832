import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PageView2 from "./PageView2";
import { childrenPageViews } from "./pages";
import {
  resetOrgData,
  logoutFromBlumira,
  navigateToDefault,
} from "../redux/actions/Page";

import { CircularProgress } from "@mui/material";
import logo from "../images/blumira-logo-blue.svg";

import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { themeSelector } from "../design/theme";
import CssBaseline from "@mui/material/CssBaseline";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

const NOOP = () => <div />;

const mapStateToProps = (state) => {
  const pageConfig = childrenPageViews.find(
    (pg) =>
      pg.route ===
      `${state.location.payload.toplevel}/${state.location.payload.secondlevel}`
  );
  const ChildView = pageConfig ? pageConfig.component : NOOP;

  const locationTopLevel = state.location.payload.toplevel;

  return {
    ChildView,
    locationTopLevel,
    loading: state.page.loading, // do not drill
    theme: state.userInterface.theme,
  };
};

const mapDispatchToProps = (dispatch, getState) => ({
  handleNavigateToDefault: () => {
    dispatch(navigateToDefault);
  },
  routeToOrg: (props) => {
    dispatch(resetOrgData);
    dispatch({ type: props.type, payload: props.payload, query: props.query });
  },
  routeToPage: (props) => {
    dispatch({ type: "PAGE", ...props });
  },
  routeToLogin: () => {
    dispatch(logoutFromBlumira);
  },
  dispatch,
  applyFilter: (filterKey, filterObj) => {
    dispatch({ type: "PAGE_FILTER", filterKey, filterObj });
  },
});

const PageViewWrapper = (props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const darkTheme = "shadow";
  const lightTheme = "default";

  const [theme, setTheme] = useState(themeSelector(props.theme || "default"));

  // if the user changes their theme
  // if theme selection is auto, handle it
  // else set the theme to the selection
  useEffect(() => {
    let isThemeAuto = props.theme === "auto";

    let theme =
      isThemeAuto && prefersDarkMode
        ? darkTheme
        : isThemeAuto && !prefersDarkMode
        ? lightTheme
        : props.theme
        ? props.theme
        : "default";

    setTheme(themeSelector(theme));
  }, [props.theme, prefersDarkMode]);

  if (props.loading) {
    return (
      <div className="h-100 w-100 d-flex align-items-center loading">
        <div className="row mx-auto align-items-center">
          <div className="col align-items-center mx-auto">
            <img className="mx-auto" src={logo} alt="Logo" title="Blumira" />
            <CircularProgress size={35} />
            <h2>Loading...</h2>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <PageView2
              ChildView={props.ChildView}
              dispatch={props.dispatch}
              theme={props.theme}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PageViewWrapper);
