import React from "react";
import PropTypes from "prop-types";

import Select from "react-select";
import _ from "lodash";

import { MetaComponent } from "./MetaComponent";
import { compareSemver, getModuleTexts } from "./moduleHelpers";
import ModuleNotes from "./ModuleNotes";

import "./NewModule.scss";

/*
Helpers
*/

const optionsForAvailableModules = ({
  availableModules = [],
  superadmin,
  license,
}) => {
  // We need to create a grouping of modules together that have the same name.
  // We then differentiate by tag and sort.
  const moduleSelectOption = (module) => {
    const { moduleTitle } = getModuleTexts(module);

    return {
      module,
      label: moduleTitle,
      value: module.id,
    };
  };

  const groupedByType = _.groupBy(availableModules, "type.id");
  const allModuleTypes = _.keys(groupedByType);

  let groupedOptions = _.map(allModuleTypes, (moduleTypeId) => {
    const modules = groupedByType[moduleTypeId];

    const modulesSortedBySemver = modules.sort(compareSemver);

    const typeInfo = _.isNil(modules) ? {} : _.first(modules).type;

    const mostRecent = _.last(modulesSortedBySemver);

    let versionOptions;
    if (superadmin) {
      // Show all versions for superadmins
      versionOptions = _.map(modulesSortedBySemver, (m) =>
        moduleSelectOption(m, mostRecent)
      );
    } else {
      // If not a superadmin, show only the latest version
      versionOptions = [moduleSelectOption(mostRecent)];
    }

    return {
      label: typeInfo.detail,
      group: typeInfo,
      options: versionOptions,
      optionCount: modules.length,
    };
  });

  if (
    superadmin ||
    license.hasFeature("HONEYPOT") ||
    license.isTagged("limited-to-xdr") ||
    license.isTagged("limited-to-siem-endpoint") ||
    license.isTagged("limited-to-advanced")
  ) {
    groupedOptions = _.sortBy(groupedOptions, [(mt) => mt.label.toLowerCase()]);
  } else {
    groupedOptions = _.sortBy(groupedOptions, [
      (mt) => mt.label.toLowerCase(),
    ]).filter((mt) => mt.group.id !== 20);
  }

  return groupedOptions;
};

/*
Main component
*/
const NewModule = ({
  availableModules = [],
  metaValues,
  setMetaValues,
  moduleValue,
  handleChangeModule,
  setFormErrorMessage,
  superadmin,
  license,
}) => {
  const { module: moduleSpec = {} } = moduleValue || {};
  const {
    meta = [],
    documentation_url: moduleUrl = "",
    notes: moduleNotes = "",
  } = moduleSpec;

  const showMeta = meta.length > 0;

  return (
    <div
      className="sensor-module-new sensor-modal-content"
      datacy={"addSensorModuleModal"}
    >
      <div className="sensor-subtitle">Choose Your New Sensor Module</div>

      <div className="sensor-form-item">
        <div className="sensor-label">Module</div>
        <Select
          options={optionsForAvailableModules({
            availableModules,
            superadmin,
            license,
          })}
          value={moduleValue}
          onChange={handleChangeModule}
          placeholder="Choose a Module"
          classNamePrefix={"sensorModuleSelect"}
        />

        <ModuleNotes moduleNotes={moduleNotes} moduleUrl={moduleUrl} />
      </div>

      {showMeta && (
        <div>
          <div className="sensor-module-meta-title">Module Configuration</div>

          <MetaComponent
            metaValues={metaValues}
            setMetaValues={setMetaValues}
            setFormErrorMessage={setFormErrorMessage}
          />
        </div>
      )}
    </div>
  );
};

// PropTypes
NewModule.propTypes = {
  availableModules: PropTypes.arrayOf(PropTypes.shape({})),
  metaValues: PropTypes.shape({}),
  setMetaValues: PropTypes.func.isRequired,
  moduleValue: PropTypes.shape({}),
  handleChangeModule: PropTypes.func.isRequired,
  setFormErrorMessage: PropTypes.func,
  superadmin: PropTypes.bool,
};

NewModule.defaultProps = {
  availableModules: [],
  metaValues: {},
  moduleValue: {},
  setFormErrorMessage: () => {},
  superadmin: false,
};

export default NewModule;
