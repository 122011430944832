import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { loadPageData } from "redux/actions/Request";

import {
  Tab,
  Box,
  Tabs,
  Chip,
  List,
  Fade,
  Dialog,
  Button,
  ListItem,
  TextField,
  Typography,
  DialogTitle,
  ListItemText,
  DialogContent,
  CircularProgress,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import PublicIcon from "@mui/icons-material/Public";
import DeleteIcon from "@mui/icons-material/Delete";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import StarIcon from "@mui/icons-material/Star";

import { isGlobal, isMicrosoft, getSecondaryDateText } from "./helpers";

import SearchField from "./SearchField";

import Request from "lib/api/Request";

const PREFIX = "SavedQueryModal";
const classes = {
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  card: `${PREFIX}-card`,
  dFlex: `${PREFIX}-dFlex`,
  formGrid: `${PREFIX}-formGrid`,
  backdrop: `${PREFIX}-backdrop`,
  filterPaper: `${PREFIX}-filterPaper`,
  searchFieldChip: `${PREFIX}-searchFieldChip`,
  expandButton: `${PREFIX}-expandButton`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`,
  cardAction: `${PREFIX}-cardAction`,
  savedQueries: `${PREFIX}-savedQueries`,
  savedQueriesSearch: `${PREFIX}-savedQueriesSearch`,
  savedQueriesList: `${PREFIX}-savedQueriesList`,
  toolBar: `${PREFIX}-toolBar`,
  titleBar: `${PREFIX}-titleBar`,
  dataSource: `${PREFIX}-dataSource`,
  modelNameContainer: `${PREFIX}-modelNameContainer`,
  iconButtons: `${PREFIX}-iconButtons`,
  closeIcon: `${PREFIX}-closeIcon`,
  checkIcon: `${PREFIX}-checkIcon`,
  confirmContainer: `${PREFIX}-confirmContainer`,
  confirmDelete: `${PREFIX}-confirmDelete`,
  savedReportBtnContainer: `${PREFIX}-savedReportBtnContainer`,
  listItem: `${PREFIX}-listItem`,
  globalIcon: `${PREFIX}-globalIcon`,
  tabLabelContainer: `${PREFIX}-tabLabelContainer`,
  tabLabel: `${PREFIX}-tabLabel`,
  tabLabelIcon: `${PREFIX}-tabLabelIcon`,
  isFavoritingLoadingIndicator: `${PREFIX}-isFavoritingLoadingIndicator`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    height: 80,
    padding: 0,
    marginRight: 5,
  },
  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.card}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.dFlex}`]: {
    display: "flex",
    alignItems: "flex-start",
  },
  [`& .${classes.formGrid}`]: {
    height: 400,
    overflowY: "auto",
  },
  [`& .${classes.backdrop}`]: {
    zIndex: theme.zIndex.drawer + 1,
  },
  [`& .${classes.filterPaper}`]: {
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    height: "32px", // Hack fixed height until i can figure out a better way of handling this
    width: "100%",
  },
  [`& .${classes.searchFieldChip}`]: {
    maxWidth: "80vw",
    marginRight: theme.spacing(0.5),
  },
  [`& .${classes.expandButton}`]: {
    marginLeft: "auto",
  },
  [`& .${classes.expand}`]: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  [`& .${classes.expandOpen}`]: {
    transform: "rotate(180deg)",
  },
  [`& .${classes.cardAction}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.savedQueries}`]: {
    padding: theme.spacing(1),
  },
  [`& .${classes.savedQueriesSearch}`]: {
    justifyContent: "flex-end",
    display: "flex",
  },
  [`& .${classes.savedQueriesList}`]: {
    overflowY: "auto",
  },
  [`& .${classes.toolBar}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [`& .${classes.titleBar}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${classes.dataSource}`]: {
    "& input": {
      height: 28,
    },
  },
  [`& .${classes.modelNameContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${classes.iconButtons}`]: {
    marginRight: 5,
  },
  [`& .${classes.closeIcon}`]: {
    marginRight: 5,
    color: theme.palette.error.main,
  },
  [`& .${classes.checkIcon}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.confirmContainer}`]: {
    minWidth: 320,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& p": {
      margin: 0,
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.confirmDelete}`]: {
    color: theme.palette.error.main,
  },
  [`& .${classes.savedReportBtnContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${classes.listItem}`]: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.globalIcon}`]: {
    "&.MuiChip-icon": {
      color: theme.palette.text.primary,
    },
  },
  [`& .${classes.tabLabelContainer}`]: {
    height: 60,
  },
  [`& .${classes.tabLabel}`]: {
    minWidth: 150,
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    flexDirection: "row",
    textTransform: "none",
    justifyContent: "center",
  },
  [`& .${classes.tabLabelIcon}`]: {
    marginRight: 10,
    marginBottom: "0px !important",
  },
  [`& .${classes.isFavoritingLoadingIndicator}`]: {
    marginRight: 15,
    marginBottom: -5,
  },
}));

const SavedQueryModal = (props) => {
  const [savedQueries, setSavedQueries] = useState([]);
  const [modelToDelete, setModelToDelete] = useState(false);
  const [savedQueryFilter, setSavedQueryFilter] = useState([]);
  const [editingModelName, setEditingModelName] = useState("");
  const [isDeletingQuery, setIsDeletingQuery] = useState(false);
  const [reportModelToEdit, setReportModelToEdit] = useState({});
  const [warningText, setWarningText] = useState("Are you sure?");
  const [isLoadingScheduledReportsId, setIsLoadingScheduledReportsId] =
    useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [recentReports, setRecentReports] = useState([]);
  const [favoriteReports, setFavoriteReports] = useState([]);
  const [isFavoriting, setIsFavoriting] = useState(null);

  useEffect(() => {
    const filterReports = (reports) => {
      return reports.map((q) => {
        return {
          model: q,
          match: savedQueryFilter.filter((s) =>
            q.name.toLowerCase().includes(s)
          ).length,
        };
      });
    };

    const filterAndSortReports = (reports) => {
      const filteredReports = filterReports(reports);
      filteredReports.sort((a, b) => (a.match > b.match ? -1 : 1));

      if (savedQueryFilter.length) {
        return filteredReports.filter((sq) => sq.match > 0);
      } else {
        return filteredReports;
      }
    };

    setSavedQueries(filterAndSortReports(props.queries));
    setRecentReports(filterAndSortReports(props.recentReports));
    setFavoriteReports(filterAndSortReports(props.favoriteReports));

    setIsDeletingQuery(false);
  }, [
    props.queries,
    props.recentReports,
    props.favoriteReports,
    savedQueryFilter,
  ]);

  const filterReports = (queries) => {
    if (props.license.isTagged("limited-to-microsoft")) {
      return queries
        .filter((o) => isGlobal(o))
        .filter((o) => isMicrosoft(o.model));
    } else if (props.license.isTagged("limited-to-free")) {
      return queries.filter((o) => isGlobal(o));
    }
    return queries;
  };

  const onClick = (savedQuery) => {
    props.onClick(savedQuery);
    props.onClose();
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setModelToDelete(false);
  };

  const handleDeleteQuery = (e) => {
    e.stopPropagation();
    if (!modelToDelete) {
      return;
    }
    setIsDeletingQuery(true);
    modelToDelete
      .delete()
      .then(() => {
        props.reload(true);
      })
      .catch((e) => {
        setIsDeletingQuery(false);
      });
  };

  const toggleConfirmDelete = (e, model) => {
    e.stopPropagation();

    // get any scheduled reports tied to this saved report
    const request = new Request("/report", [
      { field: "dataQueryId", value: model.id },
    ]);

    setIsLoadingScheduledReportsId(model.id);
    request
      .get()
      .then((scheduledReports = []) => {
        // if we find any related, scheduled reports warn the
        // user so they are aware of any inconsistencies
        if (scheduledReports.length > 0)
          setWarningText(
            `This report is attached to ${
              scheduledReports.length
            } scheduled report${
              scheduledReports.length > 1 ? "s" : ""
            }. Are you sure?`
          );
        else setWarningText("Are you sure?");

        setIsLoadingScheduledReportsId(false);
        setModelToDelete(model);
      })
      .catch((e) => {
        setIsLoadingScheduledReportsId(false);
        setModelToDelete(model);
      });
  };

  const handleEditClick = (e, model) => {
    e.stopPropagation();
    setReportModelToEdit(model);
    setEditingModelName(model.name);
  };

  const handleEditConfirm = (e) => {
    e.stopPropagation();

    try {
      reportModelToEdit.set({ name: editingModelName });
      reportModelToEdit.update();
      handleCancelEdit();
    } catch (e) {
      handleCancelEdit();
    }
  };

  const handleCancelEdit = (e) => {
    if (e) e.stopPropagation();

    setEditingModelName("");
    setReportModelToEdit({});
    setWarningText("Are you sure?");
  };

  const handleOnFocus = (e) => {
    e.stopPropagation();
  };

  const handleReportNameChange = (e) => {
    setEditingModelName(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const EmptyTabContent = ({ message }) => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={200}
        textAlign="center"
      >
        <Typography variant="body1">{message}</Typography>
      </Box>
    );
  };

  const renderListItems = (reports) => {
    return reports.map((sortedEntry, index) => {
      const isFavorite = props.favoriteReports.some(
        (report) => report.id === sortedEntry.model.id
      );

      return (
        <ListItem
          key={`saved_query_${sortedEntry.model.id}`}
          button
          onClick={() => onClick(sortedEntry.model)}
          className={classes.listItem}
          datacy={"loadSavedReportsItem"}
        >
          {sortedEntry.model.id === reportModelToEdit?.id ? (
            <div className={classes.modelNameContainer}>
              <TextField
                onClick={handleOnFocus}
                value={editingModelName}
                onChange={handleReportNameChange}
                InputProps={{ disableUnderline: true }}
              />
              <div className={classes.editContainer}>
                <CloseIcon
                  className={classes.closeIcon}
                  onClick={handleCancelEdit}
                />
                <CheckIcon
                  className={classes.checkIcon}
                  onClick={handleEditConfirm}
                />
              </div>
            </div>
          ) : (
            <div className={classes.modelNameContainer}>
              <ListItemText
                primary={sortedEntry.model.name}
                secondary={getSecondaryDateText(sortedEntry.model)}
              />
            </div>
          )}
          {isGlobal(sortedEntry.model) ? (
            <span>
              {isFavoriting === sortedEntry.model.id ? (
                <CircularProgress
                  size={20}
                  className={classes.isFavoritingLoadingIndicator}
                />
              ) : isFavorite ? (
                <IconButton
                  aria-label="unfavorite this report"
                  className={classes.iconButtons}
                  onClick={(e) =>
                    handleToggleFavoriteReport(e, sortedEntry.model, false)
                  }
                >
                  <StarIcon color={"primary"} />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="favorite this report"
                  className={classes.iconButtons}
                  onClick={(e) =>
                    handleToggleFavoriteReport(e, sortedEntry.model, true)
                  }
                >
                  <StarBorderIcon color={"primary"} />
                </IconButton>
              )}
              <Chip
                icon={<PublicIcon className={classes.globalIcon} />}
                label={"Global"}
              />
            </span> // OR
          ) : modelToDelete && modelToDelete.id === sortedEntry.model.id ? (
            <Fade in={modelToDelete.id === sortedEntry.model.id} timeout={500}>
              <div className={classes.confirmContainer}>
                {!isDeletingQuery && <p>{warningText}</p>}
                <Button
                  disabled={isDeletingQuery}
                  variant={"text"}
                  className={classes.confirmDelete}
                  onClick={handleDeleteQuery}
                  datacy={"deleteReportButton"}
                >
                  {isDeletingQuery ? "Deleting..." : "Delete report"}
                </Button>
                {!isDeletingQuery && (
                  <Button
                    variant={"text"}
                    color={"primary"}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </Fade> // OR
          ) : (
            <div className={classes.savedReportBtnContainer}>
              {isLoadingScheduledReportsId === sortedEntry.model.id ? (
                <CircularProgress size={25} color={"primary"} />
              ) : (
                <span>
                  {isFavoriting === sortedEntry.model.id ? (
                    <span className={classes.iconButtons}>
                      <CircularProgress
                        size={20}
                        className={classes.isFavoritingLoadingIndicator}
                      />
                    </span>
                  ) : isFavorite ? (
                    <IconButton
                      aria-label="unfavorite this report"
                      className={classes.iconButtons}
                      onClick={(e) =>
                        handleToggleFavoriteReport(e, sortedEntry.model, false)
                      }
                    >
                      <StarIcon color={"primary"} />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="favorite this report"
                      className={classes.iconButtons}
                      onClick={(e) =>
                        handleToggleFavoriteReport(e, sortedEntry.model, true)
                      }
                    >
                      <StarBorderIcon color={"primary"} />
                    </IconButton>
                  )}
                  {sortedEntry.model.id !== reportModelToEdit?.id && (
                    <IconButton
                      aria-label="edit this report"
                      className={classes.iconButtons}
                      onClick={(e) => handleEditClick(e, sortedEntry.model)}
                    >
                      <EditIcon color={"primary"} />
                    </IconButton>
                  )}
                  <IconButton
                    aria-label="delete this report"
                    onClick={(e) => toggleConfirmDelete(e, sortedEntry.model)}
                    datacy={"deleteReportIcon"}
                  >
                    <DeleteIcon color={"primary"} />
                  </IconButton>
                </span>
              )}
            </div>
          )}
        </ListItem>
      );
    });
  };

  const handleToggleFavoriteReport = (e, model, addToFavorites) => {
    e.stopPropagation();

    if (model && model.id) {
      const currentSavedReportIds =
        props.currentUser.config_user?.saved_report_ids || [];
      const updatedSavedReportIds = addToFavorites
        ? [...currentSavedReportIds, model.id]
        : currentSavedReportIds.filter((reportId) => reportId !== model.id);

      const dataToUpdate = {
        config_user: {
          ...props.currentUser.config_user,
          saved_report_ids: updatedSavedReportIds,
        },
      };

      setIsFavoriting(model.id);

      props
        .editUser(props.orgId, props.currentUser.id, dataToUpdate, true)
        .then(() => {
          setIsFavoriting(null);
        })
        .catch(() => {
          setIsFavoriting(null);
        });
    }
  };

  return (
    <StyledDialog
      disableScrollLock={true}
      onClose={props.onClose}
      aria-labelledby="saved-queries-dialog-title"
      open={props.show}
      fullWidth={true}
      maxWidth="md"
      datacy={"loadSavedReportsModal"}
    >
      <DialogTitle id="saved-queries-dialog-title">Saved Reports</DialogTitle>
      <DialogContent>
        <SearchField value={savedQueryFilter} onChange={setSavedQueryFilter} />
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          className={classes.tabLabelContainer}
        >
          <Tab label="All Reports" className={classes.tabLabel} />
          <Tab
            label="Recent"
            icon={<UpdateOutlinedIcon className={classes.tabLabelIcon} />}
            className={classes.tabLabel}
          />
          <Tab
            label="Favorite"
            icon={<StarBorderIcon className={classes.tabLabelIcon} />}
            className={classes.tabLabel}
          />
        </Tabs>
        {selectedTab === 0 && (
          <Box>
            {savedQueries.length > 0 ? (
              <List className={classes.savedQueriesList}>
                {renderListItems(filterReports(savedQueries))}
              </List>
            ) : (
              <EmptyTabContent message="No saved reports found." />
            )}
          </Box>
        )}
        {selectedTab === 1 && (
          <Box>
            {recentReports.length > 0 ? (
              <List className={classes.savedQueriesList}>
                {renderListItems(filterReports(recentReports))}
              </List>
            ) : (
              <EmptyTabContent message="No recent reports found." />
            )}
          </Box>
        )}
        {selectedTab === 2 && (
          <Box>
            {favoriteReports.length > 0 ? (
              <List className={classes.savedQueriesList}>
                {renderListItems(filterReports(favoriteReports))}
              </List>
            ) : (
              <EmptyTabContent message="No favorite reports found." />
            )}
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
  };
};

// ** State constants *****************
const mapStateToProps = (state) => {
  return {
    license: state.license,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavedQueryModal);
