// *****************************************************************************
// Dependencies
// *****************************************************************************
import React from "react";
import Moment from "moment-timezone";
import { func } from "prop-types";

// ** Styles **************************
import { SuggestedDatesLayout, Suggestion } from "./style";

// ** Constants **********************
const suggestions = (renderDate) => {
  return [
    {
      label: "Last 15 Minutes",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment().subtract(15, "m")).startOf("minute"),
      },
    },
    {
      label: "Last Hour",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment()).subtract(1, "h").startOf("minute"),
      },
    },
    {
      label: "Last 2 Hours",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment()).subtract(2, "h").startOf("minute"),
      },
    },
    {
      label: "Last 8 Hours",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment()).subtract(8, "h").startOf("minute"),
      },
    },
    {
      label: "Last 24 Hours",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment()).subtract(24, "h").startOf("minute"),
      },
    },
    {
      label: "Yesterday",
      value: {
        end: renderDate(Moment()).subtract(1, "d").endOf("day"),
        start: renderDate(Moment().subtract(1, "d").startOf("day")),
      },
    },
    {
      label: "This Week",
      value: {
        end: renderDate(Moment()).endOf("week"),
        start: renderDate(Moment()).startOf("week"),
      },
    },
    {
      label: "Last Week",
      value: {
        end: renderDate(Moment()).subtract(1, "w").endOf("week"),
        start: renderDate(Moment()).subtract(1, "w").startOf("week"),
      },
    },
    {
      label: "Last 7 Days",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment()).subtract(7, "d").startOf("minute"),
      },
    },
    {
      label: "This Month",
      value: {
        end: renderDate(Moment()).endOf("month"),
        start: renderDate(Moment()).startOf("month"),
      },
    },
    {
      label: "Last Month",
      value: {
        end: renderDate(Moment()).subtract(1, "M").endOf("month"),
        start: renderDate(Moment()).subtract(1, "M").startOf("month"),
      },
    },
    {
      label: "Last 30 Days",
      value: {
        end: renderDate(Moment()).startOf("minute"),
        start: renderDate(Moment()).subtract(30, "d").startOf("minute"),
      },
    },
  ];
};

// *****************************************************************************
// Component
// *****************************************************************************
//
//  Datepicker suggested dates component
//
//  ** Remarks
//  Canned date suggestions for the datepicker
//
//  ** Props
//  @param onClick {func} - func invoked on suggestion click
//
const SuggestedDates = ({ onClick, renderDate }) => (
  <SuggestedDatesLayout>
    {suggestions(renderDate).map((suggestion) => (
      <Suggestion
        key={suggestion.label}
        onClick={() => onClick(suggestion.value)}
      >
        {suggestion.label}
      </Suggestion>
    ))}
  </SuggestedDatesLayout>
);

// ** Proptypes ***********************
SuggestedDates.propTypes = {
  onClick: func.isRequired,
  renderDate: func.isRequired,
};

export default SuggestedDates;
