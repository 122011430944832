import { getApiErrorId } from "../../lib/api/API";
import Logger from "../../lib/logger";

const logger = Logger("utils/helpers");

/*
Given a list of 'entry' objects [entry1, entry2, ...],
returns an object of the form { [entry1.id]: entry1, [entry2.id]: entry2, ...}.
The entry objects are inserted without cloning.
Is used to update byId stores.
*/
export const entriesFromList = (entryList) => {
  if (Array.isArray(entryList)) {
    return entryList.reduce((result, entry) => {
      result[entry.id] = entry;
      return result;
    }, {});
  }

  return {};
};

/*
This function is used to insert 'data' into a 'store' of the form
{
  orgId: { timeInterval: data, timeInterval': data',... },
  orgId': {...}
}
`orgId` should be renamed, since it can be any object ID (e.g. sensor),
but this envloves more changes than we have time for now.
*/
export const insertData = (state = {}, action) => {
  const { payload = {} } = action;
  const { insertId, timeInterval, data } = payload;

  const orgData = state[insertId];

  const nextState = {
    ...state,
    [insertId]: {
      ...orgData,
      [timeInterval]: data,
    },
  };

  return nextState;
};

/*
Same as insertData above, but without 'timeInterval', i.e. the state is
{
  insertId: data,
  insertId': data',
  ...
}
*/
export const insertDataById = (state = {}, action) => {
  const { payload = {} } = action;
  const { insertId, data } = payload;

  if (insertId === undefined || insertId === null) {
    return state;
  }

  const nextState = {
    ...state,
    [insertId]: data,
  };

  return nextState;
};

/*
Same as insertData above, but without 'timeInterval', i.e. the state is
{
  insertId: data,
  insertId': data',
  ...
},
and merges the existing and new data objects instead of completely replacing
the existing data by the new 'data'.
*/
export const mergeDataById = (state = {}, action) => {
  const { payload = {} } = action;
  const { insertId, data } = payload;

  if (insertId === undefined || insertId === null) {
    return state;
  }

  const { [insertId]: sliceData = {} } = state;
  const nextData = {
    ...sliceData,
    ...data,
  };

  const nextState = {
    ...state,
    [insertId]: nextData,
  };

  return nextState;
};

/*
API Error reporting to the user
*/

export const GENERAL_ERROR_MESSAGE = "Something went wrong.";

// Takes an FEA request error and returns a message for displaying in the UI
export const apiErrorMessageForUi = ({ message, err }) => {
  const errorId = getApiErrorId(err);

  const text = `${
    `${message || GENERAL_ERROR_MESSAGE} If you continue to see this error,` +
    ' please, contact us via the "Help" icon'
  }${errorId ? `, mentioning the error ID ${errorId}.` : "."}`;

  return text;
};

// For an FEA request error, creates a new error with message
// `apiErrorMessageForUi`, then logs, dispatches, and throws it.
export const rethrowApiError =
  ({ message = GENERAL_ERROR_MESSAGE, err, onError = null, rethrow = true }) =>
  (dispatch) => {
    const newMessage = apiErrorMessageForUi({ message, err });

    logger.error(newMessage, err);

    if (!onError && !rethrow) {
      return;
    }

    const newError = new Error(newMessage);

    if (onError) {
      dispatch(onError(newError));
    }

    if (rethrow) {
      throw newError;
    }
  };

// A wrapper for rethrowApiError that dispatches a new error
// but does not re-throw it
export const handleApiError = ({
  message = GENERAL_ERROR_MESSAGE,
  err,
  onError = null,
}) => {
  try {
    rethrowApiError({
      message,
      err,
      onError,
      rethrow: false,
    });
  } catch (e) {
    console.log("caught error => ", e);
  }
};
