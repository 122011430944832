import React from "react";
import PropTypes from "prop-types";

import { ResponsiveBar } from "@nivo/bar";

import { DEFAULT_NUMBER_OF_BARS } from "./securityConfig";
import { formatNum } from "./securityHelpers";
import CustomLabelsLayer from "./CustomLabelsLayer";
import { getAxisLabelsLeft } from "./AxisLabelsLeft";

import "./BluBarGraph.scss";

/*
Constants
*/

const BAR_COLOR = "#61cdbb"; // hsl(170, 52%, 59%) rgb(97, 205, 187)
//  darken bar color for the label
const BAR_COLOR_DARKER_1_6 = "#37746a"; // hsl(170, 36%, 34%) rgb(55, 116, 106)

const DEFAULT_TOOLTIP_UNIT = "events";

export const BAR_GRAPH_HEIGHT = 370;
export const BAR_GRAPH_MARGIN = {
  top: 10,
  right: 110,
  bottom: 10,
  left: 115,
};
export const BAR_GRAPH_MARGIN_LARGE_LEFT = {
  top: 10,
  right: 110,
  bottom: 10,
  left: 170,
};
export const BAR_GRAPH_PADDING = 0.5;

/*
Helpers
*/

// Takes a raw data array as argument, and returns the data
// array suitable for nivo Bar/ResponsiveBar.
// Reverses the order of the entries if `putLargestAtTheTop`=true
// (this is the default);
// preserves the order if `putLargestAtTheTop`=false.
const getData = ({
  fill = true,
  putLargestAtTheTop = true,
  rawData,
  labelField, // requried for the custom tooltip 'barGraphTooltip'
  valueField,
}) => {
  if (!rawData || rawData.length === 0) {
    return [];
  }

  const data = (rawData || []).map((dataElt, index) => ({
    bluBarLabelValue: dataElt.bluBarLabelValue,
    bluLabel: dataElt[labelField],
    id: `${index}`,
    value: dataElt[valueField],
  }));

  if (fill) {
    const start = DEFAULT_NUMBER_OF_BARS - 1;
    const stop = data.length;
    for (let i = start; i >= stop; i -= 1) {
      data.push({ id: `${i}`, value: 0 });
    }
  }
  if (putLargestAtTheTop) {
    return data.reverse();
  }
  return data;
};

export const barGraphTooltip = (text) => (node) => {
  const { data = {}, value = 0 } = node;
  const { bluLabel = "", bluBarLabelValue = "" } = data;

  // Use `bluBarLabelValue` if it is provided
  const displayValue = bluBarLabelValue ? Number(bluBarLabelValue) : value;

  return (
    <span style={{ color: "black" }}>
      {`${bluLabel}: ${formatNum(displayValue)} ${text}`}
    </span>
  );
};

/*
Main compenent

Assumes that `data` comes sorted descending by `valueField`.
If shown as is, the largest bar will be at the bottom of the graph.
If `putLargestAtTheTop` is `true` (the default value),
BluBarGraph reverses the data, and the largest bar is displayed at the top.
*/

const BluBarGraph = ({
  // data parameters
  labelField,
  labelField2,
  putLargestAtTheTop = true,
  rawData,
  valueField,
  // style parameters
  barLabelColor = BAR_COLOR_DARKER_1_6,
  colors = [BAR_COLOR],
  formatBarLabel = formatNum,
  height = BAR_GRAPH_HEIGHT,
  margin = BAR_GRAPH_MARGIN,
  padding = BAR_GRAPH_PADDING,
  tooltipText = DEFAULT_TOOLTIP_UNIT,
}) => {
  const graphData = getData({
    labelField,
    putLargestAtTheTop,
    rawData,
    valueField,
  });

  const axisLabelsLeft = getAxisLabelsLeft({
    rawData,
    labelField,
    labelField2,
  });

  const customLabelsLayer = CustomLabelsLayer({
    fillColor: barLabelColor,
    format: formatBarLabel,
    putLargestAtTheTop,
  });

  if (!graphData || graphData.length < 1) {
    return null;
  }

  return (
    <div className="security-bar-graph">
      <ResponsiveBar
        data={graphData}
        layout="horizontal"
        height={height}
        margin={margin}
        padding={padding}
        colors={colors}
        axisTop={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          format: (dataId) => axisLabelsLeft[dataId],
        }}
        labelFormat={() => ""} // hide the label - we use customLabelsLayer
        tooltip={barGraphTooltip(tooltipText)}
        animate
        motionStiffness={90}
        motionDamping={15}
        layers={["grid", "axes", "bars", customLabelsLayer, "markers"]}
      />
    </div>
  );
};

BluBarGraph.propTypes = {
  axisLabelsLeft: PropTypes.shape({}),
  barLabelColor: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
  formatBarLabel: PropTypes.func,
  labelField: PropTypes.string.isRequired,
  labelField2: PropTypes.string,
  putLargestAtTheTop: PropTypes.bool,
  height: PropTypes.number,
  margin: PropTypes.shape({}),
  padding: PropTypes.number,
  rawData: PropTypes.arrayOf(PropTypes.shape({})),
  tooltipText: PropTypes.string,
  valueField: PropTypes.string.isRequired,
};

BluBarGraph.defaultProps = {
  axisLabelsLeft: {},
  barLabelColor: BAR_COLOR_DARKER_1_6,
  colors: [BAR_COLOR],
  formatBarLabel: formatNum,
  labelField2: "",
  putLargestAtTheTop: true,
  height: BAR_GRAPH_HEIGHT,
  margin: BAR_GRAPH_MARGIN,
  padding: BAR_GRAPH_PADDING,
  rawData: [],
  tooltipText: DEFAULT_TOOLTIP_UNIT,
};

export default BluBarGraph;
