const blocklistSchema = {
  $id: "/blocklist",
  title: "Block List",
  type: "object",
  properties: {
    age: {
      type: "number",
      readOnly: true,
    },
    block: {
      type: "string",
      readOnly: true,
    },
    blockConfiguration: {
      type: "object",
      properties: {
        automated: { type: "boolean" },
        community: { type: "boolean" },
        default_number: { type: "number" },
        devices: { type: "array", minItems: 1, items: { type: "string" } },
        threat_feeds: { type: "number", enum: [0, 1, 2, 3] },
      },
    },
    blockConfigurationFiles: {
      type: "object",
      readOnly: true,
      properties: {}, // fixme: define deep schema
    },
    blockEnabled: { type: "boolean" },
    created: {
      type: "string",
      readOnly: true,
    },
    createdBy: {
      type: "string",
      readOnly: true,
    },
    feedConfiguration: { type: "object", properties: {} },
    id: {
      format: "uuid",
      type: "string",
      readOnly: true,
    },
    orgId: { type: "string", readOnly: true },
    modified: { type: "string", readOnly: true },
    modifiedBy: { type: "string", readOnly: true },
  },
  required: ["orgId"],
};

export default blocklistSchema;
