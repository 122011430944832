const conditionSchema = {
  $id: "/condition",
  type: "object",
  properties: {
    id: { type: "number" },
    name: { type: "string" },
    orgId: { type: "string", format: "uuid" },
    status: { type: "number" },
    method: { type: "number" },
    analysis: { type: "string" },
    // Following 3 items are workflows
    confirmation: { type: "array", items: { type: "object" } },
    escalation: { type: "array", items: { type: "object" } },
    mitigation: { type: "array", items: { type: "object" } },
    tags: { type: "array", items: { type: "string" } },
    targetAction: { type: "number" },
    created: { type: "string", format: "date-time", readOnly: true },
  },
  required: ["status", "name"],
  additionalProperties: false,
};

export default conditionSchema;
