const findingTypeSchema = {
  $id: "/constant/finding_type",
  type: "object",
  properties: {
    id: { type: "number" },
    name: { type: "string" },
  },
  required: [],
  additionalProperties: false,
};

export default findingTypeSchema;
