import { styled } from "@mui/material/styles";
import { Accordion, Dialog } from "@mui/material";

const PREFIX = "FindingDetailView";
export const classes = {
  userContentCard: `${PREFIX}-userContentCard`,
  quill: `${PREFIX}-quill`,
  accordionPanel: `${PREFIX}-accordionPanel`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  accordionTitleWrap: `${PREFIX}-accordionTitleWrap`,
  findingsDetailContainer: `${PREFIX}-findingsDetailContainer`,
  infoListItem: `${PREFIX}-infoListItem`,
  infoListIcons: `${PREFIX}-infoListIcons`,
  infoLinkWrap: `${PREFIX}-infoLinkWrap`,
  infoTitleWrap: `${PREFIX}-infoTitleWrap`,
  checkbox: `${PREFIX}-checkbox`,
  tooltip: `${PREFIX}-tooltip`,
  contentContainer: `${PREFIX}-contentContainer`,
  innerContainer: `${PREFIX}-innerContainer`,
  sectionContainer: `${PREFIX}-sectionContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  analaysisContainer: `${PREFIX}-analaysisContainer`,
  analysisTextContainer: `${PREFIX}-analysisTextContainer`,
  matchedMeta: `${PREFIX}-matchedMeta`,
  matchedMetaText: `${PREFIX}-matchedMetaText`,
  divider: `${PREFIX}-divider`,
  addNoteWrapper: `${PREFIX}-addNoteWrapper`,
};

export const rootClasses = {
  userContentCard: `${PREFIX}-userContentCard`,
  quill: `${PREFIX}-quill`,
  accordionPanel: `${PREFIX}-accordionPanel`,
  accordionTitle: `${PREFIX}-accordionTitle`,
  accordionSummary: `${PREFIX}-accordionSummary`,
  accordionTitleWrap: `${PREFIX}-accordionTitleWrap`,
  findingsDetailContainer: `${PREFIX}-findingsDetailContainer`,
  infoListItem: `${PREFIX}-infoListItem`,
  infoListIcons: `${PREFIX}-infoListIcons`,
  infoLinkWrap: `${PREFIX}-infoLinkWrap`,
  infoTitleWrap: `${PREFIX}-infoTitleWrap`,
  tooltip: `${PREFIX}-tooltip`,
  contentContainer: `${PREFIX}-contentContainer`,
  innerContainer: `${PREFIX}-innerContainer`,
  sectionContainer: `${PREFIX}-sectionContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  analaysisContainer: `${PREFIX}-analaysisContainer`,
  analysisTextContainer: `${PREFIX}-analysisTextContainer`,
  addNoteWrapper: `${PREFIX}-addNoteWrapper`,
};

export const dialogClasses = {
  infoListItem: `${PREFIX}-infoListItem`,
  infoListIcons: `${PREFIX}-infoListIcons`,
  infoLinkWrap: `${PREFIX}-infoLinkWrap`,
  infoTitleWrap: `${PREFIX}-infoTitleWrap`,
};

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  [`&.${classes.userContentCard}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${classes.quill}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& span": {
      color: theme.palette.text.primary,
    },
  },
  [`& .${classes.accordionPanel}`]: {
    width: "100%",
    flexDirection: "column",
  },
  [`& .${classes.accordionTitle}`]: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: '"Rubik", "Roboto", sans-serif',
    paddingLeft: 20,
  },
  [`& .${classes.accordionSummary}`]: {
    "& div.MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& div.MuiAccordionSummary-expandIconWrapper": {
      order: -1,
    },
  },
  [`& .${classes.accordionTitleWrap}`]: {
    display: "flex",
  },
  [`& .${classes.findingsDetailContainer}`]: {
    padding: "0 15px",
  },
  [`& .${classes.infoListItem}`]: {
    paddingLeft: 0,
    color: theme.palette.text.primary,
  },
  [`& .${classes.infoListIcons}`]: {
    color: theme.palette.text.primary,
    minWidth: 35,
  },
  [`& .${classes.infoLinkWrap}`]: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "8px 24px 16px",
  },
  [`& .${classes.infoTitleWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${classes.checkbox}`]: {
    display: "flex",
    alignItems: "center",
    fontWeight: 300,
    "& p": {
      margin: 0,
      fontSize: 10,
      color: theme.palette.text.secondary,
    },
  },
  [`& .${classes.tooltip}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.contentContainer}`]: {
    width: "100%",
    display: "flex",
    padding: theme.shape.padding,
    justifyContent: "space-between",
  },
  [`& .${classes.innerContainer}`]: {
    width: "50%",
  },
  [`& .${classes.sectionContainer}`]: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.shape.padding,
    fontSize: 16,
  },
  [`& .${classes.sectionTitle}`]: {
    fontSize: 20,
    fontWeight: 400,
    paddingBottom: 5,
  },
  [`& .${classes.analaysisContainer}`]: {
    width: "100%",
  },
  [`& .${classes.analysisTextContainer}`]: {
    overflowWrap: "anywhere",
    color: theme.palette.text.primary,
    fontSize: 16,
  },
  [`& .${classes.matchedMeta}`]: {
    margin: "10px 0",
  },
  [`& .${classes.matchedMetaText}`]: {
    paddingLeft: 5,
  },
  [`& .${classes.divider}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${classes.addNoteWrapper}`]: {
    display: "flex",
    alignItems: "center",
  },
}));

export const Root = styled("div")(({ theme }) => ({
  [`&.${classes.userContentCard}`]: {
    marginTop: theme.shape.padding,
  },
  [`& .${classes.quill}`]: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    "& span": {
      color: theme.palette.text.primary,
    },
  },
  [`& .${classes.accordionPanel}`]: {
    width: "100%",
    flexDirection: "column",
  },
  [`& .${classes.accordionTitle}`]: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontFamily: '"Rubik", "Roboto", sans-serif',
    paddingLeft: 20,
  },
  [`& .${classes.accordionSummary}`]: {
    "& div.MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& div.MuiAccordionSummary-expandIconWrapper": {
      order: -1,
    },
  },
  [`& .${classes.accordionTitleWrap}`]: {
    display: "flex",
  },
  [`& .${classes.findingsDetailContainer}`]: {
    padding: "0 15px",
  },
  [`& .${classes.tooltip}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${classes.contentContainer}`]: {
    width: "100%",
    display: "flex",
    padding: theme.shape.padding,
    justifyContent: "space-between",
  },
  [`& .${classes.innerContainer}`]: {
    width: "50%",
  },
  [`& .${classes.sectionContainer}`]: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: theme.shape.padding,
    fontSize: 16,
  },
  [`& .${classes.sectionTitle}`]: {
    fontSize: 20,
    fontWeight: 400,
    paddingBottom: 5,
  },
  [`& .${classes.analaysisContainer}`]: {
    width: "100%",
  },
  [`& .${classes.analysisTextContainer}`]: {
    overflowWrap: "anywhere",
    color: theme.palette.text.primary,
    fontSize: 16,
  },
  [`& .${classes.addNoteWrapper}`]: {
    display: "flex",
    alignItems: "center",
  },
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.infoListItem}`]: {
    paddingLeft: 0,
    color: theme.palette.text.primary,
  },
  [`& .${classes.infoLinkWrap}`]: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "8px 24px 16px",
  },
  [`& .${classes.infoTitleWrap}`]: {
    display: "flex",
    justifyContent: "space-between",
  },
  [`& .${classes.infoListIcons}`]: {
    color: theme.palette.text.primary,
    minWidth: 35,
  },
}));
