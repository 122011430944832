import React, { useState, useEffect } from "react";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorIcon from "@mui/icons-material/Error";
import PendingIcon from "@mui/icons-material/Pending";

const ActionDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  const [displayedDescription, setDisplayedDescription] = useState(
    props.description
  );

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.description) setDisplayedDescription(props.description);
  }, [props.description]);

  const handleClick = (action) => {
    action();
  };

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth>
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>{displayedDescription}</DialogContent>
      <DialogActions>
        {props.actions.map((action, idx) => {
          let Component;
          const loadingProps = {};
          if (action.loading === undefined) {
            Component = Button;
          } else {
            Component = LoadingButton;
            loadingProps.loading = action.loading;
            if (action.loading) {
              // The pending icon is only visible for one tick.
              // It's mainly there to make space for the loading animation.
              loadingProps.loadingPosition = "end";
              loadingProps.endIcon = <PendingIcon />;
            } else if (action.error) {
              loadingProps.endIcon = <ErrorIcon />;
            }
          }
          return (
            <Component
              key={action.title}
              datacy={action.datacy}
              onClick={() => handleClick(action.action)}
              variant={action.variant ? action.variant : "text"}
              disabled={action.disabled ? action.disabled : false}
              // Theme colors are proving to be tricky on
              // MUI buttons, so adding to styles for now
              style={
                action.backgroundColor
                  ? {
                      color: action.textColor,
                      backgroundColor: action.backgroundColor,
                    }
                  : {}
              }
              color={action.backgroundColor ? "inherit" : "primary"}
              {...loadingProps}
            >
              {action.title}
            </Component>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
