import React from "react";
import PropTypes from "prop-types";

import LabelsAndTags from "./LabelsAndTags";
import LabelsAndTagsFetch from "./LabelsAndTagsFetch";

/*
This component combines LabelsAndTags with LabelsAndTagsFetch.
It fetches the labels for `orgId` and the tags for a single object `objId`, and displays both,
the Labels select dropdwon and the object's tags.
*/
const LabelsAndTagsWithFetch = (props) => {
  const { objId, objType, orgId, superadmin } = props;

  return (
    <LabelsAndTagsFetch objId={[objId]} objType={[objType]} orgId={orgId}>
      <LabelsAndTags
        objId={objId}
        objType={objType}
        orgId={orgId}
        superadmin={superadmin}
      />
    </LabelsAndTagsFetch>
  );
};

LabelsAndTagsWithFetch.propTypes = {
  objId: PropTypes.string.isRequired,
  objType: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  superadmin: PropTypes.bool,
};

LabelsAndTagsWithFetch.defaultProps = {
  superadmin: false,
};

export default LabelsAndTagsWithFetch;
