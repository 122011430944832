import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import PieChart from "../../Components/Charts/PieChart";
import EmptyVisualization from "../../Components/EmptyVisualization";
import Loading from "../../Components/Loading";

const StatesPieChart = ({ chartData, handleRetry }) => {
  const pieChartHasError = !!chartData.error;

  const [visualizationData, setVisualizationData] = useState(
    chartData.visualizationData
  );
  const [isLoading, setIsLoading] = useState(
    chartData.loading || !chartData.firstLoadComplete
  );
  const [isEmpty, setIsEmpty] = useState(
    !chartData.loading && chartData.dataIsEmpty
  );

  useEffect(() => {
    setVisualizationData(chartData.visualizationData);
    // we should always be checking for dataIsEmpty
    let isEmpty =
      chartData.dataIsEmpty ||
      Object.keys(chartData.visualizationData).length === 0;
    setIsEmpty(isEmpty);
  }, [chartData]);

  useEffect(() => {
    setIsLoading(chartData.loading);
  }, [chartData.loading]);

  return (
    <React.Fragment>
      {isLoading && <Loading />}

      {(isEmpty || pieChartHasError) && (
        <div>
          <EmptyVisualization
            isError={pieChartHasError}
            handleRetry={handleRetry}
          >
            <PieChart
              visualizationData={chartData.emptyStateVisualizationData}
            />
          </EmptyVisualization>
        </div>
      )}

      {!isLoading && !isEmpty && !pieChartHasError && (
        <PieChart visualizationData={visualizationData} />
      )}
    </React.Fragment>
  );
};

StatesPieChart.propTypes = {
  chartData: PropTypes.shape({}).isRequired,
  handleRetry: PropTypes.func.isRequired,
};

export default React.memo(StatesPieChart);
