// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// ** Constants ***********************
import COLOR from "utils/constants/color";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Selected icon container *********
export const SelectedIconContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  fill: ${COLOR.gray500};
  outline: none !important;
  padding: 8px 4px;
  transition: all 250ms;

  &:hover {
    fill: ${COLOR.blumiraBlue800};
  }
`;

// ** Selected item *****************
export const SelectedItemStyle = styled.div`
  border-radius: 3px;
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  margin: 0px;
  padding: 4px 8px;
  width: 100%;
`;

// ** Selected title ****************
export const SelectedTitle = styled.span`
  color: ${COLOR.gray800};
  display: block;
  font-size: 12px;
  overflow: hidden;
  max-width: ${(p) => p.maxWidth};
  min-width: ${(p) => p.maxWidth};
  padding-right: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 10px);
`;
