import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { metricsLastReceivedSelector } from "../../../selectors/pipelineSelectors";
import TimeRange from "../../Components/TimeRange";

const MetricsLastReceived = (props) => {
  const { metricsLastReceived } = props;

  if (!metricsLastReceived) {
    return null;
  }

  return (
    <div className="overview-subtitle">
      <TimeRange dateStart={metricsLastReceived} prefix="" label="" />
    </div>
  );
};

const mapStateToProps = (state) => {
  const metricsLastReceived = metricsLastReceivedSelector(state);

  return {
    metricsLastReceived,
  };
};

MetricsLastReceived.propTypes = {
  metricsLastReceived: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(MetricsLastReceived);
