/*
Constants
*/

export const DEFAULT_EMPTY_LABEL = "";
export const DEFAULT_NUMBER_OF_BARS = 10;
export const DEFAULT_TIME_INTERVAL = "7d";

// Orders the bar graphs
export const ORDERED_GRAPHS = [
  "attackTypes",
  "blockedAttacks",
  "victims",
  "intruders",
  "endpointAttacks",
  "endpointVictims",
  "endpointEvents",
  "failedLogins",
  "loggingDevices",
  "loggingEndpoints",
  "endpointHosts",
  "ingressTraffic",
  "egressTraffic",
];
