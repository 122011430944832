import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { ErrorBanner } from "../../Components/ErrorBanner";
import {
  clearSensorSupportError,
  fetchSupportInfo,
} from "../../../redux/actions/Sensors";
import { sensorTimeIntervalSelector as timeIntervalSelector } from "../../../selectors/timeIntervalSelectors";
import { orgIdSelector } from "../../../selectors/orgIdSelector";
import {
  currentSensorSelector,
  sensorDevicesSelector,
  sensorDevicesLogsTotalSelector,
  sensorIdSelector,
  sensorLatestParsedTimeSelector,
  sensorLatestTimestampSelector,
  sensorMoreInfoSelector,
  sensorParsingErrorsSelector,
  sensorSupportErrorSelector,
  sensorTimeWindowSelector,
} from "../../../selectors/sensorsSelectors";

import TimeRange from "../../Components/TimeRange";
import EventsPerSecond from "./EventsPerSecond";
import LatestParsedTime from "./LatestParsedTime";
import LatestTimestamp from "./LatestTimestamp";
import MoreInfo from "./MoreInfo";
import ParsingErrors from "./ParsingErrors";
import SensorStatusDetail from "./SensorStatusDetail";
import SensorAlerts from "./SensorAlerts";
import SensorHealth from "./SensorHealth";
import SupportNotes from "./SupportNotes";
import { getLabel } from "./common/helpers";

import "./Support.scss";

/*
Constants
*/

const COLUMN_NUM_LEFT = 4;
const COLUMN_NUM_RIGHT = 8;

/*
Main component
*/

const Support = ({
  dispatchClearSupportErr,
  dispatchFetchParsingErrors,
  health,
  latestParsedTime,
  latestTimestamp,
  loggerRecentPublishedEps,
  loggerRecentPublishedTimestamp,
  loggerRecentReadInputLine,
  logsTotal,
  orgId,
  parsingErrors,
  sensorId,
  statusCode,
  statusDetail,
  statusModified,
  statusName,
  supportError,
  timeInterval,
  timeWindow,
}) => {
  const { windowEnd, windowStart } = timeWindow;

  useEffect(() => {
    dispatchFetchParsingErrors({
      orgId,
      relativeStart: timeInterval,
      sensorId,
    });
  }, [dispatchFetchParsingErrors, orgId, sensorId, timeInterval]);

  const supportErrorMsg = supportError ? supportError.message : "";

  return (
    <div className="blu-support-container">
      <div className="blu-support-container-inner">
        {supportError && (
          <ErrorBanner
            message={supportErrorMsg}
            dismiss={dispatchClearSupportErr}
          />
        )}

        <div className="blu-support-section">
          <div className="blu-support-section-title">Notes</div>

          <SupportNotes />
        </div>

        <div className="blu-support-section">
          <SensorAlerts />
        </div>

        <div className="blu-support-section">
          <div className="blu-support-section-title">Sensor Status Detail</div>

          <div className="blu-support-section-body">
            <SensorStatusDetail
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              statusCode={statusCode}
              statusDetail={statusDetail}
              statusModified={statusModified}
              statusName={statusName}
            />

            <SensorHealth
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              health={health}
            />
          </div>
        </div>

        <div className="blu-support-section">
          <div className="blu-support-section-title">
            <TimeRange
              dateStart={windowStart}
              dateEnd={windowEnd}
              label={`last ${getLabel(timeInterval)}`}
              prefix="Summary for "
            />
          </div>

          <div className="blu-support-section-body">
            <ParsingErrors
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              parsingErrors={parsingErrors}
              timeInterval={timeInterval}
            />

            <EventsPerSecond
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              logsTotal={logsTotal}
              timeWindow={timeWindow}
            />

            <LatestTimestamp
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              latestTimestamp={latestTimestamp}
            />

            <LatestParsedTime
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              latestParsedTime={latestParsedTime}
            />
          </div>
        </div>

        <div className="blu-support-section">
          <div className="blu-support-section-title">Logger Telemetry</div>

          <div className="blu-support-section-body">
            <MoreInfo
              columnNumLeft={COLUMN_NUM_LEFT}
              columnNumRight={COLUMN_NUM_RIGHT}
              loggerRecentPublishedEps={loggerRecentPublishedEps}
              loggerRecentPublishedTimestamp={loggerRecentPublishedTimestamp}
              loggerRecentReadInputLine={loggerRecentReadInputLine}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Support.propTypes = {
  dispatchClearSupportErr: PropTypes.func,
  dispatchFetchParsingErrors: PropTypes.func.isRequired,
  health: PropTypes.PropTypes.shape({}).isRequired,
  latestParsedTime: PropTypes.string.isRequired,
  latestTimestamp: PropTypes.string.isRequired,
  loggerRecentPublishedEps: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  loggerRecentPublishedTimestamp: PropTypes.string.isRequired,
  loggerRecentReadInputLine: PropTypes.string.isRequired,
  logsTotal: PropTypes.number.isRequired,
  orgId: PropTypes.string.isRequired,
  parsingErrors: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeInterval: PropTypes.string.isRequired,
  sensorId: PropTypes.string.isRequired,
  statusCode: PropTypes.string,
  statusDetail: PropTypes.string,
  statusModified: PropTypes.string,
  statusName: PropTypes.string,
  supportError: PropTypes.shape({}),
  timeWindow: PropTypes.shape({}).isRequired,
};

Support.defaultProps = {
  dispatchClearSupportErr: () => {},
  statusCode: "",
  statusDetail: "",
  statusModified: "",
  statusName: "",
  supportError: null,
};

const mapStateToProps = (state) => {
  const devices = sensorDevicesSelector(state);
  const logsTotal = sensorDevicesLogsTotalSelector(state);
  const orgId = orgIdSelector(state);
  const sensor = currentSensorSelector(state);
  const sensorId = sensorIdSelector(state);
  const timeInterval = timeIntervalSelector(state);
  const timeWindow = sensorTimeWindowSelector(state);

  const {
    health = {},
    status,
    statusDetail = "",
    statusModified = "",
    statusName = "",
  } = sensor;

  const statusCode = String(status) || "";

  const latestParsedTime = sensorLatestParsedTimeSelector(state);
  const latestTimestamp = sensorLatestTimestampSelector(state);
  const {
    loggerRecentPublishedEps,
    loggerRecentPublishedTimestamp,
    loggerRecentReadInputLine,
  } = sensorMoreInfoSelector(state);
  const parsingErrors = sensorParsingErrorsSelector(state);
  const supportError = sensorSupportErrorSelector(state);

  return {
    devices,
    health,
    latestParsedTime,
    latestTimestamp,
    loggerRecentPublishedEps,
    loggerRecentPublishedTimestamp,
    loggerRecentReadInputLine,
    logsTotal,
    orgId,
    parsingErrors,
    sensorId,
    statusCode,
    statusDetail,
    statusModified,
    statusName,
    supportError,
    timeInterval,
    timeWindow,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchClearSupportErr: () => {
    dispatch(clearSensorSupportError);
  },
  dispatchFetchParsingErrors: ({ orgId, relativeStart, sensorId }) => {
    dispatch(
      fetchSupportInfo({
        orgId,
        relativeStart,
        sensorId,
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Support);
