// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { string } from "prop-types";

// ** Components **********************
import { ArrowIcon } from "views/Components/Icon";

// ** Styles **************************
import { Breadcrumb, Container, IconContainer } from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Page breadcrumbs
//
//  ** Remarks
//  Parses current path and provides breadcrumbs
//
//  ** Props
//  @param path {string} - current location
//
//  ** Methods
//  generateCrumbs - renders breadcumbs based on path
//
class Breadcrumbs extends Component {
  generateCrumbs() {
    const { path } = this.props;
    const levels = path.split("/").splice(2);

    return (
      <Container>
        {levels.map((level, i) => {
          if (i !== levels.length - 1) {
            return (
              <Container key={level}>
                <Breadcrumb>
                  {level.charAt(0).toUpperCase() + level.substring(1)}
                </Breadcrumb>
                <IconContainer>
                  <ArrowIcon height="6px" />
                </IconContainer>
              </Container>
            );
          }

          return (
            <Breadcrumb key={level}>
              {level.charAt(0).toUpperCase() + level.substring(1)}
            </Breadcrumb>
          );
        })}
      </Container>
    );
  }

  render() {
    return this.generateCrumbs();
  }
}

// ** Prop types **********************
Breadcrumbs.propTypes = {
  path: string.isRequired,
};

export default Breadcrumbs;
