import React, { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PREFIX = "SearchField";
const classes = {
  searchField: `${PREFIX}-searchField`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`&.${classes.searchField}`]: {
    width: "50ch",
    marginTop: theme.spacing(1),
  },
}));

const SearchField = (props) => {
  const debounce = 250;

  const [value, setValue] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);

  useEffect(() => {
    setValue(props.value[0]);
  }, []);

  const updateValue = (event) => {
    const textValue = event ? event.target.value : "";
    setValue(textValue);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    setDebounceTimer(
      setTimeout(() => {
        if (textValue.length > 0) {
          props.onChange(textValue.toLowerCase().split(" "));
        } else {
          props.onChange([]);
        }
        setDebounceTimer(null);
      }, debounce)
    );
  };

  return (
    <StyledFormControl className={classes.searchField}>
      <TextField
        onChange={updateValue}
        variant="outlined"
        size="small"
        value={value}
        label="Search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => updateValue()}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        datacy={"loadSavedReportsSearch"}
      />
    </StyledFormControl>
  );
};

export default SearchField;
