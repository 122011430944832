import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  activeJobMetricsErrSelector,
  activeJobMetricsRowsSelector,
} from "../../../selectors/pipelineSelectors";
import MetricsLastReceived from "./MetricsLastReceived";
import MetricsTable from "./MetricsTable";

const ActiveJobMetrics = ({ activeJobMetricsRows, errorMsg }) => (
  <React.Fragment>
    {!errorMsg && <MetricsLastReceived />}

    {errorMsg && <div className="overview-subtitle">{errorMsg}</div>}

    <MetricsTable data={activeJobMetricsRows} />
  </React.Fragment>
);

const mapStateToProps = (state) => {
  const errorMsg = activeJobMetricsErrSelector(state);
  const activeJobMetricsRows = activeJobMetricsRowsSelector(state);

  return {
    activeJobMetricsRows,
    errorMsg,
  };
};

ActiveJobMetrics.propTypes = {
  activeJobMetricsRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errorMsg: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, null)(ActiveJobMetrics);
