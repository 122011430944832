import React from "react";
import { connect } from "react-redux";
import FindingsDetailView from "./FindingsDetailView";
import FindingsListView from "./FindingsListView";
import Loading from "views/Components/Loading";

const FindingsView = (props) => {
  return props.ready ? <props.PageComponent {...props} /> : <Loading />;
};

const mapStateToProps = (state, ownProps) => {
  const retVal = {
    PageComponent: FindingsListView,
  };
  if (state.location.payload.id1) {
    retVal.PageComponent = FindingsDetailView;
  }
  return retVal;
};

export default connect(mapStateToProps)(FindingsView);
