import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { loadPageData } from "redux/actions/Request";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import { Root, IntegrationStatusDiv, classes } from "./styles";

import FlagIcon from "@mui/icons-material/Flag";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveIcon from "@mui/icons-material/Remove";

import FindingCard from "views/Components/Finding/Card";
import RunTestDialog from "views/Components/RunTestDialog";
import VisualizationList from "views/Components/VisualizationList";
import { popularReports } from "../PopularReports/popularReportsList";
import FunnelVisualization from "views/Components/FunnelVisualization";
import SearchableListDialog from "views/Components/SearchableListDialog";
import { isMicrosoft, isGlobal } from "views/Components/Reporting/helpers";
import MoreDetectionCoverageDialog from "views/Components/MoreDetectionCoverageDialog";

import { INTEGRATION_STATUS } from "../../Components/CloudModules/constants";

import Request from "lib/api/Request";

import { DemoAPI } from "../../../lib/api";

import { accountAdministrationHref } from "views/Pages/WelcomePageView";

const moment = require("moment-timezone");

const API = { demo: new DemoAPI() };

export const filterReports = (queries, license) => {
  if (license.isTagged("limited-to-microsoft")) {
    return queries
      .filter((o) => isGlobal(o))
      .filter((o) => isMicrosoft(o))
      .filter((o) => o.uiVisible);
  } else if (license.isTagged("limited-to-free")) {
    // return only global for FREE but do not restrict to microsoft
    return queries.filter((o) => o.uiVisible).filter((o) => isGlobal(o));
  }
  return queries.filter((o) => o.uiVisible);
};

const SummaryDashboard = (props) => {
  const [visibleReports, setVisibleReports] = useState([]);
  const [loadingFindings, setLoadingFindings] = useState(false);
  const [isUsersDialogOpen, setIsUsersDialogOpen] = useState(false);
  const [visibleSavedQueries, setVisibleSavedQueries] = useState([]);
  const [isReportsDialogOpen, setIsReportsDialogOpen] = useState(false);
  const [isRunTestDialogOpen, setIsRunTestDialogOpen] = useState(false);
  const [visibleFindings, setVisibleFindings] = useState(props.findings);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [isDetectionRulesDialogOpen, setIsDetectionRulesDialogOpen] =
    useState(false);
  const [isDetectionCoverageDialogOpen, setIsDetectionCoverageDialogOpen] =
    useState(false);
  const [visibleCloudConnectors, setVisibleCloudConnectors] = useState([]);

  useEffect(() => {
    if (props.savedQueries) {
      let newVisibleSavedQueries = filterReports(
        props.savedQueries,
        props.license
      );
      setVisibleSavedQueries(newVisibleSavedQueries);
    }
  }, [props.savedQueries]);

  useEffect(() => {
    // If the org is a FREE edition org and doesn't have a cloud connector setup yet, then return the full list of findings
    // This will allow FREE orgs that have generated smaple findings to view those up until they setup a cloud connector
    if (
      props.license?.isTagged("limited-to-free") &&
      !props.integrations?.length
    ) {
      setVisibleFindings(props.findings);
      // Otherwise, return a list of findings with any SAMPLE findings filtered out
    } else {
      setVisibleFindings(
        props.findings.filter((f) => !f.name.includes("[SAMPLE]"))
      );
    }
  }, [props.findings, props.license, props.integrations]);

  useEffect(() => {
    if (props.savedQueries && props.savedQueries.length) {
      let report = popularReports.find((pr) => pr.id === 1050);
      report.model = props.savedQueries.find((sq) => sq.id === report.id);
      if (report.model) {
        report.request = new Request("/bq");
        report.request.fromQuery(report.model);
        report.request.setResultParam("outputFields", [report.field]);
        report.request.setResultParam("transform", [
          { operator: "count", value: "grouped_count" },
        ]);
        report.request.setResultParam("orderBy", [
          { key: "grouped_count", value: -1 },
        ]);
        // clear any queryParams already dealing with date
        Object.keys(report.request.queryParams).forEach((uuid) => {
          if (report.request.queryParams[uuid].field === "created") {
            report.request.deleteQueryParam(uuid);
          }
        });

        report.request.setQueryParam({
          field: "created",
          operator: "gt",
          value: moment().add(-6, "months").format("YYYY-MM-DDTHH:mm"),
        });

        report.request.setQueryParam({
          field: "created",
          operator: "lt",
          value: moment().format("YYYY-MM-DDTHH:mm"),
        });

        report.request.get().then((results) => {
          const formattedResults = report.formatFunction(results);
          report.data = formattedResults;
          setVisibleReports([report]);
        });
      }
    }
  }, [props.savedQueries]);

  useEffect(() => {
    if (props.ready) {
      // If the org has more than 3 connectors setup, only show the first 3
      if (props.integrations?.length > 3) {
        const threeConnectors = props.integrations?.slice(0, 3);
        setVisibleCloudConnectors(threeConnectors);
        // Otherwise, show all of them
      } else {
        setVisibleCloudConnectors(props.integrations);
      }
    }
  }, [props.ready, props.integrations]);

  const toggleReportsDialog = () => {
    setIsReportsDialogOpen(!isReportsDialogOpen);
  };

  const toggleUsersDialog = () => {
    setIsUsersDialogOpen(!isUsersDialogOpen);
  };

  const toggleDetectionRulesDialog = () => {
    setIsDetectionRulesDialogOpen(!isDetectionRulesDialogOpen);
  };

  const toggleDetectionCoverageDialog = () => {
    setIsDetectionCoverageDialogOpen(!isDetectionCoverageDialogOpen);
  };

  const toggleRunTestDialog = () => {
    setIsRunTestDialogOpen(!isRunTestDialogOpen);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationDialogOpen(!isConfirmationDialogOpen);
  };

  const handleAdditionalInfoClick = () => {
    setIsDetectionRulesDialogOpen(false);
    setIsDetectionCoverageDialogOpen(true);
  };

  const handleReportItemClick = (reportId) => {
    if (reportId) props.routeToSavedReport(reportId, props.orgId);
  };

  const handleCloudConnectorClick = () => {
    if (props.integrations?.length) props.routeToCloudConnector(props.orgId);
  };

  const handleGenerateData = async () => {
    const data = {
      org_id: props.orgId,
      launch: true,
      reset: true,
    };

    setLoadingFindings(true);
    toggleConfirmationModal();

    await API.demo.resetDemo({
      orgId: props.orgId,
      data,
    });

    // account for generation time
    setTimeout(() => {
      setLoadingFindings(false);
      props.reload(true);
    }, 3500);
  };

  const canGenerateFindings = () => {
    // page is loaded with data, no findings, license is defined and free, and they don't have a CC setup
    return (
      !!props.ready &&
      !visibleFindings.length &&
      props?.license?.isTagged("limited-to-free") &&
      !props.integrations?.length
    );
  };

  const integrationStatus = (connector) => {
    if (connector.integrationStatus === INTEGRATION_STATUS.INIT) {
      return (
        <IntegrationStatusDiv className={classes.row}>
          <CircularProgress size={20} className={classes.statusIcon} />
          <p className={classes.statusText}>Initializing…</p>
        </IntegrationStatusDiv>
      );
    } else if (connector.integrationStatus === INTEGRATION_STATUS.OK) {
      return (
        <IntegrationStatusDiv className={classes.row}>
          <CheckCircleIcon
            className={`${classes.statusIcon} ${classes.success}`}
          />
          <p className={classes.statusText}>Connected</p>
        </IntegrationStatusDiv>
      );
    } else if (connector.integrationStatus === INTEGRATION_STATUS.ERROR) {
      return (
        <IntegrationStatusDiv className={classes.row}>
          <ErrorIcon className={`${classes.statusIcon} ${classes.error}`} />
          <p className={classes.statusText}>Needs Attention</p>
        </IntegrationStatusDiv>
      );
    } else {
      return (
        <IntegrationStatusDiv className={classes.row}>
          {connector.integrationStatus}
        </IntegrationStatusDiv>
      );
    }
  };

  const filterEnabledRules = (list) =>
    list?.filter((r) => r.status === 1) || [];

  return (
    <Root className={classes.page}>
      <div className={classes.topRowContainer}>
        <FunnelVisualization
          ready={props.ready}
          findings={visibleFindings.length || 0}
          logsTotal={
            props.logsTotal && props.logsTotal.length > 0
              ? props.logsTotal[0].grouped_count
              : 0
          }
          blockedEvents={
            props.blockedEvents && props.blockedEvents.length > 0
              ? props.blockedEvents[0].grouped_count
              : 0
          }
          threats={
            props.threats && props.threats.length > 0
              ? props.threats[0].count
              : 0
          }
          suspects={
            props.suspects && props.suspects.length > 0
              ? props.suspects[0].count
              : 0
          }
          integrations={visibleCloudConnectors}
        />
        <div
          className={`${classes.baseContainer} ${classes.rulesContainer}`}
          onClick={toggleDetectionRulesDialog}
          datacy={"detectionRulesComponent"}
        >
          <p className={classes.dataTitle}>Detection rules</p>
          <p className={classes.dataText}>
            {filterEnabledRules(props.rules).length}
          </p>
        </div>
        <div
          className={`${classes.baseContainer} ${classes.connectorStatusContainer}`}
          onClick={handleCloudConnectorClick}
          datacy={"cloudConnectorComponent"}
        >
          <p className={classes.dataTitle}>
            {props.integrations?.length > 1
              ? "Cloud Connectors"
              : "Cloud Connector"}
          </p>
          {props.integrations?.length ? (
            visibleCloudConnectors?.map((c) => (
              <div key={c.id}>
                <p className={classes.connectorName}>{c.integrationName}</p>
                <div className={classes.status}>
                  {integrationStatus(c)}
                  <RemoveIcon fontSize={"small"} className={classes.dashIcon} />
                  {c.integrationStatus === INTEGRATION_STATUS.ERROR ? (
                    <p className={classes.restoreText}>
                      Click here to restore logging
                    </p>
                  ) : (
                    <p className={classes.statusText}>
                      Last log event detected:{" "}
                      {moment
                        .utc(c.lastStatusUpdate)
                        .tz(moment.tz.guess(true))
                        .fromNow()}
                    </p>
                  )}
                </div>
              </div>
            ))
          ) : props.ready && !props.integrations?.length ? (
            <p className={classes.dataTitle}>No Cloud Connector detected</p>
          ) : (
            <p className={classes.dataTitle}>
              Gathering Cloud Connector data...
            </p>
          )}
          {!props.integrations?.length && !props.ready && (
            <div className={classes.status}>
              <CircularProgress />
            </div>
          )}
        </div>
        <div
          className={`${classes.baseContainer} ${classes.testRunContainer}`}
          onClick={toggleRunTestDialog}
          datacy={"runTestComponent"}
        >
          <FlagIcon />
          <p>Run a test</p>
        </div>
      </div>
      <div className={classes.topRowContainer}>
        <div
          className={`${classes.baseContainer} ${classes.reportNumberContainer}`}
          onClick={toggleUsersDialog}
          datacy={"usersComponent"}
        >
          <p className={classes.dataTitle}>Users</p>
          <p className={classes.dataText}>
            {props.users ? props.users.length : 0}
          </p>
        </div>
        <div
          className={`${classes.baseContainer} ${classes.reportNumberContainer}`}
          onClick={toggleReportsDialog}
          datacy={"reportsComponent"}
        >
          <p className={classes.dataTitle}>Reports</p>
          <p className={classes.dataText}>
            {visibleSavedQueries.length ? visibleSavedQueries.length : 0}
          </p>
        </div>
        {props.ready && visibleReports.length ? (
          <VisualizationList
            theme={props.theme}
            listData={visibleReports}
            isLoading={!props.ready}
            standAlone={true}
          />
        ) : (
          <div
            className={`${classes.baseContainer} ${classes.popReportPlaceholder}`}
          >
            {!props.ready ? (
              <p>Loading your report overview...</p>
            ) : (
              <p>Nothing to report, yet...</p>
            )}
            {!props.ready && <CircularProgress size={30} />}
          </div>
        )}
      </div>
      <div className={classes.topRowContainer}>
        <div className={classes.sortedDataContainer}>
          <p className={classes.findingNumberText}>
            {visibleFindings.length
              ? `${visibleFindings.length} Unresolved Findings`
              : "No Unresolved Findings"}
          </p>
          {!!visibleFindings.length && (
            <p className={classes.sortedDataText}>
              Past 6 months, sorted by newest first
            </p>
          )}
        </div>
        {canGenerateFindings() && (
          <Button
            variant={"contained"}
            className={classes.red}
            onClick={toggleConfirmationModal}
            disabled={!!loadingFindings}
            datacy={"generateSampleFindingsBtn"}
          >
            {loadingFindings
              ? "Generating sample findings"
              : "Generate sample findings"}
          </Button>
        )}
      </div>
      {!props.ready || !!loadingFindings ? (
        <div>
          <div className={classes.emptyStateContainer}>
            <p>Loading your unresolved findings...</p>
            <CircularProgress />
          </div>
        </div>
      ) : visibleFindings.length ? (
        visibleFindings.map((finding) => (
          <FindingCard
            {...finding}
            key={finding.id}
            hideComments={true}
            constants={props.constants}
          />
        ))
      ) : (
        <div className={classes.emptyStateContainer}>
          <p>No unresolved findings were generated.</p>
        </div>
      )}
      <SearchableListDialog
        title={"Reports"}
        items={visibleSavedQueries}
        open={isReportsDialogOpen}
        onClose={toggleReportsDialog}
        onItemClick={handleReportItemClick}
      />
      <SearchableListDialog
        title={"Users"}
        items={props.users}
        open={isUsersDialogOpen}
        onClose={toggleUsersDialog}
        searchableProperty={"email"}
        handleAdditionalInfoClick={accountAdministrationHref}
        additionalInfo={"Administering Blumira User Accounts"}
      />
      <SearchableListDialog
        items={filterEnabledRules(props.rules)}
        title={"Detection rules"}
        open={isDetectionRulesDialogOpen}
        onClose={toggleDetectionRulesDialog}
        additionalInfo={"Get more detection coverage"}
        handleAdditionalInfoClick={handleAdditionalInfoClick}
        isAdditionalInfoAButton={true}
        emptyText={
          "Detection rules will appear as soon as Blumira processes your incoming log data. This can take up to 60 minutes after you set up your first Cloud Connector or Sensor."
        }
      />
      <MoreDetectionCoverageDialog
        open={isDetectionCoverageDialogOpen}
        onClose={toggleDetectionCoverageDialog}
      />
      <RunTestDialog open={isRunTestDialogOpen} onClose={toggleRunTestDialog} />
      <Dialog open={isConfirmationDialogOpen}>
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <strong>WARNING:</strong>
          <p>
            Generating new findings will take a few moments and add example data
            to your account so that you can learn what Blumira findings look
            like and how to respond to them.
          </p>
          <p>
            The activity in these sample findings is not real and is not based
            on data your organization sent to Blumira.
          </p>
          <p>
            These findings will send multiple email notifications to users who
            have the default notification settings.
          </p>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleGenerateData}
            className={classes.red}
            variant={"contained"}
            datacy={"confirmButton"}
          >
            Confirm
          </Button>
          <Button
            onClick={toggleConfirmationModal}
            color="primary"
            variant={"outlined"}
            datacy={"cancelButton"}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

SummaryDashboard.defaultProps = {
  findings: [],
};

const mapStateToProps = (state) => ({
  license: state.license,
  orgId: state.location.payload.orgId,
});

const mapDispatchToProps = (dispatch) => {
  return {
    routeToSavedReport: (reportId, orgId) => {
      dispatch({
        type: "PAGE",
        payload: {
          orgId: orgId,
          id1: reportId,
          toplevel: "reporting",
          secondlevel: "builder",
        },
      });
    },
    routeToCloudConnector: (orgId) => {
      dispatch({
        type: "PAGE",
        payload: {
          orgId,
          toplevel: "settings",
          secondlevel: "cloudmodules",
        },
      });
    },
    reload: (force) => dispatch(loadPageData(force)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDashboard);
