import moment from "moment-timezone";
import { DATE_FORMAT } from "../api/API";

const DATE = {
  CURRENT_MONTH: {
    timeStart: moment().startOf("month").format(DATE_FORMAT),
    timeEnd: moment().startOf("month").add(1, "months").format(DATE_FORMAT),
  },
  ONE_MONTH_AGO: {
    timeStart: moment()
      .startOf("month")
      .subtract(1, "months")
      .format(DATE_FORMAT),
    timeEnd: moment().startOf("month").format(DATE_FORMAT),
  },
  TWO_MONTHS_AGO: {
    timeStart: moment()
      .startOf("month")
      .subtract(2, "months")
      .format(DATE_FORMAT),
    timeEnd: moment()
      .startOf("month")
      .subtract(1, "months")
      .format(DATE_FORMAT),
  },
  THREE_MONTHS_AGO: {
    timeStart: moment()
      .startOf("month")
      .subtract(3, "months")
      .format(DATE_FORMAT),
    timeEnd: moment()
      .startOf("month")
      .subtract(2, "months")
      .format(DATE_FORMAT),
  },
  FOUR_MONTHS_AGO: {
    timeStart: moment()
      .startOf("month")
      .subtract(4, "months")
      .format(DATE_FORMAT),
    timeEnd: moment()
      .startOf("month")
      .subtract(3, "months")
      .format(DATE_FORMAT),
  },
  FIVE_MONTHS_AGO: {
    timeStart: moment()
      .startOf("month")
      .subtract(5, "months")
      .format(DATE_FORMAT),
    timeEnd: moment()
      .startOf("month")
      .subtract(4, "months")
      .format(DATE_FORMAT),
  },
  LAST_30_DAYS: {
    relativeStart: "30d",
    relativeEnd: null,
  },
  SECOND_TO_LAST_30_DAYS: {
    // @TODO: rename?
    relativeStart: "60d",
    relativeEnd: "30d",
  },
};

export const DATA_QUERIES = {
  DASHBOARD_SCROLLER_STATS: {
    key: "DASHBOARD_SCROLLER_STATS",
    queries: [
      {
        dataQueryId: "100",
        options: {
          relativeStart: DATE.LAST_30_DAYS.relativeStart,
        },
      },
      {
        dataQueryId: "2",
      },
    ],
    data: [],
    visualizationData: {},
    emptyStateVisualizationData: {
      eventsDetectedAndAnalyized: "0",
      suspectsIdentifiedAndFiltered: 0,
      threatsIdentified: 0,
    },
    firstLoadComplete: false,
    dataIsEmpty: false,
    loading: false,
    error: null,
  },
  DASHBOARD_PIE_CHART: {
    key: "DASHBOARD_PIE_CHART",
    queries: [
      {
        dataQueryId: "3",
      },
    ],
    data: [],
    visualizationData: {},
    /* This is used as placeholder data for when the api returns empty for
      this data query. The numbers themselves are random and only serve to make
      the visualization below the 'data not found' message visually appealing.
    */
    emptyStateVisualizationData: {
      priority: [
        {
          name: "suspects:p1",
          value: "p1",
          category: "suspects",
          percent: 0,
          count: 0,
        },
        {
          name: "threats:p1",
          value: "p1",
          category: "threats",
          percent: 43,
          count: 9,
        },
        {
          name: "suspects:p2",
          value: "p2",
          category: "suspects",
          percent: 29,
          count: 6,
        },
        {
          name: "threats:p2",
          value: "p2",
          category: "threats",
          percent: 14,
          count: 3,
        },
        {
          name: "suspects:p3",
          value: "p3",
          category: "suspects",
          percent: 14,
          count: 3,
        },
        {
          name: "threats:p3",
          value: "p3",
          category: "threats",
          percent: 0,
          count: 0,
        },
      ],
      severity: [],
    },
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
  DASHBOARD_WORLD_MAP: {
    key: "DASHBOARD_WORLD_MAP",
    queries: [
      {
        dataQueryId: "4",
      },
      {
        dataQueryId: "5",
      },
    ],
    data: [],
    visualizationData: [],
    emptyStateVisualizationData: [
      {
        name: "Bulgaria",
        code: "BGR",
        count: 8,
      },
      {
        name: "Canada",
        code: "CAN",
        count: 12,
      },
      {
        name: "China",
        code: "CHN",
        count: 20,
      },
      {
        name: "Germany",
        code: "DEU",
        count: 4,
      },
      {
        name: "Iran, Islamic Republic of",
        code: "IRN",
        count: 8,
      },
      {
        name: "Italy",
        code: "ITA",
        count: 8,
      },
      {
        name: "United States",
        code: "USA",
        count: 4,
      },
      {
        name: "Pakistan",
        code: "PAK",
        count: 8,
      },
      {
        name: "Russian Federation",
        code: "RUS",
        count: 8,
      },
      {
        name: "Ukraine",
        code: "UKR",
        count: 4,
      },
      {
        name: "Brazil",
        code: "BRA",
        count: 4,
      },
    ],
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
  DASHBOARD_LOCATIONS_TABLE: {
    key: "DASHBOARD_LOCATIONS_TABLE",
    queries: [
      {
        dataQueryId: "4",
      },
      {
        dataQueryId: "5",
      },
    ],
    data: [],
    visualizationData: [],
    emptyStateVisualizationData: [
      {
        source: "Bulgaria",
        suspects: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
        threats: {
          count: 4,
          p1: 4,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Canada",
        suspects: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
        threats: {
          count: 8,
          p1: 4,
          p2: 4,
          p3: 0,
        },
      },
      {
        source: "China",
        suspects: {
          count: 8,
          p1: 0,
          p2: 4,
          p3: 4,
        },
        threats: {
          count: 12,
          p1: 12,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Germany",
        suspects: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
        threats: {
          count: 0,
          p1: 0,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Iran, Islamic Republic of",
        suspects: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
        threats: {
          count: 4,
          p1: 4,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Italy",
        suspects: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
        threats: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
      },
      {
        source: "United States",
        suspects: {
          count: 4,
          p1: 0,
          p2: 0,
          p3: 4,
        },
        threats: {
          count: 0,
          p1: 0,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Pakistan",
        suspects: {
          count: 0,
          p1: 0,
          p2: 0,
          p3: 0,
        },
        threats: {
          count: 8,
          p1: 8,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Russian Federation",
        suspects: {
          count: 0,
          p1: 0,
          p2: 0,
          p3: 0,
        },
        threats: {
          count: 8,
          p1: 8,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Ukraine",
        suspects: {
          count: 0,
          p1: 0,
          p2: 0,
          p3: 0,
        },
        threats: {
          count: 4,
          p1: 4,
          p2: 0,
          p3: 0,
        },
      },
      {
        source: "Brazil",
        suspects: {
          count: 0,
          p1: 0,
          p2: 0,
          p3: 0,
        },
        threats: {
          count: 4,
          p1: 0,
          p2: 4,
          p3: 0,
        },
      },
    ],
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
  DASHBOARD_RECENT_THREATS_TABLE: {
    key: "DASHBOARD_RECENT_THREATS_TABLE",
    queries: [
      {
        dataQueryId: "12",
      },
    ],
    data: [],
    visualizationData: [],
    emptyStateVisualizationData: [
      {
        name: "DEV 2 threat",
        created: "2018-11-15T12:00:00.000",
        priority: 1,
        status: "Analysis in Progress",
      },
      {
        name: "DEV 9 threat",
        created: "2018-11-15T12:00:00.000",
        priority: 1,
        status: "Open",
      },
      {
        name: "DEV 5 threat",
        created: "2018-11-15T12:00:00.000",
        priority: 1,
        status: "Closed",
      },
      {
        name: "DEV 3 threat",
        created: "2018-11-15T12:00:00.000",
        priority: 2,
        status: "Analysis Complete",
      },
      {
        name: "DEV 2 threat",
        created: "2018-11-08T12:00:00.000",
        priority: 1,
        status: "Analysis in Progress",
      },
    ],
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
  DASHBOARD_FINDINGS_DETECTED_GRAPH: {
    key: "DASHBOARD_FINDINGS_DETECTED_GRAPH",
    queries: [
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: DATE.CURRENT_MONTH.timeStart,
          timeEnd: DATE.CURRENT_MONTH.timeEnd,
          relativeStart: null,
          relativeEnd: null,
        },
      },
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: DATE.ONE_MONTH_AGO.timeStart,
          timeEnd: DATE.ONE_MONTH_AGO.timeEnd,
          relativeStart: null,
          relativeEnd: null,
        },
      },
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: DATE.TWO_MONTHS_AGO.timeStart,
          timeEnd: DATE.TWO_MONTHS_AGO.timeEnd,
          relativeStart: null,
          relativeEnd: null,
        },
      },

      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: DATE.THREE_MONTHS_AGO.timeStart,
          timeEnd: DATE.THREE_MONTHS_AGO.timeEnd,
          relativeStart: null,
          relativeEnd: null,
        },
      },
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: DATE.FOUR_MONTHS_AGO.timeStart,
          timeEnd: DATE.FOUR_MONTHS_AGO.timeEnd,
          relativeStart: null,
          relativeEnd: null,
        },
      },
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: DATE.FIVE_MONTHS_AGO.timeStart,
          timeEnd: DATE.FIVE_MONTHS_AGO.timeEnd,
          relativeStart: null,
          relativeEnd: null,
        },
      },
    ],
    data: [],
    visualizationData: {},
    emptyStateVisualizationData: {
      threats: [
        {
          label: "November",
          data: [
            {
              label: "November",
              p1: 15,
              p2: 5,
              p3: 0,
            },
          ],
        },
        {
          label: "October",
          data: [
            {
              label: "October",
              p1: 9,
              p2: 3,
              p3: 0,
            },
          ],
        },
        {
          label: "August",
          data: [
            {
              label: "August",
              p1: 6,
              p2: 2,
              p3: 0,
            },
          ],
        },
        {
          label: "September",
          data: [
            {
              label: "September",
              p1: 6,
              p2: 2,
              p3: 0,
            },
          ],
        },
        {
          label: "July",
          data: [
            {
              label: "July",
              p1: 3,
              p2: 1,
              p3: 0,
            },
          ],
        },
        {
          label: "June",
          data: [
            {
              label: "June",
              p1: 3,
              p2: 1,
              p3: 0,
            },
          ],
        },
      ],
      suspects: [
        {
          label: "November",
          data: [
            {
              label: "November",
              p1: 0,
              p2: 10,
              p3: 5,
            },
          ],
        },
        {
          label: "October",
          data: [
            {
              label: "October",
              p1: 0,
              p2: 6,
              p3: 3,
            },
          ],
        },
        {
          label: "August",
          data: [
            {
              label: "August",
              p1: 0,
              p2: 4,
              p3: 2,
            },
          ],
        },
        {
          label: "September",
          data: [
            {
              label: "September",
              p1: 0,
              p2: 4,
              p3: 2,
            },
          ],
        },
        {
          label: "July",
          data: [
            {
              label: "July",
              p1: 0,
              p2: 2,
              p3: 1,
            },
          ],
        },
        {
          label: "June",
          data: [
            {
              label: "June",
              p1: 0,
              p2: 2,
              p3: 1,
            },
          ],
        },
      ],
    },
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
  DASHBOARD_FINDINGS_TREND: {
    key: "DASHBOARD_FINDINGS_TREND",
    queries: [
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: null,
          timeEnd: null,
          relativeStart: DATE.LAST_30_DAYS.relativeStart,
          relativeEnd: null,
        },
      },
      {
        dataQueryId: "10",
        options: {
          merge: true,
          timeStart: null,
          timeEnd: null,
          relativeStart: DATE.SECOND_TO_LAST_30_DAYS.relativeStart,
          relativeEnd: DATE.SECOND_TO_LAST_30_DAYS.relativeEnd,
        },
      },
    ],
    data: [],
    visualizationData: {},
    emptyStateVisualizationData: {
      threats: [
        {
          label: "P1",
          value: 12,
          direction: "up",
          color: "red",
        },
        {
          label: "P2",
          value: 4,
          direction: "up",
          color: "red",
        },
        {
          label: "P3",
          value: 0,
          direction: "",
          color: "",
        },
      ],
      suspects: [
        {
          label: "P1",
          value: 0,
          direction: "",
          color: "",
        },
        {
          label: "P2",
          value: 8,
          direction: "down",
          color: "green",
        },
        {
          label: "P3",
          value: 4,
          direction: "up",
          color: "red",
        },
      ],
    },
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
  DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE: {
    key: "DASHBOARD_CURRENT_ACTIVE_FINDINGS_TABLE",
    queries: [
      {
        dataQueryId: "11",
      },
    ],
    data: [],
    visualizationData: [],
    emptyStateVisualizationData: [
      {
        status: "Opened",
        suspects: 12,
        threats: 12,
      },
      {
        status: "Analysis Started",
        suspects: "0",
        threats: 12,
      },
      {
        status: "Analysis Completed",
        suspects: "0",
        threats: 12,
      },
      {
        status: "Response Started",
        suspects: 12,
        threats: "0",
      },
      {
        status: "Total Active",
        suspects: 24,
        threats: 36,
      },
      {
        status: "Resolved",
        suspects: 12,
        threats: 12,
      },
    ],
    loading: false,
    firstLoadComplete: false,
    dataIsEmpty: false,
    error: null,
  },
};

// @XXX: old dataquery names,
// repurpose this to use the current data queries type
export const DATA_QUERY_STUBS = {
  TOTAL_EVENTS_1: {
    status: "OK",
    data: [
      {
        count: 14416038,
      },
    ],
  },
  TOTAL_FINDINGS_PER_TYPE_2: {
    status: "OK",
    data: [
      {
        count: 3,
        type: 10,
      },
      {
        count: 2,
        type: 20,
      },
      {
        count: 4,
        type: 30,
      },
      {
        count: 2,
        type: 40,
      },
    ],
  },
  FINDING_TYPE_PRIORITY_COUNT_3: {
    status: "OK",
    data: [
      {
        count: 1,
        priority: 1,
        type: 10,
      },
      {
        count: 1,
        priority: 2,
        type: 10,
      },
      {
        count: 2,
        priority: 4,
        type: 20,
      },
      {
        count: 3,
        priority: 1,
        type: 30,
      },
      {
        count: 1,
        priority: 2,
        type: 30,
      },
      {
        count: 1,
        priority: 1,
        type: 40,
      },
      {
        count: 1,
        priority: 2,
        type: 40,
      },
    ],
  },
  SUSPECT_SOURCE_COUNTRY_COUNTS_4: {
    status: "OK",
    data: [
      {
        count: 5,
        jsonb_array_elements_src_country: "CA",
      },
      {
        count: 3,
        jsonb_array_elements_src_country: "CN",
      },
      {
        count: 15,
        jsonb_array_elements_src_country: "IT",
      },
      {
        count: 8,
        jsonb_array_elements_src_country: "US",
      },
    ],
  },
  THREAT_SOURCE_COUNTRY_COUNTS_5: {
    status: "OK",
    data: [
      {
        count: 25,
        jsonb_array_elements_src_country: "CN",
      },
      {
        count: 2,
        jsonb_array_elements_src_country: "PK",
      },
      {
        count: 32,
        jsonb_array_elements_src_country: "RU",
      },
      {
        count: 28,
        jsonb_array_elements_src_country: "CA",
      },
      {
        count: 1,
        jsonb_array_elements_src_country: "BG",
      },
      {
        count: 1,
        jsonb_array_elements_src_country: "UA",
      },
      {
        count: 75,
        jsonb_array_elements_src_country: "BR",
      },
      {
        count: 1,
        jsonb_array_elements_src_country: "IR",
      },
      {
        count: 14,
        jsonb_array_elements_src_country: "IT",
      },
    ],
  },
  NOT_CLOSED_THREATS_PER_PRIORITY_6: {
    status: "OK",
    data: [
      {
        count: 2,
        priority: 1,
      },
      {
        count: 1,
        priority: 2,
      },
    ],
  },
  CLOSED_THREATS_PER_PRIORITY_7: {
    status: "OK",
    data: [
      {
        count: 1,
        priority: 1,
      },
    ],
  },
  AVERAGE_TIME_TO_CLOSE_THREATS_8: {
    status: "OK",
    data: [
      {
        m_avg_seconds_to_status: 89896,
      },
    ],
  },
  TOP_N_LONGEST_TO_RESOLVE_9: {
    status: "OK",
    data: [
      {
        created: "2018-10-24T14:38:27.190",
        id: "ffffffff-eeee-5555-0002-000000000005",
        name: "DEV 5 threat",
        resolution: null,
        seconds_to_status: 89896,
        summary: "Oooops, we got pwnd! Again :^(",
      },
    ],
  },
  CREATED_FINDINGS_BY_PRIORITY_AND_TYPE_10: {
    data: [
      {
        count: 3,
        priority: 1,
        type: 10,
      },
      {
        count: 3,
        priority: 2,
        type: 10,
      },
      {
        count: 6,
        priority: 2,
        type: 20,
      },
      {
        count: 3,
        priority: 3,
        type: 20,
      },
      {
        count: 9,
        priority: 1,
        type: 30,
      },
      {
        count: 3,
        priority: 2,
        type: 30,
      },
      {
        count: 3,
        priority: 1,
        type: 40,
      },
      {
        count: 3,
        priority: 2,
        type: 40,
      },
    ],
    status: "OK",
  },
  CURRENT_FINDINGS_BY_TYPE_AND_STATUS_11: {
    data: [
      {
        count: 18,
        status: 0,
        type: 10,
      },
      {
        count: 9,
        status: 0,
        type: 20,
      },
      {
        count: 9,
        status: 30,
        type: 20,
      },
      {
        count: 9,
        status: 40,
        type: 20,
      },
      {
        count: 9,
        status: 0,
        type: 30,
      },
      {
        count: 9,
        status: 10,
        type: 30,
      },
      {
        count: 9,
        status: 20,
        type: 30,
      },
      {
        count: 9,
        status: 40,
        type: 30,
      },
      {
        count: 18,
        status: 0,
        type: 40,
      },
    ],
    status: "OK",
  },
};

export const DATA_QUERY_EMPTY_STUB = {
  status: "OK",
  data: [],
};

// @TODO: GET http://finding/v1.0/constant/finding_type
export const TYPE_TO_NAME_MAPPING = {
  INFORMATION: {
    id: 10,
    name: "Information",
  },
  SUSPECT: {
    id: 20,
    name: "Suspect",
  },
  THREAT: {
    id: 30,
    name: "Threat",
  },
  ALERT: {
    id: 40,
    name: "Alert",
  },
};

// @TODO: GET http://finding/v1.0/constant/status
export const STATUS_TO_NAME_MAPPING = {
  OPEN: {
    id: 0,
    name: "Open",
  },
  ANALYSIS_IN_PROGRESS: {
    id: 10,
    name: "Analysis in Progress",
  },
  ANALYSIS_COMPLETE: {
    id: 20,
    name: "Analysis Complete",
  },
  RESPONSE_IN_PROGRESS: {
    id: 30,
    name: "Response in Progress",
  },
  CLOSED: {
    id: 40,
    name: "Closed",
  },
};
