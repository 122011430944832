import { REQUEST_GET } from "./actions/Request";
import { POLL_STATE, POLL_SUCCESS } from "./actions/Polling";
import { PAGE_LOADING, PAGE_SUCCESS } from "./actions/Page";

const initialState = {
  requests: {},
  pageCache: {},
  polls: {},
};

const RequestReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "ORGS.RESET_ORGS": {
      return initialState;
    }

    // polling
    case POLL_STATE.STARTING: {
      return {
        ...state,
        polls: {
          ...state.polls,
          [action.modelId]: {
            state: action.type,
            route: action.route,
            autoResume: action.autoResume,
            interval: action.interval,
          },
        },
      };
    }
    case POLL_STATE.RUNNING: {
      const polls = { ...state.polls };
      Object.keys(action.update).forEach((modelId) => {
        polls[modelId].state = action.type;
        polls[modelId].hash = action.update[modelId];
      });
      return {
        ...state,
        polls,
      };
    }
    case POLL_STATE.PAUSED: {
      const polls = { ...state.polls };
      Object.keys(action.update).forEach((modelId) => {
        polls[modelId].state = action.type;
      });
      return {
        ...state,
        polls,
      };
    }
    case POLL_STATE.STOPPED: {
      const polls = { ...state.polls };
      Object.keys(action.update).forEach((modelId) => {
        polls[modelId].state = action.type;
      });
      return {
        ...state,
        polls,
      };
    }
    case POLL_SUCCESS: {
      const updatePolls = {};
      action.models.forEach((model) => {
        // loop over every request, and update the model data
        Object.keys(state.requests).forEach((uuid) => {
          const match = state.requests[uuid].response.find(
            (m) => m.id === model.id
          );
          if (match) {
            match.params = model.params;
            match.hash = model.hash;
          }
        });

        updatePolls[model.id] = {
          ...state.polls[model.id],
          hash: action.models[model.id],
        };
      });
      return {
        ...state,
        polls: { ...state.polls, ...updatePolls },
      };
    }

    case REQUEST_GET: {
      const newRequests = {};

      action.requests.forEach((r) => (newRequests[r.uuid] = r));
      return {
        ...state,
        requests: { ...state.requests, ...newRequests },
      };
    }

    case PAGE_LOADING: {
      if (action.responseUUIDs) {
        return {
          ...state,
          pageCache: {
            ...state.pageCache,
            [action.route]: action.responseUUIDs,
          },
        };
      } else {
        return state;
      }
    }
    case PAGE_SUCCESS: {
      // Garbage Collection of expired requests
      const pruneUUIDs = Object.keys(state.requests).filter((rUUID) =>
        state.requests[rUUID].isExpired()
      );

      pruneUUIDs.forEach((rUUID) => {
        delete state.requests[rUUID];
        Object.keys(state.pageCache).forEach((route) => {
          const pruneAlias = [];
          Object.keys(state.pageCache[route]).forEach((alias) => {
            if (state.pageCache[route][alias] === rUUID) {
              pruneAlias.push({ route, alias });
            }
          });
          pruneAlias.forEach((p) => {
            delete state.pageCache[p.route][p.alias];
          });
        });
      });
      return { ...state };
    }
    default:
      return state;
  }
};

export default RequestReducer;
