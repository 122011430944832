import { styled } from "@mui/material/styles";
import { Dialog } from "@mui/material";

const PREFIX = "SAConnectorInfoDialog";
export const classes = {
  titleContainer: `${PREFIX}-titleContainer`,
  loadingContainer: `${PREFIX}-loadingContainer`,
  connectorDetailsOuterContainer: `${PREFIX}-connectorDetailsOuterContainer`,
  connectorDetailContainer: `${PREFIX}-connectorDetailContainer`,
  connectorDetailTitleContainer: `${PREFIX}-connectorDetailTitleContainer`,
  name: `${PREFIX}-name`,
  infoContainer: `${PREFIX}-infoContainer`,
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.titleContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.loadingContainer}`]: {
    height: 200,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  [`& .${classes.connectorDetailContainer}`]: {
    margin: 5,
    width: "100%",
    borderRadius: 10,
    padding: theme.shape.padding,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
  },
  [`& .${classes.connectorDetailsOuterContainer}`]: {
    display: "flex",
    paddingBottom: 20,
  },
  [`& .${classes.connectorDetailTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${classes.name}`]: {
    fontSize: 18,
    marginBottom: 0,
    fontWeight: "bold",
  },
  [`& .${classes.infoContainer}`]: {
    padding: theme.shape.padding,
  },
}));
