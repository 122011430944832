import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Alert, AlertTitle } from "@mui/material";

const defaultErrorMessage =
  'Oops, we had trouble with that request. Try refreshing the page or contact us via the "Help" icon.';

export const MuiErrorBanner = (props) => {
  const { error } = props;

  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if (error) {
      let { message } = error;
      // a message should always exist, but let's be safe
      message = message ? message : defaultErrorMessage;
      setErrorMessage(message);
    }
  }, [error]);

  // if we have nothing to display, or a user
  // exits the alert we should return null
  if (!errorMessage) return null;

  return (
    <Alert severity="error" onClose={() => setErrorMessage(false)}>
      <AlertTitle>Error</AlertTitle>
      {errorMessage}
    </Alert>
  );
};

const mapStateToProps = (state) => {
  const { global } = state;
  const { error } = global;
  return { error };
};

export default connect(mapStateToProps, null)(MuiErrorBanner);
