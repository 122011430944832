import { Blocklist } from "./Blocklist";
import { BlocklistEntry } from "./BlocklistEntry";
import { Category } from "./Category";
import { Column } from "./Column";
import { Constants } from "./Constants";
//import { DataQuery } from './DataQuery';
import { Finding } from "./Finding";
import { Label } from "./Label";
import { Location } from "./Location";
import { Logtype } from "./Logtype";
import { Message } from "./Message";
import { Org } from "./Org";
import { OrgLogType } from "./OrgLogType";
import { QueryResult } from "./QueryResult"; // deprecate?
import { Report } from "./Report";
import { Rule } from "./Rule";
import { AllowlistEntry } from "./AllowlistEntry";
import { Condition } from "./Condition";
import { ConditionOverride } from "./ConditionOverride";
import { Subnet } from "./Subnet";
import { User } from "./User";
import { Query } from "./Query";
import { BQ } from "./BQ";
import { BQSummary } from "./BQSummary";
import { Integration } from "./Integration";
import { IntegrationSchema } from "./IntegrationSchema";
import { WorkflowStep } from "./WorkflowStep";
import { OrgColumnStats } from "./OrgColumnStats";
import { Action } from "./Action";
import { FindingType } from "./FindingType";
import { Match } from "./Match";
import { Transmission } from "./Transmission";
import { SSOConnection } from "./SSOConnection";
import { LCAgent } from "./LCAgent";
import { LCGroup } from "./LCGroup";
import { FindingExport } from "./FindingExport";
import { MSPBillingPlan } from "./MSPBillingPlan";
import { MSPBillingPlanTemplate } from "./MSPBillingPlanTemplate";
import { UsageReport } from "./UsageReport";
import { MSPBillingPlanRamp } from "./MSPBillingPlanRamp";
import { SubaccountBillingHistory } from "./SubaccountBillingHistory";
import { PSAIntegration } from "./PSAIntegration";
import { PSACompanyMappingPSA } from "./PSACompanyMappingPSA";
import { PSAPriorityMappingPSA } from "./PSAPriorityMappingPSA";
import { PSAStatusMappingPSA } from "./PSAStatusMappingPSA";
import { PSATypeMappingPSA } from "./PSATypeMappingPSA";
import { PSAIntegrationTest } from "./PSAIntegrationTest";
import { PSASimulateFinding } from "./PSASimulateFinding";

const ModelMap = {
  [new Blocklist().schema]: Blocklist,
  [new BlocklistEntry().schema]: BlocklistEntry,
  [new Category().schema]: Category,
  [new Column().schema]: Column,
  [new Constants().schema]: Constants,
  //[new DataQuery().schema]: DataQuery,
  [new Finding().schema]: Finding,
  [new Label().schema]: Label,
  [new Location().schema]: Location,
  [new Logtype().schema]: Logtype,
  [new Message().schema]: Message,
  [new Org().schema]: Org,
  [new OrgLogType().schema]: OrgLogType,
  [new QueryResult().schema]: QueryResult,
  [new Report().schema]: Report,
  [new Rule().schema]: Rule,
  [new AllowlistEntry().schema]: AllowlistEntry,
  [new Condition().schema]: Condition,
  [new ConditionOverride().schema]: ConditionOverride,
  [new Subnet().schema]: Subnet,
  [new User().schema]: User,
  [new Query().schema]: Query,
  [new BQ().schema]: BQ,
  [new BQSummary().schema]: BQSummary,
  [new Integration().schema]: Integration,
  [new IntegrationSchema().schema]: IntegrationSchema,
  [new WorkflowStep().schema]: WorkflowStep,
  [new OrgColumnStats().schema]: OrgColumnStats,
  [new Action().schema]: Action,
  [new FindingType().schema]: FindingType,
  [new Match().schema]: Match,
  [new Transmission().schema]: Transmission,
  [new LCAgent().schema]: LCAgent,
  [new LCGroup().schema]: LCGroup,
  [new SSOConnection().schema]: SSOConnection,
  [new FindingExport().schema]: FindingExport,
  [new MSPBillingPlan().schema]: MSPBillingPlan,
  [new MSPBillingPlanTemplate().schema]: MSPBillingPlanTemplate,
  [new UsageReport().schema]: UsageReport,
  [new MSPBillingPlanRamp().schema]: MSPBillingPlanRamp,
  [new SubaccountBillingHistory().schema]: SubaccountBillingHistory,
  [new PSAIntegration().schema]: PSAIntegration,
  [new PSACompanyMappingPSA().schema]: PSACompanyMappingPSA,
  [new PSAPriorityMappingPSA().schema]: PSAPriorityMappingPSA,
  [new PSAStatusMappingPSA().schema]: PSAStatusMappingPSA,
  [new PSATypeMappingPSA().schema]: PSATypeMappingPSA,
  [new PSAIntegrationTest().schema]: PSAIntegrationTest,
  [new PSASimulateFinding().schema]: PSASimulateFinding,
};

export default ModelMap;
