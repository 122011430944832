import { handleApiError } from "../utils/helpers";
import Request from "../../lib/api/Request";

import { actionStart, actionSuccess, actionFailure } from "./Page";

export const REQUEST_LOCATIONS = "LOCATIONS.REQUEST_LOCATIONS";
export const RECEIVE_LOCATIONS = "LOCATIONS.RECEIVE_LOCATIONS";
export const RESET_LOCATIONS = "LOCATIONS.RESET";

export const resetLocations = {
  type: RESET_LOCATIONS,
};

/*
Fetches the locations for the current org
*/
export const fetchLocationsForOrg = (orgId) => (dispatch) => {
  dispatch({ type: REQUEST_LOCATIONS });
  const queryParams = [];
  if (orgId) {
    queryParams.push({ field: "orgId", value: orgId });
  }
  const request = new Request("/location", queryParams);
  return request
    .get()
    .then((locations) =>
      dispatch({
        type: RECEIVE_LOCATIONS,
        locations,
      })
    )
    .catch((err) => {
      const message = "Failed to load locations";
      dispatch(
        handleApiError({
          message,
          err,
          onError: actionFailure,
        })
      );
    });
};

export const createLocation = (location) => (dispatch) => {
  location
    .create()
    .then(() => dispatch(fetchLocationsForOrg()))
    .catch((err) => {
      const message = "Failed to create location";
      dispatch(
        handleApiError({
          message,
          err,
          onError: actionFailure,
        })
      );
    });
};

/*
Fetches the locations for the current org and dispatches 'page' actions,
*/
export const fetchLocationsForOrgWithPageActions = (orgId) => (dispatch) => {
  dispatch(actionStart);

  return dispatch(fetchLocationsForOrg(orgId))
    .then(() => {
      dispatch(actionSuccess);
    })
    .catch((err) => {
      // Dispatching error to the 'page' store
      dispatch(
        handleApiError({
          message: "Failed to load.",
          err,
          onError: actionFailure,
        })
      );
    });
};
