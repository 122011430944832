import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Request from "lib/api/Request";

import { get } from "lodash";

import AddCircleIcon from "@mui/icons-material/AddCircle";

import SimpleTable from "views/Components/SimpleTable";
import { defaultRole } from "../../Components/MSP/helpers";
import MSPDetailDialog from "../../Components/MSP/MSPDetailDialog";

import { User } from "lib/models/User";

import { loadPageData } from "redux/actions/Request";

import { getChildOrgs } from "./utils";

import { createUser, updateUser, addUserToOrg } from "redux/actions/Users";

const renderUserChildOrganizationCell =
  (orgId = "") =>
  (column, model) =>
    model[column.field].filter((obj) => obj.parent_id === orgId).length;

const MSPUsersPageView = ({
  ready,
  orgId,
  reload,
  editUser,
  addNewUser,
  currentUser,
  addExistingUser,
  childOrganizationsList,
}) => {
  const [users, setUsers] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedUserModel, setSelectedUserModel] = useState(false);
  const [roleSelectionArray, setRoleSelectionArray] = useState([defaultRole]);
  const [isUserDetailDialogOpen, setIsUserDetailDialogOpen] = useState(false);
  const [creationStep, setCreationStep] = useState(0);

  const columns = [
    {
      title: "Email address",
      field: "email",
      searchable: true,
    },
    {
      title: "Accounts",
      field: "orgs",
      renderValue: renderUserChildOrganizationCell(orgId),
    },
  ];

  const loadUsers = () => {
    setUsersLoading(true);
    // Can't use redux fetchUsersForOrg because it tramples over axios headers
    const request = new Request("/user");
    request
      .get()
      .then((allUsers) =>
        allUsers.filter(
          (user) => user.orgs.filter((o) => o.id === orgId).length > 0
        )
      )
      .then((filteredUsers) => {
        setUsers(filteredUsers);
        setUsersLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
  }, [orgId]);

  const handleTableRowClick = (userModel) => {
    setSelectedUserModel(userModel);
    toggleUserDetailDialog(userModel);
  };

  const toggleUserDetailDialog = (userModel) => {
    if (!isUserDetailDialogOpen) {
      //the dialog is not open, so we're opening it here
      //this seems like a good place to set roleSelectionArray to this currently selected user's org roles
      const userRolesIds = userModel?.orgRoles?.map((role) => role.roleId);
      const userRolesDistinct = [...new Set(userRolesIds)];
      setRoleSelectionArray(userRolesDistinct);
    }
    setErrorMessage(false);
    setIsUserDetailDialogOpen(!isUserDetailDialogOpen);
  };

  const handleAddNewUser = (event) => {
    setSelectedUserModel(new User());
    toggleUserDetailDialog();
  };

  const handleSubmit = (model) => {
    setIsSaving(true);

    // filter the selected user's current orgs to only the selected org and any corresponding child orgs
    const parentChildOrgs =
      model.orgs
        ?.filter((org) => org.id === orgId || org.parent_id === orgId)
        .map((org) => org.id) || [];

    // now look for the selected user's current org roles
    // if any exist, filter out any roles that are not one of the parent/child orgs
    // also filter out any roles that are not Administrator (10) or Responder (40)
    // (since those are the only 2 roles that the MSP Portal cares about/utilizes)
    const existingOrgRolesToUpdate =
      model.orgRoles
        ?.filter(
          (role) =>
            parentChildOrgs.includes(role.orgId) &&
            (role.roleId === 10 || role.roleId === 40)
        )
        .map((role) => role.orgId) || [];

    // multiple roles can exist for each org
    // but all we need here is the list of orgs
    // so get just a distinct list
    const existingOrgRolesToUpdateDistinct = [
      ...new Set(existingOrgRolesToUpdate),
    ];

    // grab any roles from other orgs or non-Admin, non-Respoder roles
    // to append back on below
    // this will also be any new orgs when/if adding a new user
    const newOrUnrelatedExistingOrgs =
      model.orgRoles?.filter(
        (role) =>
          !parentChildOrgs.includes(role.orgId) ||
          (role.roleId !== 10 && role.roleId !== 40)
      ) || [];

    // convert newOrUnrelatedExistingOrgs from camelCase to snake_case?
    const newOrUnrelatedExistingOrgsCamelCase = newOrUnrelatedExistingOrgs.map(
      (org) => ({ org_id: org.orgId, role_id: parseInt(org.roleId) })
    );

    // make the necessary role changes
    // based on the checkboxes selected/unselected
    let newOrgRoles = existingOrgRolesToUpdateDistinct
      .map((orgId) => {
        return roleSelectionArray
          .filter((roleId) => roleId === 10 || roleId === 40)
          .map((roleId) => ({ org_id: orgId, role_id: parseInt(roleId) }));
      })
      .flat();

    // throw the other untouched orgs back into the array
    newOrgRoles = newOrgRoles.concat(newOrUnrelatedExistingOrgsCamelCase);

    // double check if role is present for
    // parent org and if not add to the list
    const containsParentOrg = newOrgRoles.find(
      ({ org_id }) => org_id === orgId
    );

    // ensure this user is admitted access as a
    // responder to the parent organization to enable
    // customization from the NFR account if desired
    if (!containsParentOrg) {
      const parentResponderAccountRole = {
        role_id: 40,
        org_id: orgId,
      };

      newOrgRoles = newOrgRoles.concat([parentResponderAccountRole]);
    }

    const userJSON = {
      email: model.email,
      org_roles: newOrgRoles,
      last_name: model.lastName,
      first_name: model.firstName,
      config_alerts: model.configAlerts,
    };

    if (model.id) {
      // edit existing
      // ensure reducer updates properly when
      // a user is editing their own properties
      const editingSelf = model.id === currentUser.id;

      const orgIds = existingOrgRolesToUpdateDistinct;

      editUser(orgIds, model.id, userJSON, editingSelf)
        .then(() => {
          // FEA expects roles to be camelCase
          // so let's set those up here
          const orgRolesForUpdating = newOrgRoles.map(
            ({ org_id, role_id }) => ({ orgId: org_id, roleId: role_id })
          );
          return addExistingUser(orgId, model.email, orgRolesForUpdating);
        })
        .then(() => {
          loadUsers();
          toggleUserDetailDialog();
          setSelectedUserModel(false);
          setIsSaving(false);
        })
        .catch((err) => {
          setErrorMessage(err.toString());
          setIsSaving(false);
        });
    } else {
      // create new
      roleSelectionArray.forEach((roleId) => {
        userJSON.org_roles.push({ org_id: orgId, role_id: parseInt(roleId) });
      });

      addNewUser(orgId, userJSON)
        .then(() => {
          loadUsers();
          setCreationStep(0);
          setSelectedUserModel(false);
          toggleUserDetailDialog();
          setIsSaving(false);
        })
        .catch((err) => {
          setErrorMessage(err.toString());
          setIsSaving(false);
        });
    }
  };

  // for the second step of creating a user
  // user selects accounts to be enrolled in
  const handleSelectOrg = (selectedOrgIds) => {
    // map over all selected orgs and the corresponding
    // role selection to create new role array on user
    let newOrgRoles = selectedOrgIds
      .map((orgId) => {
        return roleSelectionArray.map((roleId) => ({
          orgId: orgId,
          roleId: parseInt(roleId),
        }));
      })
      .flat();

    selectedUserModel.set({
      orgRoles: newOrgRoles,
    });
  };

  const handleChange = (value, name) => {
    // this is to handle nested props
    // i.e. config.license on org model
    let names = name.split(".");

    // handle config alerts
    // TODO: this feels messy but it is a
    // heavily nested property on the user
    if (names.find((name) => name === "configAlerts")) {
      let contactDetails = selectedUserModel?.configAlerts?.contact_details
        ? selectedUserModel.configAlerts.contact_details
        : {};
      selectedUserModel.set({
        configAlerts: {
          ...selectedUserModel.configAlerts, // do not overwrite config alerts
          contact_details: {
            ...contactDetails, // do not overwrite contact details
            [names[2]]: value,
          },
        },
      });
    }
    // handle role selection
    if (name === "roleSelection") {
      let newRoleSelectionArray = roleSelectionArray.find(
        (roleValue) => roleValue === value
      )
        ? [...roleSelectionArray].filter((roleValue) => roleValue !== value)
        : [...roleSelectionArray].concat([value]);

      setRoleSelectionArray(newRoleSelectionArray);
    } else selectedUserModel.set({ [name]: value });
  };

  return (
    <div className="mspportal users-page">
      <SimpleTable
        isNorthStar
        title={"Users"}
        rowClick={handleTableRowClick}
        columns={columns}
        isFetching={!ready || usersLoading}
        data={users}
        initialOrderBy={["email", "asc"]}
        toolbarActions={[
          {
            icon: AddCircleIcon,
            tooltip: "Add user",
            onClick: handleAddNewUser,
          },
        ]}
      />
      <MSPDetailDialog
        context={"user"}
        isSaving={isSaving}
        model={selectedUserModel}
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        open={isUserDetailDialogOpen}
        toggle={toggleUserDetailDialog}
        tableData={childOrganizationsList}
        handleSelectTableItem={handleSelectOrg}
        rolesBoxesChecked={roleSelectionArray}
        orgId={orgId}
        reloadData={loadUsers}
        creationStep={creationStep}
        setCreationStep={setCreationStep}
      />
    </div>
  );
};

MSPUsersPageView.propTypes = {
  ready: PropTypes.bool,
  orgId: PropTypes.string.isRequired,
  childOrganizationsList: PropTypes.arrayOf(PropTypes.shape({})),
};

MSPUsersPageView.defaultProps = {
  ready: false,
  childOrganizationsList: [],
};

const mapStateToProps = (state) => {
  const { session, location } = state;
  const { orgId: mspOrgId } = location.payload;

  const orgListToFilter = get(session, `settings.userOrgs`, []);

  // getChildOrgs expects a a list of orgs and an
  // org id to filter and returns an array of orgs
  const childOrgsList = getChildOrgs(orgListToFilter, mspOrgId);

  return {
    ready: state.page.ready,
    childOrganizationsList: childOrgsList,
    currentUser: state.session?.settings?.user,
    orgId: state.location.payload.id1 ?? state.location.payload.orgId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reload: (force) => dispatch(loadPageData(force)),
    addNewUser: (orgId, data) => dispatch(createUser({ orgId, data })),
    addExistingUser: (orgId, email, roles) =>
      dispatch(addUserToOrg({ orgId, email, roles })),
    editUser: (orgId, personId, data, editingSelf) =>
      dispatch(updateUser({ orgId, personId, data, editingSelf })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MSPUsersPageView);
