import React from "react";

import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { StyledDialog, classes } from "./activationConfirmationDialogStyles";

export const ActivationConfirmationDialog = (props) => {
  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Please confirm activation request</DialogTitle>
      <DialogContent>
        {props.wasEmailSuccessful ? (
          <div>
            <p>
              <strong>Success!</strong>
            </p>
            <p>
              An email was sent to our sales team to enable the Blumira Agent in
              your account.
            </p>
            <p>Thank you!</p>
          </div>
        ) : (
          <ul className={classes.unorderedList}>
            <li>
              Please allow up to 1 business day for Blumira to activate the
              add-on in your account.
            </li>
            <li>
              Blumira Agent is a paid add-on.
              {/* 
                                FYI: hiding this link until GA
                                <a 
                                    target={'_blank'} 
                                    href={pricingUrl}
                                    rel={'noopener noreferrer'} 
                                    className={classes.leftMarginLink}
                                >
                                    Pricing <OpenInNewIcon/>
                                </a> 
                            */}
            </li>
          </ul>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant={"text"} onClick={props.onClose} color={"primary"}>
          {props.wasEmailSuccessful ? "Okay" : "Cancel"}
        </Button>
        {!props.wasEmailSuccessful && (
          <Button
            onClick={props.handleActivateDevicesFeature}
            variant={"contained"}
            color={"primary"}
          >
            Activate
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};
