// *****************************************************************************
// Dependencies
// *****************************************************************************
import styled from "styled-components";

// *****************************************************************************
// Styles
// *****************************************************************************

// ** Button style ********************
export const ButtonStyle = styled.button`
    align-items: center;
    background-color: ${(p) => p.bgColor};
    border: ${(p) => p.border};
    border-radius: 3px;
    color: ${(p) => p.color};
    cursor: pointer;
    display: flex;
    fill: ${(p) => p.fill};
    flex-direction: row;
    flex-shrink: 0;
    font-size: ${(p) => (p.fontSize ? p.fontSize : "12px")};
    font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};;
    justify-content: center;
    margin: ${(p) => p.margin};
    outline: none !important;
    padding: ${(p) => (p.padding ? p.padding : "6px 12px")}
    transition: all 0.3s ease;
    width: ${(p) => (p.width ? p.width : "auto")};

    @media (min-width: 769px) {
        &:hover {
            background-color: ${(p) => p.bgColorHover};
            border: ${(p) => p.borderHover};
            color: ${(p) => p.colorHover};
            fill: ${(p) => p.fillHover};
        }
    }

    &:disabled {
        background-color: ${(p) => p.bgColor};
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
