import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { connectRoutes } from "redux-first-router";
import { composeWithDevTools } from "redux-devtools-extension";
import restoreScroll from "redux-first-router-restore-scroll";
import options from "./reduxFirstRouterOptions";
import routesMap from "../routes";
import appMiddleware from "./middleware";
import appReducers from "./reducers";
import { isDevMode } from "../lib/helpers";

export default (
  history,
  preloadedState,
  routes = routesMap,
  unitTestOptions = {}
) => {
  const {
    reducer: routerReducer,
    middleware: routerMiddleware,
    enhancer: routerEnhancer,
  } = connectRoutes(routes, {
    ...options,
    restoreScroll: restoreScroll(),
    ...unitTestOptions,
  });

  const appliedMiddlewares = applyMiddleware(
    routerMiddleware,
    ...appMiddleware
  );

  // Choose a different composer for dev mode to get the Redux tools working in Chrome
  const composition = isDevMode() ? composeWithDevTools : compose;
  const enhancers = composition(routerEnhancer, appliedMiddlewares);

  const reducers = combineReducers({
    location: routerReducer,
    ...appReducers,
  });

  const rootReducer = (state, action) => reducers(state, action);

  const store = createStore(rootReducer, preloadedState, enhancers);

  return { store };
};
