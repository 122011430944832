// *****************************************************************************
// Dependencies
// *****************************************************************************
import React, { Component } from "react";
import { redirect } from "redux-first-router";
import Moment from "moment";
import { func, shape, string } from "prop-types";

// ** Layouts *************************
import { RowList } from "views/Layouts/List";

// ** Lib *****************************
import { encodeObj } from "utils/lib/paramEncoding";
import { addAggregateAndConfig, addSearch } from "views/Pages/Query/QueryUtils";

// ** Utils ***************************
import withProps from "utils/lib/withProps";

// ** Style ***************************
import {
  Container,
  Date,
  Description,
  HeaderContainer,
  Tag,
  Title,
} from "./style";

// *****************************************************************************
// Feature
// *****************************************************************************
//
//  Search feature
//
//  ** Remarks
//  Simple search component extended to simplify code across the app
//
//  ** Props
//  @param dispatch {func} - func invoked on click
//  @param orgId {string} - current organization
//  @param search {obj} - search data
//
class Search extends Component {
  onSubmit() {
    const { dispatch, orgId, search, onToggleModal } = this.props;
    const queryObj = {
      dataSource: search.dataSource,
      orgId,
      selectedQueryId: search.id,
    };

    const DEFAULT_COLUMNS = {
      finding: [
        "created",
        "short_id",
        "type",
        "priority",
        "name",
        "analysis",
        "jurisdiction",
        "status",
      ],
      bq: [
        "timestamp",
        "devname",
        "device_address",
        "src_ip",
        "dst_ip",
        "type",
      ],
    };

    const filteredableAgg = search.aggregate.slice();
    const hasNonCountAgg =
      search.aggregators.length > 1 ||
      (search.aggregators.length === 1 &&
        search.aggregators.indexOf("count(*)") < 0);
    const selectedCols =
      search.aggregate.length === 0
        ? DEFAULT_COLUMNS[search.dataSource]
        : filteredableAgg.filter((col) => col.indexOf(") as") < 0);

    // Configure search filters
    addSearch({
      filters: search.parseSearch(),
      hasAdvancedFilters: search.hasAdvancedFilters,
      query: queryObj,
      selectedQueryId: search.id,
      selectedSavedQuery: search,
    });

    // Data config
    addAggregateAndConfig({
      aggregators: search.aggregators,
      dataSource: search.dataSource,
      endTime: search.configuration.timeEnd || this.renderTime("end"),
      hasNonCountAggregators: hasNonCountAgg,
      isAggregateData: search.aggregators.indexOf("count(*)") > -1,
      query: queryObj,
      selectedColumns: selectedCols,
      selectedQueryId: search.id,
      selectedSavedQuery: search,
      startTime: search.configuration.timeStart || this.renderTime("start"),
    });

    // Add timeformat and encode data for routing
    queryObj.reportName = search.name;
    const encodedData = encodeObj(queryObj);

    dispatch(
      redirect({
        type: "PAGE",
        payload: {
          orgId,
          toplevel: "reporting",
          secondlevel: "results",
        },
        query: {
          data: encodedData,
        },
      })
    );
    if (onToggleModal) {
      onToggleModal();
    }
  }

  renderTime(type) {
    const { search } = this.props;
    const relStart = search.configuration.relativeStart;

    if (type === "end") {
      if (search.configuration.timeEnd) {
        return Moment(search.configuration.timeEnd);
      }

      return Moment();
    }

    if (search.configuration.timeStart) {
      return Moment(search.configuration.timeEnd);
    }

    const timeUnit = relStart[relStart.length - 1];
    const time = parseInt(relStart.slice(0, relStart.length - 1), 10);

    return Moment().subtract(time, timeUnit);
  }

  render() {
    const { search } = this.props;

    return (
      <Container onClick={this.onSubmit.bind(this)}>
        <HeaderContainer>
          <Title>{search.name}</Title>
          <Date>{search.date && search.date}</Date>
        </HeaderContainer>
        {search.description && <Description>{search.description}</Description>}
        <RowList itemMargin="0px 8px 0px 0px" itemPadding="12px 0px 0px">
          {search.aggregate &&
            search.aggregate.map((tag, i) => <Tag key={i}>{tag}</Tag>)}
          {search.search &&
            search
              .parseSearch()
              .map((filter) => (
                <Tag
                  key={filter.fieldName + filter.fieldValue}
                >{`${filter.fieldName} ${filter.opcode} ${filter.fieldValue}`}</Tag>
              ))}
        </RowList>
      </Container>
    );
  }
}

// ** Proptypes ***********************
Search.propTypes = {
  dispatch: func.isRequired,
  orgId: string.isRequired,
  search: shape().isRequired,
};

// *****************************************************************************
// Extensions
// *****************************************************************************

// ** Default search *******************
export const DefaultSearch = withProps((props) => ({
  dispatch: props.dispatch,
  orgId: props.orgId,
  search: props.search,
}))(Search);
