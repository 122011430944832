import React from "react";

import logo from "../../images/blumira-web-logo-01.png";
import background from "../../images/background-head_blank-2.png";

const NotFoundView = () => (
  <div className="h-100 w-100">
    <div
      className="h-50 w-100"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="h-100 w-100 row align-items-center">
        <div
          className="col align-items-center mx-auto"
          style={{ textAlign: "center" }}
        >
          <img
            className="mx-auto"
            style={{ width: "40%", minWidth: "300px" }}
            src={logo}
            alt="Logo"
            title="Blumira"
          />
        </div>
      </div>
    </div>
    <div className="h-50 w-100">
      <div
        style={{ textAlign: "center" }}
        className="h-100 w-50 row mx-auto align-items-center"
      >
        <p className="h1 mx-auto">Not Found</p>
      </div>
    </div>
  </div>
);

NotFoundView.propTypes = {};

NotFoundView.defaultProps = {};

export default NotFoundView;
