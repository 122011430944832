import {
  RECEIVE_SETTINGS,
  RESET_SETTINGS,
  RECEIVE_USER,
} from "./actions/Session";
import { RECEIVE_ORGS } from "./actions/Org";
import { sortByName } from "../lib/helpers";

const initialState = {};

const SessionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RECEIVE_SETTINGS: {
      return {
        ...state,
        settings: action.settings,
      };
    }
    case RESET_SETTINGS: {
      return { ...initialState };
    }
    case RECEIVE_USER: {
      return {
        ...state,
        settings: {
          ...state.settings,
          user: action.user,
        },
      };
    }
    case RECEIVE_ORGS: {
      let newUserOrgs = action.orgs;
      let existingUserOrgs = state.settings.userOrgs;

      // if x-blu-orgid is set, getOrgs will only fetch one org, we should update the
      // list of orgs inside the user's session instead of blowing it out and replacing it
      if (
        !!existingUserOrgs.length &&
        newUserOrgs.length !== existingUserOrgs.length
      ) {
        let existingOrg = existingUserOrgs.find(
          (o) => o.id === newUserOrgs[0].id
        );
        let index = existingUserOrgs.indexOf(existingOrg);
        existingUserOrgs[index] = newUserOrgs[0];
        newUserOrgs = existingUserOrgs;
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          userOrgs: newUserOrgs.sort(sortByName),
        },
      };
    }
    default:
      return state;
  }
};

export default SessionReducer;
