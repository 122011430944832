import { alpha, styled } from "@mui/material/styles";

import Menu from "@mui/material/Menu";

const PREFIX = "SimpleTable";
export const rootClasses = {
  actionsContainer: `${PREFIX}-actionsContainer`,
  primaryActionsContainer: `${PREFIX}-primaryActionsContainer`,
  primaryActionContainer: `${PREFIX}-primaryActionContainer`,
  searchFieldContainer: `${PREFIX}-searchFieldContainer`,
  toolbarActionButton: `${PREFIX}-toolbarActionButton`,
  multiSelectButton: `${PREFIX}-multiSelectButton`,
  multiSelectSpacer: `${PREFIX}-multiSelectSpacer`,
  multiSelectAlert: `${PREFIX}-multiSelectAlert`,
  multiSelectCloseButton: `${PREFIX}-multiSelectCloseButton`,
  alignRightAction: `${PREFIX}-alignRightAction`,
  paginationContainer: `${PREFIX}-paginationContainer`,
  multiselectContainer: `${PREFIX}-multiselectContainer`,
  multiselectContainerV2: `${PREFIX}-multiselectContainerV2`,
  multiSelectionText: `${PREFIX}-multiSelectionText`,
  multiSelectionNumber: `${PREFIX}-multiSelectionNumber`,
  table: `${PREFIX}-table`,
  tableRow: `${PREFIX}-tableRow`,
  tableRowEmpty: `${PREFIX}-tableRowEmpty`,
  tableRowInteractive: `${PREFIX}-tableRowInteractive`,
  actions: `${PREFIX}-actions`,
  tableSortLabel: `${PREFIX}-tableSortLabel`,
  visuallyHidden: `${PREFIX}-visuallyHidden`,
  titleBar: `${PREFIX}-titleBar`,
  toolBar: `${PREFIX}-toolBar`,
  toolBarSearchOnly: `${PREFIX}-toolBarSearchOnly`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellEmpty: `${PREFIX}-tableCellEmpty`,
  tableCellAction: `${PREFIX}-tableCellAction`,
  tableCellHasMenu: `${PREFIX}-tableCellHasMenu`,
  newSearchField: `${PREFIX}-newSearchField`,
  simpleTableTitleContainer: `${PREFIX}-simpleTableTitleContainer`,
  simpleTableTitle: `${PREFIX}-simpleTableTitle`,
  titleTooltipIcon: `${PREFIX}-titleTooltipIcon`,
  tooltip: `${PREFIX}-tooltip`,
  paginationText: `${PREFIX}-paginationText`,
};

export const contextMenuClasses = {
  linkLi: `${PREFIX}-linkLi`,
  tooltip: `${PREFIX}-tooltip`,
  disabledMenuItem: `${PREFIX}-disabledMenuItem`,
  buttonLi: `${PREFIX}-buttonLi`,
  menuItemButton: `${PREFIX}-menuItemButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${rootClasses.actionsContainer}`]: {
    display: "flex",
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${rootClasses.primaryActionsContainer}`]: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    "@media (max-width: 1120px)": {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  [`& .${rootClasses.primaryActionContainer}`]: {
    "@media (max-width: 1120px)": {
      marginTop: -15,
      marginBottom: 30,
    },
  },
  [`& .${rootClasses.searchFieldContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${rootClasses.toolbarActionButton}`]: {
    margin: "0 10px",
    fontWeight: "bold",
    padding: "9px 18px",
    textTransform: "none",
    color: theme.palette.text.primary,
    "&.Mui-disabled": {
      color: theme.palette.action.disabled,
    },
  },
  [`& .${rootClasses.multiSelectButton}`]: {
    zIndex: 999,
  },
  [`& .${rootClasses.multiSelectAlert}`]: {
    height: 30,
    marginLeft: 10,
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.info.main,
    "&.attention": {
      animation: "scale-shake 1s linear",
    },
    "& span": {
      color: theme.palette.primary.main,
    },
  },
  [`& .${rootClasses.alignRightAction}`]: {
    position: "absolute",
    right: 0,
  },
  [`& .${rootClasses.paginationContainer}`]: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  [`& .${rootClasses.multiselectContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${rootClasses.multiselectContainerV2}`]: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.background.multiselectControlBar,
    borderRadius: 20,
    width: "100%",
    padding: 2,
    [`& .${rootClasses.multiSelectionText}`]: {
      color: theme.palette.text.multiselectControlBar,
    },
    [`& .${rootClasses.multiSelectionNumber}`]: {
      fontWeight: "bold",
    },
    [`& .${rootClasses.multiSelectButton}`]: {
      // Select All button
      color: theme.palette.text.multiselectControlBar,
      borderWidth: 1,
      borderColor: theme.palette.text.multiselectControlBar,
      borderStyle: "solid",
      borderRadius: 15,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 15,
      paddingRight: 15,
      marginLeft: 16,
      marginRight: -8, // Added to width of multiSelectSpacer
      ["&:hover"]: {
        backgroundColor: alpha(theme.palette.text.multiselectControlBar, 0.08),
      },
    },
    [`& .${rootClasses.multiSelectSpacer}`]: {
      // Space to the left of the action buttons
      width: 16,
    },
    [`& .${rootClasses.toolbarActionButton}`]: {
      // Toolbar action buttons have reversed colors
      color: theme.palette.background.multiselectControlBar,
      backgroundColor: theme.palette.text.multiselectControlBar,
      // Border is the same color as the backgroundColor, but it makes
      // the two kinds of buttons line up better.
      borderWidth: 1,
      borderColor: theme.palette.text.multiselectControlBar,
      borderStyle: "solid",
      borderRadius: 15,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 8,
      marginLeft: 0,
      ["&:disabled"]: {
        color: alpha(theme.palette.background.multiselectControlBar, 0.5),
      },
    },
    [`& .${rootClasses.multiSelectCloseButton}`]: {
      color: theme.palette.text.multiselectControlBar,
      marginLeft: 8,
      marginRight: 8,
      marginTop: 4,
      marginBottom: 4,
      padding: 0,
      ["&:hover"]: {
        backgroundColor: alpha(theme.palette.text.multiselectControlBar, 0.08),
      },
    },
  },
  [`& .${rootClasses.multiSelectionText}`]: {
    fontSize: "0.875rem",
    margin: `0 !important`,
  },
  [`& .${rootClasses.table}`]: {
    minWidth: 450,
    borderRadius: 5,
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.tableRowInteractive}`]: {
    cursor: "pointer",
  },
  [`& .${rootClasses.tableRowEmpty}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.actions}`]: {
    padding: theme.spacing(0.5),
  },
  [`& .${rootClasses.tableSortLabel}`]: {
    fontWeight: "bold",
    "&:focus": {
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 2,
    },
  },
  [`& .${rootClasses.visuallyHidden}`]: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  [`& .${rootClasses.titleBar}`]: {
    width: "fit-content",
    flex: "1 1 100%",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  [`& .${rootClasses.toolBar}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  [`& .${rootClasses.toolBarSearchOnly}`]: {
    padding: 0,
  },
  [`& .${rootClasses.tableCell}`]: {
    maxWidth: 400,
    wordWrap: "break-word",
    "& pre": {
      whiteSpace: "pre-wrap",
      color: theme.palette.text.primary,
    },
    "&:hover $tableCellAction": {
      display: "initial",
    },
  },
  [`& .${rootClasses.tableCellEmpty}`]: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    paddingTop: 40,
    paddingBottom: 40,
  },
  [`& .${rootClasses.tableCellAction}`]: {
    display: "none",
  },
  [`& .${rootClasses.tableCellHasMenu}`]: {
    "&:hover": {
      background: theme.palette.action.hover,
      cursor: "pointer",
    },
  },
  [`& .${rootClasses.newSearchField}`]: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.simpleTableTitleContainer}`]: {
    display: "flex",
    alignItems: "center",
  },
  [`& .${rootClasses.simpleTableTitle}`]: {
    fontSize: 22,
    marginRight: 5,
    marginBottom: 5,
    fontWeight: "bold",
  },
  [`& .${rootClasses.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${rootClasses.titleTooltipIcon}`]: {
    fill: theme.palette.primary.main,
  },
  [`& .${rootClasses.paginationText}`]: {
    marginBottom: 0,
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  [`& .${contextMenuClasses.linkLi}`]: {
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    "& a": {
      color: theme.palette.text.primary,
      paddingTop: 6,
      paddingBottom: 6,
      paddingRight: 16,
      paddingLeft: 16,
      textDecoration: "none",
      width: "100%",
    },
  },
  [`& .${contextMenuClasses.tooltip}`]: {
    boxShadow: theme.shadows[4],
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  [`& .${contextMenuClasses.disabledMenuItem}`]: {
    color: theme.palette.text.secondary,
  },
  [`& .${contextMenuClasses.buttonLi}`]: {
    padding: 0,
  },
  [`& .${contextMenuClasses.menuItemButton}`]: {
    fontSize: "16px",
    width: "100%",
    padding: "6px 16px",
    justifyContent: "flex-start",
    color: theme.palette.text.primary,
  },
}));
