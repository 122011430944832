import { styled } from "@mui/material/styles";

const PREFIX = "SingleSignOnView";

export const classes = {
  switchButtonLabel: `${PREFIX}-switchButtonLabel`,
  switchButtonContainer: `${PREFIX}-switchButtonContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  textFieldContainer: `${PREFIX}-textFieldContainer`,
  textField: `${PREFIX}-textField`,
  textFieldLabel: `${PREFIX}-textFieldLabel`,
  iconButton: `${PREFIX}-iconButton`,
  submitButton: `${PREFIX}-submitButton`,
  pageDescription: `${PREFIX}-pageDescription`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.switchButtonContainer}`]: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.switchButtonLabel}`]: {
    marginBottom: 5,
    marginRight: 10,
    color: theme.palette.text.primary,
  },
  [`& .${classes.pageDescription}`]: {
    fontSize: 12,
    paddingLeft: theme.shape.padding,
    paddingBottom: theme.shape.padding,
  },
  [`& .${classes.buttonContainer}`]: {
    display: "grid",
    alignItems: "center",
    marginTop: theme.shape.padding,
    gridTemplateColumns: "30px 230px 1fr",
  },
  [`& .${classes.button}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    width: 250,
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  [`& .${classes.sectionTitle}`]: {
    fontSize: 17,
    fontWeight: "bold",
    paddingLeft: theme.shape.padding,
  },
  [`& .${classes.textFieldContainer}`]: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "200px 45px 1fr 45px",
    marginBottom: theme.shape.padding,
  },
  [`& .${classes.textField} input`]: {
    padding: 7.5,
  },
  [`& .${classes.textFieldLabel}`]: {
    marginBottom: 0,
    textAlign: "right",
  },
  [`& .${classes.iconButton}`]: {
    margin: "0px 10px",
  },
  [`& .${classes.submitButton}`]: {
    width: 200,
  },
}));
