import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

/*
Constants and helpers
*/

const INPUT_MAX_LENGTH = 255;

/*
Component
*/

const SearchInput = (props) => {
  const { placeholder, submitOnTheFly, submit } = props;

  const [value, setInputValue] = useState("");
  const [currentPlaceholder, setCurrentPlaceholder] = useState("");

  useEffect(() => {
    setCurrentPlaceholder(placeholder);
  }, [placeholder]);

  const onChange = (evt) => {
    const newValue = evt.target.value || "";

    if (newValue.length > INPUT_MAX_LENGTH) {
      return;
    }

    setInputValue(newValue);

    if (submitOnTheFly) {
      submit(newValue);
    }
  };

  const [inputFocused, setInputFocused] = useState(false);

  const onMouseEnter = () => {
    if (currentPlaceholder) {
      setCurrentPlaceholder("");
    }
  };

  const onMouseLeave = () => {
    if (!inputFocused) {
      setCurrentPlaceholder(placeholder);
    }
  };

  const onFocus = () => {
    setInputFocused(true);
    setCurrentPlaceholder("");
  };

  const onBlur = () => {
    setInputFocused(false);
    setCurrentPlaceholder(placeholder);
  };

  const onKeyPress = (evt) => {
    const { key } = evt;

    if (key === "Enter") {
      evt.preventDefault();

      submit(value);
    }
  };

  return (
    <input
      className="resources-search-input"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyPress={onKeyPress}
      placeholder={currentPlaceholder}
    />
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  submitOnTheFly: PropTypes.bool,
  submit: PropTypes.func,
};

SearchInput.defaultProps = {
  placeholder: "",
  submitOnTheFly: false,
  submit: () => {},
};

export default SearchInput;
